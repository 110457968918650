<template>
    <div>
        <div class="modal-content">
            <div class="modal-header border-bottom">
                <h5 class="modal-title" id="editJobNotesModalTitle">{{ $t('label.add custom field') }}</h5>
                <button type="button" class="close" @click="$emit('cancelFnc')" aria-label="Close">
                    <i class="ri-close-line"></i>
                </button>
            </div>

            <div class="modal-body">
                <!-- Job or Task field -->
                <div class="form-group">
                    <label class="form-control-label text-muted">{{ $t('label.custom field object') }}</label>
                    <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                    <div class="row m-0 py-1">
                        <div class="custom-control custom-radio mr-3" v-for="model in fileModels" :key="model.name">
                            <input name="model" class="custom-control-input" v-model="customfield.model" :value="model.class" :id="`input-model-${model.name}`" type="radio" required>
                            <label class="custom-control-label" :for="`input-model-${model.name}`">{{ model.name }}</label>
                        </div>
                    </div>
                </div>

                <!--  Custom field name  -->
                <div class="text-left form-group">
                    <label class="form-control-label text-muted" for="input-name">{{ $t('label.custom field name') }}</label>
                    <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                    <input type="text" class="form-control" :placeholder="$t('label.custom field name')" v-model="customfield.name" required>
                </div>

                <!--  Field Type  -->
                <div class="text-left form-group mb-2">
                    <label class="form-control-label text-muted">{{ $t('label.field type') }}</label>
                    <custom-field-type modaltype="add" :types="fieldTypes" :has-errors="false" :customfield="customfield" :selected="customfield.type_id" :label="$t('label.custom field type')" @returnFnc="returnFieldType">
                    </custom-field-type>
                </div>

                <!--  Field Status  -->
                <div class="text-left form-group m-0">
                    <label class="form-control-label text-muted">{{ $t('label.custom field status') }}</label>
                    <div class="row m-0 py-1">
                        <div class="custom-control custom-radio mr-3">
                            <input name="status" id="active" class="custom-control-input" value="1" v-model="customfield.status" type="radio">
                            <label class="custom-control-label text-light-black" for="active">{{ $t('label.active') }}</label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input name="status" id="inactive" class="custom-control-input" value="0" v-model="customfield.status" type="radio">
                            <label class="custom-control-label text-light-black" for="inactive">{{ $t('label.inactive') }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-top">
                <button type="button" class="btn btn-primary mr-auto" :disabled="!isValid || !customfield.name" @click="saveItem">{{ $t('label.save') }}</button>
                <button type="button" class="btn btn-link ml-auto" @click="$emit('cancelFnc')">{{ $t('label.cancel') }}</button>
            </div>

        </div>
    </div>
</template>

<style></style>

<script>
import bus from '@/utils/event-bus';
export default {
    props: ['fieldTypes'],
    emits: ['cancelFnc', 'returnFnc'],
    components: {},
    data() {
        return {
            customfield: { id: 0, status: 1, model_name: 'Job', model: "App\\Models\\Job", type_id: 1, name: null },
            isValid: true,
            fileModels: []
        }
    },
    mounted() {
        this.loadCustomFieldModels()
    },
    methods: {
        loadCustomFieldModels() {
            axios
                .get('/loadCustomFieldModels')
                .then(response => {
                    this.fileModels = response.data
                })
                .catch(err => {
                    console.log('ERROR: ', err)
                    bus.$emit('banner-error', { message: err, })
                });
        },
        returnFieldType(returnedFieldType) {
            this.isValid = returnedFieldType.isValid
            this.customfield.type_id = returnedFieldType.selectedTypeId
            this.customfield.type = returnedFieldType.selectedType
            this.customfield.options = returnedFieldType.typeOptions
        },
        saveItem() {
            let updateData = {
                type_id: this.customfield.type_id,
                name: this.customfield.name,
                options: JSON.stringify(this.customfield.options),
                // options: this.customfield.options,
                status: this.customfield.status,
                model: this.customfield.model,
            };

            axios.post('/customFieldsAdd', updateData)
                .then(response => {
                    bus.$emit('banner-success', {
                        message: 'Custom Field Added Successfully!',
                    });
                    //Return saved item
                    this.$emit('returnFnc', response.data)
                })
                .catch(err => {
                    console.log('ERROR: ', err);
                    bus.$emit('banner-error', { message: err });
                });
        },
    }
}
</script>