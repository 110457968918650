import guestGuard from '@/middlewares/GuestGuard';
;

export default {
  name: 'Auth',
  icon: 'people_alt',
  store: 'Auth/store/index.js',
  routes: [
    {
      path: '/',
      name: 'Home',
      redirect: { name: 'dashboard' }
    }, {
      path: '/admin-login',
      name: 'admin-login',
      component: () => import(/* webpackChunkName: "auth" */ './views/admin-login.vue'),
      beforeEnter: guestGuard,
      meta: {
        layout: 'FullPage',
        title: 'Login'
      }
    }, {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "auth" */ './views/check-domain.vue'),
      beforeEnter: guestGuard,
      meta: {
        layout: 'FullPage',
        title: 'Login'
      }
    },
    // {
    //   path: '/register',
    //   name: 'register',
    //   component: () => import(/* webpackChunkName: "auth" */ './views/register.vue'),
    //   beforeEnter: guestGuard,
    //   meta: {
    //     layout: 'FullPage',
    //     title: 'Register'
    //   }
    // }, 
    {
      path: '/register',
      name: 'register',
      component: () => import(/* webpackChunkName: "auth" */ './views/register/index.vue'),
      beforeEnter: guestGuard,
      meta: {
        layout: 'FullPage',
        title: 'Register'
      }
    }, {
      path: '/password/forgot',
      name: 'forgot-password',
      component: () => import(/* webpackChunkName: "auth" */ './views/forgot-password.vue'),
      beforeEnter: guestGuard,
      meta: {
        layout: 'FullPage',
        title: 'Forgot Password'
      }
    }, {
      path: '/password/reset',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "auth" */ './views/reset-password.vue'),
      beforeEnter: guestGuard,
      meta: {
        layout: 'FullPage',
        title: 'Reset Password'
      }
    }
  ],
  preRegisterCheck() {
    return true;
  }
};
