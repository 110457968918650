<template>
    <div class="card">
        <!-- Card Header -->
        <div class="card-header">
            <div class="row">
                <div class="col-6">
                    <h3 class="d-flex align-items-center mb-0">
                        <i class="ri-pencil-ruler-2-line"></i> {{ $t('label.billable items') }}
                    </h3>
                </div>
            </div>
        </div>

        <!-- Vpo Items Table -->
        <div class="table-responsive">
            <table class="table table-flush table-sm">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">{{ $t('label.billable item') }}</th>
                        <th scope="col" width="12%" class="text-center">{{ $t('label.quantity') }}</th>
                        <th scope="col" width="18%">{{ $t('label.amount') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, idx) in vpoItems" :key="idx">
                        <!-- Billable Item Title -->
                        <td>{{ item.billable_item }}</td>
                        <!-- Quantity -->
                        <td class="text-center">{{ item.quantity }}</td>
                        <!-- Parsed Unit Cost -->
                        <td>${{ parseFloat(item.unit_cost).toFixed(2) | formatThousands }}</td>
                    </tr>
                </tbody>
                <tbody class="border-bottom tbody-total-b-top">
                    <tr>
                        <td class=""></td>
                        <td class="align-top pt-3 text-center">
                            <label class="form-control-label text-muted d-block">Sub Total</label>
                        </td>
                        <td class="align-top pt-3 text-left">
                            <label v-if="vpoTotal > 0" class="form-control-label text-muted d-block">${{ sub_total | formatThousands }}</label>
                            <label v-else class="form-control-label text-muted d-block">$0.00</label>
                        </td>
                    </tr>
                    <tr>
                        <td class=""></td>
                        <td class="align-top pt-3 text-center">
                            <label class="form-control-label text-muted d-block">Tax</label>
                        </td>
                        <td class="align-top pt-3 text-left">
                            <label v-if="vpoTotal > 0" class="form-control-label text-muted d-block">${{ tax | formatThousands }}</label>
                            <label v-else class="form-control-label text-muted d-block">$0.00</label>
                        </td>
                    </tr>
                    <!-- Totals -->
                    <tr>
                        <td class=""></td>
                        <td class="align-top pt-3 text-center">
                            <label class="form-control-label text-muted d-block">{{ $t('label.total') }}</label>
                        </td>
                        <td class="align-top pt-3 text-left">
                            <label v-if="vpoTotal > 0" class="form-control-label text-muted d-block">${{ vpoTotal | formatThousands }}</label>
                            <label v-else class="form-control-label text-muted d-block">$0.00</label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style scoped>
.tbody-total-b-top {
    border-top: 1px solid #e9ecef;
}
</style>

<script>
import TablePagination from '@/components/TablePagination';

export default {
    props: ['vpoItems', 'vpoTotal', 'tax'],
    components: {
        TablePagination
    },
    data() {
        return {}
    },
    computed: {
        sub_total() {
            return parseFloat(Number(this.vpoTotal) - Number(this.tax)).toFixed(2);
        }
    },
    mounted() { },
    methods: {},
}
</script>
