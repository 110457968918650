<template>
	<div>
		<div class="header bg-primary pb-6">
			<div class="container-fluid">
				<div class="header-body">
					<div class="row align-items-center py-4">
						<div class="col-lg-6 col-7">
							<h6 class="h2 text-white d-inline-block mb-0">{{ $t('label.search') }}</h6>
							<nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4" style="background: #fff; padding: .625rem 1.25rem; border-radius: .25rem;">
								<ol class="breadcrumb breadcrumb-links">
									<li class="breadcrumb-item">
										<router-link class="d-flex align-items-center" :to="{ name: 'dashboard' }">
											{{ $t('label.dashboard') }}
										</router-link>
										<!-- <a href="/dashboard" class="d-flex align-items-center">
											<i class="ri-dashboard-line"></i> {{ $t('label.dashboard') }}
										</a> -->
									</li>
									<li class="breadcrumb-item">
										<router-link :to="{ name: 'contacts' }">
											{{ $t('label.search results') }}
										</router-link>
										<!-- <a href="/customer">{{ $t('label.search results') }}</a> -->
									</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid mt--6">
			<div class="row">
				<div class="col">
					<div class="card">
						<div class="card-header">
							<div class="row align-items-center">
								<div class="col-8">
									<h3 class="mb-0"><i class="fas fa-search"></i> {{ $t('label.search results') }}</h3>
								</div>
							</div>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-12">
									<div class="btn-group" role="group">
										<button type="button" class="btn btn-secondary" :class="{ active: 0 == selected }" @click="selectResult(0)">{{ $t('label.all results') }} ({{ user.role_id == '2' ? (jobsCount + tasksCount + employeesCount + customersCount) : (tasksCount + employeesCount) }})</button>
										<button v-if="user.role_id == '2'" type="button" class="btn btn-secondary" :class="{ active: 1 == selected }" @click="selectResult(1)">{{ $t('label.jobs') }} ({{ jobsCount }})</button>
										<button type="button" class="btn btn-secondary" :class="{ active: 2 == selected }" @click="selectResult(2)">{{ $t('label.tasks') }} ({{ tasksCount }})</button>
										<button type="button" class="btn btn-secondary" :class="{ active: 3 == selected }" @click="selectResult(3)">{{ $t('label.employees') }} ({{ employeesCount }})</button>
										<button v-if="user.role_id == '2'" type="button" class="btn btn-secondary" :class="{ active: 4 == selected }" @click="selectResult(4)">{{ $t('label.customers') }} ({{ customersCount }})</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<div class="card" v-if="customerstable && user.role_id == '2'">
						<div class="card-header">
							<div class="row align-items-center">
								<div class="col-8">
									<h3 class="mb-0">{{ $t('label.customers') }}</h3>
								</div>
							</div>
						</div>
						<div class="table-responsive">
							<v-client-table ref="vuetable" :columns="columns4" v-model="allcustomers" :options="options4">
								<template slot="name" slot-scope="props">
									<a :href="'/customer/' + props.row.id + '/edit'">{{ props.row.name == null ? '' : props.row.name }}</a>
								</template>
								<template slot="address" slot-scope="props">
									<span v-if="props.row.address_1">{{ props.row.address_1 }}</span>
									<span v-if="props.row.address_2">, {{ props.row.address_2 }}</span>
									<span v-if="props.row.city">, {{ props.row.city }}</span>
									<span v-if="props.row.state">
										<div v-for="(data, index) in allstates" :key="index">
											<span v-if="(data == props.row.state)">{{ index[0].toUpperCase() + index.substring(1).toLowerCase() }}</span>
										</div>
									</span>
									<span v-if="props.row.postal_code">, {{ props.row.postal_code }}</span>
								</template>
								<template slot="phone1" slot-scope="props">
									{{ props.row.phone_1 == null ? '' : props.row.phone_1 }}
								</template>
								<template slot="phone2" slot-scope="props">
									{{ props.row.phone_2 == null ? '' : props.row.phone_2 }}
								</template>
								<template slot="actions" slot-scope="props">
									<div class="w-50">
										<b-dropdown toggle-class="text-decoration-none w-100" style="width: 100%">
											<template v-slot:button-content>
												<i class="fas fa-ellipsis-v w-100"></i>
											</template>
											<b-dropdown-item-button>
												<a :href="'/customer/' + props.row.id + '/edit'">{{ $t('label.edit') }}</a>
											</b-dropdown-item-button>
										</b-dropdown>
									</div>
								</template>
							</v-client-table>
						</div>
					</div>
					<div class="card" v-if="jobstable && user.role_id == '2'">
						<div class="card-header">
							<div class="row align-items-center">
								<div class="col-8">
									<h3 class="mb-0">{{ $t('label.jobs') }}</h3>
								</div>
							</div>
						</div>
						<div class="table-responsive">
							<v-client-table ref="vuetable" :columns="columns" v-model="alljobs" :options="options">
								<template slot="job_status.name" slot-scope="props">
									<span class="badge badge-dot mr-4">
										<i class="bg-success"></i>
										{{ props.row.job_status.name }}
									</span>
								</template>
								<template slot="customer.name" slot-scope="props">
									<a :href="'/customer/' + props.row.customer.id + '/edit'">{{ props.row.customer == null ? '' : props.row.customer.name }}</a>
								</template>
								<template slot="job_manager.name" slot-scope="props" v-if="props.row.job_manager">
									<img class="avatar avatar-sm rounded-circle" :src="props.row.job_manager.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
									{{ props.row.job_manager.name }}
								</template>
								<template slot="start_date" slot-scope="props">
									{{ props.row.start_date | formatDate }}
								</template>
								<template slot="end_date" slot-scope="props">
									{{ props.row.end_date | formatDate }}
								</template>
								<template slot="job_name" slot-scope="props">
									<a :href="'/job/' + props.row.job_number.job_number + '/edit'">{{ props.row.job_name }}</a>
								</template>
								<template slot="actions" slot-scope="props">
									<div class="w-100">
										<b-dropdown toggle-class="text-decoration-none w-100" style="width: 100%">
											<template v-slot:button-content>
												<i class="fas fa-ellipsis-v w-100"></i>
											</template>
											<b-dropdown-item-button>
												<a :href="'/customer/' + props.row.id + '/edit'">{{ $t('label.edit') }}</a>
											</b-dropdown-item-button>
										</b-dropdown>
									</div>
								</template>
							</v-client-table>
						</div>
					</div>
					<div class="card" v-if="taskstable">
						<div class="card-header">
							<div class="row align-items-center">
								<div class="col-8">
									<h3 class="mb-0">{{ $t('label.tasks') }}</h3>
								</div>
							</div>
						</div>
						<div class="table-responsive">
							<v-client-table ref="vuetable" :columns="columns2" v-model="alltasks" :options="options2">
								<template slot="job.job_number.job_number" slot-scope="props">
									{{ props.row.job.job_number == null ? '' : props.row.job.job_number.job_number }}
								</template>
								<template slot="job.customer.name" slot-scope="props">
									<a v-if="props.row.job.customer && user.role_id == '2'" :href="'/customer/' + props.row.job.customer.id + '/edit'">{{ props.row.job.customer.name }}</a>
									<span v-else>{{ props.row.job.customer.name }}</span>
								</template>
								<template slot="job.job_number.job_number" slot-scope="props">
									{{ props.row.job.job_number == null ? '' : props.row.job.job_number.job_number }}
								</template>
								<template slot="job.job_name" slot-scope="props">
									<a v-if="user.role_id == '2'" :href="'/job/' + props.row.job.job_number.job_number + '/edit'">{{ props.row.job.job_name }}</a>
									<span v-else>{{ props.row.job.job_name }}</span>
								</template>
								<template slot="job.job_manager.name" slot-scope="props">
									<div v-if="props.row.job.job_manager != null">
										<img class="avatar avatar-sm rounded-circle" :src="props.row.job.job_manager.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
										{{ props.row.job.job_manager.name }}
									</div>
								</template>
								<template slot="task_status.name" slot-scope="props">
									<span class="badge badge-dot mr-4">
										<i class="bg-success"></i>
										{{ props.row.task_status.name }}
									</span>
								</template>
								<template slot="actions" slot-scope="props">
									<div v-if="user.role_id == '2'" class="w-100">
										<b-dropdown toggle-class="text-decoration-none w-100" style="width: 100%">
											<template v-slot:button-content>
												<i class="fas fa-ellipsis-v w-100"></i>
											</template>
											<b-dropdown-item-button>
												<a :href="'/customer/' + props.row.id + '/edit'">{{ $t('label.edit') }}</a>
											</b-dropdown-item-button>
										</b-dropdown>
									</div>
								</template>
							</v-client-table>
						</div>
					</div>
					<div class="card" v-if="employeestable">
						<div class="card-header">
							<div class="row align-items-center">
								<div class="col-8">
									<h3 class="mb-0">{{ $t('label.employees') }}</h3>
								</div>
							</div>
						</div>
						<div class="table-responsive">
							<v-client-table ref="vuetable" :columns="columns3" v-model="allemployees" :options="options3">
								<template slot="name" slot-scope="props">
									<img class="avatar avatar-sm rounded-circle" :src="props.row.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
									{{ props.row.name }}
								</template>
								<template slot="actions" slot-scope="props">
									<div v-if="user.role_id == '2'" class="w-50">
										<b-dropdown toggle-class="text-decoration-none w-100" style="width: 100%">
											<template v-slot:button-content>
												<i class="fas fa-ellipsis-v w-100"></i>
											</template>
											<b-dropdown-item-button>
												<a :href="'/customer/' + props.row.id + '/edit'">{{ $t('label.edit') }}</a>
											</b-dropdown-item-button>
										</b-dropdown>
									</div>
								</template>
							</v-client-table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
	
<script>
export default {
	props: ['searchresult'],
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
			alljobs: [],
			alltasks: [],
			allemployees: [],
			allcustomers: [],
			allstates: [],
			jobstable: false,
			taskstable: false,
			employeestable: false,
			customerstable: false,
			selected: 0,
			jobsCount: 0,
			tasksCount: 0,
			employeesCount: 0,
			customersCount: 0,
			// JOBS
			columns: ['job_number.job_number', 'job_name', 'customer.name', 'job_manager.name', 'job_status.name', 'start_date', 'end_date', 'actions'],
			options: {
				perPageValues: [],
				headings: {
					'job_number.job_number': this.$t('label.job id'),
					job_name: this.$t('label.job name'),
					'customer.name': this.$t('label.customer'),
					'job_manager.name': this.$t('label.job manager'),
					'job_status.name': this.$t('label.job status'),
					start_date: this.$t('label.start date'),
					end_date: this.$t('label.due date'),
					actions: '',
				},
				editableColumns: ['job_number.job_number', 'job_name', 'customer.name', 'job_manager.name', 'job_status.name', 'start_date', 'end_date'],
				sortable: ['job_number.job_number', 'job_name', 'customer.name', 'job_manager.name', 'job_status.name', 'start_date', 'end_date'],
				filterable: false,
				columnsClasses: {
					'job_number.job_number': 'text-left',
					job_name: 'text-left',
					'customer.name': 'text-left',
					'job_manager.name': 'text-left',
					'job_status.name': 'text-left',
					start_date: 'text-left',
					end_date: 'text-left',
					actions: 'text-center actions-column'
				},
			},
			// TASKS
			columns2: ['job.job_number.job_number', 'job.job_name', 'job.customer.name', 'name', 'job.job_manager.name', 'task_status.name', 'actions'],
			options2: {
				perPageValues: [],
				headings: {
					'job.job_number.job_number': this.$t('label.job id'),
					'job.job_name': this.$t('label.job name'),
					'job.customer.name': this.$t('label.customer'),
					name: $t('label.task name'),
					'job.job_manager.name': this.$t('label.job manager'),
					'task_status.name': this.$t('label.task status'),
					actions: '',
				},
				editableColumns: ['job.job_number.job_number', 'job.job_name', 'job.customer.name', 'name', 'job.job_manager.name', 'task_status.name'],
				sortable: ['job.job_number.job_number', 'job.job_name', 'job.customer.name', 'name', 'job.job_manager.name', 'task_status.name'],
				filterable: false,
				columnsClasses: {
					'job.job_number.job_number': 'text-left',
					'job.job_name': 'text-left',
					'job.customer.name': 'text-left',
					name: 'text-left',
					'job.job_manager.name': 'text-left',
					'task_status.name': 'text-left',
					actions: 'text-center actions-column'
				},
			},
			// EMPLOYEES
			columns3: ['name', 'email', 'phone', 'phone', 'manager', 'actions'],
			options3: {
				perPageValues: [],
				headings: {
					name: this.$t('label.employee name'),
					email: this.$t('label.email'),
					phone: this.$t('label.work phone'),
					phone: this.$t('label.mobile phone'),
					manager: this.$t('label.manager'),
					actions: ''
				},
				editableColumns: ['name', 'email', 'phone', 'phone', 'manager'],
				sortable: ['name', 'email', 'phone', 'phone', 'manager'],
				filterable: false,
				columnsClasses: {
					name: 'text-left',
					email: 'text-left',
					phone: 'text-left',
					phone: 'text-left',
					name: 'text-left',
					actions: 'text-center actions-column'
				},
			},
			// CUSTOMERS
			columns4: ['name', 'email', 'address', 'phone1', 'phone2', 'actions'],
			options4: {
				perPageValues: [],
				headings: {
					name: this.$t('label.customer name'),
					email: this.$t('label.email'),
					address: this.$t('label.address'),
					phone1: this.$t('label.phone') + ' 1',
					phone2: this.$t('label.phone') + ' 2',
					actions: ''
				},
				editableColumns: ['name', 'email', 'address', 'phone1', 'phone2'],
				sortable: ['name', 'email', 'address', 'phone1', 'phone2'],
				filterable: false,
				columnsClasses: {
					name: 'text-left',
					email: 'text-left',
					phone: 'text-left',
					phone: 'text-left',
					name: 'text-left',
					actions: 'text-center actions-column'
				},
			},
		}
	},
	mounted() {
		this.getAllJobs();
		this.getAllStates();
		this.selectResult(0);
		if (!this.searchresult) {
			this.$router.replace('dashboard')
		}
	},
	methods: {
		getAllJobs() {
			var request_fields = {
				'search': this.searchresult,
				'take': '1000'
			};

			axios.post('/search', request_fields)
				.then(response => {
					this.alljobs = response.data.result.jobs;
					this.alltasks = response.data.result.tasks;
					this.allemployees = response.data.result.employees;
					this.allcustomers = response.data.result.customers;
					this.jobsCount = response.data.result.jobsCount;
					this.tasksCount = response.data.result.tasksCount;
					this.employeesCount = response.data.result.employeesCount;
					this.customersCount = response.data.result.customersCount;
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		getAllStates() {
			axios.get('/get-states').then(response => {
				this.allstates = response.data.result;
			})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		selectResult(val) {
			if (val == 0) {
				this.jobstable = true;
				this.taskstable = true;
				this.employeestable = true;
				this.customerstable = true;
				this.selected = 0;
			}
			if (val == 1) {
				this.jobstable = true;
				this.taskstable = false;
				this.employeestable = false;
				this.customerstable = false;
				this.selected = 1;
			}
			if (val == 2) {
				this.jobstable = false;
				this.taskstable = true;
				this.employeestable = false;
				this.customerstable = false;
				this.selected = 2;
			}
			if (val == 3) {
				this.jobstable = false;
				this.taskstable = false;
				this.employeestable = true;
				this.customerstable = false;
				this.selected = 3;
			}
			if (val == 4) {
				this.jobstable = false;
				this.taskstable = false;
				this.employeestable = false;
				this.customerstable = true;
				this.selected = 4;
			}
		}
	}
}
</script>
<style scoped>
.VueTables.VueTables--client>.row:first-child {
	display: none;
}

.dropdown-toggle::after {
	content: none;
}

.dropdown-toggle {
	background-color: transparent;
	border-color: transparent;
	color: #ced4da !important;
	padding: 0;
}

.table-bordered th,
.table-bordered td {
	background-color: #fff;
	border: none;
	border-bottom: 1px solid #e9ecef
}

.table-bordered thead th,
.table-bordered thead td {
	color: #8898aa;
	background-color: #f6f9fc;
}

.VuePagination {
	height: 75px;
	margin: 0;
}

/* .pagination {
			position: absolute;
			right: 0;
			top: 25%;
		} */
.VuePagination__count {
	position: absolute;
	left: 0;
	top: 25%;
	text-align: left !important;
	font-family: Open Sans, sans-serif;
	font-size: .875rem;
}

li.VuePagination__pagination-item.page-item a.page-link {
	z-index: 2000;
}

.table th,
.table td {
	vertical-align: center;
}
</style>
	