<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50" id="jobTypesContainer">
            <!-- HEADER -->
            <div class="d-flex" style="z-index: 100">
                <!-- Breadcrumbs -->
                <breadcrumbs :title="$t('label.reports')">
                    <!-- Breakcrumb slots -->
                    <li class="breadcrumb-item">
                        {{ $t('label.reports') }}
                    </li>
                </breadcrumbs>

                <!-- Buttons -->
                <div class="ml-auto py-4 align-items-center">
                    <!-- <button class="btn btn-neutral" @click="toggleAddModal(true)">
                        Not Needed on Reports
                    </button> -->
                </div>
            </div>

            <!-- Content -->
            <div class="card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h3 class="d-flex mb-0"><i class="ri-bar-chart-box-line"></i> {{ $t('label.reports') }}</h3>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6">
                            <ul class="list-group">
                                <li class="bg-gray-100 list-group-item text-sm">
                                    Time Related Reports
                                </li>
                                <li class="list-group-item">
                                    <router-link class="h4" :to="{ name: 'timeOffReport' }">
                                        Time Off Report
                                    </router-link>
                                </li>
                                <li class="list-group-item">
                                    <router-link class="h4" :to="{ path: `reports/timeSheetReport/${'reports'}` }">
                                        Timesheet Report
                                    </router-link>
                                </li>
                                <li class="list-group-item">
                                    <router-link class="h4" :to="{ name: 'payrollReport' }">
                                        Payroll Report
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="col-6 col-xs-12">
                            <ul class="list-group">
                                <li class="bg-gray-100 list-group-item text-sm">
                                    Admin Reports
                                </li>
                                <li class="list-group-item">
                                    <router-link class="h4" :to="{ name: 'dailyRecapReport' }">Daily Recap Report</router-link>
                                </li>
                                <li class="list-group-item">
                                    <router-link v-if="user.role_id === 2" class="h4" :to="{ name: 'noShowReport' }">No Call - No Show</router-link>
                                </li>
                                <li class="list-group-item">
                                    <router-link class="h4" :to="{ name: 'invoiceReport' }">Invoices</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<style></style>

<script>
export default {
    props: [],
    components: {},
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            loader: null
        }
    },
    mounted() {
        this.loader = this.$loading.show();
        setTimeout(() => {
            this.loader.hide();
        }, 500);
    },
    methods: {

    }
}
</script>