<template>
    <div class="card">
        <div class="card-header mb--5" style="border-bottom: 0px;">
            <div class="row">
                <div class="col-sm-6 pt-2">
                    <h3 class="d-flex mb-0"><i class="ri-truck-line"></i> {{ $t('label.vehicles') }}</h3>
                </div>
                <div class="col-sm-6 pt-2 pt-sm-0">
                    <div class="form-group input-group input-group-alternative input-group-merge">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="ri-search-line"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="search" v-model="vehicleSearch" :change="searchVehicles()" :placeholder="$t('label.vehicles search')" />
                    </div>
                </div>
            </div>
        </div>

        <div class="m-0 p-0">
            <div class="row">
                <div class="col-sm-12">
                    <!-- Compact View -->
                    <div class="table-responsive pt-4" id="compact-vehicles" v-if="viewType === 'compact'">
                        <table id="vehiclesCompactDT" class="table table-sm table-flush compact border-bottom">
                            <thead class="thead-light">
                                <tr class="">
                                    <th scope="col">
                                        <span class="sp-row-sm-pl">{{ $t("label.vehicles name") }}</span>
                                    </th>
                                    <th scope="col" class="hidden-md-down">
                                        {{ $t("label.vehicles vin") }}
                                    </th>
                                    <th scope="col" class="hidden-sm-down">
                                        {{ $t("label.vehicles plate") }}
                                    </th>
                                    <th scope="col">
                                        {{ $t("label.vehicles operator") }}
                                    </th>
                                    <th scope="col" class="hidden-xs-down">
                                        {{ $t("label.status") }}
                                    </th>
                                    <th scope="col" class="hidden-xs-down">
                                        {{ $t("label.vehicles current meter") }}
                                    </th>
                                    <th style="min-width: 50px"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- Loop vehicles  -->
                                <template v-if="vehicles.length">
                                    <tr class="list-group-item-action" v-for="(vehicle, index) in paginatedVehicles" :key="index" style="vertical-align: middle">
                                        <td class="sp-va-mid">
                                            <div class="d-flex align-items-center sp-row-sm-pl">
                                                <!-- Vehicle Image -->
                                                <img v-if="vehicle.vehicle_photo" class="rounded" :src="vehicle.vehicle_photo" alt="" style="height:35px; width: 56px" />
                                                <img v-else class="rounded" :src="require('@/assets/images/vehicle-photos/default-car.jpg')" alt="" style="width: 56px" />&nbsp;
                                                <span>
                                                    <!-- Vehicle Name -->
                                                    <router-link class="text-dark-blue text-bold pointer" :to="{ name: 'vehicleView', params: { selectedVehicleId: vehicle.id } }">
                                                        {{ vehicle.vehicle_name }}
                                                    </router-link>

                                                    <!-- Status for Mobile View -->
                                                    <div class="d-block d-sm-none">
                                                        <span class="vehicle-status" :class="vehicle.status_class_name"></span>
                                                        &nbsp;{{ vehicle.status }}
                                                    </div>
                                                </span>
                                            </div>
                                        </td>
                                        <!-- VIN -->
                                        <td class="hidden-md-down sp-va-mid">{{ vehicle.vin }}</td>
                                        <!-- License Plate  -->
                                        <td class="hidden-sm-down sp-va-mid">{{ vehicle.license_plate }}</td>
                                        <!-- Operator -->
                                        <td class="sp-va-mid">
                                            <div class="d-flex align-items-center" v-if="vehicle.operator_history !== null">
                                                <img class="rounded-circle" :src="vehicle.operator_history.operator.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">&nbsp;
                                                <span class="cursor-pointer d-flex" @click="getUserInfo(vehicle.operator_history.operator.id, $t('label.vehicles operator'))">{{ vehicle.operator_history.operator.name }}</span>
                                            </div>
                                            <div v-else class="text-muted">
                                                {{ $t('label.unassigned') }}
                                            </div>
                                        </td>
                                        <!-- Vehicle status  -->
                                        <td class="hidden-xs-down sp-va-mid">
                                            <span class="vehicle-status" :class="vehicle.status_class_name"></span>&nbsp;
                                            {{ vehicle.status }}
                                        </td>
                                        <!-- Check for last odometer reading  -->
                                        <td class="hidden-xs-down sp-va-mid">
                                            <span>
                                                {{ (vehicle.odometer_reading !== null) ? vehicle.odometer_reading.odometer_reading.toLocaleString() + " mi" : "No Reading" }}
                                            </span>
                                        </td>

                                        <!-- Menu items -->
                                        <td class="text-right sp-va-mid" style="min-width: 60px">
                                            <div class="d-flex float-right">
                                                <span class="hidden-xs-down pt-1 pb--1" v-if="vehicle.comments_count >= 1">
                                                    <i class="ri-message-2-line"></i>
                                                </span>
                                                <span class="hidden-xs-down pt-1 pb--1" v-if="vehicle.files_count >= 1">
                                                    <i class="ri-folders-line"></i>
                                                </span>
                                                <span class="dropdown pr-0 pl-1">
                                                    <a class="btn btn-sm" href="#" role="button" data-toggle="dropdown">
                                                        <i class="fas fa-ellipsis-v text-light"></i>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                        <!-- View Vehicle  -->
                                                        <router-link class="dropdown-item" :to="{ name: 'vehicleView', params: { selectedVehicleId: vehicle.id } }">
                                                            <i class="ri-eye-line"></i>{{ $t("label.view") }}
                                                        </router-link>
                                                        <!-- Edit Vehicle -->
                                                        <router-link class="dropdown-item" :to="{ name: 'vehicleEdit', params: { selectedVehicleId: vehicle.id } }">
                                                            <i class="ri-pencil-line"></i>{{ $t("label.edit") }}
                                                        </router-link>
                                                        <!-- Archive vehicle -->
                                                        <a class="dropdown-item" href="#" @click="clickArchiveVehicle(vehicle)" data-toggle="modal">
                                                            <i class="ri-archive-line"></i>{{ $t("label.mark as sold/archived") }}
                                                        </a>
                                                    </div>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                        <table-pagination 
                            v-model="paginatedVehicles" 
                            :all-rows="filteredVehicles" 
                            :perPage="10">
                        </table-pagination>

                        <!-- Display add item if no items exist -->
                        <div v-if="filteredVehicles.length == 0" class="text-center pt-5">
                            <div class="form-group">
                                <h3>{{ $t('label.no vehicle assets') }}</h3>
                            </div>
                            <div class="form-group">
                                <router-link class="btn btn-primary" to="vehicle/create">
                                    {{ $t('label.add vehicle') | uppercase }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Regular View -->
            <div class="table-responsive pt-4" v-if="viewType === 'regular'">
                <table class="table table-flush regular">
                    <thead>
                        <tr>
                            <td class="p-0 m-0"></td>
                        </tr>
                    </thead>
                    <tbody class="border-bottom">
                        <tr class="list-group-item-action" v-for="(vehicle, index) in filteredVehicles" :key="index">
                            <td>
                                <div class="row regular-row">
                                    <div class="col-sm-7">
                                        <div class="row" style="padding-bottom: 0px; margin-bottom: 0px">
                                            <div class="col-sm-6 pl-2">
                                                <!-- Image -->
                                                <div class="mobile-img">
                                                    <img v-if="vehicle.vehicle_photo" class="rounded" :src="'../' + vehicle.vehicle_photo" alt="" style="width:75px; height: 42px" />
                                                    <img v-else class="rounded" :src="'/vehicle-photos/default-car.jpg'" alt="" style="width: 75px;" />
                                                </div>
                                            </div>
                                            <div class="col-sm-6 text-xs">
                                                <!-- Name -->
                                                <div class="row border-bottom form vehicle-info-row pb-1">
                                                    <div class="col-lg-4 col-sm-12">
                                                        <div class="text-xs text-muted">
                                                            {{ $t("label.vehicles name") }}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-8 col-sm-12">
                                                        <div class="text-bold text-dark-blue">
                                                            <router-link class="text-dark-blue text-bold pointer" :to="{ name: 'vehicleView', params: { selectedVehicleId: vehicle.id } }">
                                                                {{ vehicle.vehicle_name }}
                                                            </router-link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- Vin -->
                                                <div class="row border-bottom vehicle-info-row py-1">
                                                    <div class="col-lg-4 col-sm-12">
                                                        <div class="text-xs text-muted">
                                                            {{ $t("label.vehicles vin") }}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-8 col-sm-12">
                                                        <div class="pr-0">
                                                            {{ vehicle.vin }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-xs">
                                            <div class="row border-bottom vehicle-info-row py-1">
                                                <!-- Year -->
                                                <div class="col-sm-6 col-3">
                                                    <div class="row">
                                                        <div class="text-xs text-muted col-lg-4 col-sm-12">
                                                            {{ $t("label.vehicles year") }}
                                                        </div>
                                                        <div class="col-lg-8 col-sm-12">
                                                            {{ vehicle.year }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- License plate -->
                                                <div class="col-sm-6 col-9">
                                                    <div class="row">
                                                        <div class="col-lg-4 col-sm-12">
                                                            <div class="text-muted">
                                                                {{ $t("label.vehicles plate") }}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-8 col-sm-12">
                                                            <div>
                                                                {{ vehicle.license_plate }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row border-bottom vehicle-info-row py-1">
                                                <!-- Make -->
                                                <div class="col-sm-6 col-3">
                                                    <div class="row">
                                                        <div class="col-lg-4 col-sm-12">
                                                            <div class="text-xs text-muted">
                                                                {{ $t("label.vehicles make") }}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-8 col-sm-12">
                                                            <div>
                                                                {{ vehicle.make }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- Registration Details  -->
                                                <div class="col-sm-6 col-9">
                                                    <div class="row">
                                                        <div class="col-lg-4 col-sm-12 col-12">
                                                            <div class="text-xs text-muted">
                                                                {{ $t("label.exp date") }}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-8 col-sm-12 col-12">
                                                            <div class="row">
                                                                <div class="col-lg-5 col-md-5 col-sm-12 col-3">
                                                                    <div class="mr-0 pr-0">
                                                                        {{ vehicle.registration_expiration | formatMonthDay }}
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-7 col-md-7 col-sm-12 col-9">
                                                                    <div class="text-lg-right text-muted">
                                                                        <span refs="expires" class="" :class="checkRegistDue(vehicle)">
                                                                            {{ registrationExpCalc(vehicle) }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row border-bottom vehicle-info-row py-1">
                                                <!-- Model -->
                                                <div class="col-sm-6 col-3">
                                                    <div class="row">
                                                        <div class="col-lg-4 col-sm-12">
                                                            <div class="text-xs text-muted">
                                                                {{ $t("label.vehicles model") }}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-8 col-sm-12">
                                                            <div>
                                                                {{ vehicle.model }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- Odometer -->
                                                <div class="col-sm-6 col-9 sp-mobile-block">
                                                    <div class="row">
                                                        <div class="col-lg-4 col-sm-12">
                                                            <div class="text-xs text-muted">
                                                                {{ $t("label.vehicles current meter") }}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-sm-12">
                                                            <div>
                                                                {{ (vehicle.odometer_reading !== null) ? vehicle.odometer_reading.odometer_reading.toLocaleString() + " mi" : "No Reading" }}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-sm-12">
                                                            <div class="text-right text-muted">
                                                                <span v-if="vehicle.odometer">{{ vehicle.odometer.created_at | formatDateOnly }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-5">
                                        <div class="row">
                                            <div class="col-sm-1"></div>
                                            <div class="col-sm-10">
                                                <!-- Assigned Operator -->
                                                <div class="row border-bottom py-1">
                                                    <div class="col-lg-3 col-md-4 col-sm-12 text-xs text-muted mobile-operator">
                                                        <div class="vehicle-info-row" style="position: absolute; bottom: 0">
                                                            {{ $t("label.vehicles operator") }}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-9 col-md-8 col-sm-12 vehicle-info-row d-flex align-items-center">
                                                        <template v-if="vehicle.operator_history !== null">
                                                            <img class="rounded-circle" :src="vehicle.operator_history.operator.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">&nbsp;
                                                            <span class="cursor-pointer" @click="getUserInfo(vehicle.operator_history.operator.id, $t('label.vehicles operator'))">
                                                                {{ vehicle.operator_history.operator.name }}
                                                            </span>
                                                        </template>
                                                        <template v-else>
                                                            <span>{{ $t("label.unassigned") }}</span>
                                                        </template>
                                                    </div>
                                                </div>
                                                <!-- Vehicle Status -->
                                                <div class="row border-bottom vehicle-info-row py-1">
                                                    <div class="col-lg-3 col-md-4 col-sm-12 text-xs text-muted">
                                                        {{ $t("label.status") }}
                                                    </div>
                                                    <div class="col-lg-9 col-md-8 col-sm-12">
                                                        <span class="vehicle-status" :class="vehicle.status_class_name" style="margin-left: 13px"></span>&nbsp;
                                                        {{ vehicle.status }}
                                                    </div>
                                                </div>
                                                <!-- Commentand File Count & Options -->
                                                <div class="d-flex count-text pt-2">
                                                    <div class="mr-auto">
                                                        <span class="pt-1 pr-2" v-if="vehicle.comments_count >= 1">
                                                            <img class="icon-img" :src="'images/icons/message-2-line.png'" alt="">&nbsp;
                                                            {{ vehicle.comments_count }} {{ $t('label.comments') }}
                                                        </span>
                                                        <span class="pt-1 d-inline-block" v-if="vehicle.files_count >= 1">
                                                            <img class="icon-img" :src="'images/icons/folders-line.png'" alt="">&nbsp;
                                                            {{ vehicle.files_count }} {{ $t('label.files') }}
                                                        </span>
                                                    </div>

                                                    <!-- Dropdown -->
                                                    <div class="ml-auto">
                                                        <div class="dropdown">
                                                            <a class="btn btn-sm" href="#" role="button" data-toggle="dropdown">
                                                                <i class="fas fa-ellipsis-v"></i>
                                                            </a>
                                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                                <!-- View Vehicle  -->
                                                                <router-link class="dropdown-item" :to="{ name: 'vehicleView', params: { selectedVehicleId: vehicle.id } }">
                                                                    <i class="ri-eye-line"></i>{{ $t("label.view") }}
                                                                </router-link>
                                                                <!-- Edit Vehicle -->
                                                                <router-link class="dropdown-item" :to="{ name: 'vehicleEdit', params: { selectedVehicleId: vehicle.id } }">
                                                                    <i class="ri-pencil-line"></i>{{ $t("label.edit") }}
                                                                </router-link>
                                                                <!-- Archive vehicle -->
                                                                <a class="dropdown-item" href="#" @click="clickArchiveVehicle(vehicle)" data-toggle="modal">
                                                                    <i class="ri-archive-line"></i>{{ $t("label.mark as sold/archived") }}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table-pagination 
                    v-model="paginatedVehicles" 
                    :all-rows="filteredVehicles" 
                    :perPage="10">
                </table-pagination>
            </div>
        </div>

        <!-- Archive Vehicle Modal -->
        <div v-if="archiveToggle">
            <archive-asset :asset="vehicleToArchive" :asset-type="'vehicle'" @onArchive="onArchiveVehicle()" @onClose="onCloseArchive"></archive-asset>
        </div>

    </div>
</template>

<script>

import moment from '@/utils/date-time';
import * as _ from 'lodash';
import TablePagination from '@/components/TablePagination';

export default {
    props: ['filters', 'viewType'],
    emits: ['onLoaded'],
    components: {
        TablePagination
    },
    data() {
        return {
            archiveToggle: false,
            compactView: true,
            vehicles: [],
            filteredVehicles: [],
            paginatedVehicles: [],
            filterType: "all",
            vehicleSearch: "",
            vehicleSearchPrerender: "",
            vehicleToArchive: {}
        };
    },
    watch: {
        filters() {
            if (this.vehicles.length) {
                // Clear search bar
                this.vehicleSearch = "";
                this.vehicleSearchPrerender = "";

                this.vehicleFilter(this.vehicles, this.filters);
            }
        },
    },
    mounted() {
        this.getAllVehicles();
        console.log("backend path", this.$storagePath);
    },
    methods: {
        getAllVehicles() {
            axios.get("/get-all-vehicles").then(response => {
                this.vehicles = response.data;
                this.filteredVehicles = JSON.parse(JSON.stringify(this.vehicles));
                this.$emit('onLoaded');

            }).catch(err => console.log(err));
        },

        vehicleFilter(vehicles, filters) {
            // If both Assigned statuses are false return an empty list.
            if (!(filters.assignedStatuses.assigned || filters.assignedStatuses.unassigned))
                return [];

            this.filteredVehicles = vehicles.filter(v => {
                // Filter any with assigned status only if NOT showing all assigned
                if (!(filters.assignedStatuses.assigned && filters.assignedStatuses.unassigned)) {
                    if (filters.assignedStatuses.assigned && v.operator_history.operator === null)
                        return false;
                    else if (filters.assignedStatuses.unassigned && v.operator_history.operator)
                        return false;
                }

                // Filter based on given vehicle status
                if (filters.assetStatuses.active && (v.status === "Active"))
                    return true;
                else if (filters.assetStatuses.inShop && (v.status === "In Shop"))
                    return true;
                else if (filters.assetStatuses.inactive && (v.status === "Inactive"))
                    return true;
                else if (filters.assetStatuses.outOfService && (v.status === "Out of Service"))
                    return true;
                else if (filters.assetStatuses.sold && (v.status === "Sold"))
                    return true;
                else
                    return false;
            });
        },

        // Registration
        checkRegistDue(vehicle) {
            if (vehicle.registration_expiration) {
                var threeMonthsSecs = (((60 * 60) * 24) * 30) * 3,
                    vehicleSecs = "";

                // Calculate amount of seconds from now to expiration 
                vehicleSecs = new Date(vehicle.registration_expiration).getTime();
                vehicleSecs = vehicleSecs - new Date().getTime();
                vehicleSecs = Math.floor(vehicleSecs / 1000);

                // Expiration passed due
                if (vehicleSecs <= 0)
                    return "registration-expired";
                // Under three months
                if (vehicleSecs <= threeMonthsSecs)
                    return "registration-due";
            }
        },
        registrationExpCalc(vehicle) {
            if (vehicle.registration_expiration) {
                var expMonths = moment(vehicle.registration_expiration).fromNow(true),
                    splitExpMonth = expMonths.split(" "),
                    timeLeft = "";

                // For expired registrations
                if (new Date(vehicle.registration_expiration).getTime() - new Date().getTime() <= 0) {
                    return "Expired";
                }
                // Construct label
                if (splitExpMonth[1] === "year" || splitExpMonth[1] === "years") {
                    if (splitExpMonth[0] === "a")
                        timeLeft = "1 " + this.$t("label.year");
                    else
                        timeLeft = splitExpMonth[0] + " " + this.$t("label.years");
                } else if (splitExpMonth[1] === "month" || splitExpMonth[1] === "months") {
                    if (splitExpMonth[0] === "a")
                        timeLeft = "1 " + this.$t("label.month");
                    else
                        timeLeft = splitExpMonth[0] + " " + this.$t("label.months");
                } else if (splitExpMonth[1] === "day" || splitExpMonth[1] === "days") {
                    if (splitExpMonth[0] === "a")
                        timeLeft = "1 " + this.$t("label.day");
                    else
                        timeLeft = splitExpMonth[0] + " " + this.$t("label.days");
                } else {
                    timeLeft = "0 " + this.$t("label.days");
                }

                timeLeft += " " + this.$t("label.left");
                return timeLeft;
            }
        },

        // Search Bar Filter
        searchVehicles: _.debounce(function () {
            // After page rerenders from :change, don't proceed unless there is a change in search string
            if (this.vehicleSearch !== this.vehicleSearchPrerender) {
                if (this.vehicleSearch.length > 0) {
                    var search = this.vehicleSearch.toLowerCase(),
                        filtered = [];
                    this.filterType = "all";

                    // Find vehicles with fields matching search string
                    this.vehicles.forEach(v => {
                        var name = v.vehicle_name ? v.vehicle_name : "",
                            l_plate = v.license_plate ? v.license_plate : "",
                            vin = v.vin ? v.vin : "",
                            operator = (v.operator_history.operator) ? v.operator_history.operator.name : "",
                            status = v.status ? v.status : "";

                        // If matching, add to list
                        if (name.toLowerCase().includes(search) ||
                            l_plate.toLowerCase().includes(search) ||
                            vin.toLowerCase().includes(search) ||
                            operator.toLowerCase().includes(search) ||
                            status.toLowerCase().includes(search)) {

                            filtered.push(v);
                        }
                    });

                    // Set filtered vehicles and search string for compare after next rerender
                    this.vehicleSearchPrerender = this.vehicleSearch;
                    this.filteredVehicles = filtered;
                } else {
                    this.clearSearch();
                }
            }
        }),
        clearSearch() {
            this.vehicleSearch = "";
            this.vehicleSearchPrerender = "";
            this.filteredVehicles = this.vehicles;
        },

        // Archive Vehicle Methods
        clickArchiveVehicle(vehicle) {
            this.vehicleToArchive = JSON.parse(JSON.stringify(vehicle));
            this.archiveToggle = true;
        },
        onCloseArchive() {
            this.archiveToggle = false;
        },
        onArchiveVehicle() {
            this.onCloseArchive();
            this.getAllVehicles();
        },

        // Filter Functions
        setFilterType(type) {
            this.filterType = type;
            this.clearSearch();
        }
    },
};
</script>
