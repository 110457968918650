<template>
    <div class="card">
        <div class="card-header">
            <div class="row align-items-center">
                <div class="col-8">
                    <h3 class="mb-0 d-flex align-items-center">
                        <i class="ri-refresh-line heading-title"></i>
                        {{ $t('label.lifecycle') }}
                    </h3>
                </div>
                <div class="col-4 text-right">

                </div>
            </div>
        </div>
        <div class="card-body pb-2">
            <div class="row">
                <div class="col-6 form-group">
                    <label class="form-control-label text-muted">{{ $t('label.start date') }}</label>
                    <h3 class="border-bottom">
                        <span v-if="item.in_service_date">{{ item.in_service_date }}</span>
                        <span v-else>&nbsp;</span>
                    </h3>
                </div>
                <div class="col-6 form-group">
                    <label class="form-control-label text-muted">{{ $t('label.end date') }}</label>
                    <h3 class="border-bottom">
                        <span v-if="item.out_of_service_date">{{ item.out_of_service_date }}</span>
                        <span v-else>&nbsp;</span>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["item"],
    data() {
        return {}
    }
}
</script>
