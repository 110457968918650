<template>
    <div>
        <div class="mobile-mt-230p">
            <div v-for="(table, index) in tables" :key="index" class="row">
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <h3 class="mb-0 d-flex align-items-center">
                                        <i :class="'heading-title ' + table.icon"></i>{{ table.title }}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive table-messages pb-4 pt-0" data-list-values='["date", "type", "title", "recipient"]'>
                            <table class="table table-flush">
                                <thead class="thead-light">
                                    <tr>
                                        <th scope="col" class="sort" data-sort="date">{{ $t('label.scheduled date') }}</th>
                                        <th scope="col" class="sort" data-sort="type">{{ $t('label.message type') }}</th>
                                        <th scope="col" class="sort" data-sort="title">{{ $t('label.message title') }}</th>
                                        <th scope="col" class="sort" data-sort="recipient">{{ $t('label.recipients') }}</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(message, index) in messages.all" :key="index" v-if="message.is_schedule == table.isSchedule">
                                        <td>{{ message.post_date | formatDateAndTime }}</td>
                                        <td>{{ message.type | formatMsgType }}</td>
                                        <td>{{ message.title }}</td>
                                        <td>
                                            <div v-if="message.is_send_all" class="avatar-group recepient-group d-flex align-items-center">
                                                <a href="#" class="avatar avatar-single avatar-all-employees avatar-sm rounded-circle">
                                                    <img alt="Image placeholder" src="/images/icons/team-line.png">
                                                </a>
                                                <span class="px-2">{{ $t('label.all employees') }}</span>
                                            </div>
                                            <div v-else class="avatar-group recepient-group d-flex align-items-center">
                                                <a v-for="(recipient, idx) in message.recipients" v-if="(recipient.user_id != 0 && idx < 4) && recipient.user" :key="idx" class="avatar avatar-single avatar-sm rounded-circle pointer">
                                                    <img class="avatar-cover" alt="Image placeholder" :src="recipient.user.picture">
                                                </a>
                                                <a v-if="message.recipients.length >= 5" href="#" class="avatar avatar-single avatar-sm rounded-circle avatar-others">
                                                    <div class="employee-others centered">+{{ message.recipients.length - 4 }}</div>
                                                </a>
                                            </div>
                                        </td>
                                        <td class="text-right">
                                            <div class="dropdown">
                                                <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fas fa-ellipsis-v"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                    <a v-if="table.name == 'scheduled'" class="dropdown-item cursor-pointer" data-toggle="modal" data-target="#messageAddModal" @click="updateFormState(message)">
                                                        <i class="ri-pencil-line"></i>{{ $t('label.edit message') }}
                                                    </a>
                                                    <button v-if="table.name == 'scheduled'" type="button" class="dropdown-item text-default text-red" style="cursor: pointer" data-toggle="modal" data-target="#deleteModal" @click="deleteMessage(message.id)">
                                                        <i class="ri-delete-bin-line"></i>{{ $t('label.delete message') }}
                                                    </button>
                                                    <a v-if="table.name == 'past'" class="dropdown-item cursor-pointer" @click="getMessageId(message.id)">
                                                        <i class="ri-bar-chart-box-line"></i>{{ $t('label.view stats') }}
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal name="statsModal" height="auto" width="98%" :max-width="1024" :reset="true" :scrollable="true" :adaptive="true">
            <message-stats-component></message-stats-component>
        </modal>
        <message-form-component></message-form-component>
        <modal-delete :deleteMethod="deleteMethod"></modal-delete>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            tables: [
                {
                    name: 'scheduled',
                    title: 'Scheduled Messages',
                    isSchedule: true,
                    icon: 'ri-calendar-event-line',
                    datatable: 'datatable-basic',
                },
                {
                    name: 'past',
                    title: 'Past Messages',
                    isSchedule: false,
                    icon: 'ri-chat-poll-line',
                    datatable: 'datatable-past-messages',
                },
            ],
            id: 0,
        }
    },
    computed: {
        ...mapGetters(['messages']),
    },
    created() {
    },
    mounted() {
    },
    methods: {
        getMessageId(id) {
            this.getAllMessages();
            this.$store.dispatch('getMessageId', id);
            this.$store.dispatch('showDropzone', false);
            this.$modal.show('statsModal')
        },
        updateFormState(data) {
            this.$store.dispatch('updateFormState', data);
        },
        deleteMessage(id) {
            this.id = id;
        },
        deleteMethod() {
            this.$store.dispatch('deleteMessage', this.id);
            this.getAllMessages();
            $("#deleteModal").modal('hide');
        },
        getAllMessages() {
            this.$store.dispatch('getAllMessages');
        },
    }
}
</script>
