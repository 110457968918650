<template>
    <div class="card">
        <!-- Card Header -->
        <div class="card-header">
            <div class="d-flex align-items-center justify-content-between">
                <h3 class="d-flex align-items-center mb-0">
                    {{ $t('label.items') }}
                    <div v-if="isValid.formItem == false" class="text-danger text-sm pl-3">
                        *&nbsp;{{ $t('label.at least one item is required on purchase order') }}.
                    </div>
                </h3>
            </div>
        </div>

        <!-- Card Body -->
        <div class="pb-3">
            <table class="table table-flush table-sm" id="datatable-invoiceitemlist">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">{{ $t('label.description') }}</th>
                        <th scope="col" width="20%">{{ $t('label.sku') }}</th>
                        <th scope="col" width="10%">{{ $t('label.quantity') }}</th>
                        <th scope="col" width="15%">{{ $t('label.unit cost') }}</th>
                        <th scope="col" width="8%" class="text-right">{{ $t('label.total') }}</th>
                        <th scope="col" width="5%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr :id="data.id" v-for="(data, p_index) in formItem.listItems" :key="p_index">
                        <!-- Description -->
                        <td>
                            <!-- <input type="text" name="description" v-model="data.description" class="form-control" :placeholder="$t('label.description')" required> -->
                            <div class="dropdown w-100">
                                <div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <div class="input-group input-group-alternative input-group-merge search-contacts">
                                        <input type="text" class="form-control form-control-dropdown pointer" placeholder="Search Items" @input="filterPurchaseItems($event, p_index)" v-model.trim="data.description" autocomplete="input-field" data-lpignore="true" required>
                                        <div class="input-group-append">
                                            <span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
                                    <a class="dropdown-item text-default pointer" @click="showAddPurchseItemModal('add', p_index)">
                                        <i class="ri-add-fill"></i> Add Purchase Item
                                    </a>
                                    <div class="dropdown-divider mx-3"></div>
                                    <h4 class="mx-3">{{ $t('label.results') }}</h4>
                                    <div class="pl-3">
                                        <template v-for="(data, index) in allPurchaseItemsFilter.slice(0, 4)">
                                            <div :key="index" class="pointer dropdown-items" @click="selectPurchaseItem(data, p_index)">
                                                <span class="jobname">{{ data.name }}</span>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>

                        </td>
                        <!-- SKU -->
                        <td><input type="text" name="sku" v-model="data.sku" class="form-control" :placeholder="$t('label.optional')"></td>
                        <!-- Quantity -->
                        <td><input type="number" name="quantity" v-model="data.quantity" class="form-control" :placeholder="$t('label.quantity')" required @change="autoSum" min="0"></td>
                        <!-- Unit Cost -->
                        <td>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-group-invoice"><i class="ri-money-dollar-circle-line"></i></span>
                                </div>
                                <money v-model="data.unit_cost" v-bind="money" class="form-control" required @blur.native="autoSum"></money>
                            </div>
                        </td>
                        <!-- Subtotal -->
                        <td class="text-right"><b>${{ data.subtotal | formatThousands }}</b></td>
                        <!-- Delete Buttton -->
                        <td>
                            <button class="btn btn-danger py-0 px-2" @click="deleteItem(index)">
                                <i class="ri-delete-bin-line heading-title p-0"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tbody class="border-top">
                    <!-- Tax Calculations -->
                    <tr>
                        <td colspan="3" class="text-right"><b>{{ $t('label.tax') }}</b> <span class="text-muted">({{ $t('label.optional') }})</span></td>
                        <td>
                            <div class="input-group">
                                <money v-model="formItem.tax" v-bind="money" class="form-control" required @blur.native="autoSum"></money>
                                <div class="input-group-append">
                                    <span class="input-group-text"><i class="ri-percent-line"></i></span>
                                </div>
                            </div>
                        </td>
                        <td class="text-right"><b>${{ Number.parseFloat(formItem.taxCalc).toFixed(2) | formatThousands }}</b></td>
                        <td></td>
                    </tr>
                    <!-- Totals and Add Item Button -->
                    <tr>
                        <td class="align-top pt-4 border-0">
                            <button type="button" class="btn btn-outline-primary btn-sm" @click="addItem">
                                <i class="ri-add-line"></i> {{ $t('label.add another item') | uppercase }}
                            </button>
                        </td>
                        <td colspan="3" class="border-0 text-right"><b>{{ $t('label.total') }}</b></td>
                        <td class="border-0 text-right"><b>${{ formItem.overall_total | formatThousands }}</b></td>
                        <td class="border-0"></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <modal class="py-4" name="addPurchaseOrderModal" height="auto" width="98%" :max-width="1024" :reset="true" :scrollable="true" :adaptive="true">
            <ModalAddEditPurchaseItem @cancelFnc="hideAddPurchseItemModal" />
        </modal>
    </div>
</template>

<script>
import ModalAddEditPurchaseItem from '@/components/Admin/Vendors/ModalAddEditPurchaseItem.vue';
import { ModelSelect } from 'vue-search-select';
import { required } from 'vuelidate/lib/validators';

export default {
    props: ['formItem', 'isValid', 'poType'],
    emits: [''],
    components: {
        ModelSelect,
        ModalAddEditPurchaseItem
    },
    data() {
        return {
            purchaseItems: [],
            allPurchaseItemsFilter: [],
            modalType: '',
            modalIndex: null,
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '',
                precision: 2,
                masked: false
            },
        }
    },
    validations: {
        formItem: {
            listItems: {
                description: { required },
                quantity: { minValue: 1 },
                unit_cost: { required }
            }
        }
    },
    mounted() {
        if (this.poType == 'add') this.autoSum()
        this.getPurchaseItems();
        // if( this.poType == 'edit') {
        //    this.validateField('formItem');
        // }
    },
    methods: {
        autoSum() {
            this.formItem.sub_total = 0;
            this.formItem.listItems.forEach((data, index) => {
                data.subtotal = 0;
                console.log('data', data);
                data.quantity = parseInt(data.quantity);

                data.subtotal += parseFloat(data.quantity) * parseFloat(data.unit_cost);

                data.taxes = this.formItem.tax;
                data.total = data.subtotal + (data.subtotal * (this.formItem.tax / 100));

                this.formItem.sub_total += (data.subtotal);
                data.subtotal = parseFloat(data.subtotal).toFixed(2);
                console.log('quantity', typeof (data.quantity))
            })

            this.formItem.taxCalc = this.formItem.sub_total * (this.formItem.tax / 100);
            this.formItem.overall_total = Number.parseFloat(this.formItem.sub_total + this.formItem.taxCalc).toFixed(2);
            // this.formItem.overall_total = parseInt(this.formItem.overall_total).toFixed(2);

            //Testing why quantity error happens sometimes
            console.log('----------this.formItem', this.formItem);
        },
        addItem() {
            this.formItem.listItems.push({
                description: '',
                sku: '',
                quantity: 0,
                unit_cost: 0,
                subtotal: 0,
                taxes: 0,
                total: 0
            });
        },
        deleteItem(idx) {
            if (this.formItem.listItems[idx].id)
                this.formItem.listItemsDelete.push(this.formItem.listItems[idx].id)
            this.formItem.listItems.splice(idx, 1);
            this.autoSum();
        },
        selectNoItems() {
            this.formItem.no_items = !this.formItem.no_items
        },
        getPurchaseItems() {
             var currentUrl = window.location.pathname;
             const firstSegment = currentUrl.split('/')[1];
            axios.get("/purchase-items/list",{
                                            params: {
                                                itemType: firstSegment
                                            }
                }).then((response) => {
                this.purchaseItems = response.data.data.models;
                this.allPurchaseItemsFilter = response.data.data.models;
                this.$emit('onLoaded');
            }).catch((err) => console.log("ERROR: ", err))
        },
        filterPurchaseItems($e, index) {
            this.allPurchaseItemsFilter = this.purchaseItems;
            const value = this.formItem.listItems[index].description
            if (value.length > 0) {
                this.allPurchaseItemsFilter = this.purchaseItems.filter(x => x.name.toLowerCase().includes(value.toLowerCase()));
            } else {
                this.allPurchaseItemsFilter = this.purchaseItems;
            }
        },
        showAddPurchseItemModal(e, p_index) {
            this.modalType = e;
            this.modalIndex = p_index;
            this.$modal.show('addPurchaseOrderModal');
        },
        hideAddPurchseItemModal(item) {
            if (item) {
                this.getPurchaseItems();
                this.selectPurchaseItem(item, this.modalIndex);
            }
            this.modalType = null;
            this.modalIndex = null;
            this.$modal.hide('addPurchaseOrderModal');
        },
        selectPurchaseItem(data, p_index) {
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            });
            const model = this.formItem.listItems[p_index]
            model.description = data.name
            model.sku = data.sku
            model.quantity = 1
            model.unit_cost = formatter.format(data.price).replace('$', '')
            model.subtotal = model.quantity * Number(data.price)
            model.item_id = data.id
        }
    },
}
</script>
