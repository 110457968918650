import authGuard from '@/middlewares/AuthGuard';

export default {
  name: 'Tenant',
  icon: 'people_alt',
  store: 'Tenant/store/index.js',
  routes: [
    {
      path: '/purchase-items',
      name: 'purchase-items',
      component: () => import(/* webpackChunkName: "purchase-items" */ './views/index.vue'),
      beforeEnter: authGuard,
      meta: {
        layout: 'default',
        title: 'Tenants'
      },
      children: [{
        path: `/`,
        name: 'purchase-items-list',
        component: () => import(/* webpackChunkName: "purchase-items" */ './views/list.vue'),
        beforeEnter: authGuard,
        meta: {
          layout: 'default',
          title: 'Purchase Items'
        },
        children: []
      }, {
        path: `create`,
        name: 'purchase-item-create',
        component: () => import(/* webpackChunkName: "purchase-items" */ './views/create.vue'),
        beforeEnter: authGuard,
        meta: {
          layout: 'default',
          title: 'Purchase Items'
        },
        children: []
      }, {
        path: `edit`,
        name: 'purchase-item-edit',
        component: () => import(/* webpackChunkName: "purchase-items" */ './views/create.vue'),
        beforeEnter: authGuard,
        meta: {
          layout: 'default',
          title: 'Purchase Items'
        },
        children: []
      }]
    }
  ],
  preRegisterCheck() {
    return true;
  }
};
