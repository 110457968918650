import authGuard from '@/middlewares/AuthGuard';
;

export default {
  name: 'PurchasePlan',
  icon: 'people_alt',
  store: 'PurchasePlan/store/index.js',
  routes: [{
    path: `/subscriptions`,
    name: 'subscriptions',
    component: () => import(/* webpackChunkName: "plan" */ './views/subscriptions.vue'),
    beforeEnter: authGuard,
    meta: {
      layout: 'default',
      title: 'Subscriptions'
    }
  }, {
    path: '/purchase-plan',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ './views/index.vue'),
    beforeEnter: authGuard,
    meta: {
      layout: 'default',
      title: 'Purchase',
      accessRole: 2
    },
    children: [
      {
        path: `plan`,
        name: 'purchase-plan',
        component: () => import(/* webpackChunkName: "plan" */ './views/plan-list.vue'),
        beforeEnter: authGuard,
        meta: {
          layout: 'default',
          title: 'Purchase Plan'
        }
      }, {
        path: `expired`,
        name: 'subscription-expired',
        component: () => import(/* webpackChunkName: "plan" */ './views/subscription-expired.vue'),
        beforeEnter: authGuard,
        meta: {
          layout: 'default',
          title: 'Subscription Expired'
        }
      }
    ]
  }],
  preRegisterCheck() {
    return true;
  }
};
