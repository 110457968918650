<template>
	<!-- Duplicate Job Template w/ Vue js Modal -->
	<modal name="jobTemplateDupl" @closed="onClose()" :classes="'rounded'" :min-height="400" width="576px" :scrollable="true" height="auto" :reset="true" :adaptive="true">
		<div class="bg-gradient-primary">
			<div class="modal-header">
				<button type="button" class="close" @click="closeModal()">
					<i class="ri-close-line text-white"></i>
				</button>
			</div>
			<div class="modal-body">
				<div class="py-3 text-center ">
					<i class="ri-todo-line ni-3x text-white"></i>
					<h4 class="heading mt-4 text-white">{{ $t('label.ARE YOU SURE YOU WANT TO DUPLICATE THIS JOB TEMPLATE') }}?</h4>
				</div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn btn-white" @click="createTemplateFromTemplate()">{{ $t('label.yes') }}</button>
				<button type="button" class="btn btn-link text-white ml-auto" data-dismiss="modal" @click="closeModal()">{{ $t('label.close') }}</button>
			</div>
        </div>

	</modal>
</template>

<script>
    export default {
		props: ['templateId', 'jobId',],
		emits: ['onClose', 'onDuplicate'],
		data() {
			return {
				loader: null,
                templateTasks: [],
                templateMaterials: [],
                templateFiles: [],
				form: {
                    template_name: null,
                }
			}
		},
        mounted() {
            this.$modal.show("jobTemplateDupl");
		},
		methods: {
			closeModal() {
				this.onClose();
				this.$modal.hide("jobTemplateDupl");
			},
			onClose() {
				this.$emit('onClose', false);
            },
            createTemplateFromTemplate() {
				this.loader = this.$loading.show();
				
                axios.post('/duplicate-job-template', {
					id: this.jobId
				}).then(response => {
					this.$emit('onDuplicate', response.data);
					this.loader.hide();
					this.$modal.hide("jobTemplateDupl");
                }).catch(err => {}); 
            },
        },
        
        
    }
</script>

