<template>
    <div>
        <div class="modal fade mr-0" id="contactList" tabindex="-1" role="dialog" aria-labelledby="customerContactList" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 1024px;">
                <div class="modal-content">
                    <div class="modal-header border-bottom">
                        <h5 class="modal-title" id="customerContactList">
                            {{ customer.name }}
                            {{ $t('label.contacts') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                    <div class="modal-body pt-0 px-3">
                        <!-- Customer Contact List -->
                        <div v-show="!showAddContactForm">
                            <table class="table table-flush table-sm" id="dt-customerList">
                                <thead class="thead-light">
                                    <tr>
                                        <th scope="col">Contact Name</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Office Phone</th>
                                        <th scope="col">Mobile Phone</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Status</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contact, index) in contactList" :key="index" :class="{ 'bg-gray-300': contact.status === 'Inactive' }">
                                        <td>{{ contact.full_name }}</td>
                                        <td>{{ contact.title }}</td>
                                        <td>{{ contact.office_phone }}</td>
                                        <td>{{ contact.mobile_phone }}</td>
                                        <td>{{ contact.email }}</td>
                                        <td>{{ contact.status }}</td>
                                        <td>
                                            <!-- dropdown menu -->
                                            <div class="dropdown">
                                                <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fas fa-ellipsis-v"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                    <!-- Edit Contact -->
                                                    <a class="dropdown-item d-flex align-items-center" href="#" @click="editContact(contact)">
                                                        <i class="ri-pencil-line"></i>
                                                        {{ $t('label.edit') }}
                                                        {{ $t('label.contact') }}
                                                    </a>

                                                    <!-- Deactivate Contact -->
                                                    <a class="dropdown-item d-flex align-items-center" v-if="contact.jobs_count && contact.status === 'Active'" href="#" @click="deactivateNotify(contact)">
                                                        <i class="ri-delete-bin-line"></i>
                                                        {{ $t('label.deactivate') }}
                                                        &nbsp;{{ $t('label.contact') }}
                                                    </a>

                                                    <!-- Activate Contact -->
                                                    <a class="dropdown-item d-flex align-items-center" v-if="contact.jobs_count && contact.status === 'Inactive'" href="#" @click="activateContact(contact)">
                                                        <i class="ri-delete-bin-line"></i>
                                                        {{ $t('label.activate') }}
                                                        &nbsp;{{ $t('label.contact') }}
                                                    </a>

                                                    <!-- Delete Contact -->
                                                    <a class="dropdown-item d-flex align-items-center text-danger" v-if="!contact.jobs_count" href="#" @click="deleteNotify(contact)">
                                                        <i class="ri-delete-bin-line"></i>
                                                        {{ $t('label.delete') }}
                                                        {{ $t('label.contact') }}
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="mt-2">
                                <button type="button" class="btn btn-neutral" data-dismiss="modal">
                                    <i class="ri-close-line"></i>
                                    Close
                                </button>
                                <button type="button" class="btn btn-success" @click="addContact">
                                    <i class="ri-add-line"></i>
                                    Add Contact
                                </button>
                            </div>
                        </div>

                        <!-- Add contact component -->
                        <customer-contact-manage v-if="showAddContactForm" :customer-id="customer.id" :selected-contact="selectedContact" @cancelFnc="cancelAddContact" @returnContact="receiveNewContact">
                        </customer-contact-manage>
                    </div>
                </div>
            </div>
        </div>

        <!-- Contact Delete Modal -->
        <div class="modal fade" id="contactDelete" tabindex="-1" role="dialog" aria-labelledby="contactDelete" aria-hidden="true">
            <div class="modal-dialog modal-danger modal-dialog-centered">
                <div class="modal-content bg-gradient-danger">
                    <div class="modal-header">
                        <button type="button" class="close" aria-label="Close" @click="modalClose">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="py-3 text-center">
                            <i class="ri-error-warning-line ni-3x"></i>
                            <h4 class="heading mt-4">{{ $t('label.delete customer contact') | uppercase }}</h4>
                            <p>{{ $t('label.This action cannot be undone') }}!</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-white" @click="deleteContact">{{ $t('label.Yes, Delete') }}</button>
                        <button type="button" class="btn btn-link text-white ml-auto close_delete" data-dismiss="modal">{{ $t('label.cancel') }}</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Contact Deactivate Modal -->
        <div class="modal fade" id="contactDeactivate" tabindex="-1" role="dialog" aria-labelledby="contactDeactivate" aria-hidden="true">
            <div class="modal-dialog modal-danger modal-dialog-centered">
                <div class="modal-content bg-gradient-danger">
                    <div class="modal-header">
                        <button type="button" class="close" aria-label="Close" @click="modalClose">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="py-3 text-center">
                            <i class="ri-error-warning-line ni-3x"></i>
                            <h4 class="heading mt-4">{{ $t('label.deactivate customer contact') | uppercase }}</h4>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-white" @click="toggleContactStatus">{{ $t('label.Yes, Deactivate') }}</button>
                        <button type="button" class="btn btn-link text-white ml-auto close_delete" data-dismiss="modal">{{ $t('label.cancel') }}</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import bus from '@/utils/event-bus';
import CustomerContactManage from "./CustomerContactManageComponent";

export default {
    props: ["customer", "openPreviousModal"],
    emits: ["updateContactCount"],
    components: {
        CustomerContactManage
    },
    data() {
        return {
            contactList: [],
            showAddContactForm: false,
            selectedContact: null,
            contactDeleteModal: false,
            selectedContactId: null
        };
    },
    watch: {
        // Open modal and load the contacts for the customer id.
        customer(changedCustomer, previousId) {
            // Destroy the datatable or else it won't dump old data
            $("#dt-customerList")
                .DataTable()
                .destroy();
            this.loadCustomerContacts(changedCustomer);
            this.showAddContactForm = false;
        },
        // re-open the contacts modal when the id hasn't changed but was previously closed.
        openPreviousModal() {
            this.showAddContactForm = false;
            $("#contactList").modal("show");
        }
    },
    methods: {
        loadCustomerContacts(customer) {
            axios
                .post("/loadCustomerContacts", { customerId: customer.id })
                .then(response => {
                    this.contactList = response.data;
                    $("#dt-customerList").DataTable().destroy();
                    $("#contactList").modal("show");
                    this.runDataTable();
                })
                .catch(err => {
                    console.log("ERROR: ", err);
                    bus.$emit("banner-error", { message: err });
                });
        },
        addContact() {
            this.selectedContact = null;
            this.showAddContactForm = true;
        },
        editContact(contact) {
            this.showAddContactForm = true;
            this.selectedContact = contact;
        },
        cancelAddContact() {
            this.showAddContactForm = false;
        },
        receiveNewContact(contact) {
            this.showAddContactForm = false;

            if (contact.isEdit) {
                this.successMessage = "Contact Updated Successfully!";
            } else {
                //Add new contact to the list
                this.contactList.push(contact);

                //Update the contact count on the customer list for added contacts
                this.$emit("updateContactCount", {
                    customerId: this.customer.id,
                    contactCount: this.contactList.length
                });
            }
        },
        deleteNotify(contact) {
            this.selectedContactId = contact.id;
            $("#contactDelete").modal("show");
        },
        modalClose() {
            $("#contactDelete").modal("hide");
            $("#contactDeactivate").modal("hide");
            this.selectedContactId = null;
        },
        deleteContact() {
            axios
                .post("/deleteCustomerContact", { id: this.selectedContactId })
                .then(response => {
                    $("#dt-customerList")
                        .DataTable()
                        .destroy();
                    let idx = this.contactList.findIndex(contact => {
                        if (contact.id === this.selectedContactId) {
                            return true;
                        }
                    });
                    this.contactList.splice(idx, 1);
                    bus.$emit("banner-success", {
                        message: "Contact Deleted Successfully!"
                    });
                    this.modalClose();
                    this.$emit("updateContactCount", {
                        customerId: this.customer.id,
                        contactCount: this.contactList.length
                    });
                })
                .catch(err => {
                    console.log("ERROR: ", err);
                    bus.$emit("banner-error", { message: err });
                });
        },
        runDataTable() {
            $("#dt-customerList").ready(function () {
                $("#dt-customerList").DataTable({
                    keys: !0,
                    language: {
                        paginate: {
                            previous: "<i class='fas fa-angle-left'>",
                            next: "<i class='fas fa-angle-right'>"
                        }
                    },
                    order: [],
                    pageLength: 5,
                    searching: false,
                    lengthChange: false,
                    bDestroy: true
                });
            });
        },
        deactivateNotify(contact) {
            this.selectedContactId = contact.id;
            $("#contactDeactivate").modal("show");
        },
        activateContact(contact) {
            this.selectedContactId = contact.id;
            this.toggleContactStatus();
        },
        toggleContactStatus() {
            axios
                .post("/ChangeCustomerContactStatus", {
                    contactId: this.selectedContactId
                })
                .then(response => {
                    let idx = this.contactList.findIndex(contact => {
                        if (contact.id === this.selectedContactId) {
                            return true;
                        }
                    });

                    this.contactList[idx].status = response.data.status;
                    bus.$emit("banner-success", {
                        message: "Contact status updated successfully!"
                    });
                    this.modalClose();
                })
                .catch(err => {
                    console.log("ERROR: ", err);
                    bus.$emit("banner-error", { message: err });
                });
        }

    }
};
</script>
