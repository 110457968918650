<template>
    <div class="">
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid sp-mobile-mt-50">
            <!-- Header -->
            <div class="row align-items-center py-4" id="identification">
                <div class="col-5">
                    <h6 class="h2 text-white d-inline-block mb-0">{{ $t('label.edit item') }}</h6>
                </div>
                <div class="col-7 text-right">
                    <span class="d-block d-sm-inline pb-2">
                        <button type="button" class="btn btn-link text-white" @click="$router.go(-1)">
                            {{ $t('label.cancel') | uppercase }}
                        </button>
                    </span>
                    <span class="d-block d-sm-inline">
                        <button class="btn btn-white" type="button" @click="validateupdateItem">
                            {{ $t('label.save item') | uppercase }}
                        </button>
                    </span>
                </div>
            </div>

            <!-- Content -->
            <div class="d-flex">
                <!-- Left Menu -->
                <div class="nav-bar hidden-md-down align-items-center">
                    <div class="sticky-top pt-4 mt--4" style="z-index: 0">
                        <div class="card sp-left-nav">
                            <div class="card-body px-3 py-2">
                                <ul class="navbar-nav w-100">
                                    <li class="nav-item px-2 mx--2">
                                        <a class="nav-link d-flex align-items-center" href="#identification">
                                            <i class="ri-information-line"></i>
                                            {{ $t('label.identification') }}
                                        </a>
                                    </li>
                                    <li class="nav-item px-2 mx--2">
                                        <a class="nav-link d-flex align-items-center" href="#classification">
                                            <i class="ri-file-list-2-line"></i>
                                            {{ $t('label.classification') }}
                                        </a>
                                    </li>
                                    <li class="nav-item px-2 mx--2">
                                        <a class="nav-link d-flex align-items-center" href="#inservice">
                                            <i class="ri-login-circle-line"></i>
                                            {{ $t('label.in service') }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Right Content Column -->
                <div class="asset-body flex-grow-1 justify-content-end">
                    <!-- Identification Card -->
                    <asset-man-item-identification v-if="updateItem.identObj" :ident-obj="updateItem.identObj" :is-valid="isValid"></asset-man-item-identification>
                    <!-- Classification card -->
                    <asset-man-item-classification v-if="updateItem.classificationData" :classification-data="updateItem.classificationData" :is-valid="isValid"></asset-man-item-classification>
                    <!-- In service card -->
                    <asset-man-item-inservice v-if="updateItem.inServObj" :in-serv-obj="updateItem.inServObj"></asset-man-item-inservice>
                </div>
            </div>

            <!-- Footer Buttons -->
            <div class="d-flex pb-5">
                <div class="ml-auto">
                    <span class="d-block d-sm-inline pb-2">
                        <button class="btn btn-link" type="button" @click="$router.go(-1)">
                            {{ $t('label.cancel') | uppercase }}
                        </button>
                    </span>
                    <span class="d-block d-sm-inline">
                        <button class="btn btn-primary" type="button" @click="validateupdateItem">
                            {{ $t('label.save item') | uppercase }}
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import moment from '@/utils/date-time';
import bus from '@/utils/event-bus';
import AssetManItemClassification from './AssetManItemClassification.vue';
import AssetManItemIdentification from './AssetManItemIdentification.vue';
import AssetManItemInservice from './AssetManItemInservice.vue';
;

export default {
    props: ['editItem'],
    components: {
        AssetManItemIdentification,
        AssetManItemClassification,
        AssetManItemInservice,
    },
    data() {
        return {
            isValid: {
                name: null,
                operatorId: null
            },
            updateItem: {
            },
            thisLoader: null,
            loaderCfg: {
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: true,
                color: '#01224d',
                loader: 'dots',
                backgroundColor: '#000000',
                opacity: 0.5,
                width: 90,
                height: 90,
            },
        };
    },
    created() {
        // Send back to assets page if selectedVehicleId isn't set on edit
        if (!this.editItem) {
            this.$router.replace({ name: 'assets' })
            return
        }
        this.isValid = {
            name: this.editItem.name ? true : null,
            operatorId: this.editItem.operators.length ? true : null
        },
            this.updateItem = {
                id: this.editItem.id,
                identObj: {
                    name: this.editItem.name,
                    typeId: this.editItem.item_type_id,
                    description: this.editItem.description,
                    serialNumber: this.editItem.serial_number,
                    photo: this.editItem.photo,
                    uploadedFile: null,
                },
                classificationData: {
                    status: this.editItem.status,
                    operatorId: this.editItem.operators.length ? this.editItem.operators[0].user_id : null,
                    ownershipType: this.editItem.ownership_type,
                },
                inServObj: {
                    inserviceDate: moment(this.editItem.in_service_date).toDate(),
                    outserviceDate: moment(this.editItem.out_of_service_date).toDate(),
                }
            }
    },
    methods: {
        validateupdateItem() {
            let formValid = true;

            for (const key in this.isValid) {
                if ((this.isValid[key] === false))
                    formValid = false;
                if (this.isValid[key] === null) {
                    formValid = false;
                    this.isValid[key] = false;
                }
            }

            if (formValid) {
                this.saveItem();
            } else {
                bus.$emit('banner-error', {
                    message: this.$t('label.please validate required fields before proceeding') + '.'
                });
            }
        },

        //Submit values to server
        saveItem() {
            this.thisLoader = this.$loading.show();
            this.updateItem.id = this.editItem.id;
            const data = new FormData();
            const uri = '/assetManagementUpdateAsset';

            //Add new image if its set
            if (this.updateItem.identObj.uploadedFile)
                data.append('selectedFile', this.updateItem.identObj.uploadedFile);

            delete this.updateItem.uploadedFile;
            const json = JSON.stringify(this.updateItem);
            data.append('data', json);

            axios.post(uri, data).then(response => {
                this.thisLoader.hide();

                bus.$emit('banner-success', {
                    message: this.$t('label.asset item updated successfully'),
                });
                setTimeout(() => {
                    this.$router.go(-1);
                }, 1200);
            }).catch(err => console.log('ERROR: ', err));
        },
    },
};
</script>
