<template>
    <div class="">
        <div class="header bg-gray-600 template-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50" id="jobTemplateContainer">
            <!-- Header -->
            <div class="d-flex" style="z-index: 100">
                <div class="row py-4 align-items-center">
                    <div class="col">
                        <h6 class="h2 text-white d-inline-block mb-0">
                            {{ $t('label.job template') }} - {{ titleJobName }}
                        </h6>
                    </div>
                </div>

                <!-- Add Job Template Button -->
                <div class="ml-auto py-4">
                    <button class="btn btn-primary" type="button" @click="saveJobTemplate()">
                        {{ $t('label.save template') }}
                    </button>
                </div>
            </div>
            <div class="alert alert-default" role="alert">
                <strong>{{ $t('label.editing template') }}!</strong>
                {{ $t('label.You are editing a Job Template') }}.
                {{ $t('label.Make the necessary changes and then click "Save Template"') }}.
                {{ $t('label.The Job Name will become the Template Name') }}.
            </div>

            <div class="sp-window-content">
                <div class="fixed-navigation text-center d-none d-md-block">
                    <a class="dropdown-item" href="#job-information" data-toggle="tooltip" data-placement="left" title="Job Information"><i class="ri-information-line heading-title pr-0"></i></a>
                    <a class="dropdown-item" href="#job-location" data-toggle="tooltip" data-placement="left" title="Job Location"><i class="ri-map-pin-2-line heading-title pr-0"></i></a>
                    <a class="dropdown-item" href="#job-tasks" data-toggle="tooltip" data-placement="left" title="Tasks"><i class="ri-todo-line heading-title pr-0"></i></a>
                    <a class="dropdown-item" href="#job-notes" data-toggle="tooltip" data-placement="left" title="Notes"><i class="ri-draft-line heading-title pr-0"></i></a>
                    <a class="dropdown-item" href="#job-materials" data-toggle="tooltip" data-placement="left" title="Materials"><i class="ri-pencil-ruler-2-line heading-title pr-0"></i></a>
                    <a class="dropdown-item" href="#job-files" data-toggle="tooltip" data-placement="left" title="Files"><i class="ri-folders-line heading-title pr-0"></i></a>
                </div>
                <div class="row flex-fill mr-5 mobile-m-0">
                    <div class="col-12 mobile-p-0" id="job-information">
                        <div class="card">
                            <div class="card-header">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <h3 class="mb-0 d-flex align-items-center"><i class="ri-information-line heading-title"></i> {{ $t('label.job information') }}</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <form id="templateForm" method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="">
                                    <div class="row">
                                        <div class="col-lg-6 col-12">
                                            <h5 class="modal-title mb-3">{{ $t('label.job information') }}</h5>

                                            <!-- Template Status -->
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.template status') }}</label>
                                                <span class="text-danger text-sm">*&nbsp;{{ $t('label.required') }}</span>
                                                <select v-model="$v.form.template_status.$model" name="templateStatus" id="templateStatus" class="form-control" :class="{ 'is-invalid': $v.form.template_status.$error }" required>
                                                    <option :value="null">- {{ $t('label.select a template status') }} -</option>
                                                    <option v-for="(status, idx) in templateStatuses" :key="idx" :value="status">{{ status | capitalize }}</option>
                                                </select>

                                                <div v-if="$v.form.template_status.$error && !$v.form.template_status.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
                                            </div>

                                            <!-- Job Name -->
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.template name') }}</label>
                                                <span class="text-danger text-sm">*&nbsp;{{ $t('label.required') }}</span>
                                                <input v-model="$v.form.job_name.$model" id="job_name" type="text" name="job_name" class="form-control" :class="{ 'is-invalid': $v.form.job_name.$error }" :placeholder="$t('Enter a template name')" required autofocus>

                                                <div v-if="$v.form.job_name.$error && !$v.form.job_name.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
                                                <div v-if="$v.form.job_name.$error && $v.form.job_name.$model.length > 0" class="text-danger text-sm">*&nbsp;{{ $t('label.must be at least 3 characters') }}.</div>
                                            </div>
                                            <!-- Job Type -->
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.job type') }}</label>
                                                <span class="text-danger text-sm">*&nbsp;{{ $t('label.required') }}</span>
                                                <select v-model="$v.form.job_type_id.$model" name="job_type_id" id="job_type_id" class="form-control" :class="{ 'is-invalid': $v.form.job_type_id.$error }" required>
                                                    <option value="">- {{ $t('label.select a job type') }} -</option>
                                                    <option v-for="(type, idx) in jobTypes" :key="idx" :value="type.id">{{ type.name }}</option>
                                                </select>

                                                <div v-if="$v.form.job_type_id.$error && !$v.form.job_type_id.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
                                            </div>
                                            <!-- Job Manager -->
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.job manager') }}</label>
                                                <select v-model="form.job_manager_user_id" name="job_manager_user_id" id="job_manager_user_id" class="form-control">
                                                    <option value="">- {{ $t('label.select a job manager') }} -</option>
                                                    <option v-for="(manager, idx) in jobManagers" :key="idx" :value="manager.id">{{ manager.name }}</option>
                                                </select>
                                            </div>
                                            <!-- Job Status -->
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.job status') }}</label>
                                                <select v-model="form.job_status_id" name="job_status_id" id="job_status_id" class="form-control">
                                                    <option value="">- {{ $t('label.select a job status') }} -</option>
                                                    <option v-for="(status, idx) in jobStatuses" :key="idx" :value="status.id">{{ status.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <!-- Custom Fields  -->
                                        <div class="col-lg-6 col-12">
                                            <div v-if="jobCustomFields.length > 0" class="">
                                                <h5 class="modal-title mb-3">{{ $t('label.custom fields') }}</h5>
                                                <div class="form-group" v-for="(cfData, idx) in jobCustomFields" :key="idx">
                                                    <custom-field-input :model-id="templateData.job.id" :model-type="'job'" :custom-field="cfData" :form-save="savingForm">
                                                    </custom-field-input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <!-- Task Section -->
                    <task-component v-if="templateData" id="job-tasks" :is_template="1" :jobid="templateData.job.id" @onTasksChange="updateLastEditedDate">
                    </task-component>
                    <!-- Materials Section -->
                    <job-materials-component v-if="templateData" id="job-materials" :is_template="1" :jobid="templateData.job.id" @onMaterialsChange="updateLastEditedDate">
                    </job-materials-component>
                    <!-- Files Section -->
                    <job-files-component v-if="templateData" id="job-files" :is_template="1" :jobid="templateData.job.id" location="edit" @onFilesChange="updateLastEditedDate">
                    </job-files-component>

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.template-comp-banner {
    z-index: 0 !important;
    margin-bottom: -14.7rem !important;
    padding-bottom: 235px !important;
    position: relative !important;
}
</style>

<script>
import CustomFieldInput from '@/components/CustomFields/CustomFieldInput.vue';
import bus from '@/utils/event-bus';
import { minLength, required } from 'vuelidate/lib/validators';

export default {
    props: [],
    components: {
        CustomFieldInput
    },
    data() {
        return {
            savingForm: false,
            templateData: null,
            titleJobName: null,
            jobTypes: [],
            jobManagers: [],
            jobStatuses: [],
            jobCustomers: [],
            jobCustomFields: [],
            templateStatuses: [],
            form: {
                job_name: '',
                job_type_id: null,
                job_status_id: null,
                template_status: null,
                job_manager_user_id: null
            }
        }
    },
    validations: {
        form: {
            job_name: {
                required,
                minLength: minLength(3)
            },
            job_type_id: { required },
            template_status: { required }
        }
    },
    mounted() {
        this.loader = this.$loading.show();
        let promises = [
            this.getJobTypes(),
            this.getJobManagers(),
            this.getJobStatuses(),
            this.getTemplateData(),
            this.getJobCustomers(),
            this.getJobTemplateStatuses(),
        ];

        Promise.all(promises).then(() => {
            this.getJobCustomFields()
                .then(() => {
                    this.loader.hide();
                });
        });
    },
    methods: {
        getTemplateData() {
            return axios.post("/get-job-template-by-id", {
                id: this.$route.params.id
            }).then(response => {
                this.templateData = response.data.job_template;
                if (response.data.job_template == null) {
                    this.$bus.$emit('banner-error', {
                        message: 'Job template not found.'
                    });
                    this.$router.push('/admin/contentManagement/jobtypes');
                }
                this.initializeForm();
            }).catch(err => console.log(err));
        },
        getJobTypes() {
            return axios.get("/jobtypes").then(response => {
                this.jobTypes = response.data.result;
            }).catch(err => console.log(err));
        },
        getJobManagers() {
            return axios.get("/company-admins").then(response => {
                this.jobManagers = response.data.result;
            }).catch(err => console.log(err));
        },
        getJobStatuses() {
            return axios.get("/jobstatus").then(response => {
                this.jobStatuses = response.data.result;
            }).catch(err => console.log(err));
        },
        getJobTemplateStatuses() {
            return axios.get("/get-job-template-statuses").then(response => {
                this.templateStatuses = response.data.result;
            }).catch(err => console.log(err));
        },
        getJobCustomers() {
            return axios.post("/get-all-customers-by-status", {
                status: 'all'
            }).then(response => {
                this.jobCustomers = response.data.result;
            }).catch(err => console.log(err));
        },
        getJobCustomFields() {
            if (this.templateData) {
                return axios.post(`/loadModelCustomFields`, {
                    model_id: this.templateData.job.id,
                    model_type: 'Job'
                }).then(response => {
                    this.jobCustomFields = response.data.result;
                }).catch(err => console.log(err));
            } else {
                return new Promise((resolve, rejects) => resolve());
            }
        },

        updateLastEditedDate() {
            axios.post("/update-job-template-updated-at", { id: this.templateData });
        },

        initializeForm() {
            this.form.titleJobName = this.templateData.job.job_name;
            this.form.job_name = this.templateData.job.job_name ? this.templateData.job.job_name : '';
            this.form.job_type_id = this.templateData.job.job_type_id != 0 ? this.templateData.job.job_type_id : null;
            this.form.job_manager_user_id = this.templateData.job.job_manager_user_id;
            this.form.job_status_id = this.templateData.job.job_status_id;
            this.form.template_status = this.templateData.status;
        },

        saveJobTemplate() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loader = this.$loading.show();
                this.savingForm = true;
                this.form.id = this.templateData.job.id;


                axios.post('/update-job-template', this.form).then(response => {
                    this.loader.hide();
                    this.titleJobName = this.form.job_name;
                    this.savingForm = false;
                    bus.$emit('banner-success', {
                        message: this.$t('label.job template saved successfully') + '!'
                    });

                    setTimeout(() => {
                        this.$router.push({name:'jobTemplates'});
                    }, 1000);
                }).catch(err => console.log(err));
            }
        }
    }
}
</script>