<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header">
					<div class="d-flex align-items-center justify-content-between">
						<h3 class="d-flex mb-0 align-items-center">
							<i class="ri-group-line"></i> {{ $t('label.vendors') }}
						</h3>
						<div class="d-inline-block">
							<button type="button" class="btn btn-secondary btn-sm" @click="syncVendor2QB()" :disable="vendors.length == 0">
								<i class="ri-refresh-line"></i>Sync QuickBooks Online
							</button>
						</div>
					</div>
				</div>
				<div v-if="filter.search.length == 0 && vendors.length == 0">
					<div class="card-body m-0 py-5">
						<div class="bg-white text-center row align-items-center justify-content-center m-0">
							<img :src="require('@/assets/images/no-customer.png')" class="empty-state mb-3" />
							<p class="w-100 font-weight-normal">No Vendors have been added.</p>
						</div>
					</div>
				</div>
				<div v-else-if="filter.search.length > 0 && (filteredList.length == 0 || filteredList == [])">
					<div class="card-body m-0 py-5">
						<div class="bg-white text-center row align-items-center justify-content-center m-0">
							<img :src="require('@/assets/images/no-results.png')" class="empty-state mb-3" />
							<p class="w-100 font-weight-normal">{{ $t("label.no results were found try updating your search query") }}</p>
						</div>
					</div>
				</div>
				<div v-else class="table-responsive pb-4">

					<table class="table table-flush" style="margin-top: 0px !important;" id="datatable-customer_info">
						<thead class="thead-light">
							<tr>
								<th scope="col">{{ $t('label.vendor name') }}</th>
								<th scope="col">Quickbooks ID</th>
								<th scope="col">{{ $t('label.notes') }}</th>
								<th scope="col">{{ $t('label.active') }}</th>
								<th scope="col">{{ $t('label.contacts') }}</th>
								<th scope="col" width="5%"></th>
							</tr>
						</thead>

						<!-- Standard grouping -->
						<tbody class="border-bottom">
							<tr v-for="(  data, index  ) in   paginatedVendors  " :key="index">
								<td>
									<router-link class="dropdown-item" :to="{ name: 'vendordetails', params: { vendorInfo: data, id: data.id } }">
										{{ data.vendor_name }}
									</router-link>
								</td>
								<td>{{ data.qb_id }}</td>
								<td>
									{{ data.notes }}
								</td>
								<!-- Active -->
								<td>
									<span v-if="data.active == 1" class="badge badge-dot">
										<i class="bg-success"></i>{{ $t('label.active') }}
									</span>
									<span v-else class="badge badge-dot">
										<i class="bg-light"></i>{{ $t('label.inactive') }}
									</span>
								</td>
								<!-- Contacts Count -->
								<td>
									<a href="#" data-toggle="modal" @click="showContactsModal(data)">
										{{ data.vendor_contacts_count }} {{ $t('label.contact(s)') }}
									</a>
								</td>
								<!-- dropdown -->
								<td class="text-right">
									<div class="dropdown">
										<a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<i class="fas fa-ellipsis-v"></i>
										</a>
										<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
											<router-link class="dropdown-item" :to="{ name: 'vendordetails', params: { vendorInfo: data, id: data.id } }">
												<i class="ri-eye-line"></i>
												{{ $t('label.view') }}
												{{ $t('label.vendor') }}
											</router-link>
											<a class="dropdown-item text-default pointer" @click="showvendorAddEditModal(data, 'edit')">
												<i class="ri-pencil-line"></i>
												{{ $t('label.edit vendor') }}
											</a>
											<button v-if="data.vendor_in_use > 0" type="button" class="dropdown-item text-danger" style="cursor: pointer; opacity: 0.6" :title="$t('label.Vendor in use, cannot delete')" @click="bannerError($t('label.Vendor in use, cannot delete'))">
												<i class="ri-delete-bin-line"></i> {{ $t('label.delete vendor') }}
											</button>
											<button v-else class="dropdown-item text-danger pointer border-top" href="#" data-toggle="modal" @click="openDeleteModal(data)">
												<i class="ri-delete-bin-line"></i> {{ $t('label.delete vendor') }}
											</button>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					<table-pagination v-model="paginatedVendors" :all-rows="filteredList" :perPage="perPage">
					</table-pagination>
				</div>
			</div>
		</div>

		<!-- Vendor contacts Modal -->
		<modal class="py-4" name="showVendorContactsModal" height="auto" width="98%" :max-width="1024" :reset="true" :scrollable="true" :adaptive="true">
			<modal-vendor-contacts @updateContactCount="updateVendorContactCount" :vendor="selectedVendor" :from="0" @cancelFnc="closeVendorContacts" />
		</modal>

		<!-- Vendor Edit -->
		<modal class="py-4" name="vendorEditModal" height="auto" width="98%" :max-width="1024" :reset="true" :scrollable="true" :adaptive="true">
			<modal-add-edit-vendor :mtype="modalType" :vendordata='editvendor' @cancelFnc="closeVendorModal" @returnFnc="returnUpdatedVendor" />
		</modal>

		<!-- vendor Delete -->
		<modal-delete-component v-if="showDeletevendor" :newId="'vendorList_View'" :title="'ARE YOU SURE YOU WANT TO DELETE THIS VENDOR'" @onClose="toggleDeleteModal()" @customDeleteMethod="deleteVendor()">
		</modal-delete-component>
	</div>
</template>
<script>
import ModalAddEditVendor from '@/components/Admin/Vendors/ModalAddEditVendor';
import ModalVendorContacts from '@/components/Admin/Vendors/ModalVendorContacts';
import ModalDeleteComponent from '@/components/ModalDelete2.vue';
import TablePagination from '@/components/TablePagination';
import bus from '@/utils/event-bus';
import 'vue-loading-overlay/dist/vue-loading.css';
import VendorDetailComponent from './VendorDetailComponent';


export default {
	props: ['filter'],
	emits: ['fncCancel', 'fncReturn'],
	components: {
		// Loading,
		ModalDeleteComponent,
		ModalAddEditVendor,
		ModalVendorContacts,
		VendorDetailComponent,
		TablePagination,
	},
	data() {
		return {
			vendors: [],
			paginatedVendors: [],
			isLoading: null,
			selectedVendor: {},
			editvendor: {},
			vendorInfo: [],
			showDeletevendor: false,
			modalType: null,
			perPage: 10
		}
	},
	computed: {
		filteredList() {
			const lowerCaseSearch = this.filter.search.toLowerCase();
			let vendors = this.vendors;

			if (vendors) {
				if (this.filter.searchBy == 'name' || this.filter.searchBy == null) {
					vendors = vendors.filter((v) => {
						return v.vendor_name.toLowerCase().includes(lowerCaseSearch);
					})
				} else {
					vendors = vendors.filter((v) => {
						let found = v.vendor_contacts.findIndex((c) => {
							return c.full_name.toLowerCase().includes(lowerCaseSearch);
						});
						return found !== -1;
					})
				}
			}

			return vendors;
		},
	},
	created() {
		this.getAllVendors();
	},
	mounted() {
		//
	},
	methods: {

		showContactsModal(selectedVendor) {
			this.selectedVendor = selectedVendor;
			this.$modal.show('showVendorContactsModal');
		},
		updateVendorContactCount(contactData) {
			let idx = this.filteredList.findIndex(vendor => {
				if (vendor.id === contactData.vendorId) {
					return true;
				}
			});
			this.filteredList[idx].vendor_contacts_count = contactData.contactCount;
			this.vendors[idx].vendor_contacts_count = contactData.contactCount;
			this.paginatedVendors[idx].vendor_contacts_count = contactData.contactCount;
			this.filteredList[idx].vendor_contacts = contactData.contactList;
			this.vendors[idx].vendor_contacts = contactData.contactList;
			this.paginatedVendors[idx].vendor_contacts = contactData.contactList;
		},
		closeVendorContacts() {
			this.$modal.hide('showVendorContactsModal');
		},
		showvendorAddEditModal(data, type) {
			this.editvendor = data;
			this.modalType = type;
			this.$modal.show('vendorEditModal');
		},
		closeVendorModal() {
			this.$modal.hide('vendorEditModal')
		},
		returnUpdatedVendor(updatedVendor) {
			this.$modal.hide('vendorEditModal');
			let idx = this.vendors.findIndex(vendor => {
				if (vendor.id === updatedVendor.id) {
					return true;
				}
			});
			// Update selected vendor with changes
			if (idx >= 0) {
				updatedVendor.vendor_contacts_count = this.vendors[idx].vendor_contacts_count;
				updatedVendor.vendor_contacts = this.vendors[idx].vendor_contacts;
				this.$set(this.vendors, idx, updatedVendor);
			}
		},
		toggleDeleteModal() {
			this.showDeletevendor = false;
		},
		openDeleteModal(data) {
			this.showDeletevendor = true
			this.vendorInfo = data;
		},
		deleteVendor() {
			axios.post('/delete-vendor', this.vendorInfo)
				.then(response => {
					this.getAllVendors();
					bus.$emit("banner-success", {
						message: this.$t('label.vendor successfully deleted')
					});
				}).catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		async getAllVendors() {
			this.isLoading = this.$loading.show();
			this.vendors = [];

			let params = {
				filters: this.filter
			}
			await axios.get('/get-all-vendors', { params })
				.then(response => {
					this.vendors = response.data.results;
					this.$emit('returnFnc', this.vendors);
					this.isLoading.hide();
				}).catch(err => console.error("API ERROR GETALLvendorS", err));
		},
		bannerError(msg) {
			bus.$emit('banner-error', { message: msg });
		},
		async syncVendor2QB() {

			let load = this.$loading.show();
			await axios.post('/quickbooks/sync-vendor').then(response => {
				bus.$emit('banner-success', { message: response.data.message });
				this.getAllVendors();
			}).catch(error => {
				console.log('ERROR: ', error);
				bus.$emit('banner-error', { message: error.data.message });
			}).finally(() => {
				load.hide();
			});

		},
	}
}
</script>
<style>
.deletetext {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 28px;
	letter-spacing: -0.3px;
	color: #525F7F;
}

.button-top-right {
	position: absolute;
	top: 15px;
	right: 15px;
}
</style>