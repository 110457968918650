<template>
  <div v-if="task">
    <div class="header bg-primary sp-comp-banner"></div>

    <div class="container-fluid pb-5 sp-mobile-mt-50">
      <!-- HEADER -->
      <div class="row align-items-center py-4">
        <div class="col-md-5">
          <!-- <h2 class="text-white">Piece Rate</h2> -->
        </div>
        <div class="col-md-7 text-right"></div>
      </div>

      <div class="card mt-2">
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col-md-8">
              <h3 class="mb-0">Piece Rate Allocation</h3>
            </div>
          </div>
        </div>
        <div class="card-body">
          <!-- List Piece Rate info -->
          <div class="mb-1">
            <span class="">Piece Rate Amount:</span>
            <span>{{ task.piece_rate | toCurrency }}</span>
          </div>

          <!-- Progress bar -->
          <div class="progress" style="height: 25px;background-color: #666;">
            <div class="progress-bar bg-success" role="progressbar" :style="{ width: allocatedPercentage + '%' }">
              <span class="px-3">Percent Allocated {{ parseFloat(allocatedPercentage).toFixed(2) }}%</span>
            </div>
          </div>

          <!-- Amount left to allocate -->
          <div class="progress" style="height: 25px; background-color: #666;">
            <div class="progress-bar bg-warning" role="progressbar" :style="{ width: (100 - allocatedPercentage) + '%' }">
              <span class="px-3">${{ parseFloat(task.piece_rate - allocatedAmount).toFixed(2) }} Left to Allocate</span>
            </div>
          </div>

          <!-- user list for allocation -->
          <ul class="list-group">
            <li class="list-group-item bg-gray-600 text-white text-bold hidden-sm-down">
              <div class="row">
                <div class="col-md">User</div>
                <div class="col-md ">Percentage</div>
                <div class="col-md ">Amount</div>
                <div class="col-md ">Notes</div>
              </div>
            </li>
            <!-- Loop Crew members -->
            <li class="list-group-item" v-for="(user, idx) in taskUsers" :key="user.id" :class="{ 'bg-gray-100': idx % 2 }">
              <!-- Compensation is Piece rate -->
              <div v-if="user.user.pay_type.indexOf('Piece Rate') >= 0" class="row align-items-center">
                <!-- User name -->
                <div class="col-md-2 input-group mt-1 mb-1">
                    {{ user.user.first_name }} {{ user.user.last_name }}</div>

                <!-- Percentage -->
                <div class="col-md-4">
                  <div class="input-group mt-1 mb-1">
                    <div class="input-group-prepend hidden-sm-down">
                      <button class="btn btn-outline-primary" type="button" @click="changePercent(user, 'up')"><i class="ri-arrow-up-line"></i></button>
                    </div>

                    <input type="number" class="form-control" step="5" onfocus="this.select()" @blur="onPrecentBlur(user)" v-model="user.pieceRatePercentage">
                    <div class="input-group-prepend">
                      <span class="input-group-text">%</span>
                    </div>

                    <div class="input-group-append hidden-sm-down">
                      <button class="btn btn-outline-primary" type="button" @click="changePercent(user, 'down')"><i class="ri-arrow-down-line"></i></button>
                    </div>
                  </div>
                </div>

                <!-- Dollar Amount -->
                <div class="col-md-4 mt-1 mb-1">
                  <div class="input-group">
                    <div class="input-group-prepend hidden-sm-down">
                      <button class="btn btn-outline-primary" type="button" @click="changeAmt(user, 'up')"><i class="ri-arrow-up-line"></i></button>
                    </div>
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input type="number" class="form-control" step="5" onfocus="this.select()" @blur="onAmountBlur(user)" v-model="user.pieceRateAmount">
                    <div class="input-group-append hidden-sm-down">
                      <button class="btn btn-outline-primary" type="button" @click="changeAmt(user, 'down')"><i class="ri-arrow-down-line"></i></button>
                    </div>
                  </div>
                </div>
                <!-- Notes -->
                <div class="col-md-2 input-group mt-1 mb-1">
                  <input type="text" class="form-control" @change="$spSaveAllocations(taskUsers, task.start_date)" v-model="user.notes" placeholder="Notes">
                </div>
              </div>
              <!-- Compensation other than Piece rate -->
              <div v-else class="row align-items-center">
                <div class="col-md input-group mt-1 mb-1">
                    {{ user.user.first_name }} {{ user.user.last_name }}</div>
                <div class="col-md-8 mt-1 mb-1">
                    Employee is compensated {{ user.user.pay_type }}</div>
                <!-- Notes -->
                <div class="col-md input-group mt-1 mb-1">
                  <input type="text" class="form-control" @change="$spSaveAllocations(taskUsers, task.start_date)" v-model="user.notes" placeholder="Notes">
                </div>
              </div>
            </li>
            <li class="list-group-item text-right">
              <!-- <router-link class="btn btn-outline-primary" :to="{ name: 'fieldTasks' }">Done</router-link> -->
              <button class="btn btn-outline-primary" @click="closeTask">Done</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>




<script>
import bus from '@/utils/event-bus';

export default {
    props: ['task'],
    components: {},
    emits: [],
    data() {
        return {
            taskUsers: [],
            taskPieceRate: null,
            allocatedPercentage: 0,
            allocatedAmount: 0,
            incrementValue: 5,
        }
    },
    mounted() {
        // return the page if the task isn't set
        if (!this.task) {
            this.$router.push({ name: 'fieldTasks' })
        }
        else {
            this.setupUserAllocations()
            // Close the task if needed
            if (this.task.task_status_id == 1) {
                // this.closeTask()
            }
        }
    },
    methods: {
        closeTask() {
            if((this.task.piece_rate - this.allocatedAmount) == 0) {
            axios
                .get(`/closeTask/${this.task.id}`)
                .then(response => {
                    this.task.task_status_id = 2
                })
                .catch(err => {
                    console.log('ERROR: ', err)
                    bus.$emit('banner-error', { message: err })
                });
                this.$router.push({ name: 'fieldTasks' });

                bus.$emit('refreshFieldTasks');
            }
            else {
              bus.$emit('banner-error',{ message:'All The Money Needs To Be Allocated Before Closing The Task.'});
            }

        },
        // Load any previously saved piece rate
        async setupUserAllocations() {
            this.taskPieceRate = JSON.parse(JSON.stringify(this.task.piece_rate))
            let taskCrew = JSON.parse(JSON.stringify(this.task.assigned_to))
            this.$spGetUserAllocations(this.task.id, taskCrew, this.allocatedAmount, this.allocatedPercentage).then((result) => {
                this.updateLocalValues(result)
            })
        },
        // Adjust percentage based on amount entered
        async onAmountBlur(user) {
            user.pieceRateAmount = parseFloat(user.pieceRateAmount)
            user.pieceRatePercentage = parseFloat((user.pieceRateAmount / this.taskPieceRate) * 100)
            let result = await this.$spValidateAllocation(user, this.taskUsers, this.taskPieceRate, this.task.start_date)
            this.updateLocalValues(result)
        },
        // Adjust dollar amount based on percentage entered
        async onPrecentBlur(user) {
            user.pieceRatePercentage = parseFloat(user.pieceRatePercentage)
            user.pieceRateAmount = parseFloat((user.pieceRatePercentage * this.taskPieceRate) / 100)
            let result = await this.$spValidateAllocation(user, this.taskUsers, this.taskPieceRate, this.task.start_date)
            this.updateLocalValues(result)
        },
        async changeAmt(user, direction) {
            let userPieceRateAmount = parseFloat(user.pieceRateAmount)
            //decrement by this.incrementValue
            if (direction == "down") {
                user.pieceRateAmount = (userPieceRateAmount - this.incrementValue)
            }
            //increment by this.incrementValue
            else {
                user.pieceRateAmount = (userPieceRateAmount + this.incrementValue)
            }
            //Update percentage
            user.pieceRatePercentage = parseFloat((user.pieceRateAmount / this.taskPieceRate) * 100)
            let result = await this.$spValidateAllocation(user, this.taskUsers, this.taskPieceRate, this.task.start_date)
            this.updateLocalValues(result)
        },
        async changePercent(user, direction) {
            user.pieceRatePercentage = parseFloat(user.pieceRatePercentage)
            //decrement by this.incrementValue
            if (direction == "down") {
                user.pieceRatePercentage = ((user.pieceRatePercentage * 1) - this.incrementValue)
            }
            //increment value by this.incrementValue
            else {
                user.pieceRatePercentage = (user.pieceRatePercentage + this.incrementValue);
            }
            //update amount
            user.pieceRateAmount = parseFloat((user.pieceRatePercentage * this.taskPieceRate) / 100)
            let result = await this.$spValidateAllocation(user, this.taskUsers, this.taskPieceRate, this.task.start_date)
            this.updateLocalValues(result)
        },
        updateLocalValues(result) {
            this.taskUsers = result.taskUsers
            this.allocatedAmount = result.totalAllocatedAmount
            this.allocatedPercentage = result.totalAllocatedPercentage
        },
    }
}
</script>

<style></style>