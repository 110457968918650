<template>
    <div class="purchase-order">
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50">
            <!-- Header -->
            <div class="d-flex z-index-10">
                <!-- Breadcrumbs -->
                <div class="mr-auto z-index-1">
                    <breadcrumbs :title="$t('label.new purchase order')">
                        <!-- Breakcrumb slots -->
                        <li class="breadcrumb-item">
                            <a class="pointer" @click="$router.go(-1)">{{ $t('label.purchase orders') }}</a>
                        </li>
                        <li class="breadcrumb-item">
                            <span>{{ $t('label.new purchase order') }}</span>
                        </li>
                    </breadcrumbs>
                </div>

                <!-- Purchase Order Save/Cancel -->
                <div class="py-4 align-items-center" style="z-index: 1">
                    <button type="button" class="btn btn-link">
                        <a @click="$router.back()" class="text-white">{{ $t('label.cancel') | uppercase }}</a>
                    </button>
                    <button type="button" class="btn btn-neutral" @click="validateNewItem()">
                        <span class="text-default">{{ $t('label.save draft') | uppercase }}</span>
                    </button>
                </div>
            </div>

            <!-- Purchase Order Info -->
            <purchase-order-info v-if="showContent" :form-info="formInfo" :allVendors="allVendors" :allPurchaseOrderStatus="allPurchaseOrderStatus" :is-valid="isValid" :jobPOInfo='jobData' :po-type="'add'"></purchase-order-info>

            <!-- Purchase Order Items -->
            <purchase-order-items v-if="showContent" :form-item="formItem" :is-valid="isValid" :po-type="'add'"></purchase-order-items>

            <!-- Purchase Order Comments -->
            <purchase-order-comments v-if="showContent" :form-comment="formComment" :is-valid="isValid" :po-type="'add'"></purchase-order-comments>

            <!-- Purchase Order Comments -->
            <purchase-order-address v-if="showContent" :form-address="formAddress" :job="formInfo.job" :jobPOInfo='jobData' :vendor="formInfo.vendor" :vendorContact="formInfo.vendor_contact" :is-valid="isValid" :is-other-valid="isOtherValid" :is-other-billing-valid="isOtherBillingValid" :po-type="'add'"></purchase-order-address>

            <!-- Footer Save or Cancel Buttons -->
            <div class="d-flex text-right">
                <div class="ml-auto">
                    <span class="d-block d-sm-inline pb-2">
                        <button type="button" class="btn btn-link">
                            <a @click="$router.back()">{{ $t('label.cancel') | uppercase }}</a>
                        </button>
                    </span>
                    <span class="d-block d-sm-inline">
                        <button type="button" class="btn btn-primary" @click="validateNewItem()">
                            {{ $t('label.save draft') | uppercase }}
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from '@/utils/date-time';
import bus from '@/utils/event-bus';
import PurchaseOrderAddress from './sections/add-edit-purchase-order/PurchaseOrderAddress.vue';
import PurchaseOrderComments from './sections/add-edit-purchase-order/PurchaseOrderComments.vue';
import PurchaseOrderInfo from './sections/add-edit-purchase-order/PurchaseOrderInfo.vue';
import PurchaseOrderItems from './sections/add-edit-purchase-order/PurchaseOrderItems.vue';
;

export default {
    props: ['allVendorsList', 'jobid'],
    emits: [''],
    components: {
        PurchaseOrderInfo,
        PurchaseOrderItems,
        PurchaseOrderComments,
        PurchaseOrderAddress
    },
    data() {
        return {
            formInfo: {
                // type: 0,
                order_number: null,
                title: null,
                status: null,
                vendor_name: null,
                vendor_id: null,
                purchase_date: moment().format('MM/DD/YYYY'),
                job_name: null,
                job_id: null,
                delivery_date: null,
                terms: null,
                job: null,
                vendor: null,
                vendor_contact: null,
            },
            formItem: {
                listItems: [
                    {
                        description: '',
                        sku: '',
                        quantity: 0,
                        unit_cost: 0,
                        subtotal: 0,
                        taxes: 0,
                        total: 0
                    },
                    {
                        description: '',
                        sku: '',
                        quantity: 0,
                        unit_cost: 0,
                        subtotal: 0,
                        taxes: 0,
                        total: 0
                    },
                    {
                        description: '',
                        sku: '',
                        quantity: 0,
                        unit_cost: 0,
                        subtotal: 0,
                        taxes: 0,
                        total: 0
                    },
                ],
                sub_total: 0,
                overall_total: 0,
                tax: 0,
                no_items: false,
                hide_costs: 0,
            },
            formComment: {
                comment_id: null,
                comments: '',
                selectedEntry: {
                    attachments: [],
                },
            },
            formAddress: {
                billing_address: null,
                billing_address_id: null,
                billing_address_type: null,
                billing_address_type_address: 'billing',
                shipping_address: null,
                shipping_address_id: null,
                shipping_address_type: null,
                shipping_address_type_address: 'shipping',
                billing_other: {
                    country: 'US',
                    postal_code: null,
                    address_1: null,
                    address_2: null,
                    city: null,
                    state: null,
                },
                shipping_other: {
                    country: 'US',
                    postal_code: null,
                    address_1: null,
                    address_2: null,
                    city: null,
                    state: null,
                },
            },
            isValid: {
                vendor_name: null,
                vendor_contact_name: null,
                purchase_date: true,
                job_name: null,
                formItem: null,
                billing_address: null,
                shipping_address: null,
            },
            isOtherBillingValid: {
                postal_code: null,
                address_1: null,
                city: null,
                // state: null,
                country: true,
            },
            isOtherValid: {
                postal_code: null,
                address_1: null,
                city: null,
                // state: null,
                country: true,
            },
            allPurchaseOrderStatus: [],
            allVendors: [],
            loader: null,
            jobPOInfo2: null,
            jobData: null,
            showContent: false,

        }
    },
    created() {
        // this.getAllVendors();
    },
    mounted() {
        this.loadLkupData();
        if (this.jobid > 0) {
            this.populateJob();
        }
    },
    methods: {
        loadLkupData() {
            this.loader = this.$loading.show();
            let promises = [
                this.getPurchaseOrderStatus(),
                this.getAllVendors()

            ];
            // Hide the loader overlay after all lookup data is loaded
            Promise.all(promises).then(() => {
                this.showContent = true;
                this.loader.hide();
            });
        },
        populateJob() {
            axios.get(`/get-job-for-po/${this.jobid}`).then(res => {
                this.jobData = res.data.result;
                this.formInfo.job_id = this.jobData.id;
                this.formInfo.job = this.jobData;
                this.formInfo.job_name = this.jobData.job_name;

                this.isValid.job_name = true;

            }).catch(err => console.log(err));

        },
        // Validate all necessary fields in child components
        validateNewItem() {
            let formValid = true;

            this.formItem.listItems.every((data) => {
                let counter = 0;
                if (data.description == '') counter++;
                if (data.quantity == 0) counter++;
                if (data.unit_cost == 0) counter++;

                if (counter == 3) {
                    formValid = false;
                    this.isValid['formItem'] = false;
                    return true;
                } else if (counter == 0) {
                    formValid = true;
                    this.isValid['formItem'] = true;
                    return false;
                }
            });

            // Check fieldsWithError for fields with an error
            for (const key in this.isValid) {
                if ((this.isValid[key] === false))
                    formValid = false;
                if (this.isValid[key] === null) {
                    formValid = false;
                    this.isValid[key] = false;
                }
            }
            if (this.formAddress.shipping_address_type == 'other') {
                for (const key in this.isOtherValid) {
                    if ((this.isOtherValid[key] === false))
                        formValid = false;
                    if (this.isOtherValid[key] === null) {
                        formValid = false;
                        this.isOtherValid[key] = false;
                    }
                }
            }
            if (this.formAddress.billing_address_type == 'other') {
                for (const key in this.isOtherBillingValid) {
                    if ((this.isOtherBillingValid[key] === false))
                        formValid = false;
                    if (this.isOtherBillingValid[key] === null) {
                        formValid = false;
                        this.isOtherBillingValid[key] = false;
                    }
                }
            }
            if (this.jobid > 0) {
                this.isValid.job_name = true;
            }

            if (formValid) {
                this.saveDraft();
            } else {
                bus.$emit('banner-error', {
                    message: this.$t('label.please validate required fields before proceeding') + '.'
                });
            }
        },
        saveDraft() {
            this.loader = this.$loading.show();
            let mergedForm = {
                form_info: this.formInfo,
                form_item: this.formItem,
                form_comment: this.formComment.comments,
                form_address: this.formAddress,
            };

            //Testing why quantity error happens sometimes
            console.log('----------mergedForm:', mergedForm);

            axios.post('/purchase-order', mergedForm)
                .then(res => {
                    console.log('res po ', res);
                    this.jobPOInfo2 = null;
                    this.toPreviousPage();
                    this.loader.hide();
                    this.showContent = false;
                })
                .catch(err => console.log(err));
        },
        saveFile(entryId, file) {
            const data = new FormData();
            data.append('download', file.file);
            data.append('data', JSON.stringify({
                id: entryId,
                file_type: file.fileType
            }));

            return this.$store.dispatch('purchaseOrder/uploadSpecialAttachment', data);
        },
        toPreviousPage() {
            this.$router.go(-1);
        },
        getPurchaseOrderStatus() {
            return axios.get('/get-all-purchase-order-status')
                .then(res => {
                    this.allPurchaseOrderStatus = res.data.result;
                })
                .catch(err => console.log(err));
        },
        getAllVendors() {
            if (this.allVendorsList) {
                this.allVendors = this.allVendorsList;
            } else {
                return axios.get('/get-vendors')
                    .then(res => {
                        this.allVendors = res.data.result;
                        // this.formInfo.vendor = this.allVendors;
                    })
                    .catch(err => console.log(err));
            }
        },
    },
}
</script>
