import Vue from 'vue'

// Deterime the need to display a date range or single date for tasks an maybe other data sets
// Uses default date format of Day Month date but can be overridden by passing in a moment format
Vue.prototype.$spGetMonths = function (){
    return [
        {id:1,name:'January',short:'Jan'},
        {id:2,name:'February',short:'Feb'},
        {id:3,name:'March',short:'Mar'},
        {id:4,name:'April',short:'Apr'},
        {id:5,name:'May',short:'May'},
        {id:6,name:'June',short:'Jun'},
        {id:7,name:'July',short:'Jul'},
        {id:8,name:'August',short:'Aug'},
        {id:9,name:'September',short:'Sep'},
        {id:10,name:'October',short:'Oct'},
        {id:11,name:'November',short:'Nov'},
        {id:12,name:'December',short:'Dec'},
    ]
}

Vue.prototype.$spGetYears = function (numberOfYears, startYear){    
    let yearList = []
    if(!startYear)
        startYear = 2022
    if(!numberOfYears)
        numberOfYears = 5
    
    for(let i = startYear; i <= (startYear+numberOfYears); i++){
        yearList.push({id:i, name:i})
    }
    return yearList
}