<template>
	<div>
		<!-- MODAL FOR GETTING STARTED -->
		<div class="modal fade" id="gettingStartedModal" tabindex="-1" role="dialog" aria-labelledby="gettingStartedModalTitle" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 800px">
				<div class="modal-content">
					<div class="modal-header border-bottom">
						<button type="button" class="close" data-dismiss="modal" @click="updateGettingStarted()" aria-label="Close">
							<i class="ri-close-line"></i>
						</button>
					</div>
					<div class="modal-body text-center">
						<h1 v-if="activePage == 0" class="display-3 text-light-black"><strong>{{ pages[activePage].welcome }}</strong></h1>
						<h2 v-else class="text-light-black font-weight-normal">{{ pages[activePage].title }}</h2>
						<div class="pt-2 pb-4 px-7">
							<img :src="pages[activePage].image" :class="pages[activePage].width ? pages[activePage].width : 'w-100'">
						</div>
						<h2 v-if="activePage == 0" class="text-light-black font-weight-normal">{{ pages[activePage].title }}</h2>
						<a v-if="pages[activePage].button" target="_blank" :href="pages[activePage].link != '' ? pages[activePage].link : '#'" class="btn btn-default help-article-button">Go to the Help Article</a>
						<div v-if="pages[activePage].appbutton" class="row align-items-center justify-content-center">
							<a target="_blank" href="https://apps.apple.com/us/app/subpro/id1550705015"><img src="/images/getting-started/appstore.png" style="width: 8.5rem;"></a>
							<a target="_blank" href="https://play.google.com/store/apps/details?id=com.digiance.subpro&hl=en_US&gl=US"><img src="/images/getting-started/playstore.png" style="width: 11rem;"></a>
						</div>
					</div>
					<div class="modal-footer border-top">
						<button v-if="activePage == 9" type="submit" class="btn btn-primary mr-auto" @click="updateGettingStarted()">Let's Go!</button>
						<button v-else type="submit" class="btn btn-primary mr-auto" @click="nextPage()">Next</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: [],
	data() {
		return {
			pages: [
				{
					'image': require('@/assets/images/getting-started/01-welcome.png'),
					'welcome': "Welcome to SubPro!",
					'title': "Let's go over a few things to get you started.",
					'link': '',
					'button': false
				},
				{
					'image': require('@/assets/images/getting-started/02-profile.png'),
					'welcome': "",
					'title': "Update your profile and add your photo to add customization.",
					'link': 'https://subpro.freshdesk.com/support/solutions/articles/67000628960-update-profile-and-photo',
					'button': true
				},
				{
					'image': require('@/assets/images/getting-started/03-device.png'),
					'welcome': "",
					'title': "You can access SubPro on your desktop and mobile devices.",
					'link': '',
					'button': false,
					'width': 'w-75',
					'appbutton': true
				},
				{
					'image': require('@/assets/images/getting-started/04-add-user.png'),
					'welcome': "",
					'title': "Add Users to your account to begin collaborating on Jobs and Tasks.",
					'link': 'https://subpro.freshdesk.com/support/solutions/articles/67000597425',
					'button': true
				},
				{
					'image': require('@/assets/images/getting-started/05-create-customer.png'),
					'welcome': "",
					'title': "Learn how to create Customers.",
					'link': 'https://subpro.freshdesk.com/support/solutions/articles/67000597504',
					'button': true
				},
				{
					'image': require('@/assets/images/getting-started/06-create-job.png'),
					'welcome': "",
					'title': "Learn how to create Jobs.",
					'link': 'https://subpro.freshdesk.com/support/solutions/articles/67000595770',
					'button': true
				},
				{
					'image': require('@/assets/images/getting-started/07-create-task.png'),
					'welcome': "",
					'title': "Learn how to create Tasks.",
					'link': 'https://subpro.freshdesk.com/support/solutions/articles/67000597141',
					'button': true
				},
				{
					'image': require('@/assets/images/getting-started/08-clock-time.png'),
					'welcome': "",
					'title': "Learn how to clock time.",
					'link': 'https://subpro.freshdesk.com/support/solutions/folders/67000545088',
					'button': true
				},
				{
					'image': require('@/assets/images/getting-started/09-help.gif'),
					'welcome': "",
					'title': "You can access help articles and contact support by clicking on the Help button in the lower-right corner of any screen.",
					'link': '',
					'button': false
				},
				{
					'image': require('@/assets/images/getting-started/10-getting-started.png'),
					'welcome': "",
					'title': "You can access these Getting Started tips again in the navigation menu.",
					'link': '',
					'button': false,
					'width': 'w-75'
				},
			],
			activePage: 0
		}
	},
	mounted() {
		// $('#gettingStartedModal').modal('show');
	},
	methods: {
		nextPage() {
			this.activePage++;
		},
		updateGettingStarted() {
			axios.post('/update-getting-started')
				.then(response => {
					$('#gettingStartedModal').modal('hide');
					this.$store.commit('Auth/UPDATE_USER_INFO', response.data.user);
					if (window.location.pathname === "/getting-started") {
						this.$router.push('/job')
					}
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		}
	},
}
</script>
<style>
.help-article-button {
	line-height: 1;
	font-size: .75rem;
	padding: 5px;
}
</style>
