<template>

    <div>
        <div class="modal-header">
            <h5 class="modal-title">{{ $t('label.request time off') }}</h5>
            <button type="button" class="close" @click="closeModal()">
                <i class="ri-close-line"></i>
            </button>
        </div>

        <!-- Form -->
        <div class="modal-body">

            <!-- Employees -->
            <div class="form-group">
                <label class="form-control-label">{{ $t('label.employee') }}</label>
                <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                <select class="form-control" v-model="data.employeeId" required>
                    <option value="">- {{ $t('label.select an employee') }} -</option>
                    <option v-for="(user, index) in companyUsers" :value="user.id" :key="index">{{ user.name }}</option>
                </select>
                <!-- error display -->
                <div class="text-danger text-sm mt-1" v-if="formErrors.employeeId">* {{ $t('label.employee is required') }}
                </div>

            </div>

            <!-- Date range -->
            <div class="form-group">
                <label class="form-control-label">{{ $t('label.date range') }}</label>
                <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                <date-picker v-model="data.dates" range type="datetime" class="full-width" input-class="form-control" format="MM/DD/YYYY hh:mm a" :disabled-date="notBeforeToday" :time-picker-options="timePickerOptions">
                </date-picker>
                <!-- error display -->
                <div class="text-danger text-sm mt-1" v-if="formErrors.start_date || formErrors.end_date">* {{ $t('label.time off dates are required') }}</div>

            </div>
            <!-- Time off Reason -->
            <div class="form-group">
                <label class="form-control-label" for="time-off-description">{{ $t('label.reason') }}</label>
                <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                <textarea id="time-off-description" v-model="data.reason" name="timeOffDescription" class="form-control" placeholder="Reason for time off..." rows="3" required autofocus :class="{ 'border-danger': formErrors.reason }"></textarea>
                <!-- error display -->
                <div class="text-danger text-sm mt-1" v-if="formErrors.reason">
                    <div v-for="(error, i) in formErrors.reason" :key="i">* {{ error }}</div>
                </div>

            </div>
        </div>

        <!-- footer -->
        <div class="modal-footer border-top">
            <button type="button" class="btn btn-primary mr-auto" @click="saveChanges()">
                {{ $t('label.save request') }}
            </button>
            <button type="button" class="btn btn-link  ml-auto" @click="closeModal()">
                {{ $t('label.cancel') }}
            </button>
        </div>

    </div>
</template>

<script>

import moment from '@/utils/date-time';
import bus from '@/utils/event-bus';
import { createNamespacedHelpers } from 'vuex';
;
const { mapState, mapActions } = createNamespacedHelpers('userData')

export default {
    props: ['existingItem'],
    emits: ['modifiedRequest'],
    components: {},
    data() {
        return {
            data: {
                dates: [null, null],
                reason: null,
                employeeId: ''
            },
            timePickerOptions: {
                start: '05:00',
                step: '00:30',
                end: '22:00',
                format: 'hh:mm a'
            },
            formErrors: {},
            companyUsers: []
        }
    },
    computed: {
        ...mapState({
            allUsers: state => state.allUsers,
        }),
    },
    mounted() {
        // Only want active users
        this.companyUsers = this.allUsers.filter((user) => {
            if (parseInt(user.status) === 1) {
                return user;
            }
        });
    },
    methods: {
        notBeforeToday(date) {
            return date < moment().add(1, 'day');
        },
        saveChanges() {
            let request = {
                start_date: (this.data.dates[0]) ? moment(this.data.dates[0]).format('YYYY-MM-DD HH:mm:00') : null,
                end_date: (this.data.dates[1]) ? moment(this.data.dates[1]).format('YYYY-MM-DD HH:mm:00') : null,
                reason: this.data.reason,
                employeeId: this.data.employeeId,
            }

            axios
                .post('/addEmployeeTimeOffRequest', request)
                .then(response => {
                    this.closeModal();
                    bus.$emit('banner-success', {
                        message: 'Time off request submitted!',
                    });

                    //emit the new request back to the parent.
                    this.$emit('modifiedRequest', response.data);
                })
                .catch(err => {
                    // catch laravel validation errors
                    if (err.response) {
                        if (err.response.status === 422) {
                            console.log(err.response);
                            this.formErrors = err.response.data.errors;
                        }
                    }
                    else {
                        console.log('ERROR: ', err);
                    }
                });
        },
        closeModal() {
            this.$modal.hide('addOrEditEmployeeRequest');
        },
        processTimeOff() {
            const formData = new FormData()
            let payloads = {
                details: JSON.stringify(this.timeOffFormDetails),
                description: this.timeOffFormDescription,
                deny_reason: this.timeOffFormDenyReason,
                user_id: this.user.id,
                id: this.timeOffFormId
            }
            for (let key in payloads) {
                formData.append(key, payloads[key]);
            }

            axios.post('/process-time-off', formData).then(res => {
                if (res.data.status === 'success') {
                    //Emit back to DashboardComponent
                    this.getTimeOffTable()
                    this.getTimeOffTable(true);
                } else {
                    //
                }
            }).catch(err => {

            });
        },
        emptyTimeOffFormDetails() {
            this.timeOffFormDetails.splice(1, this.timeOffFormDetails.length);
        }
    }
}
</script>