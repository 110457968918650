<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>        
        <div class="container-fluid pb-5 sp-mobile-mt-50">
            <!-- HEADER -->
            <!-- <div class="row align-items-center py-4"> -->
                <!-- <div class="col-5"> -->
                    <!-- <h2 class="text-white">{{ $t('label.time off requests') }}</h2> -->
                <!-- </div> -->
                <!-- <div class="col-7 text-right">                 -->
                    <!-- <button class="btn btn-neutral" type="button">Text</button> -->
                <!-- </div> -->
            <!-- </div> -->
            <div>&nbsp;</div>

            <time-off-employee-requests></time-off-employee-requests>

        </div>
    </div>
</template>

<script>
    import TimeOffEmployeeRequests from '@/common/TimeOffEmployeeRequests';

    export default {
        props: [],
        components: {TimeOffEmployeeRequests},
        emits: [],
        data() {
            return {}
        },
        mounted() {    
        },
        methods: {
        
        }
    }
</script>

<style></style>