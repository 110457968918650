<template>
    <modal name="editJobTypeModal" @closed="closeModal()" :classes="'rounded'" :shiftY="0.05" :min-height="400" width="525" :scrollable="true" height="auto" :reset="true" :adaptive="true">
        <div class="card-header">
            <div class="py-2 d-flex">
                <h5 class="modal-title">{{ $t('label.edit job type') }}</h5>
                <button type="button" class="close ml-auto" @click="$modal.hide('editJobTypeModal')" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="card-body">
            <!-- Name -->
            <div class="form-group">
                <label class="form-control-label text-muted">{{ $t('label.job type name') }}</label>
                <span class="text-danger text-sm">*&nbsp;{{ $t('label.required') }}</span>
                <input type="text" v-model.trim="$v.item.name.$model" class="form-control" :class="{ 'is-invalid': $v.item.name.$error }" required autofocus>

                <div v-if="!$v.item.name.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
            </div>
            <!-- Status -->
            <div class="form-group">
                <label class="form-control-label text-muted">{{ $t('label.job type status') }}</label>
                <div class="row m-0 py-1">
                    <div class="custom-control custom-radio mr-3">
                        <input id="active" v-model="item.status" class="custom-control-input" value="1" type="radio" />
                        <label for="active" class="custom-control-label text-light-black">{{ $t('label.active') }}</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input id="inactive" v-model="item.status" class="custom-control-input" value="0" type="radio" />
                        <label for="inactive" class="custom-control-label text-light-black">{{ $t('label.inactive') }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="d-flex">
                <button type="button" class="btn btn-primary mr-auto" :disabled="formSubmit" @click="saveJobType()">{{ $t('label.save') }}</button>
                <button type="button" class="btn btn-link ml-auto" @click="$modal.hide('editJobTypeModal')">{{ $t('label.cancel') }}</button>
            </div>
        </div>
    </modal>
</template>

<script>
import bus from '@/utils/event-bus';
import { required } from 'vuelidate/lib/validators';

export default {
    props: ['selectedItem'],
    emits: ['onClose', 'onSave'],
    data() {
        return {
            formSubmit: false,
            item: {
                type_id: this.selectedItem.id,
                name: this.selectedItem.name,
                status: this.selectedItem.status
            }
        }
    },
    validations: {
        item: {
            name: {
                required,
            }
        }
    },
    mounted() {
        this.$modal.show('editJobTypeModal');
    },
    methods: {
        saveJobType() {
            this.formSubmit = true;

            // Submit only if form has no errors
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loader = this.$loading.show();

                axios.post('/update-job-type', this.item).then(response => {
                    this.loader.hide();
                    bus.$emit('banner-success', {
                        message: $t('label.Job Type successfully created')
                    });
                    this.$emit('onSave');
                    this.$modal.hide('editJobTypeModal');
                }).catch(err => console.log(err));

            }
            this.formSubmit = false;
        },

        closeModal() {
            this.$emit('onClose', false);
        }
    }
}
</script>