<template>
	<div>
		<!-- MODAL FOR DELETE -->
		<div class="modal fade" :id="newid ? 'deleteModal' + newid : 'deleteModal'" tabindex="-1" role="dialog" aria-labelledby="deleteModal" aria-hidden="true">
			<div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
				<div class="modal-content" :class="deleteType == 'archive' ? 'bg-gradient-warning' : 'bg-gradient-danger'">
					<div class="modal-header">
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="py-3 text-center">
							<i class="ri-error-warning-line ni-3x"></i>
							<h4 class="heading mt-4">{{ deleteModalText ? deleteModalText.title : 'ARE YOU SURE YOU WANT TO DELETE THIS ITEM?' }}</h4>
							<p v-if="deleteType == 'archive'">{{ deleteModalText.subtitle }}</p>
							<p v-else>{{ $t('label.This action cannot be undone') }}!</p>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-white" @click="deleteFunc()">{{ deleteModalText ? deleteModalText.button : $t('label.Yes, Delete') }}</button>
						<button type="button" class="btn btn-link text-white ml-auto close_delete" data-dismiss="modal">{{ $t('label.cancel') }}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import bus from '@/utils/event-bus';
export default {
	props: ['deleteMethod', 'deleteModalText', 'newid', 'deleteType'],
	data() {
		return {

		}
	},
	mounted() {
		// console.log('this.deleteModalText',this.deleteModalText)
	},
	methods: {
		deleteFunc() {
			if (this.newid) {
				if (this.newid == 'timesheet') {
					bus.$emit('deleteFTimesheet');
				} else if (this.newid == 'DeleteCustomer') {
					bus.$emit('deleteFCustomer');
				} else if (this.newid == 'DeleteStatus') {
					bus.$emit('deleteFStatus');
				} else if (this.newid == 'DeleteCrew') {
					bus.$emit('deleteFCrew');
				} else if (this.newid == 'DeleteInvoiceItem') {
					bus.$emit('deleteFInvoiceItem');
				} else if (this.newid == 'ArchiveChannel') {
					bus.$emit('deleteFChannel');
				}  else if(this.newid == 'reportInvoices') {
						bus.$emit('deleteFReportInvoices');
				}else {
					bus.$emit('deleteInvoice' + this.newid);
				}

			} else {
				bus.$emit('deleteF');
			}
			$('.close_delete').click();
		}
	},
}
</script>
