<template>
    <div class="card" id="assetItemDetails">
        <!-- Details Header -->
        <div class="card-header">
            <div class="row align-items-center">
                <div class="col-8">
                    <h3 class="mb-0 d-flex align-items-center">
                        <i class="ri-information-line heading-title"></i>
                        {{ $t('label.item') }} {{ $t('label.details') }}
                    </h3>
                </div>
                <div class="col-4 text-right">
                    <router-link class="btn btn-sm btn-default m-0" :to="{ name: 'assetItemEdit', params: { editItem: item } }">
                        {{ $t('label.edit') }}
                    </router-link>
                </div>
            </div>
        </div>

        <!-- Details Body -->
        <div class="card-body">
            <!-- Row 1 -->
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted">{{ $t('label.item') }} {{ $t('label.name') }}</label>
                        <h3 class="border-bottom">{{ this.item.name }}</h3>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted">{{ $t('label.status') }}</label>
                        <h3 class="border-bottom">
                            <span class="status-indicator" :class="item.status_class_name"></span>&nbsp;{{ this.item.status }}&nbsp;
                        </h3>
                    </div>
                </div>
            </div>

            <!-- Row 2 -->
            <div class="row">
                <!-- Item Type -->
                <div class="col-12 col-sm-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted">{{ $t('label.item type') }}</label>
                        <h3 class="border-bottom">
                            <span v-if="this.item.asset_item_type.name"> {{ this.item.asset_item_type.name }}</span>
                        </h3>
                    </div>
                </div>
                <!-- Responsible Person -->
                <div class="col-12 col-sm-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted">{{ $t('label.responsible person') }}</label>
                        <h3 class="border-bottom">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <span v-if="(item.operators.length > 0) && (item.operators[0].date_end == null)">
                                        <user-info-link-component :user="item.operators[0].operator" message="Responsible Person"></user-info-link-component>
                                    </span>
                                    <span v-else>{{ $t('label.unassigned') }}</span>
                                </div>
                                <div class="col-12 col-lg-6 text-lg-right text-sm-left">
                                    <button type="button" class="btn btn-link text-right" data-toggle="modal" data-target="#viewRespPersonHist">
                                        <span>{{ $t('label.vehicles view history') }}</span>
                                    </button>
                                </div>
                            </div>
                        </h3>
                    </div>
                </div>
            </div>

            <!-- Row 3 -->
            <div class="row">
                <!-- Description -->
                <div class="col-12 col-sm-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted">{{ $t('label.description') }}</label>
                        <h3 class="border-bottom">{{ this.item.description }}</h3>
                    </div>
                </div>

                <!-- Ownership -->
                <div class="col-12 col-sm-6">
                    <div class="form-group ownership-bottom">
                        <label class="form-control-label text-muted">{{ $t('label.ownership') }}</label>
                        <h3 class="pb-0 mb-0 border-bottom">{{ this.item.ownership_type }}</h3>
                    </div>
                </div>
            </div>

            <!-- Row 3 -->
            <div class="row">
                <!-- Serial Number -->
                <div class="col-12 col-sm-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted">{{ $t('label.serial number') }}</label>
                        <h3 class="border-bottom">{{ this.item.serial_number }}</h3>
                    </div>
                </div>
                <div class="col-6"></div>
            </div>
        </div>

        <!-- Responsible Person History -->
        <div class="modal fade" id="viewRespPersonHist" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document" style="max-width: 800px">
                <asset-operator-history :users="item.operators" :title="$t('label.responsible person history')" :role="$t('label.responsible person')" modal="true"></asset-operator-history>
            </div>
        </div>
    </div>
</template>

<style>
.ownership-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-right: 30px !important;
}

@media only screen and (max-width: 576px) {
    .ownership-bottom {
        position: relative;
        padding-right: 0px !important;
    }
}
</style>

<script>
export default {
    props: ['item'],
    data() {
        return {}
    },
    mounted() { },
    methods: {}
}
</script>
