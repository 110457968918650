<template>
	<div>
		<div class="header bg-primary sp-comp-banner"></div>

		<div class="container-fluid pb-5 sp-mobile-mt-50" id="timesheetSettingsContainer">
			<!-- Page Header -->
			<div class="d-flex" style="z-index: 100">
				<!-- Breadcrumbs -->
				<breadcrumbs :title="$t('label.contact lead statuses')">
					<!-- Breakcrumb slots -->
					<li class="breadcrumb-item">
						{{ $t('label.administrative') }}
					</li>
					<li class="breadcrumb-item">
						{{ $t('label.content management') }}
					</li>
					<li class="breadcrumb-item">
						{{ $t('label.contact lead statuses') }}
					</li>
				</breadcrumbs>
			</div>

			<!-- Page Body -->
			<div class="card lead-settings">
				<div class="card-header">
					<div class="row m-0 align-items-center justify-content-between">
						<h3 class="mb-0 d-flex align-items-center"> {{ $t('label.lead statuses') }}</h3>
						<button type="button" class="btn btn-sm btn-default m-0" @click="showAddStatusModal = true">
							{{ $t('label.add status') }}
						</button>
					</div>
				</div>
				<!-- Table -->
				<div class="table-responsive pb-4">
					<table class="table table-flush" id="datatable-leads_settings">
						<thead class="thead-light">
							<tr>
								<th class="col-5">{{ $t('label.lead status label') }}</th>
								<th class="col-3">{{ $t('label.lead color') }}</th>
								<th class="col-4"></th>
							</tr>
						</thead>
						<tbody id="tablecontents">
							<tr class="row1" v-for="(data, index) in leadStatus" :key="index" :data-id="data.id">
								<!-- Status Name -->
								<td class="col-5">
									<span class="badge badge-dot badge-dot-lead-status-label d-flex align-items-center auto-sort">
										<i class="bg-success" :style="{ 'background-color': data.color + ' !important' }"></i>
										<span>{{ data.name }}</span>
									</span>
								</td>
								<!-- Status Color -->
								<td class="col-3">
									<div class="dropdown auto-sort">
										<div class="w-100">
											<span class="badge badge-dot badge-dot-lead-status-color d-flex align-items-center">
												<i class="bg-success" :style="{ 'background-color': data.color + ' !important' }"></i>
												{{ data.color }}
											</span>
										</div>
									</div>
								</td>
								<td class="col-3 text-right">
									<!-- Edit Button -->
									<button @click="clickEditStatus(data)" type="button" class="btn btn-sm btn-outline-warning text-uppercase">
										<span class="d-flex align-items-center">
											<i class="ri-pencil-line"></i> {{ $t('label.edit') }}
										</span>
									</button>
									<!-- Delete Button -->
									<button @click="deleteNotify(data.id, index)" type="button" class="btn btn-sm btn-outline-danger text-uppercase">
										<span class="d-flex align-items-center">
											<i class="ri-delete-bin-line"></i> {{ $t('label.delete') }}
										</span>
									</button>
								</td>
							</tr>
						</tbody>
						<!-- Won or Lost Statuses -->
						<tbody class="border-top">
							<tr v-for="(data, index) in wonAndLost" :key="index">
								<td class="col-5">
									<span class="badge badge-dot badge-dot-lead-status-label d-flex align-items-center auto-sort">
										<i class="bg-success" :style="{ 'background-color': data.color + ' !important' }"></i>
										<span>{{ data.name }}</span>
									</span>
								</td>
								<td class="col-3"></td>
								<td class="col-3">
									<span v-if="data.order == 98" class="badge badge-pill badge-secondary d-flex align-items-center justify-content-center auto-sort pointer sp-tooltip">
										{{ $t('label.closed won') }} <i class="ri-information-line pl-1"></i>
										<span class="sp-tooltiptext custom-tooltip-size text-sm">{{ $t('label.this is the default status to describe a contact who has been won by your business') }}</span>
									</span>
									<span v-if="data.order == 99" class="badge badge-pill badge-secondary d-flex align-items-center justify-content-center auto-sort pointer sp-tooltip">
										{{ $t('label.closed lost') }} <i class="ri-information-line pl-1"></i>
										<span class="sp-tooltiptext custom-tooltip-size text-sm">{{ $t('label.this is the default status to describe a contact who has been lost by your business') }}</span>
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<!-- Add Status -->
		<AddLeadStatusModal v-if="showAddStatusModal" modalType="add" @onSave="getLeadStatus()" @onClose="showAddStatusModal = false"></AddLeadStatusModal>
		<!-- Edit Status -->
		<EditLeadStatusModal v-if="showEditStatusModal" modalType="edit" :leadStatus="selectedStatus" @onSave="getLeadStatus()" @onClose="showEditStatusModal = false"></EditLeadStatusModal>
		<!-- Delete Status -->
		<modal-delete :newid="newid" :deleteMethod="deleteStatus" :deleteModalText="deleteModalText"></modal-delete>
	</div>
</template>
<script>
import AddEditLeadStatusModal from '@/components/Admin/Contacts/AddEditLeadStatusModal.vue';
import bus from '@/utils/event-bus';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	props: [],
	components: {
		EditLeadStatusModal: AddEditLeadStatusModal,
		AddLeadStatusModal: AddEditLeadStatusModal
	},
	data() {
		return {
			leadStatus: [],
			wonAndLost: [],
			leadStatusTemp: [],
			selectedStatus: {},
			showAddStatusModal: false,
			showEditStatusModal: false,
			isLoading: null,
			defaultColors: [
				{ id: 1, color: '#ADB5BD' },
				{ id: 2, color: '#0A59D1' },
				{ id: 3, color: '#FC9105' },
				{ id: 4, color: '#0E914F' },
				{ id: 5, color: '#DD1A3E' },
			],
			newOrder: [],
			deletedStatus: [],
			newid: 'DeleteStatus',
			deleteModalText: {
				title: 'ARE YOU SURE YOU WANT TO DELETE THIS STATUS?',
				button: 'Yes, Delete'
			},
			delete_id: null,
		}
	},
	created() {
		this.getLeadStatus();
	},
	mounted() {
		bus.$on('deleteFStatus', () => {
			this.deleteStatus();
		});
	},
	methods: {
		getLeadStatus() {
			this.isLoading = this.$loading.show();

			axios.get('/get-lead-status').then(response => {
				this.leadStatus = response.data.result;
				this.wonAndLost = response.data.won_and_lost;
				this.isLoading.hide();
			}).catch(err => console.error("API ERROR", err));
		},

		clickEditStatus(status) {
			this.selectedStatus = status;
			this.showEditStatusModal = true;
		},

		deleteNotify(delete_id, index) {
			this.delete_id = delete_id;
			this.delete_idx = index;
			$("#deleteModal" + this.newid).modal('show');
		},
		deleteStatus() {
			this.loader = this.$loading.show();
			let data = { id: this.delete_id };

			axios.post('/lead-status-delete', data).then(response => {
				this.getLeadStatus();
				bus.$emit('banner-success', {
					message: $t('label.lead status deleted successfully')
				});
				this.loader.hide();
			}).catch(err => {
				//console.log('list carriers error:', error)
			});
		},
	}
}
</script>
<style >
.no-sort {
	pointer-events: none
}

.auto-sort {
	pointer-events: auto
}

.badge-dot-lead-status-label i {
	width: 10px;
	height: 10px;
}

.badge-dot-lead-status-color i {
	width: 24px;
	height: 24px;
	border-radius: 4px;
}

.lead-settings .dropdown-menu {
	min-width: 10rem;
}

.lead-settings .btn i {
	font-size: 0.9375rem
}

.lead-settings .badge i {
	font-size: 0.9375rem
}

.lead-settings .badge {
	font-size: 0.75rem;
}

.custom-tooltip-size {
	white-space: normal !important;
	width: 200px !important;
}</style>