<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>
        <div class="container-fluid pb-5 sp-mobile-mt-50" id="jobTypesContainer">
            <!-- HEADER -->
            <div class="d-flex" style="z-index: 100">
                <!-- Breadcrumbs -->
                <breadcrumbs :title="$t('label.time off request')">
                    <!-- Breakcrumb slots -->
                    <li class="breadcrumb-item">
						{{ $t('label.administrative') }}
					</li>
					<li class="breadcrumb-item">
						{{ $t('label.tools') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.time off request') }}
                    </li>
                </breadcrumbs>                
            </div>

            <!-- My Requests -->
            <time-off-employee-requests @dataLoaded="empReqsLoaded = true"></time-off-employee-requests>

            <!-- Employee Requests -->
            <time-off-admin-requested @dataLoaded="adminReqsLoaded = true"></time-off-admin-requested>

        </div>
    </div>
</template>
<script>

import TimeOffEmployeeRequests from '@/common/TimeOffEmployeeRequests'
import TimeOffAdminRequested from '@/common/TimeOffAdminRequested'

export default {
    components: {        
        TimeOffEmployeeRequests,
        TimeOffAdminRequested
    },		
    props: [],
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            myRequests:[],
            empReqsLoaded: false,
            adminReqsLoaded: false,
            companyRequests:[],
            selectedRequest:null,
        }
    },   
    watch: {
        empReqsLoaded() {
            this.stopLoader();
        },
        adminReqsLoaded() {
            this.stopLoader();
        }
    },
    mounted() {
        this.loader = this.$loading.show();
    },
    methods: {
        stopLoader() {
            if(this.empReqsLoaded && this.adminReqsLoaded)
                this.loader.hide()
        }
    }
}
</script>
