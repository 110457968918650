<template>
    <div class="">
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid sp-mobile-mt-50">
            <!-- Header buttons -->
            <div class="row align-items-center py-4" id="identification">
                <div class="col-5">
                    <h2 class="h2 text-white d-inline-block mb-0">{{ $t('label.add item') }}</h2>
                </div>
                <div class="col-7 text-right">
                    <span class="d-block d-sm-inline pb-2">
                        <button type="button" class="btn btn-link text-white" @click="$router.go(-1)">
                            {{ $t('label.cancel') | uppercase }}
                        </button>
                    </span>
                    <span class="d-block d-sm-inline">
                        <button class="btn btn-white" type="button" @click="validateNewItem">
                            {{ $t('label.save item') | uppercase }}
                        </button>
                    </span>
                </div>
            </div>

            <!-- Content -->
            <div class="d-flex">
                <!-- Left Menu -->
                <div class="nav-bar hidden-md-down align-items-center">
                    <div class="sticky-top pt-4 mt--4" style="z-index: 0">
                        <div class="card sp-left-nav">
                            <div class="card-body px-3 py-2">
                                <ul class="navbar-nav w-100">
                                    <li class="nav-item px-2 mx--2">
                                        <a class="nav-link d-flex align-items-center" href="#identification">
                                            <i class="ri-information-line"></i>
                                            {{ $t('label.identification') }}
                                        </a>
                                    </li>
                                    <li class="nav-item px-2 mx--2">
                                        <a class="nav-link d-flex align-items-center" href="#classification">
                                            <i class="ri-file-list-2-line"></i>
                                            {{ $t('label.classification') }}
                                        </a>
                                    </li>
                                    <li class="nav-item px-2 mx--2">
                                        <a class="nav-link d-flex align-items-center" href="#inservice">
                                            <i class="ri-login-circle-line"></i>
                                            {{ $t('label.in service') }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Right Content Column -->
                <div class="asset-body flex-grow-1 justify-content-end">
                    <!-- Identification Card -->
                    <asset-man-item-identification :ident-obj="newItem.identObj" :is-valid="isValid"></asset-man-item-identification>
                    <!-- Classification card -->
                    <asset-man-item-classification :classification-data="newItem.classificationData" :is-valid="isValid"></asset-man-item-classification>
                    <!-- In service card -->
                    <asset-man-item-inservice :in-serv-obj="newItem.inServObj"></asset-man-item-inservice>
                </div>
            </div>

            <!-- Footer buttons -->
            <div class="d-flex pb-5">
                <div class="ml-auto">
                    <span class="d-block d-sm-inline pb-2">
                        <button class="btn btn-link" type="button" @click="$router.go(-1)">
                            {{ $t('label.cancel') | uppercase }}
                        </button>
                    </span>
                    <span class="d-block d-sm-inline">
                        <button class="btn btn-primary" type="button" @click="validateNewItem">
                            {{ $t('label.save item') | uppercase }}
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.asset-body {
    margin-left: 30px;
}

@media (max-width: 768px) {
    .asset-body {
        margin-left: 0px;
    }
}
</style>

<script>
import AssetManItemClassification from '@/components/asset-management/AssetManItems/AssetManItemClassification';
import AssetManItemIdentification from '@/components/asset-management/AssetManItems/AssetManItemIdentification';
import AssetManItemInservice from '@/components/asset-management/AssetManItems/AssetManItemInservice';

import moment from '@/utils/date-time';
import bus from '@/utils/event-bus';

export default {
    components: {
        AssetManItemIdentification,
        AssetManItemClassification,
        AssetManItemInservice,
    },
    data() {
        return {
            isValid: {
                name: null,
                operatorId: null
            },
            newItem: {
                identObj: {
                    name: null,
                    typeId: 1,
                    description: null,
                    serialNumber: null,
                    photo: null,
                    uploadedFile: null,
                },
                classificationData: {
                    status: "Active",
                    operatorId: null,
                    ownershipType: "Owned",
                },
                inServObj: {
                    inserviceDate: moment().toDate(),
                    outserviceDate: null,
                }
            },
        };
    },
    mounted() {
    },
    methods: {
        // Validate all necessary fields in child components
        validateNewItem() {
            let formValid = true;

            // Check fieldsWithError for fields with an error
            for (const key in this.isValid) {
                if ((this.isValid[key] === false))
                    formValid = false;
                if (this.isValid[key] === null) {
                    formValid = false;
                    this.isValid[key] = false;
                }
            }

            if (formValid) {
                this.saveItem();
            } else {
                bus.$emit('banner-error', {
                    message: this.$t('label.please validate required fields before proceeding') + '.'
                });
            }
        },

        // Submit values to server
        saveItem() {
            // Set FormData
            const data = new FormData();
            data.append('selectedFile', this.newItem.identObj.uploadedFile);
            delete this.newItem.identObj.uploadedFile;

            const json = JSON.stringify(this.newItem);
            data.append('data', json);

            axios.post('/assetManagementStoreAsset', data).then(response => {
                // Show success banner then return to main list
                bus.$emit('banner-success', {
                    message: `Asset created successfully!`,
                })

                // Redirect back to assets main page
                setTimeout(() => {
                    this.$router.push({ name: 'assets' })
                }, 1200);
            }).catch(err => console.log('ERROR: ', err));
        },
    },
};
</script>
