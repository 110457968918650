<template>
    <div class="">
        <div class="modal-content">
            <div class="modal-header border-bottom">
                <h5 class="modal-title">{{ $t('label.receive items') }}</h5>
                <button type="button" class="close" @click="$emit('cancelFnc')">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="validateEntry">
                <div class="modal-body px-0">
                    <div class="row m-0" v-if="!editItemInfo">
                        <div class="col-3">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="purchase_date">{{ $t('label.date received') }}</label>
                                <date-picker v-model="received_date" valueType="format" format="MM/DD/YYYY" class="full-width" input-class="form-control" :disabled-date="disableDueDateAfterToday" :popup-style="{ left: 0 }" :append-to-body="false" :placeholder="$t('label.select date')" required>
                                </date-picker>

                                <div v-if="entryErrors.received_date" class="text-danger">{{ entryErrors.received_date }}</div>
                            </div>
                        </div>
                        <div class="col-9" style="padding-right:2em; padding-top:3em"><button type="button" class="btn btn-primary btn-sm float-right" data-toggle="modal" @click="editItem(poItem)">{{ $t('label.edit') }}</button></div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-flush table-sm">
                                <thead class="thead-light">
                                    <tr>
                                        <th class="px-2">
                                            <div class="row p-0 m-0">
                                                <div class="col-7">{{ $t('label.description') }}</div>
                                                <div class="col-1">{{ $t('label.sku') }}</div>
                                                <div class="col-1 text-right">{{ $t('label.unit cost') }}</div>
                                                <div class="col-1 text-right">{{ $t('label.total') }}</div>
                                                <div class="col-1 text-right">{{ $t('label.quantity') }}</div>
                                                <div class="col-1 text-right">{{ $t('label.received') }}</div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="border-bottom" v-if="!editItemInfo">
                                    <template  v-for="(data,index) in poItem">
                                        <tr :key="index" :id="data.id">
                                            <td class="px-2 list-group-item-action collapsed-entry">
                                                <div class="row p-0 m-0 align-items-center">
                                                    <div class="col-7">{{ data.description }}</div>
                                                    <div class="col-1">{{ data.sku }}</div>
                                                    <div class="col-1 text-right">${{ Number.parseFloat(data.unit_cost).toFixed(2) }}</div>
                                                    <div class="col-1 text-right">${{ Number.parseFloat(data.total).toFixed(2) }}</div>
                                                    <div class="col-1 text-right">{{ data.quantity - data.quantity_left }}/{{ data.quantity }}</div>
                                                    <div class="col-1 text-center">
                                                        <i v-if="data.quantity_left == 0" class="ri-check-line check-success"></i>
                                                        <input v-else type="number" name="quantity" v-model="data.received" min="0" :max="data.quantity_left" class="form-control height-sm" :placeholder="$t('label.qty')">
                                                    </div>
                                                </div> 
                                            </td>
                                        </tr>
                                        <!-- RECEIVED ITEMS > 0 AND EXPAND TR -->
                                        <template v-if="data.purchase_order_received_items.length > 0 && !data.show_received_items">
                                            <tr :key="'item' + d.id + '-' + i" v-for="(d, i) in data.purchase_order_received_items">
                                                <td class="px-2 list-group-item-action expanded-entry-body">
                                                    <div class="row p-0 m-0 align-items-center">
                                                        <div class="col-9 pl-5">{{ d.received_date | formatDate }}</div>
                                                        <div class="col-1 text-right">{{ $t('label.received') }}</div>
                                                        <div class="col-1 text-right">{{ d.quantity }}</div>
                                                        <div class="col"></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                        <!-- RECEIVED ITEMS == 0 AND EXPAND TR -->
                                        <template v-if="data.purchase_order_received_items.length == 0 && !data.show_received_items">
                                            <tr :key="'none' + index">
                                                <td class="px-2 list-group-item-action expanded-entry-body">
                                                    <div class="row p-0 m-0 align-items-center">
                                                        <div class="col-12 pl-5">Nothing received yet</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </tbody>
                                <tbody class="border-bottom" v-else>
                                    <template  v-for="(data,index) in form.listItems">
                                        <tr :key="index" :id="data.id">
                                            <td class="px-2 list-group-item-action collapsed-entry">
                                                <div class="row p-0 m-0 align-items-center">
                                                    <div class="col-7">{{ data.description }}</div>
                                                    <div class="col-1">{{ data.sku }}</div>
                                                    <div class="col-1 text-center">
                                                         <div class="input-group">
                                                            <money v-model="data.unit_cost" v-bind="money" class="form-control" required @blur.native="autoSum"></money>
                                                        </div>
                                                    </div>
                                                    <div class="col-1 text-right">${{ Number.parseFloat(data.total).toFixed(2) }}</div>
                                                    <div class="col-1 text-center">
                                                        <input type="number" name="quantity" v-model="data.quantity" :min="data.totalQuantityReceived" class="form-control height-sm" :placeholder="$t('label.qty')" @change="autoSum">
                                                    </div>
                                                    <div class="col-1 text-center">
                                                        <input type="number" name="quantity" v-model="data.totalQuantityReceived" :min="data.totalQuantityReceived" class="form-control height-sm" :placeholder="$t('label.qty')" disabled>
                                                    </div>
                                                </div>  
                                            </td>
                                        </tr>
                                        <div class="col-12" :key="'notes' + index">
                                            <label class="form-control-label text-muted">Reason for Change</label>
                                            <input type="text" v-model="data.notes" name="notes" class="form-control" placeholder="Add a note for your records">
                                        </div>

                                        <!-- RECEIVED ITEMS > 0 AND EXPAND TR -->
                                        <template v-if="data.purchase_order_received_items.length > 0 && !data.show_received_items">
                                            <tr :key="'item' + d.id + '-' + i" v-for="(d, i) in data.purchase_order_received_items">
                                                <td class="px-2 list-group-item-action expanded-entry-body">
                                                    <div class="row p-0 m-0 align-items-center">
                                                        <div class="col-9 pl-5">{{ d.received_date | formatDate }}</div>
                                                        <div class="col-1 text-right">{{ $t('label.received') }}</div>
                                                        <div class="col-1 text-right">{{ d.quantity }}</div>
                                                        <div class="col"></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                        <!-- RECEIVED ITEMS == 0 AND EXPAND TR -->
                                        <template v-if="data.purchase_order_received_items.length == 0 && !data.show_received_items">
                                            <tr :key="'none' + index">
                                                <td class="px-2 list-group-item-action expanded-entry-body">
                                                    <div class="row p-0 m-0 align-items-center">
                                                        <div class="col-12 pl-5">Nothing received yet</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </tbody>
                            </table>
                            <table class="table table-flush table-sm">
                                <thead class="thead-light">
                                    <tr>
                                        <th class="px-2">
                                            <div class="row p-0 m-0">
                                                <div class="col-7"></div>
                                                <div class="col-2"></div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="editItemInfo">
                                        <tr>
                                            <td class="px-2 list-group-item-action collapsed-entry">
                                                <div class="row p-0 m-0">
                                                    <div class="col-8 text-right"></div>
                                                    <div class="col-1 text-right">{{ $t('label.tax') }}</div>
                                                    <div class="col-1 text-right"><b>{{ Number.parseFloat(form.tax).toFixed(2) }}%</b></div>
                                                    <div class="col-1 text-right"><b>${{ Number.parseFloat(form.sub_total).toFixed(2) | formatThousands }}</b></div>
                                                    <div class="col"></div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="row p-0 m-0">
                                                    <div class="col-8 text-right"></div>
                                                    <div class="col-1 text-right"><h4>{{ $t('label.total') }}</h4></div>
                                                    <div class="col-1 text-right"><h4>${{ form.overall_total | formatThousands }}</h4></div>
                                                    <div class="col"></div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-top">
                    <button type="submit" class="btn btn-primary mr-auto">{{ $t('label.save changes') }}</button>
                    <button type="button" class="btn btn-link  ml-auto" @click="$emit('cancelFnc')">{{ $t('label.cancel') }}</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import bus from '@/utils/event-bus';

export default {
    props: ['purchaseOrderItem', 'purchaseOrderId'],
    emits: ['cancelFnc', 'reload'],
    data() {
        return {
            received_date: null,
            disableSubmit: false,
            entryErrors: {
                received_date: "",
            },
            form: {
                listItems: [],
                sub_total: 0,
                overall_total: 0,
                tax: 0,
                no_items: 0,
                hide_costs: 0,
                listItemsDelete: [],

                quantity: 0,
                quantity_left: 0,
                unit_cost: 0,
                total: 0,
                taxes: 0,
                taxCalc: 0,
                received: null,
                notes: null,
            },
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '',
                precision: 2,
                masked: false,
                listItems: [
                {
                    description: '',
                    sku: '',
                    quantity: 0,
                    unit_cost: 0,
                    subtotal: 0,
                    taxes: 0,
                    total: 0
                },]
            },
            editItemInfo: false,
            poItem: [],
            needsNotes: false,
            isNotesBlank: false,
        }
    },
    mounted() {
        this.poItem = this.purchaseOrderItem;
        this.form.received = this.poItem.received;
        this.loadLkupData();
    },
    methods: {
        loadLkupData() {
            let promises = [];
            this.loader = this.$loading.show();
            this.poItem = this.purchaseOrderItem;
            // Hide the loader overlay after all lookup data is loaded
            Promise.all(promises).then(() => {
                this.loader.hide();
            });
        },
        // Validating and Saving Entries
        validateEntry() {
            if(!this.editItemInfo) {
                this.disableSubmit = true;
                // Date Received
                this.entryErrors.received_date = this.received_date ? "" : "This field is required.";

                // Show Errors or Save
                if (this.entryErrors.received_date) {
                    this.disableSubmit = false;
                    return;
                } else {
                    this.receiveItem();
                }
            } else {
                this.checkCurrIsDiffThanOriginal(this.form.listItems);
            }
        },
        receiveItem() {
            this.loader = this.$loading.show();
            let dataReq = {
                purchase_order_id: this.purchaseOrderId,
                log_type: 'received_items'
            }
            axios.post('/purchase-order-activity-log', dataReq)
                .then(response => {
                    let form = {
                        received_date: this.received_date,
                        purchase_order_items: this.poItem,
                        purchase_order_activity_log_id: response.data.id,
                    }

                    axios.post('/receive-items', form)
                        .then(res => {
                            bus.$emit('banner-success', { message: `Changes saved successfully!` });
                            this.$emit('reload');
                            bus.$emit('reloadActivityLogs');
                            bus.$emit('reloadPurchaseOrder');
                            this.$emit('cancelFnc');

                            this.loader.hide();

                        }).catch(err => {
                            bus.$emit('banner-error', { message: err.data.message });
                            this.loader.hide();
                        });
                })
                .catch(err => console.log('purchaseOrderActivityLog', err));
        },
        savePurchaseOrderItems() {
            this.loader = this.$loading.show();

            let mergedForm = {
                form_item: this.form,
                po_Item: this.poItem,
                po_id: this.purchaseOrderId

                // section: this.section,
            };
            axios.post('/purchase-order/updateItems', mergedForm).then(res => {
                let resUpdatedItems = res.data.result.purchase_order_items;
                this.poItem = resUpdatedItems;
                let currPOStatus = res.data.result.purchase_order_status.name;
                this.$emit('reload');
                if(currPOStatus == 'Complete') {
                    this.$emit('cancelFnc');
                    bus.$emit('reloadPurchaseOrder');
                }
                this.editItemInfo = false;
                this.clearForm();
                this.loader.hide();
            })
            .catch(err => console.log('so update',err));
        },
        autoSum() {
            this.form.sub_total = 0;
            this.form.listItems.forEach((data, index) => {
                data.subtotal = 0;
                data.subtotal += parseFloat(data.quantity) * parseFloat(data.unit_cost);
                data.taxes = this.form.tax;
                data.total = data.subtotal + (data.subtotal * (this.form.tax/100));

                this.form.sub_total += data.subtotal;
                data.subtotal = parseFloat(data.subtotal).toFixed(2);

                data.received = 0;
            }) 
            this.form.taxCalc = this.form.sub_total * (this.form.tax/100);
            this.form.overall_total = Number.parseFloat(this.form.sub_total + this.form.taxCalc).toFixed(2);
        },
        editItem(data) {
            this.$emit('reload');
            this.editItemInfo = true;

            this.form.sub_total = 0;
            this.form.overall_total = data.overall_total;
            this.form.tax = data.tax;
            this.form.taxCalc = 0;
            this.form.no_items = data.no_items;
            this.form.hide_costs = data.hide_costs;
            this.form.listItems = this.poItem;
            this.form.quantity = this.poItem.quantity;
            this.form.quantity_left = data.quantity_left;
            this.form.notes = data.notes;

            data.forEach((d,i)=> {  
                var totalQuantityReceived = 0;

                d.purchase_order_received_items.forEach((r,idx) => {
                    totalQuantityReceived += r.quantity;
                });
                Object.assign(d,{totalQuantityReceived: totalQuantityReceived});
            });
            this.form.tax = data[0].taxes;
            this.autoSum();             

        },
        disableDueDateAfterToday(date) {
            return date > moment().subtract(0, 'days').toDate();
        },
        clearForm() {
            this.form.sub_total = 0;
            this.form.overall_total = 0;
            this.form.tax = 0;
            this.form.taxCalc = 0;
            this.form.no_items = 0;
            this.form.hide_costs = 0;
            this.form.listItems = [];
            this.form.quantity = 0;
            this.form.quantity_left = 0;
            this.form.notes = null;
            this.form.received = null;
            this.form.taxCalc = 0;
            this.form.taxes = 0;
            this.form.total = 0;
        },
        checkCurrIsDiffThanOriginal(data) {
            this.needsNotes = false;
            data.every((items,i)=> {
                if((items.original_quantity != items.quantity ||
                    items.original_unit_cost != items.unit_cost) &&
                    (items.notes == null || items.notes == '')) {
                    this.needsNotes = true;
                    this.isNotesBlank = true;

                    return false;

                } 
                return true;

            });
            
            if(this.needsNotes == true) {
                if(this.isNotesBlank == true) {
                    // this.editItem(data);
                    bus.$emit('banner-error', { message: 'Changes to Unit_Cost or Quantity were made, Please add a note to that item.' });

                }
                else {
                    this.savePurchaseOrderItems();
                }
            } else {
                // this.editItem(data);
                this.savePurchaseOrderItems();
            }
        },
    },
}
</script>
