<template>
    <modal :name="this.modalName" @closed="onClose()" tabindex="-1" width="500px" :scrollable="true" height="auto" :reset="true" :adaptive="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header border-bottom pb-2">
                    <h5 class="modal-title" id="changePasswordTitle">{{ $t('label.change password') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal()">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <form method="post" autocomplete="off" @submit.prevent="changePassword()">
                    <div class="p-4">
                        <div class="form-group">
                            <label class="form-control-label" for="input-current-password">{{ $t('label.current password') }}</label>
                            <input v-model="oldPassword" type="password" name="old_password" id="input-current-password" class="form-control" placeholder="******" value="" required>

                        </div>
                        <div class="form-group">
                            <label class="form-control-label" for="input-password">{{ $t('label.new password') }}</label>
                            <input v-model="newPassword" type="password" name="password" id="input-password" class="form-control" placeholder="******" value="" required>

                        </div>
                        <div class="form-group">
                            <label class="form-control-label" for="input-password-confirmation">{{ $t('label.confirm new password') }}</label>
                            <input v-model="newPasswordConfirm" type="password" name="password_confirmation" id="input-password-confirmation" class="form-control" placeholder="******" value="" required>
                        </div>
                        <div class="modal-footer border-top d-flex justify-content-between pt-4 px-0">
                            <button type="submit" class="btn btn-default">{{ $t('label.change password') }}</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal()">{{ $t('label.cancel') }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </modal>
</template>

<script>
import bus from '@/utils/event-bus';

export default {
    props: ['newId'],
    emits: ['onClose', 'onSave'],
    data() {
        return {
            modalName: 'editPassword',
            oldPassword: null,
            newPassword: null,
            newPasswordConfirm: null,
            passwordConfirmError: null,
        }
    },
    mounted() {
        this.$modal.show(this.modalName);
    },
    methods: {
        changePassword() {
            let passwordInfo = {
                oldpassword: this.oldPassword,
                password: this.newPassword,
                password_confirmation: this.newPasswordConfirm
            }
            if (this.newPassword) {
                if (this.newPassword !== this.newPasswordConfirm) {
                    this.passwordConfirmError = "Passwords do not match.";
                    bus.$emit('banner-error', { message: this.passwordConfirmError, })
                    return
                }
            }
            axios.post('/change-password', passwordInfo)
                //	headers: { Authorization: 'Bearer ' + this.token }
                .then(response => {
                    this.closeModal();
                })
        },
        closeModal() {
            this.onClose();
            this.$modal.hide(this.modalName);
        },
        onClose() {
            this.$emit('onClose', false);
        }
    }
}
</script>