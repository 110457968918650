<template>
    <modal :name="modalName" @closed="$emit('onClose')" :classes="'rounded'" :min-height="500" width="1000px" :scrollable="true" height="auto" :reset="true" :adaptive="true">
        <div>
            <div class="modal-content">
                <!-- Header -->
                <div class="modal-header border-bottom">
                    <h5 v-if="displayName" class="modal-title" id="viewUploadedFileHdr"> {{ displayName }}</h5>
                    <h5 v-else class="modal-title" id="viewUploadedFileHdr">{{ $t('label.uploaded file') }}</h5>
                    <button type="button" class="close" @click="$emit('onClose')">
                        <i class="ri-close-line"></i>
                    </button>
                </div>

                <!-- Body -->
                <div class="p-4 text-center">
                    <img style="max-height: 700px; max-width: 100%" :src="src">
                </div>

                <!-- 
                    TODO: In the future, when needed, expand file to parse
                    displayName to figure out file type. Have multiple view options
                    for other file types. 
                -->
                <div class="modal-footer border-top">
                    <button class="btn btn-link ml-auto" @click="$emit('onClose')">
                        {{ $t('label.close') }}
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<style>

</style>

<script>
export default {
    props: ['src', 'displayName'],
    emits: ['onClose'],
    components: {},
    data() {
        return {
            modalName: "view" + this.displayName
        }
    },
    mounted() {
        this.$modal.show(this.modalName);
    },
    methods: {}
}
</script>