<template>
    <div class="col-xl-12 order-xl-1 mobile-p-0 p-0">
        <div class="card">
            <div class="card-header pb-1" style="border-bottom: 0px;">
                <div class="row align-items-center justify-content-between">
                    <div class="col-6">
                        <h3 class="mb-0 d-flex align-items-center">
                            <i class="ri-history-line heading-title"></i>
                            {{ $t('label.vehicles odometer history') }}
                        </h3>
                    </div>
                    <div class="col-6 text-right">
                        <button type="button" class="btn btn-sm btn-default m-0" data-toggle="modal" @click="showAddOrEdit()">
                            {{ $t('label.vehicles update odometer') }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="m-0">
                <div class="card-body mt-2 p-0 border-top" v-show="allOdometerEntries.length < 1">
                    <div class="m-0 py-0">
                        <div class="bg-white text-center row align-items-center m-0">
                            <i class="ri-tools-line ni-8x w-100 color-gray-400"></i>
                            <p class="w-100 font-weight-normal">{{ $t('label.vehicles no odometer readings have been added to this vehicle') }}.</p>
                        </div>
                    </div>
                </div>
                <div class="card-body p-0" v-show="allOdometerEntries.length >= 1">
                    <div class="tab-content">
                        <div class="tab-pane fade show active" role="tabpanel">
                            <div class="table-responsive pb-4">
                                <table class="table table-flush table-sm border-bottom" id="odometerTable">
                                    <!-- Table Header -->
                                    <thead class="thead-light">
                                        <tr class="">
                                            <th>
                                                <div class="row p-0 m-0">
                                                    <div class="col-3">{{ $t('label.vehicles meter date') }}</div>
                                                    <div class="col-3 col-lg-2">{{ $t('label.vehicles odometer') }}</div>
                                                    <div class="col-3 hidden-md-down">{{ $t('label.source') }}</div>
                                                    <div class="col-4 col-lg-3">{{ $t('label.updated by') }}</div>
                                                    <div class="col-2 col-lg-1"></div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <!-- Table Body -->
                                    <tbody>
                                        <tr v-for="(entry, index) in paginatedOdometerEntries" :key="index">
                                            <td class="list-group-item-action">
                                                <!-- <td class=""> -->
                                                <div class="row p-0 m-0 align-items-center d-flex">
                                                    <div class="col-3">
                                                        <div class="align-items-center">{{ entry.odometer_reading_date }}</div>
                                                    </div>
                                                    <div class="col-3 col-lg-2">
                                                        <div class="align-items-center">{{ entry.odometer_reading ? entry.odometer_reading.toLocaleString() : "0" }}&nbsp;mi</div>
                                                    </div>
                                                    <div class="col-3 hidden-md-down">
                                                        <div class="align-items-center collapsed-details">{{ entry.source }}</div>
                                                    </div>
                                                    <div class="col-4 col-lg-3">
                                                        <div class="m-0 d-flex align-items-center">
                                                            <img class="avatar-img rounded-circle mr-2" :src="entry.created_by.picture" alt="">
                                                            <span class="cursor-pointer" @click="getUserInfo(entry.created_by_user_id, 'Updated By')">{{ entry.created_by.name }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="ml-auto text-right">
                                                        <!-- List Entry Options -->
                                                        <div class="dropdown ml-auto">
                                                            <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown">
                                                                <i class="fas fa-ellipsis-v"></i>
                                                            </a>
                                                            <div id="odomElmDropdown" class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                                <a class="dropdown-item d-flex align-items-center" href="#" data-dismiss="modal" data-toggle="modal" data-target="#addOrEditOdometerModal" @click="showEdit(entry)">
                                                                    <i class="ri-pencil-line pr-2"></i> {{ $t('label.vehicles edit odometer entry') }}
                                                                </a>
                                                                <a v-if="user.role_id == 1 | user.role_id == 2" class="dropdown-item d-flex align-items-center text-danger" href="#" data-dismiss="modal" data-toggle="modal" data-target="#deleteOdometerEntryModal" @click="selectEntry(entry)">
                                                                    <i class="ri-delete-bin-line pr-2"></i> {{ $t('label.vehicles delete odometer entry') }}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- Table Pagination -->
                                <table-pagination v-model="paginatedOdometerEntries" :all-rows="allOdometerEntries" :perPage="10">
                                </table-pagination>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Add or Edit Modal -->
            <div v-if="modalActive">
                <div class="mb-6 mb-sm-4" v-if="selectedEntry.id === null">
                    <odometer-add :vehicle-id="vehicleId" :modal="true" @on-close="closeAddOrEdit"></odometer-add>
                </div>

                <!-- Edit -->
                <div class="mb-6 mb-sm-4" v-if="selectedEntry.id !== null">
                    <odometer-edit :vehicle-id="vehicleId" :modal="true" :edit-entry="JSON.parse(JSON.stringify(selectedEntry))" @on-close="closeAddOrEdit"></odometer-edit>
                </div>
            </div>

            <!-- Delete Entry Modal -->
            <div class="modal fade" id="deleteOdometerEntryModal" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
                    <div class="modal-content bg-gradient-danger">
                        <!-- Delete Header -->
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <!-- Delete Form Details -->
                        <div class="modal-body">
                            <div class="py-3 text-center">
                                <i class="ri-error-warning-line ni-3x"></i>
                                <h4 class="heading mt-4 text-">{{ $t('label.ARE YOU SURE YOU WANT TO DELETE THIS ODOMETER ENTRY') }}?</h4>
                                <p>{{ $t('label.This action cannot be undone') }}!</p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-white" id="deleteEntryBtn" data-dismiss="modal" @click="deleteEntry()">{{ $t('label.Yes, Delete') }}</button>
                            <button type="button" class="btn btn-link text-white ml-auto" data-dismiss="modal">{{ $t('label.cancel') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style></style>

<script>
import OdometerAddOrEditVue from '@/components/asset-management/vehicles/vehicleOdometerHistory/OdometerAddOrEdit.vue';
import TablePagination from '@/components/TablePagination.vue';

import bus from '@/utils/event-bus';
import * as $ from 'jquery';

export default {
    props: ['vehicleId'],
    components: {
        TablePagination,
        odometerAdd: OdometerAddOrEditVue,
        odometerEdit: OdometerAddOrEditVue
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            allOdometerEntries: [],
            paginatedOdometerEntries: [],
            selectedEntry: {
                id: null
            },
            modalActive: false
        }
    },
    mounted() {
        this.getAllOdometerEntries();

        // Listen for changes in maintenance component
        bus.$on('update-odom-list', () => {
            this.reloadOdometerEntries();
        });
        // Listen for show page's quick add for odometer
        bus.$on('quick-add-odometer', () => {
            this.showAddOrEdit();
        });
    },
    methods: {
        reloadOdometerEntries() {
            this.getAllOdometerEntries();
            bus.$emit('update-current-odom'); // Update parent components current odom 
        },
        getAllOdometerEntries() {
            axios.get('/vehicle-odometer-history/get-entries/' + this.vehicleId).then(response => {
                this.allOdometerEntries = response.data;
                this.allOdometerEntries.sort(function compare(cur, next) {
                    if (cur.odometer_reading_date === next.odometer_reading_date) {
                        if (cur.odometer_reading < next.odometer_reading)
                            return 1;
                        else if (cur.odometer_reading > next.odometer_reading)
                            return -1;
                    }

                    return 0;
                });

            }).catch(err => console.log(err));
        },

        selectEntry(entry) {
            this.selectedEntry = entry;
        },

        showEdit(entry) {
            this.selectedEntry = entry;
            this.showAddOrEdit();
        },
        showAddOrEdit() {
            this.modalActive = true;
        },
        closeAddOrEdit() {
            this.modalActive = false;
            this.selectedEntry = {
                id: null
            };

            $('#addOrEditOdometerModal').modal('hide');
            this.reloadOdometerEntries();
        },

        deleteEntry() {
            let toDelete = {
                'id': this.selectedEntry.id
            };

            axios.post("/vehicle-odometer-history/destroy", toDelete).then(response => {
                // There exists a maintenance entry tied odometer entry.
                if (response.data.status === 2) {
                    bus.$emit('banner-error', {
                        message: this.$t('label.vehicles delete the corresponding maintenance entry before deleting this odometer entry') + "."
                    });
                } else {
                    bus.$emit('banner-success', {
                        message: this.$t('label.vehicles odometer log entry deleted successfully') + '!'
                    });

                    this.selectedEntry = {
                        id: null
                    };
                    this.reloadOdometerEntries();
                }
            }).catch(err => console.log(err));
        }
    }
}
</script>
