<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>
        <div class="container-fluid pb-5 sp-mobile-mt-50" id="jobTypesContainer">
            <!-- HEADER -->
            <div class="d-flex" style="z-index: 100">
                <!-- Breadcrumbs -->
                <breadcrumbs :title="'My Time Off Requests'"></breadcrumbs>                
            </div>

            <!-- My Requests -->
            <time-off-employee-requests @dataLoaded="empReqsLoaded = true"></time-off-employee-requests>
        </div>
    </div>
</template>
<script>

import TimeOffEmployeeRequests from '@/common/TimeOffEmployeeRequests'

export default {
    components: {        
        TimeOffEmployeeRequests,
    },		
    props: [],
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            empReqsLoaded: false,
        }
    },   
    watch: {
        empReqsLoaded() {
            this.stopLoader();
        },        
    },
    mounted() {
        this.loader = this.$loading.show();
    },
    methods: {
        stopLoader() {
            if(this.empReqsLoaded)
                this.loader.hide()
        }
    }
}
</script>
