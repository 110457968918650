<template>
	<div>
		<div class="modal-content">
			<div class="modal-header border-bottom">
				<h5 class="modal-title" id="invoiceSendModalTitle">{{ $t('label.send email') }}</h5>
				<button type="button" class="close" @click="$emit('cancelFnc')">
					<i class="ri-close-line"></i>
				</button>
			</div>
			<form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="sendPurchaseOrder">
				<div class="modal-body">
					<div v-if="changesToPurchaseOrder" class="row m-0 mb-3">
						<div class="col-12">
							<label class="form-control-label mb-0">{{ $t('label.changes to the purchase order require an email to the vendor with an attached purchase order') }}.</label>
						</div>
					</div>
					<!-- Send To -->
					<div class="row m-0 mb-3">
						<div class="col-12">
							<label class="form-control-label">{{ $t('label.to') }}</label>
							<input type="text" name="to" v-model="email.to" class="form-control" :placeholder="$t('label.email to')" required>
						</div>
					</div>
					<!-- Email Subject -->
					<div class="row m-0 mb-3">
						<div class="col-12">
							<label class="form-control-label">{{ $t('label.subject') }}</label>
							<input type="text" name="subject" v-model="email.subject" class="form-control" :placeholder="$t('label.email subject')" required>
						</div>
					</div>
					<!-- Email Body -->
					<div class="row m-0 mb-3">
						<div class="col-12">
							<label class="form-control-label">{{ $t('label.email body') }}</label>
							<textarea v-model="email.body" name="email_body" class="form-control" :placeholder="$t('label.body of email')" rows="10" required autofocus></textarea>
						</div>
					</div>
					<!-- Attachments -->
					<div class="row m-0 mb-3">
						<div class="col-12">
							<label class="form-control-label">{{ $t('label.attachments') }}</label>
							<div class="row align-items-center m-0">
								<div class="col-auto p-0">
									<img class="" :src="require('@/assets/images/doc-pdf.png')" style="height: 48px; object-fit: cover;" />
								</div>
								<div class="col-auto">
									<p class="m-0" style="line-height: 20px;">
										<label class="form-control-label mb-0">{{ pdfName }}</label>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer border-top">
					<button type="submit" class="btn btn-primary mr-auto text-uppercase">{{ $t('label.send email') }}</button>
					<button type="button" class="btn btn-link  ml-auto" @click="$emit('cancelFnc')">{{ $t('label.cancel') }}</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import bus from '@/utils/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
	props: ['purchaseOrder', 'sendType', 'changesToPurchaseOrder', 'subjectText'],
	emits: ['cancelFnc', 'updateStatus', 'returnAfterSend'],
	components: {
		Loading,
		vueDropzone: vue2Dropzone
	},
	data() {
		return {
			companySettings: JSON.parse(localStorage.getItem('company_settings')),
			email: {
				to: null,
				subject: null,
				body: null,
				purchase_order_id: null
			},
			selectedEntry: {
				attachments: []
			},
			viewTemplate: "form",
			disableSubmit: false,
			selectedFile: {
				id: null
			},
		}
	},
	computed: {
		pdfName() {
			let name = this.sendType == 'cancel' ? 'CANCELLED_' : '';
			return name + "SO-" + this.purchaseOrder.purchase_order_number.purchase_order_number + "_" + moment().format('YYYY_MM_DD') + ".pdf";
		},
	},
	mounted() {
		this.loadLkupData();
	},
	methods: {
		loadLkupData() {
			this.email.subject = (this.subjectText ? this.subjectText + " - " : "") + "Supply Order #" + this.purchaseOrder.purchase_order_number.purchase_order_number + " from " + this.companySettings.name;
			this.email.purchase_order_id = this.purchaseOrder.id;
			this.email.sendType = this.sendType;
			this.email.po_pdf_name = this.pdfName;

			// Get email recipients
			if (this.purchaseOrder.purchase_order_status.name == "Sent") { // Previously sent an email(use same recipients)
				this.getPreviouslySentEmail().then(() => {
					let emailAddresses = JSON.parse(this.previouslySentEmail.to);

					this.email.to = "";
					emailAddresses.forEach((emAddr, i) => {
						this.email.to += emAddr;
						this.email.to += (i != emailAddresses.length - 1) ? ", " : "";
					});
				});
			} else {
				for (var i = 0; i < this.purchaseOrder.vendor.vendor_contacts.length; i++) {
					if (this.purchaseOrder.vendor.vendor_contacts[i].id == this.purchaseOrder.vendor_contact_id)
						this.email.to = this.purchaseOrder.vendor.vendor_contacts[i].email;
				}
			}
		},
		getPreviouslySentEmail() {
			let id = this.purchaseOrder.id;
			return axios.get(`/get-po-first-sent-email/${id}`).then(rsp => {
				this.previouslySentEmail = rsp.data.result;
			}).catch(err => console.error(err));
		},
		sendPurchaseOrder() {
			if (this.changesToPurchaseOrder) {
				bus.$emit('updatePurchaseOrder', this.email);
				this.$emit('cancelFnc');

			} else {
				if (this.isFormValid()) {
					this.loader = this.$loading.show();
					this.email.toList = this.$spSplitEmailString(this.email.to);

					// Save and Send Email
					this.$store.dispatch('purchaseOrder/sendPurchaseOrder', this.email).then(() => {
						bus.$emit('banner-success', { message: `Supply Order Email sent successfully!` });
						bus.$emit('reloadPurchaseOrder');
						this.loader.hide()

						this.$emit('cancelFnc');
						this.$emit('updateStatus');
					});
				}
			}
		},
		isFormValid() {
			let isValid = true,
				emails = this.$spSplitEmailString(this.email.to);

			// Validate email(s)
			emails.forEach(email => {
				isValid = this.$spValidEmail(email);
				// Break out of foreach if an email is invalid and alert user
				if (!isValid) {
					bus.$emit('banner-error', {
						message: this.$t('label.Please verify that the email(s) in the "To" field are correct If multiple emails are entered, be sure to separate them with commas')
					});
					return;
				}
			});

			return isValid;
		},
		clearForm() {
			this.email.to = null,
				this.email.subject = null;
			this.email.body = null;
			this.requestData = null
		},
		getUploadedFile(file) {
			setTimeout(function () {
				this.selectedEntry.attachments.push({
					image: file.dataURL,
					fileName: file.name,
					fileType: file.type,
					file: file
				});

				// Clear the dropzone for more files
				this.$refs.myVueDropzone.removeAllFiles(true);
				this.disableSubmit = false;
			}.bind(this), 1000);
		},
		uploadingFile() {
			this.disableSubmit = true;
		},
		selectFile(file, viewTemplate) {
			this.selectedFile = file;
			this.viewTemplate = viewTemplate;
		},
		deleteItem(data, idx) {
			this.selectedEntry.attachments.splice(idx, 1);

			if (this.poType == 'edit') {
				this.formComment.deletedAttachments.push(data.id);
			}
		},
	}
}
</script>
<style></style>