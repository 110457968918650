<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50">
            <!-- HEADER -->
            <div class="row align-items-center py-4">
                <div class="col-5">
                    <!-- <h2 class="text-white">{{ $t('label.work history') }}</h2> -->
                </div>
                <div class="col-7 text-right">
                    <!-- <button class="btn btn-neutral" type="button">Text</button> -->
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <div>
                        <h3 class="mb-0">
                            Work History for pay period
                            <span v-if="selectedPayPeriod">
                                {{ selectedPayPeriod.start_date | formatDateNoTz }} - {{ selectedPayPeriod.end_date | formatDateNoTz }}
                            </span>
                        </h3>
                    </div>
                </div>
                        <div class="card-body">
                            <div class="form-group">
                                <h3>Select a Pay Period</h3>
                        <select class="form-control" 
                            @change="getWorkHistory()"
                            v-model="selectedPayPeriod"
                            placeholder="Pay Period">
                            <option v-for="payPeriod in payPeriods" 
                                :value="payPeriod" 
                                :key="payPeriod.id">
                                        {{ payPeriod.start_date | formatDateNoTz }} - {{ payPeriod.end_date | formatDateNoTz }}
                                    </option>
                                </select>
                            </div>
                            <!-- Loop each week of the pay period -->
                            <div v-for="(payPeriod, idx) in payPeriodWeeks" :key="idx">
                                <div class="mt-3" v-if="idx > 0"></div>
                                <h3>Week {{ payPeriod.startDate | formatDateNoTz }} - {{ payPeriod.endDate | formatDateNoTz }}</h3>
                                <user-daily-payroll-by-week :search="payPeriod" :key="refreshComponent"></user-daily-payroll-by-week>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>

<script>
import bus from '@/utils/event-bus'
import UserDailyPayrollByWeek from '@/common/UserDailyPayrollByWeek'

export default {
    props: [],
    components: { UserDailyPayrollByWeek },
    emits: [],
    data() {
        return {
            thisUser: JSON.parse(localStorage.getItem('user')),
            payPeriods: [],
            selectedPayPeriod: null,
            search: { startDate: null, endDate: null, userId: null },
            showPayroll: false,
            loader: null,
            refreshComponent: false,
            payPeriodWeeks: []
        }
    },
    mounted() {
        this.search.userId = this.thisUser.id
        this.loadPayPeriods()
    },
    methods: {
        loadPayPeriods() {
            this.loader = this.$loading.show()
            axios.get(`getPayPeriodsLimit/${3}`).then((response)=>{
                this.loader.hide()
                this.payPeriods = response.data
                if (this.payPeriods.length) {
                    this.selectedPayPeriod = this.payPeriods[0]
                    this.getWorkHistory()
                }
                else {
                    this.hasData = 1
                }
            })
        },
        disableStartDateBeforeToday(date) {
            // Disable dates after current week and older than a month
            return date < moment().subtract(1, 'month').weekday(6) || date > moment().weekday(6);
        },
        getWorkHistory (){
            //get number of days between start and end date to determine pay period type
            this.payPeriodWeeks = []
            let startDt = moment(this.selectedPayPeriod.start_date)
            let endDt = moment(this.selectedPayPeriod.end_date)

            // Bi-weekly
            if(endDt.diff(startDt, 'days')>7){
                this.payPeriodWeeks.push({userId:this.search.userId, startDate: this.selectedPayPeriod.start_date, endDate: startDt.add(6,'days').format('YYYY-MM-DD')})
                this.payPeriodWeeks.push({userId:this.search.userId, startDate: startDt.add(1,'days').format('YYYY-MM-DD'), endDate: this.selectedPayPeriod.end_date})
    }
            // Weekly
            else{
                this.payPeriodWeeks.push({userId:this.search.userId, startDate: this.selectedPayPeriod.start_date, endDate:this.selectedPayPeriod.end_date})
}
            this.showPayroll = true
            this.refreshComponent = !this.refreshComponent
        }  
    }
}
</script>