import authGuard from '@/middlewares/AuthGuard';
;

export default {
  name: 'Dashboard',
  icon: 'people_alt',
  store: 'Dashboard/store/index.js',
  routes: [{
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ './views/DashboardComponent.vue'),
    beforeEnter: authGuard,
    meta: {
      layout: 'default',
      title: 'Dashboard',
      accessRole: 2
    }
  }, {
    path: `/companyManage`,
    name: 'companyManage',
    component: () => import(/* webpackChunkName: "dashboard" */ './views/DashboardComponent.vue'),
    meta: {
      accessRole: 1
    }
  }, {
    path: `/select-company`,
    name: 'selectCompany',
    component: () => import(/* webpackChunkName: "dashboard" */ './views/selectCompany.vue'),
    meta: {
      layout: 'default',
      title: 'Dashboard',
      accessRole: 2
    }
  }],
  preRegisterCheck() {
    return true;
  }
};
