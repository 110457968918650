/* eslint-disable no-unused-vars */
export default {
    // Messages
    getAllMessages({ commit }) {
        axios.get('/get-all-messages')
            .then(response => {
                commit('GET_ALL_MESSAGES', response.data.result);
            })
            .catch(error => {
                commit('GET_ALL_MESSAGES', error);
            });
    },
    getMessageType({ commit }, payload) {
        commit('GET_MESSAGE_TYPE', payload);
    },
    getMessageEmployee({ commit }, payload) {
        commit('GET_MESSAGE_EMPLOYEE', payload);
    },
    getUploadedFile({ commit }, payload) {
        commit('GET_MESSAGE_UPLOADED_FILE', payload);
    },
    setGeolocation({ commit }) {
        navigator.geolocation.getCurrentPosition(data => {
            commit('SET_GEOLOCATION', {
                latitude: data.coords.latitude,
                longitude: data.coords.longitude
            });
        });
    },
    saveMessage({ commit }, payload) {
        axios.post('/save-message', payload)
            .then(response => {
                const data = new FormData();
                data.append('file', payload.file);

                data.append('data', response.data.result.id);

                axios.post('/save-message-file', data)
                    .then(response => {
                        commit('SAVE_MESSAGE_FILE', response.data.result);
                    })
                    .catch(error => {
                        commit('SAVE_MESSAGE_FILE', error);
                    });
                commit('SAVE_MESSAGE', response.data.result);
            })
            .catch(error => {
                commit('SAVE_MESSAGE', error);
            });
    },
    updateMessage({ commit }, payload) {
        axios.post('/message/update', payload)
            .then(response => {
                const data = new FormData();
                data.append('file', payload.file);

                data.append('data', response.data.id);

                axios.post('/save-message-file', data)
                    .then(response => {
                        commit('SAVE_MESSAGE_FILE', response.data.result);
                    })
                    .catch(error => {
                        commit('SAVE_MESSAGE_FILE', error);
                    });
                commit('UPDATE_MESSAGE', response.data.result);
            })
            .catch(error => {
                commit('UPDATE_MESSAGE', error);
            });
    },
    previewMessage({ commit }, payload) {
        commit('PREVIEW_MESSAGE', payload);
    },
    checkMessage({ commit }) {
        axios.get('/check-message')
            .then(response => {
                commit('CHECK_MESSAGE', response.data.result)
            })
            .catch(error => {
                commit('CHECK_MESSAGE', error);
            });
    },
    readMessage({ commit }, payload) {
        axios.post('/mark-as-read', { id: payload })
            .then(response => {
                commit('READ_MESSAGE', response.data.result)
            })
            .catch(error => {
                commit('READ_MESSAGE', error);
            });
    },
    getMessageId({ commit }, payload) {
        commit('GET_MESSAGE_ID', payload);
    },
    updateFormState({ commit }, payload) {
        axios.post('/get-message-recipients', { id: payload.id })
            .then(response => {
                commit('GET_RECIPIENTS', response.data.result)
            })
            .catch(error => {
                commit('GET_RECIPIENTS', error);
            });
        commit('UPDATE_FORM_STATE', payload);
    },
    clearFormState({ commit }) {
        commit('CLEAR_FORM_STATE');
    },
    deleteMessage({ commit }, id) {
        axios.post('/delete-message/' + id)
            .then(response => {
                commit('DELETE_MESSAGE', response.data.result)
            })
            .catch(error => {
                commit('DELETE_MESSAGE', error);
            });
    },
    removeImage({ commit }) {
        commit('REMOVE_IMAGE');
    },
    removeFile({ commit }) {
        commit('REMOVE_FILE');
    },

    // Users
    getAllCrews({ commit }) {
        axios.get('/get-all-crews')
            .then(response => {
                commit('GET_ALL_CREWS', response.data.result);
            })
            .catch(error => {
                commit('GET_ALL_CREWS', error);
            });
    },
    getAllUserRoles({ commit }) {
        axios.get('/get-all-user-roles')
            .then(response => {
                commit('GET_ALL_USER_ROLES', response.data.result);
            })
            .catch(error => {
                commit('GET_ALL_USER_ROLES', error);
            });
    },
    getAllEmployees({ commit }) {
        axios.get('/get-employees')
            .then(response => {
                commit('GET_ALL_EMPLOYEES', response.data.result);
            })
            .catch(error => {
                commit('GET_ALL_EMPLOYEES', error);
            });
    },
    getLoggedInUser({ commit }) {
        axios.post('/get-user')
            .then(response => {
                commit('GET_LOGGED_IN_USER', response.data.data);
            })
            .catch(error => {
                commit('GET_LOGGED_IN_USER', error);
            });
    },

    // Others
    showDropzone({ commit }, payload) {
        commit('SHOW_DROPZONE', payload);
    },
    isSyncToQb({ commit }, payload) {
        commit('UPDATE_SYNC_STATUS', payload);
    },

    // Job Notes
    getJobNotes({ commit }, payload) {
        axios.get('/jobnotes/' + payload).then(response => {
            commit('GET_ALL_JOB_NOTES', response.data.result);
            // 	this.$set(this.jobNotes, data.id, response.data.result);
            // 	this.$set(this.jobNotes2, data.id, response.data.top_5);
        })
            .catch(err => {
                console.log('list carriers error:', err)
            });
    },

    // Materials
    getJobFiles({ commit }, payload) {
        axios.get('/jobfiles/' + payload).then(response => {
            let data = response.data.result;
            data.forEach(value => {
                if (value.download) {
                    let filePath = value.download;
                    let filePathArr = filePath.split("/");
                    let filename = filePathArr[filePathArr.length - 1];
                    let fileType = filename.split('.').pop();

                    if (fileType === 'pdf') {
                        filePath = 'images/icons/doc-pdf.png';
                    } else if (fileType === 'xlsx') {
                        filePath = 'images/icons/doc-excel.png';
                    } else if (fileType === 'pptx') {
                        filePath = 'images/icons/doc-ppt.png';
                    } else if (fileType === 'docx' || fileType === 'doc') {
                        filePath = 'images/icons/doc-word.png';
                    } else if (fileType === 'txt' || fileType === 'rtf' || fileType === 'heic' || fileType === 'tiff') {
                        filePath = 'images/icons/doc-other.png';
                    }

                    value.filename = filename;
                    value.filename_path = encodeURI(filePath);
                    value.fileType = fileType;
                }
            });

            console.log(data);
            commit('GET_ALL_JOB_FILES', data);
        })
            .catch(err => {
                //console.log('list carriers error:', error)
            });
    },

    // Company
    getCompanySettings({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get('/get-company-settings').then(response => {
                commit('GET_COMPANY_SETTINGS', response.data.result);
                resolve(response);
            }).catch(err => reject(err));
        });
    },
    getStates({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get('/get-states').then(res => {
                commit('GET_STATES', res.data.result);
                resolve(res);
            }).catch(err => reject(err));
        });
    },
    getCountries({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get('/get-countries').then(res => {
                commit('GET_COUNTRIES', res.data.result);
                resolve(res);
            }).catch(err => reject(err));
        });
    }
}
