<template>
    <div class="">
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50" id="viewContainer">
            <div class="row align-items-center py-4">
                <div class="col-8">
                    <!-- Breadcrumbs -->
                    <div class="mr-auto" style="z-index: 1">
                        <breadcrumbs :title="$t('label.vehicle')">
                            <!-- Breakcrumb slots -->
                            <li class="breadcrumb-item">
                                <a class="pointer" @click="$router.go(-1)">
                                    {{ $t('label.assets') }}
                                </a>
                            </li>
                            <li class="breadcrumb-item">{{ vehicle.vehicle_name }}</li>
                        </breadcrumbs>
                    </div>
                </div>

                <!-- Vehicle Option Dropdown -->
                <div class="col-4 text-right">
                    <button type="button" class="btn btn-default" data-toggle="dropdown" id="vehicleMenu">
                        {{ $t('label.vehicle menu') | uppercase }} <i class="fas fa-angle-down"></i>
                    </button>
                    <!-- Dropdown -->
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" style="max-width: 100%;">
                        <!-- Edit Item  -->
                        <router-link class="dropdown-item" :to="{ name: 'vehicleEdit', params: { selectedVehicleId: vehicle.id } }">
                            <i class="ri-pencil-line"></i>{{ $t("label.edit") }}
                        </router-link>
                        <!-- Mark as sold/archived  -->
                        <a class="dropdown-item" href="#" @click="clickArchiveVehicle()">
                            <i class="ri-archive-line"></i>{{ $t('label.mark as sold/archived') }}
                        </a>
                    </div>
                </div>
            </div>

            <!-- CONTENT -->
            <div class="sp-window-content">
                <!-- Sticky left navigation  -->
                <div class="sp-comp-nav align-items-center" id="navWrapper">
                    <div class="sp-nav-sticky-top pt-4 mt--4">
                        <asset-show-left-nav :asset="vehicle" :asset-type="'vehicle'"></asset-show-left-nav>
                    </div>
                </div>

                <!-- Right content Column  -->
                <div class="sp-comp-body justify-content-end mobile-m-0 mobile-mt-290p flex-grow-1">
                    <div class="mobile-p-0" id="vehicle-content-column" v-if="vehicle.id">

                        <!-- Vehicle Details Card  -->
                        <vehicles-details :vehicle="vehicle"></vehicles-details>
                        <!-- Specifications Card  -->
                        <vehicles-specifications :vehicle="vehicle"></vehicles-specifications>
                        <!-- Comments Card  -->
                        <asset-comments id="assetComments" :asset-id="vehicle.id" :asset-type="'vehicle'"></asset-comments>
                        <!-- Files Card  -->
                        <asset-files id="assetFiles" :asset-id="vehicle.id" :asset-type="'vehicle'"></asset-files>
                        <!-- Maintenance Card  -->
                        <asset-mtnc-entries id="assetMaintenanceEntries" :asset-id="vehicle.id" :asset-type="'vehicle'"></asset-mtnc-entries>
                        <!-- Odometer History Card  -->
                        <vehicles-odometer-history id="assetOdometerHistory" :vehicle-id="vehicle.id"></vehicles-odometer-history>
                        <!-- Lifecycle Card  -->
                        <vehicles-lifecycle :vehicle="vehicle"></vehicles-lifecycle>

                    </div>
                </div>
            </div>

            <!-- Archive Vehicle Modal -->
            <div v-if="showArchive">
                <archive-asset :asset="vehicle" :assetType="'vehicle'" @onArchive="onArchiveVehicle()" @onClose="closeArchiveVehicle()"></archive-asset>
            </div>

        </div>
    </div>
</template>

<script>
import VehiclesDetails from '@/components/asset-management/vehicles/VehiclesDetails';
import VehiclesLifecycle from '@/components/asset-management/vehicles/VehiclesLifecycle';
import VehiclesSpecifications from '@/components/asset-management/vehicles/VehiclesSpecifications';

import moment from '@/utils/date-time';
import bus from '@/utils/event-bus';

export default {
    props: ["selectedVehicleId"],
    components: {
        VehiclesDetails,
        VehiclesLifecycle,
        VehiclesSpecifications
    },
    data() {
        return {
            showArchive: false,
            vehicle: []
        }
    },
    created() {
        bus.$on('update-current-odom', () => this.getLatestOdometer())

        // Send back to assets page if selectedVehicleId isn't set
        if (!this.selectedVehicleId) {
            this.$router.push({ name: 'assets' })
            return;
        }
    },
    mounted() {
        let promises = [];
        // this.loader = this.$loading.show();

        promises.push(this.loadVehicleData());
        // promises.push(this.getOperatorHistory());

        Promise.all(promises).then(() => {
            // this.loader.hide();
        });
    },
    methods: {
        // load Vehicle data
        loadVehicleData() {
            this.loader = this.$loading.show();
            axios
                .get(`/vehicles/edit/${this.selectedVehicleId}`)
                .then(response => {
                    this.vehicle = response.data
                    this.loader.hide();
                    // Check for vehicle operator
                    if (!this.vehicle.operator) {
                        this.$set(this.vehicle, 'operator', {
                            id: 0,
                            user_id: "",
                            date_start: moment().format("YYYY-MM-DD"),
                            date_end: "",
                            vehicle_id: 0,
                        });
                    }

                    // Format dates
                    if (this.vehicle.registration_expiration)
                        this.vehicle.registration_expiration = moment(this.vehicle.registration_expiration).toDate();
                    if (this.vehicle.in_service_date)
                        this.vehicle.in_service_date = moment(this.vehicle.in_service_date).toDate();
                    // Check for odometer
                    if (this.vehicle.odometer)
                        this.vehicle.in_service_odometer = this.vehicle.odometer.odometer_reading;
                    // Vin
                    if (!this.vehicle.vin)
                        this.vehicle.vin = "";
                })
                .catch(err => {
                    console.log('ERROR: ', err);
                    bus.$emit('banner-error', { message: err, });
                    this.loader.hide();
                })
        },
        getLatestOdometer() {
            axios.get("/vehicle-odometer-history/get-latest/" + this.vehicle.id).then(response => {
                this.vehicle.odometer_reading = response.data;
            }).catch(err => console.log(err));
        },

        clickArchiveVehicle() {
            this.showArchive = true;
        },
        closeArchiveVehicle() {
            this.showArchive = false;
        },
        onArchiveVehicle() {
            setTimeout(function () {
                location.reload();
            }, 750);
        }
    }
}
</script>
