<template>
	<div>
		<!-- Header crumbs -->
		<div class="header bg-primary pb-6 mobile-p-0">
			<div class="container-fluid">
				<div class="header-body">
					<div class="row align-items-center">
						<div class="col-lg-6 col-7">
							<breadcrumbs :title="$t('label.contacts')">
								<!-- Breadcrumb slots -->
								<li class="breadcrumb-item">
									{{ $t('label.contacts') }}
								</li>
							</breadcrumbs>
						</div>
						<div class="col-lg-6 col-5 text-right">
							<div class="dropdown">
								<button type="button" class="btn btn-neutral" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									{{ $t('label.add contact') }}
								</button>
								<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
									<a class="dropdown-item" href="#" @click="showLeadCustomerAddModal(0)">
										<i class="ri-account-box-line"></i> {{ $t('label.add lead') }}
									</a>
									<a class="dropdown-item" href="#" @click="showLeadCustomerAddModal(1)">
										<i class="ri-group-line"></i> {{ $t('label.add customer') }}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Filters -->
		<div class="container-fluid mt--6 mobile-mt-140p">
			<div class="card">
				<div class="card-header">
					<div class="row">
						<div class="col-8">
							<h3 class=""><i class="ri-group-line"></i> {{ $t('label.contacts') }}</h3>
						</div>
					</div>
				</div>
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-xs-12 col-6 text-left">
							<div class="btn-group btn-group-light rounded" role="group" aria-label="Basic example" style="z-index: 0">
								<button type="button" class="btn btn-white text-primary" :class="{ active: 1 == tableType }" @click="tableType = 1; getAllCustomers(1); removeParams()">
									{{ $t('label.customers') }}
								</button>
								<button type="button" class="btn btn-white text-primary" :class="{ active: 0 == tableType }" @click="tableType = 0; getAllCustomers(0); removeParams()">
									{{ $t('label.leads') }}
								</button>
							</div>
						</div>
						<div class="col-xs-12 col-2 text-left">
							<button v-if="filter.search.length >= 3" type="button" class="btn btn-outline-secondary text-uppercase w-100" @click="clearSearch()">Clear Search</button>
						</div>
						<div class="col-xs-12 col-4 text-left">
							<div class="form-group mb-0">
								<div class="input-group input-group-alternative input-group-merge search-contacts">
									<div class="input-group-prepend">
										<span class="input-group-text"><i class="fas fa-search"></i></span>
									</div>
									<input name="s" class="form-control" placeholder="Search all contacts" type="text" @input="onChange" v-model="filter.search" autocomplete="off" data-lpignore="true">
								</div>
							</div>
						</div>
					</div>
					<div class="row mb-3">
						<div v-if="tableType == 0" class="col-xs-12 col-12">
							<div class="form-group mb-0">
								<label class="form-control-label text-muted" for="input-name">Status</label>
							</div>
							<div class="form-group row m-0">
								<div class="mr-4 mr-xs-2" v-for="(data) in wonAndLost" :key="data.id">
									<label class="form-control-label text-muted font-weight-normal cursor-pointer2" :for="'leadStatus-' + data.id">
										<input @change="selectOnLeadStatus($event, data)" name="leadStatus" class="form-control-input mr-1" :id="'leadStatus-' + data.id" type="checkbox" :value="data.id" v-model="filter.leadStatus">
										{{ data.name }}
									</label>
								</div>
								<div class="mr-4 mr-xs-2">
									<label class="form-control-label text-muted font-weight-normal cursor-pointer2" for="leadStatus-all">
										<input @click="selectAll('lead status');" name="leadStatus" class="form-control-input mr-1" id="leadStatus-all" type="checkbox" :checked="all_leadStatus" v-model="all_leadStatus">
										{{ $t('label.all') }}
									</label>
								</div>
							</div>
						</div>
						<div v-else class="col-xs-12 col-12">
							<div class="form-group mb-0">
								<label class="form-control-label text-muted" for="input-name">Status</label>
							</div>
							<div class="form-group row m-0">
								<div class="mr-4 mr-xs-2">
									<label class="form-control-label text-muted font-weight-normal cursor-pointer2" for="status-1">
										<input @change="selectOnStatus($event, 1)" name="status" class="form-control-input mr-1" id="status-1" type="checkbox" value="1" v-model="filter.status">
										{{ $t('label.active') }}
									</label>
								</div>
								<div class="mr-4 mr-xs-2">
									<label class="form-control-label text-muted font-weight-normal cursor-pointer2" for="status-0">
										<input @change="selectOnStatus($event, 0)" name="status" class="form-control-input mr-1" id="status-0" type="checkbox" value="0" v-model="filter.status">
										{{ $t('label.inactive') }}
									</label>
								</div>
								<div class="mr-4 mr-xs-2">
									<label class="form-control-label text-muted font-weight-normal cursor-pointer2" for="status-all">
										<input @click="selectAll('status');" name="status" class="form-control-input mr-1" id="status-all" type="checkbox" :checked="all_status" v-model="all_status">
										{{ $t('label.all') }}
									</label>
								</div>
							</div>
						</div>
					</div>
					<div class="row mb-3">
						<div class="col-xs-12 col-6">
							<div class="form-group mb-0">
								<label class="form-control-label text-muted" for="input-name">Group By</label>
							</div>
							<div class="form-group row m-0">
								<div class="mr-4 mr-xs-2">
									<label class="form-control-label text-muted font-weight-normal cursor-pointer2" for="groupby-none">
										<input @change="selectOnGroupBy($event, 'none')" name="group_by" class="form-control-input" id="groupby-none" type="checkbox" value="none" v-model="filter.groupBy">
										{{ $t('label.none') }}
									</label>
								</div>
								<div class="mr-4 mr-xs-2">
									<label class="form-control-label text-muted font-weight-normal cursor-pointer2" for="groupby-status">
										<input @change="selectOnGroupBy($event, 'status')" name="group_by" class="form-control-input" id="groupby-status" type="checkbox" value="status" v-model="filter.groupBy">
										{{ $t('label.status') }}
									</label>
								</div>
								<div class="mr-4 mr-xs-2">
									<label class="form-control-label text-muted font-weight-normal cursor-pointer2" for="groupby-responsible">
										<input @change="selectOnGroupBy($event, 'responsible')" name="group_by" class="form-control-input" id="groupby-responsible" type="checkbox" value="responsible" v-model="filter.groupBy">
										{{ $t('label.responsible') }}
									</label>
								</div>
							</div>
						</div>
						<div class="col-xs-12 col-6">
							<div class="form-group">
								<label class="form-control-label text-muted" for="input-name">{{ $t('label.responsible') }}</label>
								<div class="dropdown w-100">
									<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<input name="created_date" class="form-control form-control-dropdown cursor-pointer2" placeholder="- All Employees -" type="text" v-model="filter.responsibleName" readonly>
									</div>
									<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow">
										<a class="dropdown-item text-default pointer" @click="selectResponsible('all')">
											- {{ $t('label.all employees') }} -
										</a>
										<a v-for="(data, index) in alljobpeople['users']" :key="index" class="dropdown-item text-default pointer" @click="selectResponsible(data)">
											{{ data.name }}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row mb-3">
						<div class="col-xs-12 col-6">
							<div class="form-group">
								<label class="form-control-label text-muted" for="input-name">{{ $t('label.created date') }}</label>
								<div class="dropdown w-100">
									<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<input name="created_date" class="form-control form-control-dropdown cursor-pointer2" placeholder="- Select a date range -" type="text" v-model="filter.createdDate" readonly>
									</div>
									<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow">
										<a class="dropdown-item text-default pointer" @click="selectCreatedDate('Today')">
											{{ $t('label.today') }}
										</a>
										<a class="dropdown-item text-default pointer" @click="selectCreatedDate('Yesterday')">
											{{ $t('label.yesterday') }}
										</a>
										<a class="dropdown-item text-default pointer" @click="selectCreatedDate('This week')">
											{{ $t('label.this week') }}
										</a>
										<a class="dropdown-item text-default pointer" @click="selectCreatedDate('Last week')">
											{{ $t('label.last week') }}
										</a>
										<a class="dropdown-item text-default pointer" @click="selectCreatedDate('This month')">
											{{ $t('label.this month') }}
										</a>
										<a class="dropdown-item text-default pointer" @click="selectCreatedDate('Last month')">
											{{ $t('label.last month') }}
										</a>
										<a class="dropdown-item text-default pointer" @click="selectCreatedDate('Last 30 days')">
											{{ $t('label.last 30 days') }}
										</a>
										<a class="dropdown-item text-default pointer" @click="selectCreatedDate('This quarter')">
											{{ $t('label.this quarter') }}
										</a>
										<a class="dropdown-item text-default pointer" @click="selectCreatedDate('This year')">
											{{ $t('label.this year') }}
										</a>
										<div class="dropdown-divider"></div>
										<a class="dropdown-item text-default pointer" @click="selectCreatedDate('Custom date range')">
											{{ $t('label.custom date range') }}
										</a>
										<a class="dropdown-item text-default pointer" @click="selectCreatedDate('All time')">
											{{ $t('label.all time') }}
										</a>
									</div>
								</div>
							</div>
						</div>
						<div class="col-xs-12 col-6">
							<div class="form-group">
								<label class="form-control-label text-muted" for="input-name">{{ $t('label.last activity date') }}</label>
								<div class="dropdown w-100">
									<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<input name="created_date" class="form-control form-control-dropdown cursor-pointer2" placeholder="- Select a date range -" type="text" v-model="filter.lastActivityDate" readonly>
									</div>
									<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow">
										<a class="dropdown-item text-default pointer" @click="selectLastActivityDate('today')">
											{{ $t('label.today') }}
										</a>
										<a class="dropdown-item text-default pointer" @click="selectLastActivityDate('yesterday')">
											{{ $t('label.yesterday') }}
										</a>
										<a class="dropdown-item text-default pointer" @click="selectLastActivityDate('this week')">
											{{ $t('label.this week') }}
										</a>
										<a class="dropdown-item text-default pointer" @click="selectLastActivityDate('last week')">
											{{ $t('label.last week') }}
										</a>
										<a class="dropdown-item text-default pointer" @click="selectLastActivityDate('this month')">
											{{ $t('label.this month') }}
										</a>
										<a class="dropdown-item text-default pointer" @click="selectLastActivityDate('last month')">
											{{ $t('label.last month') }}
										</a>
										<a class="dropdown-item text-default pointer" @click="selectLastActivityDate('last 30 days')">
											{{ $t('label.last 30 days') }}
										</a>
										<a class="dropdown-item text-default pointer" @click="selectLastActivityDate('this quarter')">
											{{ $t('label.this quarter') }}
										</a>
										<a class="dropdown-item text-default pointer" @click="selectLastActivityDate('this year')">
											{{ $t('label.this year') }}
										</a>
										<div class="dropdown-divider"></div>
										<a class="dropdown-item text-default pointer" @click="selectLastActivityDate('custom date range')">
											{{ $t('label.custom date range') }}
										</a>
										<a class="dropdown-item text-default pointer" @click="selectLastActivityDate('all time')">
											{{ $t('label.all time') }}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Customers component -->
			<customers-component :filter="filter" :key="reloadKey" />
		</div>

		<modal class="py-4" name="leadCustomerAddModal" height="auto" width="98%" :max-width="1024" :reset="true" :scrollable="true" :adaptive="true">
			<modal-customer :accountType="accountType" source="contacts-component" @cancelFnc="$modal.hide('leadCustomerAddModal')" @returnFnc="returnNewCustomer" />
		</modal>
	</div>
</template>
<script>
import ModalCustomer from '@/components/ModalCustomer';
import bus from '@/utils/event-bus';

export default {
	props: [],
	emits: ['fncCancel', 'fncReturn'],
	components: { ModalCustomer },
	data() {
		return {
			tableType: 1, //1 = customers, 0 = leads
			filter: {
				search: '',
				leadStatus: [],
				leadStatusData: [],
				status: [],
				groupBy: ['none'],
				responsibleName: '- All Employees -',
				responsible: null,
				createdDate: 'All time',
				lastActivityDate: null
			},
			all_status: false,
			all_leadStatus: true,
			alljobpeople: [],
			allManagers: [],
			accountType: null,
			leadStatus: [],
			wonAndLost: [],
			groupby: [],
			newCustomer: null,
			showAddEditCustomerLeadModal: false,
			reloadKey: 0,
		}
	},
	created() {

		this.getJobPeople();
		this.getCompanyManagers();
		let url2 = window.location.href;
		const url = new URL(url2);
		const params = new URLSearchParams(url.search)
		if (params.get("account_type") == 0) {
			this.tableType = 0;
			this.getAllCustomers(0);
		} else {
			this.tableType = 1;
			this.getAllCustomers(1);
		}
	},
	mounted() {

	},
	methods: {
		getLeadStatus() {
			axios.get('/get-lead-status')
				.then(response => {
					this.leadStatus = response.data.result;
					this.wonAndLost = response.data.won_and_lost;
					this.filter.leadStatus = [];
					this.filter.leadStatusData = [];

					if (this.all_leadStatus) {
						this.leadStatus.forEach((data) => {
							this.filter.leadStatus.push(data.id);
							this.filter.leadStatusData.push(data);
						})
						this.wonAndLost.forEach((data) => {
							this.filter.leadStatus.push(data.id);
							this.filter.leadStatusData.push(data);
						})
					}
					bus.$emit('getallcustomers', {
						tableType: 0
					})

				})
				.catch(err => console.error("API ERROR", err));
		},
		getJobPeople() {
			axios.get('/jobpeople').then(response => {
				this.alljobpeople = response.data.result;
			})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		getCompanyManagers() {
			return axios.get('/company-admins').then(response => {
				this.allManagers = response.data.result;
			}).catch(err => console.log('getCompanyManagers', err));
		},
		getAllCustomers(type) {
			console.log('getall type', type);
			this.filter.search = '';
			this.filter.leadStatus = [];
			this.filter.leadStatusData = [];
			this.filter.status = [];
			this.filter.groupBy = ['none'];
			this.filter.responsibleName = '- All Employees -';
			this.filter.responsible = null;
			this.filter.createdDate = 'All time';
			this.filter.lastActivityDate = null;
			if (type == 0) {
				this.getLeadStatus();
			} else {
				bus.$emit('getallcustomers', {
					tableType: type
				})
			}
		},
		selectCreatedDate(date) {
			this.filter.createdDate = date
			this.filterTable();
		},
		selectLastActivityDate(date) {
			this.filter.lastActivityDate = date
			this.filterTable();
		},
		selectResponsible(data) {
			if (data == 'all') {
				this.filter.responsibleName = '- All Employees -'
				this.filter.responsible = 'all'
			} else {
				this.filter.responsibleName = data.name
				this.filter.responsible = data.id
			}

			this.filterTable();
		},
		onChange() {
			if (this.filter.search.length >= 3) {
				this.filterTable();
			}
		},
		showLeadCustomerAddModal(type) {
			// Send the account type to the add modal 0 = lead, 1 = customer
			this.accountType = type
			this.$modal.show('leadCustomerAddModal')
		},
		selectOnGroupBy(event, data) {
			this.filter.groupBy = []
			if (event.target.checked) {
				this.filter.groupBy.push(data);
			}
			this.filterTable();
		},
		selectOnStatus(event, data) {
			this.filterTable();
		},
		selectAll(type) {
			if (type == 'status') {
				this.filter.status = [];

				if (!this.all_status) {
					this.filter.status.push('1');
					this.filter.status.push('0');
				}
			} else {
				this.filter.leadStatus = [];
				this.filter.leadStatusData = [];

				if (!this.all_leadStatus) {
					this.leadStatus.forEach((data) => {
						this.filter.leadStatus.push(data.id);
						this.filter.leadStatusData.push(data);
					})
					this.wonAndLost.forEach((data) => {
						this.filter.leadStatus.push(data.id);
						this.filter.leadStatusData.push(data);
					})
				}
			}
			this.filterTable();
		},
		selectOnLeadStatus(event, data) {
			if (event.target.checked) {
				this.filter.leadStatusData.push(data);
			} else {

				this.filter.leadStatusData.forEach((d, idx) => {
					if (d.id == data.id) {
						this.filter.leadStatusData.splice(idx, 1);
					}
				})
			}
			this.filterTable();
		},
		filterTable() {
			bus.$emit('getallcustomers', {
				tableType: this.tableType,
				use_filter: true
			})
		},
		clearSearch() {
			this.filter.search = '';
			this.filterTable();
		},
		removeParams() {
			if (location.href.includes('?')) {
				history.pushState({}, null, location.href.split('?')[0]);
			}
		},
		returnNewCustomer(newCustomer) {
			// Redirect to new customer/lead view
			console.log('inside returnNewCustomer', newCustomer);

			if (newCustomer.leadOrCustomer == 1) {
				this.tableType = 0;
				this.getAllCustomers(0);
			} else {
				this.tableType = 1;
				this.getAllCustomers(1);
			}
			this.newCustomer = newCustomer
			this.$modal.hide('leadCustomerAddModal')
			// this.reloadKey++;			
		},
	}
}
</script>
