import Crews from '@/components/Admin/Crews/Crews'
import CalendarComponent from '@/components/CalendarComponent'
import ContactsComponent from '@/components/ContactsComponent'
import CustomerDetailComponent from '@/components/CustomerDetailComponent'
import JobEditComponent from '@/components/JobEditComponent.vue'
import JobsComponent from '@/components/JobsComponent'
import ProfileEdit from '@/components/Profile/ProfileEdit'
import TimesheetReportComponent from '@/components/TimesheetReportComponent'
import Assets from '@/pages/Assets'
import Reports from '@/pages/Reports'

import AccountingSettings from '@/components/Admin/Accounting/AccountingSettings.vue'
import CompanySettings from '@/components/Admin/Company/CompanySettings.vue'
import NotificationSettings from '@/components/Admin/Notifications/NotificationSettings.vue'
import TaskSettings from '@/components/Admin/Tasks/TaskSettings.vue'
import MyTimeOffRequest from '@/components/Admin/TimeoffRequests/MyTimeOffRequest'
import TimesheetSettings from '@/components/Admin/Timesheet/TimesheetSettings.vue'

import AssetItemTypes from '@/components/Admin/Assets/AssetSettings.vue'
import ContactLeadLabels from '@/components/Admin/Contacts/LeadsSettingsComponent.vue'
import JobTemplateEdit from '@/components/Admin/JobTemplates/JobTemplateEdit.vue'
import JobTemplates from '@/components/Admin/JobTemplates/JobTemplates.vue'
import JobTypes from '@/components/Admin/JobTypes/JobTypes.vue'
import SearchResultsComponent from '@/components/SearchResultsComponent.vue'
import Messages from '@/pages/Admin/Messages'
// import JobStatuses from '@/pages/Admin/JobStatuses'
import TaskTypes from '@/components/TaskTypes/TaskTypes'
// import TaskStatuses from '@/pages/Admin/TaskStatuses'
import InvoiceItemsComponent from '@/components/Admin/Accounting/InvoiceItemsComponent.vue'
import CustomFields from '@/components/Admin/CustomFields/CustomFields.vue'
import TimeoffRequestsComponent from '@/components/Admin/TimeoffRequests/TimeOffRequest'
import VendorDetailComponent from '@/components/Admin/Vendors/VendorDetailComponent.vue'
import Vendors from '@/components/Admin/Vendors/Vendors'
import Users from '@/pages/Admin/Users'
// import Settings from '@/pages/Admin/Settings'
import DataImport from '@/components/Admin/Settings/DataImport'
import AssetItemShow from '@/components/asset-management/AssetItemShow'
import AssetManCreateItemMain from '@/components/asset-management/AssetManItems/AssetManCreateItemMain'
import AssetItemEdit from '@/components/asset-management/AssetManItems/AssetManEditItemMain'

import PurchaseOrderCreate from '@/components/PurchaseOrders/PurchaseOrderCreate'
import PurchaseOrderEdit from '@/components/PurchaseOrders/PurchaseOrderEdit'
import PurchaseOrders from '@/components/PurchaseOrders/PurchaseOrdersComponent'
import PurchaseOrderView from '@/components/PurchaseOrders/PurchaseOrderView'

import VariablePurchaseOrderCreate from '@/components/VariablePurchaseOrders/VariablePurchaseOrderCreate'
import VariablePurchaseOrderEdit from '@/components/VariablePurchaseOrders/VariablePurchaseOrderEdit'
import VariablePurchaseOrders from '@/components/VariablePurchaseOrders/VariablePurchaseOrdersComponent'
import VariablePurchaseOrderView from '@/components/VariablePurchaseOrders/VariablePurchaseOrderView'

import VehicleAddComponent from '@/components/asset-management/vehicles/VehiclesAddComponent'
import VehicleShowComponent from '@/components/asset-management/vehicles/VehiclesShow'
import DailyRecapReportComponent from '@/components/Reports/DailyRecapComponent'
import NoCallNoShowReportComponent from '@/components/Reports/NoCallNoShowReportComponent'
import ReportInvoicesComponent from '@/components/Reports/ReportInvoicesComponent'
import TimeOffReportComponent from '@/components/TimeOffReportComponent'

// Field User Components
import FieldTaskCloseAllocation from '@/components/Field/FieldTaskCloseAllocation'
import FieldTasksComponent from '@/components/Field/FieldTasks'
import FieldTimeoffRequestsComponent from '@/components/Field/FieldTimeOffRequests'
import FieldWorkHistoryComponent from '@/components/Field/FieldWorkHistory'
import PayrollReport1099Component from '@/components/Reports/PayrollReport1099Component'
import PayrollReportComponent from '@/components/Reports/PayrollReportComponent'
import PayrollUserWeekComponent from '@/components/Reports/PayrollUserWeekComponent'

// agrements
import EndUserLicenseAgreement from '@/components/EndUserLicenseAgreement'
import PrivacyPolicyUrl from '@/components/PrivacyPolicyUrl'

const rootPath = ''
export const routes = [
    { path: `${rootPath}/end-user-license`, name: 'EndUserLicenseAgreement', component: EndUserLicenseAgreement },
    { path: `${rootPath}/privacy-policy`, name: 'PrivacyPolicyUrl', component: PrivacyPolicyUrl },

    // Main Pages
    // { path: `${rootPath}/companyManage`, name: 'companyManage', component: DashboardComponent, meta: { accessRole: 1 } },
    // { path: `${rootPath}/dashboard`, name: 'dashboard', component: DashboardComponent, meta: { accessRole: 2 } },
    { path: `${rootPath}/profile`, name: 'profile', component: ProfileEdit },
    { path: `${rootPath}/calendar`, name: 'calendar', component: CalendarComponent, meta: { accessRole: 2 } },
    { path: `${rootPath}/timesheet`, name: 'timesheet', component: TimesheetReportComponent, props: true, meta: { accessRole: 2 } },
    { path: `${rootPath}/jobs`, name: 'jobs', component: JobsComponent, meta: { accessRole: 2 } },
    { path: `${rootPath}/jobs/edit/:id/:taskId?`, name: 'jobEdit', component: JobEditComponent, props: true, meta: { accessRole: 2 } },
    { path: `${rootPath}/contacts`, name: 'contacts', component: ContactsComponent, meta: { accessRole: 2 } },
    { path: `${rootPath}/customer/edit/:customerId`, name: 'customer', component: CustomerDetailComponent, props: true, meta: { accessRole: 2 } },
    { path: `${rootPath}/assets`, name: 'assets', component: Assets, meta: { accessRole: 2 } },
    { path: `${rootPath}/reports`, name: 'reports', component: Reports, meta: { accessRole: 2 } },
    { path: `${rootPath}/globalSearch`, name: 'globalSearch', component: SearchResultsComponent, props: true, meta: { accessRole: 2 } },

    // ----- Admin ---------------
    // Settings
    { path: `${rootPath}/admin/settings/company`, name: 'companySettings', component: CompanySettings, meta: { accessRole: 2 } },
    { path: `${rootPath}/admin/settings/accounting`, name: 'accountingSettings', component: AccountingSettings, meta: { accessRole: 2 } },
    { path: `${rootPath}/admin/settings/timesheet`, name: 'timesheetSettings', component: TimesheetSettings, meta: { accessRole: 2 } },
    { path: `${rootPath}/admin/settings/tasks`, name: 'taskSettings', component: TaskSettings, meta: { accessRole: 2 } },
    { path: `${rootPath}/admin/settings/notifications`, name: 'notificationSettings', component: NotificationSettings, meta: { accessRole: 2 } },

    // Content Management
    // { path: `${rootPath}/admin/jobstatuses`, name: 'jobstatuses', component: JobStatuses, meta:{accessRole:2} }, 
    // { path: `${rootPath}/admin/taskstatuses`, name: 'taskstatuses', component: TaskStatuses, meta:{accessRole:2} }, 
    { path: `${rootPath}/admin/contentManagement/contactLeadLabels`, name: 'contactLeadLabels', component: ContactLeadLabels, meta: { accessRole: 2 } },
    { path: `${rootPath}/admin/contentManagement/assetItemTypes`, name: 'assetItemTypes', component: AssetItemTypes, meta: { accessRole: 2 } },
    { path: `${rootPath}/admin/contentManagement/customfields`, name: 'customfields', component: CustomFields, meta: { accessRole: 2 } },
    { path: `${rootPath}/admin/contentManagement/jobtypes`, name: 'jobtypes', component: JobTypes, meta: { accessRole: 2 } },
    { path: `${rootPath}/admin/contentManagement/jobTemplates`, name: 'jobTemplates', component: JobTemplates },
    { path: `${rootPath}/admin/contentManagement/jobTemplates/edit/:id`, name: 'jobTemplateEdit', component: JobTemplateEdit, props: true, meta: { accessRole: 2 } },
    { path: `${rootPath}/admin/contentManagement/tasktypes`, name: 'tasktypes', component: TaskTypes, meta: { accessRole: 2 } },
    { path: `${rootPath}/admin/contentManagement/invoiceItems`, name: 'invoiceItems', component: InvoiceItemsComponent, meta: { accessRole: 2 } },
    { path: `${rootPath}/admin/contentManagement/vendors`, name: 'vendors', component: Vendors, meta: { accessRole: 2 } },
    { path: `${rootPath}/admin/contentManagement/vendor/details/:id`, name: 'vendordetails', component: VendorDetailComponent, props: true, meta: { accessRole: 2 } },
    { path: `${rootPath}/admin/contentManagement/users`, name: 'users', component: Users, meta: { accessRole: 2 } },
    { path: `${rootPath}/admin/contentManagement/crews`, name: 'crews', component: Crews, meta: { accessRole: 2 } },

    // Tools
    // TODO: --- Billing -------
    { path: `${rootPath}/admin/tools/messages`, name: 'messages', component: Messages, meta: { accessRole: 2 } },
    { path: `${rootPath}/admin/tools/timeoffRequest`, name: 'timeoffRequest', component: TimeoffRequestsComponent, meta: { accessRole: 2 } },
    { path: `${rootPath}/admin/tools/myTimeOffRequest`, name: 'myTimeOffRequest', component: MyTimeOffRequest, meta: { accessRole: 2 } },
    { path: `${rootPath}/admin/tools/dataImport`, name: 'dataImport', component: DataImport, meta: { accessRole: 2 } },

    // ----- Assets ---------------
    { path: `${rootPath}/assets/create`, name: 'assetCreate', component: AssetManCreateItemMain, meta: { accessRole: 2 } },
    { path: `${rootPath}/assets/view`, name: 'assetItemView', component: AssetItemShow, props: true, meta: { accessRole: 2 } },
    { path: `${rootPath}/assets/edit`, name: 'assetItemEdit', component: AssetItemEdit, props: true, meta: { accessRole: 2 } },
    { path: `${rootPath}/vehicle/create`, name: 'vehicleCreate', component: VehicleAddComponent, meta: { accessRole: 2 } },
    { path: `${rootPath}/vehicle/view`, name: 'vehicleView', component: VehicleShowComponent, props: true, meta: { accessRole: 2 } },
    { path: `${rootPath}/vehicle/edit`, name: 'vehicleEdit', component: VehicleAddComponent, props: true, meta: { accessRole: 2 } },

    // ----- Purchase Orders --------------- 
    { path: `${rootPath}/supply-orders`, name: 'purchaseOrders', component: PurchaseOrders, meta: { accessRole: 2 } },
    { path: `${rootPath}/supply-order/create/:jobid`, name: 'purchaseOrderCreate', component: PurchaseOrderCreate, props: true, meta: { accessRole: 2 } },
    { path: `${rootPath}/supply-order/view/:id`, name: 'purchaseOrderView', component: PurchaseOrderView, props: true, meta: { accessRole: 2 } },
    { path: `${rootPath}/supply-order/edit/:id`, name: 'purchaseOrderEdit', component: PurchaseOrderEdit, props: true, meta: { accessRole: 2 } },

    // ----- VPOs ---------------
    { path: `${rootPath}/vpos`, name: 'variablePurchaseOrders', component: VariablePurchaseOrders, props: true, meta: { accessRole: 2 } },
    // { path: `${rootPath}/vpo/create`, name: 'variablePurchaseOrderCreate', component: VariablePurchaseOrderCreate, props: true, meta: { accessRole: 2 } },
    { path: `${rootPath}/vpo/create/:jobId`, name: 'variablePurchaseOrderCreate', component: VariablePurchaseOrderCreate, props: true, meta: { accessRole: 2 } },
    { path: `${rootPath}/vpo/view/:id`, name: 'variablePurchaseOrderView', component: VariablePurchaseOrderView, props: true, meta: { accessRole: 2 } },
    { path: `${rootPath}/vpo/edit/:id`, name: 'variablePurchaseOrderEdit', component: VariablePurchaseOrderEdit, props: true, meta: { accessRole: 2 } },

    // ----- Reports --------------- 
    { path: `${rootPath}/reports/timeSheetReport/:parent`, name: 'timeSheetReport', component: TimesheetReportComponent, props: true, meta: { accessRole: 2 } },
    { path: `${rootPath}/reports/timeOffReport`, name: 'timeOffReport', component: TimeOffReportComponent, meta: { accessRole: 2 } },
    { path: `${rootPath}/reports/dailyRecapReport`, name: 'dailyRecapReport', component: DailyRecapReportComponent, meta: { accessRole: 2 } },
    { path: `${rootPath}/reports/noShowReport`, name: 'noShowReport', component: NoCallNoShowReportComponent, meta: { accessRole: 2 } },
    { path: `${rootPath}/reports/invoiceReport`, name: 'invoiceReport', component: ReportInvoicesComponent, meta: { accessRole: 2 } },
    { path: `${rootPath}/reports/payrollReport`, name: 'payrollReport', component: PayrollReportComponent, props: true, meta: { accessRole: 2 } },
    { path: `${rootPath}/reports/payroll1099Report`, name: 'payroll1099Report', component: PayrollReport1099Component, props: true, meta: { accessRole: 2 } },
    { path: `${rootPath}/reports/payrollUserWeek`, name: 'payrollUserWeek', component: PayrollUserWeekComponent, props: true, meta: { accessRole: 2 } },

    // ----- Field User Routes
    { path: `${rootPath}/fieldTasks`, name: 'fieldTasks', component: FieldTasksComponent },
    { path: `${rootPath}/fieldWorkHistory`, name: 'fieldWorkHistory', component: FieldWorkHistoryComponent },
    { path: `${rootPath}/fieldTimeoffRequests`, name: 'fieldTimeoffRequests', component: FieldTimeoffRequestsComponent },
    { path: `${rootPath}/fieldTaskCloseAllocate`, name: 'fieldTaskCloseAllocate', component: FieldTaskCloseAllocation, props: true },
]