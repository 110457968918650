<template>
    <div>
        <div class="modal-content">
            <div class="modal-header border-bottom">
                <h5 class="modal-title">
                    <span v-if="jobFile.display_name">{{ jobFile.display_name }}</span>
                    <span v-else>{{ jobFile.filename }}</span>
                </h5>
                <button type="button" class="close" @click="$emit('cancelFnc')">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-8 col-12 p-4 text-center" style="background: #F6F9FC;">
                        <div v-if="jobFile.fileType == 'pdf' || jobFile.fileType == 'xlsx' || jobFile.fileType == 'pptx' || jobFile.fileType == 'docx' || jobFile.fileType == 'doc' || jobFile.fileType == 'txt' || jobFile.fileType == 'rtf' || jobFile.fileType == 'heic' || jobFile.fileType == 'tiff'">
                            <div class="w-100">
                                <img class="w-25" src="/images/icons/other-file.png" alt="Card image cap">
                            </div>
                            <div class="pt-3 pb-4 w-100">{{ $t('label.Preview unavailable for this file type') }}.</div>
                            <!-- <a class="btn btn-primary" :href="'/uploads/' + jobFile.filename" download> -->
                            <a class="btn btn-primary text-white" @click="$downloadFile(jobFile.filename_path)">
                                <i class="ri-download-line pr-2"></i>
                                {{ $t('label.download') }}
                            </a>
                        </div>
                        <img v-else class="mw-100 mh-70vh" :src="jobFile.filename_path" alt="Card image cap">
                    </div>
                    <div class="col-lg-4 col-12 p-4">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-name">{{ $t('label.file name') }}</label>
                                    <input v-model="jobFile.name" type="text" name="job_name" class="form-control" required />
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-name">{{ $t('label.related task') }}</label>
                                    <select class="form-control" v-model="jobFile.task_id">
                                        <option v-for="(task, index) in tasks" :value="task.id" :key="index">{{ task.name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-name">{{ $t('label.file description') }}</label>
                                    <textarea v-model="jobFile.description" name="description" class="form-control" placeholder="Description" required autofocus></textarea>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.uploaded by') }}</label>
                                    <div class="input-value">
                                        <img class="rounded-circle" :src="jobFile.created_by.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                        <span class="px-2">{{ jobFile.created_by.name }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.uploaded date') }}</label>
                                    <div class="input-value">
                                        {{ jobFile.created_at | formatDate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-right px-3">
                            <button type="submit" class="btn btn-default" @click="updateFileDetails()">{{ $t('label.save') }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-top d-flex justify-content-between">
                <!-- <a class="btn btn-primary" :href="'/uploads/' + jobFile.filename" download> -->
                <a class="btn btn-primary text-white" @click="$downloadFile(jobFile.filename_path)">
                    <i class="ri-download-line pr-2"></i>
                    {{ $t('label.download') }}
                </a>
                <button type="button" class="btn btn-link  ml-auto close2editfile" @click="$emit('cancelFnc')">{{ $t('label.close') }}</button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['jobFile', 'tasks', 'index'],
    components: {
    },
    emits: ['cancelFnc', 'returnFunction'],
    data() {
        return {
            updatedFileData: {
                selectedTask: {}
            },
            isLoading: false,
        }
    },
    mounted() {
        this.editModal(this.jobFile);
    },
    methods: {
        editModal(data) {
            console.log('edit data', data);
            this.isLoading = true;
            if (this.jobFile.display_name) {
                this.jobFile.name = this.jobFile.display_name
            } else {
                this.jobFile.name = this.jobFile.filename;
            }
            // this.jobFile.task_id = data.task_id;
            // this.jobFile.task = this.tasks[this.index];

            this.isLoading = false;
        },
        updateFileDetails() {
            this.isLoading = false;
            console.log('task-id', this.jobFile.task_id);
            console.log('modal save update', this.jobFile);

            this.jobFile.task_id = this.jobFile.task_id == null ? null : this.jobFile.task_id;
            // this.jobFile.task_id = this.jobFile.task_id == 0 ? null : this.jobFile.task_id;
            this.jobFile.name = this.jobFile.name.split('.')[0] + '.' + this.jobFile.fileType;

            console.log('task', this.jobFile.task);

            // this.jobFile.task = this.jobFile.task
            axios.post('/jobfiles/update', this.jobFile)
                .then(response => {
                    console.log('update response', response);
                    this.viewFileModal(this.jobFile.id);
                    this.$emit('returnFunction');
                    this.isLoading = false;

                }).catch(err =>
                    console.log('Error Updating File Data', err));
        },
        viewFileModal(id) {
            this.jobFileId = id;
        },
    },
}

</script>
