<template>
    <div class="">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <!-- Card Label -->
                    <div class="col-6">
                        <h3 class="d-flex align-items-center mb-0">
                            <i class="ri-draft-line"></i> {{ $t('label.vpo notes') }}
                        </h3>
                    </div>
                    <!-- Add Note Button -->
                    <div class="col-6 text-right">
                        <button type="button" class="btn btn-sm btn-default m-0" @click="showAddorEditNotesModal('add')">
                            {{ $t('label.add note') }}
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="vpoNotes.length == 0">
                <div class="card-body m-0 py-0">
                    <div class="bg-white text-center row align-items-center m-0">
                        <i class="ri-draft-line ni-8x w-100 color-gray-400"></i>
                        <p class="w-100 font-weight-normal">{{ $t('label.no notes have been added to this vpo') }}.</p>
                    </div>
                </div>
            </div>
            <div v-if="vpoNotes.length > 0" class="card-body p-0">
                <!-- List group -->
                <div class="list-group list-group-flush">
                    <div v-if="showLess">
                        <div class="list-group-item list-group-item-action py-4 px-4" v-for="(item, index) in vpoNotes.slice(0, 5)" v-bind:key="index">
                            <div class="row align-items-center justify-content-between m-0">
                                <div class="col p-0 m-0 d-flex align-items-center">
                                    <div class="cursor-pointer mr-2" @click="getUserInfo(item.created_by.id, 'Created By')">
                                        <img class="rounded-circle" :src="item.created_by.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                    </div>
                                    <h4 class="cursor-pointer m-0 mr-2" @click="getUserInfo(item.created_by.id, 'Created By')">{{ item.created_by.name }}</h4>
                                    <h4 class="font-weight-normal m-0 mr-2">{{ item.created_at }}</h4>
                                </div>
                                <div class="col-auto p-0 m-0 d-flex">
                                    <a class="pointer text-primary font-weight-normal mr-3" @click="showAddorEditNotesModal('edit', item)">{{ $t('label.edit') }}</a>
                                    <a class="pointer text-primary font-weight-normal" @click="setSelectedNote(item); toggleDeleteModal(true)">{{ $t('label.delete') }}</a>
                                </div>
                            </div>
                            <p class="text-sm mb-0 ml-5 mt-3 font-weight-normal pre-wrap" v-html="item.notes"></p>
                        </div>
                    </div>
                    <div v-else>
                        <div class="list-group-item list-group-item-action py-3 px-4" v-for="(item, index) in vpoNotes" v-bind:key="index">
                            <div class="row align-items-center justify-content-between m-0">
                                <div class="col p-0 m-0 d-flex align-items-center">
                                    <div class="cursor-pointer mr-2" @click="getUserInfo(item.created_by.id, 'Created By')">
                                        <img class="rounded-circle" :src="item.created_by.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                    </div>
                                    <h4 class="cursor-pointer m-0 mr-2" @click="getUserInfo(item.created_by.id, 'Created By')">{{ item.created_by.name }}</h4>
                                    <h4 class="font-weight-normal m-0 mr-2">{{ item.created_at }}</h4>
                                </div>
                                <div class="col-auto p-0 m-0 d-flex">
                                    <a class="pointer text-primary font-weight-normal" @click="showAddorEditNotesModal('edit', item)">{{ $t('label.edit') }}</a>
                                    <a class="pointer text-primary font-weight-normal" @click="setSelectedNote(item); toggleDeleteModal(true)">{{ $t('label.delete') }}</a>
                                </div>
                            </div>
                            <p class="text-sm mb-0 ml-5 mt-3 font-weight-normal pre-wrap" v-html="item.notes"></p>
                        </div>
                    </div>
                    <button class="btn btn-white text-primary mr-auto ml-auto my-4 d-flex" v-if="vpoNotes.length > 5 && showLess" @click="showLess = false">
                        {{ $t('label.view more') }}
                    </button>
                    <button class="btn btn-white text-primary mr-auto ml-auto my-4 d-flex" v-if="vpoNotes.length > 5 && !showLess" @click="showLess = true">
                        {{ $t('label.view less') }}
                    </button>
                </div>
            </div>
        </div>

        <!-- Add or Edit Notes Modal -->
        <modal class="py-4" name="AddorEditNotesModal" height="auto" width="100%" :max-width="1000" :scrollable="true" :reset="true" :adaptive="true">
            <AddEditNoteModal :vpo-id="vpoId" :modal-type="modalType" :modal-note="modalNote" @reload="getVpoNotes" @cancelFnc="hideAddorEditNotesModal"></AddEditNoteModal>
        </modal>

        <!-- MODAL FOR DELETE -->
        <div class="modal fade" id="deleteModal1_po" tabindex="-1" role="dialog" aria-labelledby="deleteModal1" aria-hidden="true">
            <div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
                <div class="modal-content bg-gradient-danger">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="deleteJobNote">
                        <div class="modal-body">
                            <div class="py-3 text-center">
                                <i class="ri-error-warning-line ni-3x"></i>
                                <h4 class="heading mt-4 text-uppercase">{{ $t('label.ARE YOU SURE YOU WANT TO DELETE THIS NOTE') }}?</h4>
                                <p>{{ $t('label.This action cannot be undone') }}!</p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-white">{{ $t('label.Yes, Delete') }}</button>
                            <button type="button" class="btn btn-link text-white ml-auto" data-dismiss="modal">{{ $t('label.cancel') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- vpo Cancel -->
        <ModalDeleteComponent v-if="showDltPOModal" :newId="'VpoNote_view'" :title="'ARE YOU SURE YOU WANT TO DELETE THIS NOTE'" @onClose="toggleDeleteModal()" @customDeleteMethod="deleteVpoNote()">
        </ModalDeleteComponent>
    </div>
</template>

<script>
import ModalDeleteComponent from '@/components/ModalDelete2.vue';
import AddEditNoteModal from '@/components/VariablePurchaseOrders/VpoNotes/AddEditNotesModal.vue';
import bus from '@/utils/event-bus';

export default {
    props: ['vpoId', 'vpoStatus'],
    components: {
        AddEditNoteModal,
        ModalDeleteComponent
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            vpoNotes: [],
            modalType: null,
            modalNote: null,
            selectedNote: null,
            showLess: true,
            showDltPOModal: false,
        }
    },
    mounted() {
        this.getVpoNotes()
    },
    methods: {
        getVpoNotes() {
            axios.get(`/get-vpo-notes/` + this.vpoId).then(resp => {
                this.vpoNotes = resp.data.result;
            }).catch(err => console.log(err));
        },

        showAddorEditNotesModal(modalType, data = null) {
            this.modalType = modalType;
            this.modalNote = data;
            this.$modal.show('AddorEditNotesModal');
        },
        hideAddorEditNotesModal() {
            this.$modal.hide('AddorEditNotesModal');
        },

        selectedName(data) {
            if (this.findArray(data.id) === null)
                this.selectedNames.push(data);
        },
        findArray(value) {
            let countLayer = this.selectedNames.length;
            if (countLayer > 0) {
                for (var x = 0; x < countLayer; x++) {
                    if (this.selectedNames[x].id === value)
                        return this.selectedNames[x];
                }
            }
            return null;
        },
        toggleDeleteModal(status) {
            this.showDltPOModal = status;
        },
        setSelectedNote(item) {
            this.selectedNote = item;
        },
        deleteVpoNote() {
            if (this.selectedNote.id) {
                axios.post('/delete-vpo-note', { id: this.selectedNote.id }).then(() => {
                    this.getVpoNotes();
                    bus.$emit('banner-success', {
                        message: $t('label.vpo note deleted successfully')
                    });
                })
            }
        },
    }
}
</script>
