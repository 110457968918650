
const namespaced = true;

const state = {
    countryList: [],
};

const getters = {
    countryList: state => state.countryList,
};

const actions = {
    getCountryList({ state, commit, rootState, dispatch }) {
        return new Promise((resolve, reject) => {
            axios
                .get('/get-countries')
                .then(res => {
                    commit('setCountryList', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
}

const mutations = {
    setCountryList(state, data) {
        state.countryList = data;
    },
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
};