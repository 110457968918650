<template>
	<div class="page-timesheet">
		<div class="header bg-primary pb-6 mobile-p-0">
			<div class="container-fluid">
				<div class="header-body">
					<div class="row align-items-center py-4">
						<div class="col-lg-6 col-7">
							<breadcrumbs :title="$t('label.reports')">
								<!-- Breakcrumb slots -->
								<li class="breadcrumb-item">
									<a class="text-primary pointer" @click="$router.go(-1)">{{ $t('label.reports') }}</a>
								</li>
								<li class="breadcrumb-item">
									{{ $t('label.daily recap') }}
								</li>
							</breadcrumbs>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid mt--6" :class="isMobile() ? 'mt-140p' : ''">
			<div class="row">
				<div class="col">
					<div class="card">
						<div class="card-header">
							<div class="row align-items-center">
								<div class="w-100 d-flex justify-content-between align-items-center">
									<h3 class="mb-0"><i class="far fa-clock"></i> {{ $t('label.timesheet report') }}</h3>
								</div>
							</div>
						</div>
						<div class="card-body">
							<div class="row mb-4">
								<div class="col-12">
									<label class="form-control-label">{{ $t('label.select date') }}</label>
								</div>
								<div class="col-2 col-xs-12">
									<input name="recap_date" id="recap_date" v-model="recap_date" class="form-control datepicker" data-date-format="mm/dd/yyyy" :placeholder="$t('label.select start date')" required autocomplete="off">
								</div>
								<div class="col-2 col-xs-12">
									<button type="button" class="btn btn-default" @click="generateReport()">GENERATE REPORT</button>
								</div>
							</div>
							<div class="row">
								<div class="col-6 col-xs-12">
									<div class="card shadow-none">
										<div class="card-header border-0 px-0">
											<div class="row align-items-center">
												<div class="col">
													<h3 class="mb-0">Timesheet Summary</h3>
												</div>
											</div>
										</div>
										<div class="table-responsive">
											<table class="table align-items-center table-flush">
												<thead class="thead-light">
													<tr>
														<th scope="col">Employee</th>
														<th scope="col" class="text-right">HRS. Clocked Yesterday</th>
														<th scope="col" class="text-right">Weekly Hrs.</th>
													</tr>
												</thead>
												<tbody class="border-bottom">
													<tr v-for="(data, key) in timesheet_summary" :key="key">
														<th scope="row">{{ data.name }}</th>
														<td class="text-right">{{ data.sum_duration }}</td>
														<td class="text-right">{{ data.sum_duration_week }}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div class="col-6 col-xs-12">
									<div class="card shadow-none">
										<div class="card-header border-0 px-0">
											<div class="row align-items-center">
												<div class="col">
													<h3 class="mb-0">Actions Summary</h3>
												</div>
											</div>
										</div>
										<div class="table-responsive">
											<table class="table align-items-center table-flush">
												<thead class="thead-light">
													<tr>
														<th scope="col">Action</th>
														<th scope="col" class="text-right">Count</th>
													</tr>
												</thead>
												<tbody class="border-bottom">
													<tr>
														<th scope="row">Jobs Closed</th>
														<td class="text-right">{{ jobsClosed }}</td>
													</tr>
													<tr>
														<th scope="row">New Jobs</th>
														<td class="text-right">{{ newJobs }}</td>
													</tr>
													<tr>
														<th scope="row">Completed Tasks</th>
														<td class="text-right">{{ completedTasks }}</td>
													</tr>
													<tr>
														<th scope="row">New Tasks</th>
														<td class="text-right">{{ newTasks }}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-6 col-xs-12">
									<label class="form-control-label">
										See the <a href="/reports/timesheet-report" style="color: #fdb658">Timesheet Report</a> for details on individuals timesheet entries
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import moment from '@/utils/date-time';
import * as $ from 'jquery';
export default {
	props: ['jobid'],
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
			companySettings: JSON.parse(localStorage.getItem('company_settings')),
			recap_date: null,
			timesheet_summary: [],
			jobsClosed: 0,
			newJobs: 0,
			completedTasks: 0,
			newTasks: 0,
		}
	},
	created() {
		moment.tz.setDefault(this.user.timezone);
		this.recap_date = moment().subtract(1, 'days').format('MM/DD/YYYY');
	},
	mounted() {
		this.timesheetSummary()

	},
	methods: {
		timesheetSummary() {
			let params = {
				recap_date: this.recap_date
			}
			axios.post('/timesheet-summary', params)
				.then(response => {
					this.timesheet_summary = response.data.result;
					this.jobsClosed = response.data.jobsClosed;
					this.newJobs = response.data.newJobs;
					this.completedTasks = response.data.completedTasks;
					this.newTasks = response.data.newTasks;
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		generateReport() {
			this.recap_date = $('#recap_date').val()
			this.timesheetSummary()
		},
		isMobile() {
			return $(document).width() <= 768;
		},
	}
}
</script>
