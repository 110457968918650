<template>
    <div>
        <!-- Search Section -->
        <div class="">
            <div class="row">
                <!-- Start Date -->
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="filter-start-date">{{ $t('label.start date') }}</label>
                        <date-picker v-model="filter.startDate" type="date" format="MM/DD/YYYY" class="full-width" input-class="form-control" :clearable="false" placeholder="Select date"></date-picker>
                    </div>
                </div>
                <!-- End Date -->
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="filter-end-date">{{ $t('label.end date') }}</label>
                        <date-picker v-model="filter.endDate" type="date" format="MM/DD/YYYY" class="full-width" input-class="form-control" :clearable="false" placeholder="Select date"></date-picker>
                    </div>
                </div>
                <!-- Employee Id -->
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="filter-employee">{{ $t('label.employee') }}</label>
                        <select id="filter-employee" class="form-control" v-model="filter.employeeId" autofocus>
                            <option value="0">- {{ $t('label.all employees') }} -</option>
                            <option v-for="(data, index) in employees" :value="data.id" :key="index">{{ data.name }}</option>
                        </select>
                    </div>
                </div>

                <!-- Status Filter -->
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="filter-group">{{ $t('label.status') }}</label>
                        <select id="filter-group" class="form-control" v-model="filter.timeOffStatus" autofocus>
                            <option value="">- {{ $t('label.all statuses') }} -</option>
                            <option :value="status" v-for="(status, index) in timeOffStatuses" :key="index">{{ status }} {{ $t(`label.${status}`) }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="text-left col-sm-6">
                    <button type="button" class="btn btn-primary" @click="getAllTimeOffs();">
                        {{ $t('label.search time off report') }}
                    </button>
                    <button type="button" class="btn btn-link ml-auto" @click="clearSearch()">
                        {{ $t('label.clear search') }}
                    </button>
                </div>
                <div class="col-sm-6 row test-right">
                    <div class="col-sm-6">
                        <h3 class="mb-0">{{ $t('label.search results') }} ({{ timeOffs.length }})</h3>
                    </div>

                    <!-- CSV Export -->
                    <div class="col-sm-6 text-right">
                        <export-excel v-if="timeOffs.length" class="btn btn-default" :fetch="timeOffDataForExport" :fields="export_fields" worksheet="Time Off Report" type="csv" :name="exportDownloadName">
                            {{ $t('label.export results') }}
                        </export-excel>
                    </div>
                </div>
            </div>
            <div class="dropdown-divider"></div>
        </div>

        <!-- Main time off report list -->
        <div class="table-responsive">
            <!-- Display the records -->
            <div class="pt-2" v-if="recordCnt === 1">
                <table class="table table-flush table-sm">
                    <thead class="thead-light">
                        <tr>
                            <th class="col-sm-3">User/Date(s)</th>
                            <th class="col-sm-3">{{ $t('label.reason') }}</th>
                            <th class="col-sm-2">{{ $t('label.status') }}</th>
                            <th class="col-sm-3">{{ $t('label.notes') }}</th>
                            <th class="col-sm-1"></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(item, idx) in timeOffs" :key="item.id" :class="{ 'bg-gray-100': idx % 2 !== 0 }">
                            <!-- User and Dates -->
                            <td>
                                <!-- User name -->
                                <div class="font-weight-bold">{{ getEmployeeName(item.user_id) }}</div>
                                <!-- Date Range -->
                                <div class="">{{ calulateTimeOffDates(item) }}</div>
                            </td>

                            <!-- Reason -->
                            <td>{{ item.description }}</td>

                            <!-- Status -->
                            <td class="text-center">
                                <span class="badge p-2" :class="{ 'badge-warning': item.status === 'Pending', 'badge-danger': item.status === 'Denied', 'badge-success': item.status === 'Approved', 'badge-dark': item.status === 'Cancelled' }" style="font-size: 12px;">{{ item.status }}</span>
                            </td>

                            <!-- Manager Notes -->
                            <td>
                                <div v-if="item.manager_user_id">
                                    <div>
                                        {{ item.manager_notes }}
                                    </div>
                                    <div class="text-muted">
                                        {{ getEmployeeName(item.manager_user_id) }}
                                        {{ moment(item.manager_action_date).format('MM/DD/YYYY hh:mm a') }}
                                    </div>
                                </div>
                            </td>

                            <!-- Menu items -->
                            <td class="text-right">
                                <div class="dropdown" v-if="(item.status == 'Pending' || item.status == 'Approved')">
                                    <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-ellipsis-v"></i>
                                    </a>

                                    <!-- Pending requests menu items -->
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" 
                                    v-if="item.status === 'Pending' && item.user_id !== user.id">
                                        <!-- Approve Request -->
                                        <a class="dropdown-item pointer" :data-id="item.id" 
                                        @click="confirmRequestStatusChange(item, 1)">
                                            <i class="ri-thumb-up-line text-success"></i>&nbsp;{{ $t('label.approve time off request') }}
                                        </a>
                                        <!-- Deny Request -->
                                        <a class="dropdown-item pointer" @click="confirmRequestStatusChange(item, 0)">
                                            <i class="ri-thumb-down-line text-danger"></i>&nbsp;{{ $t('label.deny time off request') }}
                                        </a>
                                    </div>

                                    <!-- Approved requests can be cancelled by user that created them -->
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                        <!-- Cancel Request -->
                                        <a class="dropdown-item pointer" :disabled="item.status !== 'Approved' && item.added_user_id !== user.id" :data-id="item.id" @click="confirmRequestStatusChange(item, 2)">
                                            <i class="ri-error-warning-line text-warning"></i>&nbsp;{{ $t('label.cancel time off request') }}
                                        </a>
                                    </div>
                                </div>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- If no records, display message -->
            <div v-else class="text-center">
                <div v-if="recordCnt === 2">
                    No Results to Display
                </div>
                <div v-if="recordCnt === 0">Loading Data...</div>
            </div>

            <!-- Approve Request modal -->
            <modal name="requestApproveModal" height="auto" :reset="true" :adaptive="true">
                <time-off-approve :item="selectedRequest" @returnChanges="returnStatusChange"></time-off-approve>
            </modal>

            <!-- Deny request  Modal -->
            <modal name="requestDenyModal" height="auto" :reset="true" :adaptive="true">
                <time-off-deny :item="selectedRequest" @returnChanges="returnStatusChange"></time-off-deny>
            </modal>

            <!-- Cancel Approved Request -->
            <modal name="requestCancelModal" height="auto" :reset="true" :adaptive="true">
                <time-off-cancel :item="selectedRequest" @returnChanges="returnStatusChange"></time-off-cancel>
            </modal>
        </div>
    </div>
</template>

<script>
import TimeOffApprove from '@/components/TimeOffRequests/TimeOffApprove';
import TimeOffRequestEmployee from '@/components/TimeOffRequestEmployeeComponent';
import TimeOffCancel from '@/components/TimeOffRequests/TimeOffCancel';
import TimeOffDeny from '@/components/TimeOffRequests/TimeOffDeny';

import moment from '@/utils/date-time';
import { mapGetters } from 'vuex';

export default {
    components: {
	    TimeOffRequestEmployee,
        TimeOffApprove,
        TimeOffCancel,
        TimeOffDeny
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            selectedRequest: null,
            timeOffs: [],
            loader: null,
            recordCnt: 0,
            dataForExport: [],
            employees: [],
            timeOffStatuses: [],
            filter: {
                startDate: moment().toDate(),
                endDate: moment().add('120', 'days').toDate(),
                employeeId: 0,
                timeOffStatus: '',
            },
            timeOffsCount: 0,
            exportDownloadName: null,
            export_fields: {
                'EMPLOYEE': 'employee',
                'REQUESTED DATE(S)': 'dates',
                'STATUS': 'status',
                'REASON': 'reason',
                'MANAGER': 'manager',
                'MANAGER NOTES': 'manager_notes',
            },
            exportData: []
        }
    },
    computed: {
        ...mapGetters(['users']),
    },
    created() {
        this.loader = this.$loading.show();
    },
    mounted() {
        this.getAllTimeOffs();
        this.loadStatuses();
        this.employees = this.users.allemployees;
    },
    methods: {
        confirmRequestStatusChange(item, action) {
            // Reset the error messages
            this.selectedRequest = item;

            //Show confirmation modals
            // Approve request modal
            if (action === 1) {
                this.$modal.show('requestApproveModal');
            }
            // Cancel approved request
            else if (action === 2) {
                this.$modal.show('requestCancelModal');
            }
            //Deny request modal
            else {
                this.$modal.show('requestDenyModal');
            }
        },
        returnStatusChange(response) {
            // update the request object
            let idx = this.timeOffs.findIndex(request => {
                if (request.id === response.id) {
                    return true;
                }
            });
            this.timeOffs[idx]['manager_user_id'] = response.manager_user_id;
            this.timeOffs[idx]['manager_notes'] = response.manager_notes;
            this.timeOffs[idx]['manager_action_date'] = response.manager_action_date;
            this.timeOffs[idx]['status'] = response.status;
        },
        getEmployeeName(userId) {
            let companyUsers = this.users.allemployees;
            let idx = companyUsers.findIndex(user => {
                if (user.id === parseInt(userId)) {
                    return true;
                }
            });
            return (idx >= 0) ? companyUsers[idx].name : 'N/A';
        },
        getAllTimeOffs() {
            axios.post('/get-time-offs-report', this.filter
            ).then(res => {
                this.loader.hide();
                this.timeOffs = res.data;
                this.recordCnt = (res.data.length > 0) ? 1 : 2;
            }).catch(err => {
                console.log(err)
            });
        },
        clearSearch() {
            this.filter.startDate = moment().toDate();
            this.filter.endDate = moment().add('120', 'days').toDate();
            this.filter.employeeId = 0;
            this.filter.timeOffStatus = '';
        },
        loadStatuses() {
            axios
                .get('/loadTimeOffStatuses')
                .then(response => {
                    this.timeOffStatuses = response.data;
                })
                .catch(err => {
                    console.log('ERROR: ', err);
                });
        },
        calulateTimeOffDates(item) {
            let timeOffDate = null;

            // Date Range
            if (item.start_date !== item.end_date) {
                if (moment(item.start_date).hour() > 0) {
                    timeOffDate = moment(item.start_date).format('MM/DD/YYYY hh:mm a');
                }
                else {
                    timeOffDate = `${moment(item.start_date).format('MM/DD/YYYY')} ${this.$t('label.all day')}`;
                }

                if (moment(item.end_date).hour() > 0) {
                    timeOffDate += ` - ${moment(item.end_date).format('MM/DD/YYYY hh:mm a')}`;
                }
                else {
                    timeOffDate += ` - ${moment(item.end_date).format('MM/DD/YYYY')} ${this.$t('label.all day')}`;
                }
            }
            // Same start and end Dates
            else {
                if (moment(item.start_date).hour() > 0 && moment(item.end_date).hour() > 0) {
                    timeOffDate = `${moment(item.start_date).format('MM/DD/YYYY hh:mm a')} - ${moment(item.end_date).format('hh:mm a')}`;
                }
                else {
                    timeOffDate = `${moment(item.start_date).format('MM/DD/YYYY')} ${this.$t('label.all day')}`;
                }
            }
            return timeOffDate;
        },
        timeOffDataForExport() {
            let data = [];
            this.exportDownloadName = `Time_Off_Report_${moment().format('x')}.csv`;
            this.timeOffs.forEach((item) => {
                let newItem = {
                    employee: this.getEmployeeName(item.user_id),
                    dates: this.calulateTimeOffDates(item),
                    status: item.status,
                    reason: item.description,
                    manager: this.getEmployeeName(item.manager_user_id),
                    manager_notes: item.manager_notes,
                }
                data.push(newItem);
            });
            return data;
        },
    },
}
</script>
