<template>
    <div class="card">
        <div class="card-header">
            <h3 class="d-flex align-items-center mb-0">
                <i class="ri-road-map-line"></i> {{ trans('label.billing address') }}
            </h3>
        </div>
        <div class="card-body">
            <div class="row m-0">
                <!-- Billing and Shipping Address -->
                <div v-for="(data, index) in vpoAddress" :key="index" class="col-6 p-0">
                    <label class="form-control-label text-muted">{{ data.address_type == 'billing' ? trans('label.billing address') : trans('label.shipping address') }}</label>
                    <div class="row align-items-center pt-3">
                        <div class="display_address col-auto">
                            <div v-if="data.type == 'store_location'">
                                <div class="address">{{ trans('label.store location') }}</div>
                            </div>
                            <div v-else>
                                <div class="address">{{ data.address_1 }}</div>
                                <div v-if="data.address_2" class="address">{{ data.address_2 }}</div>
                                <div class="address">{{ data.city }}, {{ data.state }} {{ data.postal_code }}</div>
                            </div>
                        </div>
                        <!-- Link to Google Maps -->
                        <i @click="goToAddress(data)" class="ri-external-link-line heading-title pointer"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['vpoAddress'],
    data() {
        return {}
    },
    mounted() { },
    methods: {
        goToAddress(data) {
            let address = data;
            let complete_address = address.address_1 + ", " + address.city + ", " + address.state + ", " + address.postal_code;
            window.open('http://maps.google.com/?q=' + complete_address);
        }
    },
}
</script>
