<template>
    <div class="">
        <table class="table table-flush table-sm" id="datatable-reportallinvoices">
            <thead class="thead-light">
                <tr>
                    <th scope="col" width="10%">{{ $t('label.invoice #') }}</th>
                    <th scope="col" width="10%">Quickbooks Invoice No.</th>
                    <th scope="col" width="10%">{{ $t('label.due date') }}</th>
                    <th scope="col">{{ $t('label.customer') }}</th>
                    <th scope="col">{{ $t('label.job') }}</th>
                    <th scope="col" class="text-right" width="10%">{{ $t('label.invoice total') }}</th>
                    <th scope="col" class="text-right" width="10%">{{ $t('label.balance') }}</th>
                    <th scope="col" width="10%">{{ $t('label.status') }}</th>
                </tr>
            </thead>
            <template v-if="(!groupbyfilter || groupbyfilter === 'none') && clickSearch">
                <tbody>
                    <tr v-for="(data, index) in invoices" :key="index">
                        <td>
                            <a href="#" class="" @click="invoiceViewModal(data)">
                                {{ data.invoice_number_concat }}
                            </a>
                        </td>
                        <td><a v-if="data.qb_id" target="_blank" :href="qb_url+'app/invoice?txnId='+data.qb_id">{{ data.quickbooks_invoice_no }}</a></td>
                        
                        <td :style="{ background: bgColors(data) }">{{ data.due_date }}</td>
                        <td style="color:#005486" class="cursor-pointer2" @click="routeToCustomerView(data)">
                            {{ data.customer.name }}
                        </td>
                        <td style="color:#005486" class="cursor-pointer2" @click="routeToJobView(data)">
                            {{ data.job.job_name }}
                        </td>
                        <td class="text-right">${{ parseFloat(data.total).toFixed(2) | formatThousands }}</td>
                        <td class="text-right">${{ parseFloat(data.balance).toFixed(2) | formatThousands }}</td>
                        <td>
                            <span class="badge badge-dot">
                                <i class="bg-success" :style="{ 'background-color': data.invoice_status.color + ' !important' }"></i>
                                {{ data.invoice_status.name }}
                            </span>
                        </td>
                    </tr>

                </tbody>
                <thead class="ttotal">
                    <tr>
                        <th scope="col" colspan="4" class="text-right">
                            <h6 class="mb-0">{{ $t('label.totals') }}</h6>
                        </th>
                        <th scope="col" class="text-right">
                            <h4 class="mb-0">${{ invoice_total.toFixed(2) | formatThousands }}</h4>
                        </th>
                        <th scope="col" class="text-right">
                            <h4 class="mb-0">${{ invoice_balance.toFixed(2) | formatThousands }}</h4>
                        </th>
                        <th scope="col" class="text-right"></th>
                    </tr>
                </thead>
            </template>
            <template v-else-if="groupbyfilter && clickSearch">
                <tbody v-for="(groupInvoices, groupName) in invoices" :key="groupName">
                    <tr>
                        <td>
                            <a href="#" class="" @click="invoiceViewModal(groupInvoices)">
                                {{ groupInvoices.invoice_number_concat }}
                            </a>
                        </td>
                        <td :style="{ background: bgColors(groupInvoices) }">{{ groupInvoices.due_date }}</td>
                        <td>{{ groupInvoices.customer.name }}</td>
                        <td>{{ groupInvoices.job.job_name }}</td>
                        <td class="text-right">${{ parseFloat(groupInvoices.total).toFixed(2) | formatThousands }}</td>
                        <td class="text-right">${{ parseFloat(groupInvoices.balance).toFixed(2) | formatThousands }}</td>
                        <td class="text-right">
                            <span class="badge badge-dot">
                                <i class="bg-success" :style="{ 'background-color': groupInvoices.invoice_status.color + ' !important' }"></i>
                                {{ groupInvoices.invoice_status.name }}
                            </span>
                        </td>
                    </tr>
                </tbody>
                <thead class="ttotal">
                    <tr>
                        <th scope="col" colspan="4" class="text-right">
                            <h6 class="mb-0">{{ $t('label.totals') }}</h6>
                        </th>
                        <th scope="col" class="text-right">
                            <h4 class="mb-0">${{ invoice_total.toFixed(2) | formatThousands }}</h4>
                        </th>
                        <th scope="col" class="text-right">
                            <h4 class="mb-0">${{ invoice_balance.toFixed(2) | formatThousands }}</h4>
                        </th>
                        <th scope="col" class="text-right"></th>
                    </tr>
                </thead>
            </template>
        </table>


        <!-- Invoice Modal -->
        <modal class="pb-6" name="invoiceModal2" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
            <ModalInvoice :job="invoice.job" :invoice-data="invoiceData" :source="source" source2='job' @reloadInvoices="getReportInvoices" @cancelFnc="hideModal" @customChange="handleCustomChange"></ModalInvoice>
        </modal>

        <!-- Invoice View Modal -->
        <modal class="pb-6" name="invoiceViewModal" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
            <ModalInvoiceView :job="invoice.job" :invoice-data="invoiceData" from="reportInvoices" @reload="getReportInvoices" @cancelFnc="closeInvoiceView"></ModalInvoiceView>
        </modal>

        <!-- Invoice Send Modal -->
        <modal class="pb-6" name="invoiceSendModal" height="auto" width="100%" :max-width="900" :scrollable="true" :reset="true" :adaptive="true">
            <ModalInvoiceSend :job="invoice.job" :invoice-data="invoiceData" @cancelFnc="$modal.hide('invoiceSendModal')"></ModalInvoiceSend>
        </modal>

        <!-- Payment Modal -->
        <modal class="pb-6" name="paymentModal" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
            <ModalPayment :job="invoice.job" :curr-id="currId" :invoice-data="invoiceData" :source="source" source2='customer' from="reportInvoices" @reloadPayments="reloadList" @cancelFnc="$modal.hide('paymentModal')"></ModalPayment>
        </modal>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ModalDelete from '../components/ModalDelete.vue';
import ModalInvoice from '../components/ModalInvoice.vue';
import ModalInvoiceSend from '../components/ModalInvoiceSend.vue';
import ModalInvoiceView from '../components/ModalInvoiceView.vue';
import ModalPayment from '../components/ModalPayment.vue';

export default {
    props: [
        'invoices',
        'groupbyfilter',
        'clickSearch',
        'invoice_total',
        'invoice_balance',
        'getReportInvoices',
        'elapsedTime3',
        'checkpending',
        'filter',
    ],
    components: {
        ModalInvoice,
        ModalInvoiceView,
        ModalInvoiceSend,
        ModalDelete,
        ModalPayment
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            invoice: [],
            invoiceData: [],
            source: null,
            source: null,
            currId: null,
            currData: null,
            openModal: false,
            qb_url:process.env.VUE_APP_QUICKBOOKS_URL
        }
    },
    computed: {
        ...mapGetters(['company']),
    },
    mounted() {
    },
    methods: {
        toggleOpenHide(index) {
            this.invoices[index].isHidden = !this.invoices[index].isHidden;
        },
        invoiceViewModal(data) {
            this.currData = data;
            this.invoiceData = {
                data: data
            };
            this.currId = data.customer_id;
            this.$modal.show('invoiceViewModal');
        },
        closeInvoiceView(value) {
            this.$modal.hide('invoiceViewModal');
            this.invoice = this.invoiceData.data;

            if (value == 'editSelected') {
                this.source = 'editInvoice';
                this.invoiceData = {
                    data: this.currData
                };
                this.$modal.show('invoiceModal2');
            } else if (value == 'invoiceSend') {
                this.$modal.show('invoiceSendModal');
            } else if (value == 'invoicePayment') {
                this.$modal.show('paymentModal');
            }
        },
        bgColors(data) {
            var today = moment().format('MM-DD-YYYY'),
                due = moment(data.due_date, 'MM-DD-YYYY'),
                diff = due.diff(today, 'days');

            if (diff <= 5 && diff > 0 && data.mark_as_paid == 0 && data.invoice_status.name == 'Sent')
                return '#FFFF00';
            else if (diff <= 0 && data.mark_as_paid == 0 && data.invoice_status.name == 'Sent')
                return '#FF0000';
            else
                return '00FFFFFF';
        },
        hideModal(value) {
            this.invoiceData = value;
            this.handleCustomChange();
            this.$modal.hide('invoiceModal2');
        },
        handleCustomChange(value) {
            if (value == 1)
                this.invoiceSendModal(this.invoiceData);
        },
        invoiceSendModal(data) {
            this.invoiceData = {
                data: data
            };
            this.$modal.show('invoiceSendModal');
        },
        reloadList() {
            // bus.$emit('reloadReportsInvoic');
        },
        routeToCustomerView(data) {
            console.log('customer data', data.customer)
            this.$router.push({ name: 'customer', params: { customerInfo: data.customer, customerId: data.customer.id } });
        },
        routeToJobView(data) {
            this.$router.replace({ path: `/jobs/edit/${data.job.job_number.job_number}` });
        }
    },
}
</script>