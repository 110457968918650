<template>
    <div>
        <!-- Display the records -->
        <div class="pt-2" v-if="recordCnt === 1">
            <table class="table table-flush table-sm">
                <thead class="thead-light">
                    <tr>
                        <th class="col-sm-3">User/Date(s)</th>
                        <th class="col-sm-3">{{ $t('label.reason') }}</th>
                        <th class="col-sm-2">{{ $t('label.status') }}</th>
                        <th class="col-sm-4">{{ $t('label.notes') }}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(item, idx) in timeOffs" :key="idx" :class="{ 'bg-gray-100': idx % 2 !== 0 }">
                        <!-- User and Dates -->
                        <td>
                            <!-- User name -->
                            <div class="font-weight-bold">{{ getEmployeeName(item.user_id) }}</div>
                            <!-- Date Range -->
                            <div v-if="item.start_date !== item.end_date">
                                <span v-if="moment(item.start_date).hour() > 0">
                                    {{ moment(item.start_date).format('MM/DD/YYYY hh:mm a') }}
                                </span>
                                <span v-else>{{ moment(item.start_date).format('MM/DD/YYYY') }} {{ $t('label.all day') }}</span>
                                -
                                <span v-if="moment(item.end_date).hour() > 0">
                                    {{ moment(item.end_date).format('MM/DD/YYYY hh:mm a') }}
                                </span>
                                <span v-else>{{ moment(item.end_date).format('MM/DD/YYYY') }} {{ $t('label.all day') }}</span>
                            </div>
                            <!-- Single Dates -->
                            <div v-else>
                                <!-- Time Range off -->
                                <span v-if="moment(item.start_date).hour() > 0 && moment(item.end_date).hour() > 0">
                                    {{ moment(item.start_date).format('MM/DD/YYYY hh:mm a') }}
                                    -
                                    {{ moment(item.end_date).format('hh:mm a') }}
                                </span>

                                <!-- All Day off -->
                                <span v-else>{{ moment(item.start_date).format('MM/DD/YYYY') }} {{ $t('label.all day') }}</span>
                            </div>
                        </td>

                        <!-- Reason -->
                        <td>{{ item.description }}</td>

                        <!-- Status -->
                        <td class="text-center">
                            <span class="badge p-2" :class="{ 'badge-warning': item.status === 'Pending', 'badge-danger': item.status === 'Denied', 'badge-success': item.status === 'Approved', 'badge-dark': item.status === 'Cancelled' }" style="font-size: 12px;">{{ item.status }}</span>
                        </td>

                        <!-- Manager Notes -->
                        <td>
                            <div v-if="item.manager_user_id">
                                <div>
                                    {{ item.manager_notes }}
                                </div>
                                <div class="text-muted">
                                    {{ getEmployeeName(item.manager_user_id) }}
                                    {{ moment(item.manager_action_date).format('MM/DD/YYYY hh:mm a') }}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- If no records, display message -->
        <div v-else class="text-center">
            <div v-if="recordCnt === 2">
                No Results to Display
            </div>
            <div v-if="recordCnt === 0">Loading Data...</div>
        </div>

    </div>
</template>

<script>

import moment from '@/utils/date-time';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            timeOffs: null,
            loader: null,
            recordCnt: 0
        }
    },
    created() {
        this.loader = this.$loading.show();
    },
    computed: {
        ...mapGetters(['users']),
    },
    mounted() {
        this.getTodaysTimeOffs();
    },
    methods: {
        getEmployeeName(userId) {
            let companyUsers = this.users.allemployees;
            let idx = companyUsers.findIndex(request => {
                if (request.id === userId) {
                    return true;
                }
            });
            return (idx >= 0) ? companyUsers[idx].name : 'N/A';
        },
        getTodaysTimeOffs() {
            axios.get('/getTodaysTimeOffs')
                .then(res => {
                    this.loader.hide();
                    this.timeOffs = res.data;
                    this.recordCnt = (res.data.length > 0) ? 1 : 2;
                }).catch(err => {
                    console.log(err)
                });
        },
    },
}
</script>
