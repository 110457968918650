<template>
    <div class="purchase-order">
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50">
            <!-- Header -->
            <div class="d-flex z-index-10">
                <!-- Breadcrumbs -->
                <div class="mr-auto z-index-1">
                    <breadcrumbs :title="'SO #' + poNum">
                        <!-- Breakcrumb slots -->
                        <li class="breadcrumb-item">
                            <a class="pointer" @click="$router.push({ name: 'purchaseOrders' })">{{ $t('label.purchase orders') }}</a>
                        </li>
                        <li class="breadcrumb-item">
                            <span>SO #{{ poNum }}</span>
                        </li>
                    </breadcrumbs>
                </div>

                <!-- Purchase Order Save/Cancel -->
                <div class="py-4 align-items-center" style="z-index: 1">
                    <button type="button" class="btn btn-link">
                        <a @click="$router.back()" class="text-white">{{ $t('label.cancel') | uppercase }}</a>
                    </button>
                    <button type="button" class="btn btn-neutral" @click="validateNewItem()">
                        <span class="text-default">{{ $t('label.save purchase order') | uppercase }}</span>
                    </button>
                </div>
            </div>

            <!-- Purchase Order Info -->
            <purchase-order-info v-if="showSections" :form-info="formInfo" :allVendors="allVendors" :is-valid="isValid" :po-type="'edit'"></purchase-order-info>

            <!-- Purchase Order Items -->
            <purchase-order-items v-if="showSections" :form-item="formItem" :is-valid="isValid" :po-type="'edit'" :order-type="formInfo.type"></purchase-order-items>

            <!-- Purchase Order Comments -->
            <purchase-order-comments v-if="showSections" :form-comment="formComment" :is-valid="isValid" :po-type="'edit'"></purchase-order-comments>

            <!-- Purchase Order Comments -->
            <purchase-order-address v-if="showSections" :po-vc-id="purchaseOrder.vendor_contact_id" :form-address="formAddress" :job="formInfo.job" :is-valid="isValid" :is-other-valid="isOtherValid" :is-other-billing-valid="isOtherBillingValid" :po-type="'edit'" :vendor="formInfo.vendor" :vendorContact="formInfo.vendor_contact"></purchase-order-address>

            <!-- Footer Save or Cancel Buttons -->
            <div v-if="showSections" class="d-flex text-right">
                <div class="ml-auto">
                    <span class="d-block d-sm-inline pb-2">
                        <button type="button" class="btn btn-link">
                            <a @click="$router.back()">{{ $t('label.cancel') | uppercase }}</a>
                        </button>
                    </span>
                    <span class="d-block d-sm-inline">
                        <button type="button" class="btn btn-primary" @click="validateNewItem()">
                            {{ $t('label.save purchase order') | uppercase }}
                        </button>
                    </span>
                </div>
            </div>
        </div>

        <!-- Purchase Order Send Modal -->
        <modal class="py-4" name="modalPurchaseOrderChangesSend" height="auto" width="100%" :max-width="900" :scrollable="true" :reset="true" :adaptive="true">
            <ModalPurchaseOrderSend :purchase-order="purchaseOrder" :changes-to-purchase-order="true" :subject-text="'UPDATED'" @cancelFnc="$modal.hide('modalPurchaseOrderChangesSend')">
            </ModalPurchaseOrderSend>
        </modal>
    </div>
</template>

<script>
import ModalPurchaseOrderSend from '@/components/ModalPurchaseOrderSend.vue';
import bus from '@/utils/event-bus';
import PurchaseOrderAddress from './sections/add-edit-purchase-order/PurchaseOrderAddress.vue';
import PurchaseOrderComments from './sections/add-edit-purchase-order/PurchaseOrderComments.vue';
import PurchaseOrderInfo from './sections/add-edit-purchase-order/PurchaseOrderInfo.vue';
import PurchaseOrderItems from './sections/add-edit-purchase-order/PurchaseOrderItems.vue';
;

export default {
    props: ['id'],
    emits: [''],
    components: {
        PurchaseOrderInfo,
        PurchaseOrderItems,
        PurchaseOrderComments,
        PurchaseOrderAddress,
        ModalPurchaseOrderSend
    },

    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            purchaseOrder: [],
            formInfo: {
                type: 0,
                order_number: null,
                title: null,
                status: null,
                vendor_name: null,
                vendor_id: null,
                purchase_date: null,
                job_name: null,
                job_id: null,
                delivery_date: null,
                terms: null,
                job: null,
                vendor_contact_id: null,
                vendor_contact: null,
                vendor_contact_name: null,
            },
            formItem: {
                listItems: [],
                sub_total: 0,
                overall_total: 0,
                tax: 0,
                no_items: 0,
                hide_costs: 0,
                selectedItem: {},
                listItemsDelete: [],
            },
            formComment: {
                comments: '',
                selectedEntry: {
                    attachments: [],
                },
                deletedAttachments: []
            },
            formAddress: {
                billing_id: null,
                billing_address: null,
                billing_address_id: null,
                billing_address_type: null,
                billing_address_type_address: 'billing',
                shipping_id: null,
                shipping_address: null,
                shipping_address_id: null,
                shipping_address_type: null,
                shipping_address_type_address: 'shipping',
                billing_other: {
                    country: 'US',
                    postal_code: null,
                    address_1: null,
                    address_2: null,
                    city: null,
                    state: null,
                },
                shipping_other: {
                    country: 'US',
                    postal_code: null,
                    address_1: null,
                    address_2: null,
                    city: null,
                    state: null,
                },
            },
            backformInfo: {},
            backformItem: {},
            backformComment: {},
            backformAddress: {},
            isValid: {
                vendor_name: null,
                status: null,
                job_name: null,
                formItem: null,
                billing_address: null,
                shipping_address: null,
            },
            isOtherBillingValid: {
                postal_code: null,
                address_1: null,
                city: null,
                country: true,
            },
            isOtherValid: {
                postal_code: null,
                address_1: null,
                city: null,
                country: true,
            },
            changesCheck: 0,
            section: {
                info_no_email: 0,
                info: 0,
                items: 0,
                comments: 0,
                special_attachments: 0,
                billing_address: 0,
                shipping_address: 0,
            },
            email: null,
            showSections: false,
            allVendors: [],
            loader: null,
            currVendorContact: [],
            poNum: null,
        }
    },
    created() {
        this.getAllVendors();
        this.clearAddressData();
    },
    computed: {

    },
    mounted() {
        this.loadLkupData()
    },
    methods: {
        edit() {
            let editData = JSON.parse(JSON.stringify(this.purchaseOrder));

            this.formInfo.order_number = editData.order_number;
            this.formInfo.vendor_name = editData.vendor.vendor_name;
            this.formInfo.vendor_id = editData.vendor_id;
            this.formInfo.vendor = editData.vendor;
            this.formInfo.purchase_date = editData.purchase_date;
            this.formInfo.job_name = editData.job.job_name;
            this.formInfo.job_id = editData.job_id;
            this.formInfo.delivery_date = editData.delivery_date;
            this.formInfo.job = editData.job;
            this.formInfo.vendor_contact_id = editData.vendor_contact_id;
            this.formInfo.vendor_contact = this.currVendorContact;
            this.formInfo.vendor_contact_name = (this.currVendorContact.first_name + ' ' + this.currVendorContact.last_name);

            this.formItem.listItems = editData.purchase_order_items;
            this.formItem.sub_total = 0;
            this.formItem.overall_total = editData.overall_total;
            this.formItem.tax = editData.tax;
            this.formItem.taxCalc = 0;
            this.formItem.no_items = editData.no_items;
            this.formItem.hide_costs = editData.hide_costs;

            this.formComment.comment_id = editData.purchase_order_comments.id;
            this.formComment.comments = editData.purchase_order_comments.comments;
            this.formComment.deletedAttachments = [];

            this.formAddress.billing_id = editData.purchase_order_address[0].id;
            this.formAddress.billing_address = null;
            this.formAddress.billing_address_id = editData.purchase_order_address[0].type_id;
            this.formAddress.billing_address_type = editData.purchase_order_address[0].type;
            this.formAddress.billing_address_type_address = 'billing';
            this.formAddress.shipping_id = editData.purchase_order_address[1].id;
            this.formAddress.shipping_address = null;
            this.formAddress.shipping_address_id = editData.purchase_order_address[1].type_id;
            this.formAddress.shipping_address_type = editData.purchase_order_address[1].type;
            this.formAddress.shipping_address_type_address = 'shipping';

            this.formAddress.billing_other.country = 'US';
            this.formAddress.billing_other.postal_code = editData.purchase_order_address[0].postal_code;
            this.formAddress.billing_other.address_1 = editData.purchase_order_address[0].address_1;
            this.formAddress.billing_other.address_2 = editData.purchase_order_address[0].address_2;
            this.formAddress.billing_other.city = editData.purchase_order_address[0].city;
            this.formAddress.billing_other.state = editData.purchase_order_address[0].state;

            this.formAddress.shipping_other.country = 'US';
            this.formAddress.shipping_other.postal_code = editData.purchase_order_address[1].postal_code;
            this.formAddress.shipping_other.address_1 = editData.purchase_order_address[1].address_1;
            this.formAddress.shipping_other.address_2 = editData.purchase_order_address[1].address_2;
            this.formAddress.shipping_other.city = editData.purchase_order_address[1].city;
            this.formAddress.shipping_other.state = editData.purchase_order_address[1].state;

            this.showSections = true;

            this.backformInfo = JSON.parse(JSON.stringify(this.formInfo));
            this.backformItem = JSON.parse(JSON.stringify(this.formItem));
            this.backformComment = JSON.parse(JSON.stringify(this.formComment));

            this.$nextTick(() => {
                this.backformAddress = JSON.parse(JSON.stringify(this.formAddress));
            })

            for (const key in this.isValid)
                this.isValid[key] = true;

            if(this.formAddress.billing_address_type == 'other') {
                for(const key in this.isOtherBillingValid)
                    this.isOtherBillingValid[key] = true;
            }
            if(this.formAddress.shipping_address_type == 'other') {
                for(const key in this.isOtherValid)
                    this.isOtherValid[key] = true;
            }
        },
        loadLkupData() {
            this.loader = this.$loading.show();
            let promises = [];
            promises.push(this.editPurchaseOrder(this.id));
            // Hide the loader overlay after all lookup data is loaded
            Promise.all(promises).then(() => {
                this.edit();
                this.loader.hide();
            });
        },
        async editPurchaseOrder(id) {
            await axios.get('/purchase-order/' + id)
                .then(res => {
                    this.purchaseOrder = res.data.result;
                    this.poNum = this.purchaseOrder.purchase_order_number.purchase_order_number;
                    for (var i = 0; i < this.purchaseOrder.vendor.vendor_contacts.length; i++) {
                        if (this.purchaseOrder.vendor.vendor_contacts[i].id == this.purchaseOrder.vendor_contact_id) {
                            this.currVendorContact = this.purchaseOrder.vendor.vendor_contacts[i];
                        }
                    }
                })
                .catch(err => console.log(err));
        },
        // Validate all necessary fields in child components
        validateNewItem() {
            let formValid = true;

            this.formItem.listItems.forEach((data) => {
                let counter = 0;
                if (data.description == '') counter++;
            
                if (counter > 0) {
                    formValid = false;
                    this.isValid['formItem'] = false;
                    return true;
                } else if (counter == 0) {
                    formValid = true;
                    this.isValid['formItem'] = true;
                    return false;
                }
            });

            // Check fieldsWithError for fields with an error
            for (const key in this.isValid) {
                if ((this.isValid[key] === false))
                    formValid = false;
                if (this.isValid[key] === null) {
                    formValid = false;
                    this.isValid[key] = false;
                }
            }
            if(this.formAddress.billing_address_type == 'other') {
                for(const key in this.isOtherBillingValid) {
                    if ((this.isOtherBillingValid[key] === false))
                        formValid = false;
                    if (this.isOtherBillingValid[key] === null) {
                        formValid = false;
                        this.isOtherBillingValid[key] = false;
                    }
                }
            } 
            if(this.formAddress.shipping_address_type == 'other') {
                for(const key in this.isOtherValid) {
                    if ((this.isOtherValid[key] === false))
                        formValid = false;
                    if (this.isOtherValid[key] === null) {
                        formValid = false;
                        this.isOtherValid[key] = false;
                    }
                } 
            }
            if (formValid) {
                this.checkChanges();
            } else {
                bus.$emit('banner-error', {
                    message: this.$t('label.please validate required fields before proceeding') + '.'
                });
            }
        },
        checkChanges() {
            // PO STATUS AND JOB ARE NOT INCLUDED IN CHECKING per AC
            this.changesCheck = 0;
            // check info changes
            if (this.backformInfo.status != this.formInfo.status) this.section.info_no_email++;
            if (this.backformInfo.job_id != this.formInfo.job_id) this.section.info_no_email++;

            if (this.backformInfo.vendor_id != this.formInfo.vendor_id) this.section.info++;
            if (this.backformInfo.purchase_date != this.formInfo.purchase_date) this.section.info++;
            if (this.backformInfo.delivery_date != this.formInfo.delivery_date) this.section.info++;
            if (this.backformInfo.terms != this.formInfo.terms) this.section.info++;

            // check item changes
            if (this.backformItem.listItems.length != this.formItem.listItems.length) this.section.items++;
            this.backformItem.listItems.forEach((data, index) => {
                if (this.formItem.listItems[index]) {
                    if (this.backformItem.listItems[index].description != this.formItem.listItems[index].description) this.section.items++;
                    if (this.backformItem.listItems[index].sku != this.formItem.listItems[index].sku) this.section.items++;
                    if (this.backformItem.listItems[index].quantity != this.formItem.listItems[index].quantity) this.section.items++;
                    if (this.backformItem.listItems[index].unit_cost != this.formItem.listItems[index].unit_cost) this.section.items++;
                } else {
                    this.section.items++;
                }
            });
            // check tax changes
            if (this.backformItem.tax != this.formItem.tax) this.section.items++;

            // check comment changes
            if (this.backformComment.comments != this.formComment.comments) this.section.comments++;

            // check billing address changes
            if (this.backformAddress.billing_address_type != this.formAddress.billing_address_type) this.section.billing_address++;
            if (this.backformAddress.billing_address_type == 'other') {
                if (this.backformAddress.billing_other.address_1 != this.formAddress.billing_other.address_1) this.section.billing_address++;
                if (this.backformAddress.billing_other.address_2 != this.formAddress.billing_other.address_2) this.section.billing_address++;
                if (this.backformAddress.billing_other.city != this.formAddress.billing_other.city) this.section.billing_address++;
                if (this.backformAddress.billing_other.postal_code != this.formAddress.billing_other.postal_code) this.section.billing_address++;
                if (this.backformAddress.billing_other.state != this.formAddress.billing_other.state) this.section.billing_address++;
            }

            // check shipping address changes
            if (this.backformAddress.shipping_address_type != this.formAddress.shipping_address_type) this.section.shipping_address++;
            if (this.backformAddress.shipping_address_type == 'other') {
                if (this.backformAddress.shipping_other.address_1 != this.formAddress.shipping_other.address_1) this.section.shipping_address++;
                if (this.backformAddress.shipping_other.address_2 != this.formAddress.shipping_other.address_2) this.section.shipping_address++;
                if (this.backformAddress.shipping_other.city != this.formAddress.shipping_other.city) this.section.shipping_address++;
                if (this.backformAddress.shipping_other.postal_code != this.formAddress.shipping_other.postal_code) this.section.shipping_address++;
                if (this.backformAddress.shipping_other.state != this.formAddress.shipping_other.state) this.section.shipping_address++;
            }

            this.saveDraft();
        },
        saveDraft() {
            this.loader = this.$loading.show();
            let mergedForm = {
                purchase_order_id: this.purchaseOrder.id,
                form_info: this.formInfo,
                form_item: this.formItem,
                form_comment: this.formComment,
                form_address: this.formAddress,
                section: this.section,
            };

            axios.post('/purchase-order/update', mergedForm).then(res => {
                this.loader.hide();
                this.toPreviousPage();
            }).catch(err => console.log('so update', err));

        },
        async saveFile(entryId, file, activityLogId) {
            const data = new FormData();
            data.append('download', file.file);
            data.append('data', JSON.stringify({
                id: entryId,
                file_type: file.fileType,
                activity_log_id: activityLogId
            }));

            await this.$store.dispatch('purchaseOrder/uploadSpecialAttachment', data);
        },
        toPreviousPage() {
            this.$router.push({ name: 'purchaseOrderView', params: { id: this.purchaseOrder.id } })
        },
        getAllVendors() {
            axios.get('/get-vendors').then(res => {
                this.allVendors = res.data.result;
            }).catch(err => console.log(err));
        },
        clearAddressData() {
            this.formAddress.billing_id = null;
            this.formAddress.billing_address = null;
            this.formAddress.billing_address_id = null;
            this.formAddress.billing_address_type = null;
            this.formAddress.billing_address_type_address = 'billing';
            this.formAddress.shipping_id = null;
            this.formAddress.shipping_address = null;
            this.formAddress.shipping_address_id = null;
            this.formAddress.shipping_address_type = null;
            this.formAddress.shipping_address_type_address = 'shipping';

            this.formAddress.billing_other.country = 'US';
            this.formAddress.billing_other.postal_code = null;
            this.formAddress.billing_other.address_1 = null;
            this.formAddress.billing_other.address_2 = null;
            this.formAddress.billing_other.city = null;
            this.formAddress.billing_other.state = null;

            this.formAddress.shipping_other.country = 'US';
            this.formAddress.shipping_other.postal_code = null;
            this.formAddress.shipping_other.address_1 = null;
            this.formAddress.shipping_other.address_2 = null;
            this.formAddress.shipping_other.city = null;
            this.formAddress.shipping_other.state = null;
        },
    },
}
</script>
