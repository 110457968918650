<template>
	<notify-me container="notifications" width="400px" :event-bus="bus">
		<template slot="content" slot-scope="{data}">
			{{ data.type }}
			<div class="toast w-100" role="alert" aria-live="assertive" aria-atomic="true">
				<div class="toast-header">
					<div v-if="data.type == 'jobnotes' || data.type == 'task'" class="d-flex align-items-center">
						<img class="rounded-circle" :src="data.created_by_image" alt="" style="width: 30px; height: 30px; object-fit: cover;">
						<p v-if="data.type == 'jobnotes' && data.subtype != 'notask'" class="m-0 mr-3">
							<span class="font-weight-bold ml-2 mr-1">{{ data.created_by }}</span> mentioned you in a note on <span class="font-weight-bold ml-1">{{ data.job_name }}:{{ data.task_name }}</span>
						</p>
						<p v-if="data.type == 'jobnotes' && data.subtype == 'notask'" class="m-0 mr-3">
							<span class="font-weight-bold ml-2 mr-1">{{ data.created_by }}</span> mentioned you in a note on <span class="font-weight-bold ml-1">{{ data.job_name }}</span>
						</p>
						<p v-if="data.type == 'task'" class="m-0 mr-3">
							<span class="font-weight-bold ml-2 mr-1">{{ data.created_by }}</span> assigned you to a task
						</p>
					</div>
					<div v-if="data.type == 'reminder'" class="d-flex align-items-center">
						<i class="ri-time-line"></i>
						<p v-if="data.subtype == 'clockin'" class="m-0 ml-2 mr-3">
							Reminder to clock in for the day
						</p>
						<p v-else class="m-0 ml-2 mr-3">
							Reminder to clock out for the day
						</p>
					</div>
					<div v-if="data.type == 'message'" class="d-flex align-items-center">
						<i class="ri-message-2-line" style="font-size: 20px;"></i>
						<p class="m-0 mr-3">
							<span class="font-weight-bold ml-2 mr-1">Message: </span> {{ data.title }}
						</p>
					</div>
					<div v-if="data.type == 'timeoff'" class="d-flex align-items-center">
						<img class="rounded-circle" :src="data.created_by_image" alt="" style="width: 30px; height: 30px; object-fit: cover;">
						<p v-if="data.status === 'Approved'" class="m-0 mr-3">
							<span class="font-weight-bold ml-2 mr-1">{{ data.created_by }}</span> approved your time off request.
						</p>
						<p v-else class="m-0 mr-3">
							<span class="font-weight-bold ml-2 mr-1">{{ data.created_by }}</span> denied your time off request. Please contact them if needed.
						</p>
					</div>
				</div>
				<div class="toast-body">
					<div v-if="data.type == 'jobnotes' || data.type == 'task' || data.type == 'message'" class="mb-2">{{ data.created_date }}</div>
					<div v-if="data.type == 'reminder'" class="mb-2">{{ data.created_date | formatDateAndTime }}</div>
					<div v-if="data.type == 'jobnotes' || data.type == 'reminder'" class="mb-3" v-html="data.notes"></div>
					<div v-if="data.type === 'timeoff'" class="row m-0">
						<div class="col-6 pl-2 mb-2">{{ data.approveDate }}</div>
					</div>
					<div v-if="data.type == 'task'" class="mb-3">
						<div class="row m-0">
							<div class="col-3 pl-0 text-right font-weight-bold">Customer:</div>
							<div class="col-9 pl-0">{{ data.customer_name }}</div>
						</div>
						<div class="row m-0">
							<div class="col-3 pl-0 text-right font-weight-bold">Job:</div>
							<div class="col-9 pl-0">{{ data.job_name }}</div>
						</div>
						<div class="row m-0">
							<div class="col-3 pl-0 text-right font-weight-bold">Task:</div>
							<div class="col-9 pl-0">{{ data.task_name }}</div>
						</div>
					</div>
					<div v-if="data.type == 'message'" class="mb-3">
						<div v-if="data.message_image" class="row m-0 justify-content-center">
							<div class="col-6">
								<img class="rounded-circle w-100" :src="data.message_image" alt="">
							</div>
						</div>
						<div class="row m-0">
							{{ data.body }}
						</div>
					</div>
					<div v-if="data.type === 'timeoff'" class="mb-3">
						<div class="row m-0">
							<div class="col-3 pl-0 text-right font-weight-bold">Date(s):</div>
							<div class="col-9 pl-0" v-if="data.timeOffDateStart !== data.timeOffDateEnd">{{ data.timeOffDateStart | formatDate }} - {{ data.timeOffDateEnd | formatDate }}</div>
							<div class="col-9 pl-0" v-else>{{ data.timeOffDateStart | formatDate }}</div>
						</div>
						<div class="row m-0">
							<div class="col-3 pl-0 text-right font-weight-bold">Hrs:</div>
							<div class="col-9 pl-0">{{ data.timeOffTotalHours }}</div>
						</div>
						<div class="row m-0">
							<div class="col-3 pl-0 text-right font-weight-bold">Comment:</div>
							<div class="col-9 pl-0">{{ data.timeOffDescription }}</div>
						</div>
						<div class="row m-0" v-if="data.status === 'Denied'">
							<div class="col-3 pl-0 text-right font-weight-bold">Reason:</div>
							<div class="col-9 pl-0">{{ data.timeOffDenyReason }}</div>
						</div>
					</div>
					<div class="d-flex justify-content-between">
						<button v-if="data.type == 'jobnotes' || data.type == 'task'" class="btn btn-default px-2 py-1">VIEW</button>
						<div v-if="data.type == 'message'">
							<div v-if="data.btn_action == 'link' && data.btn_label != '' && data.btn_label != null">
								<a @click="readMessage" type="button" class="btn btn-default px-2 py-1" :href="data.url" target="_blank">{{ data.btn_label }}</a>
							</div>
							<div v-if="data.btn_action == 'close' && data.btn_label != '' && data.btn_label != null" class="text-center">
								<button @click="readMessage" class="btn btn-default px-2 py-1">{{ data.btn_label }}</button>
							</div>
						</div>
						<button class="btn btn-white px-2 py-1" :class="data.type == 'reminder' ? 'ml-auto' : ''"><i class="ri-inbox-line"></i></button>
					</div>
				</div>
				<!-- <div class="colored" :style="'height: 10px; width: '+ ((timeleft[data.id] / timelimit) * 100) +'%; background: #0E914F; transition : width 1s ease;'"></div> -->
				<!-- <progress value="0" max="10" id="progressBar" class="progressBar"></progress> -->
				<progress value="15" max="15" :id="'pageBeginCountdown_' + data.id" class="progressBar"></progress>
			</div>
		</template>
	</notify-me>
</template>
<script>
import bus from '@/utils/event-bus';
import Notify from 'vue-notify-me';

export default {
	name: 'notify',
	props: [],
	components: {
		'notify-me': Notify
	},
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
			companySettings: JSON.parse(localStorage.getItem('company_settings')),
			bus,
			timeleft: [],
			timelimit: 15,
			countdownTimer: null,
		}
	},
	mounted() {
		this.listenForChanges();
	},
	methods: {
		listenForChanges() {
			this.$echo.channel('notifications')
				.listen('.notifications-event', params => {
					if (!('Notification' in window)) {
						alert('Web Notification is not supported');
						return;
					}
					console.log('notifyparams', params)
					var updated_date = new Date(params.data.updated_at);
					var updated_date2 = moment.utc(String(updated_date)).format('MM/DD/YYYY') + ' at ' + moment.utc(String(updated_date)).format('hh:mm a');

					if (params.type == 'jobnotes') {

						// let taggedUsers = JSON.parse(params.data.tagged_users);
						let taggedUsers = params.data.tagged_users;

						taggedUsers.forEach((data, key) => {
							if (data.id == this.user.id) {
								this.timeleft[params.noti_id] = 15;
								this.countdownTimer = setInterval(() => {
									this.timeleft[params.noti_id]--;
									document.getElementById('pageBeginCountdown_' + params.noti_id).value = this.timeleft[params.noti_id];
									if (this.timeleft[params.noti_id] <= 0) {
										clearInterval(this.countdownTimer);
									}
								}, 1000);

								let jobnote_data = params.data;
								bus.$emit('notify-me', {
									status: this.status,
									// permanent: true,
									timeout: 15500,
									data: {
										id: params.noti_id,
										type: 'jobnotes',
										subtype: params.subtype,
										created_by: jobnote_data.created_by.name,
										created_by_image: jobnote_data.created_by.picture,
										notes: jobnote_data.notes,
										job_name: jobnote_data.job.job_name,
										task_name: jobnote_data.task?.name,
										// created_date: jobnote_data.created_at
										created_date: updated_date2
									}
								});
							}
						});
					}

					if (params.type == 'tasks') {

						let assignedTo = params.data.assigned_to;

						assignedTo.forEach((data, key) => {
							if (data.user.id == this.user.id) {
								this.timeleft[params.noti_id] = 15;
								this.countdownTimer = setInterval(() => {
									this.timeleft[params.noti_id]--;
									document.getElementById('pageBeginCountdown_' + params.noti_id).value = this.timeleft[params.noti_id];
									if (this.timeleft[params.noti_id] <= 0) {
										clearInterval(this.countdownTimer);
									}
								}, 1000);

								let task_data = params.data;
								bus.$emit('notify-me', {
									status: this.status,
									// permanent: true,
									timeout: 15500,
									data: {
										id: params.noti_id,
										type: 'task',
										created_by: task_data.created_by.name,
										created_by_image: task_data.created_by.picture,
										notes: task_data.notes,
										task_name: task_data.name,
										// created_date: task_data.created_at,
										created_date: updated_date2,
										customer_name: task_data.job.customer.name,
										job_name: task_data.job.job_name,
									}
								});
							}
						});
					}

					if (params.type == 'reminder') {

						if (params.subtype == 'clockin') {

							if (params.data.length > 0) {

								params.data.forEach((data, key) => {

									if (data.id == this.user.id) {
										this.timeleft[params.noti_id] = 15;
										this.countdownTimer = setInterval(() => {
											this.timeleft[params.noti_id]--;
											document.getElementById('pageBeginCountdown_' + params.noti_id).value = this.timeleft[params.noti_id];
											if (this.timeleft[params.noti_id] <= 0) {
												clearInterval(this.countdownTimer);
											}
										}, 1000);

										bus.$emit('notify-me', {
											status: this.status,
											// permanent: true,
											timeout: 15500,
											data: {
												id: params.noti_id,
												type: 'reminder',
												subtype: params.subtype,
												created_by: '',
												created_by_image: '',
												notes: 'You have not clocked in today. Please be sure to clock in to accurately account for your time.',
												task_name: '',
												// created_date: params.created_at,
												created_date: updated_date2,
												customer_name: '',
												job_name: '',
											}
										});
									}
								});
							}

						} else {

							if (params.data.length > 0) {
								console.log('jack', params.data[0].user_id)
								if (params.data[0].user_id == this.user.id) {
									this.timeleft[params.noti_id] = 15;
									this.countdownTimer = setInterval(() => {
										this.timeleft[params.noti_id]--;
										document.getElementById('pageBeginCountdown_' + params.noti_id).value = this.timeleft[params.noti_id];
										if (this.timeleft[params.noti_id] <= 0) {
											clearInterval(this.countdownTimer);
										}
									}, 1000);

									bus.$emit('notify-me', {
										status: this.status,
										// permanent: true,
										timeout: 15500,
										data: {
											id: params.noti_id,
											type: 'reminder',
											created_by: '',
											created_by_image: '',
											notes: 'You have been clocked-in for more than ' + this.companySettings.settings.clock_out_reminder + ' hours today. Remeber to clock out when you are completed with your daily work',
											task_name: '',
											created_date: params.data[0].time_in,
											customer_name: '',
											job_name: '',
										}
									});
								}
							}
						}

					}

					if (params.type == 'timeoff') {
						console.log(params)
						let timeOff = params.data.time_off,
							user = timeOff.user,
							timeOffDates = [],
							timeOffTotalNumberOfHours = 0,
							timeOffDetails = timeOff.time_off_details;
						timeOffDetails.forEach((data, key) => {
							timeOffDates.push(data.date)
							timeOffTotalNumberOfHours += data.hours;
						});
						console.log(timeOffDates)
						if (user.id === this.user.id) {
							this.timeleft[params.noti_id] = 15;
							this.countdownTimer = setInterval(() => {
								this.timeleft[params.noti_id]--;
								document.getElementById('pageBeginCountdown_' + params.noti_id).value = this.timeleft[params.noti_id];
								if (this.timeleft[params.noti_id] <= 0) {
									clearInterval(this.countdownTimer);
								}
							}, 1000);
							bus.$emit('notify-me', {
								status: this.status,
								// permanent: true,
								timeout: 15500,
								data: {
									id: params.noti_id,
									type: params.type,
									created_by: params.data.user.name,
									created_by_image: params.data.user.picture,
									approveDate: params.data.updated_at,
									status: params.data.status,
									timeOffDescription: timeOff.description,
									timeOffDenyReason: params.data.deny_reason,
									timeOffDateStart: timeOffDates[0],
									timeOffDateEnd: timeOffDates[timeOffDates.length - 1],
									timeOffTotalHours: timeOffTotalNumberOfHours
								}
							});
						}
					}

					// if( params.type == 'message' ) {

					// 	let recipients = params.data.recipients;

					// 	recipients.forEach((data, key) => {
					// 		if( data.user_id == this.user.id ) {
					// 			this.timeleft[params.noti_id] = 15;
					// 			this.countdownTimer = setInterval(() => {
					// 				this.timeleft[params.noti_id]--;
					// 				document.getElementById('pageBeginCountdown_'+params.noti_id).value = this.timeleft[params.noti_id];
					// 				if (this.timeleft[params.noti_id] <= 0) {
					// 					clearInterval(this.countdownTimer);
					// 				}
					// 			}, 1000);

					// 			let message_data = params.data;
					// 			bus.$emit('notify-me', {
					// 				status: this.status,
					// 				// permanent: true,
					// 				timeout: 15500,
					// 				data: {
					// 					id: params.noti_id,
					// 					type: 'message',
					// 					created_by: message_data.created_by.name,
					// 					created_by_image: message_data.created_by.picture,
					// 					created_date: message_data.created_at,
					// 					title: message_data.title,
					// 					message_image: message_data.image,
					// 					body: message_data.body,
					// 					btn_action: message_data.btn_action,
					// 					btn_label: message_data.btn_label,
					// 					url: message_data.url,
					// 				}
					// 			});
					// 		}
					// 	});
					// }

					var main = this;
					$('.notifications .delete').ready(function () {
						$('.notifications .delete').click(function () {
							clearInterval(main.countdownTimer);
						});
					});

				})
		},
		readMessage() {
			// this.$store.dispatch('readMessage', this.messages.notif.recipient.id);
			// $('#messageNotifModal').modal('hide');
		},
	},
}
</script>
