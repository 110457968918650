<template>
    <div id="hosted" class="container">
        <div style="margin: 0px auto; font-family: Roboto, Arial; padding: 50px 10px 100px;">
            <div data-custom-class="body">
                <div data-custom-class="title" style="line-height: 1.5;"><strong><span style="font-size: 26px;">ACCEPTABLE USE POLICY</span></strong></div>
                <div style="line-height: 1.5;"><br></div>
                <div data-custom-class="subtitle" style="line-height: 1.5;"><strong>Last updated&nbsp;</strong>
                    <bdt class="question"><strong>July 07, 2023</strong></bdt>
                </div>
                <div style="line-height: 1.2;"><br></div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;"><br></div>
                <div data-custom-class="body_text" style="line-height: 1.5;">This Acceptable Use Policy (<bdt class="block-component"></bdt>"<strong>Policy</strong>"<bdt class="statement-end-if-in-editor"></bdt>) is part of our <bdt class="question">__________</bdt> (<bdt class="block-component"></bdt>"<strong>Legal Terms</strong>"<bdt class="statement-end-if-in-editor"></bdt>) and should therefore be read alongside our main Legal Terms: <bdt class="question">__________</bdt>. If you do not agree with these Legal Terms, please refrain from using our Services. Your continued use of our Services implies acceptance of these Legal Terms.</div>
                <div style="line-height: 1;"><br></div>
                <div data-custom-class="body_text" style="line-height: 1.5;">Please carefully review this Policy which applies to any and all:</div>
                <div style="line-height: 1;"><br></div>
                <div data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;">(a) uses of our Services (as defined in <bdt class="block-component"></bdt>"Legal Terms"<bdt class="statement-end-if-in-editor"></bdt>)<bdt class="block-component"></bdt>
                </div>
                <div data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;">(b) forms, materials, consent tools, comments, post, and all other content available on the Services (<bdt class="block-component"></bdt>"<strong>Content</strong>"<bdt class="statement-end-if-in-editor"></bdt>)<bdt class="block-component"></bdt> and</div>
                <div data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;">(c) material which you contribute to the Services including any upload, post, review, disclosure, ratings, comments, chat etc.<bdt class="block-component"></bdt> in any forum, chatrooms, reviews, and to any interactive services associated with it<bdt class="statement-end-if-in-editor"></bdt> (<bdt class="block-component"></bdt>"<strong>Contribution</strong>"<bdt class="statement-end-if-in-editor"></bdt>).</div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;"><br></div>
                <div data-custom-class="heading_1" style="line-height: 1.5;"><strong><span style="font-size: 19px;">WHO WE ARE</span></strong></div>
                <div style="line-height: 1;"><br></div>
                <div data-custom-class="body_text" style="line-height: 1.5;">We are <bdt class="question">SubPro Contracting Software</bdt>
                    <bdt class="block-component"></bdt>, doing business as <bdt class="forloop-component"></bdt>
                    <bdt class="block-component"></bdt>
                    <bdt class="question">SubPro</bdt>
                    <bdt class="block-component"></bdt>
                    <bdt class="forloop-component"></bdt>
                    <bdt class="statement-end-if-in-editor"></bdt> (<bdt class="block-component"></bdt>"<strong>Company</strong>," "<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"<bdt class="statement-end-if-in-editor"></bdt>) a company registered in <bdt class="block-component"></bdt>
                    <bdt class="block-component"></bdt>
                    <bdt class="question">__________</bdt>, <bdt class="question">United States</bdt>
                    <bdt class="statement-end-if-in-editor"></bdt>
                    <bdt class="block-component"></bdt> at <bdt class="question">__________</bdt>
                    <bdt class="block-component"></bdt>, <bdt class="question">__________</bdt>
                    <bdt class="block-component"></bdt>
                    <bdt class="block-component"></bdt>. We operate <bdt class="block-component"></bdt>the website <bdt class="question"><a href="https://subpro.com" target="_blank" data-custom-class="link">https://subpro.com</a></bdt> (the <bdt class="block-component"></bdt>"<strong>Site</strong>"<bdt class="statement-end-if-in-editor"></bdt>)<bdt class="statement-end-if-in-editor"></bdt>
                    <bdt class="block-component"></bdt>
                    <bdt class="block-component"></bdt>, as well as any other related products and services that refer or link to this Policy (collectively, the <bdt class="block-component"></bdt>"<strong>Services</strong>"<bdt class="statement-end-if-in-editor"></bdt>).
                </div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;"><br></div>
                <div data-custom-class="heading_1" style="line-height: 1.5;"><strong><span style="font-size: 19px;">USE OF THE SERVICES</span></strong></div>
                <div style="line-height: 1;"><br></div>
                <div data-custom-class="body_text" style="line-height: 1.5;">When you use the Services you warrant that you will comply with this Policy and with all applicable laws.</div>
                <div style="line-height: 1;"><br></div>
                <div data-custom-class="body_text" style="line-height: 1.5;">You also acknowledge that you may not:</div>
                <ul>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Make any <bdt class="block-component"></bdt>unauthorized<bdt class="statement-end-if-in-editor"></bdt> use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false <bdt class="block-component"></bdt>pretenses<bdt class="statement-end-if-in-editor"></bdt>.</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services and/or the Content contained therein.&nbsp;</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Engage in <bdt class="block-component"></bdt>unauthorized<bdt class="statement-end-if-in-editor"></bdt> framing of or linking to the Services.</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Make improper use of our Services, including our support services or submit false reports of abuse or misconduct.&nbsp;</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Engage in any automated use of the Services, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Interfere with, disrupt, or create an undue burden on the Services or the networks or the Services connected.</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Attempt to impersonate another user or person or use the username of another user.</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Use any information obtained from the Services in order to harass, abuse, or harm another person.&nbsp;</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any revenue-generating <bdt class="block-component"></bdt>endeavor<bdt class="statement-end-if-in-editor"></bdt> or commercial enterprise.</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Services, except as expressly permitted by applicable law.</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services.</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you.</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Delete the copyright or other proprietary rights notice from any Content.</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Copy or adapt the Services’ software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Services.</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (<bdt class="block-component"></bdt>"gifs"<bdt class="statement-end-if-in-editor"></bdt>), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as <bdt class="block-component"></bdt>"spyware" or "passive collection mechanisms" or "pcms"<bdt class="statement-end-if-in-editor"></bdt>).</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Services, or using or launching any <bdt class="block-component"></bdt>unauthorized<bdt class="statement-end-if-in-editor"></bdt> script or other software.</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Use the Services in a manner inconsistent with any applicable laws or regulations.<bdt class="forloop-component"></bdt>
                    </li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">
                        <bdt class="question">Use a buying agent or purchasing agent to make purchases on the Services.</bdt>
                        <bdt class="forloop-component"></bdt>
                    </li>
                </ul>
                <div data-custom-class="body_text" style="line-height: 1.5;">
                    <bdt class="block-component"></bdt>If you subscribe to our Services, you understand, acknowledge, and agree that you may not, except if expressly permitted:
                </div>
                <ul>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Engage in any use, including modification, copying, redistribution, publication, display, performance, or retransmission, of any portions of any Services, other than as expressly permitted by this Policy, without the prior written consent of <bdt class="question">SubPro Contracting Software</bdt>, which consent <bdt class="question">SubPro Contracting Software</bdt> may grant or refuse in its sole and absolute discretion.<bdt class="block-component"></bdt>
                    </li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Reconstruct or attempt to discover any source code or algorithms of the Services, or any portion thereof, by any means whatsoever.<bdt class="statement-end-if-in-editor"></bdt>
                        <bdt class="block-component"></bdt>
                    </li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Provide, or otherwise make available, the Services to any third party.<bdt class="statement-end-if-in-editor"></bdt>
                        <bdt class="block-component"></bdt>
                        <bdt class="block-component"></bdt>
                    </li>
                    <li data-custom-class="body_text" style="line-height: 1.5;">Damage, reveal, or alter any user's data, or any other hardware, software, or information relating to another person or entity.<bdt class="statement-end-if-in-editor"></bdt>
                        <bdt class="statement-end-if-in-editor">
                            <bdt class="block-component"></bdt>
                        </bdt>
                    </li>
                </ul>
            </div>
            <div style="line-height: 1.5;">
                <bdt class="block-component"></bdt>
            </div>
            <div style="line-height: 1.5;">
                <bdt class="statement-end-if-in-editor">
                    <bdt class="block-component"></bdt>
                </bdt>
            </div>
            <div style="line-height: 1.5;"><br></div>
            <div style="line-height: 1.5;"><br></div>
            <div data-custom-class="heading_1" style="line-height: 1.5;"><strong><span style="font-size: 19px;">CONTRIBUTIONS</span></strong></div>
            <div style="line-height: 1;"><br></div>
            <div data-custom-class="body_text" style="line-height: 1.5;">In this Policy, the term <bdt class="block-component"></bdt>"Contributions"<bdt class="statement-end-if-in-editor"></bdt> means:</div>
            <ul>
                <li data-custom-class="body_text" style="line-height: 1.5;">any data, information, software, text, code, music, scripts, sound, graphics, photos, videos, tags, messages, interactive features, or other materials that you post, share, upload, submit, or otherwise provide in any manner on or through to the Services; or</li>
                <li data-custom-class="body_text" style="line-height: 1.5;">any other content, materials, or data you provide to <bdt class="question">SubPro Contracting Software</bdt> or use with the Services.</li>
            </ul>
            <div data-custom-class="body_text" style="line-height: 1.5;">Some areas of the Services may allow users to upload, transmit, or post Contributions. We may but are under no obligation to review or moderate the Contributions made on the Services, and we expressly exclude our liability for any loss or damage resulting from any of our users' breach of this Policy. Please report any Contribution that you believe breaches this Policy; however, we will determine, in our sole discretion, whether a Contribution is indeed in breach of this Policy.</div>
            <div style="line-height: 1.5;"><br></div>
            <div data-custom-class="body_text" style="line-height: 1.5;">You warrant that:</div>
            <ul>
                <li data-custom-class="body_text" style="line-height: 1.5;">you are the creator and owner of or have the necessary <bdt class="block-component"></bdt>licenses<bdt class="statement-end-if-in-editor"></bdt>, rights, consents, releases, and permissions to use and to <bdt class="block-component"></bdt>authorize<bdt class="statement-end-if-in-editor"></bdt> us, the Services, and other users of the Services to use your Contributions in any manner contemplated by the Services and this Policy;</li>
                <li data-custom-class="body_text" style="line-height: 1.5;">all your Contributions comply with applicable laws and are original and true (if they represent your opinion or facts);</li>
                <li data-custom-class="body_text" style="line-height: 1.5;">the creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party; and</li>
                <li data-custom-class="body_text" style="line-height: 1.5;">you have the verifiable consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Services and this Policy.</li>
            </ul>
            <div data-custom-class="body_text" style="line-height: 1.5;">You also agree that you will not post, transmit, or upload any (or any part of a) Contribution that:</div>
            <ul>
                <li data-custom-class="body_text" style="line-height: 1.5;">is in breach of applicable laws, regulation, court order, contractual obligation, this Policy, our Legal Terms, a legal duty, or that promotes or facilitates fraud or illegal activities;</li>
                <li data-custom-class="body_text" style="line-height: 1.5;">is defamatory, obscene, offensive, hateful, insulting, intimidating, bullying, abusive, or threatening, to any person or group;</li>
                <li data-custom-class="body_text" style="line-height: 1.5;">is false, inaccurate, or misleading;</li>
                <li data-custom-class="body_text" style="line-height: 1.5;">includes child sexual abuse material, or violates any applicable law concerning child pornography or otherwise intended to protect minors;</li>
                <li data-custom-class="body_text" style="line-height: 1.5;">contains any material that solicits personal information from anyone under the age of 18 or exploits people under the age of 18 in a sexual or violent manner;</li>
                <li data-custom-class="body_text" style="line-height: 1.5;">promotes violence, advocates the violent overthrow of any government, or incites, encourages, or threatens physical harm against another;</li>
                <li data-custom-class="body_text" style="line-height: 1.5;">is obscene, lewd, lascivious, filthy, violent, harassing, <bdt class="block-component"></bdt>libelous<bdt class="statement-end-if-in-editor"></bdt>, slanderous, contains sexually explicit material, or is otherwise objectionable (as determined by us);</li>
                <li data-custom-class="body_text" style="line-height: 1.5;">is discriminatory based on race, sex, religion, nationality, disability, sexual orientation, or age;</li>
                <li data-custom-class="body_text" style="line-height: 1.5;">bullies, intimidates, humiliates, or insults any person;</li>
                <li data-custom-class="body_text" style="line-height: 1.5;">promotes, facilitates, or assists anyone in promoting and facilitating acts of terrorism;</li>
                <li data-custom-class="body_text" style="line-height: 1.5;">infringes, or assists anyone in infringing, a third party's intellectual property rights or publicity or privacy rights;</li>
                <li data-custom-class="body_text" style="line-height: 1.5;">is deceitful, misrepresents your identity or affiliation with any person and/or misleads anyone as to your relationship with us or implies that the Contribution was made by someone else than you;</li>
                <li data-custom-class="body_text" style="line-height: 1.5;">contains unsolicited or <bdt class="block-component"></bdt>unauthorized<bdt class="statement-end-if-in-editor"></bdt> advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation that has been <bdt class="block-component"></bdt>"paid for,"<bdt class="statement-end-if-in-editor"></bdt> whether with monetary compensation or in kind; or</li>
                <li data-custom-class="body_text" style="line-height: 1.5;">misrepresents your identity or who the Contribution is from.</li>
            </ul>
            <div style="line-height: 1.5;">
                <bdt class="block-component"></bdt>
            </div>
            <div style="line-height: 1.5;">
                <bdt class="block-component">
                    <bdt class="block-component"></bdt>
                </bdt>
            </div>
            <div style="line-height: 1.5;"><br></div>
            <div style="line-height: 1.5;"><br></div>
            <div data-custom-class="heading_1" style="line-height: 1.5;"><strong><span style="font-size: 19px;">REPORTING A BREACH OF THIS POLICY</span></strong></div>
            <div style="line-height: 1;"><br></div>
            <div data-custom-class="body_text" style="line-height: 1.5;">We may but are under no obligation to review or moderate the Contributions made on the Services and we expressly exclude our liability for any loss or damage resulting from any of our users' breach of this Policy.</div>
            <div style="line-height: 1;"><br></div>
            <div data-custom-class="body_text" style="line-height: 1.5;">If you consider that any Content or Contribution:</div>
            <ul>
                <li data-custom-class="body_text" style="line-height: 1.5;">breach this Policy, please <bdt class="block-component"></bdt>
                    <bdt class="block-component"></bdt>
                    <bdt class="block-component"></bdt> or refer to the contact details at the bottom of this document to let us know which Content or Contribution is in breach of this Policy and why; or
                </li>
                <li data-custom-class="body_text" style="line-height: 1.5;">infringe any third-party intellectual property rights, please <bdt class="block-component"></bdt>email us at <bdt class="question">javier@subpro.com</bdt>
                    <bdt class="else-block"></bdt>.
                </li>
            </ul>
            <div data-custom-class="body_text" style="line-height: 1.5;">We will reasonably determine whether a Content or Contribution breaches this Policy.</div>
            <div style="line-height: 1.5;"></div>
            <div style="line-height: 1.5;"><br></div>
            <div style="line-height: 1.5;"><br></div>
            <div data-custom-class="heading_1" style="line-height: 1.5;"><strong><span style="font-size: 19px;">CONSEQUENCES OF BREACHING THIS POLICY</span></strong></div>
            <div style="line-height: 1;"><br></div>
            <div data-custom-class="body_text" style="line-height: 1.5;">The consequences for violating our Policy will vary depending on the severity of the breach and the user's history on the Services, by way of example:</div>
            <div style="line-height: 1;"><br></div>
            <div data-custom-class="body_text" style="line-height: 1.5;">We may, in some cases, give you a warning<bdt class="block-component"></bdt> and/or remove the infringing Contribution<bdt class="statement-end-if-in-editor"></bdt>, however, if your breach is serious or if you continue to breach our Legal Terms and this Policy, we have the right to suspend or terminate your access to and use of our Services and, if applicable, disable your account. We may also notify law enforcement or issue legal proceedings against you when we believe that there is a genuine risk to an individual or a threat to public safety.&nbsp;</div>
            <div style="line-height: 1;"><br></div>
            <div data-custom-class="body_text" style="line-height: 1.5;">We exclude our liability for all action we may take in response to any of your breaches of this Policy.</div>
            <div style="line-height: 1.5;">
                <bdt class="block-component">
                    <bdt class="block-component"></bdt>
                </bdt>
            </div>
            <div style="line-height: 1.5;"><br></div>
            <div style="line-height: 1.5;"><br></div>
            <div data-custom-class="heading_1" style="line-height: 1.5;"><strong><span style="font-size: 19px;">DISCLAIMER</span></strong></div>
            <div style="line-height: 1;"><br></div>
            <div data-custom-class="body_text" style="line-height: 1.5;">
                <bdt class="question">SubPro Contracting Software</bdt> is under no obligation to monitor users’ activities, and we disclaim any responsibility for any user’s misuse of the Services. <bdt class="question">SubPro Contracting Software</bdt> has no responsibility for any user or other Content or Contribution created, maintained, stored, transmitted, or accessible on or through the Services, and is not obligated to monitor or exercise any editorial control over such material. If <bdt class="question">SubPro Contracting Software</bdt> becomes aware that any such Content or Contribution violates this Policy, <bdt class="question">SubPro Contracting Software</bdt> may, in addition to removing such Content or Contribution and blocking your account, report such breach to the police or appropriate regulatory authority. Unless otherwise stated in this Policy, <bdt class="question">SubPro Contracting Software</bdt> disclaims any obligation to any person who has not entered into an agreement with <bdt class="question">SubPro Contracting Software</bdt> for the use of the Services.
            </div>
            <div style="line-height: 1.5;"></div>
            <div style="line-height: 1.5;"><br></div>
            <div style="line-height: 1.5;"><br></div>
            <div data-custom-class="heading_1" style="line-height: 1.5;"><strong><span style="font-size: 19px;">HOW CAN YOU CONTACT US ABOUT THIS POLICY?</span></strong></div>
            <div style="line-height: 1;"><br></div>
            <div data-custom-class="body_text" style="line-height: 1.5;">If you have any further questions or comments<bdt class="block-component"></bdt> or wish to report any problematic Content or Contribution<bdt class="statement-end-if-in-editor"></bdt>, you may contact us by:</div>
            <div style="line-height: 1;"><br></div>
            <div style="line-height: 1.5;">
                <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
                <bdt class="block-component"></bdt>
            </div>
            <div style="line-height: 1.5;"><span data-custom-class="body_text">Email: <bdt class="question">javier@subpro.com</bdt>
                    <bdt class="statement-end-if-in-editor"></bdt>
                    <bdt class="block-component"></bdt>
                </span></div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({

    })
}
</script>

<style>
[data-custom-class='body'],
[data-custom-class='body'] * {
    background: transparent !important;
}

[data-custom-class='title'],
[data-custom-class='title'] * {
    font-family: Arial !important;
    font-size: 26px !important;
    color: #000000 !important;
}

[data-custom-class='subtitle'],
[data-custom-class='subtitle'] * {
    font-family: Arial !important;
    color: #595959 !important;
    font-size: 14px !important;
}

[data-custom-class='heading_1'],
[data-custom-class='heading_1'] * {
    font-family: Arial !important;
    font-size: 19px !important;
    color: #000000 !important;
}

[data-custom-class='heading_2'],
[data-custom-class='heading_2'] * {
    font-family: Arial !important;
    font-size: 17px !important;
    color: #000000 !important;
}

[data-custom-class='body_text'],
[data-custom-class='body_text'] * {
    color: #595959 !important;
    font-size: 14px !important;
    font-family: Arial !important;
}

[data-custom-class='link'],
[data-custom-class='link'] * {
    color: #3030F1 !important;
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
}

ul {
    list-style-type: square;
}

ul>li>ul {
    list-style-type: circle;
}

ul>li>ul>li>ul {
    list-style-type: square;
}

ol li {
    font-family: Arial;
}
</style>