<template>
	<div>
		<h3>{{ $t('label.task notes') }}</h3>
		<div class="table-responsive">
			<v-client-table ref="vuetable" :columns="columns" v-model="alltasknotes" :options="options">
				<template slot="created_at" slot-scope="props">
					{{ props.row.created_at | formatDate }}
				</template>
				<template slot="actions" slot-scope="props">
					<div>
						<b-dropdown toggle-class="text-decoration-none">
							<template v-slot:button-content>
								<i class="fas fa-ellipsis-v"></i>
							</template>
							<b-dropdown-item-button data-toggle="modal" :data-target="'#editTaskNotesModal_' + props.row.task_id + '_' + props.row.id" @click="editModal(props.row)">{{ $t('label.edit') }}</b-dropdown-item-button>
						</b-dropdown>
						<!-- EDIT Modal -->
						<div class="modal fade" :id="'editTaskNotesModal_' + props.row.task_id + '_' + props.row.id" tabindex="-1" role="dialog" aria-labelledby="editTaskNotesModalTitle" aria-hidden="true">
							<div class="modal-dialog" role="document">
								<div class="modal-content">
									<div class="modal-header border-bottom">
										<h5 class="modal-title" id="editTaskNotesModalTitle">{{ $t('label.edit task notes') }}</h5>
										<button type="button" class="close" data-dismiss="modal" aria-label="Close">
											<i class="ri-close-line"></i>
										</button>
									</div>
									<form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="updateTaskNote">
										<div class="modal-body">
											<div class="row">
												<div class="col-12">
													<div class="form-group">
														<label class="form-control-label text-muted" for="input-name">{{ $t('label.task notes') }}</label>
														<textarea v-model="form.edit.notes" name="notes" class="form-control" placeholder="Task Type Name" required autofocus></textarea>
													</div>
												</div>
											</div>
										</div>
										<div class="modal-footer border-top">
											<button type="submit" class="btn btn-primary mr-auto">{{ $t('label.update task note') }}</button>
											<button type="button" :class="'btn btn-link  ml-auto close2edittasknote_' + props.row.task_id + '_' + props.row.id" data-dismiss="modal">{{ $t('label.close') }}</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</template>
			</v-client-table>
		</div>
		<!-- Modal -->
		<div class="modal fade" id="addTaskNotesModal" tabindex="-1" role="dialog" aria-labelledby="addTaskNotesModalTitle" aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header border-bottom">
						<h5 class="modal-title" id="addTaskNotesModalTitle">{{ $t('label.add task notes') }}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<i class="ri-close-line"></i>
						</button>
					</div>
					<form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="saveTaskNote">
						<div class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<label class="form-control-label text-muted" for="input-name">{{ $t('label.task notes') }}</label>
										<textarea v-model="form.add.notes" name="notes" class="form-control" placeholder="Task Type Name" required autofocus></textarea>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer border-top">
							<button type="submit" class="btn btn-primary mr-auto">{{ $t('label.add task note') }}</button>
							<button type="button" class="btn btn-link  ml-auto close2addtasknote" data-dismiss="modal">{{ $t('label.close') }}</button>
						</div>
					</form>
				</div>
			</div>
		</div>

	</div>
</template>
<style>
.VueTables.VueTables--client>.row:first-child {
	display: none;
}

.dropdown-toggle::after {
	content: none;
}

.dropdown-toggle {
	background-color: transparent;
	border-color: transparent;
	color: #ced4da !important;
	padding: 0;
}

.table-bordered th,
.table-bordered td {
	background-color: #fff;
	border: none;
	border-bottom: 1px solid #e9ecef
}

.table-bordered thead th,
.table-bordered thead td {
	color: #8898aa;
	background-color: #f6f9fc;
}

.VuePagination {
	height: 75px;
	margin: 0;
}

/* .pagination {
		position: absolute;
		right: 0;
		top: 25%;
	} */
.VuePagination__count {
	position: absolute;
	left: 0;
	top: 25%;
	text-align: left !important;
	font-family: Open Sans, sans-serif;
	font-size: .875rem;
}

li.VuePagination__pagination-item.page-item a.page-link {
	z-index: 2000;
}
</style>
<script>

import * as _ from 'lodash';
export default {
	props: ['taskid', 'updatedtaskid', 'getAllTasks'],
	data() {
		return {
			alltasknotes: [],
			form: {
				add: {
					notes: null
				},
				edit: {
					notes: null,
					id: null
				}
			},
			columns: ['notes', 'created_by.name', 'created_at', 'actions'],
			options: {
				perPageValues: [],
				headings: {
					notes: this.$t('label.notes'),
					'created_by.name': this.$t('label.created by'),
					created_at: this.$t('label.date added'),
					actions: ''
				},
				editableColumns: ['notes', 'created_by.name', 'created_at'],
				sortable: ['notes', 'created_by.name', 'created_at'],
				filterable: false,
				columnsClasses: {
					notes: 'text-left',
					'created_by.name': 'text-left',
					created_at: 'text-left',
					actions: 'text-center actions-column'
				},
				perPage: 5,
			},

		}
	},
	mounted() {
		console.log('taskid', this.taskid)
		console.log('updatedtaskid', this.updatedtaskid)
		this.getTaskNotes(null);
		return _.orderBy(this.alltasknotes, ['created_at', 'desc']);
	},
	methods: {
		getTaskNotes(id) {
			axios.get('/tasknotes/' + this.taskid).then(response => {
				this.alltasknotes = response.data.result;
			})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		saveTaskNote() {
			var ref = this;
			console.log('updatedtaskid', this.updatedtaskid)
			var request_fields = {
				'notes': this.form.add.notes,
				'task_id': this.updatedtaskid
			};
			axios.post('/tasknotes', request_fields)
				.then(response => {
					this.form.add.notes = '';
					this.getAllTasks();
					console.log('this.alltasknotes', this.alltasknotes)
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
			document.querySelector('.close2addtasknote').click();

		},
		editModal(item) {
			console.log('item2', item)
			this.form.edit.notes = item.notes;
			this.form.edit.id = item.id;
			console.log('this.form', this.form)
		},
		updateTaskNote() {
			var request_fields = {
				'notes': this.form.edit.notes,
				'task_id': this.taskid,
				'id': this.form.edit.id
			};
			axios.post('/tasknotes/update', request_fields)
				.then(response => {
					// this.getTaskNotes();
					this.getAllTasks();
					console.log('this.alltasknotes', this.alltasknotes)
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
			document.querySelector('.close2edittasknote_' + this.taskid + '_' + this.form.edit.id).click();
		}
	}
}
</script>
