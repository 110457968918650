import SecureLS from 'secure-ls';
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

var ls = new SecureLS({ isCompression: false });

/**
 * Modules
 */
// import Auth from '../modules/Auth/store/index.js';
import moment from '@/utils/date-time';
import actions from './actions';
import getters from './getters';
import Chat from './modules/Chat';
import Countries from './modules/Countries';
import Country from './modules/Country';
import Customer from './modules/Customer';
import Job from './modules/Job';
import PurchaseOrder from './modules/PurchaseOrder';
import Role from './modules/Role';
import Task from './modules/Task';
import Time from './modules/Time';
import User from './modules/User';
import mutations from './mutations';

Vue.use(Vuex);

let user = JSON.parse(localStorage.getItem('user'));
let date = user ? moment().tz(user.timezone) : moment();

export const store = new Vuex.Store({
    state: {
        user: {},
        users: {
            allemployees: []
        },
        company: {},
        states: [],
        countries: [],
        messages: {
            messageId: 0,
            notif: {},
            all: {},
            type: null,
            recipients: {
                roles: {
                    label: 'Company Roles',
                    name: 'roles',
                    data: [],
                },
                crews: {
                    label: 'Crews',
                    name: 'crews',
                    data: [],
                },
                employees: {
                    label: 'Employees',
                    name: 'employees',
                    data: [],
                },
            },
            isPreview: false,
            form: {
                body: null,
                title: null,
                image: null,
                file: null,
                btnAction: 'close',
                btnLabel: 'Close',
                btnUrl: null,
                recipients: [],
                date: date.format('MM/DD/YYYY'),
                hour: parseInt(date.format('hh')),
                min: Math.round(date.format('mm') / 10) * 10,
                am: date.format('a'),
                selection: {
                    showScheduleOption: 0,
                    showActionBtn: 0,
                    showActionBtnUrl: 0,
                    showEmployeeList: 0,
                },
                saveStatus: null,
                showDropzone: true,
            },
        },
        isSyncToQb: {
            isSyncResult: null,
            isSyncing: null
        },
        job: {
            notes: [],
            files: [],
        },
        coordinates: {
            latitude: 0,
            longitude: 0,
        },
    },
    actions,
    mutations,
    getters,
    modules: {
        chat: Chat,
        time: Time,
        userData: User,
        jobData: Job,
        taskData: Task,
        purchaseOrder: PurchaseOrder,
        roleData: Role,
        customerData: Customer,
        countries: Country,
        countryData: Countries,
    },
    plugins: [
        createPersistedState({
            reducer(val) {
                const token = localStorage.getItem('_token');
                return token ? val : {};
            },
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key)
            }
        })
    ]
});
