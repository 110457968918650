<template>
    <AppLayout>
        <!-- comment -->
        <fade-transition origin="center" mode="out-in" :duration="250">
            <router-view class="pb-5"></router-view>
        </fade-transition>
    </AppLayout>
</template>

<style scoped>
/* Add content margin when sidebar is displayed */
.content-margin {
    margin-left: 250px;
}

/* Expand the content to full screen when sidebar is hidden */
@media only screen and (max-width: 1199px) {
    .content-margin {
        margin-left: 0;
    }
}
</style>
<script>
import AppLayout from '@/layouts/AppLayout';
import { FadeTransition } from "vue2-transitions";
import ServiceWorkerStatus from './plugins/service-worker-status';

export default {
    components: {
        AppLayout,
        FadeTransition,
    },
    data() {
        return {
            worker: ServiceWorkerStatus
        }
    },
    watch: {
        worker: {
            handler(value) {
                this.serviceWorkerStatus(value);
            },
            deep: true
        }
    },
    created() {
        //
    },
    methods: {
        serviceWorkerStatus({ message }) {
            if (message) {
                this.$bus.$emit('notify-me', {
                    status: 'alert-warning',
                    data: {
                        title: 'Application Status',
                        text: message
                    }
                });
            }
        }
    }
}
</script>