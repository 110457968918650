<template>
    <div>
        <div class="modal-content">
            <div class="modal-header border-bottom">
                <h5 class="modal-title" id="viewAllJobTimeModal1Title">{{ $t('label.job time') }}</h5>
                <button type="button" class="close" @click="$emit('closeFunc')">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="row align-items-center border-bottom m-0 p-4">
                    <div class="ml-3">
                        <h4 class="font-weight-normal m-0">{{ $t('label.Each Task is listed below with the associated amount of time clocked to each Task') }}.</h4>
                    </div>
                </div>
                <div class="">
                    <table class="table table-flush table-sm" id="datatable">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col" class="text-left pl-5" style="width: 40%">{{ $t('label.task assignee') }}</th>
                                <th scope="col" style="width: 20%">{{ $t('label.status') }}</th>
                                <th scope="col" class="text-center" style="width: 20%">{{ $t('label.total time') }}</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(data, index) in allJobTime" :key="index">
                                <td colspan="4" class="p-0">
                                    <table class="table table-sm table-flush text-center mb-0" style="background-color: #fff !important;" id="datatable-basic">
                                        <thead class="thead-light" @click="showTable(data.task_id)">
                                            <tr class="tgroup">
                                                <td style="width: 50%">
                                                    <h3 class="text-left text-white mb-0 font-weight-normal">
                                                        <i class="ri-arrow-down-s-line" :id="'arrow_' + data.task_id"></i>
                                                        <span class="text-left">{{ data.task_name }}</span>
                                                    </h3>
                                                </td>
                                                <td style="width: 20%"></td>
                                                <td style="width: 20%">
                                                    <h3 class="text-center text-white mb-0 font-weight-normal">{{ data.overall_time | hourToFormattedTime }} ({{ data.overall_time }} {{ $t('label.hrs') }})</h3>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody :id="'showRecord_' + data.task_id">
                                            <tr style="border-top: 1px solid #e9ecef;">
                                                <td colspan="6" style="padding: 0 !important;">
                                                    <timesheet-proximity-component :showProximityFlag="true" :timePunches="data.crewMembers" :task="data.crewMembers ? data.crewMembers[0].task : []" :viewTaskTime_timer="viewTaskTime_timer" :checkpending="checkpending" flag="list" source="viewJobAllTime" @clockoutNotify="clockoutNotify" @openReviseTimeModal="openReviseTimeModal" @clockInFunc="clockIn">
                                                    </timesheet-proximity-component>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                        <thead class="thead-light">
                            <tr>
                                <th scope="col" colspan="2" class="text-right">{{ $t('label.total job time') }}</th>
                                <th scope="col" class="text-center">
                                    <h4 class="mb-0">{{ totalJobTime | hourToFormattedTime }} ({{ totalJobTime }} {{ $t('label.hrs') }})</h4>
                                </th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
            <div class="modal-footer border-top">
                <button type="button" class="btn btn-link ml-auto close2edittask" @click="$emit('closeFunc')">{{ $t('label.close') }}</button>
            </div>
        </div>

        <!-- Revise Time Modal -->
        <modal class="pb-6" name="reviseTimeModal_tsheet" height="auto" width="98%" :max-width="1024" :scrollable="true" :reset="true" :adaptive="true">
            <ReviseTimeModal v-if="dataSelected" :data-selected="dataSelected" @cancelFnc="$modal.hide('reviseTimeModal_tsheet')" @reload="viewAllJobTime"></ReviseTimeModal>
        </modal>

        <!-- Clockout Modal -->
        <modal-clockout-component v-if="showClockout" :username="username" @onClose="clockoutNotify" @customClockoutMethod="clockOut">
        </modal-clockout-component>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ReviseTimeModal from '../components/TaskOffCanvas/modals/ReviseTimeModal.vue';
import ModalClockout2 from './ModalClockout2.vue';
;

export default {
    props: ['jobid', 'user'],
    emits: ['closeFunc', 'reload'],
    components: {
        ModalClockoutComponent: ModalClockout2,
        ReviseTimeModal
    },
    data() {
        return {
            allJobTime: [],
            totalJobTime: 0,
            jobNumber: 0,
            viewTaskTime_timer: [],
            checkpending: false,
            showClockout: false,
            username: '',
            timepunch_id: null,
            dataSelected: [],
        }
    },
    computed: {
        ...mapGetters({
            coordinates: 'coordinates',
        }),
    },
    mounted() {
        this.viewAllJobTime()
    },
    methods: {
        async viewAllJobTime() {
            this.loader = this.$loading.show();
            var request_fields = {
                'job_id': this.jobid,
            };
            await axios.post('/view-all-job-time', request_fields).then(response => {
                this.allJobTime = response.data.result;
                this.jobNumber = response.data.jobNumber;
                this.totalJobTime = response.data.overall_time;
                this.loader.hide();
            }).catch(err => {
                //console.log('list carriers error:', error)
            });
        },
        showTable(task_id) {
            $('#showRecord_' + task_id).toggle();

            if ($('#arrow_' + task_id).hasClass('ri-arrow-down-s-line')) {
                $('#arrow_' + task_id).removeClass('ri-arrow-down-s-line').addClass('ri-arrow-right-s-line');
            } else {
                $('#arrow_' + task_id).removeClass('ri-arrow-right-s-line').addClass('ri-arrow-down-s-line');
            }
        },
        clockIn(task, data) {
            var request_fields = {
                'task_id': task.id,
                'user_id': data.user.id,
                'login_location': this.coordinates,
            };
            this.$store.dispatch('time/timeIn', request_fields).then(response => {
                this.viewAllJobTime()
                this.$store.dispatch('time/getUserCurrentClockin')
            })
        },
        clockoutNotify(data) {
            console.log('data', data)
            this.timepunch_id = data ? data.timestatus.id : null;
            this.username = data ? data.user.name : null;
            this.showClockout = !this.showClockout;
        },
        clockOut() {
            var request_fields = {
                'time_id': this.timepunch_id,
                'logout_location': this.coordinates,
            };
            this.$store.dispatch('time/timeOut', request_fields).then(response => {
                this.viewAllJobTime()
                this.$store.dispatch('time/getUserCurrentClockin')
            })
        },
        openReviseTimeModal(data) {
            console.log('data', data)
            this.dataSelected = data;
            this.dataSelected.username = data.user.name;
            this.dataSelected.userpicture = data.user.picture;
            this.dataSelected.task_manager = data.task_manager;

            this.$modal.show('reviseTimeModal_tsheet');
        },
    },
}
</script>
