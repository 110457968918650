import i18n from '@/plugins/i18n.plugin';
import AuthService from '@/services/AuthService';

/* eslint-disable */
const actions = {
  login({ state, commit, dispatch }, { data, config }) {
    return AuthService.login(data, config).then(res => {
      // state.AppActiveUser = res.data.user;
      commit('UPDATE_USER_INFO', res.data.user)
      // dispatch('Auth/getUserLocalStrings', null, { root: true })
      return res;
    })
  },

  updateDomainData({ state, commit }, payload) {
    commit('UPDATE_DOMAIN', payload);
  },

  updateUserInfo({ commit }, payload) {
    commit('UPDATE_USER_INFO', payload);
  },

  setRouteGuardLoading({ commit }, isLoading) {
    commit('AUTH_ROUTE_GUARD_LOADING', isLoading);
  },

  checkCustomerTokenExpiration({ commit, dispatch }, customerToken) {
    return customerToken ? true : false;
  },

  async getCurrentUser({ state, commit, dispatch }) {
    return new Promise(async (resolve) => {
      if (localStorage.getItem('_token')) {
        await AuthService.getCurrentUser().then((res) => {
          commit('UPDATE_USER_INFO', res.data.data)
          resolve(res?.data?.data);
        });
      } else {
        resolve(null);
      }
    });
  },

  async logout({ commit, dispatch }) {
    await localStorage.removeItem('_token');
    await localStorage.removeItem('vuex');
    await localStorage.clear();

    commit('AUTH_SUCCESS', {});
    commit('LOGOUT');
    console.log('User logged out.');
  },

  async getUserLocalStrings({ state, commit, dispatch }, payload) {
    return await AuthService.getUserLocal(payload).then(async response => {
      const res = response.data;
      await localStorage.setItem('_local', JSON.stringify(res.data));
      await i18n.setLocaleMessage(payload.language, res.data);
      i18n.locale = payload.language;
      console.log(i18n);
      await dispatch('Auth/setUserLocal', res.data, { root: true })
      return res;
    })
  },

  async setUserLocal({ state, commit, dispatch }, payload) {
    let _local = localStorage.getItem('_local');
    _local = JSON.parse(_local);
    if (_local) {
      state.local = _local;
    } else {
      await dispatch('Auth/getUserLocalStrings', null, { root: true })
    }
  },

  setLoading({ state, commit, dispatch }, payload) {
    state.isLoading = payload;
  }

};

export default actions;
