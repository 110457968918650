import Vue from 'vue';

const _vue = new Vue();

export const busPlugin = {
    install(app, options) {
        Vue.prototype.$bus = _vue;
    }
};

export default _vue;