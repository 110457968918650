import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import Vue from 'vue';
import VueEcho from 'vue-echo';

window.Pusher = Pusher;

export const registerEcho = () => {
    const echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_PUSHER_APP_KEY,
        cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
        forceTLS: true,
        encrypted: true
    });
    Vue.use(VueEcho, echo);
}
