<template>
    <div>
        <div class="row m-0 mb-2 align-items-center task-item" :class="{ 'unassigned-task': !task.assigned_to.length }" :id="'TaskItem' + task.id" @mouseenter="editDatesBtnVisible = true" @mouseleave="editDatesBtnVisible = false">
            <div :id="'task-' + task.id" class="p-0" :class="viewType == 'week' && task.date_range_overlap <= 1 ? 'col-10' : 'col'">
                <!-- WEEK VIEW -->
                <div v-if="view == 'week'" style="height: 92px">
                    <div class="d-flex mt--2">
                        <div class="align-items-center sp-ellipsis-text">
                            <div class="task-name cursor-pointer" @click="launchTaskOffCanvas">{{ task.name }}</div>
                            <div v-if="task.job" class="task-date sp-ellipsis-text">{{ task.job.job_name }}</div>
                        </div>
                        <div v-if="task.task_status" class="d-flex ml-auto align-items-center badge badge-dot mt--3">
                            <i :style="`background-color: ${task.task_status.status_color}`"></i>
                            <span class="sp-ellipsis-text">{{ task.task_status.name }}</span>
                        </div>
                    </div>
                    <div class="mb--1 d-flex">
                        <!-- Date range and time estimate-->
                        <div class="sp-ellipsis-text">
                            <div v-if="task.job" class="task-date sp-ellipsis-text">{{ task.job.customer ? task.job.customer.name : '&nbsp;' }}</div>
                            <div class="task-date sp-ellipsis-text">{{ task.date_range_text }}</div>
                            <div class="task-date">{{ task.time_estimate }} hrs</div>
                        </div>
                        <!-- Assignees -->
                        <div class="ml-auto text-xs mt-1">
                            <template v-if="task.assigned_to.length > 0">
                                <div v-if="daysToOverlay > 1" class="">
                                    <!-- Crew Name -->
                                    <div class="ml-auto pb-1 mb-1">
                                        <span class="float-right">
                                            &nbsp;
                                            <span v-if="task.assigned_to[0].user.crew_assignments.length">
                                                <span class="text-sm">{{ task.assigned_to[0].user.crew_assignments[0].crew.name }}</span>
                                            </span>
                                        </span>
                                    </div>
                                    <!-- Assignees w/ image and name -->
                                    <div class="p-0 m-0 d-flex float-right">
                                        <span v-for="(user, idx) in task.assigned_to" :key="idx" class="d-flex align-items-center">
                                            <span :id="'user-' + task.id + '-' + idx" class="sp-tooltip">
                                                <img :src="user.user.picture" class="avatar-img rounded-circle pointer" :class="{ 'sp-portrait-border': user.task_manager == '1' }" style="height: 30px; width: 30px" />
                                                <span :class="(showTooltipLeft(idx) ? 'user-tooltiptext-left' : 'sp-tooltiptext mb--1')">{{ user.user.name }}</span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div v-else class="ml-auto mt-2">
                                    <!-- Smaller view so use popover/tooltip view -->
                                    <div>&nbsp;</div>
                                    <div class="sp-tooltip sm-assignes-pos">
                                        <div class="cursor-pointer sp-ellipsis-text crew-name">{{ $t('label.assignees') }}</div>
                                        <span class="sp-tooltiptext align-items-center assignee-tt-window" :class="{ 'tooltip-left': isSameDay(task.due_date, dateRange[6]) }">
                                            <!-- Crew Name -->
                                            <div v-if="task.assigned_to[0].user.crew_assignments.length">
                                                <span class="d-flex text-sm border-bottom mb-1 justify-content-center">{{ task.assigned_to[0].user.crew_assignments[0].crew.name }}</span>
                                            </div>
                                            <!-- Assignees w/ image and name -->
                                            <div v-for="(user, idx) in task.assigned_to" :key="idx" class="d-flex align-items-center py-1 float-left text-left">
                                                <img :src="user.user.picture" class="p-0 my--2 avatar-img rounded-circle pointer" :class="{ 'sp-portrait-border': user.task_manager == '1' }" style="height: 25px; width: 25px" />
                                                &nbsp;<span class="text-sm pl-1">{{ user.user.name }}</span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="pt-4 mt-2" style="white-space: nowrap;">{{ $t('label.no assignees') }}</div>
                            </template>
                        </div>
                        <!-- Edit Date Button -->
                        <template v-if="editDatesBtnVisible">
                            <div v-if="task.time_punches_count == 0 && task.task_status.name != 'Completed'" class="d-flex align-items-center badge badge-sm bg-warning edit-date-overlay text-white pointer" @click="editDatesModalVisible = true">
                                <i class="ri-pencil-line"></i> {{ $t('label.edit date') }}
                            </div>
                            <div v-else class="d-flex align-items-center badge badge-sm bg-light text-white sp-tooltip edit-date-overlay" style="cursor: default">
                                <i class="ri-pencil-line"></i> {{ $t('label.edit date') }}
                                <span v-if="task.task_status.name == 'Completed'" class="text-xs w-100-ws-nrml" :class="{ 'user-tooltiptext-left mnthDsblEditL': !showTooltipRight(), 'user-tooltiptext-right mnthDsblEditR': showTooltipRight() }">
                                    {{ $t('label.task has been marked as completed') }}
                                </span>
                                <span v-else-if="task.time_punches_count > 0" class="text-xs w-100-ws-nrml" :class="{ 'user-tooltiptext-left mnthDsblEditL': !showTooltipRight(), 'user-tooltiptext-right mnthDsblEditR': showTooltipRight() }">
                                    {{ $t('label.time has been logged to this task') }}.
                                </span>
                            </div>
                        </template>
                    </div>
                </div>

                <!-- DAY VIEW -->
                <template v-else>
                    <!-- EXPANDED DAY VIEW -->
                    <div v-if="expandedView">
                        <!-- Task Name, Job Name, Customer Name and Status -->
                        <div class="d-flex align-items-center mt--2">
                            <div class="mr-auto d-flex align-items-center">
                                <span class="task-name cursor-pointer" @click="launchTaskOffCanvas">{{ task.name }}</span>
                                <span v-if="task.job" class="task-job-name">
                                    <span class="badge badge-dot"><i class="bg-gray mx-2"></i></span>
                                    {{ task.job.job_name }}
                                    <span class="badge badge-dot"><i class="bg-gray mx-2"></i></span>
                                    {{ task.job.customer ? task.job.customer.name : '&nbsp;' }}
                                </span>
                            </div>
                            <div class="ml-auto">
                                <span v-if="task.task_status" class="badge badge-dot">
                                    <i :style="`background-color: ${task.task_status.status_color}`"></i> {{ task.task_status.name }}
                                </span>
                            </div>
                        </div>
                        <!-- Assigned Crew and Users -->
                        <div class="d-flex align-items-center">
                            <div v-if="task.assigned_to.length" class="d-flex align-items-center">
                                <template v-if="task.assigned_to[0].user.crew_assignments.length">
                                    <span class="text-sm pr-2">{{ task.assigned_to[0].user.crew_assignments[0].crew.name }}:</span>
                                </template>
                                <div class="pr-2" v-for="(user, idx) in task.assigned_to" :key="idx">
                                    <span v-if="user.task_manager == '1'" class="sp-tooltip">
                                        <img :id="'user' + user.user.id" :src="user.user.picture" class="p-0 my--2 avatar-img rounded-circle" :class="{ 'sp-portrait-border': user.task_manager == '1' }" style="height: 30px; width: 30px" />
                                        <span class="sp-tooltiptext text-sm">{{ $t('label.task manager') }}</span>
                                    </span>
                                    <span v-else>
                                        <img :id="'user' + user.user.id" :src="user.user.picture" class="p-0 my--2 avatar-img rounded-circle" :class="{ 'sp-portrait-border': user.task_manager == '1' }" style="height: 30px; width: 30px" />
                                    </span>
                                    <span class="text-sm">{{ user.user.name }}</span>
                                </div>
                            </div>
                            <div v-else class="text-xs">
                                {{ $t('label.no assignees') }}
                            </div>
                        </div>
                        <!-- Task Date Range and Estimated Hours -->
                        <div class="d-flex align-items-center justify-content-between mb--2">
                            <span class="task-date">{{ task.date_range_text }}</span>
                            <span class="task-date">{{ task.time_estimate }} hours</span>
                        </div>
                        <!-- Edit Date Button -->
                        <template v-if="editDatesBtnVisible">
                            <div v-if="task.time_punches_count == 0 && task.task_status.name != 'Completed'" class="d-flex align-items-center badge badge-sm bg-warning edit-date-overlay text-white pointer" @click="editDatesModalVisible = true">
                                <i class="ri-pencil-line"></i> {{ $t('label.edit date') }}
                            </div>
                            <div v-else class="d-flex align-items-center badge badge-sm bg-light text-white sp-tooltip edit-date-overlay" style="cursor: default">
                                <i class="ri-pencil-line"></i> {{ $t('label.edit date') }}
                                <span v-if="task.task_status.name == 'Completed'" class="text-xs w-100-ws-nrml user-tooltiptext-left mnthDsblEditL">
                                    {{ $t('label.task has been marked as completed') }}
                                </span>
                                <span v-else-if="task.time_punches_count > 0" class="text-xs w-100-ws-nrml user-tooltiptext-left mnthDsblEditL">
                                    {{ $t('label.time has been logged to this task') }}.
                                </span>
                            </div>
                        </template>
                    </div>
                    <!-- COLLAPSED DAY VIEW -->
                    <div v-if="!expandedView">
                        <div class="row d-flex">
                            <div class="col-12 col-md-8 d-flex align-items-center">
                                <!-- Task and Job Name -->
                                <div class="task-name cursor-pointer" @click="launchTaskOffCanvas">{{ task.name }}</div>
                                <div class="badge badge-dot"><i class="bg-gray mx-2"></i></div>
                                <div v-if="task.job" class="d-flex align-items-center task-job-name">
                                    {{ task.job.job_name }}
                                </div>
                                <!-- Crew and Assignees -->
                                <div class="badge badge-dot"><i class="bg-gray mx-2"></i></div>
                                <div v-if="task.assigned_to.length" class="p-0 d-flex align-items-center mr-auto">
                                    <template v-if="task.assigned_to[0].user.crew_assignments.length">
                                        <span class="text-sm pr-2">{{ task.assigned_to[0].user.crew_assignments[0].crew.name }}:</span>
                                    </template>
                                    <div v-for="(user, idx) in task.assigned_to" :key="idx" class="sp-tooltip">
                                        <CloseOverlayBtn :elmId="'task' + task.id + 'user' + user.user.id" :dataToTransmit="user" @onClose="showRemoveAssigneeModal">
                                            <img :id="'task' + task.id + 'user' + user.user.id" :src="user.user.picture" class="p-0 my--2 avatar-img rounded-circle pointer" :class="{ 'sp-portrait-border': user.task_manager == '1' }" style="height: 30px; width: 30px" />
                                        </CloseOverlayBtn>
                                        <span class="sp-tooltiptext">
                                            {{ user.user.name }}
                                            <div v-if="user.task_manager == '1'" class="text-xs">
                                                ({{ $t('label.task manager') }})
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div v-else class="text-xs">
                                    {{ $t('label.no assignees') }}
                                </div>
                            </div>
                            <!-- Start/Due Date, Estimated Time, and Task Status -->
                            <div class="col-12 col-md-4">
                                <div class="d-flex align-items-center float-right sp-mobile-float-left">
                                    <span class="task-date"><span>{{ task.date_range_text }}</span></span>
                                    <span class="badge badge-dot"><i class="bg-gray mx-2"></i></span>
                                    <span class="task-date">{{ task.time_estimate }} hours</span>
                                    <span class="badge badge-dot"><i class="bg-gray mx-2"></i></span>
                                    <span v-if="task.task_status" class="d-flex align-items-center badge badge-dot">
                                        <i :style="`background-color: ${task.task_status.status_color}`"></i> {{ task.task_status.name }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- Edit Date Button -->
                        <template v-if="editDatesBtnVisible">
                            <div v-if="task.time_punches_count == 0 && task.task_status.name != 'Completed'" class="d-flex align-items-center badge badge-sm bg-warning edit-date-overlay dc-overlay text-white pointer" @click="editDatesModalVisible = true">
                                <i class="ri-pencil-line"></i> {{ $t('label.edit date') }}
                            </div>
                            <div v-else class="d-flex align-items-center badge badge-sm bg-light text-white sp-tooltip edit-date-overlay dc-overlay" style="cursor: default">
                                <i class="ri-pencil-line"></i> {{ $t('label.edit date') }}
                                <span v-if="task.task_status.name == 'Completed'" class="text-xs w-100-ws-nrml user-tooltiptext-left mnthDsblEditL">
                                    {{ $t('label.task has been marked as completed') }}
                                </span>
                                <span v-else-if="task.time_punches_count > 0" class="text-xs w-100-ws-nrml user-tooltiptext-left mnthDsblEditL">
                                    {{ $t('label.time has been logged to this task') }}.
                                </span>
                            </div>
                        </template>
                    </div>
                </template>
            </div>
        </div>

        <!-- Edit Task Dates Modal -->
        <EditTaskDatesModal v-if="editDatesModalVisible" :task="task" @onChange="showShiftAllTasksModal" @onClose="editDatesModalVisible = false">
        </EditTaskDatesModal>

        <!-- Remove Assignee Modal -->
        <DeleteModal v-if="deleteModalActive" :newId="'taskItemRemoveAssignee'" :btnText="$t('label.remove user')" :title="$t('label.ARE YOU SURE YOU WANT TO REMOVE THIS USER FROM THIS TASK')" @customDeleteMethod="removeAssignee()" @onClose="deleteModalActive = false">
        </DeleteModal>

        <!--Move Date Confirmation Modal -->
        <ShiftAllTaskDatesModal id="calendarMoveDateConf" key="calendarMoveDateConf" v-if="showModalMoveDateConf" :new-id="view" :selected-task="alterTask" @onClose="showModalMoveDateConf = false" @customUpdateMethod="saveTaskDates">
        </ShiftAllTaskDatesModal>
    </div>
</template>

<style scoped>
.edit-date-overlay {
    position: absolute;
    transform: translateX(-95%);
    top: -5px;
    left: 100%;
}

.dc-overlay {
    top: 1px !important;
}

.sp-tooltip .user-tooltiptext-left {
    text-transform: none;
    visibility: hidden;
    white-space: nowrap;
    width: auto;
    background-color: #adb5bd;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 100;
    transform: translateX(-100%) translateY(0%);
    left: -7px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.5s;
}

.sp-tooltip .user-tooltiptext-left {
    bottom: 3%;
    transform: translateX(-100%);
    left: -10px;
}

.sp-tooltip .user-tooltiptext-left::after {
    content: "";
    position: absolute;
    bottom: 35%;
    transform: translateX(45%);
    left: 100%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #adb5bd;
}

.sp-tooltip:hover .user-tooltiptext-left {
    z-index: 1000;
    visibility: visible;
    opacity: 1;
}

.sp-tooltip .user-tooltiptext-right {
    text-transform: none;
    visibility: hidden;
    white-space: nowrap;
    width: auto;
    background-color: #adb5bd;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 100;
    transform: translateX(100%) translateY(0%);
    left: -7px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.5s;
}

.sp-tooltip .user-tooltiptext-right {
    bottom: 3%;
    transform: translateX(-100%);
    left: -10px;
}

.sp-tooltip .user-tooltiptext-right::after {
    content: "";
    position: absolute;
    bottom: 35%;
    transform: translateX(-40%);
    left: -5%;
    margin-right: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #adb5bd transparent transparent;
}

.sp-tooltip:hover .user-tooltiptext-right {
    z-index: 1000;
    visibility: visible;
    opacity: 1;
}

.mnthDsblEditL {
    transform: translateY(20%) translateX(-100%) !important;
}

.mnthDsblEditR {
    transform: translateY(20%) translateX(100%) !important;
}

.w-100-ws-nrml {
    width: 100px !important;
    white-space: normal !important;
}

.tooltip-left {
    left: 5%;
}

.assignee-tt-window {
    max-width: 145px;
    inline-size: 145px;
    white-space: normal
}

.sm-assignes-pos {
    margin-top: 7px
}
</style>

<script>
import CloseOverlayBtn from "@/components/CloseOverlayBtn.vue";
import DeleteModal from "@/components/ModalDelete2.vue";
import ModalMoveDateConfirmation from '@/components/ModalMoveDateConfirmation.vue';
import EditTaskDatesModal from '@/components/Tasks/EditTaskDatesModal.vue';
import bus from '@/utils/event-bus';

export default {
    props: ['task', 'expandedView', 'view', 'viewType', 'daysToOverlay', 'dateRange'],
    emits: ['reloadNavData', 'parentSaveTaskDatesFnc'],
    components: {
        DeleteModal,
        CloseOverlayBtn,
        EditTaskDatesModal,
        ShiftAllTaskDatesModal: ModalMoveDateConfirmation
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            companySettings: JSON.parse(localStorage.getItem('company_settings')),
            alterTask: null,
            selectedAssignee: null,
            updateTaskDatesData: null,
            deleteModalActive: false,
            editDatesBtnVisible: false,
            editDatesModalVisible: false,
            showModalMoveDateConf: false,
        }
    },
    computed: {},
    mounted() {
        this.task.show_drag_icon = true;
    },
    methods: {
        showRemoveAssigneeModal(assignee) {
            this.selectedAssignee = assignee;
            if (assignee.task_manager == '1' && this.task.assigned_to.length > 1) {
                bus.$emit('banner-error', {
                    message: this.$t("label.Please remove all other task assignees before removing the task manager")
                });
            } else {
                this.deleteModalActive = true;
            }
        },
        removeAssignee() {
            let request_data = {
                taskId: this.task.id,
                assigneeId: this.selectedAssignee.user.id
            };

            axios.post(`/remove-assignee-from-task`, request_data).then(response => {
                this.task.assigned_to = response.data.result;
                this.$emit('reloadNavData');
                bus.$emit('banner-success', {
                    message: this.$t('label.task assignee removed successfully') + '.'
                });
            }).catch(err => {
                if (err.response.data.result == 'time_logged') {
                    bus.$emit('banner-error', {
                        message: this.$t("label.User has already logged time to this task and cannot be removed")
                    });
                }
            });
        },

        isSameDay(dt1, dt2) {
            return moment(dt1).format('MM/DD/YYYY') == moment(dt2).format('MM/DD/YYYY');
        },

        showTooltipRight() {
            let dueDt = moment(this.task.due_date).format('MM/DD/YYYY'),
                dateDiff = (moment(dueDt).diff(this.dateRange[0], 'minutes'));

            return dateDiff == 0;
        },

        showTooltipLeft(userIdx) {
            let dueDate = moment(this.task.due_date).format('MM/DD/YYYY'),
                endOfWeek = (moment(dueDate).diff(this.dateRange[6], 'minutes')),
                numAssignees = this.task.assigned_to.length;

            return (endOfWeek >= 0) && ((numAssignees - userIdx - 1) < 2);
        },
        showTooltipDirection(userIdx) {
            let dueDate = moment(this.task.due_date).format('MM/DD/YYYY'),
                endOfWeek = (moment(dueDate).diff(this.dateRange[6], 'minutes')),
                numAssignees = this.task.assigned_to.length;

            return (endOfWeek >= 0) && ((numAssignees - userIdx - 1) < 2);
        },

        showShiftAllTasksModal(taskData) {
            let currEnd = moment(moment(this.task.due_date).format('MM/DD/YYYY')),
                newEnd = moment(taskData.due_date),
                dateDiff = newEnd.diff(currEnd, 'days');

            this.alterTask = JSON.parse(JSON.stringify(this.task));
            this.$set(this.alterTask, 'date_diff', dateDiff);
            this.$set(this.alterTask, 'old_start_date', taskData.old_start_date);
            this.$set(this.alterTask, 'start_date', taskData.start_date);
            this.updateTaskDatesData = taskData;
            this.editDatesModalVisible = false;

            if (dateDiff == 0 || isNaN(dateDiff))
                this.saveTaskDates(false);
            else
                this.showModalMoveDateConf = true;
        },
        saveTaskDates(jobTasksShifted) {
            let fullListReload = jobTasksShifted;
            if (!this.updateTaskDatesData.start_date && !this.updateTaskDatesData.due_date)
                fullListReload = true;

            this.$emit('parentSaveTaskDatesFnc', this.updateTaskDatesData, fullListReload);
        },

        launchTaskOffCanvas() {
            bus.$emit('openTaskOffCanvas', this.task.id, this.view + 'Calendar');
        },
    },
};
</script>

<style>
.droppable {
    cursor: copy
}
</style>
