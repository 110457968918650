<template>
	<div class="row mb-5 lead-settings">
		<!-- <div class="vld-parent">
			<loading :active.sync="isLoading" loader="dots" background-color=#000 blur=0px :is-full-page="true"></loading>
		</div> -->
		<div class="col">
			<div class="card">
				<div class="card-header">
					<div class="row m-0 align-items-center justify-content-between">
						<div>
							<h3 class="mb-0 d-flex align-items-center"> {{ $t('label.chat settings') }}</h3>
						</div>
						<div>
							<button type="button" class="btn btn-sm btn-default m-0" data-toggle="modal" :data-target="'#channelModal'" @click="addChannel">
								{{ $t('label.add channel') }}
							</button>
						</div>
					</div>
				</div>
				<div class="">
					<div class="pt-3 px-4">
						<h3>{{ $t('label.company channels') }}</h3>
						<!-- <p>{{ $t('label.Create channels for your company. Invite all company employees or only specific people to each channel.') }}</p> -->
						<p>Create channels for your company. Invite all company employees or only specific people to each channel.</p>
					</div>
					<div class="table-responsive pb-4">
						<table class="table table-flush" id="datatable-chat-channels">
							<thead class="thead-light">
								<tr>
									<th scope="col">{{ $t('label.channel name') }}</th>
									<th scope="col">{{ $t('label.members') }}</th>
									<th scope="col">{{ $t('label.status') }}</th>
									<th scope="col" width="10%"></th>
								</tr>
							</thead>
							<tbody>
								<tr class="row1" v-for="(data, index) in allChannels" :key="index" :data-id="data.id">
									<td># {{ data.name }}</td>
									<td>{{ data.users_count }} {{ data.users_count > 1 ? 'Members' : 'Member' }}</td>
									<td>
										<span v-if="data.status == 1" class="badge badge-dot">
											<i class="bg-success"></i> Active
										</span>
										<span v-else class="badge badge-dot">
											<i class="bg-success" style="background-color: var(--gray) !important"></i> Archived
										</span>
									</td>
									<td class="text-right">
										<div class="dropdown">
											<a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<i class="fas fa-ellipsis-v"></i>
											</a>
											<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
												<a class="dropdown-item text-default pointer" data-toggle="modal" data-target="#channelModal" @click="editChannelModal(data)">
													<i class="ri-pencil-line"></i> Edit Channel
												</a>
												<a v-if="data.status == 1" class="dropdown-item text-default pointer" @click="deleteNotify(data.id, 0)">
													<i class="ri-archive-line"></i> Archive Channel
												</a>
												<a v-else class="dropdown-item text-default pointer" @click="deleteNotify(data.id, 1)">
													<i class="ri-inbox-unarchive-line"></i> Unarchive Channel
												</a>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="text-center">
				<button type="submit" class="btn btn-primary" @click="saveSettings()">{{ $t('label.save settings') }}</button>
			</div>
		</div>
		<div class="modal fade" id="channelModal" tabindex="-1" role="dialog" aria-labelledby="channelModal" aria-hidden="true">
			<div class="modal-dialog" role="document" style="max-width: 800px">
				<div class="modal-content">
					<div class="modal-header border-bottom">
						<h5 class="modal-title" id="channelModalTitle">Add Channel</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<i class="ri-close-line"></i>
						</button>
					</div>
					<form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="saveChannel">
						<div class="modal-body px-0 py-3">
							<div class="row m-0">
								<div class="col-12 col-lg-6">
									<div class="form-group text-left">
										<label class="form-control-label text-muted">{{ $t('label.channel name') }}</label>
										<input type="text" name="name" class="form-control" :placeholder="$t('label.name')" v-model="form.name" required autofocus>
									</div>
								</div>
								<div class="col-12 col-lg-6">
									<div class="form-group">
										<label class="form-control-label text-muted">{{ $t('label.channel status') }}</label>
										<div class="row m-0 py-1">
											<div class="custom-control custom-radio mr-3">
												<input name="channel_status" id="statusYes" class="custom-control-input" value="1" type="radio" v-model="form.channel_status" required>
												<label class="custom-control-label" for="statusYes">{{ $t('label.active') }}</label>
											</div>
											<div class="custom-control custom-radio">
												<input name="channel_status" id="statusNo" class="custom-control-input" value="0" type="radio" v-model="form.channel_status" required>
												<label class="custom-control-label" for="statusNo">{{ $t('label.inactive') }}</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row m-0">
								<div class="col-12">
									<div class="form-group members-list">
										<label class="form-control-label text-muted">{{ $t('label.add members') }}</label>
										<i class="ri-search-line search-icon"></i>
										<multiselect placeholder="" label="name" track-by="id" v-model="addedUsers" :options="allUsers" :multiple="true" :show-labels="false" @tag="addTag">
										</multiselect>
									</div>
								</div>
							</div>
							<div class="row m-0">
								<div class="col-12 p-0">
									<h4 class="px-3">{{ $t('label.members') }}</h4>
									<table class="table table-flush table-md members-list">
										<thead class="thead-light">
											<tr>
												<th scope="col">{{ $t('label.member name') }}</th>
												<th scope="col" width="10%"></th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(data, index) in addedUsers" :key="index">
												<td class="py-2">
													<div class="d-flex align-items-center">
														<img class="avatar-img rounded-circle mr-2" :src="data.picture">
														<div>
															<span class="name">{{ data.name }}</span>
															<span class="role">{{ data.role.name }}</span>
														</div>
													</div>
												</td>
												<td class="py-2">
													<div class="remove" @click="removeMember(data)">
														Remove
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="modal-footer border-top">
							<button type="submit" class="btn btn-primary mr-auto">{{ this.form.id ? $t('label.save channel') : $t('label.add channel') }}</button>
							<button type="button" class="btn btn-link ml-auto" data-dismiss="modal">{{ $t('label.close') }}</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<modal-delete :newid="newid" :deleteMethod="deleteChannel" :deleteModalText="deleteModalText" deleteType="archive"></modal-delete>
	</div>
</template>
<script>
import bus from '@/utils/event-bus';
import * as $ from 'jquery';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';

export default {
	props: [],
	components: {
		Loading,
		Multiselect
	},
	data() {
		return {
			// allChannels: [],
			isLoading: false,
			form: {
				id: null,
				name: null,
				status: null
			},
			addedUsers: [],
			deletedStatus: [],
			newid: 'ArchiveChannel',
			deleteModalText: {
				title: 'ARE YOU SURE YOU WANT TO ARCHIVE THIS CHANNEL?',
				button: 'Yes, Archive',
				subtitle: 'This will remove the channel for all users'
			},
			delete_id: null,
		}
	},
	created() {
		this.$store.dispatch('chat/getAllChannels')
		this.$store.dispatch('userData/getAllUsers');
	},
	computed: {
		...mapGetters({
			allChannels: 'chat/allChannels',
			allUsers: 'userData/allUsers',
		}),
	},
	mounted() {
		bus.$on('deleteFChannel', () => {
			this.deleteChannel();
		});

		var that = this;
		$('#channelModal').on('shown.bs.modal', function (e) {

		})
		$('#channelModal').on('hidden.bs.modal', function (e) {
			console.log('CLOOOOOOOOOOOSE')
			that.clearForm();
		})
	},
	methods: {
		addChannel() {

		},
		editChannelModal(data) {
			this.form.id = data.id;
			this.form.name = data.name;
			this.form.channel_status = data.status;

			data.users.forEach(data => {
				this.addedUsers.push(data.user);
			})

			console.log('this.form', this.form)
		},
		saveChannel() {
			this.isLoading = true;

			if (!this.form.id) {
				let params = {
					name: this.form.name,
					status: this.form.channel_status,
					users: this.addedUsers,
				}

				axios.post('/save-channel', params)
					.then(response => {
						bus.$emit('banner-success', {
							message: `Channel updated successfully!`,
						});
						this.$store.dispatch('chat/getAllChannels');

						this.isLoading = false;
						$('#channelModal').modal('hide')
					})
					.catch(err => console.error("API ERROR", err));

			} else {
				let params = {
					id: this.form.id,
					name: this.form.name,
					status: this.form.channel_status,
					users: this.addedUsers,
				}

				axios.post('/update-channel', params)
					.then(response => {
						bus.$emit('banner-success', {
							message: `Channel updated successfully!`,
						});
						this.$store.dispatch('chat/getAllChannels');

						this.isLoading = false;
						$('#channelModal').modal('hide')
					})
					.catch(err => console.error("API ERROR", err));

			}

		},
		addTag(newTag) {
			const tag = {
				name: newTag,
				code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
			}
			this.options.push(tag)
			this.value.push(tag)
		},
		removeMember(data) {
			let idx = this.addedUsers.map(x => x.id).indexOf(data.id);
			this.addedUsers.splice(idx, 1);
		},
		deleteNotify(delete_id, status) {
			this.delete_id = delete_id;
			if (status) {
				this.deleteModalText = {
					title: 'ARE YOU SURE YOU WANT TO UNARCHIVE THIS CHANNEL?',
					button: 'Yes, Unarchive',
					subtitle: 'This will add the channel for all users.'
				}
			} else {
				this.deleteModalText = {
					title: 'ARE YOU SURE YOU WANT TO ARCHIVE THIS CHANNEL?',
					button: 'Yes, Archive',
					subtitle: 'This will remove the channel for all users'
				}
			}

			$("#deleteModal" + this.newid).modal('show');
		},
		deleteChannel() {
			axios.post('/delete-channel/' + this.delete_id)
				.then(response => {
					bus.$emit('banner-success', {
						message: `Channel updated successfully!`,
					});
					this.$store.dispatch('chat/getAllChannels')
					this.$store.dispatch('userData/getAllUsers');
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		clearForm() {
			this.form.id = null;
			this.form.name = null;
			this.form.channel_status = null;
			this.addedUsers = [];
		},
		runDataTable() {
			$('#datatable-chat-channels').DataTable().destroy();
			var that = this;
			$('#datatable-chat-channels').ready(function () {
				$('#datatable-chat-channels').DataTable({
					language: {
						paginate: {
							previous: "<i class='fas fa-angle-left'>",
							next: "<i class='fas fa-angle-right'>"
						}
					},
					order: [],
					pageLength: 5,
					"searching": false,
					"lengthChange": false,
					"bDestroy": true,
				});
			});
		},
	}
}
</script>
<style>
.members-list .multiselect .multiselect__tags-wrap {
	display: none;
}

input.multiselect__input {
	font-size: 0.875rem;
	color: #32325D;
}

.search-icon {
	position: relative;
	bottom: -34px;
	z-index: 1;
	left: 5px;
}

.members-list .name {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #32325D;
	display: block;
}

.members-list .role {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #525F7F;
	display: block;
}

.members-list .remove {
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	text-transform: uppercase;
	color: #005486;
	cursor: pointer;
}
</style>