<template>
    <div>
        <!-- Upload Modal -->
        <div v-if="!uploadedFilesUpload.length">
            <div class="card-header border-bottom py-3 d-flex align-items-center">
                <h5 class="modal-title" id="addJobFilesModalTitle">{{ $t('label.upload files') }}</h5>
                <button type="button" class="close ml-auto" data-dismiss="modal" aria-label="Close" @click="cancelFileUpload">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <div class="card-body dropzone-file">
                <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" :destroyDropzone="false" :useCustomSlot="true" v-on:vdropzone-success="getUploadedFiles" v-on:vdropzone-error="getError" v-on:vdropzone-removed-file="removedFile">
                    <div class="dropzone-custom-content">
                        <span>{{ $t('label.drag drop files') }}</span><br>
                        <span>{{ $t('label.or') }}</span><br>
                        <button type="button" class="btn btn-lg btn-primary">
                            {{ $t('label.browse') }} {{ $t('label.files') }}
                        </button>
                    </div>
                </vue-dropzone>
            </div>
            <div class="card-footer border-top d-flex">
                <button type="button" class="btn btn-link  ml-auto close2editfile" data-dismiss="modal" @click="cancelFileUpload">Cancel</button>
            </div>
        </div>

        <!-- File Details -->
        <div v-if="uploadedFilesUpload.length">
            <div class="modal-header border-bottom">
                <h5 class="modal-title" id="addJobFileDetailsModalTitle">{{ $t('label.upload files') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="cancelFileUpload">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="saveFile">
                <div class="card-body pt-3 pb-2">
                    <div v-for="(file, index) in uploadedFilesUpload" :key="index">
                        <div v-if="file.fileType == 'pdf' || file.fileType == 'xlsx' || file.fileType == 'pptx' || file.fileType == 'docx' || file.fileType == 'doc' || file.fileType == 'txt' || file.fileType == 'rtf' || file.fileType == 'heic' || file.fileType == 'tiff'" class="image-container image-container-small" :style="'background-image: url(/' + file.filePath + ')'"></div>
                        <div v-else class="image-container image-container-small" :style="'background-image: url(' + file.dataURL + ')'"></div>

                        <div class="row">
                            <div class="col-sm">
                                <div class="form-group">
                                    <label class="form-control-label text-muted" for="input-name">{{ $t('label.file name') }}</label>
                                    <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                                    <input v-model="file.upload.filename" type="text" name="name" class="form-control" required>
                                </div>
                            </div>
                            <div class="col-sm">
                                <div class="form-group">
                                    <!-- Select Task -->
                                    <label class="form-control-label text-muted" for="input-name">{{ $t('label.assign task') }}</label>
                                    <span class="text-muted text-sm">(optional)</span>
                                    <select v-model="form.add.relatedTaskId" class="form-control">
                                        <option value="0">- {{ $t('label.select a task') }} -</option>
                                        <option v-for="(task, index) in tasks" :value="task.id" :key="index">{{ task.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-control-label text-muted" for="input-name">{{ $t('label.description') }}</label>
                            <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                            <textarea v-model="form.add.description" name="description" class="form-control" placeholder="Description" required autofocus></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-top d-flex justify-content-between">
                    <button type="submit" class="btn btn-primary">{{ $t('label.save files') }}</button>
                    <button type="button" class="btn btn-link  ml-auto close2editfile" data-dismiss="modal" @click="cancelFileUpload">{{ $t('label.cancel') }}</button>
                </div>
            </form>
        </div>

    </div>
</template>

<script>
import bus from '@/utils/event-bus';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { mapGetters } from 'vuex';

export default {
    props: ['jobid'],
    emits: ['close-add-file-modal', 'reload'],
    components: {
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            dropzoneOptions: {
                autoProcessQueue: true,
                // url: 'https://httpbin.org/post',
                url: 'https://putsreq.com/sMLPgj8Pe0jHquCDgZcs',
                maxFilesize: 10,
                thumbnailWidth: null,
                addRemoveLinks: true,
            },
            form: {
                add: {
                    name: null,
                    description: null,
                    download: null,
                    relatedTaskId: 0,
                },
            },
            inputfile: null,
            showDropdown: false,
            jobFile: {
                created_by: {
                    name: null,
                    picture: null
                }
            },
            uploadedFiles: [],
            uploadedFilesUpload: [],
            isSameRelatedTask: false,
            isSameFileDescription: false,
            jobFileId: 0,
            isUploadCancel: false,
            currentTaskId: 0,
            errorDropzone: null,
        }
    },
    computed: {
        ...mapGetters(['alljobfiles']),
    },
    mounted() {
        this.getTasks();
    },
    methods: {
        getTasks() {
            axios.get('/tasks/' + this.jobid)
                .then(response => {
                    this.tasks = response.data.result;
                    this.tasks.push({ id: 0, name: '', sequence: 0 });
                    this.tasks = _.orderBy(this.tasks, ['sequence'], ['asc']);
                })
                .catch(err => console.error("API ERROR", err));
        },
        cancelFileUpload() {
            this.isUploadCancel = true;
            this.errorDropzone = null;
            this.uploadedFiles = [];
            this.uploadedFilesUpload = [];
            this.closeModal();
        },
        removedFile() {
            this.errorDropzone = null;
        },
        getUploadedFiles: function (file, response) {
            let filename = file.upload.filename;
            let fileType = filename.split('.').pop();
            file.fileType = fileType;

            if (fileType === 'pdf') {
                file.filePath = 'images/icons/doc-pdf.png';
                file.upload.filePath = 'images/icons/doc-pdf.png';
            } else if (fileType === 'xlsx') {
                file.filePath = 'images/icons/doc-excel.png';
                file.upload.filePath = 'images/icons/doc-excel.png';
            } else if (fileType === 'pptx') {
                file.filePath = 'images/icons/doc-ppt.png';
                file.upload.filePath = 'images/icons/doc-ppt.png';
            } else if (fileType === 'docx' || fileType === 'doc') {
                file.filePath = 'images/icons/doc-word.png';
                file.upload.filePath = 'images/icons/doc-word.png';
            } else if (fileType === 'txt' || fileType === 'rtf' || fileType === 'heic' || fileType === 'tiff') {
                file.filePath = 'images/icons/doc-other.png';
                file.upload.filePath = 'images/icons/doc-other.png';
            } else {
                file.filePath = 'uploads/' + encodeURI(filename);
                file.upload.filePath = 'uploads/' + encodeURI(filename);
            }

            if (this.showFilesByTask) {
                file.upload.relatedTask = this.currentTaskId;
            }

            this.uploadedFiles.push(file);
            this.uploadedFilesUpload.push(file);
        },
        saveFile() {
            const data = new FormData();
            let file = this.uploadedFilesUpload[0];
            data.append('download', this.uploadedFiles[0]);

            const json = JSON.stringify({
                name: file.upload.filename.split('.')[0] + '.' + file.fileType,
                description: this.form.add.description,
                task_id: (this.form.add.relatedTaskId > 0) ? this.form.add.relatedTaskId : null,
                job_id: this.jobid,
            });
            data.append('data', json);

            axios.post('/jobfiles', data)
                .then(response => {
                    this.closeModal();

                    this.$emit('reload');
                    bus.$emit('banner-success', {
                        message: `File Added Successfully!`,
                    });
                    this.form.isSameFileDescription = false;
                    this.form.isSameRelatedTask = false;
                    this.form.add.description = null;
                    this.form.add.relatedTaskId = null;
                })
                .catch(err => console.error('Error saving file:', err));

        },
        getError() {
            this.errorDropzone = this.$refs.myVueDropzone.getRejectedFiles();

            if (this.errorDropzone)
                $('.dropzone-file .dropzone .dz-error-message').attr('style', 'display: unset !important');
        },
        closeModal() {
            this.$emit('close-add-file-modal');
        },
    }
}
</script>

<style>
.dropzone .dz-preview .dz-error-message {
    opacity: 1 !important;
    top: 0 !important;
}
</style>