import authGuard from '@/middlewares/AuthGuard';

export default {
  name: 'Plan',
  icon: '',
  store: 'Plan/store/index.js',
  routes: [{
    path: '/payment-models',
    name: 'plan',
    component: () => import(/* webpackChunkName: "plan" */ './views/index.vue'),
    beforeEnter: authGuard,
    meta: {
      layout: 'default',
      title: 'Plans'
    },
    children: [{
      path: `plans`,
      name: 'plans',
      component: () => import(/* webpackChunkName: "plan" */ './views/plans/index.vue'),
      beforeEnter: authGuard,
      meta: {
        layout: 'default',
        title: 'Plans'
      },
      children: [{
        path: `list`,
        name: 'list-plan',
        component: () => import(/* webpackChunkName: "plan" */ './views/plans/list.vue'),
        beforeEnter: authGuard,
        meta: {
          layout: 'default',
          title: 'All Plans'
        },
      }, {
        path: `add`,
        name: 'add-plan',
        component: () => import(/* webpackChunkName: "plan" */ './views/plans/create.vue'),
        beforeEnter: authGuard,
        meta: {
          layout: 'default',
          title: 'Add Plans'
        },
      }, {
        path: `edit/:id`,
        name: 'edit-plan',
        component: () => import(/* webpackChunkName: "plan" */ './views/plans/edit.vue'),
        beforeEnter: authGuard,
        meta: {
          layout: 'default',
          title: 'Edit Plans'
        },
      }]
    }, {
      path: `products`,
      name: 'products',
      component: () => import(/* webpackChunkName: "plan" */ './views/index.vue'),
      beforeEnter: authGuard,
      meta: {
        layout: 'default',
        title: 'Products'
      },
      children: [{
        path: `list`,
        name: 'list-product',
        component: () => import(/* webpackChunkName: "plan" */ './views/products/list.vue'),
        beforeEnter: authGuard,
        meta: {
          layout: 'default',
          title: 'All Products'
        },
      }, {
        path: `add`,
        name: 'add-product',
        component: () => import(/* webpackChunkName: "plan" */ './views/products/create.vue'),
        beforeEnter: authGuard,
        meta: {
          layout: 'default',
          title: 'Add Product'
        },
      }, {
        path: `edit/:id`,
        name: 'edit-product',
        component: () => import(/* webpackChunkName: "plan" */ './views/products/edit.vue'),
        beforeEnter: authGuard,
        meta: {
          layout: 'default',
          title: 'Edit Product'
        },
      }]
    }, {
      path: `add-ons`,
      name: 'add-ons',
      component: () => import(/* webpackChunkName: "plan" */ './views/add-ons/index.vue'),
      beforeEnter: authGuard,
      meta: {
        layout: 'default',
        title: 'Add-ons'
      },
      children: [{
        path: `list`,
        name: 'list-add-ons',
        component: () => import(/* webpackChunkName: "plan" */ './views/add-ons/list.vue'),
        beforeEnter: authGuard,
        meta: {
          layout: 'default',
          title: 'All Add-ons'
        },
      }, {
        path: `add`,
        name: 'add-add-on',
        component: () => import(/* webpackChunkName: "plan" */ './views/add-ons/create.vue'),
        beforeEnter: authGuard,
        meta: {
          layout: 'default',
          title: 'Add Add-on'
        },
      }, {
        path: `edit/:id`,
        name: 'edit-add-on',
        component: () => import(/* webpackChunkName: "plan" */ './views/add-ons/edit.vue'),
        beforeEnter: authGuard,
        meta: {
          layout: 'default',
          title: 'Edit Add-on'
        },
      }]
    }]
  }],
  preRegisterCheck() {
    return true;
  }
};
