<template>
    <div>
        <div class="modal-content">
            <div class="modal-header border-bottom">
                <h5 class="modal-title" id="invoiceModalTitle">{{ source == 'addInvoice' ? 'Create Invoice' : 'Update Invoice' }}</h5>
                <button type="button" class="close" @click="$emit('cancelFnc')">
                    <i class="ri-close-line"></i>
                </button>
            </div>

            <div class="modal-body px-0">
                <div class="row m-0">
                    <div class="col-4 p-0">
                        <div class="row m-0">
                            <!-- Invoice Number -->
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label text-muted" for="invoice_number">{{ $t('label.invoice number') }}</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text input-group-invoice">IN-</span>
                                        </div>
                                        <input type="text" name="invoice_number" id="invoice_number" v-model="form.invoice_number" class="form-control" disabled>
                                    </div>
                                </div>
                            </div>
                            <!-- Select Date -->
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label text-muted" for="due_date">{{ $t('label.due date') }}</label>
                                    <date-picker v-model="$v.form.due_date.$model" valueType="date" format="MM/DD/YYYY" :disabled-date="disableDueDateBeforeToday" :placeholder="$t('label.select date')" class="full-width" input-class="form-control" required></date-picker>
                                    <div v-if="$v.form.due_date.$error && !$v.form.due_date.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
                                </div>
                            </div>
                            <!-- Select Job -->
                            <div v-if="source == 'addInvoice' && source2 == 'customer'" class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label text-muted" for="due_date">{{ $t('label.job') }}</label>

                                    <div class="dropdown w-100">
                                        <div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': ($v.selected.$error), }">
                                                <input type="text" class="form-control form-control-dropdown pointer" :placeholder="$t('label.jobs')" v-model.trim="$v.selected.$model" autocomplete="input-field" data-lpignore="true" required>
                                                <div class="input-group-append">
                                                    <span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
                                            <div class="pl-3">
                                                <template v-for="(data, index) in jobOptions">
                                                    <div :key="index" class="pointer dropdown-items" @click="jobSelected(data)">
                                                        <span class="jobname">{{ data.text }}</span>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                        <div v-if="$v.selected.$error && !$v.selected.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Company Bill From Info -->
                    <div class="col-4 p-0">
                        <label class="form-control-label text-muted">{{ $t('label.bill from') }}</label>
                        <p>
                            {{ companySettings.name }}<br>
                            {{ companySettings.address_1 }} <br>
                            {{ companySettings.address_2 }} <br v-if="companySettings.address_2">
                            {{ companySettings.city }}, {{ companySettings.state }} {{ companySettings.postal_code }}
                        </p>
                    </div>
                    <!-- Bill To Info -->
                    <div v-if="jobData" class="col-4 p-0">
                        <label class="form-control-label text-muted">{{ $t('label.bill to') }}</label>
                        <p>
                            {{ jobData.customer.name }}<br>
                            {{ jobData.customer.address_1 }} <br>
                            {{ jobData.customer.address_2 }} <br v-if="jobData.customer.address_2">
                            {{ jobData.customer.city }}, {{ jobData.customer.state }} {{ jobData.customer.postal_code }}
                        </p>
                    </div>
                </div>
                <div class="row m-0">
                    <table class="table table-flush table-sm" id="">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">{{ $t('label.item') }}</th>
                                <th scope="col" width="10%">{{ $t('label.quantity') }}</th>
                                <th scope="col" width="15%">{{ $t('label.amount') }}</th>
                                <th scope="col" width="10%">Taxable?</th>
                                <th scope="col" width="5%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- Loop Items -->
                            <tr :id="v.id" v-for="(v, index) in $v.form.listItems.$each.$iter" :key="index">
                                <!-- Item name -->
                                <td>
                                    <div class="dropdown w-100">
                                        <div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': (v.selectedItem.$error), }">
                                                <input type="text" class="form-control form-control-dropdown pointer" :placeholder="$t('label.select item')" v-model.trim="v.selectedItem.name.$model" autocomplete="input-field" data-lpignore="true" required>
                                                <div class="input-group-append">
                                                    <span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Item List -->
                                        <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
                                            <a class="dropdown-item text-default pointer" @click="showAddItemModal('add')">
                                                <i class="ri-add-fill"></i> {{ $t('label.add invoice item') }}
                                            </a>
                                            <div class="dropdown-divider mx-3"></div>

                                            <!-- Selectable VPO's -->
                                            <div v-if="selectableJobVpos.length">
                                                <h4 class="mx-3">{{ $t('label.vpos to bill') }}</h4>
                                                <div class="pl-3" v-for="(vpo, idx) in selectableJobVpos" :key="idx + '_vpo'">
                                                    <a :value="vpo" class="dropdown-item text-default pointer" @click="updateVpoSelectableItems(vpo, 'vpo', index); vpoSelected(vpo, index)">
                                                        <span class="jobname">
                                                            <span class="text-muted">({{ $t('label.nontaxable') }})&nbsp;</span>
                                                            VPO-{{ vpo.vpo_number.vpo_number }}
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>

                                            <!-- Selectable Items -->
                                            <h4 class="mx-3">{{ $t('label.items') }}</h4>
                                            <div class="pl-3" v-for="(item, i) in options" :key="i + '_item'">
                                                <a :value="item" class="dropdown-item text-default pointer" @click="updateVpoSelectableItems(item, 'item', index); itemSelected(item, index)">
                                                    <span class="jobname">{{ item.name }}</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div v-if="v.item.$error && !v.item.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
                                    </div>
                                </td>
                                <!-- Quantity -->
                                <td>
                                    <input :id="index" type="number" name="quantity" v-model="v.quantity.$model" min="0" oninput="validity.valid||(value='');" class="form-control" required :disabled="v.$model.type != 'item'" @change="autoSum">
                                    <div v-if="v.quantity.$error && !v.quantity.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
                                </td>
                                <!-- Amount -->
                                <td>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text input-group-invoice"><i class="ri-money-dollar-circle-line"></i></span>
                                        </div>
                                        <money v-model="v.amount.$model" v-bind="money" class="form-control" required @blur.native="autoSum" :disabled="v.$model.type != 'item'"></money>
                                    </div>
                                </td>
                                <!-- Tax -->
                                <td>
                                    <div class="input-group">
                                        <input type="checkbox" @change="autoSum" v-model="v.taxable.$model" :disabled="v.$model.type != 'item'">
                                    </div>
                                </td>
                                <!-- Dropdown Options -->
                                <td class="text-right">
                                    <div class="dropdown">
                                        <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-ellipsis-v"></i>
                                        </a>
                                        <!-- Remove Item -->
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                            <a class="dropdown-item text-danger pointer" @click="deleteItem(index)">
                                                <i class="ri-delete-bin-line"></i> {{ $t('label.delete item') }}
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                        <!-- Items Footer -->
                        <tbody class="border-top">
                            <tr>
                                <!-- Add Line Item -->
                                <td class="align-top pt-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <button type="button" class="btn btn-outline-primary btn-sm" @click="addItem">
                                                <i class="ri-add-line"></i> {{ $t('label.add line item') }}
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                <td colspan="2">
                                    <div class="d-flex align-items-center">
                                        <!-- Subtotal and Taxes -->
                                        <span class="align-top pt-4">
                                            <label class="form-control-label text-muted d-block">SubTotal</label>
                                            <label class="form-control-label text-muted d-block">Total Tax</label>
                                        </span>
                                        <span class="align-top pt-4 ml-auto text-right">
                                            <label class="form-control-label text-muted d-block">${{ form.subtotal | formatThousands }}</label>
                                            <label class="form-control-label text-muted d-block">${{ form.taxes | formatThousands }}</label>
                                        </span>
                                    </div>
                                </td>
                                <td></td>
                            </tr>
                            <!-- Total -->
                            <tr>
                                <td class="border-0"></td>
                                <td class="align-top pt-3 border-top">
                                    <label class="form-control-label text-muted d-block">{{ $t('label.total') }}</label>
                                </td>
                                <td class="align-top pt-3 border-top text-right">
                                    <label class="form-control-label text-muted d-block">${{ form.total | formatThousands }}</label>
                                </td>
                                <td class="border-0"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Additional Notes -->
                <div class="col-12">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="additional_notes">{{ $t('label.additional notes') }}</label>
                        <input type="text" name="additional_notes" id="additional_notes" v-model="form.additional_notes" class="form-control">
                    </div>
                </div>

                <!-- Customer Reference Number -->
                <div class="col-12">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="customer_reference_number">{{ $t('label.customer reference number') }}</label>
                        <div class="sp-tooltip">
                            <b-icon icon="question-circle-fill" aria-label="Help"></b-icon>
                            <span class="sp-tooltiptext">
                                {{ $t('label.number will keep track of customer invoice') }}
                            </span>
                        </div>
                        <input type="text" name="customer_reference_number" id="customer_reference_number" v-model="form.customer_reference_number" class="form-control">
                    </div>
                </div>

            </div>

            <!-- Modal Footer -->
            <div class="modal-footer border-top">
                <div>
                    <button type="submit" name="btn1submit" @click="saveInvoice('create')" class="btn btn-primary mr-auto">{{ source == 'addInvoice' ? 'Create Invoice' : 'Update Invoice' }}</button>
                    <button type="submit" name="btn2submit" id="saveAndSend" @click="saveInvoice('createAndSend')" class="btn btn-primary mr-auto"> {{ source == 'addInvoice' ? 'Create and Send Invoice' : 'Update and Send Invoice' }}</button>
                </div>
                <button type="button" class="btn btn-link  ml-auto close2addinvoice" @click="$emit('cancelFnc')">{{ $t('label.close') }}</button>
            </div>
        </div>

        <!-- Add/Edit Invoice Items -->
        <modal name="invoiceItemsModal" :shiftY="0.3" :reset="true" :scrollable="true" :adaptive="true">
            <modal-invoice-items :savedType=saveType @cancelFnc="$modal.hide('invoiceItemsModal')" @onSave="onAddNewInvoiceItems">
            </modal-invoice-items>
        </modal>
    </div>
</template>

<script>
import ModalInvoiceItems from '@/components/ModalInvoiceItems';
import ModalInvoiceSend from '@/components/ModalInvoiceSend.vue';
import bus from '@/utils/event-bus';
import { BIcon } from 'bootstrap-vue';
// import Loading from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-search-select/dist/VueSearchSelect.css';
import { minValue, required, requiredIf } from 'vuelidate/lib/validators';

export default {
    props: ['job', 'source', 'source2', 'customerid', 'invoiceData', 'vpoId'],
    emits: ['cancelFnc', 'reloadInvoices'],
    components: {
        // Loading,
        ModalInvoiceSend,
        ModalInvoiceItems,
        BIcon
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            companySettings: JSON.parse(localStorage.getItem('company_settings')),
            show_taxbox: false,
            form: {
                selectedItem: {},
                listItems: [],
                listItemsDelete: [],
                taxes: 0.00,
                total: 0.00,
                subtotal: 0.00,
                nonTaxSubtotal: 0.00,
                due_date: null,
                invoice_number: null,
                invoice_status: null,
                additional_notes: null,
                customer_reference_number: null,
                selectedItem: {
                    name: null
                },
            },
            money: {
                prefix: '',
                decimal: '.',
                thousands: ',',
                precision: 2,
                masked: false
            },
            inputProps: {
                class: "ddd",
                placeholder: "Jobs",
                id: "autosuggest__input"
            },
            alljobs: [],
            jobInfo: [],
            invoice_items: [],
            selectableJobVpos: [],
            currentInvoiceData: [],
            saveType: 'save',
            amountMin: 0.01,
            loader: null,
            jobData: null,
            currentId: null,
            jobOptions: null,
            selectedJob: null,
            showInvoiceItemModal: false,
        }
    },
    validations: {
        form: {
            due_date: { required },
            listItems: {
                $each: {
                    item: { required },
                    quantity: { required },
                    amount: { required, minValue: minValue(0.00), },
                    selectedItem: { name: {} },
                    taxable: {}
                }
            }
        },
        selected: {
            required: requiredIf(function () {
                if (this.source2 == 'customer' && this.source == 'addInvoice')
                    return this.source2 == 'customer';
            })
        },
    },
    computed: {
        filteredSuggestions() {
            return [{
                data: this.alljobs.filter(item => {
                    return item.job_name.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
                })
            }];
        }
    },
    watch: {
        //
    },
    mounted() {
        this.loadLkupData();
    },
    methods: {
        loadLkupData() {
            let promises = [];
            this.loader = this.$loading.show();

            promises.push(this.getInvoiceItems());
            if (this.job)
                promises.push(this.getJobVpos(this.job.id));

            Promise.all(promises).then(() => {
                this.loader.hide();

                if (this.source == 'addInvoice') {
                    this.jobData = JSON.parse(JSON.stringify(this.job));

                    this.clearForm();
                    this.addItem();

                    if (this.source2 == 'customer')
                        this.getJobsByCustomer();

                    // If from vpo, load in vpo automatically to list
                    if (this.source2 == 'vpo') {
                        let idx = this.jobVpos.findIndex(vpo => vpo.id == this.vpoId);
                        if (idx >= 0) {
                            this.vpoSelected(this.jobVpos[idx], 0);
                            this.selectVpoItem(this.jobVpos[idx], 'vpo')
                        }
                    }
                } else {
                    this.editModal(this.invoiceData);
                }

            });
        },

        getJobVpos(id) {
            return axios.get(`/get_job_invoicable_vpos/${id}`).then(rsp => {
                this.jobVpos = rsp.data.result;
                this.selectableJobVpos = JSON.parse(JSON.stringify(this.jobVpos));
            }).catch(err => console.log(err));
        },
        getInvoiceItems() {
            this.invoice_items = [];
            this.options = [];

            return axios.get('/get-invoice-items').then(response => {
                this.invoice_items = response.data.result;

                // Loop options and filter out active items
                this.invoice_items.forEach(item => {
                    if (item.status == 1)
                        this.options.push(item);
                });

            }).catch(err => {
                //console.log('list carriers error:', error)
            });
        },
        getJobsByCustomer() {
            axios.get('/get-all-jobs/' + this.customerid).then(response => {
                this.alljobs = response.data.result;
                this.jobOptions = response.data.jobOptions;
                this.jobData = this.alljobs[0];
            }).catch(err => {
                //console.log('list carriers error:', error)
            });
        },

        disableDueDateBeforeToday(date) {
            return date < moment().subtract(0, 'days').toDate();
        },

        autoSum() {
            this.form.total = 0.00
            this.form.subtotal = 0.00;
            this.form.nonTaxSubtotal = 0.00;

            // Add up Subtotals
            this.form.listItems.forEach((data, i) => {
                // Taxable Subtotal
                if (data.type == 'item')
                    if (this.companySettings.settings.invoice && data.taxable) {
                        this.form.listItems[i].tax = Number(data.quantity) * (parseFloat(data.amount) * parseFloat(this.companySettings.settings.tax_rate / 100).toFixed(4));
                    } else {
                        this.form.listItems[i].tax = 0;
                    }
                this.form.subtotal += parseFloat(data.quantity) * parseFloat(data.amount);

                // Nontaxable Subtotal
                if (data.type == 'vpo')
                    this.form.nonTaxSubtotal += parseFloat(data.quantity) * parseFloat(data.amount);
            });

            // Subtotals and Taxes
            this.form.nonTaxSubtotal = parseFloat(this.form.nonTaxSubtotal).toFixed(2);
            this.form.subtotal = parseFloat(this.form.subtotal).toFixed(2);
            this.form.taxes = parseFloat(this.form.listItems.reduce((n, { tax }) => n + tax, 0) || 0).toFixed(2);

            // Sum all into Total
            this.form.total = parseFloat(Number(this.form.subtotal) + Number(this.form.nonTaxSubtotal) + Number(this.form.taxes)).toFixed(2);

        },
        updateVpoSelectableItems(newItem, newType, oldIdx) {
            // Alter the VPO selectable list according to new item 
            // selected and what was previously selected to allow for
            // only one selection per VPO.
            this.deselectVpoItem(oldIdx);
            this.selectVpoItem(newItem, newType);
        },
        deselectVpoItem(oldIdx) {
            // Replace old deselected vpo back into the list
            if (this.form.listItems[oldIdx].type == 'vpo')
                this.selectableJobVpos.push(this.form.listItems[oldIdx].selectedItem);
        },
        selectVpoItem(newItem, newType) {
            // Remove selected vpo from the main list
            if (newItem && newType == 'vpo') {
                let oldIdx = this.selectableJobVpos.findIndex(sv => newItem.id == sv.id);
                if (oldIdx >= 0) this.selectableJobVpos.splice(oldIdx, 1);
            }
        },
        onAddNewInvoiceItems(item) {
            this.$modal.hide('invoiceItemsModal');
            this.invoice_items.push({
                item
            });
            this.getInvoiceItems();
        },
        updateAmount(selectedItem, i) {
            this.form.listItems[i].item = selectedItem.name;
            this.form.listItems[i].amount = selectedItem.amount;
        },
        showAddItemModal(t) {
            this.$modal.show('invoiceItemsModal')
        },
        itemSelected(item, index) {
            // Set item list data
            this.form.listItems[index].type = 'item';
            this.form.listItems[index].item = item.name;
            this.form.listItems[index].amount = item.amount;
            this.form.listItems[index].selectedItem = item;
        },
        vpoSelected(vpo, i) {
            // Set item data to vpo upon vpo selection
            let vpoToAdd = JSON.parse(JSON.stringify(vpo));
            vpoToAdd.name = "(" + this.$t('label.nontaxable') + ") VPO-" + vpo.vpo_number.vpo_number;

            this.form.listItems[i].type = 'vpo';
            this.form.listItems[i].quantity = 1;
            this.form.listItems[i].item = vpoToAdd.name;
            this.form.listItems[i].selectedItem = vpoToAdd;
            this.form.listItems[i].amount = vpoToAdd.overall_total;

            this.autoSum();
        },
        jobSelected(data) {
            this.selectedJob = data.value;
            this.selected = data.text;
            this.getJobVpos(this.selectedJob.id);
        },
        addItem() {
            this.form.listItems.push({
                type: '',
                item: '',
                amount: '',
                quantity: '',
                selectedItem: ''
            });
        },
        deleteItem(index) {
            this.deselectVpoItem(index);
            this.form.listItems.splice(index, 1)
            this.autoSum();
        },
        editModal(item) {
            let editData = JSON.parse(JSON.stringify(item));
            this.form.id = editData.data.id;
            this.jobData = editData.data.job;
            this.form.due_date = moment(editData.data.due_date).toDate();
            this.form.invoice_number = editData.data.invoice_number.invoice_number;

            // Append VPOs to list items
            editData.data.invoice_vpos.forEach((vpo, idx) => {
                // Add vpo item form.listItems
                this.addItem();
                this.vpoSelected(vpo, idx);
                this.form.listItems[idx].id = vpo.id;
            });

            // Loop the invoice line items to match drop down items
            editData.data.invoice_item_list.forEach(item => {
                let index = this.invoice_items.findIndex(elm => elm.id == item.invoice_item_id),
                    listItem = {};

                // Add matched item and list item to form.listItems
                listItem.id = item.id;
                listItem.type = 'item';
                listItem.item = this.invoice_items[index].name;
                listItem.amount = item.invoice_item.amount;
                listItem.quantity = item.quantity;
                listItem.selectedItem = this.invoice_items[index];
                listItem.taxable = item.amount > item.invoice_item.amount;

                this.form.listItems.push(listItem)
            });

            this.form.nonTaxSubtotal = parseFloat(editData.data.nontaxable_subtotal).toFixed(2);
            this.form.subtotal = parseFloat(editData.data.subtotal).toFixed(2);
            this.form.taxes = parseFloat(editData.data.taxes).toFixed(2);
            this.form.total = parseFloat(editData.data.total).toFixed(2);
            this.form.invoice_status = editData.data.invoice_status_id;
            this.form.additional_notes = editData.data.additional_notes;
            this.form.customer_reference_number = editData.data.customer_reference_number;
        },
        saveInvoice(e) {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loader = this.$loading.show();
                this.form.customer_id = this.jobData.customer.id;

                // Select job id based on source
                if (this.source == 'addInvoice' && this.source2 == 'customer')
                    this.form.job_id = this.selectedJob;
                else
                    this.form.job_id = this.jobData.id;

                // Select add or edit specific logic
                let apiUri = "",
                    bannerMsg = "";
                if (this.source == 'addInvoice') {
                    apiUri = '/invoice';
                    bannerMsg = this.$t('label.invoice created successfully');
                } else {
                    apiUri = '/invoice/update';
                    bannerMsg = this.$t('label.invoice updated successfully');
                }

                // Send Request
                axios.post(apiUri, this.form).then(response => {
                    this.currentInvoiceData = response.data.result;
                    this.loader.hide();
                    this.$emit('reloadInvoices');
                    $('.close2addinvoice').click();
                    bus.$emit('ModalInvoiceSave', this.currentInvoiceData)
                    bus.$emit('banner-success', { message: bannerMsg });

                    if (e == 'createAndSend') {
                        this.$emit('cancelFnc', this.currentInvoiceData);
                        this.$emit('customChange', 1, this.currentInvoiceData.id);
                    }

                    this.clearForm();

                }).catch(err => {
                    //console.log('list carriers error:', error)
                });
            }
        },

        clearForm() {
            this.form.total = 0.00;
            this.form.taxes = 0.00;
            this.form.listItems = [];
            this.form.subtotal = 0.00;
            this.form.due_date = null;
            this.form.invoice_number = null;
            this.form.additional_notes = null;
            this.form.customer_reference_number = null;
        },

    }
}
</script>

<style>
.invoice-items {
    min-height: 45px !important;
    line-height: 22px !important;
}

.invoice-items .text {
    color: #8898aa;
}
</style>
