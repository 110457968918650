<template>
    <div class="col-xl-12 order-xl-1 mobile-p-0">
        <div class="card">
            <div class="card-header">
                <div class="row align-items-center justify-content-between">
                    <div>
                        <h3 class="mb-0 d-flex align-items-center"><i class="ri-draft-line heading-title"></i> {{ $t('label.notes') }}</h3>
                    </div>
                    <div>
                        <button type="button" class="btn btn-sm btn-default" data-toggle="modal" data-target="#addJobNotesModal">
                            {{ $t('label.add note') }}
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="alljobnotes.length == 0">
                <div class="card-body m-0 py-0">
                    <div class="bg-white text-center row align-items-center m-0">
                        <i class="ri-draft-line ni-8x w-100 color-gray-400"></i>
                        <p class="w-100 font-weight-normal">{{ $t('label.No Notes have been added to this Job') }}.</p>
                    </div>
                </div>
            </div>
            <div v-if="alljobnotes.length > 0" class="card-body p-0">
                <!-- List group -->
                <div class="list-group list-group-flush">
                    <div v-if="showLess">
                        <div class="list-group-item list-group-item-action py-4 px-4" v-for="(item, index) in alljobnotes.slice(0, 5)" v-bind:key="index">
                            <div class="row align-items-center justify-content-between m-0">
                                <div class="col p-0 m-0 d-flex align-items-center">
                                    <div class="cursor-pointer mr-2" @click="getUserInfo(item.created_by.id, 'Created By')">
                                        <img class="rounded-circle" :src="item.created_by.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                    </div>
                                    <h4 class="cursor-pointer m-0 mr-2" @click="getUserInfo(item.created_by.id, 'Created By')">{{ item.created_by.name }}</h4>
                                    <h4 class="font-weight-normal m-0 mr-2">{{ item.created_at }}</h4>
                                </div>
                                <div class="col-auto p-0 m-0 d-flex" v-if="(user.role_id == 2)">
                                    <h4><a href="#" class="text-primary font-weight-normal mr-3" data-toggle="modal" data-target="#editJobNotesModal" @click="editModal(item)">{{ $t('label.edit') }}</a></h4>
                                    <h4><a href="#" class="text-primary font-weight-normal" data-toggle="modal" data-target="#deleteModal1" @click="deleteNotify(item.id)">{{ $t('label.delete') }}</a></h4>
                                </div>
                                <div class="col-auto p-0 m-0 d-flex" v-if="(user.role_id != 2) && (item.created_by.role_id == user.role_id)">
                                    <h4><a href="#" class="text-primary font-weight-normal mr-3" data-toggle="modal" data-target="#editJobNotesModal" @click="editModal(item)">{{ $t('label.edit') }}</a></h4>
                                    <h4><a href="#" class="text-primary font-weight-normal" data-toggle="modal" data-target="#deleteModal1" @click="deleteNotify(item.id)">{{ $t('label.delete') }}</a></h4>
                                </div>
                            </div>
                            <p class="text-sm mb-0 ml-5 mt-3 font-weight-normal pre-wrap" v-html="item.notes"></p>
                        </div>
                    </div>
                    <div v-else>
                        <div class="list-group-item list-group-item-action py-3 px-4" v-for="(item, index) in alljobnotes" v-bind:key="index">
                            <div class="row align-items-center justify-content-between m-0">
                                <div class="col p-0 m-0 d-flex align-items-center">
                                    <div class="cursor-pointer mr-2" @click="getUserInfo(item.created_by.id, 'Created By')">
                                        <img class="rounded-circle" :src="item.created_by.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                    </div>
                                    <h4 class="cursor-pointer m-0 mr-2" @click="getUserInfo(item.created_by.id, 'Created By')">{{ item.created_by.name }}</h4>
                                    <h4 class="font-weight-normal m-0 mr-2">{{ item.created_at }}</h4>
                                </div>
                                <div class="col-auto p-0 m-0 d-flex" v-if="(user.role_id == 2)">
                                    <h4 class="mr-3"><a href="#" class="text-primary font-weight-normal" data-toggle="modal" data-target="#editJobNotesModal" @click="editModal(item)">{{ $t('label.edit') }}</a></h4>
                                    <h4><a href="#" class="text-primary font-weight-normal" data-toggle="modal" data-target="#deleteModal1" @click="deleteNotify(item.id)">{{ $t('label.delete') }}</a></h4>
                                </div>
                                <div class="col-auto p-0 m-0 d-flex" v-if="(user.role_id != 2) && (item.created_by.role_id == user.role_id)">
                                    <h4 class="mr-3"><a href="#" class="text-primary font-weight-normal" data-toggle="modal" data-target="#editJobNotesModal" @click="editModal(item)">{{ $t('label.edit') }}</a></h4>
                                    <h4><a href="#" class="text-primary font-weight-normal" data-toggle="modal" data-target="#deleteModal1" @click="deleteNotify(item.id)">{{ $t('label.delete') }}</a></h4>
                                </div>
                            </div>
                            <p class="text-sm mb-0 ml-5 mt-3 font-weight-normal pre-wrap" v-html="item.notes"></p>
                        </div>
                    </div>
                    <button class="btn btn-white text-primary mr-auto ml-auto my-4 d-flex" v-if="alljobnotes.length > 5 && showLess" @click="showLess = false">
                        {{ $t('label.view more') }}
                    </button>
                    <button class="btn btn-white text-primary mr-auto ml-auto my-4 d-flex" v-if="alljobnotes.length > 5 && !showLess" @click="showLess = true">
                        {{ $t('label.view less') }}
                    </button>
                </div>
            </div>
        </div>
        <!-- ADD Modal -->
        <div class="modal fade" id="addJobNotesModal" tabindex="-1" role="dialog" aria-labelledby="addJobNotesModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header border-bottom">
                        <h5 class="modal-title" id="addJobNotesModalTitle">{{ $t('label.add job notes') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                    <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="saveJobNote">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.related task') }} ({{ $t('label.optional') }})</label>
                                        <select v-model="form.add.relatedTask" class="form-control mb-2">
                                            <option value="" disabled>- {{ $t('label.select a task') }} -</option>
                                            <option v-for="(task, index) in tasks" :value="task.id" :key="index">{{ task.name }}</option>
                                        </select>
                                        <at-ta :members="allUsers" name-key="name">
                                            <template slot="item" slot-scope="s">
                                                <span v-html="s.item.name" @click="selectedName(s.item)"></span>
                                            </template>
                                            <textarea v-model="form.add.notes" id="addJobNoteField" name="notes" class="form-control" :placeholder="$t('label.write a note') + '...'" rows="10" required autofocus></textarea>
                                        </at-ta>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer border-top">
                            <button type="submit" class="btn btn-primary mr-auto">{{ $t('label.add job note') }}</button>
                            <button type="button" class="btn btn-link  ml-auto close2addnote" data-dismiss="modal">{{ $t('label.close') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- EDIT Modal -->
        <div class="modal fade" id="editJobNotesModal" tabindex="-1" role="dialog" aria-labelledby="editJobNotesModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header border-bottom">
                        <h5 class="modal-title" id="editJobNotesModalTitle">{{ $t('label.edit job notes') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                    <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="updateJobNote">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.related task') }} ({{ $t('label.optional') }})</label>
                                        <select v-model="form.edit.relatedTask" class="form-control mb-2">
                                            <option value="" disabled>- {{ $t('label.select a task') }} -</option>
                                            <option v-for="(task, index) in tasks" :value="task.id" :key="index">{{ task.name }}</option>
                                        </select>
                                        <at-ta :members="allUsers" name-key="name">
                                            <template slot="item" slot-scope="s">
                                                <span v-html="s.item.name" @click="selectedName(s.item)"></span>
                                            </template>
                                            <textarea v-model="form.edit.notes" id="addJobNoteFieldEdit" name="notes" class="form-control" :placeholder="$t('label.write a note') + '...'" rows="10" required autofocus></textarea>
                                        </at-ta>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer border-top">
                            <button type="submit" class="btn btn-primary mr-auto">{{ $t('label.update job note') }}</button>
                            <button type="button" class="btn btn-link  ml-auto close2editnote" data-dismiss="modal">{{ $t('label.close') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- MODAL FOR DELETE -->
        <div class="modal fade" id="deleteModal1" tabindex="-1" role="dialog" aria-labelledby="deleteModal1" aria-hidden="true">
            <div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
                <div class="modal-content bg-gradient-danger">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="deleteJobNote">
                        <div class="modal-body">
                            <div class="py-3 text-center">
                                <i class="ri-error-warning-line ni-3x"></i>
                                <h4 class="heading mt-4 text-uppercase">{{ $t('label.ARE YOU SURE YOU WANT TO DELETE THIS NOTE') }}?</h4>
                                <p>{{ $t('label.This action cannot be undone') }}!</p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-white">{{ $t('label.Yes, Delete') }}</button>
                            <button type="button" class="btn btn-link text-white ml-auto" data-dismiss="modal">{{ $t('label.cancel') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import At from 'vue-at';
import AtTa from 'vue-at/dist/vue-at-textarea';
import {
    mapGetters
} from 'vuex';

export default {
    components: {
        At,
        AtTa,
    },
    props: ['jobid'],
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            form: {
                add: {
                    notes: null
                },
                edit: {
                    notes: null,
                    id: null
                }
            },
            showDropdown: false,
            columns: ['notes', 'created_by.name', 'created_at', 'actions'],
            options: {
                perPageValues: [],
                headings: {
                    notes: 'Notes',
                    'created_by.name': 'Created By',
                    created_at: 'Date Added',
                    actions: ''
                },
                editableColumns: ['notes', 'created_by.name', 'created_at'],
                sortable: ['notes', 'created_by.name', 'created_at'],
                filterable: false,
                columnsClasses: {
                    notes: 'text-left w-50',
                    'created_by.name': 'text-left',
                    created_at: 'text-left',
                    actions: 'text-center actions-column'
                },
            },
            showLess: true,
            delete_id: null,
            save_in_progress: false,
            allUsers: [],
            selectedNames: [],
            tasks: [],
        }
    },
    computed: {
        ...mapGetters(['alljobnotes']),
    },
    created() {
        this.getAllUsers();
        this.getTasks();
    },
    mounted() {
        $('#addJobNotesModal, #editJobNotesModal').on('shown.bs.modal', function () {
            let checkExist = setInterval(function () {
                if ($('#addJobNoteField').length || $('#addJobNoteFieldEdit').length) {
                    $('#addJobNoteField').focus();
                    $('#addJobNoteFieldEdit').focus();
                    clearInterval(checkExist);
                }
            }, 10);
        });

        this.getJobNotes();
        return _.orderBy(this.alljobnotes, ['created_at', 'desc']);
    },
    methods: {
        getAllUsers() {
            axios.get('/get-all-users').then(response => {
                // this.allUsers = response.data.result;
                response.data.result.forEach((data, key) => {
                    this.allUsers.push({
                        name: data.name,
                        id: data.id
                    });
                });
            })
                .catch(err => console.error(err));
        },
        getTasks() {
            axios.get('/tasks/' + this.jobid)
                .then(response => {
                    this.tasks = response.data.result;
                    this.tasks.push({
                        id: 0,
                        name: 'N/A',
                        sequence: 0
                    });
                    this.tasks = _.orderBy(this.tasks, ['sequence'], ['asc']);
                })
                .catch(err => console.error("API ERROR", err));
        },
        getJobNotes() {
            this.$store.dispatch('getJobNotes', this.jobid);
        },
        saveJobNote() {
            if (!this.save_in_progress) {
                this.save_in_progress = true;

                let tempText = $('#addJobNoteField').val();
                this.form.add.notes = tempText;

                this.allUsers.forEach((data, key) => {
                    if (tempText.includes(data.name)) {
                        this.selectedName(data);
                    }
                });

                var request_fields = {
                    'notes': this.form.add.notes,
                    'job_id': this.jobid,
                    'selected_names': this.selectedNames,
                    'task_id': this.form.add.relatedTask
                };

                axios.post('/jobnotes', request_fields)
                    .then(response => {
                        this.form.add.notes = '';
                        this.getJobNotes();
                        $('#addJobNotesModal').modal('hide');
                        this.save_in_progress = false;
                    })
                    .catch(err => {
                        //console.log('list carriers error:', error)
                    });
            }

        },
        editModal(item) {
            // this.form.edit.notes = item.notes
            this.form.edit.notes = item.notes.replace(/<\/?[^>]+>/ig, "");
            this.form.edit.id = item.id;
            this.form.edit.relatedTask = item.task_id;
            this.selectedNames = item.tagged_users;
        },
        updateJobNote() {
            let tempText = $('#addJobNoteFieldEdit').val();
            this.form.edit.notes = tempText;

            this.allUsers.forEach((data, key) => {
                if (tempText.includes(data.name)) {
                    this.selectedName(data);
                }
            })

            var request_fields = {
                'notes': this.form.edit.notes,
                'job_id': this.jobid,
                'id': this.form.edit.id,
                'selected_names': this.selectedNames,
                'task_id': this.form.edit.relatedTask
            };
            axios.post('/jobnotes/update', request_fields)
                .then(response => {
                    this.getJobNotes();
                })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });

            let url = window.location.pathname.split("/");
            if ((url[1] == 'job' && url[3] == 'edit') || url[1] == 'calendar' || url[1] == 'dashboard') {
                $('#editJobNotesModal').modal('hide');
            }

            // document.querySelector('.close2editnote').click();
        },
        deleteNotify(delete_id) {
            this.delete_id = delete_id;
            // $("#deleteModal1").modal('show');
        },
        deleteJobNote() {
            axios.post('/delete-job-note/' + this.delete_id)
                .then(response => {
                    this.getJobNotes();
                    $("#deleteModal1").modal('hide');
                })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        selectedName(data) {
            if (this.findArray(data.id) === null)
                this.selectedNames.push(data);
        },
        findArray(value) {
            let countLayer = this.selectedNames.length;
            if (countLayer > 0) {
                for (var x = 0; x < countLayer; x++) {
                    if (this.selectedNames[x].id === value) {
                        return this.selectedNames[x];
                    }
                }
            }
            return null;
        },
    }
}
</script>

<style>
.atwho-li {
    /* more */
}

.atwho-li img {
    /* more */
}

.atwho-li span {
    /* more */
}

.atwho-view {
    top: 30px;
    position: relative;
}

.editor {
    height: 250px;
}
</style>
