<template>
    <div class="msg-modal">
        <!-- Prompt -->
        <div v-if="messages.type == 'prompt'" class="modal show modal-prompt" id="messagePreviewModal" tabindex="-1" role="dialog" aria-labelledby="messagePreviewModalTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div>
                            <div class="alert alert-notif alert-warning d-flex justify-content-between w-100">
                                <span class="alert-text"><strong>{{ $t('label.message preview') }}!</strong> </span>
                                <button v-if="messages.isPreview" type="button" class="btn btn-sm btn-default" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#messageAddModal">
                                    {{ $t('label.close preview') }}
                                </button>
                                <button v-else type="button" class="btn btn-sm btn-default" data-dismiss="modal" data-toggle="modal" data-target="#messageAddModal" aria-label="Close">
                                    {{ $t('label.close preview') }}
                                </button>
                            </div>

                            <div style="width: 50%; margin: 0 auto">
                                <p style="font-size: xxx-large; font-weight: bold">{{ messages.form.title }}</p>
                                <div v-if="messages.form.image" style="background-position: 50% 50%;background-size: cover;background-repeat: no-repeat;margin: 0 auto;">
                                    <img style="width: 100%" :src="messages.form.image">
                                </div>
                                <div class="py-3">
                                    <p style="font-size: large;">{{ messages.form.body }}</p>
                                </div>
                                <div v-if="messages.form.btnAction == 'link' && messages.form.btnLabel != '' && messages.form.btnLabel != null" class="text-center">
                                    <a type="button" class="btn btn-primary text-white" :href="messages.form.btnUrl" target="_blank">{{ messages.form.btnLabel }}</a>
                                </div>
                                <div v-if="messages.form.btnAction == 'close' && messages.form.btnLabel != '' && messages.form.btnLabel != null" class="text-center">
                                    <button type="button" class="btn btn-primary text-white" data-dismiss="modal" data-toggle="modal" data-target="#messageAddModal">{{ messages.form.btnLabel }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Popup -->
        <div v-if="messages.type == 'popup'" class="modal show modal-popup modal-popup-preview" id="messagePreviewModal" tabindex="-1" role="dialog" aria-labelledby="messagePreviewModalTitle" aria-hidden="true">
            <div class="modal-dialog d-flex justify-content-center align-items-center h-100 my-0 mx-auto" role="document" style="max-width: 700px">
                <div class="modal-content">
                    <div class="modal-body">
                        <div>
                            <div class="alert alert-notif alert-warning d-flex justify-content-between w-100">
                                <span class="alert-text"><strong>{{ $t('label.message preview') }}!</strong> </span>
                                <button v-if="messages.isPreview" type="button" class="btn btn-sm btn-default" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#messageAddModal">
                                    {{ $t('label.close preview') }}
                                </button>
                                <button v-else type="button" class="btn btn-sm btn-default" data-dismiss="modal" data-toggle="modal" data-target="#messageAddModal" aria-label="Close">
                                    {{ $t('label.close preview') }}
                                </button>
                            </div>

                            <div class="body">
                                <p style="font-size: xxx-large; font-weight: bold">{{ messages.form.title }}</p>
                                <div v-if="messages.form.image" style="background-position: 50% 50%;background-size: cover;background-repeat: no-repeat;margin: 0 auto;">
                                    <img style="width: 100%" :src="messages.form.image">
                                </div>
                                <div class="py-3">
                                    <p style="font-size: large;">{{ messages.form.body }}</p>
                                </div>
                                <div v-if="messages.form.btnAction == 'link' && messages.form.btnLabel != '' && messages.form.btnLabel != null" class="text-center">
                                    <a type="button" class="btn btn-primary text-white" :href="messages.form.btnUrl" target="_blank">{{ messages.form.btnLabel }}</a>
                                </div>
                                <div v-if="messages.form.btnAction == 'close' && messages.form.btnLabel != '' && messages.form.btnLabel != null" class="text-center">
                                    <button type="button" class="btn btn-primary text-white" data-dismiss="modal" data-toggle="modal" data-target="#messageAddModal">{{ messages.form.btnLabel }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Snippet -->
        <div v-if="messages.type == 'snippet'" class="modal show modal-popup modal-snippet" id="messagePreviewModal" tabindex="-1" role="dialog" aria-labelledby="messagePreviewModalTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div>
                            <div class="alert alert-notif alert-warning d-flex justify-content-between w-100 modal-box-shadow">
                                <span class="alert-text"><strong>{{ $t('label.message preview') }}!</strong> </span>
                                <button v-if="messages.isPreview" type="button" class="btn btn-sm btn-default" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#messageAddModal">
                                    {{ $t('label.close preview') }}
                                </button>
                                <button v-else type="button" class="btn btn-sm btn-default" data-dismiss="modal" data-toggle="modal" data-target="#messageAddModal" aria-label="Close">
                                    {{ $t('label.close preview') }}
                                </button>
                            </div>

                            <div class="body modal-box-shadow">
                                <div class="">
                                    <h4>{{ messages.form.title }}</h4>
                                    <p style="font-size: large;">{{ messages.form.body }}</p>
                                </div>
                                <div v-if="messages.form.btnAction == 'link' && messages.form.btnLabel != '' && messages.form.btnLabel != null" class="text-center">
                                    <a type="button" class="btn btn-primary btn-sm" :href="messages.form.btnUrl" target="_blank">{{ messages.form.btnLabel }}</a>
                                </div>
                                <div v-if="messages.form.btnAction == 'close' && messages.form.btnLabel != '' && messages.form.btnLabel != null" class="text-center">
                                    <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal" data-toggle="modal" data-target="#messageAddModal">{{ messages.form.btnLabel }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Banner -->
        <div v-if="messages.type == 'banner'" class="modal show modal-prompt modal-banner" id="messagePreviewModal" tabindex="-1" role="dialog" aria-labelledby="messagePreviewModalTitle" aria-hidden="true">
            <div class="modal-dialog" role="document" style="width: 100%; background: transparent">
                <div class="modal-content">
                    <div class="modal-body">
                        <div>
                            <div class="alert alert-notif bg-white d-flex justify-content-between align-items-center w-100 modal-box-shadow">
                                <span class="alert-text pr-5"><strong class="mx-2">{{ messages.form.title }}</strong> {{ messages.form.body }} </span>
                                <div v-if="messages.form.btnAction == 'link' && messages.form.btnLabel != '' && messages.form.btnLabel != null" class="text-center">
                                    <a type="button" class="btn btn-primary btn-sm" :href="messages.form.btnUrl" target="_blank">{{ messages.form.btnLabel }}</a>
                                </div>
                                <div v-if="messages.form.btnAction == 'close' && messages.form.btnLabel != '' && messages.form.btnLabel != null" class="text-center">
                                    <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal" data-toggle="modal" data-target="#messageAddModal">{{ messages.form.btnLabel }}</button>
                                </div>
                            </div>

                            <div class="alert alert-notif alert-warning d-flex justify-content-between w-100 modal-box-shadow">
                                <span class="alert-text"><strong>{{ $t('label.message preview') }}!</strong> </span>
                                <button v-if="messages.isPreview" type="button" class="btn btn-sm btn-default" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#messageAddModal">
                                    {{ $t('label.close preview') }}
                                </button>
                                <button v-else type="button" class="btn btn-sm btn-default" data-dismiss="modal" data-toggle="modal" data-target="#messageAddModal" aria-label="Close">
                                    {{ $t('label.close preview') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters(['messages']),
    },
    created() {
        this.$parent.$on('remove', this.removeBackdropOverlay);
    },
    mounted() {
    },
    methods: {
        removeBackdropOverlay() {
            setTimeout(() => {
                if (this.messages.type == 'banner' || this.messages.type == 'snippet') {
                    $('.modal-backdrop').addClass(' backdrop-remove');
                    $('.modal-backdrop').removeClass('backdrop-add');
                } else {
                    $('.modal-backdrop').addClass(' backdrop-add');
                    $('.modal-backdrop').removeClass('backdrop-remove');
                }
            }, 1);
        }
    }
}
</script>
