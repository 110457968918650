<template>
    <div class="purchase-order">
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50">
            <!-- Header -->
            <div class="d-flex z-index-10">
                <!-- Breadcrumbs -->
                <div class="mr-auto z-index-1">
                    <breadcrumbs :title="$t('label.edit') + ' ' + formInfo.order_number">
                        <!-- Breakcrumb slots -->
                        <li class="breadcrumb-item">
                            <a class="pointer" @click="$router.go(-1)">{{ $t('label.vpos') }}</a>
                        </li>
                        <li class="breadcrumb-item">
                            <span>{{ formInfo.order_number }}</span>
                        </li>
                    </breadcrumbs>
                </div>

                <!-- Vpo Save/Cancel -->
                <div class="py-4 align-items-center" style="z-index: 1">
                    <button type="button" class="btn btn-link">
                        <a @click="$router.back()" class="text-white">{{ $t('label.cancel') | uppercase }}</a>
                    </button>
                    <button type="button" class="btn btn-neutral" @click="validateVpo('save')">
                        <span class="text-default">{{ $t('label.save vpo') | uppercase }}</span>
                    </button>
                    <button type="button" class="btn btn-neutral" @click="validateVpo('save_send')">
                        <span class="text-default">{{ $t('label.save and send') | uppercase }}</span>
                    </button>
                </div>
            </div>

            <!-- VPO Info -->
            <VpoInfo :form-info="formInfo" :is-valid="isValid" :po-type="'edit'"></VpoInfo>

            <!-- VPO Items -->
            <VpoItems :form="formItem" :is-valid="isValid" type="edit" :formDirty="formDirty"></VpoItems>

            <!-- Footer Save or Cancel Buttons -->
            <div class="d-flex text-right">
                <div class="ml-auto">
                    <span class="d-block d-sm-inline pb-2">
                        <button type="button" class="btn btn-link">
                            <a @click="$router.back()">{{ $t('label.cancel') | uppercase }}</a>
                        </button>
                    </span>
                    <span class="d-block d-sm-inline">
                        <button type="button" class="btn btn-primary" @click="validateVpo('save')">
                            {{ $t('label.save vpo') | uppercase }}
                        </button>
                    </span>
                    <span class="d-block d-sm-inline">
                        <button type="button" class="btn btn-primary" @click="validateVpo('save_send')">
                            {{ $t('label.save and send') | uppercase }}
                        </button>
                    </span>
                </div>
            </div>
        </div>

        <!-- Send Modal -->
        <modal class="py-4" name="modalVpoSendOnEdit" height="auto" width="100%" :max-width="900" :scrollable="true" :reset="true" :adaptive="true">
            <ModalVpoSend :vpo="savedVpoData" send-type="save_request" :beforeSendFnc="saveVpo" @cancelFnc="$modal.hide('modalVpoSendOnEdit')" @returnAfterSend="toPreviousPage()"></ModalVpoSend>
        </modal>
    </div>
</template>

<script>
import VpoInfo from '@/components/VariablePurchaseOrders/AddEditVariablePurchaseOrders/VpoInfo.vue';
import VpoItems from '@/components/VariablePurchaseOrders/AddEditVariablePurchaseOrders/VpoItems.vue';
import ModalVpoSend from '@/components/VariablePurchaseOrders/SendVariablePurchaseOrder/VpoSendModal.vue';
import bus from '@/utils/event-bus';

export default {
    props: ['id'],
    components: {
        VpoInfo,
        VpoItems,
        ModalVpoSend
    },
    data() {
        return {
            formDirty: false,
            savedVpoData: {
                id: null,
                customer: null,
                vpo_number: {
                    vpo_number: null
                }
            },
            formInfo: {
                comments: '',
                job: null,
                terms: null,
                job_id: null,
                status: null,
                customer: null,
                job_name: null,
                customer_id: null,
                order_number: "",
                customer_name: null,
                delivery_date: null,
                superintendent_user_id: null,
                purchase_date: moment().format('MM/DD/YYYY'),
            },
            formItem: {
                list_items: [],
                list_items_to_delete: [],
                total: parseFloat(0.00).toFixed(2)
            },
            isValid: {
                items: null,
                customer_name: null,
                superintendent_user_id: null,
                job_name: null,
                purchase_date: true,
            },
        }
    },
    mounted() {
        this.getVpo()
    },
    methods: {
        getVpo() {
            return axios.get(`/vpo_get_by_id/` + this.id).then(rsp => {
                let vpoData = rsp.data.result;

                // Form Info
                this.copyObjData(this.formInfo, vpoData);
                this.formInfo.status = vpoData.vpo_status.id;
                this.formInfo.comments = vpoData.description;
                this.formInfo.job_name = vpoData.job.job_name;
                this.formInfo.customer_name = vpoData.customer.name;
                this.formInfo.order_number = 'VPO-' + vpoData.vpo_number.vpo_number;
                this.formInfo.purchase_date = vpoData.purchase_date ? moment(vpoData.purchase_date).format('MM/DD/YYYY') : null;
                this.formInfo.delivery_date = vpoData.delivery_date ? moment(vpoData.delivery_date).format('MM/DD/YYYY') : null;

                // Form items
                this.formItem.list_items = vpoData.vpo_items;
                this.formItem.total = parseFloat(vpoData.overall_total).toFixed(2);
            }).catch(err => console.log(err));
        },

        // Validate all necessary fields in child components
        validateVpo(type) {
            try {
                let formValid = true;
                this.touchForms();

                // Check fieldsWithError for fields with an error
                for (const key in this.isValid) {
                    if ((this.isValid[key] === false))
                        formValid = false;
                    if (this.isValid[key] === null) {
                        formValid = false;
                        this.isValid[key] = false;
                    }
                }

                // Validate number of items
                if (this.formItem.list_items.length < 1)
                    throw new Error(this.$t('label.vpo must have at least one billable item') + '.');
                // Validate Form Fields
                if (!formValid)
                    throw new Error(this.$t('label.please validate required fields before proceeding') + '.');
                // Validate VPO has billable amount
                if (this.formItem.total <= 0)
                    throw new Error(this.$t('label.cannot create a vpo with a total amount of 0') + '.');

                this.saveVpo().then(() => {
                    // Save the draft and return to previous page
                    if (type == 'save') {
                        this.toPreviousPage();
                    }

                    // Prepare the send vpo to customer modal which will call 
                    // back to save the vpo and then send the vpo afterwards.
                    else if (type == 'save_send') {
                        this.$router.push({
                            name: 'variablePurchaseOrders',
                            params: {
                                id: this.id,
                                modalToOpen: 'send_vpo'
                            }
                        });
                    }
                });

            } catch (err) {
                bus.$emit('banner-error', { message: err });
            }
        },
        touchForms() {
            this.formDirty = false;
            this.$nextTick(() => this.formDirty = true);
        },

        saveVpo() {
            this.loader = this.$loading.show();
            let mergedForm = {
                vpo_id: this.id,
                form_info: this.formInfo,
                form_item: this.formItem,
            };

            return axios.post('/vpo-update', mergedForm).then(response => {
                this.loader.hide();

                bus.$emit('banner-success', {
                    message: $t('label.vpo saved successfully!')
                });
            });
        },

        copyObjData(obj1, obj2) {
            for (const key in obj2)
                if (key in obj1) obj1[key] = obj2[key];
        },
        toPreviousPage() {
            this.$router.go(-1);
        }
    },
}
</script>
