const mutations = {
  ///////////////////////////////////////////////
  // User/Account
  ///////////////////////////////////////////////

  // Updates user info in state
  UPDATE_USER_INFO(state, payload) {
    state.AppActiveUser = payload;
  },

  UPDATE_DOMAIN(state, payload) {
    state.domain = payload;
  },

  AUTH_ROUTE_GUARD_LOADING: (state, isLoading) => {
    state.isRouteGuardLoading = isLoading;
  },

  AUTH_SUCCESS: (state, user) => {
    (state.status = 'success'), (state.currentUser = user);
  },

  LOGOUT: (state) => {
    state.AppActiveUser = {};
  }
};

export default mutations;
