<template>
	<div class="card h-100">
		<div class="card-header handle cursor-move">
			<div class="row align-items-center">
				<h3 class="mb-0 d-flex align-items-center">
					<i class="ri-drag-move-fill"></i>
					<span v-if="widgetType == 'open'">Open Tasks</span>
					<span v-if="widgetType == 'overdue'">Overdue Tasks</span>
					<span v-if="widgetType == 'closed'">Recently Closed Tasks</span>
				</h3>
			</div>
		</div>

		<div v-if="tasks.length == 0">
			<div class="card-body m-0 py-0">
				<div class="bg-white text-center row align-items-center m-0">
					<div class="w-100 text-center align-items-center">
						<i class="ri-stack-line ni-8x w-100 color-gray-300"></i>
						<span v-if="widgetType == 'open'">
							<p class="lead w-100 mt-0 font-weight-normal">{{ $t('label.No Open Tasks') }}.</p>
							<p class="w-100 font-weight-normal">{{ $t('label.All of your Open Tasks will be listed here') }}.</p>
						</span>
						<span v-if="widgetType == 'overdue'">
							<p class="lead w-100 mt-0 font-weight-normal">{{ $t('label.Amazing work') }}!</p>
							<p class="w-100 font-weight-normal">{{ $t('label.All of your Tasks are on schedule') }}. {{ $t('label.Keep it up') }}!</p>
						</span>
						<span v-if="widgetType == 'closed'">
							<p class="lead w-100 mt-0 font-weight-normal">{{ $t('label.Nothing to show here') }}.</p>
							<p class="w-100 font-weight-normal">{{ $t('label.None of your Tasks have been closed in the last 3 days') }}.</p>
						</span>
					</div>
				</div>
			</div>
		</div>

		<div v-if="tasks.length > 0" class="table-responsive pb-4 h-100" style="min-height:200px;">
			<table class="table table-sm border-bottom" style="margin: 0 !important;">
				<thead class="thead-light">
					<tr>
						<th :class="cols[0]">Task Name</th>
						<th :class="cols[1]">Job Name</th>
						<th v-if="widgetType == 'overdue'" :class="cols[4]">Due Date</th>
						<th :class="cols[2]">Assigned</th>
						<th :class="cols[3]"></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in paginatedJobs" :key="index">
						<!-- Task Name -->
						<td :class="cols[0]">
							<a class="pointer selected-text-white text-dark-blue search-task" @click="thisOpenTaskOffCanvas(item)">{{ item.name }}</a>
						</td>
						<!-- Job Name -->
						<td :class="cols[1]">
							<router-link v-if="user.role_id == '2' && item.job.job_number" :to="{ path: `jobs/edit/${item.job.job_number.job_number}` }">
								{{ item.job.job_name }}
							</router-link>
							<a v-else class="selected-text-white">{{ item.job.job_name }}</a>
						</td>
						<!-- Due Date -->
						<td v-if="widgetType == 'overdue'" :class="cols[4]">
							<span class="badge badge-danger" style="font-size: 85%;">{{ item.due_date | formatDate }}</span>
						</td>
						<!-- avatar -->
						<td :class="cols[2]" class="text-center">
							<div class="avatar avatar-sm cursor-pointer" v-if="item.assigned_to.length">
								<template v-for="(d, i) in item.assigned_to">
									<img :key="i" v-if="d.user" :src="d.user.picture" alt="" class="avatar-img rounded-circle" :class="{ 'crew-member-avatar': 0 < i }">
								</template>
							</div>
						</td>

						<!-- Menu -->
						<td class="text-right" :class="cols[3]">
							<div class="dropdown">
								<a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<i class="fas fa-ellipsis-v"></i>
								</a>
								<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
									<!-- task edit modal -->
									<span v-if="user.role_id < 3">
										<a class="dropdown-item pointer text-dark" @click="onClickEditModal(item)">
											<i class="ri-pencil-line"></i> {{ $t('label.edit task') }}
										</a>
										<div class="dropdown-divider"></div>
									</span>

									<!-- Add job note -->
									<a class="dropdown-item pointer text-dark" @click="showModifyNoteModal(item)">
										<i class="ri-draft-line"></i> {{ $t('label.add job note') }}
									</a>
									<!-- Add job file -->
									<a class="dropdown-item pointer text-dark" @click="showAddJobFile(item)">
										<i class="ri-folders-line"></i> {{ $t('label.add file') }}
									</a>
									<!-- View Job -->
									<template v-if="user.role_id < 3">
										<div class="dropdown-divider"></div>
										<router-link class="dropdown-item" v-if="user.role_id == '2' && item.job.job_number" :to="{ path: `jobs/edit/${item.job.job_number.job_number}` }">
											<i class="ri-stack-line"></i>{{ $t('label.view job') }}
										</router-link>
										<span v-if="user.role_id < 3">
											<div class="dropdown-divider"></div>
											<!-- Mark Complete -->
											<a v-if="widgetType != 'closed'" class="dropdown-item text-dark" href="#" @click="markAsComplete(item)"><i class="ri-check-line"></i> {{ $t('label.mark as complete') }}</a>
											<a v-if="widgetType == 'closed'" class="dropdown-item text-dark" href="#"><i class="fas fa-times"></i> {{ $t('label.task closed') }}</a>
										</span>
									</template>
								</div>
							</div>

						</td>
					</tr>
				</tbody>
			</table>
			<table-pagination v-model="paginatedJobs" :btnLimit="3" :btnSize="'sm'" :all-rows="tasks" :perPage="perPage">
			</table-pagination>
		</div>

		<!-- Job Files -->
		<modal :name="'taskWidgAddJobFile' + widgetType" height="auto" :scrollable="true" :reset="true" :adaptive="true">
			<job-file-upload :jobid="jobid" @close-add-file-modal="hideAddJobFile"></job-file-upload>
		</modal>

		<!-- Add Job Notes Modal -->
		<modal :name="'taskWidgAddJobNote' + widgetType" height="auto" :scrollable="true" :reset="true" :adaptive="true">
			<job-notes-modify :jobid="jobid" @close-add-note-modal="hideModifyNoteModal"></job-notes-modify>
		</modal>

		<!-- task Edit Modal -->
		<modal class="py-4" :name="'editTheTaskModal' + widgetType" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
			<modal-task :jobId="selectedTask.job_id" :task="selectedTask" :isTemplate="false" source="task" @getTasks="reloadThis" @cancelFnc="$modal.hide('editTheTaskModal' + widgetType)" />
		</modal>
	</div>
</template>

<script>
import jobFileUpload from '@/components/Jobs/JobFileUpload';
import JobNotesModify from '@/components/Jobs/JobNotesModify';
import ModalTask from '@/components/ModalTask';
import TablePagination from '@/components/TablePagination';
import bus from '@/utils/event-bus';
;

export default {
	props: ['widgetType', 'data'],
	components: {
		jobFileUpload,
		JobNotesModify,
		TablePagination,
		ModalTask
	},
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
			selectedTask: [],
			jobid: null,
			tasks: [],
			jobData: {},
			paginatedJobs: [],
			perPage: 4,
			cols: ['col-4', 'col-4', 'col-2', 'col-2'],
		}
	},
	watch: {
		// wait for the data prop to get loaded
		data() {
			//Sort the tasks by name asc
			this.tasks = _.orderBy(this.data, 'job_name', 'asc')
		}
	},
	mounted() {
		this.setUpTable()
	},
	methods: {
		setUpTable() {
			if (this.widgetType == 'overdue')
				this.cols = ['col-4', 'col-3', 'col-2', 'col-2', 'col-1'];
		},
		showAddJobFile(item) {
			this.jobid = item.job.id;
			this.$modal.show('taskWidgAddJobFile' + this.widgetType);
		},
		hideAddJobFile() {
			this.$modal.hide('taskWidgAddJobFile' + this.widgetType);
		},
		showModifyNoteModal(item) {
			this.jobid = item.job.id;
			this.$modal.show('taskWidgAddJobNote' + this.widgetType);
		},
		hideModifyNoteModal() {
			this.$modal.hide('taskWidgAddJobNote' + this.widgetType);
		},
		toggleCloseJobModal(data, show) {
			bus.$emit('toggleCloseJobModal', data.id, show)
		},
		showEditJobModal(item) {
			this.jobData = item;
			this.$modal.show('jobsOpenEditJob');
		},
		hideEditJobModal() {
			this.$modal.hide('jobsOpenEditJob');
		},
		thisOpenTaskOffCanvas(data) {
			this.selectedTask = data;
			this.jobid = data.job.id;
			bus.$emit('openTaskOffCanvas', data.id)
		},
		onClickEditModal(task) {
			this.selectedTask = task
			this.$modal.show('editTheTaskModal' + this.widgetType)
		},
		reloadThis() {
			bus.$emit('getallwidgets');
		},
		markAsComplete(data) {
			var request_fields = {
				'task_id': data.id,
			};
			axios.post('/mark-as-complete', request_fields)
				.then(response => {
					this.reloadThis();
					bus.$emit('banner-success', {
						message: `Task completed successfully!`,
					});
				})
				.catch(error => {
					console.log('ERROR OCCURED', error)
				});
		},
	}
}
</script>