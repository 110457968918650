<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>
        <div class="container-fluid pb-5 sp-mobile-mt-50" id="jobTypesContainer">
            <!-- HEADER -->
            <div class="d-flex" style="z-index: 100">
                <!-- Breadcrumbs -->
                <breadcrumbs :title="'Payroll'">
                    <!-- Breakcrumb slots -->
                    <!-- <li class="breadcrumb-item">
                        <router-link class="text-primary pointer" :to="{ name: 'reports' }">{{ $t('label.reports') }}</router-link>
                    </li>
                    <li class="breadcrumb-item">
                        Payroll Report
                    </li> -->
                </breadcrumbs>
            </div>
            <!-- Content  -->
            <div class="card">
                <div class="card-header">
                    <div class="row d-flex align-items-center">
                        <div class="col-4">
                            <h3>1099 Payroll Report</h3>
                        </div>

                        <div class="col-8 d-flex align-items-center justify-content-end">
                            <router-link class="btn btn-outline-primary btn-sm" :to="{ name: 'payrollReport' }">
                                View W2 Employee Payroll
                            </router-link>
                            <div v-if="hasData == 2">
                                <button v-if="selectedPayPeriod.is_closed" type="button" class="btn btn-secondary btn-sm pull-right" @click="sync1099Payroll2QB()">
                                    <i class="ri-refresh-line"></i>Sync QuickBooks Online
                                </button>
                                <button v-if="!selectedPayPeriod.is_closed" type="button" class="btn btn-outline-primary btn-sm" @click="changePayPeriodStatus(1)">
                                    Close Pay Period
                                </button>
                                <button v-else type="button" class="btn btn-outline-primary btn-sm" @click="changePayPeriodStatus(0)">
                                    Reopen Pay Period
                                </button>
                            </div>
                            <div v-if="hasData == 2 && selectedPayPeriod.is_closed" class="pl-2">
                                <!-- <span class="pl-2"> -->
                                <export-excel type="csv" name="payroll1099Export.csv" worksheet="Timesheet Report" class="btn btn-secondary btn-sm" :data="payrollExportData" :fields="payrollExportHeader">
                                    <i class="ri-file-excel-line"></i>
                                    {{ $t('label.export results') }}
                                </export-excel>
                                <!-- TODO Sync QB -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <!-- Search Bar -->
                    <div class="row form-group">
                        <!-- left Column -->
                        <div class="col-sm-4">
                            <div class="row">
                                <div class="col">
                                    <label>Select Month</label>
                                    <select class="form-control" @change="loadPayPeriods" v-model="payPeriodFilter.month" required>
                                        <option v-for="month in $spGetMonths()" :value="month.id" :key="month.id">{{ month.name }}</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <label>Select Year</label>
                                    <select class="form-control" @change="loadPayPeriods()" v-model="payPeriodFilter.year" required>
                                        <option v-for="year in $spGetYears()" :value="year.id" :key="year.id">{{ year.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <!-- Right Column -->
                        <div class="col-sm-8">
                            <div class="row">
                                <!-- Pay Period`` -->
                                <div class="col-sm-5">
                                    <label>Select Pay Period</label>
                                    <select class="form-control" @change="setPayPeriod()" v-model="selectedPayPeriod" placeholder="Pay Period">
                                        <option v-for="payPeriod in payPeriods" :value="payPeriod" :key="payPeriod.id">
                                            {{ payPeriod.start_date | formatDate }} - {{ payPeriod.end_date | formatDate }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-sm-4">
                                    <label for="">User</label>
                                    <select class="form-control" v-model="search.userId" @change="setPayPeriod()">
                                        <option :value="null">All Users</option>
                                        <option v-for="user in users" :value="user.id" :key="user.id">{{ user.company_name }}</option>
                                        <!-- <option v-for="user in users1099company" :value="user.id" :key="user.id">{{ user.company_name }}</option> -->
                                    </select>
                                </div>
                                <div class="col-sm-3 text-center">
                                    <label for="">Pay Period Type</label>
                                    <div class="text-blue">
                                        {{ companySettings.pay_period_type | capitalize }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center text-primary" v-if="hasData == 1">
                        <h3>No data to display for selected pay period.</h3>
                    </div>

                    <div v-if="hasData == 2">
                        <!-- Report Data -->
                        <ul class="list-group" v-if="hasData == 2">
                            <!-- loop user list -->
                            <li class="list-group-item py-0" v-for="user in payrollUsers" :key="user.id">
                                <div class="row bg-gray-600 py-2 text-white">
                                    <div class="col text-bold">{{ user.company_name }}</div>
                                    <div class="col text-bold">Status</div>
                                    <div class="col text-right">Total Amount</div>
                                </div>

                                <!-- Loop each week in the pay period -->
                                <div class="row border-bottom py-2" v-for="(week, idx) in user.payroll" :key="idx">
                                    <div class="col">
                                        <!-- date range -->
                                        <span class="pointer text-primary" @click="showDailybyWeek(user, week)">
                                            <i class="ri-link"></i>{{ week.dateRange.startDate | formatDate('M/DD') }} - {{ week.dateRange.endDate | formatDate('M/DD') }}
                                        </span>
                                    </div>
                                    <div class="col">
                                        <span class="text-danger" v-if="week.unapprovedItemsCount">Unapproved Items</span>
                                        <span class="text-success" v-else>All Items Approved</span>
                                    </div>
                                    <div class="col text-right">
                                        <div>{{ week.weekEarning | toCurrency }}</div>
                                    </div>
                                </div>
                                <!-- Display user pay period grand totals -->
                                <div class="row text-right text-bold bg-gray-100 py-2">
                                    <div class="col-10 col-xs-6">Total Amount</div>
                                    <div class="col-2 col-xs-6">{{ user.payPeriodTotals.payPeriodTotalEarnings | toCurrency }}</div>
                                </div>
                            </li>
                        </ul>

                        <!-- Report Grand Totals -->
                        <div class="card mt-2" v-if="this.payrollUsers.length" id="grandtotal">
                            <div class="card-header bg-gray-300 text-white">Pay Period Grand Total</div>
                            <div class="card-body bg-gray-600 text-white text-right">
                                <div class="row">
                                    <div class="col-10 col-xs-6">Pay Period Amount</div>
                                    <div class="col-2 col-xs-6">{{ reportTotals.reportTotalAmount | toCurrency }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <modal name="showDailybyWeek" height="auto" width="98%" :reset="true" :scrollable="true" :adaptive="true">
                <payroll-user-week-component :selected-week="selectedWeek" @closePayrollWk="getSearchData"></payroll-user-week-component>
            </modal>

            <fade-transition origin="center" :duration="250" tag="div">
                <CustomLoader v-if="qbSyncing" :loaderText="loaderText" />
            </fade-transition>
        </div>
    </div>
</template>
<script>
import PayrollUserWeekComponent from '@/components/Reports/PayrollUserWeekComponent';
import bus from '@/utils/event-bus';
import CustomLoader from './tabs/loader.vue';

export default {
    components: {
        PayrollUserWeekComponent,
        CustomLoader

    },
    data() {
        return {
            companySettings: JSON.parse(localStorage.getItem('company_settings')).settings,
            user: JSON.parse(localStorage.getItem('user')),
            payPeriodFilter: {
                year: 2023,
                month: moment().add(1, 'month').month(),
            },
            search: {
                userId: null,
                startDate: null,
                endDate: null
            },
            selectedPayPeriod: { is_closed: 0 },
            users: [],
            users1099company: [],
            payPeriods: [],
            payrollUsers: [],
            reportTotals: [],
            loader: null,
            selectedWeek: {},
            hasData: 0,
            payrollExportData: [],
            payrollExportHeader: {
                'COMPANY NAME': 'company_name',
                'WEEK': 'date_range',
                'WEEKLY TOTAL PAY': 'total_pay',
            },
            qbSyncing: false,
            loaderText: '',
            user1099NotSyncedToQB: []
        }
    },
    created() {
        bus.$on('reviseTimeModalUpdate', this.getSearchData)
        bus.$on('payrollAdjustPieceRateAllocations_Update', this.getSearchData)
    },
    beforeDestroy() {
        bus.$off('reviseTimeModalUpdate', this.getSearchData)
        bus.$off('payrollAdjustPieceRateAllocations_Update', this.getSearchData)
    },
    mounted() {
        this.loader = this.$loading.show()
        this.load1099Users()
        this.loadPayPeriods()
    },
    methods: {
        load1099Users() {
            axios.get('/load1099Users').then((response) => {
                this.users = response.data;
                this.users.forEach((item) => {
                    if (item.pay1099) {
                        this.users1099company.push(item.pay1099);
                    }
                })
                this.loader.hide()
            })
        },
        loadPayPeriods() {
            axios.post('/getPayPeriodsByMonth', this.payPeriodFilter).then((response) => {
                this.payPeriods = response.data
                if (this.payPeriods.length) {
                    this.selectedPayPeriod = this.payPeriods[0]
                    this.setPayPeriod()
                    this.loader.hide()
                }
                else {
                    this.hasData = 1
                }
            })
        },
        setPayPeriod() {
            this.payrollExportData = [];
            this.payrollUsers = []
            this.reportTotals = []
            this.search.startDate = this.selectedPayPeriod.start_date
            this.search.endDate = this.selectedPayPeriod.end_date
            this.getSearchData()
        },
        getSearchData() {
            axios
                .post('/loadPayroll1099PayPeriodData', this.search)
                .then(response => {

                    this.payrollUsers = response.data.users;

                    this.reportTotals = response.data.reportTotals

                    this.user1099NotSyncedToQB = response.data.user1099NotSyncedToQB
                    // console.log('Users ',this.payrollUsers)

                    if (this.payrollUsers.length > 0) {
                        this.hasData = 2
                        // build csv dataweek
                        this.payrollUsers.forEach(user => {
                            user.payroll.forEach(week => {
                                this.payrollExportData.push(
                                    {
                                        company_name: user.company_name,
                                        date_range: Vue.filter('formatDate')(week.dateRange.startDate, 'MM/DD/YYYY') + '-' + Vue.filter('formatDate')(week.dateRange.endDate, 'MM/DD/YYYY'),
                                        total_pay: (week.weekEarning + week.weekOvertimeEarning).toFixed(2),
                                    }
                                )
                            })
                        })
                    } else {
                        this.hasData = 1
                    }
                })
                .catch(err => {
                    console.log('ERROR: ', err)
                    bus.$emit('banner-error', { message: err, })
                })
        },
        showDailybyWeek(user, week) {
            user.payType = '1099'
            this.selectedWeek = { user: user, week: week }
            this.$modal.show('showDailybyWeek')
        },
        changePayPeriodStatus(status) {
            let statusName = 'Reopened'
            if (status == 1)
                statusName = 'Closed'

            axios
                .post('/changePayPeriodStatus', { periodId: this.selectedPayPeriod.id, status: status })
                .then(response => {
                    this.selectedPayPeriod.is_closed = status
                    this.getSearchData()
                    bus.$emit('banner-success', {
                        message: `Pay period has been ${statusName}!`,
                    })
                })
                .catch(err => {
                    console.log('ERROR: ', err)
                    bus.$emit('banner-error', { message: err, })
                })
        },
        // TODO
        async sync1099Payroll2QB() {

            let start_date = this.selectedPayPeriod.start_date;
            let end_date = this.selectedPayPeriod.end_date;
            let vendors = this.user1099NotSyncedToQB;
            var response;

            this.qbSyncing = true;
            try {

                /*if (vendors.length > 0) {
                    this.loaderText = "Syncing users 1099 to Quickbooks Online...";
                    response = await axios.post('/quickbooks/sync-user-1099', { employee: vendors });
                }*/

                this.loaderText = "Syncing users 1099 to Quickbooks Online...";
                response = await axios.post('/quickbooks/sync-user-1099');
                

                this.loaderText = "Syncing payroll 1099 to Quickbooks Online...";

                response = await axios.post('/quickbooks/sync-payroll-1099', {
                    startDate: start_date,
                    endDate: end_date, userId: null
                });

                bus.$emit('banner-success', { message: response.data.message });

                this.load1099Users();
                this.loadPayPeriods();
            } catch (error) {
                console.log('ERROR: ', error);
                bus.$emit('banner-error', { message: error.data.message });
            } finally {
                this.qbSyncing = false;
            }
        }
    }
}
</script>
