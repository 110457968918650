<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="align-items-center">
                    <h3 class="mb-0 d-flex align-items-center">
                        <i class="ri-map-pin-2-line heading-title"></i>{{ $t('label.identification') }}
                    </h3>
                </div>
            </div>
            <div class="card-body">
                <!-- Item name -->
                <div class="form-group">
                    <label class="form-control-label text-bold" for="item_name">{{ $t('label.item name') }}</label>
                    <span class="text-danger text-xs"> * {{ $t("label.required") }}</span>
                    <input type="text" onfocus="this.select()" class="form-control" :class="{ 'border-danger': (isValid.name === false || $v.identObj.name.$error), }" v-model.trim="$v.identObj.name.$model" @change="validateField('name')" required />

                    <div class="text-danger text-sm" v-if="!$v.identObj.name.minLength">
                        {{ $t('label.must have at least') }} {{ $v.identObj.name.$params.minLength.min }} {{ $t('label.characters') }}.
                    </div>
                </div>
                <!-- Item Type -->
                <div class="form-group">
                    <label class="form-control-label text-bold" for="item_type">{{ $t('label.item type') }}</label>
                    <select class="form-control" v-model.trim="identObj.typeId" required>
                        <option v-for="item in assetTypes" :key="item.id" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
                <!-- Description -->
                <div class="form-group">
                    <label class="form-control-label text-bold" for="description">{{ $t('label.description') }}</label>
                    <textarea class="form-control" onfocus="this.select()" id="description" v-model="identObj.description"></textarea>
                </div>
                <!-- Serial Number -->
                <div class="form-group">
                    <label class="form-control-label text-bold" for="serial_number">{{ $t('label.serial number') }}</label>
                    <input type="text" onfocus="this.select()" class="form-control" id="serial_number" v-model="identObj.serialNumber" />
                </div>

                <!-- Item Photo -->
                <div class="form-group">
                    <label class="form-control-label text-bold" for="serial_number">{{ $t('label.photo') }}</label>

                    <!-- Show Photo -->
                    <div class="form-group" v-if="identObj.uploadedFile || identObj.photo">
                        <div class="form-group d-flex justify-content-left">
                            <button type="button" class="btn btn-outline-danger btn-sm" @click="removePhoto()">
                                <i class="ri-delete-bin-line"></i>
                                {{ $t('label.remove photo') | uppercase }}
                            </button>
                        </div>
                        <div class="form-group d-flex justify-content-center">
                            <img v-if="identObj.photo" class="text-center sp-asset-form-img" :src="identObj.photo" alt="" />
                            <img v-if="identObj.uploadedFile" class="text-center sp-asset-form-img" :src="identObj.uploadedFile.dataURL" alt="" />
                        </div>
                    </div>
                    <!-- Load New Photo -->
                    <div v-else>
                        <vue-dropzone style="z-index: 0" ref="myVueDropzone" id="dropzone" name="dropzone" :options="dropzoneOptions" :destroyDropzone="false" v-on:vdropzone-success="getUploadedFile"></vue-dropzone>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import bus from '@/utils/event-bus';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import {
minLength, required
} from 'vuelidate/lib/validators';
;

export default {
    props: ['existingItem', 'identObj', 'isValid'],
    components: {
        vueDropzone: vue2Dropzone,
    },
    data() {
        return {
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                maxFiles: 1,
                acceptedFiles: 'image/*',
                capture: 'camera',
                addRemoveLinks: true,
                dictDefaultMessage: `
                        <div>
                            <span>${this.$t('label.drag drop files')}</span><br>
                            <span>${this.$t('label.or')}</span><br>
                            <button type="button" class="btn btn-primary">
                                ${this.$t('label.browse')}
                                ${this.$t('label.files')}                                
                            </button>
                        </div>
                    `,
            },
            assetTypes: []
        };
    },
    validations: {
        identObj: {
            name: {
                required,
                minLength: minLength(4)
            }
        },
    },
    mounted() {
        this.getAssetTypes()
    },
    methods: {
        getAssetTypes() {
            axios
                .get('/get-asset-types')
                .then(response => {
                    this.assetTypes = response.data;
                })
                .catch(err => {
                    console.log('ERROR: ', err);
                    bus.$emit('banner-error', { message: err, });
                });
        },
        getUploadedFile(file, response) {
            // Wait for dropzone animation, then save file to identObj
            setTimeout(() => {
                this.identObj.uploadedFile = file;

                console.log('In getUploadFiles', this.identObj);
            }, 1000);
        },

        // Validate Necessary Fields for Parent validation
        validateField(field) {
            // If field has error, set isValid field to false
            this.isValid[field] = !this.$v.identObj[field].$error;
        },

        removePhoto() {
            console.log('Im removing the photo');
            this.identObj.photo = null;
            this.identObj.uploadedFile = null;
        },
    },
};
</script>
