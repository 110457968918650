<template>
    <modal :name="modalName" @closed="$emit('onClose')" :classes="'rounded'" :min-height="400" width="80%" :scrollable="true" height="auto" :reset="true" :adaptive="true">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header border-bottom">
                <h5 class="modal-title"> {{ $t('label.new vpo') }}</h5>
                <button type="button" class="close" @click="$emit('onClose')">
                    <i class="ri-close-line"></i>
                </button>
            </div>

            <!-- Modal Body -->
            <div class="modal-body purchase-order">
                <!-- VPO Info -->
                <VpoInfo :form-info="formInfo" :is-valid="isValid" :po-type="'add'" :job-id="jobId"></VpoInfo>

                <!-- VPO Items -->
                <VpoItems :form="formItem" :is-valid="isValid" type="add" :formDirty="formDirty"></VpoItems>
            </div>

            <!-- Modal Footer -->
            <div class="modal-footer border-top">
                <div class="d-flex text-right">
                    <div class="ml-auto">
                        <span class="d-block d-sm-inline pb-2">
                            <button type="button" class="btn btn-link" @click="$emit('onClose')">
                                {{ $t('label.cancel') | uppercase }}
                            </button>
                        </span>
                        <span class="d-block d-sm-inline">
                            <button type="button" class="btn btn-primary" @click="validateNewItem('save')">
                                {{ $t('label.save draft') | uppercase }}
                            </button>
                        </span>
                        <span class="d-block d-sm-inline">
                            <button type="button" class="btn btn-primary" @click="validateNewItem('save_send')">
                                {{ $t('label.save and send') | uppercase }}
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import VpoInfo from '@/components/VariablePurchaseOrders/AddEditVariablePurchaseOrders/VpoInfo.vue';
import VpoItems from '@/components/VariablePurchaseOrders/AddEditVariablePurchaseOrders/VpoItems.vue';
import ModalVpoSend from '@/components/VariablePurchaseOrders/SendVariablePurchaseOrder/VpoSendModal.vue';
import bus from '@/utils/event-bus';

export default {
    props: ['source', 'jobId'],
    emits: ['onClose', 'onSaveAndSend'],
    components: {},
    components: {
        VpoInfo,
        VpoItems,
        ModalVpoSend
    },
    data() {
        return {
            modalName: "VpoCreateModal" + this.source,
            formDirty: false,
            savedVpoData: {},
            formInfo: {
                type: 1,
                comments: '',
                job: null,
                terms: null,
                title: null,
                job_id: null,
                status: null,
                customer: null,
                job_name: null,
                vendor_id: null,
                vendor_name: null,
                customer_id: null,
                order_number: null,
                customer_name: null,
                delivery_date: null,
                superintendent_user_id: null,
                purchase_date: moment().format('MM/DD/YYYY'),
            },
            formItem: {
                list_items: [],
                total: parseFloat(0.00).toFixed(2)
            },
            isValid: {
                items: null,
                customer_name: null,
                superintendent_user_id: null,
                job_name: null,
                purchase_date: true,
            },
        }
    },
    mounted() {
        this.$modal.show(this.modalName);
    },
    methods: {
        // Validate all necessary fields in child components
        validateNewItem(type) {
            try {
                let formValid = true;
                this.touchForms();

                // Check fieldsWithError for fields with an error
                for (const key in this.isValid) {
                    if ((this.isValid[key] === false))
                        formValid = false;
                    if (this.isValid[key] === null) {
                        formValid = false;
                        this.isValid[key] = false;
                    }
                }

                // Validate number of items
                if (this.formItem.list_items.length < 1)
                    throw new Error(this.$t('label.vpo must have at least one billable item') + '.');
                // Validate Form Fields
                if (!formValid)
                    throw new Error(this.$t('label.please validate required fields before proceeding') + '.');
                // Validate VPO has billable amount
                if (this.formItem.total <= 0)
                    throw new Error(this.$t('label.cannot create a vpo with a total amount of 0') + '.');

                if (type == 'save') {
                    // Save the draft and return to previous page
                    this.saveDraft().then(() => {
                        this.$emit('onClose');
                    });
                } else if (type == 'save_send') {
                    // Prepare the send vpo to customer modal which will call 
                    // back to save the vo and then send the vpo afterwards.
                    this.saveDraft().then(() => {
                        this.$emit('onSaveAndSend', this.savedVpoData.id);
                    });
                }

            } catch (err) {
                bus.$emit('banner-error', { message: err });
            }
        },
        touchForms() {
            this.formDirty = false;
            this.$nextTick(() => this.formDirty = true);
        },

        async saveDraft() {
            // Merge children component forms to be saved.
            this.loader = this.$loading.show();
            let mergedForm = {
                form_info: this.formInfo,
                form_item: this.formItem,
            };

            // console.log("mergedForm", mergedForm);
            // return;

            return axios.post('/vpo', mergedForm).then(rsp => {
                this.loader.hide();
                this.savedVpoData = rsp.data.result;

                bus.$emit('banner-success', {
                    message: this.$t('label.vpo saved successfully!')
                });
            }).catch((err) => console.error(err));
        },
    }
}
</script>