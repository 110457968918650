<template>
    <div>
        <div class="modal-content">
            <div class="modal-header border-bottom">
                <h5 class="modal-title" id="addJobNotesModalTitle">{{ $t('label.add job notes') }}</h5>
                <button type="button" class="close" aria-label="Close" @click="closeModal">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <!-- <textarea id="addJobNoteField" v-model="jobNoteText" name="notes" class="form-control" placeholder="Write a note..." rows="6" required autofocus></textarea> -->
                            <label class="form-control-label text-muted" for="input-name">{{ $t('label.related task') }} ({{ $t('label.optional') }})</label>
                            <select v-model="form.add.relatedTask" class="form-control mb-2">
                                <option value="" disabled>- {{ $t('label.select a task') }} -</option>
                                <option v-for="(task, index) in tasks" :value="task.id" :key="index">{{ task.name }}</option>
                            </select>
                            <at-ta :members="allUsers" name-key="name">
                                <template slot="item" slot-scope="s">
                                    <span v-html="s.item.name" @click="selectedName(s.item)"></span>
                                </template>
                                <textarea v-model="form.add.notes" id="addJobNoteField" name="notes" class="form-control" :placeholder="$t('label.write a note') + '...'" rows="10" required autofocus></textarea>
                            </at-ta>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-top">
                <button type="button" class="btn btn-primary mr-auto" @click="saveJobNote">{{ $t('label.add job note') }}</button>
                <button type="button" class="btn btn-link  ml-auto close2addnote" @click="closeModal">{{ $t('label.close') }}</button>
            </div>
        </div>

    </div>
</template>

<script>
import bus from '@/utils/event-bus';
import At from 'vue-at';
import AtTa from 'vue-at/dist/vue-at-textarea';
;

export default {
    props: ['jobid'],
    emits: ['close-add-note-modal', 'reload'],
    components: {
        At,
        AtTa,
    },
    data() {
        return {
            jobNoteText: null,
            allUsers: [],
            selectedNames: [],
            tasks: [],
            form: {
                add: {
                    notes: null
                },
            },
            loader: null,
        }
    },
    computed: {},
    mounted() {
        this.loadLkupData();
    },
    methods: {
        async loadLkupData() {
            this.loader = this.$loading.show();
            await this.getAllUsers();
            await this.getTasks();
            this.loader.hide();
        },
        getAllUsers() {
            axios.get('/get-all-users').then(response => {
                response.data.result.forEach((data, key) => {
                    this.allUsers.push({
                        name: data.name,
                        id: data.id
                    });
                });
            })
                .catch(err => console.error(err));
        },
        getTasks() {
            axios.get('/tasks/' + this.jobid)
                .then(response => {
                    this.tasks = response.data.result;
                    this.tasks.push({
                        id: 0,
                        name: 'N/A',
                        sequence: 0
                    });
                    this.tasks = _.orderBy(this.tasks, ['sequence'], ['asc']);
                })
                .catch(err => console.error("API ERROR", err));
        },
        saveJobNote() {
            let tempText = $('#addJobNoteField').val();
            this.form.add.notes = tempText;

            this.allUsers.forEach((data, key) => {
                if (tempText.includes(data.name)) {
                    this.selectedName(data);
                }
            });

            var request_fields = {
                'notes': this.form.add.notes,
                'job_id': this.jobid,
                'selected_names': this.selectedNames,
                'task_id': this.form.add.relatedTask
            };

            axios.post('/jobnotes', request_fields)
                .then(response => {
                    this.form.add.notes = '';
                    this.closeModal();
                    bus.$emit('banner-success', { message: 'Note Added Successfully!' });
                })
                .catch(err => console.error('Error Saving Note: ', err));
        },
        closeModal() {
            this.$emit('close-add-note-modal');
        },
        selectedName(data) {
            if (this.findArray(data.id) === null) {
                this.selectedNames.push(data);
            }
        },
        findArray(value) {
            let countLayer = this.selectedNames.length;
            if (countLayer > 0) {
                for (var x = 0; x < countLayer; x++) {
                    if (this.selectedNames[x].id === value) {
                        return this.selectedNames[x];
                    }
                }
            }
            return null;
        },
    }
}
</script>