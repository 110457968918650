console.info(`%c🐰 Starting SubPro...`, 'color:Green');
console.time('🕓 Application Loaded');

import { api } from '@/plugins/API.plugin';
import '@/plugins/bootstrap.plugin.js';
import i18n from '@/plugins/i18n.plugin';
import router from '@/plugins/registerRouter.plugin.js';
import moment from '@/utils/date-time';
import * as $ from 'jquery';
import * as _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import App from './App.vue';
import { store } from './store/store';



// Global Functions -----------------------------------------
window.Vue = Vue;
window.axios = api;
window.$ = $;
window.jQuery = $;
window._ = _;
window.moment = moment;

// Event Bus
import { busPlugin } from '@/utils/event-bus';
Vue.use(busPlugin)

// Helpers --------------------------------------------------
// window.$t = (string) => _.get(i18n, string);
// Vue.prototype.$t = string => _.get(window.i18n, string);
Vue.prototype.$moment = moment; //makes moment availble in html

// Filters --------------------------------------------------
import './filters.js';

// Mixins
import './mixins/default';
import './mixins/global';

// Plugins --------------------------------------------------
// import SalesIQ from '@/SalesIQ.js';
import 'datatables.net-bs4';
import './plugins';
import API from './plugins/API.plugin';
import { registerVeeValidate } from './plugins/VeeValidate.plugin';
import { registerArgon } from './plugins/argon.plugin';
import { registerEcho } from './plugins/echo.plugin';
import { registerPrefecrScrollbar } from './plugins/perfecrScrollbar.plugin';
import { registerModules } from './plugins/registerModule.plugin';

Vue.use(API);
// Vue.use(SalesIQ, {
//     widgetcode: '50f35e07d75640c3e80ca31bc1228c78023f71286aba1917ffad824876276567',
//     domain: 'https://salesiq.zoho.com/widget'
// });

// Global components ----------------------------------------
import './global-components.js';

// Register Service-worker ----------------------------------
import './plugins/service-worker';

// Prototypes ------------------------------------------------
import '@/prototypes/BackendStoragePrototype';
import '@/prototypes/DatePrototype';
import '@/prototypes/EmailValidationPrototype';
import '@/prototypes/MonthsYearsPrototype';
import '@/prototypes/PieceRateAllocationsPrototype';

/**
 * Initialize the Application
*/
Vue.config.productionTip = false;
// Vue.config.devtools = false

let app = null;

registerModules().then((data) => {
    registerPrefecrScrollbar();
    registerVeeValidate();
    registerEcho();
    registerArgon();

    app = new Vue({
        i18n,
        store,
        router,
        render: h => h(App),
        data() {
            return {
                companyData: JSON.parse(localStorage.getItem('company_settings')),
            };
        },
        computed: {
            ...mapGetters(['messages', 'company', 'coordinates']),
            ...mapGetters({
                user: 'Auth/currentUser'
            }),
        },
        async created() {
            // Add current gps location to vuex
            await this.$store.dispatch('setGeolocation');

            // Set the language
            let lang = localStorage.getItem('lang');
            if (lang == null) lang = 'en';

            // $('.lang-current').attr('src', '/images/flags/' + lang + '.png');

            // Make sure company data is set before loading data
            await this.getUser();

            if (this.user.role_id > 1) {
                await this.getCompanySettings();
            }

            if (this.companyData) {
                await this.$store.dispatch('chat/getMyChatRooms').then(res => {
                    let companyChannelChatRooms = res.data.companyChannelChatRooms;
                    let directMessageChatRooms = res.data.directMessageChatRooms;
                    let jobChatRooms = res.data.jobChatRooms;

                    let chatRooms = [...companyChannelChatRooms, ...directMessageChatRooms, ...jobChatRooms];

                    if (chatRooms.length > 0) {
                        this.$store.dispatch('chat/getUnreadChats');
                        chatRooms.forEach(item => {
                            this.$store.dispatch('chat/startListeningToChatRoomMessages', { id: item.id, echo: this.$echo })
                        })
                        let activeChatRooms = JSON.parse(localStorage.getItem('activeChatRooms'));
                        console.log('activeChatRooms', activeChatRooms)
                        if (activeChatRooms) {
                            this.$store.commit('chat/setActiveChatRooms', activeChatRooms)
                        }
                    }
                });
                this.$store.dispatch('chat/startListeningToNewChatRoom', { echo: this.$echo });
                this.$store.dispatch('chat/startListeningToUserStatus', { echo: this.$echo });
            }
        },
        mounted() {
            $(document).on('hidden.bs.modal', '.modal', () => {
                if (0 < $('.modal:visible').length) {
                    $('body').addClass('modal-open');
                }
            });
        },
        methods: {
            async getUser() {
                if (!this.user) {
                    await this.$store.dispatch('Auth/getCurrentUser')
                        .then(res => {
                            moment.tz.setDefault(this.user.timezone);
                        });
                }

                if (this.user.role_id == 2 && this.user.getting_started) {
                    $('#gettingStartedModal').modal('show');
                }
            },
            getCompanySettings() {
                return this.$store.dispatch('getCompanySettings');
            },
        }
    }).$mount('#app');
    console.timeEnd('🕓 Application Loaded');
});

export default app;


