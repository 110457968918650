<template>
    <div>
        <div class="card-header">
            <div class="py-2 d-flex">
                <h3>
                    {{ (isEdit) ? $t('label.edit') : $t('label.add') }}
                    {{ $t('label.contact') }}
                </h3>
                <button type="button" class="close ml-auto" @click="cancelAdd()">
                    <i class="ri-close-line"></i>
                </button>
            </div>
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.contact first name') }}</label>
                                <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                                <input type="text" name="first_name" class="form-control" v-model="$v.newContact.first_name.$model" :class="{ 'is-invalid': $v.newContact.first_name.$error }" :placeholder="$t('label.first name')" autocomplete="input-field" autofocus data-lpignore="true">
                                <div v-if="$v.newContact.first_name.$error && !$v.newContact.first_name.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.contact last name') }}</label>
                                <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                                <input type="text" name="last_name" class="form-control" v-model="newContact.last_name" :placeholder="$t('label.last name')" autocomplete="input-field" data-lpignore="true">
                                <div v-if="$v.newContact.last_name.$error && !$v.newContact.last_name.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
                            </div>
                        </div>
                        <!-- Email -->
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.email') }}</label>
                                <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                                <input type="text" name="email" class="form-control" v-model="$v.newContact.email.$model" :class="{ 'is-invalid': $v.newContact.email.$error }" :placeholder="$t('label.email')" autocomplete="input-field" data-lpignore="true">
								<div v-if="$v.newContact.email.$error && !$v.newContact.email.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>

                            </div>
                        </div>
                        <!-- Phone -->
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="input-phone">{{ $t('label.phone') }}</label>
                                <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                                <input type="text" id="input-phone" v-model="$v.newContact.phone.$model" name="phone" class="form-control phone-validate" :placeholder="$t('label.phone')" v-mask="'(###) ###-####'" autocomplete="input-field" data-lpignore="true">
                                <div v-if="$v.newContact.phone.$error && !$v.newContact.phone.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <!-- Address 1 -->
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.address') }} 1</label>
                        <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                        <input type="text" name="address_1" v-model="$v.newContact.address_1.$model" class="form-control" :class="{ 'is-invalid': $v.newContact.address_1.$error }" :placeholder="$t('label.address') + ' 1'" required autocomplete="nope">
                        <div v-if="$v.newContact.address_1.$error && !$v.newContact.address_1.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
                    </div>
                    <!-- Address 2 -->
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.address') }} 2</label>
                        <input type="text" name="address_2" v-model="newContact.address_2" class="form-control" :placeholder="$t('label.address') + ' 2'">
                    </div>

                    <!-- City -->
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.city') }}</label>
                        <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                        <input type="text" name="city" v-model="$v.newContact.city.$model" class="form-control" :class="{ 'is-invalid': $v.newContact.city.$error }" :placeholder="$t('label.city')" required autocomplete="nope">
                        <div v-if="$v.newContact.city.$error && !$v.newContact.city.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
                    </div>
                    <div class="row">
                        <!-- Zipcode -->
                        <div class="col-sm-12 col-md-6">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.postal code') }}</label>
                                <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                                <input @keyup="getStateFromPostalCode" type="text" name="postal_code" v-model="$v.newContact.postal_code.$model" class="form-control" :class="{ 'is-invalid': $v.newContact.postal_code.$error }" :placeholder="$t('label.postal code')" required autocomplete="nope">
                                <div v-if="$v.newContact.postal_code.$error && !$v.newContact.postal_code.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
                            </div>
                        </div>
                        <!-- State -->
                        <div class="col-sm-12 col-md-6">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.state') }}</label>
                                <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                                <select class="form-control" v-model="$v.newContact.state.$model" :class="{ 'is-invalid': $v.newContact.state.$error }" required>
                                    <option :value="null" selected>- {{ $t('label.select a state') }} -</option>
                                    <option v-for="(data, index) in allstates" :value="data" :key="index">{{ index }}</option>
                                </select>
                                <div v-if="$v.newContact.state.$error && !$v.newContact.state.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
                            </div>
                        </div>
                    </div>
                    <!-- Country -->
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input-country">{{ $t('label.country') }}</label>
                        <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                        <select class="form-control" v-model="$v.newContact.country.$model" :class="{ 'is-invalid': $v.newContact.country.$error }" required>
                            <option value="" selected>- {{ $t('label.select a country') }} -</option>
                            <option v-for="(data, index) in allcountries" :value="index" :key="index">{{ data }}</option>
                        </select>
                        <div v-if="$v.newContact.country.$error && !$v.newContact.country.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Form Buttons -->
        <div class="card-footer">
            <div class="d-flex">
                <button type="button" class="btn btn-primary" @click="saveContact()">
                    {{ (isEdit) ? $t('label.update') : $t('label.create') }}
                    {{ $t('label.contact') }}
                </button>
                <div class="ml-auto">
                    <button type="button" class="btn btn-link" @click="cancelAdd()">
                        {{ $t('label.cancel') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';
import { required, minLength, minValue, requiredIf } from 'vuelidate/lib/validators';
import Loading from 'vue-loading-overlay';


export default {
    props: ['vendorId', 'selectedContact'],
    emits: ['cancelFnc', 'returnContact'],
    data() {
        return {
            newContact: {
                first_name: null,
                last_name: null,
                email: null,
                phone: null,
                address_1: null,
                address_2: null,
                city: null,
                postal_code: null,
                state: null,
                country: '',
                id: null,
            },
            contactStatuses: [],
            formErrors: {},
            isEdit: false,
            allcountries: [],
            allstates: [],
            isLoading: false,

        }
    },
    validations: {
        newContact: {
            first_name: { required },
            last_name: { required },
            phone: { required },
            email: {},
            country: { required },
            postal_code: { required },
            address_1: { required },
            address_2: {},
            city: { required },
            state: { required },
        }
    },
    created() {
        this.resetForm();
        if (this.selectedContact) {
            this.isEdit = true;
        }
    },
    mounted() {
        this.loadSupportingData();
    },
    methods: {
        loadSupportingData() {
            let promises = [];
            this.loader = this.$loading.show();
            promises.push(this.getCountries());
            promises.push(this.getStates());

            this.loader.hide();
            if (this.selectedContact) {
                this.editContact(this.selectedContact);
            }
        },
        getCountries() {
            return axios.get('/get-countries').then(response => {
                this.allcountries = response.data.result;
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        getStates() {
            return axios.get('/get-states').then(response => {
                this.allstates = response.data.result;
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        getStateFromPostalCode() {
            if (this.newContact.postal_code && this.newContact.postal_code.length == 5) {
                axios.get('/get-state/' + this.newContact.postal_code).then(response => {
                    if (response.data.results) {
                        this.newContact.state = response.data.results.abbreviation;
                    }
                })
                    .catch(err => {
                        //console.log('list carriers error:', error)
                    });
            }
        },
        // return cancel back to parent
        cancelAdd() {
            this.resetForm();
            this.$emit('cancelFnc');
        },
        editContact(item) {
            this.newContact.first_name = item.first_name;
            this.newContact.last_name = item.last_name;
            this.newContact.phone = item.phone;
            this.newContact.email = item.email;
            this.newContact.address_1 = item.address_1;
            this.newContact.address_2 = item.address_2;
            this.newContact.city = item.city;
            this.newContact.postal_code = item.postal_code;
            this.newContact.state = item.state;
            this.newContact.country = item.country;
            this.newContact.id = item.id;
        },
        //Add new contact and then send the new contact back to parent list.
        saveContact() {
            this.$v.$touch();

            if (!this.$v.$invalid) {

                this.loader = this.$loading.show();

                let formData = new FormData();
                formData.append('first_name', this.newContact.first_name);
                formData.append('last_name', this.newContact.last_name);
                formData.append('phone', this.newContact.phone);
                formData.append('email', this.newContact.email);
                formData.append('address_1', this.newContact.address_1);
                formData.append('address_2', this.newContact.address_2 ? this.newContact.address_2 : '');
                formData.append('city', this.newContact.city);
                formData.append('postal_code', this.newContact.postal_code);
                formData.append('state', this.newContact.state);
                formData.append('country', this.newContact.country);
                formData.append('vendor_id', this.newContact.vendor_id);

                let endpoint = 'add-vendor-contact',
                    successMsg = 'Added';
                if (this.isEdit) {
                    endpoint = 'update-vendor-contact';
                    successMsg = 'Updated';
                    formData.append('id', this.newContact.id);
                }
                axios.post(`/${endpoint}`, formData)
                    .then(response => {
                        response.data.isEdit = this.isEdit;
                        bus.$emit('banner-success', { message: `Contact ${successMsg} Successfully!` });
                        this.$emit('returnContact', response.data);
                        this.loader.hide();
                        this.resetForm();
                    })
                    .catch(err => {
                        // catch laravel validation errors
                        if (err.response.status == 422) {
                            this.formErrors = err.response.data.errors;
                        }
                        else {
                            console.error('Unknown Error Ocurred: ', err);
                        }
                    });
            }
        },
        resetForm() {
            this.newContact = {
                first_name: null,
                last_name: null,
                email: null,
                phone: null,
                address_1: null,
                address_2: null,
                city: null,
                postal_code: null,
                state: null,
                country: "",
                vendor_id: this.vendorId,
            };
        },
    },
}
</script>
