<template>
    <div class="form-group">
        <label class="form-control-label text-muted" :for="customfield.input_name" v-text="customfield.name"></label>
        
        <input
            v-if="customfield.type === 'text'"
            v-model="inputValue"
            type="text"
            :name="customfield.input_name"
            class="form-control"
            :placeholder="customfield.name"
            @change="onInput()"/>

        <textarea v-else-if="customfield.type === 'textarea'"
            v-model="inputValue"
            rows="4"
            :name="customfield.input_name"
            class="form-control"
            placeholder="Task Description"
            @change="onInput()">
        </textarea>

        <template v-else-if="customfield.type === 'checkbox' && Array.isArray(inputValue)">
            <br />
            <div v-for="(option, index) in customfield.options" class="form-check form-check-inline" :key="index">
                <input :id="`${customfield.input_name}-${index}`"
                    v-model="inputValue[index]"
                    class="form-check-input"
                    type="checkbox"
                    :name="`${customfield.input_name}[]`"
                    :checked="customfield.value ? customfield.value.includes(option.name) : ''"
                    @change="onCheckboxInput()"/>
                <label class="form-check-label" :for="`${customfield.input_name}-${index}`" v-text="option.name"></label>
            </div>
        </template>

        <template v-else-if="customfield.type === 'radio'">
            <br />
            <div v-for="(option, index) in customfield.options" class="form-check form-check-inline" :key="index">
                <input :id="`${customfield.input_name}-${index}`"
                    class="form-check-input"
                    type="radio"
                    v-model="inputValue"
                    :value="option.name"
                    :name="customfield.input_name"
                    @change="onInput()"/>
                <label class="form-check-label" :for="`${customfield.input_name}-${index}`" v-text="option.name"></label>
            </div>
        </template>

        <select v-else-if="customfield.type === 'dropdown'"
            v-model="inputValue"
            class="form-control"
            :name="customfield.input_name"
            @change="onInput()">
            <option value="">- Select a {{ customfield.name }} -</option>
            <option v-for="(option, index) in customfield.options" :value="option.name" :key="index">
                {{ option.name }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    name: "CustomField",
    props: ['customfield', 'parentBus'],
    emits: ['input'],
    data() {
        return {
            inputValue: null
        }
    },
    created() {
        // Parent emit to clear values
        if (this.parentBus) {
            this.parentBus.$on('clearForm', () => {
                console.log("launching child emit");
                if (this.customfield.type === "checkbox")
                    this.initializeCheckbox();
                else
                    this.inputValue = null;
            });
        }

        if (this.customfield.value) {
            this.$emit("input", this.customfield.value);
        } else if (this.customfield.type === "checkbox") {
            this.$emit("input", []);
        }
    },
    mounted() {
        if (this.customfield.type === "checkbox")
            this.initializeCheckbox();
    },
    methods: {
        initializeCheckbox() {
            this.customfield.options.forEach((option) => {
                this.inputValue = [];
                this.inputValue.push(false);
            });
        },

        // For Checkbox inputs
        onCheckboxInput() {
            let values = [];
            this.customfield.options.forEach((option, idx) => {
                if (this.inputValue[idx])
                    values.push(option.value);
            });

            this.$emit("input", values);
        },
        // For all other inputs
        onInput() {
            this.$emit("input", this.inputValue);
        },
    },
};
</script>

<style lang="scss" scoped></style>
