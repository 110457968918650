<template>
    <div class="purchase-order" v-if="purchaseOrder">
        <div class="header bg-primary pb-6 mobile-p-0">
            <div class="container-fluid">
                <div class="header-body">
                    <div class="row align-items-center py-4">
                        <div class="col-lg-6 col-7">
                            <h6 class="h2 text-white d-inline-block mb-0">{{ $t("label.purchase order") }}</h6>
                            <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4" style="background: #fff; padding: .625rem 1.25rem; border-radius: .25rem;">
                                <ol class="breadcrumb breadcrumb-links">
                                    <li class="breadcrumb-item">
                                        <a href="/dashboard" class="d-flex align-items-center">
                                            <i class="ri-dashboard-line"></i> {{ $t('label.dashboard') }}
                                        </a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a class="pointer" @click="$router.push({ name: 'purchaseOrders' })">{{ $t('label.purchase orders') }}</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <span>SO #{{ poNum }}</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div class="col-lg-6 col-5 text-right">
                            <button type="button" class="btn btn-neutral" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="text-default">{{ $t('label.menu') | uppercase }}</span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                <!-- View PDF -->
                                <a class="dropdown-item text-default pointer" @click="showModalPurchaseOrderView()">
                                    <i class="ri-eye-line"></i> {{ $t('label.view pdf') }}
                                </a>
                                <!-- Send -->
                                <a class="dropdown-item text-default pointer" v-if="['Draft', 'Sent'].indexOf(poStatusName) !== -1" @click="showModalPurchaseOrderSend()">
                                    <i class="ri-mail-send-line"></i> {{ $t('label.send') }}
                                </a>
                                <!-- Download -->
                                <a @click="$downloadStream(`/get-po-pdf-download/${purchaseOrder.id}`, pdfName)" class="dropdown-item text-default pointer">
                                    <i class="ri-download-line"></i> {{ $t('label.download') }}
                                </a>
                                <!-- Print -->
                                <a @click="$openStreamBlank(`/get-po-pdf-file/${purchaseOrder.id}`), pdfName" class="dropdown-item text-default pointer">
                                    <i class="ri-printer-line"></i> {{ $t('label.print') }}
                                </a>
                                <!-- edit -->
                                <router-link v-if="poStatusName == 'Draft'" class="dropdown-item text-default pointer" :to="{ name: 'purchaseOrderEdit', params: { id: id } }">
                                    <i class="ri-pencil-line"></i> {{ $t('label.edit') }}
                                </router-link>
                                <!-- Delete Draft -->
                                <div v-if="purchaseOrder.purchase_order_status.name == 'Draft'">
                                    <div class="dropdown-divider mx-3"></div>
                                    <a class="dropdown-item text-danger pointer d-flex align-items-center" @click="setSelectedPurchaseOrder(purchaseOrder); toggleDeleteModal(true)">
                                        <i class="ri-close-circle-line"></i>{{ $t('label.delete purchase order') }}
                                    </a>
                                </div>
                                <!-- Cancel Sent PO -->
                                <div v-if="purchaseOrder.purchase_order_status.name == 'Sent'">
                                    <div class="dropdown-divider mx-3"></div>
                                    <a class="dropdown-item text-danger pointer d-flex align-items-center" @click="toggleSendCancelModal()">
                                        <i class="ri-close-circle-line"></i>{{ $t('label.cancel purchase order') }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid mt--6 mobile-mt-140p">
            <!-- Purchase Order View Info -->
            <purchase-order-view-info :purchase-order="purchaseOrder" :selected-ven-contact="selectedVenContact"></purchase-order-view-info>

            <!-- Purchase Order View Comments -->
            <purchase-order-view-address :purchase-order-address="purchaseOrder.purchase_order_address" :selected-ven-contact="selectedVenContact"></purchase-order-view-address>

            <!-- Purchase Order View Items -->
            <purchase-order-view-items :purchase-order-info="purchaseOrder" :purchase-order-id="id" :purchase-order-status="poStatusName"></purchase-order-view-items>

            <!-- Purchase Order View Comments -->
            <purchase-order-view-comments :purchase-order="purchaseOrder"></purchase-order-view-comments>

            <!-- Purchase Order View Notes -->
            <purchase-order-view-notes v-if="purchaseOrder.purchase_order_status" :purchase-order-id="id" :purchase-order-status="poStatusName"></purchase-order-view-notes>

            <!-- Purchase Order View Notes -->
            <purchase-order-view-files v-if="purchaseOrder.purchase_order_status" :purchase-order-id="id" :purchase-order-status="poStatusName"></purchase-order-view-files>

            <!-- Purchase Order View Activity Logs -->
            <purchase-order-view-activity-logs :purchase-order-id="id"></purchase-order-view-activity-logs>

        </div>

        <!-- Purchase Order View Modal -->
        <modal class="py-4" name="modalPurchaseOrderView" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
            <ModalPurchaseOrderView :purchase-order="purchaseOrder" :selected-vendor-contact="selectedVendorContact" @cancelFnc="$modal.hide('modalPurchaseOrderView')" @reload="loadLkupData"></ModalPurchaseOrderView>
        </modal>

        <!-- Purchase Order Send Modal -->
        <modal class="py-4" name="modalSendPurchaseOrder" height="auto" width="100%" :max-width="900" :scrollable="true" :reset="true" :adaptive="true">
            <ModalPurchaseOrderSend :purchase-order="purchaseOrder" :send-type="poSendType" :subject-text="subjectText" @cancelFnc="hideModalPurchaseOrderSend()"></ModalPurchaseOrderSend>
        </modal>

        <!-- Purchase Order Cancel -->
        <modal-delete-component v-if="showDltPOModal" :newId="'PurchaseOrder_view'" :title="isPoDraft ? $t('label.ARE YOU SURE YOU WANT TO DELETE THIS PURCHASE ORDER') : $t('label.ARE YOU SURE YOU WANT TO CANCEL THIS PURCHASE ORDER')" :subtitle="cancelPoSubtitle" :btnText="isPoDraft ? $t('label.Yes, Delete Purchase Order') : $t('label.Yes, Cancel Purchase Order')" @onClose="toggleDeleteModal()" @customDeleteMethod="isPoDraft ? deletePo() : sendCancelEmail()">
        </modal-delete-component>
    </div>
</template>

<script>
import ModalDeleteComponent from '@/components/ModalDelete2.vue';
import ModalPurchaseOrderSend from '@/components/ModalPurchaseOrderSend.vue';
import ModalPurchaseOrderView from '@/components/ModalPurchaseOrderView.vue';
import bus from '@/utils/event-bus';
import PurchaseOrderViewActivityLogs from './sections/view-purchase-order/PurchaseOrderViewActivityLogs.vue';
import PurchaseOrderViewAddress from './sections/view-purchase-order/PurchaseOrderViewAddress.vue';
import PurchaseOrderViewComments from './sections/view-purchase-order/PurchaseOrderViewComments.vue';
import PurchaseOrderViewFiles from './sections/view-purchase-order/PurchaseOrderViewFiles.vue';
import PurchaseOrderViewInfo from './sections/view-purchase-order/PurchaseOrderViewInfo.vue';
import PurchaseOrderViewItems from './sections/view-purchase-order/PurchaseOrderViewItems.vue';
import PurchaseOrderViewNotes from './sections/view-purchase-order/PurchaseOrderViewNotes.vue';

export default {
    props: ['id', 'title'],
    emits: [''],
    components: {
        PurchaseOrderViewInfo,
        PurchaseOrderViewAddress,
        PurchaseOrderViewItems,
        PurchaseOrderViewComments,
        PurchaseOrderViewNotes,
        PurchaseOrderViewFiles,
        ModalPurchaseOrderView,
        ModalPurchaseOrderSend,
        PurchaseOrderViewActivityLogs,
        ModalDeleteComponent
    },
    data() {
        return {
            purchaseOrderData: [],
            selectedVenContact: [],
            selectedVendorContact: [],
            selectedPurchaseOrder: {},
            poSendType: '',
            subjectText: '',
            poNum: null,
            poStatusName: null,
            purchaseOrder: null,
            showDltPOModal: false,
        }
    },
    computed: {
        pdfName() {
            if(this.purchaseOrder) {
                let name = this.purchaseOrder.purchase_order_status == 'Cancelled' ? 'CANCELLED_' : '';
                return name + "SO-" + this.purchaseOrder.purchase_order_number.purchase_order_number + "_" +  moment().format('YYYY_MM_DD') + ".pdf";
            } else 
                return "";
        },
        isPoDraft() {
            return this.selectedPurchaseOrder.purchase_order_status.name == "Draft" ? true : false;
        },
        cancelPoSubtitle() {
            if (!this.isPoDraft)
                return $t('label.This cannot be undone You will be able to send a message to the supplier on the next step');
            else
                return ' ';
        }
    },
    mounted() {
        this.loadLkupData()

        bus.$on('reloadPurchaseOrder', () => {
            this.loadLkupData()
        });
    },
    methods: {
        loadLkupData() {
            let promises = [];
            this.loader = this.$loading.show();
            promises.push(this.getPurchaseOrder(this.id));
            // Hide the loader overlay after all lookup data is loaded
            Promise.all(promises).then(() => {
                this.purchaseOrderData = this.purchaseOrder;
                this.loader.hide();
            });
        },
        getPurchaseOrder(id) {
            axios.get('/purchase-order/' + id)
                .then(res => {
                    this.purchaseOrder = res.data.result;
                    this.poNum = this.purchaseOrder.purchase_order_number.purchase_order_number;
                    this.poStatusName = this.purchaseOrder.purchase_order_status.name;

                    for (var i = 0; i < this.purchaseOrder.vendor.vendor_contacts.length; i++) {
                        if (this.purchaseOrder.vendor.vendor_contacts[i].id == this.purchaseOrder.vendor_contact_id)
                            this.selectedVenContact = this.purchaseOrder.vendor.vendor_contacts[i];
                    }
                }).catch(err => console.log(err));
        },
        showModalPurchaseOrderView(item = null) {
            this.purchaseOrderData = this.purchaseOrder;
            for (var i = 0; i < this.purchaseOrderData.vendor.vendor_contacts.length; i++) {
                if (this.purchaseOrderData.vendor.vendor_contacts[i].id == this.purchaseOrderData.vendor_contact_id) {
                    this.selectedVendorContact = this.purchaseOrderData.vendor.vendor_contacts[i];
                }
            }
            this.$modal.show('modalPurchaseOrderView');
        },
        hideModalPurchaseOrderView() {
            this.$modal.hide('modalPurchaseOrderView');
        },
        showModalPurchaseOrderSend(item = null) {
            this.subjectText = '';
            this.poSendType = 'send'
            this.$modal.show('modalSendPurchaseOrder');
        },
        hideModalPurchaseOrderSend() {
            this.$modal.hide('modalSendPurchaseOrder');
        },
        setSelectedPurchaseOrder(item) {
            this.selectedPurchaseOrder = item;
        },
        toggleDeleteModal(status) {
            this.showDltPOModal = status;
        },
        toggleSendCancelModal() {
            this.poSendType = 'cancel'
            this.subjectText = 'CANCELLED';
            this.$modal.show('modalSendPurchaseOrder');
        },
        sendEmail() {
            this.subjectText = 'CANCELLED';
            this.$modal.show('modalSendPurchaseOrder');
        },
        deletePo() {
            let reqData = {
                id: this.selectedPurchaseOrder.id,
                status: this.selectedPurchaseOrder.purchase_order_status.name,
            };

            axios.post('/delete-purchase-order', reqData).then(res => {
                bus.$emit('banner-success', {
                    message: $t('label.Purchase Order Draft deleted successfully') + '!'
                });

                this.$router.go(-1);
            });
        },
    },
}
</script>
