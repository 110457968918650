<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50" id="timesheetSettingsContainer">
            <!-- Page Header -->
            <div class="d-flex" style="z-index: 100">
                <!-- Breadcrumbs -->
                <breadcrumbs :title="$t('label.data import')">
                    <!-- Breakcrumb slots -->
                    <li class="breadcrumb-item">
                        {{ $t('label.administrative') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.tools') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.data import') }}
                    </li>
                </breadcrumbs>
            </div>

            <!-- Page Body -->
            <div class="card">
                <h3 class="card-header">{{ importType | uppercaseFirst }} {{ $t('label.data import') }}</h3>

                <!-- Import type selector -->
                <div class="card-body" v-if="step == 1">
                    <h3>{{ $t('label.select import type') }}</h3>
                    <p>{{ $t('label.please select the type of data import') }}</p>

                    <!-- TODO: Waiting for more info on adding users with stripe -->
                    <!-- <div class="custom-control custom-radio">
                        <input name="importType" 
                            class="custom-control-input" 
                            id="user"
                            value="user" 
                            v-model="importType"
                            type="radio">
                        <label class="custom-control-label" for="user">User/Employee Import</label>
                    </div>
                    <br> -->
                    <div class="custom-control custom-radio">
                        <input name="importType" class="custom-control-input" id="jobs" value="jobs" v-model="importType" type="radio">
                        <label class="custom-control-label" for="jobs">{{ $t('label.jobs import') }}</label>
                    </div>
                    <br>
                    <div class="custom-control custom-radio">
                        <input name="importType" id="customer" class="custom-control-input" value="customer" v-model="importType" type="radio">
                        <label class="custom-control-label" for="customer">{{ $t('label.customers import') }}</label>
                    </div>
                    <br>
                    <!-- File Upload -->
                    <div v-if="importType">
                        <h3>Upload a File for {{ importType }}</h3>
                        <div class="form-group">
                            <div>{{ $t('label.use the sample file below or upload your own file then map the data to the corresponding fields') }}</div>
                            <div class="text-info pointer text-sm" @click="loadSampleFile(importType)"><i class="ri-file-download-line"></i> Load Sample File</div>
                        </div>
                        <div class="mt-2">
                            <input type="checkbox" id="fileHasHeader" v-model="fileHasHeader">
                            <label for="fileHasHeader">{{ $t('label.file contains header row') }}</label>
                            <input type="file" class="form-control" ref="file" @change="readFile()" accept=".xlsx, .csv" />
                        </div>
                    </div>
                </div>

                <!-- File Mapping -->
                <div v-if="step == 2">
                    <div v-if="importType == 'customer'">
                        <customer-import :fileData="fileFields" :importData="importData" @fncSuccess="fncSuccess" @fncReturn="fncReturn"></customer-import>
                    </div>
                    <div v-if="importType == 'user'">
                        <!-- <user-import :fileData="fileFields" 
                            :importData="importData" 
                            @fncSuccess="fncSuccess" 
                            @fncReturn="fncReturn"></user-import> -->
                    </div>
                    <div v-if="importType == 'jobs'" class="mb-2">
                        <job-import :fileData="fileFields" :importData="importData" @fncSuccess="fncSuccess" @fncReturn="fncReturn"></job-import>
                    </div>
                </div>


                <!-- Import Success -->
                <div class="card-body" v-if="step == 3">
                    <h3>{{ recordsImported }} {{ $t('label.records imported successfully') }}</h3>
                    <br>
                    <div>
                        <button type="button" class="btn btn-outline-primary" @click="step = 1">New Import</button>
                        <button type="button" v-if="importType == 'customer'" class="btn btn-outline-primary" @click="$router.push({ name: 'contacts' })">{{ $t('label.go to customers') }}</button>
                        <button type="button" v-if="importType == 'user'" class="btn btn-outline-primary" @click="$router.push({ name: 'users' })">{{ $t('label.go to users') }}</button>
                        <button type="button" v-if="importType == 'jobs'" class="btn btn-outline-primary" @click="$router.push({ name: 'jobs' })">{{ $t('label.go to jobs') }}</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import CustomerImport from '@/components/Admin/Settings/DataImport/CustomerImport';
import JobImport from '@/components/Admin/Settings/DataImport/JobImport';
import bus from '@/utils/event-bus';
import csvParse from 'papaparse';
import readXlsxFile from 'read-excel-file';

export default {
    props: [],
    components: { JobImport, CustomerImport },
    emits: [],
    data() {
        return {
            loader: null,
            importType: null,
            importData: null,
            fileFields: null,
            fileHasHeader: 1,
            recordCount: null,
            step: 1,
            jobStatuses: null,
            recordsImported: null
        }
    },
    mounted() {
        this.loader = this.$loading.show();
        setTimeout(() => {
            this.loader.hide();
        }, 250);
    },
    methods: {
        // Loads sample file content from server and passes it to the CSV parser
        loadSampleFile(fileType) {
            this.loader = this.$loading.show()
            axios
                .get('/loadSampleImportFile/' + fileType)
                .then(response => {
                    this.loader.hide()
                    var self = this
                    csvParse.parse(response.data, { 
                        skipEmptyLines: true,
                        complete: function (results) {
                            try {
                                self.importData = JSON.parse(JSON.stringify(results.data))
                                // Remove the first row from data if header is included
                                if (self.fileHasHeader) {
                                    self.importData.splice(0, 1)
                                }
                                self.recordCount = self.importData.length
                                if (!results.data[0])
                                    throw "Uploaded file is empty"
                                self.createContentObj(results.data[0], results.data[1])
                            }
                            catch (e) {
                                console.error(e)
                                bus.$emit('banner-error', { message: e })
                            }
                        }
                    })
                })
                .catch(err => {
                    console.log('ERROR: ', err);
                    bus.$emit('banner-error', { message: err, });
                });
        },
        readFile() {
            try {
                // Parse CSV file
                if (this.$refs.file.files[0].type == 'text/csv') {
                    var self = this
                    csvParse.parse(this.$refs.file.files[0], {
                        skipEmptyLines: true,
                        complete: function (results) {
                            try {
                                self.importData = JSON.parse(JSON.stringify(results.data))
                                // Remove the first row from data if header is included
                                if (self.fileHasHeader) {
                                    self.importData.splice(0, 1)
                                }
                                self.recordCount = self.importData.length
                                if (!results.data[0])
                                    throw "Uploaded file is empty"
                                self.createContentObj(results.data[0], results.data[1])
                            }
                            catch (e) {
                                console.error(e)
                                bus.$emit('banner-error', { message: e })
                            }
                        }
                    })
                }

                // Parse XLSX File
                else {
                    readXlsxFile(this.$refs.file.files[0]).then((rows) => {
                        this.importData = JSON.parse(JSON.stringify(rows))
                        // Remove the first row from data if header is included
                        if (this.fileHasHeader) {
                            this.importData.splice(0, 1)
                        }
                        this.recordCount = this.importData.length
                        this.createContentObj(rows[0], rows[1])
                    }).catch(err => {
                        console.log('ERROR: ', err)
                        bus.$emit('banner-error', { message: 'Unable to read the excel file uploaded. Make sure it contains only one sheet' })
                    })
                }
            } catch (err) {
                bus.$emit('banner-error', { message: 'An ERROR has be found in the file uploaded.' })
            }
        },
        createContentObj(headerRow, dataRow) {
            let fileContent = []
            // File does not have a header
            if (!this.fileHasHeader) {
                // loop count data fields
                for (let i = 0; i < dataRow.length; i++) {
                    fileContent.push({ name: null, value: dataRow[i], attribute: 1 })
                }
            }
            else {
                // loop header row            
                headerRow.forEach(function (header, idx) {
                    fileContent.push({ name: header, value: dataRow[idx], attribute: 1 })
                })
            }

            this.fileFields = fileContent
            this.step++
        },
        getUploadedFiles() {

        },
        fncReturn() {
            this.step = 1
        },
        fncSuccess(recordsImported) {
            this.recordsImported = recordsImported
            this.step = 3
        },
    }
}
</script>

<style></style>