<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50" id="invoiceItemsContainer">
            <!-- Page Header -->
            <div class="d-flex" style="z-index: 100">
                <!-- Breadcrumbs -->
                <breadcrumbs :title="$t('label.invoice items')">
                    <!-- Breakcrumb slots -->
                    <li class="breadcrumb-item">
                        {{ $t('label.admin') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.content management') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.invoice items') }}
                    </li>
                </breadcrumbs>
            </div>

            <!-- Page Body -->
            <div class="card">
                <!-- Items List Header -->
                <div class="card-header">
                    <div class="d-flex">
                        <h3 style="margin-bottom: -0.2rem">{{ $t('label.manage invoice items') }}</h3>
                         <button  class="btn btn-default btn-sm qbsync-btn" @click="syncItems()">
                        Sync Quickbooks Online
                        </button>
                        <button type="button" class="btn btn-sm btn-default ml-auto" data-toggle="modal" data-target="#invoiceItemModal" @click="addInvoiceItem" :disabled="allInvoiceItems > 0">
                            {{ $t('label.add invoice item') }}
                        </button>
                    </div>
                </div>
                <!-- Items List -->
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-sm table-flush border-bottom" id="datatable-invoice-items">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">{{ $t('label.item name/description') }}</th>
                                    <th scope="col">Quickbooks Item ID</th>
                                    <th scope="col" width="10%">{{ $t('label.amount') }}</th>
                                    <th scope="col" width="10%">{{ $t('label.creation date') }}</th>
                                    <th scope="col" width="10%">{{ $t('label.status') }}</th>
                                    <th scope="col" width="5%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in paginateInvoiceItems" :key="index">
                                    <td>{{ data.name }}</td>
                                    <td>{{ data.qb_id }}</td>
                                    <td>{{ data.amount }}</td>
                                    <td>{{ data.created_at | formatDate }}</td>
                                    <td>{{ data.status ? 'Active' : 'Inactive' }}</td>
                                    <td class="text-right">
                                        <div class="dropdown">
                                            <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fas fa-ellipsis-v"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                <button type="button" class="dropdown-item text-default" style="cursor: pointer" data-toggle="modal" data-target="#invoiceItemModal" @click="editInvoiceItem(data)">
                                                    <i class="ri-pencil-line"></i> {{ $t('label.edit invoice item') }}
                                                </button>
                                                <button v-if="!data.is_active" type="button" class="dropdown-item text-danger" style="cursor: pointer" @click="deleteNotify(data.id)">
                                                    <i class="ri-delete-bin-line"></i> {{ $t('label.delete invoice item') }}
                                                </button>
                                                <button v-else type="button" class="dropdown-item text-danger" style="cursor: pointer; opacity: 0.6" :title="$t('label.this invoice item cannot be deleted because it is used on at least one invoice you can inactivate the invoice item to prevent future use')" @click="bannerError($t('label.this invoice item cannot be deleted because it is used on at least one invoice you can inactivate the invoice item to prevent future use'))">
                                                    <i class="ri-delete-bin-line"></i> {{ $t('label.delete invoice item') }}
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table-pagination v-model="paginateInvoiceItems" :all-rows="allInvoiceItems" :perPage="perPage">

                        </table-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- Delete Modal -->
        <modal-delete :newid="newid" :deleteMethod="deleteInvoiceItem" :deleteModalText="deleteModalText"></modal-delete>
        <!-- Add and Edit Invoice Item -->
        <modal name="invoiceItemsModal" height="auto" :reset="true" :scrollable="true" :adaptive="true">
            <modal-invoice-items :savedType=saveType :item-data=invoiceItemData :info=editInfo @cancelFnc="closeInvoiceItemModal" @onSave="onAddNewInvoiceItems">
            </modal-invoice-items>
        </modal>
    </div>
</template>
<script>
import ModalInvoiceItems from '@/components/ModalInvoiceItems';
import TablePagination from '@/components/TablePagination';
import bus from '@/utils/event-bus';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    props: [],
    components: {
        TablePagination,
        ModalInvoiceItems
    },
    data() {
        return {
            allInvoiceItems: [],
            paginateInvoiceItems: [],
            allInvoices: [],
            form: {
                name: '',
                status: '',
                amount: 0.00,
                listItems: []
            },
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '',
                precision: 2,
                masked: false
            },
            isLoading: null,
            newid: 'DeleteInvoiceItem',
            deleteModalText: {
                title: 'ARE YOU SURE YOU WANT TO DELETE THIS INVOICE ITEM?',
                button: 'Yes, Delete'
            },
            perPage: 20,
            delete_id: null,
            saveType: '',
            invoiceItemData: [],
            editInfo: []
        }
    },
    created() {
        this.getInvoiceItems();
    },
    mounted() {
        bus.$on('deleteFInvoiceItem', () => {
            this.deleteInvoiceItem();
        });
    },
    methods: {
        getInvoiceItems() {
            this.isLoading = this.$loading.show();

            axios.get('/get-invoice-items')
                .then(response => {
                    this.allInvoiceItems = response.data.result;
                    this.isLoading.hide();
                })
                .catch(err => console.error("API ERROR", err));
        },
        onAddNewInvoiceItems(item) {
            this.$modal.hide('invoiceItemsModal');
            this.getInvoiceItems();
        },
        closeInvoiceItemModal() {
            this.$modal.hide('invoiceItemsModal');
            this.getInvoiceItems;
        },
        addInvoiceItem() {
            this.saveType = 'save';
            this.$modal.show('invoiceItemsModal');
        },
        editInvoiceItem(item) {
            this.editInfo = item;
            this.saveType = 'update';
            this.itemData = {
                item: item
            }
            this.$modal.show('invoiceItemsModal');
        },
        deleteNotify(delete_id) {
            this.delete_id = delete_id;
            $("#deleteModal" + this.newid).modal('show');
        },
        deleteInvoiceItem() {
            axios.post('/delete-invoice-items/' + this.delete_id).then(() => {
                this.getInvoiceItems();
            }).catch(err => {
                //console.log('list carriers error:', error)
            });
        },

        bannerError(msg) {
            bus.$emit('banner-error', { message: msg });
        },
        syncItems() {
            let loading = this.$loading.show();
            axios.get("/quickbooks/sync-items-invoice").then((response) => {
                this.getInvoiceItems();
                this.$bus.$emit('banner-success', { message: response.data.message });
            })
            .catch((err) => {
                console.log("ERROR: ", err)
                this.$bus.$emit('banner-error', { message: err.data.message });
            })
            .finally(() => {
               loading.hide();
            })
        }
    }
}
</script>
<style scoped>
.qbsync-btn { 
    margin-left:200px;
}
</style>
