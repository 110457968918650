<template>
    <div>
        <div class="card-body">
            <h3>Global Values</h3>
            <div class="mb-2">
                <div class="form-group">
                    <label>{{ $t('label.jobs customer') }}</label>
                    <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                    <!-- Filter out leads -->
                    <select class="form-control" v-model="globalJobValues.customerId" required>
                        <option value="">{{ $t('label.select a customer') }}</option>
                        <option v-for="(data, index) in customers" :value="data.id" :key="index" v-if="data.account_type == 1">{{ data.name }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>{{ $t('label.job type') }}</label>
                    <span v-if="!$v.globalJobValues.jobType.required" class="text-xs text-danger">* {{ $t('label.required') }}</span>
                    <select class="form-control" v-model="globalJobValues.jobType" required>
                        <option value="">{{ $t('label.select a job type') }}</option>
                        <option v-for="(data, index) in jobTypes" :value="data.id" :key="index">{{ data.name }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>{{ $t('label.job status') }}</label>
                    <span v-if="!$v.globalJobValues.jobStatus.required" class="text-xs text-danger">* {{ $t('label.required') }}</span>
                    <select class="form-control" v-model="globalJobValues.jobStatus" required>
                        <option value="">{{ $t('label.select a status') }}</option>
                        <option v-for="(data, index) in jobStatuses" :value="data.id" :key="index">{{ data.name }}</option>
                    </select>
                </div>
            </div>

            <h3>{{ $t('label.map data') }} &nbsp;&nbsp;({{ recordCount }} {{ $t('label.records') }}) &nbsp;{{ $t('label.attribues with * are required') }}</h3>
            <!-- Error Display -->
            <div class="text-danger" v-if="displayErrors">
                <div>{{ $t('label.generic form validation error') }}</div>
                <div v-for="(error, idx) in validationsErrors" :key="idx">
                    {{ error }}
                </div>
            </div>
            <ul class="list-group">
                <li class="list-group-item bg-gray-100">
                    <div class="row text-bold">
                        <div class="col-3">{{ $t('label.file column header') }}</div>
                        <div class="col-3">{{ $t('label.file column values') }}</div>
                        <div class="col-3">{{ $t('label.subpro attribute') }}</div>
                        <div class="col-3">{{ $t('label.match status') }}</div>
                    </div>
                </li>
                <!-- loop uploaded file header names and first record values -->
                <li class="list-group-item">
                    <div class="row" v-for="(header, idx) in fileData" :key="idx">
                        <div class="col-3">{{ header.name }}</div>
                        <div class="col-3">{{ header.value }}</div>
                        <div class="col-3">
                            <!-- dropdown with table fields -->
                            <select class="form-control" @change="validateAttribute(header)" v-model="header.attribute" required>
                                <option value="1">{{ $t('label.select attribute') }}</option>
                                <option v-for="(data, index) in jobFields" :value="data.tableName" :key="index">{{ (data.required) ? '*' : '' }}&nbsp;{{ data.name }}</option>
                            </select>
                        </div>
                        <div class="col-3">
                            <div class="text-lg text-danger" v-if="header.attribute <= 2"><i class="ri-error-warning-line"></i>&nbsp;{{ $t('label.no match') }}</div>
                            <div class="text-lg text-success" v-if="isNaN(header.attribute)"><i class="ri-check-line"></i>&nbsp;{{ $t('label.matched') }}</div>
                        </div>
                    </div>
                </li>
            </ul>
            <br>
            <div>
                <button type="button" class="btn btn-outline-primary" @click="$emit('fncReturn')">{{ $t('label.previous') }}</button>
                <button type="button" class="btn btn-success" :disabled="this.$v.$invalid" @click="importJobs()">{{ $t('label.import data') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';
import { required } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('customerData')

export default {
    props: ['fileData', 'importData'],
    components: {},
    emits: ['fncReturn', 'fncSuccess'],
    data() {
        return {
            globalJobValues: { customerId: '', jobStatus: '', jobType: '' },
            jobFields: [
                // Global fields will be Job Type, Customer, Job status (default open)
                { name: 'Job Name', tableName: 'job_name', required: true },
                { name: 'Address 1', tableName: 'address_1', required: false },
                { name: 'Address 2', tableName: 'address_2', required: false },
                { name: 'City', tableName: 'city', required: true },
                { name: 'State', tableName: 'state', required: true },
                { name: 'Postal Code', tableName: 'postal_code', required: false },
                { name: 'Start Date', tableName: 'start_date', required: false },
                { name: 'End Date', tableName: 'end_date', required: false },
            ],
            jobTypes: null,
            jobStatuses: null,
            recordCount: this.importData.length,
            validationsErrors: [],
            displayErrors: false,
        }
    },
    validations: {
        globalJobValues: {
            customerId: { required },
            jobStatus: { required },
            jobType: { required }
        }
    },
    computed: {
        ...mapState({
            customers: state => state.customers,
        }),
    },
    mounted() {
        this.loadLookupData()
    },
    methods: {
        ...mapActions(['getCustomers']),
        loadLookupData() {
            // Customers
            if (!this.customers.length) {
                this.getCustomers()
            }
            // job Types
            axios
                .get('/all-job-types')
                .then(response => {
                    this.jobTypes = response.data.result
                })
                .catch(err => {
                    console.log('ERROR: ', err)
                    bus.$emit('banner-error', { message: err, })
                });
            // job statuses
            axios
                .get('/jobAllJobStatuses')
                .then(response => {
                    this.jobStatuses = response.data
                    let index = response.data.findIndex(item => {
                        if (item.name == 'Open') return true;
                    })
                    // default the selected Job Status to the Open status
                    this.globalJobValues.jobStatus = response.data[index].id
                })
                .catch(err => {
                    console.log('ERROR: ', err)
                    bus.$emit('banner-error', { message: err, })
                });
        },
        validateAttribute(header) {
            this.fileData.forEach(function (obj) {
                if (obj.attribute == header.attribute && obj.name != header.name) {
                    obj.attribute = 1
                }
            })
        },
        importJobs() {
            if (!this.$v.$invalid) {
                let loading = this.$loading.show()
                this.displayErrors = false
                // Make sure any required attributes are validated

                let request = {
                    customerId: this.globalJobValues.customerId,
                    jobTypeId: this.globalJobValues.jobType,
                    jobStatusId: this.globalJobValues.jobStatus,
                    mappings: this.fileData,
                    data: this.importData
                }

                axios
                    .post('/dataImportJobs', request)
                    .then(response => {
                        loading.hide()
                        this.$emit('fncSuccess', response.data.recordCount);
                    })
                    .catch(err => {
                        loading.hide()
                        console.log('ERROR: ', err)
                        bus.$emit('banner-error', { message: err, })
                    })
                    .catch(err => {
                        loading.hide()
                        this.displayErrors = true
                        window.scrollTo(0, 0)
                    })
            }
        },
        validateAttributes() {
            this.validationsErrors = []
            return new Promise((resolve, reject) => {
                // For missing required field matches
                this.jobFields.forEach(field => {
                    if (field.required) {
                        var idx = this.fileData.findIndex(item => {
                            if (field.tableName == item.attribute)
                                return true
                        })
                        if (idx < 0) {
                            this.validationsErrors.push(`* "${field.name}" ${this.$t('label.attribute must be matched')}.`)
                        }
                    }
                })

                if (this.validationsErrors.length > 0) {
                    reject()
                }
                else {
                    resolve()
                }
            })
        },
    }
}
</script>