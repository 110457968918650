<template>
    <div class="card">
        <div class="card-header">
            <h3 class="d-flex align-items-center mb-0">
                {{ $t('label.billing & shipping address') }}
            </h3>
        </div>
        <div class="card-body">
            <div class="row">
                <!-- BILLING ADDRESS -->
                <div class="col-6">
                    <label class="form-control-label text-muted">{{ $t('label.billing address') }}</label>
                    <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                    <div class="dropdown w-100">
                        <div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': (isValid.billing_address === false || $v.formAddress.billing_address.$error), }">
                                <input name="billing_address" class="form-control form-control-dropdown pointer" :placeholder="$t('label.billing address')" type="text" v-model="$v.formAddress.billing_address.$model" readonly>
                                <i class="ri-arrow-down-s-line dropdown-icon"></i>
                            </div>
                        </div>
                        <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100" style="padding-left: 1rem">
                            <div class="pointer dropdown-items" @click="selectBillingAddress('company'); validateField('billing_address')">
                                <span class="jobname">{{ companySettings.name }} {{ $t('label.address') }}</span>
                                <span class="customername">
                                    <span>{{ companySettings.address_1 }}</span>
                                    <span v-if="companySettings.address_2">, {{ companySettings.address_2 }}</span>
                                    <span>{{ companySettings.city }}, {{ companySettings.state }} {{ companySettings.postal_code }}</span>
                                </span>
                            </div>
                            <div v-if="job" class="dropdown-divider"></div>
                            <div v-if="job" class="pointer dropdown-items" @click="selectBillingAddress('job'); validateField('billing_address')">
                                <span class="jobname">{{ job.job_name }} {{ $t('label.address') }}</span>
                                <span class="customername">
                                    <span>{{ job.address_1 }}</span>
                                    <span v-if="job.address_2">, {{ job.address_2 }}</span>
                                    <span>{{ job.city }}, {{ job.state }} {{ job.postal_code }}</span>
                                </span>
                            </div>
                            <div v-if="job" class="pointer dropdown-items" @click="selectBillingAddress('customer'); validateField('billing_address')">
                                <span class="jobname">{{ job.customer.name }} {{ $t('label.address') }}</span>
                                <span class="customername">
                                    <span>{{ job.customer.address_1 }}</span>
                                    <span v-if="job.customer.address_2">, {{ job.customer.address_2 }}</span>
                                    <span>{{ job.customer.city }}, {{ job.customer.state }} {{ job.customer.postal_code }}</span>
                                </span>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div class="pointer dropdown-items" @click="selectBillingAddress('other'); validateField('billing_address')">
                                <span class="jobname">{{ $t('label.other address') }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="formAddress.billing_address == 'Other Address'" class="row pt-3">
                        <!-- Address 1 -->
                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="address_1">{{ $t('label.address') }} 1</label>
                                <div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': (isOtherBillingValid.address_1 === false || $v.formAddress.billing_other.address_1.$error), }">
                                    <input type="text" name="address_1" v-model="$v.formAddress.billing_other.address_1.$model" class="form-control" :placeholder="$t('label.address') + ' 1'" required autofocus @change="validateBillOtherField('address_1')">
                                </div>
							<!-- <div v-if="$v.formAddress.billing_other.address_1.$error && !$v.formAddress.billing_other.address_1.required" class="text-danger text-sm">*{{ trans('label.field required') }}</div> -->
                            </div>
                        </div>
                        <!-- Address 2 -->
                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="address_2">{{ $t('label.address') }} 2</label>
                                <input type="text" name="address_2" v-model="formAddress.billing_other.address_2" class="form-control" :placeholder="$t('label.address') + ' 2'" autofocus>
                            </div>
                        </div>
                        <!-- City -->
                        <div class="col-4">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="city">{{ $t('label.city') }}</label>
                                <div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': (isOtherBillingValid.city === false || $v.formAddress.billing_other.city.$error), }">
                                    <input type="text" name="city" v-model="$v.formAddress.billing_other.city.$model" class="form-control" :placeholder="$t('label.city')" required autofocus @change="validateBillOtherField('city')">
                                </div>
                            </div>
                        </div>
                        <!-- zip/postal code -->
                        <div class="col-4">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="postal_code">{{ $t('label.postal code') }}</label>
                                <div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': (isOtherBillingValid.postal_code === false || $v.formAddress.billing_other.postal_code.$error), }">
                                    <input @keyup="getStateFromPostalCode('billing')" type="text" name="postal_code" v-model="$v.formAddress.billing_other.postal_code.$model" class="form-control" :placeholder="$t('label.postal code')" required autofocus @change="validateBillOtherField('postal_code')">
                                </div>
                            </div>
                        </div>
                        <!-- State -->
                        <div class="col-4">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="state">{{ $t('label.state') }}</label>
                                <!-- <select class="form-control pointer" v-model="$v.formAddress.billing_other.state.$model" :class="{ 'border-danger': (isOtherBillingValid.state === false || $v.formAddress.billing_other.state.$error), }" required autofocus> -->
                                <select class="form-control pointer" v-model="formAddress.billing_other.state">
                                    <option value="null">- {{ $t('label.select a state') }} -</option>
                                    <option v-for="(data, index) in allstates" :value="data" :key="index">{{ index }}</option>
                                </select>
                            </div>
                        </div>
                        <!-- Country -->
                        <!-- <div class="col-12">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="country">{{ $t('label.country') }}</label>
                                <select class="form-control pointer" v-model="$v.formAddress.billing_other.country.$model" :class="{ 'border-danger': (isOtherBillingValid.country === false || $v.formAddress.billing_other.country.$error), }" required autofocus>
                                    <option value="null">- {{ $t('label.select a country') }} -</option>
                                    <option v-for="(data, index) in allcountries" :value="index" :key="index">{{ data }}</option>
                                </select>
                            </div>
                        </div> -->
                    </div>
                    <div v-else class="display_address pt-3">
                        <div v-if="display_billing_address.name">
                            <div class="name">{{ display_billing_address.name }}</div>
                            <div class="address">{{ display_billing_address.address_1 }}</div>
                            <div v-if="display_billing_address.address_2" class="address">{{ display_billing_address.address_2 }}</div>
                            <div class="address">{{ display_billing_address.city }}, {{ display_billing_address.state }} {{ display_billing_address.postal_code }}</div>
                        </div>
                    </div>
                </div>
                <!-- SHIPPING ADDRESS -->
                <div class="col-6">
                    <label class="form-control-label text-muted">{{ $t('label.shipping address') }}</label>
                    <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                    <div class="dropdown w-100">
                        <div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': (isValid.shipping_address === false || $v.formAddress.shipping_address.$error), }">
                                <input name="shipping_address" class="form-control form-control-dropdown pointer" :placeholder="$t('label.shipping address')" type="text" v-model="$v.formAddress.shipping_address.$model" readonly>
                                <i class="ri-arrow-down-s-line dropdown-icon"></i>
                            </div>
                        </div>
                        <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100" style="padding-left: 1rem">
                            <div class="pointer dropdown-items" @click="selectShippingAddress('company'); validateField('shipping_address')">
                                <span class="jobname">{{ companySettings.name }} {{ $t('label.address') }}</span>
                                <span class="customername">
                                    <span>{{ companySettings.address_1 }}</span>
                                    <span v-if="companySettings.address_2">, {{ companySettings.address_2 }}</span>
                                    <span>{{ companySettings.city }}, {{ companySettings.state }} {{ companySettings.postal_code }}</span>
                                </span>
                            </div>
                            <div v-if="vendorContact" class="dropdown-divider"></div>
                            <div v-if="vendorContact" class="pointer dropdown-items" @click="selectShippingAddress('store_location'); validateField('shipping_address')">
                                <!--<span class="jobname">{{ $t('label.store location') }}</span>-->
                                <span class="jobname">{{ vendor.vendor_name }}</span>
                                <!-- <span class="jobname">{{ vendor }}</span> -->
                                <span class="customername">
                                    <span>{{ vendorContact.address_1 }}</span>
                                    <span v-if="vendorContact.address_2 != 'null'">, {{ vendorContact.address_2 }}</span>
                                    <span>{{ vendorContact.city }}, {{ vendorContact.state }} {{ vendorContact.postal_code }}</span>
                                </span>
                            </div>
                            <div v-if="job" class="dropdown-divider"></div>
                            <div v-if="job" class="pointer dropdown-items" @click="selectShippingAddress('job'); validateField('shipping_address')">
                                <span class="jobname">{{ job.job_name }} {{ $t('label.address') }}</span>
                                <span class="customername">
                                    <span>{{ job.address_1 }}</span>
                                    <span v-if="job.address_2">, {{ job.address_2 }}</span>
                                    <span>{{ job.city }}, {{ job.state }} {{ job.postal_code }}</span>
                                </span>
                            </div>
                            <div v-if="job" class="pointer dropdown-items" @click="selectShippingAddress('customer'); validateField('shipping_address')">
                                <span class="jobname">{{ job.customer.name }} {{ $t('label.address') }}</span>
                                <span class="customername">
                                    <span>{{ job.customer.address_1 }}</span>
                                    <span v-if="job.customer.address_2">, {{ job.customer.address_2 }}</span>
                                    <span>{{ job.customer.city }}, {{ job.customer.state }} {{ job.customer.postal_code }}</span>
                                </span>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div class="pointer dropdown-items" @click="selectShippingAddress('other'); validateField('shipping_address')">
                                <span class="jobname">{{ $t('label.other address') }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="formAddress.shipping_address == 'Other Address'" class="row pt-3">
                        <!-- Address 1 -->
                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="address_1">{{ $t('label.address') }} 1</label>
                                <div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': (isOtherValid.address_1 === false || $v.formAddress.shipping_other.address_1.$error), }">
                                    <input type="text" name="address_1" v-model="$v.formAddress.shipping_other.address_1.$model" class="form-control" :placeholder="$t('label.address') + ' 1'" required autofocus @change="validateShipOtherField('address_1')">
                                </div>
                            </div>
                        </div>
                        <!-- Address 2 -->
                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="address_2">{{ $t('label.address') }} 2</label>
                                <input type="text" name="address_2" v-model="formAddress.shipping_other.address_2" class="form-control" :placeholder="$t('label.address') + ' 2'" autofocus>
                            </div>
                        </div>
                        <!-- City -->
                        <div class="col-4">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="city">{{ $t('label.city') }}</label>
                                <div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': (isOtherValid.city === false || $v.formAddress.shipping_other.city.$error), }">
                                    <input type="text" name="city" v-model="$v.formAddress.shipping_other.city.$model" class="form-control" :placeholder="$t('label.city')" required autofocus @change="validateShipOtherField('city')">
                                </div>
                            </div>
                        </div>
                        <!-- zip/postal code -->
                        <div class="col-4">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="postal_code">{{ $t('label.postal code') }}</label>
                                <div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': (isOtherValid.postal_code === false || $v.formAddress.shipping_other.postal_code.$error), }">
                                    <input @keyup="getStateFromPostalCode('shipping')" type="text" name="postal_code" v-model="$v.formAddress.shipping_other.postal_code.$model" class="form-control" :placeholder="$t('label.postal code')" required autofocus @change="validateShipOtherField('postal_code')">
                                </div>
                            </div>
                        </div>
                        <!-- State -->
                        <div class="col-4">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="state">{{ $t('label.state') }}</label>
                                <!-- <select class="form-control pointer" v-model="$v.formAddress.shipping_other.state.$model" :class="{ 'border-danger': (isOtherValid.state === false || $v.formAddress.shipping_other.state.$error), }" required autofocus> -->
                                <select class="form-control pointer" v-model="formAddress.shipping_other.state">
                                    <option value="null">- {{ $t('label.select a state') }} -</option>
                                    <option v-for="(data, index) in allstates" :value="data" :key="index">{{ index }}</option>
                                </select>
                            </div>
                        </div>
                        <!-- Country -->
                        <!-- <div class="col-12">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="country">{{ $t('label.country') }}</label>
                                <select class="form-control pointer" v-model="$v.formAddress.shipping_other.country.$model" :class="{ 'border-danger': (isOtherValid.country === false || $v.formAddress.shipping_other.country.$error), }" required autofocus>
                                    <option value="null">- {{ $t('label.select a country') }} -</option>
                                    <option v-for="(data, index) in allcountries" :value="index" :key="index">{{ data }}</option>
                                </select>
                            </div>
                        </div> -->
                    </div>
                    <div v-else class="display_address pt-3">
                        <div v-if="display_shipping_address.name">
                            <div class="name">{{ display_shipping_address.name }}</div>
                            <div class="address">{{ display_shipping_address.address_1 }}</div>
                            <div v-if="display_shipping_address.address_2 != 'null'" class="address">{{ display_shipping_address.address_2 }}</div>
                            <div class="address">{{ display_shipping_address.city }}, {{ display_shipping_address.state }} {{ display_shipping_address.postal_code }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import bus from '@/utils/event-bus';
import { required, requiredIf} from 'vuelidate/lib/validators';

export default {
    props: ['formAddress', 'job', 'isValid', 'isOtherValid', 'isOtherBillingValid', 'poType', 'vendorContact', 'vendor', 'jobPOInfo'],
    emits: [''],
    components: {

    },
    data() {
        return {
            companySettings: JSON.parse(localStorage.getItem('company_settings')),
            display_billing_address: {
                name: null,
                country: 'US',
                address_1: null,
                address_2: null,
                postal_code: null,
                city: null,
                state: null,
            },
            display_shipping_address: {
                name: null,
                country: 'US',
                address_1: null,
                address_2: null,
                postal_code: null,
                city: null,
                state: null,
            },
        }
    },
    validations: {
        formAddress: {
            billing_address: { required },
            shipping_address: { required },
            billing_other: { 
                    address_1: { required: requiredIf(function() {
                    if(this.formAddress.billing_address_type == 'other') {
                        return true;
                    } else {
                        return false;
                    }
                })},
                city: { required: requiredIf(function() {
                    if(this.formAddress.billing_address_type == 'other') {
                        return true;
                    } else {
                        return false;
                    }
                })},
                postal_code: { required: requiredIf(function() {
                    if(this.formAddress.billing_address_type == 'other') {
                        return true;
                    } else {
                        return false;
                    }
                })},
                state: { required: requiredIf(function() {
                    if(this.formAddress.billing_address_type == 'other') {
                        return true;
                    } else {
                        return false;
                    }
                })},
                country: { required: requiredIf(function() {
                    if(this.formAddress.billing_address_type == 'other') {
                        return true;
                    } else {
                        return false;
                    }
                })},
            },
            shipping_other: {
                address_1: { required: requiredIf(function() {
                    if(this.formAddress.shipping_address_type == 'other') {
                        return true;
                    } else {
                        return false;
                    }
                })},
                city: { required: requiredIf(function() {
                    if(this.formAddress.shipping_address_type == 'other') {
                        return true;
                    } else {
                        return false;
                    }
                })},
                postal_code: { required: requiredIf(function() {
                    if(this.formAddress.shipping_address_type == 'other') {
                        return true;
                    } else {
                        return false;
                    }
                })},
                state: { required: requiredIf(function() {
                    if(this.formAddress.shipping_address_type == 'other') {
                        return true;
                    } else {
                        return false;
                    }
                })},
                country: { required: requiredIf(function() {
                    if(this.formAddress.shipping_address_type == 'other') {
                        return true;
                    } else {
                        return false;
                    }
                })},
            } 
        },
    },
    watch: {
        formAddress: function (newVal, oldVal) {
        console.log('watch vendorcontact',this.vendorContact);
            this.selectBillingAddress(newVal.billing_address_type);
            this.selectShippingAddress(newVal.shipping_address_type);
        }
    },
    computed: {
        //...mapGetters({
        //    allPurchaseOrderStatus: 'purchaseOrder/allPurchaseOrderStatus',
        //    allVendors: 'purchaseOrder/allVendors',
        //}),
    },
    mounted() {
        bus.$on('resetAddress', (d) => {
            this.resetAddress(d);
        })
        this.loadLkupData();
    },
    beforeDestroy() {
        bus.$off('resetAddress', (d) => {
            this.resetAddress(d);
        })
    },
    methods: {
        loadLkupData() {
            let promises = [];
            this.loader = this.$loading.show();
            promises.push(this.getCountries());
            promises.push(this.getStates());
            // Hide the loader overlay after all lookup data is loaded
            Promise.all(promises).then(() => {        
                if (this.poType == 'edit') {
                    this.selectBillingAddress(this.formAddress.billing_address_type);
                    this.selectShippingAddress(this.formAddress.shipping_address_type);
                }
                this.loader.hide();
            });
        },
        getStateFromPostalCode(type) {
            if (type == 'billing') {
                if (this.formAddress.billing_other.postal_code && this.formAddress.billing_other.postal_code.length == 5) {
                    axios.get('/get-state/' + this.formAddress.billing_other.postal_code).then(response => {
                        if (response.data.results) {
                            this.formAddress.billing_other.state = response.data.results.abbreviation;
                        } else {
                            this.formAddress.billing_other.state = ''
                        }
                    })
                        .catch(err => {
                            //console.log('list carriers error:', error)
                        });
                }
            } else {
                if (this.formAddress.shipping_other.postal_code && this.formAddress.shipping_other.postal_code.length == 5) {
                    axios.get('/get-state/' + this.formAddress.shipping_other.postal_code).then(response => {
                        if (response.data.results) {
                            this.formAddress.shipping_other.state = response.data.results.abbreviation;
                        } else {
                            this.formAddress.shipping_other.state = ''
                        }
                    })
                        .catch(err => {
                            //console.log('list carriers error:', error)
                        });
                }
            }
        },
        getCountries() {
            return axios.get('/get-countries').then(response => {
                this.allcountries = response.data.result;
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        getStates() {
            return axios.get('/get-states').then(response => {
                this.allstates = response.data.result;
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        selectBillingAddress(type) {

            this.formAddress.billing_address_type = type;

            if (type == 'company') {
                this.formAddress.billing_address = this.companySettings.name;
                this.formAddress.billing_address_id = this.companySettings.id;

                this.display_billing_address.name = this.companySettings.name;
                this.display_billing_address.address_1 = this.companySettings.address_1;
                this.display_billing_address.address_2 = this.companySettings.address_2;
                this.display_billing_address.postal_code = this.companySettings.postal_code;
                this.display_billing_address.city = this.companySettings.city;
                this.display_billing_address.state = this.companySettings.state;

            } else if (type == 'job') {
                this.formAddress.billing_address = this.job.job_name;
                this.formAddress.billing_address_id = this.job.id;

                this.display_billing_address.name = this.job.job_name;
                this.display_billing_address.address_1 = this.job.address_1;
                this.display_billing_address.address_2 = this.job.address_2;
                this.display_billing_address.postal_code = this.job.postal_code;
                this.display_billing_address.city = this.job.city;
                this.display_billing_address.state = this.job.state;

            } else if (type == 'customer') {
                this.formAddress.billing_address = this.job.customer.name;
                this.formAddress.billing_address_id = this.job.customer.id;

                this.display_billing_address.name = this.job.customer.name;
                this.display_billing_address.address_1 = this.job.customer.address_1;
                this.display_billing_address.address_2 = this.job.customer.address_2;
                this.display_billing_address.postal_code = this.job.customer.postal_code;
                this.display_billing_address.city = this.job.customer.city;
                this.display_billing_address.state = this.job.customer.state;

            } else {
                this.formAddress.billing_address = 'Other Address';
            }
        },
        selectShippingAddress(type) {
            this.formAddress.shipping_address_type = type;

            if (type == 'company') {
                this.formAddress.shipping_address = this.companySettings.name;
                this.formAddress.shipping_address_id = this.companySettings.id;

                this.display_shipping_address.name = this.companySettings.name;
                this.display_shipping_address.address_1 = this.companySettings.address_1;
                this.display_shipping_address.address_2 = this.companySettings.address_2;
                this.display_shipping_address.postal_code = this.companySettings.postal_code;
                this.display_shipping_address.city = this.companySettings.city;
                this.display_shipping_address.state = this.companySettings.state;

            } else if (type == 'job') {
                this.formAddress.shipping_address = this.job.job_name;
                this.formAddress.shipping_address_id = this.job.id;

                this.display_shipping_address.name = this.job.job_name;
                this.display_shipping_address.address_1 = this.job.address_1;
                this.display_shipping_address.address_2 = this.job.address_2;
                this.display_shipping_address.postal_code = this.job.postal_code;
                this.display_shipping_address.city = this.job.city;
                this.display_shipping_address.state = this.job.state;

            } else if (type == 'customer') {
                this.formAddress.shipping_address = this.job.customer.name;
                this.formAddress.shipping_address_id = this.job.customer.id;

                this.display_shipping_address.name = this.job.customer.name;
                this.display_shipping_address.address_1 = this.job.customer.address_1;
                this.display_shipping_address.address_2 = this.job.customer.address_2;
                this.display_shipping_address.postal_code = this.job.customer.postal_code;
                this.display_shipping_address.city = this.job.customer.city;
                this.display_shipping_address.state = this.job.customer.state;

            } else if (type == 'store_location') {
                this.formAddress.shipping_address = this.vendor.vendor_name;
                this.formAddress.shipping_address_id = this.vendorContact.id;

                this.display_shipping_address.name = this.vendor.vendor_name;
                this.display_shipping_address.address_1 = this.vendorContact.address_1;
                this.display_shipping_address.address_2 = this.vendorContact.address_2;
                this.display_shipping_address.postal_code = this.vendorContact.postal_code;
                this.display_shipping_address.city = this.vendorContact.city;
                this.display_shipping_address.state = this.vendorContact.state;

            } else {
                this.formAddress.shipping_address = 'Other Address';
            }

        },
        resetAddress(which) {
            if (which == 1 && (this.formAddress.shipping_address_type !== 'other')) {
                this.display_shipping_address.name = null;
                this.display_shipping_address.country = 'US';
                this.display_shipping_address.address_1 = null;
                this.display_shipping_address.address_2 = null;
                this.display_shipping_address.postal_code = null;
                this.display_shipping_address.city = null;
                this.display_shipping_address.state = null;

                this.formAddress.shipping_address = null;
                this.formAddress.shipping_address_id = null;
                this.formAddress.shipping_address_type = null;

            } else {
                if (this.formAddress.billing_address_type !== 'other') {
                    this.display_billing_address.name = null;
                    this.display_billing_address.country = 'US';
                    this.display_billing_address.address_1 = null;
                    this.display_billing_address.address_2 = null;
                    this.display_billing_address.postal_code = null;
                    this.display_billing_address.city = null;
                    this.display_billing_address.state = null;

                    this.formAddress.billing_address = null;
                    this.formAddress.billing_address_id = null;
                    this.formAddress.billing_address_type = null;
                }
            }
        },
        validateField(field) {
            // If field has error, set isValid field to false
            if (this.poType == 'add' || this.poType == 'edit') {
                this.isValid[field] = !this.$v.formAddress[field].$error;
            }
        },
        validateShipOtherField(field) {
            // If field has error, set isValid field to false
            if (this.poType == 'add' || this.poType == 'edit') {
                this.isOtherValid[field] = !this.$v.formAddress.shipping_other[field].$error;
            }
        },
        validateBillOtherField(field) {
            // If field has error, set isValid field to false
            if (this.poType == 'add' || this.poType == 'edit') {
                this.isOtherBillingValid[field] = !this.$v.formAddress.billing_other[field].$error;
            }
        }
      
    },
}
</script>
