<template>
	<div class="col-xl-12 order-xl-1 mobile-p-0">
		<div class="card">
			<div class="card-header">
				<div class="row align-items-center justify-content-between">
					<div>
						<h3 class="mb-0 d-flex align-items-center"><i class="ri-bank-card-line heading-title"></i> Payments</h3>
					</div>
					<div>
						<!-- <button type="button" class="btn btn-sm btn-default m-0" @click="addModal">
							Process Payment
						</button> -->
					</div>
				</div>
			</div>
			<div v-if="allpayments.length == 0">
				<div class="card-body m-0 py-0">
					<div class="bg-white text-center row align-items-center m-0">
						<i class="ri-bank-card-line ni-8x w-100 color-gray-400"></i>
						<p class="w-100 font-weight-normal">No Payments have been processed on this Job.</p>
					</div>
				</div>
			</div>
			<div v-if="allpayments.length > 0" class="table-responsive pb-4" style="overflow-x: visible">
				<!-- <div v-if="allpayments.length > 0" class="table-responsive pb-4"> -->
				<table class="table table-flush table-sm" id="datatable-allpaymentslist">
					<thead class="thead-light">
						<tr>
							<th scope="col" width="10%">Payment ID</th>
							<th scope="col" width="10%">Payment Date</th>
							<th v-if="companySettings.settings.invoice" scope="col">Associated Invoice</th>
							<th v-if="!companySettings.settings.invoice" scope="col">Payment Note</th>
							<th class="text-right" scope="col" width="10%">Payment Total</th>
							<th scope="col" width="5%"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(data, index) in allpayments" :key="index">
							<td>{{ data.payment_number.payment_number }}</td>
							<td>{{ data.created_at }}</td>
							<td><a>{{ data.invoice_number_concat }}</a></td>
							<td class="text-right">${{ parseFloat(data.amount).toFixed(2) | formatThousands }}</td>
							<td class="text-right">
								<div class="dropdown">
									<a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<i class="fas fa-ellipsis-v"></i>
									</a>
									<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
										<a class="dropdown-item text-default pointer" @click="paymentViewModal(data)">
											<i class="ri-eye-line"></i> View Receipt
										</a>
										<a class="dropdown-item text-default pointer" @click="paymentSendModal(data)">
											<i class="ri-mail-send-line"></i> Send Receipt
										</a>
										<a :href="'/payment-download/' + data.id" target="_blank" class="dropdown-item text-default pointer">
											<i class="ri-download-line"></i> Download Receipt
										</a>
										<a :href="'/payment-print/' + data.id" target="_blank" class="dropdown-item text-default pointer">
											<i class="ri-printer-line"></i> Print Receipt
										</a>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
					<thead class="ttotal-sub">
						<tr>
							<th scope="col" colspan="3" class="text-right">TOTALS</th>
							<th class="text-right" scope="col">
								<h4 class="mb-0">${{ totalPayment | formatThousands }}</h4>
							</th>
							<th scope="col"></th>
						</tr>
					</thead>
				</table>
			</div>
		</div>
		<!-- Modal -->
		<!-- 
		<modal-payment v-if="openModal" :job="job" :source="'addPayment'"  />
		<modal-payment-view v-if="openModal" :job="job" @reloadPayments="getAllPayments"  />
		<modal-payment-send v-if="openModal" :job="job"  /> 
		-->

		<!-- Modal -->
		<!-- Payment Modal -->
		<modal class="pb-6" name="paymentModal1" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
			<ModalPayment :job="job" :invoice-data="paymentData" :source="source" @reloadPayments="getAllPayments" @cancelFnc="$modal.hide('paymentModal1')"></ModalPayment>
		</modal>

		<!-- Payment View Modal -->
		<modal class="pb-6" name="paymentViewModal" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
			<ModalPaymentView :job="job" :payment-data="paymentData" @reloadPayments="getAllPayments" @cancelFnc="$modal.hide('paymentViewModal')"></ModalPaymentView>
		</modal>

		<!-- Payment Send Modal -->
		<modal class="pb-6" name="paymentSendModal" height="auto" width="100%" :max-width="900" :scrollable="true" :reset="true" :adaptive="true">
			<ModalPaymentSend :job="job" :payment-data="paymentData" from-menu="true" @cancelFnc="$modal.hide('paymentSendModal')"></ModalPaymentSend>
		</modal>

	</div>
</template>

<script>
import bus from '@/utils/event-bus';
import ModalPayment from '../components/ModalPayment.vue';
import ModalPaymentSend from '../components/ModalPaymentSend.vue';
import ModalPaymentView from '../components/ModalPaymentView.vue';

export default {
	props: ['job'],
	components: {
		ModalPayment,
		ModalPaymentView,
		ModalPaymentSend,
	},
	data() {
		return {
			companySettings: JSON.parse(localStorage.getItem('company_settings')),
			allpayments: [],
			openModal: false,
			source: null,
			totalPayment: 0,
			dataTable: null,
			paymentData: []
		}
	},
	created() {
		this.getAllPayments();
	},
	mounted() {
		// this.runDataTable();
		bus.$on('getallpayments', () => {
			this.getAllPayments();
		});
	},
	computed: {

	},
	methods: {
		getAllPayments() {
			axios.get('/get-all-payments/' + this.job.id)
				.then(response => {
					this.allpayments = response.data.result;
					this.totalPayment = response.data.totalPayment;
					// console.log('this.allpayments',this.allpayments)
					// $('#datatable-allpaymentslist').DataTable().destroy();
					this.runDataTable();
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		// addModal() {
		// 	this.source = 'addPayment';
		// 	this.payment = [];
		// 	this.openModal = true;
		// },
		// paymentViewModal(data) {
		// 	console.log('data',data)
		// 	// this.source = '';
		// 	this.payment = data;
		// 	this.openModal = true;

		// 	setTimeout(function() {
		// 		bus.$emit('paymentView', { 
		// 			data: data,
		// 		});
		// 	}, 1000)
		// },
		// paymentSendModal(data) {
		// 	console.log('data',data)
		// 	// this.source = '';
		// 	this.payment = data;
		// 	this.openModal = true;

		// 	setTimeout(function() {
		// 		bus.$emit('paymentSend', { 
		// 			data: data,
		// 			fromMenu: true
		// 		});
		// 	}, 1000)
		// },
		addModal() {
			this.source = 'addPayment';
			this.paymentData = [];
			this.$modal.show('paymentModal1');
		},
		paymentViewModal(data) {
			this.paymentData = {
				data: data
			};
			this.$modal.show('paymentViewModal');
		},
		paymentSendModal(data) {
			this.paymentData = {
				data: data
			};
			this.$modal.show('paymentSendModal');
		},
		runDataTable() {
			if (this.dataTable !== null)
				this.dataTable.destroy();

			// Build Datatable
			this.$nextTick(() => {
				this.dataTable = $("#datatable-allpaymentslist").DataTable({
					order: [],
					pageLength: 25,
					"searching": false,
					"lengthChange": true,
					"bDestroy": true,
					"sDom": 'Rfrtilp',
					language: {
						paginate: {
							previous: "<i class='fas fa-angle-left'>",
							next: "<i class='fas fa-angle-right'>"
						},
						lengthMenu: "Items per page _MENU_",
					},
				});
			});
		},
	}
}
</script>
