<script>
import moment from '@/utils/date-time';

export default {
    methods: {
        // Return boolean based on the company settings that 
        // will allow or forbid employees to clock in on future
        // or past tasks. 
        nonCurrentDayClockin(taskDate, companySettings) {
            // Allow Clock in Only for Current Day
            if (!companySettings.future_past_clockin) {
                let currentDate = moment().format('MM/DD/YYYY'),
                    momentTaskDate = moment(taskDate).format('MM/DD/YYYY');

                if (currentDate != momentTaskDate)
                    return false;
            }

            return true;
        },
    },
}
</script>