/* eslint-disable no-unused-vars */

const namespaced = true;

const state = {
    customers: [],
};

const getters = {
    customers: state => state.customers,
};

const actions = {
    getCustomers({ state, commit, rootState, dispatch }) {
        return new Promise((resolve, reject) => {
            axios
                .get('/getCustomers')
                .then(res => {
                    commit('setCustomers', res.data);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
}

const mutations = {
    setCustomers(state, customers) {
        state.customers = customers;
    },
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
};