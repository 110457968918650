<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50">
            <!-- Header -->
            <div class="d-flex z-index-10">
                <!-- Breadcrumbs -->
                <div class="mr-auto z-index-1">
                    <breadcrumbs :title="$t('label.assets')">
                        <!-- Breakcrumb slots -->
                        <li class="breadcrumb-item">{{ $t('label.assets') }}
                        </li>
                    </breadcrumbs>
                </div>

                <!-- Asset Dropdown -->
                <div class="py-4 mr--2 align-items-center" style="z-index: 1">
                    <button type="button" class="btn btn-white" data-toggle="dropdown" id="vssetMenu">
                        {{ $t('label.add asset') | uppercase }} <i class="fas fa-angle-down"></i>
                    </button>
                    <!-- Dropdown -->
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" style="max-width: 100%;">
                        <!-- Add Item -->
                        <router-link class="dropdown-item" to="assets/create">
                            <i class="ri-add-line"></i> {{ $t('label.add item') }}
                        </router-link>
                        <!-- Mark as sold/archived -->
                        <router-link class="dropdown-item" to="vehicle/create">
                            <i class="ri-add-line"></i>{{ $t('label.add vehicle') }}
                        </router-link>
                    </div>
                </div>
            </div>

            <!-- Filters  -->
            <div class="">
                <div class="card">
                    <!-- Card Header -->
                    <div class="card-header">
                        <div class="row align-items-center">
                            <div class="col-8">
                                <h3 class="d-flex mb-0">
                                    <i class="ri-hammer-line"></i>
                                    {{ $t("label.assets") }}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <asset-man-top-filter @set-filters="setFilters" @set-view="setView"></asset-man-top-filter>
                    </div>
                </div>
            </div>

            <!-- Vehicle and Asset Item Lists -->
            <div class="mt-4" v-show="assetsShown.vehicles">
                <vehicles-component :filters="filters" :view-type="viewType" @onLoaded="hideOverlay()"></vehicles-component>
            </div>
            <div class="mt-4" v-show="assetsShown.items">
                <asset-man-item-list :filters="filters" :view-type="viewType" @onLoaded="hideOverlay()"></asset-man-item-list>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [],
    data() {
        return {
            viewType: null,
            loader: null,
            filters: {},
            assetItems: [],
            vehicles: [],
            assetsShown: {
                vehicles: true,
                items: true,
            },
            tableCompsLoaded: 0
        };
    },
    mounted() {
        this.loader = this.$loading.show();
    },
    methods: {
        // Returns selected filters from the top filters component
        setFilters(filters) {
            this.filters = JSON.parse(JSON.stringify(filters));

            if (filters.assetType === "all") {
                this.assetsShown.vehicles = true;
                this.assetsShown.items = true;
            } else if (filters.assetType === "vehicles") {
                this.assetsShown.vehicles = true;
                this.assetsShown.items = false;
            } else {
                this.assetsShown.vehicles = false;
                this.assetsShown.items = true;
            }
        },
        // Returns selected viewfrom the top filters component
        setView(viewType) {
            this.viewType = JSON.parse(JSON.stringify(viewType));
        },
        hideOverlay() {
            // Hide the overlay after each child component has received data
            this.tableCompsLoaded++;

            if (this.tableCompsLoaded >= 2)
                this.loader.hide();
        }
    },
};
</script>
