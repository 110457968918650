<template>
    <div class="position-relative">
        <div class="sp-comp-nav sp-nav-sticky-top align-items-center hidden-md-down z-index-3" style="width: 300px;">
            <div class="mr-3 z-index-10">
                <div class="card">
                    <!-- Individaul assignees -->
                    <div class="">
                        <div class="card-header">
                            <h3 class="m--2 d-flex align-items-center">
                                <i class="ri-user-line"></i>{{ $t('label.users') }}
                            </h3>
                        </div>
                        <div class="card-body p-0 mx-auto" style="max-height: 275px; overflow-y: auto;">
                            <div class="border-bottom calendar-draggable" v-for="(user, idx) in allUsers" :key="idx">
                                <!-- User Drag and Drop -->
                                <div class="px-1 d-flex align-items-center pointer">
                                    <DraggableItem :transferData="user">
                                        <div class="d-flex align-items-center mr-auto text-sm sp-grabbable">
                                            <img :src="user.picture" class="avatar-img rounded-circle" style="width: 30px; pointer-events: none;" disabled />
                                            <span class="text-sm">
                                                &nbsp;<span>{{ user.name }}</span>&nbsp;
                                                <span v-if="user.tasks_assigned_to > 0">({{ user.tasks_assigned_to }})</span>
                                                <span v-else class="text-danger text-bold">(0)</span>
                                            </span>
                                        </div>
                                    </DraggableItem>
                                    <template v-if="user.time_offs">
                                        <div v-if="user.time_offs.length" class="ml-auto d-flex align-items-center">
                                            <!-- Time Off Badge and Tooltip -->
                                            <div :id="'userTimeOff' + user.id" @mouseover="onHoverOverTimeOff('userTimeOff' + user.id, 'userTimeOffPopup' + user.id)" @mouseleave="onHoverOverTimeOff('userTimeOff' + user.id, 'userTimeOffPopup' + user.id)" class="d-flex align-items-center badge badge-sm text-white bg-danger sp-tooltip ml--2">
                                                {{ moment(user.time_offs[0].start_date).format('MM/DD') }}
                                                &nbsp;-&nbsp;{{ moment(user.time_offs[0].end_date).format('MM/DD') }}
                                                <div :id="'userTimeOffPopup' + user.id" class="d-flex align-items-center sp-tooltiptext sp-tooltiptext-danger text-xs position-fixed">
                                                    {{ $t('label.time off') }}:
                                                    {{ moment(user.time_offs[0].start_date).format('MM/DD, h:mm:ss a') }}
                                                    &nbsp;-&nbsp;{{ moment(user.time_offs[0].end_date).format('MM/DD, h:mm:ss a') }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Assignees from crew -->
                    <div class="">
                        <div class="card-header">
                            <h3 class="m--2 d-flex align-items-center">
                                <i class="ri-team-line"></i>&nbsp;{{ $t('label.crews') }}
                            </h3>
                        </div>
                        <div class="card-body p-0 mx-auto" style="max-height: 275px; overflow-y: auto;">
                            <div class="border-bottom" v-for="(crew, idx) in allCrews" :key="idx">
                                <div class="row m-0 calendar-draggable">
                                    <!-- Crew Drag and Drop -->
                                    <div class="col-10 pointer pl-2">
                                        <DraggableItem :transferData="crew.members">
                                            <div class="py-2 d-flex align-items-center pointer sp-grabbable">
                                                {{ crew.name }}&nbsp;
                                                <span v-if="crew.tasks_assigned_to > 0">({{ crew.tasks_assigned_to }})</span>
                                                <span v-else class="text-danger text-bold">(0)</span>
                                            </div>
                                        </DraggableItem>
                                    </div>
                                    <div class="col-2 d-flex align-items-center ml-auto pointer" @click="setCrewExpView(crew)">
                                        <i class="text-xs ml-auto" :class="crew.expandedView ? 'ri-arrow-down-s-line' : 'ri-arrow-right-s-line'"></i>
                                    </div>
                                </div>
                                <div v-if="crew.expandedView">
                                    <!-- Individual Crew Memeber Drag and Drop -->
                                    <div class="border-top calendar-draggable" v-for="(user, idx) in crew.members" :key="idx">
                                        <div class="px-1 d-flex align-items-center pointer">
                                            <DraggableItem :transferData="user.user">
                                                <span class="pl-2 d-flex align-items-center pointer sp-grabbable">
                                                    <img :src="user.user.picture" class="avatar-img rounded-circle" style="width: 25px; pointer-events: none;" disabled />
                                                    <span class="text-sm">&nbsp;{{ user.user.name }}</span>
                                                </span>
                                            </DraggableItem>
                                            <template v-if="user.user.time_offs">
                                                <div v-if="user.user.time_offs.length" class="ml-auto d-flex align-items-center">
                                                    <!-- Time Off Badge and Tooltip -->
                                                    <div :id="'crewUser' + user.user.id" @mouseover="onHoverOverTimeOff('crewUser' + user.user.id, 'crewUserPopup' + user.user.id)" @mouseleave="onHoverOverTimeOff('crewUser' + user.user.id, 'crewUserPopup' + user.user.id)" class="d-flex align-items-center badge badge-sm text-white bg-danger sp-tooltip ml--2">

                                                        {{ moment(user.user.time_offs[0].start_date).format('MM/DD') }}
                                                        &nbsp;-&nbsp;{{ moment(user.user.time_offs[0].end_date).format('MM/DD') }}
                                                        <div :id="'crewUserPopup' + user.user.id" class="d-flex align-items-center sp-tooltiptext sp-tooltiptext-danger text-xs position-fixed" style="z-index: 10000">
                                                            {{ $t('label.time off') }}:
                                                            {{ moment(user.user.time_offs[0].start_date).format('MM/DD, h:mm:ss a') }}
                                                            &nbsp;-&nbsp;{{ moment(user.user.time_offs[0].end_date).format('MM/DD, h:mm:ss a') }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="calendar-view card mb-5 sp-mobile-mt-175 justify-content-end flex-grow-1">
            <div class="card-header pb-3 header-sticky header1">
                <div class="row align-items-center d-flex py-0 m-0">
                    <div class="col-md-2 p-0">
                        <h3 class="mb-0 d-flex align-items-center">
                            <i class="ri-calendar-event-line heading-title"></i> {{ $t('label.day') }}
                        </h3>
                    </div>
                    <div class="p-0 mb--1 d-flex ml-auto">
                        <div class="row align-items-center d-flex">
                            <div class="pt-2 pb-1 btn-group-light calendar-filter ml-auto mr-2" :class="{ 'btn-group d-block': windowWidth >= 768, 'mt--3': windowWidth < 768 }">
                                <!-- FILTER BY TASK TYPE -->
                                <div class="dropdown" :class="{ 'mb-3 d-flex ml-auto mt--4': windowWidth < 768 }">
                                    <button type="button" class="d-flex align-items-center btn btn-white text-primary ml-auto" data-toggle="dropdown">
                                        <i class="p-0 ri-checkbox-circle-line"></i>
                                        <span v-if="allTaskTypesSelected">{{ $t('label.all types') }}</span>
                                        <span v-else-if="noTaskTypesSelected">{{ $t('label.types none') }}</span>
                                        <span v-else>{{ $t('label.selected types') }}</span>
                                    </button>

                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" style="overflow-y: scroll; max-height: 300px" @click.stop="">
                                        <!-- Select All Types -->
                                        <a class="dropdown-item text-default pointer" @click="onAllTypesClick()">
                                            <i class="ri-checkbox-circle-line"></i> {{ $t('label.select all') }}
                                        </a>
                                        <!-- Deselect All Types -->
                                        <a class="dropdown-item text-default pointer" @click="onDeselectAllTypesClick()">
                                            <i class="ri-checkbox-blank-circle-line"></i> {{ $t('label.deselect all') }}
                                        </a>

                                        <div class="dropdown-divider"></div>
                                        <a v-for="(d, i) in filterBy.taskTypes" :key="i" class="dropdown-item text-default pointer" @click="selectTypeFilter(d.id)">
                                            <div class="d-flex align-items-center">
                                                <span class="badge badge-dot">
                                                    {{ d.name }}
                                                </span>
                                                <i v-if="d.active" class="ri-checkbox-circle-line ml-auto text-success"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>

                                <!-- FILTER BY TASK STATUS -->
                                <div class="dropdown" :class="{ 'mb-3 d-flex ml-auto': windowWidth < 768 }">
                                    <button type="button" class="d-flex align-items-center btn btn-white text-primary ml-auto" data-toggle="dropdown">
                                        <i class="p-0 ri-checkbox-circle-line"></i>
                                        <span v-if="allTaskStatusesSelected">{{ $t('label.all statuses') }}</span>
                                        <span v-else-if="noTaskStatusesSelected">{{ $t('label.statuses none') }}</span>
                                        <span v-else>{{ $t('label.selected statuses') }}</span>
                                    </button>

                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" @click.stop="">
                                        <!-- Select All Statuses -->
                                        <a class="dropdown-item text-default pointer" @click="onAllStatusesClick()">
                                            <i class="ri-checkbox-circle-line"></i> {{ $t('label.select all') }}
                                        </a>
                                        <!-- Deselect All Statuses -->
                                        <a class="dropdown-item text-default pointer" @click="onDeselectAllStatusesClick()">
                                            <i class="ri-checkbox-blank-circle-line"></i> {{ $t('label.deselect all') }}
                                        </a>


                                        <div class="dropdown-divider"></div>
                                        <a v-for="(d, i) in filterBy.taskStatuses" :key="i" class="dropdown-item text-default pointer" @click="selectStatusFilter(d.id)">
                                            <div class="d-flex align-items-center">
                                                <span class="badge badge-dot">
                                                    <i :style="`background-color: ${d.status_color}`"></i> {{ d.name }}
                                                </span>
                                                <i v-if="d.active" class="ri-checkbox-circle-line ml-auto text-success"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>


                                <!-- FILTER BY CREWS/USERS -->
                                <div class="dropdown" :class="{ 'mb-3 d-flex ml-auto': windowWidth < 768 }">
                                    <button type="button" class="btn btn-white text-primary ml-auto" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="ri-group-line"></i>
                                        <span v-if="crewOrUser == 1">
                                            <span v-if="filterBy.allCrews">{{ $t('label.all crews') }}</span>
                                            <span v-else>{{ $t('label.selected crews') }}</span>
                                        </span>
                                        <span v-else>
                                            <span v-if="filterBy.allUsers">{{ $t('label.all users') }}</span>
                                            <span v-else>{{ $t('label.selected users') }}</span>
                                        </span>
                                        <i class="ri-arrow-down-s-fill"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow filterUsers" @click.stop="">
                                        <div class="d-flex justify-content-between px-3 py-1">
                                            <div class="custom-control custom-radio">
                                                <input name="crews" class="custom-control-input" value="1" id="crews" type="radio" v-model="crewOrUser" @change="toggleCrewUserFilter('crews')">
                                                <label class="custom-control-label" for="crews">{{ $t('label.crews') }}</label>
                                            </div>
                                            <div class="custom-control custom-radio">
                                                <input name="users" class="custom-control-input" value="2" id="users" type="radio" v-model="crewOrUser" @change="toggleCrewUserFilter('users')">
                                                <label class="custom-control-label" for="users">{{ $t('label.users') }}</label>
                                            </div>
                                        </div>
                                        <div class="dropdown-divider"></div>
                                        <!-- CREWS -->
                                        <template v-if="crewOrUser == 1">
                                            <a class="dropdown-item text-default">
                                                <label class="d-flex align-items-center form-control-label text-muted font-weight-normal m-0 w-100 pointer" for="all-crews">
                                                    <input @change="selectCrewFilter('All')" class="form-control-input" id="all-crews" type="checkbox" value="allcrews" v-model="filterBy.allCrews">
                                                    &nbsp;{{ $t('label.all crews') }}
                                                </label>
                                            </a>
                                            <div class="dropdown-divider"></div>
                                            <h4 class="px-3 py-3 m-0">{{ $t('label.crews') }}</h4>
                                            <a v-for="(d, i) in filtersList.crews" :key="'crew-' + i" class="dropdown-item text-default">
                                                <label class="d-flex align-items-center form-control-label text-muted font-weight-normal m-0 w-100 pointer" :for="'filter-' + d.id">
                                                    <input @change="selectCrewFilter(d)" class="form-control-input" :id="'filter-' + d.id" type="checkbox" :value="d.id" v-model="d.filteredBy">
                                                    &nbsp;{{ d.name }}
                                                </label>
                                            </a>
                                        </template>
                                        <!-- USERS -->
                                        <template v-if="crewOrUser == 2">
                                            <a class="dropdown-item text-default">
                                                <label class="d-flex align-items-center form-control-label text-muted font-weight-normal m-0 w-100 pointer" for="all-users">
                                                    <input @change="selectUserFilter('All')" class="form-control-input" id="all-users" type="checkbox" :value="true" v-model="filterBy.allUsers">
                                                    &nbsp;{{ $t('label.all users') }}
                                                </label>
                                            </a>
                                            <div class="dropdown-divider"></div>
                                            <h4 class="px-3 py-3 m-0">{{ $t('label.assignees') }}</h4>
                                            <a v-for="(d, i) in filtersList.users" :key="'user-' + i" class="dropdown-item text-default">
                                                <label class="d-flex align-items-center form-control-label text-muted font-weight-normal m-0 w-100 pointer" :for="'filter-' + d.id">
                                                    <input @change="selectUserFilter(d)" class="form-control-input" :id="'filter-' + d.id" type="checkbox" :value="d.id" v-model="d.filteredBy">
                                                    &nbsp;{{ d.name }}
                                                </label>
                                            </a>
                                        </template>
                                        <a class="dropdown-item text-default pointer text-center mt-3" @click="deselectAll()">
                                            <h4 class="m-0">{{ $t('label.deselect all') }}</h4>
                                        </a>
                                    </div>
                                </div>
                                <!-- SORT BY -->
                                <div class="dropdown" :class="{ 'mb-1 d-flex ml-auto': windowWidth < 768 }">
                                    <button type="button" class="btn btn-white text-primary ml-auto" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="ri-arrow-up-down-line"></i>{{ $t('label.sort') }}: {{ sortBy }}<i class="ri-arrow-down-s-fill"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                        <a v-for="(d, i) in sortList" :key="'sort-' + i" class="dropdown-item text-default pointer" @click="selectSortBy(d)">{{ d }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pt-3 mx--4 border-bottom"></div>
                <div class="mt-2 mb--2 mx-0 px-0 row align-items-center justify-content-between m-0">
                    <!-- Change Date View -->
                    <div class="col p-0 m-0">
                        <div class="row align-items-center m-0">
                            <div class="col-auto py-0 px-0 mr-3">
                                <button type="button" class="btn btn-default text-white text-uppercase px-3 py-1" @click="today">{{ $t('label.go to today') }}</button>
                            </div>
                            <div class="col-auto p-0 mr-2 pl-0">
                                <i class="ri-arrow-left-s-line heading-title pointer" @click="prevDate"></i>
                                <i class="ri-arrow-right-s-line heading-title pointer" @click="nextDate"></i>
                            </div>
                            <div class="col-auto pl-0">
                                <div class="d-flex align-items-center">
                                    <date-picker v-model="selectedDate" :clearable="false" class="full-width" format="dddd, DD MMMM YYYY" :placeholder="$t('label.select date')" @change="dateChange">
                                        <template v-slot:input>
                                            <span class="pointer d-flex align-items-center">
                                                <i class="ri-calendar-2-line heading-title"></i>
                                                {{ textDate }}
                                                <i class="ri-arrow-down-s-line heading-title"></i>
                                            </span>
                                        </template>
                                        <template v-slot:icon-calendar><span></span></template>
                                    </date-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Search Bar -->
                    <div class="col-12 col-sm-3 mx--3 py-2">
                        <div class="search-schedule">
                            <i class="ri-search-line"></i>
                            <input class="form-control" type="text" v-model="searchText" autocomplete="off" :placeholder="$t('label.search schedule')">
                        </div>
                    </div>
                    <span class="d-flex pr-3 hidden-sm-down"></span>
                    <!-- Expanded View -->
                    <div class="col-auto pt-2 pb-1 pl-0 mr--4 toggle d-flex" :class="{ 'col-12': windowWidth < 768 }">
                        <label class="custom-toggle view mr-auto ml-xs-auto">
                            <input type="checkbox" v-model="expandedView" @click="toggleView(1)">
                            <span class="custom-toggle-slider rounded-circle" data-label-off="Expanded View" data-label-on="Expanded View"></span>
                        </label>
                    </div>
                </div>

                <div class="pt-3 mx--4 border-bottom"></div>
                <div class="row mx--4 mb--3" style="line-height: 5px;">
                    <div class="col-1 py-3 border-col1"></div>
                    <div class="px-4 py-3 text-center col-11 border-col2">
                        <span class="border-today"></span>
                        <span class="today-text">{{ todayText }}</span>
                    </div>
                </div>
            </div>
            <!-- DISPLAY TASKS -->
            <div v-if="filteredTasks.length" class="card-body p-0 pb-3">
                <div class="py-2">
                    <div v-for="(task, idx) in filteredTasks" :key="idx">
                        <div class="row px-2 task-list">
                            <div class="col">
                                <DroppableItem :onDrop="onAssigneeAdd" :dropData="task">
                                    <TaskItem view="day" :task="task" :expanded-view="expandedView" :drag-column="true" :key="idx" @reloadNavData="reloadNavData" @parentSaveTaskDatesFnc="updateTaskDates">
                                    </TaskItem>
                                </DroppableItem>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- No Tasks View -->
            <div v-else class="card-body p-0 pb-3">
                <div class="bg-white text-center row align-items-center m-0">
                    <i class="ri-todo-line ni-8x w-100 color-gray-400"></i>
                    <p class="w-100 font-weight-normal">{{ $t('label.No Tasks have been scheduled for this day') }}.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DraggableItem from "@/components/DragAndDrop/DraggableItem.vue";
import DroppableItem from "@/components/DragAndDrop/DroppableItem.vue";
import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex';
import TaskItem from '../template/TaskItem.vue';
import TimeOffItem from '../template/TimeOffItem.vue';

export default {
    props: [],
    emits: ['closeModal'],
    components: {
        TaskItem,
        TimeOffItem,
        DraggableItem,
        DroppableItem,
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            selectedDate: moment().toDate(),
            sortBy: this.$t('label.crew name (A-Z)'),
            user: JSON.parse(localStorage.getItem('user')),
            dateRange: [moment(this.selectedDate).format('MM/DD/YYYY')],
            companySettings: JSON.parse(localStorage.getItem('company_settings')),
            crewOrUser: 1,
            searchText: '',
            loader: null,
            expandedView: false,
            showModalMoveDateConfirmation: false,
            selectedTask: {},
            crewUpdate: {
                to: null,
                from: null
            },
            filtersList: {
                crews: [],
                users: []
            },
            filterBy: {
                crews: [],
                users: [],
                taskTypes: [],
                taskStatuses: [],
                allCrews: true,
                allUsers: false,
            },
            sortBy: this.$t('label.none'),
            sortList: [
                this.$t('label.none'),
                this.$t('label.crew name (A-Z)'),
                this.$t('label.crew name (Z-A)'),
                this.$t('label.most hours scheduled'),
                this.$t('label.least hours scheduled')
            ],
        }
    },
    computed: {
        ...mapGetters({
            allUsers: 'userData/allUsers',
            allCrews: 'userData/allCrews',
            allTaskStatus: 'taskData/allTaskStatus',
            allTasksByDateRange: 'taskData/allTasksByDateRange',
        }),
        textDate() {
            return moment(this.selectedDate).format('dddd, DD MMMM YYYY')
        },
        todayText() {
            return moment(this.selectedDate).format('ddd, MM/DD')
        },

        noTaskStatusesSelected() {
            // Check to see if no task status is set
            let count = 0;
            this.filterBy.taskStatuses.forEach(status => status.active == true ? count++ : count += 0);
            return count == 0 ? true : false;
        },
        allTaskStatusesSelected() {
            // Check to see if all task status filters are active
            let allSet = true;
            this.filterBy.taskStatuses.forEach((status) => {
                if (!status.active) {
                    allSet = false;
                    return;
                }
            });

            return allSet;
        },
        noTaskTypesSelected() {
            // Check to see if no type status is set
            let count = 0;
            this.filterBy.taskTypes.forEach(status => status.active == true ? count++ : count += 0);
            return count == 0 ? true : false;
        },
        allTaskTypesSelected() {
            // Check to see if all task status filters are active
            let allSet = true;
            this.filterBy.taskTypes.forEach((status) => {
                if (!status.active) {
                    allSet = false;
                    return;
                }
            });

            return allSet;
        },

        filteredTasks() {
            if (this.searchText.length > 0) {
                // Filter the tasks by Search Bar text
                let str = this.searchText.toLowerCase(),
                    filteredTasks = [];

                this.allTasksByDateRange.forEach(task => {
                    // Search name of Task, Job, Customer, and Crew
                    if (task.name.toLowerCase().includes(str) ||
                        task.job.job_name.toLowerCase().includes(str) ||
                        task.task_status.name.toLowerCase().includes(str) ||
                        task.job.customer.name.toLowerCase().includes(str)) {
                        filteredTasks.push(task);
                    } else if (task.assigned_to.length) {
                        // Search Crew 
                        if (task.assigned_to[0].user.crew_assignments.length) {
                            if (task.assigned_to[0].user.crew_assignments[0].crew.name.toLowerCase().includes(str))
                                filteredTasks.push(task);
                        }
                        // Search Users
                        task.assigned_to.forEach(user => {
                            if (user.user.name.toLowerCase().includes(str)) {
                                let filtId = filteredTasks.findIndex((filtTask) => {
                                    filtTask.id == task.id
                                });

                                if (filtId < 0)
                                    filteredTasks.push(task);
                            }
                        });
                    }
                });

                return filteredTasks;
            } else {
                return this.allTasksByDateRange;
            }
        }
    },
    created() {
        // this.loadLkupData();

        // Watch window width
        window.addEventListener("resize", this.setWindowWidth);
        // Listen for task off canvas updates
        bus.$on('taskOffCanvasUpdate', this.reloadTasks);
    },
    mounted() {
        this.loadLkupData();
    },
    destroyed() {
        // Remove Event Listeners
        window.removeEventListener("resize", this.setWindowWidth);
        bus.$off('taskOffCanvasUpdate', this.reloadTasks);
    },
    methods: {
        setWindowWidth() {
            this.windowWidth = window.innerWidth;
        },
        loadLkupData() {
            // Load initial data
            let promises = [],
                relevantDates = {
                    dateSelected: this.selectedDate,
                    toDate: moment(this.selectedDate).add(7, 'd').format('MM/DD/YYYY')
                },
                request_data = {
                    date: [moment().format('MM/DD/YYYY')]
                };

            this.loader = this.$loading.show();
            promises.push(this.getAllTaskTypes());
            promises.push(this.$store.dispatch('taskData/getAllTaskStatus'));
            promises.push(this.$store.dispatch('taskData/getAllTasksByDateRange', request_data));
            promises.push(this.$store.dispatch('userData/getAllUsersForCalendar', relevantDates));
            promises.push(this.$store.dispatch('userData/getAllCrewsForCalendar', relevantDates));
            
            // Hide the load overlay and set filter lists
            Promise.all(promises).then(() => {
                // Set filter list of selectable users                
                this.allUsers.forEach(user => {
                    this.filtersList.users.push({ id: user.id, name: user.name, filteredBy: false });
                });
                // Set filter list of selectable crews
                this.allCrews.forEach(crew => {
                    this.filtersList.crews.push({ id: crew.id, name: crew.name, filteredBy: false });
                });

                // Set list of selectable task statuses
                this.filterBy.taskStatuses = JSON.parse(JSON.stringify(this.allTaskStatus));
                this.setAllTaskStatuses(true);
                // Set filter list of selectable task types
                this.filterBy.taskTypes = JSON.parse(JSON.stringify(this.allTaskTypes));
                this.setAllTaskTypes(true);

                this.loader.hide();
            });
        },
        reloadTasks() {
            let request_data = {
                date: [moment(this.selectedDate).format('MM/DD/YYYY')],
                filters: this.filterBy
            };

            return this.$store.dispatch('taskData/getAllTasksByDateRange', request_data);
        },
        reloadTasksWithOverlay() {
            this.loader = this.$loading.show();
            this.reloadTasks().then(() => this.loader.hide());
        },

        async getAllTaskTypes() {
            return axios.get(`/tasktypes`).then(rsp => {
                this.allTaskTypes = rsp.data.result;
            }).catch(err => console.error(err));
        },
        getAllActiveUsers() {
            return axios.get('/get-all-users').then(response => {
                this.allUsers = response.data.result;
            }).catch(err => console.error(err));
        },
        today() {
            this.selectedDate = moment().toDate();
            this.dateChange();
        },
        prevDate() {
            this.selectedDate = moment(this.selectedDate).subtract(1, "days");
            this.dateChange();
        },
        nextDate() {
            this.selectedDate = moment(this.selectedDate).add(1, "days");
            this.dateChange();
        },
        dateChange() {
            this.reloadTasksWithOverlay();
            this.reloadNavData();
        },

        toggleView(val) {
            if (val == 1)
                this.expandedView = !this.expandedView;
        },
        reloadNavData() {
            // Refresh the left nav assignment numbers for appropriate day
            let relevantDates = {
                dateSelected: this.selectedDate,
                toDate: moment(this.selectedDate).add(7, 'd')
            };

            this.$store.dispatch('userData/getAllUsersForCalendar', relevantDates);
            this.$store.dispatch('userData/getAllCrewsForCalendar', relevantDates);
        },

        updateTaskDates(taskData, fullReload) {
            let requestData = {
                task_data: taskData,
                full_reload: fullReload,
                date_range: [
                    moment(this.selectedDate).format('MM/DD/YYYY'),
                    moment(this.selectedDate).format('MM/DD/YYYY')
                ]
            };

            this.$store.dispatch('taskData/updateTaskDates', requestData).then(() => {
                if (fullReload)
                    this.reloadTasks();

                bus.$emit('banner-succes', { message: this.$t('label.task dates updated successfully') });
            });
        },

        // ----- User/Crew to Task drag and drop methods ----
        setCrewExpView(crew) {
            if (crew.expandedView)
                this.$set(crew, 'expandedView', false);
            else
                this.$set(crew, 'expandedView', true);
        },
        onAssigneeAdd(e, task) {
            let userData = JSON.parse(e.dataTransfer.getData('value')),
                postData = {
                    task_id: task.id,
                    users: []
                };

            if (Array.isArray(userData)) {
                // Users from Crew
                userData.forEach(member => {
                    // if(member.user) {
                    postData.users.push({ id: member.user.id });
                    // }
                });
            } else {
                // Individual User
                postData.users.push({ id: userData.id });
            }

            axios.post(`/add-assignees-to-task`, postData).then(response => {
                task.assigned_to = response.data.result;

                // Show success banner and refresh side nav count
                if (response.data.users_added > 0) {
                    let msg = "";
                    if (response.data.users_added > 1)
                        msg = this.$t('label.task assignees added successfully');
                    else if (response.data.users_added == 1)
                        msg = this.$t('label.task assignee added successfully');

                    bus.$emit('banner-success', { message: msg });
                    this.reloadNavData();
                }
            }).catch(err => console.log(err));
        },
        onHoverOverTimeOff(hoverElmId, popUpElmId) {
            // translate tooltip popup to handle sticky nav position restraints
            let popUpElm = document.getElementById(popUpElmId);
            let hoverElm = document.getElementById(hoverElmId).getBoundingClientRect();
            popUpElm.style.padding = "10px";
            popUpElm.style.top = "" + (hoverElm.top - 30) + "px";
            popUpElm.style.left = "" + ((hoverElm.left + hoverElm.right) / 2) + "px";
        },
        // ---------------------------------------------------

        selectSortBy(sortBy) {
            this.sortBy = sortBy;
            if (sortBy == this.$t('label.none')) {
                // Return to original state
                this.reloadTasksWithOverlay();
            } else {
                // Sort by specified field
                let newTasksList = this.allTasksByDateRange;

                newTasksList.sort((a, b) => {
                    if (sortBy == this.$t('label.crew name (A-Z)')) {
                        if (a.assigned_to.length && b.assigned_to.length) {
                            let nameA = a.assigned_to[0].user.crew_assignments.length ? a.assigned_to[0].user.crew_assignments[0].crew.name : '',
                                nameB = b.assigned_to[0].user.crew_assignments.length ? b.assigned_to[0].user.crew_assignments[0].crew.name : '';
                            if (nameA == nameB) return 0;
                            return (nameA >= nameB) ? 1 : -1;
                        } else if (!a.assigned_to.length) {
                            return -1; // Return unassigned back to the top
                        }
                    } else if (sortBy == this.$t('label.crew name (Z-A)')) {
                        if (a.assigned_to.length && b.assigned_to.length) {
                            let nameA = a.assigned_to[0].user.crew_assignments.length ? a.assigned_to[0].user.crew_assignments[0].crew.name : '',
                                nameB = b.assigned_to[0].user.crew_assignments.length ? b.assigned_to[0].user.crew_assignments[0].crew.name : '';
                            if (nameA == nameB) return 0;
                            return (nameA <= nameB) ? 1 : -1;
                        } else if (!a.assigned_to.length) {
                            return -1; // Return unassigned back to the top
                        }
                    } else if (sortBy == this.$t('label.most hours scheduled')) {
                        if (parseInt(a.time_estimate) == parseInt(b.time_estimate)) return 0;
                        else return (parseInt(a.time_estimate) < parseInt(b.time_estimate)) ? 1 : -1;
                    } else if (sortBy == this.$t('label.least hours scheduled')) {
                        if (parseInt(a.time_estimate) == parseInt(b.time_estimate)) return 0;
                        else return (parseInt(a.time_estimate) > parseInt(b.time_estimate)) ? 1 : -1;
                    }
                });

                this.$store.commit('taskData/setAllTasksByDateRange', newTasksList);
            }
        },

        onAllStatusesClick() {
            this.setAllTaskStatuses(true);
            this.reloadTasksWithOverlay();
        },
        onDeselectAllStatusesClick() {
            this.setAllTaskStatuses(false);
            this.reloadTasksWithOverlay();
        },
        onAllTypesClick() {
            this.setAllTaskTypes(true);
            this.reloadTasksWithOverlay();
        },
        onDeselectAllTypesClick() {
            this.setAllTaskTypes(false);
            this.reloadTasksWithOverlay();
        },

        setAllTaskStatuses(active) {
            // Set all the task statuses to active
            this.filterBy.taskStatuses.forEach((status) => {
                this.$set(status, 'active', active);
            });
        },
        setAllTaskTypes(active) {
            // Set all the task statuses to active
            this.filterBy.taskTypes.forEach((status) => {
                this.$set(status, 'active', active);
            });
        },

        selectStatusFilter(id) {
            let statIdx = this.filterBy.taskStatuses.findIndex((status) => {
                return status.id == id;
            });

            // Toggle active status
            if (statIdx >= 0)
                this.filterBy.taskStatuses[statIdx].active = !this.filterBy.taskStatuses[statIdx].active;

            this.reloadTasksWithOverlay();
        },
        selectTypeFilter(id) {
            let statIdx = this.filterBy.taskTypes.findIndex((status) => {
                return status.id == id;
            });

            // Toggle active status
            if (statIdx >= 0)
                this.filterBy.taskTypes[statIdx].active = !this.filterBy.taskTypes[statIdx].active;

            this.reloadTasksWithOverlay();
        },

        selectUserFilter(filterData) {
            // Filter between all or selected users
            if (filterData == "All") {
                this.filterBy.users = [];
                this.filtersList.users.forEach(u => u.filteredBy = false);
            } else if (filterData.filteredBy) {
                this.filterBy.users.push(filterData.id);
                this.filterBy.allUsers = false;
            } else if (!filterData.filteredBy) {
                let idx = this.filterBy.users.indexOf(filterData.id);
                if (idx > -1) this.filterBy.users.splice(idx, 1);
            }

            // Don't show any tasks if no users are selected
            if (!this.filterBy.allUsers && !this.filterBy.users.length)
                this.$store.commit('taskData/setAllTasksByDateRange', []);
            else
                this.reloadTasksWithOverlay();
        },
        selectCrewFilter(filterData) {
            // Filter between all or selected crews
            if (filterData == "All") {
                this.filterBy.crews = [];
                this.filtersList.crews.forEach(c => c.filteredBy = false);
            } else if (filterData.filteredBy) {
                this.filterBy.crews.push(filterData.id);
                this.filterBy.allCrews = false;
            } else if (!filterData.filteredBy) {
                let idx = this.filterBy.crews.indexOf(filterData.id);
                if (idx > -1) this.filterBy.crews.splice(idx, 1);
            }

            // Don't show any tasks if no crews are selected
            if (!this.filterBy.allCrews && !this.filterBy.crews.length)
                this.$store.commit('taskData/setAllTasksByDateRange', []);
            else
                this.reloadTasksWithOverlay();
        },
        toggleCrewUserFilter(type) {
            if (type == "crews") {
                this.filterBy.users = [];
                this.filterBy.allCrews = true;
                this.filtersList.users.forEach(c => c.filteredBy = false);
            } else if (type == "users") {
                this.filterBy.crews = [];
                this.filterBy.allUsers = true;
                this.filtersList.crews.forEach(c => c.filteredBy = false);
            }

            this.reloadTasksWithOverlay();
        },
        deselectAll() {
            this.filterBy.crews = [];
            this.filterBy.users = [];
            this.filterBy.allCrews = false;
            this.filterBy.allUsers = false;
            this.filtersList.crews.forEach(c => c.filteredBy = false);
            this.filtersList.users.forEach(u => u.filteredBy = false);
            this.$store.commit('taskData/setAllTasksByDateRange', []);
        },
    },
};
</script>

<style scoped>
.calendar-draggable:hover {
    background: #F0F5FA;
}
</style>
