<template>
    <div>
        <footer class="mobile-footer redesign">
            <ul class="footer-navigation">
                <li>
                    <router-link :to="{ name: 'dashboard' }" class="mobile-menu-icon" :class="{ 'active': $route.name == 'dashboard' }">
                        <div class="ri-dashboard-fill mobile-nav-icon"></div>
                        <p>{{ $t('label.dashboard') }}</p>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'calendar' }" class="mobile-menu-icon" :class="{ 'active': $route.name == 'calendar' }">
                        <div class="ri-calendar-line mobile-nav-icon"></div>
                        <p>{{ $t('label.task') }}</p>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'timeSheetReport' }" class="mobile-menu-icon" :class="{ 'active': $route.name == 'timeSheetReport' }">
                        <div class="ri-time-line mobile-nav-icon"></div>
                        <p>{{ $t('label.timesheet') }}</p>
                    </router-link>
                </li>
                <!-- <chat-sidebar-icon class="" @openNotif="clickOpenNotifications"></chat-sidebar-icon> -->
            </ul>
        </footer>

        <!-- Chat Sidebar -->
        <chat-sidebar-component class="" :parent-bus="component_bus">
        </chat-sidebar-component>
    </div>
</template>

<script>
export default {
    props: [],
    components: {},
    data() {
        return {
            component_bus: new Vue(),
        }
    },
    mounted() {
    },
    methods: {
        clickOpenNotifications() {
            this.$bus.$emit('openNotification');
        }
    }
}
</script>