<template>
    <div class="position-relative">
        <!-- page-navbar  -->
        <nav class="navbar navbar-top navbar-expand border-bottom navbar-dark bg-primary mobile-p-0 mobile-header">
            <div class="container-fluid">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <!-- Sidenav toggler -->
                    <ul class="navbar-nav mt-3">
                        <li class="nav-item d-xl-none" @click="toggleNavBar()">
                            <div class="pr-3 sidenav-toggler sidenav-toggler-dark" style="height: 30px; display: flex;">
                                <div class="sidenav-toggler-inner">
                                    <i class="sidenav-toggler-line"></i>
                                    <i class="sidenav-toggler-line"></i>
                                    <i class="sidenav-toggler-line"></i>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- Search box -->
                    <search-component class="pt-2"></search-component>
                    <ul class="navbar-nav align-items-center ml-auto mr-md-0">
                        <!-- Chat disabled for V1-->
                        <span @click="clickOpenNotifications()">
                            <chat-sidebar-icon class="hidden-lg-down"></chat-sidebar-icon>
                        </span>
                        <!-- User Avatar -->
                        <li class="nav-item dropdown">
                            <a class="nav-link pr-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="media align-items-center">
                                    <img class="avatar avatar-sm rounded-circle" alt="Image placeholder" :src="userInfo.picture">
                                    <div class="media-body ml-2 d-none d-lg-block">
                                        <span class="mb-0 text-sm text-white">{{ userInfo.name }}</span>
                                    </div>
                                </div>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                                <div class="dropdown-header noti-title">
                                    <h6 class="text-overflow m-0">{{ $t('Welcome!') }}</h6>
                                </div>

                                <!-- Edit Profile -->
                                <router-link :to="{ name: 'profile' }" class="dropdown-item">
                                    <i class="ri-user-line"></i>
                                    <span>{{ $t('label.my profile') }}</span>
                                </router-link>

                                <!-- User Clock in -->
                                <!-- <div class="pointer dropdown-item" v-if="userInfo.role_id == 2 && hasLoaded == false && company.settings" @click="$emit('clockIn')">
                                    <i class="ri-time-line"></i>
                                    Clock In
                                </div> -->

                                <!-- subscription link -->
                                <router-link v-if="userInfo.role_id == 2" :to="{ name: 'subscriptions' }" class="dropdown-item">
                                    <i class="ri-article-line"></i>
                                    <span>Subscriptions</span>
                                </router-link>

                                <!-- Purchase Plan -->
                                <router-link v-if="user.role_id == 2 && currentSubscriptionIsNotValid" :to="{ name: 'purchase-plan' }" class="dropdown-item">
                                    <i class="ri-article-line"></i>
                                    <span>Purchase Plan</span>
                                </router-link>

                                <!-- Manage user -->
                                <router-link v-if="user.role_id == 3 && (currentSubscription && currentSubscription.is_valid) && currentSubscriptionIsNotValid" :to="{ name: 'subscription-expired' }" class="dropdown-item">
                                    <i class="ri-user-line"></i>
                                    <span>Manage Users</span>
                                </router-link>

                                <!-- Logout -->
                                <div class="dropdown-divider"></div>
                                <a href="javascript:void(0)" class="dropdown-item" @click="logout()">
                                    <i class="ri-logout-box-line"></i>
                                    <span>{{ $t('label.logout') }}</span>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <!-- Chat Sidebar -->
        <chat-sidebar-component class="hidden-lg-down" :parent-bus="component_bus">
        </chat-sidebar-component>

    </div>
</template>

<style></style>

<script>
import bus from '@/utils/event-bus';
import { mapGetters, mapState } from 'vuex';

export default {
    props: [],
    components: {},
    data() {
        return {
            component_bus: new Vue(),
            userInfo: JSON.parse(localStorage.getItem('user')),
            viewClockInModal: false,
            viewClockOutModal: false
        }
    },
    computed: {
        ...mapState({
            currentSubscription: state => state.Plan.currentSubscription,
        }),
        ...mapGetters({
            user: 'Auth/currentUser',
            currentSubscriptionIsNotValid: 'Plan/currentSubscriptionIsNotValid',
            company: 'company',
            // coordinates: 'coordinates', 
            userClockin: 'time/userClockin',
            hasLoaded: 'time/hasLoaded',
            isEdit: 'time/isEdit',
        }),
    },
    created() {
        bus.$on("ProfileEditUpdate", this.updateUserInfo);
        bus.$on("adminUsersUpdateCurrUser", this.updateUserInfo);
    },
    destroyed() {
        bus.$off("ProfileEditUpdate", this.updateUserInfo);
        bus.$off("adminUsersUpdateCurrUser", this.updateUserInfo);
    },
    watch: {
        user(user) {
            this.initializeZohoChat()
        }
    },
    mounted() {
        this.initializeZohoChat();
    },
    methods: {
        initializeZohoChat() {
            if (window.$zoho && this.user) {
                if (this.user && this.user.role_id == 2) {
                    $zoho.salesiq.reset()
                    $zoho.salesiq.visitor.name(this.user.name);
                    $zoho.salesiq.visitor.email(this.user.email)
                } else {
                    $zoho.salesiq.floatbutton.visible("hide");
                }
            }
        },
        updateUserInfo() {
            this.userInfo = JSON.parse(localStorage.getItem('user'));
        },
        logout() {
            localStorage.clear();
            if (window.$zoho && window.$ZSIQWidget) {
                window.$zoho.salesiq.reset()
            }
            window.location = '/';
            // document.getElementById('logout-form').submit();
        },
        clickOpenNotifications() {
            this.$bus.$emit('openNotification');
        },
        toggleNavBar() {
            bus.$emit('toggleSideNav')
        },
        showClockIn() {
            this.$modal.show('clockIn')
        },
    }
}
</script>