import Vue from 'vue'

// Deterime the need to display a date range or single date for tasks an maybe other data sets
// Uses default date format of Day Month date but can be overridden by passing in a moment format
Vue.prototype.$spDisplayDateRange = function (startDate, endDate, format) {
    var dtFormat = 'ddd MMM Do'
    if (format) {
        dtFormat = format
    }
    if (moment(startDate).format('YYYY-MM-DD') == moment(endDate).format('YYYY-MM-DD')) {
        return Vue.filter('formatDateNoTz')(startDate, dtFormat)
    } else {
        return Vue.filter('formatDateNoTz')(startDate, dtFormat) + ' - ' + Vue.filter('formatDateNoTz')(endDate, dtFormat)
    }
}

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}