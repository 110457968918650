<template>
	<div class="col-xl-12 order-xl-1 mobile-p-0">
		<div class="card">
			<div class="card-header">
				<div class="row align-items-center justify-content-between">
					<div>
						<h3 class="mb-0 d-flex align-items-center"><i class="ri-bill-line heading-title"></i> Invoices</h3>
					</div>
					<div>
						<button type="button" class="btn btn-sm btn-default m-0" data-toggle="modal" data-target="#invoiceAddEditModal" @click="addModal">
							{{ $t('label.create an invoice') }}
						</button>
					</div>
				</div>
			</div>
			<div v-if="allinvoices.length == 0">
				<div class="card-body m-0 py-0">
					<div class="bg-white text-center row align-items-center m-0">
						<i class="ri-bill-line ni-8x w-100 color-gray-400"></i>
						<p class="w-100 font-weight-normal">{{ $t('label.no invoices have been added to this job') }}</p>
					</div>
				</div>
			</div>
			<div v-if="allinvoices.length > 0" class="table-responsive pb-4" style="overflow-x: visible">
				<table class="table table-flush table-sm" id="datatable-allinvoiceslist">
					<thead class="thead-light">
						<tr>
							<th scope="col" width="10%">{{ $t('label.invoice #') }}</th>
							<th scope="col" width="10%">QUICKBOOKS INVOICE NO.</th>
							<th scope="col" width="10%">Job Name</th>
							<th scope="col" width="10%">{{ $t('label.due date') }}</th>
							<th scope="col">{{ $t('label.description') }}</th>
							<th scope="col" width="10%">{{ $t('label.invoice total') }}</th>
							<th scope="col" width="10%">{{ $t('label.balance') }}</th>
							<th scope="col" width="10%">{{ $t('label.status') }}</th>
							<th scope="col" width="5%"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(data, index) in paginatedInvoices" :key="index">
							<td>
								<a href="#" class="" data-toggle="modal" data-target="#invoiceViewModal" @click="invoiceViewModal(data)">
									{{ data.invoice_number_concat }}
								</a>
							</td>
							<td><a v-if="data.qb_id" target="_blank" :href="qb_url + 'app/invoice?txnId=' + data.qb_id">{{ data.quickbooks_invoice_no }}</a></td>
							<td>{{ data.job.job_name }}</td>
							<td :style="{ background: bgColors(data) }">{{ moment(data.due_date).format('MM/DD/YYYY') }}</td>
							<td>{{ data.invoice_item_list_concat }}</td>
							<td>${{ parseFloat(data.total).toFixed(2) | formatThousands }}</td>
							<td>${{ parseFloat(data.balance).toFixed(2) | formatThousands }}</td>
							<td>
								<span class="badge badge-dot">
									<i class="bg-success" :style="{ 'background-color': data.invoice_status.color + ' !important' }"></i>
									{{ data.invoice_status.name }}
								</span>
							</td>
							<td class="text-right">
								<div class="dropdown">
									<a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<i class="fas fa-ellipsis-v"></i>
									</a>
									<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
										<a class="dropdown-item text-default pointer" data-toggle="modal" data-target="#invoiceViewModal" @click="invoiceViewModal(data)">
											<i class="ri-eye-line"></i> {{ $t('label.view invoice') }}
										</a>
										<a v-if="!data.mark_as_paid && data.balance != '0.00' && data.invoice_status.name == 'Sent'" class="dropdown-item text-default pointer" @click="markAsPaid(data, 1)">
											<i class="ri-check-line"></i> {{ $t('label.mark as paid') }}
										</a>
										<a v-if="data.mark_as_paid" class="dropdown-item text-default pointer" @click="markAsPaid(data, 0)">
											<i class="ri-check-line"></i> {{ $t('label.mark as unpaid') }}
										</a>
										<a v-if="!data.mark_as_paid && data.balance != '0.00' && data.invoice_status.name == 'Sent'" class="dropdown-item text-default pointer" @click="processPayment(data)">
											<i class="ri-bank-card-line"></i> {{ $t('label.process payment') }}
										</a>
										<!-- Send Invoice -->
										<a v-if="data.invoice_status.name == 'Draft'" class="dropdown-item text-default pointer" data-toggle="modal" data-target="#invoiceSendModal" @update:parent="fromChild = $event" @click="invoiceSendModal(data, 0)">
											<i class="ri-mail-send-line"></i> {{ $t('label.send invoice') }}
										</a>
										<a :href="'/invoice-download/' + data.id" target="_blank" class="dropdown-item text-default pointer">
											<i class="ri-download-line"></i> {{ $t('label.download invoice') }}
										</a>
										<a :href="'/invoice-print/' + data.id" target="_blank" class="dropdown-item text-default pointer">
											<i class="ri-printer-line"></i> {{ $t('label.print invoice') }}
										</a>
										<a v-if="data.invoice_status.name == 'Sent'" class="dropdown-item text-default pointer" data-toggle="modal" data-target="#invoiceSendModal" @update:parent="fromChild = $event" @click="invoiceSendModal(data, 1)">
											<i class="ri-mail-send-line"></i> {{ $t('label.cancel invoice') }}
										</a>
										<div v-if="data.invoice_status.name == 'Draft'">
											<a class="dropdown-item text-default pointer" data-toggle="modal" data-target="#invoiceAddEditModal" @click="editInvoiceModal(data)">
												<i class="ri-pencil-line"></i> {{ $t('label.edit invoice') }}
											</a>
											<a class="dropdown-item text-danger pointer" @click="deleteNotify(data.id)">
												<i class="ri-delete-bin-line"></i> {{ $t('label.delete invoice') }}
											</a>
										</div>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
					<thead class="ttotal-sub">
						<tr>
							<th scope="col" colspan="4" class="text-right">{{ $t('label.totals') }}</th>
							<th scope="col">
								<h4 class="mb-0">${{ totalBalance | formatThousands }}</h4>
							</th>
							<th scope="col">
								<h4 class="mb-0">${{ totalInvoice | formatThousands }}</h4>
							</th>
							<th scope="col" colspan="3"></th>
						</tr>
					</thead>
				</table>
				<table-pagination v-model="paginatedInvoices" :all-rows="filteredInvoices" :perPage="perPage">
				</table-pagination>
			</div>
		</div>
		<!-- Invoice Modal -->
		<modal class="pb-6" name="invoiceAddEditModal" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
			<ModalInvoice v-if="viewinvoiceAddEditModal" :customerid="customerid" :job="job" :invoice-data="invoiceData" :source="source" :source2="source3" @reloadInvoices="getAllInvoices" @cancelFnc="hideModal" @customChange="handleCustomChange"></ModalInvoice>
		</modal>

		<!-- Invoice View Modal -->
		<modal class="pb-6" name="invoiceViewModal" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
			<ModalInvoiceView :job="job" :invoice-data="invoiceData" @reload="getAllInvoices" @cancelFnc="closeInvoiceView"></ModalInvoiceView>
		</modal>

		<!-- Invoice Send Modal -->
		<modal class="pb-6" name="invoiceSendModal" height="auto" width="100%" :max-width="900" :scrollable="true" :reset="true" :adaptive="true">
			<ModalInvoiceSend :job="job" :invoice-data="invoiceData" @reloadInvoices="getAllInvoices" @cancelFnc="$modal.hide('invoiceSendModal')"></ModalInvoiceSend>
		</modal>

		<ModalDelete :newid="newid" :deleteMethod="deleteInvoice" :deleteModalText="deleteModalText"></ModalDelete>
		<!-- Payment Modal -->
		<modal class="pb-6" name="paymentModal" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
			<ModalPayment :job="job" :curr-id="currId" :invoice-data="invoiceData" source="invoice" :source2="source3" @reloadPayments="getAllInvoices" @cancelFnc="$modal.hide('paymentModal')"></ModalPayment>
		</modal>
	</div>
</template>

<script>
import TablePagination from '@/components/TablePagination';
import bus from '@/utils/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ModalDelete from '../components/ModalDelete.vue';
import ModalInvoice from '../components/ModalInvoice.vue';
import ModalInvoiceSend from '../components/ModalInvoiceSend.vue';
import ModalInvoiceView from '../components/ModalInvoiceView.vue';
import ModalPayment from '../components/ModalPayment.vue';

export default {
	props: ['customerid', 'jobid', 'job1'],
	components: {
		Loading,
		ModalInvoice,
		ModalInvoiceView,
		ModalInvoiceSend,
		ModalDelete,
		ModalPayment,
		TablePagination,
	},
	data() {
		return {
			qb_url: process.env.VUE_APP_QUICKBOOKS_URL,
			companySettings: JSON.parse(localStorage.getItem('company_settings')),
			allinvoices: [],
			openModal: false,
			source: null,
			delete: null,
			newid: 'DeleteInvoice',
			deleteModalText: {
				title: 'ARE YOU SURE YOU WANT TO DELETE THIS INVOICE?',
				button: 'Yes, Delete'
			},
			isLoading: false,
			totalInvoice: 0,
			totalBalance: 0,
			job: null,
			invoiceData: [],
			recentInvoiceData: [],
			fromChild: false,
			invoiceStatuses: [],
			isCancelled: 0,
			customerId: null,
			jobId: null,
			currId: null,
			source3: null,
			perPage: 25,
			paginatedInvoices: [],
			currData: null,
			viewinvoiceAddEditModal: false
		}
	},
	created() {
		this.getAllInvoices();
		bus.$on('ModalInvoiceSave', this.getAllInvoices);
	},
	destroyed() {
		// Remove event listeners
		bus.$off('ModalInvoiceSave', this.getAllInvoices);
	},
	mounted() {

		bus.$on('deleteInvoice' + this.newid, () => {
			this.deleteInvoice();
		});
		bus.$on('getallinvoices', () => {
			this.getAllInvoices();
		});
	},
	computed: {
		lastInvoiceCreated() {
			return this.allinvoices[this.allinvoices.length - 1];
		},
		filteredInvoices() {
			return this.allinvoices;
		}
	},
	methods: {
		getAllInvoices() {
			if (this.jobid) {
				this.currId = this.jobid;
				this.source3 = 'job';
				this.job = this.job1;
			} else {
				this.currId = this.customerid;
				this.source3 = 'customer'
			}
			axios.get('/get-all-invoices/' + this.currId + '/' + this.source3)
				.then(response => {
					this.allinvoices = response.data.result;
					this.totalInvoice = response.data.totalInvoice;
					this.totalBalance = response.data.totalBalance;
					this.recentInvoiceData = this.allinvoices[this.allinvoices.length - 1];

				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		bgColors(data) {
			var bgColor = '';
			var now = moment();
			var today = now.format('YYYY-MM-DD');
			var due = moment(data.due_date, 'YYYY-MM-DD');
			var diff = due.diff(today, 'days');
			if (diff <= 5 && diff > 0 && data.mark_as_paid == 0 && data.invoice_status.name != 'Cancelled' && data.invoice_status.name != 'Paid') {
				bgColor = '#FFFF00';

				return bgColor

			} else if (diff <= 0 && data.mark_as_paid == 0 && data.invoice_status.name != 'Cancelled' && data.invoice_status.name != 'Paid') {
				bgColor = '#FF0000';

				return bgColor;
			} else {
				bgColor = '00FFFFFF';

				return bgColor;
			}
		},
		addModal() {
			this.source = 'addInvoice';
			this.invoiceData = [];
			this.$modal.show('invoiceAddEditModal');
			this.viewinvoiceAddEditModal = true;
		},
		hideModal(value) {
			this.invoiceData = value;
			this.handleCustomChange();
			this.$modal.hide('invoiceAddEditModal');
			this.viewinvoiceAddEditModal = false;

			if (!this.jobid)
				this.job = null;
		},
		handleCustomChange(value) {
			if (value == 1) {
				this.invoiceSendModal(this.invoiceData);
			}
		},
		invoiceViewModal(data) {
			this.currData = data;
			this.invoiceData = { data: data };
			this.$modal.show('invoiceViewModal');
		},
		closeInvoiceView(value) {
			this.$modal.hide('invoiceViewModal');

			if (value == 'editSelected') {
				this.source = 'editInvoice';
				this.invoiceData = {
					data: this.currData
				};
				this.$modal.show('invoiceAddEditModal');
				this.viewinvoiceAddEditModal = true;
			} else if (value == 'invoiceSend') {
				this.$modal.show('invoiceSendModal');
			} else if (value == 'invoicePayment') {
				this.$modal.show('paymentModal');
			}
		},
		editInvoiceModal(data) {
			this.source = 'editInvoice';
			this.invoiceData = {
				data: data
			};
			this.$modal.show('invoiceAddEditModal');
			this.viewinvoiceAddEditModal = true;

			if (!this.jobid)
				this.job = data.job;
		},
		invoiceSendModal(data, cancel) {
			this.invoiceData = {
				data: data,
				cancel: cancel,
			};
			this.$modal.show('invoiceSendModal');
		},
		deleteNotify(delete_id) {
			this.delete_id = delete_id;
			$("#deleteModal" + this.newid).modal('show');
		},
		deleteInvoice() {
			let data = {
				id: this.delete_id
			}
			axios.post('/invoice-delete', data).then(() => {
				this.getAllInvoices();
				bus.$emit('InvoicesCompInvDlt', data.id);
				bus.$emit('banner-success', {
					message: this.$t('label.invoice deleted successfully') + '.'
				});
			}).catch(err => {
				//console.log('list carriers error:', error)
			});
		},
		markAsPaid(data, paid) {
			this.isLoading = true;
			let request = {
				id: data.id,
				paid: paid
			}
			axios.post('/invoice/mark-as-paid', request)
				.then(response => {
					this.getAllInvoices();
					this.isLoading = false;
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		processPayment(data) {
			this.invoiceData = data;
			this.$modal.show('paymentModal');
		},
		runDataTable() {
			$('#datatable-allinvoiceslist').ready(function () {
				$('#datatable-allinvoiceslist').DataTable({
					keys: !0,
					select: {
						style: "single"
					},
					order: [],
					pageLength: 25,
					"searching": false,
					"lengthChange": true,
					"bDestroy": true,
					"sDom": 'Rfrtilp',
					language: {
						paginate: {
							previous: "<i class='fas fa-angle-left'>",
							next: "<i class='fas fa-angle-right'>"
						},
						lengthMenu: "Items per page _MENU_",
					},
				});
			});
		},
	}
}
</script>