<template>
    <modal name="closeConfirmModal" @closed="closeModal()" :style="{ 'z-index': zIndex }" :min-height="200" :scrollable="true" classes="bg-gradient-danger text-white rounded" height="auto" :reset="true">
        <div class="container-fluid">
            <div class="modal-header">
                <h6 class="modal-title text-white">{{ $t('label.confirm close job tasks') }}</h6>
                <button type="button" class="close" @click="closeModal()">
                    <span>×</span>
                </button>
            </div>

            <div class="modal-body text-center">
                <i class="ri-error-warning-fill ri-2x"></i>
                <p>{{ $t('label.job close tasks warning') }}</p>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-link text-white" @click="closeModal()">{{ $t('label.close') }}</button>
                <button type="button" class="btn btn-white ml-auto" @click="closeJob()">{{ $t('label.confirm proceed') }}</button>
            </div>
        </div>
    </modal>
</template>

<script>
import bus from '@/utils/event-bus';

export default {
    props: ['customConfirmMethod', 'jobId', 'zIndex'],
    emits: ['on-confirm-method', 'onSave'],
    components: {},
    data() {
        return {}
    },
    mounted() {
        console.log("in closeConfirmModal", this.jobId);
        this.$modal.show('closeConfirmModal');
    },
    methods: {
        closeJob() {
            // Use provided method on closeJob if one is given
            if (this.customConfirmMethod) {
                this.$modal.hide('closeConfirmModal');
                this.$emit('on-confirm-method');

                // Else change only the Job Status to closed
            } else {
                this.$modal.hide('closeConfirmModal');

                axios.post("/close-job", {
                    'job_id': this.jobId
                }).then(response => {
                    this.$emit('onSave');
                    bus.$emit('banner-success', {
                        message: $t('label.job closed successfully') + '!'
                    });

                    this.closeModal();
                }).catch(err => console.log(err));
            }
        },

        closeModal() {
            this.$emit('on-close-modal');
        }
    }
}
</script>
