<template>
    <div class="container-fluid">
        <!-- Empty Rows Pagination View -->
        <!-- <div v-if="numRows < 1" class="pt-4 row">
            <div class="col-6 pl-4">
                <span class="text-sm">
                    Showing 0 to 0 of 0 entries
                </span>
            </div>
            <div class="col-6 pr-4">
                <b-pagination
                    v-model="currentPage"
                    :align="'right'"
                    :class="'z-index-1'"
                    :next-class="'z-index-1'" 
                    :prev-class="'z-index-1'" 
                    :first-class="'z-index-1'"
                    :hide-goto-end-buttons="true">
                        <template v-slot:next-text>
                            <i class="fa fa-angle-right"></i>
                        </template>
                        <template v-slot:prev-text>
                            <i class="fa fa-angle-left"></i>
                        </template>
                </b-pagination>
            </div>
        </div> -->
        
        <!-- Regular View -->
        <div class="pt-4 row">
            <!-- Pages Information -->
            <div class="col-6 d-flex">
                <div class="mr-auto text-align-center text-sm">
                    Showing {{ this.showingCurrPage }} to {{ this.showingToPage }} of {{ this.totalRecords }} entries
                </div>
            </div>

            <!-- Page Navigation -->
            <div class="col-6 d-flex">
                <div class="ml-auto">
                    <b-pagination 
                        align="center" 
                        next-class="z-index-1" 
                        prev-class="z-index-1"
                        page-class="z-index-1"
                        :per-page="perPage" 
                        :total-rows="totalRecords"  
                        :hide-goto-end-buttons="true" 
                        :limit="buttonLimit" 
                        :size="buttonSize"
                        v-model="currentPage">
                            <template v-slot:next-text>
                                <i class="fa fa-angle-right"></i>
                            </template>
                            <template v-slot:prev-text>
                                <i class="fa fa-angle-left"></i>
                            </template>
                    </b-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['perPage', 'totalRecords', 'btnLimit', 'btnSize', 'numRows'],
    emits: ['onChange'],
    data() {
        return {
            currentPage: 1,
            buttonLimit: (this.btnLimit) ? this.btnLimit : 5,         // Set the number of buttons default is 5 
            buttonSize: (this.btnSize) ? `${this.btnSize}`:'md',    // Set the size of the buttons default is 'md' but also accepts 'sm', 'lg'
        }
    },
    watch: {
        currentPage() {
            this.$emit('input', this.currentPage);
            this.$emit('onChange');
        },
        totalRecords() {
            console.log(this.totalRecords);
        }
    },
    computed: {
        showingCurrPage() {
            if (this.totalRecords > 0)
                return (1 + (this.perPage * (this.currentPage - 1)));
            else 
                return 0;
        },
        showingToPage() {
            let toVal = this.currentPage * this.perPage;
            if (toVal >= this.totalRecords)
                return this.totalRecords;
            else
                return toVal;
        },
    },
    mounted() {},
    methods: {}
}
</script>