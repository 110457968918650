<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>
        
        <div class="container-fluid pb-5 sp-mobile-mt-50">
            <!-- HEADER -->
            <div class="d-flex">
                <!-- Breadcrumbs -->
                <breadcrumbs :title="$t('label.messages')">
                    <!-- Breakcrumb slots -->
                    <li class="breadcrumb-item">
						{{ $t('label.admin') }}
					</li>
					<li class="breadcrumb-item">
						{{ $t('label.tools') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.messages') }}
                    </li>
                </breadcrumbs>

                <!-- Buttons -->
                <div class="ml-auto py-4 align-items-center">
                    <button type="button" class="btn btn-neutral" data-toggle="modal" data-target="#selectMsgTypeModal">
						{{ $t('label.create message') }}
					</button>
                </div>
            </div>
            
            <message-list-component></message-list-component>
            <message-type-selection-component></message-type-selection-component>            
        </div>
    </div>
</template>

<script>
export default {
    props: [],
    components: {},
    data() {
        return {
            loader: null
        }
    },
    mounted() {
        this.loader = this.$loading.show();
        setTimeout(() => {
            this.loader.hide();
        }, 500);
    },
    methods: {
    
    }
}
</script>