<template>
    <div v-if="messages.notif" class="msg-modal">
        <!-- Prompt -->
        <div v-if="messages.notif.type == 'prompt'" class="modal fade show modal-prompt" id="messageNotifModal" tabindex="-1" role="dialog" aria-labelledby="messageNotifModalTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div>
                            <div style="width: 50%; margin: 0 auto">
                                <p style="font-size: xxx-large; font-weight: bold">{{ messages.notif.title }}</p>
                                <div v-if="messages.notif.image" style="background-position: 50% 50%;background-size: cover;background-repeat: no-repeat;margin: 0 auto;">
                                    <img style="width: 100%" :src="messages.notif.image">
                                </div>
                                <div class="py-3">
                                    <p style="font-size: large;">{{ messages.notif.body }}</p>
                                </div>
                                <div v-if="messages.notif.btn_action == 'link' && messages.notif.btn_label != '' && messages.notif.btn_label != null" class="text-center">
                                    <a @click="readMessage" type="button" class="btn btn-primary text-white" :href="messages.notif.url" target="_blank">{{ messages.notif.btn_label }}</a>
                                </div>
                                <div v-if="messages.notif.btn_action  == 'close' && messages.notif.btn_label != '' && messages.notif.btn_label != null" class="text-center">
                                    <button @click="readMessage" type="button" data-dismiss="modal" class="btn btn-primary text-white">{{ messages.notif.btn_label }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Popup -->
        <div v-if="messages.notif.type == 'popup'" class="modal fade show modal-popup modal-popup-preview" id="messageNotifModal" tabindex="-1" role="dialog" aria-labelledby="messageNotifModalTitle" aria-hidden="true">
            <div class="modal-dialog d-flex justify-content-center align-items-center h-100 my-0 mx-auto" role="document" style="max-width: 700px">
                <div class="modal-content">
                    <div class="modal-body">
                        <div>
                            <div class="body">
                                <p style="font-size: xxx-large; font-weight: bold">{{ messages.notif.title }}</p>
                                <div v-if="messages.notif.image" style="background-position: 50% 50%;background-size: cover;background-repeat: no-repeat;margin: 0 auto;">
                                    <img style="width: 100%" :src="messages.notif.image">
                                </div>
                                <div class="py-3">
                                    <p style="font-size: large;">{{ messages.notif.body }}</p>
                                </div>
                                <div v-if="messages.notif.btn_action == 'link' && messages.notif.btn_label != '' && messages.notif.btn_label != null" class="text-center">
                                    <a @click="readMessage" type="button" class="btn btn-primary text-white" :href="messages.notif.url" target="_blank">{{ messages.notif.btn_label }}</a>
                                </div>
                                <div v-if="messages.notif.btn_action == 'close' && messages.notif.btn_label != '' && messages.notif.btn_label != null" class="text-center">
                                    <button @click="readMessage" type="button" class="btn btn-primary text-white" data-dismiss="modal">{{ messages.notif.btn_label }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Snippet -->
        <div v-if="messages.notif.type == 'snippet'" class="modal fade show modal-popup modal-snippet" id="messageNotifModal" tabindex="-1" role="dialog" aria-labelledby="messageNotifModalTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div>
                            <div class="body modal-box-shadow">
                                <div class="">
                                    <h2>{{ messages.notif.title }}</h2>
                                    <p style="font-size: large;">{{ messages.notif.body }}</p>
                                </div>
                                <div v-if="messages.notif.btn_action == 'link' && messages.notif.btn_label != '' && messages.notif.btn_label != null" class="text-center">
                                    <a @click="readMessage" type="button" class="btn btn-primary btn-sm" :href="messages.notif.url" target="_blank">{{ messages.notif.btn_label }}</a>
                                </div>
                                <div v-if="messages.notif.btn_action == 'close' && messages.notif.btn_label != '' && messages.notif.btn_label != null" class="text-center">
                                    <button @click="readMessage" type="button" class="btn btn-primary btn-sm" data-dismiss="modal">{{ messages.notif.btn_label }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Banner -->
        <div v-if="messages.notif.type == 'banner'" class="modal fade show modal-prompt modal-banner" id="messageNotifModal" tabindex="-1" role="dialog" aria-labelledby="messageNotifModalTitle" aria-hidden="true">
            <div class="modal-dialog" role="document" style="width: 100%; background: transparent">
                <div class="modal-content">
                    <div class="modal-body">
                        <div>
                            <div class="alert alert-notif bg-white d-flex justify-content-between align-items-center w-100 modal-box-shadow">
                                <span class="alert-text pr-5"><strong class="mx-2">{{ messages.notif.title }}</strong> {{ messages.notif.body }} </span>
                                <div v-if="messages.notif.btn_action == 'link' && messages.notif.btn_label != '' && messages.notif.btn_label != null" class="text-center">
                                    <a @click="readMessage" type="button" class="btn btn-primary btn-sm" :href="messages.notif.url" target="_blank">{{ messages.notif.btn_label }}</a>
                                </div>
                                <div v-if="messages.notif.btn_action == 'close' && messages.notif.btn_label != '' && messages.notif.btn_label != null" class="text-center">
                                    <button @click="readMessage" type="button" class="btn btn-primary btn-sm" data-dismiss="modal">{{ messages.notif.btn_label }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
		data() {
			return {
			}
        },
        computed: {
            ...mapGetters(['messages']),
        },
        mounted() {
            let checkExist = setInterval(function(messages) {
                if ($('#messageNotifModal').length && messages) {
                   $('#messageNotifModal').modal('show');
                   $('#messageNotifModal').modal({backdrop: 'static', keyboard: false});

                   if (messages.notif.type == 'banner' || messages.notif.type == 'snippet') {
                        $('.modal-backdrop').addClass(' backdrop-remove');
                        $('.modal-backdrop').removeClass('backdrop-add');
                    } else {
                        $('.modal-backdrop').addClass(' backdrop-add');
                        $('.modal-backdrop').removeClass('backdrop-remove');
                    }
                   clearInterval(checkExist);
                }
             }, 1, this.messages);
        },
		methods: {
            readMessage() {
                this.$store.dispatch('readMessage', this.messages.notif.recipient.id);
                $('#messageNotifModal').modal('hide');
            },
		}
    }
</script>
