/*=========================================================================================
  File Name: global-components.js
  Description: Here you can register components globally
==========================================================================================*/

import Vue from 'vue';

import CalendarComponent from '@/components/CalendarComponent.vue';
import CustomFieldComponent from '@/components/CustomFieldComponent.vue';
import CustomFieldTypeComponent from '@/components/CustomFieldTypeComponent.vue';
import JobAddComponent from '@/components/JobAddComponent.vue';
import JobEditComponent from '@/components/JobEditComponent.vue';
import JobFilesComponent from '@/components/JobFilesComponent';
import JobFilesTaskDetailsComponent from '@/components/JobFilesTaskDetailsComponent.vue';
import JobMaterialsComponent from '@/components/JobMaterialsComponent.vue';
import JobNotesComponent from '@/components/JobNotesComponent.vue';
import JobsComponent from '@/components/JobsComponent.vue';
import MonthComponent from '@/components/MonthComponent.vue';
import SearchComponent from '@/components/SearchComponent.vue';
import SearchResultsComponent from '@/components/SearchResultsComponent.vue';
import TaskComponent from '@/components/TaskComponent.vue';
import BreadcrumbsComponent from './components/BreadcrumbsComponent.vue';
import SidebarNavComponent from './components/SidebarNavComponent.vue';

import Crews from '@/components/Admin/Crews/Crews.vue';
import ContactsComponent from '@/components/ContactsComponent.vue';
import CustomerDetailComponent from '@/components/CustomerDetailComponent.vue';
import CustomerInvoicesComponent from '@/components/CustomerInvoicesComponent.vue';
import CustomerJobsComponent from '@/components/CustomerJobsComponent.vue';
import CustomerNotesComponent from '@/components/CustomerNotesComponent.vue';
import CustomersComponent from '@/components/CustomersComponent.vue';
import MobileFooter from '@/components/MobileFooter.vue';
import ReportsComponent from '@/components/ReportsComponent.vue';
import TaskNotesComponent from '@/components/TaskNotesComponent.vue';
import UserInfoLinkComponent from '@/components/UserInfoLinkComponent.vue';
// import JobAddComponent from '@/components/JobAddComponent.vue'
import JobTemplateEdit from '@/components/Admin/JobTemplates/JobTemplateEdit.vue';
import JobTemplates from '@/components/Admin/JobTemplates/JobTemplates.vue';
import JobTypes from '@/components/Admin/JobTypes/JobTypes.vue';
import DashboardComponent from '@/components/DashboardComponent.vue';
import MessageFormComponent from '@/components/MessageFormComponent.vue';
import MessageListComponent from '@/components/MessageListComponent.vue';
import MessageNotifComponent from '@/components/MessageNotifComponent.vue';
import MessagePreviewComponent from '@/components/MessagePreviewComponent.vue';
import MessageStatsComponent from '@/components/MessageStatsComponent.vue';
import MessageTypeSelectionComponent from '@/components/MessageTypeSelectionComponent.vue';
import ModalConfirmJobClose from '@/components/ModalConfirmJobClose.vue';
import ModalDelete from '@/components/ModalDelete.vue';
import ModalGettingStarted from '@/components/ModalGettingStarted.vue';
import ModalJobEdit from '@/components/ModalJobEdit.vue';
import ModalJobNotesDetails from '@/components/ModalJobNotesDetails.vue';
import NoCallNoShowReportDatatableComponent from '@/components/NoCallNoShowReportDatatableComponent.vue';
import TaskTimeComponent from '@/components/TaskTimeComponent.vue';
import TaskTypes from '@/components/TaskTypes/TaskTypes.vue';
import TimeOffReportComponent from '@/components/TimeOffReportComponent.vue';
import TimeOffReportDatatableComponent from '@/components/TimeOffReportDatatableComponent.vue';
import TimesheetProximityComponent from '@/components/TimesheetProximityComponent.vue';
import TimesheetReportProximityComponent from '@/components/TimesheetReportProximityComponent.vue';
import UserClockin from '@/components/UserClockin.vue';
import WidgetStat from '@/components/WidgetStat.vue';
import WidgetTable from '@/components/WidgetTable.vue';

// Asset Management
import AssetSettings from '@/components/Admin/Assets/AssetSettings.vue';
import ArchiveAsset from '@/components/asset-management/ArchiveAsset.vue';
import AssetComments from '@/components/asset-management/AssetComments.vue';
import AssetFiles from '@/components/asset-management/AssetFiles.vue';
import AssetItemDetails from '@/components/asset-management/AssetItemDetails.vue';
import AssetItemLifecycle from '@/components/asset-management/AssetItemLifecycle.vue';
import AssetItemShow from '@/components/asset-management/AssetItemShow.vue';
import AssetMaintenanceEntries from '@/components/asset-management/AssetMaintenanceEntries.vue';
import AssetManItemList from '@/components/asset-management/AssetManItemList.vue';
import AssetManCreateItemMain from '@/components/asset-management/AssetManItems/AssetManCreateItemMain.vue';
import AssetManEditItemMain from '@/components/asset-management/AssetManItems/AssetManEditItemMain.vue';
import AssetManTopFilter from '@/components/asset-management/AssetManTopFilter.vue';
import AssetOperatorHistory from '@/components/asset-management/AssetOperatorHistory.vue';
import AssetShowLeftNav from '@/components/asset-management/AssetShowLeftNav.vue';
import VehicleOdometerHistoryComponent from '@/components/asset-management/vehicles/VehicleOdometerHistoryComponent.vue';
import VehiclesAddComponent from '@/components/asset-management/vehicles/VehiclesAddComponent.vue';
import VehiclesComponent from '@/components/asset-management/vehicles/VehiclesComponent.vue';
import VehiclesShow from '@/components/asset-management/vehicles/VehiclesShow.vue';

//INVOICES REPORT
import InvoiceComponent from '@/components/InvoicesComponent.vue';
// import InvoiceItemsComponent from '@/components/InvoiceItemsComponent.vue';
// import LeadsSettingsComponent from '@/components/LeadsSettingsComponent.vue';
import ModalInvoice from '@/components/ModalInvoice.vue';
import ModalInvoiceSend from '@/components/ModalInvoiceSend.vue';
import ModalInvoiceView from '@/components/ModalInvoiceView.vue';
import ModalPayment from '@/components/ModalPayment.vue';
import ModalPaymentSend from '@/components/ModalPaymentSend.vue';
import ModalPaymentView from '@/components/ModalPaymentView.vue';
import ModalReachedUserLimitComponent from '@/components/ModalReachedUserLimitComponent.vue';
import PaymentComponent from '@/components/PaymentComponent.vue';
import ReportInvoiceDatatableComponent from '@/components/ReportInvoiceDatatableComponent.vue';
// import SettingsComponent from '@/components/SettingsComponent.vue';

// Custom
import Banner from '@/components/Banner.vue';
import ModalClockout from '@/components/ModalClockout.vue';

//CHAT COMPONENTS
import ChatChannelsComponent from '@/components/chat/ChatChannelsComponent.vue';
import ChatJob from '@/components/chat/ChatJob.vue';
import ChatPopupComponent from '@/components/chat/ChatPopupComponent.vue';
import ChatSidebarComponent from '@/components/chat/ChatSidebarComponent.vue';
import ChatSidebarIcon from './components/chat/ChatSidebarIcon.vue';

//TASKOFF CANVASS COMPONENTS
import TaskOffCanvasComponent from '@/components/TaskOffCanvas/TaskOffCanvasComponent.vue';

//PURCHASE ORDERS COMPONENTS
import PurchaseOrderCreate from '@/components/PurchaseOrders/PurchaseOrderCreate.vue';
import PurchaseOrderEdit from '@/components/PurchaseOrders/PurchaseOrderEdit.vue';
import PurchaseOrdersComponent from '@/components/PurchaseOrders/PurchaseOrdersComponent.vue';
import PurchaseOrderView from '@/components/PurchaseOrders/PurchaseOrderView.vue';


Vue.component('sidebar-nav-component', SidebarNavComponent);
Vue.component('breadcrumbs', BreadcrumbsComponent);
Vue.component('job-notes-component', JobNotesComponent);
Vue.component('job-materials-component', JobMaterialsComponent);
Vue.component('job-files-component', JobFilesComponent);
Vue.component('job-files-task-details-component', JobFilesTaskDetailsComponent);
Vue.component('jobs-component', JobsComponent);
Vue.component('job-add-component', JobAddComponent);
Vue.component('job-edit-component', JobEditComponent);
Vue.component('task-component', TaskComponent);
Vue.component('search-component', SearchComponent);
Vue.component('search-results-component', SearchResultsComponent);
Vue.component('custom-field', CustomFieldComponent);
Vue.component('custom-field-type', CustomFieldTypeComponent);
Vue.component('calendar-component', CalendarComponent);
Vue.component('month-component', MonthComponent);
Vue.component('task-notes-component', TaskNotesComponent);
Vue.component('crew-page-component', Crews);
Vue.component('user-info-link-component', UserInfoLinkComponent);
Vue.component('reports-component', ReportsComponent);
Vue.component('mobile-footer', MobileFooter);
Vue.component('contacts-component', ContactsComponent);
Vue.component('customers-component', CustomersComponent);
Vue.component('customer-detail-component', CustomerDetailComponent);
Vue.component('customer-jobs-component', CustomerJobsComponent);
Vue.component('customer-notes-component', CustomerNotesComponent);
Vue.component('customer-invoices-component', CustomerInvoicesComponent);
Vue.component('invoices-component', CustomerInvoicesComponent);
// Vue.component('job-add-component', JobAddComponent);
Vue.component('job-types', JobTypes);
Vue.component('job-templates', JobTemplates);
Vue.component('job-template-edit', JobTemplateEdit);
Vue.component('task-types', TaskTypes);
Vue.component('modal-delete', ModalDelete);
Vue.component('user-clockin', UserClockin);
Vue.component('message-list-component', MessageListComponent);
Vue.component('message-type-selection-component', MessageTypeSelectionComponent);
Vue.component('message-form-component', MessageFormComponent);
Vue.component('message-preview-component', MessagePreviewComponent);
Vue.component('message-notif-component', MessageNotifComponent);
Vue.component('message-stats-component', MessageStatsComponent);
Vue.component('dashboard-component', DashboardComponent);
Vue.component('widget-stat', WidgetStat);
Vue.component('widget-table', WidgetTable);
Vue.component('modal-job-edit', ModalJobEdit);
Vue.component('modal-confirm-job-close', ModalConfirmJobClose);
Vue.component('timesheet-proximity-component', TimesheetProximityComponent);
Vue.component('task-time-component', TaskTimeComponent);
Vue.component('timesheet-report-proximity-component', TimesheetReportProximityComponent);
Vue.component('modal-getting-started', ModalGettingStarted);
Vue.component('modal-job-notes-details', ModalJobNotesDetails);
Vue.component('timeoff-report-component', TimeOffReportComponent);
Vue.component('timeoff-report-datatable-component', TimeOffReportDatatableComponent);
Vue.component('no-call-no-show-report-datatable-component', NoCallNoShowReportDatatableComponent);

// Asset Management
Vue.component('AssetManTopFilter', AssetManTopFilter);
Vue.component('AssetManItemList', AssetManItemList);
Vue.component('AssetManCreateItemMain', AssetManCreateItemMain);
Vue.component('AssetManEditItemMain', AssetManEditItemMain);
Vue.component('asset-item-show', AssetItemShow);
Vue.component('asset-item-details', AssetItemDetails);
Vue.component('asset-item-lifecycle', AssetItemLifecycle);
Vue.component('asset-mtnc-entries', AssetMaintenanceEntries);
Vue.component('asset-operator-history', AssetOperatorHistory);
Vue.component('asset-comments', AssetComments);
Vue.component('asset-files', AssetFiles);
Vue.component('asset-show-left-nav', AssetShowLeftNav);
Vue.component('archive-asset', ArchiveAsset);
Vue.component('asset-settings', AssetSettings);
Vue.component('vehicle-show', VehiclesShow);
Vue.component('vehicles-component', VehiclesComponent);
Vue.component('vehicles-add-component', VehiclesAddComponent);
Vue.component('vehicles-odometer-history', VehicleOdometerHistoryComponent);

//INVOICES REPORT
Vue.component('report-invoice-datatable-component', ReportInvoiceDatatableComponent);
Vue.component('modal-reached-user-limit', ModalReachedUserLimitComponent);
// Vue.component('settings-component', SettingsComponent);
// Vue.component('leads-settings-component', LeadsSettingsComponent);
// Vue.component('invoice-items-component', InvoiceItemsComponent);
Vue.component('invoice-component', InvoiceComponent);
Vue.component('modal-invoice', ModalInvoice);
Vue.component('modal-invoice-view', ModalInvoiceView);
Vue.component('modal-invoice-send', ModalInvoiceSend);
Vue.component('payment-component', PaymentComponent);
Vue.component('modal-payment', ModalPayment);
Vue.component('modal-payment-view', ModalPaymentView);
Vue.component('modal-payment-send', ModalPaymentSend);

// Custom
Vue.component('modal-clockout', ModalClockout);
Vue.component('banner', Banner);

//CHAT COMPONENTS
Vue.component('chat-job', ChatJob);
Vue.component('chat-channels-component', ChatChannelsComponent);
Vue.component('chat-sidebar-component', ChatSidebarComponent);
Vue.component('chat-popup-component', ChatPopupComponent);
Vue.component('chat-sidebar-icon', ChatSidebarIcon);

//TASKOFF CANVASS COMPONENTS
Vue.component('task-off-canvas-component', TaskOffCanvasComponent);

//PURCHASE ORDERS COMPONENTS
Vue.component('purchase-orders-component', PurchaseOrdersComponent);
Vue.component('purchase-order-view', PurchaseOrderView);
Vue.component('purchase-order-edit', PurchaseOrderEdit);
Vue.component('purchase-order-create', PurchaseOrderCreate);


// Vue.component('day-component', import('@/components/DayComponent.vue').default);
// Vue.component('schedule-calendar-component', import('@/components/ScheduleCalendarComponent.vue').default);
// Vue.component('priority-task-component', import('@/components/PriorityTaskComponent.vue').default);
// Vue.component('priority-task-day-component', import('@/components/PriorityTaskDayComponent.vue').default);
// Vue.component('priority-task-item-component', import('@/components/PriorityTaskItemComponent.vue').default);
// Vue.component('modal-add-task', import('@/components/ModalAddTask.vue').default);
// Vue.component('modal-add-advanced-task', import('@/components/ModalAddAdvancedTask.vue').default);
// Vue.component('modal-edit-task', import('@/components/ModalEditTask.vue').default);
// Vue.component('modal-edit-advanced-task', import('@/components/ModalEditAdvancedTask.vue').default);
// Vue.component('modal-task-details', import('@/components/ModalTaskDetails.vue').default);
// Vue.component('widget-request', import('@/components/WidgetRequest.vue').default);
// Vue.component('overtime-projection-component', import('@/components/OvertimeProjectionComponent.vue').default);
// Vue.component('notify', import('@/components/Notify.vue').default);
// Vue.component('notification-component', import('@/components/NotificationComponent.vue').default);
// Vue.component('modal-delete-request', import('@/components/ModalDeleteRequest.vue').default);
// Vue.component('chat-view-details-modal', import('@/components/chat/ChatViewDetailsModal.vue').default);