<template>
    <div class="table-responsive pb-4">
        <table class="table table-sm table-flush" id="datatable-report" v-if="noCallNoShowsCount" ref="datatable">
            <thead class="thead-light">
                <tr>
                    <th scope="col" style="width: 20%">{{ $t('label.employee') }}</th>
                    <th scope="col" style="width: 15%">{{ $t('label.date') }}(s)</th>
                    <th scope="col" style="width: 10%">{{ $t('label.created by') }}</th>
                    <th scope="col" style="width: 30%">{{ $t('label.comment') }}</th>
                    <th scope="col" style="width: 5%;"></th>
                </tr>
            </thead>
            <tbody v-if="!groupByFilter || groupByFilter === 'all'">
                <tr v-for="(data, index) in noCallNoShows" :key="index">
                    <td>
                        <div class="row align-items-center m-0 w-100">
                            <div class="cursor-pointer">
                                <img class="rounded-circle" :src="data.user.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                            </div>
                            <div class="col-7 col-xs-12 px-0 text-left">
                                <div class="ml-3 ml-xs-0">
                                    <h4 class="font-weight-normal m-0">{{ data.user.name }}</h4>
                                    <h5 class="text-muted font-weight-normal m-0">{{ data.user.role.name }}</h5>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <h4 class="font-weight-normal m-0">
                            {{ data.date | formatDate }}
                        </h4>
                    </td>
                    <td>
                        <h4 class="font-weight-normal m-0">
                            {{ data.created_by.name }}
                        </h4>
                    </td>
                    <td>
                        <h4 class="font-weight-normal m-0">
                            {{ data.comment }}
                        </h4>
                    </td>
                    <td class="text-right">
                        <div class="dropdown">
                            <a class="btn btn-sm btn-icon-only" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-ellipsis-h fa-lg"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                <a class="dropdown-item" @click="getNoCallNoShow(index)">
                                    <i class="ri-pencil-line"></i> {{ $t('label.edit entry') }}
                                </a>
                                <a class="dropdown-item" @click="deleteNotify(index)">
                                    <i class="ri-delete-bin-line btn-outline-danger"></i> {{ $t('label.delete entry') }}
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody v-else v-for="(data, index) in noCallNoShows" :key="index">
                <tr v-if="groupByFilter === 'employee'" style="background-color: #005486;">
                    <td colspan="5">
                        <h3 class="text-left text-white mb-0">
                            <span class="text-left">
                                <i :class="data.isHidden ? 'fa-chevron-down' : 'fa-chevron-up'" class="fas mr-3 icon-list cursor-pointer" :id="'icon-list-' + '-' + index" @click="toggleOpenHide(index);"></i>
                                {{ data.no_call_no_shows[0].user.name }}
                            </span>
                        </h3>
                    </td>
                </tr>
                <tr v-for="(item, key) in data.no_call_no_shows" :key="'no-call-no-show-details-' + key" v-if="!data.isHidden">
                    <td>
                        <div class="row align-items-center m-0 w-100">
                            <div class="cursor-pointer">
                                <img class="rounded-circle" :src="item.user.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                            </div>
                            <div class="col-7 col-xs-12 px-0 text-left">
                                <div class="ml-3 ml-xs-0">
                                    <h4 class="font-weight-normal m-0">{{ item.user.name }}</h4>
                                    <h5 class="text-muted font-weight-normal m-0">{{ item.user.role.name }}</h5>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <h4 class="font-weight-normal m-0">
                            {{ item.date | formatDate }}
                        </h4>
                    </td>
                    <td>
                        <h4 class="font-weight-normal m-0">
                            {{ item.created_by.name }}
                        </h4>
                    </td>
                    <td>
                        <h4 class="font-weight-normal m-0">
                            {{ item.comment }}
                        </h4>
                    </td>
                    <td class="text-right">
                        <div class="dropdown">
                            <a class="btn btn-sm btn-icon-only" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-ellipsis-h fa-lg"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                <a class="dropdown-item" @click="getNoCallNoShow(index, key)">
                                    <i class="ri-pencil-line"></i> {{ $t('label.edit entry') }}
                                </a>
                                <a class="dropdown-item" @click="deleteNotify(index, key)">
                                    <i class="ri-delete-bin-line btn-outline-danger"></i> {{ $t('label.delete entry') }}
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr style="background-color: #DCDCDC;" :key="'no-call-no-show-details-total-group-by-hours-' + index" v-if="!data.isHidden">
                    <th scope="col" colspan="2" class="text-right">
                        <h4 class="mb-0">{{ $t('label.total') }}:</h4>
                    </th>
                    <th scope="col" colspan="3">
                        <h4 class="mb-0">{{ data.no_call_no_shows.length }}</h4>
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex';

export default {
    props: [
        'noCallNoShows',
        'startDate',
        'endDate',
        'groupByFilter',
        'noCallNoShowsCount'
    ],
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user'))
        }
    },
    mounted() {
        this.$nextTick(() => {
            console.log(this.$refs['datatable'], this.$refs.datatable)
            if (this.$refs['datatable']) {
                let dataTable = $("#datatable-report").DataTable({
                    keys: !0,
                    language: {
                        paginate: {
                            previous: "<i class='fas fa-angle-left'>",
                            next: "<i class='fas fa-angle-right'>"
                        }
                    },
                    order: [1, "asc"],
                    pageLength: 100,
                    "searching": false,
                    "lengthChange": false,
                });
            }
        })
    },
    updated() {
        console.log(this.noCallNoShows, this.noCallNoShowsCount)
    },
    computed: {
        ...mapGetters(['company']),
    },
    methods: {
        toggleOpenHide(index) {
            this.noCallNoShows[index].isHidden = !this.noCallNoShows[index].isHidden;
        },
        getNoCallNoShow(index, key = 0) {
            const data = {
                index: index,
                key: key
            }
            bus.$emit('noCallNoShowForm', data);
        },
        deleteNotify(index, key = 0) {
            const data = {
                index: index,
                key: key
            }
            bus.$emit('deleteNoCallNoShowModal', data);
        }
    },
}
</script>
