<template>
	<div>
		<!-- Add Job Modal -->
		<div class="modal-content">
			<div class="modal-header border-bottom">
				<h5 class="modal-title" id="addJobModalTitle">{{ $t('label.add job') }}</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('closeAddJobModal')">
					<i class="ri-close-line"></i>
				</button>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-sm-12 col-md-6">
						<!-- customer name -->
						<h5 class="modal-title mb-3">{{ $t('label.customer information') }}</h5>
						<div class="col-12">
							<div class="form-group">
								<label class="form-control-label text-muted" for="vendor_name">{{ $t('label.customer') }}</label>
								<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

								<div class="dropdown w-100">
									<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': ($v.form.add.customer_id.$error), }">
											<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a customer') + ' -'" v-model="$v.form.add.customer_name.$model" autocomplete="input-field" data-lpignore="true" required readonly>
											<div class="input-group-append">
												<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
											</div>
										</div>
									</div>

									<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
										<a class="dropdown-item text-default pointer" @click="$modal.show('jobAddCustomerModal')">
											<i class="ri-add-fill"></i> {{ $t('label.add customer') }}
										</a>
										<div class="dropdown-divider mx-3"></div>
										<h4 class="mx-3">{{ $t('label.results') }}</h4>
										<!-- Open select on new jobs -->
										<div class="pl-3" v-if="!customerid">
											<template v-for="(data, index) in alljobpeople['customers']">
												<div :key="index" class="pointer dropdown-items" @click="selectedCustomer(data); onCustomerChange();">
													<span class="customername">{{ data.name }}</span>
												</div>
											</template>
										</div>
										<!-- Show disabled version for added jobs from customer -->
										<div class="pl-3" v-if="customerid">
											<template v-for="(data, index) in alljobpeople['customers']">
												<div :key="index" class="pointer dropdown-items" @click="selectedCustomer(data);">
													<span class="customername">{{ data.name }}</span>
												</div>
											</template>
										</div>



									</div>
								</div>
							</div>
						</div>
						<!-- superintendent -->
						<div class="col-12">
							<div class="form-group">
								<label class="form-control-label text-muted" for="superintendent_name">{{ $t('label.superintendent') }}</label>

								<div class="dropdown w-100">
									<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<div v-if="form.add.customer_id !== null && form.add.customer_id !== undefined" class="input-group input-group-alternative input-group-merge search-contacts">
											<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a superintendent') + ' -'" v-model="form.add.superintendent_name" autocomplete="input-field" data-lpignore="true" readonly>
											<div class="input-group-append">
												<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
											</div>
										</div>
										<div v-else class="input-group input-group-alternative input-group-merge search-contacts">
											<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a customer first') + ' -'" v-model="form.add.superintendent_name" autocomplete="input-field" data-lpignore="true" disabled readonly>
											<div class="input-group-append">
												<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
											</div>
										</div>
									</div>
									<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
										<a v-if="form.add.customer_id !== null && form.add.customer_id !== undefined" class="dropdown-item text-default pointer" @click="$modal.show('jobAddAddSuperModal')">
											<i class="ri-add-fill"></i> {{ $t('label.add superintendent') }}
										</a>
										<a v-else class="dropdown-item text-default pointer">
										</a>
										<div class="dropdown-divider mx-3"></div>
										<h4 class="mx-3">{{ $t('label.results') }}</h4>
										<div class="pl-3">
											<template v-for="(data, index) in customerContactList">
												<div :key="index" :value="data.id" class="pointer dropdown-items" @click="selectedSuperintendent(data);">
													<span class="superintendentname">{{ data.full_name }}</span>
												</div>
											</template>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- Job Name -->
						<h5 class="modal-title mb-3">{{ $t('label.job information') }}</h5>
						<div class="col-12">
							<div class="form-group">
								<label class="form-control-label text-muted" for="input-name">{{ $t('label.job name') }}</label>
								<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

								<input type="text" name="job_name" v-model="$v.form.add.job_name.$model" class="form-control" :class="{ 'is-invalid': $v.form.add.job_name.$error }" :placeholder="$t('label.enter a job name')" required autofocus>
								<div v-if="$v.form.add.job_name.$error && !$v.form.add.job_name.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
							</div>
						</div>
						<!-- Job Type -->
						<div class="col-12">
							<div class="form-group">
								<label class="form-control-label text-muted" for="input_name">{{ $t('label.job type') }}</label>
								<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

								<div class="dropdown w-100">
									<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': ($v.form.add.job_type_id.$error), }">
											<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a job type') + ' -'" v-model="$v.form.add.job_type_name.$model" autocomplete="input-field" data-lpignore="true" required readonly>
											<div class="input-group-append">
												<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
											</div>
										</div>
									</div>

									<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
										<a class="dropdown-item text-default pointer" @click="toggleAddJobType(true)">
											<i class="ri-add-fill"></i> {{ $t('label.add new job type') }}
										</a>
										<div class="dropdown-divider mx-3"></div>
										<h4 class="mx-3">{{ $t('label.results') }}</h4>
										<div class="pl-3">
											<template v-for="(data, index) in alljobtypes">
												<div :key="index" :value="data.id" class="pointer dropdown-items" @click="selectedJobType(data);">
													<span class="jobtypename">{{ data.name }}</span>
												</div>
											</template>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- Manager -->
						<div class="col-12">
							<div class="form-group">
								<label class="form-control-label text-muted" for="input_name">{{ $t('label.job manager') }}</label>
								<div class="dropdown w-100">
									<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<div class="input-group input-group-alternative input-group-merge search-contacts">
											<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a job manager') + ' -'" v-model="form.add.job_manager_user_name" autocomplete="input-field" data-lpignore="true" readonly>
											<div class="input-group-append">
												<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
											</div>
										</div>
									</div>

									<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
										<div class="pl-3">
											<template v-for="(data, index) in allManagers">
												<div :key="index" :value="data.id" class="pointer dropdown-items" @click="selectedManager(data);">
													<span class="managername">{{ data.name }}</span>
												</div>
											</template>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- Job Status -->
						<div class="col-12">
							<div class="form-group">
								<label class="form-control-label text-muted" for="input_name">{{ $t('label.job status') }}</label>
								<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

								<div class="dropdown w-100">
									<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': ($v.form.add.job_status_id.$error), }">
											<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a job status') + ' -'" v-model="$v.form.add.job_status_name.$model" autocomplete="input-field" data-lpignore="true" required readonly>
											<div class="input-group-append">
												<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
											</div>
										</div>
									</div>

									<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
										<div class="pl-3">
											<template v-for="(data, index) in alljobstatus">
												<div :key="index" :value="data.id" class="pointer dropdown-items" @click="selectedJobStatus(data);">
													<span class="jobstatusname">{{ data.name }}</span>
												</div>
											</template>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- Dates -->
						<div class="row input-daterange datepicker align-items-center" style="margin-left: 0; margin-right: 0;">
							<!-- Start Date -->
							<div class="col-6">
								<div class="form-group">
									<label class="form-control-label">{{ $t('label.start date') }}</label>
									<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

									<date-picker v-model="$v.form.add.start_date.$model" valueType="date" format="MM/DD/YYYY" :disabled-date="disableStartDateBeforeToday" :placeholder="$t('label.select date')" :input-class="{
										'is-invalid': $v.form.add.start_date.$error,
										'form-control': true
									}" required>
									</date-picker>
									<div v-if="$v.form.add.start_date.$error && !$v.form.add.start_date.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
								</div>
							</div>
							<!-- Time estimate -->
							<div class="col-6">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.time estimate') }} {{ '(' + $t('label.hours') + ')' }}</label>
									<input v-model="form.add.time_estimate" type="number" inputmode="decimal" min="0" name="time_estimate" class="form-control" autocomplete="input-field" data-lpignore="true">
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-md-6">
						<!-- Use customer address checkbox -->
						<h5 class="modal-title mb-3">{{ $t('label.job location') }}</h5>
						<div class="col-12">
							<div class="custom-control custom-control-alternative custom-checkbox mb-3">
								<input class="custom-control-input" id="customer_address" type="checkbox" v-model="form.add.customer_address" @change="useCustomerAddress()">
								<label class="custom-control-label" for="customer_address">
									<span class="text-muted">Use Customer Address</span>
								</label>
							</div>
						</div>
						<!-- Address 1 -->
						<div class="col-12">
							<div class="form-group">
								<label class="form-control-label text-muted" for="input-name">{{ $t('label.address') }} 1</label>
								<input type="text" name="address_1" v-model="form.add.address_1" class="form-control" :placeholder="$t('label.address') + ' 1'" autofocus>
							</div>
						</div>
						<!-- Address 2 -->
						<div class="col-12">
							<div class="form-group">
								<label class="form-control-label text-muted" for="input-name">{{ $t('label.address') }} 2</label>
								<input type="text" name="address_2" v-model="form.add.address_2" class="form-control" :placeholder="$t('label.address') + ' 2'" autofocus>
							</div>
						</div>
						<!-- City -->
						<div class="col-12">
							<div class="form-group">
								<label class="form-control-label text-muted" for="input-name">{{ $t('label.city') }}</label>
								<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

								<input type="text" name="city" v-model="$v.form.add.city.$model" class="form-control" :class="{ 'is-invalid': $v.form.add.city.$error }" :placeholder="$t('label.city')" required autofocus>
								<div v-if="$v.form.add.city.$error && !$v.form.add.city.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
							</div>
						</div>
						<!-- Postal Code -->
						<div class="col-12">
							<div class="form-group">
								<label class="form-control-label text-muted" for="input-name">{{ $t('label.postal code') }}</label>
								<input @keyup="getStateFromPostalCode" type="text" name="postal_code" v-model="form.add.postal_code" class="form-control" :placeholder="$t('label.postal code')" autofocus>
							</div>
						</div>
						<!-- State -->
						<div class="col-12">
							<div class="form-group">
								<label class="form-control-label text-muted" for="input_name">{{ $t('label.state') }}</label>
								<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

								<div class="dropdown w-100">
									<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': ($v.form.add.state.$error), }">
											<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a state') + ' -'" v-model="$v.form.add.state.$model" autocomplete="input-field" data-lpignore="true" required readonly>
											<div class="input-group-append">
												<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
											</div>
										</div>
									</div>

									<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
										<div class="pl-3">
											<template v-for="(data, index) in allstates">
												<div :key="index" :value="data" class="pointer dropdown-items" @click="selectedState(data);">
													<span class="state">{{ index }}</span>
												</div>
											</template>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- Country -->
						<div class="col-12">
							<div class="form-group">
								<label class="form-control-label text-muted" for="input_name">{{ $t('label.country') }}</label>
								<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

								<div class="dropdown w-100">
									<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': ($v.form.add.country.$error), }">
											<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a country') + ' -'" v-model="$v.form.add.country.$model" autocomplete="input-field" data-lpignore="true" required readonly>
											<div class="input-group-append">
												<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
											</div>
										</div>
									</div>

									<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
										<div class="pl-3">
											<template v-for="(data, index) in allcountries">
												<div :key="index" :value="index" class="pointer dropdown-items" @click="selectedCountry(data);">
													<span class="country">{{ data }}</span>
												</div>
											</template>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Custom fields -->
				<h5 v-if="customfields.length" class="modal-title mb-3">{{ $t('label.custom fields') }}</h5>
				<div class="col-12">
					<div v-for="cfData in customfields" :key="cfData.id" class="col-6 form-group">
						<custom-field-input :model-id="job_id" :model-type="'job'" :custom-field="cfData" :form-save="savingForm">
						</custom-field-input>
					</div>
				</div>
			</div>

			<!-- Add and Close buttons -->
			<div class="modal-footer border-top">
				<button type="button" class="btn btn-primary mr-auto" @click="saveJob">{{ $t('label.add job') }}</button>
				<button type="button" class="btn btn-link  ml-auto close2addjob" @click="$emit('closeAddJobModal')">{{ $t('label.close') }}</button>
			</div>
		</div>

		<!-- Add New Customer -->
		<modal name="jobAddCustomerModal" height="auto" width="98%" :max-width="1024" :reset="true" :scrollable="true" :adaptive="true">
			<modal-customer source="job-component" jobcreate="true" :account-type="1" @cancelFnc="$modal.hide('jobAddCustomerModal')" @returnFnc="receiveNewCustomer" />
		</modal>

		<!-- Add Superintendent Modal -->
		<modal class="pb-6" name="jobAddAddSuperModal" height="auto" width="98%" :max-width="1024" :scrollable="true" :reset="true" :adaptive="true">
			<customer-contact-manage :customer-id="form.add.customer_id" :selected-contact="selectedContact" @cancelFnc="$modal.hide('jobAddAddSuperModal')" @returnContact="receiveNewContact"></customer-contact-manage>
		</modal>

		<!-- Add New Job Type Modal -->
		<job-type-add-modal v-if="showJobTypeModal" @onClose="toggleAddJobType" @onSave="onAddNewJobType">
		</job-type-add-modal>

	</div>
</template>

<script>
import JobTypeAddModal from '@/components/Admin/JobTypes/JobTypeAddModal';
import CustomFieldInput from '@/components/CustomFields/CustomFieldInput.vue';
import CustomerContactManage from '@/components/CustomerContact/CustomerContactManageComponent';
import ModalCustomer from '@/components/ModalCustomer';
import bus from '@/utils/event-bus';
import { VBPopoverPlugin } from 'bootstrap-vue';
import { required } from 'vuelidate/lib/validators';

export default {
	props: ['customerid', 'getjobs'],
	emits: ['closeAddJobModal', 'returnAddJobModal'],
	directives: { 'b-popover': VBPopoverPlugin },
	components: {
		CustomerContactManage,
		CustomFieldInput,
		JobTypeAddModal,
		ModalCustomer
	},
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
			allsuperintendents: [],
			customerContactList: [],
			customfields: [],
			alljobstatus: [],
			alljobpeople: [],
			allcountries: [],
			alljobtypes: [],
			allManagers: [],
			allstates: [],
			alljobs: [],
			loader: null,
			job_id: null,
			selectedContact: null,
			showJobTypeModal: false,
			savingForm: false,
			form: {
				add: {
					customer_id: (this.customerid > 0) ? this.customerid : null,
					customer_name: null,
					superintendent_user_id: 0,
					superintendent_name: null,
					job_status_id: null,
					job_status_name: null,
					job_type_id: null,
					job_type_name: null,
					job_name: null,
					job_manager_user_id: null,
					job_manager_user_name: null,
					start_date: moment().toDate(),
					end_date: null,
					time_estimate: null,
					// country: 'US',
					country: 'United States',
					address_1: null,
					address_2: null,
					city: null,
					state: null,
					postal_code: null
				}
			},
		}
	},
	validations: {
		form: {
			add: {
				customer_id: { required },
				customer_name: {},
				job_type_id: { required },
				job_type_name: {},
				job_name: { required },
				start_date: { required },
				country: { required },
				city: { required },
				state: { required },
				job_status_id: { required },
				job_status_name: {},
			}
		}
	},
	mounted() {
		this.clearForm();
		this.loadLkupData();

		console.log("form", this.customerid, this.form);
	},
	methods: {
		loadLkupData() {
			let promises = [];
			this.loader = this.$loading.show();
			promises.push(this.getCustomfields());
			promises.push(this.getCountries());
			promises.push(this.getStates());
			promises.push(this.getJobTypes());
			promises.push(this.getJobStatus());
			promises.push(this.getJobPeople());
			promises.push(this.getCompanyManagers());
			promises.push(this.loadCustomerContacts());
			// Hide the loader overlay after all lookup data is loaded
			Promise.all(promises).then(() => {
				this.loader.hide();
			});
		},
		getStateFromPostalCode() {
			if (this.form.add.postal_code && this.form.add.postal_code.length == 5) {
				axios.get('/get-state/' + this.form.add.postal_code).then(response => {
					if (response.data.results) {
						this.form.add.state = response.data.results.abbreviation;
					} else {
						this.form.add.state = ''
					}
				})
					.catch(err => {
						//console.log('list carriers error:', error)
					});
			}
		},
		getCountries() {
			return axios.get('/get-countries').then(response => {
				this.allcountries = response.data.result;
			})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		getStates() {
			return axios.get('/get-states').then(response => {
				this.allstates = response.data.result;
			})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		getJobTypes() {
			return axios.get('/jobtypes').then(response => {
				this.alljobtypes = response.data.result;
			}).catch(err => {
				//console.log('list carriers error:', error)
			});
		},
		getJobStatus() {
			return axios.get('/jobstatus').then(response => {
				this.alljobstatus = response.data.result;
				for (let i = 0; this.alljobstatus.length > 0; i++) {
					if (this.alljobstatus[i].name == 'Open') {
						this.form.add.job_status_id = this.alljobstatus[i].id;
						break;
					}
				}

			})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		getJobPeople() {
			return axios.get('/jobpeople').then(response => {
				this.alljobpeople = response.data.result;
			})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		getCompanyManagers() {
			return axios.get('/company-admins').then(response => {
				this.allManagers = response.data.result;
			}).catch(err => console.log('getCompanyManagers', err));
		},
		onCustomerChange() {
			this.loadCustomerContacts();

			if (this.form.add.customer_address)
				this.useCustomerAddress();
		},
		loadCustomerContacts() {
			this.customerContactList = [];
			if (this.form.add.customer_id) {
				return axios
					.post('/loadCustomerContacts', { customerId: this.form.add.customer_id })
					.then(response => {
						this.customerContactList = response.data;
					})
					.catch(err => {
						console.log('ERROR: ', err);
						bus.$emit('banner-error', { message: err });
					});
			}
		},
		getJobs(widget) {
			if (typeof widget !== 'undefined') {
				this.filter.mine = null;
				this.filter.overdue = null;
				this.filter.recently_closed = null;
			}

			switch (widget) {
				case 2:
					this.filter.mine = true;
					break;
				case 3:
					this.filter.overdue = true;
					break;
				case 4:
					this.filter.recently_closed = true;
					break;
			}

			var request_fields = this.filter;
			axios.post('/get-jobs', request_fields)
				.then(response => {
					this.alljobs = response.data.result;
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		onAddNewCustomer(cust_id) {
			this.getJobPeople().then(() => {
				this.form.add.customer_id = cust_id;
			}).catch(err => console.log('noAddNewCustomer:', err));
		},
		onAddNewJobType(job_type_id) {
			this.getJobTypes().then(() => {
				this.form.add.job_type_id = job_type_id;
			});
		},
		editExistingJob(jobNumber) {
			this.$router.push({
				name: 'jobEdit',
				params: { id: jobNumber },
			});
			this.$emit('returnAddJobModal', jobNumber);

		},
		selectedCustomer(data) {
			this.form.add.customer_id = data.id;
			this.form.add.customer_name = data.name;
		},
		selectedSuperintendent(data) {
			this.form.add.superintendent_user_id = data.id;
			this.form.add.superintendent_name = data.full_name;
		},
		selectedJobType(data) {
			this.form.add.job_type_id = data.id;
			this.form.add.job_type_name = data.name;
		},
		selectedManager(data) {
			this.form.add.job_manager_user_id = data.id;
			this.form.add.job_manager_user_name = data.name;
		},
		selectedJobStatus(data) {
			this.form.add.job_status_id = data.id;
			this.form.add.job_status_name = data.name;
		},
		selectedState(data) {
			this.form.add.state = data;
		},
		selectedCountry(data) {
			this.form.add.country = data;
		},
		saveJob() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.loader = this.$loading.show();

				var request_fields = {
					'customer_id': this.form.add.customer_id,
					'superintendent_user_id': this.form.add.superintendent_user_id,
					'job_type_id': this.form.add.job_type_id,
					'job_name': this.form.add.job_name,
					'job_manager_user_id': this.form.add.job_manager_user_id,
					'start_date': moment(this.form.add.start_date).format('YYYY-MM-DD'),
					'end_date': moment(this.form.add.end_date).isValid() ? moment(this.form.add.end_date).format('YYYY-MM-DD') : null,
					'time_estimate': this.form.add.time_estimate,
					'country': this.form.add.country,
					'address_1': this.form.add.address_1,
					'address_2': this.form.add.address_2,
					'city': this.form.add.city,
					'state': this.form.add.state,
					'postal_code': this.form.add.postal_code,
					'job_status_id': this.form.add.job_status_id,
				};

				axios.post('/jobs', request_fields).then((response) => {
					this.job_id = response.data.job_id;
					this.savingForm = true;

					setTimeout(() => {
						this.editExistingJob(response.data.id);
						this.$emit('closeAddJobModal');
						this.loader.hide();
					}, 2000);
				}).catch(err => { });
			} else {
				// Go to top of screen and show invalid fields
				$("#addJobModalTitle")[0].scrollIntoView();
			}
		},
		clearForm() {
			this.form.add.customer_id = (this.customerid > 0) ? this.customerid : null;
			this.form.add.customer_name = null;
			this.form.add.superintendent_user_id = 0;
			this.form.add.superintendent_name = null;
			this.form.add.job_type_id = null;
			this.form.add.job_name = null;
			this.form.add.job_manager_user_id = null;
			this.form.add.start_date = moment().toDate();
			this.form.add.end_date = null;
			this.form.add.time_estimate = null;
			this.form.add.country = 'US';
			this.form.add.address_1 = null;
			this.form.add.address_2 = null;
			this.form.add.city = null;
			this.form.add.state = null;
			this.form.add.postal_code = null;
		},
		getCustomfields() {
			return axios.post(`/loadCustomFieldsByModel`, {
				model_type: 'Job'
			}).then(response => {
				this.customfields = response.data.result;
			})
		},
		viewFilter() {
			this.extraFilter = !this.extraFilter;
		},
		isMobile() {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				return true
			} else {
				return false
			}
		},
		addJobModal() {
			$('#addJobModal').ready(function () {
				$('.datepicker').datepicker();
			});
		},
		toggleAddJobType(status) {
			this.showJobTypeModal = status;
		},
		useCustomerAddress() {
			if (this.form.add.customer_address) {
				for (let i = 0; i < this.alljobpeople['customers'].length; i++) {
					if (this.form.add.customer_id && this.alljobpeople['customers'][i].id == this.form.add.customer_id) {
						this.form.add.country = this.alljobpeople['customers'][i].country;
						this.form.add.address_1 = this.alljobpeople['customers'][i].address_1;
						this.form.add.address_2 = this.alljobpeople['customers'][i].address_2;
						this.form.add.city = this.alljobpeople['customers'][i].city;
						this.form.add.state = this.alljobpeople['customers'][i].state;
						this.form.add.postal_code = this.alljobpeople['customers'][i].postal_code;
						break;
					}
				}
			} else {
				this.form.add.country = '';
				this.form.add.address_1 = '';
				this.form.add.address_2 = '';
				this.form.add.city = '';
				this.form.add.state = '';
				this.form.add.postal_code = '';
			}
		},
		receiveNewCustomer(newCustomer) {
			this.$modal.hide('jobAddCustomerModal');
			// Add the newly created customer to the drop down list
			// and select the newly created customer
			this.alljobpeople['customers'].push(newCustomer.data);
			this.form.add.customer_id = this.alljobpeople['customers'][this.alljobpeople['customers'].length - 1].id;
			this.onCustomerChange();
		},
		receiveNewContact(newContact) {
			this.$modal.hide('jobAddAddSuperModal');

			// Add the newly created contact to the drop down 
			// list and elect the newly created contact
			this.customerContactList.push(newContact);
			this.form.add.superintendent_user_id = this.customerContactList[this.customerContactList.length - 1].id;
		},
		disableStartDateBeforeToday(date) {
			return date < moment().subtract(1, 'days').toDate();
		},
		disableEndDateBeforeStartDate(date) {
			return date < moment(this.form.add.start_date).toDate();
		},
	}
}
</script>

<style scoped>
.dropdown-menu {
	overflow: scroll;
	max-height: 300px;
}

.dropdown-items {
	line-height: 36px;
}

.dropdown-items:hover {
	background: #f6f9fc;
}
</style>