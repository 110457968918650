<template>
    <div>
        <div class="modal-content">
            <div class="modal-header border-bottom">
                <div v-if='savedType == "save"'>
                    <h5 class="modal-title" id="invoiceItemModalTitle">{{ $t('label.create invoice item') }}</h5>
                </div>
                <div v-else>
                    <h5 class="modal-title" id="invoiceItemModalTitle">{{ $t('label.update invoice item') }}</h5>
                </div>
                <button type="button" class="close" @click="$emit('cancelFnc')">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group text-left">
                    <label class="form-control-label text-muted">{{ $t('label.invoice item/description') }}</label>
                    <input type="text" name="name" class="form-control" :placeholder="$t('label.name')" v-model="$v.form.name.$model" required autofocus>
                    <div v-if="$v.form.name.$error && !$v.form.name.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
                </div>
                <div class="form-group text-left">
                    <label class="form-control-label text-muted">{{ $t('label.amount') }}</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text input-group-invoice"><i class="ri-money-dollar-circle-line"></i></span>
                        </div>
                        <money v-model="$v.form.amount.$model" v-bind="money" class="form-control" required></money>
                        <div v-if="$v.form.amount.$error && !$v.form.amount.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
                    </div>
                </div>
                <div class="form-group text-left">
                    <label class="form-control-label text-muted">{{ $t('label.invoice item status') }}</label>
                    <div class="row m-0 py-1">
                        <div class="custom-control custom-radio mr-3">
                            <input name="status" id="statusActive" class="custom-control-input" value="1" type="radio" v-model="$v.form.status.$model">
                            <label class="custom-control-label" for="statusActive">{{ $t('label.active') }}</label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input name="status" id="statusInactive" class="custom-control-input" value="0" type="radio" v-model="$v.form.status.$model">
                            <label class="custom-control-label" for="statusInactive">{{ $t('label.inactive') }}</label>
                        </div>
                    </div>
                    <div v-if="$v.form.status.$error && !$v.form.status.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
                </div>
            </div>
            <div class="modal-footer border-top">
                <div v-if='savedType == "save"'>
                    <button type="button" class="btn btn-primary mr-auto" @click="saveInvoiceItems">{{ $t('label.create invoice item') }}</button>
                </div>
                <div v-else>
                    <button type="button" class="btn btn-primary mr-auto" @click="saveInvoiceItems">{{ $t('label.update invoice item') }}</button>
                </div>
                <button type="button" class="btn btn-link ml-auto" data-dismiss="modal" @click="$emit('cancelFnc')">{{ $t('label.close') }}</button>
            </div>
        </div>
    </div>
</template>
<script>
import bus from '@/utils/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { required } from 'vuelidate/lib/validators';

export default {
    props: ['savedType', 'itemData', 'info'],
    components: {
        Loading
    },
    data() {
        return {
            allInvoiceItems: [],
            form: {
                id: '',
                name: '',
                status: '',
                amount: 0.00,
            },
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '',
                precision: 2,
                masked: false
            },
            isLoading: false,
            newid: 'DeleteInvoiceItem',
            deleteModalText: {
                title: 'ARE YOU SURE YOU WANT TO DELETE THIS INVOICE ITEM?',
                button: 'Yes, Delete'
            },
            delete_id: null,
            // saveType: '',
        }
    },
    validations: {
        form: {
            name: { required },
            amount: { required },
            status: { required }
        }
    },
    created() {
        // this.getInvoiceItems();
    },
    mounted() {
        bus.$on('deleteFInvoiceItem', () => {
            this.deleteInvoiceItem();
        });

        if (this.savedType == 'update') {
            this.editModal(this.info);
        }
    },
    methods: {
        saveInvoiceItems() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.isLoading = true;

                if (this.savedType == 'save') {
                    let params = {
                        name: this.form.name,
                        status: this.form.status,
                        amount: parseFloat(this.form.amount).toFixed(2),
                    }

                    axios.post('/save-invoice-items', params)
                        .then(response => {
                            // this.getInvoiceItems(); --1--
                            this.clearForm();
                            this.isLoading = false;
                            this.$emit('onSave', response.data.result);
                            this.$modal.hide('invoiceItemsModal');
                            // $('#invoiceItemModal').modal('hide');
                        })
                        .catch(err => {
                            //console.log('list carriers error:', error)
                        });

                } else {
                    let params = {
                        invoice_item_id: this.form.id,
                        name: this.form.name,
                        status: this.form.status,
                        amount: parseFloat(this.form.amount).toFixed(2),
                    }
                    axios.post('/update-invoice-items', params,)
                        .then(response => {
                            // this.getInvoiceItems();
                            this.clearForm();
                            this.isLoading = false;
                            this.$emit('onSave', response.data.result);
                            // $('#invoiceItemModal').modal('hide');
                        })
                        .catch(err => {
                            //console.log('list carriers error:', error)
                        });

                }
            }
        },
        editModal(item) {
            this.form.id = item.id;
            this.form.name = item.name;
            this.form.status = item.status;
            this.form.amount = item.amount;
        },
        deleteNotify(delete_id) {
            this.delete_id = delete_id;
            $("#deleteModal" + this.newid).modal('show');
        },
        deleteInvoiceItem() {
            axios.post('/delete-invoice-items/' + this.delete_id)
                .then(response => {
                    // this.getInvoiceItems();
                })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        runTooltip() {
            // $('[data-toggle="tooltip"]').tooltip()
            bsTooltip.call($("[data-toggle='tooltip']"));
        },
        clearForm() {
            this.form.id = null;
            this.form.name = null;
            this.form.status = null;
            this.form.amount = 0.00
        },

    }
}
</script>
