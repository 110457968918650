<template>
	<div class="card">
		<div v-if="name == 'Closed Jobs' || name == 'Closed Tasks'" class="card-body mb-0">
			<div class="row handle cursor-move">
				<div class="col">
					<h4 class="card-title text-uppercase text-muted mb-0"><i class="ri-drag-move-fill"></i>{{ name }}</h4>
				</div>
				<div class="col-auto">
					<div v-if="name == 'Closed Jobs'" class="icon icon-shape bg-gradient-success text-white rounded-circle shadow"><i class="ri-stack-line p-0"></i></div>
					<div v-if="name == 'Closed Tasks'" class="icon icon-shape bg-gradient-warning text-white rounded-circle shadow"><i class="ri-todo-line p-0"></i></div>
				</div>
			</div>
			<div class="row align-items-center pt-3">
				<div class="col">
					<h4 class="card-title text-uppercase font-weight-normal text-muted mb-0">{{ $t('label.today') }}</h4>
				</div>
				<div class="col text-right">
					<span class="h3 font-weight-bold mb-0 pr-3">{{ data.today }}</span>
				</div>
			</div>
			<div class="row align-items-center pt-2">
				<div class="col">
					<h4 class="card-title text-uppercase font-weight-normal text-muted mb-0">{{ $t('label.this week') }}</h4>
				</div>
				<div class="col text-right">
					<span class="h3 font-weight-bold mb-0 pr-3">{{ data.this_week }}</span>
				</div>
			</div>
			<div class="row align-items-center pt-2">
				<div class="col pr-0">
					<h4 class="card-title text-uppercase font-weight-normal text-muted mb-0">{{ $t('label.this month') }}</h4>
				</div>
				<div class="col text-right">
					<span class="h3 font-weight-bold mb-0 pr-3">{{ data.this_month }}</span>
				</div>
			</div>
		</div>
		<div v-if="name == 'Open Jobs' || name == 'Open Tasks'" class="card-body">
			<div class="row handle cursor-move">
				<div class="col">
					<h4 class="card-title text-uppercase text-muted mb-0"><i class="ri-drag-move-fill"></i>{{ name }}</h4>
					<span class="h3 font-weight-bold mb-0 pr-3">{{ data.count }}</span>
				</div>
				<div class="col-auto">
					<div v-if="name == 'Open Jobs'" class="icon icon-shape bg-gradient-success text-white rounded-circle shadow"><i class="ri-stack-line p-0"></i></div>
					<div v-if="name == 'Open Tasks'" class="icon icon-shape bg-gradient-success text-white rounded-circle shadow"><i class="ri-todo-line p-0"></i></div>
				</div>
			</div>
		</div>
		<div v-if="name == 'Time Today' || name == 'Time This Week' || name == 'Time This Month'" class="card-body">
			<div class="row">
				<div class="col">
					<h4 class="card-title text-uppercase text-muted mb-0 handle cursor-move"><i class="ri-drag-move-fill"></i>{{ name }}</h4>

					<!-- time today -->
					<router-link v-if="name == 'Time Today'" :to="{ name: 'timesheet', params: { dateStart: today, dateEnd: today } }">{{ data.time }} {{ $t('label.hours') }}</router-link>

					<!-- Time this Week -->
					<router-link v-if="name == 'Time This Week'" :to="{ name: 'timesheet', params: { dateStart: startOfWeek, dateEnd: endOfWeek } }">{{ data.time }} {{ $t('label.hours') }}</router-link>

					<!-- Time This Month -->
					<router-link v-if="name == 'Time This Month'" :to="{ name: 'timesheet', params: { dateStart: startOfMonth, dateEnd: endOfMonth } }">{{ data.time }} {{ $t('label.hours') }}</router-link>
				</div>
				<div class="col-auto">
					<div class="icon icon-shape bg-gradient-info text-white rounded-circle shadow"><i class="ri-time-line p-0"></i></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import moment from '@/utils/date-time';
import { mapGetters } from 'vuex';
export default {
	props: ['name', 'data'],
	data() {
		return {
			// user: JSON.parse(localStorage.getItem('user')),
			today: null,
			startOfWeek: null,
			endOfWeek: null,
			startOfMonth: null,
			endOfMonth: null,
		}
	},
	computed: {
		...mapGetters({
			user: 'Auth/currentUser'
		})
	},
	mounted() {
		this.today = moment().tz(this.user.timezone).format("MM/DD/YYYY");
		this.startOfMonth = moment().tz(this.user.timezone).startOf('month').format("MM/DD/YYYY");
		this.endOfMonth = moment().tz(this.user.timezone).endOf('month').format("MM/DD/YYYY");
		this.startOfWeek = moment().tz(this.user.timezone).startOf('week').format("MM/DD/YYYY");
		this.endOfWeek = moment().tz(this.user.timezone).endOf('week').format("MM/DD/YYYY");
	},
}
</script>
