<template>
	<div class="col-xl-12 order-xl-1">
		<div class="card">
			<div class="card-header">
				<div class="row align-items-center justify-content-between">
					<div>
						<h3 class="mb-0 d-flex"><i class="ri-draft-line"></i> {{ $t('label.notes') }}</h3>
					</div>
					<div>
						<button type="button" class="btn btn-sm btn-default" data-toggle="modal" data-target="#addCustomerNotesModal">
							{{ $t('label.add note') }}
						</button>
					</div>
				</div>
			</div>
			<div v-if="allcustomernotes.length == 0">
				<div class="card-body m-0 py-0">
					<div class="bg-white text-center row align-items-center m-0">
						<i class="ri-draft-line ni-8x w-100 color-gray-400"></i>
						<p class="w-100 font-weight-normal">{{ account_type == 1 ? $t('label.No Customer Notes have been added') : $t('label.No Lead Notes have been added') }}.</p>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<!-- List group -->
				<div class="list-group list-group-flush">
					<div v-if="showLess">
						<div class="list-group-item list-group-item-action py-4 px-4" v-for="(item, index) in allcustomernotes.slice(0, 5)" v-bind:key="index">
							<div class="row align-items-center justify-content-between m-0">
								<div class="col p-0 m-0 d-flex align-items-center">
									<div class="cursor-pointer mr-2" @click="getUserInfo(item.created_by.id, 'Created By')">
										<img class="rounded-circle" :src="item.created_by.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
									</div>
									<h4 class="cursor-pointer m-0 mr-2" @click="getUserInfo(item.created_by.id, 'Created By')">{{ item.created_by.name }}</h4>
									<h4 class="font-weight-normal m-0 mr-2">{{ item.created_at | formatDateTime }}</h4>
								</div>
								<div class="col-auto p-0 m-0 d-flex">
									<h4 class="mr-3"><a href="#" class="text-primary font-weight-normal" data-toggle="modal" data-target="#editCustomerNotesModal" @click="editModal(item)">{{ $t('label.edit') }}</a></h4>
									<h4><a href="#" class="text-primary font-weight-normal" data-toggle="modal" data-target="#deleteModal1" @click="deleteNotify(item.id)">{{ $t('label.delete') }}</a></h4>
								</div>
							</div>
							<p class="text-sm mb-0 ml-5 mt-3 font-weight-normal pre-wrap">{{ item.notes }}</p>
						</div>
					</div>
					<div v-else>
						<div class="list-group-item list-group-item-action py-4 px-4" v-for="(item, index) in allcustomernotes" v-bind:key="index">
							<div class="row align-items-center justify-content-between m-0">
								<div class="col p-0 m-0 d-flex align-items-center">
									<div class="cursor-pointer mr-2" @click="getUserInfo(item.created_by.id, 'Created By')">
										<img class="rounded-circle" :src="item.created_by.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
									</div>
									<h4 class="cursor-pointer m-0 mr-2" @click="getUserInfo(item.created_by.id, 'Created By')">{{ item.created_by.name }}</h4>
									<h4 class="font-weight-normal m-0 mr-2">{{ item.created_at | formatDateTime }}</h4>
								</div>
								<div class="col-auto p-0 m-0 d-flex">
									<h4 class="mr-3"><a href="#" class="text-primary font-weight-normal" data-toggle="modal" data-target="#editCustomerNotesModal" @click="editModal(item)">{{ $t('label.edit') }}</a></h4>
									<h4><a href="#" class="text-primary font-weight-normal" data-toggle="modal" data-target="#deleteModal1" @click="deleteNotify(item.id)">{{ $t('label.delete') }}</a></h4>
								</div>
							</div>
							<p class="text-sm mb-0 ml-5 mt-3 font-weight-normal pre-wrap">{{ item.notes }}</p>
						</div>
					</div>
					<button class="btn btn-white text-primary mr-auto ml-auto my-4 d-flex" v-if="allcustomernotes.length > 5 && showLess" @click="showLess = false">
						{{ $t('label.view more') }}
					</button>
					<button class="btn btn-white text-primary mr-auto ml-auto my-4 d-flex" v-if="allcustomernotes.length > 5 && !showLess" @click="showLess = true">
						{{ $t('label.view less') }}
					</button>
				</div>
			</div>
		</div>
		<!-- Modal -->
		<div class="modal fade" id="addCustomerNotesModal" tabindex="-1" role="dialog" aria-labelledby="addCustomerNotesModalTitle" aria-hidden="true">
			<div class="modal-dialog modal-lg" role="document">
				<div class="modal-content">
					<div class="modal-header border-bottom">
						<h5 class="modal-title" id="addCustomerNotesModalTitle">{{ account_type == 1 ? $t('label.add customer notes') : $t('label.add lead notes') }}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<i class="ri-close-line"></i>
						</button>
					</div>
					<form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="saveCustomerNote">
						<div class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<textarea id="addCustomerNoteField" v-model="form.add.notes" rows="10" name="notes" class="form-control" :placeholder="$t('label.write a note') + '...'" required autofocus></textarea>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer border-top">
							<button type="submit" class="btn btn-primary mr-auto">{{ account_type == 1 ? $t('label.add customer note') : $t('label.add lead note') }}</button>
							<button type="button" class="btn btn-link  ml-auto close2addnote" data-dismiss="modal">{{ $t('label.close') }}</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<!-- EDIT Modal -->
		<div class="modal fade" id="editCustomerNotesModal" tabindex="-1" role="dialog" aria-labelledby="editCustomerNotesModalTitle" aria-hidden="true">
			<div class="modal-dialog modal-lg" role="document">
				<div class="modal-content">
					<div class="modal-header border-bottom">
						<h5 class="modal-title" id="editCustomerNotesModalTitle">{{ $t('label.edit customer notes') }}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<i class="ri-close-line"></i>
						</button>
					</div>
					<form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="updateCustomerNote">
						<div class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<textarea id="editCustomerNoteField" v-model="form.edit.notes" rows="10" name="notes" class="form-control" :placeholder="$t('label.write a note') + '...'" required autofocus></textarea>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer border-top">
							<button type="submit" class="btn btn-primary mr-auto">{{ $t('label.update customer note') }}</button>
							<button type="button" class="btn btn-link  ml-auto close2editcustnote" data-dismiss="modal">{{ $t('label.close') }}</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<!-- MODAL FOR DELETE -->
		<!-- <modal-delete :deleteMethod="deleteMethod" :deleteModalText="deleteModalText"></modal-delete> -->
		<!-- MODAL FOR DELETE -->
		<div class="modal fade" id="deleteModal1" tabindex="-1" role="dialog" aria-labelledby="deleteModal1" aria-hidden="true">
			<div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
				<div class="modal-content bg-gradient-danger">
					<div class="modal-header">
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="deleteCustomerNote">
						<div class="modal-body">
							<div class="py-3 text-center">
								<i class="ri-error-warning-line ni-3x"></i>
								<h4 class="heading mt-4 text-uppercase">{{ $t('label.ARE YOU SURE YOU WANT TO DELETE THIS NOTE') }}?</h4>
								<p>{{ $t('label.This action cannot be undone') }}!</p>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-white">{{ $t('label.Yes, Delete') }}</button>
							<button type="button" class="btn btn-link text-white ml-auto" data-dismiss="modal">{{ $t('label.cancel') }}</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['customerid', 'account_type'],
	data() {
		return {
			allcustomernotes: [],
			form: {
				add: {
					notes: null
				},
				edit: {
					notes: null,
					id: null
				}
			},
			showDropdown: false,
			columns: ['notes', 'created_by.name', 'created_at', 'actions'],
			options: {
				perPageValues: [],
				headings: {
					notes: this.$t('label.notes'),
					'created_by.name': this.$t('label.created by'),
					created_at: this.$t('label.date added'),
					actions: ''
				},
				editableColumns: ['notes', 'created_by.name', 'created_at'],
				sortable: ['notes', 'created_by.name', 'created_at'],
				filterable: false,
				columnsClasses: {
					notes: 'text-left w-50',
					'created_by.name': 'text-left',
					created_at: 'text-left',
					actions: 'text-center actions-column'
				},
			},
			deleteModalText: {
				title: 'ARE YOU SURE YOU WANT TO DELETE THIS NOTE?',
				button: 'Yes, Delete'
			},
			showLess: true,
			delete_id: null,
			deleteMethod: null
		}
	},
	mounted() {
		$('#addCustomerNotesModal, #editCustomerNotesModal').on('shown.bs.modal', function () {
			let checkExist = setInterval(function () {
				if ($('#addCustomerNoteField').length || $('#editCustomerNoteField').length) {
					$('#addCustomerNoteField').focus();
					$('#editCustomerNoteField').focus();
					clearInterval(checkExist);
				}
			}, 10);
		});

		this.getCustomerNotes();
	},
	methods: {
		getCustomerNotes() {
			axios.get('/customer-notes/' + this.customerid).then(response => {
				this.allcustomernotes = response.data.result;
			})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		saveCustomerNote() {
			var request_fields = {
				'notes': this.form.add.notes,
				'customer_id': this.customerid
			};
			axios.post('/customer-notes', request_fields)
				.then(response => {
					this.form.add.notes = '';
					this.getCustomerNotes();
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
			document.querySelector('.close2addnote').click();

		},
		editModal(item) {
			this.form.edit.notes = item.notes;
			this.form.edit.id = item.id;
		},
		updateCustomerNote() {
			var request_fields = {
				'notes': this.form.edit.notes,
				'customer_id': this.customerid,
				'id': this.form.edit.id
			};
			axios.post('/customer-notes/update', request_fields)
				.then(response => {
					this.getCustomerNotes();
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
			document.querySelector('.close2editcustnote').click();
		},
		deleteNotify(delete_id) {
			this.delete_id = delete_id;
			// this.deleteMethod = this.deleteCustomerNote;
			// $("#deleteModal").modal('show');
		},
		deleteCustomerNote() {
			axios.post('/delete-customer-note/' + this.delete_id)
				.then(response => {
					this.getCustomerNotes();
					$("#deleteModal1").modal('hide');
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		}
	}
}
</script>
