<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50" id="timesheetSettingsContainer">
            <!-- Page Header -->
            <div class="d-flex" style="z-index: 100">
                <!-- Breadcrumbs -->
                <breadcrumbs :title="$t('label.asset item types')">
                    <!-- Breakcrumb slots -->
                    <li class="breadcrumb-item">
                        {{ $t('label.admin') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.content management') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.asset item types') }}
                    </li>
                </breadcrumbs>
            </div>

            <!-- Page Body -->
            <div class="card">
                <!-- Item Types  -->
                <div class="card-header">
                    <div class="row m-0 align-items-center justify-content-between">
                        <h3 class="mb-0">{{ $t('label.asset item types') }}</h3>
                        <button type="button" class="btn btn-sm btn-default" @click="toggleModal('show', 'selected')">
                            {{ $t('label.add item type') }}
                        </button>
                    </div>
                </div>
                <!-- Table -->
                <div class="table-responsive pb-4 mt--2">
                    <table class="table table-flush" id="assetTypeTable">
                        <thead class="thead-light">
                            <tr>
                                <th class="col-sm-7">{{ $t('label.item name/description') }}</th>
                                <th class="col-sm-2">{{ $t('label.creation date') }}</th>
                                <th class="col-sm-2">{{ $t('label.status') }}</th>
                                <th class="col-sm-1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, idx) in assetTypes" :key="idx">
                                <!-- Name / Description -->
                                <td class="col-sm-7">{{ item.name }}</td>
                                <!-- Creation Date -->
                                <td class="col-sm-2">{{ item.created_at }}</td>
                                <!-- Status -->
                                <td class="col-sm-2">{{ item.status }}</td>
                                <!-- Options Dropdown -->
                                <td class="col-sm-1">
                                    <span class="dropdown" v-show="item.company_id !== null">
                                        <a class="btn px-2 py-1 my--1" href="#" data-toggle="dropdown">
                                            <i class="fas fa-ellipsis-v text-light"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                            <a class="dropdown-item" href="#" data-toggle="modal" @click="toggleModal('show', 'selected', item)">
                                                <i class="ri-pencil-line"></i> {{ $t('label.edit item type') }}
                                            </a>
                                            <a class="dropdown-item text-danger" href="#" data-toggle="modal" @click="toggleModal('show', 'delete', item)">
                                                <i class="ri-delete-bin-line"></i> {{ $t('label.delete item type') }}
                                            </a>
                                        </div>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Create or Add Asset Type Modal -->
        <modal name="selectedAssetTypeModal" @closed="resetSelectedType()" :shiftY="0.2" width="800px" :min-height="200" height="auto" :scrollable="true" :reset="true">
            <div class="card-header">
                <div class="row pt-2 pb-1">
                    <div class="col-sm-6">
                        <div class="label-bold pt-1">
                            <div v-if="!selectedType.id">{{ $t('label.create item type') }}</div>
                            <div v-if="selectedType.id">{{ $t('label.edit item type') }}</div>
                        </div>
                    </div>
                    <div class="col-sm-6 text-right">
                        <button class="close" type="button" @click="toggleModal('hide', 'selected')">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <!-- Item Type Name -->
                <div class="form-group">
                    <div class="text-sm label-bold pb-2">{{ $t('label.item type name') }}</div>
                    <input class="form-control" v-model="selectedType.type_name" :class="{ 'border-danger': formErrors.type_name }" type="text" :placeholder="$t('label.enter an item type name')" />

                    <div class="text-danger text-sm" v-if="formErrors.type_name">*{{ $t("label.field required") }}.</div>
                </div>
                <!-- Status -->
                <div class="form-group">
                    <div class="text-sm label-bold pb-2">{{ $t('label.item status') }}</div>
                    <div class="form-group">
                        <b-form-radio-group v-model="selectedType.status" name="item_status">
                            <b-form-radio value="Active">{{ $t('label.active') }}</b-form-radio>
                            <b-form-radio value="Inactive">{{ $t('label.inactive') }}</b-form-radio>
                        </b-form-radio-group>
                    </div>
                </div>
            </div>

            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-default" @click="validate(selectedType)">
                            <span v-if="!selectedType.id">{{ $t('label.create item type') | uppercase }}</span>
                            <span v-if="selectedType.id">{{ $t('label.save changes') | uppercase }}</span>
                        </button>
                    </div>
                    <div class="col-sm-6 text-right">
                        <button type="button" class="btn btn-link" @click="toggleModal('hide', 'selected')">
                            {{ $t('label.cancel') }}
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <!-- Delete Asset Type Modal -->
        <modal name="deleteAssetTypeModal" @closed="resetSelectedType()" :min-height="200" width="550px" height="auto" :scrollable="true" :reset="true">
            <div class="card-body bg-gradient-danger">
                <div class="text-right ml-auto">
                    <button type="button" class="close" @click="toggleModal('hide', 'delete')">
                        <span class="text-lighter">×</span>
                    </button>
                </div>
                <div class="py-6 text-center text-white">
                    <i class="ri-error-warning-line ni-3x"></i>
                    <h4 class="heading mt-4 text-uppercase text-white">{{ $t('label.ARE YOU SURE YOU WANT TO DELETE THIS NOTE') }}?</h4>
                    <p>{{ $t('label.This action cannot be undone') }}!</p>
                </div>
                <div class="d-flex">
                    <button type="button" class="btn btn-white" @click="deleteAssetType()">{{ $t('label.Yes, Delete') }}</button>
                    <button type="button" class="btn btn-link text-white ml-auto" @click="toggleModal('hide', 'delete')">{{ $t('label.cancel') }}</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';

export default {
    props: [],
    data() {
        return {
            activeModal: "",
            assetTypes: [],
            loader: null,
            dataTable: null,
            selectedType: {
                id: null,
                type_name: null,
                status: "Active"
            },
            formErrors: {
                type_name: false
            }
        }
    },
    mounted() {
        this.getAssetTypes();
    },
    methods: {
        getAssetTypes() {
            this.loader = this.$loading.show();
            axios.get('/get-asset-types').then(response => {
                this.assetTypes = response.data;
                this.runDataTable();
                this.loader.hide();
            }).catch(err => console.log(err));
        },

        runDataTable() {
            if (this.dataTable !== null)
                this.dataTable.destroy();

            // Build Datatable
            this.$nextTick(() => {
                this.dataTable = $('#assetTypeTable').DataTable({
                    keys: !0,
                    ordering: false,
                    pageLength: 10,
                    searching: false,
                    lengthChange: false,
                    language: {
                        paginate: {
                            previous: "<i class='fas fa-angle-left'>",
                            next: "<i class='fas fa-angle-right'>",
                        }
                    }
                });
            });
        },

        resetSelectedType() {
            this.selectedType.id = null;
            this.selectedType.type_name = null;
            this.selectedType.status = "Active";
            this.formErrors.type_name = false;
        },

        toggleModal(action, modal, selectedType) {
            let modalToOpen = modal + 'AssetTypeModal';
            this.activeModal = modal;

            // Set Form Fields if Edit
            if (selectedType) {
                this.selectedType.id = selectedType.id;
                this.selectedType.type_name = selectedType.name;
                this.selectedType.status = selectedType.status;
            }

            if (action === "show")
                this.$modal.show(modalToOpen);
            else
                this.$modal.hide(modalToOpen);

        },

        validate(type) {

            // Check Item Type Name
            if (type.type_name === null || !type.type_name.length)
                this.formErrors.type_name = true;
            else
                this.formErrors.type_name = false;

            // Return Errors or Save Asset Type
            if (this.formErrors.type_name) {
                return;
            } else {
                this.toggleModal('hide', 'selected');
                this.saveAssetType();
            }
        },

        saveAssetType() {
            let apiPath = "",
                toSave = {
                    type_name: this.selectedType.type_name,
                    status: this.selectedType.status
                };

            // Set uri based on create or edit
            if (this.selectedType.id) {
                toSave.id = this.selectedType.id;
                apiPath = "/update-asset-type";
            } else {
                apiPath = "/create-asset-type";
            }

            axios.post(apiPath, toSave).then(response => {
                bus.$emit('banner-success', {
                    message: $t('label.asset type saved successfully') + '.'
                });

                this.getAssetTypes();
            }).catch(err => console.log(err));
        },

        deleteAssetType() {
            this.$modal.hide('deleteAssetTypeModal');

            axios.post("/destroy-asset-type", {
                id: this.selectedType.id
            }).then(response => {
                // console.log("response delete", response);
                bus.$emit('banner-success', {
                    message: $t('label.asset type deleted successfully') + '.'
                });

                this.getAssetTypes();
            }).catch(err => console.log(err));
        }
    }
}
</script>
