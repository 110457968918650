<template>
    <div class="form-group m-0" :class="{ 'has-danger': hasErrors }">
        <div class="row m-0 py-1">
            <!-- Loop Field types -->
            <div v-for="type in types" :key="type.id" class="custom-control custom-radio mr-3">
                <input name="type_id" class="custom-control-input" v-model="$data.selected_" :value="type.id" :id="'input-' + modaltype + '-' + customfield.id + '-' + type.id" type="radio" @change="sendBack" required>
                <label class="custom-control-label text-light-black" :for="'input-' + modaltype + '-' + customfield.id + '-' + type.id">{{ type.name }}</label>
            </div>
        </div>
        <!-- Display options for certain field types -->
        <div class="field-type__options" v-if="hasOptions">
            <!-- New Option -->
            <div class="options-input">
                <input class="form-control" type="text" v-model="option" placeholder="Field options" @keypress.prevent.enter="addOption" />
                <a class="btn btn-success btn-add" href="#" @click.prevent="addOption">
                    <i class="far fa-plus-square"></i>
                </a>
            </div>
            <!-- Options are required for certian fields -->
            <span v-if="options[selectedType].length < 1" class="invalid-feedback" role="alert" style="display: block;">
                <strong>At least one option is required</strong>
            </span>
            <span v-if="error" class="invalid-feedback" role="alert" style="display: block;">
                <strong v-text="error"></strong>
            </span>

            <!-- Options List -->
            <div class="options-list">
                <div v-for="(option, index) in options[selectedType]" :key="option.value" class="options-item">
                    <h5 v-text="option"></h5>
                    <input type="checkbox" name="options[]" :value="option" checked />
                    <a class="btn btn-danger btn-remove" href="#" @click.prevent="removeOption(index)">
                        <i class="far fa-trash-alt"></i>
                    </a>
                </div>
            </div>
        </div>

        <slot />
    </div>
</template>

<script>
export default {
    name: "custom-field-type",
    emits: ['returnFnc'],
    props: {
        types: { type: Array },
        modaltype: { type: String },
        label: { type: String, required: true },
        selected: { type: Number, default: 1 },
        oldoptions: { type: Array, default: () => [] },
        hasErrors: { type: Boolean, default: false },
        customfield: { type: Object },
    },
    data() {
        return {
            selected_: this.selected,
            error: null,
            option: "",
            options: {
                radio: [],
                checkbox: [],
                dropdown: [],
            }
        }
    },
    created() {
        if (this.oldoptions && this.oldoptions.length) {
            this.options[this.selectedType] = this.oldoptions.map(oldOption => oldOption.name ? oldOption.name : oldOption)
        }
    },
    computed: {
        hasOptions() {
            const typeIndex = this.types.findIndex(
                type => type.id === this.selected
            )

            return typeIndex !== -1 ? ["dropdown", "checkbox", "radio"].includes(this.types[typeIndex].type) : false;
        },
        selectedType() {
            return this.types.find(type => type.id === this.selected).type;
        }
    },
    methods: {
        addOption() {
            this.error = null;

            if (this.option === "") {
                this.error = "Option is required.";
                return;
            }

            if (this.options[this.selectedType].includes(this.option)) {
                this.error = "Option is duplicated.";
                return;
            }

            this.options[this.selectedType].push(this.option);
            this.option = "";
            this.sendBack()
        },
        removeOption(index) {
            this.options[this.selectedType].splice(index, 1);
            this.sendBack()
        },
        sendBack() {
            let sendBackVal = {
                isValid: false,
                selectedTypeId: this.$data.selected_,
                selectedType: this.selectedType,
                typeOptions: null,
            }
            if (this.$data.selected_ === 2 || this.$data.selected_ > 3) {
                if (this.options[this.selectedType]) {
                    sendBackVal.isValid = true
                    let optionsObj = [];
                    // convert the array into an object and stringify for storage
                    this.options[this.selectedType].forEach(function (elm) {
                        optionsObj.push({ name: elm, value: elm.toLowerCase() })
                    })
                    sendBackVal.typeOptions = optionsObj
                }
            }
            else {
                sendBackVal.isValid = true
            }
            // Send values back to parent
            this.$emit('returnFnc', sendBackVal)
        },
    },
}
</script>

<style lang="scss" scoped>
.field-type__options {
    max-width: 50%;
    padding-top: 16px;
    padding-left: 30px;

    .options-input {
        display: flex;
        align-items: center;

        .form-control {
            flex: 1;
            height: 30px;
            font-size: 13px;
            padding-top: 6px;
            border-radius: 2px;
            padding-bottom: 6px;
        }
    }

    .btn-add,
    .btn-remove {
        height: 30px;
        width: 30px;
        display: flex;
        padding: 0;
        margin-left: 8px;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        border-radius: 3px;
    }

    .options-list {
        padding-top: 12px;

        .options-item {
            display: flex;
            align-items: center;
            background: #dee2e6;
            border-radius: 2px;
            padding-left: 8px;
            margin-bottom: 8px;

            h5 {
                flex: 1;
                margin-bottom: unset;
            }
        }
    }

    input[type="checkbox"] {
        width: 0;
        height: 0;
    }
}
</style>
