import { store } from '@/store/store';
import { getDomain } from '@/utils/helpers.js';

const errors = {
  UNKNOWN_DATA: 'Unknown Customer Data',
  UNKNOWN_PLAN: 'Unknown Plan',
  USER_EXCEED: 'User Exceed',
  PLAN_EXPIRED: 'Plan Expired'
};

const domainDetails = getDomain();

export default async function (to, from, next) {
  try {
    const customerToken = await store.getters['Auth/isCustomerToken']();
    if (!customerToken) throw new Error(errors.UNKNOWN_DATA);

    await setCustomerSettings();
    await validateSubscription(to, from, next);
    next();
  } catch (e) {
    let user = store.getters['Auth/currentUser'];
    store.dispatch('Auth/setLoading', false);

    if ((e.message == errors.PLAN_EXPIRED || e.message == errors.USER_EXCEED || e.message == errors.UNKNOWN_PLAN) && user.role_id == 3) {
      return next({ name: 'subscription-expired' });
    }

    if (e.message == errors.PLAN_EXPIRED) {
      return next({ name: 'purchase-plan' });
    }

    if (e.message == errors.USER_EXCEED) {
      return next({ name: 'subscription-expired' });
    }

    if (e.message == errors.UNKNOWN_PLAN) {
      return next({ name: 'purchase-plan' });
    }

    await store.dispatch('Auth/logout');
    return next({ name: 'login' });
  }
}

async function setCustomerSettings() {
  const _local = store.getters['Auth/getLocals']
  if (!_local) {
    await store.dispatch('Auth/setUserLocal');
  }
}

async function validateSubscription(to, from, next) {
  if (domainDetails.subDomain == 'admin' || domainDetails.subDomain == 'Admin') return;
  if (to.name == 'subscription-expired' || to.name == 'purchase-plan' || to.name == 'subscriptions') return;

  let currentSubscription = store.state.Plan.currentSubscription;
  if (!currentSubscription) {
    await store.dispatch('Plan/getCurrentPlan')
  }

  currentSubscription = store.state.Plan.currentSubscription;
  const currentSubscriptionIsNotValid = await store.getters['Plan/currentSubscriptionIsNotValid'];
  if (!currentSubscription) throw new Error(errors.UNKNOWN_PLAN);
  if (currentSubscription.is_valid == false) throw new Error(errors.PLAN_EXPIRED);
  if (currentSubscriptionIsNotValid) throw new Error(errors.USER_EXCEED);

  if ((currentSubscription.active_field_users > currentSubscription.max_field_users) || (currentSubscription.active_office_users > currentSubscription.max_office_users)) throw new Error(errors.PLAN_EXPIRED);
}
