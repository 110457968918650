<template>
	<div>
		<div class="modal-content">
			<div class="modal-header border-bottom">
				<h5 class="modal-title" id="paymentModalTitle">{{ $t('label.process payment') }}</h5>
				<button type="button" class="close" @click="$emit('cancelFnc')">
					<i class="ri-close-line"></i>
				</button>
			</div>
			<div class="modal-body">
				<div class="row m-0">
					<div v-if="companySettings.settings.invoice" class="col-6 p-0">
						<div v-if="allinvoices" class="form-group">
							<label class="form-control-label text-muted" for="invoice_number">{{ $t('label.select an invoice') }}</label>
							<div class="row border m-0 p-2" v-for="(data, index) in allinvoices" :key="index" v-if="data.invoice_status.name == 'Sent'">
								<div class="col custom-control custom-radio mb-4" @click="setAmount(data)">
									<input name="custom-radio-1" class="custom-control-input" :id="'customRadio' + data.id" type="radio" :value="data.id" v-model="selectedInvoice">
									<label class="custom-control-label w-100" :for="'customRadio' + data.id">
										<div class="row">
											<div class="col">
												<div class="d-block">
													<span class="font-weight-bold">{{ $t('label.invoice') }} {{ data.invoice_number_concat }}</span>
													<span class="ml-3">({{ data.due_date }})</span>
													<span class="badge badge-dot text-muted ml-3">
														<i class="bg" :style="{ 'background-color': data.invoice_status.color }"></i>
														{{ data.invoice_status.name }}
													</span>
												</div>
												{{ data.invoice_item_list_concat }}
											</div>
											<div class="col-auto">
												${{ parseFloat(data.balance).toFixed(2) | formatThousands }}
											</div>
										</div>
									</label>
								</div>
							</div>
						</div>
						<div v-if="!companySettings.settings.invoice" class="form-group">
							<label class="form-control-label text-muted" for="invoice_number">{{ $t('label.payment note') }}</label>
							<textarea v-model="paymentNote" name="paymentNote" class="form-control" placeholder="Enter payment note here." rows="10" required autofocus></textarea>
						</div>
					</div>
					<div class="col-6">
						<div class="form-group">
							<label class="form-control-label text-muted" for="payment_amount">{{ $t('label.payment amount') }}</label>
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text input-group-invoice"><i class="ri-money-dollar-circle-line"></i></span>
								</div>
								<money v-model="amount" v-bind="money" class="form-control" required></money>
								<button v-if="companySettings.settings.payment_method == 2" type="button" class="btn btn-primary ml-3" @click="authorizeNet">Pay Now</button>
							</div>
						</div>
						<div v-if="companySettings.settings.payment_method == 1" class="form-group">
							<label class="form-control-label text-muted" for="card-element">{{ $t('label.credit card number') }}</label>
							<div :id="'card-element_' + source" class="form-control" style="padding-top: 14px;"></div>
							<div :id="'card-errors_' + source" class="mt-2 invalid-card-error"></div>
						</div>

						<div v-if="companySettings.settings.payment_method == 2">
							<div id="iframe_holder" class="center-block" style="width:100%; max-width: 1000px" data-mediator="payment-form-loader">
								<iframe id="load_payment" class="embed-responsive-item" name="load_payment" width="550" height="200" frameborder="0" scrolling="no">
								</iframe>
								<form id="send_hptoken" action="https://test.authorize.net/payment/payment" method="post" target="load_payment">
									<input type="hidden" name="token" v-model="authorizeToken" />
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer border-top">
				<button v-if="companySettings.settings.payment_method == 1" type="button" :id="'card-button_' + source" class="btn btn-primary mr-auto">{{ $t('label.process payment') }}</button>
				<button type="button" class="btn btn-link  ml-auto close2processpayment" @click="$emit('cancelFnc')">{{ $t('label.close') }}</button>
			</div>

		</div>

		<modal class="pb-6" name="paymentSendModal" height="auto" width="100%" :max-width="900" :scrollable="true" :reset="true" :adaptive="true">
			<ModalPaymentSend :job="job" :payment-data="paymentData" from-menu="false" @cancelFnc="$modal.hide('paymentSendModal')"></ModalPaymentSend>
		</modal>
	</div>
</template>

<script>
import ModalPaymentSend from '../components/ModalPaymentSend.vue';

export default {
	props: ['job', 'source', 'source2', 'invoiceData', 'currId', 'from'],
	emits: ['cancelFnc', 'reloadPayments'],
	components: {
		ModalPaymentSend
	},
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
			companySettings: JSON.parse(localStorage.getItem('company_settings')),
			paymentData: [],
			allinvoices: [],
			loader: null,
			jobData: null,
			paymentNote: null,
			authorizeToken: null,
			selectedInvoice: null,
			autoSelectInvoice: null,
			amount: 0,
			autoSelectAmount: 0,
			openModal: false,
			money: {
				decimal: '.',
				thousands: ',',
				prefix: '',
				precision: 2,
				masked: false
			},
		}
	},
	mounted() {
		this.loadLkupData(this.invoiceData)
	},
	methods: {
		async loadLkupData(data) {
			this.loader = this.$loading.show();
			this.autoSelectInvoice = data.id;
			this.autoSelectAmount = data.balance;
			this.jobData = this.job;

			await this.getAllInvoices()
			if (this.companySettings.settings.payment_method === 1)
				await this.creditCard()

			this.loader.hide();
		},
		getAuthorizeToken(amount) {
			let request = {
				amount: amount
			}
			axios.post('/get-authorize-token', request).then(response => {
				this.authorizeToken = response.data.result;
				var that = this;

				$(document).ready(function () {
					window.CommunicationHandler = {};

					function parseQueryString(str) {
						var vars = [];
						var arr = str.split('&');
						var pair;
						for (var i = 0; i < arr.length; i++) {
							pair = arr[i].split('=');
							vars[pair[0]] = unescape(pair[1]);
						}
						return vars;
					}

					window.CommunicationHandler.onReceiveCommunication = function (argument) {
						var params = parseQueryString(argument.qstr)

						switch (params['action']) {
							// case "resizeWindow"     :
							// 	console.log('resize'); break;
							// case "successfulSave"   :
							// 	console.log('save'); break;
							// case "cancel"           :
							// 	console.log('cancel'); break;
							case "$tactResponse": {
								let token = null,
									$tResponse = JSON.parse(params['response']);

								sessionStorage.removeItem("HPTokenTime");
								that.processPayment(token, $tResponse.$tId);
							}
						}
					}

					//send the token
					$('#send_hptoken').submit();
				});

			}).catch(err => {
				//console.log('list carriers error:', error)
			});
		},
		getAllInvoices() {
			var d = null;

			if (this.source2 == 'job')
				d = this.jobData.id
			else if (this.source2 == 'customer')
				d = this.currId

			axios.get('/get-all-invoices/' + d + '/' + this.source2).then(response => {
				this.allinvoices = response.data.result;
				this.allinvoices = this.allinvoices.filter(x => x.invoice_status.name != 'Paid')

				this.selectedInvoice = this.autoSelectInvoice;
				this.amount = this.autoSelectAmount;
			}).catch(err => {
				//console.log('list carriers error:', error)
			});
		},
		setAmount(data) {
			this.amount = data.balance
		},
		creditCard() {
			var that = this;
			setTimeout(() => {
				const stripe = Stripe(this.companySettings.settings.publishable_key);

				const elements = stripe.elements();
				const cardElement = elements.create('card');

				cardElement.mount('#card-element_' + that.source);

				let cardbuttonID = 'card-button_' + that.source;
				const cardButton = document.getElementById(cardbuttonID);

				cardButton.addEventListener('click', async (e) => {
					stripe.createToken(cardElement).then(function (result) {
						if (result.error) {
							// Inform the customer that there was an error.
							let carderrorsID = 'card-errors_' + that.source;
							var errorElement = document.getElementById(carderrorsID);
							errorElement.textContent = result.error.message;
						} else {
							// Send the token to your server.
							that.processPayment(result.token)
						}
					});
				});
			}, 1500);

		},
		authorizeNet() {
			this.getAuthorizeToken(this.amount)
		},
		processPayment(token, $taction_id) {
			if (this.amount >= 1) {
				this.loader = this.$loading.show();
				if (this.companySettings.settings.payment_method === 1) {
					let payment = {
						amount: this.amount,
						token: token,
						invoice_id: this.companySettings.settings.invoice ? this.selectedInvoice : null,
						job_id: this.jobData.id,
						customer_id: this.jobData.customer.id,
						note: this.companySettings.settings.invoice ? null : this.paymentNote
					}

					axios.post('/payment', payment)
						.then(response => {
							this.loader.hide();
							this.openModal = true;
							this.clearForm();

							this.paymentData = {
								data: response.data.result
							};
							this.$emit('reload')
							this.$emit('cancelFnc')
							this.$modal.show('paymentSendModal');
						}).catch(err => {
							//console.log('list carriers error:', error)
						});

				} else {
					let payment = {
						amount: this.amount,
						invoice_id: this.companySettings.settings.invoice ? this.selectedInvoice : null,
						job_id: this.jobData.id,
						customer_id: this.jobData.customer.id,
						$taction_id: $taction_id,
						note: this.companySettings.settings.invoice ? null : this.paymentNote
					};

					axios.post('/payment-authorize', payment).then(response => {
						this.isLoading = false;
						this.openModal = true;
						this.clearForm();

						this.paymentData = {
							data: response.data.result
						};
						this.$emit('reload')
						this.$emit('cancelFnc')
						this.$modal.show('paymentSendModal');
					})
				}
			}
		},
		clearForm() {
			this.allinvoices = [];
			this.selectedInvoice = null;
			this.amount = 0;
			this.authorizeToken = null;
			this.paymentNote = null;
		}
	}
}
</script>
<style>
.invoice-items {
	min-height: 45px !important;
	line-height: 22px !important;
}

.invoice-items .text {
	color: #8898aa;
}

.ccFixAlign {
	margin-left: 0 !important;
}
</style>