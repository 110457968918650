<template>
    <div>
        <div class="card-header">
            <div class="py-2 d-flex">
                <h3>
                    {{ (isEdit) ? $t('label.edit') : $t('label.add') }}
                    {{ $t('label.customer') }}
                    {{ $t('label.contact') }}
                </h3>
                <button type="button" class="close ml-auto" @click="cancelAdd()">
                    <i class="ri-close-line"></i>
                </button>
            </div>
        </div>

        <div class="card-body">
            <!-- Contact name and Title -->
            <div class="row">
                <div class="col-sm-6">
                    <!-- Contact name -->
                    <div class="form-group">
                        <label for="">
                            {{ $t('label.contact') }}
                            {{ $t('label.name') }}
                        </label>
                        <span class="ml-2 text-xs text-danger">* {{ $t('label.required') }}</span>

                        <input type="text" class="form-control" v-model="newContact.full_name" autocomplete="password" required :class="{ 'border-danger': formErrors.full_name }">

                        <div class="text-danger text-sm mt-1" v-if="formErrors.full_name">
                            <div v-for="(error, index) in formErrors.full_name" :key="index">* {{ error }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <!-- Title -->
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input_name">{{ $t('label.title') }}</label>
                        <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                        <div class="dropdown w-100">
                            <div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': ($v.newContact.title.$error), }">
                                    <input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a title') + ' -'" v-model="$v.newContact.title.$model" autocomplete="input-field" data-lpignore="true" required readonly>
                                    <div class="input-group-append">
                                        <span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
                                    </div>
                                </div>
                            </div>

                            <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
                                <div class="pl-3">
                                    <template v-for="(data, index) in titleList">
                                        <div :key="index" :value="data" class="pointer dropdown-items" @click="selectedTitle(data);">
                                            <span class="jobstatusname">{{ data }}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Office and Mobile phone -->
            <div class="row">
                <div class="col-sm-6">
                    <!-- Office Phone -->
                    <div class="form-group">
                        <label for="">
                            {{ $t('label.office') }}
                            {{ $t('label.phone') }}
                        </label>
                        <input type="text" class="form-control" v-model="newContact.office_phone" v-mask="'###-###-####'" autocomplete="password">
                    </div>
                </div>
                <div class="col-sm-6">
                    <!-- Mobile Phone -->
                    <div class="form-group">
                        <label>
                            {{ $t('label.mobile') }}
                            {{ $t('label.phone') }}
                        </label>
                        <input type="text" class="form-control" v-model="newContact.mobile_phone" v-mask="'###-###-####'" autocomplete="password">
                    </div>
                </div>
            </div>

            <!-- Email and Status -->
            <div class="row">
                <div class="col-sm-6">
                    <!-- Email -->
                    <div class="form-group">
                        <label for="">{{ $t('label.email') }}</label>
                        <input type="text" class="form-control" v-model="newContact.email" :class="{ 'border-danger': formErrors.email }" autocomplete="password">

                        <!-- error display -->
                        <div class="text-danger text-sm mt-1" v-if="formErrors.email">
                            <div v-for="(error, index) in formErrors.email" :key="index">
                                * {{ error }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <!-- Status -->
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input_name">{{ $t('label.status') }}</label>
                        <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                        <div class="dropdown w-100">
                            <div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': ($v.newContact.status.$error), }">
                                    <input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a status') + ' -'" v-model="$v.newContact.status.$model" autocomplete="input-field" data-lpignore="true" required readonly>
                                    <div class="input-group-append">
                                        <span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
                                    </div>
                                </div>
                            </div>

                            <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
                                <div class="pl-3">
                                    <template v-for="(data, index) in contactStatuses">
                                        <div :key="index" :value="data.id" class="pointer dropdown-items" @click="selectedStatus(data);">
                                            <span class="statusname">{{ data }}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Notes -->
            <div class="form-group">
                <label>{{ $t('label.notes') }}</label>
                <textarea class="form-control" v-model="newContact.notes" autocomplete="password"></textarea>
            </div>
        </div>

        <!-- Form Buttons -->
        <div class="card-footer">
            <div class="d-flex">
                <button type="button" class="btn btn-primary" @click="saveContact()">
                    {{ (isEdit) ? $t('label.update') : $t('label.create') }}
                    {{ $t('label.contact') }}
                </button>
                <div class="ml-auto">
                    <button type="button" class="btn btn-link" @click="cancelAdd()">
                        {{ $t('label.cancel') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';
import { required } from 'vuelidate/lib/validators';
export default {
    props: ['customerId', 'selectedContact'],
    emits: ['cancelFnc', 'returnContact'],
    data() {
        return {
            newContact: {},
            titleList: [],
            contactStatuses: [],
            formErrors: {},
            isEdit: false
        }
    },
    validations: {
        newContact: {
            status: { required },
            title: { required },
        }
    },
    created() {
        this.loadContactTitles();
        this.loadContactStatuses();
        this.resetForm();
        // is this an edit?
        if (this.selectedContact) {
            //console.log(this.selectedContact);
            this.newContact = this.selectedContact;
            this.isEdit = true;
        }
    },
    methods: {
        loadContactTitles() {
            axios
                .get('/loadContactTitles')
                .then(response => {
                    this.titleList = response.data;
                })
                .catch(err => {
                    console.log('ERROR: ', err);
                    bus.$emit('banner-error', { message: err, });
                });
        },
        loadContactStatuses() {
            axios
                .get('/loadContactStatuses')
                .then(response => {
                    this.contactStatuses = response.data;
                })
                .catch(err => {
                    console.log('ERROR: ', err);
                    bus.$emit('banner-error', { message: err, });
                });
        },
        // return cancel back to parent
        cancelAdd() {
            this.resetForm();
            this.$emit('cancelFnc');
        },
        selectedTitle(data) {
            this.newContact.title = data;
        },
        selectedStatus(data) {
            this.newContact.status = data;
        },
        //Add new contact and then send the new contact back to parent list.
        saveContact() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                let endpoint = 'createCustomerContact',
                    successMsg = 'Added';
                if (this.isEdit) {
                    endpoint = 'updateCustomerContact';
                    successMsg = 'Updated';
                }
                axios
                    .post(`/${endpoint}`, this.newContact)
                    .then(response => {
                        response.data.isEdit = this.isEdit;
                        bus.$emit('banner-success', { message: `Contact ${successMsg} Successfully!` });
                        //return the new contact back to the contact list parent
                        this.$emit('returnContact', response.data);
                    })
                    .catch(err => {
                        // catch laravel validation errors
                        if (err.response.status == 422) {
                            this.formErrors = err.response.data.errors;
                        }
                        else {
                            console.error('Unknown Error Ocurred: ', err);
                        }
                    });
            }
        },
        resetForm() {
            this.newContact = {
                full_name: null,
                customer_id: this.customerId,
                status: 'Active',
                title: 'Superintendent'
            };
        },
    },
}
</script>
