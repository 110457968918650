<template>
	<modal :name="modalName" @closed="onClose()" :classes="'rounded'" :min-height="400" width="525px" :scrollable="true" height="auto" :reset="true" :adaptive="true">
		<div class="modal-content">
			<div class="modal-header border-bottom d-block bg-default text-white">
				<button type="button" class="close text-white" aria-label="Close" @click="closeFunc()">
					<i class="ri-close-line"></i>
				</button>
				<div class="text-center pt-50">
					<i class="ri-todo-line ni-4x w-100 color-gray-300"></i>
					<p class="text-uppercase m-0">{{ $t('label.adjust remaining job tasks') }}</p>
					<p>Do you want to automatically adjust the remaining Job Tasks by {{ dateDiff }} day(s) as well? Below are the affected Tasks.</p>
				</div>
			</div>
			<div class="modal-body p-0">
				<div class="table-responsive">
					<table class="table table-flush m-0" id="datatable-basic">
						<thead class="thead-light">
							<tr>
								<th scope="col">{{ $t('label.task name') }}</th>
								<th scope="col" class="text-right">{{ $t('label.old start date') }}</th>
								<th scope="col" class="text-right">{{ $t('label.new start date') }}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(data, index) in allTasksByJob" :key="index">
								<td>{{ data.name }}</td>
								<td class="text-right">{{ data.old_start_date }}</td>
								<td class="text-right">{{ data.start_date }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="modal-footer border-top pt-5 bg-default brx-7">
				<button type="button" class="btn btn-secondary mr-auto" @click="updateFunc(true);">Yes, Automatically Adjust</button>
				<button type="button" class="btn btn-default ml-auto close2addnote" @click="updateFunc(false)">No, Leave Existing Dates</button>
			</div>
		</div>
	</modal>
</template>

<script>
export default {
	props: ['newId', 'selectedTask'],
	emits: ['customUpdateMethod', 'onClose'],
	data() {
		return {
			modalName: "moveDateConfirmation" + this.newId,
			allTasksByJob: [],
			dateDiff: null
		}
	},
	mounted() {
		this.initConfirmation();
	},
	methods: {
		initConfirmation() {
			// Prevent asking confirmation when no tasks will be affected
			this.getAllTasksByJob().then(() => {
				if (this.allTasksByJob.length > 0) {
					this.$modal.show(this.modalName);
				} else {
					this.$emit('customUpdateMethod', false);
					this.closeFunc();
				}
			}).catch(err => console.log(err));
		},

		getAllTasksByJob() {
			this.loader = this.$loading.show();

			this.dateDiff = this.selectedTask.date_diff;
			// return axios.post('/get-job-tasks-for-date-adjust', this.selectedTask).then(response => {
			return axios.post('/get-job-tasks-for-date-adjust', this.selectedTask).then(response => {
				this.allTasksByJob = response.data.result;

				if (this.allTasksByJob.length > 0) {
					this.allTasksByJob.forEach(data => {
						data.old_start_date = moment(data.start_date).format('MM/DD/YYYY');
						data.old_due_date = moment(data.due_date).format('MM/DD/YYYY');
						data.start_date = moment(data.new_start_date).format('MM/DD/YYYY');
						data.due_date = moment(data.new_due_date).format('MM/DD/YYYY');
					});
				}

				this.loader.hide();
			}).catch(err => {
				//console.log('list carriers error:', error)
			});
		},
		updateFunc(val) {
			if (val) {
				this.loader = this.$loading.show();
				axios.post('/adjust-date-to-other-tasks', { tasks: this.allTasksByJob })
					.then(response => {
						this.loader.hide();
					})
					.catch(err => {
						//console.log('list carriers error:', error)
					});
			}

			this.$emit('customUpdateMethod', val);
			this.closeFunc();
		},
		closeFunc() {
			this.onClose();
			this.$modal.hide(this.modalName);
		},
		onClose() {
			this.$emit('onClose', false);
		}
	},
}
</script>
