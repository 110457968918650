<template>
    <div v-click-outside="closeNotification" class="task-offcanvas-collapse" :class="toggleSidebar ? 'open' : ''">
        <div class="task-header">
            <div class="row m-0 align-items-center">
                <div v-if="task.task_status" class="col p-0">
                    <button v-if="task.task_status.name != 'Completed'" class="btn btn-outline-success py-1 px-3" @click="markAsComplete(task)">
                        <i class="ri-checkbox-circle-line"></i> {{ $t('label.mark as complete') }}
                    </button>
                </div>
                <div v-if="task.job" class="col-1 p-0">
                    <router-link class="close" :to="{ path: `/jobs/edit/${task.job.job_number.job_number}` }">
                        <i class="ri-external-link-line"></i>
                    </router-link>
                </div>

                <!-- 3 dot dropdown -->
                <div class="col-1 p-0">
                    <button type="button" class="close" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ri-more-line"></i>
                    </button>
                    <div v-if="task.task_status" class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                        <!-- Edit Task -->
                        <a class="dropdown-item text-default pointer" data-toggle="modal" @click="onClickEditModal(task)">
                            <i class="ri-pencil-line"></i> {{ $t('label.edit task') }}
                        </a>
                        <div class="dropdown-divider"></div>

                        <!-- Delete Task -->
                        <a class="dropdown-item text-danger pointer" @click="showDeleteModal(task.id)">
                            <i class="ri-delete-bin-line"></i> {{ $t('label.delete task') }}
                        </a>
                    </div>
                </div>

                <!-- Close off canvas -->
                <div class="col-1 p-0">
                    <button type="button" class="close" @click="activeState = 1; toggleSidebar = false">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="task-body">
            <div class="sections">
                <div class="m-0 px-0 py-2 mb--4 border-bottom d-flex align-items-center">
                    <!-- Task Name -->
                    <div class="col-7">
                        <h2 class="font-weight-bold ml--1">{{ task.name }}</h2>
                    </div>
                    <!-- Task Status -->
                    <div class="col-5" v-if="task.task_status">
                        <div class="d-flex align-items-center float-right">
                            <span class="badge badge-dot">
                                <i :style="`background-color: ${task.task_status.status_color}`"></i>
                            </span>
                            <span>{{ task.task_status ? task.task_status.name : '' }}</span>
                        </div>
                    </div>
                </div>
                <div class="section" id="ql-task-information">
                    <div class="row m-0 mt--3">
                        <!-- Task Type -->
                        <div class="col-5 mr-4 p-0 border-bottom">
                            <h5 class="text-muted font-weight-normal">{{ $t('label.task type') }}</h5>
                            <span>{{ task.task_type ? task.task_type.name : '' }}</span>
                        </div>
                        <!-- Date Range -->
                        <div class="col-6 p-0 border-bottom">
                            <h5 class="text-muted font-weight-normal">{{ $t('label.date') }}</h5>
                            <span>{{ task.date_range_text }}</span>
                        </div>
                    </div>
                    <div v-if="moment(task.start_date).format('MM/DD/YYYY') == moment(task.due_date).format('MM/DD/YYYY')" class="row m-0 mt-3">
                        <!-- Start Time -->
                        <div class="col-5 mr-4 p-0 border-bottom">
                            <h5 class="text-muted font-weight-normal">{{ $t('label.start time') }}</h5>
                            <span>{{ task.start_time ? moment(task.start_time).format('h:mm a') : '' }}</span>&nbsp;
                        </div>
                        <!-- End Time -->
                        <div class="col-6 p-0 border-bottom">
                            <h5 class="text-muted font-weight-normal">{{ $t('label.end time') }}</h5>
                            <span>{{ task.end_time ? moment(task.end_time).format('h:mm a') : '' }}</span>&nbsp;
                        </div>
                    </div>
                    <div class="row m-0 mt-3">
                        <!-- Assignees -->
                        <div class="col-12 p-0 border-bottom">
                            <h5 class="text-muted font-weight-normal">{{ $t('label.assignees') }}</h5>
                            <div class="">
                                <template v-for="(d, i) in task.assigned_to">
                                    <img :key="i" v-if="d.user" :src="d.user.picture" alt="" class="avatar-img rounded-circle" :class="{ 'crew-member-avatar': 0 < i }">
                                </template>
                                &nbsp;
                            </div>
                        </div>
                    </div>
                    <div class="row m-0 mt-3">
                        <!-- Task Description -->
                        <div class="col-12 p-0 border-bottom">
                            <h5 class="text-muted font-weight-normal">{{ $t('label.task description') }}</h5>
                            <p>{{ task.description }}&nbsp;</p>

                        </div>
                    </div>
                    <div class="row m-0 mt-3">
                        <!-- Task Job ID (Job Number) -->
                        <div class="col-5 mr-4 p-0 border-bottom">
                            <h5 class="text-muted font-weight-normal">{{ $t('label.job id') }}</h5>
                            <span v-if="task.job">{{ task.job.job_number.job_number }}</span>
                        </div>
                        <!-- Time Estimate -->
                        <div class="col-6 p-0 border-bottom">
                            <h5 class="text-muted font-weight-normal">{{ $t('label.time estimate') }}</h5>
                            <span>{{ task.time_estimate }}</span>
                        </div>
                    </div>

                    <!-- Job Name link -->
                    <div class="row m-0 mt-3">
                        <div class="col-12 p-0 border-bottom">
                            <h5 class="text-muted font-weight-normal">{{ $t('label.job name') }}</h5>
                            <router-link v-if="task.job" :to="{ path: `/jobs/edit/${task.job.job_number.job_number}` }">
                                {{ task.job.job_name }}
                            </router-link>
                        </div>
                    </div>

                    <!-- Customer Name Link -->
                    <div class="row m-0 mt-3">
                        <div class="col-12 p-0 border-bottom">
                            <h5 class="text-muted font-weight-normal">{{ $t('label.customer') }}</h5>
                            <router-link v-if="task.job" :to="{ name: 'customer', params: { customerInfo: task.job.customer, customerId: task.job.customer.id } }">
                                {{ task.job.customer ? task.job.customer.name : '&nbsp;' }}
                            </router-link>
                        </div>
                    </div>

                    <!-- Address -->
                    <div class="row m-0 mt-3">
                        <div class="col-12 p-0 border-bottom">
                            <h5 class="text-muted font-weight-normal">{{ $t('label.address') }}</h5>
                            <div class="d-flex align-items-center justify-content-between pointer" @click="openMap">
                                <span v-if="task.job" class="text-truncate" style="width: 278px">{{ task.job.address_1 }}, {{ task.job.city }}, {{ task.job.state }}, {{ task.job.postal_code }}</span>
                                <span class="text-muted"><i class="ri-road-map-line heading-title"></i></span>
                            </div>
                        </div>
                    </div>

                    <!-- Job Manager -->
                    <div class="row m-0 mt-3">
                        <div class="col-12 p-0 border-bottom">
                            <h5 class="text-muted font-weight-normal">{{ $t('label.job manager') }}</h5>
                            <span v-if="task.job">
                                <img class="avatar-img rounded-circle" v-if="task.job.job_manager" :src="task.job.job_manager.picture" alt="">
                                <span>{{ task.job.job_manager ? task.job.job_manager.name : '' }}&nbsp;</span>
                            </span>
                        </div>
                    </div>

                    <!-- Task Custom Fields -->
                    <div class="row m-0 mt-3" v-for="(cfField, idx) in task.custom_field" :key="idx">
                        <div class="col-12 p-0 border-bottom">
                            <h5 class="text-muted font-weight-normal">{{ cfField.name }}</h5>
                            <span v-if="cfField.custom_field_values.length">
                                <!-- Check Box Values -->
                                <template v-if="cfField.type === 'checkbox'">
                                    <span v-for="(val, idx1) in cfField.custom_field_values[0].value" :key="idx1">
                                        <span v-if="idx < cfField.custom_field_values[0].value.length - 1"> {{ val }},&nbsp;</span>
                                        <span v-else>{{ val }}</span>
                                    </span>
                                </template>
                                <!-- Other Values -->
                                <template v-else>
                                    {{ cfField.custom_field_values[0].value }}&nbsp;
                                </template>
                            </span>
                            <span v-else>&nbsp;</span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <h5 class="text-muted font-weight-normal">{{ $t('label.field user') }}</h5>
                    <a href="#" class="pointer" data-toggle="modal" @click="$modal.show('tasknotesimagesmodal')">
                        {{ $t('label.notes and files') }}
                    </a>
                </div>
                <hr>
                <CrewTimeSection :class="activeState == 3 ? 'padding-top-75' : ''" :taskid="task.id" @tgglOffCvsClick="toggleOutsideClick" @crewTimeUpdate="taskOffCanvasUpdate"></CrewTimeSection>
                <hr>
                <MaterialsSection v-if="task.job" :task="task" :taskMaterials="taskMaterials" @reload="getTaskUsedMaterials"></MaterialsSection>
                <!-- <hr> -->
                <div style="height: 100px !important"></div>
            </div>
            <!-- <div class="quick-links sticky-top position-relative" style="z-index: 1;">
                <a @click="goToSection(1)" class="dropdown-item" :class="activeState == 1 ? 'active' : ''" href="#ql-task-information" data-toggle="tooltip" data-placement="left" title="Task Information:"><i class="ri-information-line"></i></a>
                <a @click="goToSection(3)" class="dropdown-item" :class="activeState == 3 ? 'active' : ''" href="#ql-crew-time" data-toggle="tooltip" data-placement="left" title="Crew/Time"><i class="ri-time-line"></i></a>
                <a @click="goToSection(7)" class="dropdown-item" :class="activeState == 7 ? 'active' : ''" href="#ql-materials" data-toggle="tooltip" data-placement="left" title="Materials"><i class="ri-pencil-ruler-2-line"></i></a>
                <a @click="goToSection(9)" class="dropdown-item" :class="activeState == 9 ? 'active' : ''" href="#ql-add" title="Add" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="ri-add-line"></i></a>
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                    add job note
                    <a class="dropdown-item pointer" @click="showAddNoteModal()">
                        <i class="ri-draft-line"></i> {{ $t('label.add job note') }}
                    </a>
                    add materials
                    <a class="dropdown-item pointer" @click="showAddMaterialModal()">
                        <i class="ri-folders-line"></i> {{ $t('label.add material') }}
                    </a>
                    add job file
                    <a class="dropdown-item pointer" @click="showAddJobFileModal()">
                        <i class="ri-folders-line"></i> {{ $t('label.add file') }}
                    </a>
                    <div class="dropdown-divider"></div>
                    create invoice
                    <a class="dropdown-item text-default pointer" @click="createInvoice()">
                        <i class="ri-bill-line"></i> {{ $t('label.create invoice') }}
                    </a>
                    process payment
                    <a class="dropdown-item text-default pointer" @click="processPayment()">
                        <i class="ri-bank-card-line"></i> {{ $t('label.process payment') }}
                    </a>
                </div>
            </div> -->
        </div>

        <!-- Edit Task Modal -->
        <modal class="py-4" name="editTaskModal_offCanvas" height="auto" width="100%" :classes="'rounded'" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
            <modal-task :jobId="jobid" :source="source" :isTemplate="false" :task="selectedTask" @getTasks="reloadTaskOffCanvas" @cancelFnc="$modal.hide('editTaskModal_offCanvas'); toggleOutsideClick(true)" />
        </modal>
        <!-- Delete Modal -->
        <modal-delete2 v-if="deleteId" :newId="deleteId" :title="$t('label.confirm delete task')" @customDeleteMethod="deleteTask" @onClose="resetDeleteModal">
        </modal-delete2>
        <!-- Field User Task Notes and Images -->
        <modal class="py-4" name="tasknotesimagesmodal" height="auto" width="98%" :max-width="1024" :reset="true" :scrollable="true" :adaptive="true">
            <FieldDisplayTaskNotesImages :officeUser="true" :task="task" @cancelFnc="$modal.hide('tasknotesimagesmodal')" @returnFnc="$modal.hide('tasknotesimagesmodal')">
            </FieldDisplayTaskNotesImages>
        </modal>

        <!-- Job Files -->
        <!-- <modal class="py-4" name="addJobFile_offCanvas" 
            height="auto"
            :classes="'rounded'"
            :scrollable="true" 
            :reset="true"             
            :adaptive="true">
            <job-file-upload 
                v-if="task.job" 
                :jobid="task.job.id" 
                @reload="reloadTaskOffCanvas"
                @close-add-file-modal="hideAddJobFileModal">
            </job-file-upload>
        </modal> -->

        <!-- Add Job Notes Modal -->
        <!-- <modal class="py-4" name="addJobNote_offCanvas" 
            height="auto"
            :classes="'rounded'"
            :scrollable="true" 
            :reset="true"             
            :adaptive="true">
			<job-notes-modify 
                v-if="task.job" 
                :jobid="task.job.id" 
                @close-add-note-modal="hideAddNoteModal"
            ></job-notes-modify>
		</modal> -->

        <!-- Add Material Modal -->
        <!-- <modal class="py-4" name="materialModal_offCanvas"
			height="auto"
            :classes="'rounded'"
			:max-width="600"
			:scrollable="true" 
            :reset="true" 
            :adaptive="true">
            <MaterialModal v-if="task.job"
                source="add"
                :jobid="task.job.id"
                :material-data="materialData"
                @reload="reloadTaskOffCanvas" 
                @cancelFnc="hideAddMaterialModal"
            ></MaterialModal>
        </modal> -->

        <!-- Invoice Modal -->
        <!-- <modal class="py-4" name="invoiceModal_offCanvas"
			height="auto"
			width="100%"
            :classes="'rounded'"
			:max-width="1200"
			:scrollable="true" 
            :reset="true" 
            :adaptive="true"
            @closed="toggleOutsideClick(true)">
            <ModalInvoice 
                :job="task.job" 
                :invoice-data="invoiceData"
				source="addInvoice" 
                @reloadInvoices="reloadTaskOffCanvas" 
                @cancelFnc="$modal.hide('invoiceModal_offCanvas')"
            ></ModalInvoice>
        </modal> -->

        <!-- Payment Modal -->
        <!-- <modal class="py-4" name="paymentModal_offCanvas"
			height="auto"
			width="100%"
            :classes="'rounded'"
			:max-width="1200"
			:scrollable="true" 
            :reset="true" 
            :adaptive="true">
            <ModalPayment
                :job="task.job" 
                :invoice-data="invoiceData"
                source="addPayment"
                source2="job"
                @reloadPayments="reloadTaskOffCanvas"
                @cancelFnc="$modal.hide('paymentModal_offCanvas')"
            ></ModalPayment>
        </modal> -->
    </div>
</template>
<script>
import bus from '@/utils/event-bus';
import ClickOutside from 'vue-click-outside';
import { mapGetters } from 'vuex';
import CrewTimeSection from './sections/CrewTimeSection.vue';
// import TaskNotesComponent from '@/components/TaskNotesComponent.vue'
// import InvoicesSection from './sections/InvoicesSection.vue'
// import NotesSection from './sections/NotesSection.vue'
import MaterialsSection from './sections/MaterialsSection.vue';
// import FilesSection from './sections/FilesSection.vue'
// import JobFileUpload from '../Jobs/JobFileUpload.vue'
// import JobNotesModify from '../Jobs/JobNotesModify.vue'
// import MaterialModal from './modals/MaterialModal.vue'
// import ModalInvoice from '../ModalInvoice.vue'
// import ModalPayment from '../ModalPayment.vue'
import FieldDisplayTaskNotesImages from '@/components/Field/FieldDisplayTaskNotesImages.vue';
import ModalDelete2 from '@/components/ModalDelete2';
import ModalTask from '@/components/ModalTask.vue';

export default {
    emits: ['taskReloaded'],
    components: {
        CrewTimeSection,
        // InvoicesSection,
        // NotesSection,
        MaterialsSection,
        // FilesSection,
        // JobFileUpload,
        // JobNotesModify,
        // MaterialModal,
        // ModalInvoice,
        // ModalPayment,
        ModalTask,
        ModalDelete2,
        FieldDisplayTaskNotesImages
    },
    data() {
        return {
            companySettings: JSON.parse(localStorage.getItem('company_settings')),
            invoiceData: [],
            materialData: [],
            selectedTask: {},
            source: "",
            jobid: null,
            loader: null,
            deleteId: null,
            activeState: 1,
            modalActive: false,
            toggleSidebar: false,
            openEditModal: false,
            outsideClickActive: true,
            taskMaterials: [],
        }
    },
    computed: {
        ...mapGetters({
            task: 'taskData/task',
        }),
    },
    mounted() {
        bus.$on('openTaskOffCanvas', (id, src) => {
            this.source = src;
            this.openNotification(id);
        });
    },
    methods: {
        async openNotification(id) {
            this.loader = this.$loading.show();
            await this.$store.dispatch('taskData/getTaskById', id);
            this.getTaskUsedMaterials();
            this.loader.hide();
            this.toggleSidebar = true;
            this.activeState = 1;

            this.$store.dispatch('taskData/getCrewTime', { date: null, id: id });
            this.$store.dispatch('taskData/getAllInvoices', this.task.job.id);
            this.$store.dispatch('taskData/getAllJobNotes', this.task.job.id);
            this.$store.dispatch('taskData/getAllJobFiles', this.task.job.id);
        },
        getTaskUsedMaterials() {
            this.taskMaterials = [];
                axios.get(`/get-task-used-materials/${this.task.id}`).then(rsp => {
                    this.taskMaterials = rsp.data.result;
                }).catch(err => console.error(err));
        },
        closeNotification(e) {
            if (this.toggleSidebar && !this.modalActive && this.outsideClickActive) {
                this.activeState = 1;
                this.toggleSidebar = false;
            }
        },
        markAsComplete(data) {
            var request_fields = {
                'task_id': data.id,
            };
            axios.post('/mark-as-complete', request_fields).then(() => {
                this.reloadTaskOffCanvas();
                bus.$emit('banner-success', {
                    message: `Task completed successfully!`,
                });
            }).catch(error => {
                console.log('list carriers error:', error)
            });
        },
        openMap() {
            let complete_address = this.task.job.address_1 + ", " + this.task.job.city + ", " + this.task.job.state + ", " + this.task.job.postal_code;
            window.open('http://maps.google.com/?q=' + complete_address);
        },
        goToJob() {
            window.location.replace(`/job/${this.task.job.job_number.job_number}/edit`);
        },

        toggleOutsideClick(status) {
            this.outsideClickActive = status;
        },

        setSelectedTask(item) {
            this.selectedTask = item;
        },
        onClickEditModal(item) {
            this.setSelectedTask(item);
            this.toggleOutsideClick(false);

            this.jobid = item.job.id;
            this.$modal.show('editTaskModal_offCanvas')
        },
        onCloseEditModal() {
            this.openEditModal = false;
        },
        reloadTaskOffCanvas() {
            this.openNotification(this.task.id);
            bus.$emit('taskOffCanvasUpdate');
        },
        taskOffCanvasUpdate() {
            bus.$emit('taskOffCanvasUpdate');
        },
        goToSection(section) {
            this.activeState = section;
        },
        showAddJobFileModal() {
            this.modalActive = true;
            this.$modal.show('addJobFile_offCanvas');
        },
        hideAddJobFileModal() {
            this.modalActive = false;
            this.$modal.hide('addJobFile_offCanvas');
        },
        showAddMaterialModal() {
            this.$modal.show('materialModal_offCanvas');
        },
        hideAddMaterialModal() {
            this.$modal.hide('materialModal_offCanvas');
        },
        showAddNoteModal() {
            this.$modal.show('addJobNote_offCanvas');
        },
        hideAddNoteModal() {
            this.$modal.hide('addJobNote_offCanvas');
        },
        createInvoice() {
            this.invoiceData = [];
            this.$modal.show('invoiceModal_offCanvas');
            this.toggleOutsideClick(false);
        },
        processPayment() {
            this.invoiceData = [];
            this.$modal.show('paymentModal_offCanvas');
        },
        // openChatRoom() {
        // 	this.$store.commit('chat/pushChatRoomToActiveChats', this.task.chat_room)
        //     this.activeState = 1;
        //     this.toggleSidebar = false;
        // },
        // scrollToEnd (timeout = 200) {
        //     let content = this.$refs.chatmessages;
        //     setTimeout(() => {
        //         if(content !== undefined)
        //             content.scrollTop = content.scrollHeight;
        //     }, timeout);
        // },
        showDeleteModal(taskId) {
            this.deleteId = taskId
        },
        deleteTask() {
            axios.post('/delete-task/' + this.deleteId).then(response => {
                this.resetDeleteModal();
                bus.$emit('getallwidgets');
                bus.$emit("banner-success", {
                    message: $t('label.Task successfully deleted')
                });

                this.activeState = 1;
                this.toggleSidebar = false;
            }).catch(err => console.log('error:', err));

        },
        resetDeleteModal() {
            this.deleteId = null
        },
    },
    directives: {
        ClickOutside
    }
};
</script>
