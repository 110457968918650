<template>
    <div v-if="task">
        <div class="card mb-0">
            <div class="card-header">
                <div class="row align-items-center">
                    <div class="col-8">
                        <h3 class="mb-0">Piece Rate Allocation</h3>
                    </div>
                </div>
            </div>
            <div class="card-body" v-if="paytype != '1099'">
                <!-- List Piece Rate info -->
                <div class="mb-1">
                    <span class="">
                        Piece Rate Amount:
                    </span>
                    <span>
                        {{ task.piece_rate | toCurrency }}
                    </span>
                </div>

                <!-- Progress bar -->
                <div class="progress" style="height: 25px;background-color: #666;">
                    <div class="progress-bar bg-success" 
                    role="progressbar" 
                    :style="{ width: allocatedPercentage + '%' }">
                        <span class="px-3">
                            Percent Allocated {{ parseFloat(allocatedPercentage).toFixed(2) }}%
                        </span>
                    </div>
                </div>

                <!-- Amount left to allocate -->
                <div class="progress" style="height: 25px; background-color: #666;">
                    <div class="progress-bar bg-warning" 
                    role="progressbar" 
                    :style="{ width: (100 - allocatedPercentage) + '%' }">
                        <span class="px-3">
                            ${{ parseFloat(task.piece_rate - allocatedAmount).toFixed(2) }} Left to Allocate
                        </span>
                    </div>
                </div>

                <!-- user list for allocation -->
                <ul class="list-group">
                    <li class="list-group-item bg-gray-600 text-white text-bold">
                        <div class="row">
                            <div class="col">User</div>
                            <div class="col text-center">Percentage</div>
                            <div class="col text-center">Amount</div>
                            <div class="col text-center">Task Manager Notes</div>
                        </div>
                    </li>
                    <!-- Loop Crew memebers -->
                    <li class="list-group-item" 
                    v-for="(user, idx) in taskUsers" 
                    :key="user.id" 
                    :class="{ 'bg-gray-100': idx % 2 }">
                        <!-- Compensation is Piece rate -->
                        <div v-if="user.payType.indexOf('Piece Rate') >= 0 || user.payType.indexOf('1099') >= 0" class="row align-items-center">
                            <!-- User name -->
                            <div class="col-2">{{ user.user.name }}</div>

                            <!-- Percentage -->
                            <div class="col-4">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <button class="btn btn-outline-primary" type="button" @click="changePercent(user, 'up')"><i class="ri-arrow-up-line"></i></button>
                                    </div>
                                    <input 
                                    type="number" 
                                    class="form-control" 
                                    step="5" 
                                    onfocus="this.select()" 
                                    @blur="onPrecentBlur(user)" 
                                    v-model="user.pieceRatePercentage">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">%</span>
                                    </div>
                                    <div class="input-group-append">
                                        <button 
                                        class="btn btn-outline-primary" 
                                        type="button" 
                                        @click="changePercent(user, 'down')"><i class="ri-arrow-down-line"></i></button>
                                    </div>
                                </div>
                            </div>

                            <!-- Dollar Amount -->
                            <div class="col-4">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <button class="btn btn-outline-primary" 
                                        type="button" 
                                        @click="changeAmt(user, 'up')"><i class="ri-arrow-up-line"></i></button>
                                    </div>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">$</span>
                                    </div>
                                    <input type="number" 
                                    class="form-control" 
                                    step="5" 
                                    onfocus="this.select()" 
                                    @blur="onAmountBlur(user)"
                                    v-model="user.pieceRateAmount">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-primary" 
                                        type="button" 
                                        @click="changeAmt(user, 'down')"><i class="ri-arrow-down-line"></i></button>
                                    </div>
                                </div>
                            </div>
                            <!-- Task Manager Notes -->
                            <div class="col-2">
                                {{ user.notes }}
                            </div>
                        </div>
                        <!-- Compensation other than Piece rate -->
                        <div v-else class="row align-items-center">
                            <div class="col">{{ user.user.name }}</div>
                            <div class="col-8">Employee is compensated "{{ user.payType }}"</div>
                            <!-- Notes -->
                            <div class="col">
                                {{ user.notes }}
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item text-right">
                        <button type="button" class="btn btn-primary" @click="$modal.hide('reviseTaskPieceRateModal')">Close</button>
                    </li>
                </ul>
            </div>
            <div v-else class="card-body">
                <div class="mb-1">
                    <div class="form-group">
                        <label class="form-control-label">Piece Rate Amount: </label>
                        <span class="text-xs text-danger">&nbsp;* {{ $t('label.required') }}</span>
                        <input type="number" class="form-control" name="piece_rate" v-model="$v.taskPieceRate.$model" placeholder="Amount" required autocomplete="input-field" data-lpignore="true">
                        <div v-if="$v.taskPieceRate.$error && !$v.taskPieceRate.required" class="text-danger text-sm">*&nbsp; Must Be Greater Than Zero.</div>
                    </div>
                </div>
                <div class="mb-1">
                    <div class="form-group">
                        <label class="text-sm">Enter the Reason for Amount Revision:</label>
                        <div class="input-group">
                            <textarea v-model="$v.amountChangeNote.$model" name="notes" class="form-control" :placeholder="$t('label.notes')"></textarea>
                            <div v-if="$v.amountChangeNote.$error && !$v.amountChangeNote.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
                        </div>                   
                    </div>
                </div>
                <div class="list-group-item text-right">
                    <button type="button" class="btn btn-primary" @click="changedTotal">Close</button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import bus from '@/utils/event-bus'
import { minValue,required } from 'vuelidate/lib/validators';

export default {
    props: ['task','paytype','selectedDay'],
    components: {},
    emits: [],
    data() {
        return {
            taskUsers: [],
            taskPieceRate: null,
            allocatedPercentage: 0,
            allocatedAmount: 0,
            incrementValue: 5,
            taskPieceRate: 0,
            currSelectedDay: null,
            amountChangeNote: null,
            prevPieceRate: null,
        }
    },
    validations: {
        taskPieceRate: {
            required,
            minValue: minValue(0.01)},
        amountChangeNote: { required }
    },
    mounted() {
        this.taskPieceRate = this.task.piece_rate;
        this.prevPieceRate = this.task.piece_rate;
        this.currSelectedDay = this.selectedDay;
        // close modal if task isn't found
        if (!this.task) {
            setTimeout(() => {
                this.$modal.hide('reviseTaskPieceRateModal')
            }, 100)
        }
        else {
            this.setupUserAllocations()
        }
    },
    methods: {
        changedTotal() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                var request_fields = {
                    'piece_rate_1099': this.taskPieceRate,
                    'selected_day':this.currSelectedDay,
                    'task_id':this.task.id,
                    'previous_piece_rate_1099': this.prevPieceRate,
                    'notes': this.amountChangeNote,
                }
                
                axios.post('/piece-rate-1099-updated',request_fields).then(res => {

                    this.$emit('cancelFnc1099',res.data.result);
                    // this.$modal.hide('reviseTaskPieceRateModal');
                })
                .catch(err => {
                        //console.log('list carriers error:', error)
                });
            }
        },
        // Load any previously saved piece rate
        async setupUserAllocations() {
            this.taskPieceRate = JSON.parse(JSON.stringify(this.task.piece_rate))
            let taskCrew = JSON.parse(JSON.stringify(this.task.assigned_to))
            this.$spGetUserAllocations(this.task.id, taskCrew, this.allocatedAmount, this.allocatedPercentage).then((result) => {
                this.updateLocalValues(result)
            })
        },
        // Adjust percentage based on amount entered
        async onAmountBlur(user) {
            user.pieceRateAmount = parseFloat(user.pieceRateAmount)
            user.pieceRatePercentage = parseFloat((user.pieceRateAmount / this.taskPieceRate) * 100)
            let result = await this.$spValidateAllocation(user, this.taskUsers, this.taskPieceRate, this.task.start_date)
            this.updateLocalValues(result)
        },
        // Adjust dollar amount based on percentage entered
        async onPrecentBlur(user) {
            user.pieceRatePercentage = parseFloat(user.pieceRatePercentage)
            user.pieceRateAmount = parseFloat((user.pieceRatePercentage * this.taskPieceRate) / 100)
            let result = await this.$spValidateAllocation(user, this.taskUsers, this.taskPieceRate, this.task.start_date)
            this.updateLocalValues(result)
        },
        async changeAmt(user, direction) {
            let userPieceRateAmount = parseFloat(user.pieceRateAmount)
            //decrement by this.incrementValue
            if (direction == "down") {
                user.pieceRateAmount = (userPieceRateAmount - this.incrementValue)
            }
            //increment by this.incrementValue
            else {
                user.pieceRateAmount = (userPieceRateAmount + this.incrementValue)
            }
            //Update percentage
            user.pieceRatePercentage = parseFloat((user.pieceRateAmount / this.taskPieceRate) * 100)
            let result = await this.$spValidateAllocation(user, this.taskUsers, this.taskPieceRate, this.task.start_date)
            this.updateLocalValues(result)
        },
        async changePercent(user, direction) {
            user.pieceRatePercentage = parseFloat(user.pieceRatePercentage)
            //decrement by this.incrementValue
            if (direction == "down") {
                user.pieceRatePercentage = ((user.pieceRatePercentage * 1) - this.incrementValue)
            }
            //increment value by this.incrementValue
            else {
                user.pieceRatePercentage = (user.pieceRatePercentage + this.incrementValue);
            }
            //update amount
            user.pieceRateAmount = parseFloat((user.pieceRatePercentage * this.taskPieceRate) / 100)
            let result = await this.$spValidateAllocation(user, this.taskUsers, this.taskPieceRate, this.task.start_date)
            this.updateLocalValues(result)
        },
        updateLocalValues(result) {
            this.taskUsers = result.taskUsers
            this.allocatedAmount = (result.totalAllocatedAmount) ? result.totalAllocatedAmount : 0.00
            this.allocatedPercentage = (result.totalAllocatedPercentage) ? result.totalAllocatedPercentage : 0.00
            setTimeout(() => {
                bus.$emit('payrollAdjustPieceRateAllocations_Update')
            }, 1000)
        },
    }
}
</script>

<style></style>