<template>
    <div class="materials-section" id="ql-materials">
        <div class="d-flex align-items-center justify-content-between px-2 my--2" @click="toggleSection">
            <span class="d-flex align-items-center pointer">
                <i :class="openSection ? 'ri-arrow-down-s-line' : 'ri-arrow-right-s-line'"></i>
                <h5 class="modal-title">{{ $t('label.materials') }}</h5>&nbsp;

                <span class="text-sm" v-if="task.material_source == 2">({{ $t('label.using task materials') }})</span>
                <span class="text-sm" v-else-if="task.material_source == 1">({{ $t('label.using job materials') }})</span>
                <span class="text-sm" v-else>({{ $t('label.No Materials Needed') }})</span>
            </span>
            <span v-if="task.material_source != 0">
                <button class="btn btn-sm btn-default" type="button" @click="addModal()">
                    {{ $t('label.add material') }}
                </button>
            </span>
        </div>
        <div v-if="openSection">
            <table class="table table-flush table-sm mt-3 border-top border-bottom">
                <tbody>
                <template v-if="taskMaterials.length > 0">
                    <tr v-for="(data, index) in taskMaterials" v-bind:key="index">
                        <td>
                            <div class="d-flex align-items-center justify-content-between">
                                <span class="material pointer" @click="editModal(data)">{{ data.material }}</span>
                                <span class="quantity">({{ data.quantity }}) {{ data.unit }}</span>
                            </div>
                            <div class="d-flex align-items-center justify-content-between">
                                <span class="location">{{ data.location }}</span>
                                <span class="notes" v-if="data.notes"><i class="ri-draft-line"></i></span>
                            </div>
                        </td>
                        <td class="text-right" style="width: 15%">
                            <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-ellipsis-v"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                <a class="dropdown-item" @click="editModal(data)">
                                    <i class="ri-pencil-line"></i> {{ $t('label.edit material') }}
                                </a>
                                <a class="dropdown-item text-danger" @click="deleteTempMaterialNotify(data)">
                                    <i class="ri-delete-bin-line"></i> {{ $t('label.delete material') }}
                                </a>
                            </div>
                        </td>
                    </tr>
                   </template>
                    <template v-else>
                        <tr>
                            <div class="text-center">
                                {{ $t('label.no materials') }}
                            </div>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <modal class="py-4" name="editMaterialsModal" height="auto" width="100%" :max-width="600" :scrollable="true" :reset="true" :adaptive="true">
            <MaterialModal source="edit"
            :task="task"
            :material-data="materialData" 
            @reload="getTaskUsedMaterials"
            @cancelFnc="$modal.hide('editMaterialsModal')">
            </MaterialModal>
        </modal>

        <!-- Template Task Delete Modal -->
        <modal-delete-component 
        v-if="showTempTaskDlt" 
        :new-id="'Material'" 
        :title="'ARE YOU SURE YOU WANT TO DELETE THIS MATERIAL'" 
        @onClose="deleteTempMaterialNotify" @customDeleteMethod="deleteJobMaterial">
        </modal-delete-component>

        <!-- Add Material Modal -->
        <modal class="py-4"
			name="addMaterialsModal"
			height="auto"
			width="100%"
			:max-width="600"
			:scrollable="true" 
            :reset="true" 
            :adaptive="true">
            <MaterialModal 
                source="add"
                :task="task"
                @reload="getTaskUsedMaterials" 
                @cancelFnc="$modal.hide('addMaterialsModal')">
            </MaterialModal>
                <!-- :jobid="task.job.id" -->
                <!-- :material-data="materialData" -->
        </modal>
    </div>
</template>
<script>

import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex';
import ModalDelete2Vue from '../../ModalDelete2.vue';
import MaterialModal from '../modals/MaterialModal.vue';

export default {
    props: ['task','taskMaterials'],
    components: {
        MaterialModal,
        modalDeleteComponent: ModalDelete2Vue
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            materialData: [],
            openSection: true,
            showTempTaskDlt: false,
        }
    },
    mounted() {
        bus.$on('deleteFMaterial', () => {
            this.deleteJobMaterial();
        });
    },
    methods: {
        toggleSection() {
            this.openSection = !this.openSection;
        },
        getTaskUsedMaterials() {
            this.$emit('reload');     
        },
        addModal() {
            this.$modal.show('addMaterialsModal');
        },
        editModal(data) {
            this.materialData = data;
            this.$modal.show('editMaterialsModal');
        },
        deleteTempMaterialNotify(data) {
            this.showTempTaskDlt = true;
            this.job_material_id = data.id;
        },
        deleteJobMaterial() {
            let apiUri = '/',
                    busToEmit = '';

                if(this.task.material_source == 2) {
                    apiUri += 'taskmaterials/delete/';
                    busToEmit = 'MaterialsSectionDltTaskMat';
                } else if(this.task.material_source == 1) {
                    apiUri += 'jobmaterials/delete/';
                    busToEmit = 'MaterialsSectionDltJobMat';
                }

				axios.post(apiUri + this.job_material_id).then(() => {
                    this.showTempTaskDlt = false;
                    bus.$emit(busToEmit);
					this.getTaskUsedMaterials();
				}).catch(err => {
					//console.log('list carriers error:', error)
				});
			},

    },

};
</script>
