<template>
    <div>
        <div class="page-invoice-report">
            <div class="header bg-primary pb-6 mobile-p-0">
                <div class="container-fluid">
                    <div class="header-body">
                        <div class="row align-items-center py-4">
                            <div class="col-lg-6">
                                <breadcrumbs :title="$t('label.reports')">
                                    <!-- Breakcrumb slots -->
                                    <li class="breadcrumb-item">
                                        <router-link class="text-primary pointer" :to="{ name: 'reports' }">{{ $t('label.reports') }}</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        {{ $t('label.invoice report') }}
                                    </li>
                                </breadcrumbs>
                            </div>
                        </div>
                        <div class="row hidden-md-down">
                            <div class="col-xl-3 col-md-6">
                                <div class="card card-stats">
                                    <!-- Card body -->
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col" style="min-height: 68px;">
                                                <h5 class="card-title text-uppercase text-muted mb-0">{{ $t('label.sent invoices') }}</h5>
                                                <span class="h2 font-weight-bold mb-0">{{ countSentInvoices }}</span>
                                            </div>
                                            <div class="col-auto">
                                                <div class="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                                    <i class="ri-bill-line p-0"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-6">
                                <div class="card card-stats">
                                    <!-- Card body -->
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col" style="min-height: 68px;">
                                                <h5 class="card-title text-uppercase text-muted mb-0">{{ $t('label.draft invoices') }}</h5>
                                                <span class="h2 font-weight-bold mb-0">{{ countDraftInvoices }}</span>
                                            </div>
                                            <div class="col-auto">
                                                <div class="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                                                    <i class="ri-bill-line p-0"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-6">
                                <div class="card card-stats">
                                    <!-- Card body -->
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col" style="min-height: 68px;">
                                                <h5 class="card-title text-uppercase text-muted mb-0">{{ $t('label.cancelled invoices') }}</h5>
                                                <span class="h2 font-weight-bold mb-0">{{ countCancelledInvoices }}</span>
                                            </div>
                                            <div class="col-auto">
                                                <div class="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                    <i class="ri-bill-line p-0"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-6">
                                <div class="card card-stats">
                                    <!-- Card body -->
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col" style="min-height: 68px;">
                                                <h5 class="card-title text-uppercase text-muted mb-0">{{ $t('label.paid invoices') }}</h5>
                                                <span class="h2 font-weight-bold mb-0">{{ countPaidInvoices }}</span>
                                            </div>
                                            <div class="col-auto">
                                                <div class="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                    <i class="ri-bill-line p-0"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--Report-->
            <div class="container-fluid mt--6 mobile-mt-140p" id="invoiceHeader">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <div class="card-header">
                                <div class="row align-items-center">
                                    <div class="w-100 d-flex align-items-center">
                                        <i class="ri-bill-line"></i>
                                        <h3 class="mb-0">{{ $t('label.invoices') }}</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body" v-if="showSearchBox">
                                <div class="row">
                                    <div class="col-lg-12 col-sm-12">
                                        <div class="form-group mb-0">
                                            <label class="form-control-label text-muted" for="input-name">{{ $t('label.filter by') }}</label>
                                        </div>
                                        <div class="form-group row m-0 mb-3">
                                            <div class="mr-4 mr-xs-2" v-for="(data, index) in allinvoicestatus" :key="index">
                                                <input name="invoice_status" class="form-control-input" :id="data.name" type="checkbox" :value="data.id" v-model="filter.invoice_status">
                                                <label class="form-control-label text-muted font-weight-normal cursor-pointer2" :for="data.name">&nbsp;{{ data.name }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Start Date -->
                                    <div class="col-12 col-md-3">
                                        <div class="form-group">
                                            <label class="form-control-label text-muted" for="input-name">{{ $t('label.start date') }}</label>
                                            <date-picker v-model="filter.startDate" class="full-width" input-class="form-control" value-type="format" format="MM/DD/YYYY">
                                            </date-picker>
                                        </div>
                                    </div>
                                    <!-- End Date -->
                                    <div class="col-12 col-md-3">
                                        <div class="form-group">
                                            <label class="form-control-label text-muted" for="input-name">{{ $t('label.end date') }}</label>
                                            <date-picker v-model="filter.endDate" class="full-width" input-class="form-control" value-type="format" format="MM/DD/YYYY">
                                            </date-picker>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-1">
                                        <div class="form-group mb-2">
                                            <label class="form-control-label text-muted" for="input-name">&nbsp;</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-3">
                                        <div class="form-group">
                                            <label class="form-control-label text-muted" for="input-name">{{ $t('label.sort by') }}</label>
                                            <select class="form-control" v-model="filter.sortBy" autofocus>
                                                <option value="none" selected>- {{ $t('label.no grouping') }} -</option>
                                                <option value="customer">{{ $t('label.customer') }}</option>
                                                <option value="job">{{ $t('label.job') }}</option>
                                                <option value="status">{{ $t('label.status') }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4 text-left col-xs-12">
                                        <button type="button" class="btn btn-primary text-uppercase" @click="getReportInvoices(); searchBox();">
                                            {{ $t('label.search invoices') }}
                                        </button>
                                        <button type="button" class="btn btn-link ml-auto" @click="clearSearch()">
                                            {{ $t('label.clear search') }}
                                        </button>
                                    </div>
                                    <div class="col-8 text-right col-xs-12 d-none d-md-block">
                                        <button :disabled="invoicesCount == 0" type="button" class="btn btn-secondary color-black" @click="syncInvoice2QB()">
                                            <i class="ri-refresh-line"></i>Sync QuickBooks Online
                                        </button>
                                        <export-excel type="csv"
                                            name="invoice-report.xls" 
                                            worksheet="Invoice Report" 
                                            class="btn btn-secondary color-black" 
                                            :data="noPaginationInvoices" 
                                            :fields="invoice_header">
                                            {{ $t('label.export results') }}
                                        </export-excel>
                                        <button type="submit" class="btn btn-secondary color-black" @click="$modal.show('saveInvoiceViewModal')">
                                            <i class="fas fa-save"></i>
                                            {{ $t('label.save view') }}
                                        </button>
                                        <button type="button" class="btn btn-secondary color-black" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {{ $t('label.saved views') }}
                                            <i class="fas fa-angle-down"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" style="max-width: 100%;">
                                            <a class="dropdown-item pointer" v-for="(data, index) in allsearchviews" :key="index" @click="useSearchView(data)">
                                                {{ data.name }}
                                            </a>
                                            <div class="dropdown-divider"></div>
                                            <a class="dropdown-item pointer" data-toggle="modal" data-target="#manageViewModal">{{ $t('label.manage saved views') }}</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="dropdown-divider"></div>
                            </div>

                            <!-- Search section -->
                            <div class="card-header" v-if="!showSearchBox">
                                <div class="row d-flex justify-content-between align-items-center">
                                    <div>
                                        <h3 class="mb-0">{{ $t('label.search results') }} ({{ this.invoicesCount }})</h3>
                                    </div>
                                    <div class="row">
                                        <div class="col-auto">
                                            <a href="#" class="btn btn-sm btn-default" @click="searchBox()">{{ $t('label.revise search') }}</a>
                                        </div>
                                        <div class="col-auto p-0">
                                            <button type="submit" class="btn btn-sm btn-default" @click="$modal.show('saveInvoiceViewModal')">
                                                <i class="fas fa-save"></i>
                                                {{ $t('label.save view') }}
                                            </button>
                                        </div>
                                        <div class="col-auto">
                                            <export-excel type="csv" 
                                                name="invoice-report.xls" 
                                                worksheet="Invoice Report" 
                                                class="btn btn-sm btn-default" 
                                                :data="noPaginationInvoices" 
                                                :fields="invoice_header">
                                                {{ $t('label.export results') }}
                                            </export-excel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="table-responsive pb-4" v-if="clickSearch">
                                <report-invoice-datatable-component 
									:invoices="invoices"
                                    :clickSearch="clickSearch"
                                    :invoice_total="invoice_total"
                                    :invoice_balance="invoice_balance"
                                    :getReportInvoices="getReportInvoices"
                                    @reload="reload"
                                    :filter="filter"
                                    :groupbyfilter="groupbyfilter"
                                    :selectedDate="null"
                                    :checkpending="null"
                                    :elapsedTime3="null"
								/>
                                <table-server-pagination v-if="invoices"
                                    v-model="filter.current_page"
                                    :btn-limit="5"
                                    :btn-size="'sm'"
                                    :total-records="invoicesCount"
                                    :per-page="filter.rows_per_page"
                                    :num-rows="invoices.length"
                                    @onChange="reloadInvoices()">
                                </table-server-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Save Modal -->
        <modal name="saveInvoiceViewModal" :classes="'rounded'" :min-height="400" width="525px" :scrollable="true" height="auto" :reset="true" :adaptive="true">
            <div class="card-body mobile-p-0 p-0">
                <div class="modal-header border-bottom">
                    <h5 class="modal-title" id="saveViewModalTitle">{{ $t('label.save search view') }}</h5>
                    <button type="button" class="close" @click="$modal.hide('saveInvoiceViewModal')">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="saveSearchView">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label text-muted" for="input-name">{{ $t('label.name of view') }}</label>
                                    <input v-model="search.name" type="text" name="name" class="form-control" :placeholder="$t('label.name of view')" value="" required autofocus>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer border-top">
                        <button type="submit" class="btn btn-primary mr-auto">{{ $t('label.save view') }}</button>
                        <button type="button" class="btn btn-secondary close2saveinvoiceview" @click="$modal.hide('saveInvoiceViewModal')">{{ $t('label.cancel') }}</button>
                    </div>
                </form>
            </div>
        </modal>

        <!-- Manage View Modal -->
        <div class="modal fade" id="manageViewModal" tabindex="-1" role="dialog" aria-labelledby="manageViewModalTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header border-bottom">
                        <h5 class="modal-title" id="manageViewModalTitle">{{ $t('label.manage saved views') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                    <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="updateSearchViews">
                        <div class="modal-body">
                            <h4 class="mb-4">{{ $t('label.You can change the name and delete views') }}.</h4>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <div class="d-flex align-items-center mb-2" v-for="(data, index) in allsearchviews" :key="index">
                                            <div class="flex-grow-1">
                                                <input v-model="data.name" type="text" name="name" class="form-control" :placeholder="$t('label.name of view')" value="" required autofocus>
                                            </div>
                                            <div @click="deleteNotify(data.id)" class="p-3" style="background: #dd1a3e; color: #fff; border-radius: 0px 5px 5px 0px; height: 46px; line-height: 15px;">
                                                <i class="ri-delete-bin-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer border-top">
                            <button type="submit" class="btn btn-primary mr-auto">{{ $t('label.save changes') }}</button>
                            <button type="button" class="btn btn-link close2updateviews" data-dismiss="modal">{{ $t('label.cancel') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Modal Delete -->
        <modal-delete :deleteMethod="removeSavedView" :deleteModalText="deleteModalText" newid="reportInvoices"></modal-delete>

        <fade-transition origin="center" :duration="250" tag="div">
                <CustomLoader v-if="qbSyncing" :loaderText="loaderText" />
         </fade-transition>
    </div>
</template>
<script>

import TableServerPagination from '@/components/TableServerPagination.vue';
import bus from '@/utils/event-bus';
import CustomLoader from './tabs/loader.vue';

export default {
    components: {
        TableServerPagination,
        CustomLoader
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            invoices: [],
            noPaginationInvoices: [],
            allinvoicestatus: [],
            showSearchBox: true,
            filter: {
                startDate: null,
                endDate: null,
                invoice_status: [],
                current_page: 1,
                rows_per_page: 25,
                sortBy: null,
            },
            groupbyfilter: false,
            countSentInvoices: 0,
            countDraftInvoices: 0,
            countCancelledInvoices: 0,
            countPaidInvoices: 0,
            countOverdueInvoices: 0,
            invoicesCount: 0,
            delete_id: null,
            invoice_total: 0,
            invoice_balance: 0,
            clickSearch: false,
            dataTable: null,
            totalTimeByGroup: [],
            invoice_header: {
                'Invoice #': {
                    field: 'invoice_number_concat',
                    callback: (value) => {
                        return value;
                    }
                },
                'DUE DATE': 'due_date',
                'CUSTOMER': {
                    field: 'customer.name',
                    callback: (value) => {
                        return value;
                    }
                },
                'JOB': {
                    field: 'job.job_name',
                    callback: (value) => {
                        return value;
                    }
                },
                'INVOICE TOTAL': {
                    field: 'total',
                    callback: (value) => {
                        return value;
                    }
                },
                'BALANCE': 'balance',
                'STATUS': {
                    field: 'invoice_status.name',
                    callback: (value) => {
                        return value;
                    }
                },
            },
            search: {
                name: null
            },
            allsearchviews: [],
            loader: null,
            deleteModalText: {
                button: 'Yes, Delete',
                title: 'ARE YOU SURE YOU WANT TO DELETE THIS VIEW?'
            },
            newid: 'DeleteInvoice',
            qbSyncing: false,
            loaderText:''
        }
        
    },
    created() {
        bus.$on('deleteFReportInvoices', () => {
            this.removeSavedView();
        });
    },
    mounted() {
        this.loader = this.$loading.show();
        let promises = [
            this.getInvoiceStatus(),
            this.getSearchViews(),
        ];

        Promise.all(promises).then(() => {
            this.loader.hide();
        });

        bus.$on('getallinvoices', () => {
            this.getReportInvoices();
        });
        bus.$on('deleteInvoice' + this.newid, () => {
            this.deleteInvoice();
        });
        bus.$on('reloadReportInvoicesAfterSend', () => {
            this.getReportInvoices();
        });

    },
    methods: {
        clearSearch() {
            this.filter.startDate = null;
            this.filter.endDate = null;
            this.filter.invoice_status = [];
            this.groupbyfilter = false;
            this.filter.sortBy = null;
            this.allinvoicestatus.forEach((data) => {
                this.filter.invoice_status.push(data.id);
            });
        },
        saveSearchView() {
            var request_fields = {
                'filter': this.filter,
                'name': this.search.name,
                'type': 'invoice reports'
            };

            axios.post('/save-search-view', request_fields).then(() => {
                this.search.name = '';
                this.getSearchViews();
            }).catch(err => {
                //console.log('list carriers error:', error)
            });

            document.querySelector('.close2saveinvoiceview').click();
        },
        getSearchViews() {
            var request_fields = { 'type': 'invoice reports' };
            return axios.post('/get-search-views', request_fields).then(response => {
                this.allsearchviews = response.data.result;
            }).catch(err => {
                //console.log('list carriers error:', error)
            });
        },
        searchBox() {
            this.showSearchBox = !this.showSearchBox;
        },
        getInvoiceStatus() {
            axios.get('/get-invoice-status').then(response => {
                this.allinvoicestatus = response.data.result;
                this.allinvoicestatus.forEach((data) => {
                    this.filter.invoice_status.push(data.id);
                });
                this.getReportInvoices(true);
            }).catch(err => {
                //console.log('list carriers error:', error)
            });
        },
        async getReportInvoices() {
            this.clickSearch = true;
            this.invoices = [];

            return axios.post('/get-report-invoices',this.filter).then(res => {
                this.invoices = res.data.result;
                this.noPaginationInvoices = res.data.noPaginationInvoices;
                this.invoice_total = res.data.invoice_total;
                this.invoice_balance = res.data.invoice_balance;
                this.countSentInvoices = res.data.statusSent;
                this.countDraftInvoices = res.data.statusDraft;
                this.countCancelledInvoices = res.data.statusCancelled;
                this.countPaidInvoices = res.data.statusPaid;
                this.countOverdueInvoices = res.data.statusOverdue;
                this.invoicesCount = res.data.totalCount; //counts invoices

				this.invoices.forEach(i => {
                    i.due_date = moment(i.due_date).format('MM-DD-YYYY')
				});

                if(this.filter.sortBy != null) {
                    if (this.filter.sortBy !== 'none')
                        this.groupbyfilter = this.filter.sortBy != null ? true : false;
                    }

                this.totalTimeByGroup = _(this.invoices)
                    .groupBy('group_by_value')
                    .map((objs, key) => ({
                        'group_by_value': key,
                        'duration': _.sumBy(objs, 'duration') }))
                    .value();

            }).catch(err => {
                console.log(err)
            });
        },
        reload() {
            this.getReportInvoices();
        },
        reloadInvoices() {
            // Reload Timesheets and focus to the top of the list
            if (!this.isLoading) {
                this.isLoading = true; // Lock method to only process one reload

                this.loader = this.$loading.show();
                this.getReportInvoices().then(() => {
                    $('#invoiceHeader')[0].scrollIntoView();
                    this.loader.hide();
                });

                this.isLoading = false; // Unlock
            }
        },
        useSearchView(filterData) {
            let data = filterData.filter;
            this.filter.startDate = data.startDate;
            this.filter.endDate = data.endDate;
            this.filter.sortBy = data.sortBy;
            this.filter.invoice_status = data.invoice_status;
        },
        deleteNotify(delete_id) {
            this.delete_id = delete_id;
            $("#deleteModalreportInvoices").modal('show');
        },

        removeSavedView() {
            axios.post('/delete-search-view/' + this.delete_id).then(() => {
                this.getSearchViews();
            }).catch(err => {
                //console.log('list carriers error:', error)
            });
        },
        updateSearchViews() {
            var request_fields = {
                'searchviews': this.allsearchviews
            };

            axios.post('/update-search-views', request_fields).then(() => {
                this.getSearchViews();
            }).catch(err => {
                //console.log('list carriers error:', error)
            });
            document.querySelector('.close2updateviews').click();
        },
        deleteInvoice() {
            let data = {
                id: this.delete_id
            };

            axios.post('/invoice-delete', data).then(() => {
                this.getReportInvoices();
            }).catch(err => {
                //console.log('list carriers error:', error)
            });
        },

        async syncInvoice2QB() {
           
            var response;
            this.qbSyncing = true;
            try {
                
                this.loaderText = "Syncing Invoice Items master to Quickbooks Online...";
                response = await axios.get('/quickbooks/sync-items-invoice');

          
                this.loaderText = "Syncing Customers to Quickbooks Online...";
                response = await axios.post('/quickbooks/sync-customer');

                this.loaderText = "Syncing Invoice to Quickbooks Online...";
                response = await axios.post('/quickbooks/sync-invoice');

               bus.$emit('banner-success', { message: response.data.message });
               this.getReportInvoices();
      
            } catch (error) {
                console.log('ERROR: ', error);
                bus.$emit('banner-error', { message: error.data.message });
            } finally {
                this.qbSyncing = false;
            }
        }
    }
}
</script>