import Vue from 'vue';

/*
 * Validate the syntax of an email address with regex
 */
Vue.prototype.$spValidEmail = function (email) {
    let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email.toLowerCase());
}

/*
 * Split a string that has multiple emails delimited by 
 * strings into an array of string emails
 */
Vue.prototype.$spSplitEmailString = function (emStr) {
    let newEmStr = emStr.replaceAll(/\s+/g, '');
    return newEmStr.split(',');
}