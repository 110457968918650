<template>
	<div v-click-outside="hide">
		<form v-if="user.role_id <= 2" class="navbar-search navbar-search-light form-inline mr-sm-3 mb-2" id="navbar-search-main">
			<div class="form-group mb-0">
				<div class="input-group input-group-alternative input-group-merge">
					<div class="input-group-prepend">
						<span class="input-group-text"><i class="fas fa-search"></i></span>
					</div>
                    <input name="s" class="form-control" placeholder="" type="text" @focus="onChange" @input="onChange" v-model="form.search" autocomplete="off" data-lpignore="true">
				</div>
			</div>
			<button type="button" class="close" data-action="search-close" data-target="#navbar-search-main" aria-label="Close">
				<span aria-hidden="true">×</span>
			</button>
		</form>

		<div class="card searchresult" v-if="showResult" style="z-index: 9999">
			<div v-if="user.role_id == '2'" class="card-body pt-0 pb-0">
				<div class="row">
					<!-- Customer Results -->
					<div class="col-12">
						<h4 class="mt-3 text-primary"><i class="fas fa-users"></i> {{ $t('label.customers') }}</h4>
                        <ul class="pl-4">
							<li v-for="(data,index) in allcustomers" :key="index" class="search-hover" @click="hide">
								<router-link :to="{name:'customer',params:{customerInfo: data}}">
									<div class="d-flex align-items-center search-hover">
                                        <span class="pl-2 breadcrumb-item" v-html="$options.filters.highlight(data.name, form.search)"></span>										
                                    </div>
								</router-link>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div v-if="user.role_id == '2'" class="dropdown-divider"></div>
			<div v-if="user.role_id == '2'" class="card-body pt-0 pb-0">
				<div class="row">
					<!-- Job Results -->
					<div class="col-12">
						<h4 class="mt-3 text-primary"><i class="ni ni-single-copy-04"></i> {{ $t('label.jobs') }}</h4>
						<ul class="pl-4">
							<li v-for="(data,index) in alljobs" :key="index" class="search-hover" @click="hide">
								<router-link :to="{ name: 'jobEdit', params: {id: data.job_number.job_number} }">
									<div class="d-flex align-items-center">
										<span class="pl-2 breadcrumb-item" v-html="$options.filters.highlight(data.job_name, form.search)"></span>
											<!-- {{ data.job_name }} (# {{ data.job_number.job_number }}) -->
									</div>
									<div class="d-flex align-items-center">
										<small class="pl-2">{{ $t('label.customer') }}:
											<span v-if="data.customer" v-html="$options.filters.highlight(data.customer.name, form.search)"></span>
											<!-- {{ data.customer.name }} -->
										</small>
									</div>
								</router-link>

								<!-- <a :href="'/job/'+data.job_number.job_number+'/edit'" class="text-muted">
								</a> -->
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div v-if="user.role_id == '2'" class="dropdown-divider"></div>
			<div class="card-body pt-0 pb-0">
				<div class="row">
					<!-- Task Results -->
					<div class="col-12">
						<h4 class="mt-3 text-primary"><i class="ni ni-single-copy-04"></i> {{ $t('label.tasks') }}</h4>
						<ul class="pl-4">
							<li v-for="(data,index) in alltasks" :key="index" class="search-hover" @click="hide">															
								<div class="text-muted pointer" @click="gotoJobTask(data)">
									<div class="d-flex align-items-center">
										<span v-if="data.name" class="pl-2 breadcrumb-item" v-html="$options.filters.highlight(data.name, form.search)"></span>
									</div>
									<div class="d-flex align-items-center">
										<small class="pl-2">{{ $t('label.job') }}:
											<span v-if="data.job.job_name" v-html="$options.filters.highlight(data.job.job_name, form.search)"></span>
											(# {{ data.job.job_number.job_number }})
										</small>
									</div>
									<div class="d-flex align-items-center">
										<small class="pl-2">{{ $t('label.customer') }}:
											<span v-if="data.job.customer" v-html="$options.filters.highlight(data.job.customer.name, form.search)"></span>
										</small>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="dropdown-divider"></div>
			<div class="card-body pt-0 pb-0">
				<div class="row">
					<!-- Employee Results -->
					<div class="col-12">
						<h4 class="mt-3 text-primary"><i class="fas fa-users"></i> {{ $t('label.employees') }}</h4>
						<div v-for="(data,index) in allemployees" :key="index" @click="hide">
							
							<!-- <a v-if="user.role_id == '2'" :href="'/user/'+data.id+'/edit'" class="text-muted ">
								<div class="d-flex align-items-center mb-2 search-hover">
									<img :src="data.picture" 
										alt="" 
										style="width: 36px; height: 36px; object-fit: cover;">
									<span class="pl-2 breadcrumb-item" 
										v-html="$options.filters.highlight(data.name, form.search)"></span>
								</div>
							</a> -->
							<!-- <div v-if="user.role_id == '3' || user.role_id == '4'" @click="getUserInfo(data.id, 'Profile')" class="text-muted "> -->
							<div @click="getUserInfo(data.id, 'Profile')" class="text-muted pointer">
								<div class="d-flex align-items-center mb-2 search-hover">
									<img :src="data.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
									<span class="pl-2 breadcrumb-item" v-html="$options.filters.highlight(data.name, form.search)"></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="dropdown-divider"></div>
			<div class="card-body pt-1 pb-3">
				<div class="row">
					<div class="col-12 text-center" @click="hide">
						<button class="btn btn-sm btn-primary" @click="gotoSearch()">
							{{ $t('label.see all search results') }}
						</button>
						<!-- <a :href="'/search?s=' + form.search" class="btn btn-sm btn-primary">{{ $t('label.see all search results') }}</a> -->
					</div>
				</div>
			</div>
		</div>
		<!-- <modal-task-details v-if="openEdit" :taskId="taskId" :user="user" :openEdit="openEdit" :viewtype="'none'" newid="search"></modal-task-details> -->
	</div>
</template>
<style>
	
</style>
<script>
	import bus from '@/utils/event-bus';
	import ClickOutside from 'vue-click-outside';
    export default {
		props: ['jobid'],
		data() {
			return {
				user: JSON.parse(localStorage.getItem('user')),
				alljobs: [],
				alltasks: [],
				allemployees: [],
				allcustomers: [],
				form: {
					search: null
				},
                showResult: false,
				showDropdown: false,
				columns: ['notes', 'created_by.name', 'created_at','actions'],
				options: {
					perPageValues: [],
					headings: {
                        notes: this.$t('label.notes'),
						'created_by.name': this.$t('label.created by'),
						created_at: this.$t('label.date added'),
						actions: ''
					},
					editableColumns:['notes', 'created_by.name', 'created_at'],
					sortable: ['notes', 'created_by.name', 'created_at'],
					filterable: false,
					columnsClasses: {
						notes: 'text-left',
						'created_by.name': 'text-left',
						created_at: 'text-left',
						actions: 'text-center actions-column'
					},
				},
				taskId: 0,
				openEdit: false

			}
		},
        mounted() {
			return _.orderBy(this.alljobnotes, ['created_at', 'desc']);
		},
		methods: {
			onChange() {
				if( this.form.search !== null && this.form.search.length > 2 ) {
                    this.showResult = true;
					var request_fields = {
						'search': this.form.search,
						'take': '3'
					};

					var ref = this;
					setTimeout(function() {
						axios.post('/search', request_fields)
						.then(response => {
							// this.form.search = '';
							ref.alljobs = response.data.result.jobs;
                            ref.alltasks = response.data.result.tasks;
                            ref.allemployees = response.data.result.employees;
                            ref.allcustomers = response.data.result.customers;
						})
						.catch(err => {
							//console.log('list carriers error:', error)
						});
					}, 1000);
				} else {
                    this.showResult = false;
                }
			},
			hide() {
				this.showResult = false;
			},			
			gotoJobTask(taskData) {
				// need to redirect away from jobEdit page in order to reload
				this.$router.push({path:'/'})
				setTimeout(()=>{
					this.$router.replace({
						name:'jobEdit', 
						params:{
							id:taskData.job.job_number.job_number, 
							taskId:taskData.id
						}
					})
				},100)
			},
			showTaskDetailsModal(data) {
                this.taskId = data.id;
                this.tasks = data;
                this.current = data.task_status_id;
                this.selectedDate = data.start_date;
                this.openEdit = true;
            },
			gotoSearch (){
				// need to redirect away from jobEdit page in order to reload
				this.$router.push({path:'/'})
				setTimeout(()=>{
					this.$router.replace({
						name:'globalSearch', 
						params:{
							searchresult: this.form.search
						}
					})
				},100)
			},
		},
		directives: {
			ClickOutside
		}
    }
</script>
