<template>
	<li v-if="isMobile()">
		<a class="mobile-menu-icon" @click="openNotification">
			<div class="ri-discuss-line mobile-nav-icon"></div>
			<div v-if="unreadChatCount > 0" class="notification-icon-count"><span>{{ unreadChatCount }}</span></div>
			<p>{{ $t('label.chats') }}</p>
		</a>
	</li>
	<li v-else class="nav-item dropdown notifications pointer">
		<a class="nav-link text-white hidden-lg-down">
			<i class="ri-discuss-line notification-icon"></i>
			<div v-if="unreadChatCount > 0" class="notification-icon-count"><span>{{ unreadChatCount }}</span></div>
		</a>
	</li>
</template>

<script>
	import { mapGetters } from 'vuex';

    export default {
		props: ['footer'],
		emits: ['openNotif'],
		data() {
			return {}
		},
		computed: {
            ...mapGetters({
				unreadChatCount:'chat/unreadChatCount',
			}),
        },
		mounted() {},
		methods: {
			isMobile() {
                return $(document).width() <= 768;
			},
			openNotification() {
				this.$emit('openNotif')
			}
		}
    }
</script>