<template>
    <div class="card">
        <div class="card-header">
            <h3 class="d-flex align-items-center mb-0">
                <i class="ri-history-line"></i> {{ $t('label.activity log') }}
            </h3>
        </div>
        <div class="card-body">
            <div class="timeline timeline-one-side" data-timeline-content="axis" data-timeline-axis-style="line">
                <template v-for="(data, index) in purchaseOrderActivityLogs">
                    <!-- CREATED -->
                    <div :key="index" v-if="data.log_type == 'create'" class="timeline-block">
                        <span class="timeline-step badge-activity-log">
                            <i class="ri-article-line p-0"></i>
                        </span>
                        <div class="timeline-content">
                            <span class="d-block">
                                 {{ $t('label.purchase order') }} ({{ data.purchase_order.order_number }}) created by <span class="link">{{ data.created_by.name }}</span>
                            </span>
                            <span class="date">{{ data.created_at | formatDateWithTime }}</span>
                        </div>
                    </div>
                    <!-- INFO -->
                    <div :key="index" v-if="data.log_type == 'update'" class="timeline-block">
                        <span class="timeline-step badge-activity-log">
                            <i class="ri-pencil-line p-0"></i>
                        </span>
                        <div class="timeline-content">
                            <span class="d-block">
                                {{ $t('label.purchase order info') }} is updated by <span class="link">{{ data.created_by.name }}</span>
                            </span>
                            <span class="date">{{ data.created_at | formatDateWithTime }}</span>
                        </div>
                    </div>
                    <!-- COMMENT -->
                    <div :key="index" v-if="data.log_type == 'comments'" class="timeline-block">
                        <span class="timeline-step badge-activity-log">
                            <i class="ri-feedback-line p-0"></i>
                        </span>
                        <div class="timeline-content">
                            <span class="d-block">
                                {{ $t('label.description or special instructions') }} are updated by <span class="link">{{ data.created_by.name }}</span>
                            </span>
                            <span class="date">{{ data.created_at | formatDateWithTime }}</span>
                        </div>
                    </div>
                    <!-- SPECIAL ATTACHMENTS -->
                    <div :key="index" v-if="data.log_type == 'special_attachments'" class="timeline-block">
                        <span class="timeline-step badge-activity-log">
                            <i class="ri-feedback-line p-0"></i>
                        </span>
                        <div class="timeline-content">
                            <span class="d-block">
                                {{ $t('label.attachments') }} added by <span class="link">{{ data.created_by.name }}</span>
                            </span>
                            <span class="date">{{ data.created_at | formatDateWithTime }}</span>
                            <div class="details mt-3">
                                <div class=" pb-2" v-for="(file, index) in data.purchase_order_special_attachments" :key="index">
                                    <div class="d-flex align-items-center flex-grow-1">
                                        <!-- Edit Entry Files -->
                                        <template v-if="file.id">
                                            <div class="">
                                                <img class="image-container image-container-thumbnail float-left mr-2" :src="file.file_type == 'application/pdf' ? '/images/icons/doc-pdf.png' : '/' + file.download" />
                                            </div>
                                            <div class="">
                                                <span class="pointer">
                                                    <span v-if="file.display_name" class="font-weight-bolder">{{ file.display_name }}</span>
                                                    <span v-else>{{ file.name }}</span>
                                                    <!-- <br>
                                                    <span class="text-muted">
                                                        {{ file.size }}
                                                    </span> -->
                                                </span>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- REMOVED SPECIAL ATTACHMENTS -->
                    <div :key="index" v-if="data.log_type == 'special_attachments_removed'" class="timeline-block">
                        <span class="timeline-step badge-activity-log">
                            <i class="ri-feedback-line p-0"></i>
                        </span>
                        <div class="timeline-content">
                            <span class="d-block">
                                {{ $t('label.attachments') }} removed by <span class="link">{{ data.created_by.name }}</span>
                            </span>
                            <span class="date">{{ data.created_at | formatDateWithTime }}</span>
                            <!-- <div class="details mt-3">
                                <div class=" pb-2" v-for="(file, index) in data.purchase_order_special_attachments" :key="index">
                                    <div class="d-flex align-items-center flex-grow-1">
                                        <template v-if="file.id">
                                            <div class="">
                                                <img class="image-container image-container-thumbnail float-left mr-2" :src="file.file_type == 'application/pdf' ? '/images/icons/doc-pdf.png' : '/' + file.download" />
                                            </div>
                                            <div class="">
                                                <span class="pointer">
                                                    <span v-if="file.display_name" class="font-weight-bolder">{{ file.display_name }}</span>
                                                    <span v-else>{{ file.name }}</span>
                                                </span>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <!-- ITEMS -->
                    <div :key="index" v-if="data.log_type == 'items'" class="timeline-block">
                        <span class="timeline-step badge-activity-log">
                            <i class="ri-pencil-ruler-2-line p-0"></i>
                        </span>
                        <div class="timeline-content">
                            <span class="d-block">
                                {{ $t('label.items') }} are updated by <span class="link">{{ data.created_by.name }}</span>
                            </span>
                            <span class="date">{{ data.created_at | formatDateWithTime }}</span>
                        </div>
                    </div>
                    <!-- RECEIVED ITEMS -->
                    <div :key="index" v-if="data.log_type == 'received_items'" class="timeline-block">
                        <span class="timeline-step badge-activity-log">
                            <i class="ri-pencil-ruler-2-line p-0"></i>
                        </span>
                        <div class="timeline-content">
                            <span class="d-block">
                                {{ $t('label.items received by') }} <span class="link">{{ data.created_by.name }}</span>
                            </span>
                            <span class="date">{{ data.created_at | formatDateWithTime }}</span>
                            <div class="details mt-3">
                                <div class="row">
                                    <div class="col-11 font-weight-bolder">{{ $t('label.item description') }}</div>
                                    <div class="col-1 font-weight-bolder text-right">{{ $t('label.qty') }}</div>
                                </div>
                                <div :key="i" v-for="(d, i) in data.purchase_order_received_items" class="row">
                                    <div class="col-11">{{ d.purchase_order_item.description }}</div>
                                    <div class="col-1 text-right">{{ d.quantity }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- BILLING -->
                    <div :key="index" v-if="data.log_type == 'billing_address' || data.log_type == 'shipping_address'" class="timeline-block">
                        <span class="timeline-step badge-activity-log">
                            <i class="ri-road-map-line p-0"></i>
                        </span>
                        <div class="timeline-content">
                            <span class="d-block">
                                {{ $t('label.purchase order') }} {{ data.log_type == 'billing_address' ? $t('label.billing address') : $t('label.shipping address') }}
                                is updated by <span class="link">{{ data.created_by.name }}</span>
                            </span>
                            <span class="date">{{ data.created_at | formatDateWithTime }}</span>
                        </div>
                    </div>
                    <!-- NOTES -->
                    <div :key="index" v-if="data.log_type == 'notes'" class="timeline-block">
                        <span class="timeline-step badge-activity-log">
                            <i class="ri-draft-line p-0"></i>
                        </span>
                        <div class="timeline-content">
                            <span class="d-block">
                                {{ $t('label.note added by') }} <span class="link">{{ data.created_by.name }}</span>
                            </span>
                            <span class="date">{{ data.created_at | formatDateWithTime }}</span>
                            <div class="details mt-3" v-if="data.purchase_order_note">
                                {{ data.purchase_order_note.notes }}
                            </div>
                        </div>
                    </div>
                    <!-- FILES -->
                    <div :key="index" v-if="data.log_type == 'files'" class="timeline-block">
                        <span class="timeline-step badge-activity-log">
                            <i class="ri-folders-line p-0"></i>
                        </span>
                        <div class="timeline-content">
                            <span class="d-block">
                                {{ $t('label.files added by') }} <span class="link">{{ data.created_by.name }}</span>
                            </span>
                            <span class="date">{{ data.created_at | formatDateWithTime }}</span>
                            <div class="details mt-3">
                                <div class=" pb-2" v-for="(file, index) in data.purchase_order_files" :key="index">
                                    <div class="d-flex align-items-center flex-grow-1">
                                        <!-- Edit Entry Files -->
                                        <template v-if="file.id">
                                            <div class="">
                                                <img class="image-container image-container-thumbnail float-left mr-2" :src="file.file_type == 'application/pdf' ? '/images/icons/doc-pdf.png' : '/' + file.download" />
                                            </div>
                                            <div class="">
                                                <span class="pointer">
                                                    <span v-if="file.display_name" class="font-weight-bolder">{{ file.display_name }}</span>
                                                    <span v-else>{{ file.name }}</span>
                                                    <br>
                                                    <span class="text-muted">
                                                        {{ file.size }}
                                                    </span>
                                                </span>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- EMAIL -->
                    <div :key="index" v-if="data.log_type == 'email'" class="timeline-block">
                        <span class="timeline-step badge-activity-log">
                            <i class="ri-send-plane-2-line p-0"></i>
                        </span>
                        <div class="timeline-content">
                            <span class="d-block">
                                <!-- {{ $t('label.purchase order sent to') }} <span class="link">{{ data.purchase_order.vendor?.company_name }}</span> {{ $t('label.by') }} <span class="link">{{ data.created_by.name }}</span> -->
                                {{ $t('label.purchase order sent to') }} <span class="link">{{ data.purchase_order.vendor.company_name }}</span> {{ $t('label.by') }} <span class="link">{{ data.created_by.name }}</span>
                            </span>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex';
;

export default {
    props: ['purchaseOrderId'],
    emits: [''],
    components: {

    },
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters({
            purchaseOrderActivityLogs: 'purchaseOrder/purchaseOrderActivityLogs',
        }),
    },
    mounted() {
        bus.$on('reloadActivityLogs', () => {
            this.loadLkupData();
        })
        this.loadLkupData();
    },
    methods: {
        loadLkupData() {
            this.$store.dispatch('purchaseOrder/getPurchaseOrderActivityLogs', this.purchaseOrderId);
        }
    },
}
</script>
