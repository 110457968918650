import moment from '@/utils/date-time';
import Vue from 'vue';

Vue.filter('formatDate', function (value) {
    if (value) {
        var user = JSON.parse(localStorage.getItem('user'))
        var dtFormat = 'MM/DD/YYYY'
        console.log(value);
        return moment(String(value)).tz(user.timezone).format(dtFormat)
    }
});

Vue.filter('formatDateNoTz', function (value) {
    if (value) {
        var user = JSON.parse(localStorage.getItem('user'))
        var dtFormat = 'MM/DD/YYYY'
        // return moment(String(value)).tz(user.timezone).format(dtFormat)
        return moment(String(value)).format(dtFormat)
    }
});

Vue.filter('formatDateToFullDate', function (value) {
    if (value) {
        return moment(String(value)).format('MMMM D, YYYY');
    }
});

Vue.filter('formatHour', function (value) {
    return parseFloat(value).toFixed(2);
});

Vue.filter('highlight', function (words, query) {
    var check = new RegExp(query, "ig");
    return words.toString().replace(check, function (matchedText, a, b) {
        return ('<strong>' + matchedText + '</strong>');
    });
});

Vue.filter('hourToFormattedTime', function (value) {
    if (value) {
        value = value * 3600;
        var hr = Math.floor(value / 3600);
        var min = Math.floor(value % 3600 / 60);
        var sec = Math.floor(value % 3600 % 60);

        if (hr < 10) {
            hr = '0' + hr;
        }

        if (min < 10) {
            min = '0' + min;
        }

        if (sec < 10) {
            sec = '0' + sec;
        }

        return hr + ':' + min;
    } else {
        return '00:00';
    }
});

Vue.filter('formatTooltipMsg', function (data, company, time, total, isOvertime) {
    let otHr = company.settings.overtime_hrs;
    let otWarningHr = company.settings.overtime_warning_hrs;
    let week = company.settings.overtime_by_week ? 'week' : 'day';

    let msgStatus = data.status ? data.user.name + ' - ' + this.formatTime(time) : data.user.name + ' - ' + 'Clocked Out';
    let totalHours = (week ? 'Weekly' : 'Daily') + ' Hours Total: ' + total;

    if (isOvertime) {
        return msgStatus + '<br><br>' + totalHours + '<br><br>' + data.user.first_name + ' is projected to accrue overtime based on their total estimated Task hours for the ' + week + '.';
    } else {
        return msgStatus + '<br><br>' + totalHours + '<br><br>' + data.user.first_name + ' is within ' + otWarningHr + ' hours of reaching the ' + otHr + '-hour per ' + week + ' limit before overtime accrual.'
    }
});

Vue.filter('formatTime', function (value) {
    if (value) {
        var hr = Math.floor(value / 3600);
        var min = Math.floor(value % 3600 / 60);
        var sec = Math.floor(value % 3600 % 60);

        if (hr < 10) {
            hr = '0' + hr;
        }

        if (min < 10) {
            min = '0' + min;
        }

        if (sec < 10) {
            sec = '0' + sec;
        }

        return hr + ':' + min;
    } else {
        return '00:00';
    }
});

Vue.filter('formatTimeWithSec', function (value) {
    if (value) {
        var hr = Math.floor(value / 3600);
        var min = Math.floor(value % 3600 / 60);
        var sec = Math.floor(value % 3600 % 60);

        if (hr < 10) {
            hr = '0' + hr;
        }

        if (min < 10) {
            min = '0' + min;
        }

        if (sec < 10) {
            sec = '0' + sec;
        }

        return hr + ':' + min + ':' + sec;
    } else {
        return '00:00:00';
    }
});

Vue.filter('formatDateTime', function (value) {
    if (value) {
        // return moment(String(value)).format('MM/DD/YYYY - hh:mm:ss A')
        let newDate = value.replace(/[-]/g, '/');
        return moment(String(newDate)).format('MM/DD/YYYY - h:mm A')
    }
});

Vue.filter('formatDateWithTz', function (value) {
    if (value) {
        var user = JSON.parse(localStorage.getItem('user'));
        return moment.utc(String(value), 'YYYY-MM-DD HH:mm:ss').tz(user.timezone).format('MM/DD/YYYY');
    }
});

Vue.filter('formatTimeWithTz', function (value) {
    if (value) {
        var user = JSON.parse(localStorage.getItem('user'));
        return moment.utc(String(value), 'YYYY-MM-DD HH:mm:ss').tz(user.timezone).format('hh:mm A');
    }
});

Vue.filter('formatDateOnly', function (value) {
    if (value) {
        return moment.utc(String(value), 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY');
    }
});

Vue.filter('formatTimeOnly', function (value) {
    if (value) {
        return moment.utc(String(value), 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    }
});

Vue.filter('formatDatePriority', function (value) {
    if (value) {
        var user = JSON.parse(localStorage.getItem('user'));
        return moment.utc(String(value)).tz(user.timezone).format('DD dddd')
    }
});

Vue.filter('formatDatePriorityTask', function (value) {
    if (value) {
        var user = JSON.parse(localStorage.getItem('user'));
        return moment.utc(String(value)).format('DD ddd')
    }
});

Vue.filter('formatDateTitle', function (value) {
    if (value) {
        var user = JSON.parse(localStorage.getItem('user'));
        return moment.utc(String(value)).tz(user.timezone).format('dddd, D MMMM YYYY')
    }
});

Vue.filter('formatMonthDay', function (value) {
    if (value) {
        var user = JSON.parse(localStorage.getItem('user'));
        return moment.utc(String(value)).tz(user.timezone).format('MMM DD')
    }
});

Vue.filter('formatDateAndTime', function (value) {
    var user = JSON.parse(localStorage.getItem('user'));
    if (value) {
        return moment(String(value) + 'Z').tz(user.timezone).format('MM/DD/YYYY') + ' at ' + moment(String(value) + 'Z').tz(user.timezone).format('hh:mm a');
    }
});

Vue.filter('formatDateWithTime', function (value) {
    if (value) {
        var user = JSON.parse(localStorage.getItem('user'));
        return moment.utc(String(value)).tz(user.timezone).format('MM/DD/YYYY - hh:mm A')
    }
});

Vue.filter('formatDateTimeWithAt', function (value) {
    var user = JSON.parse(localStorage.getItem('user'));
    if (value) {
        return moment.utc(String(value)).tz(user.timezone).format('MM/DD/YYYY') + ' at ' + moment.utc(String(value)).tz(user.timezone).format('hh:mm a');
    }
});

Vue.filter('formatMonthDayWithoutUtc', function (value) {
    if (value) {
        var user = JSON.parse(localStorage.getItem('user'));
        return moment(String(value)).tz(user.timezone).format('MMM DD')
    }
});

Vue.filter('formatChatDateAndTime', function (value) {
    var user = JSON.parse(localStorage.getItem('user'));
    if (value) {
        return moment.utc(String(value)).tz(user.timezone).calendar();
    }
});

Vue.filter('formatSidebarChatDateAndTime', function (value) {
    var user = JSON.parse(localStorage.getItem('user'));
    if (value) {
        return moment.utc(String(value)).tz(user.timezone).calendar(null, {
            sameDay: 'hh:mm A',
            // nextDay: '[Tomorrow]',
            // nextWeek: 'dddd',
            lastDay: 'DD MMM',
            lastWeek: 'DD MMM',
            sameElse: 'DD MMM'
        });
    }
});

Vue.filter('formatMsgType', function (value) {
    if (value) {
        switch (value) {
            case 'prompt':
                return 'Prompt Screen';
            case 'snippet':
                return 'Snippet';
            case 'banner':
                return 'Banner';
            case 'popup':
                return 'Pop up';
            case 'email':
                return 'Email';
            case 'text':
                return 'Text Message';
        }
    }
});

Vue.filter('formatThousands', function (value) {
    if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
});

Vue.filter('uppercase', function (value) {
    if (value) {
        return value.toUpperCase();
    }
});

Vue.filter('uppercaseFirst', function (value) {
    if (value) {
        return value[0].toUpperCase() + value.substring(1, value.length);
    }
});

Vue.filter('capitalize', function (value) {
    if (value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    } else {
        return '';
    }
});

Vue.filter('formatSeconds2Time', function (secs) {
    if (secs) {
        const HH = `${Math.floor(secs / 3600)}`.padStart(2, '0');
        const MM = `${Math.floor(secs / 60) % 60}`.padStart(2, '0');
        const SS = `${Math.floor(secs % 60)}`.padStart(2, '0');
        return [HH, MM, SS].join(':');
    }
    else return '00:00:00'
});

Vue.filter('uScoreToSpace', function (value) {
    return value.replace('_', ' ');
});

Vue.filter('toCurrency', function (value) {
    let formattedValue = value;
    if (typeof value !== "number") {
        formattedValue = Number(value);
    }

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    return formatter.format(formattedValue).replace(".00", "").replace(/^(\D+)/, '$1');
});

Vue.filter('toMonths', function (numberOfDays) {
    var years = Math.floor(numberOfDays / 365);
    var months = Math.floor(numberOfDays % 365 / 30);
    var days = Math.floor(numberOfDays % 365 % 30);

    var yearsDisplay = years > 0 ? years + (years == 1 ? " year " : " year ") : "";
    var monthsDisplay = months > 0 ? months + (months == 1 ? " month " : " month ") : "";
    var daysDisplay = days > 0 ? days + (days == 1 ? " day" : " day") : "";
    return yearsDisplay + monthsDisplay + daysDisplay;
});