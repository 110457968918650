<template>
    <div v-if="job">
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-2 sp-mobile-mt-50">
            <!-- Header -->
            <div class="d-flex align-items-center py-4 mr--4 mr-md--2 mr-lg--4 row">
                <!-- Job Name & Number & Breadcrumbs-->
                <div class="col-lg-6 col-7">
                    <breadcrumbs :title="job.job_name + ' - ' + $t('label.job') + ' # ' + (job.job_number ? job.job_number.job_number : '')">
                        <!-- Breakcrumb slots -->
                        <li class="breadcrumb-item">
                            <router-link class="text-primary pointer" :to="{ name: 'jobs' }">{{ $t('label.jobs') }}</router-link>
                        </li>
                        <li class="breadcrumb-item">
                            {{ $t('label.job') }} #
                            {{ job.job_number ? job.job_number.job_number : '' }}
                        </li>
                    </breadcrumbs>
                </div>
                <!-- Job Menu -->
                <div class="col-sm-6 text-right d-flex justify-content-end align-items-center mobile-justify-content-between text-nowrap">
                    <h2 class="m-0 mr-4 text-white d-flex align-items-center mobile-total-job-time">
                        <i class="ri-time-line mr-1" style="font-size: 25px"></i> {{ jobEditData.total_job_time }} ({{ jobEditData.total_job_time_hr }} {{ $t('label.hrs') }})
                    </h2>
                    <div class="dropdown">
                        <button type="button" class="btn btn-neutral" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{ $t('label.job menu') }}
                        </button>
                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" style="min-width: 250px;">
                            <a class="dropdown-item text-dark pointer" @click="openJobEditModal()">
                                <i class="ri-pencil-line"></i> {{ $t('label.edit job') }}
                            </a>
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#duplicateJobModal">
                                <i class="ri-file-copy-line"></i> {{ $t('label.duplicate job') }}
                            </a>
                            <a v-if="job.job_status && job.job_status.name !== 'Closed'" class="dropdown-item d-flex align-items-center pointer text-dark" @click="showCloseJobModal()">
                                <i class="ri-check-line"></i>{{ $t('label.close job') }}
                            </a>
                            <a v-else class="dropdown-item d-flex align-items-center" href="#" data-toggle="modal" @click="reopenJob()">
                                <i class="ri-inbox-unarchive-line"></i>{{ $t('label.reopen job') }}
                            </a>
                            <div class="dropdown-divider"></div>

                            <h4 class="m-0 px-3">{{ $t('label.job templates') }}</h4>
                            <div class="row align-items-center m-0 templates cursor-pointer2 flex-nowrap" v-for="(data, index) in alljobtemplates" :value="data.id" :key="index">
                                <a class="dropdown-item col" href="#" data-toggle="modal" data-target="#useTemplateModal2" @click="useTemplate(data)">
                                    <i class="ri-pages-line"></i> {{ data.job_name }}
                                </a>
                                <div class="dropdown-submenu p-0 col-auto" style="z-index: 90000" :id="'template' + index">
                                    <a class="text-light test" href="#" role="button">
                                        <i class="fas fa-ellipsis-h btn-sm px-4 py-2"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow">
                                        <button class="dropdown-item pointer" type="button" @click="editExistingJobTemplate(data)">
                                            <i class="ri-pencil-line"></i> {{ $t('label.edit template') }}
                                        </button>
                                        <button class="dropdown-item text-danger pointer" type="button" @click="openDeleteTempModal(data)">
                                            <i class="ri-delete-bin-line"></i> {{ $t('label.delete template') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item text-dark pointer" @click="openTempFromJobModal()">
                                <i class="ri-pages-line"></i> {{ $t('label.create template from job') }}
                            </a>
                            <a class="dropdown-item text-dark pointer" @click="createNewJobTemplate()">
                                <i class="ri-file-add-line"></i> {{ $t('label.create new template') }}
                            </a>
                            <a class="dropdown-item text-dark pointer" @click="$modal.show('addEmtpyJobModal')">
                                <i class="ri-file-3-line"></i> {{ $t('label.create empty job') }}
                            </a>
                            <div class="dropdown-divider"></div>
                            <router-link :to="{ name: 'jobTemplates' }">
                                <a class="dropdown-item text-dark">
                                    <i class="ri-settings-3-line"></i>&nbsp;{{ $t('label.manage templates') }}
                                </a>
                            </router-link>
                            <!-- <template v-if="jobEditData.showDelete === 'show'">
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item text-danger pointer text-danger" @click="openDeleteJobModal()">
                                    <i class="ri-delete-bin-line"></i> {{ $t('label.delete job') }}
                                </a>
                            </template> -->
                        </div>
                    </div>
                </div>
            </div>

            <!-- Right Side Navigation -->
            <div class="fixed-navigation text-center hidden-md-down" style="margin-top: -0.05rem">
                <a class="dropdown-item" href="#job-information" data-toggle="tooltip" data-placement="left" title="Job Information"><i class="ri-information-line heading-title pr-0"></i></a>
                <a class="dropdown-item" href="#chats" data-toggle="tooltip" data-placement="left" title="Chat"><i class="ri-chat-1-line heading-title pr-0"></i></a>
                <!-- <a class="dropdown-item" href="#job-location" data-toggle="tooltip" data-placement="left" title="Job Location"><i class="ri-map-pin-2-line heading-title pr-0"></i></a> -->
                <a class="dropdown-item" href="#job-tasks" data-toggle="tooltip" data-placement="left" title="Tasks"><i class="ri-todo-line heading-title pr-0"></i></a>
                <a class="dropdown-item" href="#job-notes" data-toggle="tooltip" data-placement="left" title="Notes"><i class="ri-draft-line heading-title pr-0"></i></a>
                <a class="dropdown-item" href="#job-files" data-toggle="tooltip" data-placement="left" title="Files"><i class="ri-folders-line heading-title pr-0"></i></a>
                <a class="dropdown-item" href="#job-materials" data-toggle="tooltip" data-placement="left" title="Materials"><i class="ri-pencil-ruler-2-line heading-title pr-0"></i></a>
                <a v-if="companySettings.settings.invoice" class="dropdown-item" href="#job-invoices" data-toggle="tooltip" data-placement="left" title="Invoices"><i class="ri-bill-line heading-title pr-0"></i></a>
                <!-- <a v-if="companySettings.settings.payment" class="dropdown-item" href="#job-payments" data-toggle="tooltip" data-placement="left" title="Payments"><i class="ri-bank-card-line heading-title pr-0"></i></a> -->
            </div>

            <!-- Content -->
            <div class="row mr-md-0 mr-lg-5 mobile-m-0 sp-window-content">
                <!-- Job Information Section -->
                <div class="col-lg-7 mobile-p-0" id="job-information">
                    <div class="card" id="job-information-card">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 d-flex align-items-center"><i class="ri-information-line heading-title"></i> {{ $t('label.job information') }}</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <button type="button" class="btn btn-sm btn-default m-0" @click="openJobEditModal()">
                                        {{ $t('label.edit') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-lg-2">
                                    <div class="form-group">
                                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.job id') }}</label>
                                        <h3 class="border-bottom">{{ job.job_number ? job.job_number.job_number : '' }}&nbsp;</h3>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <div class="form-group">
                                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.job type') }}</label>
                                        <h3 class="border-bottom">
                                            <span v-if="job.job_type">{{ job.job_type ? job.job_type.name : '' }}&nbsp;</span>
                                        </h3>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.job name') }}</label>
                                        <h3 class="border-bottom">{{ job.job_name }}&nbsp;</h3>
                                    </div>
                                </div>
                                <div v-if="job.customer" class="col-12">
                                    <div class="form-group">
                                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.customer') }}</label>
                                        <h3 class="border-bottom cursor-pointer text-decoration-none">
                                            <router-link :to="{ name: 'customer', params: { customerInfo: job.customer, customerId: job.customer.id } }">
                                                {{ job.customer ? job.customer.name : '' }}
                                            </router-link>
                                        </h3>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.job manager') }}</label>
                                        <h3 class="border-bottom cursor-pointer" @click="getUserInfo(job.job_manager ? job.job_manager.id : '0', 'Job Manager')">
                                            <span>
                                                <img v-if="job.job_manager" class="avatar-img rounded-circle" :src="job.job_manager ? job.job_manager.picture : ''" alt="">
                                                <span>{{ job.job_manager ? job.job_manager.name : 'N/A' }}&nbsp;</span>
                                            </span>
                                        </h3>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.superintendent') }}</label>
                                        <h3 class="border-bottom">
                                            {{ job.customer_contact ? job.customer_contact.full_name : 'N/A' }}&nbsp;
                                        </h3>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.address') }} </label>
                                        <h3 class="border-bottom cursor-pointer text-decoration-none" @click="openMap">
                                            {{ job.address_1 }}<span v-if='job.address_2'> {{ job.address_2 }}</span>, {{ job.city }}, {{ job.state }} {{ job.postal_code }}

                                            <span class="text-muted float-right"><i @click="openMap" class="ri-road-map-line heading-title"></i></span>
                                        </h3>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-3">
                                    <div class="form-group">
                                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.start date') }}</label>
                                        <h3 class="border-bottom">{{ job.start_date | formatDateOnly }}&nbsp;</h3>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-3">
                                    <div class="form-group">
                                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.time estimate') }}</label>
                                        <h3 class="border-bottom">{{ job.time_estimate }}&nbsp;</h3>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.job status') }}</label>
                                        <h3 class="border-bottom">{{ job.job_status ? job.job_status.name : '' }}&nbsp;</h3>
                                    </div>
                                </div>

                                <!-- Custom Fields -->
                                <div v-for="customField in job.job_custom_fields" :key="customField.id" class="col-6">
                                    <div class="form-group">
                                        <label class="form-control-label text-muted">{{ customField.name }}</label>
                                        <h3 class="border-bottom">
                                            <div v-if="customField.custom_field_values.length">
                                                <!-- Check Box Values -->
                                                <template v-if="customField.type === 'checkbox'">
                                                    <span v-for="(val, idx) in customField.custom_field_values[0].value" :key="idx">
                                                        <span v-if="idx < customField.custom_field_values[0].value.length - 1">{{ val }},&nbsp;</span>
                                                        <span v-else>{{ val }}</span>
                                                    </span>
                                                </template>
                                                <!-- Other Values -->
                                                <template v-else>
                                                    {{ customField.custom_field_values[0].value }}&nbsp;
                                                </template>
                                            </div>
                                            <div v-else>&nbsp;</div>
                                        </h3>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- Chat Section -->
                <div class="col-lg-5 mobile-p-0 chat-job-section">
                    <chat-job-old id="chats" :job="job" />
                </div>
                <!-- Tasks Section -->
                <task-component v-if="job !== null" id="job-tasks" :jobid="job.id" :job="job" :user="user" :parent-changed="jobUpdated"></task-component>
                <!-- Notes Section -->
                <job-notes-component v-if="job !== null" id="job-notes" :jobid="job.id"></job-notes-component>
                <!-- Files Section -->
                <job-files-component v-if="job !== null" id="job-files" :jobid="job.id" location="edit"></job-files-component>
                <!-- Materials Section -->
                <job-materials-component v-if="job !== null" id="job-materials" :jobid="job.id"></job-materials-component>
                <!-- Invoice section -->
                <invoices-component v-if="job !== null && companySettings.settings.invoice" id="job-invoices" :jobid="job.id" :job1="job"></invoices-component>
                <!-- Purchase Orders -->
                <purchase-orders-list-for-job v-if="job !== null" :jobid="job.id"></purchase-orders-list-for-job>
                <!-- VPO's -->
                <JobVpoView v-if="job !== null" id="job-vpos" :jobId="job.id"></JobVpoView>
                <!-- Payments Section -->
                <!-- <payment-component v-if="job !== null && companySettings.settings.payment" id="job-payments" :jobid="job.id" :job="job"></payment-component> -->

                <div class="col-xl-12 order-xl-1" style="display: none;">
                    <div class="card">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0"><i class="fas fa-clipboard-list"></i> {{ $t('label.purchase orders') }}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="card-body"></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Edit Job Information Modal -->
        <modal-job-edit v-if="showJobEditModal" :jobdata="job" :key="job.id" source="jobs-edit" @onClose="hideJobEditModal" @onSave="onJobEditSave">
        </modal-job-edit>

        <!-- Mark Job as Closed Modal -->
        <modal-confirm-job-close v-if="showCloseConfirmation" :z-index="3" :job-id="job.id" :custom-confirm-method="false" @on-close-modal="hideCloseJobModal" @onSave="onJobEditSave">
        </modal-confirm-job-close>

        <!-- Create Template from Job -->
        <custom-conf-modal v-if="showTemplateFromJob" :new-id="'tempFromJob'" :title="$t('label.ARE YOU SURE YOU WANT TO CREATE A TEMPLATE FROM THIS JOB')" @customConfirmMethod="confirmTemplateFromJob" @onClose="hideTempFromJobModal">
        </custom-conf-modal>

        <!-- Duplicate Job Modal -->
        <div class="modal fade" id="duplicateJobModal" tabindex="-1" role="dialog" aria-labelledby="duplicateJobModal" aria-hidden="true">
            <div class="modal-dialog modal-primary modal-dialog-centered modal-" role="document">
                <div class="modal-content bg-gradient-primary">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i class="ri-close-line text-white"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="py-3 text-center">
                            <i class="ri-todo-line ni-3x"></i>
                            <h4 class="heading mt-4">{{ $t('label.ARE YOU SURE YOU WANT TO DUPLICATE THIS JOB') }}?</h4>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-white" @click="duplicateJob()">{{ $t('label.yes') }}</button>
                        <button type="button" class="btn btn-link text-white ml-auto" data-dismiss="modal">{{ $t('label.close') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Template Modal -->
        <div class="modal fade" id="useTemplateModal2" tabindex="-1" role="dialog" aria-labelledby="useTemplateModal2" aria-hidden="true">
            <div class="modal-dialog modal-primary modal-dialog-centered modal-" role="document">
                <div class="modal-content bg-gradient-primary">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i class="ri-close-line text-white"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="py-3 text-center">
                            <i class="ri-todo-line ni-3x"></i>
                            <h4 class="heading mt-4 text-uppercase">{{ $t('label.ARE YOU SURE YOU WANT TO USE THIS TEMPLATE') }}?</h4>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-white" data-dismiss="modal" @click="createJobFromTemplate()">{{ $t('label.yes') }}</button>
                        <button type="button" class="btn btn-link text-white ml-auto" data-dismiss="modal">{{ $t('label.close') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Add Job Modal -->
        <modal class="pb-6" name="addEmtpyJobModal" height="auto" width="100%" :max-width="1024" :scrollable="true" :reset="true" :adaptive="true">
            <JobAddComponent :customerid="customerid" :getjobs="'getJobs'" @closeAddJobModal="$modal.hide('addEmtpyJobModal')" @returnAddJobModal="receiveNewJob">
            </JobAddComponent>
        </modal>

        <delete-modal v-if="showJobDeleteModal" :new-id="'Job'" :title="$t('label.ARE YOU SURE YOU WANT TO DELETE THIS JOB')" @onClose="closeDeleteJobModal" @customDeleteMethod="deleteJob">
        </delete-modal>

        <!-- Delete Job Template -->
        <modal-delete-component v-if="showJobTempDlt" :new-id="'Job Template'" :title="$t('label.ARE YOU SURE YOU WANT TO DELETE THIS JOB TEMPLATE')" @onClose="closeDeleteTempModal" @customDeleteMethod="deleteJobTemplate">
        </modal-delete-component>
    </div>
</template>

<script>
// import { mapGetters } from 'vuex';
import InvoicesComponent from '@/components/InvoicesComponent';
import JobAddComponent from '@/components/JobAddComponent.vue';
import CustomConfModal from '@/components/ModalConfirmation.vue';
import { default as DeleteModal, default as ModalDeleteComponent } from '@/components/ModalDelete2.vue';
import PurchaseOrdersListForJob from '@/components/PurchaseOrders/PurchaseOrdersListForJob.vue';
import JobVpoView from '@/components/VariablePurchaseOrders/JobVpoView/JobVpoView.vue';
import bus from '@/utils/event-bus';
import ChatJobOld from './chat/ChatJob.vue';
import JobChat from './job-chat/index.vue';

export default {
    name: 'JobEditComponent',
    props: [],
    components: {
        ChatJobOld,
        JobChat,
        JobVpoView,
        DeleteModal,
        CustomConfModal,
        InvoicesComponent,
        PurchaseOrdersListForJob,
        JobAddComponent,
        ModalDeleteComponent
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            companySettings: JSON.parse(localStorage.getItem('company_settings')),
            job: null,
            jobEditData: null,
            jobUpdated: false,
            showJobEditModal: false,
            showJobDeleteModal: false,
            showTemplateFromJob: false,
            showCloseConfirmation: false,
            showJobTempDlt: false,
            customerid: null,
            templateData: [],
            alljobtemplates: [],
            submittingJobFromTemp: false,
            // chat: {
            //     message: null
            // }
        }
    },
    // computed: {
    //     ...mapGetters({
    //         jobChatRoom: 'chat/jobChatRoom'
    //     }),
    // },
    mounted() {
        this.getJobEditData();
        this.getAllJobTemplates(true);
    },
    methods: {
        getJobEditData() {
            this.loader = this.$loading.show();
            // Open task off canvas if a task Id is passed
            if (this.$route.params.taskId) {
                bus.$emit('openTaskOffCanvas', this.$route.params.taskId)
            }

            return axios.post("/get-job-edit-data", { id: this.$route.params.id }).then(response => {
                this.job = response.data.job;
                this.jobEditData = response.data.job_edit_data;


                this.loader.hide();
                if (!this.job.customer_id)
                    this.showJobEditModal = true;
            }).catch(err => console.log(err));
        },
        getAllJobTemplates(init) {
            return axios.get('/get-active-job-templates').then(response => {
                this.alljobtemplates = response.data.result;

                if (init) {
                    $(".templates").ready(function () {
                        $('.dropdown-submenu a.test').on("click", function (e) {
                            $('.dropdown-submenu a.test').next('div.dropdown-menu').slideUp("fast");
                            $(this).next('div.dropdown-menu').toggle();
                            e.stopPropagation();
                            e.preventDefault();
                        });
                    });

                    $(document).on("click", function (e) {
                        $('.dropdown-submenu a.test').next('div.dropdown-menu').slideUp("fast");
                    });
                }
            }).catch(err => { });
        },
        openMap() {
            let complete_address = this.job.address_1 + ", " + this.job.city + ", " + this.job.state + ", " + this.job.postal_code;
            window.open('http://maps.google.com/?q=' + complete_address);
        },

        openTempFromJobModal() {
            this.showTemplateFromJob = true;
        },
        hideTempFromJobModal() {
            this.showTemplateFromJob = false;
        },
        confirmTemplateFromJob() {
            this.loader = this.$loading.show();

            axios.post("/create-template-from-job", {
                id: this.job.id
            }).then(response => {
                this.$router.push(`/vue/admin/contentManagement/jobTemplates/edit/${response.data.result}`)
                this.loader.hide();
            }).catch(err => console.log(err));
        },

        openJobEditModal() {
            this.showJobEditModal = true;
        },
        hideJobEditModal() {
            this.showJobEditModal = false;
        },
        onJobEditSave() {
            this.getJobEditData();

            // Inform Child Components
            this.jobUpdated = true; // Tracked by children
            this.$nextTick(() => {
                this.jobUpdated = false;
            });

            this.showJobEditModal = false;
        },

        showCloseJobModal() {
            this.showCloseConfirmation = true;
        },
        hideCloseJobModal() {
            this.showCloseConfirmation = false;
        },
        createNewJobTemplate() {
            axios.post("/create-job-template").then(response => {
                this.editExistingJobTemplate(response.data.result);
            }).catch(err => console.log(err));
        },
        editExistingJobTemplate(template) {
            this.$router.push({
                name: 'jobTemplateEdit',
                params: { id: template.id }
            });
        },
        closeDeleteTempModal() {
            this.showJobTempDlt = false;
        },
        openDeleteTempModal(j_template) {
            this.templateId = j_template.job_id;
            this.showJobTempDlt = true;
        },
        deleteJobTemplate() {
            axios.post('/delete-job-template', {
                id: this.templateId
            }).then(response => {
                this.closeDeleteTempModal();
                this.getAllJobTemplates();
                bus.$emit('banner-success', {
                    message: $t('label.job template deleted successfully') + '!',
                });
            }).catch(err => { });
        },
        useTemplate(data) {
            this.templateData = data;
        },
        createJobFromTemplate() {
            // if (!this.submittingJobFromTemp) {
            //     this.submittingJobFromTemp = true;
            var data = {
                'id': this.templateData.job_id,
                'job_name': this.templateData.job_name
            };

            axios.post('/create-job-from-template', data).then(response => {
                // after creating the job from the template, edit the job
                this.editExistingJob(response.data.id);
            }).catch(err => { });

            // }
        },
        editExistingJob(jobNumber) {
            this.$router.push({
                // path: `jobs/edit/${jobNumber}`
                name: 'jobEdit',
                params: { id: jobNumber }
            });

            this.getJobEditData();
            this.getAllJobTemplates(true);
        },
        receiveNewJob(jobNumber) {
            //since it's being created from jobeditcomponent
            //we need to replace curr jobedit info
            this.getJobEditData();
        },
        reopenJob() {
            axios.post("/reopen-job", {
                job_id: this.job.id
            }).then(response => {
                this.onJobEditSave();
                bus.$emit('banner-success', {
                    message: $t('label.job reopened successfully') + '!'
                });
            }).catch(err => console.log(err));
        },

        openDeleteJobModal() {
            this.showJobDeleteModal = true;
        },
        closeDeleteJobModal() {
            this.showJobDeleteModal = false;
        },
        deleteJob() {
            this.loader = this.$loading.show();

            axios.post("/jobs/delete", { id: this.job.id }).then(() => {
                this.loader.hide();
                bus.$emit('banner-success', {
                    message: $t('label.job deleted successfully') + '!'
                });

                setTimeout(() => {
                    this.$router.replace({ name: 'jobs' });
                }, 2000);
            }).catch(err => console.log(err));
        },

        duplicateJob() {
            $('#duplicateJobModal').modal('hide');
            this.loader = this.$loading.show();

            axios.post("/duplicate-job", {
                id: this.job.id
            }).then(response => {
                // Set router to refer back to previous page, not current job
                this.$router.replace({ path: `/vue/jobs/edit/${response.data.job_number}` });

                // Reload job data for newly duplicated job
                this.loader.hide();
                this.getJobEditData().then(() => {
                    bus.$emit('banner-success', {
                        message: $t('label.Job successfully duplicated') + '!'
                    });
                });
            }).catch(err => console.log(err));
        }
    },
}
</script>
