<template>
    <!-- <div id="chat_popup" v-if="activeChatRooms.length > 0"> -->
    <div id="chat-popup">
        <div id="chat_popup_windows">
            <div v-for="(item, index) in activeChatRooms" :key="index">
                <ChatPopupWindowComponent :chatRoomIndex="index"></ChatPopupWindowComponent>
            </div>
            <ChatPopupWindowComponent></ChatPopupWindowComponent>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ChatPopupWindowComponent from './ChatPopupWindowComponent';
export default {
    // name: 'ChatPopupComponent',
    components: {
        ChatPopupWindowComponent
    },
    computed: {
        ...mapGetters({
            activeChatRooms: 'chat/activeChatRooms',
            // userChatRooms:'chat/userChatRooms',
        }),
    },
    data() {
        return {
        }
    },
    methods: {
    },
};
</script>
<style lang="scss"></style>
