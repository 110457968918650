/* eslint-disable no-unused-vars */
const namespaced = true;

const state = {
    userClockin: [],
    hasLoaded: false,
    clockin: false,
    isEdit: false,
    timeId: null
};

const getters = {
    userClockin: state => state.userClockin,
    hasLoaded: state => state.hasLoaded,
    isEdit: state => state.isEdit,
    timeId: state => state.timeId,
};


const actions = {
    // Add listener to watch local storage for only other browser
    // windows that may be open on the site for current user.
    initLocalStorageWatcher({ dispatch }) {
        // window.addEventListener("storage", () => {
        //     dispatch('getUserCurrentClockin');
        // });
    },

    getUserCurrentClockin({ state, commit, rootState, dispatch }) {
        console.log('hiiiiiiiiiiii');
        return new Promise((resolve, reject) => {
            let user = JSON.parse(localStorage.getItem('user'));

            axios.get('/get-user-current-clockin/' + user.id).then(res => {
                commit('setUserClockin', res.data.result);

                if (res.data.result) {
                    // Removed time elapsed for simiplicity until further notice
                    //
                    // let startingDate = new Date(res.data.result.time_in),
                    //     startTime = startingDate.getTime(),
                    //     currentTime = (new Date(new Date().toLocaleString("en-US", {timeZone: rootState.user.timezone}))).getTime(),
                    //     timeElapsed = (new Date(currentTime - startTime)).getTime();

                    if (state.clockin == false)
                        state.clockin = true;

                    // Set vuex and local storage clockin status
                    localStorage.setItem('clockedIn', true);
                    commit('setHasLoaded', true);

                    if (res.data.result.description == null)
                        commit('setIsEdit', true);

                } else {
                    // Set vuex and local storage clockin status
                    localStorage.setItem('clockedIn', false);
                    commit('setHasLoaded', false);
                }

                resolve(res);
            })
                .catch(err => reject(err));
        });
    },
    timeIn({ state, commit, rootState, dispatch }, params) {
        return new Promise((resolve, reject) => {
            axios.post('/time-in', params).then(res => {
                commit('setTimeId', res.data.time_id)
                resolve(res);
            }).catch(err => reject(err));
        });
    },
    timeOut({ state, commit, rootState, dispatch }, params) {
        return new Promise((resolve, reject) => {
            axios.post('/time-out', params).then(res => {
                dispatch('getUserCurrentClockin');
                state.clockin = false;
                resolve(res);
            }).catch(err => reject(err));
        });
    },
}

const mutations = {
    setUserClockin(state, userClockin) {
        state.userClockin = userClockin;
    },
    setHasLoaded(state, hasLoaded) {
        state.hasLoaded = hasLoaded;
    },
    setIsEdit(state, isEdit) {
        state.isEdit = isEdit;
    },
    setTimeId(state, timeId) {
        state.timeId = timeId;
    },
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
};