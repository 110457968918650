<template>
    <div @mouseleave="hideButton">
        <!-- Overlay Close Button -->
        <span class="closeBtn pointer" :id="overlayCloseId" @click="$emit('onClose', dataToTransmit)">
            <span class="btnForeground" style="z-index: 100 !important">
                <svg :width="iconSize" 
                    :height="iconSize"
                    viewBox="0 0 24 24" 
                    fill="rgba(255,9,0,1)" 
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" d="M0 0h24v24H0z"/>
                    <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z" />
                </svg>
            </span>
            <span class="btnBackdrop badge badge-dot"><i class="bg-white"></i></span>
        </span>
        
        <!-- Element to Overlay -->
        <span @mouseover="showButton">
            <slot id="elementToOverlay"></slot>
        </span>
    </div>
</template>

<style>
    .btnForeground {
        position: fixed;
    }
    .btnBackdrop {
        position: fixed;
        margin-top: 1px;
        margin-left: 1px;
        width: 15px !important;
        height: 15px !important;
    }
    .closeBtn {
        position: fixed;
        visibility: hidden;
    }
</style>

<script>
export default {
    props: ['elmId', 'dataToTransmit','customSize'],
    emits: ['onClose'],
    data() {
        return {
			iconSize: 15,
            overlayCloseId: 'overlayCloseButton' + this.elmId,
            elmToOverlay: null
        }
    },
    mounted() {
    	if(this.customSize){
            this.iconSize = this.customSize
        }
    },
    methods: {
        showButton() {
            this.elmToOverlay = document.getElementById(this.elmId).getBoundingClientRect();
            let overlayButtonElm = document.getElementById(this.overlayCloseId);

            overlayButtonElm.style.top = (this.elmToOverlay.top-10).toString() + "px";
            overlayButtonElm.style.left = (this.elmToOverlay.right-11).toString() + "px";
            overlayButtonElm.style.visibility = 'visible';
        }, 
        hideButton() {
            let overlayButtonElm = document.getElementById(this.overlayCloseId);
            overlayButtonElm.style.visibility = 'hidden';
        }
    }
}
</script>