<template>
    <div class="">
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid sp-mobile-mt-50" id="addVehicleBody">
            <!-- Header -->
            <div class="row align-items-center py-4">
                <div class="col-5">
                    <h6 v-if="vehicle.id" class="h2 text-white d-inline-block mb-0">{{ $t('label.vehicles edit') }}</h6>
                    <h6 v-if="!vehicle.id" class="h2 text-white d-inline-block mb-0">{{ $t('label.vehicles add') }}</h6>
                </div>
                <div class="col-7 text-right">
                    <span class="d-block d-sm-inline pb-2">
                        <button type="button" class="btn btn-link text-white" @click="toPreviousPage()">
                            {{ $t('label.cancel') | uppercase }}
                        </button>
                    </span>
                    <span class="d-block d-sm-inline">
                        <button type="button" class="btn btn-white" @click="saveVehicle()">
                            {{ $t('label.vehicles save') | uppercase }}
                        </button>
                    </span>
                </div>
            </div>

            <div id="addVehicle" class="d-flex">
                <!-- Left sticky menu -->
                <div class="nav-bar hidden-md-down align-items-center" id="navWrapper">
                    <div class="sticky-top pt-4 mt--4" style="z-index: 0">
                        <div class="card sp-left-nav" id="nav">
                            <div class="card-body px-3 py-2">
                                <ul class="navbar-nav w-100">
                                    <li class="nav-item px-2 mx--2">
                                        <a class="nav-link d-flex align-items-center" href="#addVehicleBody">
                                            <i class="ri-information-line"></i>
                                            {{ $t('label.details') }}
                                        </a>
                                    </li>
                                    <li class="nav-item px-2 mx--2">
                                        <a class="nav-link d-flex align-items-center" href="#vehicleSecifications">
                                            <i class="ri-file-list-2-line"></i>
                                            {{ $t('label.specifications') }}
                                        </a>
                                    </li>
                                    <li class="nav-item px-2 mx--2">
                                        <a class="nav-link d-flex align-items-center" href="#vehicleLifecycle">
                                            <i class="ri-refresh-line"></i>
                                            {{ $t('label.lifecycle') }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Right column content -->
                <div class="vehicle-body justify-content-end flex-grow-1 mb-5">
                    <div class="mobile-p-0" id="vehicle-content-column">

                        <!-- Vehicle Details -->
                        <div class="card" id="vehicleDetails">
                            <div class="card-header">
                                <div class="align-items-center">
                                    <div class="">
                                        <h3 class="mb-0 d-flex align-items-center"><i class="ri-map-pin-2-line heading-title"></i>{{ $t('label.identification') }}</h3>
                                    </div>
                                </div>
                            </div>

                            <!-- Identification -->
                            <div class="card-body">
                                <!-- Vehicle Name -->
                                <div class="form-group mb-3">
                                    <label class="form-control-label" style="font-weight: bold;" for="">{{ $t('label.vehicles name') }}</label>
                                    <span class="text-danger text-xs"> * {{ $t("label.required") }}</span>
                                    <input v-model="vehicle.vehicle_name" type="text" id="vehicle_name" name="vehicle_name" class="form-control" :class="{ 'border-danger': this.valErrors.vehicle_name }" :placeholder="$t('label.vehicles enter a name to distinguish this vehicle')" @change="inputValidate('vehicle_name')" autocomplete="input-field" data-lpignore="true" autofocus required>
                                </div>

                                <!-- VIN -->
                                <div class="form-group mb-3">
                                    <label class="form-control-label" style="font-weight: bold;" for="">{{ $t('label.vehicles vin long') }}</label>
                                    <div class="d-flex">
                                        <div class="flex-grow-1 pr-4">
                                            <input class="form-control" :class="{ 'border-danger': this.valErrors.vin }" v-model="vehicle.vin" type="text" id="vin" name="vin" autocomplete="input-field" data-lpignore="true" @change="inputValidate('vin')" @keyup="onVinKeypress()" minlength="17" maxlength="17">
                                            <div class="text-danger text-sm" v-if="this.valErrors.vin">{{ $t('label.vehicles vin error') }}</div>
                                        </div>

                                        <div class="ml-auto">
                                            <button class="btn btn-default text-bold" type="button" @click="vinDecoder()" :disabled="validateVin(vehicle.vin)">
                                                <i class="ri-lock-2-line" style="font-size:15px"></i>
                                                &nbsp;{{ $t('label.vehicles decode vin') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <!-- License Plate -->
                                <div class="form-group mb-3">
                                    <label class="form-control-label" style="font-weight: bold;" for="">{{ $t('label.vehicles plate') }}</label>
                                    <input v-model="vehicle.license_plate" type="text" name="license_plate" class="form-control" autocomplete="input-field" data-lpignore="true">
                                </div>

                                <!-- Vehicle Type -->
                                <div class="form-group mb-3">
                                    <label class="form-control-label" style="font-weight: bold;" for="">{{ $t('label.vehicles type') }}</label>
                                    <span class="text-danger text-xs"> * {{ $t("label.required") }}</span>

                                    <select class="form-control" :class="{ 'border-danger': this.valErrors.vehicle_type }" v-model="vehicle.vehicle_type" @change="inputValidate('vehicle_type')" id="vehicle_type" name="vehicle_type">
                                        <option value="null" disabled selected hidden>{{ $t('label.vehicles categorize this vehicle type') }}</option>
                                        <option value="null" disabled>-&nbsp;{{ $t('label.select') }}&nbsp;-</option>
                                        <option v-for="(type, index) in vehicleTypes" :key="index" :value="type">{{ type }}</option>
                                    </select>
                                </div>

                                <!-- Year -->
                                <div class="form-group mb-3">
                                    <label class="form-control-label" style="font-weight: bold;" for="">{{ $t('label.vehicles year') }}</label>
                                    <input v-model="vehicle.year" type="number" name="year" class="form-control" autocomplete="input-field" data-lpignore="true">
                                </div>

                                <!-- Make -->
                                <div class="form-group mb-3">
                                    <label class="form-control-label" style="font-weight: bold;" for="">{{ $t('label.vehicles make') }}</label>
                                    <input v-model="vehicle.make" type="text" name="make" class="form-control" autocomplete="input-field" data-lpignore="true">
                                </div>

                                <!-- Model -->
                                <div class="form-group mb-3">
                                    <label class="form-control-label" style="font-weight: bold;" for="">{{ $t('label.vehicles model') }}</label>
                                    <input v-model="vehicle.model" type="text" name="model" class="form-control" autocomplete="input-field" data-lpignore="true">
                                </div>

                                <!-- Registration State or Province -->
                                <div class="form-group mb-3">
                                    <label class="form-control-label" style="font-weight: bold;" for="">{{ $t('label.vehicles registration state') }}</label>
                                    <select class="form-control" v-model="vehicle.registration_state" name="registrationState">
                                        <option value="null" disabled selected hidden>-&nbsp;{{ $t('label.vehicles select registration state') }}&nbsp;-</option>
                                        <option value="null" disabled>-&nbsp;{{ $t('label.select') }}&nbsp;-</option>
                                        <option v-for="(state, index) in states" :key="index" :value="capitalizeFirst(index)">{{ capitalizeFirst(index) }}</option>
                                    </select>
                                </div>

                                <!-- Registration Expiration Month -->
                                <div class="form-group mb-3">
                                    <label class="form-control-label" style="font-weight: bold;" for="">{{ $t('label.vehicles registration expiration') }}</label>
                                    <date-picker v-model="vehicle.registration_expiration" format="MM/DD/YYYY" class="full-width" :append-to-body="false" :placeholder="$t('label.select date')" :input-class="{ 'text-placeholder': true, 'form-control': true }">
                                    </date-picker>
                                </div>

                                <!-- Photo -->
                                <div class="form-group">
                                    <label class="form-control-label text-bold" for="serial_number">{{ $t('label.photo') }}</label>

                                    <!-- Show Photo -->
                                    <div class="form-group" v-if="newPhoto || vehicle.vehicle_photo">
                                        <div class="form-group d-flex justify-content-left">
                                            <button type="button" class="btn btn-outline-danger btn-sm" @click="removePhoto()">
                                                <i class="ri-delete-bin-line"></i>
                                                {{ $t('label.remove photo') | uppercase }}
                                            </button>
                                        </div>
                                        <div class="form-group d-flex justify-content-center">
                                            <img v-if="newPhoto" class="text-center sp-asset-form-img" :src="newPhoto.dataURL" alt="" />
                                            <img v-if="vehicle.vehicle_photo" class="text-center sp-asset-form-img" :src="vehicle.vehicle_photo" alt="" />
                                        </div>
                                    </div>
                                    <!-- Load New Photo -->
                                    <div v-else>
                                        <vue-dropzone style="z-index: 0" ref="myVueDropzone" id="dropzone" name="dropzone" :options="dropzoneOptions" :destroyDropzone="false" v-on:vdropzone-success="getUploadedFile"></vue-dropzone>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Classification -->
                        <div class="card">
                            <div class="card-header">
                                <div class="align-items-center">
                                    <div class="">
                                        <h3 class="mb-0 d-flex align-items-center"><i class="ri-file-info-line heading-title"></i>
                                            {{ $t('label.classification') }}</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body">
                                <!-- Status -->
                                <div class="form-group mb-3">
                                    <label class="form-control-label" style="font-weight: bold;" for="">{{ $t('label.status') }}</label>
                                    <span class="vehicle-status" :class="vehicle.status.toLowerCase().replace(/ /g, '')"></span>&nbsp;
                                    <select class="form-control" v-model="vehicle.status" name="status" @change="inputValidate('assigned_user_id')">
                                        <option value="null" disabled selected hidden>{{ $t('label.active') }}</option>
                                        <option value="null" disabled>-&nbsp;{{ $t('label.select') }}&nbsp;-</option>
                                        <option v-for="(status, index) in statuses" :key="index" :value="status">{{ status }}</option>
                                    </select>
                                </div>

                                <!-- Operator -->
                                <div class="form-group mb-3">
                                    <label class="form-control-label" style="font-weight: bold;" for="">{{ $t('label.vehicles operator') }}</label>
                                    <span v-if="vehicle.status === 'Active'" class="text-danger text-xs"> * {{ $t("label.required") }}</span>
                                    <div class="sp-tooltip">
                                        <b-icon icon="question-circle-fill" aria-label="Help"></b-icon>
                                        <span class="sp-tooltiptext">
                                            {{ $t('label.operator is only required on Active status') }}
                                        </span>
                                    </div>
                                    <select class="form-control" :class="{ 'border-danger': valErrors.assigned_user_id }" v-model="vehicle.operator.user_id" @change="inputValidate('assigned_user_id')" id="assigned_user_id" name="assigned_user_id">
                                        <option :value="null" selected>-&nbsp;{{ $t('label.unassigned') }}&nbsp;-</option>
                                        <option v-for="(operator, index) in users" :key="index" :value="operator.id">
                                            {{ operator.name }}
                                        </option>
                                    </select>
                                </div>

                                <!-- Ownership -->
                                <div class="form-group mb-3">
                                    <label class="form-control-label" style="font-weight: bold;" for="">{{ $t('label.vehicles ownership') }}</label>
                                    <select class="form-control" v-model="vehicle.ownership_type" name="ownership_type">
                                        <option v-for="(type, index) in ownershipTypes" :key="index" :value="type">{{ type }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <!-- Specifications -->
                        <div class="card" id="vehicleSecifications">
                            <!-- Fuel -->
                            <div class="card-header">
                                <div class="align-items-center">
                                    <div class="">
                                        <h3 class="mb-0 d-flex align-items-center"><i class="ri-gas-station-fill heading-title"></i>
                                            {{ $t('label.fuel') }}</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body">
                                <!-- Fuel Type -->
                                <div class="form-group mb-3">
                                    <label class="form-control-label" style="font-weight: bold;" for="">{{ $t('label.vehicles fuel type') }}</label>
                                    <select class="form-control" v-model="vehicle.specifications.fuel_type" name="make">
                                        <option value="null" disabled selected hidden>-&nbsp;{{ $t('label.vehicles select fuel type') }}&nbsp;-</option>
                                        <option value="null" disabled>-&nbsp;{{ $t('label.select') }}&nbsp;-</option>
                                        <option v-for="(type, index) in fuel_types" :key="index" :value="type">{{ capitalizeFirst(type) }}</option>
                                    </select>
                                </div>

                                <!-- Fuel Capacity -->
                                <div class="form-group mb-3">
                                    <label class="form-control-label" style="font-weight: bold;" for="">{{ $t('label.vehicles fuel capacity') }}&nbsp;({{ $t('label.vehicles US gallons') }})</label>
                                    <input v-model="vehicle.specifications.fuel_max_gallons" type="number" name="fuel_capacity" class="form-control" placeholder="i.e. 25" autocomplete="on" data-lpignore="true">
                                </div>

                                <!-- Miles Per Gallon -->
                                <div class="form-group mb-3">
                                    <label class="form-control-label" style="font-weight: bold;" for="">{{ $t('label.vehicles fuel mpg') }}</label>
                                    <input v-model="vehicle.specifications.fuel_miles_per_gallon" type="number" name="miles_per_gallon" class="form-control" autocomplete="on" data-lpignore="true">
                                </div>
                            </div>
                        </div>

                        <!-- Tires & Battery -->
                        <div class="card" id="vehicleSecifications">
                            <div class="card-header">
                                <div class="align-items-center">
                                    <div class="">
                                        <h3 class="mb-0 d-flex align-items-center"><i class="ri-settings-3-line heading-title"></i>
                                            {{ $t('label.vehicles tires and Battery') }}</h3>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body">
                                <!-- Tire Size -->
                                <div class="form-group mb-3">
                                    <label class="form-control-label" style="font-weight: bold;" for="">{{ $t('label.vehicles tire size') }}</label>
                                    <input v-model="vehicle.specifications.tire_size" type="text" name="tire_size" class="form-control" autocomplete="input-field" data-lpignore="true">
                                </div>

                                <!-- Battery Size -->
                                <div class="form-group mb-3">
                                    <label class="form-control-label" style="font-weight: bold;" for="">{{ $t('label.vehicles battery size') }}</label>
                                    <input v-model="vehicle.specifications.battery_size" type="text" name="battery_size" class="form-control" autocomplete="input-field" data-lpignore="true">
                                </div>
                            </div>
                        </div>

                        <!-- Lifecycle -->
                        <!-- In Service -->
                        <div class="card" id="vehicleLifecycle">
                            <div class="card-header">
                                <div class="align-items-center">
                                    <div class="">
                                        <h3 class="mb-0 d-flex align-items-center">
                                            <i class="ri-time-line heading-title"></i>{{ $t('label.vehicles in service') }}
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body">
                                <!-- Start Date -->
                                <div class="form-group mb-3">
                                    <label class="form-control-label" style="font-weight: bold;" for="">{{ $t('label.start date') }}</label>
                                    <date-picker v-model="vehicle.in_service_date" format="MM/DD/YYYY" class="full-width" :append-to-body="false" :placeholder="$t('label.select date')" :input-class="{ 'text-placeholder': true, 'form-control': true }">
                                    </date-picker>
                                </div>

                                <!-- Starting Odometer -->
                                <div class="form-group mb-3">
                                    <label class="form-control-label" style="font-weight: bold;" for="">{{ $t('label.starting odometer') }}</label>
                                    <input v-model="vehicle.in_service_odometer" type="number" name="odometer" class="form-control" :placeholder="$t('label.vehicles odometer reading on in service date')" autocomplete="input-field" data-lpignore="true">
                                </div>
                            </div>
                        </div>

                        <!-- Footer Save or Cancel Buttons -->
                        <div class="d-flex text-right">
                            <div class="ml-auto">
                                <span class="d-block d-sm-inline pb-2">
                                    <button type="button" class="btn btn-link" @click="toPreviousPage()">
                                        {{ $t('label.cancel') | uppercase }}
                                    </button>
                                </span>
                                <span class="d-block d-sm-inline">
                                    <button type="button" class="btn btn-primary" @click="saveVehicle()">
                                        {{ $t('label.vehicles save') | uppercase }}
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.vehicle-preview {
    max-width: 250px;
}

.vehicle-body {
    margin-left: 30px;
}

@media (max-width: 992px) {
    .vehicle-body {
        margin-left: 0px;
    }
}
</style>

<script>

import moment from '@/utils/date-time';
import bus from '@/utils/event-bus';
import { BIcon } from 'bootstrap-vue';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    props: ["selectedVehicleId"],
    components: {
        vueDropzone: vue2Dropzone,
        BIcon,
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem("user")),
            loader: null,
            navSnapped: false,
            company_users: [],
            newPhoto: null,
            users: [],
            statuses: [],
            vehicleTypes: [],
            ownershipTypes: [],
            fuel_types: [],
            states: [],
            months: [],
            vehicle: {
                id: 0,
                operator: {
                    id: 0,
                    user_id: null,
                    date_start: moment().format("YYYY-MM-DD"),
                    date_end: "",
                    vehicle_id: 0,
                },
                edit: false,
                company_id: JSON.parse(localStorage.getItem("company_settings")).id,
                created_by_user_id: null,
                in_service_date: null,
                in_service_odometer: null,
                license_plate: null,
                make: null,
                model: null,
                ownership_type: "Owned",
                registration_expiration: null,
                registration_state: null,
                prev_page_source: 'vehicles',
                specifications: {
                    fuel_type: null,
                    fuel_max_gallons: null,
                    fuel_miles_per_gallon: null,
                    tire_size: null,
                    battery_size: null,
                },
                status: "Active",
                vehicle_name: "",
                vehicle_photo: null,
                vehicle_type: "",
                vin: null,
                year: null,
            },
            valErrors: {
                vin: false,
                vehicle_name: false,
                vehicle_type: false,
                assigned_user_id: false,
            },
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                maxFiles: 1,
                acceptedFiles: 'image/*',
                capture: 'camera',
                addRemoveLinks: true,
                dictDefaultMessage: `
                            <div>
                                <span>${this.$t('label.drag drop files')}</span><br>
                                <span>${this.$t('label.or')}</span><br>
                                <button type="button" class="btn btn-primary">
                                    ${this.$t('label.browse')}
                                    ${this.$t('label.files')}                                
                                </button>
                            </div>
                        `,
            },
        };
    },
    created() {
        if (this.$route.name === 'vehicleEdit') {
            // Send back to assets page if selectedVehicleId isn't set on edit
            if (!this.selectedVehicleId) {
                this.$router.push({ name: 'assets' })
                return
            }
        }
        this.initEditVehicle();

        this.getAllUsers();
        this.getStatuses();
        this.getVehicleTypes();
        this.getStates();
        this.getOwnershipTypes();
        this.getFuelTypes();
    },
    methods: {
        getAllUsers() {
            axios.get("/get-all-users").then((response) => {
                this.users = response.data.result;
            }).catch((err) => console.log("get status error", err));
        },
        getStatuses() {
            axios.get("/get-statuses").then((response) => {
                this.statuses = response.data.result;
            }).catch((err) => console.log("get status error", err));
        },
        getVehicleTypes() {
            axios.get("/get-vehicle-types").then((response) => {
                this.vehicleTypes = response.data.result;
            }).catch((err) => console.log("get vehicle types error", err));
        },
        getProvinces() {
            axios.get("/get-provinces").then((response) => {
                this.states = response.data.result;
            }).catch((err) => console.log("get states error", err));
        },
        getStates() {
            axios.get("/get-states").then((response) => {
                this.states = response.data.result;
            }).catch((err) => console.log("get states error", err));
        },
        getOwnershipTypes() {
            axios.get("/get-ownership-types").then((response) => {
                this.ownershipTypes = response.data.result;
            }).catch((err) => console.log("get ownership types error", err));
        },
        getFuelTypes() {
            axios.get("/get-fuel-types").then((response) => {
                this.fuel_types = response.data.result;
            }).catch((err) => console.log("get fuel types error", err));
        },
        validateVin(vin) {
            if (vin) {
                if (vin.length >= 17) {
                    return true
                }
            }
            return false
        },
        // If edit vehicle
        initEditVehicle() {
            if (this.selectedVehicleId > 0) {
                this.loader = this.$loading.show();
                axios
                    .get(`/vehicles/edit/${this.selectedVehicleId}`)
                    .then(response => {
                        this.vehicle = response.data
                        this.loader.hide();

                        // Check for vehicle operator
                        if (!this.vehicle.operator) {
                            this.$set(this.vehicle, 'operator', {
                                id: 0,
                                user_id: "",
                                date_start: moment().format("YYYY-MM-DD"),
                                date_end: "",
                                vehicle_id: 0,
                            });
                        }

                        // Format dates
                        if (this.vehicle.registration_expiration)
                            this.vehicle.registration_expiration = moment(this.vehicle.registration_expiration).toDate();
                        if (this.vehicle.in_service_date)
                            this.vehicle.in_service_date = moment(this.vehicle.in_service_date).toDate();
                        // Check for odometer
                        if (this.vehicle.odometer)
                            this.vehicle.in_service_odometer = this.vehicle.odometer.odometer_reading;
                        // Vin
                        if (!this.vehicle.vin)
                            this.vehicle.vin = "";
                    })
                    .catch(err => {
                        console.log('ERROR: ', err);
                        bus.$emit('banner-error', { message: err, });
                        this.loader.hide();
                    })

            }
        },

        capitalizeFirst(item) {
            if (item.length > 0) {
                var firstLetter = item[0].toUpperCase(),
                    lowerItem = item.toLowerCase();

                return firstLetter + lowerItem.slice(1, lowerItem.length);
            }
        },

        // Vin on keypress
        onVinKeypress(e) {
            this.vehicle.vin = this.vehicle.vin.replace(/[^a-zA-Z0-9]/g, "");
        },
        // Vin Decode
        vinDecoder() {
            var siteUrl = "https://vpic.nhtsa.dot.gov/api/vehicles/decodeVin/",
                dataFormat = "BA?format=json";

            axios
                .get(siteUrl + this.vehicle.vin + dataFormat)
                .then((response) => {
                    var vinArrayData = response.data.Results,
                        vinObjData = {};

                    // Convert given data into a readable object
                    vinObjData = vinArrayData.reduce(function (obj, item) {
                        return {
                            ...obj,
                            [item["Variable"].replace(/\s|-/g, "")]: item,
                        };
                    }, {});

                    this.vehicle.make = vinObjData.Make.Value;
                    this.vehicle.model = vinObjData.Model.Value;
                    this.vehicle.year = vinObjData.ModelYear.Value;

                    // Establish fuel type
                    switch (vinObjData.FuelTypePrimary.Value) {
                        case "Gasoline":
                            this.vehicle.specifications.fuel_type = "gas";
                            break;
                        case "Diesel":
                            this.vehicle.specifications.fuel_type = "diesel";
                            break;
                        case "Propane":
                            this.vehicle.specifications.fuel_type = "propane";
                            break;
                        case "Not Applicable":
                        case null:
                        case undefined:
                            this.vehicle.specifications.fuel_type = null;
                            break;
                        default:
                            this.vehicle.specifications.fuel_type = "other";
                    }
                })
                .catch((err) => {
                    console.log("vin decoding error", err);
                });
        },

        // Image functions
        getUploadedFile(file) {
            // Wait for dropzone animation, then save file to identObj
            setTimeout(() => {
                this.newPhoto = file;
            }, 1000);
        },
        removePhoto() {
            this.newPhoto = null;
            this.vehicle.vehicle_photo = null;
        },

        // Validation
        inputValidate(name) {
            switch (name) {
                case "vehicle_name":
                    // Vehicle_name Required
                    if (this.vehicle.vehicle_name.length === 0)
                        this.valErrors.vehicle_name = true;
                    else
                        this.valErrors.vehicle_name = false;

                    break;
                case "vin":
                    // Vin length must = 17
                    if (this.vehicle.vin) {
                        if (this.vehicle.vin.length !== 0 && this.vehicle.vin.length !== 17)
                            this.valErrors.vin = true;
                    }
                    else
                        this.valErrors.vin = false;

                    break;
                case "vehicle_type":
                    // Vehicle_type required
                    if (!this.vehicle.vehicle_type)
                        this.valErrors.vehicle_type = true;
                    else
                        this.valErrors.vehicle_type = false;

                    break;
                case "assigned_user_id":
                    // If status is active, operator is required
                    this.valErrors.assigned_user_id = false;
                    if (this.vehicle.status === "Active") {
                        if (this.vehicle.operator.user_id === null || this.vehicle.operator.user_id.length === 0)
                            this.valErrors.assigned_user_id = true;
                    }

                    break;
                default:
                    console.log("Invalid input");
                    break;
            }
        },
        formValidate(saveValidate) {
            let hasError = false
            for (var error in this.valErrors) {
                this.inputValidate(error);
            }

            if (saveValidate) {
                for (var err in this.valErrors) {
                    if (this.valErrors[err]) {
                        hasError = true
                        var newFocus = document.getElementById(err);
                        newFocus.focus();
                    }
                }
            }

            if (hasError) {
                bus.$emit('banner-error', { message: this.$t('label.generic form validation error') })
                return false;
            }

            return true;
        },
        // Form Save
        saveVehicle() {
            if (this.formValidate(true)) {
                var fields = JSON.parse(JSON.stringify(this.vehicle));

                // Format dates in mysql format
                if (this.vehicle.in_service_date)
                    fields.in_service_date = moment(this.vehicle.in_service_date).format("YYYY-MM-DD");
                if (this.vehicle.registration_expiration)
                    fields.registration_expiration = moment(this.vehicle.registration_expiration).format("YYYY-MM-DD");

                let url = "/vehicles-store", // Create new
                    action = "create";
                if (this.vehicle.id > 0) {
                    url = "/vehicles-update"; // Update existing
                    action = "update";
                }

                axios.post(url, fields).then((response) => {
                    // Save vehicle photo to vehicle.
                    const photoData = new FormData();
                    photoData.append('download', this.newPhoto);
                    photoData.append('data', JSON.stringify({
                        'id': response.data.vehicleId
                    }));

                    // Save vehicle image
                    if (this.newPhoto) {
                        axios.post('/vehicles-upload-file', photoData).then((response) => {
                            // console.log(response);
                        }).catch((err) => console.log(err));
                    }

                    // Show success banner then reroute
                    let message = this.$t('label.vehicle created successfully') + '!';
                    if (action === 'update')
                        message = this.$t('label.vehicle updated successfully') + '!';

                    bus.$emit('banner-success', {
                        message: message
                    });
                    setTimeout(function () {
                        this.toPreviousPage();
                    }.bind(this), 1200);

                }).catch((err) => {
                    if (err.status == 422) {
                        if (err.data.errors) {
                            Object.keys(err.data.errors).forEach((i) => {
                                const element = err.data.errors[i]
                                bus.$emit('banner-error', { message: element[0] });
                            });
                        }
                    }
                    console.log("Error saving vehicle", err);
                });
            }
        },
        toPreviousPage() {
            this.$router.push({ name: 'assets' })
        }
    },
};
</script>
