/* eslint-disable no-unused-vars */
const namespaced = true;

const state = {
    allPurchaseOrderStatus: [],
    purchaseOrderNumber: null,
    variablePurchaseOrderNumber: null,
    allPurchaseOrders: [],
    allVendors: [],
    purchaseOrder: {},
    purchaseOrderItems: [],
    purchaseOrderSpecialAttachments: [],
    purchaseOrderNotes: [],
    purchaseOrderFiles: [],
    purchaseOrderActivityLogs: [],
};

const getters = {
    allPurchaseOrderStatus: state => state.allPurchaseOrderStatus,
    purchaseOrderNumber: state => state.purchaseOrderNumber,
    variablePurchaseOrderNumber: state => state.variablePurchaseOrderNumber,
    allPurchaseOrders: state => state.allPurchaseOrders,
    allVendors: state => state.allVendors,
    purchaseOrder: state => state.purchaseOrder,
    purchaseOrderItems: state => state.purchaseOrderItems,
    purchaseOrderSpecialAttachments: state => state.purchaseOrderSpecialAttachments,
    purchaseOrderNotes: state => state.purchaseOrderNotes,
    purchaseOrderFiles: state => state.purchaseOrderFiles,
    purchaseOrderActivityLogs: state => state.purchaseOrderActivityLogs,
};

const actions = {

    getAllPurchaseOrderStatus({ state, commit, rootState, dispatch }, poStatus) {
        return new Promise((resolve, reject) => {
            axios
                .get('/get-all-purchase-order-status/' + poStatus)
                .then(res => {
                    commit('setAllPurchaseOrderStatus', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getPurchaseOrderNumber({ state, commit, rootState, dispatch }) {
        return new Promise((resolve, reject) => {
            axios
                .get('/get-purchase-order-number')
                .then(res => {
                    commit('setPurchaseOrderNumber', res.data.purchase_order_number);
                    commit('setVariablePurchaseOrderNumber', res.data.variable_purchase_order_number);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getAllVendors({ state, commit, rootState, dispatch }) {
        return new Promise((resolve, reject) => {
            axios
                .get('/get-all-vendors')
                .then(res => {
                    commit('setAllVendors', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    saveVendor({ state, commit, rootState, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/vendor', data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    savePurchaseOrder({ state, commit, rootState, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/purchase-order', data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    uploadSpecialAttachment({ state, commit, rootState, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/purchase-order/upload-special-attachment', data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getAllPurchaseOrders({ state, commit, rootState, dispatch }, type) {
        return new Promise((resolve, reject) => {
            axios
                .get('/get-all-purchase-orders/' + type)
                .then(res => {
                    commit('setAllPurchaseOrders', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getPurchaseOrder({ state, commit, rootState, dispatch }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get('/purchase-order/' + id)
                .then(res => {
                    commit('setPurchaseOrder', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getPurchaseOrderItems({ state, commit, rootState, dispatch }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get('/get-purchase-order-items/' + id)
                .then(res => {
                    commit('setPurchaseOrderItems', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    receiveItems({ state, commit, rootState, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/receive-items', data)
                .then(res => {
                    dispatch('getPurchaseOrder', res.data.id);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getPurchaseOrderSpecialAttachments({ state, commit, rootState, dispatch }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get('/get-purchase-order-special-attachments/' + id)
                .then(res => {
                    commit('setPurchaseOrderSpecialAttachments', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getPurchaseOrderNotes({ state, commit, rootState, dispatch }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get('/get-purchase-order-notes/' + id)
                .then(res => {
                    commit('setPurchaseOrderNotes', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    savePurchaseOrderNote({ state, commit, rootState, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/purchase-order-note', data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    updatePurchaseOrderNote({ state, commit, rootState, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/purchase-order-note/update', data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getPurchaseOrderFiles({ state, commit, rootState, dispatch }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get('/get-purchase-order-files/' + id)
                .then(res => {
                    commit('setPurchaseOrderFiles', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    sendPurchaseOrder({ state, commit, rootState, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/purchase-order-send-email', data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    cancelPurchaseOrder({ state, commit, rootState, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/cancel-purchase-order', data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    updatePurchaseOrder({ state, commit, rootState, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/purchase-order/update', data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getPurchaseOrderActivityLogs({ state, commit, rootState, dispatch }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get('/get-purchase-order-activity-logs/' + id)
                .then(res => {
                    commit('setPurchaseOrderActivityLogs', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    savePurchaseOrderActivityLog({ state, commit, rootState, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/purchase-order-activity-log', data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    generatePDF({ state, commit, rootState, dispatch }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get('/purchase-order-generate-pdf/' + id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    deletePurchaseOrderNote({ state, commit, rootState, dispatch }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get('/delete-purchase-order-note/' + id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },

}

const mutations = {
    setAllPurchaseOrderStatus(state, allPurchaseOrderStatus) {
        state.allPurchaseOrderStatus = allPurchaseOrderStatus;
    },
    setPurchaseOrderNumber(state, purchaseOrderNumber) {
        state.purchaseOrderNumber = purchaseOrderNumber;
    },
    setVariablePurchaseOrderNumber(state, variablePurchaseOrderNumber) {
        state.variablePurchaseOrderNumber = variablePurchaseOrderNumber;
    },
    setAllPurchaseOrders(state, allPurchaseOrders) {
        state.allPurchaseOrders = allPurchaseOrders;
    },
    setAllVendors(state, allVendors) {
        state.allVendors = allVendors;
    },
    setPurchaseOrder(state, purchaseOrder) {
        state.purchaseOrder = purchaseOrder;
    },
    setPurchaseOrderItems(state, purchaseOrderItems) {
        state.purchaseOrderItems = purchaseOrderItems;
    },
    setPurchaseOrderSpecialAttachments(state, purchaseOrderSpecialAttachments) {
        state.purchaseOrderSpecialAttachments = purchaseOrderSpecialAttachments;
    },
    setPurchaseOrderNotes(state, purchaseOrderNotes) {
        state.purchaseOrderNotes = purchaseOrderNotes;
    },
    setPurchaseOrderFiles(state, purchaseOrderFiles) {
        state.purchaseOrderFiles = purchaseOrderFiles;
    },
    setPurchaseOrderActivityLogs(state, purchaseOrderActivityLogs) {
        state.purchaseOrderActivityLogs = purchaseOrderActivityLogs;
    },

};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
};