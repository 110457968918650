<template>
    <modal name="archiveAssetModal" @closed="onClose()" :shiftY="0.2" :min-height="650" width="800px" :scrollable="true" height="auto" :reset="true" :adaptive="true">
        <div class="col-xl-12 order-xl-1 mobile-p-0 p-0">
            <!-- Header -->
            <div class="card-header border-bottom">
                <div class="row pt-2">
                    <div class="col-6">
                        <h5 v-if="assetType === 'vehicle'" class="modal-title">{{ $t('label.vehicles archive') }}</h5>
                        <h5 v-if="assetType === 'item'" class="modal-title">{{ $t('label.archive item') }}</h5>
                    </div>
                    <div class="col-6">
                        <button type="button" class="close" @click="onClose()" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Body -->
            <div class="card-body">
                <div class="">
                    <div class="form-group">
                        <div v-if="assetType === 'vehicle'">
                            {{ $t("label.update the status, out-of-service date, and odometer prior to archiving this vehicle archiving the vehicle will automatically unassign the vehicle operator") }}.
                        </div>
                        <div v-if="assetType === 'item'">
                            {{ $t('label.update the status and out-of-service date prior to archiving this asset item archiving the item will automatically unassign the responsible person') }}.
                        </div>
                    </div>

                    <!-- Asset Status -->
                    <div class="form-group pb-2">
                        <label class="form-control-label label-bold">{{ $t("label.status") }}</label>
                        <span class="status-indicator" :class="archiveData.status.toLowerCase().replace(/ /g, '')"></span>&nbsp;
                        <select class="form-control" v-model="archiveData.status">
                            <option value="null" disabled>-&nbsp;{{ $t('label.select') }}&nbsp;-</option>
                            <option v-for="(status, index) in statuses" :key="index" :value="status">{{ status }}</option>
                        </select>

                        <div class="text-danger text-sm" v-if="errors.status">* {{ this.errors.status[0] }}</div>
                    </div>

                    <!-- End Date -->
                    <div class="form-group pb-2">
                        <label class="form-control-label label-bold">{{ $t("label.end date") }}</label>&nbsp;
                        <span class="text-danger text-sm">* {{ $t('label.required') }}</span>
                        <date-picker v-model="archiveData.out_of_service_date" format="MM/DD/YYYY" class="full-width" :input-class="{ 'border-danger': errors.serviceDate, 'form-control': true }" :popup-style="{ top: '-275px', left: 0 }" :append-to-body="false">
                        </date-picker>

                        <span class="text-danger text-sm" v-if="errors.serviceDate">{{ $t('label.please enter a valid out of service date') }}.</span>
                    </div>

                    <!-- Final Odometer -->
                    <div v-if="assetType === 'vehicle'" class="form-group pb-2">
                        <label class="form-control-label label-bold">{{ $t("label.final odometer") }}</label>&nbsp;
                        <span class="text-danger text-sm">* {{ $t('label.required') }}</span>
                        <input class="form-control" v-model="archiveData.odometer_reading" :class="{ 'border-danger': errors.odometer }" :placeholder="$t('label.vehicles final odometer reading on out-of-service date')" />
                        <span>
                            {{ $t('label.vehicles last reading') }}:&nbsp;
                            <span v-if="this.asset.odometer_reading">
                                {{ this.asset.odometer_reading.odometer_reading.toLocaleString() }} mi&nbsp;
                                ({{ this.asset.odometer_reading.odometer_reading_date }})&nbsp;
                            </span>
                            <span v-else>
                                0 mi&nbsp;
                            </span>
                        </span>

                        <span class="text-danger text-sm" v-if="errors.odometer">{{ $t('label.please enter a valid odometer entry') }}.</span>
                    </div>
                </div>
            </div>

            <!-- Footer -->
            <div class="card-footer border-top">
                <div v-if="!confirmActive">
                    <span>
                        <button type="submit" class="btn btn-primary mr-auto" @click="validate()">{{ $t('label.save changes') }}</button>
                        <button type="button" class="btn btn-secondary float-right" @click="onClose()" data-dismiss="modal">{{ $t('label.close') }}</button>
                    </span>
                </div>

                <div v-if="confirmActive" class="d-flex">
                    <!-- <span class="mr-auto"> -->
                    <div>
                        <h3 class="text-danger pt-1">
                            <div v-if="assetType === 'vehicle'">
                                {{ $t('label.ARE YOU SURE YOU WANT TO ARCHIVE THIS VEHICLE') }}?
                            </div>
                            <div v-if="assetType === 'item'">
                                {{ $t('label.ARE YOU SURE YOU WANT TO ARCHIVE THIS ASSET ITEM') }}?
                            </div>
                        </h3>
                    </div>

                    <!-- Confirm or Cancel -->
                    <div class="ml-auto">
                        <button type="button" class="btn btn-success" @click="archiveAsset()" data-dismiss="modal">
                            <i class="fa fa-check"></i>&nbsp;Confirm
                        </button>
                        <button type="button" class="btn btn-secondary" @click="confirmActive = false">
                            <i class="fa fa-times"></i>&nbsp;Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<style>
.label-bold {
    font-weight: bold;
}

.archive-footer {
    padding: 25px;
}
</style>

<script>

import moment from '@/utils/date-time';
import bus from '@/utils/event-bus';

export default {
    props: ["asset", "assetType"],
    data() {
        return {
            statuses: ["Out of Service", "Sold"],
            assetApi: "/",
            confirmActive: false,
            lastOdom: 0,
            errors: {
                status: false,
                serviceDate: false,
                odometer: false
            },
            archiveData: {
                status: "Sold",
                out_of_service_date: '',
                odometer_reading: ''
            }
        };
    },
    mounted() {
        if (this.assetType === "vehicle") {
            this.assetApi += "vehicles-archive";
        } else if (this.assetType === "item") {
            this.assetApi += "asset-items-archive";
        }

        this.$modal.show('archiveAssetModal');
    },
    methods: {
        formatDate(date) {
            return date ? moment(date).format('YYYY-MM-DD') : "";
        },

        validate() {
            // Verify status, out of service date, and odometer
            let outOfServDate = this.formatDate(this.archiveData.out_of_service_date);

            // Status
            this.errors.status = (!this.archiveData.status.length) ? true : false;
            // Out of Service Date
            this.errors.serviceDate = (!outOfServDate.length) ? true : false;
            // Odometer
            if (this.assetType === 'vehicle') {
                let lastOdom = this.asset.odometer_reading.odometer_reading;

                if ((this.archiveData.odometer_reading.length <= 0) ||
                    (this.archiveData.odometer_reading <= lastOdom)) {
                    this.errors.odometer = true;
                } else {
                    this.errors.odometer = false;
                }
            }

            if (this.errors.status || this.errors.serviceDate || this.errors.odometer)
                return;
            else
                this.confirmActive = true;
        },

        archiveAsset() {
            let fields = {
                asset_id: this.asset.id,
                status: this.archiveData.status,
                out_of_service_date: moment(this.archiveData.out_of_service_date).format('YYYY-MM-DD')
            };

            if (this.assetType === "vehicle")
                fields.odometer = this.archiveData.odometer_reading;

            // Update vehicle fields in database
            axios.post(this.assetApi, fields).then(response => {
                this.$modal.hide('archiveAssetModal');

                bus.$emit('banner-success', {
                    message: this.$t('label.asset archived successfully')
                });

                this.$emit('onArchive')

            }).catch(err => console.log(err));
        },

        onClose() {
            this.$emit('onClose');
        }
    }
}
</script>
