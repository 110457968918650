<template>
    <div>
        <div class="">
            <div class="bg-primary pb-6 mobile-p-0">
                <div class="container-fluid">
                    <div class="header-body">
                        <div class="row align-items-center py-4">
                            <div class="col-lg-12">
                                <breadcrumbs :title="$t('label.reports')">
                                    <!-- Breakcrumb slots -->
                                    <li class="breadcrumb-item">
                                        <router-link class="text-primary pointer" :to="{ name: 'reports' }">{{ $t('label.reports') }}</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        {{ $t('label.time off report') }}
                                    </li>
                                </breadcrumbs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Tabbed Navigation -->
            <div class="container-fluid">
                <ul class="nav nav-tabs mt--6" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link text-primary" href="#pending" data-toggle="tab" role="button" aria-expanded="true" @click="switchTab('showTab1')" :class="{'bg-white':showTab1, 'bg-gray-300':!showTab1}">Pending Requests</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link text-primary" href="#today" data-toggle="tab" role="button" aria-expanded="false" @click="switchTab('showTab2')" :class="{'bg-white':showTab2, 'bg-gray-300':!showTab2}">Employees Off Today</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link text-primary" href="#report" data-toggle="tab" role="button" aria-expanded="false" @click="switchTab('showTab3')" :class="{'bg-white':showTab3, 'bg-gray-300':!showTab3}">Request Report</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <!-- All Pending Requests -->
                    <div class="tab-pane active" id="pending" role="tabpanel" aria-labelledby="profile-tab">
                        <div class="rounded-bottom bg-white p-4">
                            <h3 class="mb-0">{{ $t('label.pending requests') }}</h3>
                            <div class="dropdown-divider"></div>
                            <time-off-pending-requests v-if="showTab1"></time-off-pending-requests>
                        </div>
                    </div>

                    <!--Employees Off Today-->
                    <div class="tab-pane" id="today" role="tabpanel" aria-labelledby="home-tab">
                        <div class="rounded-bottom bg-white p-4">
                            <h3 class="mb-0">{{ $t('label.employees off today') }}</h3>
                            <div class="dropdown-divider"></div>
                            <div class="table-responsive">
                                <time-off-todays-requests v-if="showTab2"></time-off-todays-requests>
                            </div>
                        </div>
                    </div>

                    <!-- Main Report -->
                    <div class="tab-pane" id="report" role="tabpanel" aria-labelledby="report-tab">
                        <div class="rounded-bottom bg-white p-4">
                            <h3 class="mb-0"><i class="far fa-clock"></i> {{ $t('label.time off report') }}</h3>
                            <div class="dropdown-divider"></div>
                            <time-off-general-report v-if="showTab3"></time-off-general-report>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
import TimeOffGeneralReport from '@/components/TimeOffRequests/TimeOffGeneralReport';
import TimeOffPendingRequests from '@/components/TimeOffRequests/TimeOffPendingRequests';
import TimeOffTodaysRequests from '@/components/TimeOffRequests/TimeOffTodaysRequests';
import { mapGetters } from 'vuex';

export default {
    components: {
        TimeOffPendingRequests,
        TimeOffTodaysRequests,
        TimeOffGeneralReport
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            showTab1: true,
            showTab2: false,
            showTab3: false,
        }
    },
    computed: {
        ...mapGetters(['users']),
    },
    async created() {
        // make sure company users store is populated for usage
        if (!this.users.allemployees.length) {
            // console.log('No Users in the store');
            this.$store.dispatch('getAllEmployees');
        }
    },
    methods: {
        switchTab(tabNum) {
            this.showTab1 = false;
            this.showTab2 = false;
            this.showTab3 = false;
            this[tabNum] = true;
        },
    }
}
</script>
