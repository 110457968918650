<template>
    <div class="d-flex align-items-center">
        <!-- Image and name as a link for user -->
        <img class="rounded-circle" :src="user.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">&nbsp;
        <a class="cursor-pointer" id="assignedUserInfo" href="#" @click="getUserInfo(user.id, message)">
            {{ this.user.first_name }} {{ this.user.last_name }}
        </a>
    </div>
</template>

<script>
export default {
    props: ['user', 'message'],
    components: {},
    data() {
        return {}
    },
    mounted() {},
    methods: {}
}
</script>
