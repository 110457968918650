// css
import '@/assets/argon/js/argon';
import '@/assets/argon/js/demo';
import '@/assets/argon/scss/argon.scss';
import '@/assets/scss/app.scss';

// jquery
import 'jquery';
// bootstrap
import 'bootstrap';

// js
import '@/assets/argon/js/argon.js';
import '@/assets/argon/js/items.js';

import Vue from 'vue';
import VueLazyload from "vue-lazyload";

export const registerArgon = () => {
    Vue.use(VueLazyload);
}