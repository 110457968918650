<template>
    <div class="row align-items-center py-4">
        <div class="col-1">
            <div class="text-white text-xl pointer" onclick="window.history.back()">
                <i class="ri-arrow-left-circle-line"></i>
            </div>
        </div>
        <div class="col">
            <span class="h2 text-white">{{ this.title }}</span>
        </div>
    </div>
    <!-- <div class="row align-items-center py-4">
        <div class="col">
            <h6 class="h2 text-white d-inline-block mb-0">{{ this.title }}</h6>

            <nav class="d-none d-md-inline-block ml-md-4 breadcrumb-nav">
                <ol class="breadcrumb breadcrumb-links d-flex"> -->
                    <!-- Companies - Super Admin Only -->
                    <!-- <slot name="admin"></slot>
                    <li v-if="!hasAdminSlot && user.role_id === 1" class="breadcrumb-item align-items-center">
                        <a href="/companies/index">
                            <i class="ri-dashboard-line"></i> {{ $t('label.companies') }}&nbsp;
                        </a>
                    </li> -->
                    <!-- Dashboard -->
                    <!-- <li v-if="user.role_id === 2" class="breadcrumb-item">
                        <router-link to="/dashboard" class="d-flex align-items-center"><i class="ri-dashboard-line"></i>{{ $t('label.dashboard') }}&nbsp;</router-link>
                    </li> -->

                    <!-- Additional Breadcrumb Items -->
                    <!-- <slot></slot> -->
                <!-- </ol> -->
            <!-- </nav> -->
        <!-- </div> -->
    <!-- </div> -->
</template>

<style>
.breadcrumb-nav {
    background: #fff;
    padding: .625rem 1.25rem;
    border-radius: .25rem;
}
</style>

<script>
export default {
    props: ["title"],
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user'))
        }
    },
    mounted() {
    },
    methods: {
        // hasAdminSlot() {
        //     return !!this.$slots.admin
        // }
    }
}
</script>
