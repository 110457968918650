<template>
    <!-- <fade-transition origin="center" mode="out-in" :duration="250">
        <div v-if="isLoading" id="fullPageLoaderBg">
            <ul class="loader">
                <li class="center"></li>
                <li class="item item-1"></li>
                <li class="item item-2"></li>
                <li class="item item-3"></li>
                <li class="item item-4"></li>
                <li class="item item-5"></li>
                <li class="item item-6"></li>
                <li class="item item-7"></li>
                <li class="item item-8"></li>
            </ul>
        </div>
    </fade-transition> -->
    <fade-transition origin="center" mode="out-in" :duration="250">
        <!-- <div class="vld-parent" v-show="isLoading">
            <loading :active="true" loader="dots" background-color=#000 blur=0px :is-full-page="true"></loading>
        </div> -->
    </fade-transition>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapState } from 'vuex';

export default {
    name: 'FullPageLoader',
    components: {
        Loading
    },
    computed: {
        ...mapState({
            isLoading: state => state.Auth.isLoading
        })
    }
}
</script>

<style lang="scss">
.vld-parent {
    z-index: 9;
}

#fullPageLoaderBg {
    background: #f1f1f1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;

    li {
        display: block;
    }

    .loader {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .center {
        width: 40px;
        height: 40px;
        background: #005486;
        border-radius: 50%;
        animation: center 3.2s ease-in-out infinite;
        position: absolute;
        left: 0;
        transform: translateX(-50%);
    }

    .item {
        position: absolute;
        width: 10px;
        height: 10px;
        top: 15px;
        left: 0;
        right: 0;
        margin: auto;
        background: #005486;
        border-radius: 50%;
    }

    .item-1 {
        animation: anim-1 3.2s ease-in-out infinite 0.2s;
        animation-fill-mode: backwards;
    }

    @keyframes anim-1 {

        0%,
        60%,
        100% {
            transform: rotate(45deg) translateX(40px) scale(1);
        }

        10%,
        50% {
            transform: rotate(45deg) translateX(0) scale(1.5);
        }
    }

    .item-2 {
        animation: anim-2 3.2s ease-in-out infinite 0.4s;
        animation-fill-mode: backwards;
    }

    @keyframes anim-2 {

        0%,
        60%,
        100% {
            transform: rotate(90deg) translateX(40px) scale(1);
        }

        10%,
        50% {
            transform: rotate(90deg) translateX(0) scale(1.5);
        }
    }

    .item-3 {
        animation: anim-3 3.2s ease-in-out infinite 0.6s;
        animation-fill-mode: backwards;
    }

    @keyframes anim-3 {

        0%,
        60%,
        100% {
            transform: rotate(135deg) translateX(40px) scale(1);
        }

        10%,
        50% {
            transform: rotate(135deg) translateX(0) scale(1.5);
        }
    }

    .item-4 {
        animation: anim-4 3.2s ease-in-out infinite 0.8s;
        animation-fill-mode: backwards;
    }

    @keyframes anim-4 {

        0%,
        60%,
        100% {
            transform: rotate(180deg) translateX(40px) scale(1);
        }

        10%,
        50% {
            transform: rotate(180deg) translateX(0) scale(1.5);
        }
    }

    .item-5 {
        animation: anim-5 3.2s ease-in-out infinite 1s;
        animation-fill-mode: backwards;
    }

    @keyframes anim-5 {

        0%,
        60%,
        100% {
            transform: rotate(225deg) translateX(40px) scale(1);
        }

        10%,
        50% {
            transform: rotate(225deg) translateX(0) scale(1.5);
        }
    }

    .item-6 {
        animation: anim-6 3.2s ease-in-out infinite 1.2s;
        animation-fill-mode: backwards;
    }

    @keyframes anim-6 {

        0%,
        60%,
        100% {
            transform: rotate(270deg) translateX(40px) scale(1);
        }

        10%,
        50% {
            transform: rotate(270deg) translateX(0) scale(1.5);
        }
    }

    .item-7 {
        animation: anim-7 3.2s ease-in-out infinite 1.4s;
        animation-fill-mode: backwards;
    }

    @keyframes anim-7 {

        0%,
        60%,
        100% {
            transform: rotate(315deg) translateX(40px) scale(1);
        }

        10%,
        50% {
            transform: rotate(315deg) translateX(0) scale(1.5);
        }
    }

    .item-8 {
        animation: anim-8 3.2s ease-in-out infinite 1.6s;
        animation-fill-mode: backwards;
    }

    @keyframes anim-8 {

        0%,
        60%,
        100% {
            transform: rotate(360deg) translateX(40px) scale(1);
        }

        10%,
        50% {
            transform: rotate(360deg) translateX(0) scale(1.5);
        }
    }

    @keyframes center {

        0%,
        10%,
        90%,
        100% {
            transform: scale(0.7);
        }

        45%,
        55% {
            transform: scale(1);
        }
    }
}
</style>