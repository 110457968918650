<template>
	<div>
		<div class="modal-content">

			<!-- Add/Edit Customer -->
			<div class="modal-header border-bottom">
				<h5 v-if="modalType == 'edit'" class="modal-title">{{ $t('label.edit vendor') }}</h5>
				<h5 v-else class="modal-title">{{ $t('label.add vendor') }}</h5>
				<button type="button" class="close" @click="$emit('cancelFnc')">
					<i class="ri-close-line"></i>
				</button>
			</div>
			<!-- Data form -->
			<div class="modal-body">
				<div class="row">
					<div v-if="mtype == 'edit'" class="col-sm-12 col-md-4">
						<div class="form-group">
							<label class="form-control-label text-muted" for="input-name">{{ $t('label.vendor status') }}</label>
							<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

							<select class="form-control" v-model="$v.form.active.$model" :class="{ 'is-invalid': $v.form.active.$error }" required autofocus>
								<option value="1">{{ $t('label.active') }}</option>
								<option value="0">{{ $t('label.inactive') }}</option>
							</select>
							<div v-if="$v.form.active.$error && !$v.form.active.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
						</div>
					</div>
					<div v-else class="col-sm-12 col-md-4">
						<div class="form-group">
							<label class="form-control-label text-muted" for="input-name">{{ $t('label.vendor status') }}</label>
							<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

							<select class="form-control" v-model="$v.form.active.$model" :class="{ 'is-invalid': $v.form.active.$error }" required autofocus disabled>
								<option value="1" selected>{{ $t('label.active') }}</option>
							</select>
							<div v-if="$v.form.active.$error && !$v.form.active.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
						</div>
					</div>
					<!-- vendor name -->
					<div class="col-sm-12 col-md-6">
						<div class="form-group">
							<label class="form-control-label text-muted" for="input-name">{{ $t('label.vendor name') }}</label>
							<input type="text" name="first_name" class="form-control" v-model="$v.form.vendor_name.$model" :placeholder="$t('label.vendor name')" autocomplete="input-field" data-lpignore="true">
							<div v-if="$v.form.vendor_name.$error && !$v.form.vendor_name.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
						</div>
					</div>
					<!-- Notes -->
					<div class="col-sm-12 col-md-6">
						<div class="form-group">
							<label class="form-control-label text-muted" for="input-name">{{ $t('label.notes') }}</label>
							<textarea type="text" name="notes" class="form-control" v-model="$v.form.notes.$model" :placeholder="$t('label.notes')" rows="5" cols="40" autocomplete="input-field" data-lpignore="true"></textarea>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer border-top">
				<!-- edit -->
				<button type="button" class="btn btn-primary mr-auto" @click="saveVendor()">{{ $t('label.save changes') }}</button>
				<!-- Close -->
				<button type="button" class="btn btn-link ml-auto" @click="$emit('cancelFnc')">{{ $t('label.close') }}</button>
			</div>
		</div>
	</div>
</template> 

<script>
import bus from '@/utils/event-bus';
import { minLength, required } from 'vuelidate/lib/validators';

export default {
	props: ['customerdata', 'mtype', 'vendordata', 'comingFrom'],
	components: {
	},
	emits: ['cancelFnc', 'returnFnc'],
	data() {
		return {
			modalType: '',
			form: {
				vendor_name: '',
				notes: '',
				active: 1,
				id: null,
			},
			isLoading: false,
		}
	},
	validations: {
		form: {
			vendor_name: {
				required,
				minLength: minLength(3)
			},
			notes: {},
			active: { required },
		}
	},
	computed: {
	},
	mounted() {
		this.modalType = this.mtype;
		this.loadSupportingData();
	},
	methods: {
		loadSupportingData() {
			this.isLoading = true;
			if (this.modalType == 'edit') {
				this.editModal(this.vendordata);
				this.isLoading = false;
			}
		},
		editModal(info) {
			this.form.vendor_name = info.vendor_name;
			this.form.active = info.active;
			this.form.notes = info.notes;
			this.form.id = info.id;
		},
		saveVendor() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.loader = this.$loading.show();

				let formData = new FormData();
				formData.append('vendor_name', this.form.vendor_name);
				formData.append('active', this.form.active);
				formData.append('notes', this.form.notes);

				if (this.modalType != 'edit') {
					axios.post('/vendor', formData).then(response => {

						var requestData = {
							data: response.data,
							newContactAdded: 'newContactAdded',
						}
						bus.$emit('banner-success', { message: 'Created Successfully!' });

						if (this.comingFrom == 'po') {
							setTimeout(() => {
								this.$emit('cancelFnc');
								this.$emit('returnFnc', requestData);
								this.clearForm();
								this.loader.hide();
							}, 2000);
						} else {
							setTimeout(() => {
								this.openDetailsPage(response.data.result);
								this.$emit('cancelFnc');
								this.clearForm();
								this.loader.hide();
							}, 2000);
						}
					}).catch(err => console.log('Error adding vendor', err));
				}
				// Update vendor
				else {
					formData.append('id', this.form.id);
					axios.post('/update-vendor', formData).then(response => {
						this.loader.hide();
						var requestData = {
							data: response.data.results,
							editVendor: 'fromvendordetail',
						}
						this.$emit('returnFnc', response.data.results);
						this.clearForm();
						bus.$emit('banner-success', { message: 'Updated Successfully!' });

					}).catch(err => console.log('Error updating Customer/Lead:', err));
				}
			}
		},
		openDetailsPage(vendorInfo) {
			this.$router.push({
				name: 'vendordetails', params: { vendorInfo: vendorInfo, id: vendorInfo.id }
				// path: `admin/vendor/details/${vendorId}`
			});
		},
		clearForm() {
			this.form.vendor_name = '';
			this.form.notes = '';
			this.form.active = '';
		},
	}
}
</script>

<style></style>