<template>
    <div class="col-xl-12 order-xl-1 mobile-p-0 p-0">
        <div class="card mb-6 mb-sm-4" :class="{ 'modal-content': modal }">
            <!-- Entry Header -->
            <div class="card-header border-bottom">
                <div class="row pt-2">
                    <div class="col-6">
                        <h5 v-if="selectedEntry.id" class="modal-title">{{ $t('label.edit maintenance entry') }}</h5>
                        <h5 v-else class="modal-title">{{ $t('label.add maintenance entry') }}</h5>
                    </div>
                    <div class="col-6">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Entry Body -->
            <div class="card-body" :class="{ 'bg-gradient-danger': (viewTemplate === 'delete') }">
                <div v-show="viewTemplate === 'form'" class="form-group row">
                    <div class="col-12 col-sm-6">
                        <!-- Completion Date -->
                        <div class="p-2">
                            <label class="font-weight-bold">{{ $t('label.completion date') }}</label>
                            <date-picker v-model="selectedEntry.completion_date" format="MM/DD/YYYY" :placeholder="$t('label.date the maintenance was completed')" class="full-width" :input-class="{ 'form-control': true }" :popup-style="{ left: 0 }" :append-to-body="false"></date-picker>

                            <div v-if="entryErrors.completionDate" class="text-danger">{{ entryErrors.completionDate }}</div>
                        </div>
                        <!-- Odometer -->
                        <template v-if="assetType === 'vehicle'">
                            <div class="p-2">
                                <label class="font-weight-bold">{{ $t('label.vehicles odometer') }}</label>
                                <input class="form-control" type="number" min="0" max="10000000" oninput="validity.valid||(value='');" v-model="selectedEntry.odometer.odometer_reading" required>
                                <div v-if="entryErrors.odometer" class="text-danger">{{ entryErrors.odometer }}</div>
                            </div>
                        </template>
                        <!-- Details -->
                        <div class="p-2">
                            <label class="font-weight-bold">{{ $t('label.details') }}</label>
                            <textarea id="details" class="form-control" rows="4" maxlength="1000" v-model="selectedEntry.details" required></textarea>
                            <div v-if="entryErrors.details" class="text-danger">{{ entryErrors.details }}</div>
                        </div>
                        <!-- Total -->
                        <div class="p-2">
                            <label class="font-weight-bold">{{ $t('label.total cost') }}</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-group-invoice"><i class="ri-money-dollar-circle-line"></i></span>
                                </div>
                                <money class="form-control" v-model="selectedEntry.total_cost" v-bind="money" @focus.native="clearTotal" required></money>
                            </div>

                            <div v-if="entryErrors.totalCost" class="text-danger">{{ entryErrors.totalCost }}</div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="form-group dropzone-file p-2">
                            <div class="font-weight-bold mb-2">{{ $t('label.upload receipts') }}</div>
                            <div>
                                <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions" v-on:vdropzone-success="getUploadedFile" v-on:vdropzone-processing="uploadingFile()"></vue-dropzone>
                            </div>
                            <div class="pt-4 px-2">
                                <div v-for="(file, index) in selectedEntry.receipt_files" :key="index">
                                    <div class="d-flex pb-2">
                                        <div class="align-items-center flex-grow-1">
                                            <!-- Edit Entry Files -->
                                            <div v-if="file.id">
                                                <div class="">
                                                    <img class="image-container image-container-thumbnail float-left mr-2" :src="file.file_type == 'application/pdf' ? '/images/icons/doc-pdf.png' : '/' + file.download" />
                                                </div>
                                                <div class="">
                                                    <span class="cursor-pointer text-bold" @click="selectFile(file, 'view')">
                                                        <span v-if="file.display_name">{{ file.display_name }}</span>
                                                        <span v-else>{{ file.name }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <!-- Add Entry Files -->
                                            <div v-else class="form-group">
                                                <div class="">
                                                    <img class="image-container image-container-thumbnail float-left mr-2" :src="(file.fileType === 'image/jpeg') ? file.image : '/images/icons/doc-pdf.png'" />
                                                </div>
                                                <div class="">
                                                    <span class="cursor-pointer text-bold" @click="selectFile(file, 'view')">{{ file.fileName }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pl-2 ml-auto">
                                            <button class="btn btn-sm btn-danger" @click="selectFile(file, 'delete')">
                                                <i class="ri-delete-bin-line"></i> {{ $t('label.remove') | uppercase }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Receipt File View -->
                <div v-show="viewTemplate === 'view'" id="viewReceiptModal">
                    <!-- Return to previous view -->
                    <button class="btn btn-default" @click="clearSelectedFile()">
                        <i class="fas fa-arrow-left pr-3 pointer"></i>&nbsp;{{ $t('label.go back') }}
                    </button>

                    <div class="col-xl-12 order-xl-1 mobile-p-0 p-0">
                        <div class="p-3 row">
                            <div class="col-lg-12 col-12 p-4 text-center" style="background: #F6F9FC;">
                                <div v-if="selectedFile.file_type == 'application/pdf'">
                                    <div class="w-100">
                                        <img class="w-25" src="/images/icons/other-file.png" alt="Card image cap">
                                    </div>
                                    <div class="pt-3 pb-4 w-100">{{ $t('label.Preview unavailable for this file type') }}.</div>
                                    <a class="btn btn-primary" :href="mtncFolderPath + selectedFile.name" download>
                                        <i class="ri-download-line pr-2"></i> {{ $t('label.download') }}
                                    </a>
                                </div>
                                <div v-else class="p-2">
                                    <img class="mw-100 img-view" :src="selectedFile.id ? '/' + encodeURI(selectedFile.download) : selectedFile.image" alt="Card image cap">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Receipt File Delete -->
                <div v-show="viewTemplate === 'delete'" class="bg-gradient-danger">
                    <div class="py-3 text-center">
                        <i class="ri-error-warning-line ni-3x"></i>
                        <h4 class="heading mt-4 text-">{{ $t('label.ARE YOU SURE YOU WANT TO DELETE THIS FILE') }}?</h4>
                        <p>{{ $t('label.This action cannot be undone') }}!</p>
                    </div>
                </div>
            </div>

            <!-- Entry Footer -->
            <div class="card-footer border-top">
                <!-- Form Footer -->
                <div v-show="viewTemplate === 'form'">
                    <button type="button" class="btn btn-primary mr-auto" :disabled="disableSubmit" @click="validateEntry()">
                        {{ $t("label.save entry") }}
                    </button>
                    <button type="button" class="btn btn-link ml-auto close2addnote" style="float: right" data-dismiss="modal">
                        {{ $t('label.close') }}
                    </button>
                </div>
                <!-- Show Footer -->
                <div v-show="viewTemplate === 'view'">
                    <a class="btn btn-primary mr-auto" :href="mtncFolderPath + selectedFile.name" download>
                        <i class="ri-download-line pr-2"></i> {{ $t('label.download') }}
                    </a>
                </div>
                <!-- Delete Footer -->
                <div v-show="viewTemplate === 'delete'">
                    <button type="button" class="btn btn-white" id="deleteFileBtn" @click="deleteFile()">
                        {{ $t('label.Yes, Delete') }}
                    </button>
                    <button class="btn btn-default ml-auto" style="float: right" @click="clearSelectedFile()">
                        <i class="fas fa-arrow-left pr-3 pointer"></i>{{ $t('label.go back') }}
                    </button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import moment from '@/utils/date-time';
import bus from '@/utils/event-bus';
import * as $ from 'jquery';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    props: ['assetType', 'assetApi', 'assetId', 'modal', 'entry', 'mtncFolderPath'],
    components: {
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            viewTemplate: "form",
            disableSubmit: false,
            selectedFile: {
                id: null
            },
            selectedEntry: {
                completion_date: "",
                details: "",
                total_cost: "",
                receipt_files: [],
                odometer: {
                    odometer_reading: ""
                },
            },
            entryErrors: {
                completionDate: "",
                odometer: "",
                details: "",
                totalCost: ""
            },
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '',
                precision: 2,
                masked: false
            },
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                acceptedFiles: 'image/*, application/pdf',
                maxFilesize: 10,
                addRemoveLinks: true,
                dictDefaultMessage: `<div><span>Drag and drop files here</span><br>
                            <span>OR</span><br>
                            <button type="button" class="btn btn-lg btn-default">Browse Files</button></div>`,
            }
        }
    },
    mounted() {
        // Edit or Add
        if (this.entry)
            this.initEditEntry();
    },
    methods: {
        initEditEntry() {
            this.$set(this, 'selectedEntry', this.entry);
            this.selectedEntry.completion_date = moment(this.selectedEntry.completion_date).toDate();
        },

        // File Uploading
        getUploadedFile(file) {
            setTimeout(function () {
                this.selectedEntry.receipt_files.push({
                    image: file.dataURL,
                    fileName: file.name,
                    fileType: file.type,
                    file: file
                });

                // Clear the dropzone for more files
                this.$refs.myVueDropzone.removeAllFiles(true);
                this.disableSubmit = false;
            }.bind(this), 1000);
        },
        uploadingFile() {
            this.disableSubmit = true;
        },
        selectFile(file, viewTemplate) {
            this.selectedFile = file;
            this.viewTemplate = viewTemplate;
        },
        clearSelectedFile() {
            this.viewTemplate = "form";
            this.selectedFile = {
                id: null
            };
        },

        // Validating and Saving Entries
        validateEntry() {
            this.disableSubmit = true;
            let details = this.selectedEntry.details;

            // Completion Date
            this.entryErrors.completionDate = this.selectedEntry.completion_date ? "" : "This field is required.";
            // Details
            this.entryErrors.details = (details.length >= 10) ? "" : "This field requires a minimum of 10 characters.";
            // Odometer
            if (this.assetType === "vehicle") {
                let odo_reading = this.selectedEntry.odometer.odometer_reading;
                this.entryErrors.odometer = (odo_reading != "" && odo_reading != null) ? "" : "This field is required.";
            }

            // Show Errors or Save/Update
            if (this.entryErrors.completionDate || this.entryErrors.odometer || this.entryErrors.details || this.entryErrors.totalCost) {
                this.disableSubmit = false;
                return;
            } else {
                this.selectedEntry.id ? this.updateExistingEntry() : this.saveNewEntry();
            }

        },
        saveNewEntry() {
            let toCreate = {
                asset_id: this.assetId,
                completion_date: moment(this.selectedEntry.completion_date).format("YYYY-MM-DD"),
                details: this.selectedEntry.details,
                total_cost: this.selectedEntry.total_cost,
                receipt_files: this.selectedEntry.receipt_files
            };

            if (this.assetType === "vehicle")
                toCreate.odometer = this.selectedEntry.odometer;

            axios.post(this.assetApi + "store", toCreate).then(response => {
                let uploadPromises = [];

                // Save each file individually with FormData()
                toCreate.receipt_files.forEach(file => {
                    uploadPromises.push(this.saveFile(response.data.id, file));
                });

                // Run emits after all files have been saved
                Promise.all(uploadPromises).then(response => {
                    this.disableSubmit = false;
                    this.$emit('on-save');

                    if (this.assetType === "vehicle")
                        bus.$emit('update-odom-list');

                    bus.$emit('banner-success', {
                        message: this.$t('label.maintenance entry added successfully') + '!'
                    });
                    $("#addOrEditEntryModal").modal("hide");
                });
            }).catch(err => console.log(err));
        },
        updateExistingEntry() {
            let toUpdate = {
                id: this.selectedEntry.id,
                completion_date: moment(this.selectedEntry.completion_date).format("YYYY-MM-DD"),
                details: this.selectedEntry.details,
                total_cost: this.selectedEntry.total_cost,
                receipt_files: this.selectedEntry.receipt_files
            };

            if (this.assetType === "vehicle")
                toUpdate.odometer = this.selectedEntry.odometer;

            axios.post(this.assetApi + "update", toUpdate).then(response => {
                let uploadPromises = [];

                // Save each file individually with FormData()
                toUpdate.receipt_files.forEach(file => {
                    if (!file.id)
                        uploadPromises.push(this.saveFile(toUpdate.id, file))
                });

                // Run emits after all files have been saved
                Promise.all(uploadPromises).then(response => {
                    this.disableSubmit = false;
                    this.$emit('on-save');

                    if (this.assetType === "vehicle")
                        bus.$emit('update-odom-list');

                    bus.$emit('banner-success', {
                        message: this.$t('label.maintenance entry updated successfully') + '!'
                    });

                    $("#addOrEditEntryModal").modal("hide");
                });
            }).catch(err => console.log(err));
        },
        saveFile(entryId, file) {
            const data = new FormData();
            data.append('download', file.file);
            data.append('data', JSON.stringify({
                id: entryId,
                file_type: file.fileType
            }));

            return axios.post(this.assetApi + "upload-file", data);
        },

        deleteFile() {
            let toDelete = {
                entryId: this.selectedEntry.id,
                fileId: this.selectedFile.id
            };

            // Remove deleted file from selected_entry receipt_file list
            this.selectedEntry.receipt_files.forEach((file, idx) => {
                if (file.id) { // Saved to database
                    if (this.selectedFile.id === file.id)
                        return this.selectedEntry.receipt_files.splice(idx, 1);
                } else { // Not yet saved
                    if (this.selectedFile.file.upload.uuid === file.file.upload.uuid)
                        return this.selectedEntry.receipt_files.splice(idx, 1);
                }
            });

            if (this.selectedFile.id) {
                // Send to parent to delete file.
                this.$emit('on-file-delete', toDelete);
            } else {
                setTimeout(() => {
                    bus.$emit('banner-success', {
                        message: this.$t('label.maintenance entry file deleted successfully') + '!'
                    });
                }, 800);
            }

            this.clearSelectedFile();
        },

        clearTotal() {
            this.selectedEntry.total_cost = "";
        }
    }
}
</script>
