<template>
    <div v-if="vendor">
        <div class="header bg-primary pb-6 mobile-p-0">
            <div class="container-fluid">
                <div class="header-body">
                    <div class="row align-items-center py-4">
                        <div class="col-lg-6 col-7">
                            <h6 class="h2 text-white d-inline-block mb-0">{{ vendor.vendor_name }}</h6>
                            <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4" style="background: #fff; padding: .625rem 1.25rem; border-radius: .25rem;">
                                <ol class="breadcrumb breadcrumb-links">
                                    <li class="breadcrumb-item">
                                        <a href="/dashboard" class="d-flex align-items-center">
                                            <i class="ri-dashboard-line"></i> {{ $t('label.dashboard') }}
                                        </a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a @click="$router.go(-1)" class="text-primary pointer">{{ $t('label.vendors') }}</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        {{ vendor.vendor_name }}
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div class="col-lg-6 col-5 text-right">
                            <button type="button" class="btn btn-neutral" 
                            @click="editVendorModal(vendorInfo, 'edit')">
                                {{ $t('label.edit vendor') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid mt--6 mobile-mt-140p">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <div v-if="vendor" class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-lg-12">
                                    <div class="row mb-3">
                                        <div class="col-12 col-lg-auto col-xs-auto">
                                            <div class="m-0 d-flex align-items-center">
                                                <span class="m-0  font-weight-bold font-size-26px">{{ vendor.vendor_name }}</span>
                                                <span v-if="vendor.active == 1" class="badge badge-sm bg-success text-white ml-3">Active</span>
                                                <span v-else class="badge badge-sm bg-light text-white ml-3">Inactive</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-lg">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.notes') }}</label>
                                                <p class="border-bottom">{{ vendor.notes }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-lg">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.last updated') }}</label>
                                                <h3 class="border-bottom">{{ vendor.updated_at | formatDateOnly }}</h3>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.created date') }}</label>
                                                <h3 class="border-bottom">{{ vendor.created_at | formatDateOnly }}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Vendor Contacts -->
                <modal-vendor-contacts :vendor="vendor" :from="1"></modal-vendor-contacts>
            </div>
        </div>

        <!-- Vendor Edit -->
        <modal class="py-4" 
        name="vendorEdit" 
        height="auto" 
        width="98%" 
        :max-width="1024" 
        :reset="true" 
        :scrollable="true" 
        :adaptive="true">
            <modal-add-edit-vendor 
            :mtype="modalType" 
            :vendordata='editVendor' 
            @cancelFnc="closevendorModal" 
            @returnFnc="returnUpdatedVendor" />
        </modal>
    </div>
</template>
<script>
import ModalAddEditVendor from '@/components/Admin/Vendors/ModalAddEditVendor';
import ModalVendorContacts from '@/components/Admin/Vendors/ModalVendorContacts';

export default {
    name: 'VendorDetailComponent',
    props: ['vendorInfo', 'id'],
    emits: ['returnFnc'],
    components: {
        ModalVendorContacts,
        ModalAddEditVendor,
    },
    data() {
        return {
            customer: null,
            editVendor: {},
            modalType: null,
            vendor: null,
        }
    },
    computed: {
    },
    created() {
        if (!this.vendorInfo) {
            this.$router.back()
        }
        this.vendor = this.vendorInfo

    },
    mounted() {
    },
    methods: {
        editVendorModal(data, type) {
            this.editVendor = data;
            this.modalType = type;
            this.$modal.show('vendorEdit');
        },
        closevendorModal() {
            this.$modal.hide('vendorEdit');
        },
        returnUpdatedVendor(updatedVendor) {
            this.vendor = updatedVendor;
            this.$modal.hide('vendorEdit');
        },
    }
}
</script>
<style scoped>
h3 {
    height: 38px;
}
</style>