<template>
    <div>
        <!-- W2 user view -->
        <ul class="list-group" v-if="search.userPayType != '1099'">
            <li class="list-group-item bg-gray-600 text-white">
                <div class="row">
                    <div class="col">Date</div>
                    <div class="col">Tasks</div>
                    <div class="col">Time</div>
                    <div class="col text-right">Earnings</div>
                </div>
            </li>
            <!-- Earnings by date loop -->
            <li class="list-group-item" v-for="(day, idx) in weeklyPayroll" :key="idx" :class="{ 'bg-gray-100': idx % 2 }">
                <div class="row">
                    <div class="col">
                        {{ day.work_date | formatDateNoTz }}
                    </div>
                    <div class="col">
                        <div @click="viewTasksModal(day)" class="pointer text-primary">
                            <span>View Tasks</span>
                        </div>
                    </div>
                    <div class="col">{{ day.totalDuration | formatSeconds2Time }}</div>
                    <div class="col text-right">
                        <div>
                            {{ day.totalEarnings | toCurrency }}
                        </div>
                        <!-- <div class="text-muted text-sm">
                            {{ day.payType }}
                        </div> -->
                    </div>
                </div>
            </li>
            <li class="list-group-item text-center" v-if="!weeklyPayroll.length">
                <h3>No items to display for this pay period.</h3>
            </li>

            <!-- Report Totals -->
            <li class="list-group-item bg-gray-300 text-white" v-if="weeklyPayroll.length">
                <div class="row text-sm text-bold">
                    <div class="col">Total Regular Hours</div>
                    <div class="col text-right">Total Regular Amount</div>
                    <div class="col">Total Overtime Hours</div>
                    <div class="col text-right">Total Overtime Amount</div>
                    <div class="col text-right">Pay Period Amount</div>
                </div>
                <div class="row">
                    <div class="col">{{ periodTotals.weekDuration | formatSeconds2Time }}</div>
                    <div class="col text-right">{{ periodTotals.weekEarning | toCurrency }}</div>
                    <div class="col">{{ periodTotals.weekOvertimeDuration | formatSeconds2Time }}</div>
                    <div class="col text-right">{{ periodTotals.weekOvertimeEarning | toCurrency }}</div>
                    <div class="col text-right">{{ (periodTotals.weekEarning + periodTotals.weekOvertimeEarning) | toCurrency }}</div>
                </div>
            </li>
        </ul>


        <!-- 1099 View -->
        <ul class="list-group" v-else>
            <li class="list-group-item bg-gray-600 text-white">
                <div class="row">
                    <div class="col">Date</div>
                    <div class="col">Tasks</div>
                    <div class="col">Status</div>
                    <div class="col text-right">Daily Earnings</div>
                </div>
            </li>
            <!-- Earnings by date loop -->
            <li class="list-group-item" v-for="(day, idx) in weeklyPayroll" :key="idx" :class="{ 'bg-gray-100': idx % 2 }">
                <div class="row">
                    <div class="col">
                        {{ day.work_date | formatDateNoTz }}
                    </div>
                    <div class="col">
                        <div @click="viewTasksModal(day)" class="pointer text-primary">
                            <span>View Tasks</span>
                        </div>
                    </div>
                    <div class="col">
                        <span class="text-danger" v-if="day.unapprovedItemsCount">Unapproved Items</span>
                        <span class="text-success" v-else>All Items Approved</span>
                    </div>
                    <div class="col text-right">
                        <div>
                            {{ day.totalEarnings | toCurrency }}
                        </div>
                    </div>
                </div>
            </li>
            <li class="list-group-item text-center" v-if="!weeklyPayroll.length">
                <h3>No items to display for this pay period.</h3>
            </li>

            <!-- Report Totals -->
            <li class="list-group-item bg-gray-300 text-white text-right" v-if="weeklyPayroll.length">
                Week Amount: {{ (periodTotals.weekEarning + periodTotals.weekOvertimeEarning) | toCurrency }}
            </li>
        </ul>

        <modal name="UserDailyPayrollByTask" height="auto" width="98%" :reset='true' :scrollable='true' :adaptive='true'>
            <user-daily-payroll-by-task :selectedDay="selectedDay" @dataReturned="handleDataReturned"></user-daily-payroll-by-task>
        </modal>
    </div>
</template>

<script>

import UserDailyPayrollByTask from '@/common/UserDailyPayrollByTask'
import bus from '@/utils/event-bus'

export default {
    props: ['search'],
    components: { UserDailyPayrollByTask },
    emits: [],
    data() {
        return {
            currentUser: JSON.parse(localStorage.getItem('user')),
            startDate: null,
            endDate: null,
            weeklyPayroll: [],
            periodTotals: [],
            selectedDay: null,
            loader: null
        }
    },
    created() {
        bus.$on('reviseTimeModalUpdate', this.getWorkHistory)
        bus.$on('payrollAdjustPieceRateAllocations_Update', this.getWorkHistory)
    },
    beforeDestroy() {
        bus.$off('reviseTimeModalUpdate', this.getWorkHistory)
        bus.$off('payrollAdjustPieceRateAllocations_Update', this.getWorkHistory)
    },
    mounted() {
        this.loader = this.$loading.show()
        this.getWorkHistory()
    },
    methods: {
        getWorkHistory() {
            axios
                .post('/loadUserWeeklyPayroll', { userId: this.search.userId, startDate: this.search.startDate, endDate: this.search.endDate })
                .then(response => {
                    // console.log('Work History', response.data)
                    this.loader.hide()
                    this.weeklyPayroll = response.data.weeklyPayrollByDate
                    this.periodTotals = response.data.reportTotals
                })
                .catch(err => {
                    console.log('ERROR: ', err)
                    bus.$emit('banner-error', { message: err, })
                })
        },
        // Display tasks Modal
        viewTasksModal(day) {
            this.selectedDay = { userId: day.user_id, workDate: day.work_date, userPayType: this.search.userPayType }
            this.$modal.show('UserDailyPayrollByTask');
        },
         handleDataReturned() {
            this.getWorkHistory();
            this.$modal.hide('UserDailyPayrollByTask');        
        },
    }
}
</script>

<style></style>