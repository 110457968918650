import moment from '@/utils/date-time';

export default {
    GET_ALL_MESSAGES(state, payload) {
        state.messages.all = payload;
    },
    GET_MESSAGE_TYPE(state, payload) {
        state.messages.type = payload;
    },
    GET_MESSAGE_EMPLOYEE(state, payload) {
        state.messages.form.recipients = [payload];
        state.messages.form.selection.showEmployeeList = 1;
    },
    GET_ALL_CREWS(state, payload) {
        state.messages.recipients.crews.data = payload;
    },
    GET_ALL_USER_ROLES(state, payload) {
        payload.forEach(data => {
            data.users = Object.values(data.users);
        });
        state.messages.recipients.roles.data = payload;
    },
    GET_ALL_EMPLOYEES(state, payload) {
        state.users.allemployees = payload;
        state.messages.recipients.employees.data = payload;
    },
    PREVIEW_MESSAGE(state, payload) {
        state.messages.isPreview = payload;
    },
    GET_MESSAGE_UPLOADED_FILE(state, payload) {
        state.messages.form.image = payload.dataURL;
        state.messages.form.file = payload;
    },
    SAVE_MESSAGE(state, payload) {
        state.messages.form.lastInsertedData = payload.id;
    },
    UPDATE_MESSAGE(state, payload) {
        state.messages.form.lastInsertedData = payload;
    },
    GET_RECIPIENTS(state, payload) {
        state.messages.form.recipients = payload;
    },
    SAVE_MESSAGE_FILE(state, payload) {
        state.messages.all = payload;
    },
    GET_LOGGED_IN_USER(state, payload) {
        state.user = payload;
    },
    CHECK_MESSAGE(state, payload) {
        state.messages.notif = payload;
    },
    READ_MESSAGE(state, payload) {
        state.messages.notif.is_read = payload;
    },
    GET_MESSAGE_ID(state, payload) {
        state.messages.messageId = payload;
    },
    UPDATE_FORM_STATE(state, payload) {
        let user = JSON.parse(localStorage.getItem('user'));
        let date = moment(payload.post_date + 'Z').tz(user.timezone);

        state.messages.form = {
            type: 'edit',
            id: payload.id,
            title: payload.title,
            body: payload.body,
            image: payload.image,
            file: payload.file ? payload.file : null,
            btnAction: payload.btn_action,
            btnLabel: payload.btn_label,
            btnUrl: payload.url,
            recipients: payload.recipients,
            date: date.format('MM/DD/YYYY'),
            hour: parseInt(date.format('hh')),
            min: Math.round(date.format('mm') / 10) * 10,
            am: date.format('a'),
            selection: {
                showScheduleOption: payload.is_schedule,
                showActionBtn: payload.btn_action != null,
                showActionBtnUrl: payload.btn_action == 'link',
                showEmployeeList: payload.is_send_all ? 0 : 1,
            },
            saveStatus: null,
            showDropzone: false,
        }
        state.messages.type = payload.type;
    },
    CLEAR_FORM_STATE(state) {
        let user = JSON.parse(localStorage.getItem('user'));
        let date = moment().tz(user.timezone);

        state.messages.form = {
            type: 'add',
            body: null,
            title: null,
            image: null,
            file: null,
            btnAction: 'close',
            btnLabel: 'Close',
            btnUrl: null,
            recipients: [],
            date: date.format('MM/DD/YYYY'),
            hour: parseInt(date.format('hh')),
            min: Math.round(date.format('mm') / 10) * 10,
            am: date.format('a'),
            selection: {
                showScheduleOption: 0,
                showActionBtn: 0,
                showActionBtnUrl: 0,
                showEmployeeList: 0,
            },
            saveStatus: null,
            showDropzone: true,
        }
    },
    DELETE_MESSAGE(state, payload) {
        state.messages.form.deleteStatus = payload;
    },
    SHOW_DROPZONE(state, payload) {
        state.messages.form.showDropzone = payload;
    },
    REMOVE_IMAGE(state) {
        state.messages.form.image = null;
    },
    REMOVE_FILE(state) {
        state.messages.form.file = null;
    },
    UPDATE_SYNC_STATUS(state, payload) {
        state.isSyncToQb = payload;
    },
    GET_ALL_JOB_NOTES(state, payload) {
        state.job.notes = payload;
    },
    GET_ALL_JOB_FILES(state, payload) {
        state.job.files = payload;
    },
    GET_COMPANY_SETTINGS(state, payload) {
        state.company = payload;
    },
    GET_COUNTRIES(state, payload) {
        state.countries = payload;
    },
    GET_STATES(state, payload) {
        state.states = payload;
    },
    SET_GEOLOCATION(state, payload) {
        state.coordinates = payload;
    },
}
