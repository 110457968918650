<template>
    <div class="" v-if="assetItem">
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50" id="viewContainer">
            <!-- HEADER -->
            <div class="d-flex" style="z-index: 100">
                <!-- Breadcrumbs -->
                <div class="mr-auto" style="z-index: 1">
                    <breadcrumbs :title="$t('label.asset items')">
                        <!-- Breakcrumb slots -->
                        <li class="breadcrumb-item">
                            <a class="pointer" @click="$router.go(-1)">
                                {{ $t('label.assets') }}
                            </a>
                        </li>
                        <li class="breadcrumb-item">{{ assetItem.name }}</li>
                    </breadcrumbs>
                </div>

                <!-- Asset Item Option Dropdown -->
                <div class="py-4 mr--2 align-items-center" style="z-index: 1">
                    <button type="button" class="btn btn-default" data-toggle="dropdown" id="vssetMenu">
                        {{ $t('label.item menu') | uppercase }} <i class="fas fa-angle-down"></i>
                    </button>
                    <!-- Dropdown -->
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" style="max-width: 100%;">
                        <!-- Edit Item -->
                        <router-link class="dropdown-item" :to="{ name: 'assetItemEdit', params: { editItem: assetItem } }">
                            <i class="ri-pencil-line"></i>{{ $t('label.edit item') }}
                        </router-link>
                        <a class="dropdown-item" href="#" @click="clickArchive()" data-toggle="modal">
                            <i class="ri-archive-line"></i> {{ $t('label.mark as sold/archived') }}
                        </a>
                    </div>
                </div>
            </div>

            <!-- CONTENT -->
            <div class="sp-window-content">
                <!-- Left Menu -->
                <div class="sp-comp-nav align-items-center" id="navWrapper">
                    <div class="sp-nav-sticky-top pt-4 mt--4">
                        <asset-show-left-nav :asset="assetItem" :asset-type="'item'"></asset-show-left-nav>
                    </div>
                </div>

                <!-- Right Content Column -->
                <div class="sp-comp-body justify-content-end mobile-m-0 mobile-mt-290p flex-grow-1">
                    <div class="mobile-p-0">
                        <!-- Item Details card -->
                        <asset-item-details id="assetDetails" :item="assetItem"></asset-item-details>
                        <!-- Item Comments card -->
                        <asset-comments id="assetComments" :asset-id="assetItem.id" :asset-type="'item'"></asset-comments>
                        <!-- Item Files card -->
                        <asset-files id="assetFiles" :asset-id="assetItem.id" :asset-type="'item'"></asset-files>
                        <!-- Item Maintenance card -->
                        <asset-mtnc-entries id="assetMaintenanceEntries" :asset-id="assetItem.id" :asset-type="'item'"></asset-mtnc-entries>
                        <!-- Item lifecycle card -->
                        <asset-item-lifecycle id="assetLifecycle" :item="assetItem"></asset-item-lifecycle>
                    </div>
                </div>

            </div>
        </div>

        <!-- Archive Asset Item Modal -->
        <div v-if="showArchive">
            <archive-asset :asset="assetItem" :assetType="'item'" @onArchive="onArchive()" @onClose="closeArchive()"></archive-asset>
        </div>

    </div>
</template>

<script>
export default {
    props: ["assetItem"],
    data() {
        return {
            showArchive: false
        }
    },
    created() {
        // Send back to assets page if assetItem isn't set
        if (!this.assetItem) {
            this.$router.push({ name: 'assets' })
            return
        }
    },
    methods: {
        clickArchive() {
            this.showArchive = true;
        },
        closeArchive() {
            this.showArchive = false;
        },
        onArchive() {
            setTimeout(function () {
                console.log("onArchive");
                this.$router.push({ name: 'assets' })
            }, 750);
        }
    }
}
</script>
