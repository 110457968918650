<template>
    <div id="customLoaderContainer">
        <div class="background"></div>
        <div id="customLoader">
            <div>
                <div class="loader-container">
                    <span class="loader"></span>
                </div>
                <div class="text-container">
                    <h4>Please wait…</h4>
                    <h5>{{ loaderText }}</h5>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomPlan',
     props: ["loaderText"],
    data: () => ({
        //
    }),
    created() {
        //
    },
    mounted() {
        //
    },
    methods: {
        //
    }
}

</script>

<style scoped lang="scss">
#customLoaderContainer {
    z-index: 10;

    .background {
        background: rgba(0, 0, 0, 0.4);
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }

    #customLoader {
        width: 100%;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 100vh;

        .text-container {
            text-align: center;

            h4 {
                color: #fff;
                font-size: 32px;
                font-weight: bold;
            }

            h5 {
                color: #fff;
                font-size: 18px;
                margin: 0;
            }
        }

        .loader-container {
            text-align: center;
            margin-bottom: 20px;

            .loader,
            .loader:before,
            .loader:after {
                border-radius: 50%;
                width: 2.5em;
                height: 2.5em;
                top: -1.5em;
                animation-fill-mode: both;
                animation: bblFadInOut 1.8s infinite ease-in-out;
                display: inline-block;
            }

            .loader {
                color: #0b1448;
                font-size: 7px;
                position: relative;
                text-indent: -9999em;
                transform: translateZ(0);
                animation-delay: -0.16s;
            }

            .loader:before,
            .loader:after {
                content: '';
                position: absolute;
                top: 0;
            }

            .loader:before {
                left: -6.5em;
                animation-delay: -0.32s;
            }

            .loader:after {
                left: 6.5em;
            }
        }



    }
}

@keyframes bblFadInOut {

    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em
    }

    40% {
        box-shadow: 0 2.5em 0 0
    }
}
</style>