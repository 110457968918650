<template>
    <div class="card">
        <div class="card-header">
            <h3 class="d-flex align-items-center mb-0">
                {{ $t('label.purchase order info') }}
            </h3>
        </div>
        <div class="card-body">
            <div class="row">
                <!-- Left Column -->
                <div class="col-sm-6">
                    <!-- Vendor -->
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="vendor_name">{{ $t('label.vendor') }}</label>
                        <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                        <div class="dropdown w-100">
                            <div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': (isValid.vendor_name === false || $v.formInfo.vendor_name.$error), }">
                                    <input type="text" class="form-control form-control-dropdown pointer" @input="filterVendors" :placeholder="$t('label.vendor')" v-model.trim="$v.formInfo.vendor_name.$model" autocomplete="input-field" data-lpignore="true" required>
                                    <div class="input-group-append">
                                        <span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
                                    </div>
                                </div>
                            </div>

                            <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
                                <a class="dropdown-item text-default pointer" @click="showAddVendorModal('add')">
                                    <i class="ri-add-fill"></i> {{ $t('label.add vendor') }}
                                </a>
                                <div class="dropdown-divider mx-3"></div>
                                <h4 class="mx-3">{{ $t('label.results') }}</h4>
                                <div class="pl-3">
                                    <template v-for="(data, index) in allVendorsFilter.slice(0, 4)">
                                        <div :key="index" class="pointer dropdown-items" @click="selectVendor(data); validateField('vendor_name')">
                                            <span class="jobname">{{ data.vendor_name }}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Vendor Contact -->
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="vendor_contact_name">{{ $t('label.vendor contact') }}</label>
                        <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                        <div v-if="selectedVendor || formInfo.vendor_id || (poType == 'edit')">
                            <div class="dropdown w-100">
                                <div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': (isValid.vendor_contact_name === false || $v.formInfo.vendor_contact_name.$error), }">
                                        <input type="text" class="form-control form-control-dropdown pointer" @input="filterVendorContacts" :placeholder="$t('label.vendor contact')" v-model.trim="$v.formInfo.vendor_contact_name.$model" autocomplete="input-field" data-lpignore="true" required>
                                        <div class="input-group-append">
                                            <span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
                                    <a class="dropdown-item text-default pointer" @click="showAddVendorContactModal()">
                                        <i class="ri-add-fill"></i> {{ $t('label.add contact') }}
                                    </a>
                                    <h4 class="mx-3">{{ $t('label.results') }}</h4>
                                    <div class="pl-3">
                                        <template v-for="(data, index) in formInfo.vendor.vendor_contacts">
                                            <div :key="index" class="pointer dropdown-items" @click="selectVendorContact(data); validateField('vendor_contact_name')">
                                                <span class="vendorContact">{{ data.first_name }} {{ data.last_name }}</span>
                                                <span class="vendorcontactaddress">{{ data.address_1 }} {{ data.city }} {{ data.state }}</span>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="dropdown w-100">
                                <div class="w-100" role="button">
                                    <div class="input-group input-group-alternative input-group-merge search-contacts">
                                        <input type="text" class="form-control form-control-dropdown pointer" disabled>
                                        <div class="input-group-append">
                                            <span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Job -->
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="job_order">{{ $t('label.job') }}</label>
                        <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                        <div class="dropdown w-100">
                            <div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': (isValid.job_name === false || $v.formInfo.job_name.$error), }">
                                    <input class="form-control form-control-dropdown pointer" @input="filterJobs" :placeholder="$t('label.start typing to search jobs')" type="text" v-model="$v.formInfo.job_name.$model" autocomplete="input-field" data-lpignore="true">
                                    <div class="input-group-append">
                                        <span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
                                <h4 class="mx-3">{{ $t('label.recently viewed jobs') }}</h4>
                                <div class="pl-3">
                                    <template v-for="(data, index) in allJobsFilter.slice(0, 4)">
                                        <div :key="index" class="pointer dropdown-items" @click="selectJob(data); validateField('job_name')">
                                            <span class="jobname">{{ data.job_name }}</span>
                                            <span class="customername">{{ data.customer.name }}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Right Column -->
                <div class="col-sm-6">
                    <!-- Order Date -->
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="purchase_date">{{ $t('label.purchase order date') }}</label>
                        <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                        <date-picker v-model="$v.formInfo.purchase_date.$model" class="full-width" valueType="format" format="MM/DD/YYYY" :append-to-body="false" :placeholder="$t('label.purchase order date')" input-class="form-control" @change="validateField('purchase_date')" required></date-picker>
                        <div v-if="$v.formInfo.purchase_date.$error && !$v.formInfo.purchase_date.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
                    </div>
                    <!-- Delivery date -->
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="delivery_date">{{ $t('label.expected delivery date') }}</label>
                        <date-picker v-model="formInfo.delivery_date" valueType="format" format="MM/DD/YYYY" class="full-width" input-class="form-control" :append-to-body="false" :placeholder="$t('label.expected delivery date')" required>
                        </date-picker>
                    </div>

                    <!-- Terms -->
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="terms">{{ $t('label.terms') }}</label>
                        <input type="text" name="terms" class="form-control" :placeholder="$t('label.terms')" v-model="formInfo.terms" required>
                    </div>
                </div>
            </div>
        </div>
        <!-- Add Vendor Modal -->
        <modal class="py-4" name="addVendorModal" height="auto" width="98%" :max-width="1024" :reset="true" :scrollable="true" :adaptive="true">
            <ModalAddEditVendor :modalType="modalType" :comingFrom="comingFrom" @cancelFnc="hideAddVendorModal" @returnFnc="returnNewvendor">
            </ModalAddEditVendor>
        </modal>
        <modal class="py-4" name="openVendorContactsModal" height="auto" width="98%" :max-width="1024" :reset="true" :scrollable="true" :adaptive="true">
            <ModalVendorContacts :vendor="selectedVendor" :from="0" @cancelFnc="closeVendorContacts">
            </ModalVendorContacts>
        </modal>
    </div>
</template>

<script>
import ModalAddEditVendor from '@/components/Admin/Vendors/ModalAddEditVendor';
import AddVendorModal from '@/components/Admin/Vendors/ModalAddEditVendor.vue';
import ModalVendorContacts from '@/components/Admin/Vendors/ModalVendorContacts';
import TablePagination from '@/components/TablePagination';
import bus from '@/utils/event-bus';
import { ModelSelect } from 'vue-search-select';
import {
    required,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';


export default {
    props: ['formInfo', 'isValid', 'poType', 'allVendors', 'allPurchaseOrderStatus', 'jobPOInfo'],
    emits: [''],
    components: {
        TablePagination,
        AddVendorModal,
        ModalVendorContacts,
        ModelSelect: ModelSelect,
        ModalAddEditVendor,
    },
    data() {
        return {
            allJobs: [],
            allJobsFilter: [],
            allVendorContactsFilter: [],
            allVendorsFilter: [],
            allCustomersFilter: [],
            alljobpeople: [],
            customerContactList: [],
            selectedVendor: null,
            modalType: null,
            newvendor: null,
            comingFrom: 'po',
        }
    },
    validations: {
        formInfo: {
            vendor_name: { required },
            customer_name: { required },
            superintendent_user_id: { required },
            job_name: { required },
            purchase_date: { required },
            vendor_contact_name: { required },
        },
    },
    computed: {
        ...mapGetters({
            purchaseOrderNumber: 'purchaseOrder/purchaseOrderNumber',
        }),
    },
    mounted() {
        bus.$on('PurchaseOrderInfo', (data) => {
            this.formInfo.vendor.vendor_contacts.push(data);
        });
        this.loadLkupData();
        this.allVendorsFilter = this.allVendors;
    },
    methods: {
        loadLkupData() {
            let promises = [];
            this.selectedVendor = null;
            // this.loader = this.$loading.show();
            // if( this.poType == 'add' ) promises.push(this.$store.dispatch('purchaseOrder/getPurchaseOrderNumber'));

            promises.push(this.getOpenJobsTable());

            // Hide the loader overlay after all lookup data is loaded
            Promise.all(promises).then((data) => {
                // console.log('promise data',data);

                this.allVendorsFilter = this.allVendors;
                if (this.poType == 'add') {
                    this.formInfo.order_number = 'SO-' + this.purchaseOrderNumber;
                    this.formInfo.status = this.allPurchaseOrderStatus.filter(x => x.name == 'Draft').map(x => x.id)[0];
                }
                // this.loader.hide();
            });
        },
        showAddVendorModal(e) {
            this.modalType = e;
            this.$modal.show('addVendorModal');
        },
        returnNewvendor(newvendor) {
            this.newvendor = newvendor.data.result;
            this.$modal.hide('addVendorModal');
            this.allVendorsFilter.push(this.newvendor);
            this.allVendors = this.allVendorsFilter;
        },
        hideAddVendorModal() {
            this.$modal.hide('addVendorModal');
        },
        showAddVendorContactModal() {
            this.$modal.show('openVendorContactsModal');
        },
        closeVendorContacts() {
            this.$modal.hide('openVendorContactsModal');
        },
        getOpenJobsTable() {
            axios.get('/get-open-jobs-table').then(response => {
                this.allJobs = response.data.result;
                this.allJobsFilter = this.allJobs;
            })
                .catch(err => console.error('Error in getOpenJobsTable:', err));
        },
        selectJob(data) {
            if (this.formInfo.job_name != data.job_name &&
                this.formInfo.job_id != data.id) {
                bus.$emit('resetAddress', 2);
            }
            this.formInfo.job_name = data.job_name + ' - ' + data.customer.name;
            this.formInfo.job_id = data.id;
            this.formInfo.job = data;
        },
        filterJobs() {
            this.allJobsFilter = this.allJobs;
            if (this.formInfo.job_name.length > 0) {
                this.allJobsFilter = this.allJobs.filter(x => x.job_name.toLowerCase().includes(this.formInfo.job_name.toLowerCase()));
            }
        },
        filterVendors() {
            this.allVendorsFilter = this.allVendors;
            if (this.formInfo.vendor_name.length > 0) {
                this.allVendorsFilter = this.allVendors.filter(x => x.vendor_name.toLowerCase().includes(this.formInfo.vendor_name.toLowerCase()));
            }
        },
        filterVendorContacts() {
            this.allVendorContactsFilter = this.allVendors.vendor_contacts;
        },
        selectVendor(data) {
            if (this.formInfo.vendor_name != data.vendor_name &&
                this.formInfo.vendor_id != data.id) {
                bus.$emit('resetAddress', 1);
            }
            this.formInfo.vendor_name = data.vendor_name;
            this.formInfo.vendor_id = data.id;
            this.selectedVendor = data;
            this.formInfo.vendor_contact_id = null;
            this.formInfo.vendor_contact_name = null;
            this.formInfo.vendor = data;
        },
        selectVendorContact(data) {
            // if(this.formInfo.vendor_contact_id != data.id && 
            // (this.formInfo.vendor_contact_name != (data.first_name + " " + data.last_name))) {
            //     bus.$emit('resetAddress',1);
            // }
            this.formInfo.vendor_contact_id = data.id;
            this.formInfo.vendor_contact_name = (data.first_name + " " + data.last_name);
            this.formInfo.vendor_contact = data;
        },
        validateField(field) {
            // If field has error, set isValid field to false
            if (this.poType == 'add' || this.poType == 'edit') {
                this.isValid[field] = !this.$v.formInfo[field].$error;
            }
        },
    },
}
</script>
