<template>
    <div class="modal fade" id="modalClockout" tabindex="-1" role="dialog" aria-labelledby="modalClockout" aria-hidden="true">
        <div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
            <div class="modal-content bg-gradient-danger">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="py-3 text-center">
                        <i class="ri-stop-circle-line ni-3x"></i>
                        <h4 class="heading mt-4 text-uppercase">{{ $t('label.ARE YOU SURE YOU WANT TO CLOCK-OUT') }} {{ user.name == username ? '' : username }}?</h4>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-white" @click="clockoutFunc()">{{ $t('label.Yes, Clock Out') }}</button>
                    <button type="button" class="btn btn-link text-white ml-auto closemodalClockout" data-dismiss="modal">{{ $t('label.cancel') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';
export default {
    props: [],
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')) || {},
            username: ''
        }
    },
    mounted() {
        bus.$on('modalClockout', (data) => {
            this.username = data.username
        })
    },
    methods: {
        clockoutFunc() {
            bus.$emit('clockoutFunc')
        }
    },
}
</script>
