<template>
    <div>
        <table class="table table-sm table-flush mb-0">
            <thead class="thead-light">
                <tr class="hidden-sm-down">
                    <th scope="col" style="width: 25%">{{ $t('label.task assignee') }}</th>
                    <th scope="col" style="width: 15%">{{ $t('label.status') }}</th>
                    <th scope="col" style="width: 15%">{{ $t('label.current time') }}</th>
                    <th scope="col" style="width: 15%;">{{ $t('label.total time') }}</th>
                    <th v-if="checkpending" scope="col"></th>
                    <th scope="col" style="width: 15%;"></th>
                </tr>
                <tr class="hidden-sm-up">
                    <th scope="col" style="width: 35% !important;" colspan="2">{{ $t('label.task assignee') }}</th>
                    <th scope="col">{{ $t('label.status') }}</th>
                    <th scope="col" colspan="2">{{ $t('label.total time') }}</th>
                    <th scope="col">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colspan="12" style="padding: 0 !important;">
                        <timesheet-proximity-component :showProximityFlag="showProximityFlag" :timePunches="timePunches" :task="task" :selectedDate="selectedDate" :viewTaskTime_timer="viewTaskTime_timer" :checkpending="checkpending" :elapsedTime3="elapsedTime3" :flag="flag" :newId="newId" @clockInFunc="clockIn" @clockOutFunc="clockOut">
                        </timesheet-proximity-component>
                    </td>
                </tr>
            </tbody>
            <thead class="thead-light">
                <tr>
                    <th scope="col" colspan="3" class="text-right">{{ $t('label.total task time') }}</th>
                    <th scope="col">
                        <h4 class="mb-0">{{ totalTaskTime[task.id] | hourToFormattedTime }} ({{ totalTaskTime[task.id] }} {{ $t('label.hrs') }})</h4>
                    </th>
                    <th v-if="checkpending"><button type="button" class="btn btn-pending-curtime btn-link btn-sm">{{ $t('label.pending current time') }}</button></th>
                    <th scope="col"></th>
                </tr>
            </thead>
        </table>
    </div>
</template>
<script>
export default {
    props: ['checkpending', 'timePunches', 'showProximityFlag', 'selectedDate', 'viewTaskTime_timer', 'elapsedTime3', 'newId', 'totalTaskTime', 'flag', 'crewMembers', 'task'],
    emits: ['clockInFunc', 'clockOutFunc'],
    data() {
        return {
            //
        }
    },
    mounted() {
        //
    },
    methods: {
        clockIn(data) {
            this.$emit("clockInFunc", data.task, data.data);
        },
        clockOut(data) {
            this.$emit('clockOutFunc', data);
        }
    },
}
</script>
