<template>
    <div>
        <!-- <div class="modal" id="messageStatsModal" tabindex="-1" role="dialog" aria-labelledby="messageStatsModalTitle" aria-hidden="true"> -->
        <!-- <div class="modal-dialog" role="document" style="max-width: 1000px" v-for="(data, index) in messages.all" :key="index" v-if="data.id == messages.messageId"> -->

        <div class="modal-content" v-for="(data, index) in messages.all" :key="index" v-if="data.id == messages.messageId">
            <div class="modal-header border-bottom">
                <h5 class="modal-title" id="messageStatsModalTitle">{{ $t('label.message stats') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$modal.hide('statsModal')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body pb-0">
                <div class="row">
                    <div class="form-group col-md-3 col-sm-12">
                        <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.post date') }}</label>
                        <div class="input-value">
                            {{ data.post_date | formatDateAndTime }}
                        </div>
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.message type') }}</label>
                        <div class="input-value">
                            {{ data.type | formatMsgType }}
                        </div>
                    </div>
                    <div v-if="data.title" class="form-group col-md-6 col-sm-12">
                        <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.message title') }}</label>
                        <div class="input-value">
                            {{ data.title }}
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12 pl-0">
                    <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.message body') }}</label>
                    <div class="input-value">
                        <img v-if="data.image" :src="data.image" class="w-200" />
                        <p>{{ data.body }}</p>
                    </div>
                </div>
                <h2>{{ $t('label.recipients') }}</h2>
                <div class="row">
                    <div class="col p-0">
                        <div class="card m-0">
                            <div class="table-responsive pt-0">
                                <table class="table table-flush" id="datatable-recipient">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col">{{ $t('label.recipient') }}</th>
                                            <th scope="col">{{ $t('label.status') }}</th>
                                            <th scope="col">{{ $t('label.view date') }}</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(recipient, idx) in data.recipients" :key="idx">
                                        <tr v-if="recipient.user">
                                            <td>
                                                <div class="row m-0 d-flex align-items-center">
                                                    <a href="#" class="avatar avatar-single avatar-sm rounded-circle">
                                                        <img alt="Image placeholder" :src="recipient.user.picture">
                                                    </a>
                                                    <div>
                                                        <label style="font-size: 16px" class="col-12 m-0">{{ recipient.user.name }}</label>
                                                        <label class="col-12 text-muted m-0">{{ recipient.user.role.name }}</label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{ recipient.is_read ? 'Viewed' : 'Not Viewed' }}</td>
                                            <td>{{ recipient.read_at | formatDateAndTime }}</td>
                                            <td class="text-right">
                                                <div class="dropdown">
                                                    <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="fas fa-ellipsis-v"></i>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                        <span class="dropdown-item pointer" data-dismiss="modal" data-toggle="modal" data-target="#messageAddModal" @click="getMessageInfo('text', recipient.user.id)">
                                                            <i class="ri-smartphone-line"></i>{{ $t('label.text') }} ({{ recipient.user.phone }})
                                                        </span>
                                                        <a class="dropdown-item pointer" :href="'mailto:' + recipient.user.email">
                                                            <i class="ri-mail-line"></i>{{ $t('label.email') }} ({{ recipient.user.email }})
                                                        </a>
                                                        <span class="dropdown-item pointer" @click="getUserInfo(recipient.user.id, 'Profile')">
                                                            <i class="ri-account-circle-line"></i>{{ $t('label.view profile') }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-top d-flex justify-content-end">
                <button type="button" class="btn btn-link text-primary" data-dismiss="modal">{{ $t('label.close') }}</button>
            </div>
        </div>
        <!-- </div> -->
        <!-- </div> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters(['messages', 'user']),
    },
    created() {
    },
    mounted() {
    },
    methods: {
        getMessageInfo(messageType, employeeId) {
            this.$store.dispatch('getMessageType', messageType);
            this.$store.dispatch('clearFormState');
            this.$store.dispatch('getMessageEmployee', 'employees-' + employeeId);
        },
    }
}
</script>
