import bus from '@/utils/event-bus';
import Vue from 'vue';

// Replaced this with emit components. Emits in Main.vue
// removed original markup from app.blade.php.
Vue.mixin({
    methods: {
        getUserInfo(id, name) {
            // Display customer info modal
            if (name == 'Customer') {
                bus.$emit('displayCustomerInfo', id)
            }
            // Display user info modal
            else {
                bus.$emit('displayUserInfo', id)
            }
        },
    }
});