/* eslint-disable no-unused-vars */
import moment from '@/utils/date-time';
const namespaced = true;

const state = {
    task: [],
    crewTime: [],
    crewTimeOverall: 0,
    allInvoices: [],
    allJobNotes: [],
    allJobMaterials: [],
    allJobFiles: [],
    allTaskStatus: [],
    allTasksByDateRange: [],
    unassignedTasks: [],
    allTasks: [],
    allUnassignedTasks: [],
    allUnscheduledTasks: [],
    allTimeOffsByDateRange: [],
};

const getters = {
    task: state => state.task,
    crewTime: state => state.crewTime,
    crewTimeOverall: state => state.crewTimeOverall,
    allInvoices: state => state.allInvoices,
    allJobNotes: state => state.allJobNotes,
    allJobMaterials: state => state.allJobMaterials,
    allJobFiles: state => state.allJobFiles,
    allTaskStatus: state => state.allTaskStatus,
    allTasksByDateRange: state => state.allTasksByDateRange,
    unassignedTasks: state => state.unassignedTasks,
    allTasks: state => state.allTasks,
    allUnassignedTasks: state => state.allUnassignedTasks,
    allUnscheduledTasks: state => state.allUnscheduledTasks,
    allTimeOffsByDateRange: state => state.allTimeOffsByDateRange,
};

const actions = {

    getTaskById({ state, commit, rootState, dispatch }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get('/get-task-by-id/' + id)
                .then(res => {
                    commit('setTask', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getCrewTime({ state, commit, rootState, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/crew-time-punches', data)
                .then(res => {
                    commit('setCrewTime', res.data.result);
                    commit('setCrewTimeOverall', res.data.overall_time);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getAllInvoices({ state, commit, rootState, dispatch }, job_id) {
        return new Promise((resolve, reject) => {
            axios
                .get('/get-all-invoices/' + job_id + '/job')
                .then(res => {
                    commit('setAllInvoices', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getAllJobNotes({ state, commit, rootState, dispatch }, job_id) {
        return new Promise((resolve, reject) => {
            axios
                .get('/jobnotes/' + job_id)
                .then(res => {
                    commit('setAllJobNotes', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getAllJobMaterials({ state, commit, rootState, dispatch }, job_id) {
        return new Promise((resolve, reject) => {
            axios
                .get('/jobmaterials/' + job_id)
                .then(res => {
                    commit('setAllJobMaterials', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getAllJobFiles({ state, commit, rootState, dispatch }, job_id) {
        return new Promise((resolve, reject) => {
            axios
                .get('/jobfiles/' + job_id)
                .then(res => {
                    let data = res.data.result;
                    data.forEach(value => {
                        if (value.download) {
                            let filePath = value.download;
                            let filePathArr = filePath.split("/");
                            let filename = filePathArr[filePathArr.length - 1];
                            let fileType = filename.split('.').pop();

                            if (fileType === 'pdf') {
                                filePath = 'images/icons/doc-pdf.png';
                            } else if (fileType === 'xlsx') {
                                filePath = 'images/icons/doc-excel.png';
                            } else if (fileType === 'pptx') {
                                filePath = 'images/icons/doc-ppt.png';
                            } else if (fileType === 'docx' || fileType === 'doc') {
                                filePath = 'images/icons/doc-word.png';
                            } else if (fileType === 'txt' || fileType === 'rtf' || fileType === 'heic' || fileType === 'tiff') {
                                filePath = 'images/icons/doc-other.png';
                            }

                            value.filename = filename;
                            value.filename_path = encodeURI(filePath);
                            value.fileType = fileType;
                        }
                    });
                    commit('setAllJobFiles', data);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getAllTaskStatus({ state, commit, rootState, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get('/taskstatus').then(res => {
                commit('setAllTaskStatus', res.data.result);
                resolve(res);
            }).catch(err => reject(err));
        });
    },
    getAllTasksByDateRange({ state, commit, rootState, dispatch }, params) {
        return new Promise((resolve, reject) => {
            axios.post('/get-all-tasks-by-date-range', params)
                .then(res => {
                    commit('setAllTasksByDateRange', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    updateTaskDates({ state, commit, rootState, dispatch }, requestData) {
        return new Promise((resolve, reject) => {
            axios.post('/update-task-dates', requestData.task_data).then(res => {
                let tempTasks = JSON.parse(JSON.stringify(state.allTasksByDateRange));

                if (!requestData.full_reload) {
                    // Update only the single task details
                    tempTasks.forEach((task, idx) => {
                        let tempTask = res.data.result;

                        if (task.id == tempTask.id) {
                            // Remove updated task if it's date range no longer falls
                            // within the current view of the selected date range.
                            if (requestData.date_range) {
                                let dateRange = requestData.date_range,
                                    dateRangeStart = moment(dateRange[0]).format('MM/DD/YYYY'),
                                    dateRangeEnd = moment(dateRange[dateRange.length - 1]).format('MM/DD/YYYY'),
                                    taskStart = moment(tempTask.start_date).format('MM/DD/YYYY'),
                                    taskEnd = moment(tempTask.due_date).format('MM/DD/YYYY'),
                                    taskDueDateDiff = moment(taskEnd).diff(dateRangeStart, 'days'),
                                    taskStartDateDiff = moment(taskStart).diff(dateRangeEnd, 'days');

                                if (taskDueDateDiff < 0 || taskStartDateDiff > 0)
                                    tempTasks.splice(idx, 1);
                            }

                            task.start_date = res.data.result.start_date;
                            task.due_date = res.data.result.due_date;
                            task.date_range_text = tempTask.date_range_text;
                        }
                    });
                }

                commit('setAllTasksByDateRange', tempTasks);
                resolve(res);
            }).catch(err => reject(err));
        });
    },
    getAllTasks({ state, commit, rootState, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axios.post('/get-task-lists', data)
                .then(res => {
                    commit('setAllTasks', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getAllUnassignedTasks({ state, commit, rootState, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axios.post('/get-all-unassigned-tasks', data)
                .then(res => {
                    commit('setAllUnassignedTasks', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getAllUnscheduledTasks({ state, commit, rootState, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get('/get-all-unscheduled-tasks')
                .then(res => {
                    commit('setAllUnscheduledTasks', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getAllTimeOffsByDateRange({ state, commit, rootState, dispatch }, params) {
        return new Promise((resolve, reject) => {
            axios.post('/get-all-time-offs-by-date-range', params)
                .then(res => {
                    commit('setAllTimeOffsByDateRange', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    deleteFromTask({ state, commit, rootState, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axios.post('/delete-from-task', data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
}

const mutations = {
    setTask(state, task) {
        state.task = task;
    },
    setCrewTime(state, crewTime) {
        state.crewTime = crewTime;
    },
    setCrewTimeOverall(state, crewTimeOverall) {
        state.crewTimeOverall = crewTimeOverall;
    },
    setAllInvoices(state, allInvoices) {
        state.allInvoices = allInvoices;
    },
    setAllJobNotes(state, allJobNotes) {
        state.allJobNotes = allJobNotes;
    },
    setAllJobMaterials(state, allJobMaterials) {
        state.allJobMaterials = allJobMaterials;
    },
    setAllJobFiles(state, allJobFiles) {
        state.allJobFiles = allJobFiles;
    },
    setAllTaskStatus(state, allTaskStatus) {
        state.allTaskStatus = allTaskStatus;
    },
    setAllTasksByDateRange(state, allTasksByDateRange) {
        state.allTasksByDateRange = allTasksByDateRange;
    },
    setUnassignedTasks(state, unassignedTasks) {
        state.unassignedTasks = unassignedTasks;
    },
    setAllTasks(state, allTasks) {
        state.allTasks = allTasks;
    },
    setAllUnassignedTasks(state, allUnassignedTasks) {
        state.allUnassignedTasks = allUnassignedTasks;
    },
    setAllUnscheduledTasks(state, allUnscheduledTasks) {
        state.allUnscheduledTasks = allUnscheduledTasks;
    },
    setAllTimeOffsByDateRange(state, allTimeOffsByDateRange) {
        state.allTimeOffsByDateRange = allTimeOffsByDateRange;
    },
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
};