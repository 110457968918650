<template>
	<div>
		<!-- Header crumbs -->
		<div class="header bg-primary pb-6 mobile-p-0">
			<div class="container-fluid">
				<div class="header-body">
					<div class="row align-items-center">
						<div class="col-lg-6 col-7">
							<breadcrumbs :title="$t('label.vendors')">
								<!-- Breadcrumb slots -->
								<li class="breadcrumb-item">
									{{ $t('label.admin') }}
								</li>
								<li class="breadcrumb-item">
									{{ $t('label.content management') }}
								</li>
								<li class="breadcrumb-item">
									{{ $t('label.vendors') }}
								</li>
							</breadcrumbs>
						</div>
						<div class="col-lg-6 col-5 text-right">
							<button type="button" class="btn btn-neutral" aria-haspopup="true" aria-expanded="false" @click="showAddvendorModal('add')">
								{{ $t('label.add vendor') }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Filters -->
		<div class="container-fluid mt--6 mobile-mt-140p">
			<div class="card">
				<div class="card-header">
					<div class="row">
						<div class="col-8">
							<h3 class=""><i class="ri-group-line"></i> {{ $t('label.vendors') }}</h3>
						</div>
					</div>
				</div>
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-xs-12 col-6">
							<div class="form-group mb-0">
								<label class="form-control-label text-muted" for="input-name">Search By</label>
							</div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="radio" name="searchByName" id="searchByName" v-model="filter.searchBy" value="name">
								<label class="form-check-label text-muted check">Vendor Name</label>
							</div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="radio" name="searchByContact" id="searchByContact" v-model="filter.searchBy" value="contact">
								<label class="form-check-label text-muted">Vendor Contacts</label>
							</div>
						</div>
					</div>
					<div class="row mb-3">
						<div class="col-xs-12 col-4 text-left">
							<div class="form-group mb-0">
								<div class="input-group input-group-alternative input-group-merge search-contacts">
									<div class="input-group-prepend">
										<span class="input-group-text"><i class="fas fa-search"></i></span>
									</div>
									<div v-if="filter.searchBy == 'contact'">
										<input name="s" class="form-control" placeholder="Search by contacts" type="text" v-model="filter.search" autocomplete="off" data-lpignore="true">
									</div>
									<div v-else>
										<input name="s" class="form-control" placeholder="Search by name" type="text" v-model="filter.search" autocomplete="off" data-lpignore="true">
									</div>
									<!--<input name="s" class="form-control" placeholder="Search all contacts" type="text" v-model="filter.search" autocomplete="off" data-lpignore="true">-->
								</div>
							</div>
						</div>
						<div class="col-xs-12 col-2 text-left">
							<button v-if="filter.search.length > 0" type="button" class="btn btn-outline-secondary text-uppercase w-100" @click="clearSearch()">{{ $t('clear search') }}</button>
						</div>
					</div>
				</div>
			</div>

			<!-- Vendor component -->
			<vendor-list :vendors="vendors" :filter="filter" :key="reloadKey" />
		</div>

		<modal class="py-4" name="vendorAddModal" height="auto" width="98%" :max-width="1024" :reset="true" :scrollable="true" :adaptive="true">
			<modal-add-edit-vendor :modalType="modalType" @cancelFnc="closevendorModal" @returnFnc="returnNewvendor" />
		</modal>

	</div>
</template>
<script>
import ModalAddEditVendor from '@/components/Admin/Vendors/ModalAddEditVendor';
import ModalVendorContacts from '@/components/Admin/Vendors/ModalVendorContacts';
import VendorList from '@/components/Admin/Vendors/VendorList';

export default {
	props: [],
	emits: ['fncCancel', 'fncReturn'],
	components: {
		VendorList,
		ModalAddEditVendor,
		ModalVendorContacts
	},
	data() {
		return {
			filter: {
				search: '',
				searchBy: null,
			},
			newvendor: null,
			reloadKey: 0,
			modalType: null,
			vendors: [],
			loader: null,
		}
	},
	created() {
		this.getAllVendors();
	},
	mounted() {

	},
	methods: {
		async getAllVendors() {

			this.loader = this.$loading.show();
			this.vendors = [];

			let params = {
				filters: this.filter
			}
			await axios.get('/get-all-vendors', { params })
				.then(response => {
					this.vendors = response.data.results;
					this.$emit('returnFnc', this.vendors);

					this.loader.hide();
				}).catch(err => console.error("API ERROR GETALLvendorS", err));
		},
		showAddvendorModal(e) {
			this.modalType = e;
			this.$modal.show('vendorAddModal');
		},
		closevendorModal() {
			this.$modal.hide('vendorAddModal')
		},
		//	getAllVendors() {
		//		this.filter.search = '';
		//	},
		clearSearch() {
			this.filter.search = '';
		},
		returnNewvendor(newvendor) {
			this.newvendor = newvendor.data.result;
			this.$modal.hide('vendorAddModal');
			this.reloadKey++;
		},
	}
}
</script>
