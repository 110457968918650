class ServiceWorkerStatus {
    constructor() {
        this.isLoading = false
        this.message = null

        this.reset();
    }

    reset(time = 8000) {
        setTimeout(() => {
            this.isLoading = false
            this.message = null
        }, time)
    }

    setLoading({ status, message, reload = false }) {
        if (status) this.isLoading = status;
        if (message) this.message = message;

        if (reload) {
            setTimeout(() => window.location.reload(), 5000)
        }

        console.log(message);
        this.reset();
    }
}

const worker = new ServiceWorkerStatus();
export default worker;