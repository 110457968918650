<template>
	<div>
		<div class="modal-content">
			<!-- Header -->
			<div class="modal-header border-bottom">
				<h5 class="modal-title" id="invoiceSendModalTitle">{{ $t('label.send email') }}</h5>
				<button type="button" class="close" @click="$emit('cancelFnc')">
					<i class="ri-close-line"></i>
				</button>
			</div>

			<!-- Body -->
			<form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="sendInvoice">
				<div class="modal-body">
					<!-- Recipient Email Addresses -->
					<div class="row m-0 mb-3">
						<div class="col-12">
							<label class="form-control-label">{{ $t('label.to') }}</label>
							<span class="text-xs text-muted">( {{ $t('label.separate multiple email addresses with a comma') }} )</span>

							<input type="text" name="email_to" v-model="email.to" class="form-control" placeholder="Email To" required>
						</div>
					</div>
					<!-- Email Subject -->
					<div class="row m-0 mb-3">
						<div class="col-12">
							<label class="form-control-label">{{ $t('label.subject') }}</label>
							<input type="text" name="email_subject" v-model="email.subject" class="form-control" placeholder="Email Subject" required>
						</div>
					</div>
					<!-- Email Body Content -->
					<div class="row m-0 mb-3">
						<div class="col-12">
							<label class="form-control-label">{{ $t('label.email body') }}</label>
							<textarea v-model="email.body" name="email_body" class="form-control" placeholder="Body of email" rows="10" required autofocus></textarea>
						</div>
					</div>
					<!-- Additional Email Attachments -->
					<div class="row m-0 mb-3">
						<div class="col-12">
							<label class="form-control-label">{{ $t('label.attachments') }}</label>
							<div class="row align-items-center m-0">
								<div class="col-auto p-0">
									<img class="" :src="require('@/assets/images/doc-pdf.png')" style="height: 48px; object-fit: cover;" />
								</div>
								<div class="col-auto">
									<p class="m-0" style="line-height: 20px;">
										<label class="form-control-label mb-0">{{ email.invoice_file_name }}</label>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer border-top">
					<button type="submit" class="btn btn-primary mr-auto text-uppercase">{{ $t('label.send email') }}</button>
					<button type="button" class="btn btn-link  ml-auto close2invoicesend" @click="$emit('cancelFnc')">{{ $t('label.close') }}</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import bus from '@/utils/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	props: ['job', 'invoiceData'],
	emits: ['cancelFnc', 'reload'],
	components: {
		Loading
	},
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
			companySettings: JSON.parse(localStorage.getItem('company_settings')),
			curDate: moment().format("YYYY_MM_DD"),
			email: {
				to: null,
				subject: null,
				body: null,
				invoice_name: null,
				invoice_size: null
			},
			loader: null,
			jobData: null,
			requestData: null,
			previouslySentEmail: null
		}
	},
	created() {
		bus.$on('invoiceSend', (data) => {
			this.invoiceSendModal(data);
		});
	},
	mounted() {
		this.loadLkupData(this.invoiceData)
	},
	methods: {
		async loadLkupData(item) {
			this.loader = this.$loading.show();
			await this.invoiceSendModal(item);
			this.loader.hide();
		},
		async getSentEmail() {
			let uri = `/invoice-get-first-sent-email/${this.invoiceData.data.id}`;
			return axios.get(uri).then(rsp => {
				this.previouslySentEmail = rsp.data.result;
				console.log(this.previouslySentEmail);
			}).catch(err => console.error(err));
		},
		invoiceSendModal(item) {
			// Set Email Data
			this.jobData = item.data.job;
			this.email.invoice_file_name = "Invoice-" + item.data.invoice_number.invoice_number + "_" + this.curDate + ".pdf";
			if (item.cancel == 1) {
				this.email.subject = "Cancelled Invoice " + item.data.invoice_number.invoice_number + " from " + this.companySettings.name;
				this.email.invoice_file_name = "CANCELLED_" + this.email.invoice_file_name;

				// Get all recipients the original email was sent to
				this.getSentEmail().then(() => {
					let emailAddresses = JSON.parse(this.previouslySentEmail.to);

					this.email.to = "";
					emailAddresses.forEach((emAddr, i) => {
						this.email.to += emAddr;
						this.email.to += (i != emailAddresses.length - 1) ? ", " : "";
					});
				});
			} else {
				this.email.to = this.jobData.customer.email;
				this.email.subject = "Invoice " + item.data.invoice_number.invoice_number + " from " + this.companySettings.name;
			}

			this.requestData = {
				data: {
					'id': item.data.id,
					'invoice_status_name': item.data.invoice_status.name
				},
				email: this.email,
				cancel: item.cancel,
			}
		},

		formValid() {
			let isValid = true,
				emails = this.$spSplitEmailString(this.email.to);

			// Validate email(s)
			emails.forEach(email => {
				console.log(email, this.$spValidEmail(email));
				isValid = this.$spValidEmail(email);
				// Break out of foreach if an email is invalid and alert user
				if (!isValid) {
					bus.$emit('banner-error', {
						message: this.$t('label.Please verify that the email(s) in the "To" field are correct If multiple emails are entered, be sure to separate them with commas')
					});
					return;
				}
			});

			return isValid;
		},

		sendInvoice() {
			if (this.formValid()) {
				this.loader = this.$loading.show();
				this.email.toList = this.$spSplitEmailString(this.email.to);

				axios.post('/invoice-send-email', this.requestData).then(() => {
					this.$emit('reloadInvoices');
					bus.$emit('reloadReportInvoicesAfterSend');
					this.loader.hide();

					bus.$emit('banner-success', {
						message: this.$t('label.invoice sent successfully')
					});
					this.clearForm();
					$('.close2invoicesend').click();
				}).catch(err => {
					//console.log('list carriers error:', error)
				});
			}
		},
		clearForm() {
			this.email.to = null,
				this.email.subject = null;
			this.email.body = null;
			this.email.invoice_name = null;
			this.email.invoice_size = null;
			this.requestData = null
		},

	}
}
</script>