<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50">
            <!-- HEADER -->
            <div class="row align-items-center py-4">
                <div class="col-5">
                    <!-- <h2 class="text-white">{{ $t('label.tasks') }}</h2> -->
                </div>
                <div class="col-7 text-right">
                    <!-- <button class="btn btn-neutral px-2 py-1" type="button" @click="loadTaskList"><i class="ri-refresh-line text-lg p-0"></i></button> -->
                </div>
            </div>

            <!-- Todays tasks -->
            <div class="card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col-6">
                            <h3 class="mb-0">{{ $t('label.todays tasks') }}</h3>
                        </div>
                        <div class="col-6 text-right">                
                            <button class="btn btn-neutral px-2 py-1" type="button" @click="loadTaskList"><i class="ri-refresh-line text-lg p-0"></i></button>
                    </div>
                </div>
                </div>
                <div class="card-body">
                    <div class="row" v-if="todaysTasks.length">
                        <div class="col-sm-12" v-for="task in todaysTasks" :key="task.id">
                            <div class="card">

                                <!-- Task name in header -->
                                <div class="card-header text-white pointer" @click="toggleShowTask(task)" :style="`background-color: ${task.task_status.status_color}`">
                                    <div class="row">
                                        <div class="col-6">
                                    {{ task.name }}
                                </div>
                                        <div class="col">
                                            <span>
                                                Status: {{ task.task_status.name }}
                                            </span>                                            
                                        </div>
                                        <div class="col text-right">
                                            <i v-if="task.show" class="ri-arrow-down-s-fill"></i>
                                            <i v-else class="ri-arrow-right-s-fill"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body" v-show="task.show">
                                    <!-- Customer -->
                                    <div class="row py-1 border-bottom">
                                        <div class="col-4">Customer</div>
                                        <div class="col">{{ task.job.customer.name }}</div>
                                    </div>
                                    <!-- Customer contact -->
                                    <div class="row py-1 border-bottom" v-if="task.job.customer_contact">
                                        <div class="col-4">Customer Contact</div>
                                        <div class="col">
                                            <span>
                                                {{ task.job.customer_contact.full_name }}<br>
                                                O: <a href="tel:">{{ task.job.customer_contact.office_phone }}</a><br>
                                                M: <a href="tel:">{{ task.job.customer_contact.mobile_phone }}</a><br>
                                                E: <a href="mailto:">{{ task.job.customer_contact.email }}</a>
                                            </span>
                                        </div>
                                    </div>
                                    <!-- Job name  -->
                                    <div class="row py-1 border-bottom"> 
                                        <div class="col-4">Job</div>
                                        <div class="col">{{ task.job.job_name }}</div>
                                    </div>
                                    <!-- Location -->
                                    <div class="row py-1 border-bottom d-flex align-items-center">
                                        <div class="col-4">Location</div>
                                        <div class="col pointer" @click="openMap(task.job)">
                                            <div>
                                                {{ task.job.address_1 }},
                                                {{ task.job.city }},
                                                {{ task.job.state }},
                                                {{ task.job.postal_code }}
                                        </div>
                                    </div>
                                        <div class="col text-right pointer" @click="openMap(task.job)">
                                            <span class="text-muted">
                                                <i class="ri-road-map-line heading-title"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <!-- Job Notes -->
                                    <div class="row py-1 border-bottom">
                                        <div class="col-4">Job Notes</div>
                                        <div class="col">
                                            <div v-for="note in task.job.job_notes" :key="note.id">
                                                * {{ note.notes }}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Job Materials -->
                                    <div class="row py-1 border-bottom" v-if="task.job.job_materials">
                                        <div class="col-4">Job Materials</div>
                                        <div class="col">
                                            <div v-for="(item, idx) in task.job.job_materials" :key="item.id">
                                                <span class="text-muted">   
                                                    {{ idx+1 }}) 
                                                </span>
                                                <span>
                                                    {{ item.material }} ({{ item.quantity }} {{ item.unit }})
                                                </span>
                                                <span v-if="item.location">
                                                    &nbsp;&nbsp;
                                                    <span class="text-muted">Location:&nbsp;</span>
                                                    <span>
                                                        {{ item.location }}
                                                    </span>
                                                </span>
                                                <span v-if="item.notes">
                                                    &nbsp;&nbsp;
                                                    <span class="text-muted">Notes:&nbsp;</span>
                                                    <span>           
                                                        {{ item.notes }}
                                                    </span>
                                                </span>

                                            </div>
                                        </div>
                                    </div>
                                    <!-- Task Materials -->
                                    <div class="row py-1 border-bottom" v-if="task.job.job_materials">
                                        <div class="col-4">Task Materials</div>
                                        <div class="col">
                                            <div v-for="(item, idx) in task.task_materials" :key="item.id">
                                                <span class="text-muted">   
                                                    {{ idx+1 }}) 
                                                </span>
                                                <span>   
                                                    {{ item.material }} ({{ item.quantity }} {{ item.unit }})
                                                </span>
                                                <span v-if="item.location">
                                                    &nbsp;&nbsp;
                                                    <span class="text-muted">Location:&nbsp;</span>
                                                    <span>
                                                        {{ item.location }}
                                                    </span>
                                                </span>
                                                <span v-if="item.notes">
                                                    &nbsp;&nbsp;
                                                    <span class="text-muted">Notes:&nbsp;</span>
                                                    <span>
                                                        {{ item.notes }}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Crew -->
                                    <div class="row py-1 border-bottom">
                                        <div class="col-4">Crew</div>
                                        <div class="col">
                                            <span v-for="user in task.assigned_to" :key="user.assigned_to_user_id">
                                                <!-- Task Manager -->
                                                <span v-if="user.task_manager == '1'" class="sp-tooltip">
                                                    <img :src="user.user.picture" class="p-0 avatar-img rounded-circle" :class="{'sp-portrait-border': user.task_manager == '1'}" style="height: 30px; width: 30px" />
                                                    <span class="sp-tooltiptext text-sm">{{ user.user.name }}</span>
                                                </span>
                                                <!-- Crew Member -->
                                                <span v-else class="sp-tooltip">
                                                    <img :src="user.user.picture" class="p-0 avatar-img rounded-circle" style="height: 30px; width: 30px" />
                                                    <span class="sp-tooltiptext text-sm">{{ user.user.name }}</span>
                                            </span>
                                            </span>
                                        </div>
                                    </div>
                                    <!-- Task description -->
                                    <div class="row py-1 border-bottom" v-if="task.description">
                                        <div class="col-4">Additional Notes</div>
                                        <div class="col">
                                            * {{ task.description }}
                                        </div>
                                    </div>

                                    <!-- Compensation -->
                                    <div class="row py-1 form-group border-bottom">
                                        <div class="col-4">Compensation Type</div>
                                        <div class="col">
                                            <!-- <span v-if="task.piece_rate"> -->
                                            <span v-if="task.pay_type != 'Hourly'">
                                                Piece Rate 
                                                {{ task.piece_rate | toCurrency}}
                                            </span>
                                            <span v-else>
                                                Hourly
                                            </span>
                                            <!-- Fuel and other pay | Wait until know this is needed -->
                                            <!-- <div> -->
                                                <!-- Fuel -->
                                                <!-- <span v-if="task.fuel">
                                                    <span class="text-muted text-sm">Fuel: </span>
                                                    <span>{{ task.fuel | toCurrency }}</span>
                                                </span>
                                                &nbsp;&nbsp; -->
                                                <!-- Other -->
                                                <!-- <span v-if="task.other">
                                                    <span class="text-muted text-sm">Other: </span>
                                                    <span>{{ task.other | toCurrency }}</span>
                                                </span> -->
                                            <!-- </div> -->
                                        </div>
                                    </div>

                                    <!-- Time Log -->
                                    <div class="form-group" v-if="showOnlyTimeLogged(task).length && currentUser.pay_type != '1099'">
                                        <ul class="list-group">
                                            <li class="list-group-item bg-gray-600 text-white p-1">
                                                <div class="row">
                                                    <div class="col">User</div>
                                                    <div class="col">Total Time</div>
                                                </div>
                                            </li>
                                            <!-- Loop each users time log -->
                                            <li class="list-group-item p-1" v-for="(user, idx) in showOnlyTimeLogged(task)" :key="idx">
                                                <div class="row">
                                                    <div class="col">{{ user.user.name }}</div>
                                                    <div class="col">
                                                        <span v-if="user.user.timeLog">
                                                            <span v-if="user.userClockStatus == 0" class="text-danger">
                                                                No time logged
                                                            </span>
                                                            <span v-if="user.userClockStatus == 1">
                                                                {{ user.user.timeLog.total_seconds | formatSeconds2Time}}
                                                            </span>
                                                            <span v-if="user.userClockStatus == 2" class="text-primary">
                                                                Clocked In 
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                            <!-- Time Log options -->
                                            <li class="list-group-item p-1 text-center" v-if="currentUser.id === task.taskManagerUserId && !task.timeCardsOpen && task.hasTimeLogged">
                                                <router-link class="btn btn-outline-primary"
                                                    :to="{name:'fieldTaskCloseAllocate', params:{task: task}}" v-if="task.piece_rate">    
                                                    <!-- View Allocations -->
                                                    <span v-if="task.task_status_id == 2">
                                                        View Piece Rate Allocations
                                                    </span>
                                                    <!-- Allocate piece rate -->
                                                    <span v-else>
                                                        Close Task and Allocate Piece Rate
                                                    </span>
                                                </router-link>     

                                                <!-- Close Task - Hourly Task -->
                                                <span v-else>
                                                    <button type="button"
                                                        class="btn btn-outline-primary" 
                                                        @click="closeTask(task)" 
                                                        v-if="displayCloseBtn(task)">
                                                        <span>Close Task</span>
                                                    </button>
                                                </span>
                                            </li>

                                        </ul>
                                    </div>

                                    <!-- Action Buttons -->
                                    <div class="text-center">
                                        <span v-if="task.task_status_id == 1 && currentUser.pay_type != '1099'">
                                            <!-- Clock Out -->
                                            <button type="button" 
                                                v-if="userClockedIn && clockedInTaskId == task.id"
                                                class="btn btn-danger btn-justified mb-2"
                                                @click="confirmClockOut">
                                                Clock Out
                                            </button>
                                            <!-- Clock In -->
                                            <button type="button"
                                                v-else
                                                :disabled="userClockedIn && clockedInTaskId != task.id"
                                                class="btn btn-success btn-justified mb-2"
                                                @click="clockIn(task)">
                                                Clock In
                                            </button>
                                        </span>                                        
                                        &nbsp;
                                        <span v-if="currentUser.id === task.taskManagerUserId">
                                            <!-- Add Notes and images -->
                                            <button type="button" 
                                                class="btn btn-outline-success btn-justified mb-2"
                                                @click="addNotes(task)">
                                                Add Notes and Images
                                            </button>
                                            <!-- View Notes and Images -->
                                            <button type="button"
                                                v-if="task.task_notes_count"
                                                class="btn btn-outline-primary mb-2"
                                                @click="displayNotes(task)">
                                                View Notes/Images ({{ task.task_notes_count }})
                                            </button>
                                        </span>
                                    </div>

                                    <!-- 1099 -->
                                    <div class="mt-2 text-center" v-if="currentUser.pay_type == '1099'">
                                        <button type="button" 
                                        class="btn btn-info" 
                                        v-if="task.task_status_id == 1"
                                        @click="closeTask1099Confirm(task)">
                                            Mark Task Complete and Submit for Payment
                                        </button>
                                    </div>
                                </div>
                                <!-- end card body -->
                            </div>
                            <!-- end card -->
                        </div>
                        <!-- end column -->
                    </div>
                    <div v-else>
                        <h3 class="text-center">No tasks to display</h3>
                    </div>
                </div>
            </div>

            <!-- Upcoming tasks -->
            <div class="card">
                <div class="card-header">
                    <h3 class="mb-0">{{ $t('label.upcoming tasks') }}</h3>
                </div>
                <div class="card-body pt-1">
                    <div v-if="futureTasks.length">
                        <div class="row border-bottom py-2 text-bold bg-gray-600 text-white d-sm-flex d-none">
                            <div class="col-2">Date</div>
                            <div class="col-3">Task</div>
                            <div class="col-3">Notes</div>
                            <div class="col-3">Crew</div>
                        </div>
                        <div class="row border-bottom py-2" 
                            v-for="(task, idx) in futureTasks" 
                            :key="task.id" 
                            :class="{'bg-gray-100': idx%2}">
                            <div class="col-sm-2">
                                <span class="d-sm-none">Date:&nbsp;</span>
                                {{ $spDisplayDateRange(task.start_date, task.due_date) }}
                            </div>
                            <div class="col-sm-3">
                                <span class="d-sm-none">Task: </span>
                                {{ task.name }}
                            </div>
                            <div class="col-sm-3">
                                <span class="d-sm-none">Notes:&nbsp;</span>
                                {{ task.description }}                                
                            </div>
                            <div class="col-sm-3">
                                <span class="d-sm-none">Crew:&nbsp;</span>
                                <span v-for="user in task.assigned_to" :key="user.assigned_to_user_id">
                                    <!-- Task Manager -->
                                    <span v-if="user.task_manager == '1'" class="sp-tooltip">
                                        <img :src="user.user.picture" class="p-0 avatar-img rounded-circle" :class="{'sp-portrait-border': user.task_manager == '1'}" style="height: 30px; width: 30px" />
                                        <span class="sp-tooltiptext text-sm">{{ user.user.name }}</span>
                                </span>
                                    <!-- Crew Member -->
                                    <span v-else class="sp-tooltip">
                                        <img :src="user.user.picture" class="p-0 avatar-img rounded-circle" style="height: 30px; width: 30px" />
                                        <span class="sp-tooltiptext text-sm">{{ user.user.name }}</span>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <h3 class="text-center">No future tasks to display</h3>
                    </div>
                </div>

            </div>
        </div>

        <!-- Clock out conformation modal -->
        <modal name="clockOutConfirmation"            
            :reset="true"
            :adaptive="true">
            <div class="container-fluid text-center">
                <div class="py-4">
                    <h3>Are you sure you want to clock out?</h3>
                    <div>
                        <button type="button" class="btn btn-outline-primary" @click="$modal.hide('clockOutConfirmation')">Cancel</button>
                        <button type="button" class="btn btn-danger" @click="clockOut">Clock Out</button>
    </div>
                </div>
            </div>
        </modal>

        <!-- Add images and notes modal -->
        <modal name="fieldAddNotes"
            height="auto"
            width="98%"
            :max-width="800"
            :reset="true"
            :scrollable="true"
            :adaptive="true">
                <field-add-notes-images
                    :task="selectedTask" 
                    @cancelFnc="$modal.hide('fieldAddNotes')"
                    @returnFnc="noteImagesReturn" />
        </modal>

        <!-- Display/manage the tasks notes and images -->
        <modal name="displayNotesImages"
            height="auto"
            width="98%"
            :max-width="800"
            :reset="true"
            :scrollable="true"
            :adaptive="true">
                <field-display-task-notes-images
                    :task="selectedTask" 
                    :officeUser="false"
                    @cancelFnc="$modal.hide('displayNotesImages')"
                    @returnFnc="noteImagesReturn" />
        </modal>

        <!-- 1099 close task -->
        <modal name="displayCloseConfirmation"
            height="auto"
            width="98%"
            :max-width="600"
            :reset="true"
            :scrollable="true"
            :adaptive="true">
            <div class="card mb-0">
                <div class="card-header">
                    Task Close Confirmation
                </div>
                <div class="body text-center pb-3">
                    <h3 class="p-3">As an Independent Contractor, I certify the work performed is complete as per the instructions given. </h3>
                    <button type="button" class="btn btn-outline-default" @click="$modal.hide('displayCloseConfirmation');selectedTask = null">Cancel</button>
                    <button type="button" class="btn btn-primary" @click="closeTask1099()">Agree</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex'
import FieldAddNotesImages from '@/components/Field/FieldAddNotesImages'
import FieldDisplayTaskNotesImages from '@/components/Field/FieldDisplayTaskNotesImages'

export default {
    props: [],
    components: {FieldAddNotesImages,FieldDisplayTaskNotesImages},
    emits: [],
    data() {
        return {
            currentUser: JSON.parse(localStorage.getItem('user')),
            userClockedIn: false,         
            clockedInTaskId: 0,   
            selectedTask:null,
            todaysTasks: [],
            futureTasks:[]
        }
    },
    computed: {
        ...mapGetters({
            coordinates: 'coordinates',
            userClockin: 'time/userClockin', 
        }),
        },
    mounted() {
        this.loadTaskList();

        bus.$on('refreshFieldTasks', this.refreshFieldTasks);
    },
    beforeDestroy() {
      bus.$off('refreshFieldTasks', this.refreshFieldTasks);
    },
    methods: {
        loadTaskList() {
            // Get all user tasks for today and future
            axios
                .get('getFieldUserTasks')
                .then(response => {
                    this.todaysTasks = response.data.todaysTasks
                    this.futureTasks = response.data.futureTasks
                    // return
                    this.todaysTasks.forEach(task => {
                        task.show = true
                        task.assigned_to.forEach(user => {
                            if(parseInt(user.task_manager) == 1){
                                task.taskManagerUserId = user.assigned_to_user_id
                            }
                })
                    })
                    
                    this.getTimePunches()
                    this.$store.dispatch('time/getUserCurrentClockin').then(()=>{
                        if(this.userClockin){
                            this.userClockedIn = true
                            this.clockedInTaskId = this.userClockin.task_id
                        }
                    })
                })
                .catch(err => {
                    console.log('ERROR: ', err)
                    bus.$emit('banner-error', { message: err, })
                })
        },
        openMap(location) {
            // Open address in a new window in google maps
            let complete_address = location.address_1 + ", " + location.city + ", " + location.state + ", " + location.postal_code;
            window.open('http://maps.google.com/?q=' + complete_address);
        },
        clockIn (task){            
            let postData = {
                task_id: task.id,
                user_id: this.currentUser.id,
                login_location: this.coordinates
            }
            this.$store.dispatch('time/timeIn', postData)
                .then(response => {
                    this.userClockedIn = true
                    this.clockedInTaskId = task.id
                    bus.$emit('banner-success', {message: 'Clocked In!'})
                    // Update vuex user clock
                    this.$store.dispatch('time/getUserCurrentClockin').then((response)=>{
                        this.getTimePunches()                        
                    })                    
                })
                .catch(err => {
                    console.log('ERROR: ', err.response.data)
                    bus.$emit('banner-error', { message: err.response.data,})
                })
        },
        confirmClockOut (){            
            this.$modal.show('clockOutConfirmation')
        },
        clockOut (){   
            this.$modal.hide('clockOutConfirmation')         
            var request_fields = {
                'time_id' : this.userClockin.id,
                'logout_location': this.coordinates,
            };

            this.$store.dispatch('time/timeOut', request_fields).then(res => {
                bus.$emit('banner-succes', {
                    message: this.$t('label.you were successfully clocked out') + '.'
                })

                this.userClockedIn = false
                this.clockedInTaskId = 0

                // update vuex user clock
                this.$store.dispatch('time/getUserCurrentClockin')
                this.getTimePunches()
            }).catch(err => {
                console.log('Clock Out Error:', err)
                bus.$emit('banner-error', { message: err,})
            })
        },
        getTimePunches (){            
            let x = 0
            this.todaysTasks.forEach((task, idx) => {      
                task.hasTimeLogged = false
                let taskId = task.id 
                // Loop each crew member 
                task.assigned_to.forEach((user, idx) => {
                    axios
                        .post('user-time-punches', {task_id: taskId, user_id: user.assigned_to_user_id})
                        .then(response => {
                            if(response.data.result.length>0){
                                task.hasTimeLogged = true
                            }
                            user.user.timeLog = response.data
                            // make object reactive
                            Vue.set(task.assigned_to, idx, user)
                        })
                        .catch(err => {
                            console.log('ERROR: ', err)
                            bus.$emit('banner-error', { message: err,})
                        })
                })

            });

            // Refresh the time punches every 60 seconds
            setTimeout(() => {
                this.getTimePunches()
            }, 60000);
        },
        showOnlyTimeLogged(task){            
            // set a time card open var for displaying the close button
            task.timeCardsOpen = false
            // return users with time logged
            return task.assigned_to.filter(user => {
                let thisUserTimeLog = user.user.timeLog
                if(thisUserTimeLog){
                    // make sure user has time and current user is the task manager or time belongs to the current user
                    if( this.currentUser.id === task.taskManagerUserId || user.user.id === this.currentUser.id ){
                        // User has no time logged
                        user.userClockStatus = 0
                        if(thisUserTimeLog.result[0]){
                            // user is clocked out
                            if(thisUserTimeLog.result[0].time_out){
                                user.userClockStatus = 1
                            }
                            //user is clocked in
                            else{
                                task.timeCardsOpen = true
                                user.userClockStatus = 2
                            }
                        }
                        return user
                    }
                }
            })
        },
        addNotes (task){
            this.selectedTask = task
            this.$modal.show('fieldAddNotes')
        },
        noteImagesReturn (){
            this.$modal.hide('fieldAddNotes')
            this.$modal.hide('displayNotesImages')
            this.reloadMessageCount()
        },
        displayNotes (task){
            this.selectedTask = task
            this.$modal.show('displayNotesImages')
        },
        reloadMessageCount (){
            axios
            .post('refreshTaskNoteCount', {taskId: this.selectedTask.id})
            .then(response => {
                let idx = this.todaysTasks.findIndex(task => {
                    if(task.id === this.selectedTask.id) return true                
                });    

                if(idx >= 0){
                    this.todaysTasks[idx].task_notes_count = response.data       
                }
            })
            .catch(err => {
                console.log('ERROR: ', err)
                bus.$emit('banner-error', { message: err,})
            })
        },
        toggleShowTask (task){
            task.show = !task.show
            let idx = this.todaysTasks.findIndex(item => {
                if(item.id === task.id) return true
            })
            this.$set(this.todaysTasks, idx, task);
        },
        closeTask1099Confirm (task){
            this.selectedTask = task
            this.$modal.show('displayCloseConfirmation')
        },
        closeTask1099 () {
            // console.log(this.selectedTask)
            this.selectedTask.assigned_to[0].pieceRatePercentage = 100
            this.selectedTask.assigned_to[0].pieceRateAmount = this.selectedTask.piece_rate

            let promises = []
            // Close the selected task
            promises.push(axios.get(`closeTask/${this.selectedTask.id}}`))

            //Save piece rate amount to user in allocations
            promises.push(axios.post('savePieceRateAllocation', {
                users: this.selectedTask.assigned_to,
                taskDate: moment(this.selectedTask.start_date).format('YYYY-MM-DD'),
                payType: '1099',
            }))

            Promise.all(promises).then(response => {
                // reload the task list
                this.loadTaskList ()
                this.$modal.hide('displayCloseConfirmation')                
                bus.$emit('banner-success', {
                    message: 'Task Marked as Completed!'
                })                
            })
            .catch(err => {
                console.log('ERROR: ', err)
                bus.$emit('banner-error', { message: err,})
            })
        },
        closeTask (task){
            // Close the selected task
            axios.get(`closeTask/${task.id}}`).then(response=>{
                bus.$emit('banner-success', {
                    message: 'Task Marked as Completed!'
                })
                this.loadTaskList ()
            })
        },
        displayCloseBtn(task){
            //Only display the close task if the current date matches the task end date and the task status is open
            let today = moment()
            let taskEndDate = moment(task.due_date)

            if(task.task_status_id == 1 && taskEndDate.diff(today,'days')<=0){
                return true
            }
            else{
                return false
            }
        },
        refreshFieldTasks() {
            this.loadTaskList();
        }
    }
}
</script>

<style></style>