<template>
	<div>
		<!-- <div class="modal fade" id="paymentViewModal" tabindex="-1" role="dialog" aria-labelledby="paymentViewModalTitle" aria-hidden="true">
			<div class="modal-dialog" role="document" style="max-width: 1200px"> -->
				<div class="modal-content">
					<div class="modal-header border-bottom">
						<h5 class="modal-title" id="paymentViewModalTitle">
							Payment ID - {{ payment.payment_number ? payment.payment_number.payment_number : '' }}
						</h5>
						<button type="button" class="close" @click="$emit('cancelFnc')">
							<i class="ri-close-line"></i>
						</button>
					</div>
					<div class="modal-body">
						<div class="row m-0">
							<div class="col-8 pdf-preview">
								<div class="row m-0">
									<div class="col-6 p-0">
										<img class="" :src="companySettings.logo"  style="height: 100px; object-fit: cover;" />
										<p>{{ companySettings.name }}<br>
										{{ companySettings.address_1 }} <br>
										{{ companySettings.address_2 }} <br v-if="companySettings.address_2">
										{{ companySettings.city }}, {{ companySettings.state }} {{ companySettings.postal_code }}</p>
									</div>
									<div class="col-6 p-0 text-right">
										<h1>Receipt</h1>
										<h3>Payment ID - {{ payment.payment_number ? payment.payment_number.payment_number : '' }}</h3>
									</div>
								</div>
								<div class="row m-0">
									<div class="col-6 p-0">
										<label class="form-control-label">Charged To:</label>
										<p>{{ job.customer.name }}<br>
										{{ job.customer.address_1 }} <br>
										{{ job.customer.address_2 }} <br v-if="job.customer.address_2">
										{{ job.customer.city }}, {{ job.customer.state }} {{ job.customer.postal_code }}</p>
									</div>
									<div class="col-6 p-0 text-right">
										<label class="form-control-label">Payment Date:</label>
										<p>{{ payment.created_at }}</p>
									</div>
								</div>
								<div class="row m-0">
									<table class="table table-flush table-sm">
										<thead class="thead-light">
											<tr v-if="companySettings.settings.invoice">
												<th scope="col" width="20%">Invoice</th>
												<th scope="col">Description</th>
												<th scope="col" class="text-right" width="20%">Charge Amount</th>
											</tr>
											<tr v-else>
												<th scope="col" width="40%">Payment Note</th>
												<th scope="col" width="40%">Description</th>
												<th scope="col" class="text-right" width="20%">Charge Amount</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>{{ payment.invoice_number_concat }}</td>
												<td>{{ payment.invoice_item_list_concat }}</td>
												<td class="text-right">${{ parseFloat(payment.amount).toFixed(2) | formatThousands }}</td>
											</tr>
										</tbody>
										<tbody class="border-top">
											<tr>
												<td class="align-top pt-4" colspan="3">
													<i>Thank you for your business.</i>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="row m-0 page-footer">
									<div class="col-6">{{ companySettings.website }}</div>
									<div class="col-6 text-right">Page 1 of 1</div>
								</div>
							</div>
							<div class="col-4 p-0">
								<div class="row m-0">
									<div class="col-12 mb-3">
										<button type="button" class="btn btn-outline-primary w-100 text-uppercase" data-toggle="modal" data-dismiss="modal" data-target="#paymentSendModal" @click="paymentSendModal(payment)">
											<i class="ri-mail-send-line"></i> Send Receipt
										</button>
									</div>
									<div class="col-12 mb-3">
										<a :href="'/payment-download/'+payment.id" target="_blank" class="btn btn-outline-primary w-100 text-uppercase">
											<i class="ri-download-line"></i> Download Receipt
										</a>
									</div>
									<div class="col-12 mb-3">
										<a :href="'/payment-print/'+payment.id" target="_blank" class="btn btn-outline-primary w-100 text-uppercase">
											<i class="ri-printer-line"></i> Print Receipt
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer border-top">
						<button type="button" class="btn btn-link  ml-auto close2addpayment" @click="$emit('cancelFnc')">{{ $t('label.close') }}</button>
					</div>
				</div>
			<!-- </div>
		</div> -->
	</div>
</template>

<script>
	import bus from '@/utils/event-bus';
    export default {
		props: ['job','source','paymentData'],
		emits: ['cancelFnc'],
		data() {
			return {
				user: JSON.parse(localStorage.getItem('user')),
				companySettings: JSON.parse(localStorage.getItem('company_settings')),
				payment: {
					payment_number_concat: '',
					due_date: null,
					invoice_item_list_concat: null,
				},
			}
		},
		created() {
			bus.$on('paymentView', (data) => {
				this.paymentViewModal(data);
			})
		},
        mounted() {
			// var that = this;
			// $('#paymentViewModal').on('shown.bs.modal', function (e) {
				
			// })
			// $('#paymentViewModal').on('hidden.bs.modal', function (e) {
			// 	console.log('CLOOOOOOOOOOOSE')
			// })
			bus.$on('deletePayment'+this.newid, () => {
                this.deletePayment();
            });

			this.loadLkupData(this.paymentData)
		},
		methods: {
			async loadLkupData(item) {
				this.loader = this.$loading.show();
				await this.paymentViewModal(item);
				this.loader.hide();
			},
			paymentViewModal(item) {
				this.payment = item.data;
				console.log('this.payment',this.payment)
				console.log('item',item)
			},
			// paymentSendModal(data) {
			// 	console.log('data',data)
			// 	// this.source = '';
			// 	this.payment = data;
			// 	this.openModal = true;

			// 	setTimeout(function() {
			// 		bus.$emit('paymentSend', { 
			// 			data: data,
			// 		});
			// 	}, 1000)
			// },
			paymentSendModal(data) {
				this.$emit('cancelFnc');
                this.$modal.show('paymentSendModal');
			},
		}
    }
</script>
<style scoped>
	.pdf-preview {
		border: 1px solid #8898aa;
		border-radius: 10px;
		padding: 1.5rem;
		height: 842px;
	}
	#paymentViewModal p {
		line-height: 1.4;
	}
	.page-footer {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 20px;
	}

</style>