<template>
    <div class="container-fluid">
        <div class="mt-4 row">
            <div class="col-10">
                <h5 class="modal-title">{{ $t('label.confirm') }} <span class="text-success">{{ $t('label.approval') }}</span> {{ $t('label.of request for') }} {{ getEmployeeName(item.user_id) }} </h5>
            </div>
            <div class="col-2 text-right">
                <button type="button" class="close" @click="$modal.hide('requestApproveModal')"><span>&times;</span>
                </button>
            </div>
        </div>
        <div class="mt-4">
            <label for="notes">{{ $t('label.additional notes') }} <small class="text-muted">(Optional)</small></label>
            <textarea id="notes" class="form-control" v-model="statusNotes"></textarea>
        </div>
        <div class="mt-4 mb-4">
            <div class="row">
                <div class="col-sm-6">
                    <button type="button" class="btn btn-success" @click="submitApproval()">
                        {{ $t('label.confirm') }}
                        {{ $t('label.approval') }}
                    </button>
                </div>
                <div class="col-sm-6 text-right">
                    <button type="button" class="btn btn-secondary" @click="$modal.hide('requestApproveModal')">
                        {{ $t('label.cancel') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import bus from '@/utils/event-bus';
import { createNamespacedHelpers } from 'vuex';
;
const { mapState, mapActions } = createNamespacedHelpers('userData')

export default {
    props: ['item'],
    emit: ['returnChanges'],
    data() {
        return {
            statusNotes: null
        }
    },
    mounted() {
        console.log('Approve this', this.item)
    },
    computed: {
        ...mapState({
            allUsers: state => state.allUsers,
        }),
    },
    methods: {
        getEmployeeName(userId) {
            let companyUsers = this.allUsers;
            let idx = companyUsers.findIndex(request => {
                if (request.id === userId) {
                    return true;
                }
            });
            return (idx >= 0) ? companyUsers[idx].name : 'N/A';
        },
        submitApproval() {
            axios
                .post(`/approveTimeOffRequest`, { id: this.item.id, managerNotes: this.statusNotes })
                .then(response => {
                    this.$modal.hide('requestApproveModal');
                    bus.$emit('banner-success', {
                        message: 'Request Approved!',
                    });

                    //Pass back the status change data
                    this.$emit('returnChanges', response.data);
                })
                .catch(err => {
                    // catch laravel validation errors
                    if (err.response) {
                        if (err.response.status === 422) {
                            this.formErrors = err.response.data.errors;
                        }
                    }
                    else {
                        console.error('ERROR: ', err);
                    }
                });
        },
    }
}
</script>