<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50" id="companySettingsContainer">
            <!-- Page Header -->
            <div class="d-flex" style="z-index: 100">
                <!-- Breadcrumbs -->
                <breadcrumbs :title="$t('label.company settings')">
                    <!-- Breakcrumb slots -->
                    <li class="breadcrumb-item">
                        {{ $t('label.admin') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.settings') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.company') }}
                    </li>
                </breadcrumbs>
            </div>

            <!-- Page Body -->
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <div class="card">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">{{ $t('label.company settings') }}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="form-control-label" for="input-name">{{ $t('label.company name') }}</label>&nbsp;
                                                <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                                                <input v-model="$v.form.name.$model" type="text" name="name" id="input-name" class="form-control" :class="{ 'is-invalid': $v.form.name.$error }" placeholder="Name" required autofocus>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="form-control-label" for="input-phone">{{ $t('label.company phone') }}</label>&nbsp;
                                                <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                                                <input v-model="$v.form.phone.$model" type="text" name="phone" id="input-phone" class="form-control phone-validate" :class="{ 'is-invalid': $v.form.phone.$error }" placeholder="Phone" required autofocus>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.address') }} 1</label>&nbsp;
                                                <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                                                <input v-model="$v.form.address_1.$model" type="text" name="address_1" class="form-control" :class="{ 'is-invalid': $v.form.address_1.$error }" placeholder="Address 1" required autofocus>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.address') }} 2</label>
                                                <input v-model="form.address_2" type="text" name="address_2" class="form-control" placeholder="Address 2" autofocus>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.city') }}</label>&nbsp;
                                                <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                                                <input v-model="$v.form.city.$model" type="text" name="city" class="form-control" :class="{ 'is-invalid': $v.form.city.$error }" placeholder="City" required autofocus>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.zip') }}</label>&nbsp;
                                                <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                                                <input v-model="$v.form.postal_code.$model" type="text" name="postal_code" class="form-control" :class="{ 'is-invalid': $v.form.postal_code.$error }" placeholder="Zip" required autofocus>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.state') }}</label>&nbsp;
                                                <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                                                <select v-model="$v.form.state.$model" name="state" class="form-control" :class="{ 'is-invalid': $v.form.state.$error }" required>
                                                    <option :value="null" selected>- {{ $t('label.select a state') }} -</option>
                                                    <option v-for="(data, index) in states" :value="data" :key="index">{{ index }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.country') }}</label>&nbsp;
                                                <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                                                <select v-model="$v.form.country.$model" name="country" class="form-control" :class="{ 'is-invalid': $v.form.country.$error }" required>
                                                    <option :value="null" selected>- {{ $t('label.select a country') }} -</option>
                                                    <option v-for="(data, index) in countries" :value="index" :key="index">{{ data }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <!-- <div class="col-6">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">Timezone</label>&nbsp;
                                                <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                                                <select v-model="$v.form.timezone.$model" name="timezone" class="form-control" :class="{ 'is-invalid': $v.form.timezone.$error }" required>
                                                    <option :value="null" selected>- Select a Timezone -</option>
                                                    <option v-for="(data, index) in timeZoneData" :value="`${data.offset}-${data.name}`" :key="index">({{ data.offset }}) - {{ data.name }}</option>
                                                </select>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="form-control-label" for="input-name">{{ $t('label.company logo') }}</label>
                                                <input type="file" ref="file" accept="image/png, image/jpeg" @change="updateLogo" name="logo" id="input-name" class="form-control" placeholder="Logo Photo" autofocus style="display: none">

                                                <div v-if="!form.logo">
                                                    <div @click="$refs.file.click()" class="cursor-pointer text-center" style="border: 3px #dee2e6 dashed; background-color: #fafcfd; height: 100px; display: flex; align-items: center; justify-content: center;">
                                                        <div class="" style="">
                                                            <button type="button" class="btn btn-primary mr-auto text-uppercase">{{ $t('label.browse files') }}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="form.logo">
                                                    <div class="text-center">
                                                        <img :src="form.logo" alt="" style="height: 100px; object-fit: cover;" />
                                                        <div class="mt-3">
                                                            <button @click="$refs.file.click()" type="button" class="btn btn-outline-primary text-uppercase"><i class="ri-upload-2-line"></i> {{ $t('label.replace logo') }}</button>
                                                            <button @click="removeLogo()" type="button" class="btn btn-outline-danger text-uppercase"><i class="ri-delete-bin-line"></i> {{ $t('label.remove logo') }}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="website">{{ $t('label.company website url') }}</label>
                                                <input v-model="form.website" pattern="^(?:https?://|s?ftps?://)?(?!www | www\.)[A-Za-z0-9_-]+\.+[A-Za-z0-9.\/%&=\?_:;-]+$" name="website" class="form-control" placeholder="https://example.com" autofocus>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Save Footer -->
                    <div class="text-center">
                        <button type="button" @click="saveSettings()" class="btn btn-primary">{{ $t('label.save settings') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TimeZoneData from '@/assets/timezones.json';
import bus from '@/utils/event-bus';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
    props: [],
    components: {},
    data() {
        return {
            loader: null,
            form: {
                name: null,
                phone: null,
                address_1: null,
                address_2: null,
                city: null,
                postal_code: null,
                state: null,
                country: null,
                // timezone: null,
                logo: '',
                website: null
            },
            logoFile: null,
            timeZoneData: TimeZoneData
        }
    },
    validations: {
        form: {
            name: { required },
            phone: { required },
            address_1: { required },
            city: { required },
            postal_code: { required },
            state: { required },
            country: { required },
            // timezone: { required }
        }
    },
    computed: {
        ...mapGetters(['company', 'states', 'countries']),
    },
    created() {
        this.loader = this.$loading.show();
        let promises = [
            this.$store.dispatch('getStates'),
            this.$store.dispatch('getCountries'),
            this.$store.dispatch('getCompanySettings')
        ];

        Promise.all(promises).then(() => {
            this.initForm();
            this.loader.hide();
        });
    },
    methods: {
        initForm() {
            // Set form values
            for (const key in this.company)
                if (key in this.form)
                    this.form[key] = this.company[key];

            // if (this.company && this.company.settings && this.company.settings.timezone) {
            //     this.form.timezone = this.company.settings.timezone;
            // }
        },

        saveSettings() {
            // Validate form then save settings.
            this.$v.$touch();
            if (!this.$v.$invalid) {
                let formTemp = JSON.parse(JSON.stringify(this.form)),
                    formData = new FormData();

                // Set form data with empty strings representing null
                if (this.form.website == null) formTemp.website = '';
                if (this.form.address_2 == null) formTemp.address_2 = '';

                formData.append('photo', this.logoFile ? this.logoFile : null);
                formData.append('company_id', this.company.id);

                for (const key in formTemp)
                    formData.append(key, formTemp[key]);

                axios.post('/settings', formData).then(() => {
                    bus.$emit('banner-success', {
                        message: this.$t('label.company settings saved successfully')
                    });

                    // Update local storage company settings
                    axios.get(`/get-company-settings`).then(rsp => {
                        if (rsp.data.result)
                            localStorage.setItem('company_settings', JSON.stringify(rsp.data.result));
                    }).catch(err => console.error(err));
                }).catch(err => console.log(err));
            }
        },

        updateLogo(event) {
            let file = event.target.files[0];
            this.form.logo = URL.createObjectURL(file);
            this.logoFile = file;
        },
        removeLogo() {
            this.form.logo = '';
            this.logoFile = null;
        },
    }
}
</script>