<template>
    <div class="container-fluid">
        <!-- Empty Rows Pagination View -->
        <div v-if="allRows.length < 1" class="pt-4 row">
            <div class="col-6 pl-4">
                <span class="text-sm">
                    Showing 0 to 0 of 0 entries
                </span>
            </div>
            <div class="col-6 pr-4">
                <b-pagination v-model="currentPage" :align="'right'" :class="'z-index-1'" :next-class="'z-index-1'" :prev-class="'z-index-1'" :first-class="'z-index-1'" :hide-goto-end-buttons="true">
                    <template v-slot:next-text>
                        <i class="fa fa-angle-right"></i>
                    </template>
                    <template v-slot:prev-text>
                        <i class="fa fa-angle-left"></i>
                    </template>
                </b-pagination>
            </div>
        </div>

        <!-- Rows Pagination View -->
        <div v-else class="pt-4 row">
            <!-- Pages Information -->
            <div class="col-6 d-flex">
                <div class="mr-auto text-align-center text-sm pt-1">
                    Showing {{ this.showingCurrPage }} to {{ this.showingToPage }} of {{ this.allRows.length }} entries
                </div>
            </div>

            <!-- Page Navigation -->
            <div class="col-6 d-flex">
                <div class="ml-auto">
                    <b-pagination align="center" next-class="z-index-1" prev-class="z-index-1" page-class="z-index-1" :per-page="perPage" :total-rows="allRows.length" :hide-goto-end-buttons="true" :limit="buttonLimit" :size="buttonSize" v-model="currentPage" @change="onPageChanged">
                        <template v-slot:next-text>
                            <i class="bi bi-chevron-right"></i>
                        </template>
                        <template v-slot:prev-text>
                            <i class="bi bi-chevron-left"></i>
                        </template>
                    </b-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['allRows', 'perPage', 'btnLimit', 'btnSize'],
    data() {
        return {
            currentPage: 1,
            newPaginatedRows: [],
            buttonLimit: (this.btnLimit) ? this.btnLimit : 5,         // Set the number of buttons default is 5 
            buttonSize: (this.btnSize) ? `${this.btnSize}` : 'md',    // Set the size of the buttons default is 'md' but also accepts 'sm', 'lg'
        }
    },
    watch: {
        allRows() {
            if (this.allRows.length >= 1) {
                // Reprocess the pagination
                let pageNum = this.currentPage,
                    fullPages = this.allRows.length / this.perPage,
                    remainder = this.allRows.length % this.perPage;

                // Go back a page if current page becomes empty
                if (fullPages < pageNum && remainder <= 0)
                    --pageNum;

                this.paginate(this.perPage, pageNum - 1);
            } else {
                this.newPaginatedRows = this.allRows;
            }
        },
        newPaginatedRows() {
            this.$emit('input', this.newPaginatedRows);
        }
    },
    computed: {
        showingCurrPage() {
            if (this.allRows.length > 0)
                return (1 + (this.perPage * (this.currentPage - 1)));
            else
                return 0;
        },
        showingToPage() {
            let toVal = this.currentPage * this.perPage;
            if (toVal >= this.allRows.length)
                return this.allRows.length;
            else
                return toVal;
        },
    },
    mounted() {
        if (this.allRows.length >= 1)
            this.paginate(this.perPage, this.currentPage - 1);
    },
    methods: {
        paginate(pageSize, pageNum) {
            let entries = JSON.parse(JSON.stringify(this.allRows));
            this.newPaginatedRows = entries.slice(pageNum * pageSize, (pageNum + 1) * pageSize);
        },
        onPageChanged(page) {
            this.paginate(this.perPage, page - 1);
        },

    }
}
</script>
