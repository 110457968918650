<template>
    <div id="classification">
        <div class="card">
            <div class="card-header">
                <div class="align-items-center">
                    <h3 class="d-flex mb-0 align-items-center">
                        <i class="ri-file-info-line heading-title"></i>
                        {{ $t("label.classification") }}
                    </h3>
                </div>
            </div>
            <div class="card-body">
                <!-- Status -->
                <div class="form-group">
                    <label class="form-control-label text-bold" for="status">{{ $t("label.status") }}</label>
                    <span class="status-indicator" :class="classificationData.status.toLowerCase().replace(/ /g, '')"></span>
                    <select class="form-control" id="status" v-model="classificationData.status" @change="validateField('operatorId')">
                        <option v-for="status in assetStatuses" :value="status" :key="status">
                            {{ status }}
                        </option>
                    </select>
                </div>

                <!-- Operator -->
                <div class="form-group">
                    <label class="form-control-label text-bold" for="operator">{{ $t("label.operator") }}</label>
                    <span v-if="classificationData.status === 'Active'" class="text-danger text-xs"> * {{ $t('label.required') }}</span>
                    <select class="form-control" :class="{ 'border-danger': isValid.operatorId === false }" :placeholder="'- ' + $t('label.unassigned') + ' -'" id="operator" v-model="classificationData.operatorId" @change="validateField('operatorId')">
                        <option :value="null" selected>-&nbsp;{{ $t('label.unassigned') }}&nbsp;-</option>
                        <option v-for="operator in allUsers" :value="operator.id" :key="operator.id">
                            {{ operator.name }}
                        </option>
                    </select>
                </div>

                <!-- Ownership Type -->
                <div class="form-group">
                    <label class="form-control-label text-bold" for="ownership">{{ $t("label.ownership") }}</label>
                    <select class="form-control" id="ownership" v-model="classificationData.ownershipType">
                        <option v-for="ownership in ownershipTypes" :value="ownership" :key="ownership">
                            {{ ownership }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import bus from '@/utils/event-bus';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('userData')
export default {
    props: ["classificationData", "isValid"],
    data() {
        return {
            statuses: [],
            assetStatuses: [],
            ownershipTypes: [],
        }
    },
    computed: {
        // Get all company users from Vuex
        ...mapState({
            allUsers: state => state.allUsers,
        }),
    },
    mounted() {
        this.getAssetStatuses()
        this.getOwnershipTypes()

        // If vuex allusers hasn't been set then set it with vuex.
        if (!this.allUsers.length) {
            this.getAllUsers()
        }
    },
    methods: {
        ...mapActions(['getAllUsers']),
        getAssetStatuses() {
            axios
                .get('/get-asset-statuses')
                .then(response => {
                    this.assetStatuses = response.data
                })
                .catch(err => {
                    console.log('ERROR: ', err);
                    bus.$emit('banner-error', { message: err, });
                });
        },
        getOwnershipTypes() {
            axios
                .get('/get-ownership-list')
                .then(response => {
                    this.ownershipTypes = response.data
                })
                .catch(err => {
                    console.log('ERROR: ', err);
                    bus.$emit('banner-error', { message: err, });
                });
        },
        // Validate Necessary Fields for Parent validation
        validateField(field) {
            if (field == 'operatorId') {
                if (this.classificationData.status !== "Active")
                    this.isValid[field] = true;
                else {
                    if (this.classificationData.operatorId === null)
                        this.isValid[field] = false;
                    else
                        this.isValid[field] = true;
                }
            }
        }
    },
};
</script>
