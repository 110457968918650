<template>
    <!-- Delete Modal w/ Vue js Modal -->
    <modal :name="modalName" @closed="onClose()" :classes="'rounded'" :min-height="400" width="525px" :scrollable="true" height="auto" :reset="true" :adaptive="true">
        <div class="modal-danger" role="document">
            <div class="modal-content bg-gradient-danger">
                <div class="modal-header">
                    <button type="button" class="close" @click.stop="closeFunc()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="py-3 text-center">
                        <i class="ri-stop-circle-line ni-3x"></i>
                        <h4 class="heading mt-4 text-uppercase">{{ $t('label.ARE YOU SURE YOU WANT TO CLOCK-OUT') }} {{ user.name == username ? '' : username }}?</h4>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-white clockout" @click.stop="clockoutFunc()">{{ $t('label.Yes, Clock Out') }}</button>
                    <button type="button" class="btn btn-link text-white ml-auto clockout" @click.stop="closeFunc()">{{ $t('label.cancel') }}</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import bus from '@/utils/event-bus';
export default {
    props: ['username'],
    emits: ['customClockoutMethod', 'onClose'],
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            modalName: "clockoutModal"
        }
    },
    mounted() {
        this.$modal.show(this.modalName);
    },
    methods: {
        clockoutFunc() {
            this.$emit('customClockoutMethod');
            bus.$emit('checkClockinStatus', 'clockout');
            this.closeFunc();
        },
        closeFunc() {
            this.onClose();
            this.$modal.hide(this.modalName)
        },
        onClose() {
            this.$emit('onClose', false);
        }
    },
}
</script>
