import bus from '@/utils/event-bus.js'
import Vue from 'vue'

//Load user piece rate values
Vue.prototype.$spGetUserAllocations = function (taskId, taskUsers) {
    let theseAllocations = [],
        totalAllocatedAmount = 0,
        totalAllocatedPercentage = 0

    return axios
        .get(`/getUserTaskAllocation/${taskId}`)
        .then(response => {
            // console.log('getUserTaskAllocation',response.data)
            theseAllocations = response.data
            // Loop the assigned users and either match up the saved allocations or pre-set them
            taskUsers.forEach((user) => {
                let idx = theseAllocations.findIndex((elm) => {
                    if (elm.user_id === user.assigned_to_user_id)
                        return true
                })

                // Load the saved allocations
                if (idx >= 0) {
                    if (theseAllocations[idx].pay_type == '1099') {
                        user.payType = '1099'
                    }
                    else {
                        user.payType = (theseAllocations[idx].piece_rate_amount > 0) ? 'Piece Rate' : 'Hourly'
                    }
                    totalAllocatedAmount += theseAllocations[idx].piece_rate_amount
                    totalAllocatedPercentage += theseAllocations[idx].piece_rate_percentage

                    // Add saved notes
                    user.notes = theseAllocations[idx].notes
                    user.pieceRateAmount = (theseAllocations[idx].piece_rate_amount) ? parseFloat(theseAllocations[idx].piece_rate_amount).toFixed(2) : 0.00
                    user.pieceRatePercentage = (theseAllocations[idx].piece_rate_percentage) ? parseFloat(theseAllocations[idx].piece_rate_percentage).toFixed(2) : 0.00
                }
                // New Allocation
                else {
                    user.pieceRateAmount = parseFloat(0).toFixed(2)
                    user.pieceRatePercentage = parseFloat(0).toFixed(2)
                }
            })
            return { taskUsers: taskUsers, totalAllocatedAmount: totalAllocatedAmount, totalAllocatedPercentage: totalAllocatedPercentage }
        })
        .catch(err => {
            console.log('ERROR: ', err)
            bus.$emit('banner-error', { message: err, })
        })
}

// Validates each change to a users piece rate allocation to make sure
// they can't allocate more piece rate than assigned to the task
Vue.prototype.$spValidateAllocation = function (user, taskUsers, taskPieceRate, taskDate) {
    let allocatedAmount = 0,
        allocatedPercentage = 0,
        totalAllocatedAmount = 0,
        totalAllocatedPercentage = 0

    //Set null taskPieceRate = 0
    if (!taskPieceRate) {
        taskPieceRate = 0
    }

    //Loop the users to calculate total Piece Rate amount and percentage
    taskUsers.forEach((emp) => {
        emp.pieceRateAmount = parseFloat(emp.pieceRateAmount)
        emp.pieceRatePercentage = parseFloat(emp.pieceRatePercentage)

        //prevent out of bounds entries
        if (emp.pieceRatePercentage < 0) {
            emp.pieceRatePercentage = 0
        }
        else if (emp.pieceRatePercentage > 100) {
            emp.pieceRatePercentage = 100
        }

        if (emp.pieceRateAmount < 0) {
            emp.pieceRateAmount = 0
        }
        else if (emp.pieceRateAmount > taskPieceRate) {
            emp.pieceRateAmount = parseFloat(taskPieceRate)
        }

        allocatedAmount += emp.pieceRateAmount
        allocatedPercentage += emp.pieceRatePercentage

        emp.pieceRateAmount = emp.pieceRateAmount.toFixed(2)
        emp.pieceRatePercentage = emp.pieceRatePercentage.toFixed(2)
    })

    //make sure combined percentage is no more then 100%.
    var difference;
    if (allocatedPercentage > 100) {
        difference = (allocatedPercentage - 100)
        user.pieceRatePercentage = parseFloat(user.pieceRatePercentage - difference).toFixed(2)
        totalAllocatedPercentage = parseFloat(100).toFixed(2)
    }
    else {
        totalAllocatedPercentage = parseFloat(allocatedPercentage).toFixed(2)
    }

    //make sure combined dollar amount is no more than task piece rate amount.
    if (allocatedAmount > taskPieceRate) {
        difference = parseFloat(allocatedAmount - taskPieceRate).toFixed(2)
        user.pieceRateAmount = parseFloat(user.pieceRateAmount - difference).toFixed(2)
        totalAllocatedAmount = parseFloat(taskPieceRate).toFixed(2)
    }
    else {
        totalAllocatedAmount = parseFloat(allocatedAmount).toFixed(2)
    }
    this.$spSaveAllocations(taskUsers, taskDate)
    return {
        user: user,
        taskUsers: taskUsers,
        totalAllocatedAmount: totalAllocatedAmount,
        totalAllocatedPercentage: totalAllocatedPercentage
    }
},

    Vue.prototype.$spSaveAllocations = function (taskUsers, taskDate) {
        axios
            .post('/savePieceRateAllocation', {
                users: taskUsers,
                taskDate: moment(taskDate).format('YYYY-MM-DD')
            })
            .then(response => {
            })
            .catch(err => {
                console.log('ERROR: ', err)
                bus.$emit('banner-error', { message: err, })
            })
    }