<template>
    <div>
        <!-- <div class="modal fade" id="chatViewDetailsModal" tabindex="-1" role="dialog" aria-labelledby="chatViewDetailsModalTitle" aria-hidden="true">
			<div class="modal-dialog" role="document" style="max-width: 550px"> -->
        <div class="modal-content">
            <div v-if="chatRoom" class="modal-header border-bottom">
                <h5 v-if="chatRoom" class="modal-title d-flex align-items-center">
                    <template v-if="chatRoom.type == 'company' || chatRoom.type == 'channel'">
                        <i class="ri-hashtag"></i>
                        <div class="job-name">{{ chatRoom?.name ? chatRoom.name : '' }}</div>
                    </template>
                    <template v-if="chatRoom.type == 'job'">
                        <i class="ri-stack-line"></i>
                        <div>
                            <div class="job-name">{{ chatRoom.job ? chatRoom.job.job_name : '' }}</div>
                            <div class="customer">{{ chatRoom.job && chatRoom.job.customer ? chatRoom.job.customer.name : '' }}</div>
                        </div>
                    </template>
                    <template v-if="chatRoom.type == 'user' || chatRoom.type == 'group'">

                    </template>
                </h5>
                <button type="button" class="close" @click="clearForm(); $emit('cancelFnc')">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <div class="modal-body">
                <b-overlay :show="show" opacity="0.8" variant="white" rounded="sm">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <template v-if="chatRoom && !(chatRoom.type == 'group' || chatRoom.type == 'user')">
                                <button v-if="addPeople == 'add'" type="button" class="btn btn-sm btn-default d-flex align-items-center text-uppercase float-left" @click="addPeople = 'select'">
                                    <i class="ri-add-line"></i> Add People
                                </button>
                            </template>
                            <!-- <button v-if="chatRoom && chatRoom.type == 'job'" type="button" class="btn btn-sm btn-outline-default d-flex align-items-center text-uppercase float-right" @click="viewJob(chatRoom.job.job_number.job_number)">
                                <i class="ri-eye-line"></i> View Job
                            </button> -->
                        </div>
                        <div v-if="chatRoom && !(chatRoom.type == 'group' || chatRoom.type == 'user')" class="col-12 mb-2">
                            <div v-if="addPeople == 'select' && allUsersFilter" class="mb-2">
                                <multiselect placeholder="" label="name" track-by="id" v-model="addedUsers" :options="allUsersFilter" :multiple="true" :show-labels="false" @tag="addTag">
                                    <template slot="option" slot-scope="props">
                                        <div class="">
                                            <img class="avatar-img rounded-circle" :src="props.option.picture">
                                            <span class="">{{ props.option.name }}</span>
                                        </div>
                                    </template>
                                </multiselect>
                                <div class="float-right mt-2">
                                    <button type="button" class="btn btn-sm btn-danger d-flex align-items-center float-left" @click="addPeople = 'add'">
                                        <i class="ri-close-line"></i> Cancel
                                    </button>
                                    <button type="button" class="btn btn-sm btn-primary d-flex align-items-center float-left" @click="addPeople = 'add'; addPeopleToChatRoom()">
                                        <i class="ri-check-line"></i> Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-if="chatRoom" class="col-12 mb-2">
                            <hr class="my-2">
                            <span class="members-text">Members ({{ chatRoomCount }})</span>
                        </div>
                        <div v-if="chatRoom && chatRoom.type == 'job'" class="col-12 mb-3">
                            The Job Manager and anyone assigned to a Task on this Job is automatically added to this Job
                            Chat and cannot be removed. You can manually add people to this chat.
                        </div>
                        <div class="col-12">
                            <template v-if="chatRoom">
                                <div class="members-section mb-3" v-for="(data, index) in chatRoom.users" :key="index">
                                    <div class="d-flex align-items-center text-left">
                                        <img class="avatar-img rounded-circle mr-2" :src="data.user.picture">
                                        <div>
                                            <span class="name" v-if="data.user">{{ data.user.name }}</span>
                                            <span class="role" v-if="data.user">{{ data.user?.role?.name }}</span>
                                        </div>
                                    </div>
                                    <div v-if="(data.is_default_user == 0) && !(chatRoom.type == 'group' || chatRoom.type == 'user')" class="remove" @click="removeMember(data)">
                                        Remove
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </b-overlay>
            </div>
            <!-- <div class="modal-footer border-top">
                <button type="button" class="btn btn-link  ml-auto close2chatViewDetailsModal" @click="clearForm(); $emit('cancelFnc')">{{ $t('label.close') }}</button>
            </div> -->
        </div>
        <!-- </div>
		</div> -->
    </div>
</template>

<script>
import bus from '@/utils/event-bus';
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';

export default {
    props: ['chatRoomData'],
    emit: ['cancelFnc'],
    components: {
        Multiselect
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            companySettings: JSON.parse(localStorage.getItem('company_settings')),
            addedUsers: [],
            form: {
                name: null,
                first_name: null,
                last_name: null,
                phone_1: null,
            },
            addPeople: 'add',
            chatRoom: null,
            show: true
        }
    },
    created() {
        this.$store.dispatch('userData/getAllUsers');

        bus.$on('chatViewDetails', (data) => {
            this.getChatRoom(data);
        })

    },
    computed: {
        ...mapGetters({
            allUsers: 'userData/allUsers',
            // jobChatRoom: 'chat/jobChatRoom',
            myChatRooms: 'chat/myChatRooms',
        }),
        allUsersFilter: function () {
            let usersTemp = [];
            let usersAdd = [];

            if (this.chatRoom && this.chatRoom.users) {
                this.chatRoom.users.forEach(data => {
                    usersTemp.push(data.user.id);
                })

                this.allUsers.forEach(data => {
                    if (usersTemp.indexOf(data.id) < 0) {
                        usersAdd.push(data);
                    }
                })
            }

            return usersAdd;
        },
        chatRoomCount: function () {
            let count = 0;
            if (this.chatRoom && this.chatRoom.users) {
                count = this.chatRoom.users.length;
            }
            return count;
        }
    },
    mounted() {
        this.getChatRoom(this.chatRoomData);
    },
    methods: {
        getChatRoom(data) {
            this.show = true;
            this.$store.dispatch('chat/getMyChatRooms').then(response => {
                let matchedChatRoomIndex = this.myChatRooms.findIndex(item => item.id === data.id);
                console.log('matchedChatRoomIndex', matchedChatRoomIndex);
                if (matchedChatRoomIndex !== -1) {
                    this.chatRoom = this.myChatRooms[matchedChatRoomIndex];
                    // $('#chatViewDetailsModal').modal('show')
                }
                this.show = false;
            });

        },
        addTag(newTag) {
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            this.options.push(tag)
            this.value.push(tag)
        },
        addPeopleToChatRoom() {
            if (this.addedUsers.length > 0) {
                this.show = true;
                let data = {
                    chat_room_id: this.chatRoom.id,
                    added_users: this.addedUsers
                }
                axios.post('/add-people-to-chat-room', data)
                    .then(response => {
                        this.clearForm();
                        bus.$emit('banner-success', {
                            message: `User/s is added successfully!`,
                        });
                        this.$store.dispatch('chat/getChatRoomByJobId', this.chatRoom.type_id);
                        this.$store.dispatch('chat/getMyChatRooms').then(response => {
                            let matchedChatRoomIndex = this.myChatRooms.findIndex(item => item.id === this.chatRoom.id);
                            if (matchedChatRoomIndex !== -1) {
                                this.chatRoom = this.myChatRooms[matchedChatRoomIndex];
                                this.show = false;
                            }
                        });
                    })
                    .catch(err => {
                        //console.log('list carriers error:', error)
                    });
            }
        },
        removeMember(data) {
            this.show = true;
            axios.post('/delete-people-to-chat-room/' + data.id)
                .then(response => {
                    this.clearForm();
                    bus.$emit('banner-success', {
                        message: `User is removed successfully!`,
                    });
                    this.$store.dispatch('chat/getChatRoomByJobId', this.chatRoom.type_id);
                    this.$store.dispatch('chat/getMyChatRooms').then(response => {
                        let matchedChatRoomIndex = this.myChatRooms.findIndex(item => item.id === this.chatRoom.id);
                        if (matchedChatRoomIndex !== -1) {
                            this.chatRoom = this.myChatRooms[matchedChatRoomIndex];
                            this.show = false;
                        }
                    });
                })
                .catch(err => {
                    bus.$emit('banner-error', {
                        message: `User/s can't be removed as they have chat records.`,
                    });
                    this.show = false;
                    //console.log('list carriers error:', error)
                });
        },
        clearForm() {
            this.addedUsers = [];
        },
        viewJob(jobNumber) {
            // console.log('this.chatRoom',this.chatRoom)
            // window.location.replace('/job/'+this.chatRoom.job.job_number.job_number+'/edit');
            this.$router.push({
                name: 'jobEdit',
                params: { id: jobNumber }
            });
            this.$emit('cancelFnc');
        },

    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
#chatViewDetailsModal {
    text-align: left;
}

.members-text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.3px;
    color: #525F7F;
}

.members-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.members-section .name {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #32325D;
    display: block;
}

.members-section .role {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #525F7F;
    display: block;
}

.members-section .remove {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #005486;
    cursor: pointer;
}

/************** MULTISELECT **************/
.multiselect .multiselect__tags {
    font-size: 12px;
}

.multiselect .multiselect__tag {
    background: #CCE7FC !important;
    color: #344C8A !important;
    font-weight: 600;
    margin-bottom: 0;
}

.multiselect .multiselect__tag .multiselect__tag-icon:after {
    color: #344C8A !important;
}

.multiselect .multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
    background: #CCE7FC !important;
}

.multiselect .multiselect__content-wrapper {
    margin-top: 5px;
    border-radius: 5px;
}

.multiselect .multiselect__content-wrapper .multiselect__element {
    font-size: 14px;
}

.multiselect .multiselect__content-wrapper .multiselect__option {
    padding: 8px 15px;
}

.multiselect .multiselect__content-wrapper .multiselect__option--selected {
    background: #F6F9FC !important;
    color: #32325d;
}

.multiselect .multiselect__content-wrapper .multiselect__option--selected.multiselect__option--highlight {}

.multiselect .multiselect__content-wrapper .multiselect__option--highlight {
    background: #F6F9FC !important;
    color: #32325d;
}

.multiselect img.option__image {
    width: 32px;
}
</style>