import URLJS from 'url-js';

export const getDomain = () => {
    const url = URLJS(window.location.origin);

    const hostname = url.hostname;
    const slipttedHostname = hostname.split('.');
    let host = null;
    let subDomain = null;
    const port = url.port;
    const protocol = url.protocol;

    if (slipttedHostname.length > 2) {
        subDomain = slipttedHostname[0]
        host = slipttedHostname[1]
    }

    if (slipttedHostname.length < 3) {
        host = slipttedHostname[0]
    }

    return {
        'domain': host,
        'subDomain': subDomain,
        'port': port,
        'protocol': protocol
    };
}
