<template>
	<div class="col-xl-12 order-xl-1 mobile-p-0">
		<div class="card">
			<div class="card-header">
				<div class="row align-items-center justify-content-between">
					<div>
						<h3 class="mb-0 d-flex align-items-center"><i class="ri-pencil-ruler-2-line heading-title"></i> {{ $t('label.materials') }}</h3>
					</div>
					<div>
						<button type="button" class="btn btn-sm btn-default" data-toggle="modal" data-target="#addJobMaterialsModal" @click="clearForm()">
							{{ $t('label.add material') }}
						</button>
					</div>
				</div>
			</div>

			<div v-if="alljobmaterials.length == 0">
				<div class="card-body m-0 py-0">
					<div class="bg-white text-center row align-items-center m-0">
						<i class="ri-pencil-ruler-2-line ni-8x w-100 color-gray-400"></i>
						<p class="w-100 font-weight-normal">{{ $t('label.No Materials have been added to this Job') }}.</p>
					</div>
				</div>
			</div>
			<div v-if="alljobmaterials.length > 0" class="table-responsive">
				<table class="table table-flush table-sm border-bottom" style="margin: 0 !important;" id="datatable-jobmaterials">
					<thead class="thead-light">
						<tr>
							<th scope="col">MATERIAL</th>
							<th scope="col">UNIT</th>
							<th scope="col">QUANTITY</th>
							<th scope="col">LOCATION</th>
							<th scope="col" style="width: 25%">NOTES</th>
							<th scope="col" style="width: 5%"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in paginatedJobMaterials" v-bind:key="index" class="cursor-pointer2">
							<td class="sp-va-mid" @click="editModal(item)">{{ item.material }}</td>
							<td class="sp-va-mid" @click="editModal(item)">{{ item.unit }}</td>
							<td class="sp-va-mid" @click="editModal(item)">{{ item.quantity }}</td>
							<td class="sp-va-mid" @click="editModal(item)">{{ item.location }}</td>
							<td class="sp-va-mid" @click="editModal(item)">{{ item.notes }}</td>
							<td class="text-right sp-va-mid">
								<div class="dropdown">
									<a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<i class="fas fa-ellipsis-v"></i>
									</a>
									<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
										<a class="dropdown-item text-dark pointer" @click="editModal(item)">
											<i class="ri-pencil-line"></i> {{ $t('label.edit material') }}
										</a>
										<a class="dropdown-item text-danger" href="#" data-toggle="modal" data-target="#deleteJobMaterialsModal" @click="checkJobMaterial(item)">
											<i class="ri-delete-bin-line"></i> {{ $t('label.delete material') }}
										</a>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
				<table-pagination v-model="paginatedJobMaterials" :all-rows="alljobmaterials" :perPage="10">
				</table-pagination>

			</div>
		</div>
		<!-- Add Modal -->
		<div class="modal fade" id="addJobMaterialsModal" tabindex="-1" role="dialog" aria-labelledby="addJobMaterialsModalTitle" aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header border-bottom">
						<h5 class="modal-title" id="addJobMaterialsModalTitle">{{ $t('label.add job material') }}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<i class="ri-close-line"></i>
						</button>
					</div>
					<div class="modal-body">
						<div class="row">
							<!-- Material Name -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.material') }}</label>
									<span class="text-danger text-sm">*&nbsp;{{ $t('label.required') }}</span>
									<vue-autosuggest ref="autosuggest" v-model="query" :suggestions="filteredSuggestions" :inputProps="getAddAutoSuggestProps()" :getSuggestionValue="s => s.item.name" :limit="5" @input="onMaterialChange">
										<template slot-scope="{ suggestion }">
											<div>{{ suggestion.item.name }}</div>
										</template>
									</vue-autosuggest>

									<div v-if="$v.form.add.material.$error" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
								</div>
							</div>
							<!-- Units -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.unit') }}</label>
									<span class="text-danger text-sm">*&nbsp;{{ $t('label.required') }}</span>
									<input v-model="$v.form.add.unit.$model" type="text" name="unit" class="form-control" :class="{ 'border-danger': $v.form.add.unit.$error }" :placeholder="$t('label.unit')" required autocomplete="input-field" data-lpignore="true">

									<div v-if="$v.form.add.unit.$error && !$v.form.add.unit.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
								</div>
							</div>
							<!-- Quantity -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.quantity') }}</label>
									<span class="text-danger text-sm">*&nbsp;{{ $t('label.required') }}</span>
									<input v-model="$v.form.add.quantity.$model" type="number" name="quantity" class="form-control" min="0" :class="{ 'border-danger': $v.form.add.quantity.$error }" :placeholder="$t('label.quantity')" required autocomplete="input-field">
									<div v-if="$v.form.add.quantity.$error && !$v.form.add.quantity.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
									<div v-if="!$v.form.add.quantity.between" class="text-danger text-sm">*&nbsp;{{ $t('label.quantity of more than zero is required') }}</div>
								</div>
							</div>
							<!-- Location -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.location') }}</label>
									<input v-model="form.add.location" type="text" name="location" class="form-control" :placeholder="$t('label.location')" autocomplete="input-field" data-lpignore="true">
								</div>
							</div>
							<!-- Notes -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.notes') }}</label>
									<textarea v-model="form.add.notes" name="notes" class="form-control" :placeholder="$t('label.notes')"></textarea>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer border-top">
						<button type="button" class="btn btn-primary mr-auto" @click="saveJobMaterial()">{{ $t('label.add job material') }}</button>
						<button type="button" class="btn btn-link  ml-auto close2add" data-dismiss="modal">{{ $t('label.close') }}</button>
					</div>
				</div>
			</div>
		</div>
		<!-- EDIT Modal -->
		<div class="modal fade" id="editJobMaterialsModal" tabindex="-1" role="dialog" aria-labelledby="editJobMaterialsModalTitle" aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header border-bottom">
						<h5 class="modal-title" id="editJobMaterialsModalTitle">{{ $t('label.edit job material') }}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<i class="ri-close-line"></i>
						</button>
					</div>
					<div class="modal-body">
						<div class="row">
							<!-- Material Name -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.material') }}</label>
									<span class="text-danger text-sm">*&nbsp;{{ $t('label.required') }}</span>
									<vue-autosuggest ref="autosuggest" v-model="query" :suggestions="filteredSuggestions" :inputProps="getEditAutoSuggestProps()" :getSuggestionValue="s => s.item.name" :limit="5" @input="onMaterialChange">
										<template slot-scope="{ suggestion }">
											<div>{{ suggestion.item.name }}</div>
										</template>
									</vue-autosuggest>

									<div v-if="$v.form.edit.material.$error && !$v.form.edit.material.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
								</div>
							</div>
							<!-- Unit -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.unit') }}</label>
									<span class="text-danger text-sm">*&nbsp;{{ $t('label.required') }}</span>
									<input v-model="$v.form.edit.unit.$model" type="text" name="unit" class="form-control" :class="{ 'border-danger': $v.form.edit.unit.$error }" :placeholder="$t('label.unit')" required autofocus autocomplete="input-field" data-lpignore="true">

									<div v-if="$v.form.edit.unit.$error && !$v.form.edit.unit.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
								</div>
							</div>
							<!-- Quantity -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.quantity') }}</label>
									<span class="text-danger text-sm">*&nbsp;{{ $t('label.required') }}</span>
									<input v-model="$v.form.edit.quantity.$model" type="number" name="quantity" class="form-control" min="0" :class="{ 'border-danger': $v.form.edit.quantity.$error }" :placeholder="$t('label.quantity')" required autofocus autocomplete="input-field" data-lpignore="true">

									<div v-if="$v.form.edit.quantity.$error && !$v.form.edit.quantity.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
									<div v-if="!$v.form.add.quantity.between" class="text-danger text-sm">*&nbsp;{{ $t('label.quantity of more than zero is required') }}</div>
								</div>
							</div>
							<!-- Location -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.location') }}</label>
									<input v-model="form.edit.location" type="text" name="location" class="form-control" :placeholder="$t('label.location')" autofocus autocomplete="input-field" data-lpignore="true">
								</div>
							</div>
							<!-- Notes -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.notes') }}</label>
									<textarea v-model="form.edit.notes" name="notes" class="form-control" :placeholder="$t('label.notes')" autofocus></textarea>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer border-top">
						<button type="button" class="btn btn-primary mr-auto" @click="updateJobMaterial()">{{ $t('label.update job material') }}</button>
						<button type="button" class="btn btn-link  ml-auto close2edit" data-dismiss="modal">{{ $t('label.close') }}</button>
					</div>
				</div>
			</div>
		</div>
		<!-- MODALS FOR DELETE -->
		<div class="modal fade" id="deleteJobMaterialsModal" tabindex="-1" role="dialog" aria-labelledby="deleteJobMaterialsModal" aria-hidden="true">
			<div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
				<div class="modal-content bg-gradient-danger">
					<div class="modal-header">
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="deleteJobMaterial">
						<input type="hidden" id="job_material_id" v-model="job_material_id" />
						<div class="modal-body">
							<div class="py-3 text-center">
								<i class="ri-error-warning-line ni-3x"></i>
								<h4 class="heading mt-4 text-uppercase">{{ $t('label.ARE YOU SURE YOU WANT TO DELETE THIS MATERIAL') }}?</h4>
								<p>{{ $t('label.This action cannot be undone') }}!</p>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-white">{{ $t('label.Yes, Delete') }}</button>
							<button type="button" class="btn btn-link text-white ml-auto closedeleteJobMaterialsModal" data-dismiss="modal">{{ $t('label.cancel') }}</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import bus from '@/utils/event-bus';
import * as $ from 'jquery';
import { VueAutosuggest } from 'vue-autosuggest';
import 'vue-search-select/dist/VueSearchSelect.css';
import { between, required } from 'vuelidate/lib/validators';

import TablePagination from '@/components/TablePagination';

export default {
	props: ['jobid'],
	emits: ['onMaterialsChange'],
	components: {
		VueAutosuggest,
		TablePagination
	},
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
			alljobmaterials: [],
			jobmaterialsList: [],
			paginatedJobMaterials: [],
			showDropdown: false,
			job_material_id: null,
			query: '',
			dataTable: null,
			showEditModal: false,
			form: {
				add: {
					location: null,
					material: null,
					unit: null,
					quantity: null,
					notes: null
				},
				edit: {
					location: null,
					material: null,
					unit: null,
					quantity: null,
					notes: null,
					id: null
				}
			},
			columns: ['material', 'unit', 'quantity', 'location', 'notes', 'actions'],
			options: {
				perPageValues: [],
				headings: {
					material: this.$t('label.material'),
					unit: this.$t('label.unit'),
					quantity: this.$t('label.quantity'),
					location: this.$t('label.location'),
					notes: this.$t('label.notes'),
					actions: ''
				},
				editableColumns: ['material', 'unit', 'quantity', 'location', 'notes'],
				sortable: ['material', 'unit', 'quantity', 'location', 'notes'],
				filterable: false,
				columnsClasses: {
					material: 'text-left',
					unit: 'text-left',
					quantity: 'text-left',
					location: 'text-left',
					notes: 'text-left',
					actions: 'text-center actions-column'
				},
				rowClassCallback(row) {
					return row;
				},
			},
		}
	},
	validations: {
		form: {
			add: {
				material: { required },
				unit: { required },
				quantity: {
					required,
					between: between(1, 10000)
				}
			},
			edit: {
				material: { required },
				unit: { required },
				quantity: {
					required,
					between: between(1, 10000)
				}
			}
		}
	},
	computed: {
		filteredSuggestions() {
			return [{
				data: this.jobmaterialsList.filter(item => {
					if (item.name) {
						return (
							item.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1
						);
					} else {
						return '';
					}
				})
			}];
		}
	},
	created() {
		// Register bus listeners
		bus.$on('MaterialsSectionDltJobMat', this.getJobMaterials);
		bus.$on('MaterialModalJobUpdate', this.getJobMaterials);
	},
	destroyed() {
		// Remove bus listeners
		bus.$off('MaterialsSectionDltJobMat', this.getJobMaterials);
		bus.$off('MaterialModalJobUpdate', this.getJobMaterials);
	},
	mounted() {
		this.getJobMaterialsList();
		this.getJobMaterials();
		return _.orderBy(this.alljobmaterials, ['created_at', 'desc']);
	},
	methods: {
		getJobMaterialsList() {
			axios.get('/jobmaterials-list').then(response => {
				this.jobmaterialsList = response.data.options;
			}).catch(err => {
				//console.log('list carriers error:', error)
			});
		},
		getJobMaterials() {
			axios.get('/jobmaterials/' + this.jobid).then(response => {
				this.alljobmaterials = response.data.result;
			}).catch(err => {
				//console.log('list carriers error:', error)
			});
		},
		saveJobMaterial() {
			this.form.add.material = $('#autosuggest__input').val();

			this.$v.$touch();
			if (!this.$v.form.add.$invalid) {
				var request_fields = {
					'job_id': this.jobid,
					'material': this.$v.form.add.material.$model,
					'unit': this.$v.form.add.unit.$model,
					'quantity': this.$v.form.add.quantity.$model,
					'location': this.form.add.location,
					'notes': this.form.add.notes
				};

				axios.post('/jobmaterials', request_fields).then(response => {
					this.getJobMaterials();
					this.getJobMaterialsList();
					this.$emit('onMaterialsChange');

					bus.$emit('banner-success', {
						message: this.$t('label.job material created') + '.'
					});
				}).catch(err => {
					//console.log('list carriers error:', error)
				});
				document.querySelector('.close2add').click();
			}
		},
		editModal(item) {
			this.showEditModal = true;

			this.$v.$reset();
			this.$v.form.edit.material.$model = item.material;
			this.$v.form.edit.unit.$model = item.unit;
			this.$v.form.edit.quantity.$model = item.quantity;
			this.form.edit.location = item.location;
			this.query = item.material;
			this.form.edit.notes = item.notes;
			this.form.edit.id = item.id;

			$('#editJobMaterialsModal').modal('show')
		},
		updateJobMaterial() {
			this.form.edit.material = $('#autosuggest__input').val();

			this.$v.$touch();
			if (!this.$v.form.edit.$invalid) {
				var request_fields = {
					'material': this.$v.form.edit.material.$model,
					'unit': this.$v.form.edit.unit.$model,
					'quantity': this.$v.form.edit.quantity.$model,
					'location': this.form.edit.location,
					'notes': this.form.edit.notes,
					'job_id': this.jobid,
					'id': this.form.edit.id
				};

				axios.post('/jobmaterials/update', request_fields).then(() => {
					this.getJobMaterials();
					this.$emit('onMaterialsChange');

					bus.$emit('banner-success', {
						message: this.$t('label.job material updated') + '.'
					});
				}).catch(err => {
					//console.log('list carriers error:', error)
				});
				document.querySelector('.close2edit').click();
			}
		},
		checkJobMaterial(data) {
			this.job_material_id = data.id;
		},
		deleteJobMaterial() {
			axios.post('/jobmaterials/delete/' + this.job_material_id).then(() => {
				this.getJobMaterials();
				this.$emit('onMaterialsChange');

				bus.$emit('banner-success', {
					message: this.$t('label.job material deleted') + '.'
				});
			}).catch(err => {
				//console.log('list carriers error:', error)
			});
			document.querySelector('.closedeleteJobMaterialsModal').click();
		},
		onMaterialChange(matName) {
			if (this.showEditModal)
				this.$v.form.edit.material.$model = matName;
			else
				this.$v.form.add.material.$model = matName;
		},
		clearForm() {
			$('#autosuggest__input').val('');
			this.showEditModal = false;
			this.query = '';
			this.$v.form.add.material.$model = '';
			this.$v.form.add.unit.$model = '';
			this.$v.form.add.quantity.$model = null;
			this.form.add.location = '';
			this.form.add.notes = '';

			this.$v.form.add.$reset();
		},
		getEditAutoSuggestProps() {
			let classData = "text-dark";

			if (this.$v.form.edit.material.$error)
				classData += " border-danger";

			return {
				id: "autosuggest__input",
				placeholder: "Material",
				class: classData
			};
		},
		getAddAutoSuggestProps() {
			let classData = "text-dark";

			if (this.$v.form.add.material.$error)
				classData += " border-danger";

			return {
				id: "autosuggest__input",
				placeholder: "Material",
				class: classData
			};
		}
	}
}
</script>
<style>
.VueTables.VueTables--client>.row:first-child {
	display: none;
}

.dropdown-toggle::after {
	content: none;
}

.dropdown-toggle {
	background-color: transparent;
	border-color: transparent;
	color: #ced4da !important;
	padding: 0;
}

.table-bordered th,
.table-bordered td {
	background-color: #fff;
	border: none;
	border-bottom: 1px solid #e9ecef
}

.table-bordered thead th,
.table-bordered thead td {
	color: #8898aa;
	background-color: #f6f9fc;
}

.VuePagination {
	height: 75px;
	margin: 0;
}

.VuePagination__count {
	position: absolute;
	left: 0;
	top: 25%;
	text-align: left !important;
	font-family: Open Sans, sans-serif;
	font-size: .875rem;
}

li.VuePagination__pagination-item.page-item a.page-link {
	z-index: 2000;
}

#autosuggest__input {
	font-size: 0.875rem;
	transition: all 0.15s ease-in-out;
	line-height: 1.5;
	display: block;
	width: 100%;
	height: calc(2.75rem + 2px);
	padding: 0.625rem 0.75rem;
	transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	color: #8898aa;
	border: 1px solid #dee2e6;
	border-radius: 0.25rem;
	background-color: #fff;
	background-clip: padding-box;
	box-shadow: 0 3px 2px rgb(233 236 239 / 5%);
}

#autosuggest__input.autosuggest__input-open {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.autosuggest__results-container {
	position: relative;
	width: 100%;
}

.autosuggest__results {
	margin: 0;
	position: absolute;
	z-index: 10000001;
	width: 100%;
	border: 1px solid #e0e0e0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	background: white;
	padding: 0px;
}

.autosuggest__results ul {
	list-style: none;
	padding-left: 0;
	margin: 0;
}

.autosuggest__results .autosuggest__results-item {
	cursor: pointer;
	padding: 10px;
	font-size: 0.875rem;
}

#autosuggest ul:nth-child(1)>.autosuggest__results_title {
	border-top: none;
}

.autosuggest__results .autosuggest__results_title {
	color: #8898aa;
	font-size: 11px;
	margin-left: 0;
	padding: 15px 13px 5px;
	border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results .autosuggest__results-item.autosuggest__results-item--highlighted {
	background-color: #F6F6F6;
}

@media only screen and (max-width: 480px) {
	.VuePagination {
		height: 100% !important;
		margin: 0;
	}
}
</style>