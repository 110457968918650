<template>
    <div class="card mb-0">
        <div class="card-header">
            <div class="row d-flex align-content-center">
                <div class="col-8">
                    <h3 v-if="passedUser.payType =='1099'"><span class="text-lg text-primary">{{ passedUser.company_name }}</span> daily payroll for week {{ search.startDate | formatDate }} - {{ search.endDate | formatDate }}</h3>
                    <h3 v-else><span class="text-lg text-primary">{{ passedUser.name }}</span> daily payroll for week {{ search.startDate | formatDate }} - {{ search.endDate | formatDate }}</h3>
                </div>
                <div class="col-4 text-right">
                    <div class="">
                        <span class="pointer" @click="$modal.hide('showDailybyWeek'); $emit('close')">
                            <i class="ri-close-line text-xl"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <user-daily-payroll-by-week v-if="search.endDate" :search="search" :key="search.endDate"></user-daily-payroll-by-week>
            <div class="text-right pt-2">
                <button type="button" class="btn btn-primary" @click="$modal.hide('showDailybyWeek'); $emit('close');$emit('closePayrollWk')">Close</button>
            </div>
        </div>
    </div>
</template>
<script>
import UserDailyPayrollByWeek from '@/common/UserDailyPayrollByWeek'

export default {
    props: ['selectedWeek'],
    components: {
        UserDailyPayrollByWeek
    },
    data() {
        return {
            companySettings: JSON.parse(localStorage.getItem('company_settings')).settings,
            user: JSON.parse(localStorage.getItem('user')),
            weekData: [],
            passedUser: { name: null },
            search: { userId: null, startDate: null, endDate: null, userPayType: null }
        }
    },
    computed: {
    },
    mounted() {
        if (!this.selectedWeek) {
            this.$router.push({ name: 'payrollReport' })
        }
        else {
            this.search = {
                userPayType: this.selectedWeek.user.payType,
                userId: this.selectedWeek.user.id,
                startDate: this.selectedWeek.week.dateRange.startDate,
                endDate: this.selectedWeek.week.dateRange.endDate
            }
            this.passedUser = this.selectedWeek.user
        }
    },
    methods: {
    }
}
</script>
