import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    silentTranslationWarn: true,
    messages: []
})

function setLocale(language) {
    if (localStorage.getItem('_local')) {
        return { "en": localStorage.getItem('_local') };
    } else {
        return [];
    }
}
export const changeLanguage = (language) => {
    i18n.locale = language;
}

export default i18n;