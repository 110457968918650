<template>
    <div class="purchase-order">
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50">
            <!-- Header -->
            <div class="d-flex z-index-10">
                <!-- Breadcrumbs -->
                <div class="mr-auto z-index-1">
                    <breadcrumbs :title="$t('label.new vpo')">
                        <!-- Breakcrumb slots -->
                        <li class="breadcrumb-item">
                            <a class="pointer" @click="$router.go(-1)">{{ $t('label.vpos') }}</a>
                        </li>
                        <li class="breadcrumb-item">
                            <span>{{ $t('label.new vpo') }}</span>
                        </li>
                    </breadcrumbs>
                </div>

                <!-- Purchase Order Save/Cancel -->
                <div class="py-4 align-items-center" style="z-index: 1">
                    <button type="button" class="btn btn-link">
                        <a @click="$router.back()" class="text-white">{{ $t('label.cancel') | uppercase }}</a>
                    </button>
                    <button type="button" class="btn btn-neutral" @click="validateNewItem('save')">
                        <span class="text-default">{{ $t('label.save draft') | uppercase }}</span>
                    </button>
                    <button type="button" class="btn btn-neutral" @click="validateNewItem('save_send')">
                        <span class="text-default">{{ $t('label.save and send') | uppercase }}</span>
                    </button>
                </div>
            </div>

            <!-- VPO Info -->
            <VpoInfo :form-info="formInfo" :is-valid="isValid" :po-type="'add'" :job-id="jobId"></VpoInfo>

            <!-- VPO Items -->
            <VpoItems :form="formItem" :is-valid="isValid" type="add" :formDirty="formDirty"></VpoItems>

            <!-- Footer Save or Cancel Buttons -->
            <div class="d-flex text-right">
                <div class="ml-auto">
                    <span class="d-block d-sm-inline pb-2">
                        <button type="button" class="btn btn-link">
                            <a @click="$router.back()">{{ $t('label.cancel') | uppercase }}</a>
                        </button>
                    </span>
                    <span class="d-block d-sm-inline">
                        <button type="button" class="btn btn-primary" @click="validateNewItem('save')">
                            {{ $t('label.save draft') | uppercase }}
                        </button>
                    </span>
                    <span class="d-block d-sm-inline">
                        <button type="button" class="btn btn-primary" @click="validateNewItem('save_send')">
                            {{ $t('label.save and send') | uppercase }}
                        </button>
                    </span>
                </div>
            </div>
        </div>

        <!-- Send Modal -->
        <modal class="py-4" name="modalVpoSendOnCreate" height="auto" width="100%" :max-width="900" :scrollable="true" :reset="true" :adaptive="true">
            <ModalVpoSend :vpo="savedVpoData" send-type="save_request" @cancelFnc="$modal.hide('modalVpoSendOnCreate')" @returnAfterSend="toPreviousPage()"></ModalVpoSend>
        </modal>

    </div>
</template>

<script>
import VpoInfo from '@/components/VariablePurchaseOrders/AddEditVariablePurchaseOrders/VpoInfo.vue';
import VpoItems from '@/components/VariablePurchaseOrders/AddEditVariablePurchaseOrders/VpoItems.vue';
import ModalVpoSend from '@/components/VariablePurchaseOrders/SendVariablePurchaseOrder/VpoSendModal.vue';
import bus from '@/utils/event-bus';

export default {
    props: ['jobId' /* Passed through vue router */],
    emits: [''],
    components: {
        VpoInfo,
        VpoItems,
        ModalVpoSend
    },
    data() {
        return {
            formDirty: false,
            savedVpoData: {},
            formInfo: {
                type: 1,
                comments: '',
                job: null,
                terms: null,
                title: null,
                job_id: null,
                status: null,
                customer: null,
                job_name: null,
                vendor_id: null,
                vendor_name: null,
                customer_id: null,
                order_number: null,
                customer_name: null,
                delivery_date: null,
                superintendent_user_id: null,
                purchase_date: moment().format('MM/DD/YYYY'),
            },
            formItem: {
                list_items: [],
                total: parseFloat(0.00).toFixed(2),
                sub_total: parseFloat(0.00).toFixed(2),
                tax: parseFloat(0.00).toFixed(2),
            },
            isValid: {
                items: null,
                customer_name: null,
                superintendent_user_id: null,
                job_name: null,
                purchase_date: true,
            },
        }
    },
    mounted() { },
    methods: {
        // Validate all necessary fields in child components
        validateNewItem(type) {
            try {
                let formValid = true;
                this.touchForms();

                // Check fieldsWithError for fields with an error
                for (const key in this.isValid) {
                    if ((this.isValid[key] === false))
                        formValid = false;
                    if (this.isValid[key] === null) {
                        formValid = false;
                        this.isValid[key] = false;
                    }
                }

                // Validate number of items
                if (this.formItem.list_items.length < 1)
                    throw new Error($t('label.vpo must have at least one billable item') + '.');
                // Validate Form Fields
                if (!formValid)
                    throw new Error($t('label.please validate required fields before proceeding') + '.');
                // Validate VPO has billable amount
                if (this.formItem.total <= 0)
                    throw new Error($t('label.cannot create a vpo with a total amount of 0') + '.');

                if (type == 'save') {
                    // Save the draft and return to previous page
                    this.saveDraft().then(() => {
                        this.toPreviousPage();
                    });
                } else if (type == 'save_send') {
                    // Prepare the send vpo to customer modal which will call 
                    // back to save the vpo and then send the vpo afterwards.
                    this.saveDraft().then(() => {
                        this.$router.push({
                            name: 'variablePurchaseOrders',
                            params: {
                                id: this.savedVpoData.id,
                                modalToOpen: 'send_vpo'
                            }
                        });
                    });
                }

            } catch (err) {
                bus.$emit('banner-error', { message: err });
            }
        },
        touchForms() {
            this.formDirty = false;
            this.$nextTick(() => this.formDirty = true);
        },

        async saveDraft() {
            // Merge children component forms to be saved.
            this.loader = this.$loading.show();
            let mergedForm = {
                form_info: this.formInfo,
                form_item: this.formItem,
            };

            return axios.post('/vpo', mergedForm).then(rsp => {
                this.loader.hide();
                this.savedVpoData = rsp.data.result;

                bus.$emit('banner-success', {
                    message: this.$t('label.vpo saved successfully!')
                });
            }).catch((err) => console.error(err));
        },

        toPreviousPage() {
            this.$router.go(-1);
        }
    },
}
</script>
