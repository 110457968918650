<template>
    <div class="col-xl-12 order-xl-1 p-0" id="purchaseOrderFiles">
        <div class="card">
            <div class="card-header pb-1" style="border-bottom: 0px;" id="purchaseOrderFiles">
                <!-- Card Header -->
                <div class="d-flex align-items-center justify-content-between">
                    <div class="pr-3" data-toggle="tab" href="#tabs-btn-list">
                        <h3 class="mb-0 d-flex align-items-center"><i class="ri-folders-line heading-title"></i> {{ $t('label.purchase order files') }}</h3>
                    </div>
                    <!-- List / Gallary Toggles -->
                    <div class="ml-auto tabs-file">
                        <div class="nav btn-group btn-group-sm btn-group-light" role="tablist" id="tabs-btn">
                            <a class="btn btn-sm active" id="tabs-btn-list-tab" data-toggle="tab" href="#tabs-btn-list" role="tab">
                                <i class="ri-layout-row-line ri-font-size-2"></i>{{ $t('label.list') }}
                            </a>
                            <a class="btn btn-sm" id="tabs-btn-gallery-tab" data-toggle="tab" href="#tabs-btn-gallery" role="tab">
                                <i class="ri-layout-grid-line"></i>{{ $t('label.gallery') }}
                            </a>
                        </div>
                    </div>
                    <!-- Add new file button -->
                    <div class="pl-3 text-right">
                        <button type="button" class="btn btn-sm btn-default" data-toggle="modal" data-target="#addPurchaseOrderFilesModal" @click="viewUploadFileModal()">
                            {{ $t('label.add file') }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="m-0">
                <!-- Empty Files Table -->
                <div v-if="purchaseOrderFiles.length == 0" class="card-body p-0 mt-2 border-top">
                    <div class="m-0 py-0">
                        <div class="bg-white text-center row align-items-center m-0">
                            <i class="ri-folders-line ni-8x w-100 color-gray-400"></i>
                            <p class="w-100 font-weight-normal">{{ $t('label.no files have been added to this purchase order') }}.</p>
                        </div>
                    </div>
                </div>
                <!-- List Files Table -->
                <div v-if="purchaseOrderFiles.length > 0" class="card-body p-0">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="tabs-btn-list" role="tabpanel">
                            <div class="table-responsive pb-4">
                                <table class="table table-flush table-sm border-bottom" id="datatable-purchaseOrderFiles-view">
                                    <!-- Table Header -->
                                    <thead class="thead-light">
                                        <tr class="">
                                            <th>
                                                <span class="sp-row-sm-pl">{{ $t('label.file name') }}</span>
                                            </th>
                                            <th class="hidden-sm-down">{{ $t('label.description') }}</th>
                                            <th>{{ $t('label.uploaded by') }}</th>
                                            <th class="hidden-sm-down hidden-md-down">{{ $t('label.uploaded date') }}</th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <!-- Table Body -->
                                    <tbody class="">
                                        <tr class="px-4 list-group-item-action" v-for="(data, index) in paginatedPurchaseOrderFiles" :key="index" :id="'file-hover-' + data.id">
                                            <td class="w-20">
                                                <div class="row mx-0 sp-row-sm-pl">
                                                    <div class="d-flex align-items-center">
                                                        <img class="image-container image-container-thumbnail float-right mr-2" :src="data.filename_path" />
                                                        <a class="file-name-bold text-dark-blue cursor-pointer" href="#" data-toggle="modal" data-target="#viewFileModal" @click="viewFileModal(data.id)">
                                                            <span v-if="data.display_name">{{ data.display_name }}</span>
                                                            <span v-else>{{ data.filename }}</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="w-25 hidden-sm-down">{{ data.description }}</td>
                                            <td class="w-25">
                                                <span class="align-items-center my-0 d-flex">
                                                    <img class="avatar-img rounded-circle float-right mr-2" :src="data.created_by.picture" alt="">
                                                    <span class="cursor-pointer" @click="getUserInfo(data.created_by.id, 'Uploaded By')">
                                                        {{ data.created_by.name }}
                                                    </span>
                                                </span>
                                            </td>
                                            <td class="w-10 hidden-sm-down hidden-md-down">{{ data.created_at | formatDate }}</td>
                                            <td class="w-5">
                                                <div class="ml-auto text-right">
                                                    <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown">
                                                        <i class="fas fa-ellipsis-v"></i>
                                                    </a>
                                                    <div id="elmDropdown1" class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                        <a class="dropdown-item d-flex align-items-center" href="#" data-dismiss="modal" data-toggle="modal" data-target="#viewFileModal" @click="viewFileModal(data.id)">
                                                            <i class="ri-eye-line pr-2"></i> {{ $t('label.view file') }}
                                                        </a>
                                                        <a class="dropdown-item d-flex align-items-center" href="#" data-dismiss="modal" data-toggle="modal" data-target="#editFileModal" @click="editFileModal(data)">
                                                            <i class="ri-pencil-line pr-2"></i> {{ $t('label.edit file') }}
                                                        </a>
                                                        <!-- <a class="dropdown-item d-flex align-items-center" :href="'/' + purchaseOrderFileDest + '/' + data.filename" download> -->
                                                        <a class="dropdown-item d-flex align-items-center" @click="$downloadFile(data.filename_path)">
                                                            <i class="ri-download-line pr-2"></i> {{ $t('label.download file') }}
                                                        </a>
                                                        <a v-if="user.role_id == 1 | user.role_id == 2" class="dropdown-item d-flex align-items-center text-danger" href="#" data-dismiss="modal" data-toggle="modal" data-target="#deleteFileModal" @click="viewFileModal(data.id)">
                                                            <i class="ri-delete-bin-line pr-2"></i> {{ $t('label.delete file') }}
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table-pagination v-model="paginatedPurchaseOrderFiles" :all-rows="purchaseOrderFiles" :perPage="perPage">
                                </table-pagination>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="tabs-btn-gallery" role="tabpanel">
                            <!-- Gallery File Table -->
                            <div v-if="purchaseOrderFiles.length == 0">
                                <div class="card-body m-0 py-0">
                                    <div class="bg-white text-center row align-items-center m-0">
                                        <i class="ri-folders-line ni-8x w-100 color-gray-400"></i>
                                        <p class="w-100 font-weight-normal">{{ $t('label.no files have been added to this purchase order') }}.</p>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="file-gallery p-4">
                                <div class="form-group d-flex align-items-center justify-content-end row">
                                    <label class="form-control-label text-muted text-right m-0 col-lg-2 col-12">{{ $t('label.sorted by') }}</label>
                                    <select class="form-control p-1 h-100 col-lg-3 col-12" @change="sortByDate($event)">
                                        <option value="asc">{{ $t('label.date uploaded (ascending)') }}</option>
                                        <option value="desc">{{ $t('label.date uploaded (descending)') }}</option>
                                    </select>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="card-container pointer pb-4 col-xl-2 col-lg-4 col-md-4 col-xs-12" v-for="(file, index) in purchaseOrderFiles" :key="index">
                                            <div id="my-table" class="card p-0 m-0" @mouseover="showFileBtns(index)" @mouseout="hideFileBtns(index)">
                                                <img class="file-image" :src="file.filename_path" />
                                                <div :class="'text-center file-buttons file-buttons-' + index">
                                                    <div class="row file-container">
                                                        <button type="button" class="btn btn-outline-primary w-100" data-toggle="modal" data-target="#viewFileModal" @click="viewFileModal(file.id)">
                                                            <i class="ri-eye-line mrn-5"></i>
                                                            {{ $t('label.view') }}
                                                        </button>
                                                        <!-- <a :href="'/' + purchaseOrderFileDest + '/' + file.filename" class="btn btn-outline-primary w-100" download> -->
                                                        <a @click="$downloadFile(file.filename_path)" class="btn btn-outline-primary w-100">
                                                            <div>{{ $t('label.download') }}</div>
                                                            <i class="ri-download-line mrn-5"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="card-body pt-3 card-filename">
                                                    <a href="#" data-toggle="modal" data-target="#viewFileModal" @click="viewFileModal(file.id)" class="form-control-label">
                                                        <span v-if="file.display_name">{{ file.display_name }}</span>
                                                        <span v-else>{{ file.filename }}</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Upload Modal -->
            <div class="modal fade" id="addPurchaseOrderFilesModal" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document" style="max-width: 800px;">
                    <div class="modal-content dropzone-file">
                        <div class="modal-header border-bottom">
                            <h5 class="modal-title" id="addPurchaseOrderFilesModalTitle">{{ $t('label.upload files') }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="cancelFileUpload">
                                <i class="ri-close-line"></i>
                            </button>
                        </div>
                        <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" :destroyDropzone="false" v-on:vdropzone-upload-progress="progress" v-on:vdropzone-total-upload-progress="totalProgress" v-on:vdropzone-success="getUploadedFiles" v-on:vdropzone-error="getError" v-on:vdropzone-removed-file="removedFile">
                        </vue-dropzone>
                        <div class="modal-footer border-top">
                            <button type="button" class="btn btn-link  ml-auto close2editfile" data-dismiss="modal" @click="cancelFileUpload">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Upload Modal Details -->
            <div class="modal fade" id="addPurchaseOrderFileDetailsModal" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document" style="max-width: 800px;">
                    <div class="modal-content">
                        <!-- Upload Header -->
                        <div class="modal-header border-bottom">
                            <h5 class="modal-title" id="addPurchaseOrderFileDetailsModalTitle">{{ $t('label.upload files') }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="cancelFileUpload">
                                <i class="ri-close-line"></i>
                            </button>
                        </div>
                        <!-- Upload Form -->
                        <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="saveFile">
                            <div class="modal-body py-0">
                                <div class="row p-3" v-for="(file, index) in uploadedFilesUpload" :key="index">
                                    <div v-if="file.fileType == 'pdf' || file.fileType == 'xlsx' || file.fileType == 'pptx' || file.fileType == 'docx' || file.fileType == 'doc' || file.fileType == 'txt' || file.fileType == 'rtf' || file.fileType == 'heic' || file.fileType == 'tiff'" class="image-container image-container-small" :style="'background-image: url(/' + file.filePath + ')'"></div>
                                    <div v-else class="image-container image-container-small" :style="'background-image: url(' + file.dataURL + ')'"></div>
                                    <div class="col-10 pl-4">
                                        <div class="row">
                                            <div class="col-sm">
                                                <div class="form-group">
                                                    <label class="form-control-label text-muted" for="input-name">{{ $t('label.file name') }}</label>
                                                    <input v-model="file.upload.filename" type="text" name="name" class="form-control" minlength="5" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 p-0">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.description') }}</label>
                                                <textarea v-model="file.upload.description" name="description" class="form-control" placeholder="Description" required autofocus></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer border-top d-flex justify-content-between">
                                <button type="submit" class="btn btn-primary" :disabled="isSavingFiles">{{ $t('label.save files') }}</button>
                                <button type="button" class="btn btn-link  ml-auto close2editfile" data-dismiss="modal" @click="cancelFileUpload">{{ $t('label.cancel') }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- View Modal -->
            <div class="modal fade" id="viewFileModal" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document" v-for="(file, index) in purchaseOrderFiles" :key="index">
                    <div class="modal-content" v-if="file.id == purchaseOrderFileId">
                        <!-- View Header -->
                        <div class="modal-header border-bottom">
                            <h5 class="modal-title" id="viewFileModalTitle">
                                <span v-if="file.display_name">{{ file.display_name }}</span>
                                <span v-else>{{ file.filename }}</span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <i class="ri-close-line"></i>
                            </button>
                        </div>

                        <div class="modal-body p-0">
                            <div class="row">
                                <!-- View of the file -->
                                <div class="col-lg-8 col-12 p-4 text-center" style="background: #F6F9FC;">
                                    <div v-if="file.fileType == 'pdf' || file.fileType == 'xlsx' || file.fileType == 'pptx' || file.fileType == 'docx' || file.fileType == 'doc' || file.fileType == 'txt' || file.fileType == 'rtf' || file.fileType == 'heic' || file.fileType == 'tiff'">
                                        <div class="w-100">
                                            <img class="w-25" src="/images/icons/other-file.png" alt="Card image cap">
                                        </div>
                                        <div class="pt-3 pb-4 w-100">{{ $t('label.Preview unavailable for this file type') }}.</div>
                                        <!-- <a class="btn btn-primary" :href="'/' + purchaseOrderFileDest + '/' + file.filename" download> -->
                                        <a class="btn btn-primary" @click="$downloadFile(file.filename_path)">
                                            <i class="ri-download-line pr-2"></i>
                                            {{ $t('label.download') }}
                                        </a>
                                    </div>
                                    <img v-else class="mw-100" :src="file.filename_path" alt="Card image cap">
                                </div>
                                <!-- File Details -->
                                <div class="col-lg-4 col-12 p-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <h1 class="p-0 col-lg-10" style="word-break: break-all;">
                                                    <span v-if="file.display_name">{{ file.display_name }}</span>
                                                    <span v-else>{{ file.filename }}</span>
                                                </h1>
                                                <button type="button" class="btn btn-default btn-sm m-0 p-0 dropdown-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fas fa-ellipsis-h"></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                    <a class="dropdown-item d-flex align-items-center" href="#" data-dismiss="modal" data-toggle="modal" data-target="#editFileModal" @click="editFileModal(file)">
                                                        <i class="ri-pencil-line pr-2"></i> {{ $t('label.edit file') }}
                                                    </a>
                                                    <!-- <a class="dropdown-item d-flex align-items-center" :href="'/' + purchaseOrderFileDest + '/' + file.filename" download> -->
                                                    <a class="dropdown-item d-flex align-items-center text-dark pointer" @click="$downloadFile(file.filename_path)">
                                                        <i class="ri-download-line pr-2"></i> {{ $t('label.download file') }}
                                                    </a>
                                                    <a class="dropdown-item d-flex align-items-center text-danger" href="#" data-dismiss="modal" data-toggle="modal" data-target="#deleteFileModal" @click="viewFileModal(file.id)">
                                                        <i class="ri-delete-bin-line pr-2"></i> {{ $t('label.delete file') }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.description') }}</label>
                                                <div class="input-value">{{ file.description }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.uploaded by') }}</label>
                                                <div class="input-value">
                                                    <img class="rounded-circle" :src="file.created_by.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                                    <span class="cursor-pointer px-2" @click="getUserInfo(user.id, 'Uploaded By')">{{ file.created_by.name }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.uploaded date') }}</label>
                                                <div class="input-value">
                                                    {{ file.created_at | formatDate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- View Footer -->
                        <div class="modal-footer border-top d-flex justify-content-between">
                            <!-- <a class="btn btn-primary" :href="'/' + purchaseOrderFileDest + '/' + file.filename" download> -->
                            <a class="btn btn-primary text-white" @click="$downloadFile(file.filename_path)">
                                <i class="ri-download-line pr-2"></i>
                                {{ $t('label.download') }}
                            </a>
                            <button type="button" class="btn btn-link  ml-auto close2editfile" data-dismiss="modal">{{ $t('label.close') }}</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Edit Modal -->
            <div class="modal fade" style="z-index:10" id="editFileModal" tabindex="-1" role="dialog">
                <div class="modal-dialog" style="z-index:10" role="document">
                    <div class="modal-content" style="z-index:10">
                        <!-- Edit Header -->
                        <div class="modal-header border-bottom">
                            <h5 class="modal-title" id="editFileModalTitle">
                                <span v-if="purchaseOrderFile.display_name">{{ purchaseOrderFile.display_name }}</span>
                                <span v-else>{{ purchaseOrderFile.filename }}</span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <i class="ri-close-line"></i>
                            </button>
                        </div>
                        <!-- Edit Details -->
                        <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="updateFileDetails">
                            <div class="modal-body p-0">
                                <div class="row">
                                    <div class="col-lg-8 col-12 p-4 text-center" style="background: #F6F9FC;">
                                        <div v-if="purchaseOrderFile.fileType == 'pdf' || purchaseOrderFile.fileType == 'xlsx' || purchaseOrderFile.fileType == 'pptx' || purchaseOrderFile.fileType == 'docx' || purchaseOrderFile.fileType == 'doc' || purchaseOrderFile.fileType == 'txt' || purchaseOrderFile.fileType == 'rtf' || purchaseOrderFile.fileType == 'heic' || purchaseOrderFile.fileType == 'tiff'">
                                            <div class="w-100">
                                                <img class="w-25" src="/images/icons/other-file.png" alt="Card image cap">
                                            </div>
                                            <div class="pt-3 pb-4 w-100">{{ $t('label.Preview unavailable for this file type') }}.</div>
                                            <a class="btn btn-primary text-white" @click="$downloadFile(purchaseOrderFile.filename_path)">
                                                <i class="ri-download-line pr-2"></i>
                                                {{ $t('label.download') }}
                                            </a>
                                        </div>
                                        <div v-else>
                                            <img v-if="purchaseOrderFile.filename_path" class="mw-100" :src="purchaseOrderFile.filename_path" alt="Card image cap">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12 p-4">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label class="form-control-label" for="input-name">{{ $t('label.file name') }}</label>
                                                    <input class="form-control" v-model="purchaseOrderFile.name" type="text" minlength="5" required />
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label class="form-control-label" for="input-name">{{ $t('label.file description') }}</label>
                                                    <textarea v-model="purchaseOrderFile.description" name="description" class="form-control" rows="5" placeholder="Description" required autofocus></textarea>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.uploaded by') }}</label>
                                                    <div class="input-value">
                                                        <img class="rounded-circle" :src="purchaseOrderFile.created_by.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                                        <span class="cursor-pointer px-2" @click="getUserInfo(user.id, 'Uploaded By')">{{ purchaseOrderFile.created_by.name }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.uploaded date') }}</label>
                                                    <div class="input-value">
                                                        {{ purchaseOrderFile.created_at | formatDate }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-right px-3">
                                            <button type="submit" class="btn btn-default">{{ $t('label.save') }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Edit Footer -->
                            <div class="modal-footer border-top d-flex justify-content-between">
                                <a class="btn btn-primary text-white" @click="$downloadFile(purchaseOrderFile.filename_path)">
                                    <i class="ri-download-line pr-2"></i>
                                    {{ $t('label.download') }}
                                </a>
                                <button type="button" class="btn btn-link  ml-auto close2editfile" data-dismiss="modal">{{ $t('label.close') }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Delete Modal -->
            <div class="modal fade" id="deleteFileModal" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
                    <div class="modal-content bg-gradient-danger">
                        <!-- Delete Header -->
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <!-- Delete Form Details -->
                        <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="deleteFile">
                            <div class="modal-body">
                                <div class="py-3 text-center">
                                    <i class="ri-error-warning-line ni-3x"></i>
                                    <h4 class="heading mt-4 text-">{{ $t('label.ARE YOU SURE YOU WANT TO DELETE THIS FILE') }}?</h4>
                                    <p>{{ $t('label.This action cannot be undone') }}!</p>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="submit" class="btn btn-white" id="deleteBtn">{{ $t('label.Yes, Delete') }}</button>
                                <button type="button" class="btn btn-link text-white ml-auto" data-dismiss="modal">{{ $t('label.cancel') }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.dropzone .dz-preview .dz-error-message {
    opacity: 1 !important;
    top: 0 !important;
}

.file-name-bold {
    font-weight: 600;
}
</style>

<script>
import TablePagination from '@/components/TablePagination';

import bus from '@/utils/event-bus';
import * as $ from 'jquery';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { mapGetters } from 'vuex';
;

export default {
    props: ['purchaseOrderId', 'assetType'],
    name: 'app',
    components: {
        vueDropzone: vue2Dropzone,
        TablePagination
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            // allPurchaseOrderFiles: [],
            paginatedPurchaseOrderFiles: [],
            perPage: 5,
            uploadedFiles: [],
            uploadedFilesUpload: [],
            purchaseOrderFileId: 0,
            purchaseOrderFileApi: "",
            purchaseOrderFileDest: "",
            inputfile: null,
            dataTable: null,
            errorDropzone: null,
            elmDropdown: false,
            showDropdown: false,
            isUploadCancel: false,
            isSavingFiles: false,
            form: {
                add: {
                    name: null,
                    description: null,
                    download: null,
                },
                edit: {
                    name: null,
                    description: null,
                    download: null,
                    download2: null,
                    id: null,
                }
            },
            purchaseOrderFile: {
                created_by: {
                    name: null,
                    picture: null
                }
            },
            options: {
                rowClassCallback: function (row) {
                    return 'file-row-' + row.id;
                },
                perPageValues: [],
                headings: {
                    user_image: '',
                    download: this.$t('label.file name'),
                    description: this.$t('label.description'),
                    'created_by.name': this.$t('label.uploaded by'),
                    created_at: this.$t('label.uploaded date'),
                    actions: ''
                },
                sortable: ['name', 'description', 'download', 'created_by.name', 'created_at'],
                filterable: false,
            },
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
              //  url: 'https://putsreq.com/sMLPgj8Pe0jHquCDgZcs',
                maxFilesize: 10,
                addRemoveLinks: true,
                headers: {
                    'Cache-Control': '',
                },
                dictDefaultMessage: `<div><span>Drag and drop files here</span><br>
                            <span>OR</span><br>
                            <button type="button" class="btn btn-lg btn-primary">Browse Files</button></div>`,
            },
            type: 0
        }
    },
    computed: {
        ...mapGetters({
            purchaseOrderFiles: 'purchaseOrder/purchaseOrderFiles',
        }),
    },
    mounted() {
        // if (this.assetType === "vehicle") {
        //     this.purchaseOrderFileApi += "vehicle-files/";
        //     this.purchaseOrderFileDest = "vehicle-uploads";
        // } else if (this.assetType === "item") {
        //     this.purchaseOrderFileApi += "asset-item-files/";
        //     this.purchaseOrderFileDest = "asset-item-uploads";
        // }

        this.purchaseOrderFileApi += "purchase-order-files/";
        this.purchaseOrderFileDest = "purchase-order-files";
        this.getPurchaseOrderFiles();
    },
    methods: {
        template: function () {
            return `<div class="dz-preview dz-file-preview">
                        <div class="dz-image">
                            <div data-dz-thumbnail-bg></div>
                        </div>
                        <i class="fas fa-upload"></i>
                        <div class="dz-details">
                            <div class="dz-filename"><span data-dz-name></span></div>
                        </div>
                        <div class="dz-progress-percentage"></div>
                        <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                        <div class="dz-error-message"><span data-dz-errormessage></span></div>
                        <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                        <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                    </div>`;
        },
        getPurchaseOrderFiles() {
            this.$store.dispatch('purchaseOrder/getPurchaseOrderFiles', this.purchaseOrderId).then(response => {
               // this.type = response.data.type;
                // this.allPurchaseOrderFiles = response.data.result;
                // this.purchaseOrderDataTable();
                // bus.$emit('get-file-count', this.allPurchaseOrderFiles.length);
            })
        },
        viewUploadFileModal() {
            this.cancelFileUpload();
            this.isUploadCancel = false;
        },
        purchaseOrderDataTable() {
            if (this.dataTable !== null)
                this.dataTable.destroy();

            // Initialize data table
            this.$nextTick(() => {
                this.dataTable = $('#datatable-purchaseOrderFiles-view').DataTable({
                    keys: !0,
                    ordering: false,
                    pageLength: 5,
                    responsive: true,
                    autoWidth: false,
                    "searching": false,
                    "lengthChange": false,
                    "bDestroy": true,
                    dom: "<'row'<'col-sm-12'f>>" +
                        "<'row'<'col-sm-12' t>>" +
                        "<'row p-2'<'col-sm-1' i><'col-sm-11' p>>",
                    language: {
                        paginate: {
                            previous: "<i class='fas fa-angle-left'>",
                            next: "<i class='fas fa-angle-right'>"
                        }
                    }
                });
            });
        },
        cancelFileUpload() {
            this.isUploadCancel = true;
            if (this.uploadedFilesUpload.length) {
                this.$refs.myVueDropzone.removeAllFiles(true);
            }
            this.uploadedFiles = [];
            this.uploadedFilesUpload = [];
        },
        progress: function (file, progress, bytesSent) {
            $(file.previewElement).find(".dz-progress-percentage").text(parseInt(progress) + " %");
            if (progress == 100) {
                $(file.previewElement).find(".dz-progress .dz-upload").css("background", "#2bce89");
                setTimeout(function () {
                    $(file.previewElement).find(".dz-success-mark").css("opacity", "1");
                }, 300);
            }
        },
        totalProgress: function (totalUploadProgress, totalBytes, totalBytesSent) {
            if (totalUploadProgress === 100 && !this.isUploadCancel && !this.errorDropzone) {
                setTimeout(function () {
                    $('#addPurchaseOrderFilesModal').modal('hide');
                    $('#addPurchaseOrderFileDetailsModal').modal('show');
                }, 1000);
            }
        },
        viewFileModal(id) {
            this.purchaseOrderFileId = id;
        },
        showFileBtns(id) {
            $(".file-buttons-" + id).show();
            $(".file-buttons-" + id).addClass('file-hover');
            $('#file-hover-' + id).css('background-color', '#e9ecef');
        },
        hideFileBtns(id) {
            $(".file-buttons-" + id).hide();
            $(".file-buttons-" + id).removeClass('file-hover');
            $('#file-hover-' + id).css('background-color', 'unset');
        },
        deleteFile() {
            $('#deleteFileModal').modal('hide');

            axios.post(this.purchaseOrderFileApi + "destroy", {
                id: this.purchaseOrderFileId
            }).then(response => {
                this.getPurchaseOrderFiles();
                bus.$emit('banner-success', {
                    message: this.$t('label.purchase order file deleted successfully') + "!"
                });
            }).catch(err => console.log(err));
        },
        sortByDate(event) {
            let sort = event.target.value;
            this.allPurchaseOrderFiles.sort((a, b) => a.weight < b.weight ? 1 : -1);
        },
        savePurchaseOrderFile(files, index, activityLogId) {
            let file = files.upload;
            let description = file.description;
            let dataRequest = {
                purchase_order_id: this.purchaseOrderId,
                name: file.filename,
                description: description,
                file_type: files.type,
                size: file.total,
                purchase_order_activity_log: activityLogId
            };

            axios.post(this.purchaseOrderFileApi + "store", dataRequest).then(response => {
                this.form.add.description = null;

                const fileUpload = new FormData();
                fileUpload.append('download', this.uploadedFiles[index]);
                fileUpload.append('file_name', JSON.stringify(file.filename));
                fileUpload.append('id', JSON.stringify(response.data.id));

                //Save file to DB after table element has been validated and created.
                axios.post(this.purchaseOrderFileApi + "upload-file", fileUpload).then(response => {
                    this.getPurchaseOrderFiles();
                    bus.$emit('banner-success', {
                        message: this.$t('label.purchase order file uploaded successfully') + "!"
                    });
                    bus.$emit('reloadActivityLogs');
                }).catch(err => console.log(err));
            }).catch(err => console.log(err));
        },
        getUploadedFiles: function (file, response) {
            let filename = file.upload.filename;
            let fileType = filename.split('.').pop();
            file.fileType = fileType;

            if (fileType === 'pdf') {
                file.filePath = 'images/icons/doc-pdf.png';
                file.upload.filePath = 'images/icons/doc-pdf.png';
            } else if (fileType === 'xlsx') {
                file.filePath = 'images/icons/doc-excel.png';
                file.upload.filePath = 'images/icons/doc-excel.png';
            } else if (fileType === 'pptx') {
                file.filePath = 'images/icons/doc-ppt.png';
                file.upload.filePath = 'images/icons/doc-ppt.png';
            } else if (fileType === 'docx' || fileType === 'doc') {
                file.filePath = 'images/icons/doc-word.png';
                file.upload.filePath = 'images/icons/doc-word.png';
            } else if (fileType === 'txt' || fileType === 'rtf' || fileType === 'heic' || fileType === 'tiff') {
                file.filePath = 'images/icons/doc-other.png';
                file.upload.filePath = 'images/icons/doc-other.png';
            } else {
                file.filePath = this.purchaseOrderFileDest + '/' + encodeURI(filename);
                file.upload.filePath = this.purchaseOrderFileDest + '/' + encodeURI(filename);
            }

            this.uploadedFiles.push(file);
            this.uploadedFilesUpload.push(file);
        },
        saveFile() {
            $('#addPurchaseOrderFileDetailsModal').modal('hide');
            this.isSavingFiles = true;

            let dataReq = {
                purchase_order_id: this.purchaseOrderId,
                log_type: 'files'
            }
            // save activity log first then save the files
            this.$store.dispatch('purchaseOrder/savePurchaseOrderActivityLog', dataReq).then(response => {
                this.uploadedFilesUpload.forEach((file, index) => {
                    file.upload.filename = file.upload.filename.split('.')[0] + '.' + file.fileType;
                    this.savePurchaseOrderFile(file, index, response.data.id);
                });
            });

            setTimeout(() => {
                this.isSavingFiles = false;
            }, 300);
        },
        updateFileDetails() {
            $('#editFileModal').modal('hide');

            axios.post(this.purchaseOrderFileApi + "update", this.purchaseOrderFile).then(response => {
                this.getPurchaseOrderFiles();
                bus.$emit('banner-success', {
                    message: this.$t('label.purchase order file updated successfully') + "!"
                });
                bus.$emit('reloadActivityLogs');
            }).catch(err => console.log(err));
        },
        onFileChange(e) {
            this.form.add.download = e.target.files[0];
        },
        onFileChange2(e) {
            this.form.edit.download2 = e.target.files[0];
        },
        editFileModal(data) {
            this.purchaseOrderFile = JSON.parse(JSON.stringify(data));

            if (this.purchaseOrderFile.display_name)
                this.purchaseOrderFile.name = this.purchaseOrderFile.display_name;
            else
                this.purchaseOrderFile.name = this.purchaseOrderFile.filename;
        },
        getError() {
            this.errorDropzone = this.$refs.myVueDropzone.getRejectedFiles();
            if (this.errorDropzone)
                $('.dropzone-file .dropzone .dz-error-message').attr('style', 'display: unset !important');
        },
        removedFile(removedFile) {
            this.errorDropzone = null;
            this.uploadedFilesUpload.forEach((file, idx) => {
                if (removedFile.filePath === file.filePath)
                    this.uploadedFilesUpload.splice(idx, 1);
            });
        }
    }
}
</script>
