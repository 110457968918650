<template>
    <div v-if="customer">
        <div class="header bg-primary pb-6 mobile-p-0">
            <div class="container-fluid">
                <div class="header-body">
                    <div class="row align-items-center py-4">
                        <div class="col-lg-6">
                            <breadcrumbs :title="customer.name">
                                <!-- Breakcrumb slots -->
                                <!-- <li class="breadcrumb-item">
                                    <router-link class="text-primary pointer" :to="{ name: 'reports' }">{{ $t('label.reports') }}</router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    {{ $t('label.invoice report') }}
                                </li> -->
                            </breadcrumbs>
                        </div>
                        <div class="col-lg-6 col-5 text-right">
                            <button v-if="customer.account_type == 0" 
                                type="button" 
                                class="btn btn-neutral" 
                                @click="editCustomerModal()">
                                {{ $t('label.edit lead') }}
                            </button>
                            <button v-else 
                                type="button" 
                                class="btn btn-neutral" 
                                @click="editCustomerModal()">
                                {{ $t('label.edit customer') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid mt--6 mobile-mt-140p">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <div v-if="customer" class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-lg-12">
                                    <div class="row mb-3">
                                        <div class="col-12 col-lg-auto col-xs-auto align-self-center">
                                            <img class="rounded-circle" v-if="customer.logo" :src="customer.logo" alt="" style="border: 1px solid rgba(0, 0, 0, 0.1); width: 72px; height: 72px; object-fit: cover;">
                                            <img class="rounded-circle" v-else :src="require('@/assets/images/company_default.png')" alt="" style="border: 1px solid rgba(0, 0, 0, 0.1); width: 72px; height: 72px; object-fit: cover;">
                                        </div>
                                        <div class="col-12 col-lg-auto col-xs-auto">
                                            <div class="m-0 d-flex align-items-center">
                                                <span class="m-0  font-weight-bold font-size-26px">{{ customer.name }}</span>
                                                <span v-if="customer.status == 1" class="badge badge-sm bg-success text-white ml-3">Active</span>
                                                <span v-else class="badge badge-sm bg-light text-white ml-3">Inactive</span>
                                            </div>
                                            <div><a :href="customer.domain" target="_blank" class="m-0 font-weight-bold font-size-14px">{{ customer.domain }}</a></div>
                                            <div class="m-0 font-size-16px font-weight-bold">{{ customer.phone_1 }}</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-lg-3">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.contact') }}</label>
                                                <h3 class="border-bottom">{{ customer.first_name }} {{ (customer.last_name) ? customer.last_name : '' }}</h3>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-3">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.email') }}</label>
                                                <h3 class="border-bottom">{{ customer.email }}</h3>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-6">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.address') }}</label>
                                                <h3 class="border-bottom">
                                                    <span>{{ customer.address_1 }}, </span>
                                                    <span v-if="customer.address_2">{{ customer.address_2 }}, </span>
                                                    <span v-if="customer.city">{{ customer.city }}, </span>
                                                    <span v-if="customer.state">{{ customer.state }}, </span>
                                                    <span v-if="customer.postal_code">{{ customer.postal_code }}</span>
                                                    <span class="text-muted cursor-pointer2 float-right" @click="openMap"><i class="ri-road-map-line heading-title"></i></span>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <!-- responsible user -->
                                        <div class="col-12 col-lg">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.responsible') }}</label>
                                                <h3 v-if="customer" class="border-bottom cursor-pointer">
                                                    <img v-if="getEmployee(customer.user_id).picture" class="rounded-circle" :src="getEmployee(customer.user_id).picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                                    {{ getEmployee(customer.user_id).name }}
                                                </h3>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.budget') }}</label>
                                                <h3 class="border-bottom">${{ parseFloat(customer.budget).toFixed(2) | formatThousands }}</h3>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.last updated') }}</label>
                                                <h3 class="border-bottom">{{ customer.updated_at | formatDateOnly }}</h3>
                                            </div>
                                        </div>
                                        <div v-if="customer.converted_date" class="col-12 col-lg">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.converted date') }}</label>
                                                <h3 class="border-bottom">{{ customer.converted_date | formatDateOnly }}</h3>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.created date') }}</label>
                                                <h3 class="border-bottom">{{ customer.created_at | formatDateOnly }}</h3>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- For Leads -->
                                    <div v-if="customer.account_type == 0" class="row">
                                        <div class="col-12">
                                            <ul class="ribbon row m-0 mt-4">
                                                <li :order="data.order" :data-id="data.id" class="col" v-for="data in leadStatus" :key="data.id" :class="classUpdate(data)" @click="changeStatus(data)">
                                                    <i class="ri-check-line"></i><i class="ri-close-line"></i> {{ data.name }}
                                                </li>
                                                <li :order="data.order" :data-id="data.id" class="col" v-for="data in wonAndLost" :key="data.id" :class="classUpdate(data)" @click="changeStatus(data)">
                                                    <i class="ri-check-line"></i><i class="ri-close-line"></i> {{ data.name }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Customer Jobs section -->
                <customer-jobs-component v-if="customer.account_type == 1" :customerid="customer.id"></customer-jobs-component>
                <!-- Customer Notes section -->
                <customer-notes-component :customerid="customer.id" :account_type="customer.account_type"></customer-notes-component>
                <!-- Customer Invoices section -->
                <InvoicesComponent v-if="customer.account_type == 1" :customerid="customer.id"></InvoicesComponent>
            </div>
        </div>

        <!-- Edit Customer Modal -->
        <modal name="customerAddEditModal" height="auto" width="98%" :max-width="1024" :reset="true" :scrollable="true" :adaptive="true">
            <modal-customer :customerdata="customer" @cancelFnc="$modal.hide('customerAddEditModal')" @returnFnc="customerReturn"></modal-customer>
        </modal>
    </div>
</template>
<script>
import ModalCustomer from '@/components/ModalCustomer'
import InvoicesComponent from '@/components/InvoicesComponent.vue';
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('userData')
export default {
    props: ['customerInfo', 'customerId'],
    emits: ['returnFnc'],
    components: {
        ModalCustomer,
        InvoicesComponent
    },
    data() {
        return {
            openModal: false,
            leadStatus: [],
            wonAndLost: [],
            customer: null,
            tableType: 1,
            showAddEditCustomerLeadModal: false,
            reloadKey: 0,
            allUsers: [],
            logo: null,
        }
    },
    computed: {
        // ...mapState({
        //     allUsers: state => state.allUsers,
        // }),
    },
    created() {
        // go back if the prop customerInfo is not passed
        if (!this.customerInfo && this.customerId) {
            this.getCustomer();
        } else {
            this.customer = this.customerInfo;
        }

        this.getCustomer();
        // this.customer = this.customerInfo
        this.getLeadStatus()
        if (!this.allUsers.length)
            this.getAllActiveUsers();

    },
    mounted() {
    },
    methods: {
        // ...mapActions(['getAllUsers']),
        getCustomer() {
            axios.get(`/get-customer-by-id/${this.customerId}`).then(rsp => {
                this.customer = rsp.data.result;
                this.logo = rsp.data.logo;
                
            }).catch(err => console.error(err));
        },
        getLeadStatus() {
            axios.get('/get-lead-status')
                .then(response => {
                    this.leadStatus = response.data.result;
                    this.wonAndLost = response.data.won_and_lost;
                    var that = this;
                    $('.ribbon').hide()
                    setTimeout(function () {
                        $('.ribbon li').each(function () {
                            let colorId = $(this).attr('color-id');
                            let id = $(this).attr('data-id');
                            if (that.customer?.lead_status_id == id) {
                                $(this).addClass('activeRibbon color-' + colorId);
                            }
                        })

                        if (that.customer?.lead_status) {
                            if (that.customer?.lead_status.order == '99') {
                                $('.activeRibbon').prevAll().addClass('color-DD1A3E');
                                $('.ri-check-line').hide()
                                $('.ri-close-line').hide()
                                $('.ribbon li').each(function () {
                                    if ($(this).hasClass('color-DD1A3E')) {
                                        if ($(this).attr('order') == '98' || $(this).attr('order') == '99') {
                                            $(this).find('.ri-close-line').show();
                                            $(this).find('.ri-check-line').hide()
                                        } else {
                                            $(this).find('.ri-check-line').show()
                                        }

                                    } else {
                                        $(this).find('.ri-check-line').hide()
                                    }

                                })
                            } else {
                                $('.activeRibbon').prevAll().addClass('color-0E914F');
                                $('.activeRibbon').nextAll().addClass('color-DEE2E6');
                                $('.ri-check-line').hide()
                                $('.ri-close-line').hide()
                                $('.ribbon li').each(function () {
                                    if ($(this).hasClass('color-0E914F')) {
                                        $(this).find('.ri-check-line').show()
                                    } else {
                                        $(this).find('.ri-check-line').hide()
                                    }

                                })
                            }
                        }
                        $('.activeRibbon').prevAll().addClass('color-0E914F');
                        $('.activeRibbon').nextAll().addClass('color-DEE2E6');
                        $('.ribbon').show()
                    }, 1000)
                })
                .catch(err => console.error("API ERROR", err));
        },
        editCustomerModal() {
            this.openModal = true;
            this.$modal.show('customerAddEditModal');
        },
        closeAddEditModal() {
            this.showAddEditCustomerLeadModal = false;
            let id = this.customerInfo.id;
            this.$modal.hide('customerEditModal');
        },
        changeStatus(data) {
            $('.ribbon').hide()
            $('.ribbon li').each(function () {
                $(this).removeClass().addClass('col')
                let colorId = $(this).attr('color-id');
                let id = $(this).attr('data-id');
                if (id == data.id) {
                    $(this).addClass('activeRibbon color-' + colorId);
                }
            })
            setTimeout(function () {
                if (data.order == '99') {
                    $('.activeRibbon').prevAll().addClass('color-DD1A3E');
                    $('.ri-check-line').hide()
                    $('.ri-close-line').hide()
                    $('.ribbon li').each(function () {
                        if ($(this).hasClass('color-DD1A3E')) {
                            if ($(this).attr('order') == '98' || $(this).attr('order') == '99') {
                                $(this).find('.ri-close-line').show();
                                $(this).find('.ri-check-line').hide()
                            } else {
                                $(this).find('.ri-check-line').show()
                            }

                        } else {
                            $(this).find('.ri-check-line').hide()
                        }

                    })
                } else {
                    $('.activeRibbon').prevAll().addClass('color-0E914F');
                    $('.activeRibbon').nextAll().addClass('color-DEE2E6');
                    $('.ri-check-line').hide()
                    $('.ri-close-line').hide()
                    $('.ribbon li').each(function () {
                        if ($(this).hasClass('color-0E914F')) {
                            $(this).find('.ri-check-line').show()
                        } else {
                            $(this).find('.ri-check-line').hide()
                        }

                    })
                }
                $('.ribbon').show()
            }, 1000)
            let params = {
                customer_id: this.customer.id,
                lead_status_id: data.id,
                order: data.order
            }
            axios.post('/update-lead-status', params)
                .then(response => {
                    this.customer = response.data.result;
                    // this.getLeadStatus();
                    if (response.data.refresh) {
                        location.reload();
                    }
                })
                .catch(err => console.error("API ERROR", err));
        },
        classUpdate(data) {
            // let newColorClass = '';
            // newColorClass = this.customer.lead_status_id == data.id ? 'activeRibbon color-' + data.color.substring(1) : ''
            // return newColorClass;
        },
        openMap() {
            let complete_address = this.customer.address_1 + ", " + this.customer.city + ", " + this.customer.state + ", " + this.customer.postal_code;
            window.open('http://maps.google.com/?q=' + complete_address);
        },
        customerReturn(customer) {
            // reassign customer to changed customer
            this.customer = customer
            this.$modal.hide('customerAddEditModal')
        },
        getEmployee(userId) {
            let idx = this.allUsers.findIndex(request => {
                if (request.id === parseInt(userId)) {
                    return true
                }
            })
            return (idx >= 0) ? this.allUsers[idx] : {}
        },
        getAllActiveUsers() {
            return axios.get('/get-all-users-for-customers').then(response => {
                this.allUsers = response.data.result;
            }).catch(err => console.error(err));
        },
    }
}
</script>
<style scoped>
h3 {
    height: 38px;
}
</style>