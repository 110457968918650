<template>
	<!-- <div v-if="invoice"> -->
	<div>
		<div class="modal-content">
			<div class="modal-header border-bottom">
				<h5 class="modal-title" id="invoiceViewModalTitle" v-if="invoice.invoice_number">
					{{ $t('label.invoice') }} - {{ invoice.invoice_number.invoice_number }}
					<span class="badge badge-lg badge-warning ml-3" v-if="invoice.invoice_status.name == 'Draft'">{{ invoice.invoice_status.name }}</span>
					<span class="badge badge-lg badge-info ml-3" v-if="invoice.invoice_status.name == 'Sent'">{{ invoice.invoice_status.name }}</span>
					<span class="badge badge-lg badge-danger ml-3" v-if="invoice.invoice_status.name == 'Overdue'">{{ invoice.invoice_status.name }}</span>
					<span class="badge badge-lg badge-success ml-3" v-if="invoice.invoice_status.name == 'Paid'">{{ invoice.invoice_status.name }}</span>
				</h5>
				<button type="button" class="close" @click="$emit('cancelFnc')">
					<i class="ri-close-line"></i>
				</button>
			</div>
			<div class="modal-body">
				<div class="row m-0">

					<!-- Left Column -->
					<div class="col-8" v-if="currentPage == 1">
						<!-- <div class="col-12 pdf-preview" v-if="currentPage == 1"> -->
						<!-- <template v-if="currentPage == 1"> -->
						<div class="">
							<iframe id="InvoiceViewPdfFrame" src="" width="100%" height="850px"></iframe>
						</div>
						<!-- </template> -->
					</div>

					<!-- <div class="col-8 pdf-preview">
						<template v-if="currentPage == 1">
							<div class="row m-0">
								<div class="col-6 p-0">
									<div><img class="mb-3" :src="company.logo" style="height: 75px; object-fit: cover;" /></div>
									<template>
										<p>{{ company.name }}<br>
											{{ company.address_1 }} <br>
											{{ company.address_2 }} <br v-if="company.address_2">
											{{ company.city }}, {{ company.state }} {{ company.postal_code }}</p>
									</template>
								</div>
								<div class="col-6 p-0 text-right">
									<h1 class="m-0">{{ $t('label.invoice') }}</h1>
									<h3>{{ invoice.invoice_number_concat }}</h3>
									<h3 class="m-0 mt-4">{{ $t('label.date') }}:</h3>
									<p>{{ invoice.due_date }}</p>

								</div>
							</div>
							<div class="row m-0">
									<div class="col-6 p-0">
										<h3 class="m-0">{{$t('label.bill to')}}</h3>
										<div class="display_address col-auto p-0">
											<div>
												<p>{{ invoiceData.data.job.address_1 }} <br>
													{{ invoiceData.data.job.address_2 }} <br v-if="invoiceData.data.job.address_2">
													{{ invoiceData.data.job.city }}, {{ invoiceData.data.job.state }} {{ invoiceData.data.job.postal_code }}</p>
											
											</div>
										</div>
									</div>
							</div>
							<div class="row m-0 pt-2 pb-4">
								<table class="table table-flush table-sm">
									<thead class="thead-light">
										<tr class="p-0">
											<th scope="col" style="width:20rem">{{ $t('label.item') }}</th>
											<th></th>
											<th scope="col" class="text-right" style="">{{ $t('label.quantity') }}</th>
											<th scope="col" class="text-right" style="">{{ $t('label.amount') }}</th>
											
										</tr>
									</thead>
									<tbody>
										<tr v-for="(data, index) in invoice.invoice_item_list" :key="index">
											<td>{{ data.invoice_item.name }}</td>
											<td></td>
											<td class="text-right">{{ data.quantity }}</td>
											<td class="text-right">${{ data.invoice_item.amount }}</td>
											
										</tr>
									</tbody>
									<tbody class="border-top">
										<tr>
											<td colspan="2" class="align-top pt-2">&nbsp;</td>
											<td class="align-top pt-2">
												<h3 class="m-0">{{ $t('label.nontaxable subtotal') }}</h3>
												<h3 class="m-0">{{ $t('label.taxable subtotal') }}</h3>
												<h3 class="m-0">{{ $t('label.taxes') }}</h3>
											</td>
											<td colspan="2" class="align-top pt-2 text-right">
												<h3 class="m-0">${{ nonTaxItemTotal | formatThousands }}</h3>
												<h3 class="m-0">${{ invoiceItemSubTotal | formatThousands }}</h3>
												<h3 class="m-0">${{ invoiceOrderItemTaxes | formatThousands }}</h3>
											</td>
										</tr>
										<tr>
											<td colspan="2" class="border-0"></td>
											<td class="align-top pt-2 border-top">
												<h3 class="m-0">{{ $t('label.total') }}</h3>
											</td>
											<td colspan="2" class="align-top pt-2 border-top text-right">
												<h3 class="m-0">${{ invoice.total | formatThousands }}</h3>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div v-if="invoice.additional_notes" class="row m-0 pb-4">
								<div class="col-12 px-0">
									<h3 class="m-0">{{ $t('label.additional_notes') }}:</h3>
									<p class="lh-140">{{ invoice.additional_notes }}</p>
								</div>
							</div>
						</template>
					</div> -->



					<!-- right column -->
					<div class="col-4 p-0">
						<!-- Invoice Actions -->
						<div class="row m-0">
							<!-- Process Payment -->
							<div v-if="!invoice.mark_as_paid && invoice.balance != '0.00' && invoice.invoice_status.name == 'Sent'" class="col-12 mb-3">
								<button type="button" class="btn btn-primary w-100 text-uppercase" @click="paymentModal(invoice)">
									<i class="ri-bank-card-line"></i>{{ $t('label.process payment') }}
								</button>
							</div>
							<!-- Mark as Paid -->
							<div v-if="!invoice.mark_as_paid && invoice.balance != '0.00' && invoice.invoice_status.name == 'Sent'" class="col-12 mb-3">
								<button type="button" class="btn btn-outline-primary w-100 text-uppercase" @click="markAsPaid(invoice, 1)">
									<i class="ri-check-line"></i>{{ $t('label.mark as paid') }}
								</button>
							</div>
							<!-- Mark as Unpaid -->
							<div v-if="invoice.mark_as_paid" class="col-12 mb-3">
								<button type="button" class="btn btn-outline-primary w-100 text-uppercase" @click="markAsPaid(invoice, 0)">
									<i class="ri-check-line"></i> {{ $t('label.mark as unpaid') }}
								</button>
							</div>
							<!-- Send Invoice -->
							<div v-if="invoice.invoice_status.name == 'Draft'" class="col-12 mb-3">
								<button type="button" class="btn btn-outline-primary w-100 text-uppercase" data-toggle="modal" data-dismiss="modal" data-target="#invoiceSendModal" @click="invoiceSendModal(invoice)">
									<i class="ri-mail-send-line"></i> {{ $t('label.send invoice') }}
								</button>
							</div>
							<!-- Edit Invoice -->
							<div class="col-12 mb-3" v-if="invoice.invoice_status.name == 'Draft'">
								<button type="button" class="btn btn-outline-primary w-100 text-uppercase" @click="editInvoiceModal()">
									<i class="ri-pencil-line"></i> {{ $t('label.edit invoice') }}
								</button>
							</div>
							<!-- Delete Invoice -->
							<div class="col-12 mb-3" v-if="invoice.invoice_status.name == 'Draft'">
								<button type="button" class="btn btn-danger w-100 text-uppercase" @click="deleteNotify(invoice.id)">
									<i class="ri-delete-bin-line"></i> {{ $t('label.delete invoice') }}
								</button>
							</div>
						</div>

						<!-- Invoice Summary -->
						<div class="row m-0 px-3" :class="{ 'mt-4': invoice.invoice_status.name != 'Cancelled' }">
							<table class="table table-flush table-sm">
								<tbody>
									<tr class="invoice-summary-header">
										<td colspan="2">{{ $t('label.invoice summary') }}</td>
									</tr>
									<tr>
										<td>{{ $t('label.invoice total') }}</td>
										<td class="text-right">${{ parseFloat(invoice.total).toFixed(2) | formatThousands }}</td>
									</tr>
									<tr>
										<td>{{ $t('label.total payments') }}</td>
										<td class="text-right">${{ parseFloat(invoice.total - invoice.balance).toFixed(2) | formatThousands }}</td>
									</tr>
									<tr>
										<td class="border-bottom">{{ $t('label.balance due') }}</td>
										<td class="text-right border-bottom">${{ parseFloat(invoice.balance).toFixed(2) | formatThousands }}</td>
									</tr>
								</tbody>
							</table>
						</div>

						<!-- Payments -->
						<div class="row m-0 mt-4 px-3">
							<table v-if="!invoice.mark_as_paid" class="table table-flush table-sm">
								<tbody>
									<tr class="invoice-summary-header">
										<td colspan="2">{{ $t('label.payments') }}</td>
									</tr>
									<tr v-if="allpayments.length == 0" class="text-center">
										<td colspan="2">
											<img src="/images/payment-graphic.png" alt="">
											<p>{{ $t('label.no payments have been made on this invoice') }}.</p>
										</td>
									</tr>
									<tr v-for="(data, index) in allpayments" :key="index">
										<td class="text-left"><b>P-{{ data.payment_number.payment_number }}</b></td>
										<td class="text-right">${{ parseFloat(data.amount).toFixed(2) | formatThousands }}</td>
									</tr>
								</tbody>
							</table>
							<table v-if="invoice.mark_as_paid" class="table table-flush table-sm">
								<tbody>
									<tr class="invoice-summary-header">
										<td colspan="2">{{ $t('label.payments') }}</td>
									</tr>
									<tr class="text-center">
										<td colspan="2">
											<p class="mx-5 my-3">{{ $t('label.this invoice was manually marked as paid') }}.</p>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

				</div>
			</div>
			<div class="modal-footer border-top">
				<button type="button" class="btn btn-link  ml-auto close2addinvoice" @click="$emit('cancelFnc')">{{ $t('label.close') }}</button>
			</div>
		</div>

		<!-- Delete Modal -->
		<modal-delete :newid="newid" :deleteMethod="deleteInvoice" :deleteModalText="deleteModalText"></modal-delete>

		<DeleteModal v-if="viewDeleteModal" newId="ModalInvoiceView" :title="$t('label.ARE YOU SURE YOU WANT TO DELETE THIS INVOICE')" @customDeleteMethod="deleteInvoice" @onClose="hideDeleteNotify">
		</DeleteModal>
	</div>
</template>

<script>
import DeleteModal from '@/components/ModalDelete2.vue';
import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex';
export default {
	props: ['job', 'invoiceData', 'from'],
	emits: ['cancelFnc', 'reloadInvoices'],
	components: {
		DeleteModal
	},
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
			// companySettings: JSON.parse(localStorage.getItem('company_settings')),
			taxable: true,
			allpayments: [],
			jobData: null,
			delete_id: null,
			invoicedVpos: null,
			viewDeleteModal: false,
			invoice: {
				due_date: null,
				invoice_item_list: null,
				invoice_status: {
					name: null
				},
			},
			newid: 'DeleteInvoiceModalView',
			deleteModalText: {
				title: 'ARE YOU SURE YOU WANT TO DELETE THIS INVOICE?',
				button: 'Yes, Delete'
			},
			currentPage: 1,
			address: {
				address_1: null,
				address_2: null,
				state: null,
				postal_code: null,
			}
		}
	},
	created() {
		this.$store.dispatch('getCompanySettings').then(() => {
			// this.initForm();
			this.loader.hide();
		});
	},
	computed: {
		...mapGetters(['company']),
	},
	mounted() {
		bus.$on('deleteInvoice' + this.newid, () => {
			this.deleteInvoice();
		});

		this.loadLkupData(this.invoiceData);
	},
	methods: {
		async loadLkupData(item) {
			let promises = [];
			this.loader = this.$loading.show();

			promises.push(this.invoiceViewModal(item));
			promises.push(this.getInvoicedVpos());
			promises.push(this.getPdf());

			Promise.all(promises);
			this.loader.hide();
		},
		getPdf() {
			console.log('invoicedata', this.invoiceData);
			console.log('invoice', this.invoice);
			let url = `/invoice-pdf-file/${this.invoice.id}`;
			return axios.get(url, { responseType: 'blob' }).then(rsp => {
				console.log('rsp', rsp);
				const dataUrl = URL.createObjectURL(rsp.data);
				const iFrame = document.querySelector("#InvoiceViewPdfFrame");
				iFrame.src = dataUrl;
			}).catch((err) => console.log("err", err));
		},
		async getPayments() {
			return axios.get('/get-payments/' + this.invoice.id).then(response => {
				this.allpayments = response.data.result;
			}).catch(err => {
				//console.log('list carriers error:', error)
			});
		},
		async getInvoicedVpos() {
			return axios.get(`/vpo_get_by_invoice_id/${this.invoice.id}`).then(rsp => {
				this.invoicedVpos = rsp.data.result;
			}).catch(err => console.error(err));
		},

		invoiceViewModal(item) {
			// console.log('item',item);
			console.log('item', item.data);
			this.invoice = item.data;
			this.jobData = item.data.job;

			return this.getPayments()
		},
		changePage(page) {
			this.currentPage = page;
		},
		invoiceSendModal() {
			this.$emit('cancelFnc', 'invoiceSend');
		},
		editInvoiceModal() {
			this.$emit('cancelFnc', 'editSelected');
		},
		paymentModal() {
			this.$emit('cancelFnc', 'invoicePayment');
		},
		deleteNotify(delete_id) {
			this.delete_id = delete_id;
			this.viewDeleteModal = true;
			// $("#deleteModal"+this.newid).modal('show');
		},
		hideDeleteNotify() {
			this.viewDeleteModal = false;
		},

		deleteInvoice() {
			let data = {
				id: this.delete_id
			};

			axios.post('/invoice-delete', data).then(() => {
				this.$emit('reloadInvoices');
				bus.$emit('ModalInvoiceViewDlt', data.id);
				bus.$emit('reloadReportInvoices');
				this.$emit('cancelFnc');
				// $('.close2addinvoice').click();
			}).catch(err => {
				//console.log('list carriers error:', error)
			});
		},
		markAsPaid(data, paid) {
			this.isLoading = true;
			let request = {
				id: data.id,
				paid: paid
			}
			axios.post('/invoice/mark-as-paid', request).then(rsp => {
				this.invoice = rsp.data.invoice;
				bus.$emit('getallinvoices');
				this.isLoading = false;
			}).catch(err => {
				//console.log('list carriers error:', error)
			});
		},
	}
}
</script>
<style scoped>
.pdf-preview {
	border: 1px solid #8898aa;
	border-radius: 10px;
	padding: 1.5rem;
}

#invoiceViewModal p {
	line-height: 1.4;
}

.page-footer {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 20px;
}

.invoice-summary-header {
	background-color: #2d8bb6;
	color: #fff;
}
</style>