<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50" id="jobTypesContainer">
            <!-- HEADER -->
            <div class="d-flex" style="z-index: 100">
                <!-- Breadcrumbs -->
                <breadcrumbs :title="$t('label.users')">
                    <!-- Breakcrumb slots -->
                    <li class="breadcrumb-item">
                        {{ $t('label.admin') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.content management') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.users') }}
                    </li>
                </breadcrumbs>

                <!-- Buttons -->
                <div class="ml-auto py-4 align-items-center">
                    <button class="btn btn-neutral text-bold" @click="syncToQuickbooks" :disabled="employee.length == 0">
                    Sync Quickbooks Online
                    </button>

                    <button class="btn btn-neutral" @click="userAdd()">
                        {{ $t('label.add user') }}
                    </button>
                </div>
            </div>

            <!-- Content -->
            <div class="card">
                <div class="card-header d-flex">
                    <h3 class="d-flex mb-0 align-items-center">
                        <span>{{ $t('label.users') }}</span>
                    </h3>
                    <div class="ml-auto py-1 btn-group btn-group-light nav">
                        <button class="btn text-bold" :class="{ active: currentFilter == 'all' }" @click="currentFilter = 'all'"> {{ $t('label.all users') }}</button>
                        <button class="btn text-bold" :class="{ active: currentFilter == 'active' }" @click="currentFilter = 'active'">{{ $t('label.active') }}</button>
                        <button class="btn text-bold" :class="{ active: currentFilter == 'inactive' }" @click="currentFilter = 'inactive'">{{ $t('label.inactive') }}</button>
                    </div>
                </div>
                <div class="card-body p-0 m-0">
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush border-bottom">
                            <thead class="thead-light">
                                <tr>
                                    <th></th>
                                    <th scope="col">{{ $t('label.photo') }}</th>
                                    <th scope="col">
                                        {{ $t('label.name') }}
                                    </th>
                                    <th scope="col">{{ $t('label.email') }}</th>
                                    <th scope="col">{{ $t('label.phone') }}</th>
                                    <th scope="col">{{ $t('label.role') }}</th>
                                    <th scope="col">Pay Type</th>
                                    <th scope="col">{{ $t('label.status') }}</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="user in paginatedUsers" :key="user.id">
                                <td>
                                 <input type="checkbox" name="employee" v-if="!user.qb_id" :value="user.id" v-model="employee" />
                                </td>
                                    <td>
                                        <span class="avatar avatar-sm rounded-circle">
                                            <img :src="user.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                        </span>
                                    </td>
                                    <td>{{ user.first_name }} {{ user.last_name }}</td>
                                    <td>
                                        <a :href="`mailto:${user.email}`" target="_blank">{{ user.email }}</a>
                                    </td>
                                    <td>{{ user.phone }}</td>
                                    <td>{{ getRole(user.role_id).name }}</td>
                                    <td>{{ user.pay_type }}</td>
                                    <td>{{ user.status == '1' ? 'Active' : 'Inactive' }}</td>
                                    <td class="text-right">
                                        <!-- dropdown menu -->
                                        <div class="dropdown" v-if="thisUser.role_id <= 2">
                                            <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="ri-more-2-fill"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                <!-- Admin Edit User -->
                                                <a v-if="thisUser.role_id == 2" class="dropdown-item pointer text-dark" @click="userEdit(user)">
                                                    <i class="ri-pencil-line"></i>{{ $t('label.edit') }}
                                                </a>
                                                <!-- Super Admin Only -->
                                                <a v-if="thisUser.role_id == 1" class="dropdown-item" target="_self">{{ $t('Impersonate') }}</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <!-- End loop -->
                            </tbody>
                        </table>
                        <table-pagination v-model="paginatedUsers" :all-rows="filteredUsers" :perPage="perPage">
                        </table-pagination>
                    </div>
                </div>
            </div>

        </div>
        <!-- User Add Modal -->
        <modal name="userAddModal" height="auto" width="98%" :max-width="800" :reset="true" :scrollable="true" :adaptive="true">
            <user-edit :roles="roles" :selectedUser="selectedUser" @cancelFnc="cancelFunction" @returnFnc="returnFunction" />
        </modal>

        <!-- User Edit Modal -->
        <modal name="userEditModal" height="auto" width="98%" :max-width="800" :reset="true" :scrollable="true" :adaptive="true">
            <user-edit v-if="selectedUser" :roles="roles" :selectedUser="selectedUser" @cancelFnc="cancelFunction" @returnFnc="returnFunction" />
        </modal>
    </div>
</template>

<style></style>

<script>
import UserEdit from '@/components/Admin/Users/UserEdit';
import TablePagination from '@/components/TablePagination';
import bus from '@/utils/event-bus';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('roleData');

export default {
    props: [],
    components: { UserEdit, TablePagination },
    data() {
        return {
            thisUser: JSON.parse(localStorage.getItem('user')),
            users: [],
            paginatedUsers: [],
            perPage: 30,
            loader: null,
            selectedUser: null,
            currentFilter: 'active',
            employee:[]
        }
    },
    computed: {
        ...mapState({
            roles: state => state.roles,
        }),
        filteredUsers() {
            if (this.currentFilter == 'active')
                return this.users.filter(user => user.status == '1');
            else if (this.currentFilter == 'inactive')
                return this.users.filter(user => user.status == '0');
            else if (this.currentFilter == 'all')
                return this.users;
            else
                return null
        }
    },
    mounted() {
        //Make sure we have roles
        if (!this.roles.length)
            this.getRoles().then(response => { })

        this.loadUsers()
    },
    methods: {
        ...mapActions(['getRoles']),
        userAdd() {
            this.selectedUser = null;
            this.$modal.show('userAddModal')
        },
        userEdit(user) {
            this.selectedUser = user;
            this.$modal.show('userEditModal');
        },
        cancelFunction() {
            this.$modal.hide('userEditModal');
            this.$modal.hide('userAddModal');
        },
        returnFunction(data) {
            let idx = this.users.findIndex(user => {
                if (user.id === parseInt(data.id))
                    return true;
            });

            // Update User
            if (idx >= 0) {
                this.$set(this.users, idx, data);
                this.loadUsers();
            } else {
                // Add User
                this.users.unshift(data);
            }
            this.loadUsers();
            this.cancelFunction();
        },
        loadUsers() {
            this.employee = [];
            let loading = this.$loading.show();
            axios.get('/get-employees').then(response => {
                this.users = response.data.result;
                loading.hide();
            }).catch(err => {
                console.log('ERROR: ', err)
                bus.$emit('banner-error', { message: err, })
                loading.hide();
            });
        },
        getRole(roldId) {
            let idx = this.roles.findIndex(role => {
                if (role.id === parseInt(roldId))
                    return true;
            });
            return (idx >= 0) ? this.roles[idx] : {}
        },
        syncToQuickbooks(){
          let loading = this.$loading.show();
           axios.post('/quickbooks/sync-employee',{employee:this.employee}).then(response => {
                let msg = response.data.message;
                bus.$emit('banner-success', { message: msg });
                this.loadUsers();
                loading.hide();
               // window.location.reload();
            }).catch(err => {
                console.log('ERROR: ', err.data.message)
                bus.$emit('banner-error', { message: err.data.message });
                loading.hide();
            });
        }
    }
}
</script>