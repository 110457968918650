<template>
    <div class="container-fluid" v-cloak>
        <div class="py-3">
            <h3>Task Notes and Images</h3>
        </div>

        <div class="form-group">
            <ul class="list-group">
                <!-- Notes -->
                <li class="list-group-item" v-for="(note, idx) in  noteList " :key="note.id">
                    <!-- Edit note -->
                    <div class="input-group" v-if="note.edit && !officeUser">
                        <textarea type="text" class="form-control" v-model="note.newNote"></textarea>
                        <div class="input-group-append">
                            <span class="input-group-text" @click="note.edit = false; note.newNote = note.task_note">
                                <i class="ri-close-line pointer" @click="note.edit = false; note.newNote = note.task_note "></i>
                            </span>
                            <span class="input-group-text" :class="{ 'd-none': note.newNote.length < 10 }" @click="updateNote(note)">
                                <i class="ri-save-line pointer text-success"></i>
                            </span>
                        </div>
                    </div>
                    <!-- Display note -->
                    <div class="row" v-else>
                        <div class="col-9">
                            <h3>{{ idx + 1 }}) {{ note.created_at | formatDateNoTz('ddd, MMM Do YY') }} - {{ note.task_note }}</h3>
                        </div>
                        <div v-if="!officeUser" class="col-3 text-xl text-right">
                            <i class="ri-file-edit-line pointer" @click="note.edit = true"></i>
                            <i class="ri-delete-bin-6-line text-danger pointer" @click="confirmRemoveNote(note)"></i>
                        </div>
                    </div>

                    <div class="border-bottom mb-3"></div>
                    <!-- Files -->
                    <div class="row">
                        <div class="col" v-for=" image  in  note.task_files " :key="image.id">
                            <div class="wrapper">
                                <div class="content">
                                    {{ image.file_path }}
                                    <!-- Pulls images from the storage/app/public directory -->

                                    <img class="img-thumbnail pointer" :id="`image${image.id}`" :src="`${image.file_path}`" width="150" @click="viewLargeImage(image.file_path)">


                                    <!-- Delete Icon -->
                                    <svg :width="30" :height="30" v-if="!officeUser" class="myDeleteBtn pointer" @click="confirmRemoveImage(image)" viewBox="0 0 24 24" fill="rgba(220,52,68,255)" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z" />
                                    </svg>
                                </div>
                            </div>

                            <!-- File description -->
                            <div>
                                <div v-if="!image.edit">
                                    {{ image.file_description ? image.file_description : 'Add Description' }} <i v-if="!officeUser" class="ri-file-edit-line pointer text-lg" @click="image.edit = true"></i>
                                </div>
                                <div v-else>
                                    <div class="input-group mt-2">
                                        <input type="text" class="form-control" v-model="image.newDescription">
                                        <div class="input-group-append">
                                            <span class="input-group-text" @click="image.edit = false; image.newDescription = image.file_description ">
                                                <i class="ri-close-line pointer"></i>
                                            </span>
                                            <span class="input-group-text" @click="updateImageDescription(image)">
                                                <i class="ri-save-line pointer text-success"></i>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="mb-4">
            <button type="button" class="btn btn-outline-primary" @click="$emit('returnFnc')">{{ $t('label.close') }}</button>
        </div>

        <!-- image viewer -->
        <modal name="imageViewer" height="auto" width="98%" :max-width="1024" :reset="true" :scrollable="true" :adaptive="true">
            <div class="container-fluid text-center bg-dark" @click="$modal.hide('imageViewer')">
                <div class="py-4">
                    <img class="img-fluid rounded" :src="selectedLargeImage" />
                </div>
            </div>
        </modal>
        <!-- Confirm note delete -->
        <modal name="noteDeleteConfirmation" :reset="true" :adaptive="true">
            <div class="container-fluid text-center">
                <div class="py-4">
                    <h3>This will remove the note and all note images. Are you sure you want to remove this Note?</h3>
                    <div>
                        <button type="button" class="btn btn-outline-primary" @click="$modal.hide('noteDeleteConfirmation')">Cancel</button>
                        <button type="button" class="btn btn-danger" @click="removeNote">Confirm</button>
                    </div>
                </div>
            </div>
        </modal>
        <!-- Confirm image delete -->
        <modal name="imageDeleteConfirmation" :reset="true" :adaptive="true">
            <div class="container-fluid text-center">
                <div class="py-4">
                    <h3>Are you sure you want to remove this Image?</h3>
                    <div>
                        <button type="button" class="btn btn-outline-primary" @click="$modal.hide('imageDeleteConfirmation')">Cancel</button>
                        <button type="button" class="btn btn-danger" @click="removeImage">Confirm</button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';
export default {
    props: ['task', 'officeUser'],
    emits: ['cancelFnc', 'returnFnc'],
    components: {},
    data() {
        return {
            noteList: [],
            selectedLargeImage: null,
            selectedNote: null,
            selectedImage: null,
        }
    },
    mounted() {
        this.getTaskNotesAndImages()
    },
    methods: {
        getTaskNotesAndImages() {
            let loader = this.$loading.show()
            axios
                .get('/getTaskNotesAndImages/' + this.task.id)
                .then(response => {
                    loader.hide()
                    // add an edit variable to the note to toggle editing
                    response.data.forEach(note => {
                        note.edit = false
                        note.newNote = note.task_note
                        // add and edit and new file_description for editing
                        note.task_files.forEach(file => {
                            file.edit = false
                            file.newDescription = file.file_description
                        })
                    })
                    this.noteList = response.data
                })
                .catch(err => {
                    console.log('ERROR: ', err);
                    bus.$emit('banner-error', { message: err, })
                })
        },
        viewLargeImage(image) {
            this.selectedLargeImage = image
            this.$modal.show('imageViewer')
        },
        confirmRemoveImage(image) {
            this.selectedImage = image
            this.$modal.show('imageDeleteConfirmation')
        },
        removeImage() {
            this.$modal.hide('imageDeleteConfirmation')
            // console.log(this.selectedImage)
            // return
            axios
                .delete(`/deleteTaskImages/${this.selectedImage.id}`)
                .then(response => {
                    console.log(response.data)
                    // this.getTaskNotesAndImages()
                    // Find the image and remove it from the obj
                    const noteList = JSON.parse(JSON.stringify(this.noteList))
                    this.noteList = []

                    let noteIdx = noteList.findIndex(note => {
                        if (note.id === this.selectedImage.task_note_id)
                            return true
                    })
                    if (noteIdx >= 0) {
                        let imgIdx = noteList[noteIdx].task_files.findIndex(file => {
                            if (file.id === this.selectedImage.id)
                                return true
                        })
                        if (imgIdx >= 0) {
                            noteList[noteIdx].task_files.splice(imgIdx, 1)
                            this.$nextTick(() => {
                                this.noteList = noteList
                            })
                        }
                    }

                    bus.$emit('banner-success', {
                        message: 'Image Removed!',
                    })
                })
                .catch(err => {
                    if (err.response) {
                        console.error('ERROR: ', err.response.data)
                        bus.$emit('banner-error', { message: err.response.data, })
                    }
                    else {
                        console.error('ERROR: ', err)
                    }
                })
        },
        confirmRemoveNote(note) {
            this.selectedNote = note
            this.$modal.show('noteDeleteConfirmation')
        },
        removeNote() {
            this.$modal.hide('noteDeleteConfirmation')
            axios
                .delete(`/deleteTaskNotes/${this.selectedNote.id}`)
                .then(response => {
                    // Find the Note and remove it from the obj
                    let noteIdx = this.noteList.findIndex(note => {
                        if (note.id === this.selectedNote.id)
                            return true
                    })
                    if (noteIdx >= 0) {
                        this.noteList.splice(noteIdx, 1)
                    }
                    // this.$emit('returnFnc')

                    bus.$emit('banner-success', {
                        message: 'Note Removed!',
                    })
                })
                .catch(err => {
                    if (err.response) {
                        console.error('ERROR: ', err.response.data)
                        bus.$emit('banner-error', { message: err.response.data, })
                    }
                    else {
                        console.error('ERROR: ', err)
                    }
                })
        },
        updateNote(note) {
            axios
                .post('/updateTaskNote', { id: note.id, task_note: note.newNote })
                .then(response => {
                    note.task_note = note.newNote
                    note.edit = false
                    bus.$emit('banner-success', {
                        message: 'Note Updated!',
                    })
                })
                .catch(err => {
                    if (err.response) {
                        console.log('ERROR: ', err.response.data)
                        bus.$emit('banner-error', { message: err.response.data, })
                    }
                    else {
                        console.log('ERROR: ', err)
                        bus.$emit('banner-error', { message: err })
                    }
                })
        },
        updateImageDescription(image) {
            console.log(image)
            axios
                .post('/updateTaskImageDescription', { id: image.id, file_description: image.newDescription })
                .then(response => {
                    image.file_description = image.newDescription
                    image.edit = false
                    bus.$emit('banner-success', {
                        message: 'Image Description Updated!',
                    })
                })
                .catch(err => {
                    if (err.response) {
                        console.log('ERROR: ', err.response.data)
                        bus.$emit('banner-error', { message: err.response.data, })
                    }
                    else {
                        console.log('ERROR: ', err)
                        bus.$emit('banner-error', { message: err })
                    }
                })
        },
    }
}
</script>

<style scoped>
.wrapper {
    display: flex;
}

.content {
    position: relative;
    width: max-content
}

.content img {
    display: block;
}

.content .myDeleteBtn {
    position: absolute;
    top: -12px;
    right: -15px;
}
</style>