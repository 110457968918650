<template>
    <div class="">
        <div class="modal-content">
            <div class="modal-header border-bottom">
                <h5 class="modal-title">{{ modalType == 'add' ? $t('label.add note') : $t('label.edit note') }}</h5>
                <button type="button" class="close" @click="$emit('cancelFnc')">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="saveOrUpdateNotes">
                <!-- Text Area field with @ capability -->
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <at-ta :members="allUsers" name-key="name">
                                    <template slot="item" slot-scope="s">
                                        <span v-html="s.item.name" @click="selectedName(s.item)"></span>
                                    </template>
                                    <textarea id="vpo_notes" v-model="form.notes" name="notes" class="form-control" :placeholder="$t('label.write a note') + '...'" rows="10" required autofocus></textarea>
                                </at-ta>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Save or Cancel Buttons -->
                <div class="modal-footer border-top">
                    <button type="submit" class="btn btn-primary mr-auto">{{ $t('label.save') }}</button>
                    <button type="button" class="btn btn-link  ml-auto" @click="$emit('cancelFnc')">{{ $t('label.cancel') }}</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import bus from '@/utils/event-bus';
import At from 'vue-at';
import AtTa from 'vue-at/dist/vue-at-textarea';
import { mapGetters } from 'vuex';

export default {
    props: ['vpoId', 'modalType', 'modalNote'],
    emits: ['cancelFnc', 'reload'],
    components: {
        At,
        AtTa,
    },
    data() {
        return {
            form: {
                id: null,
                notes: null,
            },
            selectedNames: []
        }
    },
    computed: {
        ...mapGetters({
            allUsers: 'userData/allUsers',
        }),
    },
    mounted() {
        this.clearForm();
        this.loadLkupData();
    },
    methods: {
        loadLkupData() {
            let promises = [];
            this.loader = this.$loading.show();
            promises.push(this.$store.dispatch('userData/getAllUsers'));
            // Hide the loader overlay after all lookup data is loaded
            Promise.all(promises).then(() => {
                if (this.modalType == 'edit')
                    this.editModal();

                this.loader.hide();
            });
        },
        editModal() {
            this.form.notes = this.modalNote.notes.replace(/<\/?[^>]+>/ig, "");
            this.form.id = this.modalNote.id;
            this.selectedNames = this.modalNote.tagged_users;
        },
        clearForm() {
            this.form.id = null;
            this.form.notes = null;
            this.selectedNames = [];
        },
        saveOrUpdateNotes() {
            let tempText = $('#vpo_notes').val();
            this.form.notes = tempText;

            this.allUsers.forEach((data, key) => {
                if (tempText.includes(data.name))
                    this.selectedName(data);
            });

            let data_fields = {
                'id': this.form.id,
                'notes': this.form.notes,
                'vpo_id': this.vpoId,
                'selected_names': this.selectedNames,
            };

            let apiPath = '',
                message = '';
            if (this.modalType == 'add') {
                apiPath = 'vpo-note/store';
                message = `VPO Note added successfully!`;
            } else {
                apiPath = 'vpo-note/update';
                message = `VPO Note updated successfully!`;
            }

            axios.post('/' + apiPath, data_fields).then(() => {
                bus.$emit('banner-success', { message: message });
                this.$emit('reload');
                bus.$emit('reloadActivityLogs');
                this.$emit('cancelFnc');
            });
        },
        selectedName(data) {
            if (this.findArray(data.id) === null)
                this.selectedNames.push(data);
        },
        findArray(value) {
            let countLayer = this.selectedNames.length;
            if (countLayer > 0) {
                for (var x = 0; x < countLayer; x++) {
                    if (this.selectedNames[x].id === value)
                        return this.selectedNames[x];
                }
            }
            return null;
        },
    },
}
</script>
