<template>
	<div>
		<div class="page-timesheet">
			<div class="header bg-primary pb-6 mobile-p-0">
				<div class="container-fluid">
					<div class="header-body">
						<div class="row align-items-center py-4">
							<div class="col-lg-6 col-7">
								<!-- Breakcrumb slots -->
								<breadcrumbs :title="$t('label.reports')">
									<!-- Show reports breadcrumb if this page was routed to from the reports. -->
									<li v-if="parent == 'reports'" class="breadcrumb-item">
										<router-link class="text-primary pointer" :to="{name: 'reports'}">{{ $t('label.reports') }}</router-link>
									</li>
									<li class="breadcrumb-item">
										{{ $t('label.timesheet report') }}
									</li>
								</breadcrumbs>                            
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Timesheet Report Card -->
			<div class="container-fluid mt--6 mobile-mt-200" id="timesheetHeader">
				<!-- Banner Widgets -->
				<div class="row d-flex">
					<!-- Clocked-In Alerts -->
					<div class="col-xxl-3 col-xl-4 col-md-6 col-12">
						<div class="card card-stats">
							<div class="card-body">
								<div class="row">
									<div class="col">
										<h5 class="card-title text-uppercase text-muted mb-0">{{ $t('label.clocked-in alerts') }}</h5>
										<span class="h2 font-weight-bold mb-0">{{ clockedInAlerts }}</span>
									</div>
									<div class="col-auto mt--3">
										<i class="ri-error-warning-line ri-3x"></i>
									</div>
								</div>
								<p class="mt-3 mb-0 text-sm">
									<span class="text-nowrap font-weight-light cursor-pointer text-muted" @click="getTimesheets(1)">{{ $t('label.Time entries still running') }}</span>
								</p>
							</div>
						</div>
					</div>                        
					<!-- Excessive Time -->
					<!-- <div class="col-xxl-3 col-xl-4 col-md-6 col-12">
						<div class="card card-stats">
							<div class="card-body">
								<div class="row">
									<div class="col">
										<h5 class="card-title text-uppercase text-muted mb-0">{{ $t('label.excessive time') }}</h5>
										<span class="h2 font-weight-bold mb-0">{{ excessiveTime }}</span>
									</div>
									<div class="col-auto">
										<div class="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
											<i class="ni ni-money-coins"></i>
										</div>
									</div>
								</div>
								<p class="mt-3 mb-0 text-sm">
									<span class="text-nowrap font-weight-light cursor-pointer text-muted" @click="getTimesheets(3)">{{ $t('label.Entries more than crew average') }}</span>
								</p>
							</div>
						</div>
					</div> -->
				</div>
				
				<div v-if="qbstatus.isSyncResult == 'success'" class="alert alert-success alert-dismissible fade show" role="alert">
					<span class="alert-inner--text"><strong>{{ $t('label.success') }}!</strong> {{ $t('label.Timesheet result successfully synced to quickbooks') }}.</span>
					<button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="updateSyncResult">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div v-if="qbstatus.isSyncResult == 'error'" class="alert alert-danger alert-dismissible fade show" role="alert">
					<span class="alert-inner--text">
						<strong>{{ $t('label.error') }}!</strong> {{ $t("label.Sorry, there's an error in syncing the timesheet to quickbook") }}. <br> {{ $t('label.Please refresh the page and try again') }}.
					</span>
					<button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="updateSyncResult">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>

				<!-- Timesheet Report Card -->
				<div class="row">
					<div class="col">
						<div class="card">
							<div class="card-header" v-if="showSearchbox">
								<div class="row align-items-center">
									<div class="w-100 d-flex justify-content-between align-items-center">
										<h3 class="mb-0"><i class="far fa-clock"></i> {{ $t('label.timesheet report') }}</h3>
										<button v-if="company.sync_to_qb" @click="getAuthUrl" class="btn btn-primary">
											{{ $t('label.sync to quickbooks') }}
										</button>
									</div>
								</div>
							</div>
							<div class="card-body" v-if="showSearchbox">
								<div class="row">
									<!-- Start Date -->
									<div class="col-12 col-md-2">
										<div class="form-group">
											<label class="form-control-label text-muted" for="input-name">{{ $t('label.start date') }}</label>
											<date-picker v-model="filter.start_date" 
												class="full-width"
												input-class="form-control"
												value-type="format"
												format="MM/DD/YYYY">
											</date-picker>
										</div>
									</div>
									<!-- End Date -->
									<div class="col-12 col-md-2">
										<div class="form-group">
											<label class="form-control-label text-muted" for="input-name">{{ $t('label.end date') }}</label>
											<date-picker v-model="filter.end_date"
												class="full-width" 
												input-class="form-control"
												value-type="format"
												format="MM/DD/YYYY">
											</date-picker>
										</div>
									</div>
									<!-- Week / Month Filter -->
									<div class="col-12 col-md-2">
										<div class="form-group mb-0">
											<label class="form-control-label text-muted" for="input-name">{{ $t('label.date') }}</label>
										</div>
										<div class="form-group row m-0">
											<div class="mr-2">
												<input class="form-control-input" id="week" type="checkbox" value="" v-model="filter.week">
												<label class="form-control-label text-muted font-weight-normal" for="week">{{ $t('label.this week') }}</label>
											</div>
											<div>
												<input class="form-control-input" id="month" type="checkbox" value="" v-model="filter.month">
												<label class="form-control-label text-muted font-weight-normal" for="month">{{ $t('label.this month') }}</label>
											</div>
										</div>
									</div>
									<!-- Status -->
									<div class="col-12 col-md-2">
										<div class="form-group mb-0">
											<label class="form-control-label text-muted" for="input-name">{{ $t('label.status') }}</label>
										</div>
										<div class="form-group">
											<input class="form-control-input" id="status" type="checkbox" value="" v-model="filter.status">
											<label class="form-control-label text-muted font-weight-normal" for="status">{{ $t('label.clocked in') }}</label>
										</div>
									</div>
									<!-- Records Per Page -->
									<div class="col-12 col-md-4 d-flex">
										<div class="ml-auto" style="width: 125px">
											<label class="form-control-label text-muted">{{ $t('label.records per page') }}</label>
											<select class="form-control" v-model="filter.rows_per_page" @change="reloadTimesheets()">
												<option v-for="(val, idx) in rowsPerPageOptions" :key="idx" :value="val">{{ val }}</option>
											</select>
										</div>
									</div>
								</div>
								<div class="row">
									<!-- Employee -->
									<div class="col-12 col-md-2" v-if="user.role_id != '4'">
										<div class="form-group">
											<label class="form-control-label text-muted" for="input-name">{{ $t('label.employee') }}</label>
											<select class="form-control" v-model="filter.user_id" autofocus>
												<option value="all" selected>- {{ $t('label.all employees') }} -</option>
												<option v-for="(data,index) in alljobpeople['timesheet_users']" :value="data.id" :key="index">{{data.name}}</option>
											</select>
										</div>
									</div>
									<!-- Job Filter -->
									<div class="col-12 col-md-2">
										<div class="form-group">
											<label class="form-control-label text-muted" for="input-name">{{ $t('label.job') }}</label>
											<select class="form-control" v-model="filter.jobname" autofocus>
												<option value="all" selected>- {{ $t('label.all jobs') }} -</option>
												<option v-for="(data,index) in alljobnames" :value="data" :key="index">{{data}}</option>
											</select>
										</div>
									</div>
									<!-- Group By -->
									<div class="col-12 col-md-2">
										<div class="form-group">
											<label class="form-control-label text-muted" for="input-name">{{ $t('label.group by') }}</label>
											<select class="form-control" v-model="filter.group_by" autofocus>
												<option value="all" selected>- {{ $t('label.no grouping') }} -</option>
												<option value="job" >{{ $t('label.job') }}</option>
												<option value="task" >{{ $t('label.task') }}</option>
												<option value="employee" >{{ $t('label.employee') }}</option>
											</select>
										</div>
									</div>
									<!-- Sort By -->
									<div class="col-12 col-md-2">
										<div class="form-group">
											<label class="form-control-label text-muted" for="input-name">{{ $t('label.sort by') }}</label>
											<select class="form-control" v-model="filter.sort_by" autofocus>
												<option value="all" selected>- {{ $t('label.no grouping') }} -</option>
												<option value="job_name" >{{ $t('label.job name') }}</option>
												<option value="time_in" >{{ $t('label.start time') }}</option>
												<option value="user_name" >{{ $t('label.employee') }}</option>
												<option value="total_time_asc" >{{ $t('label.time clocked') }} ({{ $t('label.ascending') }})</option>
												<option value="total_time_desc" >{{ $t('label.time clocked') }} ({{ $t('label.descending') }})</option>
											</select>
										</div>
									</div>

									<div class="col-8 col-xs-12">
										<div class="col-12"></div>
									</div>
								</div>
								<div class="row mb--4">
									<div class="col-5 text-left col-xs-12">
										<!-- Search Timesheets -->
										<button type="button" class="btn btn-primary mb-2" @click="reloadTimesheets(); searchBox();">
											{{ $t('label.search timesheets') }}
										</button>
										<!-- Clear Search -->
										<button type="button" class="btn btn-link ml-auto" @click="clearSearch()">
											{{ $t('label.clear search') }}
										</button>
									</div>
									<div class="col-7 text-right col-xs-12 d-block">
										<!-- Export Results -->
										<export-excel type="csv"
											name="timesheet-report.xls"
											worksheet="Timesheet Report"
											class="btn btn-secondary color-black mb-2"
											:data="Object.values(noPaginationTimesheets)"
											:fields="timesheet_header">
											{{ $t('label.export results') }}
										</export-excel>
										<!-- Save View -->
										<button type="submit" class="btn btn-secondary color-black mb-2" @click="$modal.show('saveViewModal')">
											<i class="fas fa-save"></i>
											{{ $t('label.save view') }}
										</button>
										<!-- Saved Views -->
										<button type="button" class="btn btn-secondary color-black mb-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											{{ $t('label.saved views') }}
											<i class="fas fa-angle-down"></i>
										</button>
										<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" style="max-width: 100%;">
											<a class="dropdown-item pointer" v-for="(data,index) in allsearchviews" :key="index" @click="useSearchView(data)">
												{{ data.name }}
											</a>
											<div class="dropdown-divider"></div>
											<a class="dropdown-item pointer" data-toggle="modal" data-target="#manageViewModal">{{ $t('label.manage saved views') }}</a>
										</div>
									</div>
								</div>
							</div>
							<div class="">
								<div class="card-header" v-if="!showSearchbox">
									<div class="row d-flex justify-content-between align-items-center">
										<div>
											<h3 class="mb-0">{{ $t('label.search results') }} ({{ totalTimesheets }})</h3>
										</div>
										<div class="d-flex ml-auto">
											<div class="col-auto">
												<button class="btn btn-sm btn-default" @click="searchBox()">{{ $t('label.revise search') }}</button>
											</div>
											<div class="col-auto p-0">
												<button type="submit" class="btn btn-sm btn-default" @click="$modal.show('saveViewModal')">
													<i class="fas fa-save"></i>
													{{ $t('label.save view') }}
												</button>
											</div>
											<div class="col-auto">                                          
												<export-excel type="csv"
													name="timesheet-report.xls"
													worksheet="Timesheet Report"
													class="btn btn-sm btn-default"
													:data="Object.values(noPaginationTimesheets)"
													:fields="timesheet_header">
													{{ $t('label.export results') }}
												</export-excel>
											</div>
										</div>
									</div>
								</div>
								
								<!-- Time Punches Table -->
								<div class="table-responsive py-4">
									<timesheet-report-proximity-component
										flag="list"
										:task="null"
										:selectedDate="null"
										:checkpending="null"
										:elapsedTime3="null"
										:viewTaskTime_timer="null"
										:showProximityFlag="true"
										:timePunches="timesheets"
										:totalTaskTime="totalTaskTime"
										:groupbyfilter="groupbyfilter"
										:viewTimePunches="viewTimePunches"
										@reload="reload">
									</timesheet-report-proximity-component>		
									<table-server-pagination v-if="timesheets"
										v-model="filter.current_page"
										:btn-limit="5"
										:btn-size="'sm'"
										:total-records="totalTimesheets"
										:per-page="filter.rows_per_page"
										:num-rows="timesheets.length"
										@onChange="reloadTimesheets()">
									</table-server-pagination>		
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Save Modal -->
		<modal name="saveViewModal" :classes="'rounded'" :min-height="400" width="525px" :scrollable="true" height="auto" :reset="true" :adaptive="true">
			<div class="card-body mobile-p-0 p-0">
				<div class="modal-header border-bottom">
					<h5 class="modal-title" id="saveViewModalTitle">{{ $t('label.save search view') }}</h5>
					<button type="button" class="close" @click="$modal.hide('saveViewModal')">
						<i class="ri-close-line"></i>
					</button>
				</div>
				<form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="saveSearchView">
					<div class="modal-body">
						<div class="row">
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.name of view') }}</label>
									<input v-model="search.name" type="text" name="name" class="form-control" :placeholder="$t('label.name of view')" value="" required autofocus>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer border-top">
						<button type="submit" class="btn btn-primary mr-auto">{{ $t('label.save view') }}</button>
						<button type="button" class="btn btn-secondary close2saveview" @click="$modal.hide('saveViewModal')">{{ $t('label.cancel') }}</button>
					</div>
				</form>
			</div>
		</modal>

		<!-- Manage View Modal -->
		<div class="modal fade" id="manageViewModal" tabindex="-1" role="dialog" aria-labelledby="manageViewModalTitle" aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header border-bottom">
						<h5 class="modal-title" id="manageViewModalTitle">{{ $t('label.manage saved views') }}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<i class="ri-close-line"></i>
						</button>
					</div>
					<form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="updateSearchViews">
						<div class="modal-body">
							<h4 class="mb-4">{{ $t('label.You can change the name and delete views') }}.</h4>
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<div class="d-flex align-items-center mb-2" v-for="(data,index) in allsearchviews" :key="index">
											<div class="flex-grow-1">
												<input v-model="data.name" type="text" name="name" class="form-control" :placeholder="$t('label.name of view')" value="" required autofocus>
											</div>
											<div @click="deleteNotify(data.id)" class="p-3" style="background: #dd1a3e; color: #fff; border-radius: 0px 5px 5px 0px; height: 46px; line-height: 15px;">
												<i class="ri-delete-bin-line"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer border-top">
							<button type="submit" class="btn btn-primary mr-auto">{{ $t('label.save changes') }}</button>
							<button type="button" class="btn btn-link close2updateviews" data-dismiss="modal">{{ $t('label.cancel') }}</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<!-- Modal Delete -->
		<modal-delete :deleteMethod="removeSavedView" :deleteModalText="deleteModalText" newid="timesheet"></modal-delete>
	</div>
</template>

<style scoped>
	@media only screen and (max-width: 768px) {
		.mobile-mt-200 {
			margin-top: 200px !important;
		}
	}
</style>

<script>
	import bus from '@/utils/event-bus';
    import { mapGetters } from 'vuex';
	import TableServerPagination from '@/components/TableServerPagination.vue';

    export default {
		props:['dateStart', 'dateEnd'],
        components: {
			TableServerPagination
        },
		data() {
			return {
				user: JSON.parse(localStorage.getItem('user')),
				company: JSON.parse(localStorage.getItem('company_settings')),
                tasks: [],
				timesheets: [],
				noPaginationTimesheets: [],
				alljobnames: [],
				alljobpeople: [],
				allsearchviews: [],
                totalTimeByGroup: [],
				rowsPerPageOptions: [25, 50, 100, 250],
                taskId: 0,
                current: 0,
				countMyJobs: 0,
                totalTaskTime: 0,
                excessiveTime: 0,
                clockedInAlerts: 0,
				countOverdueJobs: 0,
				countRecentlyClosedJobs: 0,
                isSyncing: false,
				showSearchbox: true,
				groupbyfilter: false,
				parent: null,
				loader: null,
				delete_id: null,
                isSyncResult: null,
                selectedDate: null,
				totalTimesheets: null,
				search: {
					name: null
				},
				filter: {
					status: null,
					jobname: null,
					sort_by: null,
					user_id: null,
					time_off: null,
					end_date: moment().format('MM/DD/YYYY'),
					group_by: null,
					start_date: moment().subtract(7,'days').format('MM/DD/YYYY'),
					current_page: 1,
					rows_per_page: 50,
                },
				deleteModalText: {
					button: 'Yes, Delete',
					title: 'ARE YOU SURE YOU WANT TO DELETE THIS VIEW?'
				},
				timesheet_header: {
					'END TIME': 'time_out',
					'START TIME': 'time_in',
					'TOTAL TIME': 'total_time',
					'EMPLOYEE': {
						field: 'user.name',
						callback: (value) => {
							return value;
						}
					},
					'JOB': {
						field: 'task.job.job_name',
						callback: (value) => {
							return value;
						}
					},
					'TASK' : {
						field: 'task.name',
						callback: (value) => {
							return value;
						}
					},
					'DESCRIPTION': 'description',
				}
			}
		},
		watch: {
			$route() {
				this.parent = this.$route.params.parent;
			}
		},
        computed: {
            ...mapGetters(['qbstatus']),
        },
		created() {
			this.parent = this.$route.params.parent;

			bus.$on('markResolved', (data) => {
				this.getTimesheets();
			});
			bus.$on('deleteFTimesheet', () => {
                this.removeSavedView();
            });
		},
        mounted() {
			this.loader = this.$loading.show();

			let promises = [
				this.getJobPeople(),
			//	this.getTimesheets(),
				this.getAllJobNames(),
				this.getSearchViews()
			];
            
			if(this.dateStart) {			
				this.filter.start_date = JSON.parse(JSON.stringify(this.dateStart))
				this.filter.end_date = JSON.parse(JSON.stringify(this.dateEnd))
			}

			Promise.all(promises).then(() => {
				this.loader.hide();
			});
		},
		methods: {
			getJobPeople() {
				return axios.get('/jobpeople').then(response => {
					this.alljobpeople = response.data.result;
				}).catch(err => {
					//console.log('list carriers error:', error)
				});
			},
			getAllJobNames() {
				return axios.get('/get-all-job-names').then(response => {
					this.alljobnames = response.data.result;
				}).catch(err => {
					//console.log('list carriers error:', error)
				});
			},

			reload() {
				this.getTimesheets();
				bus.$emit('checkClockinStatus');
			},

			async getTimesheets(widget) {
                if (this.filter.status != null && !this.filter.status)
                    this.filter.status = null;

                if (this.filter.week != null && !this.filter.week)
                    this.filter.week = null;

                if (this.filter.month != null && !this.filter.month)
                    this.filter.month = null;

				if (typeof widget !== 'undefined') {
					this.filter.clockin_alerts = null;
					this.filter.excessive_time = null;
				}

				if (widget == 1) // Time entries still running
					this.filter.clockin_alerts = true;
				else if (widget == 3) // Entries more than crew average
					this.filter.excessive_time = true;

				this.timesheets = [];
				return axios.post('/get-timesheets', this.filter).then(response => {
					this.timesheets = response.data.result;
					this.noPaginationTimesheets = response.data.noPaginationTimesheets;

					if( this.filter.group_by != null || this.filter.sort_by != null ) {
						if (this.filter.group_by !== 'all')
							this.groupbyfilter = this.filter.group_by != null ? true : false;
					} 

					this.totalTimesheets = response.data.totalRecords;
					this.timesheets.forEach((data, index) => {
						data.showPin_login = true;
						data.showPin_logout = true;

						// Mark the time punches if the location was relevant 
						if( data.login_location !== null ) {
							let login_location = JSON.parse(data.login_location);
							if( login_location.latitude == 0 && login_location.longitude == 0 ) 
								data.showPin_login = false;
						} else {
							data.showPin_login = false;
						}

						if( data.logout_location !== null ) {
							let logout_location = JSON.parse(data.logout_location);
							if( logout_location.latitude == 0 && logout_location.longitude == 0 ) 
								data.showPin_logout = false;
						} else {
							data.showPin_logout = false;
						}

						if( !data.task ) {
							data.showPin_login = false;
							data.showPin_logout = false;
						}

					});

                    this.totalTimeByGroup = _(this.timesheets)
                        .groupBy('group_by_value')
                        .map((objs, key) => ({
                            'group_by_value': key,
                            'duration': _.sumBy(objs, 'duration') }))
                        .value();

                    this.clockedInAlerts = response.data.clockedInAlerts;
                    this.totalTaskTime = response.data.totalTaskTime;
                    this.excessiveTime = response.data.excessiveTime;

					// console.log("this.timesheets", this.timesheets, moment.tz(this.timesheets[0].timezone).format('zz'));
				}).catch(err => {
					//console.log('list carriers error:', error)
				});
			},
			reloadTimesheets() {
				// Reload Timesheets and focus to the top of the list
				if (!this.isLoading) {
					this.isLoading = true; // Lock method to only process one reload

					this.loader = this.$loading.show();
					this.getTimesheets().then(() => {
						$('#timesheetHeader')[0].scrollIntoView();
						this.loader.hide();
					});

					this.isLoading = false; // Unlock
				}
			},
			clearSearch() {
				this.filter.start_date = null;
				this.filter.end_date = null;
				this.filter.user_id = null;
				this.filter.group_by = null;
				this.filter.sort_by = null;
				this.filter.status = null;
				this.filter.week = null;
				this.filter.month = null;
				this.filter.time_off = null;
				this.filter.jobname = null;
				this.filter.clockin_alerts = null;
				this.filter.excessive_time = null;
			},
			saveSearchView() {
				var request_fields = {
					'filter': this.filter,
					'name': this.search.name,
					'type': 'reports'
				};

				axios.post('/save-search-view', request_fields)
				.then(response => {
					this.search.name = '';
					this.getSearchViews();
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
				document.querySelector('.close2saveview').click();
			},
			getSearchViews() {
				var request_fields = { 'type': 'reports' };
				return axios.post('/get-search-views', request_fields).then(response => {
					this.allsearchviews = response.data.result;
				}).catch(err => {
					//console.log('list carriers error:', error)
				});
			},
			useSearchView(filterData) {
                let data = filterData.filter;
				this.filter.start_date = data.start_date;
				this.filter.end_date = data.end_date;
				this.filter.user_id = data.user_id;
				this.filter.group_by = data.group_by;
				this.filter.sort_by = data.sort_by;
				this.filter.status = data.status;
				this.filter.week = data.week;
				this.filter.month = data.month;
				this.filter.time_off = data.time_off;
                this.filter.jobname = data.jobname;
			},
			deleteNotify(delete_id) {
				this.delete_id = delete_id;

				$("#deleteModaltimesheet").modal('show');
			},
			removeSavedView() {
					axios.post('/delete-search-view/' + this.delete_id)
					.then(response => {
						this.getSearchViews();
					})
					.catch(err => {
						//console.log('list carriers error:', error)
					});
			},
			updateSearchViews() {
				var request_fields = {
					'searchviews': this.allsearchviews
				};
				axios.post('/update-search-views', request_fields)
				.then(response => {
					this.getSearchViews();
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
				document.querySelector('.close2updateviews').click();
			},
			searchBox() {
				this.showSearchbox = !this.showSearchbox;
			},
			viewTimePunches(data) {
				var request_fields = {
					'time_punch_id': data.id
				};
				axios.post('/get-time-punches-audit', request_fields).then(response => {
					this.timePunchesAudit = response.data.result;
				}).catch(err => {
					//console.log('list carriers error:', error)
				});
            },
            updateSyncResult() {
                this.$store.dispatch('isSyncToQb', {
                    'isSyncResult': null,
                    'isSyncing': false
                });
            },
            getAuthUrl() {
                this.$store.dispatch('isSyncToQb', {
                    'isSyncResult': null,
                    'isSyncing': true
                });
                axios.get('/get-auth-url')
				.then(response => {
                    let authUrl = response.data.data;
                    let accessToken = response.data.accessToken;
                    let timesheets = this.timesheets;
                    let isSyncResult = this.isSyncResult;
                    let isSyncing = this.isSyncing;
                    let store = this.$store;

                    if (!accessToken) {
                        let win = window.open(authUrl, 'connectPopup');
                        let pollOAuth = window.setInterval(function () {
                            try {
                                if (win.document.URL.indexOf("code") != -1) {
                                    window.clearInterval(pollOAuth);
                                    win.close();

                                    axios.post('/sync-to-quickbooks', {'timesheets': timesheets})
                                    .then(response => {
                                        store.dispatch('isSyncToQb', {
                                            'isSyncResult': response.data.status,
                                            'isSyncing': false
                                        });
                                    })
                                    .catch(err => {
                                        console.log('Error API (Sync to Quickbooks):', err);
                                    });
                                }
                            } catch (e) {
                                console.log(e)
                            }
                        }, 100);
                    } else {
                        this.syncToQuickBooks(this.timesheets);
                    }
				})
				.catch(error => {
					console.log('Get quickbook auth url error:', error)
				});
            },
            syncToQuickBooks(timesheets) {
                axios.post('/sync-to-quickbooks', {'timesheets': timesheets})
                .then(response => {
                    this.$store.dispatch('isSyncToQb', {
                        'isSyncResult': response.data.status,
                        'isSyncing': false
                    });
                })
                .catch(err => {
                    console.log('Error API (Sync to Quickbooks):', err);
                });
            }
		}
    }
</script>
