<template>
    <div class="chat-window">
        <div class="card m-0" v-if="chatRoom" :key="chatRoom.id + 'chat_room'">
            <b-badge variant="danger" class="unread-chat-window-dot" v-if="hasUnreadMessage"> &nbsp; </b-badge>
            <div class="card-header">
                <div class="row align-items-center">
                    <div class="col-9 p-0 cursor-pointer2" @click="chatRoom.minimized ? maximizeChatRoomWindow() : toggleModal()">
                        <div class="row m-0 align-items-center text-left chat-header">
                            <template v-if="chatRoom.type == 'company' || chatRoom.type == 'channel'">
                                <div class="col-2 p-0"><i class="ri-hashtag"></i></div>
                                <div class="col-10 p-0">
                                    <span class="name">{{ chatRoom.name }}</span>
                                    <span class="sub-name">{{ chatRoom.users.length }} members</span>
                                </div>
                            </template>
                            <!-- <template v-if="chatRoom.type == 'user'">
                                <div class="col-2 p-0"><img class="avatar-sm rounded-circle" :src="chatRoom.dm_user.user.picture" /></div>
                                <div class="col-10 p-0 pl-1">
                                    <span class="name">{{ chatRoom.dm_user ? chatRoom.dm_user.user.name : '' }}</span>
                                    <span class="sub-name">Clocked-in</span>
                                </div>
                            </template> -->
                            <template v-if="chatRoom.type == 'user'">
                                <div class="col-2 p-0">
                                    <div class="row align-items-center m-0">
                                        <div class="cursor-pointer2" :class="i == 0 ? '' : 'mt--3 ml-2'" v-for="(d, i) in chatRoom.dm_user.slice(0, 2)" :key="i">
                                            <img class="rounded-circle" :class="chatRoom.dm_user.length > 1 ? 'avatar-xs' : 'avatar-sm'" :src="d.user.picture" />
                                            <i v-if="d.user.task_clocked_in" class="clockedin-icon"></i>
                                            <i v-else class="clockedout-icon"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-10 p-0 pl-1">
                                    <span class="name">
                                        <template v-for="(d, i) in chatRoom.dm_user">
                                            <template v-if="i == 1 || i == 2">,</template>
                                            <template v-if="i == 0 || i == 1">{{ d.user.name }}</template>
                                            <template v-if="i == 2">... </template>
                                        </template>
                                    </span>
                                    <span v-if="chatRoom.type == 'user'" class="sub-name">{{ chatRoom.dm_user[0].user.user_status ? 'Clocked-in' : 'Clocked-out' }}</span>
                                </div>
                            </template>
                            <template v-if="chatRoom.type == 'group'">
                                <div class="col-2 p-0">
                                    <div class="row align-items-center m-0">
                                        <div class="cursor-pointer2" :class="i == 0 ? '' : 'mt--3 ml-2'" v-for="(d, i) in chatRoom.dm_user.slice(0, 2)" :key="i">
                                            <img class="rounded-circle" :class="chatRoom.dm_user.length > 1 ? 'avatar-xs' : 'avatar-sm'" :src="d.user.picture" />
                                            <i v-if="d.user.task_clocked_in" class="clockedin-icon"></i>
                                            <i v-else class="clockedout-icon"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-10 p-0 pl-1">
                                    <span class="name">
                                        <template v-for="(d, i) in chatRoom.dm_user">
                                            <template v-if="i == 1 || i == 2">,</template>
                                            <template v-if="i == 0 || i == 1">{{ d.user.name }}</template>
                                            <template v-if="i == 2">... </template>
                                        </template>
                                    </span>
                                    <span v-if="chatRoom.type == 'group'" class="sub-name">{{ chatRoom.users.length }} members</span>
                                </div>
                            </template>
                            <template v-if="chatRoom.type == 'job'">
                                <div class="col-2 p-0"><i class="ri-discuss-line"></i></div>
                                <div class="col-10 p-0">
                                    <span class="name">{{ chatRoom.job.job_name }}</span>
                                    <span class="sub-name">{{ chatRoom.job?.customer?.name }}</span>
                                    <span class="sub-name">{{ chatRoom.users.length }} members</span>
                                </div>
                            </template>
                            <template v-if="chatRoom.type == 'new'">
                                <div class="col-12 p-0">New chat</div>
                            </template>
                        </div>
                    </div>
                    <div class="col-3 pr-0 text-right">
                        <button v-if="!chatRoom.minimized" type="button" class="close float-none" @click="minimizeChatRoomWindow()"><i class="ri-subtract-line"></i></button>
                        <button v-if="chatRoom.minimized" type="button" class="close float-none" @click="maximizeChatRoomWindow()"><i class="ri-layout-top-line"></i></button>
                        <button type="button" class="close float-none" @click="closeChatRoomWindow()"><i class="ri-close-line"></i></button>
                    </div>
                </div>
                <div v-if="chatRoom.type == 'new'" class="row align-items-center new-chat">
                    <div class="col-12 p-0">
                        <div class="d-flex align-items-center">
                            <span class="pr-2">To: </span>
                            <multiselect placeholder="" label="name" track-by="id" v-model="addedUsers" :options="allUsersFilter" :multiple="true" :show-labels="false" @select="selectUser" @remove="removeUser">
                                <template slot="option" slot-scope="props">
                                    <div class="">
                                        <img class="avatar-img rounded-circle" :src="props.option.picture">
                                        <span class="">{{ props.option.name }}</span>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!chatRoom.minimized">
                <div class="card-body p-0">
                    <div class="row m-0">
                        <div class="col-12" ref="chatmessages" id="messages">
                            <!-- <b-overlay :show="show" opacity="0.8" variant="white" rounded="sm"> -->
                            <template v-if="chatMessages.length > 0">
                                <div v-for="(data, index) in chatMessages" :key="index">
                                    <div v-if="data.user_id == user.id" class="message-section-owner all-messages">
                                        <div>
                                            <div class="text-right">
                                                <span class="time">{{ data.created_at | formatChatDateAndTime }}</span>
                                                <i class="ri-check-double-line"></i>
                                            </div>
                                            <div class="message">
                                                <img v-if="data.image_url" class="chat-image" :src="data.image_url" />
                                                <div v-html="data.message"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="message-section all-messages">
                                        <span class="position-relative">
                                            <img class="avatar-img rounded-circle" :src="data.user.picture" />
                                            <i v-if="data.user.task_clocked_in" class="clockedin-icon"></i>
                                            <i v-else class="clockedout-icon"></i>
                                        </span>
                                        <div>
                                            <div>
                                                <span class="name">{{ data.user.name }}</span>
                                                <span class="time">{{ data.created_at | formatChatDateAndTime }}</span>
                                            </div>
                                            <div class="message">
                                                <img v-if="data.image_url" class="chat-image" :src="data.image_url" />
                                                <div v-html="data.message"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="chatMessages.length == 0">
                                <template v-if="chatRoom.dm_user.length > 0">
                                    <div class="row justify-content-center mt-5">
                                        <div :style="i == 0 ? 'z-index: 1' : ''" class="cursor-pointer2" :class="i == 0 ? '' : 'mt--3 ml--3'" v-for="(d, i) in chatRoom.dm_user.slice(0, 2)" :key="i">
                                            <img class="rounded-circle avatar-lg" :src="d.user.picture" />
                                            <i v-if="d.user.task_clocked_in" class="clockedin-icon"></i>
                                            <i v-else class="clockedout-icon"></i>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center mt-2">
                                        <span class="name">
                                            <template v-for="(d, i) in chatRoom.dm_user">
                                                <template v-if="i == 1 || i == 2">,</template>
                                                <template v-if="i == 0 || i == 1">{{ d.user.name }}</template>
                                                <template v-if="i == 2">... </template>
                                            </template>
                                        </span>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="row justify-content-center mt-5">
                                        <div :style="i == 0 ? 'z-index: 1' : ''" class="cursor-pointer2" :class="i == 0 ? '' : 'mt--3 ml--3'" v-for="(d, i) in addedUsers.slice(0, 2)" :key="i">
                                            <img class="rounded-circle avatar-lg" :src="d.picture" />
                                            <i v-if="d.task_clocked_in" class="clockedin-icon"></i>
                                            <i v-else class="clockedout-icon"></i>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center mt-2">
                                        <span class="name">
                                            <template v-for="(d, i) in addedUsers">
                                                <template v-if="i == 1 || i == 2">,</template>
                                                <template v-if="i == 0 || i == 1">{{ d.name }}</template>
                                                <template v-if="i == 2">... </template>
                                            </template>
                                        </span>
                                    </div>
                                </template>
                            </template>
                            <!-- </b-overlay> -->
                        </div>
                    </div>
                </div>
                <div class="card-footer px-2">
                    <div class="row m-0">
                        <form method="post" class="w-100" autocomplete="off" enctype="multipart/form-data" @submit="sendMessage">
                            <div class="col-12 d-flex align-items-end p-0">
                                <input type="file" ref="file" name="photo" class="form-control" placeholder="Photo" @change="updateImage" autofocus style="display: none">
                                <i class="ri-image-2-line heading-title pr-2 cursor-pointer2" @click="$refs.file.click()"></i>
                                <div class="chat-box">
                                    <div class="text-left m-0" v-if="chat.image">
                                        <img class="" :src="chat.image" />
                                        <i class="ri-close-circle-line heading-title remove-image" @click="removeImage"></i>
                                    </div>
                                    <!-- <textarea-autosize
                                        class="form-control"
                                        placeholder="Type something here..."
                                        rows="1"
                                        ref="myTextarea"
                                        v-model="chat.message"
                                        :min-height="25"
                                        :max-height="150"
                                        @focus.native="onFocusTextarea"
                                        @blur.native="onBlurTextarea"
                                        @keyup.native="handleTextarea"
                                    /> -->
                                    <at-ta :members="allMembers" name-key="name">
                                        <template slot="item" slot-scope="s">
                                            <span v-html="s.item.name" @click="selectedName(s.item)"></span>
                                        </template>
                                        <textarea v-on:keyup.enter="sendMessage" v-model="chat.message" @focus="onFocusTextarea" name="notes" rows="1" class="form-control" placeholder="Type something here..." required autofocus></textarea>
                                    </at-ta>
                                    <span class="chat-emoji-selector">
                                        <emoji-picker @emoji="append" :search="search">
                                            <div class="emoji-invoker" slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent">
                                                <b-icon icon="emoji-smile" aria-hidden="true" class="f-primary"></b-icon>
                                            </div>
                                            <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                                                <div class="emoji-picker">
                                                    <div class="emoji-picker__search">
                                                        <input type="text" v-model="search" v-focus>
                                                    </div>
                                                    <div>
                                                        <div v-for="(emojiGroup, category) in emojis" :key="category">
                                                            <h5>{{ category }}</h5>
                                                            <div class="emojis">
                                                                <span v-for="(emoji, emojiName) in emojiGroup" :key="emojiName" @click="insert(emoji)" :title="emojiName">
                                                                    {{ emoji }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </emoji-picker>
                                    </span>
                                </div>
                                <i class="ri-send-plane-2-line heading-title pl-2 cursor-pointer2" @click="sendMessage"></i>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Chat View Details Modal -->
        <!-- <modal v-if="showModal" :name="'chatViewDetails' + chatRoom.id" height="auto" width="100%" :max-width="550" :scrollable="true" :reset="true" :adaptive="true">
            <ChatViewDetailsModal :chat-room-data="chatRoom" @cancelFnc="$modal.hide('chatViewDetails' + chatRoom.id)"></ChatViewDetailsModal>
        </modal> -->
    </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue';
import AtTa from 'vue-at/dist/vue-at-textarea';
import EmojiPicker from 'vue-emoji-picker';
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import ChatViewDetailsModal from '../chat/ChatViewDetailsModal';

export default {
    props: ['chatRoomIndex'],
    components: {
        Multiselect,
        EmojiPicker,
        ChatViewDetailsModal,
        BBadge,
        AtTa,
    },
    directives: {
        focus: {
            inserted(el) {
                el.focus()
            },
        },
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            chat: {
                message: '',
                image: null,
                imageFile: '',
            },
            options: {
                chat_room_id: null,
                offset: 0
            },
            retrievingMessages: true,
            chatMessages: [],
            addedUsers: [],
            show: false,
            hasUnreadMessage: false,
            search: '',
            showModal: false,
            modalName: 'chatViewDetailsModal',
            selectedNames: [],
        }
    },
    computed: {
        ...mapGetters({
            activeChatRooms: 'chat/activeChatRooms',
            unreadChats: 'chat/unreadChats',
            allUsers: 'userData/allUsers',
        }),
        chatRoom() {
            return this.activeChatRooms[this.chatRoomIndex];
        },
        allUsersFilter: function () {
            let filteredUsers = this.allUsers.filter(x => x.id != this.user.id);
            return filteredUsers;
        },
        allMembers() {
            let members = [];
            if (this.chatRoom && this.chatRoom.users) {
                this.chatRoom.users.forEach((data) => {
                    members.push(data.user);
                });
            }
            return members;
        }
    },
    created() {
        this.$store.dispatch('userData/getAllUsers')
        this.options.chat_room_id = this.activeChatRooms[this.chatRoomIndex].id;
        if (this.activeChatRooms[this.chatRoomIndex].id != 0) {
            this.getMessages(true);
        } else {
            this.chatMessages = []
        }

        if (this.chatRoom.minimized == false) {
            // automatically mark message as read if window is minimized
            this.$store.dispatch('chat/markMessageAsRead', this.chatRoom.id);
        } else {
            // check for unread messages on creation
            let matchedUnreadChat = this.unreadChats.findIndex(item => item.chat_room_id == this.chatRoom.id);
            // console.log('matchedUnreadChat',matchedUnreadChat)
            // console.log('this.unreadChats[matchedUnreadChat]',this.unreadChats[matchedUnreadChat])
            if (matchedUnreadChat !== -1) {
                if (this.unreadChats[matchedUnreadChat].is_read == 0) {
                    this.hasUnreadMessage = true;
                }
            }
        }

        // Start listening for chat messages
        this.$echo.channel(`chat-room.${this.chatRoom.id}`)
            .listen('.NewChatMessage', this.addMessage);
    },
    mounted() {
        // TEXTAREA AUTORESIZE
        // this.$nextTick(() => {
        //     // DOM updated
        //     this.$el.setAttribute(
        //         "style",
        //         "height:" + this.$el.scrollHeight + "px;overflow-y:hidden;"
        //     );
        //     this.$el.addEventListener("input", this.resizeTextarea);
        // });
    },
    beforeDestroy: function () {
        // Stop listening to chat messages
        this.$echo.channel(`chat-room.${this.options.chat_room_id}`)
            .stopListening('.NewChatMessage');
        // resubscribe chat to room
        this.$store.dispatch('chat/startListeningToChatRoomMessages', { id: this.options.chat_room_id, echo: this.$echo });
    },
    methods: {
        // TEXTAREA AUTORESIZE
        // resizeTextarea(event) {
        //     event.target.style.height = "auto";
        //     event.target.style.height = event.target.scrollHeight + "px";
        // },
        getMessages(refresh = false) {
            this.show = true;
            this.retrievingMessages = true;
            if (refresh) {
                this.options.offset = 0;
            }
            this.$store.dispatch('chat/getMessages', this.options).then(response => {
                this.retrievingMessages = false;
                this.chatMessages = response.data.result;
                this.show = false;

                this.scrollToEnd();

                // this.messageCount = response.data.data.total;

                // if( response.data.data.messages.length > 0 ) {
                //     if( !refresh ) {
                //         let currentMessages = this.chatMessages;
                //         let tmpArray = response.data.data.messages.slice().reverse();
                //         let merged = tmpArray.concat(currentMessages);
                //         this.chatMessages = merged;
                //         this.scrollToMiddle();

                //     } else {
                //         this.chatMessages = response.data.data.messages.slice().reverse();
                //         this.scrollToEnd();
                //         // set delay when opening chat window to prevent triggering the infinite scroller
                //         setTimeout(()=>{
                //             this.messagesInitialized = true;
                //         },2000);

                //     }
                // }
            })
        },
        updateImage(event) {
            const file = event.target.files[0];
            this.chat.image = URL.createObjectURL(file);
            this.chat.imageFile = file;
        },
        removeImage() {
            this.chat.image = null;
        },
        handleTextarea(e) {
            if (e.keyCode == 13 && e.shiftKey == false) {
                let message = this.chat.message;
                this.chat.message = message.replace(/\n+$/, "");
                this.sendMessage();
            }
        },
        sendMessage(e = false) {
            if (e) {
                e.preventDefault();
            }
            let params = {
                chat_room_id: this.chatRoom.type == 'new' ? this.options.chat_room_id : this.chatRoom.id,
                job_id: this.chatRoom.type == 'new' ? null : this.chatRoom.type_id,
                message: this.chat.message,
            }

            var formData = new FormData();
            formData.append('chat_room_id', this.chatRoom.type == 'new' ? this.options.chat_room_id : this.chatRoom.id);
            formData.append('job_id', this.chatRoom.type == 'new' ? null : this.chatRoom.type_id);
            formData.append('message', this.chat.message);
            formData.append('image', this.chat.imageFile);

            if (this.chat.message != '' || this.chat.image != null) {
                if (params.chat_room_id != 0) {
                    const pushedIndex = this.chatMessages.push({
                        user_id: this.user.id,
                        message: this.chat.message,
                        id: null,
                        created_at: new Date(),
                    }) - 1;
                    this.clearForm();
                    this.scrollToEnd();
                    if (this.chatRoom.type == 'new') {
                        var that = this;
                        setTimeout(() => {
                            that.$store.commit('chat/removeChatRoomFromActiveChats', that.chatRoomIndex);
                        }, 200)
                    }
                    this.$store.dispatch('chat/sendMessage', formData).then(response => {
                        this.$set(this.chatMessages, pushedIndex, response.data.result)
                        // this.chatMessages.push(response.data.result);
                        this.scrollToEnd();
                    })
                }
            }
        },
        attachImage() {

        },
        clearForm() {
            this.chat.message = '';
            this.chat.image = null;
            this.chat.imageFile = '';
        },
        scrollToEnd(timeout = 200) {
            let content = this.$refs.chatmessages;
            setTimeout(() => {
                if (content !== undefined) {
                    content.scrollTop = content.scrollHeight;
                }
            }, timeout)
        },
        onFocusTextarea() {
            this.$store.dispatch('chat/markMessageAsRead', this.chatRoom.id);
            // $('.height-auto-not').addClass('height-auto').removeClass('height-auto-not')
        },
        onBlurTextarea() {
            // $('.height-auto').addClass('height-auto-not').removeClass('height-auto')
        },
        minimizeChatRoomWindow() {
            let chatRooms = this.activeChatRooms;
            chatRooms[this.chatRoomIndex].minimized = true;
            this.$store.commit('chat/setActiveChatRooms', chatRooms);
        },
        maximizeChatRoomWindow() {
            if (this.chatRoom.minimized) {
                let chatRooms = this.activeChatRooms;
                chatRooms[this.chatRoomIndex].minimized = false;
                this.$store.commit('chat/setActiveChatRooms', chatRooms);
                this.$store.dispatch('chat/markMessageAsRead', this.chatRoom.id);
                this.hasUnreadMessage = false;

                // mark message as read on maximize
                // this.$store.dispatch('chat/markMessageAsRead',this.chatRoom.id);
                // this.hasUnreadMessage = false;
                // this.messagesInitialized = false;

                this.$nextTick(() => {
                    this.scrollToEnd();
                })

                // set delay when opening chat window to prevent triggering the infinite scroller
                // setTimeout(()=>{
                //     this.messagesInitialized = true;
                // },2000);
            }

        },
        closeChatRoomWindow() {
            this.$store.commit('chat/removeChatRoomFromActiveChats', this.chatRoomIndex);
        },
        addMessage(e) {
            console.log('eee', e)
            this.getMessages(true);
            // if( e.chatMessage.user_id != this.user.id ) {
            //     this.chatMessages.push(e.chatMessage);
            // } 
            // else if( e.chatMessage.chat_room.type_id == this.chatRoom.type_id && e.chatMessage.user_id == this.user.id ) {
            //     this.chatMessages.push(e.chatMessage);
            // }

            // if( this.chatRoom.minimized == false && this.isMainWindowFocused == true ) {
            if (this.chatRoom.minimized == false) {
                // automatically mark message as read if window is minimized
                this.$store.dispatch('chat/markMessageAsRead', this.chatRoom.id);
            } else {
                // show red dot icon
                this.hasUnreadMessage = true;
                this.$store.dispatch('chat/getUnreadChats');
                // play audio file only when the window is minimized
                // if( this.isMainWindowFocused == false ) {
                //     let notificationSound = new Audio(require(`../../assets/public_sound_notification.mp3`));
                //     notificationSound.play();
                // }
            }
            this.scrollToEnd();
        },
        selectUser(user) {
            console.log('user', user)

            this.$nextTick(() => {
                this.$store.dispatch('chat/createChatRoom', this.addedUsers).then(response => {
                    this.options.chat_room_id = response.data.result.id;
                    this.getMessages(true);
                });
            });
        },
        removeUser() {
            this.$nextTick(() => {
                console.log('this.addedUsers', this.addedUsers)
            });
        },
        append(emoji) {
            this.chat.message += emoji
        },
        toggleModal() {
            // // if( !(this.chatRoom.type == 'user' || this.chatRoom.type == 'group') ) {
            //     this.showModal = true;

            //     this.$nextTick(() => {
            //         bus.$emit('chatViewDetails', this.chatRoom)
            //     })
            // // }
            this.showModal = true;
            this.$nextTick(() => {
                this.$modal.show('chatViewDetails' + this.chatRoom.id)
            })
        },
        selectedName(data) {
            if (this.findArray(data.id) === null)
                this.selectedNames.push(data);
        },
        findArray(value) {
            let countLayer = this.selectedNames.length;
            if (countLayer > 0) {
                for (var x = 0; x < countLayer; x++) {
                    if (this.selectedNames[x].id === value) {
                        return this.selectedNames[x];
                    }
                }
            }
            return null;
        },

    },
};
</script>
<style lang="scss"></style>
