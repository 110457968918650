<template>
    <div class="container-fluid">
        <div class="py-3">
            <h3>Add Notes and Images</h3>
        </div>
        <div class="form-group" v-for="(image, idx) in images" :key="idx">
            <div class="row">
                <div class="col-sm-9 pt-4">
                    <input type="file" :id="idx" class="form-control-file" name="photo" accept="image/*" ref="fileInput" @change="onFileSelect" :placeholder="$t('label.photo')">
                    <input type="text" class="form-control" placeholder="Breif Description" v-model="image.description">
                </div>
                <div class="col-sm-3">
                    <img class="img-thumbnail pointer" v-if="image.picture" @click="viewLargeImage(image.picture)" :src="image.picture" />
                </div>
            </div>
        </div>
        <div class="mb-2">
            <button type="button" class="btn btn-outline-success" @click="addItem">Add Image</button>
        </div>
        <div class="mb-2">
            <label>Task Notes</label>
            <textarea class="form-control" v-model="taskNote" rows="5" required></textarea>
        </div>
        <div class="border-bottom"></div>
        <div class="py-4">
            <button type="button" class="btn btn-outline-primary" @click="$emit('cancelFnc')">Cancel</button>
            <button type="button" class="btn btn btn-success" @click="saveNotes" :disabled="taskNote.length < 10">Save Notes/Images</button>
        </div>

        <modal name="imageViewer" height="auto" width="98%" :max-width="1024" :reset="true" :scrollable="true" :adaptive="true">
            <div class="container-fluid text-center bg-dark" @click="$modal.hide('imageViewer')">
                <div class="py-4">
                    <img class="img-fluid rounded" :src="selectedLargeImage" />
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';

export default {
    props: ['task'],
    emits: ['cancelFnc', 'returnFnc'],
    components: {},
    data() {
        return {
            images: [],
            taskNote: '',
            selectedLargeImage: null
        };
    },
    mounted() {
        console.log(this.task)
    },
    methods: {
        async onFileSelect(event) {
            const files = event.target.files
            // Add the selected file to an array
            this.images[event.target.id].file = files[0]

            const imageData = await new Promise((resolve, reject) => {
                var fr = new FileReader();
                fr.onloadend = (e) => {
                    resolve({ reader: fr, data: e.target.result })
                };
                fr.onerror = reject;
                fr.readAsDataURL(files[0]);
            }).then(({ fr, data }) => {
                this.images[event.target.id].picture = data
            }).catch(err => {
                console.log(err);
            })

            // // Use the file reader to create a displayable version of the image
            // const fileReader = new FileReader()
            // fileReader.addEventListener('load', () => {
            //     // this assigned the file reader data to a variable so it can be displayed on the page
            //     this.images[event.target.id].picture = fileReader.result
            // })
            // fileReader.readAsDataURL(files[0])
        },
        addItem() {
            this.images.push({ file: null, picture: null, description: '' })
            const nextIdx = this.images.length - 1
            console.log(nextIdx);
            // Open the image selector automaticall
            this.$nextTick(() => {
                const fileElm = document.getElementById(nextIdx)
                fileElm.click()
            })
        },
        saveNotes() {
            this.loader = this.$loading.show();
            const data = new FormData();
            // loop the saved images and add each file to the formData array images
            this.images.forEach(image => {
                if (image.file) {
                    data.append('images[]', image.file)
                    data.append('imgDescriptions[]', image.description)
                }
            })

            // Add global form data
            data.append('taskData', JSON.stringify({
                jobId: this.task.job_id,
                taskId: this.task.id,
                taskNote: this.taskNote,
            }))

            axios
                .post('/addTaskNotesImages', data)
                .then(response => {
                    this.loader.hide();
                    bus.$emit('banner-success', {
                        message: 'Note added successfully!',
                    })
                    this.$emit('returnFnc')
                })
                .catch(err => {
                    console.log('ERROR: ', err)
                    bus.$emit('banner-error', { message: err, })
                })
        },
        viewLargeImage(image) {
            this.selectedLargeImage = image
            this.$modal.show('imageViewer')
        },
    },
};
</script>