<template>
	<div v-if="showProximityFlag" class="table-responsive pb-4">
		<table class="table table-sm table-flush">
			<thead class="thead-light">
				<tr>
					<th class="col-2">{{ $t('label.employee') }}</th>
					<th class="col-md-5 col-9 p-0">{{ $t('label.time details') }}</th>
					<th class="col-4 hidden-md-down">
						<div class="row p-0 m-0">
							<div class="col-6">{{ $t('label.job slash task') }}</div>
							<div class="col-6">{{ $t('label.description') }}</div>
						</div>
					</th>
					<th class="col-1">&nbsp;</th>
				</tr>
			</thead>
			<tbody>
				<template v-for="(data, index) in timesheets">
					<tr :key="index + 'row'" class="pointer" :class="{ 'bg-darkest-blue text-white row-btm-border': data.showDetails }">
						<td class="col-2" @click="toggleData(data)">
							<!-- Employee and Image -->
							<span class="d-flex align-items-center">
								<i v-if="data.showDetails" class="ri-arrow-up-s-line row-arrow-sz"></i>
								<i v-else class="ri-arrow-down-s-line row-arrow-sz"></i>
								<span class="cursor-pointer d-flex align-items-center" @click.stop="getUserInfo(data.user.id, 'Employee')">
									<img class="rounded-circle" :src="data.user.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
									&nbsp;<span>{{ data.user.name }}</span>
								</span>
							</span>
						</td>
						<td class="col-md-5 col-9 p-0 m-0 text-xs" @click="toggleData(data)">
							<!-- Time Details -->
							<div class="row">
								<div class="col-12 col-sm-6">
									<div class="d-flex" style="white-space: nowrap;">
										<span class="text-bold">{{ $t('label.start') }}:&nbsp;</span>
										<span>{{ data.time_in | formatDateTime }}</span>
										<span style="margin-top: -0.15rem;">
											<span class="position-absolute">
												<span class="align-items-center" @click.stop="viewTimePunchesAudit(data)">
													<i v-if="data.timePunchAudit" class="ri-edit-2-line heading-title cursor-pointer2 mr--2 icon-timesheet-sm"></i>
												</span>
												<i v-if="data.is_resolved && data.showPin_login" class="ri-map-pin-2-line heading-title pr-0 icon-timesheet-sm"></i>
												<i v-if="!data.is_resolved && data.showPin_login" class="ri-error-warning-line text-warning icon-timesheet-sm"></i>
											</span>
										</span>
									</div>
									<div class="d-flex" style="white-space: nowrap;">
										<span class="text-bold" style="padding-right: 8px">{{ $t('label.end') }}:&nbsp;</span>
										<span class="d-flex" v-if="data.time_out != null">
											<span>{{ data.time_out | formatDateTime }}</span>
											<span style="margin-top: -0.15rem;">
												<span class="position-absolute">
													<i v-if="data.is_resolved && data.showPin_logout" class="ri-map-pin-2-line heading-title pr-0 icon-timesheet-sm"></i>
													<i v-if="!data.is_resolved && data.showPin_logout" class="ri-error-warning-line text-warning icon-timesheet-sm"></i>
												</span>
											</span>
										</span>
										<span v-else class="text-warning">
											{{ $t('label.clocked in') }}
										</span>
									</div>
								</div>
								<div class="col-12 col-sm-6">
									<div style="white-space: nowrap;">
										<span class="text-bold">{{ $t('label.time zone') }}:&nbsp;</span>
										{{ data.timezone | uScoreToSpace }}
									</div>
									<div style="white-space: nowrap;">
										<span class="text-bold">{{ $t('label.total time') }}:&nbsp;</span>
										<span v-if="data.time_out != null">
											{{ data.total_time | hourToFormattedTime }} ({{ data.total_time }} {{ $t('label.hrs') }})
										</span>
										<span v-else>--:--</span>
									</div>
								</div>
							</div>
							<!-- Mobile Job/Task and Description -->
							<div class="row hidden-lg-up">
								<div class="col-12 col-sm-6">
									<!-- Job/Task -->
									<div style="white-space: nowrap;">
										<span class="text-bold" style="padding-right: 8px">{{ $t('label.job') }}:&nbsp;</span>
										<span v-if="data.task">
											<a v-if="data.task.job.job_number">{{ data.task.job.job_name }}</a>
										</span>
										<span v-else></span>
									</div>
									<div style="white-space: nowrap;">
										<span class="text-bold">{{ $t('label.task') }}:&nbsp;</span>
										<span v-if="data.task">{{ data.task.name }}</span>
									</div>
								</div>
								<div class="col-12 col-sm-6">
									<!-- Description -->
									<span class="text-bold">{{ $t('label.description') }}:&nbsp;</span>
									<span class="text-xs">{{ data.description }}</span>
								</div>
							</div>
						</td>
						<td class="col-4 text-xs hidden-md-down" @click="toggleData(data)">
							<div class="row p-0 m-0">
								<div class="col-6">
									<!-- Job/Task -->
									<div style="white-space: nowrap;">
										<span class="text-bold" style="padding-right: 8px">{{ $t('label.job') }}:&nbsp;</span>
										<span v-if="data.task">
											<a v-if="data.task.job.job_number">{{ data.task.job.job_name }}</a>
										</span>
										<span v-else></span>
									</div>
									<div style="white-space: nowrap;">
										<span class="text-bold">{{ $t('label.task') }}:&nbsp;</span>
										<span v-if="data.task">{{ data.task.name }}</span>
									</div>
								</div>
								<div class="col-6">
									<!-- Description -->
									<span v-if="data.description" class="text-bold">{{ $t('label.description') }}:&nbsp;</span>
									<span class="text-xs">{{ data.description }}</span>
								</div>
							</div>
						</td>
						<td class="col-1">
							<!-- Options -->
							<div class="dropdown ml-auto float-right mr--1">
								<a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<i class="fas fa-ellipsis-v"></i>
								</a>
								<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
									<a class="dropdown-item pointer" @click="editTimePunch(data)">{{ $t('label.edit') }}</a>
								</div>
								<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
									<a class="dropdown-item d-flex align-content-center pointer" href="#" @click="editTimePunch(props.row)"><i class="ri-eye-line"></i>{{ $t('label.view timesheet details') }}</a>
									<a class="dropdown-item d-flex align-content-center pointer" href="#" @click="editTimePunch(props.row)"><i class="ri-pencil-line"></i>{{ $t('label.edit') }}</a>
								</div>
							</div>
						</td>
					</tr>
					<tr class="bg-darkest-gray" v-if="data.showDetails" :key="index + 'map'">
						<td class="p-0" colspan="8" :class="((flag == 'flag' && !data.isWithinProximity) || flag == 'list') ? '' : 'd-none'">
							<div class="timesheet-entry mx-4 my-4">
								<div class="card p-4 p-xs-2">
									<!-- Expanded Time Record Details -->
									<div class="row m-0 d-flex mbn-20">
										<!-- Date -->
										<div class="form-group col-md-3 col-12">
											<label class="text-muted">{{ $t('label.date') }}</label>
											<h4 class="border-bottom-light-gray font-weight-normal">{{ data.time_in | formatDateOnly }}</h4>
										</div>
										<!-- Clock In -->
										<div class="form-group col-md-3 col-12">
											<label class="text-muted">{{ $t('label.clock in') }}</label>
											<h4 class="border-bottom-light-gray font-weight-normal d-flex justify-content-between align-items-center">
												<div>{{ data.time_in | formatTimeOnly }}</div>
												<i class="ri-map-pin-2-fill ni-1x text-green icon-25"></i>
											</h4>
										</div>
										<!-- Clock Out -->
										<div class="form-group col-md-3 col-12">
											<label class="text-muted">{{ $t('label.clock out') }}</label>
											<h4 class="border-bottom-light-gray font-weight-normal d-flex justify-content-between align-items-center">
												<div v-if="data.time_out">{{ data.time_out | formatTimeOnly }}</div>
												<i v-if="data.time_out" class="ri-map-pin-2-fill ni-1x text-red icon-25"></i>
												<button v-else class="btn btn-sm btn-clockin mt-n-10 mb-1">Clocked In</button>
											</h4>
										</div>

										<div class="form-group col-md-12 col-12 d-md-flex align-items-center">
											<div class="col-11 col-xs-12 p-0">
												<label class="text-muted">{{ $t('label.job location') }}</label>
												<h4 class="border-bottom-light-gray font-weight-normal d-flex justify-content-between align-items-center">
													<div v-if="data.task">
														<span v-if="data.task.job.address_1">{{ data.task.job.address_1 }}</span>
														<span v-if="data.task.job.city">, {{ data.task.job.city }}</span>
														<span v-if="data.task.job.state">, {{ data.task.job.state }}</span>
														<span v-if="data.task.job.postal_code">,{{ data.task.job.postal_code }}</span>
													</div>
													<i class="ri-map-pin-2-fill ni-1x text-primary icon-25"></i>
												</h4>
											</div>
											<div class="col-1 col-xs-12 p-0 p-md-2">
												<button v-if="!data.showMap" class="btn btn-sm btn-secondary" @click="toggleMap(data)">
													<i class="ri-road-map-line"></i>&nbsp;{{ $t('label.show map') }}
												</button>
												<button v-if="data.showMap" class="btn btn-sm bg-lightest-blue" @click="toggleMap(data)">
													<i class="ri-road-map-line"></i>&nbsp;{{ $t('label.hide map') }}
												</button>
											</div>
										</div>
									</div>

									<!-- Found Location -->
									<div v-if="data.isWithinProximity == 1" class="row m-0 align-items-center">
										<i class="ri-check-line text-success font-20"></i>
										<p class="m-0 pr-3">{{ $t('label.proximity check for clock in and clock out locations approved') }}</p>
									</div>
									<!-- Cannot find time punch location -->
									<div v-else-if="data.clockinProximity == 2 || data.clockoutProximity == 2" class="row m-0 align-items-center">
										<i v-if="data.is_resolved" class="ri-map-pin-2-line heading-title pr-0 icon-timesheet"></i>
										<i v-if="!data.is_resolved" class="ri-error-warning-line text-warning font-20"></i>
										<p v-if="data.clockinProximity == 2 && data.clockoutProximity == 2" class="m-0">{{ $t('label.clock in and clock out location not found') }}</p>
										<p v-else-if="!data.clockinProximity && data.clockoutProximity == 2" class="m-0">{{ $t('label.clock out location not found') }}</p>
										<p v-else-if="data.clockinProximity == 2 && !data.clockoutProximity" class="m-0">{{ $t('label.clock in location not found') }}</p>
									</div>
									<!-- Location not within approved proximity -->
									<div v-else-if="data.time_out === null && data.is_resolved == 0 && (data.login_isWithinProximity == 0 || data.logout_isWithinProximity == 0)" class="row m-0 align-items-center">
										<i class="ri-error-warning-line text-warning font-20"></i>
										<p class="m-0 pr-3">
											<span v-if="!data.clockinProximity && !data.clockoutProximity">{{ $t('label.the clock in and out location is not within the approved proximity') }}</span>
											<span v-if="!data.clockinProximity && data.clockoutProximity">{{ $t('label.the clock in location is not within the approved proximity') }}</span>
											<span v-if="data.clockinProximity && !data.clockoutProximity">{{ $t('label.the clock out location is not within the approved proximity') }}</span>
										</p>
										<button v-if="user.role_id == '1' || user.role_id == '2'" class="btn btn-sm btn-default" @click="updateTimesheetProximity(data)">{{ $t('label.mark resolved') }}</button>
									</div>
									<div v-else-if="data.is_resolved == 0 && (data.login_isWithinProximity == 0 || data.logout_isWithinProximity == 0)" class="row m-0 align-items-center">
										<i class="ri-error-warning-line text-warning font-20"></i>
										<p class="m-0 pr-3">
											<span v-if="data.login_isWithinProximity == 0 && data.logout_isWithinProximity == 0">{{ $t('label.the clock in and out location is not within the approved proximity') }}</span>
											<span v-if="data.login_isWithinProximity == 0 && data.logout_isWithinProximity != 0">{{ $t('label.the clock in location is not within the approved proximity') }}</span>
											<span v-if="data.login_isWithinProximity != 0 && data.logout_isWithinProximity == 0">{{ $t('label.the clock out location is not within the approved proximity') }}</span>
										</p>
										<button v-if="user.role_id == '1' || user.role_id == '2'" class="btn btn-sm btn-default" @click="updateTimesheetProximity(data)">{{ $t('label.mark resolved') }}</button>
									</div>
									<div v-else class="row m-0 align-items-center">
										<i v-if="data.is_resolved" class="ri-map-pin-2-line heading-title pr-0 icon-timesheet"></i>
										<i v-if="!data.is_resolved" class="ri-error-warning-line text-warning font-20"></i>
										<p class="m-0 pr-3">
											<span v-if="!data.clockinProximity && !data.clockoutProximity">{{ $t('label.the clock in and out location is not within the approved proximity') }}</span>
											<span v-if="!data.clockinProximity && data.clockoutProximity">{{ $t('label.the clock in location is not within the approved proximity') }}</span>
											<span v-if="data.clockinProximity && !data.clockoutProximity">{{ $t('label.the clock out location is not within the approved proximity') }}</span>
										</p>
										<button v-if="user.role_id == '1' || user.role_id == '2'" class="btn btn-sm btn-default" @click="updateTimesheetProximity(data)">{{ $t('label.mark resolved') }}</button>
									</div>

									<!-- Map -->
									<div class="mt-3 map" v-show="data.showMap">
										<div class="map" :id="'map-custom-' + flag + '-' + data.id" style="height: 400px;"></div>
									</div>
								</div>
							</div>
						</td>
					</tr>
				</template>
			</tbody>
		</table>
		<!-- Time Total Banner - Bottom -->
		<table class="table table-sm table-flush">
			<thead class="thead-light">
				<tr v-if="!groupbyfilter">
					<th scope="col" colspan="3" class="text-right">{{ $t('label.overall total time clocked') }}</th>
					<th scope="col" colspan="2">
						<h4 class="mb-0">{{ totalTaskTime | hourToFormattedTime }} ({{ totalTaskTime }} {{ $t('label.hrs') }})</h4>
					</th>
					<th scope="col" colspan="3"></th>
				</tr>
			</thead>
		</table>

		<!-- Time Punches Audit Modal -->
		<modal name="timePunchAudit" @closed="hidePunchAuditModal()" height="auto" width="98%" :classes="'rounded'" :max-width="1024" :scrollable="true" :reset="true" :adaptive="true">

			<div class="modal-content">
				<div class="modal-header border-bottom">
					<h5 class="modal-title">Time Entry History</h5>
					<button type="button" class="close" @click="$modal.hide('timePunchAudit')">
						<i class="ri-close-line"></i>
					</button>
				</div>
				<div class="modal-body p-0">
					<div class="">
						<table class="table table-sm table-flush text-center">
							<thead class="thead-light">
								<tr>
									<th scope="col">{{ $t('label.time start') }} (HH:MM)</th>
									<th scope="col">{{ $t('label.time stop ') }}(HH:MM)</th>
									<th scope="col">{{ $t('label.time clocked') }}</th>
									<th scope="col">{{ $t('label.created by') }}</th>
									<th scope="col">{{ $t('label.last edited by') }}</th>
									<th scope="col">{{ $t('label.edit date') }}</th>
								</tr>
							</thead>
							<tbody>
								<tr class="entries" v-for="(dt, idx) in timePunchesAudit" :key="idx">
									<td>{{ dt.time_in | formatDateTime }}</td>
									<td>{{ dt.time_out | formatDateTime }}</td>
									<td>{{ dt.total_time }} {{ $t('label.hrs') }}</td>
									<td>
										<div v-if="dt.created_by" class="cursor-pointer" @click="getUserInfo(dt.user.id, 'Employee')">
											<img class="rounded-circle" :src="dt.created_by.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
											{{ dt.created_by.name }}
										</div>
									</td>
									<td>{{ dt.created_at | formatDateWithTime }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer border-top">
					<button type="button" class="btn btn-primary mr-auto" @click="$modal.hide('timePunchAudit')">{{ $t('label.close') }}</button>
				</div>
			</div>
		</modal>

		<!-- Edit Time Punch -->
		<modal name="editTimePunchModal" height="auto" width="98%" :max-width="1024" :scrollable="true" :reset="true" :adaptive="true">
			<ReviseTimeSingle :time-punch="punchToEdit" @closeFnc="$modal.hide('editTimePunchModal')" @reload="$emit('reload')">
			</ReviseTimeSingle>
		</modal>
	</div>
</template>

<style scoped>
.icon-timesheet-sm {
	font-size: 0.85rem;
	padding-left: 7px;
}

.row-btm-border {
	border-bottom: 0px hidden !important;
}

.row-arrow-sz {
	font-size: 17px;
}
</style>

<script>
import ReviseTimeSingle from '@/components/TimeManagement/ReviseTimeSingle.vue';
import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex';

export default {
	props: ['flag', 'timePunches', 'task', 'viewTaskTime_timer', 'elapsedTime3', 'checkpending', 'showProximityFlag',
		'totalTaskTime', 'groupbyfilter'],
	emits: ['reload'],
	components: {
		ReviseTimeSingle
	},
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
			tasks: [],
			timesheets: [],
			showTimesheet: [],
			timePunchesAudit: [],
			timesheetProximity: [],
			loader: null,
			punchToEdit: null,
			selectedDate: null,
			taskId: 0,
			current: 0,
			proximityCount: 0,
			openEdit: false,
		}
	},
	mounted() {

	},
	computed: {
		...mapGetters(['company']),
	},
	watch: {
		timePunches(newValue) {
			if (newValue)
				this.getTimePunches();
		},
	},
	methods: {
		initMap(data) {
			let marker1 = {},
				gmarkers = [],
				bounds = new google.maps.LatLngBounds(),
				map = document.getElementById('map-custom-' + this.flag + '-' + data.id),
				jobLat = data.task !== null ? parseFloat(data.task.job.latitude !== null ? data.task.job.latitude : 0) : null,
				jobLng = data.task !== null ? parseFloat(data.task.job.longitude !== null ? data.task.job.longitude : 0) : null,
				myLatlng = new google.maps.LatLng(jobLat, jobLng),
				mapOptions = {
					zoom: 18,
					center: myLatlng,
					gestureHandling: 'greedy',
					mapTypeId: google.maps.MapTypeId.ROADMAP,
				};

			bounds.extend(myLatlng);
			map = new google.maps.Map(map, mapOptions);

			data.markers.forEach((marker, key) => {
				if (marker.position.lat && marker.position.lng) {
					marker1 = new google.maps.Marker({
						position: marker.position,
						map: map,
						animation: google.maps.Animation.DROP,
						icon: marker.icon,
					});

					gmarkers.push(marker1);

					new google.maps.Marker({
						position: marker.position,
						icon: {
							path: google.maps.SymbolPath.CIRCLE,
							scale: 40,
							trokeColor: marker.color,
							fillColor: marker.color,
							strokeColor: marker.color,
							strokeOpacity: 1,
							strokeWeight: 1,
							fillOpacity: 0.2,
						},
						map: map
					});

					bounds.extend(marker.position);
				}
			});

			// map.fitBounds(bounds);

			var listener = google.maps.event.addListener(map, "idle", function () {
				google.maps.event.removeListener(listener);
			});

			new MarkerClusterer(map, gmarkers, {
				maxZoom: 20,
				imagePath:
					"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
			});
		},
		getTimePunches(text) {
			let main = this;
			let checkExist = setInterval(function () {
				if (main.timePunches.length) {
					main.proximityCount = 0;
					main.timePunches.forEach((data) => {
						let clockin = JSON.parse(data.login_location),
							clockout = JSON.parse(data.logout_location),
							jobLat = data.task !== null ? parseFloat(data.task.job.latitude !== null ? data.task.job.latitude : 0) : null,
							jobLng = data.task !== null ? parseFloat(data.task.job.longitude !== null ? data.task.job.longitude : 0) : null,
							lat1 = clockin ? clockin.latitude : null,
							lng1 = clockin ? clockin.longitude : null,
							lat2 = clockout ? (clockout.latitude == 0 ? clockout.latitude : clockout.latitude + 0.00002) : null,
							lng2 = clockout ? (clockout.longitude == 0 ? clockout.longitude : clockout.longitude + 0.00002) : null;

						data.markers = [{
							position: {
								lat: jobLat,
								lng: jobLng
							},
							icon: {
								url: main.getMarkerIcon('rgba(94,114,228,1)'),
								size: { width: 30, height: 30, f: "px", b: "px" },
								scaledSize: { width: 30, height: 30, f: "px", b: "px" }
							},
							color: '#5e72e4'
						},
						{
							position: {
								lat: lat1,
								lng: lng1
							},
							icon: {
								url: main.getMarkerIcon('rgba(45,206,137,1)'),
								size: { width: 30, height: 30, f: "px", b: "px" },
								scaledSize: { width: 30, height: 30, f: "px", b: "px" }
							},
							color: '#2dce89'
						},
						{
							position: {
								lat: lat2,
								lng: lng2
							},
							icon: {
								url: main.getMarkerIcon('rgba(245,54,92,1)'),
								size: { width: 30, height: 30, f: "px", b: "px" },
								scaledSize: { width: 30, height: 30, f: "px", b: "px" }
							},
							color: '#f5365c'
						},
						];
						data.clockinProximity = data.is_resolved ? 1 : main.isWithinProximity(jobLat, jobLng, lat1, lng1, data, 'clockin');
						data.clockoutProximity = data.is_resolved ? 1 : main.isWithinProximity(jobLat, jobLng, lat2, lng2, data, 'clockout');
						data.isWithinProximity = (data.clockinProximity && data.clockoutProximity);

						if (data.clockinProximity == 2 || data.clockoutProximity == 2)
							data.isWithinProximity = 2;

						if (!data.isWithinProximity)
							main.proximityCount++;

						data.lat1 = lat1;
						data.lng1 = lng1;
						data.lat2 = lat2;
						data.lng2 = lng2;
					});

					if (main.flag == 'flag') {
						if (main.proximityCount == 0) {
							bus.$emit('showProximity', {
								isShow: false,
								count: main.proximityCount
							});
						} else {
							bus.$emit('showProximity', {
								isShow: true,
								count: main.proximityCount
							});
						}
					}

				}
				clearInterval(checkExist);
			}, 10);

			this.timesheets = this.timePunches;
		},
		editTimePunch(timePunch) {
			this.punchToEdit = timePunch;
			this.$modal.show('editTimePunchModal');
		},
		isWithinProximity(lat1, lng1, lat2, lng2) {
			let temp = 0;
			if (lat1 && lng1 && lat2 && lng2) {
				temp = (google.maps.geometry.spherical.computeDistanceBetween(
					new google.maps.LatLng(lat1, lng1),
					new google.maps.LatLng(lat2, lng2)
				)) <= 100;

				return temp;
			} else {
				return 2;
			}
		},
		toggleData(data) {
			if (data.showDetails) {
				data.showMap = false;
				data.showDetails = false;
			} else {
				this.$set(data, 'showDetails', true);
				this.$set(data, 'showMap', true);
				this.$nextTick(() => this.initMap(data));
			}
		},
		toggleMap(data) {
			if (data.showMap) {
				data.showMap = false;
			} else {
				this.$set(data, 'showMap', true);
				this.$nextTick(() => this.initMap(data));
			}
		},
		getMarkerIcon(color) {
			return "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='240' height='240'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='" + color + "'/%3E%3C/svg%3E";
		},
		viewTimePunchesAudit(data) {
			this.loader = this.$loading.show();
			axios.post('/get-time-punches-audit', {
				'user_id': data.user_id,
				'task_id': data.task_id,
			}).then(response => {
				this.timePunchesAudit = response.data.result;
				this.$modal.show('timePunchAudit');
				this.loader.hide();
			}).catch(err => {/*console.log('list carriers error:', error)*/ });
		},
		hidePunchAuditModal() {
			this.timePunchesAudit = [];
		},
		updateTimesheetProximity(data) {
			let params = {
				id: data.id,
				logtype: 'both',
				isWithinProximity: 1
			}
			axios.post('/update-timesheet-proximity', params).then(() => {
				bus.$emit('markResolved', {});
			}).catch(err => {
				console.log("timesheet proximity error", err);
			});
		},
		isMobile() {
			return $(window).width() < 481;
		},
	},
}
</script>