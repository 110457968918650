<template>
    <div class="col-xl-12 order-xl-1 mobile-p-0 p-0" id="job-files">
        <div v-if="!hideFilesComponent">
            <div class="">
                <div class="card-header border-0 py-0">
                    <div class="row align-items-start justify-content-between mb-2">
                        <div class="pointer" data-toggle="tab" href="#tabs-btn-list">
                            <h3 class="mb-0 d-flex align-items-center"><i class="ri-folders-line heading-title"></i> {{ $t('label.files') }}</h3>
                        </div>
                        <div class="row">
                            <div class="col tabs tabs-file">
                                <div class="nav btn-group btn-group-sm btn-group-light rounded" role="tablist" aria-label="Basic example" id="tabs-btn">
                                    <a class="btn active" id="tabs-btn-list-tab-modal" data-toggle="tab" href="#tabs-btn-list" role="tab" aria-controls="tabs-btn-basic" aria-selected="true">
                                        <i class="ri-layout-row-line ri-font-size-2"></i>{{ $t('label.list') }}
                                    </a>
                                    <a class="btn" id="tabs-btn-gallery-tab-modal" data-toggle="tab" href="#tabs-btn-gallery" role="tab" aria-controls="tabs-btn-advance" aria-selected="false">
                                        <i class="ri-layout-grid-line"></i>{{ $t('label.gallery') }}
                                    </a>
                                </div>
                            </div>
                            <div class="col-auto text-right">
                                <button type="button" class="btn btn-sm btn-primary" data-toggle="modal" data-target="#addJobFilesModal1" @click="viewUploadFileModal()">
                                    {{ $t('label.add file') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="m-0">
                    <div class="card-body p-0">
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="tabs-btn-list" role="tabpanel" aria-labelledby="tabs-btn-list-tab-modal">
                                <!-- TABLE -->
                                <div v-if="alljobfiles.length == 0">
                                    <div class="card-body m-0 py-0">
                                        <div class="bg-white text-center row align-items-center m-0">
                                            <i class="ri-folders-line ni-8x w-100 color-gray-400"></i>
                                            <p class="w-100 font-weight-normal">{{ $t('label.No Files have been added to this Job') }}.</p>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="alljobfiles.length > 0" class="table-responsive pb-4">
                                    <table class="table table-flush table-sm" id="datatable-jobfiles1">
                                        <thead class="thead-light">
                                            <tr>
                                                <th scope="col">{{ $t('label.file name') }}</th>
                                                <th scope="col">{{ $t('label.description') }}</th>
                                                <th scope="col">{{ $t('label.related task') }}</th>
                                                <th scope="col">{{ $t('label.uploaded by') }}</th>
                                                <th scope="col">{{ $t('label.uploaded date') }}</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data, index) in paginatedJobFiles" :key="index" :id="'file-hover-' + data.id" @mouseover="showFileBtns(data.id)" @mouseout="hideFileBtns(data.id)">
                                                <td class="w-25">
                                                    <div :class="'file-table-buttons file-buttons-' + data.id" style="display: none;">
                                                        <div class="row m-1 justify-content-center">
                                                            <button type="button" class="shadow rounded btn text-primary bg-white" data-toggle="modal" data-target="#viewFileModal1" @click="viewFileModal(data.id)">
                                                                <i class="ri-eye-line"></i> {{ $t('label.view') }}
                                                            </button>
                                                            <a :href="'/uploads/' + data.filename" class="shadow rounded btn text-primary bg-white" download>
                                                                <i class="ri-download-line"></i> {{ $t('label.download') }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="row align-items-center m-0">

                                                        <img class="image-container image-container-thumbnail float-right mr-2" :src="'/' + data.filename_path" />
                                                        <a href="#" data-toggle="modal" data-target="#viewFileModal1" @click="viewFileModal(data.id)">
                                                            <span v-if="data.display_name">{{ data.display_name }}</span>
                                                            <span v-else>{{ data.filename }}</span>
                                                        </a>
                                                    </div>
                                                </td>
                                                <td class="w-25">{{ data.description }}</td>
                                                <td class="w-20">{{ data.task ? data.task.name : '' }}</td>
                                                <td class="w-20">
                                                    <div class="row align-items-center m-0">
                                                        <img class="avatar-img rounded-circle float-right mr-2" :src="data.created_by.picture" alt="">
                                                        {{ data.created_by.name }}
                                                    </div>
                                                </td>
                                                <td class="w-5">{{ data.created_at | formatDate }}</td>
                                                <td class="text-right w-5">
                                                    <div class="dropdown">
                                                        <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i class="fas fa-ellipsis-v"></i>
                                                        </a>
                                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                            <button class="dropdown-item pointer" data-toggle="modal" data-target="#editFileModal1" @click="editFileModal(data)">{{ $t('label.edit') }}</button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table-pagination v-model="paginatedJobFiles" :btnSize="'md'" :all-rows="alljobfiles" :perPage="5">
                                    </table-pagination>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="tabs-btn-gallery" role="tabpanel" aria-labelledby="tabs-btn-gallery-tab-modal">
                                <!-- GALLERY -->
                                <div v-if="alljobfiles.length == 0">
                                    <div class="card-body m-0 py-0">
                                        <div class="bg-white text-center row align-items-center m-0">
                                            <i class="ri-folders-line ni-8x w-100 color-gray-400"></i>
                                            <p class="w-100 font-weight-normal">{{ $t('label.No Files have been added to this Job') }}.</p>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="file-gallery p-4">
                                    <div class="form-group d-flex align-items-center justify-content-end row">
                                        <label class="form-control-label text-muted text-right m-0 col-lg-2 col-12">{{ $t('label.sorted by') }}</label>
                                        <select class="form-control p-1 h-100 col-lg-3 col-12" @change="sortByDate($event)">
                                            <option value="asc">{{ $t('label.date uploaded (ascending)') }}</option>
                                            <option value="desc">{{ $t('label.date uploaded (descending)') }}</option>
                                        </select>
                                    </div>
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="card-container card-container-modal pointer pb-4 col-lg-2 col-md-4 col-xs-12" v-for="(file, index) in alljobfiles" :key="index">
                                                <div id="my-table" class="card p-0 m-0" @mouseover="showFileBtns(index)" @mouseout="hideFileBtns(index)">
                                                    <img class="file-image" :src="'/' + file.filename_path" />
                                                    <div :class="'text-center file-buttons file-buttons-' + index">
                                                        <div class="row file-container">
                                                            <button type="button" class="btn btn-outline-primary w-100" data-toggle="modal" data-target="#viewFileModal1" @click="viewFileModal(file.id)">
                                                                <i class="ri-eye-line mrn-5"></i>
                                                                {{ $t('label.view') }}
                                                            </button>
                                                            <a :href="'/uploads/' + file.filename" class="btn btn-outline-primary w-100" download>
                                                                <i class="ri-download-line mrn-5"></i>
                                                                {{ $t('label.download') }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="card-body pt-3 card-filename">
                                                        <a href="#" data-toggle="modal" data-target="#viewFileModal1" @click="viewFileModal(file.id)" class="form-control-label">
                                                            <span v-if="file.display_name">{{ file.display_name }}</span>
                                                            <span v-else>{{ file.filename }}</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Upload Modal -->
        <modal height="auto" :name="'taskDetailsAddJobFile'" :scrollable="true" :reset="true" :adaptive="true">
            <job-file-upload v-if="showUploadFileModal" :jobid="jobid" @close-add-file-modal="closeUploadFileModal" @reload="getJobFiles">
            </job-file-upload>
        </modal>

        <!-- View Modal -->
        <div class="modal" id="viewFileModal1" tabindex="-1" role="dialog" aria-labelledby="viewFileModal1Title" aria-hidden="true">
            <div class="modal-dialog" role="document" v-for="(file, index) in alljobfiles" :key="index">
                <div class="modal-content" v-if="file.id == jobFileId">
                    <div class="modal-header border-bottom">
                        <h5 class="modal-title" id="viewFileModal1Title">
                            <span v-if="file.display_name">{{ file.display_name }}</span>
                            <span v-else>{{ file.filename }}</span>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" data-toggle="modal" data-target="#viewTaskDetailsModal" aria-label="Close">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                    <div class="modal-body p-0">
                        <div class="row">
                            <div class="col-lg-8 col-12 p-4 text-center" style="background: #F6F9FC;">
                                <div v-if="file.fileType == 'pdf' || file.fileType == 'xlsx' || file.fileType == 'pptx' || file.fileType == 'docx' || file.fileType == 'doc' || file.fileType == 'txt' || file.fileType == 'rtf' || file.fileType == 'heic' || file.fileType == 'tiff'">
                                    <div class="w-100">
                                        <img class="w-25" src="/images/icons/other-file.png" alt="Card image cap">
                                    </div>
                                    <div class="pt-3 pb-4 w-100">{{ $t('label.Preview unavailable for this file type') }}.</div>
                                    <a class="btn btn-primary" :href="'/uploads/' + file.filename" download>
                                        <i class="ri-download-line pr-2"></i> {{ $t('label.download') }}
                                    </a>
                                </div>
                                <img v-else class="mw-100 mh-70vh" :src="'/' + file.filename_path" alt="Card image cap">
                            </div>
                            <div class="col-lg-4 col-12 p-4">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <h1 class="p-0 col-lg-10" style="word-break: break-all;">
                                                <span v-if="file.display_name">{{ file.display_name }}</span>
                                                <span v-else>{{ file.filename }}</span>
                                            </h1>
                                            <button type="button" class="btn btn-primary btn-sm m-0 p-0 dropdown-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fas fa-ellipsis-h"></i>
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                <a class="dropdown-item d-flex align-items-center" href="#" data-toggle="modal" data-target="#editFileModal1" @click="editFileModal(file)">
                                                    <i class="ri-pencil-line pr-2"></i> {{ $t('label.edit') }}
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center" :href="'/uploads/' + file.filename" download>
                                                    <i class="ri-download-line pr-2"></i>
                                                    {{ $t('label.download') }}
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center text-danger" href="#" data-toggle="modal" @click="viewFileModal(file.id, true)">
                                                    <i class="ri-delete-bin-line pr-2"></i> {{ $t('label.delete') }}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.related task') }}</label>
                                            <div class="input-value">
                                                {{ file.task == null ? 'N/A' : file.task.name }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.description') }}</label>
                                            <div class="input-value">{{ file.description }}</div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.uploaded by') }}</label>
                                            <div class="input-value">
                                                <img class="rounded-circle" :src="file.created_by.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                                <span class="px-2">{{ file.created_by.name }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.uploaded date') }}</label>
                                            <div class="input-value">
                                                {{ file.created_at | formatDate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer border-top d-flex justify-content-between">
                        <a class="btn btn-primary" :href="'/uploads/' + file.filename" download>
                            <i class="ri-download-line pr-2"></i>
                            {{ $t('label.download') }}
                        </a>
                        <button type="button" class="btn btn-link  ml-auto close2editfile" data-dismiss="modal" data-toggle="modal" data-target="#viewTaskDetailsModal">{{ $t('label.close') }}</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- EDIT Modal -->
        <div class="modal" id="editFileModal1" tabindex="-1" role="dialog" aria-labelledby="editFileModal1Title" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header border-bottom">
                        <h5 class="modal-title" id="editFileModal1Title">
                            <span v-if="jobFile.display_name">{{ jobFile.display_name }}</span>
                            <span v-else>{{ jobFile.filename }}</span>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" data-toggle="modal" data-target="#viewTaskDetailsModal" aria-label="Close">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                    <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="updateFileDetails">
                        <div class="modal-body p-0">
                            <div class="row">
                                <div class="col-lg-8 col-12 p-4 text-center" style="background: #F6F9FC;">
                                    <div v-if="jobFile.fileType == 'pdf' || jobFile.fileType == 'xlsx' || jobFile.fileType == 'pptx' || jobFile.fileType == 'docx' || jobFile.fileType == 'doc' || jobFile.fileType == 'txt' || jobFile.fileType == 'rtf' || jobFile.fileType == 'heic' || jobFile.fileType == 'tiff'">
                                        <div class="w-100">
                                            <img class="w-25" src="/images/icons/other-file.png" alt="Card image cap">
                                        </div>
                                        <div class="pt-3 pb-4 w-100">{{ $t('label.Preview unavailable for this file type') }}.</div>
                                        <a class="btn btn-primary" :href="'/uploads/' + jobFile.filename" download>
                                            <i class="ri-download-line pr-2"></i>
                                            {{ $t('label.download') }}
                                        </a>
                                    </div>
                                    <img v-else class="mw-100 mh-70vh" :src="'/' + jobFile.filename_path" alt="Card image cap">
                                </div>
                                <div class="col-lg-4 col-12 p-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="form-control-label" for="input-name">{{ $t('label.file name') }}</label>
                                                <input class="form-control" v-model="jobFile.name" />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="form-control-label" for="input-name">{{ $t('label.related task') }}</label>
                                                <select class="form-control" v-model="jobFile.task_id" required>
                                                    <option v-for="(task, index) in tasks" :value="task.id" :key="index">{{ task.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="form-control-label" for="input-name">{{ $t('label.file description') }}</label>
                                                <textarea v-model="jobFile.description" name="description" class="form-control" :placeholder="$t('description')" required autofocus></textarea>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.uploaded by') }}</label>
                                                <div class="input-value">
                                                    <img class="rounded-circle" :src="jobFile.created_by.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                                    <span class="px-2">{{ jobFile.created_by.name }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.uploaded date') }}</label>
                                                <div class="input-value">
                                                    {{ jobFile.created_at | formatDate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-right px-3">
                                        <button type="submit" class="btn btn-default">{{ $t('label.save') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer border-top d-flex justify-content-between">
                            <a class="btn btn-primary" :href="'/uploads/' + jobFile.filename" download>
                                <i class="ri-download-line pr-2"></i>
                                {{ $t('label.download') }}
                            </a>
                            <button type="button" class="btn btn-link  ml-auto close2editfile" data-dismiss="modal" data-toggle="modal" data-target="#viewTaskDetailsModal">{{ $t('label.close') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Delete Modal -->
        <div class="modal fade" id="deleteFileModal1" tabindex="-1" role="dialog" aria-labelledby="deleteFileModal1Title" aria-hidden="true">
            <div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
                <div class="modal-content bg-gradient-danger">
                    <div class="modal-header">
                        <button type="button" class="close" aria-label="Close" @click="closeDeleteFileModal">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="deleteFile">
                        <div class="modal-body">
                            <div class="py-3 text-center">
                                <i class="ri-error-warning-line ni-3x"></i>
                                <h4 class="heading mt-4">{{ $t('label.ARE YOU SURE YOU WANT TO DELETE THIS FILE') }}?</h4>
                                <p>{{ $t('label.This action cannot be undone') }}!</p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-white" id="deleteBtn">{{ $t('label.Yes, Delete') }}</button>
                            <button type="button" class="btn btn-link text-white ml-auto" @click="closeDeleteFileModal">{{ $t('label.cancel') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.dropzone .dz-preview .dz-error-message {
    opacity: 1 !important;
    top: 0 !important;
}
</style>

<script>
import JobFileUpload from '@/components/Jobs/JobFileUpload.vue';
import TablePagination from '@/components/TablePagination';
import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex';

export default {
    props: ['jobid', 'showFilesByTask', 'taskId', 'hideFilesComponent'],
    components: {
        JobFileUpload,
        TablePagination
    },
    data() {
        return {
            uploadedFiles: [],
            uploadedFilesUpload: [],
            paginatedJobFiles: [],
            isSameRelatedTask: false,
            isSameFileDescription: false,
            tasks: [],
            jobFileId: 0,
            isUploadCancel: false,
            currentTaskId: 0,
            errorDropzone: null,
            inputfile: null,
            showDropdown: false,
            showUploadFileModal: false,
            form: {
                add: {
                    name: null,
                    description: null,
                    download: null,
                    relatedTask: null,
                },
                edit: {
                    name: null,
                    description: null,
                    download: null,
                    download2: null,
                    id: null,
                    relatedTask: null,
                }
            },
            jobFile: {
                created_by: {
                    name: null,
                    picture: null
                }
            }
        }
    },
    computed: {
        ...mapGetters(['alljobfiles']),
    },
    mounted() {
        this.getJobFiles();
        this.getTasks();
    },
    created: function () {
        bus.$on('getTaskId', (id) => {
            this.currentTaskId = id;
            this.form.add.relatedTask = id;
            this.isSameRelatedTask = true;
            this.viewUploadFileModal();
        });
        this.$parent.$on('click', this.viewUploadFileModal);
    },
    methods: {
        viewUploadFileModal() {
            this.getTasks();
            this.$modal.show('taskDetailsAddJobFile');
            this.showUploadFileModal = true;
        },
        closeUploadFileModal() {
            this.$modal.hide('taskDetailsAddJobFile');
            this.showUploadFileModal = false;
        },
        cancelFileUpload() {
            this.isUploadCancel = true;
            this.uploadedFiles = [];
            this.uploadedFilesUpload = [];
        },
        getTasks() {
            axios.get('/tasks/' + this.jobid)
                .then(response => {
                    this.tasks = response.data.result;
                    this.tasks.push({ id: 0, name: 'N/A', sequence: 0 });
                    this.tasks = _.orderBy(this.tasks, ['sequence'], ['asc']);
                })
                .catch(err => console.error("API ERROR", err));
        },
        viewFileModal(id, isDelete) {
            if (isDelete) {
                $('#deleteFileModal1').modal('show');
                $('#deleteFileModal1').css('overflow', 'hidden');
                $('#editFileModal1').modal('hide');
                $('#viewFileModal1').modal('hide');
            }
            this.jobFileId = id;
        },
        showFileBtns(id) {
            $("#job-files .file-buttons-" + id).show();
            $("#job-files .file-buttons-" + id).addClass('file-hover');
            $('#file-hover-' + id).css('background-color', '#e9ecef');
        },
        hideFileBtns(id) {
            $("#job-files .file-buttons-" + id).hide();
            $("#job-files .file-buttons-" + id).removeClass('file-hover');
            $('#file-hover-' + id).css('background-color', 'unset');
        },
        deleteFile() {
            axios.post('/delete-file/' + this.jobFileId)
                .then(response => {
                    this.getJobFiles();
                    $('#deleteFileModal1').modal('hide');
                })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        sortByDate(event) {
            let sort = event.target.value;
            this.alljobfiles.sort((a, b) => a.weight < b.weight ? 1 : -1);
        },
        getJobFiles() {
            this.$store.dispatch('getJobFiles', this.jobid);
        },
        getUploadedFiles: function (file, response) {
            let filename = file.upload.filename;
            let fileType = filename.split('.').pop();
            file.fileType = fileType;

            if (fileType === 'pdf') {
                file.filePath = 'images/icons/doc-pdf.png';
                file.upload.filePath = 'images/icons/doc-pdf.png';
            } else if (fileType === 'xlsx') {
                file.filePath = 'images/icons/doc-excel.png';
                file.upload.filePath = 'images/icons/doc-excel.png';
            } else if (fileType === 'pptx') {
                file.filePath = 'images/icons/doc-ppt.png';
                file.upload.filePath = 'images/icons/doc-ppt.png';
            } else if (fileType === 'docx' || fileType === 'doc') {
                file.filePath = 'images/icons/doc-word.png';
                file.upload.filePath = 'images/icons/doc-word.png';
            } else if (fileType === 'txt' || fileType === 'rtf' || fileType === 'heic' || fileType === 'tiff') {
                file.filePath = 'images/icons/doc-other.png';
                file.upload.filePath = 'images/icons/doc-other.png';
            } else {
                file.filePath = 'uploads/' + encodeURI(filename);
                file.upload.filePath = 'uploads/' + encodeURI(filename);
            }

            if (this.showFilesByTask) {
                file.upload.relatedTask = this.currentTaskId;
            }

            this.uploadedFiles.push(file);
            this.uploadedFilesUpload.push(file);
        },
        updateFileDetails() {
            this.jobFile.task_id = this.jobFile.task_id == 0 ? null : this.jobFile.task_id;
            this.jobFile.name = this.jobFile.name.split('.')[0] + '.' + this.jobFile.fileType;

            axios.post('/jobfiles/update', this.jobFile)
                .then(response => {
                    this.getJobFiles();
                    this.viewFileModal(this.jobFile.id);
                    $('#editFileModal1').modal('hide');
                    $('#viewFileModal1').modal('show');

                })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        sameFileDescription() {
            if (!this.isSameFileDescription) {
                this.form.add.description = this.uploadedFilesUpload.length ? this.uploadedFilesUpload[0].upload.description : null;
            } else {
                this.uploadedFilesUpload[0].upload.description = this.form.add.description;
            }
        },
        sameRelatedTask() {
            if (!this.isSameRelatedTask) {
                this.form.add.relatedTask = this.uploadedFilesUpload.length ? this.uploadedFilesUpload[0].upload.relatedTask : null;
            } else {
                this.uploadedFilesUpload[0].upload.relatedTask = this.form.add.relatedTask;
            }
        },
        onFileChange(e) {
            this.form.add.download = e.target.files[0];
        },
        onFileChange2(e) {
            this.form.edit.download2 = e.target.files[0];
        },
        editFileModal(data) {
            this.jobFile = data;
            this.jobFile.name = data.display_name ? data.display_name : data.filename;
        },
        closeDeleteFileModal() {
            $('#deleteFileModal1').modal('hide');
        }
    }
}
</script>
