<template>
	<div>
		<div class="header bg-primary sp-comp-banner"></div>
		<div class="container-fluid pb-5 sp-mobile-mt-50">
			<!-- HEADER -->
			<div class="row align-items-center py-4">
				<div class="col">
					<!-- Breadcrumbs -->
					<breadcrumbs :title="$t('label.crews')">
						<!-- Breakcrumb slots -->
						<li class="breadcrumb-item">
							{{ $t('label.administrative') }}
						</li>
						<li class="breadcrumb-item">
							{{ $t('label.content management') }}
						</li>
						<li class="breadcrumb-item">
							{{ $t('label.crews') }}
						</li>
					</breadcrumbs>
				</div>
				<!-- Add Crews Button -->
				<div class="col-12 col-xs-6 col-sm-3 text-right">
					<button type="button" class="btn btn-neutral" data-toggle="modal" @click="openAddEditModal(null, 'add')">
						{{ $t('label.add crew') }}
					</button>
				</div>

			</div>
			<div class="row" v-for="(data, index) in allCrews" :key="index">
				<div class="col">
					<div class="card crew-page">
						<div class="card-header">
							<div class="row align-items-center m-0 justify-content-between">
								<a :id="'crew-header-' + index" class="mobile-mb-10p" data-toggle="collapse" :href="'.crew-details-' + index" @click="toggleIcon(index)">
									<div>
										<i class="fas fa-chevron-down"></i>
										<h3 class="mb-0 ml-2 d-inline">{{ data.name }}</h3>
									</div>
								</a>
								<div>
									<button type="button" class="btn btn-danger btn-sm float-right ml-3" @click="deleteNotify(data.id)"><i class="ri-delete-bin-line p-0"></i></button>
									<button type="button" class="btn btn-primary btn-sm float-right" data-toggle="modal" @click="openAddEditModal(index, 'edit')">{{ $t('label.edit crew') }}</button>
								</div>
							</div>
						</div>

						<div :class="'table-responsive collapse crew-details-' + index">
							<div v-if="1 > data.members.length" class="text-sm text-muted p-3">
								{{ $t('label.Crew does not contain any members') }}
							</div>
							<table v-else class="table align-items-center table-flush">
								<thead class="thead-light">
									<tr>
										<th scope="col">{{ $t('label.name') }}</th>
										<th scope="col">{{ $t('label.position') }}</th>
										<th scope="col">{{ $t('label.phone') }}</th>
										<th scope="col">{{ $t('label.email') }}</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(d, i) in data.members" :key="i">
										<td @click="getUserInfo(d.user.id, d.user.role.name)" class="cursor-pointer">
											<img class="rounded-circle" :src="d.user.picture" alt="" style="width: 36px; height: 36px; object-fit: cover" />
											<span v-if="d.user.status == 0">
												{{d.user.name}} <span style="color:#FF0000">INACTIVE</span>
											</span>
											<span v-else>
											{{ d.user.name }}
											</span>
										</td>
										<td>{{ d.user.role.name }}</td>
										<td>{{ d.user.phone }}</td>
										<td>
											<a :href="'mailto:' + d.user.email">{{ d.user.email }}</a>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			<modal-delete :newid="newid" :deleteMethod="removeCrew" :deleteModalText="deleteModalText"></modal-delete>

			<modal-crew-add-edit v-if='showAddEditCrewModal' :new-id="selected_crew" :all-crews="current_crews" :all-users="current_users" :mode="set_mode" @onClose='closeCrewAddEditModal'>
			</modal-crew-add-edit>
		</div>
	</div>
</template>
 
<style>
.crew-member-avatar {
	margin-left: -5px;
}

.collapsing {
	visibility: hidden;
}

.collapse.show {
	visibility: visible;
}

.crew-page .card-header {
	background-color: #d8e5f2 !important;
}
</style>

<script>
import bus from '@/utils/event-bus';
import ModalCrewAddEdit from './ModalCrewAddEdit.vue';
export default {
	components: {
		ModalCrewAddEdit
	},
	data() {
		return {
			allCrews: [],
			allUsers: [],
			current_crews: [],
			current_users: [],
			crewIndex: 0,
			loader: null,
			delete_id: null,
			selected_crew: null,
			showAddEditCrewModal: false,
			crewId: '',
			newid: 'DeleteCrew',
			newId: 'crew page',
			set_mode: '',
			deleteModalText: {
				title: 'ARE YOU SURE YOU WANT TO DELETE THIS CREW?',
				button: 'Yes, Delete'
			}
		}
	},
	created() {
		this.loader = this.$loading.show();
		let promises = [
			this.getAllCrews(),
			this.getAllUsers()
		];

		Promise.all(promises).then(() => {
			this.loader.hide();
		});
	},
	computed: {
		modalFormIsInvalid() {
			if (0 < this.modalFields.crewMembers.length) {
				return false;
			} else {
				return true;
			}
		}
	},
	mounted() {
		bus.$on('deleteFCrew', () => {
			this.removeCrew();
		});
	},
	methods: {
		getAllCrews() {
			return axios.get('/get-all-crews2').then(response => {
				this.allCrews = response.data.result;
			})
				.catch(err => console.error(err));
		},
		getAllUsers() {
			return axios.get('/get-all-users').then(response => {
				this.allUsers = response.data.result;
			}).catch(err => console.error(err));
		},
		closeCrewAddEditModal() {
			this.showAddEditCrewModal = false;
			this.getAllCrews();
		},
		openAddEditModal(index, mode) {
			this.set_mode = mode;
			this.showAddEditCrewModal = true;
			this.selected_crew = index;
			this.current_crews = this.allCrews;
			this.current_users = this.allUsers;
		},
		toggleIcon(index) {
			let icon = $('#crew-header-' + index).find('i');
			if (icon.hasClass('fa-chevron-down')) {
				icon.removeClass('fa-chevron-down').addClass('fa-chevron-up');
			} else {
				icon.removeClass('fa-chevron-up').addClass('fa-chevron-down');
			}
		},
		removeCrew(crewId) {
			axios
				.post('/delete-crew/' + this.delete_id)
				.then(response => {
					this.getAllCrews();
					bus.$emit('banner-success', {
						message: 'Crew deleted successfully!',
					});
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		deleteNotify(delete_id) {
			this.delete_id = delete_id;
			$("#deleteModal" + this.newid).modal('show');
		},
	},
};
</script>