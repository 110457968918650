<template>
	<div class="card h-100">
		<div class="card-header">
			<div class="row align-items-center">
				<h3 class="mb-0 d-flex align-items-center"><i class="ri-drag-move-fill"></i>{{ name }}</h3>
			</div>
		</div>
		<div v-if="data.length == 0">
			<div class="card-body m-0 py-0">
				<div class="bg-white text-center row align-items-center m-0">
					<div v-if="name == 'Open Jobs'" class="w-100 text-center align-items-center">
						<i class="ri-stack-line ni-8x w-100 color-gray-300"></i>
						<p class="lead w-100 mt-0 font-weight-normal">{{ $t('label.No Open Jobs') }}.</p>
						<p class="w-100 font-weight-normal">{{ $t('label.All of your Open Jobs will be listed here') }}.</p>
					</div>
					<div v-if="name == 'Open Tasks'" class="w-100 text-center align-items-center">
						<i class="ri-todo-line ni-8x w-100 color-gray-300"></i>
						<p class="lead w-100 mt-0 font-weight-normal">{{ $t('label.No Open Tasks') }}.</p>
						<p class="w-100 font-weight-normal">{{ $t('label.All of your Open Tasks will be listed here') }}.</p>
					</div>
					<div v-if="name == 'Overdue Jobs'" class="w-100 text-center align-items-center">
						<i class="ri-thumb-up-line ni-8x w-100 color-gray-300"></i>
						<p class="lead w-100 mt-0 font-weight-normal">{{ $t('label.You are on schedule') }}!</p>
						<p class="w-100 font-weight-normal">{{ $t('label.You do not have any overdue Jobs') }}. {{ $t('label.Great work') }}!</p>
					</div>
					<div v-if="name == 'Overdue Tasks'" class="w-100 text-center align-items-center">
						<i class="ri-task-line ni-8x w-100 color-gray-300"></i>
						<p class="lead w-100 mt-0 font-weight-normal">{{ $t('label.Amazing work') }}!</p>
						<p class="w-100 font-weight-normal">{{ $t('label.All of your Tasks are on schedule') }}. {{ $t('label.Keep it up') }}!</p>
					</div>
					<div v-if="name == 'Recently Closed Jobs'" class="w-100 text-center align-items-center">
						<i class="ri-archive-line ni-8x w-100 color-gray-300"></i>
						<p class="lead w-100 mt-0 font-weight-normal">{{ $t('label.Nothing to show here') }}.</p>
						<p class="w-100 font-weight-normal">{{ $t('label.None of your Jobs have been closed in the last 3 days') }}.</p>
					</div>
					<div v-if="name == 'Recently Closed Tasks'" class="w-100 text-center align-items-center">
						<i class="ri-todo-line ni-8x w-100 color-gray-300"></i>
						<p class="lead w-100 mt-0 font-weight-normal">{{ $t('label.Nothing to show here') }}.</p>
						<p class="w-100 font-weight-normal">{{ $t('label.None of your Tasks have been closed in the last 3 days') }}.</p>
					</div>
					<div v-if="name == 'Recently Added Jobs'" class="w-100 text-center align-items-center">
						<i class="ri-cloud-line ni-8x w-100 color-gray-300"></i>
						<p class="lead w-100 mt-0 font-weight-normal">{{ $t('label.Nothing to show here') }}.</p>
						<p class="w-100 font-weight-normal">{{ $t('label.You have not been assigned any new Jobs in the last 3 days') }}.</p>
					</div>
					<div v-if="name == 'Clocked In Employees'" class="w-100 text-center align-items-center">
						<i class="ri-zzz-line ni-8x w-100 color-gray-300"></i>
						<p class="lead w-100 mt-0 font-weight-normal">{{ $t('label.Seems pretty quiet') }}...</p>
						<p class="w-100 font-weight-normal">{{ $t('label.Maybe everyone is still getting their daily coffee') }}.</p>
					</div>
					<div v-if="name == 'Clocked In Employees (Over 8 Hours)'" class="w-100 text-center align-items-center">
						<i class="ri-history-line ni-8x w-100 color-gray-300"></i>
						<p class="lead w-100 mt-0 font-weight-normal">{{ $t('label.Everything looks good here') }}!</p>
						<p class="w-100 font-weight-normal">{{ $t('label.Looks like everyone remembered to clock out for the day') }}!</p>
					</div>
				</div>
			</div>
		</div>

		<div v-if="data.length > 0" class="table-responsive pb-4 h-100">
			<!-- <table class="table table-flush table-sm" :id="'table-widgets-'+tableid" style="margin: 0 !important;"> -->
			<table class="table table-sm" :id="'table-widgets-' + tableid" style="margin: 0 !important;">
				<thead class="thead-light">
					<tr>
						<th v-for="(item, index) in columns" :key="index" scope="col">{{ item }}</th>
						<th scope="col" style="width: 18%;"></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in paginatedData" :key="index">
						<td v-if="type == 'job'">
							<a v-if="user.role_id == '2' && item.job_number" class="selected-text-white" :href="'/job/' + item.job_number.job_number + '/edit'" target="_blank">{{ item.job_name }}</a>
							<a v-else class="selected-text-white">{{ item.job_name }}</a>
						</td>
						<td v-if="type == 'job'">
							<a v-if="user.role_id == '2'" class="pointer selected-text-white text-dark-blue" @click="getUserInfo(item.customer.id, 'Customer')">{{ item.customer ? item.customer.name : '' }}</a>
							<a v-else class="selected-text-white">{{ item.customer ? item.customer.name : '' }}</a>
						</td>
						<td v-if="type == 'job' && name == 'Overdue Jobs'"><span class="badge badge-danger" style="font-size: 85%;">{{ item.end_date | formatDate }}</span></td>

						<td v-if="type == 'task'">
							<a class="pointer selected-text-white text-dark-blue search-task" @click="getJobId(item)">{{ item.name }}</a>
						</td>

						<td v-if="type == 'task'">
							<a v-if="user.role_id == '2' && item.job.job_number" class="selected-text-white" :href="'/job/' + item.job.job_number.job_number + '/edit'" target="_blank">{{ item.job.job_name }}</a>
							<a v-else class="selected-text-white">{{ item.job.job_name }}</a>
						</td>
						<td v-if="type == 'task' && name == 'Overdue Tasks'"><span class="badge badge-danger" style="font-size: 85%;">{{ item.due_date | formatDate }}</span></td>

						<td v-if="type == 'timesheet' || type == 'pto'">
							<div class="d-flex align-items-center">
								<div class="" :class="{ 'crew-member-avatar': 0 < i }">
									<img :src="item.user.picture" alt="" class="avatar-img rounded-circle">
									<i v-if="item.status" class="clockedin-icon"></i>
								</div>
								{{ item.user.name }}
							</div>
						</td>
						<td v-if="type == 'timesheet'">{{ item.task ? item.task.name : '' }}</td>
						<td v-if="type == 'timesheet'">{{ item.total_time | hourToFormattedTime }} ({{ item.total_time }} {{ $t('label.hrs') }})</td>

						<td v-if="type == 'pto'">{{ item.user.created_at | formatDate }}</td>
						<td v-if="type == 'pto'">{{ item.user.created_at | formatDate }}</td>
						<td class="text-right">
							<div v-if="type == 'job'" class="avatar avatar-sm rounded-circle float-left cursor-pointer">
								<img v-if="item.job_manager" :src="item.job_manager.picture" alt="" class="avatar-img rounded-circle">
							</div>
							<div v-if="type == 'task' && item.assigned_to.length" :class="{ 'crew-member-avatar': 0 < i }">
								<template v-for="(d, i) in item.assigned_to">
									<img :key="i" v-if="d.user" :src="d.user.picture" alt="" class="avatar-img rounded-circle">
								</template>
							</div>

							<!-- Job Dropdown -->
							<div v-if="type == 'job'" class="dropdown">
								<a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<i class="fas fa-ellipsis-v"></i>
								</a>
								<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
									<a class="dropdown-item" href="#" data-toggle="modal" data-dismiss="modal" @click="getJobId(item); $modal.show('editJobModal')">
										<!-- <a class="dropdown-item" href="#" data-toggle="modal" data-target="#editJobModal" data-dismiss="modal" @click="getJobId(item)"> -->
										<i class="ri-pencil-line"></i> {{ $t('label.edit job') }}
									</a>
									<a v-if="item.job_status.name !== 'Closed'" class="dropdown-item" href="#" data-toggle="modal" data-dismiss="modal" @click="toggleCloseJobModal(item, true)">
										<i class="ri-check-line"></i> {{ $t('label.close job') }}
									</a>
									<a v-else class="dropdown-item" href="#" data-toggle="modal" data-dismiss="modal" @click="reopenJob(item); $modal.show('editJobModal')">
										<!-- <a v-else class="dropdown-item" href="#" data-toggle="modal" data-target="#editJobModal" data-dismiss="modal" @click="reopenJob(item)"> -->
										<i class="ri-inbox-unarchive-line"></i> {{ $t('label.reopen job') }}
									</a>
									<div class="dropdown-divider"></div>
									<a class="dropdown-item" href="#" data-toggle="modal" data-target="#addJobNotesModal" @click="getJobId(item)">
										<i class="ri-draft-line"></i> {{ $t('label.add job note') }}
									</a>
									<a class="dropdown-item" href="#" data-toggle="modal" data-target="#addJobFilesModal" @click="$emit('click'); getJobId(item)">
										<i class="ri-folders-line"></i> {{ $t('label.add file') }}
									</a>
									<div class="dropdown-divider"></div>
									<a v-if="item.job_number" class="dropdown-item" :href="'/job/' + item.job_number.job_number + '/edit/'" target="_blank"><i class="ri-stack-line"></i> {{ $t('label.view job') }}</a>
								</div>
							</div>

							<!-- Task Dropdown -->
							<div v-if="type == 'task'" class="dropdown">
								<a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<i class="fas fa-ellipsis-v"></i>
								</a>
								<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
									<a class="dropdown-item" href="#" data-dismiss="modal" @click="getJobId(item)">
										<i class="ri-pencil-line"></i> {{ $t('label.edit task') }}
									</a>
									<div class="dropdown-divider"></div>
									<a class="dropdown-item" href="#" data-toggle="modal" data-target="#addJobNotesModal" @click="getJobId(item)">
										<i class="ri-draft-line"></i> {{ $t('label.add job note') }}
									</a>
									<a class="dropdown-item" href="#" data-toggle="modal" data-target="#addJobFilesModal" @click="$emit('click'); getJobId(item)">
										<i class="ri-folders-line"></i> {{ $t('label.add file') }}
									</a>
									<div v-if="item.job_number" class="dropdown-divider"></div>
									<a v-if="item.job_number" class="dropdown-item" :href="'/job/' + item.job_number.job_number + '/edit/'" target="_blank"><i class="ri-stack-line"></i> {{ $t('label.view job') }}</a>
									<div class="dropdown-divider"></div>
									<div v-if="item.task_status">
										<a v-if="item.task_status.name != 'Closed'" class="dropdown-item" href="#" @click="markAsComplete(item)"><i class="ri-check-line"></i> {{ $t('label.mark as complete') }}</a>
										<a v-if="item.task_status.name == 'Closed'" class="dropdown-item" href="#"><i class="fas fa-times"></i> {{ $t('label.task closed') }}</a>
									</div>
								</div>
							</div>
							<!-- Timesheet Dropdown -->
							<div v-if="type == 'timesheet'" class="dropdown">
								<a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<i class="fas fa-ellipsis-v"></i>
								</a>
								<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" style="min-width: 300px;">
									<div v-if="!item.status && (user.role_id != '4' || user.id == d.user.id)" class="">
										<div v-if="!item.statusDisabled">
											<button type="button" class="dropdown-item" style="cursor: pointer;" @click="clockIn(item)">
												<i class="ri-time-line"></i> {{ $t('label.clock in') }}
											</button>
										</div>
										<div v-if="item.statusDisabled" class="row m-0 w-100">
											<div class="col-4 d-inline-block p-0" data-toggle="tooltip" data-placement="top" title="This person is already clocked in to another Task.">
												<button type="button" class="dropdown-item" style="cursor: pointer; pointer-events: none;" :disabled="item.statusDisabled" @click="clockIn(item)">
													<i class="ri-time-line"></i> {{ $t('label.clock in') }}
												</button>
											</div>
											<span class="dropdown-item-taskname text-right col-8">
												({{ $t('label.clocked in to') }} <a v-if="item.job.job_number" :href="'/job/' + item.job.job_number.job_number + '/edit'">{{ item.task_name }})</a>
											</span>
										</div>
									</div>
									<button v-if="item.status && (user.role_id != '4' || user.id == d.user.id)" type="button" class="dropdown-item" @click="stopNotify2(item)">
										<i class="ri-stop-circle-line"></i> {{ $t('label.clock out') }}
									</button>
									<a v-if="user.role_id != '4' || user.id == d.user.id" class="dropdown-item" href="#" data-toggle="modal" data-target="#viewReviseTimeModal" @click="viewReviseTime(item, data.selected_date)">
										<i class="ri-edit-line"></i> {{ $t('label.revise time') }}
									</a>
									<div v-if="user.role_id != '4' || user.id == d.user.id" class="dropdown-divider"></div>
									<button type="button" class="dropdown-item">
										<i class="ri-phone-line"></i> {{ $t('label.mobile') }} ({{ item.user.phone }})
									</button>
									<a class="dropdown-item" :href="'mailto:' + item.user.email"><i class="ri-mail-line"></i> {{ $t('label.email') }} ({{ item.user.email }})</a>
									<span class="dropdown-item" style="cursor: pointer" @click="getUserInfo(item.user.id, 'Profile')">
										<i class="ri-account-circle-line"></i> {{ $t('label.view profile') }}
									</span>
									<div v-if="user.role_id == '2' || user.role_id == '3'" class="dropdown-divider"></div>
									<a v-if="user.role_id == '2' || user.role_id == '3'" class="dropdown-item" href="#"><i class="ri-delete-bin-line btn-outline-danger"></i> {{ $t('label.remove from task') }}</a>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<table-pagination v-model="paginatedData" :btnLimit="3" :btnSize="'sm'" :all-rows="data" :perPage="perPage">
			</table-pagination>
		</div>
	</div>
</template>
<script>
import TablePagination from '@/components/TablePagination';

import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex';
;

export default {
	props: ['tableid', 'name', 'type', 'data', 'columns'],
	components: { TablePagination },
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
			taskdata: [],
			jobid: null,
			paginatedData: [],
			perPage: 4
		}
	},
	computed: {
		...mapGetters(['company', 'coordinates']),
	},
	mounted() {
		// console.log("in widgets-table", this.name, this.type, JSON.parse(JSON.stringify(this.data)));
	},
	methods: {
		getJobId(data) {
			if (this.type == 'task') {
				this.taskdata = data;
				this.jobid = data.job.id;
				// var test = {
				// 	taskdata: this.taskdata,
				// 	jobid: data.job.id,
				// 	type: this.type,
				// 	taskId: data.id,
				// 	current: data.task_status_id,
				// 	selectedDate: moment(data.start_date),
				// 	openEdit: true,
				// }
				bus.$emit('openTaskOffCanvas', data.id)

			} else {
				this.taskdata = data;
				this.jobid = data.id;
				var test = {
					taskdata: this.taskdata,
					jobid: data.id,
					type: this.type
				}

				bus.$emit('getjobid', test)
			}
		},
		markAsComplete(data) {
			var request_fields = {
				'task_id': data.id,
			};
			axios.post('/mark-as-complete', request_fields)
				.then(response => {
					bus.$emit('getallwidgets');
					bus.$emit('banner-success', {
						message: `Task completed successfully!`,
					});
				})
				.catch(error => {
					console.log('list carriers error:', error)
				});
		},
		toggleCloseJobModal(data, show) {
			bus.$emit('toggleCloseJobModal', data.id, show);
		},
		reopenJob(data) {
			// Save job status then update widgets
			axios.post("/reopen-job", {
				job_id: data.id
			}).then(response => {
				bus.$emit('getallwidgets');
				bus.$emit('getallwidgets2');
				bus.$emit('getallwidgets4');
				bus.$emit('banner-success', {
					message: this.$t('label.job reopened successfully') + '!'
				});
			}).catch(err => console.log(err));
		},
		clockIn(data) {
			this.dataSelected = data;

			var request_fields = {
				'task_id': data.task_id,
				'user_id': data.user.id,
				'coordinates': this.coordinates,
			};
			axios.post('/time-in', request_fields)
				.then(response => {
					this.timepunch_id = response.data.time_id;
				})
				.catch(error => {
					console.log('list carriers error:', error)
				});
		},
		stopNotify2(data) {
			let params = {
				timepunch_id: data.timestatus.id,
				username: data.user.name
			}

			bus.$emit('stopnotify', params)
		},
		viewReviseTime(data, selected_date) {
			this.dataSelected = data;
			this.dataSelectedDate = selected_date;
			this.username = data.user.name;
			this.userpicture = data.user.picture;
			this.task_manager = data.task_manager;

			bus.$emit('usertimerecords', data)
		},
	}
}
</script>
