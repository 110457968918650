<template>
    <div class="col-xl-12 order-xl-1 mobile-p-0" id="job-files" :class="location == 'edit' ? '' : 'p-0'">
        <div v-if="!hideFilesComponent" :class="location == 'edit' ? 'card' : ''">
            <div v-if="showFilesByTask">
                <div class="row align-items-start justify-content-between">
                    <div class="pointer" data-toggle="tab" href="#tabs-btn-list">
                        <h3 class="mb-0 d-flex align-items-center"> {{ $t('label.files') }}</h3>
                    </div>
                    <div class="row">
                        <div class="col tabs tabs-file">
                            <div class="nav btn-group btn-group-sm btn-group-light" role="tablist" aria-label="Basic example" id="tabs-btn">
                                <a class="btn active" :id="'tabs-btn-list-tab' + taskId" data-toggle="tab" :href="'#tabs-btn-list-' + taskId" role="tab" aria-controls="tabs-btn-basic" aria-selected="true">
                                    <i class="ri-layout-row-line ri-font-size-2"></i>{{ $t('label.list') }}
                                </a>
                                <a class="btn" :id="'tabs-btn-gallery-tab-' + taskId" data-toggle="tab" :href="'#tabs-btn-gallery-' + taskId" role="tab" aria-controls="tabs-btn-advance" aria-selected="false">
                                    <i class="ri-layout-grid-line"></i>{{ $t('label.gallery') }}
                                </a>
                            </div>
                        </div>
                        <div class="col-auto text-right">
                            <button type="button" class="btn btn-sm btn-default" data-toggle="modal" data-target="#addJobFilesModal" @click="viewUploadFileModal()">
                                {{ $t('label.add file') }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" :id="'tabs-btn-list-' + taskId" role="tabpanel" :aria-labelledby="'tabs-btn-list-tab-' + taskId">
                        <!-- TABLE -->
                        <div v-if="alljobfiles.length == 0">
                            <div class="card-body m-0 py-0">
                                <div class="bg-white text-center row align-items-center m-0">
                                    <i class="ri-folders-line ni-8x w-100 color-gray-400"></i>
                                    <p class="w-100 font-weight-normal">{{ $t('label.No Files have been added to this Job') }}.</p>
                                </div>
                            </div>
                        </div>
                        <div v-if="alljobfiles.length > 0" class="table-responsive pb-4">
                            <table class="table table-flush table-sm" id="datatable-jobfilerecords">
                                <thead class="thead-light">
                                    <tr>
                                        <th scope="col">{{ $t('label.file name') }}</th>
                                        <th scope="col" class="hidden-sm-down">{{ $t('label.description') }}</th>
                                        <th scope="col">{{ $t('label.related task') }}</th>
                                        <th scope="col">{{ $t('label.uploaded by') }}</th>
                                        <th scope="col" class="hidden-sm-down">{{ $t('label.uploaded date') }}</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(data, index) in alljobfiles" :key="index" v-if="data.task_id == taskId" :id="'file-hover-' + data.id" @mouseover="showFileBtns(data.id)" @mouseout="hideFileBtns(data.id)">
                                        <td class="w-25">
                                            <div :class="'file-table-buttons file-buttons-' + data.id" style="display: none;">
                                                <div class="row m-1 justify-content-center">
                                                    <button type="button" class="shadow rounded btn text-primary bg-white" data-toggle="modal" data-target="#viewFileModal" @click="viewFileModal(data.id)">
                                                        <i class="ri-eye-line"></i>
                                                        {{ $t('label.view') }}
                                                    </button>
                                                    <a class="shadow rounded btn text-primary bg-white" @click="$downloadFile(data.download)">
                                                        <i class="ri-download-line"></i>
                                                        {{ $t('label.download') }}
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="row align-items-center m-0">
                                                <div class="image-container image-container-thumbnail float-right mr-2" :style="'background-image: url(/' + data.filename_path + ')'">
                                                </div>
                                                <a href="#" data-toggle="modal" data-target="#viewFileModal" @click="viewFileModal(data.id)">
                                                    <span v-if="data.display_name">{{ data.display_name }}</span>
                                                    <span v-else>{{ data.filename }}</span>
                                                </a>
                                            </div>
                                        </td>
                                        <td class="w-25 hidden-sm-down">{{ data.description }}</td>
                                        <td class="w-20">{{ data.task ? data.task.name : '' }}</td>
                                        <td class="w-20">
                                            <div class="row align-items-center m-0">
                                                <img class="avatar-img rounded-circle float-right mr-2" :src="data.created_by.picture" alt="">
                                                {{ data.created_by.name }}
                                            </div>
                                        </td>
                                        <td class="w-5 hidden-sm-down">{{ data.created_at | formatDate }}</td>
                                        <td class="text-right w-5">
                                            <div class="dropdown">
                                                <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fas fa-ellipsis-v"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                    <button class="dropdown-item pointer" @click="editFileModal(data, index)">{{ $t('label.edit') }} 1</button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="tab-pane fade" :id="'tabs-btn-gallery-' + taskId" role="tabpanel" :aria-labelledby="'tabs-btn-gallery-tab-' + taskId">
                        <!-- GALLERY -->
                        <div v-if="alljobfiles.length == 0">
                            <div class="card-body m-0 py-0">
                                <div class="bg-white text-center row align-items-center m-0">
                                    <i class="ri-folders-line ni-8x w-100 color-gray-400"></i>
                                    <p class="w-100 font-weight-normal">{{ $t('label.No Files have been added to this Job') }}.</p>
                                </div>
                            </div>
                        </div>
                        <div v-else class="file-gallery p-4">
                            <div class="form-group d-flex align-items-center justify-content-end row">
                                <label class="form-control-label text-muted text-right m-0 col-lg-2 col-12">{{ $t('label.sorted by') }}</label>
                                <select class="form-control p-1 h-100 col-12 col-lg-3" @change="sortByDate($event)">
                                    <option value="asc">{{ $t('label.date uploaded (ascending)') }}</option>
                                    <option value="desc">{{ $t('label.date uploaded (descending)') }}</option>
                                </select>
                            </div>
                            <div class="col-12 p-0">
                                <div class="row">
                                    <div class="card-container pointer pb-4 col-xl-2 col-lg-4 col-md-4 col-xs-12" v-for="(file, index) in alljobfiles" :key="index" v-if="file.task_id == taskId">
                                        <div id="my-table" class="card p-0 m-0" @mouseover="showFileBtns(index)" @mouseout="hideFileBtns(index)">
                                            <div class="file-image" :style="'background-image: url(/' + file.filename_path + ')'">
                                                <div :class="'text-center file-buttons file-buttons-' + index">
                                                    <div class="row">
                                                        <button type="button" class="btn btn-outline-primary w-100" data-toggle="modal" data-target="#viewFileModal" @click="viewFileModal(file.id)">
                                                            <i class="ri-eye-line"></i>
                                                            {{ $t('label.view') }}
                                                        </button>
                                                        <a class="btn btn-outline-primary w-100 " @click="$downloadFile(file.download)">
                                                            <i class="ri-download-line"></i>
                                                            {{ $t('label.download') }}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body pt-3 card-filename">
                                                <a href="#" data-toggle="modal" data-target="#viewFileModal" @click="viewFileModal(file.id)" class="form-control-label">
                                                    <span v-if="file.display_name">{{ file.display_name }}</span>
                                                    <span v-else>{{ file.filename }}</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="">
                <div class="card-header border-bottom" :class="location == 'edit' ? '' : 'py-0'">
                    <div class="row align-items-start justify-content-between">
                        <div class="pointer" data-toggle="tab" href="#tabs-btn-list">
                            <h3 class="mb-0 d-flex align-items-center"><i class="ri-folders-line heading-title"></i> {{ $t('label.files') }}</h3>
                        </div>
                        <div class="row">
                            <div class="col tabs tabs-file">
                                <div class="nav btn-group btn-group-sm btn-group-light rounded" role="tablist" aria-label="Basic example" id="tabs-btn">
                                    <a class="btn active" id="tabs-btn-list-tab" data-toggle="tab" href="#tabs-btn-list" role="tab" aria-controls="tabs-btn-basic" aria-selected="true">
                                        <i class="ri-layout-row-line ri-font-size-2"></i>{{ $t('label.list') }}
                                    </a>
                                    <a class="btn" id="tabs-btn-gallery-tab" data-toggle="tab" href="#tabs-btn-gallery" role="tab" aria-controls="tabs-btn-advance" aria-selected="false">
                                        <i class="ri-layout-grid-line"></i>{{ $t('label.gallery') }}
                                    </a>
                                </div>
                            </div>
                            <div class="col-auto text-right">
                                <button type="button" class="btn btn-sm btn-default" data-toggle="modal" data-target="#addJobFilesModal" @click="viewUploadFileModal()">
                                    {{ $t('label.add file') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="m-0">
                    <div class="card-body p-0">
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="tabs-btn-list" role="tabpanel" aria-labelledby="tabs-btn-list-tab">
                                <!-- TABLE -->
                                <div v-if="alljobfiles.length == 0">
                                    <div class="card-body m-0 py-0">
                                        <div class="bg-white text-center row align-items-center m-0">
                                            <i class="ri-folders-line ni-8x w-100 color-gray-400"></i>
                                            <p class="w-100 font-weight-normal">{{ $t('label.No Files have been added to this Job') }}.</p>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="alljobfiles.length > 0" class="pb-4">
                                    <table class="table table-flush table-sm border-bottom" id="datatable-jobfilerecords2">
                                        <thead class="thead-light">
                                            <tr>
                                                <th scope="col">{{ $t('label.file name') }}</th>
                                                <th scope="col" class="hidden-sm-down">{{ $t('label.description') }}</th>
                                                <th scope="col">{{ $t('label.related task') }}</th>
                                                <th scope="col">{{ $t('label.uploaded by') }}</th>
                                                <th scope="col" class="hidden-sm-down">{{ $t('label.uploaded date') }}</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data, index) in paginatedFiles" :key="index" :id="'file-hover-' + data.id" @mouseover="showFileBtns(data.id)" @mouseout="hideFileBtns(data.id)">
                                                <td class="w-25 sp-va-mid">
                                                    <div :class="'file-table-buttons file-buttons-' + data.id" style="display: none;">
                                                        <div class="row m-1 justify-content-center">
                                                            <button type="button" class="shadow rounded btn text-primary bg-white" data-toggle="modal" data-target="#viewFileModal" @click="viewFileModal(data.id)">
                                                                <i class="ri-eye-line"></i>
                                                                {{ $t('label.view') }}
                                                            </button>
                                                            <a class="shadow rounded btn text-primary bg-white" @click="$downloadFile(data.download)">
                                                                <i class="ri-download-line"></i>
                                                                {{ $t('label.download') }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="row align-items-center m-0">
                                                        <img class="image-container image-container-thumbnail float-right mr-2" :src="data.filename_path" />
                                                        <a href="#" data-toggle="modal" data-target="#viewFileModal" @click="viewFileModal(data.id)">
                                                            <span v-if="data.display_name">{{ data.display_name }}</span>
                                                            <span v-else>{{ data.filename }}</span>
                                                        </a>
                                                    </div>
                                                </td>
                                                <td class="w-25 sp-va-mid hidden-sm-down">{{ data.description }}</td>
                                                <td class="w-20 sp-va-mid">{{ data.task ? data.task.name : '' }}</td>
                                                <td class="w-20 sp-va-mid">
                                                    <div class="row align-items-center m-0">
                                                        <img class="avatar-img rounded-circle float-right mr-2" :src="data.created_by.picture" alt="">
                                                        {{ data.created_by.name }}
                                                    </div>
                                                </td>
                                                <td class="w-5 sp-va-mid hidden-sm-down">{{ data.created_at | formatDate }}</td>
                                                <td class="text-right sp-va-mid w-5">
                                                    <div class="dropdown">
                                                        <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i class="fas fa-ellipsis-v"></i>
                                                        </a>
                                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                            <a class="dropdown-item d-flex align-items-center" href="#" data-dismiss="modal" data-toggle="modal" data-target="#viewFileModal" @click="viewFileModal(data.id)">
                                                                <i class="ri-eye-line pr-2"></i> {{ $t('label.view file') }}
                                                            </a>
                                                            <a class="dropdown-item text-dark d-flex align-items-center pointer" @click="editFileModal(data, index)">
                                                                <i class="ri-pencil-line pr-2"></i> {{ $t('label.edit file') }}
                                                            </a>
                                                            <a class="dropdown-item d-flex align-items-center text-dark pointer" @click="$downloadFile(data.download)">
                                                                <i class="ri-download-line pr-2"></i> {{ $t('label.download file') }}
                                                            </a>
                                                            <a v-if="user.role_id == 1 | user.role_id == 2" class="dropdown-item d-flex align-items-center text-danger" href="#" data-dismiss="modal" data-toggle="modal" data-target="#deleteFileModal" @click="viewFileModal(data.id)">
                                                                <i class="ri-delete-bin-line pr-2"></i> {{ $t('label.delete file') }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="tabs-btn-gallery" role="tabpanel" aria-labelledby="tabs-btn-gallery-tab">
                                <!-- GALLERY -->
                                <div v-if="alljobfiles.length == 0">
                                    <div class="card-body m-0 py-0">
                                        <div class="bg-white text-center row align-items-center m-0">
                                            <i class="ri-folders-line ni-8x w-100 color-gray-400"></i>
                                            <p class="w-100 font-weight-normal">{{ $t('label.No Files have been added to this Job') }}.</p>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="file-gallery p-4">
                                    <div class="form-group d-flex align-items-center justify-content-end row">
                                        <label class="form-control-label text-muted text-right m-0 col-lg-2 col-12">{{ $t('label.sorted by') }}</label>
                                        <select class="form-control p-1 h-100 col-lg-3 col-12" @change="sortByDate($event)">
                                            <option value="asc">{{ $t('label.date uploaded (ascending)') }}</option>
                                            <option value="desc">{{ $t('label.date uploaded (descending)') }}</option>
                                        </select>
                                    </div>
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="card-container pointer pb-4 col-xl-2 col-lg-4 col-md-4 col-xs-12" v-for="(file, index) in alljobfiles" :key="index">
                                                <div id="my-table" class="card p-0 m-0" @mouseover="showFileBtns(index)" @mouseout="hideFileBtns(index)">
                                                    <img class="file-image" :src="file.filename_path" />
                                                    <div :class="'text-center file-buttons file-buttons-' + index">
                                                        <div class="row file-container">
                                                            <button type="button" class="btn btn-outline-primary w-100" data-toggle="modal" data-target="#viewFileModal" @click="viewFileModal(file.id)">
                                                                <i class="ri-eye-line mrn-5"></i>
                                                                {{ $t('label.view') }}
                                                            </button>
                                                            <a class="btn btn-outline-primary w-100" @click="$downloadFile(file.download)">
                                                                <i class="ri-download-line mrn-5"></i>
                                                                {{ $t('label.download') }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="card-body pt-3 card-filename">
                                                        <a href="#" data-toggle="modal" data-target="#viewFileModal" @click="viewFileModal(file.id)" class="form-control-label">
                                                            <span v-if="file.display_name">{{ file.display_name }}</span>
                                                            <span v-else>{{ file.filename }}</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table-pagination v-model="paginatedFiles" :all-rows="filteredFiles" :perPage="perPage">
                    </table-pagination>
                </div>
            </div>
        </div>
        <!-- Upload Modal -->
        <div class="modal" id="addJobFilesModal" tabindex="-1" role="dialog" aria-labelledby="addJobFilesModalTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content dropzone-file">
                    <div class="modal-header border-bottom">
                        <h5 class="modal-title" id="addJobFilesModalTitle">{{ $t('label.upload files') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="cancelFileUpload">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                    <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" :destroyDropzone="false" v-on:vdropzone-upload-progress="progress" v-on:vdropzone-total-upload-progress="totalProgress" v-on:vdropzone-success="getUploadedFiles" v-on:vdropzone-error="getError" v-on:vdropzone-removed-file="removedFile">
                    </vue-dropzone>
                    <div class="modal-footer border-top">
                        <button type="button" class="btn btn-link  ml-auto close2editfile" data-dismiss="modal" @click="cancelFileUpload">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Upload Modal Details -->
        <div class="modal" id="addJobFileDetailsModal" tabindex="-1" role="dialog" aria-labelledby="addJobFileDetailsModalTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <!-- Upload Header -->
                    <div class="modal-header border-bottom">
                        <h5 class="modal-title" id="addJobFileDetailsModalTitle">{{ $t('label.upload files') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="cancelFileUpload">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                    <!-- Upload Form -->
                    <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="saveFile">
                        <div class="modal-body py-0">
                            <div class="row">
                                <div class="form-check pr-5">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="isSameRelatedTask" @click="sameRelatedTask">
                                    <label class="form-check-label" for="exampleCheck1">{{ $t('label.same related task') }}</label>
                                </div>
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck2" v-model="isSameFileDescription" @click="sameFileDescription">
                                    <label class="form-check-label" for="exampleCheck2">{{ $t('label.same file description') }}</label>
                                </div>
                            </div>
                            <div class="row" v-for="(file, index) in uploadedFilesUpload" :key="index">
                                <div v-if="file.fileType == 'pdf' || file.fileType == 'xlsx' || file.fileType == 'pptx' || file.fileType == 'docx' || file.fileType == 'doc' || file.fileType == 'txt' || file.fileType == 'rtf' || file.fileType == 'heic' || file.fileType == 'tiff'" class="image-container image-container-small" :style="'background-image: url(/' + file.filePath + ')'"></div>
                                <div v-else class="image-container image-container-small" :style="'background-image: url(' + file.dataURL + ')'"></div>
                                <div class="col-10 pl-4">
                                    <div class="row">
                                        <div class="col-sm">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.file name') }}</label>
                                                <input v-model="file.upload.filename" type="text" name="name" class="form-control" required>
                                            </div>
                                        </div>
                                        <div class="col-sm">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.related task') }}</label>
                                                <select v-if="!isSameRelatedTask" v-model="file.upload.relatedTask" class="form-control">
                                                    <option value="" disabled>- {{ $t('label.select a task') }} -</option>
                                                    <option v-for="(task, index) in tasks" :value="task.id" :key="index">{{ task.name }}</option>
                                                </select>
                                                <select v-if="isSameRelatedTask" v-model="form.add.relatedTask" class="form-control" required>
                                                    <option value="" selected>- {{ $t('label.select a task') }} -</option>
                                                    <option v-for="(task, index) in tasks" :value="task.id" :key="index">{{ task.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div class="form-group">
                                            <label class="form-control-label text-muted" for="input-name">{{ $t('label.description') }}</label>
                                            <textarea v-if="!isSameFileDescription" v-model="file.upload.description" name="description" class="form-control" placeholder="Description" required autofocus></textarea>
                                            <textarea v-if="isSameFileDescription" v-model="form.add.description" name="description" class="form-control" placeholder="Description" required autofocus></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer border-top d-flex justify-content-between">
                            <button type="submit" class="btn btn-primary">{{ $t('label.save files') }}</button>
                            <button type="button" class="btn btn-link  ml-auto close2editfile" data-dismiss="modal" @click="cancelFileUpload">{{ $t('label.cancel') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- View Modal -->
        <div class="modal" id="viewFileModal" tabindex="-1" role="dialog" aria-labelledby="viewFileModalTitle" aria-hidden="true">
            <div class="modal-dialog" role="document" v-for="(file, index) in alljobfiles" :key="index">
                <div class="modal-content" v-if="file.id == jobFileId">
                    <div class="modal-header border-bottom">
                        <h5 class="modal-title" id="viewFileModalTitle">
                            <span v-if="file.display_name">{{ file.display_name }}</span>
                            <span v-else>{{ file.filename }}</span>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                    <div class="modal-body p-0">
                        <div class="row">
                            <div class="col-lg-8 col-12 p-4 text-center" style="background: #F6F9FC;">
                                <div v-if="file.fileType == 'pdf' || file.fileType == 'xlsx' || file.fileType == 'pptx' || file.fileType == 'docx' || file.fileType == 'doc' || file.fileType == 'txt' || file.fileType == 'rtf' || file.fileType == 'heic' || file.fileType == 'tiff'">
                                    <div class="w-100">
                                        <img class="w-25" :src="require('@/assets/images/icons/other-file.png')" alt="Card image cap">
                                    </div>
                                    <div class="pt-3 pb-4 w-100">{{ $t('label.Preview unavailable for this file type') }}.</div>
                                    <a class="btn btn-primary text-dark pointer" @click="$downloadFile(file.download)">
                                        <i class="ri-download-line pr-2"></i>
                                        {{ $t('label.download') }}
                                    </a>
                                </div>
                                <img v-else class="mw-100 mh-70vh" :src="file.download" alt="Card image cap">
                            </div>
                            <div class="col-lg-4 col-12 p-4">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-flex justify-content-between align-items-start">
                                            <h1 class="p-0 col-lg-10" style="word-break: break-all;">
                                                <span v-if="file.display_name">{{ file.display_name }}</span>
                                                <span v-else>{{ file.filename }}</span>
                                            </h1>
                                            <button type="button" class="btn btn-default btn-sm m-0 p-0 dropdown-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fas fa-ellipsis-h"></i>
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                <a class="dropdown-item d-flex align-items-center" href="#" @click="editFileModal(file, index)">
                                                    <i class="ri-pencil-line pr-2"></i> {{ $t('label.edit file') }}
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center text-dark pointer" @click="$downloadFile(file.download)">
                                                    <i class="ri-download-line pr-2"></i> {{ $t('label.download file') }}
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center text-danger" href="#" data-dismiss="modal" data-toggle="modal" data-target="#deleteFileModal" @click="viewFileModal(file.id)">
                                                    <i class="ri-delete-bin-line pr-2"></i> {{ $t('label.delete file') }}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.related task') }}</label>
                                            <div class="input-value">
                                                {{ file.task == null ? '' : file.task.name }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.description') }}</label>
                                            <div class="input-value">{{ file.description }}</div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.uploaded by') }}</label>
                                            <div class="input-value">
                                                <img class="rounded-circle" :src="file.created_by.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                                <span class="px-2">{{ file.created_by.name }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.uploaded date') }}</label>
                                            <div class="input-value">
                                                {{ file.created_at | formatDate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer border-top d-flex justify-content-between">
                        <a class="btn btn-primary text-white" @click="$downloadFile(file.download)">
                            <i class="ri-download-line pr-2"></i>
                            {{ $t('label.download') }}
                        </a>
                        <button type="button" class="btn btn-link  ml-auto close2editfile" data-dismiss="modal">{{ $t('label.close') }}</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Edit -->
        <modal class="py-4" name="jobFileEditModal" height="auto" width="98%" :max-width="1024" :reset="true" :scrollable="true" :adaptive="true">
            <job-file-edit-modal :jobFile='editFile' :tasks='tasks' :index='index' @cancelFnc="closeEditFileModal" @returnFunction="returnUpdatedFile" />
        </modal>

        <!-- Delete Modal -->
        <div class="modal fade" id="deleteFileModal" tabindex="-1" role="dialog" aria-labelledby="deleteFileModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
                <div class="modal-content bg-gradient-danger">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="deleteFile">
                        <div class="modal-body">
                            <div class="py-3 text-center">
                                <i class="ri-error-warning-line ni-3x"></i>
                                <h4 class="heading mt-4 text-">{{ $t('label.ARE YOU SURE YOU WANT TO DELETE THIS FILE') }}?</h4>
                                <p>{{ $t('label.This action cannot be undone') }}!</p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-white" id="deleteBtn">{{ $t('label.Yes, Delete') }}</button>
                            <button type="button" class="btn btn-link text-white ml-auto" data-dismiss="modal">{{ $t('label.cancel') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import JobFileEditModal from '@/components/Jobs/JobFileEditModal';
import TablePagination from '@/components/TablePagination';
import bus from '@/utils/event-bus';

import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    props: ['jobid', 'showFilesByTask', 'taskId', 'hideFilesComponent', 'location', 'is_template'],
    emits: ['onFilesChange'],
    components: {
        vueDropzone: vue2Dropzone,
        TablePagination,
        JobFileEditModal,
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            loader: null,
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                maxFilesize: 10,
                thumbnailWidth: null,
                addRemoveLinks: true,
                dictDefaultMessage: `
                        <div>
                            <span>Drag and drop files here</span><br>
                            <span>OR</span><br>
                            <button type="button" class="btn btn-lg btn-primary">
							    Browse Files
						    </button>
                        </div>
                    `,
            },
            form: {
                add: {
                    name: null,
                    download: null,
                    description: null,
                    relatedTask: null,
                },
                edit: {
                    id: null,
                    name: null,
                    download: null,
                    download2: null,
                    description: null,
                    relatedTask: null,
                }
            },
            inputfile: null,
            showDropdown: false,
            columns: ['task_image', 'download', 'description', 'related_task', 'user_image', 'created_by.name', 'created_at', 'actions'],
            options: {
                rowClassCallback: function (row) {
                    return 'file-row-' + row.id;
                },
                perPageValues: [],
                headings: {
                    task_image: '',
                    user_image: '',
                    download: this.$t('label.file name'),
                    description: this.$t('label.description'),
                    related_task: this.$t('label.related task'),
                    'created_by.name': this.$t('label.uploaded by'),
                    created_at: this.$t('label.uploaded date'),
                    actions: ''
                },
                editableColumns: ['name', 'related_task', 'description', 'download', 'created_by.name', 'created_at', 'task_image', 'user_image'],
                sortable: ['name', 'related_task', 'description', 'download', 'created_by.name', 'created_at'],
                filterable: false,
                columnsClasses: {
                    task_image: 'pl-5 pr-1',
                    user_image: 'pl-5 pr-1',
                    download: 'text-left pl-1',
                    description: 'text-left',
                    'created_by.name': 'text-left pl-1',
                    created_at: 'text-left position-relative',
                    actions: 'text-center actions-column'
                },
            },
            jobFile: {
                created_by: {
                    name: null,
                    picture: null
                }
            },
            uploadedFiles: [],
            uploadedFilesUpload: [],
            isSameRelatedTask: false,
            isSameFileDescription: false,
            tasks: [],
            jobFileId: 0,
            isUploadCancel: false,
            currentTaskId: 0,
            errorDropzone: null,
            dataTable: null,
            paginatedFiles: [],
            alljobfiles: [],
            perPage: 10,
            editFile: {},
            index: null,
        }
    },
    computed: {
        filteredFiles() {
            return this.alljobfiles;
        }
    },
    mounted() {
        this.getJobFiles();
        this.getTasks();
    },
    created: function () {
        bus.$on('getTaskId', (id) => {
            this.currentTaskId = id;
            this.form.add.relatedTask = id;
            this.isSameRelatedTask = true;
            this.viewUploadFileModal();
        });
        this.$parent.$on('click', this.viewUploadFileModal);
    },
    methods: {
        template: function () {
            return `<div class="dz-preview dz-file-preview">
                        <div class="dz-image">
                            <div data-dz-thumbnail-bg></div>
                        </div>
                        <i class="fas fa-upload"></i>
                        <div class="dz-details">
                            <div class="dz-filename"><span data-dz-name></span></div>
                        </div>
                        <div class="dz-progress-percentage"></div>
                        <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                        <div class="dz-error-message"><span data-dz-errormessage></span></div>
                        <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                        <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                    </div>
                `;
        },
        // downloadFile(filePath) {
        //     axios.post(`/download-file-by-url`, {filePath: filePath}, {responseType: 'blob'}).then(rsp => {
        //         const blobUrl = URL.createObjectURL(rsp.data);
        //         const aElm = document.createElement('a');
        //         let splitPath = filePath.split('/'),
        //             fileName = splitPath[splitPath.length - 1];

        //         aElm.href = blobUrl;
        //         aElm.download = fileName;
        //         aElm.click();
        //         aElm.remove();
        //     }).catch((err) => console.log("err", err));
        // },
        viewUploadFileModal() {
            this.cancelFileUpload();
            this.getTasks();
            this.isUploadCancel = false;
        },
        cancelFileUpload() {
            this.isUploadCancel = true;
            if (this.uploadedFilesUpload.length) {
                this.$refs.myVueDropzone.removeAllFiles(true);
            }
            this.uploadedFiles = [];
            this.uploadedFilesUpload = [];
        },
        getTasks() {
            axios.get('/tasks/' + this.jobid)
                .then(response => {
                    this.tasks = response.data.result;
                    // this.tasks.push({ id: 0, name: '', sequence: 0 });
                    this.tasks = _.orderBy(this.tasks, ['sequence'], ['asc']);
                })
                .catch(err => console.error("API ERROR", err));
        },
        progress: function (file, progress, bytesSent) {
            $(file.previewElement).find(".dz-progress-percentage").text(parseInt(progress) + " %");
            if (progress == 100) {
                $(file.previewElement).find(".dz-progress .dz-upload").css("background", "#2bce89");
                setTimeout(function () {
                    $(file.previewElement).find(".dz-success-mark").css("opacity", "1");
                }, 300);
            }
        },
        totalProgress: function (totaluploadprogress, totalBytes, totalBytesSent) {
            if (totaluploadprogress === 100 && !this.isUploadCancel && !this.errorDropzone) {
                setTimeout(function () {
                    $('#addJobFilesModal').modal('hide');
                    $('#addJobFileDetailsModal').modal('show');
                }, 1000);
            }
        },
        viewFileModal(id) {
            this.jobFileId = id;
        },
        showFileBtns(id) {
            $(".file-buttons-" + id).show();
            $(".file-buttons-" + id).addClass('file-hover');
            $('#file-hover-' + id).css('background-color', '#e9ecef');
        },
        hideFileBtns(id) {
            $(".file-buttons-" + id).hide();
            $(".file-buttons-" + id).removeClass('file-hover');
            $('#file-hover-' + id).css('background-color', 'unset');
        },
        deleteFile() {
            axios.post('/delete-file/' + this.jobFileId)
                .then(response => {
                    this.getJobFiles();
                    this.$emit('onFilesChange');
                    $('#deleteFileModal').modal('hide');
                })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        sortByDate(event) {
            let sort = event.target.value;
            this.alljobfiles.sort((a, b) => a.weight < b.weight ? 1 : -1);
        },
        getJobFiles() {
            axios.get('/jobfiles/' + this.jobid).then(response => {
                this.alljobfiles = response.data.result;
                if (this.loader) {
                    this.loader.hide()
                }
            });
        },
        saveJobFile(files, index) {
            this.loader = this.$loading.show()
            let file = files.upload;
            const data = new FormData();
            data.append('download', this.uploadedFiles[index]);
            let description = this.isSameFileDescription ? this.form.add.description : file.description;
            let relatedTask = this.isSameRelatedTask ? this.form.add.relatedTask : file.relatedTask;

            const json = JSON.stringify({
                name: file.filename,
                description: description,
                // task_id : relatedTask == 0 ? null : relatedTask,
                task_id: relatedTask,
                job_id: this.jobid,
            });
            data.append('data', json);

            axios.post('/jobfiles', data).then(() => {
                this.form.isSameFileDescription = false;
                this.form.isSameRelatedTask = false;
                this.form.add.description = null;
                this.form.add.relatedTask = null;
                this.getJobFiles();
                this.$emit('onFilesChange');
            }).catch(err => {
                this.loader.hide()
                //console.log('list carriers error:', error)
            })
        },
        getUploadedFiles: function (file, response) {
            const env = process.env;
            console.log(env)
            const API_URL = env.VUE_APP_API_URL;
            let filename = file.upload.filename;
            let fileType = filename.split('.').pop();
            file.fileType = fileType;

            if (fileType === 'pdf') {
                file.filePath = API_URL + '/images/icons/doc-pdf.png';
                file.upload.filePath = API_URL + '/images/icons/doc-pdf.png';
            } else if (fileType === 'xlsx') {
                file.filePath = API_URL + '/images/icons/doc-excel.png';
                file.upload.filePath = API_URL + '/images/icons/doc-excel.png';
            } else if (fileType === 'pptx') {
                file.filePath = API_URL + '/images/icons/doc-ppt.png';
                file.upload.filePath = API_URL + '/images/icons/doc-ppt.png';
            } else if (fileType === 'docx' || fileType === 'doc') {
                file.filePath = API_URL + '/images/icons/doc-word.png';
                file.upload.filePath = API_URL + '/images/icons/doc-word.png';
            } else if (fileType === 'txt' || fileType === 'rtf' || fileType === 'heic' || fileType === 'tiff') {
                file.filePath = API_URL + '/images/icons/doc-other.png';
                file.upload.filePath = API_URL + '/images/icons/doc-other.png';
            } else {
                file.filePath = API_URL + '/uploads/' + encodeURI(filename);
                file.upload.filePath = API_URL + '/uploads/' + encodeURI(filename);
            }

            if (this.showFilesByTask) {
                file.upload.relatedTask = this.currentTaskId;
            } else {
                file.upload.relatedTask = null;
            }

            this.uploadedFiles.push(file);
            this.uploadedFilesUpload.push(file);

        },
        saveFile() {
            $('#addJobFileDetailsModal').modal('hide');

            this.uploadedFilesUpload.forEach((file, index) => {
                file.upload.filename = file.upload.filename.split('.')[0] + '.' + file.fileType;
                this.saveJobFile(file, index);
            });
        },
        updateFileDetails() {
            this.jobFile.task_id = this.jobFile.task_id == null ? null : this.jobFile.task_id;
            // this.jobFile.task_id = this.jobFile.task_id == 0 ? null : this.jobFile.task_id;
            this.jobFile.name = this.jobFile.name.split('.')[0] + '.' + this.jobFile.fileType;

            axios.post('/jobfiles/update', this.jobFile)
                .then(response => {
                    this.getJobFiles();
                    this.$emit('onFilesChange');
                    this.viewFileModal(this.jobFile.id);

                    $('#editFileModal').modal('hide');
                    $('#viewFileModal').modal('show');
                })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        sameFileDescription() {
            if (!this.isSameFileDescription) {
                this.form.add.description = this.uploadedFilesUpload.length ? this.uploadedFilesUpload[0].upload.description : null;
            } else {
                this.uploadedFilesUpload[0].upload.description = this.form.add.description;
            }
        },
        sameRelatedTask() {
            if (!this.isSameRelatedTask) {
                this.form.add.relatedTask = this.uploadedFilesUpload.length ? this.uploadedFilesUpload[0].upload.relatedTask : null;
            } else {
                this.uploadedFilesUpload[0].upload.relatedTask = this.form.add.relatedTask;
            }
        },
        onFileChange(e) {
            this.form.add.download = e.target.files[0];
        },
        onFileChange2(e) {
            this.form.edit.download2 = e.target.files[0];
        },

        editFileModal(data, index) {
            this.index = index;
            this.editFile = data;
            this.$modal.show('jobFileEditModal');
        },
        closeEditFileModal() {
            this.$modal.hide('jobFileEditModal');
        },
        returnUpdatedFile() {
            this.$modal.hide('jobFileEditModal');
            this.getJobFiles();
        },
        getError() {
            this.errorDropzone = this.$refs.myVueDropzone.getRejectedFiles();

            if (this.errorDropzone) {
                $('.dropzone-file .dropzone .dz-error-message').attr('style', 'display: unset !important');
            }
        },
        removedFile() {
            this.errorDropzone = null;
        },
    }
}
</script>

<style scoped>
.dropzone .dz-preview .dz-error-message {
    opacity: 1 !important;
    top: 0 !important;
}
</style>