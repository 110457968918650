<template>
    <div class="card">
        <div class="card-header">
            <h3 class="d-flex align-items-center mb-0">
                <i class="ri-road-map-line"></i> {{ $t('label.billing & shipping address') }}
            </h3>
        </div>
        <div class="card-body">
            <div class="row m-0">
            <template v-for="(data,index) in purchaseOrderAddress" >
                <div :key="index" class="col-6 p-0">
                <!-- <div :key="index" v-for="(data, index) in purchaseOrderAddress" class="col-6 p-0"> <div>{{data}}</div> -->
                    <label class="form-control-label text-muted">{{ data.address_type == 'billing' ? $t('label.billing address') : $t('label.shipping address') }}</label>
                    <div class="row align-items-center pt-3">
                        <div class="display_address col-auto">
                            <!-- <div v-if="data.type == 'store_location'">
                                <div class="address">{{ $t('label.store location') }}</div>
                            </div> -->
                            <div>
                            <!-- <div v-else> -->
                                <div class="address">{{ addressFilter(data).address_1 }}</div>
                                <div v-if="addressFilter.address_2" class="address">{{ addressFilter(data).address_2 }}</div>
                                <div class="address">{{ addressFilter(data).city }}, {{ addressFilter(data).state }} {{ addressFilter(data).postal_code }}</div>
                            </div>
                        </div>
                        <i @click="goToAddress(data)" class="ri-external-link-line heading-title pointer"></i>
                    </div>
                    </div>
            </template>
                <!-- </div> -->
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['purchaseOrderAddress', 'selectedVenContact'],
    emits: [''],
    components: {

    },
    data() {
        return {

        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        addressFilter(data) {
            let tempAddress = {};

            if (data.type == 'company') {
                tempAddress = data.company_address;

            } else if (data.type == 'customer') {
                tempAddress = data.customer_address;

            } else if (data.type == 'job') {
                tempAddress = data.job_address;

            } else if (data.type == 'store_location') {
                tempAddress = this.selectedVenContact;
            } else {
                tempAddress = data;
            }

            return tempAddress;
        },
        goToAddress(data) {
            let address = this.addressFilter(data);
            let complete_address = address.address_1 + ", " + address.city + ", " + address.state + ", " + address.postal_code;
            window.open('http://maps.google.com/?q=' + complete_address);
        }
    },
}
</script>
