<template>
    <div>
        <div class="card" v-if="user.role_id === 2">
            <div class="card-header">
                <div class="row align-items-center">
                    <div class="col-sm-8">
                        <h3 class="mb-0">{{ $t('label.employee time off requests') }}</h3>
                    </div>
                    <div class="col-sm-4 text-right">
                        <a class="btn btn-primary btn-sm" style="color:#ffffff;" @click="addEmployeeTimeOff()">
                            <i class="ri-add-line"></i>&nbsp;{{ $t('label.add time off request') }}
                        </a>
                    </div>
                </div>
            </div>
            <div class="card-body p-0 m-0">
                <div class="table-responsive" v-if="companyRequests.length">
                    <table class="table table-flush table-sm">
                        <thead class="thead-light">
                            <tr>
                                <th class="col-sm-3">User/Date(s)</th>
                                <th class="col-sm-3">{{ $t('label.reason') }}</th>
                                <th class="col-sm-2">{{ $t('label.status') }}</th>
                                <th class="col-sm-3">{{ $t('label.notes') }}</th>
                                <th class="col-sm-1"></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="item in companyRequests" :key="item.id">
                                <!-- User and Dates -->
                                <td>
                                    <!-- User name -->
                                    <div class="font-weight-bold">{{ getEmployeeName(item.user_id) }}</div>
                                    <!-- Date Range -->
                                    <div v-if="item.start_date !== item.end_date">
                                        <span v-if="moment(item.start_date).hour() > 0">
                                            {{ moment(item.start_date).format('MM/DD/YYYY hh:mm a') }}
                                        </span>
                                        <span v-else>{{ moment(item.start_date).format('MM/DD/YYYY') }} {{ $t('label.all day') }}</span>
                                        -
                                        <span v-if="moment(item.end_date).hour() > 0">
                                            {{ moment(item.end_date).format('MM/DD/YYYY hh:mm a') }}
                                        </span>
                                        <span v-else>{{ moment(item.end_date).format('MM/DD/YYYY') }} {{ $t('label.all day') }}</span>
                                    </div>
                                    <!-- Single Dates -->
                                    <div v-else>
                                        <!-- Time Range off -->
                                        <span v-if="moment(item.start_date).hour() > 0 && moment(item.end_date).hour() > 0">
                                            {{ moment(item.start_date).format('MM/DD/YYYY hh:mm a') }}
                                            -
                                            {{ moment(item.end_date).format('hh:mm a') }}
                                        </span>

                                        <!-- All Day off -->
                                        <span v-else>{{ moment(item.start_date).format('MM/DD/YYYY') }} {{ $t('label.all day') }}</span>
                                    </div>
                                </td>

                                <!-- Reason -->
                                <td>{{ item.description }}</td>

                                <!-- Status -->
                                <td>
                                    <span class="badge p-2" :class="{ 'badge-warning': item.status === 'Pending', 'badge-danger': item.status === 'Denied', 'badge-success': item.status === 'Approved', 'badge-dark': item.status === 'Cancelled' }" style="font-size: 12px;">{{ item.status }}</span>
                                </td>

                                <!-- Manager Notes -->
                                <td>
                                    <div v-if="item.manager_user_id">
                                        <div>
                                            {{ item.manager_notes }}
                                        </div>
                                        <div class="text-muted">
                                            {{ getEmployeeName(item.manager_user_id) }}
                                            {{ moment(item.manager_action_date).format('MM/DD/YYYY hh:mm a') }}
                                        </div>
                                    </div>
                                </td>

                                <!-- Menu items -->
                                <td class="text-right">
                                    <div class="dropdown" v-if="item.status == 'Pending' || item.status == 'Approved'">
                                        <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-ellipsis-v"></i>
                                        </a>

                                        <!-- Pending requests menu items -->
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" v-if="item.status === 'Pending'">
                                            <!-- Approve Request -->
                                            <a class="dropdown-item pointer" :data-id="item.id" @click="confirmRequestStatusChange(item, 1)">
                                                <i class="ri-thumb-up-line text-success"></i>&nbsp;{{ $t('label.approve time off request') }}
                                            </a>
                                            <!-- Deny Request -->
                                            <a class="dropdown-item pointer" @click="confirmRequestStatusChange(item, 0)">
                                                <i class="ri-thumb-down-line text-danger"></i>&nbsp;{{ $t('label.deny time off request') }}
                                            </a>
                                        </div>

                                        <!-- Approved requests can be cancelled by user that created them -->
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                            <!-- Cancel Request -->
                                            <a class="dropdown-item pointer" :disabled="item.status !== 'Approved' || item.added_user_id !== user.id" :data-id="item.id" @click="confirmRequestStatusChange(item, 2)">
                                                <i class="ri-error-warning-line text-warning"></i>&nbsp;{{ $t('label.cancel time off request') }}
                                            </a>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <table-pagination
                        v-model="paginatedJobTypes"
                        :all-rows="jobTypes"
                        :perPage="perPage">
                    </table-pagination> -->
                </div>

                <!-- No items to show message -->
                <div v-else class="p-4">
                    <div class="mb-0 d-flex align-items-center">You have no time off request from your team right now.</div>
                </div>
            </div>
        </div>

        <!-- Add/Edit Employee Time off Request Modal -->
        <modal name="addOrEditEmployeeRequest" :width="800" height="auto" :scrollable="true" :reset="true" :adaptive="true">
            <time-off-request-employee :data="selectedRequest" @modifiedRequest="employeeRequestReturn"></time-off-request-employee>
        </modal>

        <!-- Approve Request modal -->
        <modal name="requestApproveModal" height="auto" :reset="true" :adaptive="true">
            <time-off-approve :item="selectedRequest" @returnChanges="returnStatusChange"></time-off-approve>
        </modal>

        <!-- Deny request  Modal -->
        <modal name="requestDenyModal" height="auto" :reset="true" :adaptive="true">
            <time-off-deny :item="selectedRequest" @returnChanges="returnStatusChange"></time-off-deny>
        </modal>

        <!-- Cancel Approved Request -->
        <modal name="requestCancelModal" height="auto" :reset="true" :adaptive="true">
            <time-off-cancel :item="selectedRequest" @returnChanges="returnStatusChange"></time-off-cancel>
        </modal>
    </div>
</template>

<script>
import TimeOffRequestEmployee from '@/components/TimeOffRequestEmployeeComponent';
import TimeOffApprove from '@/components/TimeOffRequests/TimeOffApprove';
import TimeOffCancel from '@/components/TimeOffRequests/TimeOffCancel';
import TimeOffDeny from '@/components/TimeOffRequests/TimeOffDeny';
import bus from '@/utils/event-bus';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('userData')
export default {
    props: [],
    emits: ['dataLoaded'],
    components: {
        TimeOffRequestEmployee,
        TimeOffApprove,
        TimeOffDeny,
        TimeOffCancel,
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            companyRequests: [],
            selectedRequest: null,
        }
    },
    computed: {
        ...mapState({
            allUsers: state => state.allUsers,
        }),
    },
    mounted() {
        this.getCompanyTimeOffRequests();
        if (!this.allUsers.length) {
            this.getAllUsers();
        }
    },
    methods: {
        ...mapActions(['getAllUsers']),

        employeeRequestReturn(request) {
            this.$modal.hide('addOrEditEmployeeRequest');
            // Add newly added request to current list object and order by start_date desc
            this.companyRequests.push(request);
            this.companyRequests = _.orderBy(this.companyRequests, 'start_date', 'desc');
        },

        // get requests for company users if current user is manager
        getCompanyTimeOffRequests() {
            axios
                .get('/companyTimeOffReqestList')
                .then(response => {
                    this.companyRequests = response.data;
                    this.$emit('dataLoaded');
                })
                .catch(err => {
                    console.log('ERROR: ', err);
                    bus.$emit('banner-error', { message: err });
                });
        },

        confirmRequestStatusChange(item, action) {
            // Reset the error messages
            this.selectedRequest = item;

            //Show confirmation modal
            // Approve request modal
            if (action === 1) {
                this.$modal.show('requestApproveModal');
            }
            // Cancel approved request
            else if (action === 2) {
                this.$modal.show('requestCancelModal');
            }
            //Deny request modal
            else {
                this.$modal.show('requestDenyModal');
            }
        },
        returnStatusChange(response) {
            // update the request object
            let idx = this.companyRequests.findIndex(request => {
                if (request.id === response.id) {
                    return true;
                }
            });
            this.companyRequests[idx]['manager_user_id'] = response.manager_user_id;
            this.companyRequests[idx]['manager_notes'] = response.manager_notes;
            this.companyRequests[idx]['manager_action_date'] = response.manager_action_date;
            this.companyRequests[idx]['status'] = response.status;
        },
        addEmployeeTimeOff() {
            this.$modal.show('addOrEditEmployeeRequest');
        },
        getEmployeeName(userId) {
            if (this.allUsers) {
                let idx = this.allUsers.findIndex(request => {
                    if (request.id === userId) {
                        return true;
                    }
                });
                return (idx >= 0) ? this.allUsers[idx].name : 'N/A';
            }
        },
    }
}
</script>

<style></style>