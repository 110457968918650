/* eslint-disable */
import AuthService from '@/services/AuthService';
import VeeValidate from 'vee-validate';
import Vue from 'vue';


/**
 * Custom Phone Number Validator's
 */
const phoneValidatorRule = {
  getMessage(field, args) {
    return `The ${field} must be either a valid phone number`;
  },
  validate(value, args) {
    // Custom regex for a phone number 
    const MOBILEREG = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    // Check for either of these to return true
    return MOBILEREG.test(value);
  }
};
VeeValidate.Validator.extend('phone', phoneValidatorRule);

/**
 * Custom Company Name Validation
 */
const companyNameValidatorRule = {
  getMessage(field, args) {
    return `The company ${args[0]} already exists.`;
  },
  async validate(value, args) {
    if (Array.isArray(args) && args.length > 0) {
      const toReturn = await AuthService.validateField({ 'field_name': args[0], value }).then(data => data.data);
      return toReturn;
    }
    return true;
  }
};
VeeValidate.Validator.extend('company', companyNameValidatorRule);


/**
 * Load the Module
 * 
 * @plugins
 */
async function loadModules() {
  Vue.use(VeeValidate);
  VeeValidate.Validator.extend('verify_password', {
    getMessage: field => `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
    validate: value => {
      var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
      return strongRegex.test(value);
    }
  });
  Vue.prototype.$setValidationErrorsFromResponse = function (errorResponse, scope = null) {
    // only allow this function to be run if the validator exists
    if (!this.hasOwnProperty('$validator')) {
      return;
    }

    // clear errors
    this.$validator.errors.clear();

    // check if errors exist
    if (!errorResponse.hasOwnProperty('errors')) {
      return;
    }

    let errorFields = Object.keys(errorResponse.errors);

    // insert laravel errors
    for (let i = 0; i < errorFields.length; i++) {
      let field = errorFields[i];

      let errorString = errorResponse.errors[field].join(', ');
      let error = {
        field: field,
        msg: errorString
      };

      if (scope) {
        error.scope = scope;
      }

      this.$validator.errors.add(error);
    }
  };
}

export async function registerVeeValidate() {
  await loadModules();
}
