<template>
	<div>
		<div class="header bg-primary pb-6 mobile-p-0">
			<div class="container-fluid">
				<div class="header-body">
					<div class="row align-items-center py-4">
						<div class="col-lg-6 col-7">
							<div class="my--4">
								<breadcrumbs :title="$t('label.jobs')">
									<!-- Breadcrumb slots -->
									<li class="breadcrumb-item">
										{{ $t('label.jobs') }}
									</li>
								</breadcrumbs>
							</div>
						</div>
						<div class="col-lg-6 col-5 text-right">
							<button class="btn btn-neutral mobile-d-none" type="button" @click="createNewJobTemplate()">
								{{ $t('label.create new template') }}
							</button>
							<div class="dropdown">
								<button type="button" class="btn btn-neutral" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									{{ $t('label.add job') }}
								</button>
								<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" style="min-width: 300px;">
									<a class="dropdown-item" href="#" @click="$modal.show('jobsAddJobModal')">
										<i class="ri-file-3-line"></i> {{ $t('label.empty job') }}
									</a>
									<div class="dropdown-divider"></div>
									<h4 class="m-0 px-3">{{ $t('label.job templates') }}</h4>
									<div class="row align-items-center m-0 templates cursor-pointer2 flex-nowrap" v-for="(data, index) in alljobtemplates" :value="data.id" :key="index">
										<a class="dropdown-item col" href="#" data-toggle="modal" data-target="#useTemplateModal" @click="useTemplate(data)">
											<i class="ri-pages-line"></i> {{ data.job_name }}
										</a>
										<div class="dropdown-submenu p-0 col-auto" style="z-index: 90000" :id="'template' + index">
											<a class="text-light test" href="#" role="button">
												<i class="fas fa-ellipsis-h btn-sm px-4 py-2"></i>
											</a>
											<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow">
												<button class="dropdown-item pointer" type="button" @click="editExistingJobTemplate(data)">
													<i class="ri-pencil-line"></i> {{ $t('label.edit template') }}
												</button>
												<button class="dropdown-item text-danger pointer" type="button" @click="openDeleteTempModal(data)">
													<i class="ri-delete-bin-line"></i> {{ $t('label.delete template') }}
												</button>
											</div>
										</div>
									</div>
									<div class="dropdown-divider"></div>
									<button class="dropdown-item pointer" type="button" @click="createNewJobTemplate()">
										<i class="ri-file-add-line"></i> {{ $t('label.new template') }}
									</button>
									<router-link :to="{ name: 'jobTemplates' }">
										<a class="dropdown-item text-dark">
											<i class="ri-settings-3-line"></i>&nbsp;{{ $t('label.manage templates') }}
										</a>
									</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="row hidden-md-down">
						<div class="col-xl-3 col-md-6">
							<div class="card card-stats">
								<div class="card-body">
									<div class="row">
										<div class="col" style="min-height: 68px;">
											<h5 class="card-title text-uppercase text-muted mb-0">{{ $t('label.total open jobs') }}</h5>
											<span class="h2 font-weight-bold mb-0">{{ countAllJobs }}</span>
										</div>
										<div class="col-auto">
											<div class="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
												<i class="ni ni-active-40"></i>
											</div>
										</div>
									</div>
									<p class="mt-3 mb-0 text-sm">
										<span class="text-nowrap text-primary font-weight-bold cursor-pointer" @click="getJobs(1)">{{ $t('label.view jobs') }}</span>
									</p>
								</div>
							</div>
						</div>
						<div class="col-xl-3 col-md-6">
							<div class="card card-stats">
								<div class="card-body">
									<div class="row">
										<div class="col" style="min-height: 68px;">
											<h5 class="card-title text-uppercase text-muted mb-0">{{ $t('label.my jobs') }}</h5>
											<span class="h2 font-weight-bold mb-0">{{ countMyJobs }}</span>
										</div>
										<div class="col-auto">
											<div class="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
												<i class="ni ni-chart-pie-35"></i>
											</div>
										</div>
									</div>
									<p class="mt-3 mb-0 text-sm">
										<span class="text-nowrap text-primary font-weight-bold cursor-pointer" @click="getJobs(2)">{{ $t('label.view jobs') }}</span>
									</p>
								</div>
							</div>
						</div>
						<div class="col-xl-3 col-md-6">
							<div class="card card-stats">
								<div class="card-body">
									<div class="row">
										<div class="col" style="min-height: 68px;">
											<h5 class="card-title text-uppercase text-muted mb-0">{{ $t('label.my recently closed') }}</h5>
											<span class="h2 font-weight-bold mb-0">{{ countRecentlyClosedJobs }}</span>
										</div>
										<div class="col-auto">
											<div class="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
												<i class="ni ni-chart-bar-32"></i>
											</div>
										</div>
									</div>
									<p class="mt-3 mb-0 text-sm">
										<span class="text-nowrap text-primary font-weight-bold cursor-pointer" @click="getJobs(4)">{{ $t('label.view jobs') }}</span>
									</p>
								</div>
							</div>
						</div>
					</div>
					<!-- MOBILE -->
					<div class="d-flex d-md-none mobile-d-none">
						<div class="col-xs-3 p-1">
							<div class="card card-stats">
								<!-- Card body -->
								<div class="card-body p-2 text-center" @click="getJobs(1)">
									<div class="row">
										<div class="col">
											<h5 class="card-title text-uppercase text-muted mb-0">{{ $t('label.total open jobs') }}</h5>
											<span class="h2 font-weight-bold mb-0">{{ countAllJobs }}</span>
										</div>
										<div class="col">
											<div class="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
												<i class="ni ni-active-40"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-xs-3 p-1">
							<div class="card card-stats">
								<!-- Card body -->
								<div class="card-body p-2 text-center" @click="getJobs(2)">
									<div class="row">
										<div class="col">
											<h5 class="card-title text-uppercase text-muted mb-0">{{ $t('label.my total jobs') }}</h5>
											<span class="h2 font-weight-bold mb-0">{{ countMyJobs }}</span>
										</div>
										<div class="col">
											<div class="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
												<i class="ni ni-chart-pie-35"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-xs-3 p-1">
							<div class="card card-stats">
								<!-- Card body -->
								<div class="card-body p-2 text-center" @click="getJobs(3)">
									<div class="row">
										<div class="col">
											<h5 class="card-title text-uppercase text-muted mb-0">{{ $t('label.my overdue jobs') }}</h5>
											<span class="h2 font-weight-bold mb-0">{{ countOverdueJobs }}</span>
										</div>
										<div class="col">
											<div class="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
												<i class="ni ni-money-coins"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-xs-3 p-1">
							<div class="card card-stats">
								<!-- Card body -->
								<div class="card-body p-2 text-center" @click="getJobs(4)">
									<div class="row">
										<div class="col">
											<h5 class="card-title text-uppercase text-muted mb-0">{{ $t('label.my recently closed') }}</h5>
											<span class="h2 font-weight-bold mb-0">{{ countRecentlyClosedJobs }}</span>
										</div>
										<div class="col">
											<div class="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
												<i class="ni ni-chart-bar-32"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid mt--6 mobile-mt-160p">
			<!-- Search Box -->
			<div class="row" v-show="showSearchbox">
				<div class="col">
					<div class="card job-search">
						<div class="card-header py-0">
							<div class="tabs row align-items-center justify-content-between">
								<div class="col col-xs-12 mobile-pt-1">
									<h3 class="mb-0"><i class="fas fa-search"></i> {{ $t('label.search jobs') }}</h3>
								</div>
								<div class="nav-wrapper col-xs-12">
									<div class="nav btn-group btn-group-light justify-content-center" role="tablist" aria-label="Basic example" id="tabs-btn" style="z-index: 0">
										<a class="btn active" id="tabs-btn-basic-1" data-toggle="tab" role="tab" aria-controls="tabs-btn-basic" aria-selected="true" @click="showAdvancedSearch()">
											{{ $t('label.basic search') }}
										</a>
										<a class="btn" id="tabs-btn-basic-2" data-toggle="tab" role="tab" aria-controls="tabs-btn-advance" aria-selected="false" @click="hideAdvancedSearch()">
											{{ $t('label.advanced search') }}
										</a>
									</div>
								</div>
							</div>
						</div>
						<div class="card-body">
							<div class="job-info">
								<h5 class="modal-title mb-3">{{ $t('label.job info') }}</h5>
								<div class="row">
									<div class="col-lg-2">
										<div class="form-group">
											<label class="form-control-label text-muted" for="input-name">{{ $t('label.job id') }}</label>
											<input v-model="filter.job_id" type="text" name="job_id" class="form-control" :placeholder="$t('label.job id') + ': 5236'" autofocus autocomplete="input-field" data-lpignore="true">
										</div>
									</div>
									<div class="col-lg-3">
										<div class="form-group">
											<label class="form-control-label text-muted" for="input-name">{{ $t('label.job name') }}</label>
											<input v-model="filter.job_name" type="text" name="job_name" class="form-control" :placeholder="$t('label.example') + ': ' + $t('label.Jensen Family Home')" autofocus autocomplete="input-field" data-lpignore="true">
										</div>
									</div>
									<div class="col-lg-3">
										<div class="form-group">
											<label class="form-control-label text-muted" for="input-name">{{ $t('label.start date range') }}</label>
											<date-picker v-model="filter.start_date" valueType="format" type="date" range placeholder="Select date range" format="MM/DD/YYYY" class="full-width" input-class="form-control"></date-picker>
										</div>
									</div>
									<div class="col-lg-3">
										<div class="form-group">
											<label class="form-control-label text-muted" for="input_name">{{ $t('label.job type') }}</label>
											<div class="dropdown w-100">
												<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
													<div class="input-group input-group-alternative input-group-merge search-contacts">
														<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a job type') + ' -'" v-model="filter.job_type_name" autocomplete="input-field" data-lpignore="true" readonly>
														<div class="input-group-append">
															<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
														</div>
													</div>
												</div>

												<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
													<div class="pl-3">
														<span class="jobtypename pointer dropdown-items" value="" @click="selectedJobType('');">- {{ $t('label.select a job type') }} -</span>
														<template v-for="(data, index) in alljobtypes">
															<div :key="index" :value="data.id" class="pointer dropdown-items" @click="selectedJobType(data);">
																<span class="jobtypename">{{ data.name }}</span>
															</div>
														</template>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-lg-2">
										<div class="form-group">
											<label class="form-control-label text-muted" for="input_name">{{ $t('label.job status') }}</label>
											<div class="dropdown w-100">
												<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
													<div class="input-group input-group-alternative input-group-merge search-contacts">
														<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.job status') + ' -'" v-model="filter.job_status_name" autocomplete="input-field" data-lpignore="true" readonly>
														<div class="input-group-append">
															<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
														</div>
													</div>
												</div>

												<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
													<div class="pl-3">
														<span class="jobstatusname pointer dropdown-items" value="" @click="selectedJobStatus('');">- {{ $t('label.select a job type') }} -</span>
														<template v-for="(data, index) in alljobstatus">
															<div :key="index" :value="data.id" class="pointer dropdown-items" @click="selectedJobStatus(data);">
																<span class="jobstatusname">{{ data.name }}</span>
															</div>
														</template>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-lg-3">
										<div class="form-group">
											<label class="form-control-label text-muted" for="input-name">{{ $t('label.address') }}</label>
											<input v-model="filter.address" type="text" name="address" class="form-control" :placeholder="$t('label.example') + ': ' + $t('label.123 Main St')" autofocus autocomplete="input-field" data-lpignore="true">
										</div>
									</div>
								</div>
								<div v-show="!advancedSearchView" class="row" id="job-search-btns">
									<div class="col-6 text-left col-xs-12">
										<button type="button" class="btn btn-primary" @click="getJobs(); searchBox();">
											{{ $t('label.search jobs') }}
										</button>
										<button type="button" class="btn btn-link ml-auto" @click="clearSearch()">
											{{ $t('label.clear search') }}
										</button>
									</div>
									<div class="col-6 text-right col-xs-12 d-none d-md-block">
										<button type="submit" class="btn btn-secondary color-black" data-toggle="modal" data-target="#saveViewModal">
											<i class="fas fa-save"></i>
											{{ $t('label.save view') }}
										</button>
										<button type="button" class="btn btn-secondary color-black" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											{{ $t('label.saved views') }}
											<i class="fas fa-angle-down"></i>
										</button>
										<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" style="max-width: 100%;">
											<a class="dropdown-item" href="#" v-for="(data, index) in allsearchviews" :key="index" @click="useSearchView(data)">
												{{ data.name }}
											</a>
											<div class="dropdown-divider"></div>
											<a class="dropdown-item" href="#" data-toggle="modal" data-target="#manageViewModal">{{ $t('label.manage saved views') }}</a>
										</div>
									</div>
								</div>
							</div>
							<div v-show="advancedSearchView" id="myTabContent">
								<div class="job-info">
									<h5 class="modal-title mb-3">{{ $t('label.people and customers') }}</h5>
									<div class="row">
										<div class="col-lg-3">
											<div class="form-group">
												<label class="form-control-label text-muted" for="input_name">{{ $t('label.job manager') }}</label>
												<div class="dropdown w-100">
													<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<div class="input-group input-group-alternative input-group-merge search-contacts">
															<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a job manager') + ' -'" v-model="filter.job_manager_name" autocomplete="input-field" data-lpignore="true" readonly>
															<div class="input-group-append">
																<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
															</div>
														</div>
													</div>
													<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
														<div class="pl-3">
															<span class="managername pointer dropdown-items" value="" @click="selectedManager('');">- {{ $t('label.select a job type') }} -</span>
															<template v-for="(data, index) in allManagers">
																<div :key="index" :value="data.id" class="pointer dropdown-items" @click="selectedManager(data);">
																	<span class="managername">{{ data.name }}</span>
																</div>
															</template>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-lg-3">
											<div class="form-group">
												<label class="form-control-label text-muted" for="input_name">{{ $t('label.foreman') }}</label>
												<div class="dropdown w-100">
													<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<div class="input-group input-group-alternative input-group-merge search-contacts">
															<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a foreman') + ' -'" v-model="filter.job_foreman_name" autocomplete="input-field" data-lpignore="true" readonly>
															<div class="input-group-append">
																<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
															</div>
														</div>
													</div>

													<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
														<div class="pl-3">
															<span class="foremanname pointer dropdown-items" value="" @click="selectedForeman('');">- {{ $t('label.select a foreman') }} -</span>
															<template v-for="(data, index) in alljobpeople['users']">
																<div :key="index" :value="data.id" class="pointer dropdown-items" @click="selectedForeman(data);">
																	<span class="foremanname">{{ data.name }}</span>
																</div>
															</template>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-lg-3">
											<div class="form-group">
												<label class="form-control-label text-muted" for="input_name">{{ $t('label.laborer') }}</label>
												<div class="dropdown w-100">
													<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<div class="input-group input-group-alternative input-group-merge search-contacts">
															<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a laborer') + ' -'" v-model="filter.job_laborer_name" autocomplete="input-field" data-lpignore="true" readonly>
															<div class="input-group-append">
																<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
															</div>
														</div>
													</div>

													<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
														<div class="pl-3">
															<span class="foremanname pointer dropdown-items" value="" @click="selectedJobLaborer('');">- {{ $t('label.select a laborer') }} -</span>
															<template v-for="(data, index) in alljobpeople['users']">
																<div :key="index" :value="data.id" class="pointer dropdown-items" @click="selectedJobLaborer(data);">
																	<span class="foremanname">{{ data.name }}</span>
																</div>
															</template>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-lg-3">
											<div class="form-group">
												<label class="form-control-label text-muted" for="input_name">{{ $t('label.customer') }}</label>
												<div class="dropdown w-100">
													<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<div class="input-group input-group-alternative input-group-merge search-contacts">
															<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a customer') + ' -'" v-model="filter.job_customer_name" autocomplete="input-field" data-lpignore="true" readonly>
															<div class="input-group-append">
																<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
															</div>
														</div>
													</div>

													<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
														<div class="pl-3">
															<span class="customername pointer dropdown-items" value="" @click="selectedCustomer('');">- {{ $t('label.select a customer') }} -</span>
															<template v-for="(data, index) in allCustomers">
																<div :key="index" :value="data.id" class="pointer dropdown-items" @click="selectedCustomer(data);">
																	<span class="customername">{{ data.name }}</span>
																</div>
															</template>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="" v-if="extraFilter">
										<h5 class="modal-title mb-3">Job Custom Fields</h5>
										<div class="row">
											<div v-for="customfield in customfields" :key="customfield.id" class="col-lg-3">
												<custom-field :customfield="customfield" :parent-bus="component_bus" @input="onCustomfieldInput($event, customfield)"></custom-field>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-6 text-left col-xs-12">
										<button type="button" class="btn btn-primary" @click="getJobs(); searchBox();">
											{{ $t('label.search jobs') }}
										</button>
										<button type="button" class="btn btn-link ml-auto" @click="clearSearch()">
											{{ $t('label.clear search') }}
										</button>
									</div>
									<div class="col-6 text-right col-xs-12 d-none d-md-block">
										<button type="submit" class="btn btn-secondary color-black" data-toggle="modal" data-target="#saveViewModal">
											<i class="fas fa-save"></i>
											{{ $t('label.save view') }}
										</button>
										<button type="button" class="btn btn-secondary color-black" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											{{ $t('label.saved views') }}
											<i class="fas fa-angle-down"></i>
										</button>
										<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" style="max-width: 100%;">
											<a class="dropdown-item" href="#" v-for="(data, index) in allsearchviews" :key="index" @click="useSearchView(data)">
												{{ data.name }}
											</a>
											<div class="dropdown-divider"></div>
											<a class="dropdown-item" href="#" data-toggle="modal" data-target="#manageViewModal">{{ $t('label.manage saved views') }}</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Jobs List -->
			<div class="row" id="jobs-header-data" :data-column0="$t('label.job id')" :data-column1="$t('label.job name')" :data-column2="$t('label.customer')" :data-column3="$t('label.job manager')" :data-column4="$t('label.job status')" :data-column5="$t('label.start date')" :data-column6="$t('label.job type')">
				<div class="col">
					<div class="card">
						<div class="card-header">
							<div class="row align-items-center">
								<div class="col-8">
									<h3 class="mb-0 d-flex align-items-center"><i class="ri-stack-line heading-title"></i> {{ $t('label.jobs') }} ({{ totalCount }})</h3>
								</div>
								<div class="col-4 text-right" v-if="!showSearchbox">
									<a href="#" class="btn btn-sm btn-default" @click="searchBox()">{{ $t('label.revise search') }}</a>
								</div>
							</div>
						</div>

						<div class="table-responsive pb-4" id="jobs-header-table">
							<v-server-table class="table-sm" ref="vueservertable" url="/get-jobs" :columns="columns" :options="options">
								<template slot="job_status.name" slot-scope="props">
									<span class="badge badge-dot">
										<i :style="`background-color: ${props.row.job_status.status_color}`"></i>
										{{ props.row.job_status ? props.row.job_status.name : '' }}
									</span>
								</template>
								<template slot="job_manager.name" slot-scope="props">
									<div v-if="props.row.job_manager" class="cursor-pointer" @click="getUserInfo(props.row.job_manager.id, 'Job Manager')">
										<img class="rounded-circle" :src="props.row.job_manager.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
										{{ props.row.job_manager ? props.row.job_manager.name : '' }}
									</div>
								</template>
								<template slot="customer.name" slot-scope="props">
									<div v-if="props.row.customer" class="cursor-pointer" @click="getUserInfo(props.row.customer.id, 'Customer')">
										<img class="rounded-circle" v-if="props.row.customer.logo" :src="props.row.customer.logo" alt="" style="width: 36px; height: 36px; object-fit: cover;">
										{{ props.row.customer ? props.row.customer.name : '' }}
									</div>
								</template>
								<template slot="start_date" slot-scope="props">
									{{ props.row.start_date | formatDate }}
								</template>
								<template slot="job_type_name" slot-scope="props">
									{{ props.row.job_type.name }}
								</template>
								<template slot="job_name" slot-scope="props">
									<a class="cursor-pointer text-bold" @click="editExistingJob(props.row.job_number.job_number)">
										{{ props.row.job_name }}
									</a>
								</template>
								<template slot="actions" slot-scope="props">
									<div class="dropdown">
										<a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<i class="fas fa-ellipsis-v"></i>
										</a>
										<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
											<button class="dropdown-item d-flex align-items-center pointer" type="button" @click="editExistingJob(props.row.job_number.job_number)">
												<i class="ri-pencil-line"></i>{{ $t('label.edit job') }}
											</button>
											<template v-if="props.row.job_status">
												<a v-if="props.row.job_status.name !== 'Closed'" class="text-dark dropdown-item d-flex align-items-center pointer" @click="showCloseJobModal(props.row.id)">
													<i class="ri-check-line"></i>{{ $t('label.close job') }}
												</a>
												<a v-else class="dropdown-item d-flex align-items-center" href="#" data-target="modal" @click="quickReopenJob(props.row.id)">
													<i class="ri-inbox-unarchive-line"></i>{{ $t('label.reopen job') }}
												</a>
											</template>
										</div>
									</div>
								</template>
								<template slot="total_job_time" slot-scope="props">
									{{ props.row.totalTime | formatTime }} ({{ (props.row.totalTime / 3600) | formatHour }} {{ $t('label.hrs') }})
								</template>
							</v-server-table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!--SAVE VIEW MODAL -->
		<div class="modal fade" id="saveViewModal" tabindex="-1" role="dialog" aria-labelledby="saveViewModalTitle" aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header border-bottom">
						<h5 class="modal-title" id="saveViewModalTitle">{{ $t('label.save search view') }}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<i class="ri-close-line"></i>
						</button>
					</div>
					<form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="saveSearchView">
						<div class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<label class="form-control-label text-muted" for="input-name">{{ $t('label.name of view') }}</label>
										<input v-model="search.name" type="text" name="name" class="form-control" placeholder="Name of View" autofocus autocomplete="input-field" data-lpignore="true">
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer border-top">
							<button type="submit" class="btn btn-primary mr-auto">{{ $t('label.save view') }}</button>
							<button type="button" class="btn btn-secondary close2saveview" data-dismiss="modal">{{ $t('label.cancel') }}</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<!--MANAGE VIEW MODAL -->
		<div class="modal fade" id="manageViewModal" tabindex="-1" role="dialog" aria-labelledby="manageViewModalTitle" aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header border-bottom">
						<h5 class="modal-title" id="manageViewModalTitle">{{ $t('label.manage saved views') }}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<i class="ri-close-line"></i>
						</button>
					</div>
					<form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="updateSearchViews">
						<div class="modal-body">
							<h4 class="mb-4">{{ $t('label.You can change the name and delete views') }}. {{ $t('label.Deleting views cannot be undone') }}.</h4>
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<div class="d-flex align-items-center mb-2" v-for="(data, index) in allsearchviews" :key="index">
											<div class="flex-grow-1">
												<input v-model="data.name" type="text" name="name" class="form-control" placeholder="Name of View" required autofocus autocomplete="input-field" data-lpignore="true">
											</div>
											<div @click="removeSavedView(index)" class="p-3" style="background: #dd1a3e; color: #fff; border-radius: 0px 5px 5px 0px; height: 46px; line-height: 15px;">
												<i class="ri-delete-bin-line"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer border-top">
							<button type="submit" class="btn btn-primary mr-auto">{{ $t('label.save changes') }}</button>
							<button type="button" class="btn btn-link close2updateviews" data-dismiss="modal">{{ $t('label.cancel') }}</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<!-- Add Job Modal -->
		<modal class="pb-6" name="jobsAddJobModal" height="auto" width="100%" :max-width="1024" :scrollable="true" :reset="true" :adaptive="true">
			<job-add-component :customerid="customerid" :getjobs="getJobs" @closeAddJobModal="$modal.hide('jobsAddJobModal')" @returnAddJobModal="receiveNewJob">
			</job-add-component>
		</modal>

		<!-- Template Modal -->
		<div class="modal fade" id="useTemplateModal" tabindex="-1" role="dialog" aria-labelledby="useTemplateModal" aria-hidden="true">
			<div class="modal-dialog modal-primary modal-dialog-centered modal-" role="document">
				<div class="modal-content bg-gradient-primary">
					<div class="modal-header">
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<i class="ri-close-line text-white"></i>
						</button>
					</div>
					<div class="modal-body">
						<div class="py-3 text-center">
							<i class="ri-todo-line ni-3x"></i>
							<h4 class="heading mt-4 text-uppercase">{{ $t('label.ARE YOU SURE YOU WANT TO USE THIS TEMPLATE') }}?</h4>
						</div>
					</div>
					<div class="modal-footer">
						<button type="submit" class="btn btn-white" data-dismiss="modal" @click="createJobFromTemplate()">{{ $t('label.yes') }}</button>
						<button type="button" class="btn btn-link text-white ml-auto" data-dismiss="modal">{{ $t('label.close') }}</button>
					</div>
				</div>
			</div>
		</div>

		<!-- Delete Job Template -->
		<modal-delete-component v-if="showJobTempDlt" :new-id="'Job Template'" :title="$t('label.ARE YOU SURE YOU WANT TO DELETE THIS JOB TEMPLATE')" @onClose="closeDeleteTempModal" @customDeleteMethod="deleteJobTemplate">
		</modal-delete-component>

		<!-- Close Job Confirmation Modal -->
		<modal-confirm-job-close v-if="showCloseJobConf" :z-index="3" :job-id="selectedJobId" :custom-confirm-method="true" @on-confirm-method="quickCloseJob" @on-close-modal="hideCloseJobModal">
		</modal-confirm-job-close>

	</div>
</template>

<script>
import ModalDeleteComponent from "@/components/ModalDelete2.vue";
import bus from '@/utils/event-bus';
import moment from 'moment';

export default {
	props: ['jobid', 'token'],
	components: {
		ModalDeleteComponent
	},
	data() {
		return {
			component_bus: new Vue(),
			user: JSON.parse(localStorage.getItem('user')),
			alljobtypes: [],
			alljobstatus: [],
			alljobpeople: [],
			allManagers: [],
			allCustomers: [],
			alljobs: [],
			customerid: null,
			templateId: null,
			allsearchviews: [],
			filter: {
				job_id: null,
				job_name: null,
				job_type_name: null,
				job_type_id: '',
				job_type_name: null,
				start_date: null,
				end_date: null,
				address: null,
				job_status_id: '',
				job_status_name: null,
				job_manager_id: null,
				job_manager_name: null,
				job_foreman_id: null,
				job_foreman_name: null,
				job_laborer_id: null,
				job_laborer_name: null,
				job_customer_id: null,
				job_customer_name: null,
				job_superintendent_id: null,
				job_superintendent_name: null,
				custom: {},
				superintendent: null,
			},
			totalCount: 0,
			countAllJobs: 0,
			countMyJobs: 0,
			countOverdueJobs: 0,
			countRecentlyClosedJobs: 0,
			search: {
				name: null
			},
			showCloseJobConf: false,
			showJobTempDlt: false,
			showSearchbox: true,
			advancedSearchView: false,
			submittingJobFromTemp: false,
			allcountries: [],
			allstates: [],
			form: {
				add: {
					customer_id: 0,
					superintendent_user_id: 0,
					job_type_id: '',
					job_name: null,
					job_manager_user_id: '',
					start_date: moment().format('MM/DD/YYYY'),
					end_date: moment().format('MM/DD/YYYY'),
					address: null,
					country: 'US',
					address_1: null,
					address_2: null,
					city: null,
					state: '',
					postal_code: null,
					job_status_id: '',
					job_status_name: null,
					superintendent: null,
					customer_address: null
				}
			},
			columns: this.isMobile() ? ['job_number.job_number', 'job_name', 'customer.name', 'total_job_time', 'job_status.name']
				: ['job_number.job_number', 'job_name', 'customer.name', 'job_manager.name', 'job_status.name', 'start_date', 'job_type_name', 'total_job_time', 'actions',],
			options: {
				perPage: 100,
				perPageValues: [100],
				headings: {
					'job_number.job_number': this.$t('label.job id'),
					job_name: this.$t('label.job name'),
					'customer.name': this.$t('label.customer'),
					'job_manager.name': this.$t('label.job manager'),
					'job_status.name': this.$t('label.job status'),
					start_date: this.$t('label.start date'),
					end_date: this.$t('label.due date'),
					total_job_time: this.$t('label.total job time'),
					actions: '',
				},
				editableColumns: ['job_number.job_number', 'job_name', 'customer.name', 'job_manager.name', 'job_status.name', 'start_date', 'end_date', 'total_job_time'],
				sortable: ['job_number.job_number', 'job_name', 'customer.name', 'job_manager.name', 'job_status.name', 'start_date', 'end_date', 'total_job_time'],
				filterable: false,
				columnsClasses: {
					'job_number.job_number': 'text-left sp-va-mid',
					job_name: 'text-left sp-va-mid',
					'customer.name': 'text-left sp-va-mid',
					'job_manager.name': 'text-left sp-va-mid',
					'job_status.name': 'text-left sp-va-mid',
					job_type_name: 'text-left sp-va-mid',
					start_date: 'text-left sp-va-mid',
					end_date: 'text-left sp-va-mid',
					total_job_time: 'text-left sp-va-mid',
					actions: 'text-center actions-column sp-va-mid',
				},
				responseAdapter({ data }) { },
				requestAdapter(data) { },
				rowClassCallback(row) {
					return row;
				},
			},
			customfields: [],
			selectedJobId: null,
			extraFilter: this.isMobile() ? false : true,
			allsuperintendents: [],
			alljobtemplates: [],
			templateData: [],
			customerContactList: [],
			loader: null,
		}
	},
	mounted() {
		this.loadLkupData();

		// Get headers for $tlation
		if ($("#jobs-header-data").length) {
			setTimeout(function () {
				let headers = $('#jobs-header-table .VueTables__heading');
				let header = null;

				for (let x = 0; x < headers.length; x++) {
					header = $('#jobs-header-data').data('column' + x);
					headers[x].textContent = header;
				}
			}, 500);
		}
	},
	methods: {
		loadLkupData() {
			this.loader = this.$loading.show();
			let promises = [
				this.getAllJobTemplates(true),
				this.getCountries(),
				this.getStates(),
				this.getJobTypes(),
				this.getJobStatus(),
				this.getJobPeople(),
				this.getCompanyManagers(),
				this.getCustomers(),
				this.getSearchViews(),
				this.getCustomfields(),
				this.getJobs(),
				this.getCountForWidget()
			];

			Promise.all(promises).then(() => {
				this.loader.hide();
			});
		},
		getStateFromPostalCode() {
			if (this.form.add.postal_code && this.form.add.postal_code.length == 5) {
				return axios.get('/get-state/' + this.form.add.postal_code).then(response => {
					this.form.add.state = response.data.results.abbreviation;
				})
					.catch(err => {
						//console.log('list carriers error:', error)
					});
			}
		},
		getCountries() {
			return axios.get('/get-countries').then(response => {
				this.allcountries = response.data.result;
			})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		getStates() {
			return axios.get('/get-states').then(response => {
				this.allstates = response.data.result;
			})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		getJobTypes() {
			return axios.get('/jobtypes').then(response => {
				this.alljobtypes = response.data.result;
			})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		selectedJobType(data) {
			this.filter.job_type_id = data.id;
			this.filter.job_type_name = data.name;
		},
		selectedJobStatus(data) {
			this.filter.job_status_id = data.id;
			this.filter.job_status_name = data.name;
		},
		selectedManager(data) {
			this.filter.job_manager_id = data.id;
			this.filter.job_manager_name = data.name;
		},
		selectedForeman(data) {
			this.filter.job_foreman_id = data.id;
			this.filter.job_foreman_name = data.name;
		},
		selectedJobLaborer(data) {
			this.filter.job_laborer_id = data.id;
			this.filter.job_laborer_name = data.name;
		},
		selectedCustomer(data) {
			this.filter.job_customer_id = data.id;
			this.filter.job_customer_name = data.name;
		},
		selectedSuperintendent(data) {
			this.filter.job_superintendent_id = data.id;
			this.filter.job_superintendent_name = data.full_name;
		},
		getJobStatus() {
			return axios.get('/jobstatus').then(response => {
				this.alljobstatus = response.data.result;
				for (let i = 0; this.alljobstatus.length > 0; i++) {
					if (this.alljobstatus[i].name == 'Open') {
						this.form.add.job_status_id = this.alljobstatus[i].id;
						break;
					}
				}
			})
				.catch(err => { });
		},
		getJobPeople() {
			return axios.get('/jobpeople').then(response => {
				this.alljobpeople = response.data.result;
			})
				.catch(err => { });
		},
		getCompanyManagers() {
			return axios.get('/company-admins').then(response => {
				this.allManagers = response.data.result;
			}).catch(err => console.log('getCompanyManagers', err));
		},
		getCustomers() {
			return axios.get('/get-customers').then(response => {
				this.allCustomers = response.data.result;
			}).catch(err => console.log('getCustomers in Jobs', err));
		},
		getJobs(widget) {
			this.customfields.forEach(customfield => {
				this.filter.custom[customfield.input_name] = this.form.add[customfield.input_name];
			});

			if (typeof widget !== 'undefined') {
				this.filter.open = null;
				this.filter.mine = null;
				this.filter.overdue = null;
				this.filter.recently_closed = null;
			}

			switch (widget) {
				case 1:
					this.filter.open = true;
					break;
				case 2:
					this.filter.mine = true;
					break;
				case 3:
					this.filter.overdue = true;
					break;
				case 4:
					this.filter.recently_closed = true;
					break;
			}

			var request_fields = this.filter;
			var that = this;
			this.options.requestAdapter = function (data) {
				return {
					limit: data.limit ? data.limit : 5,
					page: data.page,
					custom2: that.filter.custom,
					end_date: that.filter.end_date,
					job_customer_id: that.filter.job_customer_id,
					job_foreman_id: that.filter.job_foreman_id,
					job_id: that.filter.job_id,
					job_laborer_id: that.filter.job_laborer_id,
					job_manager_id: that.filter.job_manager_id,
					job_name: that.filter.job_name,
					job_type_name: that.filter.job_type_name,
					job_type_id: that.filter.job_type_id,
					job_status_id: that.filter.job_status_id,
					job_superintendent_id: that.filter.job_superintendent_id,
					start_date: that.filter.start_date,
					address: that.filter.address,
					superintendent: that.filter.superintendent,
					open: that.filter.open,
					mine: that.filter.mine,
					overdue: that.filter.overdue,
					recently_closed: that.filter.recently_closed,
				}
			}

			this.options.responseAdapter = function ({ data }) {
				that.totalCount = data.count;
				return {
					data: data.result2,
					count: data.result.length
				}
			}

			this.$refs.vueservertable.refresh();
		},
		getCountForWidget() {
			var request_fields = this.filter;
			axios.post('/get-counts-for-widget', request_fields)
				.then(response => {
					this.countAllJobs = response.data.result.countAllJobs;
					this.countMyJobs = response.data.result.countMyJobs;
					this.countOverdueJobs = response.data.result.countOverdueJobs;
					this.countRecentlyClosedJobs = response.data.result.countRecentlyClosedJobs;
				})
				.catch(err => { });
		},
		editExistingJob(jobNumber) {
			this.$router.push({
				path: `jobs/edit/${jobNumber}`
			});
		},
		clearSearch() {
			this.filter.job_id = null;
			this.filter.job_name = null;
			this.filter.job_type_name = null;
			this.filter.start_date = null;
			this.filter.end_date = null;
			this.filter.address = null;
			this.filter.job_status_id = [];
			this.filter.job_status_name = null;
			this.filter.job_status_name = null;
			this.filter.job_type_id = [];
			this.filter.job_manager_id = null;
			this.filter.job_manager_name = null;
			this.filter.job_foreman_id = null;
			this.filter.job_foreman_name = null;
			this.filter.job_laborer_id = null;
			this.filter.job_laborer_name = null;
			this.filter.job_customer_id = null;
			this.filter.job_customer_name = null;
			this.filter.job_superintendent_id = null;
			this.filter.job_superintendent_name = null;
			this.filter.open = null;
			this.filter.mine = null;
			this.filter.overdue = null;
			this.filter.recently_closed = null;
			this.filter.count = null;

			if (this.customfields.length) {
				this.customfields.forEach(customfield => {
					if (Array.isArray(this.form.add[customfield.input_name]))
						this.form.add[customfield.input_name] = [];
					else
						this.form.add[customfield.input_name] = null;
				});

				this.component_bus.$emit('clearForm');
			}
		},
		saveSearchView() {
			var request_fields = {
				'filter': this.filter,
				'name': this.search.name,
				'type': 'jobs'
			};

			axios.post('/save-search-view', request_fields)
				.then(response => {
					this.search.name = '';
					this.getSearchViews();
				})
				.catch(err => { });
			document.querySelector('.close2saveview').click();
		},
		getSearchViews() {
			var request_fields = {
				'type': 'jobs'
			};

			return axios.post('/get-search-views', request_fields).then(response => {
				this.allsearchviews = response.data.result;
			}).catch(err => { });
		},
		useSearchView(data) {
			this.filter.job_id = data.filter.job_id;
			this.filter.job_name = data.filter.job_name;
			this.filter.job_type_name = data.filter.job_type_name;
			this.filter.start_date = data.filter.start_date;
			this.filter.end_date = data.filter.end_date;
			this.filter.address = data.filter.address;
			this.filter.job_type_id = data.filter.job_type_id;
			this.filter.job_status_id = data.filter.job_status_id;
			this.filter.job_manager_id = data.filter.job_manager_id;
			this.filter.job_foreman_id = data.filter.job_foreman_id;
			this.filter.job_laborer_id = data.filter.job_laborer_id;
			this.filter.job_customer_id = data.filter.job_customer_id;
			this.filter.job_superintendent_id = data.filter.job_superintendent_id;
		},
		removeSavedView(index) {
			let removeCheck = confirm("Are you sure to remove this item?");

			if (removeCheck) {
				axios.post('/delete-search-view/' + this.allsearchviews[index].id)
					.then(response => {
						this.getSearchViews();
					})
					.catch(err => {
					});
				this.allsearchviews.splice(index, 1)
			}
		},
		updateSearchViews() {
			var request_fields = {
				'searchviews': this.allsearchviews
			};
			axios.post('/update-search-views', request_fields)
				.then(response => {
					this.getSearchViews();
				})
				.catch(err => { });
			document.querySelector('.close2updateviews').click();
		},
		searchBox() {
			this.showSearchbox = !this.showSearchbox;
		},
		getCustomfields() {
			return axios.post('/loadCustomFieldsByModel', { model_type: 'job' }).then(response => {
				this.customfields = response.data.result;

				let add_data = {}, edit_data = {};
				response.data.result.forEach(cf => {
					add_data[cf.input_name] = null;
					edit_data[cf.input_name] = cf.value;
				});
				this.form.add = { ...this.form.add, ...add_data };
				this.form.edit = { ...this.form.edit, ...edit_data };
			})
		},
		onCustomfieldInput(value, customfield, subForm = 'add') {
			const foundIndex = this.customfields.findIndex(cf => cf.id === customfield.id);
			this.form[subForm][customfield.input_name] = this.customfields[foundIndex].value = value;
		},
		resetCustomFields(subForm = 'add') {
			this.customfields.forEach(customfield => {
				const customfieldValue = customfield.type === 'checkbox' ? [] : '';
				this.onCustomfieldInput(customfieldValue, customfield);
			});
		},

		isMobile() {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				return true
			} else {
				return false
			}
		},
		hideAdvancedSearch() {
			this.advancedSearchView = true;
		},
		showAdvancedSearch() {
			this.advancedSearchView = false;
		},
		hideCloseJobModal() {
			this.selectedJobId = null;
			this.showCloseJobConf = false;
		},
		showCloseJobModal(jobId) {
			this.selectedJobId = jobId;
			this.showCloseJobConf = true;
		},
		quickCloseJob() {
			// Save job status then refresh page data
			axios.post("/close-job", {
				job_id: this.selectedJobId
			}).then(response => {
				this.getCountForWidget();
				this.$refs.vueservertable.refresh();

				bus.$emit('banner-success', {
					message: this.$t('label.')
				});
			}).catch(err => console.log(err));
		},
		quickReopenJob(jobId) {
			// Save job status then refresh page data
			axios.post("/reopen-job", {
				job_id: jobId
			}).then(response => {
				this.getCountForWidget();
				this.$refs.vueservertable.refresh();

				bus.$emit('banner-success', {
					message: this.$t('label.job reopened successfully') + '!'
				});
			}).catch(err => console.log(err));
		},
		getAllJobTemplates(init) {
			return axios.get('/get-active-job-templates').then(response => {
				this.alljobtemplates = response.data.result;

				if (init) {
					$(".templates").ready(function () {
						$('.dropdown-submenu a.test').on("click", function (e) {
							$('.dropdown-submenu a.test').next('div.dropdown-menu').slideUp("fast");
							$(this).next('div.dropdown-menu').toggle();
							e.stopPropagation();
							e.preventDefault();
						});
					});

					$(document).on("click", function (e) {
						$('.dropdown-submenu a.test').next('div.dropdown-menu').slideUp("fast");
					});
				}
			}).catch(err => { });
		},
		useTemplate(data) {
			this.templateData = data;
		},

		closeDeleteTempModal() {
			this.showJobTempDlt = false;
		},
		openDeleteTempModal(j_template) {
			this.templateId = j_template.job_id;
			this.showJobTempDlt = true;
		},
		editExistingJobTemplate(template) {
			this.$router.push({
				path: `admin/contentManagement/jobTemplates/edit/${template.id}`
			});
		},
		createNewJobTemplate() {
			axios.post("/create-job-template").then(response => {
				this.editExistingJobTemplate(response.data.result);
			}).catch(err => console.log(err));
		},
		createJobFromTemplate() {
			// if (!this.submittingJobFromTemp) {
			// 	this.submittingJobFromTemp = true;
			var data = {
				'id': this.templateData.job_id,
				'job_name': this.templateData.job_name
			};

			axios.post('/create-job-from-template', data).then(response => {
				this.editExistingJob(response.data.id);
			}).catch(err => { });
			// }
		},
		deleteJobTemplate() {
			axios.post('/delete-job-template', {
				id: this.templateId
			}).then(response => {
				this.closeDeleteTempModal();
				this.getAllJobTemplates();
				bus.$emit('banner-success', {
					message: this.$t('label.job template deleted successfully') + '!',
				});
			}).catch(err => { });
		},
		loadCustomerContacts() {
			return axios.post('/loadCustomerContacts', {
				customerId: this.form.add.customer_id
			}).then(response => {
				this.customerContactList = response.data;
			}).catch(err => {
				console.log('ERROR: ', err);
				bus.$emit('banner-error', { message: err });
			});
		},
		receiveNewJob(newJob) {
			// console.log('Newly Added job', newJob)
		},
	}
}
</script>

<style scoped>
.dropdown-items {
	line-height: 36px;
}

.dropdown-items:hover {
	background: #f6f9fc;
}
</style>
<style scoped>
.VueTables.VueTables--client>.row:first-child {
	display: none;
}

.dropdown-toggle::after {
	content: none;
}

.dropdown-toggle {
	background-color: transparent;
	border-color: transparent;
	color: #ced4da !important;
	padding: 0;
}

.table-bordered th,
.table-bordered td {
	background-color: #fff;
	border: none;
	border-bottom: 1px solid #e9ecef
}

.table-bordered thead th,
.table-bordered thead td {
	color: #8898aa;
	background-color: #f6f9fc;
}

.VuePagination {
	height: 75px;
	margin: 0;
}

.VuePagination__count {
	position: absolute;
	left: 0;
	top: 25%;
	text-align: left !important;
	font-family: Open Sans, sans-serif;
	font-size: .875rem;
}

li.VuePagination__pagination-item.page-item a.page-link {
	z-index: 2000;
}

.table th,
.table td {
	vertical-align: center;
}

#jobs-header-table {
	overflow-x: hidden;
}

.VuePagination__pagination li:first-child,
.VuePagination__pagination li:last-child {
	display: none;
}
</style>