<template>
    <div v-if="formReady">
        <label class="form-control-label text-muted" :for="customField.input_name">{{ customField.name }}</label>

        <!-- Text Input -->
        <template v-if="customField.type === 'text'">
            <input class="form-control" v-model="customFieldValue" type="text" :name="customField.input_name" :placeholder="customField.name" />
        </template>

        <!-- Dropdown -->
        <template v-else-if="customField.type === 'dropdown'">
            <div class="form-group">
                <div class="dropdown w-100">
                    <div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div class="input-group input-group-alternative input-group-merge search-contacts">
                            <input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + customField.name + ' -'" v-model="customFieldName" autocomplete="input-field" data-lpignore="true" readonly>
                            <div class="input-group-append">
                                <span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
                        <div class="pl-3">
                            <div class="pointer dropdown-items"><span class="customname pointer dropdown-items" :value="null" @click="selectedCustom('');">- {{ customField.name }} -</span></div>
                            <div v-for="(option, idx) in customField.options" :key="idx">
                                <div :value="option.name" class="pointer dropdown-items" @click="selectedCustom(option)">
                                    <span class="customname">{{ option.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <!-- Text Area Box -->
        <template v-else-if="customField.type === 'textarea'">
            <textarea v-model="customFieldValue" :name="customField.input_name" class="form-control" :placeholder="customField.name" rows="3"></textarea>
        </template>

        <!-- Checkbox -->
        <template v-else-if="customField.type === 'checkbox'">
            <br>
            <div v-for="(option, idx) in customField.options" :key="idx">
                <div class="form-check form-check-inline">
                    <input v-model="customFieldValue[idx]" class="form-check-input" type="checkbox" :name="customField.input_name + idx" />
                    <label class="form-check-label" :for="customField.input_name + idx">{{ option.name }}</label>
                </div>
            </div>
        </template>

        <!-- Radio Buttons -->
        <template v-else-if="customField.type === 'radio'">
            <br>
            <div v-for="(option, idx) in customField.options" :key="idx">
                <div class="form-check form-check-inline">
                    <input v-model="customFieldValue" class="form-check-input" type="radio" :name="customField.input_name + idx" :value="option.name" />
                    <label class="form-check-label" :for="customField.input_name + idx">{{ option.name }}</label>
                </div>
            </div>
        </template>
    </div>
</template>

<style></style>

<script>
export default {
    props: ['modelId', 'modelType', 'customField', 'formSave'],
    watch: {
        formSave() {
            if (this.formSave) {
                this.saveModelCustomField();
            }
        }
    },
    data() {
        return {
            formReady: false,
            customFieldValue: null,
            customFieldName: null
        }
    },
    mounted() {
        if (this.customField.input_form_data) {
            this.customFieldValue = this.customField.input_form_data;
            if (this.customField.options) {
                for (let i = 0; i < this.customField.options.length; i++) {
                    if (this.customField.options[i].value == this.customField.input_form_data) {
                        this.customFieldName = this.customField.options[i].name;
                    }
                }
            }
        } else {
            if (this.customField.type === "checkbox") {
                this.customFieldValue = [];
                this.customFieldValue.forEach((val) => val.push(false));
            }
        }

        this.formReady = true;
    },
    methods: {
        saveModelCustomField() {
            let data = {
                'model_id': this.modelId,
                'model_type': this.modelType,
                'custom_field_id': this.customField.id,
                'value_data': this.customFieldValue
            };

            // Load checked checkbox values into array
            if (this.customField.type === "checkbox") {
                data.value_data = [];

                this.customFieldValue.forEach((val, i) => {
                    if (val)
                        data.value_data.push(this.customField.options[i].name);
                });
            }

            axios.post("/save-custom-field-value", data).catch(err => console.log(err));
        },
        selectedCustom(option) {
            this.customFieldName = option.name;
            this.customFieldValue = option.value;
        },
    }
}
</script>