<template>
  <fade-transition origin="center" :duration="250" tag="div">
    <component :is="layout">
      <slot />
    </component>
  </fade-transition>
</template>

<script>
import AppLayoutDefault from '@/layouts/Default';
import FullPageLayout from '@/layouts/FullPage';
import { FadeTransition } from 'vue2-transitions';

export default {
  name: 'AppLayout',
  components: {
    FadeTransition
  },
  data: () => ({
    layout: AppLayoutDefault
  }),
  watch: {
    '$route.meta.layout': {
      immediate: true,
      async handler(layout) {
        if (layout) {
          try {
            this.$store.dispatch('Auth/setRouteGuardLoading', true);
            const component = await import(`@/layouts/${layout}/index.vue`).then((m) => m.default);
            this.layout = component || AppLayoutDefault;
            // this.$store.dispatch('Auth/setRouteGuardLoading', false);
          } catch (e) {
            this.layout = AppLayoutDefault;
            // this.$store.dispatch('Auth/setRouteGuardLoading', false);
          }
        } else {
          var loggedIn = this.$store.getters['Auth/isLoggedIn'];
          if (loggedIn) {
            this.layout = AppLayoutDefault;
          } else {
            this.layout = FullPageLayout;
          }
        }
      }
    }
  }
};
</script>

<style>

</style>
