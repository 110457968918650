<template>
    <div class="mobile-window">
        <div class="calendar-view">
            <div class="card mb-5" >
                <div class="card-header py-3 header-sticky header1">
                    <div class="row align-items-center justify-content-between m-0">
                        <div class="col-md-2 p-0">
                            <h3 class="mb-0 d-flex align-items-center">
                                <i class="ri-calendar-2-line heading-title"></i> {{ $t('label.week') }}
                            </h3>
                        </div>
                        <div class="d-flex align-items-center ml-auto">
                            <div class="pt-2 pb-1 btn-group-light rounded calendar-filter ml-auto" :class="{'btn-group d-block' : windowWidth >= 768, 'mt--3': windowWidth < 768}">
                                <!-- FILTER BY TASK TYPE -->
                                <div class="dropdown" :class="{'mb-3 d-flex ml-auto mt--4' : windowWidth < 768}">
                                    <button type="button" class="d-flex align-items-center btn btn-white text-primary ml-auto" data-toggle="dropdown">
                                        <i class="p-0 ri-checkbox-circle-line"></i>
                                        <span v-if="allTaskTypesSelected">{{ $t('label.all types') }}</span>
                                        <span v-else-if="noTaskTypesSelected">{{ $t('label.types none') }}</span>
                                        <span v-else>{{ $t('label.selected types') }}</span>
                                    </button>

                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" style="overflow-y: scroll; max-height: 300px" @click.stop="">
                                        <!-- Select All Types -->
                                        <a class="dropdown-item text-default pointer" @click="onAllTypesClick()">
                                            <i class="ri-checkbox-circle-line"></i> {{ $t('label.select all') }}
                                        </a>
                                        <!-- Deselect All Types -->
                                        <a class="dropdown-item text-default pointer" @click="onDeselectAllTypesClick()">
                                            <i class="ri-checkbox-blank-circle-line"></i> {{ $t('label.deselect all') }}
                                        </a>

                                        <div class="dropdown-divider"></div>
                                        <a v-for="(d,i) in filterBy.taskTypes" :key="i" class="dropdown-item text-default pointer" @click="selectTypeFilter(d.id)">
                                            <div class="d-flex align-items-center">
                                                <span class="badge badge-dot">
                                                    {{ d.name }}
                                                </span>
                                                <i v-if="d.active" class="ri-checkbox-circle-line ml-auto text-success"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                               
                                <!-- FILTER BY TASK STATUS -->
                                <div class="dropdown" :class="{'mb-3 d-flex ml-auto' : windowWidth < 768}">
                                    <button type="button" class="d-flex align-items-center btn btn-white text-primary ml-auto" data-toggle="dropdown">
                                        <i class="p-0 ri-checkbox-circle-line"></i>
                                        <span v-if="allTaskStatusesSelected">{{ $t('label.all statuses') }}</span>
                                        <span v-else-if="noTaskStatusesSelected">{{ $t('label.statuses none') }}</span>
                                        <span v-else>{{ $t('label.selected statuses') }}</span>
                                    </button>

                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" style="overflow-y: scroll; max-height: 300px" @click.stop="">
                                        <!-- Select All Statuses -->
                                        <a class="dropdown-item text-default pointer" @click="onAllStatusesClick()">
                                            <i class="ri-checkbox-circle-line"></i> {{ $t('label.select all') }}
                                        </a>
                                        <!-- Deselect All Statuses -->
                                        <a class="dropdown-item text-default pointer" @click="onDeselectAllStatusesClick()">
                                            <i class="ri-checkbox-blank-circle-line"></i> {{ $t('label.deselect all') }}
                                        </a>

                                        <div class="dropdown-divider"></div>
                                        <a v-for="(d,i) in filterBy.taskStatuses" :key="i" class="dropdown-item text-default pointer" @click="selectStatusFilter(d.id)">
                                            <div class="d-flex align-items-center">
                                                <span class="badge badge-dot">
                                                    <i :style="`background-color: ${d.status_color}`"></i> {{ d.name }}
                                                </span>
                                                <i v-if="d.active" class="ri-checkbox-circle-line ml-auto text-success"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>


                                <!-- FILTER BY CREWS/USERS -->
                                <div class="dropdown" :class="{'mb-3 d-flex ml-auto' : windowWidth < 768}">
                                    <button type="button" class="btn btn-white text-primary ml-auto" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="ri-group-line"></i>
                                        <span v-if="crewOrUser == 1">
                                            <span v-if="filterBy.allCrews">{{ $t('label.all crews') }}</span>
                                            <span v-else>{{ $t('label.selected crews') }}</span>
                                        </span>
                                        <span v-else>
                                            <span v-if="filterBy.allUsers">{{ $t('label.all users') }}</span>
                                            <span v-else>{{ $t('label.selected users') }}</span>
                                        </span>
                                        <i class="ri-arrow-down-s-fill"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow filterUsers" @click.stop="">
                                        <div class="d-flex justify-content-between px-3 py-1">
                                            <div class="custom-control custom-radio">
                                                <input name="crews" class="custom-control-input" value="1" id="crews" type="radio" v-model="crewOrUser" @change="toggleCrewUserFilter('crews')">
                                                <label class="custom-control-label" for="crews">{{ $t('label.crews') }}</label>
                                            </div>
                                            <div class="custom-control custom-radio">
                                                <input name="users" class="custom-control-input" value="2" id="users" type="radio" v-model="crewOrUser" @change="toggleCrewUserFilter('users')">
                                                <label class="custom-control-label" for="users">{{ $t('label.users') }}</label>
                                            </div>
                                        </div>
                                        <div class="dropdown-divider"></div>
                                        <!-- CREWS -->
                                        <template v-if="crewOrUser == 1">
                                            <a class="dropdown-item text-default">
                                                <label class="d-flex align-items-center form-control-label text-muted font-weight-normal m-0 w-100 pointer" for="all-crews">
                                                    <input @change="selectCrewFilter('All')" class="form-control-input" id="all-crews" type="checkbox" value="allcrews" v-model="filterBy.allCrews">
                                                    &nbsp;{{ $t('label.all crews') }}
                                                </label>
                                            </a>
                                            <div class="dropdown-divider"></div>
                                            <h4 class="px-3 py-3 m-0">{{ $t('label.crews') }}</h4>
                                            <a v-for="(d,i) in filtersList.crews" :key="'crew-'+i" class="dropdown-item text-default">
                                                <label class="d-flex align-items-center form-control-label text-muted font-weight-normal m-0 w-100 pointer" :for="'filter-'+d.id">
                                                    <input @change="selectCrewFilter(d)" class="form-control-input" :id="'filter-'+d.id" type="checkbox" :value="d.id" v-model="d.filteredBy">
                                                    &nbsp;{{ d.name }}
                                                </label>
                                            </a>
                                        </template>
                                        <!-- USERS -->
                                        <template v-if="crewOrUser == 2">
                                            <a class="dropdown-item text-default">
                                                <label class="d-flex align-items-center form-control-label text-muted font-weight-normal m-0 w-100 pointer" for="all-users">
                                                    <input @change="selectUserFilter('All')" class="form-control-input" id="all-users" type="checkbox" :value="true" v-model="filterBy.allUsers">
                                                    &nbsp;{{ $t('label.all users') }}
                                                </label>
                                            </a>
                                            <div class="dropdown-divider"></div>
                                            <h4 class="px-3 py-3 m-0">{{ $t('label.assignees') }}</h4>
                                            <a v-for="(d,i) in filtersList.users" :key="'user-'+i" class="dropdown-item text-default">
                                                <label class="d-flex align-items-center form-control-label text-muted font-weight-normal m-0 w-100 pointer" :for="'filter-'+d.id">
                                                    <input @change="selectUserFilter(d)" class="form-control-input" :id="'filter-'+d.id" type="checkbox" :value="d.id" v-model="d.filteredBy" >
                                                    &nbsp;{{ d.name }}
                                                </label>
                                            </a>
                                        </template>
                                        <a class="dropdown-item text-default pointer text-center mt-3" @click="deselectAll()">
                                            <h4 class="m-0">{{ $t('label.deselect all') }}</h4>
                                        </a>
                                    </div>
                                </div>
                                <!-- SORT BY -->
                                <div class="dropdown" :class="{'d-flex ml-auto' : windowWidth < 768}">
                                    <button type="button" class="btn btn-white text-primary ml-auto" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="ri-arrow-up-down-line"></i>{{ $t('label.sort') }}: {{ sortBy }}<i class="ri-arrow-down-s-fill"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                        <a v-for="(d,i) in sortList" :key="'sort-'+i" class="dropdown-item text-default pointer" @click="selectSortBy(d)">{{ d }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pt-3 mx--4 border-bottom"></div>
                    <div class="mt-2 mb--2 mx-0 px-0 row align-items-center justify-content-between m-0">
                        <!-- Change Date View -->
                        <div class="col p-0">
                            <div class="row align-items-center m-0">
                                <div class="col-auto p-0">
                                    <button type="button" class="btn btn-default text-white text-uppercase px-3 py-1" @click="today">{{ $t('label.go to today') }}</button>
                                </div>
                                <div class="col-auto p-0 pl-3">
                                    <i class="ri-arrow-left-s-line heading-title pointer" @click="prevDate"></i>
                                    <i class="ri-arrow-right-s-line heading-title pointer"  @click="nextDate"></i>
                                </div>
                                <div class="col-auto">
                                    <div class="d-flex align-items-center">
                                        <date-picker v-model="selectedDate" 
                                            :clearable="false"
                                            class="full-width"
                                            format="dddd, DD MMMM YYYY"
                                            :placeholder="$t('label.select date')"
                                            @change="reloadTasksWithOverlay">
                                                <template v-slot:input>
                                                    <span class="pointer d-flex align-items-center">
                                                        <i class="ri-calendar-2-line heading-title"></i>
                                                        {{ textDate }}
                                                        <i class="ri-arrow-down-s-line heading-title"></i>
                                                    </span>
                                                </template>
                                                <template v-slot:icon-calendar><span></span></template>
                                        </date-picker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Search Bar -->
                        <div class="col-12 col-sm-3 mx--3 py-2">
                            <div class="search-schedule">
                                <i class="ri-search-line"></i>
                                <input class="form-control" type="text" v-model="searchText" autocomplete="off" :placeholder="$t('label.search schedule')">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- DISPLAY TASKS -->
                <div class="card-body p-0 pb-3 position-relative scroll-view">
                    <div class="week-length-min" id="weekCalendarBody">
                        <div class="d-flex mt--1 mb-1 border-top mt-3" style="line-height: 5px; position: sticky; top: 0;">
                            <div></div>
                            <div v-for="(date, index) in dateRange" :key="index" class="px-0 mt--1 mx-0 py-3 d-flex text-center justify-content-center flex-grow-1 border-col1 position-relative">
                                <div v-if="moment().format('MM/DD/YYYY') == date" class="border-today pt-1 mt-1"> </div>
                                <div class="px-0 mx-0 text-center justify-content-center day-header-w">
                                    <span :class="moment().format('MM/DD/YYYY') == date ? 'today-text' : ''">{{ moment(date).format('ddd, MM/DD') }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- Task Rows -->
                        <div v-if="filteredTasks.length">
                            <div v-for="(d, index) in filteredTasks" class="d-flex task-list" :key="index" :id="'row'+index">
                                <!-- Day Bins -->
                                <div v-for="(dayBin, bIdx) in dateRange" :key="bIdx" class="day-h pl-2 d-flex flex-grow-1 border-col1">
                                    <span v-if="d.dayBin == bIdx" :id="'taskRow'+d.id" class="m-0 pt-1 position-absolute">
                                        <TaskItem view="week"
                                            :task="d"
                                            :dateRange="dateRange"
                                            :daysToOverlay="daysToOverlay(d, dayBin)"
                                            @showEditDatesModal="showEditDateModal"
                                            @parentSaveTaskDatesFnc="updateTaskDates">
                                        </TaskItem>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- No Tasks View -->
                        <div v-else class="bg-white text-center row align-items-center m-0">
                            <i class="ri-todo-line ni-8x w-100 color-gray-400"></i>
                            <p class="w-100 font-weight-normal">{{ $t('label.No Tasks have been scheduled for this week') }}.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .scroll-view {
        overflow-x: auto; 
        width: auto;
    }
    .week-length-min {
        min-width: 1225px
    }
    .day-h {
        height: 118px
    }
    .day-header-w {
        width: 120px
    }

    @media only screen and (max-width: 768px) {
        .mobile-window {
            margin-top: 300px !important;
        }
    }
</style>

<script>
    import bus from '@/utils/event-bus';;
    import { mapGetters } from 'vuex';
    import TaskItem from '../template/TaskItem.vue';
    import TimeOffItem from '../template/TimeOffItem.vue';
    import EditTaskDatesModal from '@/components/Tasks/EditTaskDatesModal.vue';

    export default {
        props: [],
        emits: ['closeModal'],
        components: {
            TaskItem,
            TimeOffItem,
            EditTaskDatesModal,
        },
        data() {
			return {
                companySettings: JSON.parse(localStorage.getItem('company_settings')),
                user: JSON.parse(localStorage.getItem('user')),
                sortBy: this.$t('label.crew name (A-Z)'),
                selectedDate: moment().toDate(),
                windowWidth: window.innerWidth,
                filtersList: {
                    crews: [],
                    users: []
                },
                filterBy: {
                    crews: [],
                    users: [],
                    taskTypes:[],
                    taskStatuses: [],
                    allCrews: true,
                    allUsers: false,
                },
                sortList: [
                    this.$t('label.none'),
                    this.$t('label.crew name (A-Z)'), 
                    this.$t('label.crew name (Z-A)'),
                    this.$t('label.most hours scheduled'),
                    this.$t('label.least hours scheduled')
                ],
                dateRange: [],
                allTaskTypes: [],
                crewOrUser: 1,
                searchText: '',
                loader: null,
                selectedTask: null,
                drag: false,
                twoDays: false,
                showWeekend: true,
                expandedView: true,
                showWeekend2: true,
                editDatesModalVisible: false,
			}
		},
        watch: {
            filteredTasks() {
                this.renderCalendar();
            }
        },
        computed: {
            ...mapGetters({
                allUsers:'userData/allUsers',
                allCrews:'userData/allCrews',
                allTaskStatus:'taskData/allTaskStatus',
                allTasksByDateRange:'taskData/allTasksByDateRange',
                allTimeOffsByDateRange:'taskData/allTimeOffsByDateRange',
            }),

            textDate() {
                return moment(this.selectedDate).format('dddd, DD MMMM YYYY')
            },

            noTaskStatusesSelected() {
                // Check to see if no task status is set
                let count = 0;
                this.filterBy.taskStatuses.forEach(status => status.active == true ? count++ : count += 0);
                return count == 0 ? true : false;
            },
            allTaskStatusesSelected() {
                // Check to see if all task status filters are active
                let allSet = true;
                this.filterBy.taskStatuses.forEach((status) => {
                    if(!status.active) {
                        allSet = false;
                        return;
                    }
                });

                return allSet;
            },
            noTaskTypesSelected() {
                // Check to see if no type status is set
                let count = 0;
                this.filterBy.taskTypes.forEach(status => status.active == true ? count++ : count += 0);
                return count == 0 ? true : false;
            },
            allTaskTypesSelected() {
                // Check to see if all task status filters are active
                let allSet = true;
                this.filterBy.taskTypes.forEach((status) => {
                    if(!status.active) {
                        allSet = false;
                        return;
                    }
                });

                return allSet;
            },

            filteredTasks() {
                if(this.searchText.length > 0) {
                    // Filter the tasks by Search Bar text
                    let str = this.searchText.toLowerCase(),
                        filteredTasks = [];
                    
                    this.allTasksByDateRange.forEach(task => {
                        // Search name of Task, Job, Customer, and Crew
                        if(task.name.toLowerCase().includes(str) ||
                            task.job.job_name.toLowerCase().includes(str) ||
                            task.task_status.name.toLowerCase().includes(str) ||
                            task.job.customer.name.toLowerCase().includes(str)) {
                                filteredTasks.push(task);
                        } else if(task.assigned_to.length) { 
                            // Search Crew 
                            if(task.assigned_to[0].user.crew_assignments.length) {
                                if(task.assigned_to[0].user.crew_assignments[0].crew.name.toLowerCase().includes(str)) 
                                    filteredTasks.push(task);
                            } 
                            task.assigned_to.forEach(user => {
                                if (user.user.name.toLowerCase().includes(str)) {
                                    let filtId = filteredTasks.findIndex((filtTask) => {
                                        filtTask.id == task.id
                                    });
                                    
                                    if(filtId < 0)
                                        filteredTasks.push(task);
                                }
                            });
                        }
                    });

                    return filteredTasks;
                } else {
                    return this.allTasksByDateRange;
                }
            }
        },
        created() {
            // Align Task block dimensions on window resize
            window.addEventListener("resize", this.setAllDraggablesWidths);
            // Listen for task off canvas updates
            bus.$on('taskOffCanvasUpdate', this.reloadTasks);
        },
        mounted() {

            moment.tz.setDefault(this.user.timezone);
            this.loadLkupData();
        },
        destroyed() {
            // Remove Event Listeners
            window.removeEventListener("resize", this.setAllDraggablesWidths);
            bus.$off('taskOffCanvasUpdate', this.reloadTasks);
        },
        methods: {
            loadLkupData() {
                this.getWeek();
                let promises = [],
                    request_data = {
                        date: [
                            this.dateRange[0],
                            this.dateRange[6]
                        ]
                    };

				this.loader = this.$loading.show();
                promises.push(this.getAllTaskTypes());
				promises.push(this.$store.dispatch('userData/getAllCrews'));
				promises.push(this.$store.dispatch('userData/getAllUsers'));
				promises.push(this.$store.dispatch('taskData/getAllTaskStatus'));
				promises.push(this.$store.dispatch('taskData/getAllTasksByDateRange', request_data));
				// Hide the load overlay and set filter lists
				Promise.all(promises).then(()=>{
                    // Set filter list of selectable users
                    this.allUsers.forEach(user => {
                        this.filtersList.users.push({id: user.id, name: user.name, filteredBy: false});
                    });
                    // Set filter list of selectable crews
                    this.allCrews.forEach(crew => {
                        this.filtersList.crews.push({id: crew.id, name: crew.name, filteredBy: false});
                    });

                    // Set filter list of selectable task statuses
                    this.filterBy.taskStatuses = JSON.parse(JSON.stringify(this.allTaskStatus));
                    this.setAllTaskStatuses(true);
                    // Set filter list of selectable task types
                    this.filterBy.taskTypes = JSON.parse(JSON.stringify(this.allTaskTypes));
                    this.setAllTaskTypes(true);

					this.loader.hide();
                    this.renderCalendar();
				});
			},
            async reloadTasks() {
                this.getWeek();
                let request_data = {
                        date: [
                            this.dateRange[0],
                            this.dateRange[6]
                        ],
                        filters: this.filterBy
                    };

				return this.$store.dispatch('taskData/getAllTasksByDateRange', request_data)
                    .then(() => {
                        this.renderCalendar()
                    });
            },
            reloadTasksWithOverlay() {
                this.loader = this.$loading.show();
                this.reloadTasks().then(() => this.loader.hide());
            },

            async getAllTaskTypes() {
                return axios.get(`tasktypes`).then(rsp => {
                    this.allTaskTypes = rsp.data.result;
                }).catch(err => console.error(err));
            },

            daysToOverlay(task, dayBin) {
                if(task.start_date && task.due_date) {
                    // Set the number of days that the task will overlay
                    let daysToOverlay = moment(moment(task.due_date).format('MM/DD/YYYY')).diff(dayBin, 'days') + 1,
                        tskToEndOfWk = moment(moment(this.dateRange[6]).format('MM/DD/YYYY')).diff(dayBin, 'days') + 1;
    
                    // Set daysToOverlay to be within the week range
                    if (daysToOverlay > tskToEndOfWk)
                        daysToOverlay = tskToEndOfWk;
    
                    return (daysToOverlay > 7) ? 7 : daysToOverlay;
                } else {
                    // If missing either the start_date or due_date, 
                    // treat the task as a single day task. 
                    return 1;
                }
            },
            findTaskStartBin(task) {
                // Return the dayBin that the task start day falls on
                let compareState = (task.start_date) ? moment(task.start_date).format('MM/DD/YYYY') : moment(task.due_date).format('MM/DD/YYYY'),
                    dayBin = this.dateRange.findIndex(d => d == compareState);

                return (dayBin != -1) ? dayBin : 0; 
            },
            setAllDraggablesWidths() {
                this.windowWidth = window.innerWidth;

                // Set all task widths
                this.filteredTasks.forEach(t => {
                    this.setDraggableWidth(t, this.dateRange[t.dayBin]);
                });
            },
            setDraggableWidth(task, dayBin) {
                // Set task width based on how many days it spans 
                // and keep each row dynamic with the page width.
                this.$nextTick(() => {
                    let cldrElm = document.getElementById('weekCalendarBody').getBoundingClientRect(),
                        taskRowElm = document.getElementById('taskRow'+task.id);

                    taskRowElm.style.width = ((cldrElm.width / 7) * this.daysToOverlay(task, dayBin) - 17) + "px";
                });
            },
            renderCalendar() {
                // Render the calendar
                this.filteredTasks.forEach((t) => {
                    this.$set(t, 'dayBin', this.findTaskStartBin(t));
                    this.setDraggableWidth(t, this.dateRange[t.dayBin]);
                });
            },
            
            getWeek() {
                this.dateRange = [];
                this.week_start = moment(this.selectedDate).startOf('week');
                for(let i = 0 ; i < 7 ; i++)
                    this.dateRange.push(moment(this.week_start).add(i,'days').format('MM/DD/YYYY'));
            },
            today() {
                this.selectedDate = moment().toDate();
                this.reloadTasksWithOverlay();
            },
            prevDate() {
                this.selectedDate = moment(this.selectedDate).subtract(7, "days").format('MM/DD/YYYY');
                this.reloadTasksWithOverlay();
            },
            nextDate() {
                this.selectedDate = moment(this.selectedDate).add(7, "days").format('MM/DD/YYYY');
                this.reloadTasksWithOverlay();
            },

            showModalMoveDate() {
                this.showModalMoveDateConfirmation = !this.showModalMoveDateConfirmation;
			},
            showEditDateModal(task) {
                this.selectedTask = task;
                this.editDatesModalVisible = true;
            },
            updateTaskDates(taskData, fullReload) {
                let requestData = {
                    task_data: taskData,
                    date_range: this.dateRange
                };

                this.$store.dispatch('taskData/updateTaskDates', requestData).then(() => {
                    if (fullReload)
                        this.reloadTasksWithOverlay();
                    else 
                        this.renderCalendar();

                    bus.$emit('banner-success', {message: this.$t('label.task dates updated successfully')});
                });
            },

            selectSortBy(sortBy) {
                this.sortBy = sortBy;
                if (sortBy == this.$t('label.none')) {
                    // Return to original state
                    this.reloadTasksWithOverlay();
                } else {
                    // Sort by specified field
                    let newTasksList = this.allTasksByDateRange;

                    newTasksList.sort((a,b) => {
                        if (sortBy == $t('label.crew name (A-Z)')) {
                            if(a.assigned_to.length && b.assigned_to.length) {
                                let nameA = a.assigned_to[0].user.crew_assignments.length ? a.assigned_to[0].user.crew_assignments[0].crew.name : '',
                                    nameB = b.assigned_to[0].user.crew_assignments.length ? b.assigned_to[0].user.crew_assignments[0].crew.name : '';
                                if (nameA == nameB) return 0;
                                return (nameA >= nameB) ? 1 : -1;
                            } else if (!a.assigned_to.length) {
                                return -1; // Return unassigned back to the top
                            }
                        } else if (sortBy == this.$t('label.crew name (Z-A)')) {
                            if(a.assigned_to.length && b.assigned_to.length) {
                                let nameA = a.assigned_to[0].user.crew_assignments.length ? a.assigned_to[0].user.crew_assignments[0].crew.name : '',
                                    nameB = b.assigned_to[0].user.crew_assignments.length ? b.assigned_to[0].user.crew_assignments[0].crew.name : '';
                                if (nameA == nameB) return 0;
                                return (nameA <= nameB) ? 1 : -1;
                            } else if (!a.assigned_to.length) {
                                return -1; // Return unassigned back to the top
                            }
                        } else if (sortBy == this.$t('label.most hours scheduled')) {
                            if(parseInt(a.time_estimate) == parseInt(b.time_estimate)) return 0;
                            else return (parseInt(a.time_estimate) < parseInt(b.time_estimate)) ? 1 : -1;
                        } else if (sortBy == this.$t('label.least hours scheduled')) {
                            if(parseInt(a.time_estimate) == parseInt(b.time_estimate)) return 0;
                            else return (parseInt(a.time_estimate) > parseInt(b.time_estimate)) ? 1 : -1;
                        }
                    });

                    this.$store.commit('taskData/setAllTasksByDateRange', newTasksList);
                }
            },

            onAllStatusesClick() {
                this.setAllTaskStatuses(true);
                this.reloadTasksWithOverlay();
            },
            onDeselectAllStatusesClick() {
                this.setAllTaskStatuses(false);
                this.reloadTasksWithOverlay();
            },
            onAllTypesClick() {
                this.setAllTaskTypes(true);
                this.reloadTasksWithOverlay();
            },
            onDeselectAllTypesClick() {
                this.setAllTaskTypes(false);
                this.reloadTasksWithOverlay();
            },

            setAllTaskStatuses(active) {
                // Set all the task statuses to active
                this.filterBy.taskStatuses.forEach((status) => {
                    this.$set(status, 'active', active);
                });
            },
            setAllTaskTypes(active) {
                // Set all the task statuses to active
                this.filterBy.taskTypes.forEach((status) => {
                    this.$set(status, 'active', active);
                });
            },
            
            selectStatusFilter(id) {
                let statIdx = this.filterBy.taskStatuses.findIndex((status) => {
                    return status.id == id;
                });

                // Toggle active status
                if(statIdx >= 0)
                    this.filterBy.taskStatuses[statIdx].active = !this.filterBy.taskStatuses[statIdx].active;

                this.reloadTasksWithOverlay();
            },
            selectTypeFilter(id) {
                let statIdx = this.filterBy.taskTypes.findIndex((status) => {
                    return status.id == id;
                });

                // Toggle active status
                if(statIdx >= 0)
                    this.filterBy.taskTypes[statIdx].active = !this.filterBy.taskTypes[statIdx].active;

                this.reloadTasksWithOverlay();
            },

            selectUserFilter(filterData) {
                // Filter between all or selected users
                if(filterData == "All") {
                    this.filterBy.users = [];
                    this.filtersList.users.forEach(u => u.filteredBy = false);
                } else if(filterData.filteredBy) {
                    this.filterBy.users.push(filterData.id);
                    this.filterBy.allUsers = false;
                } else if(!filterData.filteredBy) { 
                    let idx = this.filterBy.users.indexOf(filterData.id);
                    if (idx > -1) this.filterBy.users.splice(idx, 1);
                }

                // Don't show any tasks if no users are selected
                if(!this.filterBy.allUsers && !this.filterBy.users.length)
                    this.$store.commit('taskData/setAllTasksByDateRange', []);
                else
                    this.reloadTasksWithOverlay();
            },
            selectCrewFilter(filterData) {
                // Filter between all or selected crews
                if(filterData == "All") {
                    this.filterBy.crews = [];
                    this.filtersList.crews.forEach(c => c.filteredBy = false);
                } else if(filterData.filteredBy) {
                    this.filterBy.crews.push(filterData.id);
                    this.filterBy.allCrews = false;
                } else if(!filterData.filteredBy) {
                    let idx = this.filterBy.crews.indexOf(filterData.id);
                    if (idx > -1) this.filterBy.crews.splice(idx, 1);
                }

                // Don't show any tasks if no crews are selected
                if(!this.filterBy.allCrews && !this.filterBy.crews.length)
                    this.$store.commit('taskData/setAllTasksByDateRange', []);
                else
                    this.reloadTasksWithOverlay();
            },
            toggleCrewUserFilter(type) {
                if(type == "crews") {
                    this.filterBy.users  = [];
                    this.filterBy.allCrews = true;
                    this.filtersList.users.forEach(c => c.filteredBy = false);
                } else if (type == "users") {
                    this.filterBy.crews  = [];
                    this.filterBy.allUsers = true;
                    this.filtersList.crews.forEach(c => c.filteredBy = false);
                }

                this.reloadTasksWithOverlay();
            },
            deselectAll() {
                this.filterBy.crews = [];
                this.filterBy.users = [];
                this.filterBy.allCrews = false;
                this.filterBy.allUsers = false;
                this.filtersList.crews.forEach(c => c.filteredBy = false);
                this.filtersList.users.forEach(u => u.filteredBy = false);
                this.$store.commit('taskData/setAllTasksByDateRange', []);
            }
        },
    };
</script>
