<template>
    <div class="card">
        <!-- Header -->
        <div class="card-header" :class="{ 'border-0': listAssetComments.length }">
            <div class="row align-items-center justify-content-between">
                <div class="col-8">
                    <h3 class="mb-0 d-flex align-items-center">
                        <i class="ri-message-2-line heading-title"></i>
                        {{ $t('label.comments') }}
                    </h3>
                </div>
                <div class="col-4 text-right">
                    <button type="button" class="btn btn-sm btn-default m-0" @click="editOrAddComment()" data-toggle="modal" data-target="#commentModal">
                        {{ $t('label.add comment') }}
                    </button>
                </div>
            </div>
        </div>
        <div v-if="assetComments.length == 0">
            <div class="card-body m-0 py-0">
                <div class="bg-white text-center row align-items-center m-0">
                    <i class="ri-draft-line ni-8x w-100 color-gray-400"></i>
                    <p class="w-100 font-weight-normal">{{ $t('label.no comments have been added to this asset') }}.</p>
                </div>
            </div>
        </div>

        <!-- Body -->
        <div class="card-body p-0" v-if="assetComments.length > 0">
            <!-- Comments List -->
            <div>
                <!-- List of Comments-->
                <div class="list-group-item list-group-item-action px-4" :class="{ 'py-4': showLess, 'py-3': !showLess }" v-for="(item, index) in listAssetComments" v-bind:key="index">
                    <div class="row align-items-center justify-content-between m-0">
                        <!-- User Info -->
                        <div class="col p-0 m-0 d-flex align-items-center">
                            <div class="cursor-pointer mr-2" @click="getUserInfo(item.user.id, 'Created By')">
                                <img class="rounded-circle" :src="item.user.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                            </div>
                            <h4 class="cursor-pointer m-0 mr-2" @click="getUserInfo(item.user.id, 'Created By')">{{ item.user.name }}</h4>
                            <h4 class="font-weight-normal m-0 mr-2">{{ item.created_at }}</h4>
                        </div>

                        <!-- Edit and Delete Buttons -->
                        <div class="col-auto p-0 m-0 d-flex" v-if="(user.role_id == 2)">
                            <h4><a href="#" class="text-primary font-weight-normal mr-3" data-toggle="modal" data-target="#commentModal" @click="editOrAddComment(item)">{{ $t('label.edit') }}</a></h4>
                            <h4><a href="#" class="text-primary font-weight-normal" data-toggle="modal" data-target="#deleteCommentModal" @click="setCommentToDelete(item)">{{ $t('label.delete') }}</a></h4>
                        </div>
                        <div class="col-auto p-0 m-0 d-flex" v-if="(user.role_id != 2) && (item.user.role_id == user.role_id)">
                            <h4><a href="#" class="text-primary font-weight-normal mr-3" data-toggle="modal" data-target="#commentModal" @click="editOrAddComment(item)">{{ $t('label.edit') }}</a></h4>
                            <h4><a href="#" class="text-primary font-weight-normal" data-toggle="modal" data-target="#deleteCommentModal" @click="setCommentToDelete(item)">{{ $t('label.delete') }}</a></h4>
                        </div>
                    </div>
                    <!-- Comment -->
                    <p class="text-sm mb-0 ml-5 mt-3 font-weight-normal pre-wrap" v-html="item.comment"></p>
                </div>

                <!-- View More or Less -->
                <button class="btn btn-white text-primary mr-auto ml-auto my-4 d-flex" v-if="assetComments.length > 5 && showLess" @click="setCommentListSize(true)">
                    {{ $t('label.view more') }}
                </button>
                <button class="btn btn-white text-primary mr-auto ml-auto my-4 d-flex" v-if="assetComments.length > 5 && !showLess" @click="setCommentListSize(false)">
                    {{ $t('label.view less') }}
                </button>
            </div>
        </div>

        <!-- Create or Edit Comment Modal -->
        <div class="modal fade" id="commentModal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <!-- Header -->
                    <div class="modal-header border-bottom">
                        <h5 class="modal-title" id="commentTitle">{{ $t('label.add comment') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>

                    <!-- Comment Entry -->
                    <div autocomplete="off" class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <at-ta id="at-ta" :members="allUsers" name-key="name">
                                        <div id="templateId" slot="item" slot-scope="s">
                                            <span v-html="s.item.name" @click="selectedName(s.item)"></span>
                                        </div>
                                        <textarea id="commentField" v-model="newComment.comment" name="notes" class="form-control" :placeholder="$t('label.write a comment') + '...'" rows="10" required autofocus></textarea>
                                    </at-ta>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Submit and Cancel -->
                    <div class="modal-footer border-top">
                        <button type="button" class="btn btn-primary mr-auto" data-dismiss="modal" @click="saveComment()">{{ newComment.id ? $t('label.update comment') : $t('label.add comment') }}</button>
                        <button type="button" class="btn btn-link ml-auto close2addnote" data-dismiss="modal">{{ $t('label.close') }}</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Delete Comment Modal -->
        <div class="modal fade" id="deleteCommentModal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-danger modal-dialog-centered" role="document">
                <div class="modal-content bg-gradient-danger">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">
                            <span>×</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="py-3 text-center">
                            <i class="ri-error-warning-line ni-3x"></i>
                            <h4 class="heading mt-4 text-uppercase">{{ $t('label.ARE YOU SURE YOU WANT TO DELETE THIS NOTE') }}?</h4>
                            <p>{{ $t('label.This action cannot be undone') }}!</p>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-white" data-dismiss="modal" @click="deleteComment()">{{ $t('label.Yes, Delete') }}</button>
                        <button type="button" class="btn btn-link text-white ml-auto" data-dismiss="modal">{{ $t('label.cancel') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import bus from '@/utils/event-bus';
import * as $ from 'jquery';
// import At from 'vue-at';
import AtTa from 'vue-at/dist/vue-at-textarea';

export default {
    components: {
        // At,
        AtTa
    },
    props: ['assetId', 'assetType'],
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            allUsers: [],
            assetComments: [],
            selectedNames: [],
            listAssetComments: [],
            newTaggedUser: {},
            selectedComment: {},
            assetApi: "/",
            deleteId: null,
            showLess: true,
            addComment: false,
            newComment: {
                comment: ""
            },
            columns: ['notes', 'created_by.name', 'created_at', 'actions'],
            options: {
                perPageValues: [],
                filterable: false,
                sortable: ['notes', 'created_by.name', 'created_at'],
                editableColumns: ['notes', 'created_by.name', 'created_at'],
                headings: {
                    actions: '',
                    notes: this.$t('label.notes'),
                    'created_by.name': this.$t('label.created by'),
                    created_at: this.$t('label.date added'),
                },
                columnsClasses: {
                    notes: 'text-left w-50',
                    created_at: 'text-left',
                    'created_by.name': 'text-left',
                    actions: 'text-center actions-column'
                },
            },
        }
    },
    mounted() {
        if (this.assetType === "vehicle")
            this.assetApi += "vehicle-comments/";
        else if (this.assetType === "item")
            this.assetApi += "asset-item-comments/";

        this.getAllUsers();
        this.getAssetComments();
    },
    methods: {
        getAllUsers() {
            axios.get('/get-all-users').then(response => {
                response.data.result.forEach((data, key) => {
                    this.allUsers.push({
                        name: data.name,
                        id: data.id
                    });
                });
            }).catch(err => console.error(err));
        },
        getAssetComments() {
            axios.get(this.assetApi + "get-comments/" + this.assetId).then(response => {
                this.assetComments = response.data;
                bus.$emit('get-comment-count', this.assetComments.length);
                this.setCommentListSize(false);
            }).catch(err => console.error(err));
        },

        editOrAddComment(comment = null) {
            // Set up newComment for create or edit
            if (comment === null) {
                this.newComment = {
                    comment: ""
                };
            } else {
                this.selectedNames = JSON.parse(comment.tagged_users);
                this.newComment = {
                    id: comment.id,
                    comment: comment.comment.replace(/<\/?[^>]+>/ig, "")
                };
            }
        },

        setCommentListSize(showMore) {
            // Alter list size if it's expanded
            if (showMore)
                this.listAssetComments = this.assetComments;
            else
                this.listAssetComments = this.assetComments.slice(0, 5);

            this.showLess = !showMore;
        },

        saveComment() {
            let saveApi = this.assetApi,
                commentToSave = {
                    'comment': this.newComment.comment,
                    'asset_id': this.assetId,
                    'tagged_users': []
                };;

            // Create or Update Comment
            if (this.newComment.id) {
                saveApi += "update";
                commentToSave.id = this.newComment.id;
            } else {
                saveApi += "store";
            }

            // Attach any users mentioned in comment to the comment 
            commentToSave.comment = $("#commentField").val();
            this.allUsers.forEach(data => {
                if (commentToSave.comment.includes(data.name)) {
                    this.selectedName(data);
                }
            });
            commentToSave.tagged_users = this.selectedNames;

            axios.post(saveApi, commentToSave).then(response => {
                // Push new comment onto list
                if (this.newComment.id) {
                    this.assetComments.forEach(data => {
                        if (data.id === this.newComment.id)
                            data.comment = response.data.comment;
                    });
                } else {
                    this.assetComments.splice(0, 0, response.data);
                    this.setCommentListSize(!this.showLess);
                }

                bus.$emit('get-comment-count', this.assetComments.length);
                bus.$emit('banner-success', {
                    message: this.$t('label.comment saved successfully') + '!'
                });

            }).catch(err => console.log(err));
        },
        setCommentToDelete(comment) {
            this.selectedComment = comment;
        },
        deleteComment() {
            axios.post(this.assetApi + "destroy", {
                id: this.selectedComment.id
            }).then(response => {
                // Remove comment from list
                for (let i = 0; i < (this.assetComments).length; i++) {
                    if (this.assetComments[i].id === this.selectedComment.id)
                        this.assetComments.splice(i, 1);
                }
                this.setCommentListSize();

                bus.$emit('banner-success', {
                    message: this.$t('label.comment saved successfully') + '!'
                });

            }).catch(err => console.log(err));
        },

        // Functions for setting up tagged users data from at component
        selectedName(data) {
            if (this.findArray(data.id) === null)
                this.selectedNames.push(data);
        },
        findArray(value) {
            let countLayer = this.selectedNames.length;
            if (countLayer > 0) {
                for (var i = 0; i < countLayer; i++) {
                    if (this.selectedNames[i].id === value) {
                        return this.selectedNames[i];
                    }
                }
            }
            return null;
        }
    }
}
</script>

<style>
.atwho-view {
    top: 30px;
    position: relative;
}

.editor {
    height: 250px;
}
</style>
