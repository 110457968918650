<template>
    <div>
        <div class="card-body">
            <h3>Global Import Fields</h3>
            <div class="form-group">
                <label for="input-name">{{ $t('label.country') }}</label>
                <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                <!-- Country select -->
                <select class="form-control" v-model="$v.globalCustomerValues.country.$model" :class="{ 'is-invalid': $v.globalCustomerValues.country.$error }" required autofocus>
                    <option v-for="(data, index) in countries" :value="index" :key="index">{{ data }}</option>
                </select>
                <div v-if="$v.globalCustomerValues.country.$error && !$v.globalCustomerValues.country.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
            </div>

            <h3>{{ $t('label.map data') }} &nbsp;&nbsp;({{ recordCount }} {{ $t('label.records') }}) &nbsp;{{ $t('label.attribues with * are required') }}</h3>
            <div class="text-danger" v-if="displayErrors">
                <div>{{ $t('label.generic form validation error') }}</div>
                <div v-for="(error, idx) in validationsErrors" :key="idx">
                    {{ error }}
                </div>
            </div>
            <ul class="list-group">
                <li class="list-group-item bg-gray-100">
                    <div class="row text-bold">
                        <div class="col-3">{{ $t('label.file column header') }}</div>
                        <div class="col-3">{{ $t('label.file column values') }}</div>
                        <div class="col-3">{{ $t('label.subpro attribute') }}</div>
                        <div class="col-3">{{ $t('label.match status') }}</div>
                    </div>
                </li>
                <!-- loop uploaded file header names and first record values -->
                <li class="list-group-item">
                    <div class="row" v-for="(header, idx) in fileData" :key="idx">
                        <div class="col-3">{{ header.name }}</div>
                        <div class="col-3">{{ header.value }}</div>
                        <div class="col-3">
                            <!-- dropdown with table fields -->
                            <select class="form-control" @change="validateAttribute(header)" v-model="header.attribute" required>
                                <option value="1">Select Attribute</option>
                                <option v-for="(data, index) in customerFields" :value="data.tableName" :key="index">{{ (data.required) ? '*' : '' }}&nbsp;{{ data.name }}</option>
                            </select>
                        </div>
                        <div class="col-3">
                            <div class="text-lg text-danger" v-if="header.attribute <= 2"><i class="ri-error-warning-line"></i>&nbsp;{{ $t('label.no match') }}</div>
                            <div class="text-lg text-success" v-if="isNaN(header.attribute)"><i class="ri-check-line"></i>&nbsp;{{ $t('label.matched') }}</div>
                        </div>
                    </div>
                </li>
            </ul>
            <br>
            <div>
                <button type="button" class="btn btn-outline-primary" @click="$emit('fncReturn')">{{ $t('label.previous') }}</button>
                <button type="button" class="btn btn-success" :disabled="this.$v.$invalid" @click="importCustomers()">{{ $t('label.import data') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';
import { required } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('countries')

export default {
    props: ['fileData', 'importData'],
    components: {},
    emits: ['fncReturn', 'fncSuccess'],
    data() {
        return {
            globalCustomerValues: { country: 'US' },
            customerFields: [
                { name: 'Customer Name', tableName: 'name', required: true },
                { name: 'Contact First Name', tableName: 'first_name', required: false },
                { name: 'Contact Last Name', tableName: 'last_name', required: false },
                { name: 'Address 1', tableName: 'address_1', required: true },
                { name: 'Address 2', tableName: 'address_2', required: false },
                { name: 'City', tableName: 'city', required: true },
                { name: 'State', tableName: 'state', required: true },
                { name: 'Postal Code', tableName: 'postal_code', required: true },
                { name: 'Phone 1', tableName: 'phone_1', required: false },
                { name: 'Phone 2', tableName: 'phone_2', required: false },
                { name: 'Email', tableName: 'email', required: false },
                { name: 'Website', tableName: 'domain', required: false },
            ],
            recordCount: this.importData.length,
            validationsErrors: [],
            displayErrors: false,
        }
    },
    validations: {
        globalCustomerValues: {
            country: { required },
        }
    },
    computed: {
        ...mapState({
            countries: state => state.countries,
        }),
    },
    mounted() {
        if (!this.countries.length) {
            this.getCountries().then(response => { })
        }
    },
    methods: {
        ...mapActions(['getCountries']),
        validateAttribute(header) {
            this.fileData.forEach(function (obj) {
                if (obj.attribute == header.attribute && obj.name != header.name) {
                    obj.attribute = 1
                }
            })
        },
        importCustomers() {
            let loading = this.$loading.show()
            // Make sure any required attributes are validated
            this.validationsErrors = []
            this.displayErrors = false
            this.customerFields.forEach(field => {
                if (field.required) {
                    var idx = this.fileData.findIndex(item => {
                        if (field.tableName == item.attribute)
                            return true
                    })
                    // Throw error if field isn't matched
                    if (idx < 0) {
                        this.validationsErrors.push(`* "${field.name}" ${this.$t('label.attribute must be matched')}.`)
                    }
                }
            })

            if (this.validationsErrors.length > 0) {
                this.displayErrors = true
                window.scrollTo(0, 0)
                loading.hide()
                return false
            }

            let request = {
                country: this.globalCustomerValues.country,
                mappings: this.fileData,
                data: this.importData
            }

            axios
                .post('/dataImportCustomers', request)
                .then(response => {
                    loading.hide()
                    console.log('Result from server', response.data)
                    this.$emit('fncSuccess', response.data.recordCount);
                })
                .catch(err => {
                    loading.hide()
                    console.log('ERROR: ', err)
                    bus.$emit('banner-error', { message: err, })
                })
        },
    }
}
</script>