<template>
	<div>
		<!-- <div class="vld-parent">
			<loading :active.sync="isLoading" loader="dots" background-color=#000 blur=0px :is-full-page="true"></loading>
		</div> -->
		<!-- <div class="modal fade" id="paymentSendModal" tabindex="-1" role="dialog" aria-labelledby="paymentSendModalTitle" aria-hidden="true">
			<div class="modal-dialog" role="document" style="max-width: 900px"> -->
		<div class="modal-content">
			<div class="modal-header border-bottom">
				<h5 class="modal-title" id="paymentSendModalTitle">Send Receipt</h5>
				<button type="button" class="close" @click="$emit('cancelFnc')">
					<i class="ri-close-line"></i>
				</button>
			</div>
			<form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="sendPaymentReceipt">
				<div class="modal-body">
					<div v-if="!fromMenu" class="row m-0 mb-3 payment-success">
						<div class="col-12">
							<h3><i class="ri-checkbox-circle-line"></i> Payment of ${{ parseFloat(email.amount).toFixed(2) | formatThousands }} was processed successfully!</h3>
						</div>
					</div>
					<div class="row m-0 mb-3">
						<div class="col-12">
							<!-- <h3>Send Receipt</h3> -->
							<label class="form-control-label">To</label>
							<input type="text" name="email_to" v-model="email.to" class="form-control" placeholder="Email To" required>
						</div>
					</div>
					<div class="row m-0 mb-3">
						<div class="col-12">
							<label class="form-control-label">Subject</label>
							<input type="text" name="email_subject" v-model="email.subject" class="form-control" placeholder="Email Subject" required>
						</div>
					</div>
					<div class="row m-0 mb-3">
						<div class="col-12">
							<label class="form-control-label">Email Body</label>
							<textarea v-model="email.body" name="email_body" class="form-control" placeholder="Body of email" rows="10" required autofocus></textarea>
						</div>
					</div>
					<div class="row m-0 mb-3">
						<div class="col-12">
							<label class="form-control-label">Attachments</label>
							<div class="row align-items-center m-0">
								<div class="col-auto p-0">
									<img class="" :src="require('@/assets/images/doc-pdf.png')" style="height: 48px; object-fit: cover;" />
								</div>
								<div class="col-auto">
									<p class="m-0" style="line-height: 20px;">
										<label class="form-control-label mb-0">{{ email.payment_file_name }}</label>
										<br>
										<span class="text-muted">
											{{ email.invoice_file_size }}
										</span>
									</p>
								</div>
								<div class="col-auto p-0">
									<label class="form-control-label mb-0 text-uppercase">REMOVE</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer border-top">
					<button type="submit" class="btn btn-primary mr-auto text-uppercase">Send Receipt</button>
					<button type="button" class="btn btn-link  ml-auto close2paymentsend" @click="$emit('cancelFnc')">{{ $t('label.close') }}</button>
				</div>
			</form>
		</div>
		<!-- </div>
		</div> -->
	</div>
</template>

<script>
import bus from '@/utils/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	props: ['job', 'paymentData', 'fromMenu'],
	emits: ['cancelFnc'],
	components: {
		Loading
	},
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
			companySettings: JSON.parse(localStorage.getItem('company_settings')),
			email: {
				to: null,
				subject: null,
				body: null,
				payment_file_name: null,
				payment_file_size: null
			},
			requestData: null,
			isLoading: false,
			jobData: null,
			// fromMenu: false
		}
	},
	created() {
		bus.$on('paymentSend', (data) => {
			this.fromMenu = data.fromMenu ? data.fromMenu : this.fromMenu;
			$('#paymentSendModal').modal('show');
			this.paymentSendModal(data);
		})
	},
	mounted() {
		// var that = this;
		// $('#paymentSendModal').on('shown.bs.modal', function (e) {

		// })
		// $('#paymentSendModal').on('hidden.bs.modal', function (e) {
		// 	console.log('CLOOOOOOOOOOOSE')
		// 	bus.$emit('getallpayments');
		// 	bus.$emit('getallinvoices');
		// })

		this.loadLkupData(this.paymentData)
	},
	methods: {
		async loadLkupData(item) {
			this.loader = this.$loading.show();
			await this.paymentSendModal(item);
			this.loader.hide();
		},
		paymentSendModal(item) {
			console.log('item', item)
			// this.email.to = this.job.customer.email;
			this.email.to = item.data.job.customer.email;
			this.email.subject = "Receipt for Payment " + item.data.payment_number.payment_number + " from " + this.companySettings.name;
			this.email.payment_file_name = item.data.payment_attachment.name;
			this.email.payment_file_size = item.data.payment_attachment.size;
			this.email.amount = item.data.amount;
			this.requestData = {
				email: this.email,
				data: item.data,
				companySettings: this.companySettings,
				job: item.data.job
			}
		},
		sendPaymentReceipt() {
			this.isLoading = true;
			axios.post('/payment-send-email', this.requestData)
				.then(response => {
					this.clearForm()
					bus.$emit('getallpayments');
					bus.$emit('getallinvoices');
					$('.close2paymentsend').click();
					this.isLoading = false;

					bus.$emit('banner-success', {
						message: 'Payment processed successfully!',
					})
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		clearForm() {
			this.email.to = null,
				this.email.subject = null;
			this.email.body = null;
			this.email.payment_file_name = null;
			this.email.payment_file_size = null;
			this.requestData = null
		},

	}
}
</script>
<style>
.payment-success h3 {
	display: flex;
	align-items: center;
	justify-content: center;
}

.payment-success h3 i {
	font-size: 40px;
	color: var(--success);
}
</style>