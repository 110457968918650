<template>
    <div class="card">
        <div class="card-header">
            <h3 class="d-flex align-items-center mb-0">
                <i class="ri-information-line"></i> {{ $t('label.purchase order info') }}
            </h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="vendor_name">{{ $t('label.vendor') }}</label>
                        <h3 class="border-bottom" v-if="purchaseOrder.vendor">{{ purchaseOrder.vendor.vendor_name }}</h3>
                        <h3 class="border-bottom" v-else>&nbsp;</h3>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="order_number">{{ $t('label.purchase order') }} #</label>
                        <h3 class="border-bottom d-flex align-items-center justify-content-between" v-if="purchaseOrder.order_number">
                            <span class="d-flex align-items-center">
                                {{ purchaseOrder.order_number }}
                            </span>
                        </h3>
                        <h3 class="border-bottom" v-else>&nbsp;</h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="vendor_contact_name">{{ $t('label.vendor contact') }}</label>
                        <h3 class="border-bottom" v-if="selectedVenContact">{{ selectedVenContact.first_name }} {{ selectedVenContact.last_name }}</h3>
                        <h3 class="border-bottom" v-else>&nbsp;</h3>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="status">{{ $t('label.status') }}</label>
                        <h3 class="border-bottom" v-if="purchaseOrder.purchase_order_status">
                            <span class="badge badge-dot">
                                <i class="bg-success" :style="'background-color:' + purchaseOrder.purchase_order_status.color + ' !important'"></i> {{ purchaseOrder.purchase_order_status.name }}
                            </span>
                        </h3>
                        <h3 class="border-bottom" v-else>&nbsp;</h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="job_order">{{ $t('label.job') }}</label>
                        <h3 class="border-bottom" v-if="purchaseOrder.job">{{ purchaseOrder.job.job_name }}</h3>
                        <h3 class="border-bottom" v-else>&nbsp;</h3>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="purchase_date">{{ $t('label.purchase order date') }}</label>
                        <h3 class="border-bottom" v-if="purchaseOrder.purchase_date">{{ purchaseOrder.purchase_date }}</h3>
                        <h3 class="border-bottom" v-else>&nbsp;</h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="customer_name">{{ $t('label.customer name') }}</label>
                        <h3 class="border-bottom" v-if="purchaseOrder.job">{{ purchaseOrder.job.customer.name }}</h3>
                        <h3 class="border-bottom" v-else>&nbsp;</h3>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="delivery_date">{{ $t('label.expected delivery date') }}</label>
                        <h3 class="border-bottom" v-if="purchaseOrder.delivery_date">{{ purchaseOrder.delivery_date }}</h3>
                        <h3 class="border-bottom" v-else>&nbsp;</h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="created_by">{{ $t('label.created by') }}</label>
                        <h3 class="border-bottom" v-if="purchaseOrder.created_by">
                            <img :src="purchaseOrder.created_by.picture" class="avatar-img rounded-circle">
                            <span>{{ purchaseOrder.created_by.name }}</span>
                        </h3>
                        <h3 class="border-bottom" v-else>&nbsp;</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['purchaseOrder', 'selectedVenContact'],
    emits: [''],
    components: {

    },
    data() {
        return {

        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {


    },
}
</script>
