import i18n from '@/plugins/i18n.plugin';
import { routes } from '@/router/routes';
import { store } from '@/store/store';
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    // When going back in history, goto the previous page position
    if (savedPosition) {
      return savedPosition
    }
    else {
      const position = {}
      // You can scroll to an anchor on next route by adding a hash attribute in the router-link
      // <router-link :to="{name:'routeName', params:{someparam:someVal}, hash:'#nameOfAnchor'}"
      if (to.hash) {
        position.selector = to.hash
        if (document.querySelector(to.hash)) {
          return position
        }
        return false
      }
      //By default scroll to the top of the page
      else {
        scrollTo(0, 0)
      }
    }
  },
  routes: routes
});

router.beforeEach(async (to, from, next) => {
  store.dispatch('Auth/setLoading', true);
  checkLocalUserData();
  let userRole = JSON.parse(localStorage.getItem('user'))?.role_id
  // Make sure user has the required role to access this resource
  if (userRole) {

    // Redirect the user to the correct home page
    if (userRole !== to.meta.accessRole && to.meta.accessRole) {
      if (userRole == 3) {
        return next('fieldTasks');
      }
      else {
        return next();
      }
    }
    // If the user checks out, continue to route
    else {
      return next();
    }
  }

  // const isLoggedIn = store.getters['Auth/isLoggedIn'];
  // const admin_selected_company = localStorage.getItem('admin_selected_company_domain');
  // const domainVar = getDomain();
  // let subdomain = domainVar.subDomain;
  // if (isLoggedIn && to.name != 'selectCompany' && subdomain == 'admin' && !admin_selected_company) {
  //   next({ name: 'selectCompany' });
  // }

  return next();
});

router.afterEach((to) => {
  document.title = 'SubPro | ' + (to.meta.title != undefined ? to.meta.title : 'SubPro');
  setTimeout(() => {
    store.dispatch('Auth/setLoading', false);
  }, 1000);
});

/**
 * Helper Functions
 */
const checkLocalUserData = async () => {
  let isLoggedIn = store.getters['Auth/isLoggedIn'];
  if (isLoggedIn) {
    let user = store.getters['Auth/currentUser'];
    store.commit('Auth/UPDATE_USER_INFO', user);
    let _local = localStorage.getItem('_local');
    _local = (_local && Object.keys(user).length > 0) ? JSON.parse(_local) : null;

    if (!_local) {
      await store.dispatch('Auth/setUserLocal');
    }

    const lang = user.language ?? 'en';
    i18n.setLocaleMessage(lang, _local);
  } else {
    store.dispatch('Auth/getCurrentUser');
  }
}

export default router;
