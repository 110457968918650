<template>
    <modal name="userClockOutModal" @closed="$emit('closeFnc')" :classes="'rounded'" :shiftY="0.5" :min-height="400" width="525" :scrollable="true" height="auto" :reset="true" :adaptive="true">
        <div class="card-body bg-gradient-danger mobile-p-0 p-0">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="text-lighter" aria-hidden="true">×</span>
                </button>
            </div>
            <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="stop">
                <div class="modal-body text-white">
                    <div class="py-3 text-center">
                        <i class="ri-stop-circle-line ni-3x"></i>
                        <h4 class="heading mt-4 text-uppercase text-white">{{ $t('label.CONFIRM CLOCK OUT') }}</h4>
                        <p>{{ $t('label.Are you sure you want to clock out') }}?</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-white">{{ $t('label.Yes, Clock Out') }}</button>
                    <button type="button" class="btn btn-link text-white ml-auto closeclockOutNoti" @click="$modal.hide('userClockOutModal')">{{ $t('label.cancel') }}</button>
                </div>
            </form>
        </div>
    </modal>
</template>

<script>
import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex';
;

export default {
    emits: ['closeFnc'],
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            coordinates: 'coordinates',
            userClockin: 'time/userClockin',
        }),
    },
    mounted() {
        this.$modal.show("userClockOutModal");
        this.$store.dispatch('setGeolocation');
    },
    methods: {
        stop() {
            var request_fields = {
                'time_id': this.userClockin.id,
                'logout_location': this.coordinates,
            };

            this.$store.dispatch('time/timeOut', request_fields).then(res => {
                bus.$emit('banner-succes', {
                    message: this.$t('label.you were successfully clocked out') + '.'
                });

                bus.$emit('sidebarClockout', 'get all tasks 3');
                bus.$emit('getTasks', 'getTasks');
                this.$modal.hide("userClockOutModal");
                this.$emit('closeFnc');
            }).catch(err => console.log(err));
        },
    }
}
</script>