/* eslint-disable no-console */

import { register } from 'register-service-worker';
import ServiceWorkerStatus from './service-worker-status';

console.log('Registering service worker');

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered() {
      // ServiceWorkerStatus.setLoading({ status: false, message: 'Service worker has been registered.' });
      console.log('Service worker has been registered.')
    },
    cached() {
      ServiceWorkerStatus.setLoading({ status: false, message: 'Application Content has been cached for offline use.' });
    },
    updatefound() {
      ServiceWorkerStatus.setLoading({ status: true, message: 'New update is downloading.' });
    },
    updated(registration) {
      setTimeout(() => {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }, 3000);
      ServiceWorkerStatus.setLoading({ status: true, message: 'New update is available. Please refresh the page or page will refresh in 5 seconds...', reload: true });
    },
    offline() {
      ServiceWorkerStatus.setLoading({ status: false, message: 'No internet connection found. Application is running in offline mode.' });
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
