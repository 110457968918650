<template>
    <div>
        <!-- In Service - Start Date -->
        <div id="inservice" class="form-group">
            <div class="card">
                <div class="card-header">
                    <div class="align-items-center">
                        <h3 class="d-flex mb-0 align-items-center">
                            <i class="ri-time-line heading-title"></i>{{ $t('label.in service') }}
                        </h3>
                    </div>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label class="form-control-label text-bold" for="item_name">{{ $t('label.start date') }}</label>
                        <date-picker v-model="inServObj.inserviceDate" format="MM/DD/YYYY" class="full-width" input-class="form-control" :placeholder="$t('label.select date')" :append-to-body="false">
                        </date-picker>
                    </div>
                </div>
            </div>
        </div>

        <!-- Out of Service - End Date -->
        <div id="outservice" class="form-group" v-if="existingItem">
            <div class="card">
                <div class="card-header">
                    <div class="align-items-center">
                        <h3 class="d-flex mb-0 align-items-center">
                            <i class="ri-time-line heading-title"></i>{{ $t('label.out of service') }}
                        </h3>
                    </div>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label class="form-control-label text-bold" for="item_name">{{ $t('label.end date') }}</label>
                        <date-picker v-model="inServObj.outserviceDate" format="MM/DD/YYYY" class="full-width" input-class="form-control" :placeholder="$t('label.select date')" :append-to-body="false">
                        </date-picker>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['existingItem', 'inServObj'],
    data() {
        return {};
    },
    mounted() {
        console.log("inServObj", this.inServObj);
    },
    methods: {},
};
</script>
