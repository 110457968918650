<template>
  <span draggable="true" @dragstart="handleDragStart" @dragover.prevent @dragenter.prevent @dragleave.prevent>
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: ['transferData'],
  methods: {
    handleDragStart(event) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('value', JSON.stringify(this.transferData));
    },
  }
}
</script>


