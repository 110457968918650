<template>
	<div>
		<!-- Job Edit Modal -->
		<modal name="editJobModal" @closed="$emit('onClose')" id="editJobModal" height="auto" width="100%" :max-width="1024" :reset="true" :scrollable="true" :adaptive="true">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="editJobModalTitle">
						{{ $t('label.edit job') }}
					</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clearForm();">
						<i class="ri-close-line"></i>
					</button>
				</div>
				<div class="modal-body border-bottom">
					<div class="row">
						<!-- Customer Information Column -->
						<div class="col-12 col-lg-6">
							<h5 class="modal-title mb-3">
								{{ $t('label.customer information') }}
							</h5>
							<!-- Customer -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="vendor_name">{{ $t('label.customer') }}</label>
									<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

									<div class="dropdown w-100">
										<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': ($v.form.edit.customer_id.$error), }">
												<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a customer') + ' -'" v-model="$v.form.edit.customer_name.$model" autocomplete="input-field" data-lpignore="true" required readonly>
												<div class="input-group-append">
													<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
												</div>
											</div>
										</div>

										<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
											<a class="dropdown-item text-default pointer" @click="$modal.show('jobAddCustomerModal')">
												<i class="ri-add-fill"></i> {{ $t('label.add customer') }}
											</a>
											<div class="dropdown-divider mx-3"></div>
											<h4 class="mx-3">{{ $t('label.results') }}</h4>
											<div class="pl-3">
												<template v-for="(data, index) in alljobpeople['customers']">
													<div :key="index" class="pointer dropdown-items" @click="selectedCustomer(data); onCustomerChange();">
														<span class="customername">{{ data.name }}</span>
													</div>
												</template>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- Superintendent -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="superintendent_name">{{ $t('label.superintendent') }}</label>

									<div class="dropdown w-100">
										<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<div v-if="form.edit.customer_id !== null && form.edit.customer_id !== undefined" class="input-group input-group-alternative input-group-merge search-contacts">
												<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a superintendent') + ' -'" v-model="form.edit.superintendent_name" autocomplete="input-field" data-lpignore="true" readonly>
												<div class="input-group-append">
													<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
												</div>
											</div>
											<div v-else class="input-group input-group-alternative input-group-merge search-contacts">
												<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a customer first') + ' -'" v-model="form.edit.superintendent_name" autocomplete="input-field" data-lpignore="true" disabled readonly>
												<div class="input-group-append">
													<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
												</div>
											</div>
										</div>
										<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
											<a v-if="form.edit.customer_id !== null && form.edit.customer_id !== undefined" class="dropdown-item text-default pointer" @click="$modal.show('jobAddAddSuperModal')">
												<i class="ri-add-fill"></i> {{ $t('label.add superintendent') }}
											</a>
											<a v-else class="dropdown-item text-default pointer">
											</a>
											<div class="dropdown-divider mx-3"></div>
											<h4 class="mx-3">{{ $t('label.results') }}</h4>
											<div class="pl-3">
												<template v-for="(data, index) in customerContactList">
													<div :key="index" :value="data.id" class="pointer dropdown-items" @click="selectedSuperintendent(data);">
														<span class="superintendentname">{{ data.full_name }}</span>
													</div>
												</template>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- Job Information -->
							<h5 class="modal-title mb-3">{{ $t('label.job information') }}</h5>

							<!-- Job Type -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input_name">{{ $t('label.job type') }}</label>
									<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

									<div class="dropdown w-100">
										<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': ($v.form.edit.job_type_id.$error), }">
												<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a job type') + ' -'" v-model="$v.form.edit.job_type_name.$model" autocomplete="input-field" data-lpignore="true" required readonly>
												<div class="input-group-append">
													<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
												</div>
											</div>
										</div>
										<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
											<a class="dropdown-item text-default pointer" @click="toggleAddJobType(true)">
												<i class="ri-add-fill"></i> {{ $t('label.add new job type') }}
											</a>
											<div class="dropdown-divider mx-3"></div>
											<h4 class="mx-3">{{ $t('label.results') }}</h4>
											<div class="pl-3">
												<template v-for="(data, index) in alljobtypes">
													<div :key="index" :value="data.id" class="pointer dropdown-items" @click="selectedJobType(data);">
														<span class="jobtypename">{{ data.name }}</span>
													</div>
												</template>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- Job name -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.job name') }}</label>
									<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

									<input type="text" name="job_name" v-model="$v.form.edit.job_name.$model" class="form-control" :class="{ 'is-invalid': $v.form.edit.job_name.$error }" :placeholder="$t('label.enter a job name')" value="" required>
									<div v-if="$v.form.edit.job_name.$error && !$v.form.edit.job_name.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
								</div>
							</div>

							<!-- Manager -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input_name">{{ $t('label.job manager') }}</label>
									<div class="dropdown w-100">
										<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<div class="input-group input-group-alternative input-group-merge search-contacts">
												<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a job manager') + ' -'" v-model="form.edit.job_manager_user_name" autocomplete="input-field" data-lpignore="true" readonly>
												<div class="input-group-append">
													<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
												</div>
											</div>
										</div>
										<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
											<div class="pl-3">
												<template v-for="(data, index) in allManagers">
													<div :key="index" :value="data.id" class="pointer dropdown-items" @click="selectedManager(data);">
														<span class="managername">{{ data.name }}</span>
													</div>
												</template>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- Job Status -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input_name">{{ $t('label.job status') }}</label>
									<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

									<div class="dropdown w-100">
										<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': ($v.form.edit.job_status_id.$error), }">
												<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a job status') + ' -'" v-model="$v.form.edit.job_status_name.$model" autocomplete="input-field" data-lpignore="true" required readonly>
												<div class="input-group-append">
													<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
												</div>
											</div>
										</div>

										<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
											<div class="pl-3">
												<template v-for="(data, index) in alljobstatus">
													<div :key="index" :value="data.id" class="pointer dropdown-items" @click="selectedJobStatus(data);">
														<span class="jobstatusname">{{ data.name }}</span>
													</div>
												</template>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="row mx-0">
								<!-- Start Date -->
								<div class="col-6">
									<div class="form-group">
										<label class="form-control-label">{{ $t('label.start date') }}</label>
										<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

										<date-picker v-model="$v.form.edit.start_date.$model" valueType="format" format="MM/DD/YYYY" :default-value="moment().format('MM/DD/YYYY')" :disabled-date="disableStartDateBeforeToday" :placeholder="$t('label.select date')" :input-class="{
											'is-invalid': $v.form.edit.start_date.$error,
											'form-control': true
										}" required>
										</date-picker>
										<div v-if="$v.form.edit.start_date.$error && !$v.form.edit.start_date.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
									</div>
								</div>
								<!-- Time estimate -->
								<div class="col-6">
									<div class="form-group">
										<label class="form-control-label text-muted" for="input-name">{{ $t('label.time estimate') }} {{ '(' + $t('label.hours') + ')' }}</label>
										<input v-model="form.edit.time_estimate" type="number" inputmode="decimal" min="0" name="time_estimate" class="form-control" autocomplete="input-field" data-lpignore="true">
									</div>
								</div>
							</div>
						</div>

						<!-- Job Location Column -->
						<div class="col-12 col-lg-6">
							<!-- Use customer address -->
							<div class="col-12">
								<h5 class="modal-title mb-3">
									{{ $t('label.job location') }}
								</h5>
								<div v-if="source != 'customer'" class="custom-control custom-control-alternative custom-checkbox mb-3" @change="useCustomerAddress()">
									<input class="custom-control-input" id="customer_address" type="checkbox" v-model="form.edit.customer_address" />
									<label class="custom-control-label" for="customer_address">
										<span class="text-muted">{{ $t('label.use customer address') }}</span>
									</label>
								</div>
							</div>
							<!-- Address 1 -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.address') }} 1</label>
									<input type="text" name="address_1" v-model="form.edit.address_1" class="form-control" :placeholder="$t('label.address') + ' 1'" value="">
								</div>
							</div>
							<!-- address 2 -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.address') }} 2</label>
									<input type="text" name="address_2" v-model="form.edit.address_2" class="form-control" :placeholder="$t('label.address') + ' 2'" value="" />
								</div>
							</div>
							<!-- City -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.city') }}</label>
									<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

									<input type="text" name="city" v-model="$v.form.edit.city.$model" class="form-control" :class="{ 'is-invalid': $v.form.edit.city.$error }" :placeholder="$t('label.city')" value="" required>
									<div v-if="$v.form.edit.city.$error && !$v.form.edit.city.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
								</div>
							</div>
							<!-- Zipcode -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.postal code') }}</label>
									<input @keyup="getStateFromPostalCode" type="text" name="postal_code" v-model="form.edit.postal_code" class="form-control" :placeholder="$t('label.postal code')" value="">
								</div>
							</div>
							<!-- State  -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input_name">{{ $t('label.state') }}</label>
									<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

									<div class="dropdown w-100">
										<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': ($v.form.edit.state.$error), }">
												<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a state') + ' -'" v-model="$v.form.edit.state.$model" autocomplete="input-field" data-lpignore="true" required readonly>
												<div class="input-group-append">
													<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
												</div>
											</div>
										</div>
										<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
											<div class="pl-3">
												<template v-for="(data, index) in allstates">
													<div :key="index" :value="data" class="pointer dropdown-items" @click="selectedState(data);">
														<span class="state">{{ index }}</span>
													</div>
												</template>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- country -->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input_name">{{ $t('label.country') }}</label>
									<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

									<div class="dropdown w-100">
										<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': ($v.form.edit.country.$error), }">
												<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a country') + ' -'" v-model="$v.form.edit.country.$model" autocomplete="input-field" data-lpignore="true" required readonly>
												<div class="input-group-append">
													<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
												</div>
											</div>
										</div>

										<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
											<div class="pl-3">
												<template v-for="(data, index) in allcountries">
													<div :key="index" :value="index" class="pointer dropdown-items" @click="selectedCountry(data);">
														<span class="country">{{ data }}</span>
													</div>
												</template>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Custom Fields -->
				<div class="modal-body" v-if="jobCustomFields.length > 0">
					<h5 class="modal-title mb-3">{{ $t("label.custom fields") }}</h5>
					<div class="row">
						<div v-for="(cfData, idx) in jobCustomFields" :key="idx" class="col-6">
							<div class="form-group">
								<custom-field-input :model-id="jobdata.id" :model-type="'job'" :custom-field="cfData" :form-save="savingForm">
								</custom-field-input>
							</div>
						</div>
					</div>
				</div>

				<!-- Buttons -->
				<div class="modal-footer border-top">
					<button type="submit" class="btn btn-primary mr-auto" @click="submitForm()">{{ $t('label.save job') }}</button>
					<button type="button" class="btn btn-link  ml-auto close2addjob" data-dismiss="modal" @click="clearForm();">{{ $t('label.close') }}</button>
				</div>
			</div>
		</modal>

		<!-- Add New Customer -->
		<modal name="jobAddCustomerModal" height="auto" width="98%" :max-width="1024" :reset="true" :scrollable="true" :adaptive="true">
			<modal-customer source="job-edit" jobcreate="true" :account-type="1" @cancelFnc="$modal.hide('jobAddCustomerModal')" @returnFnc="receiveNewCustomer" />
		</modal>

		<!-- Add Superintendent Modal -->
		<modal class="pb-6" name="jobAddAddSuperModal" height="auto" width="98%" :max-width="1024" :scrollable="true" :reset="true" :adaptive="true">
			<customer-contact-manage :customer-id="form.edit.customer_id" @cancelFnc="$modal.hide('jobAddAddSuperModal')" @returnContact="receiveNewContact"></customer-contact-manage>
		</modal>

		<!-- Add New Job Type Modal -->
		<job-type-add-modal v-if="showJobTypeModal" @onClose="toggleAddJobType" @onSave="onAddNewJobType">
		</job-type-add-modal>

		<!-- Job Close Confirmation Modal -->
		<modal-confirm-job-close v-if="showCloseConfirmation" :custom-confirm-method="true" :job-id="jobid" :z-index="closeConfirmModalIndex" @on-close-modal="onCloseConfirmModal" @on-confirm-method="updateJob">
		</modal-confirm-job-close>
	</div>
</template>

<script>
import JobTypeAddModal from '@/components/Admin/JobTypes/JobTypeAddModal';
import CustomFieldInput from '@/components/CustomFields/CustomFieldInput.vue';
import CustomerContactManage from '@/components/CustomerContact/CustomerContactManageComponent';
import ModalCustomer from '@/components/ModalCustomer';
import bus from '@/utils/event-bus';
import { VBPopoverPlugin } from 'bootstrap-vue';
import { required } from 'vuelidate/lib/validators';

export default {
	props: ['jobdata', 'source'],
	emits: ['onClose', 'onSave'],
	directives: {
		'b-popover': VBPopoverPlugin
	},
	components: {
		CustomerContactManage,
		CustomFieldInput,
		JobTypeAddModal,
		ModalCustomer
	},
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
			alljobtypes: [],
			alljobstatus: [],
			alljobpeople: [],
			allManagers: [],
			alljobs: [],
			allcountries: [],
			allstates: [],
			form: {
				edit: {
					customer_id: null,
					customer_name: null,
					superintendent_user_id: this.jobdata.superintendent_user_id,
					superintendent_name: null,
					job_type_id: null,
					job_type_name: null,
					job_name: null,
					job_manager_user_id: [],
					job_manager_user_name: null,
					start_date: null,
					end_date: null,
					time_estimate: null,
					country: null,
					address_1: null,
					address_2: null,
					city: null,
					state: null,
					postal_code: null,
					job_status_id: null,
					job_status_name: null,
					superintendent: null,
					customer_address: null
				}
			},
			jobCustomFields: [],
			allsuperintendents: [],
			jobid: this.jobdata.id,
			customerContactList: [],
			closeConfirmModalIndex: 1,
			showCloseConfirmation: false,
			showJobTypeModal: false,
			savingForm: false,
			loader: null
		}
	},
	validations: {
		form: {
			edit: {
				customer_id: { required },
				customer_name: {},
				job_type_id: { required },
				job_type_name: {},
				job_name: { required },
				start_date: { required },
				country: { required },
				city: { required },
				state: { required },
				job_status_id: { required },
				job_status_name: {},
			}
		}
	},
	created() {
		this.loader = this.$loading.show();
		let promises = [
			this.getCustomfields(),
			this.getCountries(),
			this.getStates(),
			this.getJobTypes(),
			this.getJobStatus(),
			this.getJobPeople(),
			this.getCompanyManagers()
		];

		Promise.all(promises).then(response => {
			this.loader.hide();
		});
	},
	mounted() {
		this.$modal.show('editJobModal');
		// Load the edit values on modal load
		this.editModal(this.jobdata);
	},
	methods: {
		getStateFromPostalCode() {
			if (this.form.edit.postal_code && this.form.edit.postal_code.length >= 5) {
				return axios.get('/get-state/' + this.form.edit.postal_code).then(response => {
					if (response.data.results)
						this.form.edit.state = response.data.results.abbreviation;
					else
						this.form.edit.state = '';
				}).catch(err => { });
			}
		},
		getCountries() {
			return axios.get('/get-countries').then(response => {
				this.allcountries = response.data.result;
			}).catch(err => { });
		},
		getStates() {
			return axios.get('/get-states').then(response => {
				this.allstates = response.data.result;
			}).catch(err => { });
		},
		getJobTypes() {
			return axios.get('/jobtypes').then(response => {
				this.alljobtypes = response.data.result;
			}).catch(err => { });
		},
		getJobStatus() {
			return axios.get('/jobstatus').then(response => {
				this.alljobstatus = response.data.result;
			}).catch(err => { });
		},
		getJobPeople() {
			return axios.get('/jobpeople').then(response => {
				this.alljobpeople = response.data.result;
			}).catch(err => { });
		},
		getCompanyManagers() {
			return axios.get('/company-admins').then(response => {
				this.allManagers = response.data.result;
			}).catch(err => console.log('getCompanyManagers', err));
		},
		getCustomfields() {
			return axios.post(`/loadModelCustomFields`, {
				model_id: this.jobid,
				model_type: 'Job'
			}).then(response => {
				this.jobCustomFields = response.data.result;
			}).catch(err => console.log(err));
		},
		selectedCustomer(data) {
			this.form.edit.customer_id = data.id;
			this.form.edit.customer_name = data.name;
		},
		selectedSuperintendent(data) {
			this.form.edit.superintendent_user_id = data.id;
			this.form.edit.superintendent_name = data.full_name;
		},
		selectedJobType(data) {
			this.form.edit.job_type_id = data.id;
			this.form.edit.job_type_name = data.name;
		},
		selectedManager(data) {
			this.form.edit.job_manager_user_id = data.id;
			this.form.edit.job_manager_user_name = data.name;
		},
		selectedJobStatus(data) {
			this.form.edit.job_status_id = data.id;
			this.form.edit.job_status_name = data.name;
		},
		selectedState(data) {
			this.form.edit.state = data;

		},
		selectedCountry(data) {
			this.form.edit.country = data;
		},
		editModal(item) {
			this.editMode = true;
			this.form.edit.customer_id = item.customer_id != 0 ? item.customer_id : null;
			this.form.edit.customer_name = (item.customer_id != 0 && item.customer != null) ? item.customer.name : null;
			this.form.edit.superintendent_user_id = (item.superintendent_user_id) ? item.superintendent_user_id : 0;
			this.form.edit.superintendent_name = (item.superintendent_user_id) ? item.customer_contact.full_name : null;
			this.form.edit.superintendent = item.superintendent;
			this.form.edit.job_type_id = item.job_type_id;
			this.form.edit.job_type_name = (item.job_type_id) ? item.job_type.name : null;
			this.form.edit.job_name = item.job_name;
			this.form.edit.job_manager_user_id = (item.job_manager_user_id) ? item.job_manager_user_id : 0;
			this.form.edit.job_manager_user_name = (item.job_manager_user_id) ? item.job_manager.name : null;
			this.form.edit.job_status_id = item.job_status_id;
			this.form.edit.job_status_name = (item.job_status) ? item.job_status.name : null;
			this.form.edit.start_date = item.start_date ? moment(item.start_date).format('MM/DD/YYYY') : null;
			this.form.edit.end_date = item.end_date ? moment(item.end_date).format('MM/DD/YYYY') : null;
			this.form.edit.time_estimate = item.time_estimate;
			this.form.edit.country = item.country;
			this.form.edit.address_1 = item.address_1;
			this.form.edit.address_2 = item.address_2;
			this.form.edit.city = item.city;
			this.form.edit.state = item.state;
			this.form.edit.postal_code = item.postal_code;
			this.form.edit.inputs = [];

			this.loadCustomerContacts();
		},
		submitForm() {
			//Check for job status of closed
			let idx = -1;
			if (this.jobdata.job_status_id !== this.form.edit.job_status_id) {
				idx = this.alljobstatus.findIndex(status => {
					if (status.id === this.form.edit.job_status_id) return true;
				});
			}
			// Display close confirm modal
			if (idx >= 0 && this.alljobstatus[idx].name === 'Closed') {
				let editModalIdx = parseInt($('#editJobModal').css('z-index'));
				this.closeConfirmModalIndex = editModalIdx + 1;
				this.showCloseConfirmation = true;
			}
			// Go ahead and update the job
			else {
				this.updateJob();
			}
		},
		updateJob() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.loader = this.$loading.show();
				this.showCloseConfirmation = false;
				this.savingForm = true;

				var request_fields = {
					'customer_id': this.form.edit.customer_id,
					'superintendent_user_id': this.form.edit.superintendent_user_id,
					'job_type_id': this.form.edit.job_type_id,
					'job_name': this.form.edit.job_name,
					'job_manager_user_id': this.form.edit.job_manager_user_id,
					'start_date': moment(this.form.edit.start_date).isValid() ? moment(this.form.edit.start_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
					'end_date': moment(this.form.edit.end_date).isValid() ? moment(this.form.edit.end_date).format('YYYY-MM-DD') : null,
					'time_estimate': this.form.edit.time_estimate,
					'country': this.form.edit.country,
					'address_1': this.form.edit.address_1,
					'address_2': this.form.edit.address_2,
					'city': this.form.edit.city,
					'state': this.form.edit.state,
					'postal_code': this.form.edit.postal_code,
					'job_status_id': this.form.edit.job_status_id,
					'superintendent': this.form.edit.superintendent,
					'jobid': this.jobid
				};

				axios.post('/update-job', request_fields).then(response => {
					bus.$emit('getallwidgets2', 'widgets');
					bus.$emit('getJobStatus');
					bus.$emit('banner-success', {
						message: `Job updated successfully!`,
					});

					this.loader.hide();
					this.savingForm = false;
					this.$emit('onSave');
					this.clearForm();
				}).catch(err => {
					//console.log('list carriers error:', error)
				});
			} else {
				// Go to top of screen and show invalid fields
				$("#editJobModalTitle")[0].scrollIntoView();
			}
		},
		clearForm() {
			this.$modal.hide('editJobModal')
		},
		onCloseConfirmModal() {
			this.showCloseConfirmation = false;
		},
		viewFilter() {
			this.extraFilter = !this.extraFilter;
		},
		isMobile() {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				return true
			} else {
				return false
			}
		},
		useCustomerAddress() {
			if (this.form.edit.customer_address) {
				for (let i = 0; i < this.alljobpeople['customers'].length; i++) {
					if (this.form.edit.customer_id && this.alljobpeople['customers'][i].id == this.form.edit.customer_id) {
						this.form.edit.country = this.alljobpeople['customers'][i].country;
						this.form.edit.address_1 = this.alljobpeople['customers'][i].address_1;
						this.form.edit.address_2 = this.alljobpeople['customers'][i].address_2;
						this.form.edit.city = this.alljobpeople['customers'][i].city;
						this.form.edit.state = this.alljobpeople['customers'][i].state;
						this.form.edit.postal_code = this.alljobpeople['customers'][i].postal_code;
						break;
					}
				}
			} else {
				this.form.edit.country = '';
				this.form.edit.address_1 = '';
				this.form.edit.address_2 = '';
				this.form.edit.city = '';
				this.form.edit.state = '';
				this.form.edit.postal_code = '';
			}
		},
		onCustomerChange() {
			this.loadCustomerContacts();

			if (this.form.edit.customer_address)
				this.useCustomerAddress();
		},
		loadCustomerContacts() {
			this.customerContactList = [];

			return axios.post('/loadCustomerContacts', {
				customerId: this.form.edit.customer_id
			}).then(response => {
				this.customerContactList = response.data;
			}).catch(err => {
				console.log('ERROR: ', err);
				bus.$emit('banner-error', { message: err });
			});
		},
		receiveNewCustomer(newCustomer) {
			this.$modal.hide('jobAddCustomerModal');
			this.form.edit.superintendent_user_id = null;

			// Add the newly created customer to the drop down list
			// and select the newly created customer
			this.alljobpeople['customers'].push(newCustomer.data);
			this.form.edit.customer_id = this.alljobpeople['customers'][this.alljobpeople['customers'].length - 1].id;
			this.onCustomerChange();
		},
		receiveNewContact(newContact) {
			this.$modal.hide('jobAddAddSuperModal');

			// Add the newly created contact to the drop down 
			// list and elect the newly created contact
			this.customerContactList.push(newContact);
			this.form.edit.superintendent_user_id = this.customerContactList[this.customerContactList.length - 1].id;
		},
		onAddNewJobType(job_type_id) {
			this.getJobTypes().then(() => {
				this.form.edit.job_type_id = job_type_id;
			});
		},
		toggleAddJobType(status) {
			this.showJobTypeModal = status;
		},
		disableStartDateBeforeToday(date) {
			return date < moment().subtract(1, 'days').toDate();
		},
		disableEndDateBeforeStartDate(date) {
			return date < moment(this.form.edit.start_date).toDate();
		},
	},
};
</script>

<style scoped>
.dropdown-menu {
	overflow-y: scroll;
	max-height: 300px;
}

.dropdown-items {
	line-height: 36px;
}

.dropdown-items:hover {
	background: #f6f9fc;
}
</style>
