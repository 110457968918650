<template>
    <div :key="reloadKey">
        <!-- Header -->
        <div class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center" style="background-image: url(&quot;../argon/img/theme/profile-cover.jpg&quot;); background-size: cover; background-position: center top;">
            <span class="mask bg-gradient-default opacity-8"></span>
            <div class="container-fluid d-flex align-items-center">
                <div class="row">
                    <div class="col-md-12 col-lg-7">
                        <h1 class="display-2 text-white">{{ $t('label.Hello') }} {{ user.first_name }} {{ user.last_name }}</h1>
                        <p class="text-white mt-0 mb-5">{{ $t('label.this is your profile page you can update your contact information upload a profile photo and change your password') }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid mt--6">
            <div class="row">
                <div class="col-xl-8 order-xl-1">
                    <div class="card">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">{{ $t('label.edit profile') }}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="editProfile">
                                <h6 class="heading-small text-muted mb-4">{{ $t('label.user information') }}</h6>
                                <div class="pl-lg-4">

                                    <!-- First Name -->
                                    <div class="form-group">
                                        <label class="form-control-label" for="input-name">{{ $t('label.first name') }}</label>
                                        <input type="text" name="first_name" v-model="user.first_name" id="input-name" class="form-control" :placeholder="$t('label.first name')" required autofocus autocomplete="input-field" data-lpignore="true">
                                    </div>

                                    <!-- Last Name -->
                                    <div class="form-group">
                                        <label class="form-control-label" for="input-name">{{ $t('label.last name') }}</label>
                                        <input type="text" name="last_name" id="input-name" class="form-control" :placeholder="$t('label.last name')" v-model="user.last_name" required autofocus>
                                    </div>

                                    <!-- Email -->
                                    <div class="form-group">
                                        <label class="form-control-label" for="input-email">{{ $t('label.email') }}</label>
                                        <input type="email" name="email" id="input-email" class="form-control" :placeholder="$t('label.email')" v-model="user.email" required>
                                    </div>

                                    <!-- Phone -->
                                    <div class="form-group">
                                        <label class="form-control-label" for="input-phone">{{ $t('label.phone') }}</label>
                                        <input type="text" name="phone" id="input-phone" class="form-control" :placeholder="$t('label.phone')" v-model="user.phone" required>
                                    </div>

                                    <!-- Avatar -->
                                    <div class="form-group">
                                        <label class="form-control-label">{{ $t('label.profile photo') }}</label>
                                        <div class="">
                                            <input type="file" @change="selectedPhoto" class="form-control" name="photo" accept="image/png, image/jpeg" :placeholder="$t('label.photo')" autofocus>
                                            <img class="rounded-circle" v-if="user.picture" :src="user.picture" style="width: 100px; height: 100px; object-fit: cover;" />
                                        </div>
                                    </div>

                                    <!-- Timezone -->
                                    <div class="form-group">
                                        <label class="form-control-label" for="input-timezone">{{ $t('label.timezone') }}</label>
                                        <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                                        <!-- {{user.timezone}} -->
                                        <div class="sp-tooltip d-block">
                                            <select name="timezone" required class="form-control" v-model="user.timezone" :disabled="userClockin" :placeholder="$t('label.timezone')">
                                                <option v-for="(timeZone, index) in timezonesInfo.timezones" :key="index" :value="timeZone.timezone">(UTC {{ timeZone.offset }}) {{ timeZone.new_name }}
                                                </option>
                                            </select>
                                            <span v-if="userClockin" class="sp-tooltiptext text-sm time-logged mb--3">
                                                {{ $t('label.user must be clocked out before you can change their timezone') }}.
                                            </span>
                                        </div>
                                    </div>

                                    <button type="button" class="btn btn-outline-default mt-4 mobile-w-100" data-toggle="modal" @click="toggleEditPasswordModal()">{{ $t('label.change password') }}</button>
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-primary mt-4">{{ $t('label.save profile') }}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-edit-password v-if="showEditPassword" @onClose="closeEditPasswordModal">
        </modal-edit-password>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex';

import ModalEditPassword from './ModalEditPassword.vue';

export default {
    components: {
        ModalEditPassword,
    },
    data() {
        return {
            timezonesInfo: {
                timezones: [],
            },
            showEditPassword: false,
            profileImg: null,
            selected: null,
            loader: null,
            user: JSON.parse(localStorage.getItem('user')),
            reloadKey: 0
        }
    },
    computed: {
        ...mapGetters({
            userClockin: 'time/userClockin',
        })
    },
    created() {
        this.getUser();

        this.$store.dispatch('time/getUserCurrentClockin');
    },
    methods: {
        getUser() {
            this.loader = this.$loading.show();
            axios.get('/profile/user', {}).then(response => {
                this.timezonesInfo.timezones = response.data.timezones;
                this.loader.hide();
            }).catch(err => console.error(err));
        },
        editProfile() {
            let url = '/updateProfile',
                action = 'updated',
                requested_fields = {
                    id: this.user.id,
                    first_name: this.user.first_name,
                    last_name: this.user.last_name,
                    name: this.user.first_name + ' ' + this.user.last_name,
                    email: this.user.email,
                    phone: this.user.phone,
                    timezone: this.user.timezone,
                };

            let formData = new FormData()
            for (let key in requested_fields)
                formData.append(key, requested_fields[key])

            // File photo upload
            if (this.profileImg)
                formData.append('photo', this.profileImg)

            axios.post(url, formData).then(rsp => {
                bus.$emit('banner-success', {
                    message: `Profile ${action} successfully!`,
                });

                // Update current user local storage
                if (rsp.data.data)
                    localStorage.setItem('user', JSON.stringify(rsp.data.data));
                this.$bus.$emit("ProfileEditUpdate");

            }).catch(err => {
                console.log('ERROR: ', err)
                bus.$emit('banner-error', { message: err, })
            });
        },

        selectedPhoto(e) {
            const files = e.target.files;
            const fileReader = new FileReader();

            fileReader.addEventListener('load', () => {
                this.user.picture = fileReader.result;
            });
            fileReader.readAsDataURL(files[0]);
            this.profileImg = files[0];
        },

        toggleEditPasswordModal() {
            this.showEditPassword = true;
        },
        closeEditPasswordModal() {
            this.showEditPassword = false;
        }
    }
}
</script>

<style scoped>
.time-logged {
    width: 200px;
    white-space: normal;
}
</style>