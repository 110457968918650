<template>
	<modal :name="modalName" @closed="onClose()" tabindex="-1" :classes="'rounded'" :shiftY="0.1" :min-height="400" width="525" :scrollable="true" height="auto" :reset="true" :adaptive="true">
		<div class="card-header">
			<div class="py-2 d-flex">
				<h5 class="modal-title mr-atuo" id="crewModalTitle">{{ modalTitle }}</h5>
				<button type="button" class="close ml-auto" data-dismiss="modal" @click="closeModal">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		</div>
		<div class="card-body">
			<form id="modalCrewAddEditForm" method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="saveCrew">
				<div class="row">
					<div class="col-12">

						<!-- Crew Name -->
						<div class="col-12">
							<div class="form-group">
								<label class="form-control-label text-muted" for="input-name">{{ $t('label.crew name') }}</label>
								<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

								<input v-model="modalFields.name" type="text" name="name" class="form-control" :placeholder="$t('label.crew name')" value="" required autofocus autocomplete="input-field" data-lpignore="true">
							</div>
						</div>

						<!-- Add employee btn -->
						<div class="col-12">
							<h5 class="modal-title mb-3">
								{{ $t('label.crew members') }}
								<div class="float-right">
									<button type="button" class="btn btn-sm btn-primary" @click="addCrewMember()">
										{{ $t('label.add member') }}
									</button>
								</div>
							</h5>
							<label class="form-control-label text-muted" for="input-name">
								{{ $t('label.foreman') }}</label>
							<div class="d-flex align-items-center mb-2" v-for="(user, i) in modalFields.crewMembers" :key="i">
								<div class="p-3" style=" background: #e9ecef; color: #fff; border-radius: 5px 0px 0px 5px; height: 46px; line-height: 15px;">
									<input type="radio" name="foreman" v-model="modalFields.foreman" :value="i" required autocomplete="input-field" data-lpignore="true" />
								</div>
								<div class="flex-grow-1">
									<select class="form-control rounded-0" v-model="user.user_id" required>
										<option v-for="(data, index) in allUsers" :value="data.id" :key="index">
											{{ data.name }}
										</option>
									</select>
								</div>
								<!-- Delete Crew member -->
								<div v-if="user.id" @click="removeCrewMemberEdit(i, user.id)" class="p-3 pointer" style=" background: #dd1a3e; color: #fff; border-radius: 0px 5px 5px 0px; height: 46px; line-height: 15px;">
									<i class="ri-delete-bin-line p-0"></i>
								</div>
								<div v-else @click="removeCrewMember(i)" class="p-3" style=" background: #dd1a3e; color: #fff; border-radius: 0px 5px 5px 0px; height: 46px; line-height: 15px;">
									<i class="ri-delete-bin-line p-0"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pl-3 pt-3" v-if="modalFormIsInvalid">
					<span class="text-sm text-danger">{{ modalMessage }}</span>
				</div>
			</form>
		</div>

		<div class="card-footer d-flex">
			<button type="submit" class="btn btn-primary mr-auto" form="modalCrewAddEditForm">
				{{ modalSubmitTitle }}
			</button>
			<button type="button" class="btn btn-secondary closeCrewModal ml-auto" data-dismiss="modal" @click="closeModal()">
				{{ $t('label.close') }}
			</button>
		</div>

		<ModalDeleteComponent
			v-if="showDltCrewMemberModal"
			:newId="'ModalCrewAddEdit'"
			:title="$t('label.ARE YOU SURE YOU WANT TO DELETE THIS CREW MEMBER')"
			@onClose="toggleDeleteModal()"
			@customDeleteMethod="deleteCrewMember()">
		</ModalDeleteComponent>
	</modal>
</template>
<script>
import bus from '@/utils/event-bus';
import ModalDeleteComponent from '@/components/ModalDelete2.vue';

export default {
	props: ['mode', 'newId', 'allCrews', 'allUsers'],
	emits: ['customCrewAddEditMethod', 'onClose'],
	components: {
		ModalDeleteComponent,
	},
	data() {
		return {
			modalName: 'crewModal' + this.newId,
			modalTitle: this.$t('label.add crew'),
			modalSubmitTitle: this.$t('label.create'),
			editMode: 0,
			crewIndex: 0,
			modalMessage: '',
			crewId: '',
			modalFields: {
				name: '',
				foreman: '',
				crewMembers: [{
					'user_id': ''
				}]
			},
			showDltCrewMemberModal: false,
			currMemberId: null,
			currMemberIdx: null,
		}
	},
	computed: {
		modalFormIsInvalid() {
			if (0 < this.modalFields.crewMembers.length) {
				return false;
			} else {
				return true;
			}
		},
	},
	mounted() {
		this.checkMode();
		this.$modal.show(this.modalName);
	},
	methods: {
		getAllCrews() {
			axios.get('/get-all-crews', {
				headers: { Authorization: 'Bearer ' + this.token }
			}).then(response => {
				this.allCrews = response.data.result;
			}).catch(err => console.error(err));
		},
		addCrewMember() {
			this.modalFields.crewMembers.push({
				name: '',
				foreman: '',
				crewMembers: 'user_id'
			});
		},
		saveCrew(event) {
			var a = this;
			if (this.modalFormIsInvalid)
				this.modalMessage = 'At least one member is required';

			var request_fields = {
				crew_id: this.crewId,
				name: this.modalFields.name,
				foreman: this.modalFields.foreman,
				members: this.modalFields.crewMembers,
			};
			let url = '/crews',
				action = 'added';

			if (1 == this.editMode) {
				url = '/crews/update';
				action = 'updated';
			}

			axios.post(url, request_fields).then(response => {
				this.resetModal();
				bus.$emit('banner-success', {
					message: `Crew ${action} successfully!`,
				});
				this.closeModal();
			}).catch(err => console.error(err));
		},
		checkMode() {
			if (this.mode == 'edit') {
				//Removes Users that are inactive
				let tempVar = JSON.parse(JSON.stringify(this.allCrews[this.newId].members));
				tempVar.forEach((data, index)=> {
					if(data.user.status != '1') {
						this.allCrews[this.newId].members.splice(index,1);
					}
				})

				this.modalTitle = 'Edit Crew';
				this.modalSubmitTitle = 'Update';
				this.editCrew();
			} else {
				this.modalTitle = 'Add Crew';
				this.modalSubmitTitle = 'Create';
				this.editMode = 0;
			}
		},
		editCrew(crewIndex) {
			var a = this;
			this.editMode = 1;
			this.crewId = this.allCrews[this.newId].id;
			this.modalFields.name = this.allCrews[this.newId].name;
			this.modalFields.crewMembers = JSON.parse(JSON.stringify(this.allCrews[this.newId].members));
			this.allCrews[this.newId].members.forEach(function (item, index) {
				if (item.is_foreman == 1) {
					a.modalFields.foreman = index;
				}
			});
		},
		removeCrewMember(index) {
			this.modalFields.crewMembers.splice(index, 1);
		},
		removeCrewMemberEdit(index, id) {
			this.currMemberId = id;
			this.currMemberIdx = index;
			this.showDltCrewMemberModal = true;
		},
		toggleDeleteModal() {
			this.showDeleteContact = false;
		},
		deleteCrewMember() {
			let sendData = {
				id: this.currMemberId
			}
			axios.post('/delete-crew-member', sendData)
				.then(res => {
					this.modalFields.foreman = null;
					this.modalFields.crewMembers.splice(this.currMemberIdx, 1);
					this.showDltCrewMemberModal = false;
					this.currMemberId = null;
					this.currMemberIdx = null;
					bus.$emit('banner-success', {
						message: `User removed from crew successfully!`,
					});
				});
		},
		resetModal() {
			this.editMode = 0;
			this.modalTitle = 'Add Crew';
			this.modalSubmitTitle = 'Create';
			this.resetModalFields();
		},
		resetModalFields() {
			this.crewId = '';
			this.modalFields.name = '';
			this.modalFields.foreman = '';
			this.modalFields.crewMembers.user_id = '';
			this.editMode = 0;
		},
		closeModal() {
			this.onClose();
			this.$modal.hide(this.modalName);
		},
		onClose() {
			this.$emit('onClose', false);
		},
	}
}
</script>