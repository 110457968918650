<template>
  <span @dragover="handleOnDragOver" @drop="onDrop($event, dropData)">
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: ['onDragOver', 'onDragLeave', 'onDrop', 'dropData'],
  setup(props) {
    const handleOnDragOver = event => {
      event.preventDefault();
    }

    return { handleOnDragOver };
  }
}
</script>