<template>
    <div class="card">
        <div class="card-header">
            <h3 class="d-flex align-items-center mb-0">
                <i class="ri-history-line"></i> {{ $t('label.activity log') }}
            </h3>
        </div>
        <div class="card-body">
            <div v-for="(data, index) in vpoActivityLogs" :key="index" class="timeline timeline-one-side" data-timeline-content="axis" data-timeline-axis-style="line">
                <!-- DRAFT CREATED -->
                <div v-if="index == 0" class="timeline-block">
                    <span class="timeline-step badge-activity-log">
                        <i class="ri-article-line p-0"></i>
                    </span>
                    <div class="timeline-content">
                        <span class="d-block">
                            {{ $t('label.vpo') }} created with status of {{ data.vpo_status.name }} by
                            <span class="link cursor-pointer" @click="getUserInfo(data.created_by.id, 'Uploaded By')">
                                {{ data.created_by.name }}
                            </span>
                        </span>
                        <div class="date">{{ data.created_at | formatDateWithTime }}</div>
                    </div>
                </div>
                <!-- REQUESTED -->
                <div v-else-if="data.vpo_status.name == 'Requested'" class="timeline-block">
                    <span class="timeline-step badge-activity-log">
                        <i class="ri-mail-send-line p-0"></i>
                    </span>
                    <div class="timeline-content">
                        <span class="d-block">
                            {{ $t('label.vpo') }} Sent and {{ data.vpo_status.name }} by
                            <span class="link cursor-pointer" @click="getUserInfo(data.created_by.id, 'Uploaded By')">
                                {{ data.created_by.name }}
                            </span>
                        </span>
                        <div class="date">{{ data.created_at | formatDateWithTime }}</div>
                    </div>
                </div>

                <!-- APPROVED -->
                <div v-else-if="data.vpo_status.name == 'Approved'" class="timeline-block">
                    <span class="timeline-step badge-activity-log">
                        <i class="ri-checkbox-circle-line p-0"></i>
                    </span>
                    <div class="timeline-content">
                        <span class="d-block">
                            {{ $t('label.vpo') }} marked as {{ data.vpo_status.name }} by
                            <span class="link cursor-pointer" @click="getUserInfo(data.created_by.id, 'Uploaded By')">
                                {{ data.created_by.name }}
                            </span>
                        </span>
                        <div class="date">{{ data.created_at | formatDateWithTime }}</div>
                    </div>
                </div>

                <!-- Invoiced -->
                <div v-else-if="data.vpo_status.name == 'Invoiced'" class="timeline-block">
                    <span class="timeline-step badge-activity-log">
                        <i class="ri-money-dollar-circle-line p-0"></i>
                    </span>
                    <div class="timeline-content">
                        <span class="d-block">
                            {{ $t('label.vpo') }} marked as {{ data.vpo_status.name }} by
                            <span class="link cursor-pointer" @click="getUserInfo(data.created_by.id, 'Uploaded By')">
                                {{ data.created_by.name }}
                            </span>
                        </span>
                        <div class="date">{{ data.created_at | formatDateWithTime }}</div>
                    </div>
                </div>

                <!-- DENIED -->
                <div v-else-if="data.vpo_status.name == 'Denied'" class="timeline-block">
                    <span class="timeline-step badge-activity-log">
                        <i class="ri-close-line text-danger p-0"></i>
                    </span>
                    <div class="timeline-content">
                        <span class="d-block">
                            {{ $t('label.vpo') }} marked as {{ data.vpo_status.name }} by
                            <span class="link cursor-pointer" @click="getUserInfo(data.created_by.id, 'Uploaded By')">
                                {{ data.created_by.name }}
                            </span>
                            <div class="date">{{ data.created_at | formatDateWithTime }}</div>
                        </span>
                    </div>
                </div>

                <!-- CANCELLED -->
                <div v-else-if="data.vpo_status.name == 'Cancelled'" class="timeline-block">
                    <span class="timeline-step badge-activity-log">
                        <i class="ri-close-line p-0"></i>
                    </span>
                    <div class="timeline-content">
                        <span class="d-block">
                            {{ $t('label.vpo') }} {{ data.vpo_status.name }} by
                            <span class="link cursor-pointer" @click="getUserInfo(data.created_by.id, 'Uploaded By')">
                                {{ data.created_by.name }}
                            </span>
                        </span>
                        <div class="date">{{ data.created_at | formatDateWithTime }}</div>
                    </div>
                </div>

                <!-- COMPLETED -->
                <div v-else-if="data.vpo_status.name == 'Completed'" class="timeline-block">
                    <span class="timeline-step badge-activity-log">
                        <i class="ri-checkbox-circle-fill text-success"></i>
                    </span>
                    <div class="timeline-content">
                        <span class="d-block">
                            {{ $t('label.vpo') }} ({{ data.vpo.order_number }}) marked as {{ data.vpo_status.name }} by
                            <span class="link cursor-pointer" @click="getUserInfo(data.created_by.id, 'Uploaded By')">
                                {{ data.created_by.name }}
                            </span>
                        </span>
                        <div class="date">{{ data.created_at | formatDateWithTime }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';

export default {
    props: ['vpoId', 'vpoStatus'],
    emits: [''],
    components: {},
    watch: {
        'vpoStatus.name'() {
            this.loadActivityLogs();
        }
    },
    data() {
        return {
            vpoActivityLogs: []
        }
    },
    mounted() {
        bus.$on('reloadActivityLogs', () => {
            this.loadActivityLogs();
        });
        this.loadActivityLogs();
    },
    methods: {
        loadActivityLogs() {
            return axios.get(`/get-vpo-activity-logs/` + this.vpoId).then(rsp => {
                this.vpoActivityLogs = rsp.data.result;
            }).catch(err => console.log(err));
        }
    },
}
</script>
