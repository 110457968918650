<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50" id="jobTypesContainer">
            <!-- HEADER -->
            <div class="d-flex" style="z-index: 100">
                <!-- Breadcrumbs -->
                <breadcrumbs :title="$t('label.custom fields')">
                    <!-- Breakcrumb slots -->
                    <li class="breadcrumb-item">
                        {{ $t('label.admin') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.content management') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.custom fields') }}
                    </li>
                </breadcrumbs>
            </div>

            <!-- Content -->
            <div class="card">
                <div class="card-header" style="border-bottom: 0px !important">
                    <div class="row m-0 align-items-center justify-content-between">
                        <h3 class="mb-0 d-flex align-items-center">{{ $t('label.custom fields') }}</h3>
                        <button class="btn btn-sm btn-default m-0" @click="$modal.show('addCustomFieldModal')">
                            {{ $t('label.add custom field') }}
                        </button>
                    </div>
                </div>

                <div class="table-responsive pb-4">
                    <table class="table table-flush border-bottom">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">{{ $t('label.name') }}</th>
                                <th scope="col">{{ $t('label.job') }}/{{ $t('label.task') }}</th>
                                <th scope="col">{{ $t('label.creation date') }}</th>
                                <th scope="col">{{ $t('label.custom field status') }}</th>
                                <th scope="col">{{ $t('label.type') }}</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <!--  Loop each custom field in list  -->
                            <tr v-for="customfield in paginatedCustomFields" :key="customfield.id">
                                <td>{{ customfield.name }}</td>
                                <td>{{ customfield.model_name }}</td>
                                <td>{{ customfield.created_at | formatDate }}</td>
                                <td>{{ customfield.status ? 'Active' : 'Inactive' }}</td>
                                <td>{{ customfield.type | capitalize }}</td>
                                <td class="text-right">
                                    <div class="dropdown">
                                        <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-ellipsis-v"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                            <!-- Edit -->
                                            <a class="dropdown-item d-flex align-items-center pointer" @click="editItem(customfield)" data-toggle="modal">
                                                <i class="ri-pencil-line"></i>{{ $t('label.edit custom field') }}
                                            </a>
                                            <!-- Delete -->
                                            <a v-if="!customfield.is_active" class="dropdown-item d-flex align-items-center text-danger pointer" @click="deleteItem(customfield.id)">
                                                <i class="ri-delete-bin-line"></i>{{ $t('label.delete custom field') }}
                                            </a>
                                            <a v-else class="dropdown-item d-flex align-items-center text-danger pointer" style="opacity: 0.6" :title="$t('label.custom field in use and cannot be deleted') + '.'" @click="bannerError($t('label.custom field is currently in use and cannot be deleted') + '.')">

                                                <i class="ri-delete-bin-line"></i>{{ $t('label.delete custom field') }}
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table-pagination v-model="paginatedCustomFields" :all-rows="jobCustomFields" :perPage="15">
                    </table-pagination>
                </div>
            </div>
        </div>

        <!-- Add Custom Field Modal -->
        <modal name="addCustomFieldModal" height="auto" width="98%" :classes="'rounded'" :shiftY="0.1" :max-width="640" :reset="true" :scrollable="true" :adaptive="true">
            <custom-field-add :fieldTypes="customFieldTypes" @cancelFnc="cancelFnc" @returnFnc="returnFnc" />
        </modal>

        <!-- Edit Custom Field Modal -->
        <modal name="editCustomFieldModal" height="auto" width="98%" :classes="'rounded'" :shiftY="0.1" :max-width="640" :reset="true" :scrollable="true" :adaptive="true">
            <custom-field-edit :editItem="selectedItem" :fieldTypes="customFieldTypes" @cancelFnc="cancelFnc" @returnFnc="returnFnc" />
        </modal>

        <!-- Delete Modal -->
        <modal-delete2 v-if="deleteId" :newId="deleteId" @onClose="onCloseDltModal" @customDeleteMethod="deleteConfirm">
        </modal-delete2>
    </div>
</template>

<style></style>

<script>
import CustomFieldAdd from '@/components/Admin/CustomFields/CustomFieldAdd';
import CustomFieldEdit from '@/components/Admin/CustomFields/CustomFieldEdit';
import ModalDelete2 from '@/components/ModalDelete2';
import TablePagination from '@/components/TablePagination.vue';
import bus from '@/utils/event-bus';

export default {
    props: [],
    components: { CustomFieldEdit, CustomFieldAdd, TablePagination, ModalDelete2 },
    data() {
        return {
            jobCustomFields: [],
            paginatedCustomFields: [],
            customFieldTypes: null,
            selectedItem: null,
            deleteId: 0
        }
    },
    mounted() {
        this.loader = this.$loading.show();
        let promises = [
            this.loadCustomFields(),
            this.loadCustomFieldTypes()
        ];

        Promise.all(promises).then(() => {
            this.loader.hide();
        });
    },
    methods: {
        loadCustomFields() {
            return axios.get('/loadCustomFields').then(response => {
                this.jobCustomFields = response.data;
            }).catch(err => {
                console.log('ERROR: ', err);
                bus.$emit('banner-error', { message: err, });
            });
        },
        loadCustomFieldTypes() {
            return axios.get('/getCustomFieldTypes').then(response => {
                this.customFieldTypes = response.data;
            }).catch(err => {
                console.log('ERROR: ', err);
                bus.$emit('banner-error', { message: err, });
            })
        },

        editItem(item) {
            this.selectedItem = item;
            this.$modal.show('editCustomFieldModal');
        },
        deleteItem(id) {
            this.deleteId = id;
        },
        deleteConfirm() {
            axios.post('/customFieldsDelete', { id: this.deleteId }).then(response => {
                // let idx = this.jobCustomFields.findIndex(field => {
                //     if(field.id === this.deleteId)
                //         return true;
                // });

                // this.jobCustomFields.splice(idx, 1);

                this.loadCustomFields();
                bus.$emit('banner-success', {
                    message: 'Custom Field Deleted Successfully!',
                });
            }).catch(err => {
                console.log('ERROR: ', err);
                bus.$emit('banner-error', { message: err, });
            });
        },
        onCloseDltModal() {
            this.deleteId = null;
        },
        cancelFnc() {
            this.$modal.hide('editCustomFieldModal');
            this.$modal.hide('addCustomFieldModal');
        },
        returnFnc(customField) {
            // let index = this.jobCustomFields.findIndex(item => {
            //     if(item.id === customField.id) return true
            // });
            // // Update edited item values
            // if(index >= 0){                
            //     this.$set(this.jobCustomFields, index, customField);
            // }
            // // New Item, add to list
            // else{
            //     this.jobCustomFields.push(customField);
            // }
            this.loadCustomFields();
            this.cancelFnc();
        },
        bannerError(msg) {
            bus.$emit('banner-error', { message: msg });
        },
    }
}
</script>