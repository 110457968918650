<template>
    <div class="card">
        <div class="card-header">
            <h3 class="d-flex align-items-center mb-0">
                {{ $t('label.description, special instructions & attachments') }}
            </h3>
        </div>
        <div class="card-body">
            <div class="row mb-3">
                <div class="col-12">
                    <label class="form-control-label text-muted">{{ $t('label.description or special instructions') }}</label>
                    <textarea v-model="formComment.comments" name="comments" class="form-control" :placeholder="$t('label.description or special instructions')" rows="5" required></textarea>
                </div>
            </div>
            <!-- hide attachments per AC -jack 09.16.2022 -->
            <!-- <div class="row">
                <div class="col-12">
                    <div class="form-group dropzone-file m-0">
                        <label class="form-control-label text-muted">{{ $t('label.attachments') }}</label>
                    </div>
                    <div class="row m-0 pt-4 px-2">
                        <div class="col-6 d-flex pb-3" v-for="(file, index) in formComment.selectedEntry.attachments" :key="index">
                            <div class="align-items-center flex-grow-1">
                                <template v-if="file.id">
                                    <div class="">
                                        <img class="image-container image-container-thumbnail float-left mr-2" :src="file.file_type == 'application/pdf' ? '/images/icons/doc-pdf.png' : '/' + file.download" />
                                    </div>
                                    <div class="">
                                        <span class="cursor-pointer text-bold" @click="selectFile(file, 'view')">
                                            <span v-if="file.display_name">{{ file.display_name }}</span>
                                            <span v-else>{{ file.name }}</span>
                                        </span>
                                    </div>
                                </template>
                                <template v-else class="form-group">
                                    <div class="">
                                        <img class="image-container image-container-thumbnail float-left mr-2" :src="(file.fileType === 'image/jpeg' || file.fileType === 'image/png') ? file.image : '/images/icons/doc-pdf.png'" />
                                    </div>
                                    <div class="">
                                        <span class="cursor-pointer text-bold" @click="selectFile(file, 'view')">{{ file.fileName }}</span>
                                    </div>
                                </template>
                            </div>
                            <div class="pl-2 ml-auto">
                                <button class="btn btn-danger py-0 px-2" @click="deleteItem(file, index)">
                                    <i class="ri-delete-bin-line heading-title p-0"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group dropzone-file m-0">
                        <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions" v-on:vdropzone-success="getUploadedFile" v-on:vdropzone-processing="uploadingFile()"></vue-dropzone>
                    </div>
                </div>
            </div> -->
            <!-- <div class="">
                <div v-show="viewTemplate === 'delete'" class="bg-gradient-danger">
                    <div class="py-3 text-center">
                        <i class="ri-error-warning-line ni-3x"></i>
                        <h4 class="heading mt-4 text-">{{ $t('label.ARE YOU SURE YOU WANT TO DELETE THIS FILE') }}?</h4>
                        <p>{{ $t('label.This action cannot be undone') }}!</p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
;

export default {
    props: ['formComment', 'isValid', 'poType'],
    emits: [''],
    components: {
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            dropzoneOptions: {
                // url: 'https://httpbin.org/post',
                url: 'https://putsreq.com/sMLPgj8Pe0jHquCDgZcs',
                acceptedFiles: 'image/*, application/pdf',
                maxFilesize: 10,
                addRemoveLinks: true,
                dictDefaultMessage: `<div><span>Drag and drop files here</span><br>
                                <span>OR</span><br>
                                <button type="button"class="btn btn-md btn-default">Browse Files</button></div>`,
            },
            viewTemplate: "form",
            disableSubmit: false,
            selectedFile: {
                id: null
            },
        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        getUploadedFile(file) {
            setTimeout(function () {
                this.formComment.selectedEntry.attachments.push({
                    image: file.dataURL,
                    fileName: file.name,
                    fileType: file.type,
                    file: file
                });

                // Clear the dropzone for more files
                this.$refs.myVueDropzone.removeAllFiles(true);
                this.disableSubmit = false;
            }.bind(this), 1000);
        },
        uploadingFile() {
            this.disableSubmit = true;
        },
        selectFile(file, viewTemplate) {
            this.selectedFile = file;
            this.viewTemplate = viewTemplate;
        },
        deleteItem(data, idx) {
            this.formComment.selectedEntry.attachments.splice(idx, 1);

            if (this.poType == 'edit') {
                this.formComment.deletedAttachments.push(data.id);
            }

        }
    },
}
</script>
