/* eslint-disable no-unused-vars */
import store from '@/modules/Auth/store';
import { getDomain } from '@/utils/helpers';
import axios from 'axios';
import Vue from 'vue';
import router from './registerRouter.plugin';
// import App from '../main';
// import 'xdomain';

const env = process.env;
// xdomain.slaves({
//   'http://127.0.0.1:8000': '/jcsd56domdsnsdf.html',
//   'https://api.spdevawt.com': '/jcsd56domdsnsdf.html',
// });

if (env.VUE_APP_API_URL == null) {
  console.error(`ENV "VUE_APP_API_URL" does't exist!`);
}

const domain = env.VUE_APP_API_URL;

/**
 * Default Axios Config
 */
export const BaseApi = axios.create({
  baseURL: domain + '/api/v1',
  headers: {
    'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    Authorization: `Bearer ${localStorage.getItem('_token')}`
  }
});

/*
 * Add a Request Interceptor
 */
BaseApi.interceptors.request.use(
  async (config) => {
    // Set Authorization Header before sending request
    const token = window.localStorage.getItem('_token');
    if (token) config.headers.Authorization = `Bearer ${token}`;

    /**
     * Set Multi-Tenant Header before sending request
     * @ Header : X-Tenant
     * @ Header : X-Supervisor
     */
    const windowDomain = getDomain().subDomain;
    let subdomain, admin_selected_company, supervisorHeader;


    if (windowDomain != 'admin') {
      const userDomain = await store.state.domain;
      subdomain = localStorage.getItem('subdomain') || userDomain?.domain;
    } else {
      subdomain = 'admin';
      admin_selected_company = localStorage.getItem('admin_selected_company_domain');
      console.log(admin_selected_company);
      supervisorHeader = null;
      if (admin_selected_company) {
        subdomain = admin_selected_company
        supervisorHeader = 'admin';
      }
    }

    config.headers['X-Tenant'] = subdomain;
    config.headers['X-Supervisor'] = supervisorHeader;

    // Return the Configured Headers
    return config;
  },
  (error) => Promise.reject(error)
);

/*
 * Add a Response Interceptor
 */
BaseApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error && error.response.status == 500) {
      bus.$emit('banner-error', { message: "Something went wrong. Page will refresh in 5 seconds." })
      setTimeout(() => {
        router.push('/')
      }, 5000)
    }

    if (error && error.response) {
      if ((error.response.status === 401 || error.response.status === 406)) {
        if (window.location.pathname != '/login' || window.location.pathname == '') {
          window.localStorage.clear();
          window.location.href = '/login';
        }
      }
      return Promise.reject(error.response);
    }
  }
);

/**
 * Set API Header on move
 * 
 * @param String key 
 * @param {*} value 
 * -----------------------------------------------------
 * * Depriciated * *
 * -----------------------------------------------------
 */
export const setApiHeader = (key, value) => {
  axios.defaults.headers.common[key] = value;
}

export default {
  install(app, options) {
    Vue.prototype.$http = BaseApi;
  }
};

export const api = BaseApi;
