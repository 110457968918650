<template>
    <div>
        <div class="dashboard-page" :class="isMobile ? 'mb-50p' : ''">
            <div class="header bg-primary pb-6 mobile-p-0">
                <div class="container-fluid">
                    <div class="header-body">
                        <div v-if="!isMobile" class="row align-items-center py-4">
                            <div class="col-lg-6 col-7">
                                <h6 class="h2 text-white d-inline-block mb-0">{{ $t('label.dashboard') }}</h6>
                            </div>
                            <div class="col-lg-6 col-5 text-right">
                                <!-- Looks like an option to view and configure other user's dashboard -->
                                <!-- <h6 v-if="user.role_id == 1 | user.role_id == 2" class="h2 text-white d-inline-block mb-0">{{ $t('label.view') }}: </h6> -->
                                <!-- <div v-if="user.role_id == 1 | user.role_id == 2" class="dropdown">
                                    <a class="btn btn-neutral d-none" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="selectedCompany">
                                        {{ $t('label.company') }} <i class="fas fa-chevron-down"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-bottom dropdown-menu-arrow">
                                        <button v-for="(item, index) in employees" :key="index" class="pointer dropdown-item" @click="getAllWidgets(item)">{{item.name}}</button>
                                    </div>
                                </div> -->
                                <button class="btn" :class="configureDashboard ? 'btn-default' : 'btn-neutral'" @click="configureDashboard = !configureDashboard; draggable = !draggable">{{ $t('label.configure dashboard') }}</button>
                            </div>
                        </div>
                        <div v-else class="py-3">
                            <div class="row m-0 d-flex justify-content-between align-items-center">
                                <h6 class="h2 text-white mb-0 col-sm">{{ $t('label.dashboard') }}</h6>
                                <button class="btn col-sm" :class="configureDashboard ? 'btn-default' : 'btn-neutral'" @click="configureDashboard = !configureDashboard; draggable = !draggable">{{ $t('label.configure dashboard') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Configure Dashboard Modal -->
            <div v-if="configureDashboard" class="container-fluid mt--6" :class="isMobile ? 'mt-165p mb-165np' : ''">
                <div class="row">
                    <div class="col">
                        <div class="card job-search">
                            <div class="card-header">
                                <h3 class="mb-0">{{ $t('label.configure dashboard') }}</h3>
                            </div>
                            <div class="card-body">
                                <h4 class="font-weight-normal mb-4">{{ $t('label.Show and hide dashboard widgets below') }}.</h4>
                                <div class="row">
                                    <div class="col-lg-3 col-md-6 col-12">
                                        <h3 class="">{{ $t('label.stats widgets') }}</h3>
                                        <div v-if="item.type == 'stats' && (user.role_id != 3 && user.role_id != 4)" v-for="(item, index) in allwidgets" :key="index" class="d-flex justify-content-between align-items-center col-8 p-0 mb-2">
                                            <h4 class="font-weight-normal">{{ item.label }}</h4>
                                            <div class="">
                                                <label class="custom-toggle  custom-toggle-default">
                                                    <input type="checkbox" v-model="item.show" @click="toggleWidget(item, index)">
                                                    <span class="custom-toggle-slider rounded-circle" data-label-off="Hide" data-label-on="Show"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div v-if="item.type == 'stats' && (user.role_id == 3) && item.label != 'Open Jobs' && item.label != 'Closed Jobs'" v-for="(item, index) in allwidgets" :key="index" class="d-flex justify-content-between align-items-center col-8 p-0 mb-2">
                                            <h4 class="font-weight-normal">{{ item.label }}</h4>
                                            <div class="">
                                                <label class="custom-toggle  custom-toggle-default">
                                                    <input type="checkbox" v-model="item.show" @click="toggleWidget(item, index)">
                                                    <span class="custom-toggle-slider rounded-circle" data-label-off="Hide" data-label-on="Show"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-12">
                                        <h3 class="">Table Widgets</h3>
                                        <div v-if="item.type == 'table' && (user.role_id != 3)" v-for="(item, index) in allwidgets" :key="index" class="d-flex justify-content-between align-items-center col-8 p-0 mb-2">
                                            <h4 class="font-weight-normal">{{ item.label }}</h4>
                                            <div class="">
                                                <label class="custom-toggle  custom-toggle-default">
                                                    <input type="checkbox" v-model="item.show" @click="toggleWidget(item, index)">
                                                    <span class="custom-toggle-slider rounded-circle" data-label-off="Hide" data-label-on="Show"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div v-if="item.type == 'table' && (user.role_id == 3) && item.label != 'Open Jobs' && item.label != 'Overdue Jobs' && item.label != 'Recently Closed Jobs' && item.label != 'Recently Added Jobs'" v-for="(item, index) in allwidgets" :key="index" class="d-flex justify-content-between align-items-center col-8 p-0 mb-2">
                                            <h4 class="font-weight-normal">{{ item.label }}</h4>
                                            <div class="">
                                                <label class="custom-toggle  custom-toggle-default">
                                                    <input type="checkbox" v-model="item.show" @click="toggleWidget(item, index)">
                                                    <span class="custom-toggle-slider rounded-circle" data-label-off="Hide" data-label-on="Show"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="user.role_id == 1 || user.role_id == 2" class="col-lg-3 col-md-6 col-12">
                                        <h3 class="">{{ $t('label.timesheet widgets') }}</h3>
                                        <div v-for="(item, index) in allwidgets" :key="index" class="d-flex justify-content-between align-items-center col-8 p-0 mb-2">
                                            <template v-if="item.type == 'timesheet'">
                                                <h4 v-if="item.label != 'Employees on PTO'" class="font-weight-normal">{{ item.label }}</h4>
                                                <div v-if="item.label != 'Employees on PTO'" class="">
                                                    <label class="custom-toggle  custom-toggle-default">
                                                        <input type="checkbox" v-model="item.show" @click="toggleWidget(item, index)">
                                                        <span class="custom-toggle-slider rounded-circle" data-label-off="Hide" data-label-on="Show"></span>
                                                    </label>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-12">
                                        <h3 class="">{{ $t('label.request widgets') }}</h3>
                                        <div v-for="(item, index) in allwidgets" :key="index" class="d-flex justify-content-between align-items-center col-8 p-0 mb-2">
                                            <template v-if="item.type == 'request'">
                                                <h4 class="font-weight-normal">{{ item.label }}</h4>
                                                <div class="">
                                                    <label class="custom-toggle custom-toggle-default">
                                                        <input type="checkbox" v-model="item.show" @click="toggleWidget(item, index)">
                                                        <span class="custom-toggle-slider rounded-circle" data-label-off="Hide" data-label-on="Show"></span>
                                                    </label>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Widgets -->
            <div class="container-fluid pb-5" :class="{ 'mt-165p': isMobile, 'mt--6': !configureDashboard }">
                <!-- Widget Stats -->
                <draggable :list="layout" ghost-class="ghost" handle=".handle" class="row" @end="updateUserWidgets">
                    <div class="w-100 col-lg-4" v-for="item in layout" :key="item.i" v-if="item.show && item.type == 'stats'">
                        <widget-stat v-if="item.label == 'Closed Jobs'" :name="item.label" :data="closedJobs"></widget-stat>
                        <widget-stat v-if="item.label == 'Closed Tasks'" :name="item.label" :data="closedTasks"></widget-stat>
                        <widget-stat v-if="item.label == 'Open Jobs'" :name="item.label" :data="openJobs"></widget-stat>
                        <widget-stat v-if="item.label == 'Open Tasks'" :name="item.label" :data="openTasks"></widget-stat>
                        <widget-stat v-if="item.label == 'Time Today'" :name="item.label" :data="timeToday"></widget-stat>
                        <widget-stat v-if="item.label == 'Time This Week'" :name="item.label" :data="timeThisWeek"></widget-stat>
                        <widget-stat v-if="item.label == 'Time This Month'" :name="item.label" :data="timeThisMonth"></widget-stat>
                    </div>
                </draggable>

                <!-- Widget Table -->
                <draggable :list="layout" ghost-class="ghost" handle=".handle" class="row" @end="updateUserWidgets">
                    <div class="w-100 col-lg-6 pb-3" v-for="item in layout" :key="item.i" v-if="item.show && item.type == 'table'">
                        <!-- Job Widgets -->
                        <jobs-widget v-if="item.label == 'Open Jobs'" :widget-type="'open'" :data="openJobsTable"></jobs-widget>
                        <jobs-widget v-if="item.label == 'Overdue Jobs'" :widget-type="'overdue'" :data="overdueJobsTable"></jobs-widget>
                        <jobs-widget v-if="item.label == 'Recently Closed Jobs'" :widget-type="'closed'" :data="recentlyClosedJobsTable"></jobs-widget>
                        <jobs-widget v-if="item.label == 'Recently Added Jobs'" :widget-type="'added'" :data="recentlyAddedJobsTable"></jobs-widget>

                        <!-- Task Widgets -->
                        <tasks-widget v-if="item.label == 'Open Tasks'" :widget-type="'open'" :data="openTasksTable"></tasks-widget>
                        <tasks-widget v-if="item.label == 'Overdue Tasks'" :widget-type="'overdue'" :data="overdueTasksTable"></tasks-widget>
                        <tasks-widget v-if="item.label == 'Recently Closed Tasks'" :widget-type="'closed'" :data="recentlyClosedTasksTable"></tasks-widget>

                        <!-- Admin Widgets -->
                        <span v-if="user.role_id == 1 || user.role_id == 2">
                            <widget-table v-if="item.label == 'Clocked In Employees'" :tableid="item.i" :name="item.label" :type="'timesheet'" :data="clockedinEmployeesTable" :columns="clockedinEmployeesColumns"></widget-table>
                            <widget-table v-if="item.label == 'Clocked In Employees (Over 8 Hours)'" :tableid="item.i" :name="item.label" :type="'timesheet'" :data="clockedinEmployeesOver8hoursTable" :columns="clockedinEmployeesOver8hoursColumns"></widget-table>
                            <!-- <widget-table v-if="item.label == 'Employees on PTO'" :tableid="item.i" :name="item.label" :type="'pto'" :data="employeesOnPTOTable" :columns="employeesOnPTOColumns"></widget-table> -->
                        </span>
                    </div>
                </draggable>

                <!-- Time Off Widget -->
                <!-- <div class="w-100" v-for="item in filterActiveRequestWidgets" :key="item.i">  -->
                <!-- <time-off-request-widget v-if="item.label === 'Time Off'"></time-off-request-widget> -->
                <!-- </div>                 -->
            </div>

            <!-- Job edit Modal -->
            <modal-job-edit v-if="modalJobEdit" :jobdata="taskdata" source="jobs"></modal-job-edit>

            <!-- Job Close Confirmation Modal -->
            <modal-confirm-job-close v-if="showCloseJobConf" z:index="3" :job-id="jobid" :custom-confirm-method="true" @on-close-modal="toggleJobCloseModal" @on-confirm-method="closeJob"></modal-confirm-job-close>

            <!-- Clock out notice modal -->
            <div class="modal fade" id="clockOutNoti2" tabindex="-1" role="dialog" aria-labelledby="clockOutNoti2" aria-hidden="true">
                <div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
                    <div class="modal-content bg-gradient-danger">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="clockOut">
                            <input type="hidden" id="time_id" v-model="time_id" value="" />
                            <div class="modal-body">
                                <div class="py-3 text-center">
                                    <i class="ri-stop-circle-line ni-3x"></i>
                                    <h4 class="heading mt-4 text-uppercase">{{ $t('label.CONFIRM CLOCK OUT OF MEMBER') }}</h4>
                                    <p>{{ $t('label.Are you sure you want to clock out your member') }}?</p>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="submit" class="btn btn-white">{{ $t('label.Yes, Clock Out') }}</button>
                                <button type="button" class="btn btn-link text-white ml-auto closeclockOutNoti2" data-dismiss="modal">{{ $t('label.cancel') }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Time change modal -->
            <div class="modal" id="viewReviseTimeModal" tabindex="-1" role="dialog" aria-labelledby="viewReviseTimeModalTitle" aria-hidden="true">
                <div class="modal-dialog" role="document" style="max-width: 1000px;">
                    <div class="modal-content">
                        <div class="modal-header border-bottom">
                            <h5 class="modal-title" id="viewReviseTimeModalTitle">{{ $t('label.revise time') }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#viewTaskDetailsModal">
                                <i class="ri-close-line"></i>
                            </button>
                        </div>
                        <div class="modal-body p-0">
                            <div class="row align-items-center justify-content-around border-bottom m-0 p-2">
                                <div class="cursor-pointer">
                                    <img class="rounded-circle" :src="userpicture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                </div>
                                <div class="col-9 col-xs-9 pl-0">
                                    <h3 class="mb-0">{{ username }}</h3>
                                    <h4 class="mb-0 font-weight-normal">{{ task_manager == '1' ? 'Crew Manager' : 'Crew Member' }}</h4>
                                </div>
                                <div class="col-2 col-xs-12 p-0 text-right">
                                    <button type="button" class="btn btn-default btn-sm" @click="addTimePunch()">
                                        {{ $t('label.add time entry') }}
                                    </button>
                                </div>
                            </div>
                            <div class="">
                                <table class="table table-sm table-flush text-center">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col" class="text-left pl-5" style="width: 180px;">{{ $t('label.date') }}</th>
                                            <th scope="col" style="width: 180px;">{{ $t('label.time start') }} (HH:MM)</th>
                                            <th scope="col" style="width: 180px;">{{ $t('label.time stop') }} (HH:MM)</th>
                                            <th scope="col">{{ $t('label.time clocked') }}</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="entries" v-for="(dt, idx) in userTimePunches" :key="idx">
                                            <td><input name="date" :id="idx" class="form-control h-35 datepicker bg-white" data-date-format="mm/dd/yyyy" v-model="dt.date" readonly></td>
                                            <td><input name="time_in" class="form-control h-35 flatpickr datetimepicker bg-white" v-model="dt.timein" readonly></td>
                                            <td><input name="time_out" class="form-control h-35 flatpickr datetimepicker bg-white" v-model="dt.timeout" readonly></td>
                                            <td>
                                                <span v-if="dt.timeout != '00:00'">{{ dt.durationComputed }}</span>
                                                <button v-if="dt.timeout == '00:00'" type="button" class="btn btn-clockin btn-link btn-sm">{{ $t('label.clocked in') }}</button>
                                            </td>
                                            <td class="text-right">
                                                <button class="btn btn-icon-only btn-link mr-0" @click="updateTimePunch(dt)">
                                                    <i class="ri-save-line heading-title"></i>
                                                </button>
                                                <button class="btn btn-icon-only btn-link" data-toggle="modal" data-target="#deleteModal1" @click="deleteNotify(dt.id)">
                                                    <i class="ri-delete-bin-line text-danger heading-title"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        <!-- <tr id="newEntry" v-if="addTimePunches.length > 0" v-for="(dt,idx) in addTimePunches" :key="'new'+idx"> -->
                                        <tr id="newEntry" v-for="(dt, idx) in addTimePunches" :key="'new' + idx">
                                            <td><input :id="'new' + idx" name="date" class="form-control h-35 datepicker newdate" data-date-format="mm/dd/yyyy" v-model="dt.date" readonly></td>
                                            <td><input name="time_in" class="form-control h-35 flatpickr datetimepicker" v-model="dt.timein" readonly></td>
                                            <td><input name="time_out" class="form-control h-35 flatpickr datetimepicker" v-model="dt.timeout" readonly></td>
                                            <td></td>
                                            <td class="text-right">
                                                <button class="btn btn-icon-only btn-link mr-0" @click="updateTimePunch(dt)">
                                                    <i class="ri-save-line heading-title"></i>
                                                </button>
                                                <button class="btn btn-icon-only btn-link" @click="deleteNewTimePunch(idx)">
                                                    <i class="ri-delete-bin-line text-danger heading-title"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col" colspan="3" class="text-right">{{ $t('label.total task time') }}</th>
                                            <th scope="col">
                                                <h4 class="mb-0">{{ userTotalTaskTime }}</h4>
                                            </th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                        <div class="modal-footer border-top">
                            <button type="button" class="btn btn-primary mr-auto" data-dismiss="modal" data-toggle="modal" data-target="#viewTaskDetailsModal">{{ $t('label.close') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';
import draggable from "vuedraggable";
import { mapGetters } from 'vuex';
// import TimeOffRequestModify from '@/components/TimeOffRequestModifyComponent'
// import TimeOffRequestWidget from '@/components/Dashboard/TimeOffRequestWidget'
import JobsWidget from '@/components/Dashboard/JobsWidget';
import TasksWidget from '@/components/Dashboard/TasksWidget';
export default {
    components: {
        // TimeOffRequestModify,
        // TimeOffRequestWidget,
        JobsWidget,
        TasksWidget,
        draggable
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            companySettings: JSON.parse(localStorage.getItem('company_settings')),
            employees: [],
            showWidget: [],
            configureDashboard: false,
            allwidgets: [],
            layout: [],
            layouts: {},
            draggable: false,
            resizable: false,
            index: 0,
            responsive: true,
            closedJobs: [],
            closedTasks: [],
            openJobs: [],
            openTasks: [],
            timeToday: [],
            timeThisWeek: [],
            timeThisMonth: [],
            openJobsTable: [],
            openJobsColumns: [],
            overdueJobsTable: [],
            overdueJobsColumns: [],
            openTasksTable: [],
            openTasksColumns: [],
            overdueTasksTable: [],
            overdueTasksColumns: [],
            recentlyClosedJobsTable: [],
            recentlyClosedJobsColumns: [],
            recentlyAddedJobsTable: [],
            recentlyAddedJobsColumns: [],
            recentlyClosedTasksTable: [],
            recentlyClosedTasksColumns: [],
            clockedinEmployeesTable: [],
            clockedinEmployeesColumns: [],
            clockedinEmployeesOver8hoursTable: [],
            clockedinEmployeesOver8hoursColumns: [],
            employeesOnPTOTable: [],
            employeesOnPTOColumns: [],
            timeOff: [],
            timeOffForApproval: [],
            timeOffColumns: [],
            taskdata: null,
            jobid: null,
            modalEdit: false,
            modalJobEdit: false,
            showCloseJobConf: false,
            jobNoteText: '',
            time_id: null,
            timepunch_id: null,
            username: null,
            userpicture: null,
            task_manager: null,
            userTimePunches: [],
            userTotalTaskTime: 0,
            addTimePunches: [],
            taskId: null,
            openEdit: false,
            selectedDate: null,
            current: null,
            isMobile: $(document).width() <= 768,
            timeOffFormDetails: [],
            timeOffFormDescription: '',
            timeOffFormDenyReason: '',
            timeOffFormAction: '',
            timeOffFormUser: '',
            timeOffFormId: 0,
            deleteModalDetail: {},
            deleteModalName: '',
            timeOffFormTableDetails: []
        }
    },
    created() {
        window.addEventListener("resize", this.getWindowSize);
        this.getAllWidgets(this.user);
        if (this.user.role_id == 1 | this.user.role_id == 2) {
            this.getMembers();
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.getWindowSize);
    },
    computed: {
        ...mapGetters(['coordinates']),
        filterActiveStatsWidgets() {
            return this.layout.filter(item => {
                return (item.show && item.type === 'stats');
            });
        },
        filterActiveTableWidgets() {
            return this.layout.filter(item => {
                return item.show && (item.type === 'table' || item.type === 'timesheet');
            });
        },
        filterActiveRequestWidgets() {
            return this.layout.filter(item => {
                return item.show && (item.type === 'request');
            });
        }
    },
    mounted() {
        let checkExist = setInterval(function () {
            let element = $('.vue-grid-layout');
            if (element.length) {
                let height = document.body.scrollHeight - 180;
                element.attr('style', 'height: ' + height + 'px !important');
                clearInterval(checkExist);
            }
        }, 1000);

        $('#addJobNotesModal, #editJobNotesModal').on('shown.bs.modal', function () {
            let checkExist = setInterval(function () {
                if ($('#addJobNoteField').length || $('#addJobNoteFieldEdit').length) {
                    $('#addJobNoteField').focus();
                    $('#addJobNoteFieldEdit').focus();
                    clearInterval(checkExist);
                }
            }, 10);
        });
        bus.$on('getjobid', (data) => {
            this.getJobId(data);
            this.taskId = data.taskId;
            this.current = data.task_status_id;
            this.selectedDate = data.selectedDate;
            this.openEdit = data.openEdit;
            bus.$emit('getTasksTEMP', data);
            $('#viewTaskDetailsModal').modal('show');
        })
        bus.$on('stopnotify', (data) => {
            this.timepunch_id = data;
            this.stopNotify2(data.username);
        })

        // Time off request modal loader
        bus.$on('requestFormAction', (data) => {
            switch (data.name) {
                case 'Time Off':
                    this.$modal.show('addOrEditTimeOffRequest');
            }
        })
        bus.$on('getTimeOff', (data) => {
            this.timeOffFormDetails = data.timeOffDetails;
            this.timeOffFormDescription = data.description;
            this.timeOffFormId = data.id
        })
        bus.$on('deleteRequestNotify', (data) => {
            if (data.name === 'Time Off') {
                this.deleteModalDetail = data.deleteModalDetail
                this.deleteModalName = data.name
            }
            $("#deleteModalRequest").modal('show').data('id', data.id);
        })
        bus.$on('deleteTimeOff', (data) => {
            this.timeOffFormAction = 'd'
            this.timeOffFormId = data
            this.processTimeOff();
        })
        bus.$on('processApprovalRequest', (data) => {
            if (data.name === 'Time Off') {
                this.timeOffFormAction = data.action
                this.timeOffFormId = data.id
                this.timeOffFormUser = data.user
                if (data.action === 'approve') {
                    this.processTimeOff();
                } else {
                    this.getTimeOffTable(false, data.action, data.userId);
                }
            }
        })
        bus.$on('processClearingRequest', (data) => {
            if (data.name === 'Time Off') {
                this.timeOffFormAction = data.action
                this.timeOffFormId = data.id
                this.processTimeOff();
            }
        })
        bus.$on('getallwidgets', (data) => {
            axios.get('/get-open-tasks-table').then(response => {
                this.openTasksTable = response.data.result;
                this.openTasksColumns = response.data.columns;
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
            axios.get('/get-overdue-tasks-table').then(response => {
                this.overdueTasksTable = response.data.result;
                this.overdueTasksColumns = response.data.columns;
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
            axios.get('/get-recently-closed-tasks-table').then(response => {
                this.recentlyClosedTasksTable = response.data.result;
                this.recentlyClosedTasksColumns = response.data.columns;
                // this.updateDatatable();
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        })
        bus.$on('getallwidgets2', (data) => {
            axios.get('/get-open-jobs-table').then(response => {
                this.openJobsTable = response.data.result;
                this.openJobsColumns = response.data.columns;
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
            axios.get('/get-overdue-jobs-table').then(response => {
                this.overdueJobsTable = response.data.result;
                this.overdueJobsColumns = response.data.columns;
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
            axios.get('/get-recently-closed-jobs-table').then(response => {
                this.recentlyClosedJobsTable = response.data.result;
                this.recentlyClosedJobsColumns = response.data.columns;
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
            axios.get('/get-recently-added-jobs-table').then(response => {
                this.recentlyAddedJobsTable = response.data.result;
                this.recentlyAddedJobsColumns = response.data.columns;
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        })
        bus.$on('getallwidgets3', (data) => {
            axios.get('/get-clockedin-employees').then(response => {
                this.clockedinEmployeesTable = response.data.result;
                this.clockedinEmployeesColumns = response.data.columns;
                for (var i = 0; i < this.clockedinEmployeesTable.length; i++) {
                    var startingDate = new Date(this.clockedinEmployeesTable[i].time_in);
                    var startTime = startingDate.getTime();
                    var currentTime = (new Date(new Date().toLocaleString("en-US", { timeZone: this.user.timezone }))).getTime();
                    var timeElapsed = (new Date(currentTime - startTime)).getTime();
                    var duration = timeElapsed / 1000;
                    this.clockedinEmployeesTable[i].total_time = parseFloat(duration / 3600).toFixed(2);
                }
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
            axios.get('/get-clockedin-employees-over-8hours').then(response => {
                var clockedinEmployeesTable = response.data.result;
                this.clockedinEmployeesOver8hoursColumns = response.data.columns;
                this.clockedinEmployeesOver8hoursTable = [];
                for (var i = 0; i < clockedinEmployeesTable.length; i++) {
                    var startingDate = new Date(clockedinEmployeesTable[i].time_in);
                    var startTime = startingDate.getTime();
                    var currentTime = (new Date(new Date().toLocaleString("en-US", { timeZone: this.user.timezone }))).getTime();
                    var timeElapsed = (new Date(currentTime - startTime)).getTime();
                    var duration = timeElapsed / 1000;
                    clockedinEmployeesTable[i].total_time = parseFloat(duration / 3600).toFixed(2);
                    if (clockedinEmployeesTable[i].total_time > 8) {
                        this.clockedinEmployeesOver8hoursTable.push(clockedinEmployeesTable[i]);
                    }
                }
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        })
        bus.$on('getallwidgets4', () => {
            this.getClosedJobs();
            this.getClosedTasks();
            this.getOpenJobs();
            this.getOpenTasks();
        });
        bus.$on('usertimerecords', (data) => {
            this.dataSelected = data;
            this.dataSelectedDate = null;
            this.username = data.user.name;
            this.userpicture = data.user.picture;
            this.task_manager = data.task_manager;
            this.userTimeRecords();
            $('#DataTables_Table_0_wrapper > .row:nth-child(3)').hide();
        })
        bus.$on('clockoutFunc', () => {
            this.clockOut();
        })
        bus.$on('toggleCloseJobModal', (jobId, show) => {
            this.toggleJobCloseModal(jobId, show);
        });
        $('.footer').remove();
    },
    updated() {
        for (let i = 0; i < this.timeOffFormDetails.length; i++) {
            var ref = this;
            $('#time-off-date-' + i).datepicker().on('changeDate', () => {
                ref.timeOffFormDetails[i].date = $('#time-off-date-' + i).val();
                this.emptyTimeOffFormDetails()
            });
        }
    },
    methods: {
        getWindowSize() {
            this.isMobile = $(document).width() <= 768;
        },
        getMembers() {
            axios.get('/get-employees').then(response => {
                this.employees = response.data.result;
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        // Loads users widget layout
        getAllWidgets(data) {
            var request_fields = {
                'user_id': data.id
            }
            axios.post('/get-all-widgets', request_fields)
                .then(response => {
                    this.allwidgets = response.data.result
                    this.layout = this.allwidgets
                    // Load all widget data
                    for (var i = 0; i < this.allwidgets.length; i++) {
                        if (this.allwidgets[i].show) {
                            if (this.allwidgets[i].type == 'timesheet') {
                                if (this.user.role_id == 1 || this.user.role_id == 2) {
                                    this.selectWidget(this.allwidgets[i].label, this.allwidgets[i].type);
                                }
                            }
                            this.selectWidget(this.allwidgets[i].label, this.allwidgets[i].type);
                        }
                    }
                })
                .catch(err => {
                    console.log('Error in getAllWidgets:', err)
                });
        },
        // toggle widget on and off
        toggleWidget(item, index) {
            this.layout[index].show = !item.show;
            this.updateUserWidgets();
            // Load widget data on show select.
            if (item.show) {
                this.selectWidget(item.label);
            }
        },
        // Updates the users widget configuration
        updateUserWidgets() {
            var request_fields = { 'widgets': this.layout, }
            axios.post('/update-widgets', request_fields)
                .then(response => { })
                .catch(err => {
                    console.log('Error Occurred:', err);
                })
        },
        // Load widget data
        selectWidget(val, type) {
            if (type == 'stats') {
                switch (val) {
                    case 'Closed Jobs': this.getClosedJobs(); break;
                    case 'Closed Tasks': this.getClosedTasks(); break;
                    case 'Open Jobs': this.getOpenJobs(); break;
                    case 'Open Tasks': this.getOpenTasks(); break;
                    case 'Time Today': this.getTimeToday(); break;
                    case 'Time This Week': this.getTimeThisWeek(); break;
                    case 'Time This Month': this.getTimeThisMonth(); break;
                }
            } else {
                switch (val) {
                    case 'Open Jobs': this.getOpenJobsTable(); break;
                    case 'Overdue Jobs': this.getOverdueJobsTable(); break;
                    case 'Open Tasks': this.getOpenTasksTable(); break;
                    case 'Overdue Tasks': this.getOverdueTasksTable(); break;
                    case 'Recently Closed Jobs': this.getRecentlyClosedJobsTable(); break;
                    case 'Recently Added Jobs': this.getRecentlyAddedJobsTable(); break;
                    case 'Recently Closed Tasks': this.getRecentlyClosedTasksTable(); break;
                    case 'Clocked In Employees': this.getClockedinEmployees(); break;
                    case 'Clocked In Employees (Over 8 Hours)': this.getClockedinEmployeesOver8hours(); break;
                    case 'Employees on PTO': this.getEmployeesOnPTO(); break;
                    case 'Time Off': this.getTimeOffTable(); this.user.role_id === 2 ? this.getTimeOffTable(true) : ''; break;
                }
            }
        },
        updateDatatable() {
            // debugger;
            $(document).ready(function () {
                let table = $('.table-flush').not('.timeoff-table').DataTable();
                table.destroy();
                table = $('.table-flush').not('.timeoff-table').DataTable({
                    keys: !0,
                    language: {
                        paginate: {
                            previous: "<i class='fas fa-angle-left'>",
                            next: "<i class='fas fa-angle-right'>"
                        }
                    },
                    pageLength: 4,
                    "searching": false,
                    "lengthChange": false,
                    "bDestroy": true
                });
            });
        },
        getClosedJobs() {
            axios.get('/get-closed-jobs').then(response => {
                this.closedJobs = response.data.result;
                //console.log('this.closedJobs',this.closedJobs)
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        getClosedTasks() {
            axios.get('/get-closed-tasks').then(response => {
                this.closedTasks = response.data.result;
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        getOpenJobs() {
            axios.get('/get-open-jobs').then(response => {
                this.openJobs = response.data.result;
            })
                .catch(err => console.log('Error in getOpenJobs:', err));
        },
        getOpenTasks() {
            axios.get('/get-open-tasks').then(response => {
                this.openTasks = response.data.result;
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        getTimeToday() {
            axios.get('/get-time-today').then(response => {
                this.timeToday = response.data.result;
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        getTimeThisWeek() {
            axios.get('/get-time-this-week').then(response => {
                this.timeThisWeek = response.data.result;
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        getTimeThisMonth() {
            axios.get('/get-time-this-month').then(response => {
                this.timeThisMonth = response.data.result;
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        getOpenJobsTable() {
            axios.get('/get-open-jobs-table').then(response => {
                this.openJobsTable = response.data.result;
                this.openJobsColumns = response.data.columns;
                this.updateDatatable();
            })
                .catch(err => console.error('Error in getOpenJobsTable:', err));
        },
        getOverdueJobsTable() {
            axios.get('/get-overdue-jobs-table').then(response => {
                this.overdueJobsTable = response.data.result;
                this.overdueJobsColumns = response.data.columns;
                this.updateDatatable();
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        getOpenTasksTable() {
            axios.get('/get-open-tasks-table').then(response => {
                this.openTasksTable = response.data.result;
                this.openTasksColumns = response.data.columns;
                this.updateDatatable();
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        getOverdueTasksTable() {
            axios.get('/get-overdue-tasks-table').then(response => {
                this.overdueTasksTable = response.data.result;
                this.overdueTasksColumns = response.data.columns;
                this.updateDatatable();
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        getRecentlyClosedJobsTable() {
            axios.get('/get-recently-closed-jobs-table').then(response => {
                this.recentlyClosedJobsTable = response.data.result;
                this.recentlyClosedJobsColumns = response.data.columns;
                this.updateDatatable();
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        getRecentlyAddedJobsTable() {
            axios.get('/get-recently-added-jobs-table').then(response => {
                this.recentlyAddedJobsTable = response.data.result;
                this.recentlyAddedJobsColumns = response.data.columns;
                this.updateDatatable();
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        getRecentlyClosedTasksTable() {
            axios.get('/get-recently-closed-tasks-table').then(response => {
                this.recentlyClosedTasksTable = response.data.result;
                this.recentlyClosedTasksColumns = response.data.columns;
                this.updateDatatable();
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        getClockedinEmployees() {
            axios.get('/get-clockedin-employees').then(response => {
                this.clockedinEmployeesTable = response.data.result;
                this.clockedinEmployeesColumns = response.data.columns;
                for (var i = 0; i < this.clockedinEmployeesTable.length; i++) {
                    var startingDate = new Date(this.clockedinEmployeesTable[i].time_in);
                    var startTime = startingDate.getTime();
                    var currentTime = (new Date(new Date().toLocaleString("en-US", { timeZone: this.user.timezone }))).getTime();
                    var timeElapsed = (new Date(currentTime - startTime)).getTime();
                    var duration = timeElapsed / 1000;
                    this.clockedinEmployeesTable[i].total_time = parseFloat(duration / 3600).toFixed(2);
                }
                this.updateDatatable();
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        getClockedinEmployeesOver8hours() {
            axios.get('/get-clockedin-employees-over-8hours').then(response => {
                var clockedinEmployeesTable = response.data.result;
                this.clockedinEmployeesOver8hoursColumns = response.data.columns;
                for (var i = 0; i < clockedinEmployeesTable.length; i++) {
                    var startingDate = new Date(clockedinEmployeesTable[i].time_in);
                    var startTime = startingDate.getTime();
                    var currentTime = (new Date(new Date().toLocaleString("en-US", { timeZone: this.user.timezone }))).getTime();
                    var timeElapsed = (new Date(currentTime - startTime)).getTime();
                    var duration = timeElapsed / 1000;
                    clockedinEmployeesTable[i].total_time = parseFloat(duration / 3600).toFixed(2);
                    if (clockedinEmployeesTable[i].total_time > 8) {
                        this.clockedinEmployeesOver8hoursTable.push(clockedinEmployeesTable[i]);
                    }
                }
                this.updateDatatable();
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        getEmployeesOnPTO() {
            axios.get('/get-employees-on-pto').then(response => {
                this.employeesOnPTOTable = response.data.result;
                this.employeesOnPTOColumns = response.data.columns;
                this.updateDatatable();
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        getJobId(data) {
            if (data.type == 'task') {
                this.taskdata = data.taskdata;
                this.jobid = data.jobid;
                this.modalEdit = !this.modalEdit;
            } else {
                this.jobNoteText = '';
                this.taskdata = data.taskdata;
                this.jobid = data.jobid;
                this.modalJobEdit = !this.modalJobEdit;
            }
        },
        saveJobNote() {
            var request_fields = {
                'notes': this.jobNoteText,
                'job_id': this.jobid
            };
            axios.post('/jobnotes', request_fields)
                .then(response => {
                    this.jobNoteText = '';
                })
                .catch(err => {
                    //console.log('list carriers error:', error)vie
                });
            document.querySelector('.close2addnote').click();
        },
        toggleJobCloseModal(jobId, show) {
            this.jobid = jobId ? jobId : null;
            this.showCloseJobConf = show ? show : null;
        },
        closeJob() {
            // Save job status then update widgets
            axios.post("/close-job", {
                job_id: this.jobid
            }).then(response => {
                bus.$emit('getallwidgets');
                bus.$emit('getallwidgets2');
                bus.$emit('getallwidgets4');
                bus.$emit('banner-success', {
                    message: $t('label.job closed successfully') + '!'
                });
            }).catch(err => console.log(err));
        },
        stopNotify2(data) {
            bus.$emit('modalClockout', {
                username: data
            })
            $("#modalClockout").modal('show');
        },
        clockOut() {
            var request_fields = {
                'time_id': this.timepunch_id,
                'logout_location': this.coordinates,
            };
            axios.post('/time-out', request_fields)
                .then(response => {
                    bus.$emit('checkClockinStatus', 'start');
                    bus.$emit('getallwidgets3');
                    $("#modalClockout").modal('hide');
                })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });

        },
        updateTimePunch(data) {
            axios.post('/update-user-time-punch', data)
                .then(response => {
                    this.userTimeRecords();
                    if (data.type == 'new') this.addTimePunches = [];
                    $.notifyDefaults({
                        type: 'success',
                        allow_dismiss: true,
                        z_index: 2100,
                        placement: {
                            from: "top",
                            align: "center"
                        },
                    });
                    $.notify({
                        title: "<strong>Success:</strong> ",
                        message: "Done updating the time records"
                    });
                })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        deleteNotify(delete_id) {
            this.delete_id = delete_id;
            $("#deleteModal").modal('show');
        },
        deleteTimePunch() {
            axios.post('/delete-time-punch/' + this.delete_id)
                .then(response => {
                    this.userTimeRecords();
                    this.getTasks(this.current);
                    $("#deleteModal").modal('hide');
                })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
            this.openModal = false;
        },
        userTimeRecords() {
            var request_fields = {
                'task_id': this.dataSelected.task_id,
                'user_id': this.dataSelected.user.id,
                'date': this.dataSelectedDate,
                'user_data': this.dataSelected
            };
            axios.post('/user-time-punches', request_fields)
                .then(response => {
                    this.userTimePunches = response.data.result;
                    this.userTotalTaskTime = response.data.overall_time;
                    var main = this;
                    $('.entries').ready(function () {
                        $('.datepicker').datepicker();
                        $('.datepicker').datepicker().on('changeDate', (ev) => {
                            main.userTimePunches[ev.currentTarget.id].date = ev.currentTarget.value
                        });
                        $(".flatpickr").flatpickr({
                            enableTime: true,
                            noCalendar: true,
                            static: true,
                            dateFormat: "G:i K",
                            minuteIncrement: 1
                        });
                    });
                })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        addTimePunch() {
            this.addTimePunches.push({
                task_id: this.dataSelected.task_id,
                user_id: this.dataSelected.user.id,
                type: 'new',
                date: moment().format('YYYY-MM-DD'),
                timein: '00:00',
                timeout: '00:00'
            });
            var main = this;
            $('#newEntry').ready(function () {
                $('.datepicker').datepicker();
                $('.datepicker.newdate').datepicker().on('changeDate', (ev) => {
                    main.addTimePunches[ev.currentTarget.id.replace(/\D/g, "")].date = ev.currentTarget.value;
                    $(".flatpickr").flatpickr({
                        enableTime: true,
                        noCalendar: true,
                        static: true,
                        dateFormat: "G:i K",
                        minuteIncrement: 1
                    });
                });
            });
        },
        deleteNewTimePunch(index) {
            this.addTimePunches.splice(index, 1);
        },
        getTimeOffTable(forApproval = false, action = 'n', userId = 0) {
            return;
        },
        processTimeOff() {
            // console.error('I am in ProcessTimeOff')
            // return;
            const formData = new FormData()
            let payloads = {
                details: JSON.stringify(this.timeOffFormDetails),
                description: this.timeOffFormDescription,
                deny_reason: this.timeOffFormDenyReason,
                user_id: this.user.id,
                action: this.timeOffFormAction,
                id: this.timeOffFormId
            }
            for (let key in payloads) {
                formData.append(key, payloads[key]);
            }
            axios.post('/process-time-off', formData).then(res => {
                if (res.data.status === 'success') {
                    this.getTimeOffTable()
                    this.getTimeOffTable(true);
                    $.notifyDefaults({
                        type: 'success',
                        allow_dismiss: true,
                        z_index: 2100,
                        delay: 10000,
                        timer: 1000,
                        offset: 20,
                        spacing: 10,
                        placement: {
                            from: "top",
                            align: "right"
                        },
                        template: '<div data-notify="container" class="col-xs-10 col-sm-10 alert alert-dismissable alert-{0}" role="alert">' +
                            '<button type="button" aria-hidden="true" class="close mt-2 mr-2" data-notify="dismiss">×</button>' +
                            '<span data-notify="message">{2}</span>' +
                            '</div>'
                    });
                    $.notify({
                        message: "<strong><i class=\"fas fa-thumbs-up\"></i> Success!</strong> " + res.data.message
                    });
                    $('.closeTimeOffForm').click();
                    $('.closeDeleteRequestModal').click();
                } else {
                    $.notifyDefaults({
                        type: 'danger',
                        allow_dismiss: true,
                        z_index: 2100,
                        delay: 10000,
                        timer: 1000,
                        offset: 20,
                        spacing: 10,
                        placement: {
                            from: "top",
                            align: "right"
                        },
                        template: '<div data-notify="container" class="col-xs-10 col-sm-10 alert alert-dismissable alert-{0}" role="alert">' +
                            '<button type="button" aria-hidden="true" class="close mt-2 mr-2" data-notify="dismiss">×</button>' +
                            '<span data-notify="message">{2}</span>' +
                            '</div>'
                    });
                    $.notify({
                        message: "<strong><i class=\"fas fa-times-circle\"></i> Error!</strong> " + res.data.message
                    });
                }
            }).catch(err => {
            });
        },
        emptyTimeOffFormDetails() {
            this.timeOffFormDetails.splice(1, this.timeOffFormDetails.length);
        }
    }
}
</script>
<style>
.container .vue-grid-item.vue-grid-placeholder {
    background: green;
}

.vue-grid-layout {
    background: transparent;
    height: max-content !important;
}

.vue-grid-item:not(.vue-grid-placeholder) {}

.vue-grid-item .resizing {
    opacity: 0.9;
}

.vue-grid-item .static {
    background: #cce;
}

.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}

.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}

.vue-grid-item .minMax {
    font-size: 12px;
}

.vue-grid-item .add {
    cursor: pointer;
}

.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}

.custom-toggle {
    width: 60px;
}

.custom-toggle input:checked+.custom-toggle-slider:before {
    transform: translateX(35px);
}

.custom-toggle-slider:after {
    right: 5px;
}

/* Breakpoint MD */
@media (max-width: 1400px) {
    .card-body {
        padding: 1.2rem;
    }

    .h3 {
        font-size: .9625rem;
    }

    h4 {
        font-size: .8375rem;
    }

    .table-sm th,
    .table-sm td {
        padding: 0.3rem !important
    }
}
</style>