<template>
    <modal name="addJobTypeModal" @closed="closeModal()" :classes="'rounded'" :shiftY="0.1" :min-height="400" width="525" :scrollable="true" height="auto" :reset="true" :adaptive="true">
        <div class="card-header">
            <div class="py-2 d-flex">
                <h5 class="modal-title mr-atuo" id="editJobTypesModalTitle">{{ this.$t('label.add job type') }}</h5>
                <button type="button" class="close ml-auto" @click="$modal.hide('addJobTypeModal')" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <!-- Name -->
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input-name">{{ this.$t('label.job type name') }}</label>
                        <span class="text-danger text-sm">*&nbsp;{{ this.$t('label.required') }}</span>
                        <input type="text" name="name" class="form-control" v-model="$v.name.$model" :class="{ 'is-invalid': $v.name.$error }" :placeholder="$t('label.job type name')" required autofocus>

                        <div v-if="$v.name.$error && !$v.name.required" class="text-danger text-sm">*&nbsp;{{ this.$t('label.field required') }}.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="d-flex">
                <button type="button" class="btn btn-primary" :disabled="formSubmit" @click="saveJobType()">{{ this.$t('label.save') }}</button>
                <button type="button" class="btn btn-link ml-auto" @click="$modal.hide('addJobTypeModal')">{{ this.$t('label.close') }}</button>
            </div>
        </div>
    </modal>
</template>

<style></style>

<script>
import bus from '@/utils/event-bus';
import { required } from 'vuelidate/lib/validators';

export default {
    props: [],
    emits: ['onClose', 'onSave'],
    data() {
        return {
            name: "",
            loader: null,
            formSubmit: false
        }
    },
    validations: {
        name: {
            required,
        }
    },
    mounted() {
        this.$modal.show('addJobTypeModal');
    },
    methods: {
        saveJobType() {
            this.formSubmit = true;

            // Submit only if form has no errors
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loader = this.$loading.show();

                axios.post('/save-job-type', {
                    name: this.name
                }).then(response => {
                    this.loader.hide();
                    bus.$emit('banner-success', {
                        message: this.$t('label.Job Type successfully created')
                    });
                    this.$emit('onSave', response.data.result.id);
                    this.$modal.hide('addJobTypeModal');
                }).catch(err => console.log(err));
            }

            this.formSubmit = false;
        },

        closeModal() {
            this.$emit('onClose', false);
        }
    }
}
</script>
