<template>
	<div class="modal-content">
		<div class="modal-header border-bottom">
			<div class="align-items-center">
				<h3 class="mb-0 d-flex align-items-center">
					<i class="ri-tools-line heading-title"></i>Purchase Item
				</h3>
			</div>
			<button type="button" class="close" @click="$emit('cancelFnc')">
				<i class="ri-close-line"></i>
			</button>
		</div>
		<div class="modal-body">
			<div class="row">
				<div class="col-md-6">
					<!-- Item name -->
					<div class="form-group">
						<label class="form-control-label text-bold" for="item_name">{{ $t('label.item name') }}</label>
						<span class="text-danger text-xs"> * {{ $t("label.required") }}</span>
						<input type="text" v-model="form.name" class="form-control" required />
					</div>
				</div>
				<div class="col-md-6">
					<!-- Item sku -->
					<div class="form-group">
						<label class="form-control-label text-bold" for="item_name">Item SKU</label>
						<span class="text-danger text-xs"> * {{ $t("label.required") }}</span>
						<input type="text" v-model="form.sku" class="form-control" required />
					</div>
				</div>
				<div class="col-md-6">
					<!-- Item sku -->
					<div class="form-group">
						<label class="form-control-label text-bold" for="item_name">Item Price</label>
						<span class="text-danger text-xs"> * {{ $t("label.required") }}</span>
						<input type="number" v-model="form.price" class="form-control" required />
					</div>
				</div>
				<div class="col-md-6">
					<!-- Item sku -->
					<div class="form-group">
						<label class="form-control-label text-bold" for="item_name">Item Status</label>
						<span class="text-danger text-xs"> * {{ $t("label.required") }}</span>
						<select id="status" v-model="form.status" class="form-control">
							<option value="1"> Active </option>
							<option value="0"> Inactive </option>
						</select>
					</div>
				</div>
				<div class="col-md-6">
					<!-- Item sku -->
					<div class="form-group">
						<label class="form-control-label text-bold" for="item_type">Item Type</label>
						<span class="text-danger text-xs"> * {{ $t("label.required") }}</span>
						<select id="item_type" v-model="form.item_type" class="form-control">
							<option value="">--Select item type--</option>
							<option value="vpo"> VPO </option>
							<option value="so"> Supply Order </option>
						</select>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<div class="flex">
				<button type="button" class="btn btn-link mr-2" @click="$emit('cancelFnc')">
					{{ $t('label.cancel') | uppercase }}
				</button>
				<button class="btn btn-primary" type="button" @click="validateupdateItem">
					{{ $t('label.save item') | uppercase }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PurchaseItemCreate',
	data: () => ({
		form: {
			name: '',
			sku: '',
			pice: '',
			status: 1,
			item_type:''
		},
	}),
	mounted() {
		//
	},
	methods: {
		validateupdateItem() {
			this.setLoading(true)
			let url = '/purchase-items/create'
			axios.post(url, this.form).then((response) => {
				const item = response.data.data;
				this.$bus.$emit('banner-success', {
					message: this.$route.params?.item ? 'Purchase Item Updated Successfully' : 'Purchase Item Created Successfully.',
				});
				this.$emit('cancelFnc', item)
			}).catch((err) => {
                 console.log("Error:",err);
                 if (err.data.code === 422){
                  this.$bus.$emit('banner-error', {
                    message: Object.values(err.data.message.errors).flat().join("\n")
                });
              }
            }).finally(() => {
				this.setLoading(false)
			})
		}
	}
};
</script>
