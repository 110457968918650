<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="row align-items-center">
                    <div class="col-sm-8">
                        <h3 class="mb-0">{{ $t('label.my time off requests') }}</h3>
                    </div>
                    <div class="col-sm-4 text-right">
                        <a class="btn btn-primary btn-sm" style="color:#ffffff;" @click="showAddModal()">
                            <i class="ri-add-line"></i>&nbsp;{{ $t('label.time off request') }}
                        </a>
                    </div>
                </div>
            </div>
            <div class="card-body p-0 m-0">
                <div class="table-responsive" v-if="myRequests.length">
                    <table class="table table-flush border-bottom">
                        <thead class="thead-light">
                            <tr>
                                <th class="col-sm-3">Date(s)</th>
                                <th class="col-sm-3">{{ $t('label.reason') }}</th>
                                <th class="col-sm-2">{{ $t('label.status') }}</th>
                                <th class="col-sm-3">{{ $t('label.notes') }}</th>
                                <th class="col-sm-1"></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="item in myRequests" :key="item.id">
                                <!-- Date Range -->
                                <td v-if="item.start_date !== item.end_date">
                                    <span v-if="moment(item.start_date).hour() > 0">
                                        {{ moment(item.start_date).format('MM/DD/YYYY hh:mm a') }}
                                    </span>
                                    <span v-else>{{ moment(item.start_date).format('MM/DD/YYYY') }} {{ $t('label.all day') }}</span>
                                    -
                                    <span v-if="moment(item.end_date).hour() > 0">
                                        {{ moment(item.end_date).format('MM/DD/YYYY hh:mm a') }}
                                    </span>
                                    <span v-else>{{ moment(item.end_date).format('MM/DD/YYYY') }} {{ $t('label.all day') }}</span>
                                </td>

                                <!-- Single Dates -->
                                <td v-else>
                                    <!-- Time Range off -->
                                    <span v-if="moment(item.start_date).hour() > 0 && moment(item.end_date).hour() > 0">
                                        {{ moment(item.start_date).format('MM/DD/YYYY hh:mm a') }}
                                        -
                                        {{ moment(item.end_date).format('hh:mm a') }}
                                    </span>

                                    <!-- All Day off -->
                                    <span v-else>{{ moment(item.start_date).format('MM/DD/YYYY') }} {{ $t('label.all day') }}</span>
                                </td>

                                <!-- Reason -->
                                <td>{{ item.description }}</td>

                                <!-- Status -->
                                <td>
                                    <span class="badge p-2" :class="item.status === 'Pending' ? 'badge-warning' : (item.status === 'Denied' ? 'badge-danger' : 'badge-success')" style="font-size: 12px;">{{ item.status }}</span>
                                </td>
                                <!-- Manager Notes -->
                                <td>
                                    <div v-if="item.manager_user_id">
                                        <div>
                                            {{ item.manager_notes }}
                                        </div>
                                        <div class="text-muted">
                                            {{ getEmployeeName(item.manager_user_id) }}
                                        </div>
                                        <div class="text-muted">
                                            {{ moment(item.manager_action_date).format('MM/DD/YYYY hh:mm a') }}
                                        </div>
                                    </div>
                                </td>

                                <!-- Menu items -->
                                <td class="text-right">
                                    <div class="dropdown" v-if="item.status !== 'Approved' ">
                                        <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" 
                                        aria-haspopup="true" 
                                        aria-expanded="false">
                                            <i class="fas fa-ellipsis-v"></i>
                                        </a>
                                        
                                <!-- Pending requests menu items -->
                                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" v-if="item.status === 'Pending'">
                                    <a class="dropdown-item pointer" :data-id="item.id" @click="editMyRequest(item)">
                                        <i class="ri-pencil-line"></i> {{ $t('label.edit time off request') }}
                                    </a>
                                    <a class="dropdown-item pointer" @click="deleteNotify(item)">
                                        <i class="ri-delete-bin-line btn-outline-danger"></i> {{ $t('label.delete time off request') }}
                                    </a>
                                </div>

                                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" v-if="item.status === 'Denied'">
                                    <a class="dropdown-item pointer" @click="hideRequest(item.id)">
                                        <i class="ri-delete-bin-line btn-outline-danger"></i> {{ $t('label.clear time off request') }}
                                    </a>
                                </div>
                </div>
                </td>
                </tr>
                </tbody>
                </table>
                <!-- <table-pagination
                        v-model="paginatedJobTypes"
                        :all-rows="jobTypes"
                        :perPage="perPage">
                    </table-pagination> -->
            </div>

            <!-- No items to show message -->
            <div class="p-4" v-else>
                <div class="mb-0 d-flex align-items-center">All of your time off requests will be listed here.</div>
            </div>
        </div>
    </div>

    <!-- Add new request modal -->
    <modal name="addOrEditTimeOffRequest" :width="800" height="auto" :scrollable="true" :reset="true" :adaptive="true">
        <time-off-request-modify :existing-item-data="selectedRequest" @modifiedRequest="modifiedRequestReturn"></time-off-request-modify>
    </modal>

    <!-- Delete Conformation Modal -->
    <modal name="requestDelete" height="auto" :width="525" :reset="true" :adaptive="true">
        <div class="modal-content bg-gradient-danger">
            <div class="modal-header text-white">
                <button type="button" class="close" @click="$modal.hide('requestDelete')">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body text-white">
                <div class="py-3 text-center">
                    <i class="ri-error-warning-line ni-3x"></i>
                    <h4 class="heading mt-4">{{ $t('label.confirm delete request') | uppercase }}</h4>
                    <p>{{ $t('label.This action cannot be undone') }}!</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-white" @click="deleteRequest()">{{ $t('label.Yes, Delete') }}</button>
                <button type="button" class="btn btn-link text-white ml-auto close_delete" @click="$modal.hide('requestDelete')">{{ $t('label.cancel') }}</button>
            </div>
        </div>
    </modal>
    </div>
</template>

<script>
import TimeOffRequestModify from '@/components/TimeOffRequestModifyComponent';
import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex';
export default {
    props: [],
    components: { TimeOffRequestModify },
    emits: ['dataLoaded'],
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            myRequests: [],
            selectedRequest: null,
        }
    },
    computed: {
        ...mapGetters(['users']),
    },
    mounted() {
        this.getUserTimeOffRequests();
        if (!this.users.allemployees.length) {
            this.$store.dispatch('getAllEmployees');
        }

    },
    methods: {
        getEmployeeName(userId) {
            let companyUsers = this.users.allemployees;
            let idx = companyUsers.findIndex(user => {
                if (user.id === parseInt(userId)) {
                    return true;
                }
            });
            return (idx >= 0) ? companyUsers[idx].name : 'N/A';
        },
        // get current user time off requests
        getUserTimeOffRequests() {
            axios
                .get('/userTimeOffReqestList')
                .then(response => {
                    this.myRequests = response.data;
                    this.$emit('dataLoaded');
                })
                .catch(err => {
                    console.log('ERROR: ', err);
                    bus.$emit('banner-error', { message: err });
                });
        },
        showAddModal() {
            this.selectedRequest = { user: { name: null } };
            this.$modal.show('addOrEditTimeOffRequest');
        },
        editMyRequest(request) {
            this.selectedRequest = request;
            this.$modal.show('addOrEditTimeOffRequest');
        },
        modifiedRequestReturn(returnRequest) {
            this.$modal.hide('addOrEditTimeOffRequest');
            let idx = this.myRequests.findIndex(request => {
                if (request.id === returnRequest.id) {
                    return true;
                }
            });
            // updating existing request
            if (idx >= 0) {
                this.myRequests.splice(idx, 1);
            }
            // Add request to list. Sort by date asc
            this.myRequests.push(returnRequest);
            this.myRequests = _.orderBy(this.myRequests, 'start_date', 'asc');
        },
        deleteNotify(item) {
            //Show delete modal
            this.selectedRequest = item;
            this.$modal.show('requestDelete');
        },
        deleteRequest() {
            axios
                .post('/deleteTimeOffRequest', { id: this.selectedRequest.id })
                .then(response => {
                    this.$modal.hide('requestDelete');
                    let idx = this.myRequests.findIndex(request => {
                        if (request.id === this.selectedRequest.id) {
                            return true;
                        }
                    });
                    this.myRequests.splice(idx, 1);
                    bus.$emit('banner-success', {
                        message: 'Request Deleted Successfully!',
                    });
                })
                .catch(err => {
                    console.error('ERROR: ', err);
                });
        },
    }
}
</script>

<style></style>