/* eslint-disable no-unused-vars */
const namespaced = true;

const state = {
    allUsers: [],
    allCrews: []
};

const getters = {
    allUsers: state => state.allUsers,
    allCrews: state => state.allCrews,
};

const actions = {
    getAllUsers({ state, commit, rootState, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get('/get-all-users').then(res => {
                commit('setAllUsers', res.data.result);
                resolve(res);
            }).catch(err => reject(err));
        });
    },
    getAllCrews({ state, commit, rootState, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get('/get-all-crews').then(res => {
                commit('setAllCrews', res.data.result);
                resolve(res);
            }).catch(err => reject(err));
        });
    },
    getAllUsersForCalendar({ state, commit, rootState, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axios.post('/get-all-users-for-calendar', data).then(response => {
                commit('setAllUsers', response.data.result);
                resolve(response);
            }).catch(err => console.log(err));
        });
    },
    getAllCrewsForCalendar({ state, commit, rootState, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axios.post(`/get-all-crews-for-calendar`, data).then(response => {
                commit('setAllCrews', response.data.result);
                resolve(response);
            }).catch(err => console.log(err));
        });
    }
}

const mutations = {
    setAllUsers(state, allUsers) {
        state.allUsers = allUsers;
    },
    setAllCrews(state, allCrews) {
        state.allCrews = allCrews;
    },
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
};