/* eslint-disable no-unused-vars */

const namespaced = true;

const state = {
    roles: [],
};

const getters = {
    roles: state => state.roles,
};

const actions = {
    getRoles({ state, commit, rootState, dispatch }) {
        return new Promise((resolve, reject) => {
            axios
                .get('/getRoles')
                .then(res => {
                    commit('setRoles', res.data);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
}

const mutations = {
    setRoles(state, roles) {
        state.roles = roles;
    },
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
};