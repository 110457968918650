<template>
    <modal :name="'editTaskDatesModal' + task.id" @closed="$emit('onClose')" :classes="'rounded'" :min-height="400" width="650px" :scrollable="true" height="auto" :reset="true" :adaptive="true">
        <div class="card-header border-bottom">
            <div class="row pt-2">
                <div class="col-6 d-flex align-items-center">
                    <h4>{{ $t('label.edit task dates') }}</h4>
                </div>
                <div class="col-6">
                    <button type="button pointer" class="close" @click="$emit('onClose')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <!-- Start Date -->
                <div class="col-6 form-group">
                    <label class="form-control-label label-bold">{{ $t('label.start date') }}</label>
                    <date-picker v-model="form.start_date" value-type="format" format="MM/DD/YYYY" :placeholder="$t('label.select start date')" class="full-width" input-class="form-control" @change="shiftDueDate()">
                    </date-picker>
                </div>
                <!-- Due Date -->
                <div class="col-6 form-group">
                    <label class="form-control-label label-bold">{{ $t('label.due date') }}</label>
                    <date-picker v-model="form.due_date" value-type="format" format="MM/DD/YYYY" :disabled-date="disableDueDateBeforeStartDate" :placeholder="$t('label.select due date')" class="full-width" input-class="form-control">
                    </date-picker>
                </div>
            </div>
        </div>
        <div class="card-footer d-flex justify-content-between border-top">
            <button class="btn btn-primary" @click="saveDates()">{{ $t('label.save') }}</button>
            <button class="btn btn-secondary" @click="$emit('onClose')">{{ $t('label.close') }}</button>
        </div>
    </modal>
</template>

<script>

export default {
    props: ['task'],
    emits: ['onChange', 'onClose'],
    data() {
        return {
            assignees: [],
            assigneesHaveTimeOff: false,
            form: {
                task_id: null,
                due_date: null,
                start_date: null,
                old_start_date: null
            }
        }
    },
    watch: {
        // 'form.start_date'(newVal, oldVal) {
        //     this.shiftDueDate(newVal, oldVal);
        // }
    },
    mounted() {
        this.form.task_id = this.task.id;
        this.assignees = this.task.assinged_to;
        this.form.start_date = this.task.start_date ? moment(this.task.start_date).format('MM/DD/YYYY') : null;
        this.form.old_start_date = this.task.start_date ? moment(this.task.start_date).format('MM/DD/YYYY') : null;
        this.form.due_date = this.task.due_date ? moment(this.task.due_date).format('MM/DD/YYYY') : null;

        this.$modal.show('editTaskDatesModal' + this.task.id);
    },
    methods: {
        saveDates() {
            this.$emit('onChange', this.form);
            this.$emit('onClose');
        },
        shiftDueDate() {
            // After date is originally set and has changed,  
            // check to see if due_date needs to shift.
            if (this.form.start_date && this.form.old_start_date) {
                let newDt = moment(this.form.start_date).format('MM/DD/YYYY'),
                    oldDt = moment(this.form.old_start_date).format('MM/DD/YYYY'),
                    daysAdded = moment(newDt).diff(moment(oldDt), 'days');

                if (daysAdded > 0 && this.form.due_date)
                    this.form.due_date = moment(this.form.due_date).add(daysAdded + 1, 'days').format('MM/DD/YYYY');
            } else if (this.form.start_date && !this.form.old_start_date) {
                // Avoid having due date before start date by setting 
                // due date equal to start date.
                this.form.due_date = this.form.start_date;
            }

            // this.fastForwardOldStartDate();
        },

        fastForwardOldStartDate() {
            this.form.old_start_date = this.form.start_date;
        },
        disableDueDateBeforeStartDate(date) {
            if (this.form.start_date)
                return moment(date).diff(moment(this.form.start_date), 'days') < 0;
            else
                return false;
        }
    }
}
</script>