import moment from '@/utils/date-time';
import Vue from 'vue';

Vue.mixin({
    methods: {
        moment(data) {
            return moment(data);
        },
        setLoading(val, divId = null) {
            const id = divId ? divId : '#div-with-loading';
            const element = window.document.querySelector(id);
            if (element && !element.classList.contains("relative")) {
                element.classList.add("relative");
            }
            if (val == true) {
                element.classList.add('is-loading');
                setTimeout(() => {
                    element.classList.add('start-transition-bg');
                    element.classList.add('start-transition-circle');
                }, 200);
            } else {
                element.classList.remove('start-transition-bg');
                element.classList.remove('start-transition-circle');
                setTimeout(() => {
                    element.classList.remove('is-loading');
                }, 200);
            }
        },
        isPastDate(date) {
            var user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                return moment(date).tz(user.timezone).isBefore();
            } else {
                return moment(date).isBefore();
            }
        },
    }
});