<template>
    <div class="" id="ql-crew-time">
        <div class="d-flex align-items-center justify-content-between pointer px-2 my--2" @click="toggleSection">
            <span class="d-flex align-items-center">
                <i :class="openSection ? 'ri-arrow-down-s-line' : 'ri-arrow-right-s-line'"></i>
                <h5 class="modal-title">{{ $t('label.crew/time') }}</h5>
            </span>
            <span class="d-flex align-items-center">
                <i class="ri-time-line pr-1"></i> {{ crewTimeOverall | hourToFormattedTime }} ({{ crewTimeOverall }} {{ $t('label.hrs') }})
            </span>
        </div>
        <div v-if="openSection">
            <table class="table table-sm mt-3 mb--3">
                <thead class="thead-light">
                    <tr>
                        <th scope="col" style="width: 55%;">{{ $t('label.task assignee') }}</th>
                        <th scope="col" class="text-center">{{ $t('label.total time') }}</th>
                        <th scope="col" style="width: 5%;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in crewTime" :key="index">
                        <td>
                            <!-- Task Assignee -->
                            <div class="row align-items-center m-0 w-100">
                                <div class="pointer">
                                    <img class="avatar-img rounded-circle" :src="data.user.picture" alt="">
                                </div>
                                <div class="col px-0 text-left">
                                    <div class="ml-3 ml-xs-0">
                                        <h4 class="font-weight-normal m-0">{{ data.user.name }}</h4>
                                        <span v-if="data.user.role" class="ttext-muted font-weight-normal font-12">{{ data.user.role.name }}</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="text-center">
                            <!-- Total Time and Status -->                            
                            <button v-if="(data.timestatus && (data.task_id === data.timestatus.task_id))" type="button" class="btn btn-clockin btn-sm btn-link status-badge">{{ $t('label.clocked in') }}</button>

                            <span v-else class="text-muted font-weight-normal font-12">
                                <span v-if="data.user.pay_type!='1099'">
                                    {{ data.total_time | hourToFormattedTime }} ({{ data.total_time }} {{ $t('label.hrs') }})
                                </span>
                                <span v-else>
                                    1099 User
                                </span>
                            </span>
                        </td>
                        <td>
                            <!-- Crew Member Dropdown -->
                            <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-ellipsis-v"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" style="min-width: 300px;">
                                <!-- Clockin / Clockout buttons -->
                                <div v-if="canModifyTime(data, 'clockin')" class="">
                                    <div v-if="!data.user.user_status">
                                        <button type="button" class="dropdown-item pointer" @click="clockIn(data.task, data.user)">
                                            <i class="ri-time-line"></i> {{ $t('label.clock in') }}
                                        </button>
                                    </div>
                                </div>
                                <div v-if="data.user.user_status && (user.role_id != '4' || ((user.id != data.user.id) && company.settings.allow_clockin))" class="">
                                    <div v-if="data.user.task_clocked_in.task && taskid == data.user.task_clocked_in.task.id">
                                        <button v-if="data.user.user_status" type="button" class="dropdown-item" @click="clockoutNotify(data)">
                                            <i class="ri-stop-circle-line"></i> {{ $t('label.clock out') }}
                                        </button>
                                    </div>
                                    <div v-else class="row m-0 w-100">
                                        <button type="button" class="dropdown-item pointer text-gray" style="pointer-events: none;" @click="clockIn(data.task, data.user)" disabled>
                                            <span v-if="data.timestatus.task" class="d-flex align-items-center">
                                                <i class="ri-time-line"></i>&nbsp; {{ $t('label.currently clocked in to') }} {{ data.timestatus.task.name }}
                                            </span>
                                            <span v-else class="d-flex align-items-center">
                                                <i class="ri-time-line"></i>&nbsp; {{ $t("label.currently clocked in elsewhere") }}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                
                                <!-- Revise Time -->
                                <span v-if="canModifyTime(data)">
                                    <a class="dropdown-item pointer" @click="viewReviseTime(data)">
                                        <i class="ri-edit-line"></i> {{ $t('label.revise time') }}
                                    </a>
                                    <div class="dropdown-divider"></div>
                                </span>
                                <!-- Open Chat (Hidden for now) -->
                                <!-- <button type="button" class="dropdown-item" @click="openChatRoom(data)">
                                    <i class="ri-discuss-line"></i> {{ $t('label.open chat') }}
                                </button> -->
                                <!-- Mobile Phone -->
                                <button type="button" class="dropdown-item">
                                    <i class="ri-phone-line"></i> {{ $t('label.mobile') }} ({{ data.user.phone }})
                                </button>
                                <!-- Email -->
                                <a class="dropdown-item" :href="'mailto:' + data.user.email"><i class="ri-mail-line"></i> {{ $t('label.email') }} ({{ data.user.email }})</a>
                                <!-- View Profile -->
                                <span class="dropdown-item pointer" @click="getUserInfo(data.user.id, 'Profile')">
                                    <i class="ri-account-circle-line"></i> {{ $t('label.view profile') }}
                                </span>
                                <!-- Remove From Task -->
                                <span v-if="data.task.task_status_id==1 && (user.role_id == '2' || user.role_id == '3')">
                                    <div class="dropdown-divider"></div>
                                    <button class="dropdown-item text-danger align-items-center"
                                        :class="{'text-gray': data.timepunches.length > 0}"
                                        :disabled="data.timepunches.length > 0"
                                        @click="deleteNotify(data, true)">
                                        <span class="sp-tooltip">
                                            <span class="d-flex align-items-center">
                                                <i class="ri-delete-bin-line"></i>&nbsp;{{ $t('label.remove from task') }}
                                            </span>
                                            <span v-if="data.timepunches.length > 0" class="sp-tooltiptext time-logged">{{ $t('label.this user has logged time and cannot be removed from the task') }}</span>
                                        </span>
                                    </button>

                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Revise Time Modal -->
        <modal class="py-4"
			name="reviseTimeModal"
			height="auto"
			width="98%"
			:max-width="1024"
			:scrollable="true" 
            :reset="true" 
            :adaptive="true"
            @closed="$emit('tgglOffCvsClick', true)">
			<ReviseTimeModal
				:data-selected="dataSelected"
				@cancelFnc="$modal.hide('reviseTimeModal')"
				@reload="reload"
			></ReviseTimeModal>	
		</modal>

        <!-- Clockout Modal -->
		<modal-clockout-component
			v-if="showClockout"
			:username="username"
			@onClose="clockoutNotify"
			@customClockoutMethod="clockOut">
		</modal-clockout-component>

        <!-- Template Task Delete Modal -->
		<modal-delete-component
			v-if="showTempTaskDlt"
			:new-id="'CrewTime'"
			:title="'ARE YOU SURE YOU WANT TO DELETE THIS USER FROM TASK'"
			@onClose="deleteNotify"
			@customDeleteMethod="removeFromTask">
		</modal-delete-component>
    </div>
</template>

<style scoped>
    .time-logged {
        width: 200px;
        white-space: normal;
    }
</style>

<script>
    import { mapGetters } from 'vuex';
    import ReviseTimeModal from '../modals/ReviseTimeModal.vue'
    import ModalClockout2 from '../../ModalClockout2.vue'
    import ModalDelete2Vue from '@/components/ModalDelete2.vue';
    
    export default {
        props: ['taskid'],
        emits: ['closeModal', 'tgglOffCvsClick', 'crewTimeUpdate'],
        components: {
            ReviseTimeModal,
            ModalClockoutComponent: ModalClockout2,
            modalDeleteComponent: ModalDelete2Vue,
        },
        data() {
			return {
                user: JSON.parse(localStorage.getItem('user')),
                company: JSON.parse(localStorage.getItem('company_settings')),
                openSection: true,
                dataSelected: [],
                showClockout: false,
                username: '',
                timepunch_id: null,
                showTempTaskDlt: false,
                selectedItem: null
			}
		},
        computed: {
            ...mapGetters({
                crewTime:'taskData/crewTime',
                crewTimeOverall:'taskData/crewTimeOverall',
                coordinates: 'coordinates',
                myChatRooms: 'chat/myChatRooms',
            }),
        },
        mounted() {
        },
        methods: {
            toggleSection() {
                this.openSection = !this.openSection;
                if( this.openSection )
                    this.reload();
            },
            viewReviseTime(data) {
                this.dataSelected = data;
				this.dataSelected.username = data.user.name;
				this.dataSelected.userpicture = data.user.picture;
				this.dataSelected.task_manager = data.task_manager;

                this.$modal.show('reviseTimeModal');
                this.$emit('tgglOffCvsClick', false);
            },
            reload() {
                let data = {
                    date: null,
                    id: this.taskid
                };

                this.$store.dispatch('taskData/getCrewTime', data);
				this.$emit('crewTimeUpdate');
            },
            canModifyTime (data){
                let display = true
                // cant be completed
                if(data.task.task_status_id!=1){
                    display = false
                }
                // can't be 1099 user
                if(data.user.pay_type =='1099'){
                    display = false
                } 
                // user_status can't be true
                if (data.user.user_status) {
                    display = false
                }                
                // task start date can't be in the future
                let diff = moment(moment(data.task.start_date).format('YYYY-MM-DD')).diff(moment().format('YYYY-MM-DD'),'days')                
                if (diff>0) {
                    display = false
                }                
                return display                
                
            },
            clockIn(task, user) {
                var request_fields = {
                    'task_id': task.id,
                    'user_id': user.id,
                    'login_location': this.coordinates,
                };

                if(this.user.id == user.id) {
                    // If current user use vuex
                    this.$store.dispatch('time/timeIn', request_fields).then(response => {
                        this.reload();
                        this.$store.dispatch('time/getUserCurrentClockin');
                    });
                } else {
                    // Else directly use api
                    axios.post(`/time-in`, request_fields).then(response => {
                        this.reload();
                    }).catch(err => console.log(err));
                }
            },
            clockoutNotify(data) {
				this.timepunch_id = data ? data.timestatus.id : null;
                this.username = data ? data.user.name : null;
                this.showClockout = !this.showClockout;

                this.$emit('tgglOffCvsClick', !this.showClockout);
			},
            clockOut() {
                var request_fields = {
                    'time_id': this.timepunch_id,
                    'logout_location': this.coordinates,
                };
                this.$store.dispatch('time/timeOut', request_fields).then(response => {
                    this.reload();
                    this.$store.dispatch('time/getUserCurrentClockin')
                })
			},
            deleteNotify(data, show){
                this.showTempTaskDlt = show;
				this.selectedItem = data;
            },
            removeFromTask() {
                let request = {
                    task_id: this.taskid,
                    user_id: this.selectedItem.assigned_to_user_id
                }
                this.$store.dispatch('taskData/deleteFromTask', request).then(response => {
                    this.reload();
                });
            },
            openChatRoom(data) {
                let chatRoom = this.myChatRooms.filter(x => {
                    let check = x.dm_user.filter(y => y.user.id == data.user.id && x.type == 'user');
                    return check.length > 0;
                });

                if( chatRoom.length > 0 ) {
                    this.$store.commit('chat/pushChatRoomToActiveChats', chatRoom[0])
                } else {

                    let addedUsers = [];
                    addedUsers.push(data.user);

                    this.$store.dispatch('chat/createChatRoom', addedUsers).then(response => {
                        setTimeout(function () {
                            let chatRoom = this.myChatRooms.filter(x => {
                                let check = x.dm_user.filter(y => y.user.id == data.user.id && x.type == 'user');
                                return check.length > 0;
                            })

                            if( chatRoom.length > 0 )
                                this.$store.commit('chat/pushChatRoomToActiveChats', chatRoom[0])

                        }.bind(this), 2000);
                    });
                }
				
			},
        },

    };
</script>
