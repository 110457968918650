<template>
    <div>
        <!-- Image card  -->
        <div class="card">
            <div class="card-body p-1 mx-auto">
                <div class="py-2 my-1">
                    <template v-if="assetType === 'vehicle'">
                        <div v-if="asset.vehicle_photo">
                            <div class="align-items-center">
                                <img class="img-fluid rounded" :src="asset.vehicle_photo" alt="" style="width: 160px;">
                            </div>
                        </div>
                        <div v-else>
                            <div class="align-items-center py-4">
                                <img class="img-fluid rounded" :src="require('@/assets/images/vehicle-photos/default-car.jpg')" alt="" style="width: 160px;">
                            </div>
                        </div>
                    </template>
                    <div v-if="assetType === 'item'">
                        <div v-if="asset.photo" class="align-items-center ml-auto mr-auto">
                            <img class="rounded" :src="asset.photo" alt="" style="width: 160px;" />
                        </div>
                        <div v-else>
                            <img class="rounded" :src="$storagePath + 'asset-item-uploads/default-item.png'" style="width: 160px;" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Quick Add button  -->
        <div class="align-items-center">
            <button type="button" class="btn btn-success btn-block" data-toggle="dropdown">
                <i class="ri-add-line"></i>&nbsp;{{ $t('label.quick add') }}
            </button>
            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#commentModal">
                    <i class="ri-message-2-line"></i> {{ $t('label.new comment') }}
                </a>
                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#addAssetFilesModal">
                    <i class="ri-folders-line"></i> {{ $t('label.upload files') }}
                </a>
                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#addOrEditEntryModal">
                    <i class="ri-tools-line"></i> {{ $t('label.new maintenance entry') }}
                </a>
                <template v-if="assetType === 'vehicle'">
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#" data-toggle="modal" @click="quickAddOdometer()">
                        <i class="ri-dashboard-3-line"></i> {{ $t('label.vehicles update odometer') }}
                    </a>
                </template>
            </div>
        </div>

        <!-- Text Menu Card  -->
        <div class="card sp-left-nav mt-4">
            <div class="card-body px-3 py-2">
                <div class="row m-0">
                    <div class="col-12 p-0">
                        <ul class="navbar-nav w-100">
                            <li class="nav-item px-2 mx--2">
                                <a class="nav-link d-flex align-items-center" href="#assetDetails">
                                    <i class="ri-information-line"></i> {{ $t("label.details") }}
                                </a>
                            </li>
                            <li v-if="assetType === 'vehicle'" class="nav-item px-2 mx--2">
                                <a class="nav-link d-flex align-items-center" href="#assetSpecifications">
                                    <i class="ri-file-list-2-line"></i> {{ $t('label.specifications') }}
                                </a>
                            </li>
                            <li class="nav-item px-2 mx--2">
                                <a class="nav-link d-flex align-items-center" href="#assetComments">
                                    <i class="ri-message-2-line"></i>
                                    {{ $t("label.comments") }} ({{ this.commentCount }})
                                </a>
                            </li>
                            <li class="nav-item px-2 mx--2">
                                <a class="nav-link d-flex align-items-center" href="#assetFiles">
                                    <i class="ri-folders-line"></i>
                                    {{ $t("label.files") }} ({{ this.fileCount }})
                                </a>
                            </li>
                            <li class="nav-item px-2 mx--2">
                                <a class="nav-link d-flex align-items-center" href="#assetMaintenanceEntries">
                                    <i class="ri-tools-line"></i> {{ $t("label.maintenance") }}
                                </a>
                            </li>
                            <li v-if="assetType === 'vehicle'" class="nav-item px-2 mx--2">
                                <a class="nav-link d-flex align-items-center" href="#assetOdometerHistory">
                                    <i class="ri-history-line"></i> {{ $t('label.vehicles odometer history') }}
                                </a>
                            </li>
                            <li class="nav-item px-2 mx--2">
                                <a class="nav-link d-flex align-items-center" href="#assetLifecycle">
                                    <i class="ri-refresh-line"></i> {{ $t("label.lifecycle") }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';

export default {
    props: ["asset", "assetType"],
    data() {
        return {
            commentCount: 0,
            fileCount: 0
        }
    },
    watch: {
        asset() {
            console.log("asset", this.asset.vehicle_photo);
        }
    },
    mounted() {
        bus.$on('get-comment-count', (count) => this.commentCount = count);
        bus.$on('get-file-count', (count) => this.fileCount = count);
    },
    methods: {
        quickAddOdometer() {
            bus.$emit('quick-add-odometer');
        }
    }
}
</script>
