<template>
	<div class="card h-100">
		<div class="card-header handle cursor-move">
			<div class="row align-items-center">
				<h3 class="mb-0 d-flex align-items-center">
					<i class="ri-drag-move-fill"></i>
					<span v-if="widgetType == 'open'">Open Jobs</span>
					<span v-if="widgetType == 'overdue'">Overdue Jobs</span>
					<span v-if="widgetType == 'closed'">{{ $t('label.recently closed jobs') }}</span>
					<span v-if="widgetType == 'added'">Recently Added Jobs</span>
				</h3>
			</div>
		</div>

		<div v-if="jobs.length == 0">
			<div class="card-body m-0 py-0">
				<div class="bg-white text-center row align-items-center m-0">
					<div class="w-100 text-center align-items-center">
						<i class="ri-stack-line ni-8x w-100 color-gray-300"></i>
						<span v-if="widgetType == 'open'">
							<p class="lead w-100 mt-0 font-weight-normal">{{ $t('label.No Open Jobs') }}.</p>
							<p class="w-100 font-weight-normal">{{ $t('label.All of your Open Jobs will be listed here') }}.</p>
						</span>
						<span v-if="widgetType == 'overdue'">
							<p class="lead w-100 mt-0 font-weight-normal">{{ $t('label.your on schedule') }}.</p>
							<p class="w-100 font-weight-normal">{{ $t('label.no overdue jobs') }}.</p>
						</span>
						<span v-if="widgetType == 'closed'">
							<p class="lead w-100 mt-0 font-weight-normal">{{ $t('label.Nothing to show here') }}.</p>
							<p class="w-100 font-weight-normal">{{ $t('label.None of your Jobs have been closed in the last 3 days') }}.</p>
						</span>
						<span v-if="widgetType == 'added'">
							<p class="lead w-100 mt-0 font-weight-normal">{{ $t('label.Nothing to show here') }}.</p>
							<p class="w-100 font-weight-normal">{{ $t('label.You have not been assigned any new Jobs in the last 3 days') }}.</p>
						</span>
					</div>
				</div>
			</div>
		</div>

		<div v-if="jobs.length > 0" class="table-responsive pb-4 h-100" style="min-height:200px;">
			<table class="table table-sm border-bottom" style="margin: 0 !important;">
				<thead class="thead-light">
					<tr>
						<th :class="cols[0]">Job Name</th>
						<th :class="cols[1]">Customer</th>
						<th v-if="widgetType == 'overdue'" :class="cols[4]">Due Date</th>
						<th :class="cols[2]">Manager</th>
						<th :class="cols[3]"></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in paginatedJobs" :key="index">
						<!-- Job Name -->
						<td :class="cols[0]">
							<router-link v-if="user.role_id == '2' && item.job_number" 
							:to="{ path: `jobs/edit/${item.job_number.job_number}` }">
								{{ item.job_name }}
							</router-link>

							<a v-else class="selected-text-white">{{ item.job_name }}</a>
						</td>
						<!-- Customer -->
						<td :class="cols[1]">
							<a v-if="user.role_id == '2'" class="pointer selected-text-white text-dark-blue" @click="getUserInfo(item.customer.id, 'Customer')">{{ item.customer ? item.customer.name : '' }}</a>
							<a v-else class="selected-text-white">{{ item.customer ? item.customer.name : '' }}</a>
						</td>
						<!-- Due Date -->
						<td v-if="widgetType == 'overdue'" :class="cols[4]">
							<span class="badge badge-danger" style="font-size: 85%;">{{ item.end_date | formatDate }}</span>
						</td>
						<!-- avatar -->
						<td :class="cols[2]" class="text-center">
							<div class="avatar avatar-sm rounded-circle cursor-pointer" v-if="item.job_manager">
								<img :src="item.job_manager.picture" alt="" class="avatar-img rounded-circle">
							</div>
						</td>

						<!-- Menu -->
						<td class="text-right" :class="cols[3]">
							<div class="dropdown">
								<a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<i class="fas fa-ellipsis-v"></i>
								</a>
								<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
									<span v-if="user.role_id < 3">
										<!-- Open job edit modal -->
										<a class="dropdown-item pointer text-dark" 
										v-if="item.job_number" 
										@click="showEditJobModal(item)">
											<i class="ri-pencil-line"></i> {{ $t('label.edit job') }}
										</a>
										<!-- Close Job -->
										<a v-if="item.job_status.name !== 'Closed'" class="dropdown-item" href="#" data-toggle="modal" data-dismiss="modal" @click="toggleCloseJobModal(item, true)">
											<i class="ri-check-line"></i> {{ $t('label.close job') }}
										</a>
										<!-- Reopen job -->
										<a v-else class="dropdown-item" href="#" data-dismiss="modal" @click="reopenJob(item)">
											<i class="ri-inbox-unarchive-line"></i> {{ $t('label.reopen job') }}
										</a>
										<div class="dropdown-divider"></div>
									</span>
									<!-- add job note -->
									<a class="dropdown-item pointer text-dark" @click="showModifyNoteModal(item)">
										<i class="ri-draft-line"></i> {{ $t('label.add job note') }}
									</a>
									<!-- add job file -->
									<a class="dropdown-item pointer text-dark" @click="showAddJobFile(item)">
										<i class="ri-folders-line"></i> {{ $t('label.add file') }}
									</a>
									<!-- View Job -->
									<template v-if="item.job_number">
										<div class="dropdown-divider"></div>
										<router-link :to="{ name: 'jobEdit', params: { id: item.job_number.job_number } }">
											<a class="dropdown-item text-dark">
												<i class="ri-stack-line"></i> {{ $t('label.view job') }}
											</a>
										</router-link>
									</template>
								</div>
							</div>

						</td>
					</tr>
				</tbody>
			</table>
			<table-pagination 
			v-model="paginatedJobs" 
			:btnLimit="3" 
			:btnSize="'sm'" 
			:all-rows="jobs" 
			:perPage="perPage">
			</table-pagination>
		</div>

		<!-- Job Files -->
		<modal :name="'addJobFile' + widgetType" 
		height="auto" :scrollable="true" 
		:reset="true" :adaptive="true">
			<job-file-upload :jobid="jobid" @close-add-file-modal="hideAddJobFile"></job-file-upload>
		</modal>

		<!-- Add Job Notes Modal -->
		<modal :name="'addJobNote' + widgetType" 
		height="auto" 
		:scrollable="true" 
		:reset="true" 
		:adaptive="true">
			<job-notes-modify :jobid="jobid" @close-add-note-modal="hideModifyNoteModal"></job-notes-modify>
		</modal>

		<!-- Job Edit Modal -->
		<modal-job-edit v-if="showModalJobEdit" 
		:jobdata="jobData" 
		:key="jobData.id" 
		source="dashb-job-edit" 
		@onClose="hideEditJobModal" 
		@onSave="reload">
		</modal-job-edit>

	</div>
</template>

<script>
import jobFileUpload from '@/components/Jobs/JobFileUpload';
import JobNotesModify from '@/components/Jobs/JobNotesModify';
import TablePagination from '@/components/TablePagination';
import bus from '@/utils/event-bus';
;

export default {
	props: ['widgetType', 'data'],
	components: {
		jobFileUpload,
		JobNotesModify,
		TablePagination
	},
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
			taskdata: [],
			jobid: null,
			jobs: [],
			jobData: {},
			paginatedJobs: [],
			showModalJobEdit: false,
			perPage: 4,
			cols: ['col-4', 'col-4', 'col-2', 'col-2']
		}
	},
	watch: {
		// wait for the data prop to get loaded
		data() {
			//Sort the jobs by name asc
			this.jobs = _.orderBy(this.data, 'job_name', 'asc')
		}
	},
	mounted() {
		this.setUpTable()
	},
	methods: {
		setUpTable() {
			if (this.widgetType == 'overdue') {
				this.cols = ['col-4', 'col-3', 'col-2', 'col-2', 'col-1']
			}
		},
		showAddJobFile(item) {
			this.jobid = item.id;
			this.$modal.show('addJobFile' + this.widgetType);
		},
		hideAddJobFile() {
			this.$modal.hide('addJobFile' + this.widgetType);
		},
		showModifyNoteModal(item) {
			this.jobid = item.id;
			this.$modal.show('addJobNote' + this.widgetType);
		},
		hideModifyNoteModal() {
			this.$modal.hide('addJobNote' + this.widgetType);
		},
		toggleCloseJobModal(data, show) {
			bus.$emit('toggleCloseJobModal', data.id, show)
		},
		showEditJobModal(item) {
			this.jobData = item;
			this.showModalJobEdit = true;
		},
		hideEditJobModal() {
			this.showModalJobEdit = false;
		},
		reload() {
			bus.$emit('getallwidgets');
		}
	}
}
</script>