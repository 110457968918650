/* eslint-disable no-unused-vars */


const namespaced = true;
const state = {
    countries: [],
};

const getters = {
    countries: state => state.countries,
};

const actions = {
    getCountries({ state, commit, rootState, dispatch }) {
        return new Promise((resolve, reject) => {
            axios
                .get('/get-countries')
                .then(res => {
                    commit('setCountries', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
}

const mutations = {
    setCountries(state, countries) {
        state.countries = countries;
    },
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
};