<template>
    <modal name="addOrEditOdom" @closed="$emit('on-close')" :shiftY="0.2" :min-height="450" width="800px" :scrollable="true" height="auto" :reset="true" :adaptive="true">
        <div class="col-xl-12 order-xl-1 mobile-p-0 p-0">
            <!-- Header -->
            <div class="card-header border-bottom">
                <div class="row pt-2">
                    <div class="col-6">
                        <h5 v-if="selectedEntry.id" class="modal-title">{{ $t('label.vehicles edit odometer entry') }}</h5>
                        <h5 v-else class="modal-title">{{ $t('label.vehicles new odometer entry') }}</h5>
                    </div>
                    <div class="col-6">
                        <button type="button" class="close" @click="closeComponent()">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                </div>
            </div>
            <!-- Body -->
            <div class="card-body">
                <div class="form-group">
                    <div class="p-2">
                        <!-- Odometer Reading -->
                        <div class="pb-3">
                            <label class="font-weight-bold">{{ $t('label.vehicles odometer reading') }}</label>
                            <input class="form-control" type="number" v-model="selectedEntry.odometer_reading" min="0" max="10000000" oninput="validity.valid||(value='');" required>
                            <div class="" v-if="latestEntry.id">
                                {{ $t('label.vehicles last reading') }}:&nbsp;
                                {{ this.latestEntry.odometer_reading.toLocaleString() }} mi&nbsp;
                                ({{ this.latestEntry.odometer_reading_date }})&nbsp;

                                <span class="text-danger" v-if="this.entryErrors.odometerReading.length">{{ this.entryErrors.odometerReading }}</span>
                            </div>
                            <div v-else>{{ $t('label.vehicles no previous odometer log entries') }}</div>
                        </div>
                        <!-- Date -->
                        <div class="pt-2">
                            <!-- Padding on the top for open datepicker -->
                            <div v-if="datepickerOpen" class="pt-6"></div>

                            <label class="font-weight-bold">{{ $t('label.date') }}</label>
                            <date-picker v-model="selectedEntry.odometer_reading_date" valueType="format" format="MM/DD/YYYY" class="full-width" input-class="form-control" :append-to-body="false" :popup-style="{ top: '-275px', left: 0 }" :placeholder="$t('label.select date')" @open="isDatepickerOpen(true)" @close="isDatepickerOpen(false)">
                            </date-picker>
                            <div class="text-danger" v-if="this.entryErrors.odometerReadingDate.length">{{ this.entryErrors.odometerReadingDate }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Footer -->
            <div class="card-footer border-top d-flex">
                <button type="button" class="btn btn-primary mr-auto" :disabled="disableSubmit" @click="validateEntry()">
                    {{ $t("label.vehicles save entry") }}
                </button>
                <button type="button" class="btn btn-link ml-auto" @click="closeComponent()">
                    {{ $t('label.close') }}
                </button>
            </div>
        </div>
    </modal>
</template>

<script>

import moment from '@/utils/date-time';
import bus from '@/utils/event-bus';

export default {
    props: ['vehicleId', 'modal', 'editEntry'],
    data() {
        return {
            disableSubmit: false,
            currentDay: moment().format('MM/DD/YYYY'),
            selectedEntry: {
                odometer_reading: "",
                odometer_reading_date: moment().format('MM/DD/YYYY')
            },
            entryErrors: {
                odometerReading: "",
                odometerReadingDate: ""
            },
            latestEntry: {},
            datepickerOpen: false
        }
    },
    mounted() {
        this.getLatestEntry();

        if (this.editEntry)
            this.$set(this, 'selectedEntry', this.editEntry);

        console.log("editEntry", this.selectedEntry);
        this.$modal.show('addOrEditOdom');
    },
    methods: {
        getLatestEntry() {
            axios.get("/vehicle-odometer-history/get-latest/" + this.vehicleId).then(response => {
                this.latestEntry = response.data;
            }).catch(err => console.log(err));
        },

        isDatepickerOpen(status) {
            this.datepickerOpen = status;
            console.log("isDatepickerOpen", this.datepickerOpen);
        },

        validateEntry() {
            this.disableSubmit = true;
            this.entryErrors.odometerReading = "";
            this.entryErrors.odometerReadingDate = "";

            // Odometer Reading
            if (!String(this.selectedEntry.odometer_reading).length)
                this.entryErrors.odometerReading = "This field is required.";
            // Reading Date
            if (!this.selectedEntry.odometer_reading_date.length)
                this.entryErrors.odometerReadingDate = "This field is required.";

            if (this.entryErrors.odometerReading || this.entryErrors.odometerReadingDate) {
                this.disableSubmit = false;
                return;
            } else {
                this.selectedEntry.id ? this.updateEntry() : this.saveEntry();
            }
        },

        saveEntry() {
            let toCreate = {
                vehicle_id: this.vehicleId,
                odometer_reading: this.selectedEntry.odometer_reading,
                odometer_reading_date: moment(this.selectedEntry.odometer_reading_date).format("YYYY-MM-DD")
            };

            axios.post("/vehicle-odometer-history/store", toCreate).then(response => {
                this.closeComponent();

                bus.$emit('banner-success', {
                    message: this.$t('label.vehicles odometer log entry updated successfully') + '!'
                });
            }).catch(err => console.log(err));
        },

        updateEntry() {
            let toUpdate = {
                id: this.selectedEntry.id,
                odometer_reading: this.selectedEntry.odometer_reading,
                odometer_reading_date: moment(this.selectedEntry.odometer_reading_date).format("YYYY-MM-DD")
            };

            axios.post("/vehicle-odometer-history/update", toUpdate).then(response => {
                this.closeComponent();

                bus.$emit('banner-success', {
                    message: this.$t('label.vehicles odometer log entry updated successfully') + '!'
                });

            }).catch(err => console.log(err));
        },

        closeComponent() {
            this.$modal.hide('addOrEditOdom');
        }
    }
}
</script>
