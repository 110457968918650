<template>
    <div class="col-xl-12 order-xl-1 mobile-p-0">
        <div class="card">
            <div class="card-header">
                <div class="row d-flex align-items-center">
                    <div class="d-flex align-items-center mr-auto">
                        <i class="heading-title ri-article-line"></i>
                        <h3 class="pl-1 mb-0 d-flex align-items-center">{{ $t('label.vpos') }}</h3>
                    </div>
                    <div class="ml-auto">
                        <button type="button" class="btn btn-sm btn-default" @click="showCreateVpoModal()">
                            {{ $t('label.new vpo') }}
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="allJobVpos.length == 0" class="card-body py-5">
                <div class="bg-white text-center row align-items-center justify-content-center m-0">
                    <i class="ri-article-line" style="color: #CED4DA !important; font-size: 85px;"></i>
                    <p class="w-100 font-weight-normal">{{ $t('label.no VPOs have been created for this job') }}.</p>
                </div>
            </div>
            <div v-else>
                <div class="table-responsive">
                    <table class="table table-flush table-sm border-bottom">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col" style="width: 8.5rem">VPO #</th>
                                <th scope="col" width="10%">QUICKBOOKS INVOICE NO.</th>
                                <th scope="col" style="width: 15rem">{{ $t('label.customer') }}</th>
                                <th scope="col" style="width: 15rem">{{ $t('label.job') }}</th>
                                <th scope="col" style="width: 10rem">{{ $t('label.amount') }}</th>
                                <th scope="col" style="width: 10rem">{{ $t('label.created') }}</th>
                                <th scope="col" style="width: 10rem">{{ $t('label.status') }}</th>
                                <th scope="col" style="width: 8rem"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- Loop Tasks -->
                            <tr v-for="(data, index) in paginatedJobVpos" :key="index" class="pointer">
                                <!-- PO # -->
                                <td>
                                    <div class="cursor-pointer text-dark-blue text-bold">
                                        <router-link :to="{ name: 'variablePurchaseOrderView', params: { id: data.id } }">
                                            {{ data.vpo_number.vpo_number }}
                                        </router-link>
                                    </div>
                                </td>
                                <td><a v-if="data.qb_id" target="_blank" :href="qb_url + 'app/invoice?txnId=' + data.qb_id">{{ data.quickbooks_bill_no }}</a></td>
                                <!-- Customer -->
                                <td>
                                    <div class="cursor-pointer text-dark-blue text-bold">
                                        <router-link :to="{ name: 'customer', params: { customerId: data.customer.id } }">
                                            {{ data.customer?.name }}
                                        </router-link>
                                    </div>
                                </td>
                                <!-- Job -->
                                <td>{{ data.job.job_name }}</td>
                                <!-- Amount -->
                                <td>
                                    <div class="job-name search-task">${{ parseFloat(data.overall_total).toFixed(2) | formatThousands }}</div>
                                </td>
                                <!-- Created Date -->
                                <td>
                                    <div class="job-name search-task">{{ moment(data.created_at).format("MM/DD/YYYY") }}</div>
                                </td>
                                <!-- Status -->
                                <td>
                                    <span class="badge badge-dot search-task">
                                        <i class="bg-success" :style="'background-color:' + data.vpo_status.color + ' !important'"></i> {{ data.vpo_status.name }}
                                    </span>
                                </td>
                                <!-- Menu Dropdown -->
                                <td class="text-right">
                                    <a v-if="data.has_notes" class="btn dropdown-toggle" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                                        <i class="ri-message-2-line heading-title p-1"></i>
                                    </a>
                                    <a v-if="data.has_files" class="btn dropdown-toggle" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                                        <i class="ri-folders-line heading-title p-1"></i>
                                    </a>
                                    <a class="btn dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                                        <i class="ri-more-2-fill heading-title p-1"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" aria-labelledby="taskItemMenu">
                                        <!-- Edit VPO -->
                                        <a class="dropdown-item text-default pointer" @click="setSelectedVpo(data); showEditVpoModal()">
                                            <i class="ri-pencil-line"></i> {{ $t('label.edit') }}
                                        </a>
                                        <!-- View VPO Details -->
                                        <router-link class="dropdown-item text-default pointer" :to="{ name: 'variablePurchaseOrderView', params: { id: data.id } }">
                                            <i class="ri-eye-line"></i> {{ $t('label.view details') }}
                                        </router-link>
                                        <!-- View VPO PDF -->
                                        <a class="dropdown-item text-default pointer" @click="showModalVpoView(data)">
                                            <i class="ri-eye-line"></i> {{ $t('label.view pdf') }}
                                        </a>
                                        <!-- Send VPO -->
                                        <a v-if="data.vpo_status.name == 'Draft'" class="dropdown-item text-default pointer" @click="showModalVpoSend(data)">
                                            <i class="ri-mail-send-line"></i> {{ $t('label.send') }}
                                        </a>
                                        <!-- Download VPO as PDF -->
                                        <a @click="$downloadStream(`/get-vpo-pdf-download/${data.id}`, getPdfDownloadName(data))" class="dropdown-item text-default pointer">
                                            <i class="ri-download-line"></i> {{ $t('label.download') }}
                                        </a>
                                        <!-- Print VPO PDF -->
                                        <a @click="$openStreamBlank(`/get-vpo-pdf-file/${data.id}`), getPdfDownloadName(data)" class="dropdown-item text-default pointer">
                                            <i class="ri-printer-line"></i> {{ $t('label.print') }}
                                        </a>
                                        <!-- Mark Requested VPO as Approved or Denied -->
                                        <template v-if="data.vpo_status.name == 'Requested'">
                                            <div class="dropdown-divider mx-3"></div>
                                            <a class="dropdown-item text-default pointer" @click="setSelectedVpo(data); showMarkVpoApprModal()">
                                                <span class="d-flex align-items-center">
                                                    <i class="ri-checkbox-circle-line"></i> {{ $t('label.mark as approved') }}
                                                </span>
                                            </a>
                                            <a class="dropdown-item text-danger pointer" @click="setSelectedVpo(data); showMarkVpoDeniedModal()">
                                                <span class="d-flex align-items-center">
                                                    <i class="ri-close-circle-fill"></i> {{ $t('label.mark as denied') }}
                                                </span>
                                            </a>
                                        </template>
                                        <!-- Create Invoice from Approved VPO -->
                                        <template v-else-if="data.vpo_status.name == 'Approved'">
                                            <div class="dropdown-divider mx-3"></div>
                                            <a class="dropdown-item text-success pointer" @click="setSelectedVpo(data); showVpoInvoiceAddModal(data)">
                                                <span class="d-flex align-items-center">
                                                    <i class="ri-checkbox-circle-fill"></i> {{ $t('label.create invoice') }}
                                                </span>
                                            </a>
                                        </template>
                                        <!-- View Invoice PDF -->
                                        <template v-else-if="data.vpo_status.name == 'Invoiced'">
                                            <div class="dropdown-divider mx-3"></div>
                                            <a class="dropdown-item text-default pointer" @click="setSelectedVpo(data); showVpoInvoicePDFModal(data)">
                                                <span class="d-flex align-items-center">
                                                    <i class="ri-bill-line"></i> {{ $t('label.view invoice') }}
                                                </span>
                                            </a>
                                        </template>
                                        <!-- Cancel/Delete VPO -->
                                        <template v-if="isVpoRemovable(data)">
                                            <div class="dropdown-divider mx-3"></div>
                                            <a class="dropdown-item text-danger pointer" @click="setSelectedVpo(data); toggleDeleteModal(true)">
                                                <span class="d-flex align-items-center">
                                                    <i class="ri-close-circle-line"></i> {{ data.vpo_status.name == 'Draft' ? $t('label.delete vpo') : $t('label.cancel vpo') }}
                                                </span>
                                            </a>
                                        </template>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table-pagination v-model="paginatedJobVpos" :all-rows="allJobVpos" :perPage="perPage">
                    </table-pagination>
                </div>
            </div>
        </div>

        <!-- View PDF Modal -->
        <modal class="py-4" name="modalVpoView" height="auto" width="100%" :max-width="1000" :scrollable="true" :reset="true" :adaptive="true">
            <ModalVpoView :vpo="vpoData" @reload="loadLkupData" @cancelFnc="$modal.hide('modalVpoView')"></ModalVpoView>
        </modal>

        <!-- Send Modal -->
        <modal class="py-4" name="modalVpoSend" height="auto" width="100%" :max-width="900" :scrollable="true" :reset="true" :adaptive="true">
            <ModalVpoSend :vpo="vpoData" :send-type="sendType" @cancelFnc="$modal.hide('modalVpoSend')" @returnAfterSend="getJobVpos()"></ModalVpoSend>
        </modal>

        <!-- Create New Vpo -->
        <VpoCreateModal v-if="viewCreateVpoModal" source="Job" :jobId="jobId" @onSaveAndSend="handleCreateToSend" @onClose="hideCreateVpoModal(); getJobVpos()">
        </VpoCreateModal>

        <!-- Edit Vpo -->
        <VpoEditModal v-if="viewEditVpoModal" source="Job" :jobId="jobId" :vpoId="selectedVpo.id" @onSaveAndSend="handleEditToSend" @onClose="hideEditVpoModal(); getJobVpos()">
        </VpoEditModal>

        <!-- Mark VPO as Approved -->
        <ModalConfirmation v-if="viewMarkVpoApprModal" newId="MarkVpoApproved" :title="$t('label.ARE YOU SURE YOU WANT TO MARK THIS VPO AS APPROVED')" @onClose="hideMarkVpoApprModal()" @customConfirmMethod="updateStatus('Approved')">
        </ModalConfirmation>

        <!-- Mark VPO as Paid -->
        <ModalConfirmation v-if="viewMarkVpoPaidModal" newId="MarkVpoPaid" :title="$t('label.ARE YOU SURE YOU WANT TO MARK THIS VPO AS PAID')" @onClose="hideMarkVpoPaidModal()" @customConfirmMethod="updateStatus('Paid')">
        </ModalConfirmation>

        <!-- Mark VPO as Denied -->
        <ModalConfirmMarkDenied v-if="viewMarkVpoDeniedModal" newId="MarkVpoDenied" :btnText="$t('label.yes')" :title="$t('label.ARE YOU SURE YOU WANT TO MARK THIS VPO AS DENIED')" @onClose="hideMarkVpoDeniedModal()" @customDeleteMethod="updateStatus('Denied')">
        </ModalConfirmMarkDenied>

        <!-- Cancel VPO Modal -->
        <modal-delete-component v-if="showDltPOModal" :newId="'Vpo_view'" :title="$t('label.ARE YOU SURE YOU WANT TO CANCEL THIS VPO')" :subtitle="cancelVpoSubtitle" :btnText="isVpoDraft ? $t('label.Yes, Delete VPO') : $t('label.Yes, Cancel VPO')" @onClose="toggleDeleteModal()" @customDeleteMethod="isVpoDraft ? deleteVpo() : showSendCancelModal()">
        </modal-delete-component>

        <!-- Create Invoice From VPO -->
        <modal name="VpoInvoiceAddModal" class="pb-6" width="100%" height="auto" :reset="true" :adaptive="true" :max-width="1200" :scrollable="true">
            <ModalInvoice v-if="viewVpoInvoiceAddModal" source2="vpo" source="addInvoice" :job="selectedVpoJob" :vpoId="selectedVpo.id" :customerid="selectedVpo.customer_id" @reloadInvoices="getJobVpos" @cancelFnc="hideVpoInvoiceAddModal" @customChange="vpoInvcMdlCustChng">
            </ModalInvoice>
        </modal>

        <!-- VPO Edit Invoice Modal -->
        <modal name="VpoInvoiceEditModal" class="pb-6" width="100%" height="auto" :reset="true" :adaptive="true" :max-width="1200" :scrollable="true">
            <ModalInvoice v-if="viewVpoInvoiceEditModal" source2="vpo" source="editInvoice" :job="selectedVpoJob" :customerid="selectedVpo.customer_id" :invoice-data="{ data: selectedVpoInvoice }" @reloadInvoices="getJobVpos" @cancelFnc="hideVpoInvoiceEditModal" @customChange="vpoInvcMdlCustChng">
            </ModalInvoice>
        </modal>

        <!-- VPO Invoice PDF Modal -->
        <modal class="pb-6" name="vpoInvoiceViewModal" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
            <ModalInvoiceView v-if="viewVpoInvoicePDFModal" :job="selectedVpoJob" :invoice-data="{ data: selectedVpoInvoice }" @cancelFnc="hideVpoInvoicePDFModal" @reloadInvoices="getJobVpos"></ModalInvoiceView>
        </modal>

        <!-- VPO Invoice Send Modal -->
        <modal class="pb-6" name="vpoInvoiceSendModal" height="auto" width="100%" :max-width="900" :scrollable="true" :reset="true" :adaptive="true">
            <ModalInvoiceSend v-if="viewVpoInvoiceSendModal" :job="selectedVpoJob" :invoice-data="{ data: selectedVpoInvoice }" @reloadInvoices="getJobVpos" @cancelFnc="hideVpoInvoiceSendModal"></ModalInvoiceSend>
        </modal>

        <!-- VPO Invoice Payment Modal -->
        <modal class="pb-6" name="vpoInvoicePaymentModal" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
            <ModalPayment v-if="viewVpoInvoicePaymentModal" source2="job" source="invoice" :job="selectedVpoJob" :curr-id="selectedVpoJob.id" :invoice-data="{ data: selectedVpoInvoice }" @reloadPayments="getJobVpos" @cancelFnc="hideVpoInvoicePaymentModal"></ModalPayment>
        </modal>
    </div>
</template>

<script>
import ModalConfirmation from '@/components/ModalConfirmation.vue';
import ModalDeleteComponent from '@/components/ModalDelete2.vue';
import ModalInvoice from '@/components/ModalInvoice.vue';
import ModalInvoiceSend from '@/components/ModalInvoiceSend.vue';
import ModalInvoiceView from '@/components/ModalInvoiceView.vue';
import ModalPayment from '@/components/ModalPayment.vue';
import TablePagination from '@/components/TablePagination';
import ModalVpoSend from '@/components/VariablePurchaseOrders/SendVariablePurchaseOrder/VpoSendModal.vue';
import VpoCreateModal from '@/components/VariablePurchaseOrders/modals/VpoCreateModal.vue';
import VpoEditModal from '@/components/VariablePurchaseOrders/modals/VpoEditModal.vue';
import ModalVpoView from '@/components/VariablePurchaseOrders/modals/VpoPdfView.vue';
import bus from '@/utils/event-bus';

export default {
    props: ['jobId'],
    components: {
        ModalVpoView,
        ModalInvoice,
        ModalPayment,
        ModalVpoSend,
        VpoEditModal,
        VpoCreateModal,
        TablePagination,
        ModalInvoiceView,
        ModalInvoiceSend,
        ModalConfirmation,
        ModalDeleteComponent,
        ModalConfirmMarkDenied: ModalDeleteComponent,
    },
    data() {
        return {
            qb_url: process.env.VUE_APP_QUICKBOOKS_URL,
            perPage: 10,
            sendType: '',
            searchText: '',
            vpoData: [],
            allJobVpos: [],
            paginatedJobVpos: [],
            selectedVpo: {},
            selectedVpoJob: {},
            selectedVpoInvoice: {},
            showDltPOModal: false,
            viewEditVpoModal: false,
            viewCreateVpoModal: false,
            viewMarkVpoApprModal: false,
            viewMarkVpoPaidModal: false,
            viewMarkVpoDeniedModal: false,
            viewVpoInvoiceAddModal: false,
            viewVpoInvoicePDFModal: false,
            viewVpoInvoiceEditModal: false,
            viewVpoInvoiceSendModal: false,
            viewVpoInvoicePaymentModal: false,
        }
    },
    computed: {
        isVpoDraft() {
            return this.selectedVpo.vpo_status.name == "Draft" ? true : false;
        },
        cancelVpoSubtitle() {
            if (!this.isVpoDraft)
                return $t('label.This cannot be undone You will be able to send a message to the Customer on the next step');
            else
                return ' ';
        }
    },
    created() {
        // Register Bus Events
        bus.$on('ModalInvoiceSave', this.invoiceSaveHandler);
        bus.$on('ModalInvoiceViewDlt', this.invoiceDeleteHandler);
        bus.$on('InvoicesCompInvDlt', this.invoiceDeleteHandler);
    },
    mounted() {
        bus.$on('reloadVPO', () => {
            this.loadLkupData();
        });

        this.loadLkupData();
    },
    destroyed() {
        // Remove Bus Events
        bus.$off('ModalInvoiceSave', this.invoiceSaveHandler);
        bus.$off('ModalInvoiceViewDlt', this.invoiceDeleteHandler);
        bus.$off('InvoicesCompInvDlt', this.invoiceDeleteHandler);
    },
    methods: {
        loadLkupData() {
            this.getJobVpos();
        },

        async getJobVpos() {
            return axios.get(`/vpo_get_by_job_id/` + this.jobId).then(rsp => {
                this.allJobVpos = rsp.data.result;
            }).catch(err => console.log(err));
        },

        async getSelectedVpoJob(id) {
            return axios.get(`/get-job-by-id/${id}`).then(rsp => {
                this.selectedVpoJob = rsp.data.result;
            }).catch(err => console.log(err));
        },

        async getSelectedVpoInvoice(id) {
            return axios.get(`/get-invoice-by_id/${id}`).then(rsp => {
                this.selectedVpoInvoice = rsp.data.result;
            }).catch(err => console.error(err));
        },

        /* Vpo Approve Modal */
        showMarkVpoApprModal() {
            this.viewMarkVpoApprModal = true;
        },
        hideMarkVpoApprModal() {
            this.viewMarkVpoApprModal = false;
        },

        /* Vpo Deny Modal */
        showMarkVpoDeniedModal() {
            this.viewMarkVpoDeniedModal = true;
        },
        hideMarkVpoDeniedModal() {
            this.viewMarkVpoDeniedModal = false;
        },

        /* Create Vpo Modal */
        showCreateVpoModal() {
            this.viewCreateVpoModal = true;
        },
        hideCreateVpoModal() {
            this.viewCreateVpoModal = false;
        },
        handleCreateToSend(vpoId) {
            this.getJobVpos().then(() => {
                let vpoIdx = this.allJobVpos.findIndex(vpo => vpo.id = vpoId);
                if (vpoIdx >= 0) {
                    this.hideCreateVpoModal();
                    this.showModalVpoSend(this.allJobVpos[vpoIdx]);
                }
            });
        },

        /* Edit Vpo Modal */
        showEditVpoModal() {
            this.viewEditVpoModal = true;
        },
        hideEditVpoModal() {
            this.viewEditVpoModal = false;
        },
        handleEditToSend(vpoId) {
            this.getJobVpos().then(() => {
                let vpoIdx = this.allJobVpos.findIndex(vpo => vpo.id = vpoId);
                if (vpoIdx >= 0) {
                    this.hideEditVpoModal();
                    this.showModalVpoSend(this.allJobVpos[vpoIdx]);
                }
            });
        },

        /* View Vpo Modal */
        showModalVpoView(item = null) {
            this.vpoData = item;
            this.$modal.show('modalVpoView');
        },
        hideModalVpoView() {
            this.$modal.hide('modalVpoView');
        },

        /* Vpo Send Modal */
        showModalVpoSend(item = null) {
            this.vpoData = item;
            this.sendType = "request";
            this.$modal.show('modalVpoSend');
        },
        hideModalVpoSend() {
            this.$modal.hide('modalVpoSend');
        },

        /* Vpo Send(Cancel Modal) */
        showSendCancelModal() {
            this.sendType = "cancel";
            this.$modal.show('modalVpoSend');
        },

        /* Vpos Invoice Create View */
        showVpoInvoiceAddModal(vpo) {
            // Render modal after necessary data has loaded
            this.getSelectedVpoJob(vpo.job_id).then(() => {
                this.viewVpoInvoiceAddModal = true;
                this.$modal.show("VpoInvoiceAddModal");
            });
        },
        hideVpoInvoiceAddModal() {
            this.viewVpoInvoiceAddModal = false;
            this.$modal.hide("VpoInvoiceAddModal");
        },

        /* Vpos Invoice Edit View */
        showVpoInvoiceEditModal() {
            this.viewVpoInvoiceEditModal = true;
            this.$modal.show("VpoInvoiceEditModal");
        },
        hideVpoInvoiceEditModal() {
            this.viewVpoInvoiceEditModal = false;
            this.$modal.hide("VpoInvoiceEditModal");
        },

        /* Vpos Invoice PDF View */
        showVpoInvoicePDFModal(vpo) {
            let promises = [
                this.getSelectedVpoJob(vpo.job_id),
                this.getSelectedVpoInvoice(vpo.invoice_id),
            ];

            Promise.all(promises).then(() => {
                this.viewVpoInvoicePDFModal = true;
                this.$modal.show('vpoInvoiceViewModal');
            });
        },
        hideVpoInvoicePDFModal(value) {
            this.viewVpoInvoicePDFModal = false;
            this.$modal.hide('vpoInvoiceViewModal');

            // If PDF Modal is calling a different modal
            if (value == 'editSelected')
                this.showVpoInvoiceEditModal();
            else if (value == 'invoiceSend')
                this.showVpoInvoiceSendModal();
            else if (value == 'invoicePayment')
                this.showVpoInvoicePaymentModal();
        },

        /* Vpos Invoice Send View */
        showVpoInvoiceSendModal() {
            this.viewVpoInvoiceSendModal = true;
            this.$modal.show('vpoInvoiceSendModal');
        },
        hideVpoInvoiceSendModal() {
            this.viewVpoInvoiceSendModal = false;
            this.$modal.hide('vpoInvoiceSendModal');
        },
        vpoInvcMdlCustChng(value, invId) {
            // Prepare to send the invoice
            if (value == 1) {
                // Update invoice data
                this.getSelectedVpoInvoice(invId).then(() => {
                    this.showVpoInvoiceSendModal();
                });
            }
        },

        /* Vpo Invoice Process Payment Modal */
        showVpoInvoicePaymentModal() {
            this.viewVpoInvoicePaymentModal = true;
            this.$modal.show('vpoInvoicePaymentModal');
        },
        hideVpoInvoicePaymentModal() {
            this.viewVpoInvoicePaymentModal = false;
            this.$modal.hide('vpoInvoicePaymentModal');
        },

        editExistingJob(jobNumber) {
            this.$router.push({
                path: `jobs/edit/${jobNumber}`
            });
        },
        setSelectedVpo(item) {
            this.selectedVpo = item;
            this.vpoData = this.selectedVpo;
        },
        toggleDeleteModal(status) {
            this.showDltPOModal = status;
        },

        isVpoRemovable(vpo) {
            let stNm = vpo.vpo_status.name;
            if (['Draft', 'Requested'].findIndex((nm) => nm == stNm) >= 0)
                return true;
        },

        invoiceSaveHandler(invoice) {
            if (invoice.invoice_vpos.length > 0) {
                // If invoice has vpos, the vpos need to update to check for changes
                this.getJobVpos();
            } else {
                // if invoice currently has no vpos, check to see if 
                // current state vpos have same invoice id to react to 
                // the vpos being removed from the invoice
                this.allJobVpos.forEach(vpo => {
                    if (vpo.invoice_id == invoice.id)
                        this.getJobVpos();
                });
            }
        },
        invoiceDeleteHandler(invoiceId) {
            // Update the vpos if they deleted invoice contatined a vpo
            this.allJobVpos.forEach(vpo => {
                if (vpo.invoice_id == invoiceId)
                    return this.getJobVpos();
            });
        },

        getPdfDownloadName(vpo) {
            let name = vpo.vpo_status.name == 'Cancelled' ? 'CANCELLED_' : '';
            return name + "VPO-" + vpo.vpo_number.vpo_number + "_" + moment().format('YYYY_MM_DD') + ".pdf";
        },

        updateStatus(statusName) {
            let rqstData = {
                id: this.selectedVpo.id,
                status_name: statusName
            };

            axios.post("/vpo-update-status", rqstData).then(() => {
                this.getJobVpos();
                bus.$emit('banner-success', {
                    message: $t('label.VPO status updated successfully') + '!'
                });
            }).catch(err => console.log(err));
        },

        // For drafts
        deleteVpo() {
            let rqstData = {
                id: this.selectedVpo.id,
                status_name: this.selectedVpo.vpo_status.name
            };

            axios.post("/vpo-delete", rqstData).then(() => {
                this.getJobVpos();
                bus.$emit('banner-success', {
                    message: $t('label.VPO Draft deleted successfully') + '!'
                });
            }).catch(err => console.log(err));
        },
    }
}
</script>