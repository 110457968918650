<template>
    <div>
        <div class="modal" id="selectMsgTypeModal" tabindex="-1" role="dialog" aria-labelledby="selectMsgTypeModalTitle" aria-hidden="true">
            <div class="modal-dialog" role="document" style="max-width: 1000px">
                <div class="modal-content">
                    <div class="modal-header border-bottom">
                        <h5 class="modal-title" id="selectMsgTypeModalTitle">{{ $t('label.create message') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div>{{ $t('label.What kind of Message do you want to create') }}?</div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group my-4">
                                    <div>
                                        <h2>{{ $t('label.external messages') }}</h2>
                                        <div>{{ $t('label.External messages are things like emails and SMS messages send outside the system') }}.</div>
                                        <div class="row my-4 mx-0">
                                            <button type="button" class="col-sm btn btn-message-option py-4 align-items-center mobile-m-0 mobile-my-10p" data-toggle="modal" data-dismiss="modal" data-target="#messageAddModal" @click="getMessageType('email')">
                                                <div class="d-flex align-items-center btn-message-icon">
                                                    <i class="ri-mail-send-line"></i>{{ $t('label.email') }}
                                                </div>
                                                <div class="btn-message-label font-weight-normal">{{ $t('label.Send an email to employees') }}.</div>
                                            </button>
                                            <button type="button" class="col-sm btn btn-message-option py-4 align-items-center mobile-my-10p" data-toggle="modal" data-dismiss="modal" data-target="#messageAddModal" @click="getMessageType('text'); setMessageId()">
                                                <div class="d-flex align-items-center btn-message-icon">
                                                    <i class="ri-smartphone-line"></i>{{ $t('label.sms message') }}
                                                </div>
                                                <div class="btn-message-label font-weight-normal">{{ $t('label.Send an SMS message to employees') }}.</div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group my-4">
                                    <div>
                                        <h2>{{ $t('label.internal messages') }}</h2>
                                        <div>{{ $t('label.Internal messages are delivered and displayed within the system') }}. {{ $t('label.Users will see it as they work in the system') }}</div>
                                        <div class="row my-4 mx-0">
                                            <button type="button" class="col-sm btn btn-message-option py-4 d-block mobile-my-10p" data-toggle="modal" data-dismiss="modal" data-target="#messageAddModal" @click="getMessageType('prompt')">
                                                <h2 class="btn-message-title font-weight-normal">{{ $t('label.prompt screen') }}</h2>
                                                <div class="btn-message-image">
                                                    <img src="/images/messages/Prompt Screen Graphic.png" alt="Prompt Screen Graphic" />
                                                </div>
                                                <div class="btn-message-label font-weight-normal py-2">
                                                    {{ $t('label.Prompt Screens fill the entire screen and require the user to acknowledge or take action before it will close') }}.
                                                </div>
                                            </button>
                                            <button type="button" class="col-sm btn btn-message-option py-4 d-block mobile-my-10p" data-toggle="modal" data-dismiss="modal" data-target="#messageAddModal" @click="getMessageType('snippet')">
                                                <h2 class="btn-message-title font-weight-normal">{{ $t('label.snippet') }}</h2>
                                                <div class="btn-message-image">
                                                    <img src="/images/messages/Snippet Screen Graphic.png" alt="Snippet Screen Graphic" />
                                                </div>
                                                <div class="btn-message-label font-weight-normal py-2">
                                                    {{ $t('label.Snippets appear in the lower left of the screen') }}. {{ $t('label.They can be informational or require action') }}.
                                                </div>
                                            </button>
                                            <button type="button" class="col-sm btn btn-message-option py-4 d-block mobile-my-10p" data-toggle="modal" data-dismiss="modal" data-target="#messageAddModal" @click="getMessageType('banner')">
                                                <h2 class="btn-message-title font-weight-normal">{{ $t('label.banner') }}</h2>
                                                <div class="btn-message-image">
                                                    <img src="/images/messages/Banner Screen Graphic.png" alt="Banner Screen Graphic" />
                                                </div>
                                                <div class="btn-message-label font-weight-normal py-2">
                                                    {{ $t('label.Banners appear across the top of the page with a short message and can be informational or require actions') }}.
                                                </div>
                                            </button>
                                            <button type="button" class="col-sm btn btn-message-option py-4 d-block mobile-my-10p" data-toggle="modal" data-dismiss="modal" data-target="#messageAddModal" @click="getMessageType('popup')">
                                                <h2 class="btn-message-title font-weight-normal">{{ $t('label.popup') }}</h2>
                                                <div class="btn-message-image">
                                                    <img src="/images/messages/Popup Screen Graphic.png" alt="Popup Screen Graphic" />
                                                </div>
                                                <div class="btn-message-label font-weight-normal py-2">
                                                    {{ $t('label.Popups are urgent messages or alerts that users need to see immediately') }}.
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer border-top">
                        <button type="button" class="btn btn-link text-primary" data-dismiss="modal">{{ $t('label.close') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <message-form-component></message-form-component>
    </div>
</template>
<script>
export default {
    data() {
        return {
        }
    },
    computed: {
    },
    created() {
    },
    mounted() {
    },
    methods: {
        getMessageType(messageType) {
            this.$store.dispatch('getMessageType', messageType);
            this.$store.dispatch('clearFormState');
        },
        setMessageId() {
            this.$store.dispatch('getMessageId', 0);
        }
    }
}
</script>
