<template>
    <div class="chat-job">
        <div class="card">
            <div class="card-header">
                <div class="row align-items-center">
                    <div class="col-8">
                        <h3 class="mb-0 d-flex align-items-center">
                            <i class="ri-discuss-line heading-title"></i>Chat
                        </h3>
                    </div>
                    <div class="col-4 text-right">
                        <!-- <button type="button" class="btn btn-sm btn-default m-0"> View Details </button> -->
                    </div>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="row m-0">
                    <div id="messages" ref="chatmessages" class="col-12" :style="offsetHeight">
                        <!-- open message -->
                        <div v-if="chatMessages && chatMessages.length > 0">
                            <SlideYDownTransition group>
                                <div v-for="(data, index) in chatMessages" :key="index">
                                    <div v-if="data.sender_id == user.id" class="message-section-owner all-messages">
                                        <div>
                                            <div class="text-right flex-center">
                                                <span class="time">{{ data.created_at | formatChatDateAndTime }}</span>
                                                <i v-if="data.id" class="ri-check-double-line"></i>
                                                <div v-else class="loading-div loadingAnimation ml-1"></div>
                                            </div>
                                            <div class="message">
                                                <img v-if="data.image_url" class="chat-image" :src="data.image_url" />
                                                <div v-html="data.message"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="message-section all-messages">
                                        <img v-if="data?.sender?.picture" class="avatar-img rounded-circle" :src="data.sender.picture" />
                                        <div>
                                            <div>
                                                <span class="name">{{ data.sender.name }}</span> |
                                                <span class="time">{{ data.created_at | formatChatDateAndTime }}</span>
                                            </div>
                                            <div class="message">
                                                <img v-if="data.image_url" class="chat-image" :src="data.image_url" />
                                                <div v-html="data.message"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SlideYDownTransition>
                        </div>
                        <div v-else style="width:100%;height: 100%;display:flex;align-items: center;justify-content: space-between;">
                            <div style="flex:1;text-align: center">
                                <h2 class="text-gray font-bold">{{ retrievingMessages ? 'Loading ...' : 'No messages yet ...' }}</h2>
                            </div>
                        </div>
                        <!-- end message -->
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <form method="post" class="w-100" autocomplete="off" enctype="multipart/form-data" @submit="sendMessage">
                        <div class="col-12 d-flex align-items-end">
                            <!-- <input type="file" ref="file" name="photo" class="form-control" placeholder="Photo" @change="updateImage" autofocus style="display: none">
                            <i class="ri-image-2-line heading-title pr-2 cursor-pointer2" @click="$refs.file.click()"></i> -->
                            <div class="chat-box">
                                <div class="text-left" v-if="chat.image">
                                    <img class="" :src="chat.image" />
                                    <i class="ri-close-circle-line heading-title remove-image" @click="removeImage"></i>
                                </div>
                                <at-ta :members="allMembers" name-key="name">
                                    <template slot="item" slot-scope="s">
                                        <span v-html="s.item.name" @click="selectedName(s.item)"></span>
                                    </template>
                                    <textarea v-model="chat.message" @focus="onFocusTextarea" name="notes" rows="1" class="form-control" placeholder="Type something here..." required autofocus></textarea>
                                </at-ta>
                                <span class="chat-emoji-selector">
                                    <emoji-picker @emoji="append" :search="search">
                                        <div class="emoji-invoker" slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent">
                                            <b-icon icon="emoji-smile" aria-hidden="true" class="f-primary"></b-icon>
                                        </div>
                                        <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                                            <div class="emoji-picker">
                                                <div class="emoji-picker__search">
                                                    <input type="text" v-model="search" v-focus>
                                                </div>
                                                <div>
                                                    <div v-for="(emojiGroup, category) in emojis" :key="category">
                                                        <h5>{{ category }}</h5>
                                                        <div class="emojis">
                                                            <span v-for="(emoji, emojiName) in emojiGroup" :key="emojiName" @click="insert(emoji)" :title="emojiName">
                                                                {{ emoji }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </emoji-picker>
                                </span>
                            </div>
                            <i class="ri-send-plane-2-line heading-title pl-2 cursor-pointer2" @click="sendMessage"></i>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import At from 'vue-at'
import AtTa from 'vue-at/dist/vue-at-textarea';
import EmojiPicker from 'vue-emoji-picker';
import { mapGetters } from 'vuex';
import { SlideYDownTransition } from "vue2-transitions";

export default {
    name: 'JobChat',
    props: {
        job: {
            required: true
        }
    },
    components: {
        EmojiPicker,
        At,
        AtTa,
        SlideYDownTransition,
    },
    computed: {
        ...mapGetters({
            //
        }),
        allMembers() {
            let members = [];
            if (this.jobChatRoom && this.jobChatRoom.users) {
                this.jobChatRoom.users.forEach((data) => {
                    members.push(data.user);
                });
            }
            return members;
        }
    },
    directives: {
        focus: {
            inserted(el) {
                el.focus()
            },
        },
    },
    watch: {
        'jobChatRoom.messages': function (newVal, oldVal) {
            // this.scrollToEnd(800)
            this.show = true;
            this.getMessages(true);
        },
        chatMessages: function () {
            this.scrollToEnd()
        }
    },
    data: () => ({
        user: JSON.parse(localStorage.getItem('user')),
        hasUnreadMessage: false,
        search: '',
        jobChatRoom: null,
        show: false,
        chat: {
            message: '',
            image: null,
            imageFile: '',
        },
        chatMessages: [],
        retrievingMessages: false,
        sendingMessage: false,
        offsetHeight: 0,
    }),
    mounted() {
        let offsetHeight = document.getElementById('job-information-card').offsetHeight
        this.offsetHeight = { 'height': `calc(${offsetHeight}px - 120px)` };

        this.$echo.channel(`job-chat-room.${this.job.id}`)
            .listen('.new-message', (data) => {
                this.chatMessages.push(data.message)
                // this.scrollToEnd();
            });
        this.getMessages();
    },
    methods: {
        selectedName() { },
        updateImage() {

        },
        removeImage() {

        },
        getMessages(refresh = false) {
            this.show = true;
            this.retrievingMessages = true;
            if (refresh) {
                this.options.offset = 0;
            }
            axios.get(`/job-chat/get-all-messages-bu-job-id/${this.job.id}`).then(response => {
                this.retrievingMessages = false;
                this.chatMessages = response.data.data.messages;
                // this.show = false;
                this.scrollToEnd();
            })
        },
        sendMessage(e) {
            e.preventDefault();
            this.show = true;
            const message = this.chat.message;
            this.chat.message = '';
            const pushedIndex = this.chatMessages.push({
                sender_id: this.user.id,
                message: message,
                id: null,
                created_at: new Date(),
            }) - 1;
            // this.scrollToEnd();
            axios.post(`/job-chat/post-message/${this.job.id}`, {
                'message': message
            }).then(response => {
                this.sendingMessage = false;
                this.$set(this.chatMessages, pushedIndex, response.data.data.message)
                this.show = false;
                // this.scrollToEnd();
            }).catch(err => {
                this.chatMessages = this.chatMessages.slice(1, pushedIndex)
                this.cha
                this.chat.message = message;
            })
        },
        onFocusTextarea() {
            // $('.height-auto-not').addClass('height-auto').removeClass('height-auto-not')
        },
        onBlurTextarea() {
            // $('.height-auto').addClass('height-auto-not').removeClass('height-auto')
        },
        append(emoji) {
            this.chat.message += emoji
        },
        scrollToEnd(timeout = 50) {
            let content = this.$refs.chatmessages;
            setTimeout(() => {
                if (content !== undefined) {
                    content.scrollTop = content.scrollHeight;
                }
            }, timeout)
        },
    }
}
</script>

<style scoped>
.flex-center {
    display: flex;
    align-items: center;
}

.loading-div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px dashed #000;
}

.loadingAnimation {
    animation-name: rotate;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
</style>