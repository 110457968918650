<template>
    <div>
        <!-- MODAL FOR GETTING STARTED -->
        <div class="modal fade" id="reachedUserLimitModal" tabindex="-1" role="dialog" aria-labelledby="reachedUserLimitModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 800px">
                <div class="modal-content">
                    <div class="modal-header border-bottom">
                        <button type="button" class="close" data-dismiss="modal" @click="save()" aria-label="Close">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                    <div class="modal-body text-center">
                        <h2 class="text-light-black font-weight-normal">Additional user licenses required to proceed. Additional user licenses are ${{ price }}/month.</h2>
                        <h2 class="text-light-black font-weight-normal text-center">Would you like to proceed with purchasing an additional user license?</h2>
                        <div class="row">
<!--                            <div class="col-4">
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label class="form-control-label text-muted" for="input-number-of-users">Number of users:</label>
                                    <input v-model="form.number_of_users" id="input-number-of-users" type="number" name="number_of_users" class="form-control text-center" :placeholder="$t('label.task name')" min="1" required autofocus data-lpignore="true">
                                </div>
                            </div>
                            <div class="col-4">
                            </div>-->
                        </div>
                    </div>
                    <div class="modal-footer border-top">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                        <button type="submit" class="btn btn-primary" @click="save()">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['price'],
    data() {
        return {
            form: {
                number_of_users: 1
            }
        }
    },
    mounted() {
        /*$('#reachedUserLimitModal').modal('show');*/
    },
    methods: {
        save() {
            axios.post('/update-user-addon-subscription', this.form)
                .then(response => {
                    $('#reachedUserLimitModal').modal('hide');
                    window.location.href = '/user/create';
                })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        }
    },
}
</script>
<style>
.help-article-button {
    line-height: 1;
    font-size: .75rem;
    padding: 5px;
}
</style>
