import { FormRadioPlugin, IconsPlugin, OverlayPlugin, PaginationPlugin } from 'bootstrap-vue';
import Dialog from 'bootstrap-vue-dialog';
import Clipboard from "v-clipboard";
import VueMask from 'v-mask';
import money from 'v-money';
import Vue from 'vue';
import VueGeolocation from 'vue-browser-geolocation';
import VueCurrencyInput from 'vue-currency-input';
import excel from 'vue-excel-export';
import { default as Vmodal, default as VModal } from 'vue-js-modal';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-swatches/dist/vue-swatches.css';
import { ClientTable, ServerTable } from 'vue-tables-2';
import VueTextareaAutosize from "vue-textarea-autosize";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VueTransition from "vue2-transitions";
import Vuelidate from 'vuelidate';


Vue.use(Clipboard);
Vue.use(ClientTable);
Vue.use(ServerTable);
Vue.use(PaginationPlugin);
Vue.use(IconsPlugin);
Vue.use(OverlayPlugin);
Vue.use(FormRadioPlugin);
Vue.use(DatePicker);
Vue.use(VueMask);
Vue.use(VueCurrencyInput);
Vue.use(excel);
Vue.use(VueGeolocation);
Vue.use(money, { precision: 4 })
Vue.use(Vmodal);
Vue.use(Vuelidate)
Vue.use(Loading, { color: '#01224d', loader: 'dots', backgroundColor: '#000000', opacity: 0.5, width: 90, height: 90 })
Vue.use(VueTextareaAutosize);
Vue.use(VueTransition);
Vue.use(VModal);
Vue.use(Dialog)