<template>
    <div class="card">
        <div class="card-header">
            <h3 class="d-flex align-items-center mb-0">
                {{ $t('label.vpo info') }}
            </h3>
        </div>
        <div class="card-body">

            <div class="row">
                <div class="col-6">
                    <!-- Customer -->
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="customer_name">{{ $t('label.customer') }}</label>
                        <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                        <!-- From Job - Read Only -->
                        <div v-if="jobId >= 0">
                            <input class="form-control" type="text" v-model="$v.formInfo.customer_name.$model" disabled>
                        </div>
                        <!-- From Other - Selectable -->
                        <div v-else class="dropdown w-100">
                            <div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': (isValid.customer_name === false || $v.formInfo.customer_name.$error), }">
                                    <input type="text" class="form-control form-control-dropdown pointer" @input="filterCustomers" :placeholder="$t('label.start typing to search customers')" v-model.trim="$v.formInfo.customer_name.$model" autocomplete="input-field" data-lpignore="true" required>
                                    <div class="input-group-append">
                                        <span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
                                <h4 class="mx-3">{{ $t('label.recently viewed customers') }}</h4>
                                <div class="pl-3">
                                    <template v-for="(data, index) in allCustomersFilter">
                                        <div :key="index" class="pointer dropdown-items" @click="selectCustomer(data); deselectJob(); validateField('customer_name')">
                                            <span class="jobname">{{ data.name }}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <!-- VPO Date -->
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="purchase_date">{{ $t('label.vpo date') }}</label>
                        <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                        <date-picker v-model="$v.formInfo.purchase_date.$model" class="full-width" valueType="format" format="MM/DD/YYYY" :append-to-body="false" :placeholder="$t('label.vpo date')" :input-class="{ 'border-danger': (isValid.purchase_date === false || $v.formInfo.purchase_date.$error), 'form-control': true }" @change="validateField('purchase_date')" required>
                        </date-picker>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <!-- Job -->
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="job_order">{{ $t('label.job') }}</label>
                        <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                        <!-- From Job - Read Only -->
                        <div v-if="jobId >= 0">
                            <input class="form-control" type="text" v-model="$v.formInfo.job_name.$model" disabled>
                        </div>
                        <!-- From Other - Selectable -->
                        <div v-else class="dropdown w-100">
                            <div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': (isValid.job_name === false || $v.formInfo.job_name.$error), }">
                                    <input class="form-control form-control-dropdown pointer" @input="filterJobs" :placeholder="$t('label.start typing to search jobs')" type="text" v-model="$v.formInfo.job_name.$model" autocomplete="input-field" data-lpignore="true">
                                    <div class="input-group-append">
                                        <span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
                                <h4 class="mx-3">{{ $t('label.recently viewed jobs') }}</h4>
                                <div class="pl-3">
                                    <template v-for="(data, index) in allJobsFilter.slice(0, 4)">
                                        <div :key="index" class="pointer dropdown-items" @click="selectJob(data); validateField('job_name')">
                                            <span class="jobname">{{ data.job_name }}</span>
                                            <span v-if="data.customer" class="customername">{{ data.customer.name }}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Expected Completion Date -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="delivery_date">{{ $t('label.expected completion date') }}</label>
                        <date-picker v-model="formInfo.delivery_date" valueType="format" format="MM/DD/YYYY" class="full-width" input-class="form-control" :append-to-body="false" :placeholder="$t('label.expected completion date')" required>
                        </date-picker>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- Superintendent -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="superintendent">{{ $t('label.superintendent') }}</label>
                        <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                        <select name="superintendent" class="form-control pointer" v-model="$v.formInfo.superintendent_user_id.$model" @change="validateField('superintendent_user_id')" :class="{ 'border-danger': (isValid.superintendent_user_id === false || $v.formInfo.superintendent_user_id.$error), }">
                            <option value="0" disabled selected hidden>- {{ $t('label.select a superintendent') }} -</option>
                            <option v-for="(data, index) in customerContactList" :value="data.id" :key="index">{{ data.full_name }}</option>
                        </select>
                    </div>
                </div>
                <!-- Terms -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="terms">{{ $t('label.terms') }}</label>
                        <input type="text" name="terms" class="form-control" :placeholder="$t('label.terms')" v-model="formInfo.terms" required>
                    </div>
                </div>
            </div>
            <!-- Description or Special Instructions -->
            <div class="row mb-3">
                <div class="col-12">
                    <label class="form-control-label text-muted">{{ $t('label.description or special instructions') }}</label>
                    <textarea v-model="formInfo.comments" name="comments" class="form-control" :placeholder="$t('label.description or special instructions')" rows="5" required></textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TablePagination from '@/components/TablePagination';
import bus from '@/utils/event-bus';
import { required } from 'vuelidate/lib/validators';

export default {
    props: ['formInfo', 'isValid', 'poType', 'jobId'],
    emits: [''],
    components: {
        TablePagination
    },
    data() {
        return {
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '',
                precision: 2,
                masked: false
            },
            allJobs: [],
            vpoNumber: [],
            alljobpeople: [],
            allJobsFilter: [],
            allVpoStatuses: [],
            allCustomersFilter: [],
            customerContactList: [],
        }
    },
    validations: {
        formInfo: {
            customer_name: { required },
            superintendent_user_id: { required },
            job_name: { required },
            purchase_date: { required },
        },
    },
    mounted() {
        this.loadLkupData();

        if (this.jobId > 0) // Routing from job 
            this.initRelevantJobInfo();
    },
    methods: {
        loadLkupData() {
            let promises = [];

            promises.push(this.getVpoStatuses());
            promises.push(this.getOpenJobsTable());
            promises.push(this.getJobPeople());

            // Hide the loader overlay after all lookup data is loaded
            Promise.all(promises).then(() => {
                if (this.poType == 'add') {
                    this.formInfo.status = this.allVpoStatuses.filter(x => x.name == 'Draft').map(x => x.id)[0];
                } else if (this.poType == 'edit') {
                    for (const key in this.isValid)
                        this.isValid[key] = true;

                    this.selectJob(this.formInfo.job)
                }

                if (this.formInfo.customer_id) {
                    if (this.formInfo.type == 1 && this.poType == 'edit')
                        this.loadCustomerContacts();
                }
            });
        },
        initRelevantJobInfo() {
            axios.get(`/get_job_for_vpo/${this.jobId}`).then(rsp => {
                this.selectJob(rsp.data.result);
                this.validateField('job_name');
                this.validateField('customer_name');
            }).catch(err => console.log(err));
        },

        getVpoStatuses() {
            return axios.get(`/vpo_statuses_get_all`).then(resp => {
                this.allVpoStatuses = resp.data.result;
            }).catch(err => console.log(err));
        },
        getJobPeople() {
            return axios.get('/jobpeople').then(response => {
                this.alljobpeople = response.data.result;
                this.allCustomersFilter = this.alljobpeople['customers'];
            }).catch(err => { });
        },
        getOpenJobsTable() {
            return axios.get('/get-open-jobs-table').then(response => {
                this.allJobs = response.data.result;
                this.allJobsFilter = this.allJobs;
            }).catch(err => console.error('Error in getOpenJobsTable:', err));
        },
        loadCustomerContacts() {
            this.customerContactList = [];

            return axios.post('/loadCustomerContacts', {
                customerId: this.formInfo.customer_id
            }).then(response => {
                this.customerContactList = response.data;
            }).catch(err => {
                console.log('ERROR: ', err);
                bus.$emit('banner-error', { message: err });
            });
        },

        validateField(field) {
            // If field has error, set isValid field to false
            if (this.poType == 'add' || this.poType == 'edit')
                this.isValid[field] = !this.$v.formInfo[field].$error;
        },

        selectJob(data) {
            this.formInfo.job_name = data.job_name + ' - ' + (data.customer ? data.customer.name : '');
            this.formInfo.job_id = data.id;
            this.formInfo.job = data;

            this.selectCustomer(data.customer);
        },
        deselectJob() {
            this.formInfo.job_name = null;
            this.formInfo.job_id = null;
            this.formInfo.job = null;
        },
        selectCustomer(data) {
            this.formInfo.customer_name = data.name;
            this.formInfo.customer_id = data.id;
            this.formInfo.customer = data;
            this.loadCustomerContacts();

            this.allJobsFilter = this.allJobs.filter(x => x.customer_id == data.id);
        },
        filterJobs() {
            this.allJobsFilter = this.allJobs;
            if (this.formInfo.job_name.length > 0)
                this.allJobsFilter = this.allJobs.filter(x => x.job_name.toLowerCase().includes(this.formInfo.job_name.toLowerCase()));
        },
        filterCustomers() {
            this.allCustomersFilter = this.alljobpeople['customers'];
            if (this.formInfo.customer_name.length > 0)
                this.allCustomersFilter = this.alljobpeople['customers'].filter(x => x.name.toLowerCase().includes(this.formInfo.customer_name.toLowerCase()));
        },

        onCustomerChange() {
            this.loadCustomerContacts();
        },
    },
}
</script>
