<template>
	<!-- Delete Modal w/ Vue js Modal -->
	<modal :name="modalName" @closed="onClose()" :classes="'rounded'" :min-height="400" width="525px" :scrollable="true" height="auto" :reset="true" :adaptive="true">
		<div class="card-body bg-gradient-danger mobile-p-0 p-0">
			<div class="modal-header">
				<button type="button" class="close" @click.stop="closeFunc()">
					<span class="text-lighter close-button" aria-hidden="true">×</span>
				</button>
			</div>
			<div class="modal-body text-white">
				<div class="py-3 text-center">
					<i class="ri-error-warning-line ri-3x"></i>
					<h4 class="heading mt-4 text-white">{{ title ? title : $t('label.ARE YOU SURE YOU WANT TO DELETE THIS ITEM') }}?</h4>
					<p>{{ subtitle ? subtitle : $t('label.This action cannot be undone') }}</p>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-white close-button text-uppercase" @click.stop="deleteFunc()">{{ btnText ? btnText : $t('label.Yes, Delete') }}</button>
				<button type="button" class="btn btn-link text-white ml-auto close_delete close-button text-uppercase" data-dismiss="modal" @click.stop="closeFunc()">{{ $t('label.cancel') }}</button>
			</div>
		</div>
	</modal>
</template>

<script>
export default {
	props: ['newId', 'title', 'btnText', 'subtitle'],
	emits: ['customDeleteMethod', 'onClose'],
	data() {
		return {
			modalName: "deleteModal" + this.newId
		}
	},
	mounted() {
		this.$modal.show(this.modalName);
	},
	methods: {
		deleteFunc() {
			this.$emit('customDeleteMethod');
			this.closeFunc();
		},
		closeFunc() {
			this.onClose();
			this.$modal.hide(this.modalName);
		},
		onClose() {
			this.$emit('onClose', false);
		}
	},
}
</script>
