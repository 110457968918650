<template>
    <div>
        <div class="page-no-call-no-show">
            <div class="header bg-primary pb-6 mobile-p-0">
                <div class="container-fluid">
                    <div class="header-body">
                        <div class="row align-items-center py-4">
                            <div class="col-lg-6 col-7">
                                <breadcrumbs :title="$t('label.reports')">
                                    <!-- Breakcrumb slots -->
                                    <li class="breadcrumb-item">
                                        <router-link class="text-primary pointer" :to="{ name: 'reports' }">{{ $t('label.reports') }}</router-link>
                                        <!-- <a class="text-primary pointer" @click="$router.go(-1)">{{ $t('label.reports') }}</a> -->
                                    </li>
                                    <li class="breadcrumb-item">
                                        {{ $t('label.no call no show') }}
                                    </li>
                                </breadcrumbs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--NO CALL - NO SHOW REPORT-->
            <div class="container-fluid mt--6 mobile-mt-140p">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <div class="">
                                <div class="card-header">
                                    <div class="row d-flex justify-content-between align-items-center">
                                        <div class="col-6 col-xs-12">
                                            <h3 class="mb-0"><i class="fas fa-user-times"></i> {{ $t('label.no call no show') }}</h3>
                                        </div>
                                        <div class="col-6 col-xs-12 text-right">
                                            <button type="button" class="btn btn-default" @click="showForm('n')">
                                                {{ $t('label.add no show entry') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12 col-md-2">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="filter-start-date">{{ $t('label.start date') }}</label>
                                                <input id="filter-start-date" name="start_date" class="form-control datepicker" data-date-format="mm/dd/yyyy" v-model="filter.startDate" autocomplete="off">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-2">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="filter-end-date">{{ $t('label.end date') }}</label>
                                                <input id="filter-end-date" name="end_date" class="form-control datepicker" data-date-format="mm/dd/yyyy" v-model="filter.endDate" autocomplete="off">
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-2">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="filter-employee">{{ $t('label.employee') }}</label>
                                                <select id="filter-employee" class="form-control" v-model="filter.employeeId">
                                                    <option value="all" selected>- {{ $t('label.all employees') }} -</option>
                                                    <option v-for="(data, index) in employees" :value="data.id" :key="index">{{ data.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-2">
                                            <div class="form-group">
                                                <label class="form-control-label text-muted" for="filter-group">{{ $t('label.group by') }}</label>
                                                <select id="filter-group" class="form-control" v-model="filter.groupBy">
                                                    <option value="all" selected>- {{ $t('label.no grouping') }} -</option>
                                                    <option value="employee">{{ $t('label.employee') }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row align-items-center">
                                        <div class="col-6 text-left col-xs-6">
                                            <button type="button" class="btn btn-primary" @click="getNoCallNoShows(); getNoCallNoShows(); searchBox();">
                                                {{ $t('label.generate report') }}
                                            </button>
                                        </div>
                                        <!-- Excel Export -->
                                        <div class="col-6 text-right col-xs-6">
                                            <export-excel 
                                            class="btn btn-sm btn-default text-right" 
                                            :fetch="noCallNoShowDataForExport" 
                                            :fields="export_fields" 
                                            worksheet="No Call - No Show Report" 
                                            type="csv" 
                                            name="nocall-noshow-repot.xls">
                                                {{ $t('label.export results') }}
                                            </export-excel>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive pb-4">
                                    <no-call-no-show-report-datatable-component 
                                    :noCallNoShows="noCallNoShows" 
                                    :startDate="filter.startDate" 
                                    :endDate="filter.endDate" 
                                    :employeeId="filter.employeeId" 
                                    :groupByFilter="filter.groupBy" 
                                    :noCallNoShowsCount="noCallNoShowsCount">
                                    </no-call-no-show-report-datatable-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Add No Call - No Show Modal -->
        <div class="modal" id="noCallNoShowForm" tabindex="-1" role="dialog" aria-labelledby="noCallNoShowFormTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header border-bottom">
                        <h5 class="modal-title" id="noCallNoShowFormTitle">{{ $t('label.add a no call - no show') }}</h5>
                        <button type="button" class="close" aria-label="Close" data-dismiss="modal">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                    <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="processNoShow">
                        <div class="modal-body">
                            <div class="row">
                                <!-- Employee DD -->
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="form-control-label text-muted" for="form-employee-id">{{ $t('label.employee') }}</label>
                                        <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                                        <select id="form-employee-id" class="form-control" v-model="form.employeeId" required>
                                            <option value="" hidden>- {{ $t('label.all employees') }} -</option>
                                            <option v-for="(data, index) in employees" :value="data.id" :key="index">{{ data.name }}</option>
                                        </select>
                                    </div>
                                </div>

                                <!-- DAte Picker -->
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="form-control-label" for="form-date">{{ $t('label.date') }}</label>
                                        <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                                        <input id="form-date" 
                                        v-model="form.date" 
                                        class="form-control datepicker" 
                                        data-date-format="mm/dd/yyyy" 
                                        :placeholder="'-' + $t('label.select date') + '-'"
                                        required>
                                    </div>
                                </div>
                                <div class="col-6">
                                </div>
                                <!-- Comments -->
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="form-control-label" for="form-comment">{{ $t('label.comment') }}</label>
                                        <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                                        <textarea id="form-comment" v-model="form.comment" class="form-control" placeholder="Write a comment..." rows="5" required></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer border-top">
                            <button type="submit" class="btn btn-primary mr-auto">{{ $t('label.save') }}</button>
                            <button type="button" class="btn btn-link  ml-auto closeNoCallNoShowForm" data-dismiss="modal">{{ $t('label.close') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Delete Modal -->
        <div class="modal fade" id="noCallNoShowDeleteNotify" tabindex="-1" role="dialog" aria-labelledby="noCallNoShowDeleteNotify" aria-hidden="true">
            <div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
                <div class="modal-content bg-gradient-danger">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-bod   y">
                        <div class="py-3 text-center">
                            <i class="ri-error-warning-line ni-3x"></i>
                            <h4 class="heading mt-4">ARE YOU SURE YOU WANT TO DELETE THIS ENTRY?</h4>
                            <p>{{ $t('label.This action cannot be undone') }}</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-white" @click="processNoShow">{{ $t('label.Yes, Delete') }}</button>
                        <button type="button" class="btn btn-link text-white ml-auto closeDeleteRequestModal" data-dismiss="modal">{{ $t('label.cancel') }}</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
/* eslint-disable no-prototype-builtins */

import moment from '@/utils/date-time';
import bus from '@/utils/event-bus';
import * as $ from 'jquery';

export default {
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            noCallNoShows: [],
            noCallNoShowsCount: 0,
            employees: [],
            form: {
                employeeId: "",
                date: '',
                comment: '',
                action: '',
                id: 0
            },
            filter: {
                startDate: moment().startOf('month').format('L'),
                endDate: moment().format('L'),
                employeeId: 'all',
                groupBy: 'all',
            },
            export_fields: {
                'EMPLOYEE': 'user.name',
                'DATE(S)': {
                    field: 'date',
                    callback: (value) => {
                        return moment(value).format('L')
                    }
                },
                'CREATED_BY': 'created_by.name',
                'COMMENT': 'comment'
            }
        }
    },
    created() {

    },
    mounted() {
        this.getEmployees();
        this.getNoCallNoShows();
        this.getNoCallNoShows();
        var ref = this;
        $('.datepicker').ready(function () {
            $('.datepicker').datepicker();
            $('.datepicker').datepicker().on('changeDate', () => {
                ref.filter.startDate = $('#filter-start-date').val();
                ref.filter.endDate = $('#filter-end-date').val();
                ref.form.date = $('#form-date').val();
            });

        });

        let url = new URL(window.location.href);
        if (url.searchParams.has("startDate") && url.searchParams.has("endDate")) {
            this.filter.startDate = url.searchParams.get("startDate");
            this.filter.endDate = url.searchParams.get("endDate");
        }
        bus.$on('noCallNoShowForm', (data) => {
            const noCallNoShow = this.noCallNoShows[data.index];
            if (this.filter.groupBy && this.filter.groupBy !== 'all') {
                this.form = {
                    id: noCallNoShow.no_call_no_shows[data.key].id,
                    employeeId: noCallNoShow.no_call_no_shows[data.key].user_id,
                    date: moment(noCallNoShow.no_call_no_shows[data.key].date).format('L'),
                    comment: noCallNoShow.no_call_no_shows[data.key].comment
                }
            } else {
                this.form = {
                    id: noCallNoShow.id,
                    employeeId: noCallNoShow.user_id,
                    date: moment(noCallNoShow.date).format('L'),
                    comment: noCallNoShow.comment
                }
            }
            this.showForm('u');
        })
        bus.$on('deleteNoCallNoShowModal', (data) => {
            if (this.filter.groupBy && this.filter.groupBy !== 'all') {
                this.form = {
                    id: this.noCallNoShows[data.index].no_call_no_shows[data.key].id
                }
            } else {
                this.form = {
                    id: this.noCallNoShows[data.index].id
                }
            }
            this.form.action = 'd'
            $("#noCallNoShowDeleteNotify").modal('show');
        })

    },
    methods: {
        showForm(action = 'n') {
            if (action === 'n') {
                this.form = {
                    employeeId: "",
                    date: '',
                    comment: '',
                    id: 0
                }
            }
            $("#noCallNoShowForm").modal('show');
            this.form.action = action
        },
        async processNoShow() {
            const formData = new FormData()
            for (let key in this.form) {
                formData.append(key, this.form[key])
            }
            await axios.post('/process-no-call-no-show', formData).then(res => {
                if (res.data.status === 'success') {
                    bus.$emit('banner-success', { message: 'Success' })

                    $('.closeNoCallNoShowForm').click();
                    $('.closeDeleteRequestModal').click();
                    this.getNoCallNoShows();
                }
            }).catch(err => {
                console.log(err)
            });
        },
        clearSearch() {
            this.filter.startDate = null;
            this.filter.endDate = null;
            this.filter.employeeId = null;
            this.filter.groupBy = null;
        },
        searchBox() {
            this.showSearchBox = !this.showSearchBox;

            var ref = this;
            $('.datepicker').ready(function () {
                $('.datepicker').datepicker();

                $('.datepicker').datepicker().on('changeDate', () => {
                    ref.filter.startDate = $('#filter-start-date').val();
                    ref.filter.endDate = $('#filter-end-date').val();
                });
            });
        },
        async getNoCallNoShows() {
            await axios.get('/get-no-call-no-shows-report', {
                params: {
                    filters: this.filter
                }
            }).then(res => {
                this.noCallNoShows = res.data;
                let count = 0
                for (let key in this.noCallNoShows) {
                    if (this.noCallNoShows.hasOwnProperty(key)) {
                        if (!this.filter.groupBy || this.filter.groupBy === 'all') {
                            count++;
                        } else {
                            count += this.noCallNoShows[key].no_call_no_shows.length
                        }
                    }
                }
                this.noCallNoShowsCount = count;
                if ($.fn.DataTable.isDataTable('#datatable-report')) {
                    $('#datatable-report').DataTable().destroy().draw();
                } else {
                    $("#datatable-report").DataTable({
                        keys: !0,
                        language: {
                            paginate: {
                                previous: "<i class='fas fa-angle-left'>",
                                next: "<i class='fas fa-angle-right'>"
                            }
                        },
                        order: [1, "desc"],
                        pageLength: 10,
                        "searching": false,
                        "lengthChange": false
                    });
                }
            }).catch(err => {
                console.log(err)
            });
        },
        async getEmployees() {
            await axios.get('/get-employees-for-report').then(res => {
                this.employees = res.data
            }).catch(err => {
                console.log(err)
            });
        },
        noCallNoShowDataForExport() {
            let data = [];
            if (this.filter.groupBy && this.filter.groupBy !== 'all') {
                if (this.filter.groupBy === 'employee') {
                    for (let key in this.noCallNoShows) {
                        if (this.noCallNoShows.hasOwnProperty(key)) {
                            if (this.noCallNoShows[key].no_call_no_shows.length) {
                                this.noCallNoShows[key].no_call_no_shows.forEach(noCallNoShow => {
                                    data.push(noCallNoShow)
                                });
                            }
                        }
                    }
                }
            } else {
                data = this.noCallNoShows;
            }
            return data
        },
        disableEndDatesAfterToday(date) {
            return date > moment().subtract(1, 'days').toDate();
        },
    }
}
</script>
