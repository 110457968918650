<template>
    <div class="row">
        <!-- <div class="vld-parent">
            <loading :active.sync="isLoading" loader="dots" background-color="#000" blur="0px" :is-full-page="true"></loading>
        </div> -->

        <!-- Customers -->
        <div v-show="tableType == 1 || filter.search.length >= 3" class="col-12">
            <div class="card">
                <div class="card-header">
                    <h3 class="d-flex mb-0">
                        <i class="ri-group-line"></i> {{ $t('label.customers') }} 
                    </h3>
                    <button class="btn btn-primary text-bold pull-right to-right" @click="syncToQuickbooks" :disabled="customers.length == 0">Sync Quickbooks Online</button>
                    
                </div>
                <div v-if="!use_filter && customers.length == 0">
                    <div class="card-body m-0 py-5">
                        <div class="bg-white text-center row align-items-center justify-content-center m-0">
                            <img src="/images/no-customer.png" class="empty-state mb-3" />
                            <p class="w-100 font-weight-normal">No Customers have been added.</p>
                        </div>
                    </div>
                </div>
                <div v-else-if="use_filter && customers.length == 0">
                    <div class="card-body m-0 py-5">
                        <div class="bg-white text-center row align-items-center justify-content-center m-0">
                            <img src="/images/no-results.png" class="empty-state mb-3" />
                            <p class="w-100 font-weight-normal">No results were found. Try updating your search query.</p>
                        </div>
                    </div>
                </div>
                <!-- <div v-else class="table-responsive pb-4"> -->
                <div v-else class="table-responsive pb-4">
                    <table class="table table-flush" style="margin-top: 0px !important;" id="datatable-customer_info">
                        <thead class="thead-light">
                            <tr>
                                <!-- <th scope="col"></th> -->
                                <th scope="col">{{ $t('label.customer name') }}</th>
                                <th>Quickbooks ID</th>
                                <th scope="col">{{ $t('label.location') }}</th>
                                <th scope="col">{{ $t('label.responsible') }}</th>
                                <th scope="col">{{ $t('label.status') }}</th>
                                <th scope="col">{{ $t('label.budget') }}</th>
                                <th scope="col">{{ $t('label.contacts') }}</th>
                                <th scope="col" width="5%"></th>
                            </tr>
                        </thead>

                        <!-- Standard grouping -->
                        <tbody v-if="filter.groupBy.length == 0 || filter.groupBy[0] == 'none'">
                            <tr v-for="(data, index) in customers" :key="index">
                               <!--  <td>
                                 <input type="checkbox" name="qbcustomers" v-if="!data.qb_id" :value="data.id" v-model="qbcustomers" />
                                </td> -->
                                <td>
                                    <router-link class="dropdown-item" :to="{ name: 'customer', params: { customerInfo: data, customerId: data.id } }">
                                        <img class="rounded-circle" :src="data.logo" alt="" style="width: 36px; height: 36px; object-fit: cover;" />
                                        {{ data.name }}
                                    </router-link>
                                </td>
                                <td>{{ data.qb_id }}</td>
                                <td>
                                    <span>{{ data.address_1 }}, </span>
                                    <span v-if="data.address_2">{{ data.address_2 }}, </span><br />
                                    <span v-if="data.city">{{ data.city }}, </span>
                                    <span v-if="data.state">{{ data.stateCode }}, </span>
                                    <span v-if="data.postal_code">{{ data.postal_code }}</span>
                                </td>

                                <!-- Person Responsible -->
                                <td>
                                    <div v-if="data.user" class="cursor-pointer">
                                        <img class="rounded-circle" :src="data.user.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;" />
                                        {{ data.user ? data.user.name : '' }}
                                    </div>
                                </td>
                                <!-- Status -->
                                <td>
                                    <span v-if="data.status == 1" class="badge badge-dot">
                                        <i class="bg-success"></i>Active
                                    </span>
                                    <span v-else class="badge badge-dot">
                                        <i class="bg-light"></i>Inactive
                                    </span>
                                </td>
                                <!-- Budget -->
                                <td>
                                    ${{ parseFloat(data.budget ? data.budget : 0).toFixed(2) | formatThousands }}
                                </td>

                                <!-- Contacts Count -->
                                <td>
                                    <a href="#" data-toggle="modal" @click="showModal(data)">
                                        {{ data.contacts_count }} Contact(s)
                                    </a>
                                </td>

                                <!-- dropdown -->
                                <td class="text-right">
                                    <div class="dropdown">
                                        <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-ellipsis-v"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                            <router-link class="dropdown-item" :to="{ name: 'customer', params: { customerInfo: data, customerId: data.id } }">
                                                <i class="ri-eye-line"></i>
                                                {{ $t('label.view customer') }}
                                            </router-link>
                                            <a class="dropdown-item text-default pointer" @click="showEditCustomerModal(data)">
                                                <i class="ri-pencil-line"></i>
                                                {{ $t('label.edit customer') }}
                                            </a>
                                            <!-- <a class="dropdown-item text-danger pointer border-top" href="#" data-toggle="modal" @click="toggleModal('show', 'delete', data)">
                                                <i class="ri-delete-bin-line"></i> {{ $t('label.delete customer') }}
                                            </a> -->
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                        <!-- Display when grouping is by status or person responsible -->
                        <tbody v-else-if="filter.groupBy.length > 0 && filter.groupBy[0] != 'none'
                        " v-for="(groupContacts, groupName) in customers" :key="groupName">
                            <tr v-if="filter.groupBy" class="tgroup">
                                <td colspan="7" class="cursor-pointer2 px-3 py-0" @click="toggleOpenHide(groupName)">
                                    <h3 class="text-white mb-0">
                                        <span class="d-flex align-items-center">
                                            <i :class="groupContacts.isHidden ? 'ri-arrow-down-s-line' : 'ri-arrow-right-s-line'" class="mr-3 icon-list" :id="'icon-list-' + '-' + groupName"></i>
                                            {{ groupName }}
                                        </span>
                                    </h3>
                                </td>
                            </tr>
                            <template v-if="groupContacts.isHidden">
                                <tr v-for="(data, index) in groupContacts.data" :key="index">
                                    <td>
                                        <router-link class="dropdown-item" :to="{ name: 'customer', params: { customerInfo: data, customerId: data.id } }">
                                            {{ data.name }}
                                        </router-link>
                                    </td>
                                    <td>
                                        <span>{{ data.address_1 }}, </span>
                                        <span v-if="data.address_2">{{ data.address_2 }}, </span><br />
                                        <span v-if="data.city">{{ data.city }}, </span>
                                        <span v-if="data.state">{{ data.stateCode }}, </span>
                                        <span v-if="data.postal_code">{{ data.postal_code }}</span>
                                    </td>
                                    <td>
                                        <div v-if="data.user" class="cursor-pointer">
                                            <img class="rounded-circle" :src="data.user.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;" />
                                            {{ data.user ? data.user.name : '' }}
                                        </div>
                                    </td>
                                    <td>
                                        <span v-if="data.status == 1" class="badge badge-dot">
                                            <i class="bg-success"></i>Active
                                        </span>
                                        <span v-else class="badge badge-dot">
                                            <i class="bg-light"></i>Inactive
                                        </span>
                                    </td>
                                    <td>
                                        ${{ parseFloat(data.budget ? data.budget : 0).toFixed(2) | formatThousands }}
                                    </td>
                                    <td>{{ data.updated_at | formatDateOnly }}</td>
                                    <td class="text-right">
                                        <div class="dropdown">
                                            <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fas fa-ellipsis-v"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                <router-link class="dropdown-item" :to="{ name: 'customer', params: { customerInfo: data, customerId: data.id } }">
                                                    <i class="ri-eye-line"></i>
                                                    {{ $t('label.view customer') }}
                                                </router-link>
                                                <a class="dropdown-item text-default pointer" @click="showEditCustomerModal(data)">
                                                    <i class="ri-pencil-line"></i>
                                                    {{ $t('label.edit customer') }}
                                                </a>
                                                <!-- <a class="dropdown-item text-danger pointer border-top" data-toggle="modal" @click="toggleModal('show', 'delete', data)">
                                                    <i class="ri-delete-bin-line"></i> {{ $t('label.delete customer') }}
                                                </a> -->
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Leads -->
        <div v-show="tableType == 0 || filter.search.length >= 3" class="col-12">
            <div class="card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h3 class="d-flex mb-0"><i class="ri-account-box-line"></i> {{ $t('label.leads') }}</h3>
                        </div>
                    </div>
                </div>
                <div v-if="!use_filter && customers.length == 0">
                    <div class="card-body m-0 py-5">
                        <div class="bg-white text-center row align-items-center justify-content-center m-0">
                            <img src="/images/no-lead.png" class="empty-state mb-3" />
                            <p class="w-100 font-weight-normal">No Leads have been added.</p>
                        </div>
                    </div>
                </div>
                <div v-else-if="use_filter && customers.length == 0">
                    <div class="card-body m-0 py-5">
                        <div class="bg-white text-center row align-items-center justify-content-center m-0">
                            <img src="/images/no-results.png" class="empty-state mb-3" />
                            <p class="w-100 font-weight-normal">No results were found. Try updating your search query.</p>
                        </div>
                    </div>
                </div>
                <div v-else class="table-responsive pb-4">
                    <div v-if="orderedLeadStatusData.length > 0" class="row m-0" style="padding: 48px 24px;">
                        <table class="ribbon-table">
                            <tbody>
                                <tr>
                                    <td v-for="data in orderedLeadStatusData" :key="data.id" :style="'background: ' + data.color" :class="data.color == '#ADB5BD' || data.color == '#FC9105' ? 'font-weight-600 text-gray-dark' : 'font-weight-600 text-white'">
                                        {{ data.name }} (${{
                                            parseFloat(totalBudget[data.id]).toFixed(2)
                                            | formatThousands
                                        }})
                                    </td>
                                </tr>
                                <tr v-if="showPotential">
                                    <td :colspan="countPotential" class="potential font-weight-600 text-gray-dark">
                                        Potential (${{
                                            parseFloat(potential).toFixed(2) | formatThousands
                                        }})
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <table class="table table-flush" id="datatable-customer_info_leads">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">{{ $t('label.company name') }}</th>
                                <th scope="col">{{ $t('label.contact name') }}</th>
                                <th scope="col">{{ $t('label.responsible') }}</th>
                                <th scope="col">{{ $t('label.status') }}</th>
                                <th scope="col">{{ $t('label.budget') }}</th>
                                <th scope="col" width="10%">{{ $t('label.created') }}</th>
                                <th scope="col" width="10%">{{ $t('label.last updated') }}</th>
                                <th scope="col" width="5%"></th>
                            </tr>
                        </thead>
                        <tbody v-if="filter.groupBy.length == 0 || filter.groupBy[0] == 'none'">
                            <tr v-for="(data, index) in customers" :key="index">
                                <td>
                                    <router-link class="dropdown-item" :to="{ name: 'customer', params: { customerInfo: data, customerId: data.id } }">
                                        {{ data.name }}
                                    </router-link>
                                </td>
                                <td>{{ data.first_name }} {{ data.last_name }}</td>
                                <td>
                                    <div v-if="data.user" class="cursor-pointer">
                                        <img class="rounded-circle" :src="data.user.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                        {{ data.user ? data.user.name : '' }}
                                    </div>
                                </td>
                                <td>
                                    <span v-if="data.lead_status" class="badge badge-dot">
                                        <i class="bg-success" :style="'background-color:' + data.lead_status.color + ' !important'"></i> {{ data.lead_status.name }}
                                    </span>
                                </td>
                                <td>${{ parseFloat(data.budget ? data.budget : 0).toFixed(2) | formatThousands }}</td>
                                <td>{{ data.created_at | formatDateOnly }}</td>
                                <td>{{ data.updated_at | formatDateOnly }}</td>
                                <td class="text-right">
                                    <div class="dropdown">
                                        <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-ellipsis-v"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                            <router-link class="dropdown-item" :to="{ name: 'customer', params: { customerInfo: data, customerId: data.id } }">
                                                <i class="ri-eye-line"></i>
                                                {{ $t('label.view lead') }}
                                            </router-link>
                                            <a class="dropdown-item text-default pointer" @click="showEditCustomerModal(data)">
                                                <i class="ri-pencil-line"></i> {{ $t('label.edit lead') }}
                                            </a>
                                            <!-- <a class="dropdown-item text-danger pointer border-top" data-toggle="modal" @click="toggleModal('show', 'delete', data)">
                                                <i class="ri-delete-bin-line"></i> {{ $t('label.delete lead') }}
                                            </a> -->
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else-if="filter.groupBy.length > 0 && filter.groupBy[0] != 'none'" v-for="(groupContacts, groupName) in customers" :key="groupName">
                            <tr v-if="filter.groupBy" class="tgroup" :style="filter.groupBy[0] == 'status' && groupContacts.data[0].lead_status ? 'background-color: ' + groupContacts.data[0].lead_status.color : 'background-color: #2d8bb6'">
                                <td colspan="8" class="cursor-pointer2 px-3 py-0" @click="toggleOpenHide(groupName);">
                                    <h3 class="mb-0" :class="groupContacts.data[0].lead_status && (groupContacts.data[0].lead_status.color == '#ADB5BD' || groupContacts.data[0].lead_status.color) == '#FC9105' ? 'text-gray-dark' : 'text-white'">
                                        <span class="d-flex align-items-center">
                                            <i :class="groupContacts.isHidden ? 'ri-arrow-down-s-line' : 'ri-arrow-right-s-line'" class="mr-3 icon-list" :id="'icon-list-' + '-' + groupName"></i>
                                            {{ groupName }}
                                        </span>
                                    </h3>
                                </td>
                            </tr>
                            <template v-if="groupContacts.isHidden">
                                <tr v-for="(data, index) in groupContacts.data" :key="index">
                                    <td>
                                        <router-link class="dropdown-item" :to="{ name: 'customer', params: { customerInfo: data, customerId: data.id } }">
                                            {{ data.name }}
                                        </router-link>
                                    </td>
                                    <td>{{ data.first_name }} {{ data.last_name }}</td>
                                    <td>
                                        <div v-if="data.user" class="cursor-pointer">
                                            <img class="rounded-circle" :src="data.user.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                            {{ data.user ? data.user.name : '' }}
                                        </div>
                                    </td>
                                    <td>
                                        <span v-if="data.lead_status" class="badge badge-dot">
                                            <i class="bg-success" :style="'background-color:' + data.lead_status.color + ' !important'"></i> {{ data.lead_status.name }}
                                        </span>
                                    </td>
                                    <td>${{ parseFloat(data.budget ? data.budget : 0).toFixed(2) | formatThousands }}</td>
                                    <td>{{ data.created_at | formatDateOnly }}</td>
                                    <td>{{ data.updated_at | formatDateOnly }}</td>
                                    <td class="text-right">
                                        <div class="dropdown">
                                            <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fas fa-ellipsis-v"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                <router-link class="dropdown-item" :to="{ name: 'customer', params: { customerInfo: data, customerId: data.id } }">
                                                    <i class="ri-eye-line"></i>
                                                    {{ $t('label.view lead') }}
                                                </router-link>
                                                <a class="dropdown-item text-default pointer" @click="showEditCustomerModal(data)">
                                                    <i class="ri-pencil-line"></i> {{ $t('label.edit lead') }}
                                                </a>
                                                <!-- <a class="dropdown-item text-danger pointer border-top" data-toggle="modal" @click="toggleModal('show', 'delete', data)">
                                                    <i class="ri-delete-bin-line"></i> {{ $t('label.delete lead') }}
                                                </a> -->
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>


        <modal class="py-4" name="customerEditModal" height="auto" width="98%" :max-width="1024" :reset="true" :scrollable="true" :adaptive="true">
            <modal-customer :customerdata="editCustomer" source="customer-component" @cancelFnc="$modal.hide('customerEditModal')" @returnFnc="returnCustomer" />
        </modal>

        <!-- Customer contacts list modal -->
        <customer-contact-list @updateContactCount="updateContactCount" :customer="selectedCustomer" :open-previous-modal="openPreviousModal">
        </customer-contact-list>

        <!-- Delete Component -->
        <modal name="deleteContactsModal" @closed="resetDeleteForm()" :min-height="200" width="550px" height="auto" :scrollable="true" :reset="true">
            <div class="modal-content">
                <div class="modal-header border-bottom">
                    <h5 class="modal-title" id="paymentModalTitle">{{ form.accountType == '1' ? $t('label.delete customer') : $t('label.delete lead') }}</h5>
                    <button type="button" class="close" @click="toggleModal('hide', 'delete')">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <div class="modal-body p-2">
                    <div class="row m-0">
                        <div class="col-12">
                            <span class="deletetext">
                                Are you sure you want to delete this {{ form.accountType == '1' ? 'Customer' : 'Lead' }}? This action cannot be undone.
                                <br><br>
                                Enter the {{ form.accountType == '1' ? 'Customer' : 'Lead' }} name below to delete it.
                            </span>
                            <div class="form-group pt-3">
                                <div class="input-group">
                                    <input class="form-control" type="text" name="customerName" :placeholder="form.placeholder" @keyup="validateText" v-model="form.deleteName" required autofocus>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-top">
                    <button type="button" class="btn btn-danger text-uppercase" :disabled="disabled" @click="deleteCustomer()"><i class="ri-delete-bin-line"></i> {{ form.accountType == '1' ? $t('label.delete customer') : $t('label.delete lead') }}</button>
                    <button type="button" class="btn btn-link ml-auto text-uppercase" @click="toggleModal('hide', 'delete')">{{ $t('label.cancel') }}</button>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import bus from '@/utils/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CustomerContactList from './CustomerContact/CustomerContactListComponent';
import CustomerDetailComponent from './CustomerDetailComponent';
import ModalCustomer from './ModalCustomer';

export default {
    props: ['filter'],
    emits: ['fncCancel', 'fncReturn'],
    components: {
        Loading,
        ModalCustomer,
        CustomerContactList,
        CustomerDetailComponent,
    },
    data() {
        return {
            customers: [],
            leadStatus: [],
            wonAndLost: [],
            delete: null,
            newid: 'DeleteCustomer',
            deleteModalText: {
                title: 'ARE YOU SURE YOU WANT TO DELETE THIS CUSTOMER?',
                button: 'Yes, Delete'
            },
            openModal: false,
            delete_id: null,
            isLoading: false,
            tableType: 1,
            totalBudget: [],
            potential: 0,
            selectedCustomer: {},
            openPreviousModal: 0,
            use_filter: false,
            dataTable: null,
            activeModal: "",
            form: {
                placeholder: '',
                deleteName: '',
                deleteId: null,
                accountType: null
            },
            disabled: true,
            editCustomer: {},
            listCustomerLeads: [],
           // qbcustomers:[]
        }
    },
    computed: {
        orderedLeadStatusData: function () {
            return _.orderBy(this.filter.leadStatusData, 'order')
        },
        showPotential: function () {
            var ctr = 0;
            this.filter.leadStatusData.forEach((data) => {
                if (data.order == 98 || data.order == 99) {
                    //
                } else {
                    ctr++;
                }
            })
            if (ctr > 0) return true;
            return false;
        },
        countPotential: function () {
            var ctr = 0;
            this.filter.leadStatusData.forEach((data) => {
                if (data.order == 98 || data.order == 99) {
                    //
                } else {
                    ctr++;
                }
            })
            if (ctr > 0) {
                return ctr;
            }
            return false;
        }
    },
    created() {
        let url2 = window.location.href;
        const url = new URL(url2);
        const params = new URLSearchParams(url.search)

        if (params.get("account_type") == 0) {
            this.tableType = 0;
            this.getAllCustomers();
        } else {
            this.tableType = 1;
            this.getAllCustomers();
        }
    },
    mounted() {
        bus.$on('getallcustomers', (data) => {
            this.use_filter = false;
            this.tableType = data.tableType;
            this.use_filter = data.use_filter;

            if (this.tableType == 0) {
                this.getLeadStatus();
            }

            this.getAllCustomers();
        });
        bus.$on('deleteFCustomer', () => {
            this.deleteCustomer();
        });

    },
    methods: {
        // Pass the selected customer to the customer contacts modal
        showModal(selectedCustomer) {
            if (selectedCustomer === this.selectedCustomer) {
                this.openPreviousModal++;
            }
            this.selectedCustomer = selectedCustomer;
        },
        getLeadStatus() {
            axios.get('/get-lead-status')
                .then(response => {
                    this.leadStatus = response.data.result;
                    this.wonAndLost = response.data.won_and_lost;
                })
                .catch(err => console.error("API ERROR", err));
        },
        async getAllCustomers() {
            let that = this.listCustomerLeads;
            this.isLoading = true;
            this.customers = [];
            //this.qbcustomers = [];

            let params = {
                tableType: this.tableType,
                filters: this.filter
            }
            console.log('params', params);
            await axios.get('/get-all-contacts', { params })
                .then(response => {
                    this.customers = response.data.result;
                    this.totalBudget = response.data.totalBudget;
                    this.potential = response.data.potential;
                    console.log('customers', this.customers);
                    that = this.customers;

                    if (this.filter.groupBy[0] == 'none') {
                        let idTable = this.tableType == 1 ? '#datatable-customer_info' : '#datatable-customer_info_leads';
                        this.runDataTable();
                    }

                    this.isLoading = false;
                })
                .catch(err => console.error("API ERROR", err));
        },
        updateContactCount(contactData) {
            let idx = this.customers.findIndex(customer => {
                if (customer.id === contactData.customerId) {
                    return true;
                }
            });
            this.customers[idx].contacts_count = contactData.contactCount;
        },

        showEditCustomerModal(data) {
            this.editCustomer = data;
            console.log('the data', data);
            this.$modal.show('customerEditModal');

        },
        returnCustomer(updatedCustomer) {
            console.log('Returned Customer', updatedCustomer, this.customers)
            this.$modal.hide('customerEditModal');
            let idx = this.customers.findIndex(customer => {
                if (customer.id === updatedCustomer.id) {
                    return true;
                }
            });
            // Update selected customer with changes
            if (idx >= 0) {
                updatedCustomer.user = this.customers[idx].user;
                updatedCustomer.contacts_count = this.customers[idx].contacts_count;
                updatedCustomer.lead_status = this.customers[idx].lead_status;
                updatedCustomer.stateCode = this.customers[idx].stateCode;
                this.$set(this.customers, idx, updatedCustomer);
            }
        },
        deleteCustomer() {
            let data = {
                id: this.form.deleteId
            }
            axios.post('/customer-delete', data)
                .then(response => {
                    this.toggleModal('hide', 'delete');
                    this.getAllCustomers();
                })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        toggleOpenHide(index) {
            this.customers[index].isHidden = !this.customers[index].isHidden;
        },
        runDataTable() {
            let idTable = this.tableType == 1 ? '#datatable-customer_info' : '#datatable-customer_info_leads';

            if (this.dataTable !== null)
                this.dataTable.destroy();

            // Build Datatable
            this.$nextTick(() => {
                this.dataTable = $(idTable).DataTable({
                    order: [],
                    pageLength: 25,
                    "searching": false,
                    "lengthChange": true,
                    "bDestroy": true,
                    "sDom": 'Rfrtilp',
                    language: {
                        paginate: {
                            previous: "<i class='fas fa-angle-left'>",
                            next: "<i class='fas fa-angle-right'>"
                        },
                        lengthMenu: "Items per page _MENU_",
                    },
                });
            });

        },
        toggleModal(action, modal, data) {
            let modalToOpen = modal + 'ContactsModal';
            this.activeModal = modal;

            if (data) {
                this.form.placeholder = data.name;
                this.form.accountType = data.account_type;
                this.form.deleteId = data.id;
            }


            if (action === "show")
                this.$modal.show(modalToOpen);
            else
                this.$modal.hide(modalToOpen);

        },
        validateText() {
            this.disabled = true;
            if (this.form.deleteName === this.form.placeholder) this.disabled = false;
        },
        resetDeleteForm() {
            this.form.placeholder = '';
            this.form.deleteName = '';
            this.form.accountType = null;
            this.form.deleteId = null;
        },
         syncToQuickbooks(){
             let loading = this.$loading.show();
           axios.post('/quickbooks/sync-customer').then(response => {
                let msg = response.data.message;
                bus.$emit('banner-success', { message: msg });
                this.getAllCustomers();
                loading.hide();
            }).catch(err => {
                console.log('ERROR: ', err.data.message)
                bus.$emit('banner-error', { message: err.data.message });
                loading.hide();
            });
        }
    }
}
</script>
<style>
.deletetext {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.3px;
    color: #525F7F;
}
.to-right{
    float:right;
    margin-top:-35px;
}
</style>