import Vue from 'vue';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.prototype.$storagePath = process.env.VUE_APP_API_URL + '/';

/*
 * For file data that is saved on the server, retrieve 
 * them in the form of a blob and download.
 */
Vue.prototype.$downloadFile = function (filePath) {
    let loading = this.$loading.show();

    return axios.post(`/download-file-by-url`, { filePath: filePath }, { responseType: 'blob' }).then(rsp => {
        let splitPath = filePath.split('/'),
            fileName = splitPath[splitPath.length - 1];

        initiateDownload(rsp.data, fileName);
        loading.hide();

    }).catch((err) => console.log("err", err));
}

/*
 * For file data that is generated, retrieve the 
 * stream/download in the form of a blob and download.
 */
Vue.prototype.$downloadStream = function (apiPath, fileName) {
    let loading = this.$loading.show();

    return axios.get(apiPath, { responseType: 'blob' }).then(rsp => {
        initiateDownload(rsp.data, fileName);
        loading.hide();
    }).catch(err => console.error(err));
}

Vue.prototype.$openStreamBlank = function (apiPath, fileName) {
    let loading = this.$loading.show();

    return axios.get(apiPath, { responseType: 'blob' }).then(rsp => {
        initiateOpenBlank(rsp.data, fileName);
        loading.hide();
    }).catch(err => console.error(err));
}

/*
 * Initiate a download of blob from an <a> element on 
 * the dom that has the  downloadable blob as it's soruce. 
 */
function initiateDownload(blob, fileName) {
    const blobUrl = URL.createObjectURL(blob);
    const aElm = document.createElement('a');

    // Set file download name
    if (fileName)
        aElm.download = fileName;

    // Download the file and remove temp elm
    aElm.href = blobUrl;
    aElm.click();
    aElm.remove();
}

function initiateOpenBlank(blob, fileName) {
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, fileName);
}