<template>
<div>
	<div class="header bg-primary calendar-header">
		<div class="container-fluid">
			<div class="header-body">
				<div class="row align-items-center py-4">
					<div class="col-lg-6 col-12 col-md-6">
						<breadcrumbs :title="$t('label.calendar')">
							<!-- Breadcrumb slots -->
							<li class="breadcrumb-item">
								{{ $t('label.calendar') }}
							</li>
						</breadcrumbs>
					</div>
					<div class="col-6 mt-lg-0 text-lg-right">
						<div class="btn-group" role="group" aria-label="Basic example">
							<button type="button" id="daybutton" class="btn btn-secondary d-flex align-items-center py-1" :class="{active:calendarButton == 1}" data-calendar-view="basicDay" @click="calendarToggle(1)">
								<i class="ri-calendar-event-line heading-title"></i> {{ $t('label.day') }}
							</button>
							<button type="button" id="weekbutton" class="btn btn-secondary d-flex align-items-center py-1" :class="{active:calendarButton == 2}" data-calendar-view="basicWeek" @click="calendarToggle(2)">
								<i class="ri-calendar-2-line heading-title"></i> {{ $t('label.week') }}
							</button>

							<!-- Tyler Deschamps 4/30/2023 - Problems with pulling the correct 
							tasks from the database. Api needs revision. Commented out for now.  -->
							<!-- <button type="button" id="monthbutton" class="btn btn-secondary d-flex align-items-center py-1" :class="{active:calendarButton == 3}" data-calendar-view="month" @click="calendarToggle(3)">
								<i class="ri-calendar-line heading-title"></i> {{ $t('label.month') }}
							</button> -->

							<!-- Tyler Deschamps 4/30/2023 - Commented out for now. Needs 
								to be replaced with unscheduled tasks component.  -->
							<!-- <button type="button" id="daybutton" class="btn btn-secondary d-flex align-items-center py-1" :class="{active:calendarButton == 4}" data-calendar-view="basicDay" @click="calendarToggle(4)">
								<i class="ri-layout-row-line heading-title"></i> {{ $t('label.task list') }}
							</button> -->
						</div>
					</div>

                    <!-- Mobile -->
					<!-- <div class="calendar-view-option-mobile col-lg-6 col-12 col-md-6 mt-lg-0 text-lg-right">
						<div class="btn-group d-flex justify-content-between w-100" role="group" aria-label="Basic example">
							<button type="button" id="daybutton" class="btn btn-secondary d-flex align-items-center p-0 col-sm d-flex justify-content-center" :class="{active:calendarButton == 1}" data-calendar-view="basicDay" @click="calendarToggle(1)">
								<i class="ri-calendar-event-line heading-title"></i> {{ $t('label.day') }}
							</button>
							<button type="button" id="weekbutton" class="btn btn-secondary d-flex align-items-center p-0 col-sm d-flex justify-content-center" :class="{active:calendarButton == 2}" data-calendar-view="basicWeek" @click="calendarToggle(2)">
								<i class="ri-calendar-2-line heading-title"></i> {{ $t('label.week') }}
							</button>
							<button type="button" id="monthbutton" class="btn btn-secondary d-flex align-items-center p-0 col-sm d-flex justify-content-center" :class="{active:calendarButton == 3}" data-calendar-view="month" @click="calendarToggle(3)">
								<i class="ri-calendar-line heading-title"></i> {{ $t('label.month') }}
							</button>
							<button type="button" id="daybutton" class="btn btn-secondary d-flex align-items-center p-0 col-sm d-flex justify-content-center" :class="{active:calendarButton == 4}" data-calendar-view="basicDay" @click="calendarToggle(4)">
								<i class="ri-layout-row-line heading-title"></i> {{ $t('label.task list') }}
							</button>
						</div>
					</div> -->
				</div>
			</div>
		</div>
	</div>
	<div class="container-fluid mt--6">
		<div class="card-body p-0 position-relative" style="z-index: 0" id="calendarcomp">
			<div id="dayComponent" v-if="calendarButton == 1">
				<Day></Day>
			</div>
			<div id="weekComponent" v-if="calendarButton == 2">
				<Week></Week>
			</div>
			<!-- Tyler Deschamps 4/30/2023 - Problems with pulling the correct 
				tasks from the database. Api needs revision. Commented out for now.  -->
			<!-- <div id="monthComponent" v-if="calendarButton == 3">
				<month-component :firstname="firstname" :user="user"></month-component>
			</div> -->

			<!-- Tyler Deschamps 4/30/2023 - Commented out for now. Needs 
				to be replaced with unscheduled tasks component.  -->
			<!-- <div id="taskListComponent" v-if="calendarButton == 4">
				<TaskList></TaskList>
			</div> -->
		</div>
    </div>
</div>
</template>

<script>
	import Day from '../components/Schedule/views/Day.vue'
	import Week from '../components/Schedule/views/Week.vue'
	import TaskList from '../components/Schedule/views/TaskList.vue'
    export default {
		props: [],
		components: {
            Day,
			Week,
			TaskList
        },
		data() {
			return {
				user: JSON.parse(localStorage.getItem('user')),
				firstname:null,
				calendarButton: null,
				companySettings: JSON.parse(localStorage.getItem('company_settings')),
			}
		},
        mounted() {
			this.calendarButton = 1;
			this.firstname = this.user.first_name
		},
		methods: {
			calendarToggle(val) {
				this.calendarButton = val;
			}
		}
    }
</script>
