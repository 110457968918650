/* eslint-disable no-unused-vars */

import * as _ from 'lodash';
const namespaced = true;

const state = {
    userInfo: JSON.parse(localStorage.getItem('user')),
    allChannels: [],
    // allJobChatRoomUsers: [],
    jobChatRoom: [],
    activeChatRooms: [],
    myChatRooms: [],
    unreadChats: [],
    unreadChatCount: 0,
};

const getters = {
    allChannels: state => state.allChannels,
    // allJobChatRoomUsers: state => state.allJobChatRoomUsers,
    jobChatRoom: state => state.jobChatRoom,
    activeChatRooms: state => state.activeChatRooms,
    myChatRooms: state => state.myChatRooms,
    unreadChats: state => state.unreadChats,
    unreadChatCount: state => state.unreadChatCount,
};

const actions = {
    getAllChannels({ state, commit, rootState, dispatch }) {
        return new Promise((resolve, reject) => {
            axios
                .get('/get-all-chat-rooms/channel')
                .then(res => {
                    commit('setAllChannels', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    // getAllJobChatRoomUsers({ state, commit, rootState, dispatch }, id){
    //     return new Promise((resolve, reject) => {
    //         axios
    //             .get('/get-all-job-chat-room-users/' + id)
    //             .then(res => {
    //                 commit('setAllJobChatRoomUsers', res.data.result);
    //                 resolve(res);
    //             })
    //             .catch(err => reject(err));
    //     });
    // },
    getChatRoomByJobId({ state, commit, rootState, dispatch }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get('/get-job-chat-room/' + id)
                .then(res => {
                    commit('setJobChatRoom', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    sendMessage({ state, commit, rootState, dispatch }, params) {
        params.user_id = rootState.user.id;
        return new Promise((resolve, reject) => {
            axios
                .post('/send-chat-message', params)
                .then(res => {
                    // dispatch('getChatRoomByJobId', params.job_id);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    startListeningToChatRoomMessages({ state, commit, rootState, dispatch }, { id, echo }) {
        function addMessage(e) {
            console.log('sdsdsd', e);
            // if(rootState.user.currentOrgId != e.message.organization_id){
            // dispatch('getUnreadChats');
            // return;
            // }
            dispatch('getUnreadChats');

            let matchedActiveChatRoom = -1;
            if (state.activeChatRooms != null) {
                matchedActiveChatRoom = state.activeChatRooms.findIndex(item => item.id === e.chatMessage.chat_room_id);
            }

            if (matchedActiveChatRoom === -1) {
                if (state.activeChatRooms.length < 3) {
                    // find chat room
                    let matchedChatRoomIndex = state.myChatRooms.findIndex(item => item.id === e.chatMessage.chat_room_id);
                    // push to active chat
                    // if( matchedChatRoomIndex !== -1 && state.mainChatIndex == null ) {
                    if (matchedChatRoomIndex !== -1) {
                        commit('pushChatRoomToActiveChats', state.myChatRooms[matchedChatRoomIndex]);
                    }

                } else {
                    // dispatch('getUnreadChats');
                    // if( state.isMainWindowFocused == false ) {
                    //     // play notification sound when window is minimized
                    //     let notificationSound = new Audio(require(`../../assets/public_sound_notification.mp3`));
                    //     notificationSound.play();
                    // }
                }
            } else {
                // let activeChatRooms = state.activeChatRooms;

                // activeChatRooms[matchedActiveChatRoom].messages.push(e.chatMessage);

                // commit('setActiveChatRooms', activeChatRooms);

            }
            // if( state.mainChatIndex != e.chatMessage.chat_room_id ) {
            //     dispatch('getLastUnreadMessage',e.chatMessage.chat_room_id).then(res=>{
            //         commit('updateChatRoomLastUnreadMessage',res.data.data);
            //     });
            //     if( state.isMainWindowFocused == false ) {
            //         // play notification sound when window is minimized
            //         let notificationSound = new Audio(require(`../../assets/public_sound_notification.mp3`));
            //         notificationSound.play();
            //     }
            // }

            if (e.chatMessage.chat_room.type == 'job') {
                if (!(_.isEmpty(state.jobChatRoom))) {
                    let jobChatRoom = state.jobChatRoom;
                    jobChatRoom.messages.push(e.chatMessage);
                    commit('setJobChatRoom', jobChatRoom);
                }
            }

        }
        // state.echo = echo;
        echo.channel(`chat-room.${id}`)
            .listen('.NewChatMessage', addMessage)
    },
    startListeningToNewChatRoom({ state, commit, rootState, dispatch }, { echo }) {
        function addChatRoom(e) {
            console.log('...startListeningToNewChatRoom...')
            if (e) {
                dispatch('getMyChatRooms');
                dispatch('startListeningToChatRoomMessages', { id: e.data.chat_room.id, echo: state.echo });
            }
        }
        echo.channel(`user-chat-room.${rootState.Auth.AppActiveUser.id}`)
            .listen('.AddToRoom', addChatRoom)
    },
    startListeningToUserStatus({ state, commit, rootState, dispatch }, { echo }) {
        function statusChange(e) {
            dispatch('getMyChatRooms').then(res => {
                commit('updateActiveChatRoomsStatus');
            });
        }
        echo.channel(`status`)
            .listen('.StatusChange', statusChange)
    },
    getMyChatRooms({ state, commit, rootState }) {
        return new Promise((resolve, reject) => {
            axios
                .get('/get-my-chat-rooms')
                .then(res => {
                    // commit('setMyChatRooms', res.data.result);
                    let companyChannelChatRooms = res.data.companyChannelChatRooms;
                    let directMessageChatRooms = res.data.directMessageChatRooms;
                    let jobChatRooms = res.data.jobChatRooms;

                    let chatRooms = [...companyChannelChatRooms, ...directMessageChatRooms, ...jobChatRooms];

                    commit('setMyChatRooms', chatRooms);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getMessages({ state, commit, rootState, dispatch }, params) {
        return new Promise((resolve, reject) => {
            axios
                .post('/get-chat-room-messages', params)
                .then(res => {
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    createChatRoom({ state, commit, rootState, dispatch }, params) {
        let newParams = [];
        // newParams.push(params);
        newParams = params;
        // newParams.push(rootState.user);
        let sendRequest = {
            added_users: newParams,
            user: rootState.user
        }
        return new Promise((resolve, reject) => {
            axios
                .post('/create-chat-room', sendRequest)
                .then(res => {
                    dispatch('getMyChatRooms');
                    dispatch('startListeningToChatRoomMessages', { id: res.data.result.id, echo: state.echo });

                    // commit('removeChatRoomFromActiveChats', matchedChatRoom)
                    // commit('pushChatRoomToActiveChats', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    markMessageAsRead({ state, commit, rootState, dispatch }, chat_room_id) {
        return new Promise((resolve, reject) => {
            axios
                .get('/mark-message-as-read/' + chat_room_id)
                .then(res => {
                    dispatch('getUnreadChats');
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    getUnreadChats({ state, commit, rootState, dispatch }) {
        return new Promise((resolve, reject) => {
            axios
                .get('/get-unread-messages')
                .then(res => {
                    if (res?.data?.result.lengtn > 0) {
                        commit('setUnreadChats', res.data.result);

                        let unreadCount = 0;
                        res.data.result.forEach(item => {
                            if (item.is_read == 0) {
                                unreadCount = unreadCount + 1;
                            }
                        })

                        commit('setUnreadChatCount', unreadCount);
                    }

                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
}

const mutations = {
    setAllChannels(state, allChannels) {
        state.allChannels = allChannels;
    },
    // setAllJobChatRoomUsers(state, allJobChatRoomUsers) {
    //     state.allJobChatRoomUsers = allJobChatRoomUsers;
    // },
    setJobChatRoom(state, jobChatRoom) {
        state.jobChatRoom = jobChatRoom;
    },
    setActiveChatRooms(state, activeChatRooms) {
        state.activeChatRooms = activeChatRooms;
        //to remain chat popup window on the page
        localStorage.setItem('activeChatRooms', JSON.stringify(activeChatRooms));
    },
    setMyChatRooms(state, myChatRooms) {
        state.myChatRooms = myChatRooms;
    },
    pushChatRoom(state, chatRoom) {
        let matchedChatRoom = state.myChatRooms.findIndex(item => item.id === chatRoom.id);
        if (matchedChatRoom === -1) {
            let myChatRooms = state.myChatRooms;
            myChatRooms.push(chatRoom);
            state.myChatRooms = myChatRooms;
        }
    },
    updateActiveChatRoomsStatus(state) {
        let activeChatRooms = JSON.parse(localStorage.getItem('activeChatRooms'));
        let temp_activeChatRooms = [];

        if (activeChatRooms) {
            activeChatRooms.forEach((data, key) => {
                let matchedChatRoomIndex = state.myChatRooms.findIndex(item => item.id === data.id);
                if (matchedChatRoomIndex !== -1) {
                    let tempo = state.myChatRooms[matchedChatRoomIndex];
                    tempo.minimized = data.minimized;
                    temp_activeChatRooms.push(tempo);
                }
            })

            setTimeout(() => {
                state.activeChatRooms = temp_activeChatRooms;
                localStorage.setItem('activeChatRooms', JSON.stringify(temp_activeChatRooms));
            }, 1000)
        }
    },
    pushChatRoomToActiveChats(state, chatRoom) {
        console.log('chatRoom', chatRoom)
        let activeChatRooms = state.activeChatRooms;
        let matchedChatRoom = state.activeChatRooms.findIndex(item => item.id === chatRoom.id);

        if (matchedChatRoom === -1) {
            if (activeChatRooms.length < 3) {
                activeChatRooms.push(chatRoom);

            } else {
                activeChatRooms.splice(0, 1);
                activeChatRooms.push(chatRoom);

            }
            state.activeChatRooms = activeChatRooms;
            //to remain chat popup window on the page
            localStorage.setItem('activeChatRooms', JSON.stringify(activeChatRooms));

        } else {
            activeChatRooms.splice(matchedChatRoom, 1);
            state.activeChatRooms = activeChatRooms; // temporarily remove room
            setTimeout(() => {
                chatRoom.minimized = false;
                activeChatRooms.push(chatRoom);
                state.activeChatRooms = activeChatRooms; // add room back
                //to remain chat popup window on the page
                localStorage.setItem('activeChatRooms', JSON.stringify(activeChatRooms));
            }, 200)

        }
    },
    removeChatRoomFromActiveChats(state, index) {
        let activeChatRooms = state.activeChatRooms;
        activeChatRooms.splice(index, 1);
        state.activeChatRooms = activeChatRooms;
        //to remain chat popup window on the page
        localStorage.setItem('activeChatRooms', JSON.stringify(activeChatRooms));
    },
    setUnreadChats(state, unreadChats) {
        state.unreadChats = unreadChats;
    },
    setUnreadChatCount(state, unreadChatCount) {
        state.unreadChatCount = unreadChatCount;
    },
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
};