<template>
    <div>
        <!-- Display the records -->
        <div class="pt-2" v-if="timeOffs.length">
            <table class="table table-flush table-sm">
                <thead class="thead-light">
                    <tr>
                        <th class="col-sm-3">User/Date(s)</th>
                        <th class="col-sm-3">{{ $t('label.reason') }}</th>
                        <th class="col-sm-2">{{ $t('label.status') }}</th>
                        <th class="col-sm-3">{{ $t('label.notes') }}</th>
                        <th class="col-sm-1"></th>
                    </tr>
                </thead>

                <tbody>
                <template v-for="(item, idx) in timeOffs" >
                    <tr :key="item.id" :class="{ 'bg-gray-100': idx % 2 !== 0 }">
                        <!-- User and Dates -->
                        <td>
                            <!-- User name -->
                            <div class="font-weight-bold">{{ getEmployeeName(item.user_id) }}</div>
                            <!-- Date Range -->
                            <div v-if="item.start_date !== item.end_date">
                                <span v-if="moment(item.start_date).hour() > 0">
                                    {{ moment(item.start_date).format('MM/DD/YYYY hh:mm a') }}
                                </span>
                                <span v-else>{{ moment(item.start_date).format('MM/DD/YYYY') }} {{ $t('label.all day') }}</span>
                                -
                                <span v-if="moment(item.end_date).hour() > 0">
                                    {{ moment(item.end_date).format('MM/DD/YYYY hh:mm a') }}
                                </span>
                                <span v-else>{{ moment(item.end_date).format('MM/DD/YYYY') }} {{ $t('label.all day') }}</span>
                            </div>
                            <!-- Single Dates -->
                            <div v-else>
                                <!-- Time Range off -->
                                <span v-if="moment(item.start_date).hour() > 0 && moment(item.end_date).hour() > 0">
                                    {{ moment(item.start_date).format('MM/DD/YYYY hh:mm a') }}
                                    -
                                    {{ moment(item.end_date).format('hh:mm a') }}
                                </span>

                                <!-- All Day off -->
                                <span v-else>{{ moment(item.start_date).format('MM/DD/YYYY') }} {{ $t('label.all day') }}</span>
                            </div>
                        </td>

                        <!-- Reason -->
                        <td>{{ item.description }}</td>

                        <!-- Status -->
                        <td class="text-center">
                            <span class="badge p-2" :class="{ 'badge-warning': item.status === 'Pending', 'badge-danger': item.status === 'Denied', 'badge-success': item.status === 'Approved', 'badge-dark': item.status === 'Cancelled' }" style="font-size: 12px;">{{ item.status }}</span>
                        </td>

                        <!-- Manager Notes -->
                        <td>
                            <div v-if="item.manager_user_id">
                                <div>
                                    {{ item.manager_notes }}
                                </div>
                                <div class="text-muted">
                                    {{ getEmployeeName(item.manager_user_id) }}
                                    {{ moment(item.manager_action_date).format('MM/DD/YYYY hh:mm a') }}
                                </div>
                            </div>
                        </td>

                        <!-- Menu items -->
                        <td class="text-right">
                            <div class="dropdown" v-if="(item.status == 'Pending' || item.status == 'Approved') && reportName !== 'today'">
                                <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v"></i>
                                </a>

                                <!-- Pending requests menu items -->
                                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" v-if="item.status === 'Pending' && item.user_id !== user.id">
                                    <!-- Approve Request -->
                                    <a class="dropdown-item pointer" :data-id="item.id" @click="confirmRequestStatusChange(item, 1)">
                                        <i class="ri-thumb-up-line text-success"></i>&nbsp;{{ $t('label.approve time off request') }}
                                    </a>
                                    <!-- Deny Request -->
                                    <a class="dropdown-item pointer" @click="confirmRequestStatusChange(item, 0)">
                                        <i class="ri-thumb-down-line text-danger"></i>&nbsp;{{ $t('label.deny time off request') }}
                                    </a>
                                </div>

                                <!-- Approved requests can be cancelled by user that created them -->
                                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                    <!-- Cancel Request -->
                                    <a class="dropdown-item pointer" :disabled="item.status !== 'Approved' && item.added_user_id !== user.id" :data-id="item.id" @click="confirmRequestStatusChange(item, 2)">
                                        <i class="ri-error-warning-line text-warning"></i>&nbsp;{{ $t('label.cancel time off request') }}
                                    </a>
                                </div>
                            </div>

                        </td>
                    </tr>
				</template>
             </tbody>
         </table>
        </div>
        <!-- If no records, display message -->
        <div v-else class="text-center">No Results to Display</div>

        <!-- Approve Request modal -->
        <modal name="requestApproveModal" height="auto" :reset="true" :adaptive="true">
            <time-off-approve :item="selectedRequest" @returnChanges="returnStatusChange"></time-off-approve>
        </modal>

        <!-- Deny request  Modal -->
        <modal name="requestDenyModal" height="auto" :reset="true" :adaptive="true">
            <time-off-deny :item="selectedRequest" @returnChanges="returnStatusChange"></time-off-deny>
        </modal>

        <!-- Cancel Approved Request -->
        <modal name="requestCancelModal" height="auto" :reset="true" :adaptive="true">
            <time-off-cancel :item="selectedRequest" @returnChanges="returnStatusChange"></time-off-cancel>
        </modal>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('userData')
// import TimeOffRequestEmployee from '../components/TimeOffRequestEmployeeComponent';
import TimeOffApprove from '../components/TimeOffRequests/TimeOffApprove';
import TimeOffCancel from '../components/TimeOffRequests/TimeOffCancel';
import TimeOffDeny from '../components/TimeOffRequests/TimeOffDeny';

export default {
    props: [
        'timeOffs',
        'reportName'
    ],
    components: {
        // TimeOffRequestEmployee,
        TimeOffApprove,
        TimeOffCancel,
        TimeOffDeny
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            selectedRequest: null
        }
    },
    computed: {
        ...mapState({
            allUsers: state => state.allUsers,
        }),
    },
    methods: {
        confirmRequestStatusChange(item, action) {
            // Reset the error messages
            this.selectedRequest = item;

            //Show confirmation modals
            // Approve request modal
            if (action === 1) {
                this.$modal.show('requestApproveModal');
            }
            // Cancel approved request
            else if (action === 2) {
                this.$modal.show('requestCancelModal');
            }
            //Deny request modal
            else {
                this.$modal.show('requestDenyModal');
            }
        },
        returnStatusChange(response) {
            // update the request object
            let idx = this.timeOffs.findIndex(request => {
                if (request.id === response.id) {
                    return true;
                }
            });
            this.timeOffs[idx]['manager_user_id'] = response.manager_user_id;
            this.timeOffs[idx]['manager_notes'] = response.manager_notes;
            this.timeOffs[idx]['manager_action_date'] = response.manager_action_date;
            this.timeOffs[idx]['status'] = response.status;
        },
        getEmployeeName(userId) {
            let companyUsers = this.allUsers;
            let idx = companyUsers.findIndex(request => {
                if (request.id === userId) {
                    return true;
                }
            });
            return (idx >= 0) ? companyUsers[idx].name : 'N/A';
        },
    },
}
</script>
