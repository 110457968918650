<template>
    <div class="purchase-order">
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50" v-if="vpo">
            <!-- Header -->
            <div class="d-flex z-index-10">
                <!-- Breadcrumbs -->
                <div class="mr-auto z-index-1">
                    <breadcrumbs :title="'VPO #' + (vpo.vpo_number ? vpo.vpo_number.vpo_number : '')">
                        <!-- Breakcrumb slots -->
                        <li class="breadcrumb-item">
                            <a class="pointer" @click="$router.push({ name: 'variablePurchaseOrders' })">{{ $t('label.vpos') }}</a>
                        </li>
                        <li class="breadcrumb-item">
                            <span>{{ 'VPO #' + (vpo.vpo_number ? vpo.vpo_number.vpo_number : '') }}</span>
                        </li>
                    </breadcrumbs>
                </div>

                <!-- VPO Save/Cancel -->
                <div class="py-4 align-items-center" style="z-index: 1">
                    <div class="dropdown">
                        <button type="button" class="btn btn-neutral" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="text-default">{{ $t('label.menu') | uppercase }}</span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                            <template v-if="vpo.vpo_status">
                                <router-link v-if="vpo.vpo_status.name == 'Draft'" class="dropdown-item text-default pointer" :to="{ name: 'variablePurchaseOrderEdit', params: { id: this.id } }">
                                    <i class="ri-pencil-line"></i> {{ $t('label.edit') }}
                                </router-link>
                            </template>
                            <!-- View PDF -->
                            <a class="dropdown-item text-default pointer" @click="showModalVpoView()">
                                <i class="ri-eye-line"></i> {{ $t('label.view pdf') }}
                            </a>
                            <!-- Send VPO -->
                            <a v-if="vpo.vpo_status.name == 'Draft'" class="dropdown-item text-default pointer" @click="showModalVpoSend()">
                                <i class="ri-mail-send-line"></i> {{ $t('label.send') }}
                            </a>
                            <!-- Download -->
                            <a @click="$downloadStream(`/get-vpo-pdf-download/${vpo.id}`, pdfDownloadName)" class="dropdown-item text-default pointer">
                                <i class="ri-download-line"></i> {{ $t('label.download') }}
                            </a>
                            <!-- Print -->
                            <a @click="$openStreamBlank(`/get-vpo-pdf-file/${vpo.id}`), pdfDownloadName" class="dropdown-item text-default pointer">
                                <i class="ri-printer-line"></i> {{ $t('label.print') }}
                            </a>
                            <!-- <a :href="'/get-vpo-pdf-file/' + vpo.id" target="_blank" class="dropdown-item text-default pointer">
                                <i class="ri-printer-line"></i> {{ $t('label.print') }}
                            </a> -->
                            <!-- Mark Requested VPO as Approved or Denied -->
                            <template v-if="vpo.vpo_status.name == 'Requested'">
                                <div class="dropdown-divider mx-3"></div>
                                <a class="dropdown-item text-default pointer" @click="showMarkVpoApprModal()">
                                    <span class="d-flex align-items-center">
                                        <i class="ri-checkbox-circle-line"></i> {{ $t('label.mark as approved') }}
                                    </span>
                                </a>
                                <a class="dropdown-item text-danger pointer" @click="showMarkVpoDeniedModal()">
                                    <span class="d-flex align-items-center">
                                        <i class="ri-close-circle-fill"></i> {{ $t('label.mark as denied') }}
                                    </span>
                                </a>
                            </template>
                            <!-- Create Invoice from VPO -->
                            <template v-else-if="vpo.vpo_status.name == 'Approved'">
                                <div class="dropdown-divider mx-3"></div>
                                <a class="dropdown-item text-success pointer" @click="showVpoInvoiceAddModal()">
                                    <span class="d-flex align-items-center">
                                        <i class="ri-checkbox-circle-fill"></i> {{ $t('label.create invoice') }}
                                    </span>
                                </a>
                            </template>
                            <!-- View Invoice PDF -->
                            <template v-else-if="vpo.vpo_status.name == 'Invoiced'">
                                <div class="dropdown-divider mx-3"></div>
                                <a class="dropdown-item text-default pointer" @click="showVpoInvoicePDFModal()">
                                    <span class="d-flex align-items-center">
                                        <i class="ri-bill-line"></i> {{ $t('label.view invoice') }}
                                    </span>
                                </a>
                            </template>
                            <!-- Cancel/Delete VPO -->
                            <template v-if="isVpoRemovable()">
                                <div class="dropdown-divider mx-3"></div>
                                <a class="dropdown-item text-danger pointer" @click="toggleDeleteModal(true)">
                                    <span class="d-flex align-items-center">
                                        <i class="ri-close-circle-line"></i> {{ vpo.vpo_status.name == 'Draft' ? $t('label.delete vpo') : $t('label.cancel vpo') }}
                                    </span>
                                </a>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <!-- VPO View Info -->
            <VpoViewInfo :vpo="vpo"></VpoViewInfo>

            <!-- VPO View Items -->
            <VpoViewItems :vpoItems="vpo.vpo_items" :tax="parseFloat(vpo.tax).toFixed(2)" :vpoTotal="parseFloat(vpo.overall_total).toFixed(2)"></VpoViewItems>

            <!-- VPO View Notes -->
            <VpoViewNotes :vpo-id="id"></VpoViewNotes>

            <!-- VPO View Files -->
            <VpoViewFiles :vpo-id="id"></VpoViewFiles>

            <!-- VPO View Attachments -->
            <VpoViewAttachments v-if="vpo.vpo_status.name != 'Draft'" :vpo="vpo"></VpoViewAttachments>

            <!-- VPO View Activity Logs -->
            <ModalVpoActivityLogs :vpo-id="id" :vpoStatus="vpo.vpo_status"></ModalVpoActivityLogs>

        </div>

        <!-- View Modal -->
        <modal class="py-4" name="modalVpoView" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
            <ModalVpoView :vpo="vpo" @reload="loadLkupData" @cancelFnc="$modal.hide('modalVpoView')"></ModalVpoView>
        </modal>

        <!-- Send Modal -->
        <modal class="py-4" name="modalVpoSend" height="auto" width="100%" :max-width="900" :scrollable="true" :reset="true" :adaptive="true">
            <ModalVpoSend :vpo="vpo" :send-type="sendType" @cancelFnc="$modal.hide('modalVpoSend')" @returnAfterSend="getVpo()"></ModalVpoSend>
        </modal>

        <!-- Mark VPO as Approved -->
        <ModalConfirmation v-if="viewMarkVpoApprModal" newId="MarkVpoApproved" :title="$t('label.ARE YOU SURE YOU WANT TO MARK THIS VPO AS APPROVED')" @onClose="hideMarkVpoApprModal()" @customConfirmMethod="updateStatus('Approved')">
        </ModalConfirmation>

        <!-- Create Invoice From VPO -->
        <modal name="VpoInvoiceAddModal" class="pb-6" width="100%" height="auto" :reset="true" :adaptive="true" :max-width="1200" :scrollable="true">
            <ModalInvoice v-if="viewVpoInvoiceAddModal" source2="vpo" source="addInvoice" :job="vpo.job" :vpoId="vpo.id" :customerid="vpo.customer_id" @reloadInvoices="getVpo" @cancelFnc="hideVpoInvoiceAddModal" @customChange="vpoInvcMdlCustChng">
            </ModalInvoice>
        </modal>

        <!-- VPO Edit Invoice Modal -->
        <modal name="VpoInvoiceEditModal" class="pb-6" width="100%" height="auto" :reset="true" :adaptive="true" :max-width="1200" :scrollable="true">
            <ModalInvoice v-if="viewVpoInvoiceEditModal" source2="vpo" source="editInvoice" :job="vpo.job" :customerid="vpo.customer_id" :invoice-data="{ data: vpoInvoice }" @cancelFnc="hideVpoInvoiceEditModal" @customChange="vpoInvcMdlCustChng" @reloadInvoices="getVpo">
            </ModalInvoice>
        </modal>

        <!-- VPO Invoice PDF Modal -->
        <modal class="pb-6" name="vpoInvoiceViewModal" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
            <ModalInvoiceView v-if="viewVpoInvoicePDFModal" :job="vpo.job" :invoice-data="{ data: vpoInvoice }" @cancelFnc="hideVpoInvoicePDFModal" @reloadInvoices="getVpo"></ModalInvoiceView>
        </modal>

        <!-- VPO Invoice Send Modal -->
        <modal class="pb-6" name="vpoInvoiceSendModal" height="auto" width="100%" :max-width="900" :scrollable="true" :reset="true" :adaptive="true">
            <ModalInvoiceSend v-if="viewVpoInvoiceSendModal" :job="vpo.job" :invoice-data="{ data: vpoInvoice }" @cancelFnc="hideVpoInvoiceSendModal" @reloadInvoices="getVpo"></ModalInvoiceSend>
        </modal>

        <!-- VPO Invoice Payment Modal -->
        <modal class="pb-6" name="vpoInvoicePaymentModal" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
            <ModalPayment v-if="viewVpoInvoicePaymentModal" source2="job" source="invoice" :job="vpo.job" :curr-id="vpo.job.id" :invoice-data="{ data: vpoInvoice }" @cancelFnc="hideVpoInvoicePaymentModal" @reloadPayments="getVpo"></ModalPayment>
        </modal>

        <!-- Mark VPO as Denied -->
        <ModalConfirmMarkDenied v-if="viewMarkVpoDeniedModal" newId="MarkVpoDenied" :btnText="$t('label.yes')" :title="$t('label.ARE YOU SURE YOU WANT TO MARK THIS VPO AS DENIED')" @onClose="hideMarkVpoDeniedModal()" @customDeleteMethod="updateStatus('Denied')">
        </ModalConfirmMarkDenied>

        <!-- Cancel Modal -->
        <modal-delete-component v-if="showDltVPOModal" :newId="'Vpo_view'" :title="'ARE YOU SURE YOU WANT TO CANCEL THIS VPO'" :subtitle="'This cannot be undone. You will be able to send a message to the Customer on the next step.'" :btnText="'Yes, Cancel VPO'" @onClose="toggleDeleteModal()" @customDeleteMethod="vpo.vpo_status.name == 'Draft' ? deleteVpo() : showSendCancelModal()">
        </modal-delete-component>
    </div>
</template>

<script>
import ModalConfirmation from '@/components/ModalConfirmation.vue';
import ModalDeleteComponent from '@/components/ModalDelete2.vue';
import ModalInvoice from '@/components/ModalInvoice.vue';
import ModalInvoiceSend from '@/components/ModalInvoiceSend.vue';
import ModalInvoiceView from '@/components/ModalInvoiceView.vue';
import ModalPayment from '@/components/ModalPayment.vue';
import ModalVpoSend from '@/components/VariablePurchaseOrders/SendVariablePurchaseOrder/VpoSendModal.vue';
import ModalVpoActivityLogs from '@/components/VariablePurchaseOrders/ViewVariablePurchaseOrder/VpoViewActivityLogs.vue';
import VpoViewAddress from '@/components/VariablePurchaseOrders/ViewVariablePurchaseOrder/VpoViewAddress.vue';
import VpoViewAttachments from '@/components/VariablePurchaseOrders/ViewVariablePurchaseOrder/VpoViewAttachments.vue';
import VpoViewFiles from '@/components/VariablePurchaseOrders/ViewVariablePurchaseOrder/VpoViewFiles.vue';
import VpoViewInfo from '@/components/VariablePurchaseOrders/ViewVariablePurchaseOrder/VpoViewInfo.vue';
import VpoViewItems from '@/components/VariablePurchaseOrders/ViewVariablePurchaseOrder/VpoViewItems.vue';
import VpoViewNotes from '@/components/VariablePurchaseOrders/ViewVariablePurchaseOrder/VpoViewNotes.vue';
import ModalVpoView from '@/components/VariablePurchaseOrders/modals/VpoPdfView.vue';
import bus from '@/utils/event-bus';

export default {
    props: ['id', 'title'],
    components: {
        VpoViewInfo,
        ModalPayment,
        ModalInvoice,
        ModalVpoView,
        VpoViewItems,
        VpoViewNotes,
        VpoViewFiles,
        ModalVpoSend,
        VpoViewAddress,
        ModalInvoiceView,
        ModalInvoiceSend,
        ModalConfirmation,
        VpoViewAttachments,
        ModalDeleteComponent,
        ModalVpoActivityLogs,
        ModalConfirmMarkDenied: ModalDeleteComponent,
    },
    data() {
        return {
            vpo: null,
            vpoInvoice: null,
            sendType: '',
            subjectText: '',
            showDltVPOModal: false,
            viewMarkVpoApprModal: false,
            viewMarkVpoPaidModal: false,
            viewMarkVpoDeniedModal: false,
            viewVpoInvoiceAddModal: false,
            viewVpoInvoicePDFModal: false,
            viewVpoInvoiceEditModal: false,
            viewVpoInvoiceSendModal: false,
            viewVpoInvoicePaymentModal: false,
        }
    },
    computed: {
        pdfDownloadName() {
            if(this.vpo) {
                let name = this.vpo.vpo_status.name == 'Cancelled' ? 'CANCELLED_' : '';
                return name + "VPO-" + this.vpo.vpo_number.vpo_number + "_" +  moment().format('YYYY_MM_DD') + ".pdf";
            } else
                return "";
        }
    },
    mounted() {
        this.loadLkupData();
    },
    methods: {
        loadLkupData() {
            let promises = [];
            this.loader = this.$loading.show();
            promises.push(this.getVpo());

            // Hide the loader overlay after all lookup data is loaded
            Promise.all(promises).then(() => {
                setTimeout(() => {
                    this.loader.hide();
                }, 250);
            });
        },
        getVpo() {
            axios.get(`/vpo_get_by_id/` + this.id).then(resp => {
                this.vpo = resp.data.result;
            }).catch(err => console.log(err));
        },
        async getVpoInvoice() {
            let id = this.vpo.invoice_id;
            return axios.get(`/get-invoice-by_id/${id}`).then(rsp => {
                this.vpoInvoice = rsp.data.result;
            }).catch(err => console.error(err));
        },

        showMarkVpoApprModal() {
            this.viewMarkVpoApprModal = true;
        },
        hideMarkVpoApprModal() {
            this.viewMarkVpoApprModal = false;
        },
        showMarkVpoDeniedModal() {
            this.viewMarkVpoDeniedModal = true;
        },
        hideMarkVpoDeniedModal() {
            this.viewMarkVpoDeniedModal = false;
        },
        showModalVpoView() {
            this.$modal.show('modalVpoView');
        },
        hideModalVpoView() {
            this.$modal.hide('modalVpoView');
        },
        showModalVpoSend() {
            this.sendType = "request";
            this.$modal.show('modalVpoSend');
        },
        hideModalVpoSend() {
            this.$modal.hide('modalVpoSend');
        },

        /* Vpos Invoice Create View */
        showVpoInvoiceAddModal() {
            // Render modal after necessary data has loaded
            this.viewVpoInvoiceAddModal = true;
            this.$modal.show("VpoInvoiceAddModal");
        },
        hideVpoInvoiceAddModal() {
            this.viewVpoInvoiceAddModal = false;
            this.$modal.hide("VpoInvoiceAddModal");
        },

        /* Vpos Invoice Edit View */
        showVpoInvoiceEditModal() {
            this.viewVpoInvoiceEditModal = true;
            this.$modal.show("VpoInvoiceEditModal");
        },
        hideVpoInvoiceEditModal() {
            this.viewVpoInvoiceEditModal = false;
            this.$modal.hide("VpoInvoiceEditModal");
        },

        /* Vpos Invoice PDF View */
        showVpoInvoicePDFModal(vpo) {
            this.getVpoInvoice().then(() => {
                this.viewVpoInvoicePDFModal = true;
                this.$modal.show('vpoInvoiceViewModal');
            });
        },
        hideVpoInvoicePDFModal(value) {
            this.viewVpoInvoicePDFModal = false;
            this.$modal.hide('vpoInvoiceViewModal');

            // If PDF Modal is calling a different modal
            if (value == 'editSelected')
                this.showVpoInvoiceEditModal();
            else if (value == 'invoiceSend')
                this.showVpoInvoiceSendModal();
            else if (value == 'invoicePayment')
                this.showVpoInvoicePaymentModal();
        },

        // /* Vpos Invoice Send View */
        showVpoInvoiceSendModal() {
            this.viewVpoInvoiceSendModal = true;
            this.$modal.show('vpoInvoiceSendModal');
        },
        hideVpoInvoiceSendModal() {
            this.viewVpoInvoiceSendModal = false;
            this.$modal.hide('vpoInvoiceSendModal');
        },
        vpoInvcMdlCustChng(value) {
            // Prepare to send the invoice
            if (value == 1) {
                // Update invoice data
                this.getVpoInvoice().then(() => {
                    this.showVpoInvoiceSendModal();
                });
            }
        },

        /* Vpo Invoice Process Payment Modal */
        showVpoInvoicePaymentModal() {
            this.viewVpoInvoicePaymentModal = true;
            this.$modal.show('vpoInvoicePaymentModal');
        },
        hideVpoInvoicePaymentModal() {
            this.viewVpoInvoicePaymentModal = false;
            this.$modal.hide('vpoInvoicePaymentModal');
        },

        toggleDeleteModal(status) {
            this.showDltVPOModal = status;
        },
        showSendCancelModal() {
            this.sendType = "cancel";
            this.$modal.show('modalVpoSend');
        },

        isVpoRemovable() {
            let stNm = this.vpo.vpo_status.name;
            if (['Draft', 'Requested', 'On Hold'].findIndex((nm) => nm == stNm) >= 0)
                return true;
        },

        updateStatus(statusName) {
            let rqstData = {
                id: this.vpo.id,
                status_name: statusName
            };

            axios.post("/vpo-update-status", rqstData).then(() => {
                this.getVpo();
                bus.$emit('banner-success', {
                    message: this.$t('label.VPO status updated successfully') + '!'
                });
            }).catch(err => console.log(err));
        },
        // For drafts
        deleteVpo() {
            let rqstData = {
                id: this.vpo.id,
                status_name: this.vpo.vpo_status.name
            };

            axios.post("/vpo-delete", rqstData).then(() => {
                this.getVpo();
                bus.$emit('banner-success', {
                    message: this.$t('label.VPO Draft deleted successfully') + '!'
                });
            }).catch(err => console.log(err));
        },
    },
}
</script>
