<template>
    <modal :name="modalName" @closed="$emit('onClose')" :classes="'rounded'" :min-height="400" width="525px" :scrollable="true" height="auto" :reset="true" :adaptive="true">
        <div class="modal-content bg-gradient-danger">
            <div class="modal-header">
                <button type="button" class="close" @click="$modal.hide(modalName)">
                    <span class="text-lighter" aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="py-3 text-center">
                    <i class="ri-stop-circle-line ni-3x text-white"></i>
                    <h4 class="heading mt-4 text-uppercase text-white">{{ $t('label.ARE YOU SURE YOU WANT TO CLOCK-OUT') }} {{ userTime.user ? userTime.user.name : '' }}?</h4>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-white" @click="clockOutFunc()">{{ $t('label.Yes, Clock Out') }}</button>
                <button type="button" class="btn btn-link text-white ml-auto" @click="$modal.hide(modalName)">{{ $t('label.cancel') }}</button>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    props: ['modalName', 'userTime'],
    emit: ['onClose', 'clockOutFunc'],
    data() {
        return {}
    },
    mounted() {
        this.$modal.show(this.modalName);
    },
    methods: {
        clockOutFunc() {
            this.$emit('clockOutFunc', this.userTime.timestatus.id);
            this.$emit('onClose');
        }
    },
}
</script>
