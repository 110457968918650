<template>
<div>
    <modal name="userClockInModal" @closed="$emit('closeFnc')" :classes="'rounded z-index-5; position: absolute'" :shiftY="0.5" :min-height="400" width="625" :scrollable="true" height="auto" :reset="true" :adaptive="true">
        <div class="modal-content">
            <!-- Header -->
            <div class="modal-header border-bottom d-flex align-items-center">
                <h5 class="modal-title">
                    <i class="far fa-clock"></i>&nbsp;{{ $t('label.clock in details') }}
                </h5>
                <button type="button" class="close" @click="$emit('closeFnc')">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <div class="modal-body">
                <!-- Select task -->
                <div class="form-group">
                    <label class="form-control-label text-muted" for="clockInTask">{{ $t('label.task') }}</label>
                    <span class="text-danger text-xs">*&nbsp;{{ $t('label.required') }}</span>
                    
                    <select class="form-control" v-model="$v.form.taskId.$model" :class="{ 'is-invalid': $v.form.taskId.$error }" name="clockInTask">
                        <option :value="null"></option>
                        <template v-if="tasks.length > 0">
                            <option :value="task.id" v-for="(task, idx) in tasks" :key="idx">
                                {{ task.name }} - {{ task.job.job_name }}
                            </option>
                        </template>
                        <template v-else>
                            <option :value="null" disabled>
                                {{ $t('label.there are not any tasks currently assigned to you please contact your supervisor') }}
                            </option>
                        </template>
                    </select>
                    <div v-if="$v.form.taskId.$error && !$v.form.taskId.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
                </div>
                <!-- Clock in description -->
                <div class="form-group">
                    <label class="form-control-label text-muted" for="briefDescrip">{{ $t('label.description') }}</label>
                    <textarea class="form-control" v-model="form.description" name="briefDescrip" rows="2" :placeholder="$t('label.Provide a brief description')"></textarea>
                </div>
            </div>
            <!-- Footer Buttons -->
            <div class="card-footer border-top d-flex">
                <button type="button" class="btn btn-primary mr-auto" :disabled="clockingIn" @click="clockIn()">{{ $t('label.clock in') }}</button>
                <button type="button" class="btn btn-link ml-auto" @click="$emit('closeFnc')">{{ $t('label.close') }}</button>
            </div>
        </div>
    </modal>
</div>
</template>

<script>
import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
    props: [],
    emits: ['returnFnc', 'closeFnc'],
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            clockingIn: false,
            tasks: [],
            form: {
                taskId: null,
                description: ''
            }
        }
    },
    validations: {
        form: {
            taskId: { required }
        }
    },
    computed: {
        ...mapGetters({
            coordinates: 'coordinates',
        }),
    },
    mounted() {
        this.$store.dispatch('setGeolocation');
        this.$modal.show('userClockInModal');
        this.getUserTasks();
    },
    methods: {
        getUserTasks() {
            let data = {
                user_id: this.user.id
            };

            axios.post(`/get-all-tasks-by-employee`, data).then(response => {
                this.tasks = response.data.result;
            }).catch(err => console.log(err));
        },

        clockIn() {
            this.clockingIn = true;
            this.$v.$touch();

            // Validate
            if (!this.$v.$invalid) {
                // Save time punch
                let data = {
                    'user_id': this.user.id,
                    'task_id': this.form.taskId,
                    'login_location': this.coordinates,
                    'description': this.form.description
                };

                console.log("data", data);

                axios.post(`/time-in`, data).then(response => {
                    this.$store.dispatch('time/getUserCurrentClockin');

                    bus.$emit('checkClockinStatus');
                    bus.$emit('banner-success', {
                        message: $t('label.you were successfully clocked in') + '.'
                    });

                    this.$emit('closeFnc');
                    this.clockingIn = false;
                }).catch(err => console.log(err));
            }
        }
    }
}
</script>