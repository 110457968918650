<template>
    <div class="chat-job">
        <div class="card">
            <div class="card-header">
                <div class="row align-items-center">
                    <div class="col-8">
                        <h3 class="mb-0 d-flex align-items-center"><i class="ri-discuss-line heading-title"></i> {{ $t('label.job chat') }}</h3>
                    </div>
                    <div class="col-4 text-right">
                        <!-- <button type="button" class="btn btn-sm btn-default m-0" data-toggle="modal" data-target="#chatViewDetailsModal"> -->
                        <button type="button" class="btn btn-sm btn-default m-0" @click="toggleModal">
                            View Details
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="row m-0">
                    <div class="col-12" ref="chatmessages" id="messages" :style="offsetHeight">
                        <!-- <b-overlay :show="show" opacity="0.8" variant="white" rounded="sm"> -->
                        <div v-for="(data, index) in chatMessages" :key="index">
                            <div v-if="data.user_id == user.id" class="message-section-owner all-messages">
                                <div>
                                    <div class="text-right flex-center">
                                        <span class="time">{{ data.created_at | formatChatDateAndTime }}</span>
                                        <i v-if="data.id" class="ri-check-double-line"></i>
                                        <div v-else class="loading-div loadingAnimation ml-1"></div>
                                    </div>
                                    <div class="message">
                                        <img v-if="data.image_url" class="chat-image" :src="data.image_url" />
                                        <div v-html="data.message"></div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="message-section all-messages">
                                <img class="avatar-img rounded-circle" v-if="data?.user?.picture" :src="data.user.picture" />
                                <div>
                                    <div>
                                        <span class="name">{{ data.user.name }} | </span>
                                        <span class="time">{{ data.created_at | formatChatDateAndTime }}</span>
                                    </div>
                                    <div class="message">
                                        <img v-if="data.image_url" class="chat-image" :src="data.image_url" />
                                        <div v-html="data.message"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- </b-overlay> -->
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <form method="post" class="w-100" autocomplete="off" enctype="multipart/form-data" @submit="sendMessage">
                        <div class="col-12 d-flex align-items-end">
                            <input type="file" ref="file" name="photo" class="form-control" placeholder="Photo" @change="updateImage" autofocus style="display: none">
                            <i class="ri-image-2-line heading-title pr-2 cursor-pointer2" @click="$refs.file.click()"></i>
                            <div class="chat-box">
                                <div class="text-left" v-if="chat.image">
                                    <img class="" :src="chat.image" />
                                    <i class="ri-close-circle-line heading-title remove-image" @click="removeImage"></i>
                                </div>
                                <at-ta :members="allMembers" name-key="name">
                                    <template slot="item" slot-scope="s">
                                        <span v-html="s.item.name" @click="selectedName(s.item)"></span>
                                    </template>
                                    <!-- oninput='()=>{ this.style.height = ""; this.style.height = this.scrollHeight + "px"' 
                                    onfocus='this.style.height = ""; this.style.height = this.scrollHeight + "px"; }'  -->
                                    <textarea v-on:keyup.enter="sendMessage" v-model="chat.message" @focus="onFocusTextarea" name="notes" rows="1" class="form-control" placeholder="Type something here..." required autofocus></textarea>
                                </at-ta>
                                <span class="chat-emoji-selector">
                                    <emoji-picker @emoji="append" :search="search">
                                        <div class="emoji-invoker" slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent">
                                            <b-icon icon="emoji-smile" aria-hidden="true" class="f-primary"></b-icon>
                                        </div>
                                        <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                                            <div class="emoji-picker">
                                                <div class="emoji-picker__search">
                                                    <input type="text" v-model="search" v-focus>
                                                </div>
                                                <div>
                                                    <div v-for="(emojiGroup, category) in emojis" :key="category">
                                                        <h5>{{ category }}</h5>
                                                        <div class="emojis">
                                                            <span v-for="(emoji, emojiName) in emojiGroup" :key="emojiName" @click="insert(emoji)" :title="emojiName">
                                                                {{ emoji }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </emoji-picker>
                                </span>
                            </div>
                            <i class="ri-send-plane-2-line heading-title pl-2 cursor-pointer2" @click="sendMessage"></i>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Chat View Details Modal -->
        <modal name="chatViewDetailsModal1" height="auto" width="100%" :max-width="550" :scrollable="true" :reset="true" :adaptive="true">
            <ChatViewDetailsModal :chat-room-data="jobChatRoom" @cancelFnc="$modal.hide('chatViewDetailsModal1')"></ChatViewDetailsModal>
        </modal>
    </div>
</template>
<script>
import At from 'vue-at';
import AtTa from 'vue-at/dist/vue-at-textarea';
import EmojiPicker from 'vue-emoji-picker';
import { mapGetters } from 'vuex';
import ChatViewDetailsModal from '../chat/ChatViewDetailsModal';

export default {
    props: ['job'],
    components: {
        EmojiPicker,
        ChatViewDetailsModal,
        At,
        AtTa,
    },
    directives: {
        focus: {
            inserted(el) {
                el.focus()
            },
        },
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            chat: {
                message: '',
                image: null,
                imageFile: '',
            },
            hasUnreadMessage: false,
            search: '',
            options: {
                chat_room_id: null,
                offset: 0
            },
            retrievingMessages: true,
            chatMessages: [],
            show: false,
            showModal: false,
            offsetHeight: 0,
            selectedNames: [],
        }
    },
    computed: {
        ...mapGetters({
            jobChatRoom: 'chat/jobChatRoom',
            // allUsers: 'userData/allUsers'
        }),
        allMembers() {
            let members = [];
            if (this.jobChatRoom && this.jobChatRoom.users) {
                this.jobChatRoom.users.forEach((data) => {
                    members.push(data.user);
                });
            }
            return members;
        }
    },
    watch: {
        'jobChatRoom.messages': function (newVal, oldVal) {
            // this.scrollToEnd(800)
            this.show = true;
            this.options.chat_room_id = this.jobChatRoom.id;
            this.getMessages(true);
        }
    },
    mounted() {
        this.loadLkupData();

        let offsetHeight = document.getElementById('job-information-card').offsetHeight
        this.offsetHeight = { 'height': `calc(${offsetHeight}px - 120px)` };

        // Start listening for chat messages
        this.$echo.channel(`chat-room.${this.jobChatRoom.id}`)
            .listen('.NewChatMessage', data => {
                this.listenForChatMessages(data);
            });
    },
    methods: {
        loadLkupData() {
            this.$store.dispatch('chat/getChatRoomByJobId', this.job.id);
        },
        listenForChatMessages(item) {
            this.chatMessages.push(item)
        },
        getMessages(refresh = false) {
            this.show = true;
            this.retrievingMessages = true;
            if (refresh) {
                this.options.offset = 0;
            }
            this.$store.dispatch('chat/getMessages', this.options).then(response => {
                this.retrievingMessages = false;
                this.chatMessages = response.data.result;
                this.show = false;

                this.scrollToEnd();

            })
        },
        handleTextarea(e) {
            if (e.keyCode == 13 && e.shiftKey == false) {
                let message = this.chat.message;
                this.chat.message = message.replace(/\n+$/, "");
                this.sendMessage();
            }
        },
        sendMessage(e = false) {
            if (e) {
                e.preventDefault();
            }
            var formData = new FormData();
            formData.append('chat_room_id', this.jobChatRoom.id);
            formData.append('job_id', this.job.id);
            formData.append('message', this.chat.message);
            formData.append('image', this.chat.imageFile);
            formData.append('source', 'chat-job');

            if (this.chat.message != '' || this.chat.image != null) {
                const pushedIndex = this.chatMessages.push({
                    user_id: this.user.id,
                    message: this.chat.message,
                    id: null,
                    created_at: new Date(),
                }) - 1;
                this.clearForm();
                this.scrollToEnd(100);
                this.$store.dispatch('chat/sendMessage', formData).then(response => {
                    this.$set(this.chatMessages, pushedIndex, response.data.result)
                    // this.$store.dispatch('chat/getChatRoomByJobId', this.job.id);
                })
            }
        },
        updateImage(event) {
            const file = event.target.files[0];
            this.chat.image = URL.createObjectURL(file);
            this.chat.imageFile = file;
        },
        removeImage() {
            this.chat.image = null;
        },
        clearForm() {
            this.chat.message = null;
            this.chat.image = null;
            this.chat.imageFile = '';
        },
        scrollToEnd(timeout = 200) {
            let content = this.$refs.chatmessages;
            setTimeout(() => {
                if (content !== undefined) {
                    content.scrollTop = content.scrollHeight;
                }
            }, timeout)
        },
        onFocusTextarea() {
            // $('.height-auto-not').addClass('height-auto').removeClass('height-auto-not')
        },
        onBlurTextarea() {
            // $('.height-auto').addClass('height-auto-not').removeClass('height-auto')
        },
        append(emoji) {
            this.chat.message += emoji
        },
        toggleModal() {
            // this.showModal = true;

            // this.$nextTick(() => {
            //     bus.$emit('chatViewDetails', this.jobChatRoom)
            // })
            this.$modal.show('chatViewDetailsModal1')
        },
        selectedName(data) {
            if (this.findArray(data.id) === null)
                this.selectedNames.push(data);
        },
        findArray(value) {
            let countLayer = this.selectedNames.length;
            if (countLayer > 0) {
                for (var x = 0; x < countLayer; x++) {
                    if (this.selectedNames[x].id === value) {
                        return this.selectedNames[x];
                    }
                }
            }
            return null;
        },

    },
}
</script>
<style lang="scss"></style>

<style scoped>
.flex-center {
    display: flex;
    align-items: center;
}

.loading-div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px dashed #000;
}

.loadingAnimation {
    animation-name: rotate;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
</style>