<template>
    <div class="modal-content">
        <!-- Header -->
        <div class="modal-header border-bottom">
            <h5 class="modal-title">{{ $t('label.revise time') }}</h5>
            <button type="button" class="close " @click="$emit('closeFnc')">
                <i class="ri-close-line"></i>
            </button>
        </div>

        <!-- Body -->
        <div class="modal-body p-0">
            <!-- Employee Name Header -->
            <div class="row d-flex align-items-center m-0 p-2">
                <span class="cursor-pointer" @click="getUserInfo(timePunch.user.id)">
                    <img class="rounded-circle" :src="timePunch.user.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                </span>
                <span class="pl-2 cursor-pointer" @click="getUserInfo(timePunch.user.id)">
                    <h3 class="mb-0">{{ timePunch.user.name }}</h3>
                </span>
            </div>
            <!-- Time Punch -->
            <table class="table table-sm table-flush text-center">
                <thead class="thead-light">
                    <tr>
                        <th scope="col" class="text-left pl-5">{{ $t('label.date') }}</th>
                        <th scope="col">{{ $t('label.time start') }} (HH:MM)</th>
                        <th scope="col"><span class="mr-5">{{ $t('label.time stop') }} (HH:MM)</span></th>
                        <th scope="col">{{ $t('label.time clocked') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <!-- Date -->
                            <date-picker v-model="timePunch.date" value-type="format" format="MM/DD/YYYY" class="full-width" input-class="h-35 form-control off-canvas-date off-canvas-include" :clearable="false" :editable="false">
                            </date-picker>
                        </td>
                        <td>
                            <!-- Start Time -->
                            <date-picker v-model="timePunch.timein" format="hh:mm a" :minute-step="5" value-type="format" type="time" placeholder="hh:mm a"></date-picker>
                        </td>
                        <td>
                            <!-- End Time -->
                            <div class="d-flex btn-group">
                                <date-picker v-model="timePunch.timeout" format="hh:mm a" :minute-step="5" value-type="format" type="time" placeholder="hh:mm a"></date-picker>
                            </div>
                        </td>
                        <td>
                            <!-- Time Clocked and Status -->
                            <span v-if="timePunch.time_out">{{ timePunch.durationComputed }}</span>
                            <button v-else type="button" class="btn btn-clockin btn-link btn-sm status-badge">{{ $t('label.clocked in') }}</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Footer -->
        <div class="d-flex modal-footer border-top">
            <div class="mr-auto">
                <button type="button" class="btn btn-primary align-items-center" @click="updateTimePunch(timePunch)">
                    <i class="ri-save-line"></i>&nbsp;{{ $t('label.save entry') }}
                </button>
            </div>
            <div class="ml-auto">
                <button type="button" class="btn btn-danger align-items-center" @click="viewDeleteConf(timePunch)">
                    <i class="ri-delete-bin-line"></i>&nbsp;{{ $t('label.delete entry') }}
                </button>
                <button type="button" class="btn btn-secondary" @click="$emit('closeFnc')">
                    {{ $t('label.close') }}
                </button>
            </div>
        </div>

        <!-- Delete Time Punch Modal -->
        <delete-modal v-if="showDeleteModal" :new-id="'timePunch'" :title="$t('label.ARE YOU SURE YOU WANT TO DELETE THIS TIME PUNCH')" @onClose="showDeleteModal = false" @customDeleteMethod="deleteTimePunch()">
        </delete-modal>
    </div>
</template>

<script>
import DeleteModal from '@/components/ModalDelete2.vue';
import bus from '@/utils/event-bus';
import * as $ from 'jquery';

export default {
    props: ['timePunch'],
    emits: ['closeFnc', 'reload'],
    components: {
        DeleteModal
    },
    data() {
        return {
            loader: null,
            clockedInData: null,
            showDeleteModal: false
        }
    },
    mounted() {
        this.loader = this.$loading.show();
        this.getUserCurrentClockin().then(() => {
            this.loader.hide();
        });        
    },
    methods: {
        getUserCurrentClockin() {
            let userId = this.timePunch.user.id;
            return axios.get("/get-user-current-clockin/" + userId).then(response => {
                this.clockedInData = response.data.result;
            }).catch(err => console.log(err));
        },

        updateTimePunch() {
            // Allow clocking in only if they won't clock the person in twice. 
            if (!this.clockedInData || (this.timePunch.timeout !== null)) {
                this.loader = this.$loading.show();

                axios.post('/update-user-time-punch', this.timePunch).then(response => {
                    let message = this.$t('label.time punch saved successfully') + '!';
                    bus.$emit('banner-success', { message: message });
                    this.$emit('reload');
                    this.$emit('closeFnc');
                }).catch(err => {
                    // Let user know if there is time overlap
                    if (err.data == 'overlap') {
                        bus.$emit('banner-error', {
                            message: this.$t('label.the time punch is overlapping with another time punch and cannot be saved') + '.'
                        });
                    }
                }).finally(() => this.loader.hide());

            } else {
                bus.$emit('banner-error', {
                    message: this.dataSelected.user.name + " " + this.$t('label.is already clocked in') + "."
                });
            }
        },
        viewDeleteConf() {
            this.showDeleteModal = true;
        },
        deleteTimePunch() {
            this.loader = this.$loading.show();

            axios.post(`/delete-time-punch/` + this.timePunch.id).then(response => {
                this.$emit('reload');
                this.loader.hide();
                bus.$emit('banner-success', {
                    message: this.$t('label.time punch deleted successfully') + '!'
                });
                this.$emit('closeFnc');
            }).catch(err => console.log(err));
        }
    }
}
</script>