<template>
	<div>

		<div class="modal-content">
			<div id="topOfModalCustomer"></div>
			<!-- Add/Edit Lead -->
			<div v-if="form.account_type == 0" class="modal-header border-bottom">
				<h5 v-if="modalType == 'edit'" class="modal-title">{{ $t('label.edit lead') }}</h5>
				<h5 v-else class="modal-title">{{ $t('label.add lead') }}</h5>
				<button type="button" class="close" @click="$emit('cancelFnc')">
					<i class="ri-close-line"></i>
				</button>
			</div>

			<!-- Add/Edit Customer -->
			<div v-if="form.account_type == 1" class="modal-header border-bottom">
				<h5 v-if="modalType == 'edit'" class="modal-title">{{ $t('label.edit customer') }}</h5>
				<h5 v-else class="modal-title">{{ $t('label.add customer') }}</h5>
				<button type="button" class="close" @click="$emit('cancelFnc')">
					<i class="ri-close-line"></i>
				</button>
			</div>

			<!-- Data form -->
			<div class="modal-body">
				<div class="row">
					<div class="col-sm-12 col-md-4">
						<!-- Lead Status -->
						<div v-if="form.account_type == 0" class="form-group">
							<label class="form-control-label text-muted" for="input_name">{{ $t('label.lead status') }}</label>
							<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

							<div class="dropdown w-100">
								<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': ($v.form.status.$error), }">
										<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a status') + ' -'" v-model="$v.form.status_name.$model" autocomplete="input-field" data-lpignore="true" required readonly>
										<div class="input-group-append">
											<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
										</div>
									</div>
								</div>

								<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
									<div class="pl-3">
										<span class="leadstatus pointer dropdown-items" :value="null" @click="selectedLeadStatus('');">{{ $t('label.select a status') }}</span>
										<template v-for="status in leadStatus">
											<div :key="status.id" :value="status.id" class="pointer dropdown-items" @click="selectedLeadStatus(status);">
												<span class="leadstatus">{{ status.name }}</span>
											</div>
										</template>
										<template v-for="status in wonAndLost">
											<div :key="status.id" :value="status.id" class="pointer dropdown-items" @click="selectedLeadStatus(status);">
												<span class="leadstatus">{{ status.name }}</span>
											</div>
										</template>
									</div>
								</div>
							</div>
						</div>

						<!-- Customer Status -->
						<div v-else class="form-group">
							<label class="form-control-label text-muted" for="input_name">{{ $t('label.customer status') }}</label>
							<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

							<div class="dropdown w-100">
								<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': ($v.form.status.$error), }">
										<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a status') + ' -'" v-model="$v.form.status_name.$model" autocomplete="input-field" data-lpignore="true" required readonly>
										<div class="input-group-append">
											<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
										</div>
									</div>
								</div>

								<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
									<div class="pl-3">
										<template>
											<div class="pointer dropdown-items" @click="selectedStatus(1, 'Active')">
												<span class="customerstatus pointer dropdown-items" value="1">{{ $t('label.active') }}</span>
											</div>
											<div class="pointer dropdown-items" @click="selectedStatus(0, 'Inactive')">
												<span class="customerstatus pointer dropdown-items" value="0">{{ $t('label.inactive') }}</span>
											</div>
										</template>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-md-4">
						<!-- Person Responsible -->
						<div class="form-group">
							<label class="form-control-label text-muted" for="vendor_name">{{ $t('label.responsible') }}</label>
							<div class="dropdown w-100">
								<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<div class="input-group input-group-alternative input-group-merge search-contacts">
										<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a person') + ' -'" v-model="$v.form.user_name.$model" autocomplete="input-field" data-lpignore="true" required readonly>
										<div class="input-group-append">
											<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
										</div>
									</div>
								</div>

								<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
									<div class="pl-3">
										<template>
											<div class="pointer dropdown-items" @click="selectedResponsible(null)">
												<span :value="null" class="username">- Select a Person -</span>
											</div>
										</template>
										<template v-for="(data, index) in allUsers">
											<div :key="index" class="pointer dropdown-items" @click="selectedResponsible(data)">
												<span class="username">{{ data.name }}</span>
											</div>
										</template>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- Budget -->
					<div class="col-sm-12 col-md-4">
						<div class="form-group">
							<label class="form-control-label text-muted" for="input-name">{{ $t('label.budget') }}</label>
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text input-group-invoice"><i class="ri-money-dollar-circle-line"></i></span>
								</div>
								<money v-model="form.budget" v-bind="money" class="form-control" required></money>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12 col-md-6">
						<!-- Lead Name or Customer name -->
						<div class="row">
							<div class="col-sm-12 form-group">
								<div v-if="form.account_type == 0">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.lead name') }} (Company)</label>
									<span class="text-xs text-danger">* {{ $t('label.required') }}</span>
									<input type="text" name="name" class="form-control" v-model="$v.form.name.$model" :class="{ 'is-invalid': $v.form.name.$error }" :placeholder="$t('label.lead name') + ' (Company)'" required autofocus autocomplete="input-field" data-lpignore="true">
								</div>
								<div v-else>
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.customer name') }}</label>
									<span class="text-xs text-danger">* {{ $t('label.required') }}</span>
									<input type="text" name="name" class="form-control" v-model="$v.form.name.$model" :class="{ 'is-invalid': $v.form.name.$error }" :placeholder="$t('label.customer name')" required autofocus autocomplete="input-field" data-lpignore="true">
								</div>
								<div v-if="$v.form.name.$error && !$v.form.name.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
								<div v-if="$v.form.name.$error && $v.form.name.$model.length > 0" class="text-danger text-sm">*{{ $t('label.must be at least 3 characters') }}</div>
							</div>
						</div>

						<div class="row">
							<!-- Contact first name -->
							<div class="col-sm-12 col-md-6">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.contact first name') }}</label>
									<input type="text" name="first_name" class="form-control" v-model="form.first_name" :placeholder="$t('label.first name')" autocomplete="input-field" data-lpignore="true">
								</div>
							</div>

							<!-- Contact last name -->
							<div class="col-sm-12 col-md-6">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.contact last name') }}</label>
									<input type="text" name="last_name" class="form-control" v-model="form.last_name" :placeholder="$t('label.last name')" autocomplete="input-field" data-lpignore="true">
								</div>
							</div>

							<!-- Email -->
							<div class="col-sm-12 col-md-6">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.email') }}</label>
									<input type="text" name="email" class="form-control" v-model="form.email" :placeholder="$t('label.email')" autocomplete="input-field" data-lpignore="true">
								</div>
							</div>
							<!-- Phone -->
							<div class="col-sm-12 col-md-6">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-phone">{{ $t('label.phone') }}</label>
									<input type="text" id="input-phone" v-model="form.phone_1" name="phone_1" class="form-control phone-validate" :placeholder="$t('label.phone')" v-mask="'(###) ###-####'" autocomplete="input-field" data-lpignore="true">
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-12 col-md-8">
								<!-- Lead Domain/URL -->
								<div v-if="form.account_type == 0" class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.lead domain/url') }}</label>
									<input @keyup="noDomainKey($event)" type="text" name="domain" v-model="form.domain" class="form-control" :placeholder="$t('label.lead domain/url')" autofocus>
								</div>
								<!-- Customer Domain/URL -->
								<div v-else class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.customer domain/url') }}</label>
									<input @keyup="noDomainKey($event)" type="text" name="domain" v-model="form.domain" class="form-control" :placeholder="$t('label.customer domain/url')" autofocus>
								</div>
							</div>
							<!-- no domain checkbox -->
							<div class="col-sm-12 col-md-4">
								<div class="form-group">
									<label class="form-control-label text-muted" for="no_domain">&nbsp;</label>
									<div class="custom-control custom-control-alternative custom-checkbox mb-3" @change="noDomainChange($event)">
										<input class="custom-control-input" id="no_domain" type="checkbox" v-model="form.no_domain">
										<label class="custom-control-label" for="no_domain">
											<span class="text-muted">No Domain/URL</span>
										</label>
									</div>
								</div>
							</div>
						</div>

						<!-- Logo -->
						<div class="form-group dropzone-msg">
							<label v-if="form.account_type == 0" class="form-control-label" for="input-name">{{ $t('label.lead logo/photo') }}</label>
							<label v-else class="form-control-label" for="input-name">{{ $t('label.customer logo/photo') }}</label>
							<vue-dropzone v-show="!hasLogo" id="theDropzone" ref="myVueDropzone" :options="dropzoneOptions" v-on:vdropzone-processing="inProgressFileUpload" v-on:vdropzone-file-added="getAddedFile" v-on:vdropzone-success="getUploadedFile">
							</vue-dropzone>
							<div v-show="hasLogo" class="text-center" id="dropzone-image">
								<div class="dz-image">
									<img width="100%" :alt="form.logo" :src="form.logo">
								</div>
							</div>

							<div v-if="uploadedFiles || hasLogo" class="text-center">
								<div class="mt-3">
									<button @click="replaceLogo" type="button" class="btn btn-outline-primary text-uppercase"><i class="ri-upload-2-line"></i> Replace Logo</button>
									<button @click="removeLogo" type="button" class="btn btn-outline-danger text-uppercase"><i class="ri-delete-bin-line"></i> Remove Logo</button>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-md-6">
						<!-- Address 1 -->
						<div class="form-group">
							<label class="form-control-label text-muted" for="input-name">{{ $t('label.address') }} 1</label>
							<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

							<input type="text" name="address_1" v-model="$v.form.address_1.$model" class="form-control" :class="{ 'is-invalid': $v.form.address_1.$error }" :placeholder="$t('label.address') + ' 1'" required autofocus autocomplete="nope">
							<div v-if="$v.form.address_1.$error && !$v.form.address_1.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
						</div>
						<!-- Address 2 -->
						<div class="form-group">
							<label class="form-control-label text-muted" for="input-name">{{ $t('label.address') }} 2</label>
							<input type="text" name="address_2" v-model="form.address_2" class="form-control" :placeholder="$t('label.address') + ' 2'" autofocus>
						</div>

						<!-- City -->
						<div class="form-group">
							<label class="form-control-label text-muted" for="input-name">{{ $t('label.city') }}</label>
							<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

							<input type="text" name="city" v-model="$v.form.city.$model" class="form-control" :class="{ 'is-invalid': $v.form.city.$error }" :placeholder="$t('label.city')" required autofocus autocomplete="nope">
							<div v-if="$v.form.city.$error && !$v.form.city.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
						</div>
						<div class="row">
							<!-- Zipcode -->
							<div class="col-sm-12 col-md-6">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input-name">{{ $t('label.postal code') }}</label>
									<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

									<input @keyup="getStateFromPostalCode" type="text" name="postal_code" v-model="$v.form.postal_code.$model" class="form-control" :class="{ 'is-invalid': $v.form.postal_code.$error }" :placeholder="$t('label.postal code')" required autofocus autocomplete="nope">
									<div v-if="$v.form.postal_code.$error && !$v.form.postal_code.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
								</div>
							</div>
							<!-- State -->
							<div class="col-sm-12 col-md-6">
								<div class="form-group">
									<label class="form-control-label text-muted" for="input_name">{{ $t('label.state') }}</label>
									<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

									<div class="dropdown w-100">
										<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': ($v.form.state.$error), }">
												<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a state') + ' -'" v-model="$v.form.state.$model" autocomplete="input-field" data-lpignore="true" required readonly>
												<div class="input-group-append">
													<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
												</div>
											</div>
										</div>

										<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
											<div class="pl-3">
												<template v-for="(data, index) in allstates">
													<div :key="index" :value="data" class="pointer dropdown-items" @click="selectedState(data);">
														<span class="state">{{ index }}</span>
													</div>
												</template>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- Country -->
						<div class="form-group">
							<label class="form-control-label text-muted" for="input_name">{{ $t('label.country') }}</label>
							<span class="text-xs text-danger">* {{ $t('label.required') }}</span>

							<div class="dropdown w-100">
								<div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<div class="input-group input-group-alternative input-group-merge search-contacts" :class="{ 'border-danger': ($v.form.country.$error), }">
										<input type="text" class="form-control form-control-dropdown pointer" :placeholder="'- ' + $t('label.select a country') + ' -'" v-model="$v.form.country.$model" autocomplete="input-field" data-lpignore="true" required readonly>
										<div class="input-group-append">
											<span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
										</div>
									</div>
								</div>

								<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
									<div class="pl-3">
										<template v-for="(data, index) in allcountries">
											<div :key="index" :value="index" class="pointer dropdown-items" @click="selectedCountry(data);">
												<span class="country">{{ data }}</span>
											</div>
										</template>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="modal-footer border-top">
				<!-- Lead -->
				<template v-if="form.account_type == 0">
					<!-- edit -->
					<button v-if="modalType == 'edit'" type="button" class="btn btn-primary mr-auto" @click="saveCustomer(1)">{{ $t('label.save changes') }}</button>
					<!-- Add -->
					<button v-else type="button" class="btn btn-primary mr-auto" @click="saveCustomer(1)">{{ $t('label.add lead') }}</button>
				</template>
				<!-- Customer -->
				<template v-else-if="form.account_type == 1">
					<!-- edit -->
					<button v-if="modalType == 'edit'" type="button" class="btn btn-primary mr-auto" @click="saveCustomer(0)">{{ $t('label.save changes') }}</button>
					<!-- Add -->
					<button v-else type="button" class="btn btn-primary mr-auto" @click="saveCustomer(0)">{{ $t('label.add customer') }}</button>
				</template>

				<!-- Close -->
				<button type="button" class="btn btn-link ml-auto" @click="$emit('cancelFnc')">{{ $t('label.close') }}</button>
			</div>
		</div>

	</div>
</template>

<script>
import bus from '@/utils/event-bus';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { minLength, required } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers('userData');

export default {
	props: ['job', 'customerdata', 'source', 'jobcreate', 'accountType'],
	components: {
		vueDropzone: vue2Dropzone
	},
	emits: ['cancelFnc', 'returnFnc'],
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
			companySettings: JSON.parse(localStorage.getItem('company_settings')),
			allstates: [],
			leadStatus: [],
			wonAndLost: [],
			allcountries: [],
			hasLogo: true,
			hasImage: true,
			photo: null,
			photoFile: null,
			modalType: null,
			uploadedFiles: null,
			form: {
				id: null,
				status: this.accountType == 1 ? 1 : null,
				status_name: null,
				name: '',
				first_name: null,
				last_name: null,
				phone_1: null,
				phone_2: null,
				email: null,
				country: 'United States',
				address_1: null,
				address_2: null,
				city: null,
				state: null,
				postal_code: null,
				user_id: null,
				user_name: null,
				budget: 0.00,
				domain: null,
				account_type: this.accountType,
				logo: null
			},
			money: {
				prefix: '',
				decimal: '.',
				thousands: ',',
				precision: 2,
				masked: false
			},
			dropzoneOptions: {
				maxFiles: 1,
				maxFilesize: 10,
				thumbnailWidth: null,
				addRemoveLinks: true,
				acceptedFiles: 'image/*',
				// url: 'https://putsreq.com/sMLPgj8Pe0jHquCDgZcs',
				url: 'https://httpbin.org/post',
				dictDefaultMessage: `
						<div>
							<span>Drag and drop files here</span><br>
							<span>OR</span><br>
							<button type="button" class="btn btn-default">
								Browse Files
							</button>
						</div>
					`,
			},
			allUsers: {},
		}
	},
	validations: {
		form: {
			name: {
				required,
				minLength: minLength(3)
			},
			city: { required },
			state: { required },
			status: { required },
			status_name: {},
			user_id: {},
			user_name: {},
			country: { required },
			address_1: { required },
			postal_code: { required },
		}
	},
	computed: {
		// ...mapState({
		// 	allUsers: state => state.allUsers,
		// }),
	},
	mounted() {
		this.loadSupportingData();

		if (this.customerdata) {
			this.modalType = 'edit'
		}
	},
	methods: {
		// ...mapActions(['getAllUsers']),
		// Load all the look up date before loading existing lead/customer or allowing user imput.
		loadSupportingData() {
			this.loader = this.$loading.show();
			var promises = [
				axios.get('/get-countries'),
				axios.get('/get-states'),
				axios.get('/get-lead-status'),
				this.getAllActiveUsers(),
			]

			// pull from vuex but if empty load it
			// if (!this.allUsers.length) promises.push(this.getAllUsers())
			Promise.all(promises).then((responses) => {
				this.loader.hide();
				this.allcountries = responses[0].data.result;
				this.allstates = responses[1].data.result;
				this.leadStatus = responses[2].data.result;
				this.wonAndLost = responses[2].data.won_and_lost;
				// Load the existing customer/lead into the form on edit
				if (this.customerdata) {
					this.editModal();
				}
				else {
					//Setup form for new customer/lead
					this.hasLogo = false;
				}
			});
		},
		template: function () {
			return `<div class="dz-preview dz-file-preview">
                        <div class="dz-image">
                            <div data-dz-thumbnail-bg></div>
                        </div>
                        <i class="fas fa-upload"></i>
                        <div class="dz-details">
                            <div class="dz-filename"><span data-dz-name></span></div>
                        </div>
                        <div class="dz-progress-percentage"></div>
                        <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                        <div class="dz-error-message"><span data-dz-errormessage></span></div>
                        <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                        <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                    </div>
                `;
		},
		getStateFromPostalCode() {
			if (this.form.postal_code && this.form.postal_code.length == 5) {
				axios.get('/get-state/' + this.form.postal_code).then(response => {
					if (response.data.results) {
						this.form.state = response.data.results.abbreviation;
					}
				})
					.catch(err => {
						//console.log('list carriers error:', error)
					});
			}
		},
		getAllActiveUsers() {
			return axios.get('/get-all-users').then(response => {
				this.allUsers = response.data.result;
			}).catch(err => console.error(err));
		},
		editModal() {
			this.form.account_type = this.customerdata.account_type;
			this.form.status = this.form.account_type == 1 ? this.customerdata.status : this.customerdata.lead_status_id;
			this.form.status_name = this.form.status == 1 ? this.$t('label.active') : this.$t('label.inactive');
			this.form.id = this.customerdata.id;
			this.form.user_id = this.customerdata.user_id ? this.customerdata.user_id : null;
			this.form.user_name = this.customerdata.user ? this.customerdata.user.name : null;
			this.form.budget = parseFloat(this.customerdata.budget).toFixed(2);
			this.form.name = this.customerdata.name;
			this.form.first_name = this.customerdata.first_name;
			this.form.last_name = this.customerdata.last_name;
			this.form.email = this.customerdata.email;
			// $('#input-phone').val(this.customerdata.phone_1);
			this.form.phone_1 = this.customerdata.phone_1;
			this.form.phone_2 = this.customerdata.phone_2;
			this.form.domain = this.customerdata.domain;
			this.form.logo = (this.customerdata.logo == '/logo/Avatar (Company Default).png') ? null : this.customerdata.logo;
			this.photo = this.customerdata.logo;
			this.form.country = this.customerdata.country;
			this.form.address_1 = this.customerdata.address_1;
			this.form.address_2 = this.customerdata.address_2;
			this.form.city = this.customerdata.city;
			this.form.state = this.customerdata.state;
			this.form.postal_code = this.customerdata.postal_code;
			if (!this.form.logo)
				this.hasLogo = false
		},
		saveCustomer(e) {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.loader = this.$loading.show();
				let address2 = this.form.address_2 === null || this.form.address_2 == '' ? '' : this.form.address_2,
					uploadFile = this.uploadedFiles === null ? 0 : this.uploadedFiles,
					formData = new FormData();

				formData.append('id', this.form.id);
				formData.append('user_id', this.form.user_id == null ? null : this.form.user_id);
				formData.append('name', this.form.name);
				formData.append('first_name', (this.form.first_name == null) ? '' : this.form.first_name);
				formData.append('last_name', (this.form.last_name == null) ? '' : this.form.last_name);
				// formData.append('phone_1', $('#input-phone').val());
				formData.append('phone_1', (this.form.phone_1 == null) ? '' : this.form.phone_1);
				formData.append('phone_2', (this.form.phone_2 == null) ? '' : this.form.phone_2);
				formData.append('email', (this.form.email == null) ? '' : this.form.email);
				formData.append('country', this.form.country);
				formData.append('address_1', this.form.address_1);
				formData.append('address_2', address2);
				formData.append('city', this.form.city);
				formData.append('state', this.form.state);
				formData.append('postal_code', this.form.postal_code);
				formData.append('budget', this.form.budget);
				formData.append('domain', this.form.domain);
				formData.append('account_type', this.form.account_type);
				formData.append('status', this.form.status);
				formData.append('lead_status_id', this.form.account_type == 0 ? this.form.status : null);
				formData.append('photo', uploadFile);

				// Add contact
				if (this.modalType != 'edit') {
					axios.post('/customer', formData).then(response => {
						this.loader.hide();

						var requestData = {
							data: response.data,
							leadOrCustomer: e,
						}
						bus.$emit('banner-success', { message: 'Created Successfully!' });
						this.$emit('returnFnc', requestData);
					}).catch(err => console.log('Error adding Customer/Lead:', err));
				}
				// Update Customer/Lead
				else {
					axios.post('/update-customer', formData).then(response => {
						this.loader.hide();
						bus.$emit('banner-success', { message: 'Updated Successfully!' });

						if (this.customerEdit == 'customer-page') {
							bus.$emit('getallcustomers', {
								tableType: this.form.account_type
							});
						} else {
							this.$emit('returnFnc', response.data);
						}
					}).catch(err => console.log('Error updating Customer/Lead:', err));
				}
			} else {
				$("#topOfModalCustomer")[0].scrollIntoView();
			}
		},
		selectedStatus(value, name) {
			this.form.status = value;
			this.form.status_name = name;
		},
		selectedLeadStatus(data) {
			this.form.status = data.id;
			this.form.status_name = data.name;
		},
		selectedResponsible(data) {
			if (data == null) {
				this.form.user_id = null;
				this.form.user_name = null;
			} else {
				this.form.user_id = data.id;
				this.form.user_name = data.name;
			}
		},
		selectedState(data) {
			this.form.state = data;
		},
		selectedCountry(data) {
			this.form.country = data;
		},
		updatePhoto(event) {
			const file = event.target.files[0];
			if (file) {
				this.photo = URL.createObjectURL(file);
				this.photoFile = file;
			}
		},
		removeLogo() {
			this.photo = '';
		},
		noDomainKey(event) {
			if (event.target.value != '') {
				this.form.no_domain = false;
			}
		},
		noDomainChange(event) {
			if (this.form.no_domain) {
				this.form.domain = '';
			}
		},
		removeLogo() {
			if (this.uploadedFiles === null) {
				this.form.logo = null
			} else {
				this.$refs.myVueDropzone.removeAllFiles();
				this.uploadedFiles = null;
			}
			this.hasLogo = false
		},
		replaceLogo() {
			if (this.uploadedFiles === null) {
				this.form.logo = null;
			} else {
				this.$refs.myVueDropzone.removeAllFiles();
				this.uploadedFiles = null;
			}
			this.hasLogo = false
			this.$nextTick(() => {
				document.getElementById("theDropzone").click();
			})
		},
		getAddedFile(file) {
			this.hasImage = true;
		},
		getUploadedFile(file, response) {
			console.log(file);
			this.uploadedFiles = file;
			$('#btn-schedule-message').attr('disabled', false);
			$('#btn-update-message').attr('disabled', false);
			$('#btn-preview-message').attr('disabled', false);
		},
		inProgressFileUpload(file) {
			$('#btn-schedule-message').attr('disabled', true);
			$('#btn-update-message').attr('disabled', true);
			$('#btn-preview-message').attr('disabled', true);
		},
	}
}
</script>

<style>
.dropzone-file .dropzone {
	padding: 0 !important;
}

.dropzone .dz-message {
	padding: 30px 0 !important;
}

#dropzone-image {
	padding: 0 70px;
}
</style>
<style scoped>
.dropdown-items {
	line-height: 36px;
}

.dropdown-items:hover {
	background: #f6f9fc;
}

.dropdown-menu {
	max-height: 40vh;
	overflow: scroll;
}
</style>