<template>
	<div v-click-outside="closeNotification" class="chat-offcanvas-collapse" :class="toggleSidebar ? 'open' : ''">
		<div class="card-header pr-2">
			<h3 class="d-flex align-items-center justify-content-between">
				{{ $t('label.chats') }}
				<div class="d-flex align-items-center">
					<i class="ri-edit-box-line cursor-pointer2" @click="createNewMessage(); toggleSidebar = false;"></i>
					<button type="button" class="close hidden-unless-xs" @click="toggleSidebar = false;"><i class="ri-close-line"></i> </button>
				</div>
			</h3>
		</div>
		<div class="card-header border-0">
			<div class="row">
				<div class="col-12">
					<div class="form-group mb-0 search-rooms">
						<i class="ri-search-line search-icon"></i>
						<input name="s" class="form-control" placeholder="Search chats" type="text" @input="onChange" v-model="form.search" autocomplete="off" data-lpignore="true">
					</div>
				</div>
			</div>
		</div>
		<div class="card-body">
			<b-overlay :show="show" opacity="0.8" variant="white" rounded="sm">
				<div class="mb-4">
					<h5 class="text-uppercase">{{ $t('label.company channels') }}</h5>
					<div class="row align-items-center m-0 chat-rooms" v-for="(data, index) in companyChannelChatRooms" :key="index" @click="openChatRoom(data)">
						<div class="col-1 p-0 py-2">
							<div><i class="ri-hashtag heading-title"></i></div>
						</div>
						<div class="col p-0 border-bottom py-2 ml-3">
							<div class="d-flex align-items-center justify-content-between">
								<span class="name">{{ data.name }}</span>
								<span class="time">{{
									data.messages.length > 0 ? data.messages.at(-1).created_at : '' |
										formatSidebarChatDateAndTime
								}}</span>
							</div>
							<div class="d-flex align-items-center justify-content-between">
								<span class="message">
									{{
										data.messages.length > 0 ? data.messages.at(-1).user.name + ' : ' +
											data.messages.at(-1).message : 'No messages'
									}}
								</span>
								<span v-if="data.unread_messages_count > 0" class="unread-count rounded-circle">{{
									data.unread_messages_count
								}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="mb-4">
					<h5 class="text-uppercase">{{ $t('label.direct messages') }}</h5>
					<div class="row align-items-center m-0 chat-rooms" v-for="(data, index) in directMessageChatRooms" :key="index" @click="openChatRoom(data)">
						<div class="col-1 p-0 py-2">
							<div class="row align-items-center m-0">
								<div class="cursor-pointer2" :class="i == 0 ? '' : 'mt--3 ml-2'" v-for="(d, i) in data.dm_user.slice(0, 2)" :key="i">
									<img class=" rounded-circle" :class="data.dm_user.length > 1 ? 'avatar-xs' : 'avatar-sm'" :src="d.user.picture" />
								</div>
							</div>
						</div>
						<div class="col p-0 border-bottom py-2 ml-3">
							<div class="d-flex align-items-center justify-content-between">
								<span class="name">
									<template v-for="(d, i) in data.dm_user">
										<template v-if="i == 1 || i == 2">,</template>
										<template v-if="i == 0 || i == 1">{{ d.user.name }}</template>
										<template v-if="i == 2">... </template>
									</template>
								</span>
								<span class="time">{{
									data.messages.length > 0 ? data.messages.at(-1).created_at : '' |
										formatSidebarChatDateAndTime
								}}</span>
							</div>
							<div class="d-flex align-items-center justify-content-between">
								<span class="message">{{ data.messages.length > 0 ? data.messages.at(-1).user.name + ' : ' + data.messages.at(-1).message : 'No messages' }}</span>
								<span v-if="data.unread_messages_count > 0" class="unread-count rounded-circle">{{
									data.unread_messages_count
								}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="mb-4">
					<h5 class="text-uppercase">{{ $t('label.jobs') }}</h5>
					<div class="row align-items-center m-0 chat-rooms" v-for="(data, index) in jobChatRooms" :key="index" @click="openChatRoom(data)">
						<div class="col-1 p-0 py-2">
							<div><i class="ri-stack-line heading-title"></i></div>
						</div>
						<div class="col p-0 border-bottom py-2 ml-3">
							<div class="d-flex align-items-center justify-content-between">
								<span class="name">{{ data.job.job_name }}</span>
								<span class="time">{{
									data.messages.length > 0 ? data.messages.at(-1).created_at : '' |
										formatSidebarChatDateAndTime
								}}</span>
							</div>
							<div class="d-flex align-items-center justify-content-between">
								<span class="message">{{ data.messages.length > 0 ? data.messages.at(-1).user.name + ' : ' + data.messages.at(-1).message : 'No messages' }}</span>
								<span v-if="data.unread_messages_count > 0" class="unread-count rounded-circle">{{
									data.unread_messages_count
								}}</span>
							</div>
						</div>
					</div>
				</div>
			</b-overlay>
		</div>
	</div>
</template>
<script>
import * as _ from 'lodash';
import ClickOutside from 'vue-click-outside';
import { mapGetters } from 'vuex';

export default {
	props: ['footer', 'parentBus'],
	components: {
		//
	},
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
			form: {
				search: ''
			},
			companyChannelChatRooms: [],
			directMessageChatRooms: [],
			jobChatRooms: [],
			show: false,
			toggleSidebar: false,
			openingNavbar: false // To avoid using dom related methods 
		}
	},
	computed: {
		...mapGetters({
			myChatRooms: 'chat/myChatRooms',
			unreadChatCount: 'chat/unreadChatCount',
		}),
	},
	created() {
		this.$bus.$on('openNotification', () => {
			console.log('hi');
			this.openingNavbar = true;
			this.openNotification();
		});
	},
	mounted() { },
	methods: {
		openNotification() {
			this.form.search = '';
			this.show = true;
			this.$store.dispatch('chat/getMyChatRooms').then(response => {
				console.log('response', response)
				this.companyChannelChatRooms = response.data.companyChannelChatRooms;
				this.directMessageChatRooms = response.data.directMessageChatRooms;
				this.jobChatRooms = response.data.jobChatRooms;

				this.show = false;
			})
			this.toggleSidebar = true;
		},
		closeNotification() {
			if (this.openingNavbar)
				this.openingNavbar = false;
			else
				this.toggleSidebar = false;
		},
		openChatRoom(data) {
			this.$store.commit('chat/pushChatRoomToActiveChats', data)
			this.toggleSidebar = false;
			this.closeNotification()
		},
		createNewMessage() {
			let data = {
				id: 0,
				minimized: false,
				type: 'new',
				dm_user: []
			}
			this.$store.commit('chat/pushChatRoomToActiveChats', data)
			this.closeNotification()
		},
		onChange() {
			var that = this;

			if (this.form.search.length > 2) {
				this.show = true;

				this.$store.dispatch('chat/getMyChatRooms').then(response => {
					this.companyChannelChatRooms = this.companyChannelChatRooms.filter(x => {
						let checkMesg = x.messages.filter(y => y.message && y.message.toLowerCase().includes(that.form.search.toLowerCase()));
						let ret = x.name.toLowerCase().includes(that.form.search.toLowerCase()) || (checkMesg.length > 0);
						return ret;
					})
					this.directMessageChatRooms = this.directMessageChatRooms.filter(x => {
						let checkMesg = x.messages.filter(y => y.message && y.message.toLowerCase().includes(that.form.search.toLowerCase()));
						let check = x.dm_user.filter(y => y.user.name.toLowerCase().includes(that.form.search.toLowerCase())) || (checkMesg.length > 0);
						return check.length > 0;
					})
					this.jobChatRooms = this.jobChatRooms.filter(x => {
						let checkMesg = x.messages.filter(y => y.message && y.message.toLowerCase().includes(that.form.search.toLowerCase()));
						let ret = x.job.job_name.toLowerCase().includes(that.form.search.toLowerCase()) || (checkMesg.length > 0);
						return ret;
					});

					this.show = false;
				})
			}

			if (this.form.search.length == 0) {
				this.show = true;
				this.$store.dispatch('chat/getMyChatRooms').then(response => {
					this.filterChatRooms();
					this.sortChatRooms();

					this.show = false;
				})

			}
		},
		filterChatRooms() {
			this.companyChannelChatRooms = this.myChatRooms.filter(x => x.type == 'company' || x.type == 'channel');
			this.directMessageChatRooms = this.myChatRooms.filter(x => x.type == 'user' || x.type == 'group');
			this.jobChatRooms = this.myChatRooms.filter(x => x.type == 'job');
		},
		sortChatRooms() {
			// sort chatrooms
			this.companyChannelChatRooms = _.orderBy(this.companyChannelChatRooms, ['unread_messages_count', 'unread_messages[0].created_at', data => data.name.toUpperCase()], ['desc', 'desc', 'asc']);
			this.directMessageChatRooms = _.orderBy(this.directMessageChatRooms, ['unread_messages_count', 'unread_messages[0].created_at', data => data.dm_user[0].user.name.toUpperCase()], ['desc', 'desc', 'asc']);
			this.jobChatRooms = _.orderBy(this.jobChatRooms, ['unread_messages_count', 'unread_messages[0].created_at', data => data.job.job_name.toUpperCase()], ['desc', 'desc', 'asc']);
		}
	},
	directives: {
		ClickOutside
	}
}
</script>
<style lang="scss" scoped>
.chat-rooms {
	cursor: pointer;

	.name {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		display: flex;
		align-items: center;
		letter-spacing: 0.2px;
		color: #32325D;
		width: 75%;
	}

	.time {
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		display: flex;
		align-items: center;
		text-align: right;
		color: #8898AA;
	}

	.message {
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		display: flex;
		align-items: center;
		color: #8898AA;
		max-width: 90%;
	}

	.unread-count {
		width: 14px;
		height: 14px;
		background: #FDD9D0;
		color: #6A043D;
		font-style: normal;
		font-weight: 700;
		font-size: 10px;
		line-height: 14px;
		text-align: center;
	}
}

.search-rooms {
	.search-icon {
		position: relative;
		bottom: -37px;
		z-index: 1;
		left: 10px;
	}

	input {
		padding-left: 30px;
	}
}
</style>