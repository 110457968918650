<template>
    <div class="col-xl-12 order-xl-1 p-0" id="vpoViewAttachments">
        <div class="card">
            <!-- Card Header -->
            <div class="card-header pb-1" style="border-bottom: 0px;">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="pr-3" data-toggle="tab" href="#tabs-btn-list">
                        <h3 class="mb-0 d-flex align-items-center">
                            <i class="ri-folders-line heading-title"></i> {{ $t('label.attachments') }}
                        </h3>
                    </div>
                </div>
            </div>

            <!-- Card Body -->
            <div class="m-0">
                <div class="card-body mt-2 border-top">
                    <div class="row m-0 mb-3">
                        <!-- VPO Pdf -->
                        <div class="col-4">
                            <div class="d-flex align-items-center m-0">
                                <img class="mr-2" :src="require('@/assets/images/doc-pdf.png')" style="height: 48px; object-fit: cover;" />
                                <span class="cursor-pointer text-bold" style="word-wrap: break-word" @click="showPdf()">
                                    {{ vpoPdfName }}
                                </span>
                            </div>
                        </div>

                        <!-- Additional Attachments -->
                        <div class="col-4 pb-3 pl-0" v-for="(file, index) in attachments" :key="index">
                            <div class="d-flex align-items-center flex-grow-1">
                                <div class="d-flex align-items-center" v-if="file.id">
                                    <img class="image-container float-left mr-2" style="height: 48px;  object-fit: cover;" :src="file.file_type == 'application/pdf' ? '/images/icons/doc-pdf.png' : file.download" />
                                    <span class="cursor-pointer text-bold" @click="showAttachmentFile(file)">
                                        <span v-if="file.display_name">{{ file.display_name }}</span>
                                        <span v-else>{{ file.name }}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- View File Modal -->
        <ViewUploadFileModal v-if="viewFileModal" :src="fileToView.download" :display-name="fileToView.display_name" @onClose="hideViewFileModal()">
        </ViewUploadFileModal>

        <!-- View VPO PDF -->
        <modal class="py-4" name="modalVpoViewAttachment" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
            <ModalVpoPdfView :vpo="vpo" @cancelFnc="$modal.hide('modalVpoViewAttachment')">
            </ModalVpoPdfView>
        </modal>

    </div>
</template>

<script>
import ViewUploadFileModal from '@/components/UploadedFiles/ViewUploadedFileModal.vue';
import ModalVpoPdfView from '@/components/VariablePurchaseOrders/modals/VpoPdfView.vue';

export default {
    props: ['vpo'],
    components: {
        ModalVpoPdfView,
        ViewUploadFileModal
    },
    data() {
        return {
            fileToView: {},
            attachments: {},
            viewFileModal: false,
        }
    },
    computed: {
        vpoPdfName() {
            if (this.vpo.vpo_number) {
                let pdfName = "VPO-" + this.vpo.vpo_number.vpo_number + "_" + moment().format('YYYY_MM_DD');
                return (this.vpo.vpo_status.name == 'Cancelled') ? ("CANCELLED_" + pdfName) : pdfName;
            } else {
                return '';
            }
        }
    },
    mounted() {
        this.getVpoAttachments();
    },
    methods: {
        getVpoAttachments() {
            let apiUrl = '/vpo-get-attachments/';
            return axios.get(apiUrl + this.vpo.id).then((resp) => {
                this.attachments = resp.data.result;
            });
        },

        showPdf() {
            this.$modal.show('modalVpoViewAttachment');
        },

        showAttachmentFile(file) {
            this.fileToView = file;
            this.showViewFileModal();
        },

        showViewFileModal() {
            this.viewFileModal = true;
        },
        hideViewFileModal() {
            this.viewFileModal = false;
        }
    }
}
</script>