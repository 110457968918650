<template>
    <div v-if="showProximityFlag">
        <h3 v-if="flag == 'flag'">Timesheet Proximity Flags</h3>
        <table class="table table-flush table-sm" id="datatable-basic" :class="flag == 'flag' ? 'mb-4' : ''">
            <tbody>
                <tr v-for="(data, index) in timesheets" :key="index" v-if="showMap && (flag == 'flag' ? !data.checkDataProximity : showMap)">
                    <td colspan="7" class="p-0">
                        <table class="table table-sm table-flush text-center mb-0" id="datatable-basic" :style="(flag == 'list' && index != (timePunches.length - 1)) ? 'border-bottom: 1px solid #e9ecef' : ''">
                            <thead class="thead-light" :class="data.timepunches.length ? 'pointer' : ''" @click="toggleData(index, data);">
                                <tr :id="'task-time-header-' + flag + '-' + task.id + '-' + index" v-if="flag == 'flag'" class="bg-white">
                                    <td colspan="7">
                                        <h3 :id="'task-assignee-header-' + flag + '-' + task.id + '-' + index" class="text-left mb-0 font-weight-normal d-flex align-items-center">
                                            <i class="fa-chevron-down fas mr-3 icon-list" :style="!data.timepunches.length ? 'opacity: 0' : ''" :id="'icon-list-' + flag + '-' + task.id + '-' + index"></i>
                                            <div class="row align-items-center m-0 w-100">
                                                <div class="cursor-pointer">
                                                    <img class="rounded-circle" :src="data.user.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                                </div>
                                                <div class="col-7 col-xs-12 px-0 text-left">
                                                    <div class="ml-3 ml-xs-0">
                                                        <h4 class="font-weight-normal m-0 assignee-name">{{ data.user.name }}</h4>
                                                        <h5 class="text-muted font-weight-normal m-0 assignee-manager">{{ data.task_manager == '1' ? 'Crew Manager' : 'Crew Member' }}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </h3>
                                    </td>
                                </tr>
                                <tr v-else :id="'task-time-header-' + flag + '-' + task.id + '-' + index" class="bg-white">
                                    <td style="width: 30%" class="text-left">
                                        <h3 :id="'task-assignee-header-' + flag + '-' + task.id + '-' + index" class="text-left mb-0 font-weight-normal d-flex align-items-center">
                                            <i class="fa-chevron-down fas mr-3 icon-list" :style="!data.timepunches.length ? 'opacity: 0' : ''" :id="'icon-list-' + flag + '-' + task.id + '-' + index"></i>
                                            <div class="row align-items-center m-0 w-100">
                                                <div class="cursor-pointer">
                                                    <img class="rounded-circle" :src="data.user.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                                </div>
                                                <div class="col-7 col-xs-12 px-0 text-left">
                                                    <div class="ml-3 ml-xs-0">
                                                        <h4 class="font-weight-normal m-0 assignee-name">{{ data.user.name }}</h4>
                                                        <h5 class="text-muted font-weight-normal m-0 assignee-manager">{{ data.task_manager == '1' ? 'Crew Manager' : 'Crew Member' }}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </h3>
                                    </td>
                                    <td style="width: 15%" class="text-left">
                                        <button v-if="!data.status" class="btn btn-clockout btn-link btn-sm">{{ $t('label.clocked out') }}</button>
                                        <button v-else type="button" class="btn btn-clockin btn-link btn-sm">{{ $t('label.clocked in') }}</button>
                                    </td>
                                    <td style="width: 20%" class="text-left hidden-sm-down">
                                        {{ viewTaskTime_timer[data.id] | formatTimeWithSec }} ({{ viewTaskTime_timer[data.id] / 3600 | formatHour }} {{ $t('label.hrs') }}) <span style="display: none;">{{ elapsedTime3 }} </span>
                                    </td>
                                    <td style="width: 15%" class="text-left">{{ data.total_time | hourToFormattedTime }} ({{ data.total_time }} {{ $t('label.hrs') }})</td>
                                    <td style="width: 10%" class="text-left">
                                        <i v-if="!data.checkDataProximity" class="ri-error-warning-line text-warning font-20"></i>
                                        <i v-else class="ri-error-warning-line font-20 opacity-0"></i>
                                    </td>
                                    <td style="width: 15%" class="text-left hidden-sm-down" v-if="checkpending">
                                        <button v-if="data.status" type="button" class="btn btn-pending-curtime btn-link btn-sm whitespace-sm-prewrap">{{ $t('label.pending current time') }}</button>
                                        <button v-else type="button" class="btn btn-pending-curtime btn-link btn-sm opacity-0 whitespace-sm-prewrap">{{ $t('label.pending current time') }}</button>
                                    </td>
                                    <td style="width: 10%" class="text-right">
                                        <!-- Dropdown Menu -->
                                        <b-dropdown id="userDropdownMenu" text="dropdown" class="m-1" right @click.stop>
                                            <template #button-content>
                                                <div class="px-3 py-1">
                                                    <i class="fas fa-ellipsis-v"></i>
                                                </div>
                                            </template>

                                            <!-- Time Clock with Permissions -->
                                            <template v-if="nonCurrentDayClockin(task.start_date, company.settings)">
                                                <!-- Clock in -->
                                                <template v-if="!data.status && (user.role_id != '4' || ((user.id == data.user.id) && company.settings.allow_clockin))">
                                                    <b-dropdown-item v-if="!data.statusDisabled" :disabled="data.task.task_status && data.task.task_status.name == 'Closed'">
                                                        <div class="d-flex" @click="clockIn(task, data)">
                                                            <i class="ri-time-line"></i> {{ $t('label.clock in') }}
                                                            <div v-if="data.task.task_status && data.task.task_status.name == 'Closed'" class="ml-auto">
                                                                ({{ $t('label.task closed') }})
                                                            </div>
                                                        </div>
                                                    </b-dropdown-item>
                                                    <b-dropdown-item v-if="data.statusDisabled" :disabled="data.statusDisabled">
                                                        <div class="d-flex" @click.stop="clockIn(task, data)">
                                                            <i class="ri-time-line"></i>{{ $t('label.clock in') }}
                                                            <div class="ml-auto">
                                                                ({{ $t('label.clocked in to') }}
                                                                <a v-if="user.role_id === '1' || user.role_id === '2'" class="blue-text" :href="'/job/' + task.job.job_number.job_number + '/edit'">{{ data.task_name }}</a>
                                                                <span v-else>{{ data.task_name }}</span>)
                                                            </div>
                                                        </div>
                                                    </b-dropdown-item>
                                                </template>
                                                <!-- Clock out -->
                                                <b-dropdown-item v-if="data.status && (user.role_id != '4' || ((user.id == data.user.id) && company.settings.allow_clockin))" @click.stop="stopClockNotify(data)" class="d-flex">
                                                    <div class="d-flex">
                                                        <i class="ri-stop-circle-line"></i> {{ $t('label.clock out') }}
                                                    </div>
                                                </b-dropdown-item>
                                                <!-- Revise Time -->
                                                <b-dropdown-item v-if="user.role_id != '4' || ((user.id == data.user.id) && company.settings.allow_clockin)" data-toggle="modal" data-target="#viewReviseTimeModal" @click.stop="viewReviseTime(data, task.selected_date)">
                                                    <div class="d-flex">
                                                        <i class="ri-edit-line"></i> {{ $t('label.revise time') }}
                                                    </div>
                                                </b-dropdown-item>
                                                <b-dropdown-divider v-if="user.role_id != '4' || ((user.id == data.user.id) && company.settings.allow_clockin)"></b-dropdown-divider>
                                            </template>
                                            <!-- User's Mobile Phone -->
                                            <b-dropdown-item href="#">
                                                <div class="d-flex">
                                                    <i class="ri-phone-line"></i> {{ $t('label.mobile') }} ({{ data.user.phone }})
                                                </div>
                                            </b-dropdown-item>
                                            <!-- User's Email -->
                                            <b-dropdown-item style="min-width: 300px;">
                                                <div class="blue-text d-flex">
                                                    <i class="ri-mail-line"></i> {{ $t('label.email') }} ({{ data.user.email }})
                                                </div>
                                            </b-dropdown-item>
                                            <!-- View Profile -->
                                            <b-dropdown-item>
                                                <div class="d-flex" @click.stop="getUserInfo(data.user.id, 'Profile')">
                                                    <i class="ri-account-circle-line"></i> {{ $t('label.view profile') }}
                                                </div>
                                            </b-dropdown-item>
                                            <template v-if="user.role_id == '2' || user.role_id == '3'">
                                                <b-dropdown-divider></b-dropdown-divider>
                                                <!-- Remove From Task -->
                                                <b-dropdown-item>
                                                    <div class="d-flex" @click="deleteNotify(data, true)">
                                                        <i class="ri-delete-bin-line btn-outline-danger"></i> {{ $t('label.remove from task') }}
                                                    </div>
                                                </b-dropdown-item>
                                            </template>
                                        </b-dropdown>
                                    </td>
                                </tr>
                            </thead>
                            <tbody class="bg-white text-left timesheet d-none" :id="'timesheet-' + flag + '-' + task.id + '-' + index">
                                <tr v-for="(time, idx) in data.timepunches" :key="idx" class="bg-darkest-gray">
                                    <td class="p-0" colspan="7" :class="((flag == 'flag' && !time.isWithinProximity) || flag == 'list') ? '' : 'd-none'">
                                        <div class="timesheet-entry mx-4" :id="'timesheet-entry-' + flag + '-' + task.id + '-' + index + '-' + idx" :class="!idx ? 'my-4' : 'my-2'">
                                            <div class="card p-4">
                                                <div v-if="flag != 'list'" class="row m-0">
                                                    <div class="form-group col-lg-3 col-md-4 col-12">
                                                        <label class="text-muted">Task</label>
                                                        <h4 class="text-primary border-bottom-light-gray font-weight-500">{{ data.task.name }}</h4>
                                                    </div>
                                                    <div class="form-group col-lg-3 col-md-4 col-12">
                                                        <label class="text-muted">Job</label>
                                                        <h4 class="text-primary border-bottom-light-gray font-weight-500">{{ data.task.job.job_name }}</h4>
                                                    </div>
                                                    <div class="form-group col-lg-3 col-md-4 col-12">
                                                        <label class="text-muted">Customer</label>
                                                        <h4 class="text-primary border-bottom-light-gray font-weight-500">{{ data.task.job.customer.name }}</h4>
                                                    </div>
                                                </div>
                                                <div class="row m-0 mbn-20">
                                                    <div class="form-group col-md-3 col-12 pl-0">
                                                        <label class="text-muted">Date</label>
                                                        <h4 class="border-bottom-light-gray font-weight-normal">{{ time.time_in | formatDateOnly }}</h4>
                                                    </div>
                                                    <div class="form-group col-md-3 col-12">
                                                        <label class="text-muted">Clock In</label>
                                                        <h4 class="border-bottom-light-gray font-weight-normal d-flex justify-content-between align-items-center">
                                                            <div>{{ time.time_in | formatTimeOnly }}</div>
                                                            <i class="ri-map-pin-2-fill ni-1x text-green icon-25"></i>
                                                        </h4>
                                                    </div>
                                                    <div class="form-group col-md-3 col-12">
                                                        <label class="text-muted">Clock Out</label>
                                                        <h4 class="border-bottom-light-gray font-weight-normal d-flex justify-content-between align-items-center">
                                                            <div v-if="time.time_out">{{ time.time_out | formatTimeOnly }}</div>
                                                            <i v-if="time.time_out" class="ri-map-pin-2-fill ni-1x text-red icon-25"></i>
                                                            <button v-else class="btn btn-sm btn-clockin mt-n-10 mb-1">Clocked In</button>
                                                        </h4>
                                                    </div>
                                                    <div class="form-group col-md-12 col-12 row align-items-center mx-xs-0 p-0">
                                                        <div class="col-lg-11 col-sm-10 col-xs-9">
                                                            <label class="text-muted">Job Location</label>
                                                            <h4 class="border-bottom-light-gray font-weight-normal d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <span v-if="data.task.job.address_1">{{ data.task.job.address_1 }}</span>
                                                                    <span v-if="data.task.job.city">, {{ data.task.job.city }}</span>
                                                                    <span v-if="data.task.job.state">, {{ data.task.job.state }}</span>
                                                                    <span v-if="data.task.job.postal_code">,{{ data.task.job.postal_code }}</span>
                                                                </div>
                                                                <i class="ri-map-pin-2-fill ni-1x text-primary icon-25"></i>
                                                            </h4>
                                                        </div>
                                                        <div class="col-1">
                                                            <button :style="time.mapStatus == false ? 'display: none' : ''" class="btn btn-sm btn-secondary show-proximity-map" @click="toggleMap(index, idx, true, data)"><i class="ri-road-map-line"></i> Show Map</button>
                                                            <button :style="time.mapStatus == true ? 'display: none' : ''" class="btn btn-sm bg-lightest-blue hide-proximity-map" @click="toggleMap(index, idx, false, data)"><i class="ri-road-map-line"></i> Hide Map</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="time.time_out === null && time.is_resolved == 0" class="row m-0 align-items-center">
                                                    <i class="ri-error-warning-line text-warning font-20"></i>
                                                    <p class="m-0 pr-3">
                                                        <span>The</span>
                                                        <span v-if="!time.clockinProximity && !time.clockoutProximity"> clock in and out</span>
                                                        <span v-if="!time.clockinProximity && time.clockoutProximity"> clock in</span>
                                                        <span v-if="time.clockinProximity && !time.clockoutProximity"> clock out</span>
                                                        <span> location is not within the approved proximity.</span>
                                                    </p>
                                                    <button class="btn btn-sm btn-default" @click="updateTimesheetProximity(time)">Mark Resolved</button>
                                                </div>
                                                <div v-else-if="time.is_resolved == 0 && (time.login_isWithinProximity == 0 || time.logout_isWithinProximity == 0)" class="row m-0 align-items-center">
                                                    <i class="ri-error-warning-line text-warning font-20"></i>
                                                    <p class="m-0 pr-3">
                                                        <span>The</span>
                                                        <span v-if="time.login_isWithinProximity == 0 && time.logout_isWithinProximity == 0"> clock in and out</span>
                                                        <span v-if="time.login_isWithinProximity == 0 && time.logout_isWithinProximity != 0"> clock in</span>
                                                        <span v-if="time.login_isWithinProximity != 0 && time.logout_isWithinProximity == 0"> clock out</span>
                                                        <span> location is not within the approved proximity.</span>
                                                    </p>
                                                    <button class="btn btn-sm btn-default" @click="updateTimesheetProximity(time)">Mark Resolved</button>
                                                </div>
                                                <div v-else-if="time.clockinProximity == 2 || time.clockoutProximity == 2" class="row m-0 align-items-center">
                                                    <i class="ri-error-warning-line text-warning font-20"></i>
                                                    <p v-if="time.clockinProximity == 2 && time.clockoutProximity == 2" class="m-0">Clockin and clockout location not found.</p>
                                                    <p v-else-if="!time.clockinProximity && time.clockoutProximity == 2" class="m-0">Clockout location not found.</p>
                                                    <p v-else-if="time.clockinProximity == 2 && !time.clockoutProximity" class="m-0">Clockin location not found.</p>
                                                </div>
                                                <div v-else-if="time.isWithinProximity == 1" class="row m-0 align-items-center">
                                                    <i class="ri-check-line text-success font-20"></i>
                                                    <p class="m-0 pr-3">Proximity check for clock in and clock out locations approved.</p>
                                                </div>
                                                <div v-else class="row m-0 align-items-center">
                                                    <i class="ri-error-warning-line text-warning font-20"></i>
                                                    <p class="m-0 pr-3">
                                                        <span>The</span>
                                                        <span v-if="!time.clockinProximity && !time.clockoutProximity"> clock in and out</span>
                                                        <span v-if="!time.clockinProximity && time.clockoutProximity"> clock in</span>
                                                        <span v-if="time.clockinProximity && !time.clockoutProximity"> clock out</span>
                                                        <span> location is not within the approved proximity.</span>
                                                    </p>
                                                    <button class="btn btn-sm btn-default" @click="updateTimesheetProximity(time)">Mark Resolved</button>
                                                </div>
                                                <div class="mt-3 map" :style="time.mapStatus ? 'display: none' : ''">
                                                    <div class="map" :id="'map-custom-' + flag + '-' + time.id" style="height: 400px;"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- Clockout Modal -->
        <!-- Uses parent or nested parent clock out method instead of a global 
        bus method to keep event calling conflicts from occurring. -->
        <clock-out-notify 
        v-if="showClockoutNotify" 
        :user-time="userTime" 
        :modal-name="'tsProximyClockout'" 
        @onClose="closeClockoutNotify" 
        @clockOutFunc="clockOut">
        </clock-out-notify>

        <!-- Template Task Delete Modal -->
        <modal-delete-component 
        v-if="showTempTaskDlt" 
        :new-id="'CrewTime'" 
        :title="'ARE YOU SURE YOU WANT TO DELETE THIS USER FROM TASK'" 
        @onClose="deleteNotify" 
        @customDeleteMethod="removeFromTask">
        </modal-delete-component>
    </div>
</template>

<style>
.blue-text {
    color: #055788 !important;
}
</style>

<script>
/* eslint-disable no-undef */
import ModalDelete2Vue from '@/components/ModalDelete2.vue';
import ClockOutNotify from '@/components/TimeManagement/ModalClockOutNotify.vue';
import TimeClock from '@/mixins/TimeClock.vue';

import bus from '@/utils/event-bus';
import { DropdownPlugin } from 'bootstrap-vue';
import * as $ from 'jquery';
import * as _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

Vue.use(DropdownPlugin);

export default {
    mixins: [TimeClock],
    props: ['selectedDate', 'flag', 'timePunches', 'task', 'viewTaskTime_timer', 'elapsedTime3', 'checkpending', 'newId', 'showProximityFlag', 'source'],
    emits: ['openReviseTimeModal', 'clockOutFunc', 'clockInFunc'],
    components: {
        ClockOutNotify,
        modalDeleteComponent: ModalDelete2Vue,
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            showTimesheet: [],
            timesheetProximity: [],
            showMap: false,
            timesheets: [],
            proximityCount: 0,
            userTime: null,
            showClockoutNotify: false,
            showTempTaskDlt: false,
            selectedItem: null
        }
    },
    mounted() {
        this.getTimePunches();
    },
    computed: {
        ...mapGetters(['company']),
    },
    watch: {
        timePunches(newValue) {
            if (newValue) {
                this.timePunches = newValue;
                this.getTimePunches();
            }
        },
    },
    methods: {
        filteredMap(data) {
            return this.flag == 'flag' ? data.checkDataProximity : this.showMap;
        },
        mt_rand(min, max) {
            var argc = arguments.length;
            if (argc === 0) {
                min = 0;
                max = 2147483647;
            } else if (argc === 1) {
                throw new Error('Warning: mt_rand() expects exactly 2 parameters, 1 given');
            }
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        initMap(data) {
            data.timepunches.forEach((time, key1) => {
                let gmarkers = [];
                let marker1 = {};
                let bounds = new google.maps.LatLngBounds();
                let map = document.getElementById('map-custom-' + this.flag + '-' + time.id);
                let jobLat = parseFloat(data.task.job.latitude) ?? null;
                let jobLng = parseFloat(data.task.job.longitude) ?? null;
                let myLatlng = new google.maps.LatLng(jobLat, jobLng);
                let mapOptions = {
                    zoom: 18,
                    gestureHandling: 'greedy',
                    center: myLatlng,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                }

                bounds.extend(myLatlng);

                map = new google.maps.Map(map, mapOptions);

                time.markers.forEach((marker, key) => {
                    if (marker.position.lat && marker.position.lng) {
                        marker1 = new google.maps.Marker({
                            position: marker.position,
                            map: map,
                            animation: google.maps.Animation.DROP,
                            icon: marker.icon,
                        });

                        gmarkers.push(marker1);

                        new google.maps.Marker({
                            position: marker.position,
                            icon: {
                                path: google.maps.SymbolPath.CIRCLE,
                                scale: 40,
                                trokeColor: marker.color,
                                fillColor: marker.color,
                                strokeColor: marker.color,
                                strokeOpacity: 1,
                                strokeWeight: 1,
                                fillOpacity: 0.2,
                            },
                            map: map
                        });

                        bounds.extend(marker.position);
                    }
                });

                map.fitBounds(bounds);

                var listener = google.maps.event.addListener(map, "idle", function () {
                    google.maps.event.removeListener(listener);
                });

                new MarkerClusterer(map, gmarkers, {
                    maxZoom: 20,
                    imagePath:
                        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
                });
            });
        },
        getTimePunches() {
            let main = this;
            let checkExist = setInterval(function () {
                if (main.timePunches && main.timePunches.length) {
                    main.proximityCount = 0;
                    main.timePunches.forEach((data, index) => {
                        data.checkDataProximity = true;
                        data.timepunches.forEach((time, key) => {
                            let clockin = JSON.parse(time.login_location);
                            let clockout = JSON.parse(time.logout_location);
                            let jobLat = parseFloat(data.task.job.latitude) ?? null;
                            let jobLng = parseFloat(data.task.job.longitude) ?? null;
                            let random_num_lat = 0.00002;
                            let lat1 = clockin ? clockin.latitude : null;
                            let lng1 = clockin ? clockin.longitude : null;
                            let lat2 = clockout ? (clockout.latitude == 0 ? clockout.latitude : clockout.latitude + random_num_lat) : null;
                            let lng2 = clockout ? (clockout.longitude == 0 ? clockout.longitude : clockout.longitude + random_num_lat) : null;

                            time.markers = [
                                {
                                    position:
                                    {
                                        lat: jobLat,
                                        lng: jobLng
                                    },
                                    icon:
                                    {
                                        url: main.getMarkerIcon('rgba(94,114,228,1)'),
                                        size: { width: 30, height: 30, f: "px", b: "px" },
                                        scaledSize: { width: 30, height: 30, f: "px", b: "px" }
                                    },
                                    color: '#5e72e4'

                                },
                                {
                                    position:
                                    {
                                        lat: lat1,
                                        lng: lng1
                                    },
                                    icon:
                                    {
                                        url: main.getMarkerIcon('rgba(45,206,137,1)'),
                                        size: { width: 30, height: 30, f: "px", b: "px" },
                                        scaledSize: { width: 30, height: 30, f: "px", b: "px" }
                                    },
                                    color: '#2dce89'
                                },
                                {
                                    position:
                                    {
                                        lat: lat2,
                                        lng: lng2
                                    },
                                    icon: {
                                        url: main.getMarkerIcon('rgba(245,54,92,1)'),
                                        size: { width: 30, height: 30, f: "px", b: "px" },
                                        scaledSize: { width: 30, height: 30, f: "px", b: "px" }
                                    },
                                    color: '#f5365c'
                                },
                            ];
                            time.clockinProximity = time.is_resolved ? 1 : main.isWithinProximity(jobLat, jobLng, lat1, lng1, time, 'clockin');
                            time.clockoutProximity = time.is_resolved ? 1 : main.isWithinProximity(jobLat, jobLng, lat2, lng2, time, 'clockout');
                            time.isWithinProximity = (time.clockinProximity && time.clockoutProximity);

                            if (time.clockinProximity == 2 || time.clockoutProximity == 2)
                                time.isWithinProximity = 2;

                            if (!time.isWithinProximity) {
                                data.checkDataProximity = false;
                                main.proximityCount++;
                            }

                            time.mapStatus = time.time_in !== null && time.time_out !== null ? true : false;
                        });
                        data.timepunches = _.orderBy(data.timepunches, ['created_at'], ['desc']);
                    });
                    main.timesheets = main.timePunches;

                    if (main.flag == 'flag') {
                        if (main.proximityCount == 0) {
                            bus.$emit('showProximity', {
                                isShow: false,
                                count: main.proximityCount
                            });
                        } else {
                            bus.$emit('showProximity', {
                                isShow: true,
                                count: main.proximityCount
                            });
                        }
                    }

                    main.showMap = true;
                    clearInterval(checkExist);
                }
            }, 10);
        },
        isWithinProximity(lat1, lng1, lat2, lng2, data, logtype) {
            let temp = 0;
            if (lat1 && lng1 && lat2 && lng2) {
                temp = (google.maps.geometry.spherical.computeDistanceBetween(
                    new google.maps.LatLng(lat1, lng1),
                    new google.maps.LatLng(lat2, lng2)
                )) <= 100;

                return temp;

            } else {
                return 2;
            }
        },
        updateTimesheetProximity(data) {
            let params = {
                id: data.id,
                logtype: 'both',
                isWithinProximity: 1
            }
            axios.post('/update-timesheet-proximity', params)
                .then(response => {
                    bus.$emit('markResolved', data);
                })
                .catch(err => {
                    console.log("timesheet proximity error", err);
                });
        },
        toggleData(index, data) {
            this.initMap(data);
            let id = this.flag + '-' + this.task.id + '-' + index;
            if (data.timepunches.length) {
                if (this.showTimesheet[index]) {
                    $('#timesheet-' + id).hide();
                    $('#icon-list-' + id).removeClass('fa-chevron-up');
                    $('#icon-list-' + id).addClass(' fa-chevron-down');
                    $('#task-time-header-' + id).addClass(' bg-white');
                    $('#task-time-header-' + id).removeClass('bg-darkest-blue text-white');
                    $('#task-time-header-' + id).find('a').removeClass('text-white');
                    $('#task-assignee-header-' + id).removeClass('text-white');
                    $('#task-assignee-header-' + id + ' .assignee-name').removeClass('text-white');
                    $('#task-assignee-header-' + id + ' .assignee-manager').removeClass('text-white');
                } else {
                    $('#timesheet-' + id).removeClass('d-none');
                    $('#timesheet-' + id).show();
                    $('#icon-list-' + id).addClass(' fa-chevron-up');
                    $('#icon-list-' + id).removeClass('fa-chevron-down');
                    $('#task-time-header-' + id).removeClass('bg-white');
                    $('#task-time-header-' + id).addClass(' bg-darkest-blue text-white');
                    $('#task-time-header-' + id).find('a:not(.dropdown-item)').addClass('text-white');
                    $('#task-assignee-header-' + id).addClass(' text-white');
                    $('#task-assignee-header-' + id + ' .assignee-name').addClass(' text-white');
                    $('#task-assignee-header-' + id + ' .assignee-manager').addClass(' text-white');
                }
                this.showTimesheet[index] = !this.showTimesheet[index];
            }
        },
        toggleMap(index, idx, isShow, data) {
            let id = this.flag + '-' + this.task.id + '-' + index + '-' + idx;
            if (isShow) {
                this.initMap(data);
                $('#timesheet-entry-' + id + ' .map').show();
                $('#timesheet-entry-' + id + ' .hide-proximity-map').show();
                $('#timesheet-entry-' + id + ' .show-proximity-map').hide();
            } else {
                $('#timesheet-entry-' + id + ' .map').hide();
                $('#timesheet-entry-' + id + ' .hide-proximity-map').hide();
                $('#timesheet-entry-' + id + ' .show-proximity-map').show();
            }
        },
        toggleUserDropdown() {
            $('.user-dropdown-menu').dropdown("toggle");
        },
        getMarkerIcon(color) {
            return "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='240' height='240'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='" + color + "'/%3E%3C/svg%3E";
        },

        viewReviseTime(data, selected_date) {
            if (this.source) {
                this.$emit('openReviseTimeModal', data);
            } else {
                bus.$emit('viewReviseTime', { data: data, selected_date: selected_date });
            }
        },
        clockIn(task, data) {
            // Uses the parent or nested parent clock in method
            // instead of a global bus method to avoid event calling 
            // conflicts
            this.$emit('clockInFunc', { task: task, data: data });
        },
        closeClockoutNotify() {
            this.showClockoutNotify = false;
        },
        stopClockNotify(userTime) {
            // This function uses the modalClockOutNotify component 
            // to confirm clockout
            this.userTime = userTime;
            this.showClockoutNotify = true;
        },
        clockOut(data) {
            this.$emit('clockOutFunc', data)
        },

        launchMailTo(email) {
            location.href = ("mailto:" + email);
        },
        deleteNotify(data, show) {
            this.showTempTaskDlt = show;
            this.selectedItem = data;
        },
        removeFromTask() {
            let request = {
                task_id: this.selectedItem.task_id,
                user_id: this.selectedItem.assigned_to_user_id
            }
            this.$store.dispatch('taskData/deleteFromTask', request).then(response => {
                this.getTimePunches();
            });
        },
    },
}
</script>
