<template>
	<div>
		<!-- Task Details Modal-->
		<div class="modal" :id="newid ? 'viewJobNotesDetailsModal' + newid : 'viewJobNotesDetailsModal'" tabindex="-1" role="dialog" aria-labelledby="viewJobNotesDetailsModalTitle" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 1300px;">{{ jobNotesDetailsId }}
				<div class="modal-content" v-for="(data, index) in alljobs" :key="index" v-if="data.id === jobNotesDetailsId">
					<div class="modal-header border-bottom">
						<div class="col-3 col-xs-4 p-0">
							<div class="row align-items-center m-0">
								<div class="pl-3">
									<h3 class="mb-0">{{ data.job_name }}</h3>
									<h4 class="mb-0">{{ data.customer.name }}</h4>
								</div>
							</div>
						</div>
						<button type="button" class="close m-0 p-2" data-dismiss="modal" aria-label="Close">
							<i class="ri-close-line"></i>
						</button>
					</div>
					<div class="modal-body p-0">
						<div v-if="notask" class="card m-0">
							<div class="">
								<div class="row m-0">
									<div class="card-body p-2 pb-3 col-lg-2 col-12 border-right task-sidebar">
										<ul class="navbar-nav">
											<li class="nav-item" :class="{ active: 'materials' == taskSidebar[data.id] }" @click="setTaskSidebar(data, 'materials');">
												<span class="nav-link cursor-pointer2" href="#">
													<i class="ri-pencil-ruler-2-line"></i>
													<span class="nav-link-text">{{ $t('label.materials') }}</span>
												</span>
											</li>
											<li class="nav-item" :class="{ active: 'notes' == taskSidebar[data.id] }" @click="setTaskSidebar(data, 'notes');">
												<span class="nav-link cursor-pointer2" href="#">
													<i class="ri-draft-line"></i>
													<span class="nav-link-text">{{ $t('label.job notes') }}</span>
												</span>
											</li>
											<li class="nav-item" :class="{ active: 'files' == taskSidebar[data.id] }" @click="setTaskSidebar(data, 'files'); getTaskId(data.id)">
												<span class="nav-link cursor-pointer2" href="#">
													<i class="ri-folders-line"></i>
													<span class="nav-link-text">{{ $t('label.files') }}</span>
												</span>
											</li>
										</ul>
									</div>
									<div class="col-lg-10 col-12 p-0">
										<div class="card-body" v-if="taskSidebar[data.id] == 'materials'">
											<h5 class="modal-title mb-2">Job {{ $t('label.materials') }}</h5>
											<table class="table table-sm table-flush text-center">
												<thead class="thead-light">
													<tr>
														<th scope="col">{{ $t('label.location') }}</th>
														<th scope="col">{{ $t('label.material') }}</th>
														<th scope="col">{{ $t('label.unit') }}</th>
														<th scope="col">{{ $t('label.quantity') }}</th>
														<th scope="col">{{ $t('label.notes') }}</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(dt, idx) in taskMaterials[data.id]" :key="idx">
														<td>{{ dt.location }}</td>
														<td>{{ dt.material }}</td>
														<td>{{ dt.unit }}</td>
														<td>{{ dt.quantity }}</td>
														<td>{{ dt.notes }}</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div class="card-body" v-if="taskSidebar[data.id] == 'notes'">
											<!-- List group -->
											<div class="row align-items-center">
												<div class="col-8">
													<h3 class="modal-title mb-2">{{ $t('label.job notes') }}</h3>
												</div>
												<div class="col-4 text-right">
													<button type="button" class="btn btn-sm btn-primary mb-2" data-dismiss="modal" data-toggle="modal" :data-target="newid ? '#addJobNotesDetailsModal1' + newid : '#addJobNotesDetailsModal1'" @click="getJobId(data)">
														{{ $t('label.add note') }}
													</button>
												</div>
											</div>
											<div class="list-group list-group-flush">
												<div v-if="showLess">
													<div class="list-group-item list-group-item-action py-3 px-4" v-for="(item, index) in alljobnotes.slice(0, 5)" v-bind:key="index">
														<div class="row align-items-center justify-content-between m-0">
															<div class="row m-0 d-flex align-items-center">
																<div class="cursor-pointer mr-2" @click="getUserInfo(item.created_by.id, 'Created By')">
																	<img class="rounded-circle" :src="item.created_by.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
																</div>
																<h4 class="cursor-pointer m-0 mr-2" @click="getUserInfo(item.created_by.id, 'Created By')">{{ item.created_by.name }}</h4>
																<h4 class="font-weight-normal m-0 mr-2">{{ item.created_at | formatDateTime }}</h4>
															</div>
															<div class="row m-0">
																<h4 class="mr-3"><a href="#" class="text-primary font-weight-normal" data-toggle="modal" :data-target="newid ? '#editJobNotesDetailsModal1' + newid : '#editJobNotesDetailsModal1'" data-dismiss="modal" @click="editModal(data, item)">{{ $t('label.edit') }}</a></h4>
																<h4><a href="#" class="text-primary font-weight-normal" data-toggle="modal" :data-target="newid ? '#deleteModal2' + newid : '#deleteModal2'" data-dismiss="modal" @click="getJobId(data); deleteNotify(item.id)">{{ $t('label.delete') }}</a></h4>
															</div>
														</div>
														<p class="text-sm mb-0 mt-3 font-weight-normal pre-wrap" style="padding-left: 7px; margin-left: 38px;" v-html="item.notes"></p>
													</div>
												</div>
												<div v-else>
													<div class="list-group-item list-group-item-action py-3 px-4" v-for="(item, index) in alljobnotes" v-bind:key="index">
														<div class="row align-items-center justify-content-between m-0">
															<div class="row m-0 d-flex align-items-center">
																<div class="cursor-pointer mr-2" @click="getUserInfo(item.created_by.id, 'Created By')">
																	<img class="rounded-circle" :src="item.created_by.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
																</div>
																<h4 class="cursor-pointer m-0 mr-2" @click="getUserInfo(item.created_by.id, 'Created By')">{{ item.created_by.name }}</h4>
																<h4 class="font-weight-normal m-0 mr-2">{{ item.created_at | formatDateTime }}</h4>
															</div>
															<div class="row m-0">
																<h4 class="mr-3"><a href="#" class="text-primary font-weight-normal" data-toggle="modal" :data-target="newid ? '#editJobNotesDetailsModal1' + newid : '#editJobNotesDetailsModal1'" data-dismiss="modal" @click="editModal(data, item)">{{ $t('label.edit') }}</a></h4>
																<h4><a href="#" class="text-primary font-weight-normal" data-toggle="modal" :data-target="newid ? '#deleteModal2' + newid : '#deleteModal2'" data-dismiss="modal" @click="deleteNotify(item.id)">{{ $t('label.delete') }}</a></h4>
															</div>
														</div>
														<p class="text-sm mb-0 mt-3 font-weight-normal pre-wrap" style="padding-left: 7px; margin-left: 38px;" v-html="item.notes"></p>
													</div>
												</div>
												<button class="btn btn-white text-primary mr-auto ml-auto my-4 d-flex" v-if="alljobnotes.length > 5 && showLess" @click="showLess = false">
													{{ $t('label.view more') }}
												</button>
												<button class="btn btn-white text-primary mr-auto ml-auto my-4 d-flex" v-if="alljobnotes.length > 5 && !showLess" @click="showLess = true">
													{{ $t('label.view less') }}
												</button>
											</div>
										</div>
										<div class="card-body" v-if="taskSidebar[data.id] == 'files'">
											<job-files-task-details-component id="job-files" :hideFilesComponent="taskSidebar[data.id] != 'files'" :taskId="data.id" :jobid="data.id"></job-files-task-details-component>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer border-top">
						<button type="button" class="btn btn-primary mr-auto" data-dismiss="modal">{{ $t('label.close') }}</button>
					</div>
				</div>
			</div>
		</div>
		<!-- MODALS FOR CONFIRMATION -->

		<!-- MODALS -->
		<!-- <div class="modal" id="addJobNotesDetailsModal1" tabindex="-1" role="dialog" aria-labelledby="addJobNotesDetailsModal1Title" aria-hidden="true"> -->
		<div class="modal" :id="newid ? 'addJobNotesDetailsModal1' + newid : 'addJobNotesDetailsModal1'" tabindex="-1" role="dialog" aria-labelledby="addJobNotesDetailsModal1Title" aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header border-bottom">
						<h5 class="modal-title" id="addJobNotesDetailsModal1Title">{{ $t('label.add job notes') }}</h5>
						<button type="button" class="close" aria-label="Close" data-dismiss="modal" data-toggle="modal" :data-target="newid ? '#viewJobNotesDetailsModal' + newid : '#viewJobNotesDetailsModal'">
							<i class="ri-close-line"></i>
						</button>
					</div>
					<form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="saveJobNote">
						<div class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<!-- <textarea id="addJobNoteField1" v-model="jobNoteText" name="notes" class="form-control" :placeholder="$t('label.write a note') + '...'" rows="10" required autofocus></textarea> -->
										<label class="form-control-label text-muted" for="input-name">{{ $t('label.related task') }} (Optional)</label>
										<select v-model="relatedTask" class="form-control mb-2">
											<option value="" disabled>- {{ $t('label.select a task') }} -</option>
											<option v-for="(task, index) in tasksAll" :value="task.id" :key="index">{{ task.name }}</option>
										</select>
										<at-ta :members="allUsers" name-key="name">
											<template slot="item" slot-scope="s">
												<span v-html="s.item.name" @click="selectedName(s.item)"></span>
											</template>
											<!-- <div class="form-control editor" contenteditable @input="onInput"></div> -->
											<textarea v-model="jobNoteText" :id="newid ? 'addJobNoteField1' + newid : 'addJobNoteField1A'" name="notes" class="form-control" :placeholder="$t('label.write a note') + '...'" rows="10" required autofocus></textarea>
										</at-ta>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer border-top">
							<button type="submit" class="btn btn-primary mr-auto">{{ $t('label.add job note') }}</button>
							<button type="button" class="btn btn-link  ml-auto" data-dismiss="modal" data-toggle="modal" :data-target="newid ? '#viewJobNotesDetailsModal' + newid : '#viewJobNotesDetailsModal'">{{ $t('label.close') }}</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<!-- EDIT Modal -->
		<!-- <div class="modal fade" id="editJobNotesDetailsModal1" tabindex="-1" role="dialog" aria-labelledby="editJobNotesDetailsModal1Title" aria-hidden="true"> -->
		<div class="modal fade" :id="newid ? 'editJobNotesDetailsModal1' + newid : 'editJobNotesDetailsModal1'" tabindex="-1" role="dialog" aria-labelledby="editJobNotesDetailsModal1Title" aria-hidden="true">
			<div class="modal-dialog modal-lg" role="document">
				<div class="modal-content">
					<div class="modal-header border-bottom">
						<h5 class="modal-title" id="editJobNotesDetailsModal1Title">{{ $t('label.edit job notes') }}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close" data-toggle="modal" :data-target="newid ? '#viewJobNotesDetailsModal' + newid : '#viewJobNotesDetailsModal'">
							<i class="ri-close-line"></i>
						</button>
					</div>
					<form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="updateJobNote">
						<div class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<!-- <textarea id="addJobNoteFieldEdit1" rows="10" v-model="form.edit.notes" name="notes" class="form-control" :placeholder="$t('label.write a note') + '...'" required autofocus></textarea> -->
										<label class="form-control-label text-muted" for="input-name">{{ $t('label.related task') }} (Optional)</label>
										<select v-model="form.edit.relatedTask" class="form-control mb-2">
											<option value="" disabled>- {{ $t('label.select a task') }} -</option>
											<option v-for="(task, index) in tasksAll" :value="task.id" :key="index">{{ task.name }}</option>
										</select>
										<at-ta :members="allUsers" name-key="name">
											<template slot="item" slot-scope="s">
												<span v-html="s.item.name" @click="selectedName(s.item)"></span>
											</template>
											<!-- <div class="form-control editor" contenteditable @input="onInput"></div> -->
											<textarea v-model="form.edit.notes" :id="newid ? 'addJobNoteFieldEdit1' + newid : 'addJobNoteFieldEdit1A'" name="notes" class="form-control" :placeholder="$t('label.write a note') + '...'" rows="10" required autofocus></textarea>
										</at-ta>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer border-top">
							<button type="submit" class="btn btn-primary mr-auto">{{ $t('label.update job note') }}</button>
							<button type="button" class="btn btn-link  ml-auto close2editnote" data-dismiss="modal" aria-label="Close" data-toggle="modal" :data-target="newid ? '#viewJobNotesDetailsModal' + newid : '#viewJobNotesDetailsModal'">{{ $t('label.close') }}</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<!-- MODAL FOR DELETE -->
		<!-- <div class="modal fade" id="deleteModal2" tabindex="-1" role="dialog" aria-labelledby="deleteModal2" aria-hidden="true"> -->
		<div class="modal fade" :id="newid ? 'deleteModal2' + newid : 'deleteModal2'" tabindex="-1" role="dialog" aria-labelledby="deleteModal2" aria-hidden="true">
			<div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
				<div class="modal-content bg-gradient-danger">
					<div class="modal-header">
						<button type="button" class="close" data-dismiss="modal" aria-label="Close" data-toggle="modal" :data-target="newid ? '#viewJobNotesDetailsModal' + newid : '#viewJobNotesDetailsModal'">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="deleteJobNote">
						<div class="modal-body">
							<div class="py-3 text-center">
								<i class="ri-error-warning-line ni-3x"></i>
								<h4 class="heading mt-4 text-uppercase">{{ $t('label.ARE YOU SURE YOU WANT TO DELETE THIS NOTE') }}?</h4>
								<p>{{ $t('label.This action cannot be undone') }}!</p>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-white">{{ $t('label.Yes, Delete') }}</button>
							<button type="button" class="btn btn-link text-white ml-auto" data-dismiss="modal" aria-label="Close" data-toggle="modal" :data-target="newid ? '#viewJobNotesDetailsModal' + newid : '#viewJobNotesDetailsModal'">{{ $t('label.cancel') }}</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import bus from '@/utils/event-bus';
import At from 'vue-at';
import AtTa from 'vue-at/dist/vue-at-textarea';
import { mapGetters } from 'vuex';

export default {
	components: {
		At,
		AtTa
	},
	props: ['openEditJobNotes', 'current', 'selectedDate', 'viewtype', 'getUpdatedDated', 'getAllTasksByType', 'newid', 'notask', 'jobNotesDetailsId'],
	data() {
		return {
			// From Day component
			alljobs: [],
			taskdata: null,
			delete_id: null,
			jobid: 0,
			jobNoteText: '',
			allUsers: [],
			taskSidebar: [],
			sidebarData: [],
			sidebarValue: 'notes',
			showLess: true,

			form: {
				add: {
					notes: null
				},
				edit: {
					notes: null,
					id: null
				}
			},
			selectedNames: [],
			relatedTask: null,
			tasksAll: [],
			taskMaterials: []
		}
	},
	watch: {
		openEditJobNotes() {
			this.getTasks();
		},
	},
	computed: {
		...mapGetters(['alljobnotes', 'company', 'coordinates']),
	},
	created() {
		this.getUsers();
	},
	mounted() {
		let main = this;
		$('#addJobNotesDetailsModal1, #editJobNotesDetailsModal1').on('shown.bs.modal', function () {
			let checkExist = setInterval(function () {
				if ($('#addJobNoteField1').length || $('#addJobNoteFieldEdit1').length) {
					$('#addJobNoteField1').focus();
					$('#addJobNoteFieldEdit1').focus();
					clearInterval(checkExist);
				}
			}, 10);
		});

	},
	methods: {
		getUsers() {
			axios.get('/get-all-users').then(response => {
				this.allUsers = response.data.result;
			})
				.catch(err => console.error(err));
		},
		getTasks() {
			let customerid = null;
			axios.get('/get-all-jobs/' + customerid)
				.then(response => {
					this.alljobs = response.data.result;
					console.log('alljobs', this.alljobs)

					for (var i = 0; i < this.alljobs.length; i++) {
						if (this.jobNotesDetailsId == this.alljobs[i].id) {
							console.log('dataaaaaaaa')
							this.setTaskSidebar(this.alljobs[i], this.sidebarValue);
						}
					}
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		viewMaterials(data) {
			console.log('data', data)
			if (data.material_source == 1) this.getJobMaterials(data)
			if (data.material_source == 2) this.getTaskMaterials(data)
		},
		viewNotes(data) {
			this.getJobNotes(data);
		},
		getTaskMaterials(data) {
			axios.get('/get-task-materials/' + data.id)
				.then(response => {
					// this.taskMaterials[data.id] = response.data.result;
					this.$set(this.taskMaterials, data.id, response.data.result);
				})
				.catch(err => console.error(err));
		},
		getJobMaterials(data) {
			axios.get('/get-job-materials/' + data.id)
				.then(response => {
					// this.taskMaterials[data.id] = response.data.result;
					this.$set(this.taskMaterials, data.id, response.data.result);
				})
				.catch(err => console.error(err));
		},
		getJobNotes(data) {
			this.$store.dispatch('getJobNotes', data.id);
		},
		getJobId(data) {
			console.log('data', data)
			this.taskdata = data;
			this.jobid = data.id;
		},
		getTaskId(id) {
			bus.$emit('getTaskId', id);
		},
		setTaskSidebar(data, val) {
			this.sidebarData = data;
			this.sidebarValue = val;
			this.showLess = true;

			console.log('this.sidebarData', this.sidebarData)
			console.log('this.sidebarValue', this.sidebarValue)
			if (val == 'crew') {
				this.viewTaskTime(data);
				this.viewAllJobTime(data);
			}
			if (val == 'materials') {
				// this.viewMaterials(data);
				this.getJobMaterials(data)
			}
			if (val == 'notes') {
				this.viewNotes(data);
				this.getTasksAll(data.id);
			}
			this.$set(this.taskSidebar, data.id, val)
		},
		showTable(task_id) {
			$('#showRecord_' + task_id).toggle();

			if ($('#arrow_' + task_id).hasClass('fa-chevron-down')) {
				$('#arrow_' + task_id).removeClass('fa-chevron-down').addClass('fa-chevron-up');
			} else {
				$('#arrow_' + task_id).removeClass('fa-chevron-up').addClass('fa-chevron-down');
			}
		},
		editModal(data, item) {
			this.getJobId(data);

			this.form.edit.notes = item.notes.replace(/<\/?[^>]+>/ig, "");
			this.form.edit.id = item.id;
			this.form.edit.relatedTask = item.task_id;
			this.selectedNames = item.tagged_users;
			console.log('this.form.edit.notes', this.form.edit.notes)
		},
		saveJobNote() {
			let noteID = this.newid ? '#addJobNoteField1' + this.newid : '#addJobNoteField1A';
			let tempText = $(noteID).val();
			this.jobNoteText = tempText;

			this.allUsers.forEach((data, key) => {
				if (tempText.includes(data.name)) {
					this.selectedName(data);
				}
			})

			var request_fields = {
				'notes': this.jobNoteText,
				// 'job_id': this.jobid,
				'job_id': this.jobNotesDetailsId,
				'selected_names': this.selectedNames,
				'task_id': this.relatedTask
			};

			axios.post('/jobnotes', request_fields)
				.then(response => {
					this.getJobNotes(this.taskdata);
					this.jobNoteText = '';
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});

			let url = window.location.pathname.split("/");
			if ((url[1] == 'job' && url[3] == 'edit') || url[1] == 'calendar' || url[1] == 'dashboard') {
				let addjobnotesID = this.newid ? '#addJobNotesDetailsModal1' + this.newid : '#addJobNotesDetailsModal1';
				let viewtaskdetailsID = this.newid ? '#viewJobNotesDetailsModal' + this.newid : '#viewJobNotesDetailsModal';
				$(addjobnotesID).modal('hide');
				$(viewtaskdetailsID).modal('show');
			}
		},
		updateJobNote() {
			let noteID = this.newid ? '#addJobNoteFieldEdit1' + this.newid : '#addJobNoteFieldEdit1A';
			let tempText = $(noteID).val();
			this.form.edit.notes = tempText;

			this.allUsers.forEach((data, key) => {
				if (tempText.includes(data.name)) {
					this.selectedName(data);
				}
			})

			var request_fields = {
				'notes': this.form.edit.notes,
				'job_id': this.jobid,
				'id': this.form.edit.id,
				'selected_names': this.selectedNames,
				'task_id': this.form.edit.relatedTask
			};
			axios.post('/jobnotes/update', request_fields)
				.then(response => {
					this.getJobNotes(this.taskdata);
				})
				.catch(err => {
					console.log('list carriers error:', err);
				});
			let url = window.location.pathname.split("/");
			if ((url[1] == 'job' && url[3] == 'edit') || url[1] == 'calendar' || url[1] == 'dashboard') {
				let editjobnotesID = this.newid ? '#editJobNotesDetailsModal1' + this.newid : '#editJobNotesDetailsModal1';
				let viewtaskdetailsID = this.newid ? '#viewJobNotesDetailsModal' + this.newid : '#viewJobNotesDetailsModal';
				$(editjobnotesID).modal('hide');
				$(viewtaskdetailsID).modal('show');
			}
			// document.querySelector('.close2editnote').click();
		},
		deleteNotify(delete_id) {
			this.delete_id = delete_id;
			// $("#deleteModal2").modal('show');
		},
		deleteJobNote() {
			axios.post('/delete-job-note/' + this.delete_id)
				.then(response => {
					this.getJobNotes(this.taskdata);
					let deletemodal2ID = this.newid ? "#deleteModal2" + this.newid : "#deleteModal2";
					$(deletemodal2ID).modal('hide');
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		selectedName(data) {
			console.log('data', data)
			if (this.findArray(data.id) === null) {
				this.selectedNames.push(data);
			}
			console.log('this.selectedNames', this.selectedNames)
		},
		findArray(value) {
			let countLayer = this.selectedNames.length;
			if (countLayer > 0) {
				for (var x = 0; x < countLayer; x++) {
					if (this.selectedNames[x].id === value) {
						return this.selectedNames[x];
					}
				}
			}
			return null;
		},
		getTasksAll(job_id) {
			axios.get('/tasks/' + job_id)
				.then(response => {
					console.log('response', response)
					this.tasksAll = response.data.result;
					this.tasksAll.push({ id: 0, name: 'N/A', sequence: 0 });
					this.tasksAll = _.orderBy(this.tasksAll, ['sequence'], ['asc']);
					console.log('this.tasksAll', this.tasksAll)
				})
				.catch(err => console.error("API ERROR", err));
		},
	},
}
</script>
