<template>
    <div>
        <div class="modal" id="messageAddModal" tabindex="-1" role="dialog" aria-labelledby="messageAddModalTitle" aria-hidden="true">
            <div class="modal-dialog" role="document" style="max-width: 1000px">
                <div class="modal-content">
                    <div class="modal-header border-bottom">
                        <h5 v-if="messages.form.type == 'add'" class="modal-title" id="messageAddModalTitle">{{ $t('label.create message') }}: {{ messages.type | formatMsgType }}</h5>
                        <h5 v-else class="modal-title" id="messageAddModalTitle">{{ $t('label.update message') }}: {{ messages.type | formatMsgType }}</h5>
                        <button v-if="messages.messageId" type="button" class="close" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#messageStatsModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <button v-else-if="messages.form.type == 'add'" type="button" class="close" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#selectMsgTypeModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <button v-else type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="saveMessage(messages.form.type)">
                        <div class="modal-body">
                            <div class="form-group message">
                                <div>{{ $t('label.When do you want to post a message') }}?</div>
                                <div class="row m-0 py-3">
                                    <div class="custom-control custom-radio mb-3 mr-5">
                                        <input @change="changePostType" v-model="messages.form.selection.showScheduleOption" :value="0" name="custom-radio-1" class="custom-control-input" id="customRadio1" type="radio" checked>
                                        <label class="custom-control-label" for="customRadio1">{{ $t('label.immediately') }}</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-3">
                                        <input @change="changePostType" v-model="messages.form.selection.showScheduleOption" :value="1" name="custom-radio-1" class="custom-control-input" id="customRadio2" type="radio">
                                        <label class="custom-control-label" for="customRadio2">{{ $t('label.schedule') }}</label>
                                    </div>
                                </div>
                                <div v-if="messages.form.selection.showScheduleOption" class="row m-0 align-items-center" id="schedule-time">
                                    <div class="form-group mr-3">
                                        <label for="scheduled_date" class="font-weight-bold">{{ $t('label.post date') }}</label>
                                        <input v-on:blur="changeDate" v-model="messages.form.date" id="scheduled_date" class="form-control datepicker" :placeholder="'- ' + $t('label.select a date') + ' -'" type="text">
                                    </div>
                                    <div class="form-group mx-3">
                                        <label for="schedule_hr" class="font-weight-bold">{{ $t('label.post time') }} ({{ $t('label.hr') }})</label>
                                        <select class="form-control" v-model="messages.form.hour">
                                            <option v-for="(hour, index) in 12" :key="index" :value="hour">{{ hour }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group mx-3">
                                        <label for="schedule_min" class="font-weight-bold">{{ $t('label.post time') }} ({{ $t('label.minute') }})</label>
                                        <select class="form-control" v-model="messages.form.min">
                                            <option value="0">00</option>
                                            <option v-for="min in 50" :key="min" v-if="min % 10 == 0">{{ min }}</option>
                                        </select>
                                    </div>
                                    <div class="custom-control custom-radio mx-3">
                                        <input v-model="messages.form.am" name="radio-time" class="custom-control-input" id="am" value="am" type="radio" checked="">
                                        <label class="custom-control-label" for="am">AM</label>
                                    </div>
                                    <div class="custom-control custom-radio mx-3">
                                        <input v-model="messages.form.am" name="radio-time" class="custom-control-input" id="pm" value="pm" type="radio">
                                        <label class="custom-control-label" for="pm">PM</label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="messages.type == 'email' || messages.type == 'text' || messages.type == 'banner' || messages.type == 'snippet'" class="form-group message">
                                <div v-if="messages.type != 'text'" class="form-group p-0 col-md-7 col-sm-12">
                                    <label for="msg-title" class="font-weight-bold">{{ $t('label.message title') }}</label>
                                    <input v-model="messages.form.title" type="text" class="form-control" id="msgTitle" :placeholder="$t('label.Create a message title')" required />
                                </div>
                                <div class="form-group">
                                    <label for="msg-body" class="font-weight-bold">{{ $t('label.message body') }}</label>
                                    <textarea v-on:keydown="getRemainingChar" v-on:keyup="getRemainingChar" v-model="messages.form.body" type="text" rows="7" maxlength="1000" class="form-control mb-2" id="msgBody" :placeholder="$t('label.Create a message body')" required></textarea>
                                    <span id="remainingChar"></span>
                                </div>
                            </div>
                            <div v-if="messages.type == 'prompt' || messages.type == 'popup'" class="form-group message">
                                <div class="row">
                                    <div class="col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label for="msg-title" class="font-weight-bold">{{ $t('label.message title') }}</label>
                                            <input v-model="messages.form.title" type="text" class="form-control" id="msgTitle" :placeholder="$t('label.Create a message title')" required />
                                        </div>
                                        <div class="form-group">
                                            <label for="msg-body" class="font-weight-bold">{{ $t('label.message body') }}</label>
                                            <textarea v-on:keydown="getRemainingChar" v-on:keyup="getRemainingChar" v-model="messages.form.body" type="text" rows="9" maxlength="1000" class="form-control mb-2" id="msgBody" :placeholder="$t('label.Create a message body')" required></textarea>
                                            <span id="remainingChar"></span>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6 col-sm-12 dropzone-msg">
                                        <label for="msg-body" class="font-weight-bold">{{ $t('label.message image') }} ({{ $t('label.optional') }})</label>
                                        <div v-if="messages.form.showDropzone || messages.form.image == null">
                                            <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions" v-on:vdropzone-processing="inProgressFileUpload" v-on:vdropzone-file-added="getAddedFile" v-on:vdropzone-success="getUploadedFile">
                                            </vue-dropzone>
                                        </div>
                                        <div v-else class="text-center" id="dropzone-image">
                                            <div class="dz-image">
                                                <img width="100%" alt="activities-2.jpg" :src="messages.form.image">
                                            </div>
                                        </div>
                                        <div v-if="hasImage || messages.form.image" class="text-center">
                                            <a class="btn btn-sm btn-danger m-2 text-white text-center" @click="removeImage(true)">
                                                <i class="ri-delete-bin-line text-white"></i>{{ $t('label.remove image') }}
                                            </a>
                                        </div>
                                        <label style="font-size: small; padding-top: 10px">{{ $t('label.You can display one image to display on the message') }}.</label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="messages.type == 'email'" class="form-group message">
                                <div>{{ $t('label.Do you want to include an action button at the bottom of the email') }}?</div>
                                <div class="row m-0 py-3">
                                    <div class="custom-control custom-checkbox mb-3">
                                        <input v-model="messages.form.selection.showActionBtn" value="link" class="custom-control-input" id="showActionBtn" type="checkbox">
                                        <label class="custom-control-label" for="showActionBtn">{{ $t('label.show action button') }}</label>
                                    </div>
                                    <div v-if="messages.form.selection.showActionBtn" class="row ml-2">
                                        <div class="form-group px-4">
                                            <label for="btn-label" class="font-weight-bold">{{ $t('label.message button') }}</label>
                                            <input v-model="messages.form.btnLabel" type="text" class="form-control" id="btnLabel" :placeholder="'i.e.' + $t('label.Okay, got it') + '!'" />
                                            <small class="form-text text-muted">{{ $t('label.This is what the message button will read') }}.</small>
                                        </div>
                                        <div class="form-group">
                                            <label for="btn-url" class="font-weight-bold">{{ $t('label.button url') }}</label>
                                            <input v-model="messages.form.btnUrl" type="text" class="form-control" id="btnUrl" placeholder="i.e. https://google.com" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="messages.type != 'email' && messages.type != 'text'" class="form-group message">
                                <div class="row action-btn m-0 py-3">
                                    <div class="form-group pr-4">
                                        <label for="btn-label" class="font-weight-bold">{{ $t('label.message button') }}</label>
                                        <input v-model="messages.form.btnLabel" type="text" class="form-control" id="btnLabel" :placeholder="'i.e.' + $t('label.Okay, got it') + '!'" />
                                        <small class="form-text text-muted">{{ $t('label.This is what the message button will read') }}.</small>
                                    </div>
                                    <div class="form-group pr-4">
                                        <label for="btn-url" class="font-weight-bold">{{ $t('label.button action') }}</label>
                                        <select v-model="messages.form.btnAction" class="form-control">
                                            <option value="close" selected>{{ $t('label.close') }}</option>
                                            <option value="link">{{ $t('label.open url') }}</option>
                                        </select>
                                    </div>
                                    <div v-if="messages.form.btnAction == 'link'" class="form-group btn-url">
                                        <label for="btn-url" class="font-weight-bold">{{ $t('label.button url') }}</label>
                                        <input v-model="messages.form.btnUrl" type="text" class="form-control" id="btnUrl" placeholder="i.e. https://google.com" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group border-0 m-0">
                                <div>{{ $t('label.Who do you want to receive this message') }}?</div>
                                <div class="row m-0 py-3">
                                    <div class="custom-control custom-radio mb-3 mr-5">
                                        <input v-model="messages.form.selection.showEmployeeList" :value="0" name="custom-radio-2" class="custom-control-input" id="customRadioAllEmployees" type="radio">
                                        <label class="custom-control-label" for="customRadioAllEmployees">{{ $t('label.all employees') }}</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-3">
                                        <input v-model="messages.form.selection.showEmployeeList" :value="1" name="custom-radio-2" class="custom-control-input" id="customRadioSelectEmployees" checked="" type="radio">
                                        <label class="custom-control-label" for="customRadioSelectEmployees">{{ $t('label.select employees') }}</label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="messages.form.selection.showEmployeeList" class="row m-0 msg-recipient">
                                <div v-for="(role, index) in messages.recipients" :key="index" class="col-sm form-group pl-0">
                                    <label class="font-weight-bold">{{ role.label }}</label>
                                    <div class="pt-2">
                                        <div v-for="(employee, idx) in role.data" :key="idx" class="custom-control custom-checkbox mb-3 d-flex
                                                                align-items-center" v-if="(role.label == 'Company Roles' && employee.users && employee.users.length) || role.label != 'Company Roles'">
                                            <input v-model="messages.form.recipients" class="custom-control-input" :value="role.name + '-' + employee.id" :id="'recipient-' + index + '-' + idx" type="checkbox">
                                            <label class="custom-control-label d-flex align-items-center mb-3" :for="'recipient-' + index + '-' + idx">
                                                <a href="#" class="avatar avatar-sm rounded-circle avatar-single mr-2">
                                                    <img v-if="index == 'employees'" alt="Image placeholder" class="avatar avatar-single" :src="employee.picture">
                                                    <a v-if="index == 'roles' || index == 'crews'" href="#" class="avatar avatar-single avatar-all-employees avatar-sm rounded-circle">
                                                        <img v-if="index == 'roles' && employee.id == 2" alt="Image placeholder" src="/images/icons/admin-line.png">
                                                        <img v-if="index == 'roles' && employee.id != 2" alt="Image placeholder" src="/images/icons/group-line.png">
                                                        <img v-if="index == 'crews'" alt="Image placeholder" src="/images/icons/group-2-line.png">
                                                    </a>
                                                </a>
                                                <span>{{ employee.name }}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer border-top d-flex justify-content-between">
                            <div>
                                <button id="btn-schedule-message" v-if="messages.form.type == 'add' && messages.form.selection.showScheduleOption == 1" type="submit" class="btn btn-primary mr-3">{{ $t('label.schedule message') }}</button>
                                <button id="btn-schedule-message" v-else-if="messages.form.type == 'add' && messages.form.selection.showScheduleOption == 0" type="submit" class="btn btn-primary mr-3">{{ $t('label.send message') }}</button>
                                <button id="btn-update-message" v-else type="submit" class="btn btn-primary mr-3">{{ $t('label.update message') }}</button>
                                <button v-if="messages.type == 'email'" type="button" class="btn btn-secondary footer-btn" :disabled="this.messages.form.body == null || this.messages.form.body == '' || this.messages.form.title == null || this.messages.form.title == '' ? true : false" @click="sendTestEmail">{{ $t('label.send test email') }}</button>
                                <button v-else-if="messages.type == 'text'" type="button" class="btn btn-secondary footer-btn" data-toggle="modal" data-target="#exampleModal" :disabled="this.messages.form.body == null || this.messages.form.body == '' ? true : false" @click="sendTestTextMessage">{{ $t('label.send test sms message') }}</button>
                                <button id="btn-preview-message" v-else type="button" class="btn btn-secondary footer-btn" data-toggle="modal" data-target="#messagePreviewModal" data-dismiss="modal" :disabled="this.messages.form.body == null || this.messages.form.body == '' || this.messages.form.title == null || this.messages.form.title == '' ? true : false" @click="previewMessage(true); $emit('remove');">{{ $t('label.preview message') }}</button>
                            </div>
                            <button v-if="messages.messageId" type="button" class="btn btn-link text-primary" data-dismiss="modal" data-toggle="modal" data-target="#messageStatsModal">{{ $t('label.close') }}</button>
                            <button v-else-if="messages.form.type == 'add'" type="button" class="btn btn-link text-primary" data-dismiss="modal" data-toggle="modal" data-target="#selectMsgTypeModal">{{ $t('label.close') }}</button>
                            <button v-else type="button" class="btn btn-link text-primary" data-dismiss="modal">{{ $t('label.close') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <message-preview-component></message-preview-component>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { mapGetters } from 'vuex';
;

export default {
    components: {
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                maxFiles: 1,
                maxFilesize: 10,
                addRemoveLinks: true,
                acceptedFiles: 'image/*',
                dictDefaultMessage: `
                        <div>
                            <span>Drag and drop files here</span><br>
                            <span>OR</span><br>
                            <button type="button" class="btn btn-default">
                                Browse Files
                            </button>
                        </div>
                    `,
            },
            hasImage: true,
        }
    },
    computed: {
        ...mapGetters(['messages']),
    },
    created() {
        this.getAllCrews();
        this.getAllUserRoles();
        this.getAllEmployees();
    },
    mounted() {
        $("#messageAddModal").on('shown.bs.modal', function () {
            $('#remainingChar').hide();
        });
    },
    methods: {
        getAllCrews() {
            this.$store.dispatch('getAllCrews');
        },
        getAllUserRoles() {
            this.$store.dispatch('getAllUserRoles');
        },
        getAllEmployees() {
            this.$store.dispatch('getAllEmployees');
        },
        previewMessage(isPreview) {
            this.$store.dispatch('previewMessage', isPreview);
        },
        getUploadedFile(file, response) {
            this.$store.dispatch('getUploadedFile', file);
            $('#btn-schedule-message').attr('disabled', false);
            $('#btn-update-message').attr('disabled', false);
            $('#btn-preview-message').attr('disabled', false);
        },
        saveMessage(formType) {
            let { date, hour, min, am, file, recipients, selection } = this.messages.form;
            let day = moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');
            let time = moment(hour.toString().padStart(2, 0) + ':' + min.toString().padStart(2, 0) + ' ' + am, 'hh:mm a').format('HH:mm:ss');
            this.messages.form.postDate = moment.tz(day + ' ' + time, this.user.timezone).utc().format('YYYY-MM-DD HH:mm:ss');

            if (!selection.showEmployeeList) {
                recipients = this.getAllRecipientEmployees();
            }

            if (formType == 'add') {
                this.$store.dispatch('saveMessage', { ...this.messages, recipients, file });
                this.$store.dispatch('getAllMessages');
            } else {
                this.$store.dispatch('updateMessage', { ...this.messages, file });
                this.$store.dispatch('getAllMessages');
            }

            $('#messageAddModal').modal('hide');
        },
        getAllRecipientEmployees() {
            let recipients = [];
            this.messages.recipients.employees.data.forEach((data) => {
                recipients.push('employees-' + data.id);
            });

            return recipients;
        },
        sendTestTextMessage() {
            this.loader = this.$loading.show();
            axios.post('/send-message', {
                body: this.messages.form.body,
            })
                .then(response => {
                    this.loader.hide();
                    this.$bus.$emit('banner-success', { message: response.data.message });
                })
                .catch(error => {
                    this.loader.hide();
                    console.log('Send test SMS message API:', error)
                    this.$bus.$emit('banner-error', { message: error.data.message });
                });
        },
        sendTestEmail() {
            this.loader = this.$loading.show();

            const { title, body, btnAction, btnLabel, btnUrl, selection } = this.messages.form;
            let request = {
                title: title,
                body: body,
                btn_label: btnLabel,
                btn_link: btnUrl,
                button: selection.showActionBtn,
                is_test_email: true,
            }

            axios.post('/sendmail', request)
                .then(response => {
                    // this.isSent = true;
                    this.loader.hide();
                    this.$bus.$emit('banner-success', { message: response.data.message });
                })
                .catch(err => {
                    this.loader.hide();
                    this.$bus.$emit('banner-error', { message: err.data.message });
                })
                .finally(res => {
                    this.loader.hide();
                });
        },
        removeImage(payload) {
            this.$store.dispatch('showDropzone', payload);
            // this.$store.dispatch('removeImage');
            if (this.messages.form.file) {
                this.$refs.myVueDropzone.removeAllFiles();
                // this.$store.dispatch('removeFile');
            }
            this.hasImage = false;
        },
        getAddedFile(file) {
            this.hasImage = true;
        },
        inProgressFileUpload(file) {
            $('#btn-schedule-message').attr('disabled', true);
            $('#btn-update-message').attr('disabled', true);
            $('#btn-preview-message').attr('disabled', true);
        },
        changeDate() {
            this.messages.form.date = $('.datepicker').val();
        },
        changePostType() {
            setTimeout(function () {
                $('.datepicker').datepicker({
                    startDate: '-0m'
                }, 10);
            });
        },
        getRemainingChar() {
            $('#remainingChar').show();
            let maxlength = 1000;
            let { body } = this.messages.form;
            let charCount = (body == null) ? 0 : body.length;

            if (charCount > maxlength) {
                return false;
            }

            $("#remainingChar").html((maxlength - charCount) + " characters remaining");
        },
    }
}
</script>
<style>
.dropzone .dz-preview .dz-error-message {
    opacity: 1 !important;
    top: 0 !important;
}
</style>