<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50" id="timesheetSettingsContainer">
            <!-- Page Header -->
            <div class="d-flex" style="z-index: 100">
                <!-- Breadcrumbs -->
                <breadcrumbs :title="$t('label.task settings')">
                    <!-- Breakcrumb slots -->
                    <li class="breadcrumb-item">
                        {{ $t('label.administrative') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.settings') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.tasks') }}
                    </li>
                </breadcrumbs>
            </div>

            <!-- Page Body -->
            <div class="card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h3 class="mb-0">{{ $t('label.task settings') }}</h3>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <h3 class="mb-0">{{ $t('label.use custom task fields') }}</h3>
                    <!-- Use Task Custom Fields -->
                    <div class="form-group">
                        <div class="row m-0 py-1">
                            <div class="custom-control custom-radio mr-3">
                                <input name="custom_task_fields" v-model="form.custom_task_fields" class="custom-control-input" value="1" id="customTaskFieldsYes" type="radio">
                                <label class="custom-control-label" for="customTaskFieldsYes">{{ $t('label.yes') }}</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input name="custom_task_fields" v-model="form.custom_task_fields" class="custom-control-input" value="0" id="customTaskFieldsNo" type="radio">
                                <label class="custom-control-label" for="customTaskFieldsNo">{{ $t('label.no') }}</label>
                            </div>
                        </div>
                    </div>
                    <hr>

                    <!-- Use Task Materials -->
                    <h3 class="mb-0">{{ $t('label.use task materials') }}</h3>
                    <div class="form-group">
                        <div class="row m-0 py-1">
                            <div class="custom-control custom-radio mr-3">
                                <input name="task_materials" v-model="form.task_materials" class="custom-control-input" value="1" id="taskMaterialsYes" type="radio">
                                <label class="custom-control-label" for="taskMaterialsYes">{{ $t('label.yes') }}</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input name="task_materials" v-model="form.task_materials" class="custom-control-input" value="0" id="taskMaterialsNo" type="radio">
                                <label class="custom-control-label" for="taskMaterialsNo">{{ $t('label.no') }}</label>
                            </div>
                        </div>
                        <span class="text-sm">
                            {{ $t('label.add job materials or specific task materials to each task') }}
                        </span>
                    </div>
                    <!-- <hr> -->

                    <!-- Use Task Adjust Dates -->
                    <!-- <h3 class="mb-0">{{ $t('label.prompt to automatically adjust tasks dates') }}</h3>
                    <div class="form-group">
                        <div class="row m-0 py-1">
                            <div class="custom-control custom-radio mr-3">
                                <input name="adjust_task_dates" v-model="form.adjust_task_dates" class="custom-control-input" value="1" id="adjustDate" type="radio">
                                <label class="custom-control-label" for="adjustDate">{{ $t('label.yes') }}</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input name="adjust_task_dates" v-model="form.adjust_task_dates" class="custom-control-input" value="0" id="dontAdjustDate" type="radio">
                                <label class="custom-control-label" for="dontAdjustDate">{{ $t('label.no') }}</label>
                            </div>
                        </div>
                        <span class="text-sm">
                            {{ $t('prompt users to automatically adjust the start dates for remaining tasks on jobs when a preceding task due date is adjusted') }}
                        </span>
                    </div> -->
                </div>
            </div>
            <!-- Save Footer -->
            <div class="text-center">
                <button type="button" @click="saveSettings()" class="btn btn-primary">{{ $t('label.save settings') }}</button>
            </div>

        </div>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex';

export default {
    props: [],
    components: {},
    data() {
        return {
            loader: null,
            form: {
                task_materials: null,
                // adjust_task_dates: null,
                custom_task_fields: null
            }
        }
    },
    computed: {
        ...mapGetters(['company']),
    },
    created() {
        this.loader = this.$loading.show();
        this.$store.dispatch('getCompanySettings').then(() => {
            this.initForm();
            this.loader.hide();
        });
    },
    mounted() { },
    methods: {
        initForm() {
            // Set form values
            // this.form.adjust_task_dates = this.company.adjust_task_dates;
            for (const key in this.company.settings)
                if (key in this.form)
                    this.form[key] = this.company.settings[key];
        },

        saveSettings() {
            let taskSettings = JSON.parse(JSON.stringify(this.form));
            taskSettings.company_id = this.company.id;

            axios.post('/settings', taskSettings).then(response => {
                bus.$emit('banner-success', {
                    message: this.$t('label.company settings saved successfully')
                });
            }).catch(err => console.log(err));
        }
    }
}
</script>