<template>
    <div>
        <div class="page-invoice-report">
            <div class="header bg-primary pb-6 mobile-p-0">
                <div class="container-fluid">
                    <div class="header-body">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-7">
                                <breadcrumbs :title="$t('label.purchase orders')">
                                    <!-- Breakcrumb slots -->
                                    <li class="breadcrumb-item">
                                        {{ $t('label.purchase orders') }}
                                    </li>
                                </breadcrumbs>
                            </div>
                            <div class="col-lg-6 col-5 text-right">
                                <router-link class="text-primary" :to="{ name: 'purchaseOrderCreate', params: { allVendorsList: this.allVendors, jobid: -1 } }">
                                    <button type="button" class="btn btn-neutral">
                                        {{ $t('label.new purchase order') | uppercase }}
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Report-->
            <div class="container-fluid mt--6 mobile-mt-140p" id="invoiceHeader">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <div class="card-header">
                                <div class="row align-items-center">
                                    <div class="w-100 d-flex align-items-center">
                                        <i class="ri-bill-line"></i>
                                        <h3 class="mb-0">{{ $t('label.purchase orders') }}</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body" v-if="showSearchBox">
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="form-group">
                                            <div class="input-group input-group-alternative input-group-merge search-contacts">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i class="fas fa-search"></i></span>
                                                </div>
                                                <input name="search_order" class="form-control" :placeholder="$t('label.search purchase orders by job')" type="text" v-model="filterBy.search" autocomplete="off" data-lpignore="true">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="form-group">
                                            <div class="dropdown w-100">
                                                <div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <div class="input-group input-group-alternative input-group-merge search-contacts">
                                                        <input name="allvendors" class="form-control form-control-dropdown pointer" :placeholder="$t('label.all statuses')" autocomplete="input-field" data-lpignore="true" readonly>
                                                        <div class="input-group-append">
                                                            <span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100" @click.stop="">
                                                    <a v-for="(d, i) in allPurchaseOrderStatus" :key="'user-' + i" class="dropdown-item text-default">
                                                        <label class="form-control-label font-weight-normal m-0 w-100 pointer" :for="'filter-' + d.id">
                                                            <input class="form-control-input" :id="'filter-' + d.id" type="checkbox" :value="d.id" v-model="filterBy.status_ids">
                                                            {{ d.name }}
                                                        </label>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="form-group">
                                            <div class="dropdown w-100">
                                                <div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <div class="input-group input-group-alternative input-group-merge search-contacts">
                                                        <input name="allvendors" class="form-control form-control-dropdown pointer" :placeholder="$t('label.all vendors')" autocomplete="input-field" data-lpignore="true" readonly>
                                                        <div class="input-group-append">
                                                            <span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100" @click.stop="">
                                                    <a v-for="(d, i) in allVendors" :key="'user-' + i" class="dropdown-item text-default">
                                                        <label class="form-control-label font-weight-normal m-0 w-100 pointer" :for="'filter-' + d.id">
                                                            <input class="form-control-input" :id="'filter-' + d.id" type="checkbox" :value="d.id" v-model="filterBy.vendor_ids">
                                                            {{ d.vendor_name }}
                                                        </label>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-5 text-left col-xs-12">
                                        <button type="button" class="btn btn-primary text-uppercase" @click="getPurchaseOrders(); searchBox();">
                                            {{ $t('label.search purchase orders') }}
                                        </button>
                                        <button type="button" class="btn btn-link ml-auto" @click="clearSearch()">
                                            {{ $t('label.clear search') }}
                                        </button>
                                    </div>
                                    <div class="col-7 text-right col-xs-12 d-block">
                                        <export-excel type="csv" 
                                        name="SupplyOrderExport.csv" 
                                        worksheet="Timesheet Report" 
                                        class="btn btn-secondary btn-sm" 
                                        :data="noPaginationPO" 
                                        :fields="purchase_order_header">
                                            <i class="ri-file-excel-line"></i>
                                            {{ $t('label.export results') }}
                                        </export-excel>
                                        <!-- TODO Sync QB -->
                                        <button type="button" class="btn btn-secondary btn-sm" @click="syncPurchaseOrder2QB()">
                                            <i class="ri-refresh-line"></i>Sync QuickBooks Online
                                        </button>
                                    </div>  
                                </div>
                                <div class="dropdown-divider"></div>
                            </div>
                            <div class="card-header" v-if="!showSearchBox">
                                <div class="row d-flex justify-content-between align-items-center">
                                    <div>
                                        <h3 class="mb-0">{{ $t('label.search results') }} ({{ this.purchaseOrderCount }})</h3>
                                    </div>
                                    <div class="row">
                                        <div class="col-auto">
                                            <a href="#" class="btn btn-sm btn-default" @click="searchBox()">{{ $t('label.revise search') }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive pb-4" v-if="clickSearch">
                                <table class="table table-flush table-sm">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col" style="width: 8.5rem">SO #</th>
                                            <th scope="col">Quickbooks Bill No.</th>
                                            <th scope="col">{{ $t('label.vendor') }}</th>
                                            <th scope="col">{{ $t('label.job') }}</th>
                                            <th scope="col" style="width: 10rem">{{ $t('label.amount') }}</th>
                                            <th scope="col" style="width: 10rem">{{ $t('label.created') }}</th>
                                            <th scope="col" style="width: 12rem">{{ $t('label.status') }}</th>
                                            <th scope="col" style="width: 11.5rem"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- Loop Tasks -->
                                        <tr v-for="(data, index) in paginatedAllPurchaseOrders" :key="index" class="pointer">
                                            <!-- PO # -->
                                            <td @click="routeToPOView(data)">
                                                <div class="task-name search-task">
                                                    {{ 'SO-' + data.purchase_order_number.purchase_order_number }}
                                                </div>
                                            </td>
                                            <td><a v-if="data.qb_bill_id" target="_blank" :href="qb_url+'app/bill?txnId='+data.qb_bill_id">{{ data.quickbooks_bill_no }}</a></td>
                                            <!-- Vendor -->
                                            <td @click="routeToVendorView(data)">
                                                <div class="job-name search-task">{{ data.vendor.vendor_name }}</div>
                                            </td>
                                            <!-- Job -->
                                            <td>
                                                <div class="text-link" v-if="data.job" @click="editExistingJob(data.job.job_number.job_number)">{{ data.job.job_name }}</div>
                                            </td>
                                            <!-- Amount -->
                                            <td>
                                                <div class="job-name search-task">${{ parseFloat(data.amount).toFixed(2) | formatThousands }}</div>
                                            </td>
                                            <!-- Created Date -->
                                            <td>
                                                <div class="job-name search-task">{{ data.created_at | formatDateWithTz }}</div>
                                            </td>
                                            <!-- Status -->
                                            <td>
                                                <span class="badge badge-dot search-task">
                                                    <i class="bg-success" :style="'background-color:' + data.purchase_order_status.color + ' !important'"></i> {{ data.purchase_order_status.name }}
                                                </span>
                                            </td>
                                            <!-- menu dropdown -->
                                            <td class="text-right">
                                                <div v-if="data.has_notes" class="soIcons" aria-haspopup="true" aria-expanded="false">
                                                    <i class="ri-message-2-line heading-title p-1"></i>
                                                </div>
                                                <div v-if="data.has_files" class="soIcons"  aria-haspopup="true" aria-expanded="false">
                                                    <i class="ri-folders-line heading-title p-1"></i>
                                                </div>
                                                <a class="btn dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                                                    <i class="ri-more-2-fill heading-title p-1"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" aria-labelledby="taskItemMenu">
                                                    <!-- Edit PO only on draft -->
                                                    <router-link v-if="data.purchase_order_status.name == 'Draft'" class="dropdown-item text-default pointer" :to="{ name: 'purchaseOrderEdit', params: { id: data.id } }">
                                                        <i class="ri-pencil-line"></i> {{ $t('label.edit') }}
                                                    </router-link>
                                                    <!-- View PO -->
                                                    <router-link class="dropdown-item text-default pointer" :to="{ name: 'purchaseOrderView', params: { id: data.id } }">
                                                        <i class="ri-eye-line"></i> {{ $t('label.view purchase order') }}
                                                    </router-link>
                                                    <!-- View PDF -->
                                                    <a class="dropdown-item text-default pointer" @click="showModalPurchaseOrderView(data)">
                                                        <i class="ri-eye-line"></i> {{ $t('label.view pdf') }}
                                                    </a>
                                                    <!-- Send only on draft and send -->
                                                    <a class="dropdown-item text-default pointer" v-if="['Draft', 'Sent'].indexOf(data.purchase_order_status.name) !== -1" @click="showModalPurchaseOrderSend(data)">
                                                        <i class="ri-mail-send-line"></i> {{ $t('label.send') }}
                                                    </a>
                                                    <!-- Download -->
                                                    <a @click="$downloadStream(`/get-po-pdf-download/${data.id}`, getPdfDownloadName(data))" class="dropdown-item text-default pointer">
                                                        <i class="ri-download-line"></i> {{ $t('label.download') }}
                                                    </a>
                                                    <!-- Print -->
                                                    <a @click="$openStreamBlank(`/get-po-pdf-file/${data.id}`), getPdfDownloadName(data)" class="dropdown-item text-default pointer">
                                                        <i class="ri-printer-line"></i> {{ $t('label.print') }}
                                                    </a>
                                                    <!-- Delete Draft -->
                                                    <div v-if="data.purchase_order_status.name == 'Draft'">
                                                        <div class="dropdown-divider mx-3"></div>
                                                        <a class="dropdown-item text-danger pointer d-flex align-items-center" @click="setSelectedPurchaseOrder(data); toggleDeleteModal(true)">
                                                            <i class="ri-close-circle-line"></i>{{ $t('label.delete purchase order') }}
                                                        </a>
                                                    </div>
                                                    <!-- Cancel Sent PO -->
                                                    <div v-if="data.purchase_order_status.name == 'Sent'">
                                                        <div class="dropdown-divider mx-3"></div>
                                                        <a class="dropdown-item text-danger pointer d-flex align-items-center" @click="setSelectedPurchaseOrder(data); toggleSendCancelModal()">
                                                            <i class="ri-close-circle-line"></i>{{ $t('label.cancel purchase order') }}
                                                        </a>
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <TableServerPagination v-if="paginatedAllPurchaseOrders" v-model="filterBy.current_page" :btn-limit="5" :btn-size="'sm'" :total-records="purchaseOrderCount" :per-page="filterBy.rows_per_page" :num-rows="paginatedAllPurchaseOrders.length" @onChange="getPurchaseOrders()">
                                </TableServerPagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Purchase Order View Modal -->
        <modal class="py-4" name="modalPurchaseOrderView" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
            <ModalPurchaseOrderView :purchase-order="purchaseOrderData" :selected-vendor-contact="selectedVendorContact" @reload="loadLkupData" @cancelFnc="hidePOView"></ModalPurchaseOrderView>
        </modal>

        <!-- Purchase Order Send Modal -->
        <modal class="py-4" name="modalPurchaseOrderSend" height="auto" width="100%" :max-width="900" :scrollable="true" :reset="true" :adaptive="true">
            <ModalPurchaseOrderSend :purchase-order="purchaseOrderData" :send-type="poSendType" :subject-text="subjectText" @cancelFnc="$modal.hide('modalPurchaseOrderSend');" @updateStatus="updateStatus"></ModalPurchaseOrderSend>
        </modal>


        <!-- Purchase Order Cancel -->
        <modal-delete-component v-if="showDltPOModal" :newId="'PurchaseOrder_view'" :title="isPoDraft ? $t('label.ARE YOU SURE YOU WANT TO DELETE THIS PURCHASE ORDER') : $t('label.ARE YOU SURE YOU WANT TO CANCEL THIS PURCHASE ORDER')" :subtitle="cancelPoSubtitle" :btnText="isPoDraft ? $t('label.Yes, Delete Purchase Order') : $t('label.Yes, Cancel Purchase Order')" @onClose="toggleDeleteModal()" @customDeleteMethod="isPoDraft ? deletePo() : sendCancelEmail()">
        </modal-delete-component>
         <fade-transition origin="center" :duration="250" tag="div">
                <CustomLoader v-if="qbSyncing" :loaderText="loaderText" />
         </fade-transition>

    </div>
</template>

<script>
import ModalDeleteComponent from '@/components/ModalDelete2.vue';
import ModalPurchaseOrderSend from '@/components/ModalPurchaseOrderSend.vue';
import ModalPurchaseOrderView from '@/components/ModalPurchaseOrderView.vue';
import TableServerPagination from '@/components/TableServerPagination.vue';
import bus from '@/utils/event-bus';
import CustomLoader from './tabs/loader.vue';


export default {
    props: [''],
    emits: [''],
    components: {
        TableServerPagination,
        ModalPurchaseOrderView,
        ModalPurchaseOrderSend,
        ModalDeleteComponent,
        CustomLoader
    },
    data() {
        return {
            allVendors: [],
            purchaseOrders: [],
            noPaginationPO: [],
            purchaseOrderData: [],
            selectedPurchaseOrder: {},
            selectedVendorContact: [],
            allPurchaseOrderStatus: [],
            paginatedAllPurchaseOrders: [],
            filterBy: {
                search: '',
                status: null,
                status_ids: [],
                vendor_ids: [],
                rows_per_page: 20,
                current_page: 1,
            },
            loader: null,
            perPage: 10,
            purchaseOrderCount: 0,
            clickSearch: false,
            showSearchBox: true,
            showDltPOModal: false,
            poSendType: '',
            subjectText: '',
            cancelStatus: 'Draft',
            cancelSubtitle: this.$t('label.This cannot be undone You will be able to send a message to the supplier on the next step'),
            purchase_order_header: {
                'Supply Order #': 'order_number',
                'VENDOR': {
                    field: 'vendor.vendor_name',
                    callback: (value) => {
                        return value;
                    }
                },
                'JOB': {
                    field: 'job.job_name',
                    callback: (value) => {
                        return value;
                    }
                },
                'AMOUNT': {
                    field: 'amount',
                    callback: (value) => {
                    return '$' + parseFloat(value).toFixed(2);
                    }
                },
                'CREATED AT': {
                    field: 'created_at',
                    callback: (value) => {
                    return moment(value).format('MM/DD/YYYY hh:mm a');
                    }
                },
                'STATUS': {
                    field: 'purchase_order_status.name',
                    callback: (value) => {
                        return value;
                    }
                },
            },
            vendorsNotSynced: [],
            qbSyncing: false,
            loaderText:'',
            qb_url:process.env.VUE_APP_QUICKBOOKS_URL
        }

    },
    created() {

    },
    computed: {
        isPoDraft() {
            return this.selectedPurchaseOrder.purchase_order_status.name == "Draft" ? true : false;
        },
        cancelPoSubtitle() {
            if (!this.isPoDraft)
                return this.$t('label.This cannot be undone You will be able to send a message to the supplier on the next step');
            else
                return ' ';
        }
    },
    mounted() {
        this.loadLkupData();
    },
    methods: {
        loadLkupData() {
            this.loader = this.$loading.show();
            let promises = [
                this.getPurchaseOrders(),
                this.getPurchaseOrderStatus(),
                this.getAllVendors(),
            ];
            // Hide the loader overlay after all lookup data is loaded
            Promise.all(promises).then(() => {
                this.loader.hide();
            });
        },
        getPurchaseOrders() {
            this.clickSearch = true;
            this.purchaseOrders = [];
            this.paginatedAllPurchaseOrders = [];
            let params = {
                filters: this.filterBy
            }
            axios.post('/get-purchase-orders', this.filterBy)
                .then(res => {
                    this.paginatedAllPurchaseOrders = res.data.result;
                    this.noPaginationPO = res.data.noPaginationPO;
                    this.purchaseOrderCount = res.data.totalCount;
                    this.vendorsNotSynced = res.data.vendorsNotSynced;
                })
                .catch(err => console.log(err));
        },
        getPurchaseOrderStatus() {
            axios.get('/get-all-purchase-order-status')
                .then(res => {
                    this.allPurchaseOrderStatus = res.data.result;
                })
                .catch(err => console.log(err));
        },
        async getAllVendors() {
            await axios.get('/get-vendors')
                .then(res => {
                    this.allVendors = res.data.result;
                })
                .catch(err => console.log(err));
        },
        searchBox() {
            this.showSearchBox = !this.showSearchBox;
        },
        routeToPOView(data) {
            this.$router.push({ name: 'purchaseOrderView', params: { id: data.id } });
        },
        routeToVendorView(data) {
            this.$router.push({ name: 'vendordetails', params: { id: data.vendor_id, vendorInfo: data.vendor } })
        },
        showModalPurchaseOrderView(item = null) {
            this.purchaseOrderData = item;
            for (var i = 0; i < item.vendor.vendor_contacts.length; i++) {
                if (item.vendor.vendor_contacts[i].id == item.vendor_contact_id) {
                    this.selectedVendorContact = item.vendor.vendor_contacts[i];
                }
            }
            this.$modal.show('modalPurchaseOrderView');
        },
        hideModalPurchaseOrderView() {
            this.$modal.hide('modalPurchaseOrderView');
        },
        showModalPurchaseOrderSend(item = null) {
            this.purchaseOrderData = item;
            this.subjectText = '';
            this.poSendType = 'send';
            this.$modal.show('modalPurchaseOrderSend');
        },
        hideModalPurchaseOrderSend() {
            this.$modal.hide('modalPurchaseOrderSend');
        },
        editExistingJob(jobNumber) {
            this.$router.push({ path: `jobs/edit/${jobNumber}` });
        },
        setSelectedPurchaseOrder(item) {
            this.selectedPurchaseOrder = item;
            this.purchaseOrderData = this.selectedPurchaseOrder;
        },
        toggleDeleteModal(show) {
            this.showDltPOModal = show
        },
        getPdfDownloadName(po) {
            let name = po.purchase_order_status.name == 'Cancelled' ? 'CANCELLED_' : '';
            return name + "SO-" + po.purchase_order_number.purchase_order_number + "_" +  moment().format('YYYY_MM_DD') + ".pdf";
        },
        toggleSendCancelModal() {
            this.poSendType = 'cancel'
            this.subjectText = 'CANCELLED';
            this.$modal.show('modalPurchaseOrderSend');
        },
        sendCancelEmail() {
            this.subjectText = 'CANCELLED';
            this.$modal.show('modalPurchaseOrderSend');
        },
        deletePo() {
            let reqData = {
                id: this.selectedPurchaseOrder.id,
                status: this.selectedPurchaseOrder.purchase_order_status.name,
            };

            axios.post('/delete-purchase-order', reqData).then(res => {
                this.getPurchaseOrders();
                bus.$emit('banner-success', {
                    message: this.$t('label.Purchase Order Draft deleted successfully') + '!'
                });
            });
        },
        updateStatus() {
            this.getPurchaseOrders();
            this.$modal.hide('modalPurchaseOrderSend');
        },
        clearSearch() {
            this.filterBy.search = '';
            this.filterBy.status_ids = [];
            this.filterBy.vendor_ids = [];

            this.getPurchaseOrders();
        },
        hidePOView() {
            this.$modal.hide('modalPurchaseOrderView');
            this.getPurchaseOrders();
        },
        // TODO
        async syncPurchaseOrder2QB() {
            console.log("vendor:",this.vendorsNotSynced);
          
            var response;
            let vendorsNotSynced = this.vendorsNotSynced;
            this.qbSyncing = true;
            try {

                this.loaderText = "Syncing Supply order Items master to Quickbooks Online...";
                response = await axios.get('/quickbooks/sync-items');

                this.loaderText = "Syncing vendors to Quickbooks Online...";
                response = await axios.post('/quickbooks/sync-vendor');
                
                this.loaderText = "Syncing supply orders to Quickbooks Online...";
                response = await axios.post('/quickbooks/sync-supply-order');

                this.loadLkupData();

                bus.$emit('banner-success', { message: response.data.message });
            } catch (error) {
                console.log('ERROR: ', error);
                bus.$emit('banner-error', { message: error.data.message });
            } finally {
                this.qbSyncing = false;
            }
        },
    },
}
</script>
<style scoped>
.soIcons {
    padding: 0;
    color: #ced4da !important;
    border-color: transparent;
    background-color: transparent;
    text-align: center;
    display: inline-block;
    margin-right: 0.5rem;
    cursor: auto;
}
</style>