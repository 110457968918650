<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>
        <div class="container-fluid pb-5 sp-mobile-mt-50" id="jobTypesContainer">
            <!-- HEADER -->
            <div class="d-flex">
                <!-- Breadcrumbs -->
                <breadcrumbs :title="'Payroll'">
                    <!-- Breakcrumb slots -->
                    <!-- <li class="breadcrumb-item">
                        <router-link class="text-primary pointer" :to="{ name: 'reports' }">{{ $t('label.reports') }}</router-link>
                    </li>
                    <li class="breadcrumb-item">
                        Payroll Report
                    </li> -->
                </breadcrumbs>
            </div>
            <!-- Content  -->
            <div class="card">
                <div class="card-header">
                    <div class="row d-flex align-items-center">
                        <div class="col-4">
                            <h3>W2 Employee Payroll Report</h3>
                        </div>
                        <div class="col-2 text-right">
                            <router-link class="btn btn-outline-primary btn-sm" :to="{ name: 'payroll1099Report' }">
                                View 1099 Payroll
                            </router-link>
                        </div>
                        <div class="col-6 text-right">
                            <span v-if="hasData == 2">
                                <button v-if="!selectedPayPeriod.is_closed" type="button" class="btn btn-outline-primary btn-sm" @click="changePayPeriodStatus(1)">
                                    Close Pay Period
                                </button>
                                <button v-else type="button" class="btn btn-outline-primary btn-sm" @click="changePayPeriodStatus(0)">
                                    Reopen Pay Period
                                </button>
                            </span>
                            <span v-if="hasData == 2 && selectedPayPeriod.is_closed" class="pl-2">
                                <export-excel type="csv" 
                                name="payrollExport.csv" 
                                worksheet="Timesheet Report" 
                                class="btn btn-secondary btn-sm" 
                                :data="payrollExportData" 
                                :fields="payrollExportHeader">
                                    <i class="ri-file-excel-line"></i>
                                    {{ $t('label.export results') }}
                                </export-excel>
                                <!-- TODO Sync QB -->
                                <button type="button" class="btn btn-secondary btn-sm" @click="syncPayroll2QB()">
                                    <i class="ri-refresh-line"></i>Sync QuickBooks Online
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="card-body">

                    <!-- Search Bar -->
                    <div class="row form-group">
                        <!-- left Column -->
                        <div class="col-sm-4">
                            <div class="row">
                                <div class="col">
                                    <label>Select Month</label>
                                    <select class="form-control" 
                                    @change="loadPayPeriods" 
                                    v-model="payPeriodFilter.month" required>
                                        <option v-for="month in $spGetMonths()" 
                                        :value="month.id" 
                                        :key="month.id">{{ month.name }}</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <label>Select Year</label>
                                    <select class="form-control" 
                                    @change="loadPayPeriods()" 
                                    v-model="payPeriodFilter.year" required>
                                        <option v-for="year in $spGetYears()" 
                                        :value="year.id" 
                                        :key="year.id">{{ year.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <!-- Right Column -->
                        <div class="col-sm-8">
                            <div class="row">
                                <!-- Pay Period`` -->
                                <div class="col-sm-5">
                                    <label>Select Pay Period</label>
                                    <select class="form-control" @change="setPayPeriod()" v-model="selectedPayPeriod" placeholder="Pay Period">
                                        <option v-for="payPeriod in payPeriods" :value="payPeriod" :key="payPeriod.id">
                                            {{ payPeriod.start_date | formatDate }} - {{ payPeriod.end_date | formatDate }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-sm-4">
                                    <label for="">User</label>
                                    <select class="form-control" v-model="search.userId" @change="setPayPeriod()">
                                        <option :value="null">All Users</option>
                                        <option v-for="user in users" :value="user.id" :key="user.id">{{ user.name }}</option>
                                    </select>
                                </div>
                                <div class="col-sm-3 text-center">
                                    <label for="">Pay Period Type</label>
                                    <div class="text-blue">
                                        {{ companySettings.pay_period_type | capitalize }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center text-primary" v-if="hasData == 1">
                        <h3>No data to display for selected pay period.</h3>
                    </div>

                    <div v-if="hasData == 2">
                        <!-- Report Data -->
                        <ul class="list-group" v-if="hasData == 2">
                            <!-- loop user list                     -->
                            <li class="list-group-item py-0" v-for="user in payrollUsers" :key="user.id">
                                <div class="row bg-gray-600 py-2 text-white">
                                    <div class="col text-bold">{{ user.name }}</div>
                                    <div class="col text-right">Regular</div>
                                    <div class="col text-right">Overtime</div>
                                    <div class="col text-right">Total Amount</div>
                                </div>
                                <!-- Loop each week in the pay period -->
                                <div class="row border-bottom" v-for="(week, idx) in user.payroll" :key="idx">
                                    <div class="col text-center pt-4">
                                        <!-- date range -->
                                        <span class="pointer text-primary" @click="showDailybyWeek(user, week)">
                                            <i class="ri-link"></i>{{ week.dateRange.startDate | formatDate('M/DD') }} - {{ week.dateRange.endDate | formatDate('M/DD') }}
                                        </span>
                                    </div>
                                    <div class="col text-sm text-right">
                                        <div><span class="text-xs">Hours: </span> {{ week.weekDuration | formatTimeWithSec }}</div>
                                        <div>
                                            <span class="text-xs">Hourly Rate: </span>
                                            <!-- <span class="text-muted text-xs">
                                                (Approx)
                                            </span> -->
                                            <span>
                                                {{ week.weekHourlyRate | toCurrency }}
                                            </span>
                                        </div>
                                        <div><span class="text-xs">Amount: </span>{{ week.weekEarning | toCurrency }}</div>
                                    </div>
                                    <div class="col text-right">
                                        <div v-if="week.weekOvertimeDuration">
                                            <div><span class="text-xs">Hours: </span>{{ week.weekOvertimeDuration | formatTimeWithSec }}</div>
                                            <div><span class="text-xs">Hourly Rate: </span>{{ week.weekOvertimeRate | toCurrency }}</div>
                                            <div><span class="text-xs">Amount: </span>{{ week.weekOvertimeEarning | toCurrency }}</div>
                                        </div>
                                    </div>
                                    <div class="col text-right pt-4">{{ (week.weekEarning + week.weekOvertimeEarning) | toCurrency }}</div>
                                </div>
                                <!-- Display user pay period grand totals -->
                                <div class="row text-sm text-right bg-gray-100 py-2">
                                    <div class="col-sm-12">
                                        <div class="row">
                                            <div class="col">Total Regular Hours</div>
                                            <div class="col">Total Regular Amount</div>
                                            <div class="col">Total Overtime Hours</div>
                                            <div class="col">Total Overtime Amount</div>
                                            <div class="col">Pay Period Amount</div>
                                        </div>
                                        <div class="row text-bold">
                                            <div class="col">{{ user.payPeriodTotals.payPeriodRegularDuration | formatTimeWithSec }}</div>
                                            <div class="col">{{ user.payPeriodTotals.payPeriodRegularEarnings | toCurrency }}</div>
                                            <div class="col">{{ user.payPeriodTotals.payPeriodOvertimeDuration | formatTimeWithSec }}</div>
                                            <div class="col">{{ user.payPeriodTotals.payPeriodOvertimeEarnings | toCurrency }}</div>
                                            <div class="col">{{ user.payPeriodTotals.payPeriodTotalEarnings | toCurrency }}</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <!-- Report Grand Totals -->
                        <div class="card mt-2" v-if="this.payrollUsers.length" id="grandtotal">
                            <div class="card-header bg-gray-300 text-white">Pay Period Grand Totals</div>
                            <div class="card-body bg-gray-600 text-white text-right">
                                <div class="row text-sm">
                                    <div class="col">Total Regular Hours</div>
                                    <div class="col">Total Regular Amount</div>
                                    <div class="col">Total Overtime Hours</div>
                                    <div class="col">Total Overtime Amount</div>
                                    <div class="col">Pay Period Amount</div>
                                </div>
                                <div class="row text-bold">
                                    <div class="col">{{ reportTotals.reportTotalRegularDuration | formatTimeWithSec }}</div>
                                    <div class="col">{{ reportTotals.reportTotalRegularAmount | toCurrency }}</div>
                                    <div class="col">{{ reportTotals.reportTotalOvertimeDuration | formatTimeWithSec }}</div>
                                    <div class="col">{{ reportTotals.reportTotalOvertimeAmount | toCurrency }}</div>
                                    <div class="col">{{ reportTotals.reportTotalAmount | toCurrency }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <modal name="showDailybyWeek" height="auto" width="98%" :reset="true" :scrollable="true" :adaptive="true">
            <payroll-user-week-component :selected-week="selectedWeek"></payroll-user-week-component>
        </modal> -->
        <fade-transition origin="center" :duration="250" tag="div">
            <CustomLoader v-if="qbSyncing" :loaderText="loaderText" />
        </fade-transition>
    </div>
</template>
<script>
import PayrollUserWeekComponent from '@/components/Reports/PayrollUserWeekComponent'
import bus from '@/utils/event-bus'
import CustomLoader from './tabs/loader.vue';

export default {
    components: {
        PayrollUserWeekComponent,
        CustomLoader
    },
    data() {
        return {
            companySettings: JSON.parse(localStorage.getItem('company_settings')).settings,
            user: JSON.parse(localStorage.getItem('user')),
            payPeriodFilter: {
                year: 2023,
                month: moment().add(1, 'month').month(),
            },
            search: {
                userId: null,
                startDate: null,
                endDate: null,
            },
            selectedPayPeriod: { is_closed: 0 },
            users: [],
            payPeriods: [],
            payrollUsers: [],
            reportTotals: [],
            loader: null,
            selectedWeek: {},
            hasData: 0,
            payrollExportData: [],
            payrollExportHeader: {
                'USER': 'user_name',
                'WEEK': 'date_range',
                'REGULAR TIME': 'regular_time',
                'REGULAR RATE': 'regular_rate',
                'REGULAR PAY': 'regular_pay',
                'OVERTIME': 'overtime',
                'OVERTIME RATE': 'overtime_rate',
                'OVERTIME PAY': 'overtime_pay',
                'WEEKLY TOTAL TIME': 'total_time',
                'WEEKLY TOTAL PAY': 'total_pay',
            },
            allUsersNotSynced: [],
            allCustomersNotSynced: [],
            usersInTaskList: [],
            qbSyncing: false,
            loaderText:''
        }
    },
    created() {
        bus.$on('reviseTimeModalUpdate', this.getSearchData)
        bus.$on('payrollAdjustPieceRateAllocations_Update', this.getSearchData)
    },
    beforeDestroy() {
        bus.$off('reviseTimeModalUpdate', this.getSearchData)
        bus.$off('payrollAdjustPieceRateAllocations_Update', this.getSearchData)
    },
    mounted() {
        this.loader = this.$loading.show()
        this.loadW2Users()
        this.loadPayPeriods()
    },
    methods: {
        loadW2Users() {
            axios.get('/loadW2Users').then((response) => {
                this.users = response.data
                this.loader.hide()
            })
        },        
        loadPayPeriods() {
            axios.post('/getPayPeriodsByMonth', this.payPeriodFilter).then((response) => {
                this.payPeriods = response.data
                if (this.payPeriods.length) {
                    this.selectedPayPeriod = this.payPeriods[0]
                    this.setPayPeriod()
                    this.loader.hide()
                }
                else {
                    this.hasData = 1
                }
            })
        },
        setPayPeriod() {
            this.payrollExportData = []
            this.payrollUsers = []
            this.reportTotals = []
            this.search.startDate = this.selectedPayPeriod.start_date
            this.search.endDate = this.selectedPayPeriod.end_date
            this.getSearchData()
        },
        getSearchData() {
            axios
                .post('/loadPayrollPayPeriodData', this.search)
                .then(response => {
                    this.payrollUsers = response.data.users
                    this.reportTotals = response.data.reportTotals
                    this.allUsersNotSynced=response.data.allUsersNotSynced
                    this.allCustomersNotSynced=response.data.allCustomersNotSynced
                    this.usersInTaskList = response.data.usersInTaskList
                    if (this.payrollUsers.length > 0) {
                        this.hasData = 2
                        // build csv data
                        this.payrollUsers.forEach(user => {
                            user.payroll.forEach(week => {
                                this.payrollExportData.push(
                                    {
                                        user_name: user.name,
                                        date_range: Vue.filter('formatDate')(week.dateRange.startDate, 'MM/DD/YYYY') + '-' + Vue.filter('formatDate')(week.dateRange.endDate, 'MM/DD/YYYY'),
                                        regular_time: (week.weekDuration / 3600).toFixed(2),
                                        regular_rate: (week.weekDuration) ? (week.weekEarning / (week.weekDuration / 3600)).toFixed(2) : 0,
                                        regular_pay: week.weekEarning.toFixed(2),
                                        overtime: (week.weekOvertimeDuration / 3600).toFixed(2),
                                        overtime_rate: week.weekOvertimeRate.toFixed(2),
                                        overtime_pay: week.weekOvertimeEarning.toFixed(2),
                                        total_time: ((week.weekDuration / 3600) + (week.weekOvertimeDuration / 3600)).toFixed(2),
                                        total_pay: (week.weekEarning + week.weekOvertimeEarning).toFixed(2),
                                    }
                                )
                            })
                        })
                    } else {
                        this.hasData = 1
                    }
                })
                .catch(err => {
                    console.log('ERROR: ', err)
                    bus.$emit('banner-error', { message: err, })
                })
        },
        showDailybyWeek(user, week) {
            user.payType = 'W2'
            this.selectedWeek = { user: user, week: week }
            // this.$modal.show('showDailybyWeek')
            this.$modal.show(
                PayrollUserWeekComponent,
                { selectedWeek: this.selectedWeek },
                {
                    height: "auto",
                    width: "98%",
                    // maxWidth: 1024,
                    reset: true,
                    scrollable: true,
                    adaptive: true
                }
            )
        },
        changePayPeriodStatus(status) {
            let statusName = 'Reopened'
            if (status == 1)
                statusName = 'Closed'

            axios
                .post('/changePayPeriodStatus', { periodId: this.selectedPayPeriod.id, status: status })
                .then(response => {
                    this.selectedPayPeriod.is_closed = status
                    this.getSearchData()
                    bus.$emit('banner-success', {
                        message: `Pay period has been ${statusName}!`,
                    })
                })
                .catch(err => {
                    console.log('ERROR: ', err)
                    bus.$emit('banner-error', { message: err, })
                })
        },
        // TODO
        async syncPayroll2QB() {
       
         let syncStatus = this.selectedPayPeriod.sync_status

         if (syncStatus =='2')
         {
            bus.$emit('banner-error', { message: 'Payroll already synced for this pay period!'});
            return false;
         }
         

         let employee = this.allUsersNotSynced;
         let customers = this.allCustomersNotSynced;
         let usersInTaskList = this.usersInTaskList;
         let selectedPayPeriod = this.selectedPayPeriod
         let periodId = this.selectedPayPeriod.id

       
          /* console.log('employee',employee)
           console.log('customers',customers)
           console.log('usersInTaskList',usersInTaskList)*/
           
        // var loading = this.$loading.show()
        
         this.qbSyncing = true;
         var response;

         try {
        
              this.loaderText = "Syncing users to Quickbooks Online...";
              response = await axios.post('/quickbooks/sync-employee-all');
         
  
              this.loaderText = "Syncing customers to Quickbooks Online...";
              response = await axios.post('/quickbooks/sync-customer');
          
              this.loaderText = "Syncing W2 payroll to Quickbooks Online...";

              response = await axios.post('/quickbooks/sync-payroll',{users:usersInTaskList, startDate: selectedPayPeriod.start_date, endDate:selectedPayPeriod.end_date,periodId: periodId });

          this.loadW2Users();
          this.loadPayPeriods();

           bus.$emit('banner-success', { message: response.data.message });
         } catch (error) {
               console.log('ERROR: ', error);
                    bus.$emit('banner-error', { message: error.data.message});
         } finally {
            this.qbSyncing = false;
         }
        },

    }
}
</script>
