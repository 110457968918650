<template>
    <!-- Specifications Card-->
    <div id="assetSpecifications" class="card">
        <div class="card-header">
            <div class="row align-items-center">
                <div class="col-8">
                    <h3 class="mb-0 d-flex align-items-center">
                        <i class="ri-file-list-2-line heading-title"></i>
                        {{ $t('label.specifications') }}
                    </h3>
                </div>
                <div class="col-4 text-right">
                    <router-link class="btn btn-sm btn-default m-0" :to="{ name: 'vehicleEdit', params: { selectedVehicleId: vehicle.id } }">
                        <i class="ri-pencil-line"></i>{{ $t("label.edit") }}
                    </router-link>
                </div>
            </div>
        </div>

        <div class="card-body">
            <h3>{{ $t('label.fuel') }}</h3>
            <div class="row">
                <!-- row 1  -->
                <!-- Fuel type -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.vehicles fuel type') }}</label>
                        <h3 class="border-bottom" v-if="vehicle.specifications">
                            <div v-if="vehicle.specifications.fuel_type">{{ vehicle.specifications.fuel_type | uppercaseFirst }}</div>
                            <div v-else>&nbsp;</div>
                        </h3>
                    </div>
                </div>
                <!-- Vehicle Miles Per Gallon -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.vehicles fuel mpg') }}</label>
                        <h3 class="border-bottom" v-if="vehicle.specifications">
                            <div v-if="vehicle.specifications.fuel_miles_per_gallon">
                                {{ vehicle.specifications.fuel_miles_per_gallon }}&nbsp;MPG
                            </div>
                            <div v-else>&nbsp;</div>
                        </h3>
                    </div>
                </div>

                <!-- row 2  -->
                <!-- Fuel Max Gallons -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.vehicles fuel capacity') }}</label>
                        <h3 class="border-bottom" v-if="vehicle.specifications">
                            <div v-if="vehicle.specifications.fuel_max_gallons">
                                {{ vehicle.specifications.fuel_max_gallons }}&nbsp;{{ $t('label.vehicles gallons') }}
                            </div>
                            <div v-else>&nbsp;</div>
                        </h3>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                    </div>
                </div>
            </div>

            <!-- row 3 -->
            <h3>{{ $t('label.vehicles tires and Battery') }}</h3>
            <div class="row">
                <!-- Tires  -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.vehicles tire size') }}</label>
                        <h3 class="border-bottom" v-if="vehicle.specifications">
                            <div v-if="vehicle.specifications.tire_size">{{ vehicle.specifications.tire_size }}</div>
                            <div v-else>&nbsp;</div>
                        </h3>
                    </div>
                </div>
                <!-- Battery -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.vehicles battery size') }}</label>
                        <h3 class="border-bottom" v-if="vehicle.specifications">
                            <div v-if="vehicle.specifications.battery_size">{{ vehicle.specifications.battery_size }}</div>
                            <div v-else>&nbsp;</div>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>

</style>

<script>
export default {
    props: ['vehicle'],
    data() {
        return {}
    },
    mounted() {
    },
    methods: {}
}
</script>