<template>
    <div>
        <div class="modal-content">
            <div class="modal-header border-bottom">
                <h5 class="modal-title"><span id="modalTitle">{{ $t('label.user information') }}</span></h5>
                <button type="button" class="close" aria-label="Close" @click="$emit('closeFnc')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body p-0">
                <div class="border-bottom p-4">
                    <div class="row align-items-center m-0">
                        <div>
                            <img class="rounded-circle" :src="userInfo.picture" alt="" style="width: 50px; height: 50px; object-fit: cover;">
                        </div>
                        <div class="">
                            <div class="ml-3">
                                <!-- User Name -->
                                <h2 class="font-weight-normal m-0">{{ userInfo.name }}</h2>
                                <!-- Role -->
                                <h4 class="text-muted font-weight-normal m-0">{{ getRole(userInfo.role_id).name }}</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Company Name -->
                <div class="row align-items-center border-bottom m-0 p-1 pl-3">
                    <div class="col-4">
                        <h4 class="text-muted font-weight-normal m-0">{{ $t('label.company') }}</h4>
                    </div>
                    <div class="">
                        <h4 class="font-weight-normal m-0">{{ userInfo.company.name }}</h4>
                    </div>
                </div>

                <!-- Office -->
                <div class="row align-items-center border-bottom m-0 p-1 pl-3">
                    <div class="col-4">
                        <h4 class="text-muted font-weight-normal m-0">{{ $t('label.office') }}</h4>
                    </div>
                    <div class="">
                        <h4 class="font-weight-normal m-0">{{ userInfo.phone_1 }}</h4>
                    </div>
                </div>
                <!-- Mobile -->
                <div class="row align-items-center border-bottom m-0 p-1 pl-3">
                    <div class="col-4">
                        <h4 class="text-muted font-weight-normal m-0">{{ $t('label.mobile') }}</h4>
                    </div>
                    <div class="">
                        <h4 class="font-weight-normal m-0">{{ userInfo.phone_2 }}</h4>
                    </div>
                </div>
                <!-- email -->
                <div class="row align-items-center m-0 p-1 pl-3">
                    <div class="col-4">
                        <h4 class="text-muted font-weight-normal m-0">{{ $t('label.email') }}</h4>
                    </div>
                    <div class="">
                        <h4 class="font-weight-normal m-0"><a :href="'mailto:' + userInfo.email">{{ userInfo.email }}</a></h4>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-top">
                <button type="button" class="btn btn-primary mr-auto" @click="$emit('closeFnc')">{{ $t('label.close') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('roleData')

export default {
    props: ['userId'],
    components: {},
    emits: ['closeFnc'],
    data() {
        return {
            userInfo: { company: {} }
        }
    },
    computed: {
        ...mapState({
            roles: state => state.roles,
        }),
    },
    mounted() {
        //Make sure we have roles
        if (!this.roles.length) {
            this.getRoles().then(response => { })
        }
        this.loadCustomerData()
    },
    methods: {
        ...mapActions(['getRoles']),
        loadCustomerData() {
            axios.post('/get-user-info', { id: this.userId, name: 'User' })
                .then(response => {
                    this.userInfo = response.data.data
                })
                .catch(err => {
                    console.log('ERROR: ', err)
                    bus.$emit('banner-error', { message: err, })
                })
        },
        getRole(roldId) {
            let idx = this.roles.findIndex(role => {
                if (role.id === parseInt(roldId)) {
                    return true
                }
            })
            return (idx >= 0) ? this.roles[idx] : {}
        },
    }
}
</script>