<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50" id="timesheetSettingsContainer">
            <!-- Page Header -->
            <div class="d-flex" style="z-index: 100">
                <!-- Breadcrumbs -->
                <breadcrumbs :title="$t('label.timesheet settings')">
                    <!-- Breakcrumb slots -->
                    <li class="breadcrumb-item">
                        {{ $t('label.administrative') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.settings') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.timesheet') }}
                    </li>
                </breadcrumbs>
            </div>
            
            <!-- Page Body -->
            <div class="card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h3 class="mb-0">{{ $t('label.timesheet settings') }}</h3>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <!-- <div class="">
                        <h3>{{ $t('label.task clock in') }}</h3> -->
                        <!-- Laborers Can Clock In/Out on Tasks -->
                        <!-- <div class="pb-3">
                            <div class="custom-control custom-checkbox">
                                <input type="hidden" name="allow_clockin" value="0">
                                <input class="custom-control-input" v-model="form.allow_clockin" name="allow_clockin" true-value="1" false-value="0" id="chk-laborer-time" type="checkbox">
                                <label class="custom-control-label text-muted" for="chk-laborer-time">{{ $t('label.allow laborers to clock in and out of tasks') }}</label>
                            </div>
                            <span class="py-2 text-sm">{{ $t('label.if this is not selected then only the admin and foreman roles will be able to clock time for the laborer role') }}</span>
                        </div> -->
                        <!-- <hr> -->

                        <!-- Clock Into Future/Past Tasks -->
                        <!-- <div class="">
                            <div class="custom-control custom-checkbox">
                                <input type="hidden" name="future_past_clockin" value="0" />
                                <input class="custom-control-input" v-model="form.future_past_clockin" name="future_past_clockin" true-value="1" false-value="0" id="future_past_clockin" type="checkbox" />
                                <label class="custom-control-label text-muted" for="future_past_clockin">{{ $t('label.allow foremen and laborers to clock in on future or past tasks') }}</label>
                            </div>
                            <span class="py-2 text-sm">{{ $t('label.if this is not selected, then foremen and laborers can only clock in on tasks that are assigned to them for that current day') }}.</span>
                        </div>
                    </div>
                    <hr>                     -->
                    <!-- Week Start -->
                    <div class="">
                        <h3>{{ $t('label.start of week') }}</h3>
                        <div class="form-group">
                            <div class="row m-0">
                                <div class="custom-control custom-radio mr-3">
                                    <input name="start_week" 
                                        v-model="form.start_week" 
                                        class="custom-control-input" 
                                        value="sun"
                                        @change="setPayPeriodCalendarDay();form.pay_period_start = null" 
                                        id="sunday" 
                                        type="radio">
                                    <label class="custom-control-label text-muted" for="sunday">{{ $t('label.sunday') }}</label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input name="start_week" 
                                        v-model="form.start_week" 
                                        class="custom-control-input" 
                                        value="mon" 
                                        @change="setPayPeriodCalendarDay();form.pay_period_start = null" 
                                        id="monday" 
                                        type="radio">
                                    <label class="custom-control-label text-muted" for="monday">{{ $t('label.monday') }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <!-- Pay Period -->
                    <div class="">
                        <h3>Pay Period Settings</h3>
                        <p class="mb-1">Pay period will be calculated from the selected start of the week above.</p>
                        <div class="form-group">
                            <div class="row m-0">
                                <div class="custom-control custom-radio mr-3">
                                    <input name="payPeriodType"
                                        v-model="form.pay_period_type" 
                                        class="custom-control-input" 
                                        value="weekly"
                                        @click="form.pay_period_start = null"
                                        id="weekly" 
                                        type="radio">
                                    <label class="custom-control-label text-muted" for="weekly">Weekly</label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input name="payPeriodType"
                                        v-model="form.pay_period_type" 
                                        class="custom-control-input" 
                                        @click="form.pay_period_start = null"
                                        value="biweekly" 
                                        id="biweekly" 
                                        type="radio">
                                    <label class="custom-control-label text-muted" for="biweekly">Bi-weekly</label>
                                </div>                                
                            </div>
                        </div>
                        <!-- Pay Period start -->
                        <div class="form-group">
                            <span class="text-sm">Date of first Pay Period: </span>
                            <date-picker v-model="form.pay_period_start" 
                                input-class="form-control"
                                valueType="format"
                                format="MM/DD/YYYY"
                                :disabled-date="disableCalendarDays"
                                :placeholder="$t('label.date')"        
                                required></date-picker>                                     
                        </div>
                    </div>
                    <!-- <hr> -->
                    <!-- Overtime -->
                    <!-- <div class="">
                        <h3 class="mb-0">{{ $t('label.overtime') }}</h3>
                        <p class="py-2 m-0">{{ $t('label.select whether overtime is calculated on a weekly or daily basis') }}</p>
                        <div class="form-group">
                            <div class="row m-0 d-flex align-items-center">
                                <div class="col-2"> -->
                                    <!-- weekly -->
                                    <!-- <span class="custom-control custom-radio mr-3">
                                        <input name="overtime_by_week"
                                            v-model="form.overtime_by_week" 
                                            class="custom-control-input" 
                                            value="1" 
                                            id="overtimeWeekly" 
                                            type="radio">
                                        <label class="custom-control-label text-muted" for="overtimeWeekly">{{ $t('label.weekly') }}</label>
                                    </span>
                                </div>
                                <div class="col-2"> -->
                                    <!-- Daily -->
                                    <!-- <span class="custom-control custom-radio">
                                        <input name="overtime_by_week" 
                                            v-model="form.overtime_by_week" 
                                            class="custom-control-input" 
                                            value="0" 
                                            id="overtimeDaily" 
                                            type="radio">
                                        <label class="custom-control-label text-muted" for="overtimeDaily">{{ $t('label.daily') }}</label>
                                    </span>
                                </div> -->
                                <!-- Overtime begins on hours -->
                                <!-- <div class="col">
                                    <label for="overtime_hrs">Overtime begins after the set number of hours</label>
                                    <input type="text" 
                                        v-model="form.overtime_hrs" 
                                        name="overtime_hrs" 
                                        id="overtime_hrs" 
                                        class="form-control w-25" 
                                        autofocus>
                                </div>
                            </div> -->
                            <!-- <div class="row m-0 py-4 align-items-center">
                                <p class="m-0 mr-3">At</p>
                                <input type="text" 
                                    v-model="form.overtime_hrs" 
                                    name="overtime_hrs" 
                                    id="input-name" 
                                    class="form-control w-80" 
                                    placeholder="##" 
                                    autofocus>
                                <p class="m-0 ml-3">{{ $t('label.hours') }} <span id="overtimeHrs" class="font-weight-bold">{{ $t('label.per week') }}</span>, {{ $t('label.hourly employees begin accruing overtime') }}.</p>
                            </div> -->
                            <!-- <div class="row m-0 align-items-center">
                                <p class="m-0 mr-3">{{ $t('label.show warning') }}</p>
                                <input type="text" v-model="form.overtime_warning_hrs" name="overtime_warning_hrs" id="input-name" class="form-control w-80" placeholder="##" autofocus>
                                <p class="m-0 ml-3">{{ $t('label.hours prior to employees reaching the') }} <span id="overtimeWarningHrs" class="font-weight-bold">{{ $t('label.weekly') }}</span> {{ $t('label.overtime threshold') }}.</p>
                            </div> -->
                        <!-- </div> -->
                        <!-- <div class="custom-control custom-checkbox">
                            <input type="hidden" name="overtime_warning" value="0">
                            <input class="custom-control-input"  v-model="form.overtime_warning" name="overtime_warning" value="1" id="chk-warning-overtime" type="checkbox">
                            <label class="custom-control-label text-muted" for="chk-warning-overtime">{{ $t('label.show warning if assigned Tasks will put employee into overtime') }}</label>
                        </div> -->
                    <!-- </div> -->
                    <!-- <hr> -->

                    <!-- Overtime Projections -->
                    <!-- <div class="">
                        <h3>{{ $t('label.overtime projection alerts') }}</h3>
                        <p class="">{{ $t('label.alert the user for timesheet overtime projections') }}</p>
                        <div class="form-group mt--1">
                            <div class="row m-0">
                                <div class="custom-control custom-radio mr-3">
                                    <input name="timesheet_alert" class="custom-control-input" value="1" type="radio" id="dotimesheetAlert" v-model="form.timesheet_alert">
                                    <label class="custom-control-label" for="dotimesheetAlert">{{ $t('label.yes') }}</label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input name="timesheet_alert" class="custom-control-input" value="0" id="donttimesheetAlert" type="radio" v-model="form.timesheet_alert">
                                    <label class="custom-control-label" for="donttimesheetAlert">{{ $t('label.no') }}</label>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <!-- Save Footer -->
            <div class="text-center">
                <button type="button" @click="saveSettings()" class="btn btn-primary">{{ $t('label.save settings') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex';

export default {
    props: [],
    components: {},
    data() {
        return {
            loader: null,
            form: {
                start_week: null,
                pay_period_type: null,
                pay_period_start: null,
                overtime_by_week: null,
                allow_clockin: null,
                future_past_clockin: null,
                overtime_hrs: null,
            },
            payPeriodStartDate:null, 
            calendarDaysToBlock:[]           
        }
    },
    computed: {
        ...mapGetters(['company'])
    },
    created() {
        this.loader = this.$loading.show();
        this.$store.dispatch('getCompanySettings').then(() => {
            this.initForm();
            this.loader.hide();
        });
    },
    mounted() {},
    methods: {
        initForm() {
            // Set form values
            for(const key in this.company.settings){
                if(key in this.form){
                    if(key == 'pay_period_start'){
                        this.form[key] = moment(this.company.settings[key]).format('MM/DD/YYYY');
                    }
                    else{
                        this.form[key] = this.company.settings[key];
                    }
                }                
            }
            this.setPayPeriodCalendarDay()
        },
        saveSettings() {
            let timesheetSettings = JSON.parse(JSON.stringify(this.form));
            timesheetSettings.company_id = this.company.id;
            if(timesheetSettings.pay_period_start){
                timesheetSettings.pay_period_start = moment(timesheetSettings.pay_period_start,'MM/DD/YYYY').format('YYYY-MM-DD')
            }
            axios.post('/updateTimesheetSettings', timesheetSettings).then(response => {
                bus.$emit('banner-success', {
                    message: this.$t('label.company settings saved successfully')
                });
            }).catch(err => {
                console.log(err)
                bus.$emit('banner-error', {
                    message: err
                });
            });
        },
        setPayPeriodCalendarDay (){
            if(this.form.start_week == 'mon'){
                this.calendarDaysToBlock = [0,2,3,4,5,6]
            }
            else{
                this.calendarDaysToBlock = [1,2,3,4,5,6]
            }
        },
        disableCalendarDays (date){            
            for (let i=0; i<this.calendarDaysToBlock.length; i++) {
                if(moment(date).weekday() == this.calendarDaysToBlock[i] || date < moment().subtract(7,'days').toDate()) {
                    return date
                }
            }
        },
    }
}
</script>