<template>
    <div class="col-xl-12 order-xl-1 mobile-p-0">
        <div :class="modalVendorContactsStyling">
            <div v-if="from == 1" class="modal-header border-bottom">
                <h5 class="modal-title" id="vendorContactList">
                    {{ $t('label.contacts') }}
                </h5>
                <div>
                    <button type="button" class="btn btn-sm btn-default" @click="addContact()">
                        {{ $t('label.add contact') }}
                    </button>
                </div>
            </div>
            <div v-else class="modal-header border-bottom">
                <h5 class="modal-title" id="vendorContactList">
                    {{ vendor.vendor_name }}
                    {{ $t('label.contacts') }}
                </h5>
                <div>
                    <button type="button" class="close" @click="$emit('cancelFnc')">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
            </div>
            <div class="modal-body pt-0 px-3">
                <!-- vendor Contact List -->
                <div class='table-responsive'>
                    <table class="table table-flush table-sm border-bottom" id="dt-vendorList">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">{{ $t('label.name') }}</th>
                                <th scope="col">{{ $t('label.phone') }}</th>
                                <th scope="col">{{ $t('label.email') }}</th>
                                <th scope="col">{{ $t('label.address') }}</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(contact, index) in paginatedContacts" :key="index">
                                <td>{{ contact.first_name }} {{ contact.last_name }}</td>
                                <td>{{ contact.phone }}</td>
                                <td>{{ contact.email }}</td>

                                <td>{{ contact.address_1 }}
                                    {{ contact.address_2 }}
                                    {{ contact.city }},
                                    {{ contact.state }}
                                    {{ contact.postal_code }}
                                    {{ contact.country }}
                                </td>

                                <td>
                                    <div class="dropdown">
                                        <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-ellipsis-v"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                            <a class="dropdown-item d-flex align-items-center" href="#" @click="editContact(contact)">
                                                <i class="ri-pencil-line"></i>
                                                {{ $t('label.edit') }}
                                                {{ $t('label.contact') }}
                                            </a>
                                            <span v-if="contact.vendor_contact_in_use > 0" class="sp-tooltip">
                                                <span v-if="contact.vendor_contact_in_use > 0" class="dropdown-item text-danger border-top sp-tooltip" style="opacity: 0.6">
                                                    <i class="ri-delete-bin-line"></i> {{ $t('label.delete vendor') }}
                                                </span>
                                                <span class="sp-tooltiptext sp-tooltiptext-danger text-xs" style="width: 225px; white-space: normal; margin-left: 1rem">{{ $t('label.Contact in use, cannot delete') }}</span>
                                            </span>
                                            <a v-else class="dropdown-item text-danger pointer border-top" href="#" data-toggle="modal" @click="openDeleteModal(contact)">
                                                <i class="ri-delete-bin-line"></i> {{ $t('label.delete') }} {{ $t('label.contact') }}
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- Pagination -->
                    <table-pagination v-model="paginatedContacts" :all-rows="filteredVendors" :perPage="perPage">
                    </table-pagination>

                    <div v-if="from != 1">
                        <div class="mt-2">
                            <button type="button" class="btn btn-neutral" @click="$emit('cancelFnc')">
                                <i class="ri-close-line"></i>
                                {{ $t('label.close') }}
                            </button>
                            <button type="button" class="btn btn-success" @click="addContact()">
                                <i class="ri-add-line"></i>
                                {{ $t('label.add contact') }}
                            </button>
                        </div>
                    </div>
                </div>
                <modal class="py-4" name="showAddContactsModal" height="auto" width="98%" :max-width="1024" :reset="true" :scrollable="true" :adaptive="true">
                    <modal-add-contact :vendor-id="vendor.id" :selected-contact="selectedContact" @cancelFnc="cancelAddContact" @returnContact="receiveNewContact" />
                </modal>
            </div>
        </div>
        <!-- Contact Delete -->
        <modal-delete-component v-if="showDeleteContact" :newId="'modalVendorContacts'" :title="'ARE YOU SURE YOU WANT TO DELETE THIS CONTACT'" @onClose="toggleDeleteModal()" @customDeleteMethod="deleteVendorContact()">
        </modal-delete-component>

        <modal name="contactAddPrompt" height="auto" width="100%" :max-width="650" :scrollable="true" :reset="true" :adaptive="true">
            <div class="modal-content">
                <div class="modal-header border-bottom">
                    <h5 class="modal-title" id="viewAllJobTimeModal1Title">{{ $t('label.missing vendor contact') }}</h5>
                    <button type="button" class="close" @click="$modal.hide('contactAddPrompt')">
                        <i class="ri-close-line"></i>
                    </button>
                </div>
                <div class="modal-body text-center">
                    <div class="form-group">
                        No contacts have been added to this vendor. Would you like to add a contact now?
                    </div>
                    <div>
                        <button type="button" class="btn btn-success" @click="addContact(); $modal.hide('contactAddPrompt')">{{ $t('label.yes') }}</button>
                        <button type="button" class="btn btn-outline-default" @click="$modal.hide('contactAddPrompt')">{{ $t('label.no') }}</button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import ModalAddContact from '@/components/Admin/Vendors/ModalAddContact';
import ModalDeleteComponent from '@/components/ModalDelete2.vue';
import TablePagination from '@/components/TablePagination';
import bus from '@/utils/event-bus';

export default {
    props: ["vendor", "from", 'newVendorAdded'],
    emits: ["updateContactCount", 'cancelFnc'],
    components: {
        ModalAddContact,
        ModalDeleteComponent,
        TablePagination,
    },
    data() {
        return {
            contactList: [],
            paginatedContacts: [],
            selectedContact: null,
            selectedContactId: null,
            isLoading: false,
            showDeleteContact: false,
            contactInfo: [],
            perPage: 5,
        };
    },
    watch: {
    },
    computed: {
        filteredVendors() {
            return this.contactList;
        },
        modalVendorContactsStyling() {
            if (this.from == 1) {
                return "card"
            } else {
                return "";
            }
        },
    },
    mounted() {
        this.getAllVendorContacts();
    },
    methods: {
        getAllVendorContacts() {
            this.isLoading = true;
            this.contactList = [];

            return axios
                .get("/get-all-vendor-contacts/" + this.vendor.id)
                .then(response => {
                    this.contactList = response.data.results;
                    this.$emit("updateContactCount", {
                        vendorId: this.vendor.id,
                        contactCount: this.contactList.length,
                    });
                    this.isLoading = false;

                    if (this.contactList == 0) {
                        this.$modal.show('contactAddPrompt');
                    }
                })
                .catch(err => {
                    console.log("ERROR: ", err);
                    bus.$emit("banner-error", { message: err });
                });
        },
        addContact() {
            this.$modal.show('showAddContactsModal');
            this.selectedContact = null;
        },
        editContact(contact) {
            this.$modal.show('showAddContactsModal');
            this.selectedContact = contact;
        },
        cancelAddContact() {
            this.$modal.hide('showAddContactsModal');
        },
        receiveNewContact(contact) {
            if (contact.isEdit) {
                this.successMessage = "Contact Updated Successfully!";
                this.getAllVendorContacts();
            } else {
                bus.$emit('PurchaseOrderInfo', contact.result);
                //Add new contact to the list
                this.contactList.push(contact.result);
                this.$emit("updateVendorContactCount", {
                    vendorId: this.vendor.id,
                    contactCount: this.contactList.length,
                    contactList: this.contactList,
                });
            }
            this.$modal.hide('showAddContactsModal');
        },
        openDeleteModal(data) {
            this.showDeleteContact = true
            this.contactInfo = data;
        },
        toggleDeleteModal() {
            this.showDeleteContact = false;
        },
        deleteVendorContact() {
            axios.post('/delete-vendor-contact', this.contactInfo)
                .then(res => {

                    this.getAllVendorContacts().then(() => {
                        this.$emit("updateVendorContactCount", {
                            vendorId: this.vendor.id,
                            contactCount: this.contactList.length,
                            contactList: this.contactList,
                        });
                    });
                    bus.$emit("banner-success", {
                        message: $t('label.contact successfully deleted')
                    });
                }).catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
    }
};
</script>
