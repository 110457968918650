<template>
    <modal name="addEditLeadStatusModal" @closed="$emit('onClose')" :classes="'rounded'" :min-height="400" width="700px" :scrollable="true" height="auto" :reset="true" :adaptive="true">
        <div class="card-header">
            <div class="py-1 d-flex align-items-center">
                <h5 v-if="modalType == 'add'" class="modal-title mr-auto">{{ $t('label.add lead status') }}</h5>
                <h5 v-if="modalType == 'edit'" class="modal-title mr-auto">{{ $t('label.edit lead status') }}</h5>
                <button type="button" class="close ml-auto" data-dismiss="modal" @click="$emit('onClose')">
                    <i class="ri-close-line"></i>
                </button>
            </div>
        </div>
        <div class="card-body">
            <div class="row mb-2">
                <!-- Name -->
                <div class="col-8">
                    <label class="form-control-label text-muted" for="input-name">{{ $t('label.lead status label') }}</label>
                    <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                    <div class="badge badge-dot badge-dot-lead-status-label d-flex align-items-center auto-sort">
                        <i class="bg-success" :style="{ 'background-color': form.color + ' !important' }"></i>
                        <input v-model="$v.form.name.$model" type="text" class="form-control" :class="{ 'is-invalid': $v.form.name.$error }" placeholder="Name" required autofocus>
                    </div>
                </div>
                <!-- Color -->
                <div class="col-4">
                    <div>
                        <label class="form-control-label text-muted">{{ $t('label.lead color') }}</label>
                    </div>
                    <div class="dropdown auto-sort mt-3">
                        <div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-dot badge-dot-lead-status-color d-flex align-items-center pointer">
                                <i class="bg-success" :style="{ 'background-color': form.color + ' !important' }"></i>
                                {{ form.color }}
                            </span>
                        </div>
                        <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow" style="overflow-y: scroll; height: 100px">
                            <a v-for="(d,i) in defaultColors" :key="i" class="dropdown-item text-default pointer" @click="form.color = d.color">
                                <span class="badge badge-dot badge-dot-lead-status-color p-0">
                                    <i class="bg-success" :style="{ 'background-color': d.color + ' !important' }"></i>
                                    {{ d.color }}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer d-flex">
            <button type="button" class="btn btn-primary mr-auto" @click="saveLeadStatus()">{{ $t('label.save') }}</button>
            <button type="button" class="btn btn-secondary ml-auto" data-dismiss="modal" @click="$emit('onClose')">{{ $t('label.close') }}</button>
        </div>
    </modal>
</template>

<script>
import bus from '@/utils/event-bus';
import { required } from 'vuelidate/lib/validators';

export default {
    props: ['modalType', 'leadStatus'],
    emits: ['onSave', 'onClose'],
    components: {},
    data() {
        return {	
            loader: null,			
            defaultColors: [
                { id: 1, color: '#ADB5BD' },
                { id: 2, color: '#0A59D1' },
                { id: 3, color: '#FC9105' },
                { id: 4, color: '#0E914F' },
                { id: 5, color: '#DD1A3E' },
            ],
            form: {
                name: null,
                color: null,
            }
        }
    },
    validations: {
        form: {
            name: { required }
        }
    },
    mounted() {
        this.$modal.show("addEditLeadStatusModal");
        console.log("in addEditLeadStatusModal");
        this.initForm();
    },
    methods: {
        initForm() {
            if(this.modalType == "edit") {
                this.form.name = this.leadStatus.name;
                this.form.color = this.leadStatus.color;
            } else if(this.modalType == "add") {
                this.form.color = this.defaultColors[0].color;
            }
        },

        saveLeadStatus() {
            this.loader = this.$loading.show();

            this.$v.$touch();
            if(!this.$v.$invalid) {
                let data = JSON.parse(JSON.stringify(this.form)),
                    apiPath = '';
            
                if(this.modalType == 'edit') {
                    apiPath = '/lead-status/update';
                    data.id = this.leadStatus.id;
                } else if(this.modalType == 'add') {
                    apiPath = '/lead-status/store';
                } 

                axios.post(apiPath, data).then(res => {
                    this.$emit('onSave');
                    bus.$emit('banner-success', {
                        message: 'lead status saved successfully'
                    });
                    this.$emit('onClose');
                    this.loader.hide();
                }).catch(err => console.error("API ERROR", err));
            }
        },

        // onClose() {

        // }
    }
}
</script>