<template>
    <div class="row m-0 calendar-view">
        <div class="col-9">
            <div ref="tasklistcard" class="card mb-5">
                <div class="card-header py-3">
                    <div class="row align-items-center justify-content-between m-0">
                        <div class="col-4 p-0">
                            <h3 class="mb-0 d-flex align-items-center">
                                <i class="ri-todo-line heading-title"></i> {{ $t('label.tasks') }}
                            </h3>
                        </div>
                        <div class="d-flex align-items-center">
                            <div class="btn-group btn-group-light rounded calendar-filter" role="group" aria-label="Basic example">
                                <!-- FILTER BY TASK STATUS -->
                                <div class="dropdown w-100">
                                    <button type="button" class="btn btn-white text-primary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="ri-checkbox-circle-line"></i>{{ filterBy.task_status }}<i class="ri-arrow-down-s-fill"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                        <a class="dropdown-item text-default pointer" @click="selectFilter('Incomplete', 'status')">
                                            <i class="ri-checkbox-circle-line"></i> {{ $t('label.incomplete tasks') }}
                                        </a>
                                        <div class="dropdown-divider"></div>
                                        <a v-for="(d, i) in allTaskStatus" :key="i" class="dropdown-item text-default pointer" @click="selectFilter(d.name, 'status', d)">
                                            <span class="badge badge-dot">
                                                <i class="bg-success" :style="'background-color:' + d.color + ' !important'"></i> {{ d.name }} {{ $t('label.tasks') }}
                                            </span>
                                        </a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item text-default pointer" @click="selectFilter('All', 'status')">
                                            <span class="badge badge-dot">
                                                <i class="bg-primary"></i> {{ $t('label.all tasks') }}
                                            </span>
                                        </a>
                                    </div>
                                </div>
                                <!-- FILTER BY CREW -->
                                <div class="dropdown w-100">
                                    <button type="button" class="btn btn-white text-primary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="ri-group-line"></i>{{ filterCrewText }}<i class="ri-arrow-down-s-fill"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow filterUsers" @click.stop="">
                                        <div class="d-flex justify-content-between px-3 py-1">
                                            <div class="custom-control custom-radio">
                                                <input name="crews" class="custom-control-input" value="1" id="crews" type="radio" v-model="crewOrUser" @change="onToggle($event, 1)">
                                                <label class="custom-control-label" for="crews">{{ $t('label.crews') }}</label>
                                            </div>
                                            <div class="custom-control custom-radio">
                                                <input name="users" class="custom-control-input" value="2" id="users" type="radio" v-model="crewOrUser" @change="onToggle($event, 2)">
                                                <label class="custom-control-label" for="users">{{ $t('label.users') }}</label>
                                            </div>
                                        </div>
                                        <div class="dropdown-divider"></div>
                                        <!-- CREWS -->
                                        <template v-if="crewOrUser == 1">
                                            <a class="dropdown-item text-default">
                                                <label class="form-control-label text-muted font-weight-normal m-0 w-100 pointer" for="all-crews">
                                                    <input @change="selectCrew($event, 'allcrews', 'crew')" class="form-control-input" id="all-crews" type="checkbox" value="allcrews" v-model="filterBy.crew_ids">
                                                    {{ $t('label.all crews') }}
                                                </label>
                                            </a>
                                            <div class="dropdown-divider"></div>
                                            <h4 class="px-3 py-3 m-0">{{ $t('label.crews') }}</h4>
                                            <a v-for="(d, i) in allCrews" :key="'crew-' + i" class="dropdown-item text-default">
                                                <label class="form-control-label text-muted font-weight-normal m-0 w-100 pointer" :for="'filter-' + d.id">
                                                    <input @change="selectCrew($event, d, 'crew')" class="form-control-input" :id="'filter-' + d.id" type="checkbox" :value="d.id" v-model="filterBy.crew_ids">
                                                    {{ d.name }}
                                                </label>
                                            </a>
                                        </template>
                                        <!-- USERS -->
                                        <template v-if="crewOrUser == 2">
                                            <a class="dropdown-item text-default">
                                                <label class="form-control-label text-muted font-weight-normal m-0 w-100 pointer" for="all-users">
                                                    <input @change="selectCrew($event, 'all', 'user')" class="form-control-input" id="all-users" type="checkbox" value="allusers" v-model="filterBy.crew_ids">
                                                    {{ $t('label.all users') }}
                                                </label>
                                            </a>
                                            <div class="dropdown-divider"></div>
                                            <h4 class="px-3 py-3 m-0">{{ $t('label.assignees') }}</h4>
                                            <a v-for="(d, i) in allUsers" :key="'user-' + i" class="dropdown-item text-default">
                                                <label class="form-control-label text-muted font-weight-normal m-0 w-100 pointer" :for="'filter-' + d.id">
                                                    <input @change="selectCrew($event, d, 'user')" class="form-control-input" :id="'filter-' + d.id" type="checkbox" :value="d.id" v-model="filterBy.crew_ids">
                                                    {{ d.name }}
                                                </label>
                                            </a>
                                        </template>
                                        <a class="dropdown-item text-default pointer text-center mt-3" @click="deselectAll()">
                                            <h4 class="m-0">{{ $t('label.deselect all') }}</h4>
                                        </a>
                                    </div>
                                </div>
                                <!-- SORT BY -->
                                <div class="dropdown w-100">
                                    <button type="button" class="btn btn-white text-primary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="ri-arrow-up-down-line"></i>{{ $t('label.sort') }}: {{ sortBy }}<i class="ri-arrow-down-s-fill"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                        <a v-for="(d, i) in sortList" :key="'sort-' + i" class="dropdown-item text-default pointer" @click="selectSortBy(d)">{{ d }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="allTasks.length == 0">
                    <div class="card-body m-0 py-0">
                        <div class="bg-white text-center row align-items-center m-0">
                            <i class="ri-todo-line ni-8x w-100 color-gray-400"></i>
                            <p class="w-100 font-weight-normal">{{ $t('label.No Tasks have been added to this Job') }}.</p>
                        </div>
                    </div>
                </div>
                <div v-if="allTasks.length > 0" class="table-responsive">
                    <table class="table table-flush table-sm table-tasks">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col" colspan="3">{{ $t('label.task name') }}</th>
                                <th scope="col">{{ $t('label.hours') }}</th>
                                <th scope="col" style="width: 14%">{{ $t('label.dates') }}</th>
                                <th scope="col">{{ $t('label.status') }}</th>
                                <th scope="col" style="width: 5%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- Loop Tasks -->
                            <tr v-for="(data, index) in paginatedAllTasks" :key="index" class="pointer">
                                <!-- Task Name and Task Type -->
                                <td>
                                    <div class="task-name search-task" @click="setSelectedTask(data)">{{ data.name }}</div>
                                    <div class="task-type">{{ data.task_type.name }}</div>
                                </td>
                                <!-- Job Name and Customer Name -->
                                <td>
                                    <div class="job-name">{{ data.job.job_name }}</div>
                                    <div class="customer-name" v-if="data.job.customer">{{ data.job.customer.name }}</div>
                                </td>
                                <!-- Assigned employee -->
                                <td>
                                    <div class="task-name">{{ data.crew ? data.crew.name : '' }}</div>
                                    <div class="row align-items-center m-0">
                                        <div :class="{ 'crew-member-avatar': 0 < i }" v-for="(d, i) in data.assigned_to.slice(0, 4)" :value="d.id" :key="i">
                                            <img :src="d.user.picture" :alt="d.user.name" class="avatar-img rounded-circle pointer">
                                        </div>
                                        <span class="assignee" v-if="data.assigned_to.length > 4">+ {{ data.assigned_to.length - 4 }} others</span>
                                    </div>
                                </td>
                                <!-- Time Estimate -->
                                <td>{{ data.time_estimate }}</td>
                                <!-- Date Range -->
                                <td>{{ data.date_range_text }}</td>
                                <!-- task status -->
                                <td>
                                    <span class="badge badge-dot">
                                        <i class="bg-success" :style="'background-color:' + data.task_status.color + ' !important'"></i> {{ data.task_status.name }}
                                    </span>
                                </td>
                                <!-- task menu dropdown -->
                                <td class="text-right">
                                    <div class="dropdown">
                                        <a class="btn btn-sm btn-icon-only text-light dropdown-toggle" id="taskItemMenu" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-ellipsis-v"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" aria-labelledby="taskItemMenu">
                                            <a class="dropdown-item text-default pointer" data-toggle="modal" @click="setSelectedTask(data); onClickEditModal(data)">
                                                <i class="ri-pencil-line"></i> {{ $t('label.edit task') }}
                                            </a>
                                            <a v-if="data.task_status.name != 'Closed'" class="dropdown-item text-default pointer" @click="markAsComplete(data)">
                                                <i class="ri-check-line"></i> {{ $t('label.mark as complete') }}
                                            </a>
                                            <span v-if="data.task_status.name == 'Closed'" class="dropdown-item text-default">
                                                <i class="fas fa-times mr-2"></i> {{ $t('label.task closed') }}
                                            </span>
                                            <a @mouseover="runTooltip()" disabled v-if="!data.can_delete" class="dropdown-item text-danger pointer" href="#" data-toggle="tooltip" data-placement="left" title="This Task cannot be deleted because time has been clocked to it, or someone is currently clocked into the Task.">
                                                <i class="ri-delete-bin-line"></i> {{ $t('label.delete task') }}
                                            </a>
                                            <a v-if="data.can_delete" class="dropdown-item text-danger pointer" @click="setSelectedTask(data); toggleDeleteModal(true)">
                                                <i class="ri-delete-bin-line"></i> {{ $t('label.delete task') }}
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table-pagination v-model="paginatedAllTasks" :all-rows="allTasks" :perPage="perPage">
                    </table-pagination>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="card mb-0" :style="newHeight">
                <div class="card-header py-3 border-0">
                    <div class="btn-group btn-group-light rounded calendar-filter taskgroup" role="group" aria-label="Basic example" style="z-index: 0">
                        <button type="button" class="btn btn-white text-primary" :class="{ active: taskGroup == 1 }" @click="selectTaskGroup(1)">
                            {{ $t('label.unassigned') }}
                        </button>
                        <button type="button" class="btn btn-white text-primary" :class="{ active: taskGroup == 2 }" @click="selectTaskGroup(2)">
                            {{ $t('label.unscheduled') }}
                        </button>
                    </div>
                </div>
                <div v-if="taskGroup == 1" class="card-body m-0 py-0">
                    <div class="form-group mb-0">
                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.date range') }}</label>
                        <div class="dropdown w-100">
                            <div class="w-100 dropdown-select" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <input name="date_range" class="form-control form-control-dropdown pointer" placeholder="- Select a date range -" type="text" v-model="dateRange" readonly>
                                <i class="ri-arrow-down-s-line"></i>
                            </div>
                            <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow">
                                <a class="dropdown-item text-default pointer" @click="selectDateRange('Today')">
                                    {{ $t('label.today') }}
                                </a>
                                <a class="dropdown-item text-default pointer" @click="selectDateRange('Tomorrow')">
                                    {{ $t('label.tomorrow') }}
                                </a>
                                <a class="dropdown-item text-default pointer" @click="selectDateRange('This week')">
                                    {{ $t('label.this week') }}
                                </a>
                                <a class="dropdown-item text-default pointer" @click="selectDateRange('Next week')">
                                    {{ $t('label.next week') }}
                                </a>
                                <a class="dropdown-item text-default pointer" @click="selectDateRange('This month')">
                                    {{ $t('label.this month') }}
                                </a>
                                <a class="dropdown-item text-default pointer" @click="selectDateRange('Next month')">
                                    {{ $t('label.next month') }}
                                </a>
                                <div class="dropdown-divider"></div>
                                <a @click.stop="" class="dropdown-item text-default pointer" @click="selectDateRange('Custom date range')">
                                    {{ $t('label.custom date range') }}
                                </a>
                                <div v-if="dateRange == 'Custom date range'" class="row input-daterange datepicker align-items-center" style="margin-left: 0; margin-right: 0;">
                                    <!-- Start Date -->
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label class="form-control-label">{{ $t('label.start date') }}</label>

                                            <date-picker v-model="filter.start_date" valueType="date" format="MM/DD/YYYY" class="full-width" input-class="form-control" :placeholder="$t('label.select date')" :disabled-date="disableStartDateBeforeToday" @click.stop @change="dateChange" required>
                                            </date-picker>
                                        </div>
                                    </div>
                                    <!-- Due Date -->
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label class="form-control-label">{{ $t('label.due date') }}</label>

                                            <date-picker v-model="filter.due_date" valueType="date" format="MM/DD/YYYY" class="full-width" input-class="form-control" :disabled-date="disableEndDateBeforeStartDate" @change="dateChange" :placeholder="$t('label.select date')">
                                            </date-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 my-3 task-list unassigned">
                        <div class="col-12 p-0">
                            <div :key="i" v-for="(d, i) in allUnassignedTasks">
                                <TaskItem :unassigned="true" :task="d" :drag-column="false" task-group="unassigned"></TaskItem>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="card-body m-0 py-0">
                    <div class="row mx-0 my-3 task-list unscheduled">
                        <div class="col-12 p-0">
                            <div :key="i" v-for="(d, i) in allUnscheduledTasks">
                                <TaskItem :unassigned="true" :task="d" :drag-column="false" task-group="unscheduled"></TaskItem>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Edit Task Modal -->
        <modal class="py-4" name="editTaskModal" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
            <modal-task :jobId="jobid" :task="selectedTask" :isTemplate="false" source="task" @getTasks="reloadTasks" @cancelFnc="$modal.hide('editTaskModal')" />
        </modal>

        <!-- Delete Task Modal -->
        <modal-delete-component v-if="showDltTypeModal" :newId="'Task'" :title="$t('label.ARE YOU SURE YOU WANT TO DELETE THIS TASK')" @onClose="toggleDeleteModal()" @customDeleteMethod="deleteTask()">
        </modal-delete-component>
    </div>
</template>
<script>
import ModalDeleteComponent from '@/components/ModalDelete2.vue';
import ModalTask from '@/components/ModalTask.vue';
import TablePagination from '@/components/TablePagination';
import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex';
import TaskItem from '../template/TaskItem.vue';

export default {
    props: [],
    emits: ['closeModal'],
    components: {
        TablePagination,
        ModalTask,
        ModalDeleteComponent,
        TaskItem
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            companySettings: JSON.parse(localStorage.getItem('company_settings')),
            paginatedAllTasks: [],
            perPage: 10,
            loader: null,
            filterBy: {
                task_status: 'All Tasks',
                task_status_id: 'All',
                crew: ['All Crews'],
                crew_ids: ['allcrews'],
                type: 'crew'
            },
            sortList: [
                $t('label.crew name (A-Z)'),
                $t('label.crew name (Z-A)'),
                $t('label.most hours scheduled'),
                $t('label.least hours scheduled')
            ],
            sortBy: $t('label.crew name (A-Z)'),
            crewOrUser: 1,
            jobid: null,
            task: {},
            selectedTask: {},
            showDltTypeModal: false,
            taskGroup: 1,
            dateRange: 'This week',
            filter: {
                start_date: moment().toDate(),
                due_date: moment().toDate()
            },
            newHeight: null
        }
    },
    computed: {
        ...mapGetters({
            crewTime: 'taskData/crewTime',
            allTaskStatus: 'taskData/allTaskStatus',
            allUsers: 'userData/allUsers',
            allCrews: 'userData/allCrews',
            allTasks: 'taskData/allTasks',
            allUnassignedTasks: 'taskData/allUnassignedTasks',
            allUnscheduledTasks: 'taskData/allUnscheduledTasks',
        }),
        filterCrewText() {
            return this.filterBy.crew.join(',');
        },
    },
    mounted() {
        this.loadLkupData();
    },
    methods: {
        loadLkupData() {
            let filter = {
                filterBy: this.filterBy,
                sortBy: this.sortBy,
            }
            let promises = [];
            this.loader = this.$loading.show();
            promises.push(this.$store.dispatch('taskData/getAllTaskStatus'));
            promises.push(this.$store.dispatch('userData/getAllCrews'));
            promises.push(this.$store.dispatch('userData/getAllUsers'));
            promises.push(this.$store.dispatch('taskData/getAllTasks', filter));
            // promises.push(this.$store.dispatch('taskData/getAllUnassignedTasks', { dateRange: this.dateRange }));
            // promises.push(this.$store.dispatch('taskData/getAllUnscheduledTasks'));
            // Hide the loader overlay after all lookup data is loaded
            Promise.all(promises).then(() => {
                let newheight = this.$refs.tasklistcard.clientHeight;
                this.newHeight = 'height: ' + newheight + 'px';
                this.loader.hide();
            });

            this.$store.dispatch('taskData/getAllUnassignedTasks', { dateRange: this.dateRange });
            this.$store.dispatch('taskData/getAllUnscheduledTasks');
        },
        async reloadTasks() {
            this.loader = this.$loading.show();
            let filter = {
                filterBy: this.filterBy,
                sortBy: this.sortBy,
            }
            await this.$store.dispatch('taskData/getAllTasks', filter);
            await this.$store.dispatch('taskData/getAllUnassignedTasks', { dateRange: this.dateRange });
            await this.$store.dispatch('taskData/getAllUnscheduledTasks');

            let newheight = this.$refs.tasklistcard.clientHeight;
            this.newHeight = 'height: ' + newheight + 'px';

            this.loader.hide();
        },
        onClickEditModal(item) {
            this.jobid = item.job.id;
            this.$modal.show('editTaskModal')
        },
        markAsComplete(data) {
            var request_fields = {
                'task_id': data.id,
            };
            axios.post('/mark-as-complete', request_fields)
                .then(response => {
                    this.reloadThis()
                    bus.$emit('banner-success', {
                        message: `Task completed successfully!`,
                    });
                })
                .catch(error => {
                    console.log('ERROR OCCURED', error)
                });
        },
        toggleDeleteModal(status) {
            this.showDltTypeModal = status;
        },
        deleteTask() {
            if (this.selectedJobType.id) {
                axios.post("/delete-job-type/" + this.selectedJobType.id)
                    .then(response => {
                        this.getJobTypes();
                        bus.$emit('banner-success', {
                            message: $t('label.Job Type successfully deleted')
                        });
                    })
                    .catch(err => console.log(err));
            }
        },
        runTooltip() {
            $('[data-toggle="tooltip"]').tooltip()
        },
        setSelectedTask(item) {
            this.selectedTask = item;

            bus.$emit('openTaskOffCanvas', item.id)
        },
        toggleDeleteModal(status) {
            this.showDltTypeModal = status;
        },
        selectTaskGroup(val) {
            this.taskGroup = val;
        },
        selectDateRange(val) {
            this.dateRange = val;

            if (this.dateRange == 'Custom date range') {
                console.log('');
            } else {
                this.$store.dispatch('taskData/getAllUnassignedTasks', { dateRange: this.dateRange })
            }
        },
        disableStartDateBeforeToday(date) {
            // return date < moment().subtract(1,'days').toDate();
        },
        disableEndDateBeforeStartDate(date) {
            return date < moment(this.filter.start_date).toDate();
        },
        dateChange() {
            let start_date = moment(this.filter.start_date).format('MM/DD/YYYY');
            let due_date = moment(this.filter.due_date).format('MM/DD/YYYY');

            let req = {
                dateRange: this.dateRange,
                start_date: start_date,
                due_date: due_date,
            }

            this.$store.dispatch('taskData/getAllUnassignedTasks', req)
        },
        selectFilter(filter, type, data = null) {
            if (type == 'status') {
                if (filter == 'Incomplete' || filter == 'All') {
                    this.filterBy.task_status = filter + ' Tasks';
                    this.filterBy.task_status_id = filter;
                } else {
                    this.filterBy.task_status = 'Status: ' + filter;
                    this.filterBy.task_status_id = data.id;
                }
            }
            this.reloadTasks();
        },
        selectCrew(e, data, type) {
            this.$nextTick(() => {
                if (type == 'crew') {
                    if (e.target.value != 'allcrews') {
                        let allIdx = this.filterBy.crew_ids.findIndex(item => item == 'allcrews');
                        if (allIdx !== -1) {
                            this.filterBy.crew.splice(allIdx, 1);
                            this.filterBy.crew_ids.splice(allIdx, 1);
                        }

                        let dataIdx = this.filterBy.crew.findIndex(item => item == data.name);
                        if (dataIdx !== -1) {
                            this.filterBy.crew.splice(dataIdx, 1);
                        } else {
                            this.filterBy.crew.push(data.name);
                        }

                    } else {
                        this.filterBy.crew = [];
                        this.filterBy.crew_ids = [];
                        this.filterBy.crew.push('All Crews');
                        this.filterBy.crew_ids.push('allcrews');

                    }

                } else {
                    if (e.target.value != 'allusers') {
                        let allIdx = this.filterBy.crew_ids.findIndex(item => item == 'allusers');
                        if (allIdx !== -1) {
                            this.filterBy.crew.splice(allIdx, 1);
                            this.filterBy.crew_ids.splice(allIdx, 1);
                        }

                        let dataIdx = this.filterBy.crew.findIndex(item => item == data.name);
                        if (dataIdx !== -1) {
                            this.filterBy.crew.splice(dataIdx, 1);
                        } else {
                            this.filterBy.crew.push(data.name);
                        }

                    } else {
                        this.filterBy.crew = [];
                        this.filterBy.crew_ids = [];
                        this.filterBy.crew.push('All Users');
                        this.filterBy.crew_ids.push('allusers');

                    }
                }
            });
            this.reloadTasks();
            // console.log('this.filterBy',this.filterBy)
        },
        selectSortBy(val) {
            this.sortBy = val;
            this.reloadTasks();
        },
        onToggle(e, val) {
            this.filterBy.crew = [];
            this.filterBy.crew_ids = [];

            if (val == 1) {
                this.filterBy.crew.push('All Crews');
                this.filterBy.crew_ids.push('allcrews');
                this.filterBy.type = 'crew';
            } else {
                this.filterBy.crew.push('All Users');
                this.filterBy.crew_ids.push('allusers');
                this.filterBy.type = 'user';
            }

            this.reloadTasks();
        },
        deselectAll() {
            this.filterBy.crew = [];
            this.filterBy.crew_ids = [];
        }
    },

};
</script>
