<template>
    <div class="row m-0 mb-2 align-items-center pointer time-off-item" 
        :style="styleUpdate(timeOff)"
        :class="getClass()"
        @mouseover="timeOff.show_drag_icon = true" 
        @mouseout="timeOff.show_drag_icon = false"
    >
        <!-- <div class="p-0" :class="viewType == 'week' && timeOff.date_range_overlap == 1 ? 'col-10' : 'col'"> -->
        <div class="p-0 col">
            <!-- WEEK VIEW -->
            <template v-if="viewType == 'week'">
                <template >
                    <div v-if="!timeOff.opacity_hide" class="d-flex align-items-center" :class="timeOff.date_range_overlap > 1 ? '' : 'justify-content-between'">
                        <span v-if="timeOff.user" class="d-flex align-items-center user-name">
                            <img :src="timeOff.user.picture" class="avatar-img rounded-circle mr-2 userimg" />
                            <span>{{ timeOff.user.name }}</span>
                        </span>
                        <span v-if="timeOff.date_range_overlap > 1" class="badge badge-dot"><i class="bg-gray mx-2"></i></span>
                        <span v-if="timeOff.date_range_overlap > 1" class="hours">{{ timeOff.time_off_hours }} hours</span>
                        <span v-if="timeOff.date_range_overlap <= 1" class="hours">({{ timeOff.time_off_hours }})</span>
                        <span v-if="timeOff.date_range_overlap > 1" class="badge badge-dot"><i class="bg-gray mx-2"></i></span>
                        <span v-if="timeOff.date_range_overlap > 1" class="description">{{ timeOff.description }}</span>
                    </div>
                </template>
            </template>
            <!-- DAY VIEW -->
            <template v-else>
                <template >
                    <div v-if="!timeOff.opacity_hide" class="d-flex align-items-center">
                        <span v-if="timeOff.user" class="d-flex align-items-center user-name">
                            <img :src="timeOff.user.picture" class="avatar-img rounded-circle mr-2 userimg" />
                            {{ timeOff.user.name }}
                        </span>
                        <span class="badge badge-dot"><i class="bg-gray mx-2"></i></span>
                        <span class="hours">{{ timeOff.time_off_hours }} hours</span>
                        <span class="badge badge-dot"><i class="bg-gray mx-2"></i></span>
                        <span class="description">{{ timeOff.description }}</span>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    
    export default {
        props: ['timeOff','dragColumn','viewType'],
        emits: [],
        components: {
        },
        data() {
			return {
                user: JSON.parse(localStorage.getItem('user')),
                companySettings: JSON.parse(localStorage.getItem('company_settings')),
                
			}
		},
        computed: {
            
        },
        mounted() {

        },
        methods: {
            today() {},
            styleUpdate(data) {
                // console.log('data',data)
                let newStyle = 'height: 37px; ';

                if( this.viewType == 'week' ) {
                    if( data.new_width ) {
                        newStyle += 'width: ' + data.new_width + '%; position: relative; z-index: 1;';
                    }
                    if( data.opacity_hide ) {
                        newStyle += 'opacity: 0; z-index: 0;';
                    }

                } else {
                    if( data.new_width ) {
                        newStyle += 'width: ' + data.new_width + '%; position: relative; z-index: 1;';
                    }
                    if( data.opacity_hide ) {
                        newStyle += 'opacity: 0; z-index: 0;';
                    }
                }
                
                return newStyle;
            },
            classUpdate(data) {
                return data.opacityHide ? '' : 'handle';
            },
            getClass() {
                let retClass = '';
                retClass += this.unassigned ? 'unassigned-task' : '';
                retClass += this.timeOff.id == 0 ? 'opacity-0' : 'draggable-item';

                return retClass;
            }
            
        },

    };
</script>
