<template>
    <div class="">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header border-bottom">
                <h5 class="modal-title" id="viewReviseTimeModalTitle">{{ $t('label.revise time') }}</h5>
                <button type="button" class="close" @click="$emit('cancelFnc')">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="row align-items-center justify-content-around border-bottom m-0 p-2">
                    <div class="cursor-pointer">
                        <img class="rounded-circle" :src="dataSelected.userpicture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                    </div>
                    <div class="col-9 col-xs-9 pl-0">
                        <h3 class="mb-0">{{ dataSelected.username }}</h3>
                        <h4 class="mb-0 font-weight-normal">{{ dataSelected.task_manager == '1' ? 'Crew Manager' : 'Crew Member' }}</h4>
                    </div>
                    <!-- Add Time Entry -->
                    <div class="col-2 col-xs-12 p-0 text-right">
                        <span id="addTimePunch">
                            <button type="button" class="btn btn-default btn-sm" :disabled="addTimePunches.length > 0" @click="addTimePunch()">
                                {{ $t('label.add time entry') }}
                            </button>
                        </span>
                        <b-popover v-if="addTimePunches.length > 0" placement="left" target="addTimePunch" triggers="hover focus" custom-class="bg-light-blue">
                            {{ $t('label.save current new entry to add another one') }}.
                        </b-popover>
                    </div>
                </div>
                <div class="">
                    <table class="table table-sm table-flush text-center">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">Date In</th>
                                <th scope="col">{{ $t('label.time start') }} (HH:MM)</th>
                                <th scope="col">Date Out</th>
                                <th scope="col" style="width: 180px;">{{ $t('label.time stop') }} (HH:MM)</th>
                                <th scope="col">{{ $t('label.time clocked') }}</th>
                                <!-- <th scope="col"></th> -->
                                <th scope="col">Reason for Change</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- New Time Punch -->
                            <tr id="newEntry" v-for="(dt, idx) in addTimePunches" :key="'new' + idx">
                                <td>
                                    {{ dt.date }}
                                    <!--  <date-picker v-model="dt.date" value-type="format" format="MM/DD/YYYY" input-class="h-35 form-control off-canvas-date off-canvas-include" :clearable="false" :editable="false">
                                    </date-picker>-->
                                </td>
                                <td>
                                    <date-picker v-model="dt.timein" :minute-step="5" format="hh:mm a" value-type="format" type="time" placeholder="hh:mm a"></date-picker>
                                </td>
                                <td class="text-bold">
                                    {{ dt.date}}
                                </td>
                                <td>
                                    <span class="d-flex btn-group">
                                        <date-picker v-model="dt.timeout" :minute-step="5" format="hh:mm a" value-type="format" type="time" placeholder="hh:mm a"></date-picker>
                                    </span>
                                </td>
                                <td>
                                    <button class="btn btn-info bg-light-blue btn-link btn-sm status-badge" style="border: 0px !important">
                                        {{ $t('label.new entry') }}
                                    </button>
                                </td>
                                <td>
                                    <textarea v-model="dt.timePunchEditNote" name="notes" class="form-control" :placeholder="$t('label.notes')"></textarea>
                                    <div v-if="isNoteFilled && idx === currentIndex" class="text-danger text-sm">*{{ $t('label.field required') }}</div>                                </td>
                                <td class="text-right">
                                    <a :id="'saveTimePunch' + idx" class="btn btn-sm pr-1 mr-0" @click="updateTimePunch(dt,idx)">
                                        <i class="ri-save-line heading-title"></i>
                                    </a>
                                    <a class="btn btn-sm pr-1" @click="deleteNewTimePunch(idx)">
                                        <i class="ri-delete-bin-line text-danger heading-title"></i>
                                    </a>
                                </td>
                            </tr>
                            <!-- Existing User Time Punches -->
                            <tr class="entries" v-for="(dt, idx) in userTimePunches" :key="idx">
                                <td class="text-bold">
                                    {{ dt.time_in | formatDate }}
                                </td>
                                <!-- Time In -->
                                <td>
                                    <date-picker v-model="dt.timein" format="hh:mm a" :minute-step="5" value-type="format" type="time" placeholder="hh:mm a"></date-picker>
                                    <!-- <input name="time_in" class="form-control h-35 flatpickr datetimepicker bg-white" v-model="dt.timein" readonly> -->
                                </td>
                                <td class="text-bold">
                                    {{ dt.time_out | formatDate}}
                                </td>
                                <!-- Time Out -->
                                <td>
                                    <span class="d-flex btn-group">
                                        <date-picker v-model="dt.timeout" format="hh:mm a" :minute-step="5" value-type="format" type="time" placeholder="hh:mm a"></date-picker>
                                        <!--<input name="time_out" class="form-control h-35 flatpickr datetimepicker bg-white" v-model="dt.timeout" readonly>-->

                                    </span>
                                </td>
                                <td>
                                    <span v-if="dt.time_out">{{ dt.durationComputed }}</span>
                                    <button v-else type="button" class="btn btn-clockin btn-link btn-sm status-badge">{{ $t('label.clocked in') }}</button>
                                </td>
                                <td>
                                    <textarea v-model="dt.timePunchEditNote" name="notes" class="form-control" :placeholder="$t('label.notes')"></textarea>
                                    <!-- <div v-if="isNoteFilled && idx === currentIndex" class="text-danger text-sm">*{{ $t('label.field required') }}</div> -->

                                </td>
                                <td class="text-right">
                                    <a class="btn btn-sm pr-1 mr-0" @click="updateTimePunch(dt,idx)">
                                        <i class="ri-save-line heading-title"></i>
                                    </a>
                                    <a class="btn btn-sm pr-1" @click="viewDeleteExistingConf(dt)">
                                        <i class="ri-delete-bin-line text-danger heading-title"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                        <thead class="thead-light">
                            <tr>
                                <th scope="col" colspan="4" class="text-right">{{ $t('label.total task time') }}</th>
                                <th scope="col">
                                    <h4 class="mb-0">{{ userTotalTaskTime }}</h4>
                                </th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
            <div class="text-right pt-2" style="padding:.75rem;">
                <button type="button" class="btn btn-primary mr-auto" @click="$emit('cancelFnc')">{{ $t('label.close') }}</button>
            </div>
        </div>

        <delete-modal v-if="showDeleteExistingConf" :newId="'TimePunch'" :title="$t('label.ARE YOU SURE YOU WANT TO DELETE THIS TIME PUNCH')" @onClose="showDeleteExistingConf = false" @customDeleteMethod="deleteExistingTimePunch()">
        </delete-modal>
    </div>
</template>
<script>
import DeleteModal from '@/components/ModalDelete2.vue';
import moment from '@/utils/date-time';
import bus from '@/utils/event-bus';
import { BPopover } from 'bootstrap-vue';
import { required } from 'vuelidate/lib/validators';

export default {
    props: ['dataSelected'],
    emits: ['cancelFnc', 'reload'],
    components: {
        BPopover,
        DeleteModal
    },
    data() {
        return {
            loader: null,
            toDelete: null,
            clockedInData: null,
            addTimePunches: [],
            userTimePunches: [],
            userTotalTaskTime: [],
            showDeleteExistingConf: false,
            isNoteFilled:true,
            originalUserTimePunches: [],
            currTimePunches: [],
            currentIndex: -1,

}
    },
    created() {
        if (this.dataSelected) {
            this.loader = this.$loading.show();
            this.userTimeRecords();
            this.getUserCurrentClockin();
        }
    },
    mounted() {
        // if (this.dataSelected) {
        //     this.loader = this.$loading.show();
        //     this.userTimeRecords();
        //     this.getUserCurrentClockin();
        // }
    },
    methods: {
        getUserCurrentClockin() {
            let userId = this.dataSelected.user.id;
            axios.get("/get-user-current-clockin/" + userId).then(response => {
                this.clockedInData = response.data.result;
            }).catch(err => console.log(err));
        },
        userTimeRecords() {
            var request_fields = {
                'date': null,
                'user_data': this.dataSelected,
                'task_id': this.dataSelected.task_id,
                'user_id': this.dataSelected.user.id,
                'work_date': this.dataSelected.workDate,
                'comingFrom': 'ReviseTimeModal',
            };

            axios.post('/user-time-punches', request_fields).then(response => {
                this.loader.hide()
                this.userTimePunches = response.data.result;
                this.userTimePunches.forEach((item)=> {
                    item.timePunchEditNote = '';
                })
                this.originalUserTimePunches = this.userTimePunches;
                this.userTotalTaskTime = response.data.overall_time
            }).catch(err => {
                console.log(err)
            })
        },
        reload() {
            this.userTimeRecords();
            this.getUserCurrentClockin();
            this.$emit('reload');
            bus.$emit('checkClockinStatus');
            bus.$emit('reviseTimeModalUpdate');
        },

        // return cancel back to parent
        cancelAdd() {
            this.resetForm();
            this.$emit('cancelFnc');
        },
        addTimePunch() {
            if (this.addTimePunches.length < 1) {
                this.addTimePunches.push({
                    task_id: this.dataSelected.task_id,
                    user_id: this.dataSelected.user.id,
                    type: 'new',
                    date: moment(this.dataSelected.workDate).format('MM/DD/YYYY'),
                    timein: moment(new Date()).format('hh:mm A'),
                    timeout: moment(new Date()).format('hh:mm A'),
                    timePunchEditNote: ''
                });
            }
        },
        deleteNewTimePunch() {
            this.addTimePunches = [];
        },
        viewDeleteExistingConf(timePunch) {
            this.showDeleteExistingConf = true;
            this.toDelete = timePunch;
        },
        deleteExistingTimePunch() {
            let temp = this.toDelete;
            temp.reqForm = 'reviseTimeModal';
            temp.log_type = 'deleting TimePunch';

            if(temp.timePunchEditNote || temp.timePunchEditNote != '') {
                this.isNoteFilled = false;
                this.loader = this.$loading.show();
                axios.post(`/delete-time-punch/` + this.toDelete.id).then(response => {
                    axios.post('/add-task-activity-log',temp).then(res => {

                
                        this.loader.hide();
                        this.reload();
                        this.toDelete.active = 0;
                        bus.$emit('banner-success', {
                            message: this.$t('label.time punch deleted successfully') + '!'
                        });
                    }).catch(err => console.log(err));
                }).catch(err => console.log(err));

                } else {
                this.isNoteFilled = true;
                bus.$emit('banner-error', {
                    message: "Please Provide a Reason for the Time Deletion."
                });
            }
        },
        async updateTimePunch(data,idx) {
            this.currentIndex = idx;
            if(data.timePunchEditNote || data.timePunchEditNote != '') {
                this.isNoteFilled = false;
            data.reqFrom = "reviseTimeModal";
                try {
                    // Allow clocking in only if they won't clock the person in twice. 
                    if (!this.clockedInData || (data.timeout !== null)) {
                        this.loader = this.$loading.show();
                        // Validate the times entered
                        await this.compareTimes(data.timein, data.timeout)

                        axios.post('/update-user-time-punch', data).then(response => {

                            this.reload()
                            if (data.type == 'new') this.addTimePunches = []
                            bus.$emit('banner-success', {
                                message: this.$t('label.time punch saved successfully') + '!'
                            });
                        }).catch(err => {
                            this.loader.hide()
                            // Let user know if there is time overlap
                            if (err.data == 'overlap') {
                                bus.$emit('banner-error', {
                                    message: this.$t('label.the time punch is overlapping with another time punch and cannot be saved') + '.'
                                });
                            }
                        });
                    } else {
                        this.loader.hide()
                        bus.$emit('banner-error', {
                            message: this.dataSelected.user.name + " " + this.$t('label.is already clocked in') + "."
                        });
                    }
                }
                catch (err) {
                    this.loader.hide()
                    if (err == 'timeFail') {
                        bus.$emit('banner-error', {
                            message: 'End Time cannot be the same or before Start Time.'
                        });
                    }
                }
            } else {
                this.isNoteFilled = true;
                bus.$emit('banner-error', {
                    message: "Please Provide a Reason for the Time Revision."
                });
            }
        },
        compareTimes(startTime, endTime) {
            return new Promise((resolve, reject) => {
                let timeIn = moment(startTime, 'h:mm a')
                let timeOut = moment(endTime, 'h:mm a')
                let diff = timeOut.diff(timeIn, 'seconds')
                if (diff <= 0) {
                    reject('timeFail')
                }
                else {
                    resolve();
                }
            })
        },
        resetNote() {
            // just reset note variable to be ''
        }
    },
}
</script>

<style>
.off-canvas-date {
    cursor: pointer;
    background-color: white !important;
}
</style>