<template>
	<div class="col-xl-12 order-xl-1 mobile-p-0">
		<div class="card">
			<div class="card-header">
				<div class="row align-items-center justify-content-between">
					<div>
						<h3 class="mb-0 d-flex align-items-center"><i class="ri-todo-line heading-title"></i> {{ $t('label.tasks') }}</h3>
					</div>
					<div>
						<!-- Add Task Button -->
						<button type="button" class="btn btn-sm btn-default" @click="addModal">
							{{ $t('label.add task') }}
						</button>
						<!-- All tasks Menu -->
						<button v-if="!is_template" type="button" class="btn btn-default btn-sm m-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<i class="fas fa-ellipsis-h"></i>
						</button>
						<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
							<a class="dropdown-item pointer" @click="openViewAllJobTimeModal()">
								<i class="ri-time-line"></i> {{ $t('label.view all job time') }}
							</a>
						</div>
					</div>
				</div>
			</div>

			<div v-if="alltasks.length == 0">
				<div class="card-body m-0 py-0">
					<div class="bg-white text-center row align-items-center m-0">
						<i class="ri-todo-line ni-8x w-100 color-gray-400"></i>
						<p class="w-100 font-weight-normal">{{ $t('label.No Tasks have been added to this Job') }}.</p>
					</div>
				</div>
			</div>
			<div v-if="!is_template && alltasks.length > 0" class="table-responsive pb-4">
				<table class="table table-flush table-sm m-0" style="margin: 0 !important;" id="datatable-alltasks">
					<thead class="thead-light">
						<tr>
							<th scope="col" class="taskname">{{ $t('label.task name') }}</th>
							<th scope="col">{{ $t('label.dates') }}</th>
							<th scope="col" class="hidden-sm-down">{{ $t('label.assigned to') }}</th>
							<th scope="col">Piece Rate</th>
							<th scope="col" class="hidden-sm-down">{{ $t('label.time estimate') }}</th>
							<th scope="col">{{ $t('label.time clocked') }}</th>
							<th scope="col">{{ $t('label.status') }}</th>
							<th scope="col" style="width: 5%"></th>
						</tr>
					</thead>
					<tbody>
						<template v-if="alltasks">
							<!-- Loop Tasks -->
							<tr v-for="(data, index) in paginatedAllTasks" :key="index" class="pointer" @click="showTaskOffcanvas(data)">
								<!-- Task Name -->
								<td class="sp-va-mid">
									<a :id="'taskid-' + data.id" class="cursor-pointer">{{ data.name }}</a>
								</td>
								<!-- Start Date and Due Date -->
								<td class="sp-va-mid" data-toggle="modal">
									<span :class="{ 'due-dt-only': !data.start_date }">
										{{ data.date_range_text }}
									</span>
								</td>
								<!-- Assigned employee -->
								<td data-toggle="modal" class="hidden-sm-down sp-va-mid">
									<div class="row align-items-center m-0">
										<div @click="getUserInfo(data.user.id, data.task_manager == '1' ? 'Crew Manager' : 'Crew Member')" class="avatar avatar-sm rounded-circle float-left cursor-pointer" :class="{ 'crew-member-avatar': 0 < i }" v-for="(d, i) in data.assigned_to" :value="d.id" :key="i">
											<img v-if="d.user" :src="d.user.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
										</div>
									</div>
								</td>
								<td data-toggle="modal" class="sp-va-mid">{{ data.piece_rate }}</td>
								<!-- Estimated time -->
								<td data-toggle="modal" class="hidden-sm-down sp-va-mid">{{ data.time_estimate }} {{ $t('label.hrs') }}</td>
								<!-- Time Logged -->
								<td data-toggle="modal" class="sp-va-mid">{{ data.time_clocked | hourToFormattedTime }} ({{ data.time_clocked | formatHour }} {{ $t('label.hrs') }})</td>
								<!-- task status -->
								<td data-toggle="modal" class="sp-va-mid">
									<span class="badge badge-dot">
										<i :style="`background-color: ${data.task_status.status_color}`"></i>
										{{ data.task_status ? data.task_status.name : '' }}
									</span>
								</td>
								<!-- task menu dropdown -->
								<td class="text-right sp-va-mid" @click.stop>
									<b-dropdown text="dropdown" class="m-1" right @click.stop>
										<template #button-content>
											<div class="px-3 py-1">
												<i class="fas fa-ellipsis-v"></i>
											</div>
										</template>

										<b-dropdown-item @click.stop="onClickEditModal(data)">
											<div class="text-default">
												<i class="ri-pencil-line"></i> {{ $t('label.edit task') }}
											</div>
										</b-dropdown-item>
										<b-dropdown-item v-if="data.task_status.name != 'Closed'" @click.stop="markAsComplete(data)">
											<div class="text-default">
												<i class="ri-check-line"></i> {{ $t('label.complete task') }}
											</div>
										</b-dropdown-item>
										<b-dropdown-item v-if="data.task_status.name == 'Closed'">
											<span class="text-default">
												<i class="fas fa-times mr-2"></i> {{ $t('label.task closed') }}
											</span>
										</b-dropdown-item>

										<!-- Disabled Delete Btn -->
										<b-dropdown-item v-if="!data.can_delete">
											<div @mouseover="runTooltip()" class="text-muted" data-toggle="tooltip" data-placement="left" title="This Task cannot be deleted because time has been clocked to it, or someone is currently clocked into the Task.">
												<i class="ri-delete-bin-line"></i> {{ $t('label.delete task') }}
											</div>
										</b-dropdown-item>
										<b-dropdown-item v-if="data.can_delete" @click.stop="deleteTaskNotify(true, data.id)">
											<div class="text-danger">
												<i class="ri-delete-bin-line"></i> {{ $t('label.delete task') }}
											</div>
										</b-dropdown-item>
									</b-dropdown>
								</td>
							</tr>
						</template>
					</tbody>
					<thead class="thead-light">
						<tr>
							<th scope="col" colspan="4" class="text-right hidden-sm-down">{{ $t('label.total time clocked') }}</th>
							<th scope="col" colspan="2" class="text-right hidden-sm-up">{{ $t('label.total time clocked') }}</th>
							<th scope="col" class="hidden-sm-down sp-va-mid">
								<h4 class="mb-0">{{ total_time_clocked | hourToFormattedTime }} ({{ total_time_clocked }} {{ $t('label.hrs') }})</h4>
							</th>
							<th scope="col" class="hidden-sm-up sp-va-mid" colspan="3">
								<h4 class="mb-0">{{ total_time_clocked | hourToFormattedTime }} ({{ total_time_clocked }} {{ $t('label.hrs') }})</h4>
							</th>
							<th scope="col" class="hidden-sm-down"></th>
							<th scope="col" class="hidden-sm-down"></th>
						</tr>
					</thead>
				</table>
				<table-pagination v-model="paginatedAllTasks" :all-rows="sortArrays(alltasks)" :perPage="10">
				</table-pagination>
			</div>

			<!-- Job Template Tasks With Gaps -->
			<div class="card-body m-0 p-0" v-if="is_template && alltasks.length > 0 && taskTimeGaps.length">
				<div class="bg-light-blue p-2 px-3">
					<div class="row m-0">
						<h3 class="pr-4">{{ $t('label.first task start date') }} ({{ $t('label.optional') }})</h3>
						<p>{{ $t('label.you can automatically set the start date for the first task in this template below') }}.</p>
					</div>
					<div class="form-group m-0">
						<div class="row m-0 align-items-center" v-for="(data, index) in taskTimeGaps" :key="index">
							<template v-if="index == 0">
								<input @blur="updateTaskGap" type="text" v-model="data.gap" name="name" class="form-control col-lg-1 col-1 col-xs-2" onkeypress="return event.charCode >= 48 && event.charCode <= 57" required autofocus />
								<label class="col-lg-4 col-5 col-xs-6">{{ $t('label.days after the job start date') }}.</label>
								<div class="row m-0 col-lg-7 col-6 col-xs-4 p-xs-0">
									<div class="form-check pr-5 p-xs-0 p-mobile-0">
										<input type="checkbox" class="form-check-input" id="exampleCheck1-sat" v-model="data.is_skip_sat" @change="updateTaskGap">
										<label class="form-check-label" for="exampleCheck1-sat">{{ $t('label.skip saturday') }}</label>
									</div>
									<div class="form-check p-xs-0">
										<input type="checkbox" class="form-check-input" id="exampleCheck2-sun" v-model="data.is_skip_sun" @change="updateTaskGap">
										<label class="form-check-label" for="exampleCheck2-sun">{{ $t('label.skip sunday') }}</label>
									</div>
								</div>
							</template>
						</div>
					</div>
				</div>
				<div>
					<draggable class="list-group h-100" :list="alltasks" group="people" @change="drag" handle=".handle">
						<template v-if="alltasks">
							<div v-for="(data, index) in alltasks" :key="index">
								<div v-if="data.isTask" class="bg-violet p-2 px-3 text-white d-flex align-items-center handle" @mouseover="hideBtn(false, index)" @mouseleave="hideBtn(true, index)">
									<div>{{ data.sequence }} - {{ data.name }}</div>
									<span class="ml-auto pr-3 my--1">
										<button :id="'editBtn-' + index" class="btn btn-sm btn-secondary text-primary editBtn" data-toggle="modal" @click="onClickEditModal(data)">
											<i class="ri-pencil-line pr-2"></i>{{ $t('label.edit task') }}
										</button>
										<button :id="'dltBtn-' + index" class="btn btn-sm btn-danger editBtn" data-toggle="modal" :disabled="deletingTempTask" @click="deleteTaskNotify(true, data.id)">
											<i class="fas fa-trash-alt pr-2"></i>{{ $t('label.delete task') }}
										</button>
									</span>
									<div><i class="fas fa-grip-vertical ml-auto"></i></div>
								</div>
								<div v-else class="bg-pink p-2 px-3">
									<div class="row m-0 align-items-center">
										<h3 class="pr-4">{{ $t('label.time gap') }} ({{ $t('label.optional') }})</h3>
										<div draggable="false" class="form-group m-0">
											<div class="row m-0 align-items-center">
												<input @blur="updateTaskGap" type="text" v-model="data.gap" :name="'gap-' + index" class="form-control col-lg-1 col-1 col-xs-2" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
												<label class="col-lg-6 col-5 col-xs-6 p-xs-2">{{ $t('label.days between the tasks above and below') }}.</label>
												<div class="row m-0 col-lg-5 col-6 col-xs-4 p-xs-0">
													<div class="form-check pr-5 p-xs-0 p-mobile-0">
														<input type="checkbox" class="form-check-input" :id="'exampleCheck1-' + index" v-model="data.is_skip_sat" @change="updateTaskGap">
														<label class="form-check-label" :for="'exampleCheck1-' + index">{{ $t('label.skip saturday') }}</label>
													</div>
													<div class="form-check p-xs-0">
														<input type="checkbox" class="form-check-input" :id="'exampleCheck2-' + index" v-model="data.is_skip_sun" @change="updateTaskGap">
														<label class="form-check-label" :for="'exampleCheck2-' + index">{{ $t('label.skip sunday') }}</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>
					</draggable>
				</div>
				<div class="p-3"></div>
			</div>
		</div>

		<!-- ADD Modal -->
		<modal class="" name="addTaskModal" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
			<modal-task :jobId="jobid" :current="current" :isTemplate="is_template" source="task" @getTasks="getTasks" @onSave="$emit('onTasksChange')" @cancelFnc="$modal.hide('addTaskModal')" />
		</modal>

		<!-- Edit Task Modal -->
		<modal class="" name="editTaskModal" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
			<modal-task :jobId="jobid" :task="task" :current="current" :isTemplate="is_template" source="task" @getTasks="getTasks" @cancelFnc="$modal.hide('editTaskModal')" @onSave="$emit('onTasksChange')" />
		</modal>

		<div class="modal fade" id="addCrewModal1" tabindex="-1" role="dialog" aria-labelledby="addCrewModal1Title" aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header border-bottom">
						<h5 class="modal-title">{{ $t('label.add crew') }}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<i class="ri-close-line"></i>
						</button>
					</div>
					<form method="post" action="" autocomplete="off" enctype="multipart/form-data">
						<div class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<label class="form-control-label text-muted" for="input-name">{{ $t('label.crew') }}</label>
										<select class="form-control" v-model="selectedCrew" required>
											<option v-for="data in allCrews" :value="data.id" :key="data.id">{{ data.name }}</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer border-top">
							<button type="button" class="btn btn-primary mr-auto" @click="getCrewMembers" data-dismiss="modal">{{ $t('label.add crew') }}</button>
							<button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('label.close') }}</button>
						</div>
					</form>
				</div>
			</div>
		</div>


		<!-- Job Time Modal -->
		<modal class="my-5" name="viewAllJobTimeModal1" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
			<ModalViewAllJobTime :jobid="jobid" :user="user" @reload="closeViewAllJobTimeModal" @closeFunc="closeViewAllJobTimeModal"></ModalViewAllJobTime>
		</modal>

		<!-- Clock out notification Modal -->
		<div class="modal fade" id="clockOutNoti21" tabindex="-1" role="dialog" aria-labelledby="clockOutNoti21" aria-hidden="true">
			<div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
				<div class="modal-content bg-gradient-danger">
					<div class="modal-header">
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<i class="ri-close-line"></i>
						</button>
					</div>
					<form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="clockOut">
						<div class="modal-body">
							<div class="py-3 text-center">
								<i class="ri-stop-circle-line ni-3x"></i>
								<h4 class="heading mt-4 text-uppercase">{{ $t('label.CONFIRM CLOCK OUT OF MEMBER') }}</h4>
								<p>{{ $t('label.Are you sure you want to clock out your member') }}?</p>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-white">{{ $t('label.Yes, Clock Out') }}</button>
							<button type="button" class="btn btn-link text-white ml-auto closeclockOutNoti21" data-dismiss="modal">{{ $t('label.cancel') }}</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<!-- Task Delete Modal -->
		<modal-delete-component v-if="showTaskDlt" :new-id="'Task'" :title="$t('label.ARE YOU SURE YOU WANT TO DELETE THIS TASK')" @onClose="deleteTaskNotify" @customDeleteMethod="deleteJobTask">
		</modal-delete-component>

		<modal name="taskAddPrompt" height="auto" width="100%" :max-width="650" :scrollable="true" :reset="true" :adaptive="true">
			<div class="modal-content">
				<div class="modal-header border-bottom">
					<h5 class="modal-title" id="viewAllJobTimeModal1Title">{{ $t('label.missing job tasks') }}</h5>
					<button type="button" class="close" @click="$modal.hide('taskAddPrompt')">
						<i class="ri-close-line"></i>
					</button>
				</div>
				<div class="modal-body text-center">
					<div class="form-group">{{ $t('label.No tasks have been added to this job Would you like to add a task now') }}</div>
					<div>
						<button type="button" class="btn btn-success" @click="addModal(); $modal.hide('taskAddPrompt')">Yes</button>
						<button type="button" class="btn btn-outline-default" @click="$modal.hide('taskAddPrompt')">No</button>
					</div>
				</div>
			</div>
		</modal>
	</div>
</template>


<script>
import bus from '@/utils/event-bus';
import { DropdownPlugin } from 'bootstrap-vue';
import * as $ from 'jquery';
import * as _ from 'lodash';
import Vue from 'vue';
import { CurrencyInput } from 'vue-currency-input';
import Draggable from "vuedraggable";
import { mapGetters } from 'vuex';
import ModalDelete2Vue from './ModalDelete2.vue';
import ModalTask from './ModalTask.vue';
import ModalViewAllJobTime from './ModalViewAllJobTime.vue';
import TablePagination from './TablePagination.vue';

Vue.use(DropdownPlugin);

export default {
	props: ['jobid', 'job', 'is_template', 'user', 'parentChanged'],
	emits: ['onTasksChange'],
	components: {
		Draggable,
		ModalTask,
		CurrencyInput,
		TablePagination,
		ModalViewAllJobTime,
		modalDeleteComponent: ModalDelete2Vue,
	},
	data() {
		return {
			companySettings: JSON.parse(localStorage.getItem('company_settings')),
			task: {},
			allUsers: [],
			allCrews: [],
			alltasks: [],
			paginatedAllTasks: [],
			materials: [],
			crewMembers: [],
			customfields: [],
			alltasktypes: [],
			taskTimeGaps: [],
			alltaskstatus: [],
			removedMaterials: [],
			taskMaterialsFromJob: [],
			current: 0,
			sourceMaterial: 0,
			total_time_clocked: 0,
			selectedCrew: '',
			taskId: null,
			selectedDate: null,
			username: null,
			userpicture: null,
			task_manager: null,
			delete_id: null,
			//	dataTable: null,
			openEdit: false,
			editMode: false,
			showTaskDlt: false,
			showDropdown: false,
			openEditModal: false,
			showCrewDropdown: false,
			deletingTempTask: false,
			showTaskEditModal: false,
			isTaskTimeGapSaved: false,
			form: {
				add: {
					inputs: [],
					name: null,
					status: null,
					due_date: null,
					start_date: null,
					task_manager: null,
					time_estimate: null
				},
				edit: {
					inputs: [],
					id: null,
					name: null,
					status: null,
					due_date: null,
					start_date: null,
					task_manager: null,
					time_estimate: null,
				}
			},
			columns: [
				'name',
				'start_date',
				'assigned_to',
				'time_estimate',
				'time_clocked',
				'task_status.name',
				'actions'
			],
			options: {
				perPageValues: [],
				filterable: false,
				headings: {
					actions: '',
					name: this.$i18n.t('label.task name'),
					start_date: this.$i18n.t('label.start date'),
					assigned_to: this.$i18n.t('label.assigned to'),
					time_clocked: this.$i18n.t('label.time clocked'),
					'task_status.name': this.$i18n.t('label.status'),
					time_estimate: this.$i18n.t('label.time estimate')
				},
				columnsClasses: {
					name: 'text-left',
					start_date: 'text-left',
					assigned_to: 'text-left',
					time_clocked: 'text-left',
					time_estimate: 'text-left',
					'task_status.name': 'text-left',
					actions: 'text-center actions-column'
				},
				editableColumns: ['name', 'start_date', 'assigned_to', 'time_estimate', 'time_clocked', 'task_status.name'],
				sortable: ['name', 'start_date', 'assigned_to', 'time_estimate', 'time_clocked', 'task_status.name']
			},
			jobMaterialsColumns: ['location', 'material', 'unit', 'quantity', 'notes'],
			jobMaterialsOptions: {
				perPageValues: [],
				filterable: false,
				columnsClasses: {
					unit: 'text-left',
					notes: 'text-left',
					location: 'text-left',
					material: 'text-left',
					quantity: 'text-left'
				},
				headings: {
					unit: this.$i18n.t('label.unit'),
					notes: this.$i18n.t('label.notes'),
					location: this.$i18n.t('label.location'),
					material: this.$i18n.t('label.material'),
					quantity: this.$i18n.t('label.quantity')
				}
			},
		}
	},
	computed: {
		materialsIsInvalid() {
			return this.materials.length < 1;
		},
		requireTaskMaterialFields() {
			return (this.sourceMaterial == 2) ? true : false;
		},
		...mapGetters({
			coordinates: 'coordinates',
		})
	},
	watch: {
		alltasks() {
			this.hideAllBtns();
		},
		parentChanged(newVal, oldVal) {
			// Parent wants current component to reach to change
			if (newVal) this.getTasks();
		}
	},
	created() {
		bus.$on('taskOffCanvasUpdate', this.getTasks);
		bus.$on('MaterialsSectionDltTaskMat', this.getTasks);
		bus.$on('MaterialModalTaskUpdate', this.getTasks);

		this.getCustomfields();
		this.getTaskTypes();
		this.getTaskStatus();
		this.getUsers();
		this.getCrews();
		this.getTasks();
	},
	destroyed() {
		// Remove Event Listeners
		bus.$off('taskOffCanvasUpdate', this.getTasks);
		bus.$off('MaterialsSectionDltTaskMat', this.getTasks);
		bus.$off('MaterialModalTaskUpdate', this.getTasks);
	},
	mounted() {
		bus.$on('deleteF', () => {
			this.deleteTask();
		});

		bus.$on('clockoutFunc', () => {
			this.clockOut();
		});
	},


	methods: {
		hideAllBtns() {
			this.$nextTick(() => {
				if ($('.editBtn').length)
					$('.editBtn').hide();
			});
		},
		hideBtn(isHide, id) {
			$('.editBtn').hide();
			if (!isHide) {
				$('#editBtn-' + id).show();
				$('#dltBtn-' + id).show();
			}
		},
		drag(evt) {
			let gapArr = [];
			let tasks = [];
			let newTasks = [];
			let ctr = 0;

			gapArr = this.alltasks.filter((data, index) => {
				return !data.isTask;
			});

			tasks = this.alltasks.filter((data, index) => {
				return data.isTask;
			});

			for (let x = 0; x < tasks.length; x++) {
				newTasks[ctr] = tasks[x];
				newTasks[ctr].sequence = (ctr / 2) + 1;
				ctr++;
				if ((tasks.length - 1) != x) {
					newTasks[ctr] = gapArr[x];
					ctr++;
				}
			}

			this.alltasks = newTasks;
			this.updateTaskGap();
		},
		getTaskTypes() {
			axios.get('/tasktypes')
				.then(response => {
					this.alltasktypes = response.data.result;
				})
				.catch(err => console.error(err));
		},
		getTaskStatus() {
			axios.get('/taskstatus')
				.then(response => {
					this.alltaskstatus = response.data.result;
				})
				.catch(err => console.error(err));
		},
		getUsers() {
			axios.get('/get-all-users')
				.then(response => {
					this.allUsers = response.data.result;
				})
				.catch(err => console.error(err));
		},
		getTasks() {
			axios.get('/task-time-gaps/' + this.jobid).then(response => {
				let temporaryTimeGaps = response.data.result;

				axios.get('/tasks/' + this.jobid).then(response => {
					this.alltasks = response.data.result;
					let searchTaskId = new URL(location.href).searchParams.get('taskid');

					$('#taskid-' + searchTaskId).click();

					this.alltasks.forEach((data) => {
						if (data.id == searchTaskId) {
							this.showTaskDetails(data);
						}
					});

					// when the job has no tasks, show the add a task prompt. Not on templates
					if (this.alltasks.length == 0 && !this.is_template)
						this.$modal.show('taskAddPrompt')

					if (this.is_template) {
						this.alltasks = _.sortBy(this.alltasks, ['sequence']);
						let ctr = 0;
						let newTasks = [];
						this.alltasks.forEach((data, index) => {
							newTasks[ctr] = data;
							data.isTask = true;
							ctr++;
							if ((this.alltasks.length - 1) != index) {
								newTasks[ctr] = {
									gap: temporaryTimeGaps[index + 1].gap,
									is_skip_sat: temporaryTimeGaps[index + 1].is_skip_sat,
									is_skip_sun: temporaryTimeGaps[index + 1].is_skip_sun,
									isTask: temporaryTimeGaps[index + 1].isTask,
									id: temporaryTimeGaps[index + 1].id,
									isTask: false,
									job_id: temporaryTimeGaps[index + 1].job_id,
								};
								ctr++;
							}
						});
						this.alltasks = newTasks;
					}

					this.total_time_clocked = response.data.total_time_clocked;
					this.taskTimeGaps = temporaryTimeGaps;
					//	this.runDataTable();
				}).catch(err => console.error(err));
			});
		},
		getCrews() {
			axios.get('/get-all-crews2').then(response => {
				this.allCrews = response.data.result;
			});
		},
		getCrewMembers(crewId) {
			axios.get('/get-crew-members/' + this.selectedCrew).then(response => {
				this.crewMembers = response.data.result;
				this.populateCrewMembers();
				this.showCrewDropdown = false
			});
		},
		getJobMaterials() {
			axios.get('/get-job-materials/' + this.jobid).then(response => {
				this.taskMaterialsFromJob = response.data.result;
			}).catch(err => console.error(err));
		},
		updateTaskGap() {
			let tasks = this.alltasks.filter((data, index) => { return data.isTask }),
				request_fields = {
					'taskTimeGaps': this.verifyTaskTimeGaps(),
					'tasks': tasks,
					'jobId': this.jobid,
				};

			axios.post('/task-time-gaps/update', request_fields).then(response => {
				this.$emit('onTasksChange');
				this.isTaskTimeGapSaved = response.data.result;
			}).catch(err => console.error(err));
		},
		verifyTaskTimeGaps() {
			// Validate and autofill (if necessary) all fields for gap entries 
			let timeGaps = [];

			// First Task Start Date gap
			if (this.taskTimeGaps[0].gap < 1)
				this.taskTimeGaps[0].gap = 0;
			timeGaps.push(this.taskTimeGaps[0]);

			// Any additional gaps
			return timeGaps.concat(this.alltasks.filter((data, index) => {
				if (!data.isTask) {
					if (data.gap < 1)
						data.gap = 0;
				}
				return !data.isTask;
			}));
		},
		showTaskDetails(data) {
			this.taskId = data.id;
			this.current = data.task_status_id;
			this.selectedDate = moment(data.start_date);
			this.openEdit = true;

			let tempData = {
				taskdata: data,
				taskId: data.id,
				selectedDate: this.selectedDate,
				current: this.current
			}

			bus.$emit('getTasksTEMP', tempData);
			$('#viewTaskDetailsModal').modal('show');
		},
		onClickEditModal(item) {
			this.task = item
			this.openEditModal = true;
			this.$modal.show('editTaskModal')
		},
		onCloseEditModal() {
			this.openEditModal = false;
		},
		markAsComplete(data) {
			var request_fields = {
				'task_id': data.id,
			};
			axios.post('/mark-as-complete', request_fields)
				.then(response => {
					this.getTasks();
				})
				.catch(error => {
					console.log('list carriers error:', error)
				});
		},
		editModalAdd() {
			this.form.edit.inputs.push({
				assigned_to: '',
			})
		},
		getCustomfields() {
			axios.get(`/get-customfields?job_id=${this.jobid}&model_name=task`)
				.then(response => {
					this.customfields = response.data.data;

					let add_data = {}, edit_data = {};
					response.data.data.forEach(cf => {
						add_data[cf.input_name] = null;
						edit_data[cf.input_name] = cf.value;
					});
					this.form.add = { ...this.form.add, ...add_data };
					this.form.edit = { ...this.form.edit, ...edit_data };
				})
				.catch(err => console.error(err));
		},
		onCustomfieldInput(value, customfield, subForm = 'add') {
			const foundIndex = this.customfields.findIndex(cf => cf.id === customfield.id);
			this.form[subForm][customfield.input_name] = this.customfields[foundIndex].value = value;
		},
		resetCustomFields(subForm = 'add') {
			this.customfields.forEach(customfield => {
				const customfieldValue = customfield.type === 'checkbox' ? [] : '';
				this.onCustomfieldInput(customfieldValue, customfield);
			});
		},
		populateCrewMembers() {
			let main = this;
			let foremanIndex;
			this.form.edit.inputs = [];
			this.form.add.inputs = [];

			this.crewMembers.forEach(function (member, index) {
				let userId = member.user.id;

				if (1 === member.is_foreman) {
					foremanIndex = index;
				}

				if (main.editMode) {
					main.form.edit.task_manager = foremanIndex;
					main.form.edit.inputs.push({
						assigned_to: userId,
					});
				} else {
					main.form.add.task_manager = foremanIndex;
					main.form.add.inputs.push({
						assigned_to: userId,
					});
				}
			});
		},
		removeTaskMaterial(index) {
			this.materials.splice(index, 1);
		},
		editRemoveTaskMaterial(index, taskMaterialId) {
			if ('' == taskMaterialId) {
				this.materials.splice(index, 1);
			} else {
				let removeCheck = confirm("Are you sure to remove this item?");

				if (removeCheck) {
					this.removedMaterials.push(taskMaterialId);
					this.materials.splice(index, 1);
				}
			}
		},
		getTaskMaterials() {
			if ('1' == this.sourceMaterial) {
				this.getJobMaterials();
			}
		},
		addNewTaskMaterial() {
			this.materials.push({
				id: '',
				location: '',
				material: '',
				unit: '',
				notes: ''
			});
		},
		resetModal() {
			this.resetCustomFields();

			// Materials related
			this.sourceMaterial = 0;
			this.materials = [];
			this.removedMaterials = [];
			this.addNewTaskMaterial();

			// Assignee related
			this.form.add.inputs = [];
		},
		addModal() {
			this.resetModal();
			this.$modal.show('addTaskModal')
		},
		openViewAllJobTimeModal() {
			this.$modal.show('viewAllJobTimeModal1');
		},
		closeViewAllJobTimeModal() {
			this.$modal.hide('viewAllJobTimeModal1');
		},
		deleteTaskNotify(show, delete_id) {
			this.showTaskDlt = show;
			this.delete_id = delete_id;
		},
		deleteJobTask() {
			if (this.is_template)
				this.deleteTemplateTask();
			else
				this.deleteTask();
		},
		deleteTask() {
			axios.post('/delete-task/' + this.delete_id).then(response => {
				this.showTaskDlt = false;
				this.getTasks();

				bus.$emit("banner-success", {
					message: $t('label.Task successfully deleted')
				});
			}).catch(err => {
				//console.log('list carriers error:', error)
			});
		},
		deleteTemplateTask() {
			this.deletingTempTask = true;
			let toDelete = {
				start_gap: {},
				gap_id: null,
				task_id: this.delete_id
			};

			// Find id of correct corresponding gap of the given task
			this.alltasks.forEach((item, idx) => {
				if (item.id === this.delete_id) {
					// If is first task, must handle start gap
					if (idx === 0) {
						// Replace the start gap with the gap below
						if (this.alltasks.length > 1) {
							toDelete.start_gap.id = this.taskTimeGaps[0].id;
							toDelete.start_gap.gap = this.alltasks[idx + 1].gap;
							toDelete.start_gap.is_skip_sat = this.alltasks[idx + 1].is_skip_sat;
							toDelete.start_gap.is_skip_sun = this.alltasks[idx + 1].is_skip_sun;
							toDelete.gap_id = this.alltasks[idx + 1].id;
						}
						// Remove start gap
						else {
							toDelete.gap_id = this.taskTimeGaps[0].id;
						}
					}
					// else grab the gap id for the gap above the task
					else {
						toDelete.gap_id = this.alltasks[idx - 1].id;
					}

					return;
				}
			});

			axios.post("/delete-template-task", toDelete).then(response => {
				this.deletingTempTask = false;
				this.showTaskDlt = false;
				this.getTasks();
				this.$emit('onTasksChange');

				bus.$emit("banner-success", {
					message: $t('label.Task successfully deleted')
				});
			}).catch(err => console.log(err));
		},
		runTooltip() {
			$('[data-toggle="tooltip"]').tooltip()
		},
		sortArrays(arrays) {
			if (this.is_template)
				return _.orderBy(arrays, 'sequence', 'asc');
			else
				return arrays;
		},
		runDataTable() {
			if (this.dataTable !== null)
				this.dataTable.destroy();

			// Build Datatable
			this.$nextTick(() => {
				this.dataTable = $("#datatable-alltasks").DataTable({
					order: [],
					pageLength: 25,
					"searching": false,
					"lengthChange": true,
					"bDestroy": true,
					"sDom": 'Rfrtilp',
					language: {
						paginate: {
							previous: "<i class='fas fa-angle-left'>",
							next: "<i class='fas fa-angle-right'>"
						},
						lengthMenu: "Items per page _MENU_",
					},
				});
			});
		},
		showTaskOffcanvas(item) {
			bus.$emit('openTaskOffCanvas', item.id)
		},
	}
}
</script>

<style scoped>
.VueTables.VueTables--client>.row:first-child {
	display: none;
}

.dropdown-toggle::after {
	content: none;
}

.dropdown-toggle {
	background-color: transparent;
	border-color: transparent;
	color: #ced4da !important;
	padding: 0;
}

.table-bordered th,
.table-bordered td {
	background-color: #fff;
	border: none;
	border-bottom: 1px solid #e9ecef
}

.table-bordered thead th,
.table-bordered thead td {
	color: #8898aa;
	background-color: #f6f9fc;
}

.VuePagination {
	height: 75px;
	margin: 0;
}

.VuePagination__count {
	position: absolute;
	left: 0;
	top: 25%;
	text-align: left !important;
	font-family: Open Sans, sans-serif;
	font-size: .875rem;
}

li.VuePagination__pagination-item.page-item a.page-link {
	z-index: 2000;
}

.removeTaskMaterial {
	margin-top: 32px;
}

.due-dt-only {
	margin-left: 48px;
}
</style>