<template>
    <div>
        <div class="modal-content">
            <div class="alert alert-warning alert-dismissible priorityAlert" role="alert" id="priorityAlertWarning2">
                <span class="alert-icon"><i class="ri-error-warning-fill"></i></span>
                <span class="alert-text"><strong>Warning!</strong> At least one crew member has Time Off scheduled during this time. You should remove them from the Task to avoid schedule confusion.</span>
                <button type="button" class="close" aria-label="Close" @click="closeAlert()">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <div class="modal-header border-bottom">
                <h5 v-if="modalType == 'edit'" class="modal-title" id="editTasksModal1Title">{{ $t('label.edit task') }}</h5>
                <h5 v-else class="modal-title" id="addTasksModalTitle">{{ $t('label.add task') }}</h5>
                <button type="button" class="close" @click="$emit('cancelFnc')">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="showModalMoveDate">
                <div class="modal-body">
                    <div class="row mb-4">
                        <!-- Left Column -->
                        <div class="col-12 col-lg-6">
                            <!--TASK INFORMATION-->
                            <div class="row">
                                <div class="col-12">
                                    <h5 class="modal-title mb-3">{{ $t('label.task information') }}</h5>
                                    <div class="form-group">
                                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.task name') }}</label>
                                        <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                                        <input v-model="form.name" type="text" name="name" class="form-control" :placeholder="$t('label.task name')" required autofocus autocomplete="input-field" data-lpignore="true">
                                    </div>
                                </div>
                            </div>
                            <!-- Task Type -->
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <div class="d-flex align-items-center">
                                            <label class="form-control-label text-muted" for="input-name">
                                                {{ $t('label.task type') }}
                                                <span class="text-xs text-danger">* {{ $t('label.required') }}</span>
                                            </label>
                                            <!-- Add Task Type -->
                                            <div class="ml-auto">
                                                <a class="cursor-pointer" @click="toggleTaskTypeModal(true)" data-toggle="modal">
                                                    {{ $t('label.add new task type') }}
                                                </a>
                                            </div>
                                        </div>

                                        <select class="form-control" v-model="form.task_type_id" required>
                                            <option v-for="(data, index) in allTaskTypes" :value="data.id" :key="index">{{ data.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <!-- description -->
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.task description') }}</label>

                                        <textarea v-model="form.description" rows="4" name="description" class="form-control" :placeholder="$t('label.task description')"></textarea>
                                    </div>
                                </div>
                            </div>
                            <!-- Task Status -->
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.task status') }}</label>
                                        <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                                        <!-- The field will always have an autoselected option -->
                                        <select v-if="allTaskStatuses" class="form-control" v-model="form.task_status_id" required>
                                            <option v-for="(data, index) in allTaskStatuses" :value="data.id" :key="index">{{ data.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <template v-if="!isTemplate">
                                <!-- Start and Due Dates -->
                                <div class="row">
                                    <div class="col-4">
                                        <label class="form-control-label text-muted">{{ $t('label.start date') }}</label>
                                        <div class="sp-tooltip">
                                            <date-picker v-model="form.start_date" value-type="format" class="full-width" format="MM/DD/YYYY" input-class="form-control" :placeholder="$t('label.select start date')" :disabled="taskTimePunches > 0 || isTaskCompleted()" @change="checkUsersAvailability(); shiftDueDate()"></date-picker>
                                            <span v-if="taskTimePunches > 0" class="sp-tooltiptext mb--3 text-sm time-logged-date">{{ $t('label.time has been logged to this task') }}.</span>
                                        </div>
                                    </div>
                                    <div class="col-4 pr-2">
                                        <label class="form-control-label text-muted">{{ $t('label.due date') }}</label>
                                        <div class="sp-tooltip">
                                            <date-picker v-model="form.due_date" value-type="format" format="MM/DD/YYYY" class="full-width" input-class="form-control" :disabled-date="disableDueDateBeforeStartDate" :placeholder="$t('label.select due date')" :disabled="taskTimePunches > 0 || isTaskCompleted()" @change="checkUsersAvailability()"></date-picker>
                                            <span v-if="taskTimePunches > 0" class="sp-tooltiptext mb--3 text-sm time-logged-date">{{ $t('label.time has been logged to this task') }}.</span>
                                        </div>
                                    </div>
                                    <div class="col-4 pr-2">
                                        <div class="form-group">
                                            <label class="form-control-label text-muted" for="input-name">{{ $t('label.time estimate') }}</label>
                                            <input v-model="form.time_estimate" type="number" inputmode="decimal" name="time_estimate" class="form-control" autocomplete="input-field" data-lpignore="true">
                                        </div>
                                    </div>
                                </div>
                                <!-- Start and End Times -->
                                <div v-if="taskSpansOneDay" class="row">
                                    <div class="col-4">
                                        <label class="form-control-label text-muted">{{ $t('label.start time') }}</label>
                                        <div class="d-flex">
                                            <date-picker format="h:mm a" type="time" :minute-step="5" v-model="form.start_time" class="full-width" input-class="form-control" @change="checkUsersAvailability()">
                                            </date-picker>
                                        </div>
                                    </div>
                                    <div class="col-4 pr-2">
                                        <label class="form-control-label text-muted">{{ $t('label.end time') }}</label>
                                        <div class="d-flex">
                                            <date-picker format="h:mm a" type="time" class="full-width" v-model="form.end_time" input-class="form-control" :minute-step="5" @change="checkUsersAvailability()">
                                            </date-picker>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <!-- Right Column -->
                        <div class="col-12 col-lg-6">
                            <!-- Crew Assignments -->
                            <div class="row">
                                <div class="col-12">
                                    <h5 class="modal-title mb-3">{{ $t('label.task assignee (s)') }}</h5>
                                    <!-- Assignment Dropdowns -->
                                    <div class="row">
                                        <!-- Crew -->
                                        <div class="col-5">
                                            <label class="form-control-label text-muted" for="input-name">{{ $t('label.add crew') }}</label>
                                            <select class="form-control mb-4" v-model="newCrew" @change="addCrewAssignees(newCrew)">
                                                <option :value="null" selected disabled>- {{ $t('label.select a crew to add') }} -</option>
                                                <option v-for="data in allCrews" :value="data" :key="data.id">{{ data.name }}</option>
                                            </select>
                                        </div>
                                        <!-- Radio Button -->
                                        <div class="col-7">
                                            <label class="form-control-label text-muted">Select assignee type</label>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" id="w2Radio" value="w2" v-model="assigneeType" @change="toggleSelectOptions">
                                                <label class="form-check-label" for="w2Radio">W2</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" id="1099Radio" value="1099" v-model="assigneeType" @change="toggleSelectOptions">
                                                <label class="form-check-label" for="1099Radio">1099</label>
                                            </div>
                                        </div>

                                        <!-- W2 Assignees -->
                                        <div class="col-7" v-if="showW2Options">
                                            <label class="form-control-label text-muted" for="input-name">Add W2 Assignee</label>
                                            <select class="form-control rounded-0" v-model="newAssignee" @change="addAssignee(newAssignee)">
                                                <option :value="null" selected disabled>- select a W2 assignee to add -</option>
                                                <option v-for="(data) in onlyW2Users" :value="data" :key="data.id">{{ data.name }}</option>
                                            </select>
                                        </div>

                                        <!-- 1099 Companies -->
                                        <div class="col-7" v-if="show1099Options">
                                            <label class="form-control-label text-muted" for="input-name">Add 1099 Company</label>
                                            <select class="form-control rounded-0" v-model="newAssignee" @change="addAssignee(newAssignee)">
                                                <option :value="null" selected disabled>- select a 1099 company to add -</option>
                                                <option v-for="(data) in only1099Users" :value="data" :key="data.id">{{ data.company_name_1099 }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- Current Assigned Users -->
                                    <div class="form-group" v-if="form.assignees.length">
                                        <div class="d-flex justify-content-between">
                                            <label class="form-control-label text-muted" for="input-name">{{ $t('label.task manager') }}</label>
                                            <label class="form-control-label text-muted ml-4" for="input-name">{{ $t('label.availability') }}</label>
                                        </div>
                                        <div :key="assignee.id" v-for="(assignee, idx) in form.assignees">
                                            <div class="d-flex align-items-center mb-2">
                                                <div class="p-3" style="background: #E9ECEF; color: #fff; border-radius: 5px 0px 0px 5px; height: 46px; line-height: 15px;">
                                                    <input type="radio" name="task_manager" v-model="form.task_manager" :value="assignee.id" required @click="reOrderAssignees(idx, assignee.id)">
                                                </div>
                                                <div class="col-8 col-lg-7 p-0">
                                                    <input class="form-control rounded-0 assignee-background" type="text" :value="assigneeType === 'w2' ? assignee.name : assignee.company_name_1099" disabled>
                                                </div>
                                                <!-- Remove Assignee -->
                                                <div v-if="!assignee.time_punch_count" @click="removeAssignee(idx, assignee.id)" class="p-3 pointer remove_assignee">
                                                    <i class="ri-delete-bin-line mt--1" style="position: absolute;"></i>
                                                </div>
                                                <div v-else class="p-3 sp-tooltip remove_assignee" style="background-color: #e96a81 !important">
                                                    <i class="position-absolute mt--1 ri-delete-bin-line"></i>
                                                    <span class="sp-tooltiptext time-logged mb--3 text-sm">{{ $t('label.this user has logged time and cannot be removed from the task') }}</span>
                                                </div>
                                                <!-- Availability Status -->
                                                <div class="col-3 py-2 pr-1 d-flex">
                                                    <template v-if="assignee.time_off">
                                                        <div v-if="assignee.time_off.length == 0" class="d-flex align-items-center ml-auto" style="color: #0E914F;">
                                                            <i class="ri-check-line"></i> {{ $t('label.available') }}
                                                        </div>
                                                        <div v-else class="d-flex ml-auto sp-tooltip align-items-center" style="color: #DD1A3E;">
                                                            <i class="ri-error-warning-line"></i> {{ $t('label.unavailable') }}
                                                            <div class="sp-tooltiptext sp-tooltiptext-danger time-off">
                                                                <div class="border-bottom text-sm">{{ $t('label.time off scheduled') }}:</div>
                                                                <div class="text-xs ml-auto mr-auto pt-2" v-for="(time_off, i) in assignee.time_off" :key="i">
                                                                    <div v-if="i > 0" class="border-bottom mt--1 mb-1 mx-3"></div>
                                                                    {{ moment(time_off.start_date).format('MM/DD, h:mm a') }}
                                                                    <div class="my--1">to</div>
                                                                    {{ moment(time_off.end_date).format('MM/DD, h:mm a') }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center mb-2" :key="idx + '-time-off-warning'" v-if="assignee.status === 'Unavailable'">
                                                <div class="flex-grow-1 assignee-time-off-warning-container">
                                                    <div class="assignee-time-off-warning-content m-3">
                                                        {{ assignee.user ? assignee.user.first_name : '' }} has Time Off scheduled {{ form.start_date | formatMonthDayWithoutUtc }}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr v-if="idx === 0 && form.task_manager" class="mt-3 mb-3" :key="idx + 'i'" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row" v-if="!form.assignees.length && !showCrewDropdown">
                                <div>
                                    <p class="form-control-label text-muted mt-4 px-3">
                                        Nobody has been assigned to this Task. Add a Crew or Individual to get started with the scheduling of this Task.
                                    </p>
                                </div>
                                <div class="col-12 text-center">
                                    <img src="/images/crew_illustration.png" alt="">
                                </div>
                            </div> -->

                            <!-- Milestones (In experimental phase)-->
                            <!-- <div>
                                <h5 class="modal-title mb-3">Attach a Milestone</h5>
                                <select class="form-control" v-model="form.milestone_id" @change="addMilestone()">
                                    <option value="new">- Add New Milestone -</option>
                                    <option v-for="(data, index) in milestoneList" :value="data.id" :key="index">{{data.milestone}}</option>
                                </select>
                            </div> -->
                        </div>
                    </div>

                    <!--COMPENSATION-->
                    <template v-if="taskSpansOneDay">
                        <div class="form-divider" />
                        <div class="row mb-4">
                            <div class="col-12 col-lg-6">
                                <div class="row">
                                    <div class="col-4">
                                        <h5 class="modal-title mb-3">{{ $t('label.compensation') }}</h5>
                                        <div class="form-group">
                                            <label class="form-control-label text-muted" for="input-name">{{ $t('label.piece rate') }}</label>
                                            <currency-input v-model="form.piece_rate" currency="USD" locale="en" class="form-control" />
                                        </div>
                                    </div>
                                    <!-- <div class="col-4">
                                        <h5 class="modal-title mb-3">&nbsp;</h5>
                                        <div class="form-group">
                                            <label class="form-control-label text-muted" for="input-name">{{ $t('label.fuel') }}</label>
                                            <currency-input v-model="form.fuel" currency="USD" locale="en" class="form-control"/>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-4">
                                        <h5 class="modal-title mb-3">&nbsp;</h5>
                                        <div class="form-group">
                                            <label class="form-control-label text-muted" for="input-name">{{ $t('label.other') }}</label>
                                            <currency-input v-model="form.other" currency="USD" locale="en" class="form-control"/>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </template>

                    <!-- 1099 PAY - Don't need it right now,-->
                    <!-- <div class="form-divider" />
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="row">
                                <div class="col-4">
                                    <h5 class="modal-title mb-3">1099 Pay</h5>
                                    <div class="form-group">
                                        <label class="form-control-label text-muted" for="input-name">1099 Pay Rate</label>
                                        <currency-input v-model="form.pay_rate_1099" currency="USD" locale="en" class="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <!--CUSTOM FIELDS-->
                    <div v-if="companySettings.settings.custom_task_fields" class="form-divider" />
                    <div v-if="companySettings.settings.custom_task_fields" class="row mb-4">
                        <div class="col-12">
                            <h5 v-if="customFields.length" class="modal-title mb-3">{{ $t('label.custom fields') }}</h5>
                        </div>
                        <div v-for="customfield in customFields" :key="customfield.id" class="col-12 col-lg-6">
                            <custom-field-input :model-id="taskId" :model-type="'task'" :custom-field="customfield" :form-save="savingCustomFields">
                            </custom-field-input>
                        </div>
                    </div>

                    <!--TASK MATERIALS-->
                    <div v-if="companySettings.settings.task_materials" class="form-divider" />
                    <div v-if="companySettings.settings.task_materials" class="row mb-4">
                        <div class="col-12">
                            <h5 class="modal-title mb-3">{{ $t('label.task materials') }}</h5>
                        </div>

                        <!-- Material Radio Btns -->
                        <div class="col">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="jobMaterials" id="taskMaterialsNone" v-model="sourceMaterial" value="0" @change="getTaskMaterials" checked="checked">
                                <label class="form-check-label" for="taskMaterialsNone">{{ $t('label.No Materials Needed') }}</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="jobMaterials" id="taskMaterialsJob" v-model="sourceMaterial" value="1" @change="getTaskMaterials">
                                <label class="form-check-label" for="taskMaterialsJob">{{ $t('label.Use Job Materials') }}</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="jobMaterials" id="taskMaterialsTask" v-model="sourceMaterial" value="2" @change="getTaskMaterials">
                                <label class="form-check-label" for="taskMaterialsTask">{{ $t('label.add task materials') }}</label>
                            </div>
                        </div>

                        <!-- Job Materials Table -->
                        <div class="col-12" v-if="sourceMaterial == 1">
                            <div class="table-responsive">
                                <v-client-table v-if="taskMaterialsFromJob.length >= 0" ref="vuetable" :columns="jobMaterialsColumns" v-model="taskMaterialsFromJob" :options="jobMaterialsOptions"></v-client-table>
                            </div>
                        </div>

                        <!-- Task Materials List -->
                        <div class="col-12" v-show="sourceMaterial == 2">
                            <hr>
                            <div class="form-row" v-for="(d, i) in materials" :key="i">
                                <input type="hidden" :value="d.id" :name="'taskMaterials[' + i + '][id]'">
                                <!-- Material Name -->
                                <div class="form-group col-md-2 col-sm-12">
                                    <label class="form-control-label text-muted">{{ $t('label.material') }}</label>
                                    <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                                    <input type="text" class="form-control" v-model="d.material" :name="'taskMaterials[' + i + '][material]'" :required="requireTaskMaterialFields" autocomplete="input-field" data-lpignore="true">
                                </div>
                                <!-- Unit -->
                                <div class="form-group col-md-2 col-sm-12">
                                    <label class="form-control-label text-muted">{{ $t('label.unit') }}</label>
                                    <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                                    <input type="text" class="form-control" v-model="d.unit" :name="'taskMaterials[' + i + '][unit]'" :required="requireTaskMaterialFields" autocomplete="input-field" data-lpignore="true">
                                </div>
                                <!-- Quantity -->
                                <div class="form-group col-md-2 col-sm-12">
                                    <label class="form-control-label text-muted">{{ $t('label.quantity') }}</label>
                                    <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

                                    <input type="text" class="form-control" v-model="d.quantity" min="0" @change="checkMaterialQty(i)" :name="'taskMaterials[' + i + '][quantity]'" :required="requireTaskMaterialFields" autocomplete="input-field" data-lpignore="true">
                                </div>
                                <!-- Location -->
                                <div class="form-group col-md-2 col-sm-12">
                                    <label class="form-control-label text-muted">{{ $t('label.location') }}</label>

                                    <input type="text" class="form-control" v-model="d.location" :name="'taskMaterials[' + i + '][location]'" autocomplete="input-field" data-lpignore="true">
                                </div>
                                <!-- Notes -->
                                <div class="form-group col-md-3 col-sm-12">
                                    <label class="form-control-label text-muted">{{ $t('label.notes') }}</label>
                                    <input type="text" class="form-control" v-model="d.notes" :name="'taskMaterials[' + i + '][notes]'" autocomplete="input-field" data-lpignore="true">
                                </div>
                                <div class="col form-group" style="padding-top: 31px">
                                    <button @click.prevent="removeTaskMaterial(i)" class="removeTaskMaterial btn btn-danger m-xs-0">
                                        <i class="ri-delete-bin-line"></i>
                                    </button>
                                </div>
                                <hr>
                            </div>
                            <p v-if="materialsIsInvalid" class="text-danger text-sm">{{ $t('label.At least one task material is needed') }}.</p>
                            <button type="button" class="btn btn-primary btn-sm" v-show="sourceMaterial == 2" @click="addNewTaskMaterial">{{ $t('label.add material') }}</button>
                        </div>
                    </div>

                </div>
                <div class="modal-footer border-top">
                    <button type="submit" class="btn btn-primary mr-auto">{{ modalType == 'edit' ? $t('label.update task') : $t('label.add task') }}</button>
                    <button type="button" class="btn btn-link ml-auto close2edittask" @click="$emit('cancelFnc')">{{ $t('label.close') }}</button>
                </div>
            </form>
        </div>
        <!-- Add New Task Type Modal -->
        <task-type-add-modal v-if="showTaskTypeModal" @onClose="toggleTaskTypeModal" @onSave="onAddNewTaskType">
        </task-type-add-modal>

        <!--Move Date Confirmation Modal -->
        <ModalMoveDateConfirmation v-if="showModalMoveDateConfirmation" :new-id="'ModalTask'" :selected-task="form" @onClose="showModalMoveDate" @customUpdateMethod="updateTask">
        </ModalMoveDateConfirmation>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('userData');

import CustomFieldInput from '@/components/CustomFields/CustomFieldInput.vue';
import ModalMoveDateConfirmation from '@/components/ModalMoveDateConfirmation.vue';
import TaskTypeAddModal from '@/components/TaskTypes/TaskTypeAddModal.vue';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    props: [
        'task',
        'jobId',
        'source',
        'current',
        'dashboard',
        'isTemplate',
    ],
    emits: ['getTasks', 'cancelFnc', 'onSave'],
    components: {
        TaskTypeAddModal,
        CustomFieldInput,
        ModalMoveDateConfirmation
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            companySettings: JSON.parse(localStorage.getItem('company_settings')),
            allCrews: [],
            alltasks: [],
            materials: [],
            crewMembers: [],
            customFields: [],
            allTaskTypes: [],
            allTaskStatuses: [],
            removedMaterials: [],
            taskMaterialsFromJob: [],
            selectedCrew: '',
            sourceMaterial: 0,
            jobid: null,
            taskId: null,
            newCrew: null,
            modalType: null,
            statusOpen: null,
            newAssignee: null,
            taskTimePunches: null,
            showCrewDropdown: null,
            editMode: false,
            statusInput: false,
            showTaskTypeModal: false,
            savingCustomFields: false,
            form: {
                assignees: [],
                fuel: 0,
                other: 0,
                piece_rate: 0,
                id: null,
                name: null,
                status: null,
                due_date: null,
                end_time: null,
                date_diff: null,
                start_date: null,
                start_time: null,
                task_manager: null,
                old_due_date: null,
                time_estimate: null,
                old_start_date: null,
                task_status_id: null,
                pay_rate_1099: null,
            },
            jobMaterialsColumns: [
                'material',
                'unit',
                'quantity',
                'location',
                'notes'
            ],
            jobMaterialsOptions: {
                perPageValues: [],
                filterable: false,
                headings: {
                    unit: this.$t('label.unit'),
                    notes: this.$t('label.notes'),
                    location: this.$t('label.location'),
                    material: this.$t('label.material'),
                    quantity: this.$t('label.quantity')
                },
                columnsClasses: {
                    unit: 'text-left',
                    notes: 'text-left',
                    location: 'text-left',
                    material: 'text-left',
                    quantity: 'text-left'
                }
            },
            showModalMoveDateConfirmation: false,
            milestoneList: [],
            allUsers: [],
            onlyW2Users: [],
            only1099Users: [],
            assigneeType: null,
    showW2Options: false,
    show1099Options: false,
    newAssignee: null,
        }
    },
    watch: {
        task: function (newVal, oldVal) {
            this.jobid = newVal.job_id;
        }
    },
    computed: {
        taskSpansOneDay() {
            let start = this.form.start_date ? moment(this.form.start_date).format('MM/DD/YYYY') : null,
                due = this.form.due_date ? moment(this.form.due_date).format('MM/DD/YYYY') : null;

            if (start)
                return (this.form.start_date && !this.form.due_date) || (start == due);
            else
                return false;
        },
        materialsIsInvalid() {
            return this.materials && this.materials.length < 1;
        },
        requireTaskMaterialFields() {
            return (this.sourceMaterial == 2) ? true : false;
        },
        // ...mapState({
        //     allUsers: state => state.allUsers,
        // }),
    },
    mounted() {
        if (this.task) {
            this.modalType = 'edit'
            this.taskId = this.task.id;
        }

        this.closeAlert();
        this.loadSupportingData();
    },
    methods: {
    toggleSelectOptions() {
        this.form.assignees = [];
        if (this.assigneeType === 'w2') {
        this.showW2Options = true;
        this.show1099Options = false;
        } else if (this.assigneeType === '1099') {
        this.showW2Options = false;
        this.show1099Options = true;
        } else {
        this.showW2Options = false;
        this.show1099Options = false;
        }
    },
        // ...mapActions(['getAllUsers']),
        // Load all the look up date before loading existing task or allowing user imput.
        loadSupportingData() {
            this.loader = this.$loading.show();

            // Select api for custom fields based on add or edit
            let customFieldPost = '';
            if (this.modalType === 'edit') {
                customFieldPost = axios.post(`/loadModelCustomFields`, {
                    model_id: this.task.id,
                    model_type: 'task'
                });
            } else {
                customFieldPost = axios.post(`/loadCustomFieldsByModel`, {
                    model_type: 'task'
                });
            }

            var promises = [
                axios.get('/tasktypes'),
                axios.get('/taskstatus'),
                axios.get('/get-all-crews-for-tasks'),
                customFieldPost,
                this.getAllActiveUsers(),
                // axios.get('/getMilestoneList'),	
            ];

            // Pull from vuex but if empty load it
            // if (!this.allUsers.length) promises.push(this.getAllUsers());

            Promise.all(promises).then((responses) => {
                this.loader.hide();

                // Task Types
                this.allTaskTypes = responses[0].data.result;

                // Task Statuses
                this.allTaskStatuses = responses[1].data.result;
                this.allTaskStatuses.forEach(status => {
                    if (status.name === "Open")
                        this.form.task_status_id = status.id;
                });

                // Task Crews
                this.allCrews = responses[2].data.result;

                // Task Custom Fields
                let add_data = {}, edit_data = {};
                this.customFields = responses[3].data.result;
                this.customFields.forEach(cf => {
                    add_data[cf.input_name] = null;
                    edit_data[cf.input_name] = cf.value ? cf.value : null;
                });
                this.form = { ...this.form, ...add_data };

                // Milestone
                // this.milestoneList = responses[4].data


                if (this.task) // Load the existing task into the form on edit
                    this.editModal();
                else // Setup form for new task
                    this.configForm();
            });
        },
        configForm() {
            this.form.name = '';
            this.form.task_type_id = '';
            this.form.end_time = null;
            this.form.start_time = null;
            this.form.start_date = moment().format('MM/DD/YYYY');
            this.form.due_date = moment().format('MM/DD/YYYY');
            this.form.description = '';
            this.form.time_estimate = 0;
            this.form.assignees = [];
            this.form.task_manager = null;
            this.form.piece_rate = null;
            this.form.fuel = null;
            this.form.other = null;
            this.materials = [];
            this.sourceMaterial = 0;
            this.taskMaterialsFromJob = [];
        },
        getJobMaterials() {
            axios.get(`/get-job-materials/${this.jobId}`).then(response => {
                this.taskMaterialsFromJob = response.data.result;
            }).catch(err => console.error(err));
        },
        getAllActiveUsers() {
            axios.get('/get-all-users').then(response => {
                this.allUsers = response.data.result;
                this.allUsers.forEach((user) => {
                    if(user.pay_type != '1099') {
                    this.onlyW2Users.push(user);
                    } else {
                        this.only1099Users.push(user);
                    }
                })
            }).catch(err => console.error(err));
        },
        editModal() {
            let startDate = moment(this.task.start_date).format('MM/DD/YYYY'),
                dueDate = moment(this.task.due_date).format('MM/DD/YYYY'),
                startTime = moment(this.task.start_time).toDate(),
                endTime = moment(this.task.end_time).toDate();

            this.editMode = true;
            this.removedMaterials = [];
            this.materials = this.task.task_materials;
            this.sourceMaterial = this.task.material_source;
            this.taskTimePunches = this.task.time_punches_count;

            this.form.assignees = [];
            this.form.id = this.task.id;
            this.form.name = this.task.name;
            this.form.fuel = parseFloat(this.task.fuel);
            this.form.other = parseFloat(this.task.other);
            this.form.description = this.task.description;
            this.form.task_type_id = this.task.task_type_id;
            this.form.time_estimate = this.task.time_estimate;
            this.form.task_status_id = this.task.task_status_id;
            this.form.piece_rate = parseFloat(this.task.piece_rate);
            this.form.end_time = this.task.end_time ? endTime : null;
            this.form.due_date = this.task.due_date ? dueDate : null;
            this.form.old_due_date = this.form.due_date;
            this.form.start_time = this.task.start_time ? startTime : null;
            this.form.start_date = this.task.start_date ? startDate : null;
            this.form.old_start_date = this.form.start_date;
            this.form.pay_rate_1099 = this.task.pay_rate_1099;

            if (1 == this.sourceMaterial)
                this.getJobMaterials();

            this.task.assigned_to.forEach(assignee => {
                this.$set(assignee.user, 'time_punch_count', assignee.time_punch_count);
                this.form.assignees.push(assignee.user);

                if (assignee.task_manager == '1') {
                    this.form.task_manager = assignee.user.id;
                    this.reOrderAssignees(this.form.assignees.length - 1, assignee.user.id);
                }

                this.checkUsersAvailability();
            });
        },
        updateTask(val) {
            this.loader = this.$loading.show();

            let apiUrl = '',
                request_fields = null,
                startDate = null,
                dueDate = null,
                startTime = null,
                endTime = null;

            // UPDATE TASK
            if (this.modalType == 'edit') {

                if (!this.isTemplate) {
                    startDate = this.form.start_date == null ? null : moment(this.form.start_date).format('MM/DD/YYYY');
                    dueDate = this.form.due_date == null ? null : moment(this.form.due_date).format('MM/DD/YYYY');

                    if (this.taskSpansOneDay) {
                        startTime = this.form.start_time ? moment(this.form.start_time).format('MM/DD/YYYY HH:mm:ss') : null;
                        endTime = this.form.end_time ? moment(this.form.end_time).format('MM/DD/YYYY HH:mm:ss') : null;
                    }
                }

                request_fields = {
                    'job_id': this.jobid,
                    'name': this.form.name,
                    'task_type_id': this.form.task_type_id,
                    'task_status_id': this.form.task_status_id,
                    'start_date': startDate,
                    'due_date': dueDate,
                    'start_time': startTime,
                    'end_time': endTime,
                    'description': this.form.description,
                    'time_estimate': this.form.time_estimate,
                    'assignees': this.form.assignees,
                    'task_manager': this.form.task_manager,
                    'piece_rate': this.form.piece_rate,
                    'fuel': this.form.fuel,
                    'other': this.form.other,
                    'pay_rate_1099': this.form.pay_rate_1099,
                    'id': this.form.id,
                    'material_source': this.sourceMaterial,
                    'removed_materials': this.removedMaterials,
                    'is_template': this.isTemplate
                };

                if ('2' == this.sourceMaterial) {
                    if (this.materialsIsInvalid)
                        return;

                    request_fields.materials = this.materials;
                }

                apiUrl = '/tasks/update';

            } else {
                // ADD NEW TASK
                if (!this.isTemplate) {
                    startDate = this.form.start_date ? moment(this.form.start_date).format('MM/DD/YYYY') : null;
                    dueDate = this.form.due_date ? moment(this.form.due_date).format('MM/DD/YYYY') : null;
                    if (this.taskSpansOneDay) {
                        startTime = this.form.start_time ? moment(this.form.start_time).format('MM/DD/YYYY HH:mm:ss') : null;
                        endTime = this.form.end_time ? moment(this.form.end_time).format('MM/DD/YYYY HH:mm:ss') : null;
                    }
                }

                request_fields = {
                    'job_id': this.jobId,
                    'name': this.form.name,
                    'task_type_id': this.form.task_type_id,
                    'task_status_id': this.form.task_status_id,
                    'start_date': startDate,
                    'due_date': dueDate,
                    'start_time': startTime,
                    'end_date': endTime,
                    'description': this.form.description,
                    'time_estimate': this.form.time_estimate,
                    'assignees': this.form.assignees,
                    'task_manager': this.form.task_manager,
                    'piece_rate': this.form.piece_rate,
                    'fuel': this.form.fuel,
                    'other': this.form.other,
                    'material_source': this.sourceMaterial,
                    'is_template': this.isTemplate,
                    'pay_rate_1099': this.form.pay_rate_1099,
                };

                if ('2' == this.sourceMaterial) {
                    if (this.materialsIsInvalid)
                        return;

                    request_fields.materials = this.materials;
                }

                apiUrl = '/tasks';
            }

            axios.post(apiUrl, request_fields).then(response => {
                if (this.modalType !== 'edit')
                    this.taskId = response.data.result.id;
                else
                    this.emitSourceFncs();

                this.savingCustomFields = true;

                setTimeout(() => {
                    this.savingCustomFields = false;

                    if (this.dashboard)
                        bus.$emit('getallwidgets', 'widgets')
                    else
                        this.$emit('getTasks')

                    this.loader.hide();
                    this.$emit('onSave');
                    this.$emit('cancelFnc');

                    // Display success banner
                    bus.$emit('banner-success', {
                        message: `Task updated successfully!`,
                    });
                    // bus.$emit('banner-success', {
                    //     message: `Task completed successfully!`,
                    // });
                }, 1000);
            }).catch(err => console.error(err));
        },
        emitSourceFncs() {
            if (this.source == 'weekCalendar')
                bus.$emit('updateWeekCalendar');
            else if (this.source == 'dayCalendar')
                bus.$emit('updateDayCalendar');
        },
        removeAssignee(idx, user_id) {
            if (this.form.task_manager == user_id)
                this.form.task_manager = null;

            this.form.assignees.splice(idx, 1)
        },
        removeTaskMaterial(index) {
            this.materials.splice(index, 1);
        },
        editRemoveTaskMaterial(index, taskMaterialId) {
            if ('' == taskMaterialId) {
                this.materials.splice(index, 1);
            } else {
                let removeCheck = confirm("Are you sure to remove this item?");

                if (removeCheck) {
                    this.removedMaterials.push(taskMaterialId);
                    this.materials.splice(index, 1);
                }
            }
        },
        getTaskMaterials() {
            if (1 == this.sourceMaterial) {
                this.getJobMaterials();
            }
            else if (2 == this.sourceMaterial) {
                if (this.materials.length === 0)
                    this.addNewTaskMaterial();
            }
        },
        addNewTaskMaterial() {
            this.materials.push({
                id: '',
                location: '',
                material: '',
                unit: '',
                notes: ''
            });
        },
        addCrewAssignees(crew) {
            crew.members.forEach(user => {
                if (user.user) {
                    // Avoid duplicate assignments
                    if (!(this.form.assignees.find(assigned => assigned.id == user.user_id))) {
                        this.form.assignees.push(user.user);
                        this.checkUsersAvailability();
                    }

                    // If manager isn't assigned, assign the crews foreman
                    if (this.form.task_manager == null && crew.foreman.user_id == user.user_id) {
                        this.form.task_manager = user.user_id;
                        this.reOrderAssignees(this.form.assignees.length - 1, user.user_id);
                    }

                    this.newCrew = null;
                }
            });
        },
        addAssignee(selectedUser) {
            // Avoid duplicate assignments
            if (!(this.form.assignees.find(assigned => assigned.id == selectedUser.id))) {
                this.form.assignees.push(selectedUser);
                this.checkUsersAvailability();
            }

            this.newAssignee = null;
        },
        reOrderAssignees(idx) {
            if (idx != 0) {
                this.form.assignees.splice(0, 0, this.form.assignees[idx]);
                this.form.assignees.splice(idx + 1, 1);
            }
        },

        closeAlert() {
            $(".priorityAlert").hide()
        },

        isTaskCompleted() {
            // Check if edit form status is completed
            if (this.allTaskStatuses.length <= 0 || this.task == null)
                return false;

            let i = this.allTaskStatuses.findIndex(st => st.id == this.form.task_status_id);
            if (i >= 0)
                return this.allTaskStatuses[i].name == 'Completed';
        },

        checkUsersAvailability() {
            let reqData = {
                users: [],
                start_date: null,
                due_date: null,
            };

            // Add user id's
            this.form.assignees.forEach(assignee => reqData.users.push({ id: assignee.id }));

            // Set Dates
            if (this.form.start_date)
                reqData.start_date = moment(this.form.start_date).format('MM/DD/YYYY');
            else if (this.form.due_date)
                reqData.start_date = moment().format('MM/DD/YYYY');
            if (this.form.due_date)
                reqData.due_date = moment(this.form.due_date).format('MM/DD/YYYY');
            else if (this.form.start_date)
                reqData.due_date = reqData.start_date;

            // Append time constraint if start or end time exists
            if (this.form.start_date) {
                if (this.form.start_time)
                    reqData.start_date += ' ' + moment(this.form.start_time).format('HH:mm:ss');

                if (this.form.end_time)
                    reqData.due_date += ' ' + moment(this.form.end_time).format('HH:mm:ss');
                else
                    reqData.due_date = moment(reqData.due_date).endOf('day').format('MM/DD/YYYY HH:mm:ss');
            }

            axios.post('/check-users-availability', reqData).then(rsp => {
                let returnedUsers = rsp.data.results;

                // Set each users time off data
                returnedUsers.forEach(user => {
                    let uIdx = this.form.assignees.findIndex(assignee => {
                        return assignee.id == user.id;
                    });

                    if (uIdx >= 0)
                        this.$set(this.form.assignees[uIdx], 'time_off', user.time_off_requests);
                });
            }).catch(err => console.log(err));
        },

        shiftDueDate() {
            if (this.form.start_date && this.form.old_start_date) {
                let newDt = moment(this.form.start_date).format('MM/DD/YYYY'),
                    oldDt = moment(this.form.old_start_date).format('MM/DD/YYYY'),
                    daysAdded = moment(newDt).diff(moment(oldDt), 'days');

                if (daysAdded > 0 && this.form.due_date)
                    this.form.due_date = moment(this.form.due_date).add(daysAdded, 'days').format('MM/DD/YYYY');

                this.checkUsersAvailability();
            } else if (this.form.start_date && !this.form.old_start_date) {
                // Avoid having due date before start date by setting 
                // due date equal to start date.
                this.form.due_date = this.form.start_date;
            }

            // this.fastForwardOldStartDate();
        },

        toggleTaskTypeModal(status) {
            this.showTaskTypeModal = status;
        },
        onAddNewTaskType(task_type_id) {
            axios.get('/tasktypes').then(response => {
                this.allTaskTypes = response.data.result;
                this.form.task_type_id = task_type_id;
            }).catch(err => console.error(err));
        },

        fastForwardOldStartDate() {
            this.form.old_start_date = this.form.start_date;
        },
        disableDueDateBeforeStartDate(date) {
            if (this.form.start_date)
                return moment(date).diff(moment(this.form.start_date), 'days') < 0;
            else
                return false;
        },

        showModalMoveDate() {
            // Decided on showing the shift remaining job task dates modal
            // if (this.companySettings.adjust_task_dates && this.modalType == 'edit') {
            if (this.modalType == 'edit') {
                this.form.job_id = this.jobId;

                let oldDueDate = moment(this.form.old_due_date).format('MM/DD/YYYY');
                this.form.date_diff = moment(this.form.due_date).diff(moment(oldDueDate), 'days');

                if (this.form.date_diff == 0 || isNaN(this.form.date_diff)) {
                    // No Shift
                    this.updateTask();
                }
                else {
                    // Shift
                    this.showModalMoveDateConfirmation = true;
                }
            } else {
                this.updateTask();
            }
        },
        addMilestone() {
            if (this.form.milestone_id === 'new') {
                window.alert('Pop up add milestone modal')
                this.form.milestone_id = null
            }
        },
        checkMaterialQty(idx) {
            if (this.materials[idx].quantity < 0)
                this.materials[idx].quantity = 0;
        },
    }
}
</script>

<style scoped>
.remove_assignee {
    background: #dd1a3e;
    color: #fff;
    border-radius: 0px 5px 5px 0px;
    height: 46px;
    width: 50px;
}

.time-logged {
    width: 175px;
    white-space: normal;
}

.time-logged-date {
    width: 150px;
    white-space: normal;
}

.time-off {
    margin-left: -2.9rem;
    width: 150px;
    white-space: normal;
}

#ui-datepicker-div {
    display: none;
}

.assignee-background {
    background: #f9fcfd !important;
}
</style>
