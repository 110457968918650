<template>
    <div class="card">
        <div class="card-header mb--4" style="border-bottom: 0px;">
            <div class="row align-items-center">
                <div class="col-sm-6 pt-2">
                    <h3 class="d-flex mb-0">
                        <i class="ri-tools-line"></i>
                        {{ $t('label.items') }}
                    </h3>
                </div>
                <!-- Search Bar -->
                <div class="col-sm-6 pt-2 pt-sm-0">
                    <div class="input-group input-group-alternative input-group-merge">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="ri-search-line"></i>
                            </span>
                        </div>
                        <input class="form-control" v-model="searchValue" :change="searchAssetItems()" :placeholder="$t('label.search items')" type="text" autocomplete="off" data-lpignore="true" />
                    </div>
                </div>
            </div>
        </div>

        <div class="m-0 p-0">
            <!-- Compact View -->
            <div class="table-responsive pt-4" v-if="viewType === 'compact'">
                <!-- Display add item if no items exist -->
                <div v-if="filteredAssetItems.length == 0" class="text-center pt-5 border-top">
                    <div class="form-group">
                        <h3>{{ $t('label.no item assets') }}</h3>
                    </div>
                    <div class="form-group">
                        <router-link class="btn btn-primary" to="assets/create">
                            {{ $t('label.add item') | uppercase }}
                        </router-link>
                    </div>
                </div>
                <div v-else>
                    <table class="table table-flush table-sm border-bottom">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <span class="sp-row-sm-pl">{{ $t('label.item name') }}</span>
                                </th>
                                <th class="hidden-xs-down" scope="col">{{ $t('label.type') }}</th>
                                <th class="hidden-md-down" scope="col">{{ $t('label.serial number') }}</th>
                                <th scope="col">{{ $t('label.assigned to') }}</th>
                                <th class="hidden-xs-down" scope="col">{{ $t('label.status') }}</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- Loop items -->
                            <tr class="list-group-item-action sp-va-mid" v-for="(data, index) in paginatedItems" :key="index">
                                <!-- Item Name -->
                                <td class="text-dark-blue">
                                    <div class="d-flex align-items-center sp-row-sm-pl">
                                        <!-- No item image -->
                                        <i v-if="!data.photo" class="ri-tools-line text-xl text-muted px-1 mr-1" />
                                        <!-- Display item image -->
                                        <img class="rounded item-img-compact" v-if="data.photo" :src="data.photo" alt="" />&nbsp;
                                        <!-- Asset Name -->
                                        <span class="">
                                            <router-link class="text-dark-blue text-bold pointer" :to="{ name: 'assetItemView', params: { assetItem: data } }">
                                                {{ data.name }}
                                            </router-link>
                                            <!-- Status for Mobile View -->
                                            <span class="d-block d-sm-none">
                                                <span class="status-indicator" :class="data.status_class_name"></span>
                                                &nbsp;{{ data.status }}
                                            </span>
                                        </span>
                                    </div>
                                </td>
                                <!-- Item Type -->
                                <td class="hidden-xs-down sp-va-mid">
                                    {{ data.asset_item_type.name }}
                                </td>
                                <!-- Serial Number -->
                                <td class="hidden-md-down sp-va-mid">
                                    {{ data.serial_number }}
                                </td>
                                <!-- Assigned employee -->
                                <td class="sp-va-mid">
                                    <span v-if="data.operators[0]" class="d-flex align-items-center">
                                        <img class="rounded-circle user-img" :src="data.operators[0].operator.picture" alt="" />&nbsp;
                                        <span class="cursor-pointer d-flex" @click="getUserInfo(data.operators[0].operator.id, $t('label.responsible person'))">{{ data.operators[0].operator.name }}</span>
                                    </span>
                                    <span v-if="data.operators.length == 0" class="text-muted">
                                        {{ $t("label.unassigned") }}
                                    </span>
                                </td>

                                <!-- Task Status -->
                                <td class="hidden-xs-down sp-va-mid">
                                    <span class="status-indicator" :class="data.status_class_name"></span>
                                    &nbsp;{{ data.status }}
                                </td>
                                <!-- Dropdown -->
                                <td class="text-right sp-va-mid">
                                    <div class="d-flex float-right">
                                        <span class="hidden-xs-down pt-1 pb--1" v-if="data.comments_count >= 1">
                                            <i class="ri-message-2-line"></i>
                                        </span>
                                        <span class="hidden-xs-down pt-1 pb--1" v-if="data.files_count >= 1">
                                            <i class="ri-folders-line"></i>
                                        </span>
                                        <span class="dropdown pr-0 pl-1">
                                            <a class="btn btn-sm" id="assetItemMenu" data-toggle="dropdown" href="#" role="button">
                                                <i class="fas fa-ellipsis-v text-light"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" aria-labelledby="assetItemMenu">
                                                <!-- View Item -->
                                                <router-link class="dropdown-item" :to="{ name: 'assetItemView', params: { assetItem: data } }">
                                                    <i class="ri-eye-line"></i>{{ $t('label.view') }}
                                                </router-link>
                                                <!-- Edit Item -->
                                                <router-link class="dropdown-item" :to="{ name: 'assetItemEdit', params: { editItem: data } }">
                                                    <i class="ri-pencil-line"></i>{{ $t('label.edit') }}
                                                </router-link>
                                                <!-- Archive item -->
                                                <a class="dropdown-item" href="#" @click="clickArchive(data)" data-toggle="modal">
                                                    <i class="ri-archive-line"></i>{{ $t('label.mark as sold/archived') }}
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table-pagination 
                        v-model="paginatedItems" 
                        :all-rows="filteredAssetItems" 
                        :perPage="10">
                    </table-pagination>

                </div>
            </div>

            <!-- Regular View -->
            <div class="table-responsive pt-4 mt-1" v-if="viewType === 'regular'" id="regular-asset-items">
                <table class="table table-flush regular mb-3" id="assetItemsRegularDT">
                    <thead class="p-0 m-0">
                        <tr class="p-0 m-0">
                            <td class="p-0 m-0"></td>
                        </tr>
                    </thead>
                    <tbody class="border-bottom pb-4">
                        <!-- Trying to fix the bottom datatable footer -->
                        <tr class="list-group-item-action" v-for="(data, index) in paginatedItems" :key="index">
                            <td>
                                <div class="row regular-row">
                                    <div class="col-sm-7">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <!-- Item Image -->
                                                <div class="mobile-img">
                                                    <img v-if="data.photo" class="rounded item-img-regular" :src="data.photo" alt="" />
                                                    <img v-else class="rounded item-img-regular" :src="$storagePath + 'asset-item-uploads/default-item.png'" />
                                                </div>
                                            </div>
                                            <div class="col-sm-6 text-xs">
                                                <div class="row border-bottom pb-2">
                                                    <!-- Name -->
                                                    <div class="col-lg-4 col-sm-12 text-xs text-muted">
                                                        {{ $t('label.item name') }}
                                                    </div>
                                                    <div class="col-lg-8 col-sm-12 text-bold text-dark-blue">
                                                        <router-link class="text-dark-blue text-bold pointer" :to="{ name: 'assetItemView', params: { assetItem: data } }">
                                                            {{ data.name }}
                                                        </router-link>
                                                    </div>
                                                </div>
                                                <div class="row border-bottom py-2">
                                                    <!-- Description -->
                                                    <div class="col-lg-4 col-sm-12 text-xs text-muted pr-0">
                                                        {{ $t('label.description') }}
                                                    </div>
                                                    <div class="col-lg-8 col-sm-12">
                                                        {{ data.description }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row border-bottom py-2">
                                            <!-- Item Type -->
                                            <div class="col-sm-6 col-3">
                                                <div class="row">
                                                    <div class="col-lg-3 col-sm-12 text-xs text-muted">
                                                        {{ $t('label.type') }}
                                                    </div>
                                                    <div class="col-lg-9 col-sm-12">
                                                        {{ data.asset_item_type.name }}
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Serial Number -->
                                            <div class="col-sm-6 col-9">
                                                <div class="row">
                                                    <div class="col-lg-4 col-sm-12 text-xs text-muted">
                                                        {{ $t('label.serial number') }}
                                                    </div>
                                                    <div class="col-lg-8 col-sm-12">
                                                        {{ data.serial_number }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-5">
                                        <div class="row">
                                            <div class="col-sm-1"></div>
                                            <div class="col-sm-10">
                                                <!-- Assigned Responsible Person -->
                                                <div class="row border-bottom py-1">
                                                    <div class="col-lg-3 col-md-4 col-sm-12 text-xs text-muted mobile-operator">
                                                        <div style="position: absolute; bottom: 0">
                                                            {{ $t("label.responsible person") }}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-9 col-md-8 col-sm-12 d-flex align-items-center">
                                                        <template v-if="data.operators[0]">
                                                            <img class="rounded-circle user-img" :src="data.operators[0].operator.picture" alt="" />&nbsp;
                                                            <span class="cursor-pointer d-flex" @click="getUserInfo(data.operators[0].operator.id, $t('label.responsible person'))">
                                                                {{ data.operators[0].operator.name }}
                                                            </span>
                                                        </template>
                                                        <template v-else>
                                                            <span class="mt-3">
                                                                {{ $t("label.unassigned") }}
                                                            </span>
                                                        </template>
                                                    </div>
                                                </div>
                                                <!-- Item Status -->
                                                <div class="row border-bottom py-1">
                                                    <div class="col-lg-3 col-md-4 col-sm-12 text-xs text-muted">
                                                        {{ $t("label.status") }}
                                                    </div>
                                                    <div class="col-lg-9 col-md-8 col-sm-12">
                                                        <span class="status-indicator" :class="data.status_class_name" style="margin-left: 13px"></span>&nbsp;
                                                        {{ data.status }}
                                                    </div>
                                                </div>
                                                <!-- Comment and File Count -->
                                                <div class="d-flex pt-2 count-text">
                                                    <div class="mr-auto">
                                                        <span class="pt-1 pr-2" v-if="data.comments_count >= 1">
                                                            <img class="icon-img" :src="'images/icons/message-2-line.png'" alt="">&nbsp;
                                                            {{ data.comments_count }} {{ $t('label.comments') }}
                                                        </span>
                                                        <span class="pt-1 d-inline-block" v-if="data.files_count >= 1">
                                                            <img class="icon-img" :src="'images/icons/folders-line.png'" alt="">&nbsp;
                                                            {{ data.files_count }} {{ $t('label.files') }}
                                                        </span>
                                                    </div>

                                                    <!-- Dropdown -->
                                                    <div class="ml-auto">
                                                        <div class="dropdown">
                                                            <a class="btn btn-sm" id="assetItemMenu" data-toggle="dropdown" href="#" role="button">
                                                                <i class="fas fa-ellipsis-v"></i>
                                                            </a>
                                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                                <!-- View Item -->
                                                                <router-link class="dropdown-item" :to="{ name: 'assetItemView', params: { assetItem: data } }">
                                                                    <i class="ri-eye-line"></i>{{ $t('label.view') }}
                                                                </router-link>
                                                                <!-- Edit Item -->
                                                                <router-link class="dropdown-item" :to="{ name: 'assetItemEdit', params: { editItem: data } }">
                                                                    <i class="ri-pencil-line"></i>{{ $t('label.edit') }}
                                                                </router-link>
                                                                <!-- Archive item -->
                                                                <a class="dropdown-item" href="#" @click="clickArchive(data)" data-toggle="modal">
                                                                    <i class="ri-archive-line"></i>{{ $t('label.mark as sold/archived') }}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table-pagination 
                    v-model="paginatedItems" 
                    :all-rows="filteredAssetItems" 
                    :perPage="10">
                </table-pagination>

            </div>
        </div>

        <div v-if="showArchiveModal">
            <archive-asset :asset="selectedAssetItem" :assetType="'item'" @onArchive="onSaveArchive()" @onClose="onCloseArchive()"></archive-asset>
        </div>
    </div>
</template>

<style>
    .icon-img {
        width: 20px;
    }

    .user-img {
        width: 36px;
        height: 36px;
        object-fit: cover;
    }

    .item-img-compact {
        height: 36px;
        width: 36px;
    }

    .item-img-regular {
        width: 48px;
        height: 48px;
    }
</style>

<script>
    import * as _ from 'lodash';
    import TablePagination from '@/components/TablePagination';

    export default {
        props: ['filters', 'viewType'],
        emits: ['onLoaded'],
        components: {
            TablePagination
        },
        data() {
            return {
                showArchiveModal: false,
                assetItems: [],
                paginatedItems: [],
                filteredAssetItems: [],
                selectedAssetItem: {},
                searchValue: null,
                searchValuePrerender: null,
            };
        },
        watch: {
            // Watch for changes in filters and views to keep
            // items list updated.
            filters() {
                if (this.assetItems.length) {
                    // Clear search bar
                    this.searchValue = null;
                    this.searchValuePrerender = null;

                    this.filterAssetItems(this.assetItems, this.filters);
                }
            },
        },
        mounted() {
            this.getAssetItems();
        },
        methods: {
            getAssetItems() {
                axios.post("/assetManagementLoadAssetItems").then((response) => {
                    this.assetItems = response.data.assetItems;
                    this.filteredAssetItems = JSON.parse(JSON.stringify(this.assetItems));
                    this.$emit('onLoaded');

                }).catch((err) => console.log("ERROR: ", err))
            },

            sortArrays(arrays) {
                return _.orderBy(arrays, 'name', 'asc');
            },

            showDetails(itemId) {
                // Show the task details page
                window.location.href = `/asset-management/item/${itemId}`;
            },

            clickArchive(assetItem) {
                this.selectedAssetItem = assetItem;
                this.showArchiveModal = true;
            },
            onCloseArchive() {
                this.showArchiveModal = false;
            },
            onSaveArchive() {
                this.onCloseArchive();
                this.getAssetItems();
            },

            filterAssetItems(assetItems, filters) {
                if (!(filters.assignedStatuses.assigned || filters.assignedStatuses.unassigned))
                    return [];

                this.filteredAssetItems = assetItems.filter(a => {
                    // Filter any with assigned status only if NOT showing all assigned
                    if (!(filters.assignedStatuses.assigned && filters.assignedStatuses.unassigned)) {
                        if (filters.assignedStatuses.assigned && a.operators.length < 1)
                            return false;
                        else if (filters.assignedStatuses.unassigned && a.operators.length)
                            return false;
                    }

                    // Filter based on given item status
                    if (filters.assetStatuses.active && (a.status === "Active"))
                        return true;
                    else if (filters.assetStatuses.inShop && (a.status === "In Shop"))
                        return true;
                    else if (filters.assetStatuses.inactive && (a.status === "Inactive"))
                        return true;
                    else if (filters.assetStatuses.outOfService && (a.status === "Out of Service"))
                        return true;
                    else if (filters.assetStatuses.sold && (a.status === "Sold"))
                        return true;
                    else
                        return false;
                });
            },

            // Search Bar Filter
            searchAssetItems: _.debounce(function () {
                if (this.searchValue !== this.searchValuePrerender) {
                    if (this.searchValue.length > 0) {
                        this.filteredAssetItems = this.sortArrays(this.assetItems).filter(item => {
                            let lowerSearchVal = this.searchValue.toLowerCase(),
                                toCheck = {
                                    name: item.name.toLowerCase(),
                                    item_type: item.asset_item_type.name.toLowerCase(),
                                    ser_num: item.serial_number !== null ? item.serial_number.toLowerCase() : null,
                                    oper_name: item.operators.length ? item.operators[0].operator.name.toLowerCase() : 'no assignment',
                                    status: item.status.toLowerCase()
                                };

                            // Loop through each field and return if any field includes the search val.
                            for (const field in toCheck) {
                                if ((toCheck[field] !== null) && toCheck[field].includes(lowerSearchVal))
                                    return true;
                            }
                        });

                        this.searchValuePrerender = this.searchValue;
                    } else {
                        this.searchValuePrerender = "";
                        this.filteredAssetItems = this.assetItems;
                    }
                }
            })
        },
    };
</script>
