<template>
    <div>
        <div class="alert banner-alert" :class="banner.classObj" style="z-index: 10000" role="alert" id="bannerAlert">
            <div class="d-flex align-items-center">
            <span class="alert-icon pt-1"><i :class="banner.iconObj"></i></span>
            <span class="alert-text">
                <strong>{{ banner.note }}</strong>&nbsp;
                {{ message }}
            </span>
            <button type="button" class="close ml-auto" aria-label="Close" @click="closeAlert()">
                <i class="ri-close-line"></i>
            </button>
			</div>
        </div>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';
export default {
    props: [],
    data() {
        return {
			showBanner: false,
            bannerTimeout: null,
            message: '',
            // timer: null,
            banner: {
                note: '',
                classObj: {},
                iconObj: {}
            },
        }
    },
    mounted() {
        this.closeAlert()

        bus.$on('banner-success', (data) => {
          //  this.closeAlert();
            this.message = data.message;
            this.banner.note = 'Success!';
            this.banner.iconObj = {
                'ri-thumb-up-fill': true
            };
            this.banner.classObj = {
                'alert-success': true
            };

            this.bannerAlert();
        });

        bus.$on('banner-error', (data) => {
            //this.closeAlert();
            this.message = data.message;
            this.banner.note = 'Something went wrong.';
            this.banner.iconObj = {
                'ri-thumb-down-fill': true
            };
            this.banner.classObj = {
                'alert-danger': true
            };

            this.bannerAlert();
        });
    },
    methods: {
        bannerAlert() {
            if(this.bannerTimeout)
                window.clearTimeout(this.bannerTimeout);

            $("#bannerAlert").hide().slideDown();
            this.bannerTimeout = setTimeout(() => {
                this.closeAlert();
            }, 8000);
            },
        closeAlert() {
            $(".banner-alert").fadeOut();
            setTimeout(() => {
                this.bannerTimeout = null;
            }, 1000);
        },
    },
}
</script>

<style>
.banner-alert {
    position: fixed !important;
    z-index: 30000;
    width: 45%;
    margin: 0;
    top: 20px;
    left: 27.5%;
}
</style>
