<template>
    <div class="card" id="assetDetails">
        <div class="card-header">
            <div class="row align-items-center">
                <div class="col-8">
                    <h3 class="mb-0 d-flex align-items-center"><i class="ri-map-pin-2-line heading-title"></i>
                        {{ $t('label.vehicles details') }}
                    </h3>
                </div>
                <div class="col-4 text-right">
                    <router-link class="btn btn-sm btn-default m-0" :to="{ name: 'vehicleEdit', params: { selectedVehicleId: vehicle.id } }">
                        <i class="ri-pencil-line"></i>{{ $t("label.edit") }}
                    </router-link>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <!-- row 1 -->
                <!-- Vehicle Name -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted">{{ $t('label.vehicles name') }}</label>
                        <h3 class="border-bottom">{{ vehicle.vehicle_name }}</h3>
                    </div>
                </div>
                <!-- Status -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted">{{ $t('label.status') }}</label>
                        <h3 class="border-bottom">
                            <span class="vehicle-status" :class="vehicle.status_class_name"></span>&nbsp;{{ vehicle.status }}&nbsp;
                        </h3>
                    </div>
                </div>

                <!-- row 2 -->
                <!-- Vin -->
                <div class="col-12 col-sm-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted">{{ $t('label.vehicles vin long') }}</label>
                        <h3 class="border-bottom">
                            <div v-if="vehicle.vin">{{ vehicle.vin }}</div>
                            <div v-else>&nbsp;</div>
                        </h3>
                    </div>
                </div>
                <!-- Operator -->
                <div class="col-12 col-sm-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted">{{ $t('label.vehicles operator') }}</label>
                        <h3 class="border-bottom">
                            <div class="row">
                                <!-- Currently Assigned Operator -->
                                <div class="col-12 col-lg-6">
                                    <div v-if="vehicle.operator_history">
                                        <user-info-link-component v-if="vehicle.operator_history[0].date_end === null" :user="vehicle.operator_history[0].operator" :message="'Operator'"></user-info-link-component>
                                    </div>
                                    <div v-else>{{ $t('label.unassigned') }}</div>
                                </div>
                                <!-- Operator History -->
                                <div class="col-12 col-lg-6 text-lg-right text-sm-left">
                                    <button type="button" class="btn btn-link m-0" data-toggle="modal" data-target="#viewOperatorHistory">
                                        {{ $t('label.vehicles view history') }}
                                    </button>
                                </div>
                            </div>
                        </h3>
                    </div>
                </div>

                <!-- row 3 -->
                <!-- License Plate -->
                <div class="col-12 col-sm-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.vehicles plate') }}</label>
                        <h3 class="border-bottom">
                            <div v-if="vehicle.license_plate">{{ vehicle.license_plate }}</div>
                            <div v-else>&nbsp;</div>
                        </h3>
                    </div>
                </div>
            </div>

            <!-- row 4  -->
            <div class="row">
                <!-- Vehicle Type -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.vehicles type') }}</label>
                        <h3 class="border-bottom">
                            <div v-if="vehicle.vehicle_type">{{ vehicle.vehicle_type }}</div>
                            <div v-else>&nbsp;</div>
                        </h3>
                    </div>
                </div>
                <!-- Ownership Type -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.vehicles ownership') }}</label>
                        <h3 class="border-bottom">
                            <div v-if="vehicle.ownership_type">{{ vehicle.ownership_type }}</div>
                            <div v-else>&nbsp;</div>
                        </h3>
                    </div>
                </div>
            </div>

            <!-- row 5 -->
            <div class="row">
                <!-- Vehicle Year -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.vehicles year') }}</label>
                        <h3 class="border-bottom">
                            <div v-if="vehicle.year">{{ vehicle.year }}</div>
                            <div v-else>&nbsp;</div>
                        </h3>
                    </div>
                </div>
                <!-- Registration State -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.vehicles registration state') }}</label>
                        <h3 class="border-bottom">
                            <div v-if="vehicle.registration_state">{{ vehicle.registration_state }}</div>
                            <div v-else>&nbsp;</div>
                        </h3>
                    </div>
                </div>
            </div>

            <!-- row 6 -->
            <div class="row">
                <!-- Make -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.vehicles make') }}</label>
                        <h3 class="border-bottom">
                            <div v-if="vehicle.make">{{ vehicle.make }}</div>
                            <div v-else>&nbsp;</div>
                        </h3>
                    </div>
                </div>
                <!-- Registration Expiration -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.vehicles registration expiration') }}</label>
                        <h3 class="border-bottom">
                            <div v-if="vehicle.registration_expiration">
                                {{ moment(vehicle.registration_expiration).format('MMMM') }}
                            </div>
                            <div v-else>&nbsp;</div>
                        </h3>
                    </div>
                </div>
            </div>

            <!-- row 7 -->
            <div class="row">
                <!-- Model -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.vehicles model') }}</label>
                        <h3 class="border-bottom">
                            <div v-if="vehicle.model">{{ vehicle.model }}</div>
                            <div v-else>&nbsp;</div>
                        </h3>
                    </div>
                </div>
                <!-- Current Odometer -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="input-name">{{ $t('label.vehicles current meter') }}</label>
                        <h3 class="border-bottom">
                            <div v-if="vehicle.odometer_reading" class="row">
                                <div class="col-12 col-sm-6">
                                    {{ vehicle.odometer_reading.odometer_reading.toLocaleString('en-US') }}&nbsp;mi
                                </div>
                                <div class="col-12 col-sm-6 text-left text-sm-right text-muted">
                                    ({{ moment(vehicle.created_at).format('MM/DD/YYYY') }})
                                </div>
                            </div>
                            <div v-else>{{ $t('label.no reading') }}</div>
                        </h3>
                    </div>
                </div>
            </div>
        </div>


        <!-- Operator History -->
        <div v-if="vehicle.operator_history" class="modal fade" id="viewOperatorHistory" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document" style="max-width: 800px">
                <asset-operator-history :users="vehicle.operator_history" :title="$t('label.vehicles operator history')" :role="$t('label.vehicles operator')" modal="true"></asset-operator-history>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['vehicle'],
    data() {
        return {}
    },
    mounted() {
        this.getOperatorHistory();
    },
    methods: {
        getOperatorHistory() {
            return axios.get(`/get-vehicle-operator-history/${this.vehicle.id}`).then(rsp => {
                this.$set(this.vehicle, 'operator_history', rsp.data.result);
            }).catch(err => console.log(err));
        },
    }
}
</script>