<template>
    <div class="bg-gray-100 p-2 d-none d-lg-block" style="z-index: inherit;">
        <div class="row">
            <div class="col-4 col-lg-6">
                <div class="copyright text-sm text-muted">
                    &copy; {{ currentYear }} SubPro
                </div>
            </div>
            <div class="col-8 col-lg-6 text-right">
                <div class="row text-sm text-center">
                    <div class="col">
                        <a href="/privacy-policy" target="_blank">{{ $t('label.privacy policy') }}</a>
                    </div>
                    <div class="col">
                        <a href="/terms-of-service" target="_blank">{{ $t('label.terms of service') }}</a>
                    </div>
                    <div class="col">
                        <a href="#" target="_blank">{{ $t('label.contact us') }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from '@/utils/date-time';
export default {
    props: [],
    components: {},
    emits: [],
    data() {
        return {
            currentYear: moment().format('Y')
        }
    },
    mounted() { },
    methods: {}
}
</script>
<style scoped>
a {
    text-decoration: none;
    color: #6c757d;
}

a:hover {
    color: #495057;
}
</style>