<template>
    <div style="position: relative">
        <!-- initialize some global components using bus.$emits -->
        <full-page-loader />
        <notify></notify>
        <banner></banner>
        <message-notif-component></message-notif-component>
        <modal-getting-started></modal-getting-started>
        <modal-clockout></modal-clockout>
        <task-off-canvas-component></task-off-canvas-component>
        <chat-popup-component></chat-popup-component>

        <!-- Site wide modal for customer information -->
        <modal name="modalForCustomerInfo" style="z-index: 9999;" :shiftY="0.1">
            <customer-info-modal :customerId="selectedId" @closeFnc="closeMe" />
        </modal>
        <!-- Site wide modal for user information -->
        <modal name="modalForUserInfo" style="z-index: 9999;" :shiftY="0.1">
            <user-info-modal :userId="selectedId" @closeFnc="closeMe" />
        </modal>
        <!-- Page Layout -->
        <div>
            <!-- Side Nav -->
            <!-- <sidebar-nav-component></sidebar-nav-component> -->
            <left-nav-component v-if="!currentSubscriptionIsNotValid"></left-nav-component>

            <!-- Main Content -->
            <div class="content-margin" :class="{ 'ml-0': currentSubscriptionIsNotValid }">
                <content-header></content-header>
                <!-- <fade-transition origin="center" mode="out-in" :duration="250"> -->
                <router-view class="pb-5"></router-view>
                <!-- </fade-transition> -->
            </div>

            <!-- Footer -->
            <div class="content-margin fixed-bottom" style="z-index: 1">
                <content-footer></content-footer>
            </div>
        </div>
    </div>
</template>

<style scoped>
/* Add content margin when sidebar is displayed */
.content-margin {
    margin-left: 250px;
}

/* Expand the content to full screen when sidebar is hidden */
@media only screen and (max-width: 1199px) {
    .content-margin {
        margin-left: 0;
    }
}
</style>
<script>
// import SalesIQ from '@/SalesIQ.js';
import CustomerInfoModal from '@/common/CustomerInfoModal';
import UserInfoModal from '@/common/UserInfoModal';
import Banner from '@/components/Banner.vue';
import MessageNotifComponent from '@/components/MessageNotifComponent.vue';
import ModalGettingStarted from '@/components/ModalGettingStarted.vue';
import Notify from '@/components/Notify.vue';
import FullPageLoader from '@/components/loader/full-page-loader';
import ContentFooter from '@/layouts/Default/ContentFooter';
import ContentHeader from '@/layouts/Default/ContentHeader';
import LeftNavComponent from '@/layouts/Default/LeftNavComponent.vue';
import bus from '@/utils/event-bus';
import { FadeTransition } from "vue2-transitions";
import { mapGetters, mapState } from 'vuex';

export default {
    components: {
        FullPageLoader,
        FadeTransition,
        UserInfoModal,
        ContentHeader,
        ContentFooter,
        CustomerInfoModal,
        LeftNavComponent,
        Notify,
        Banner,
        MessageNotifComponent,
        ModalGettingStarted
    },
    data() {
        return {
            selectedId: null,
        }
    },
    computed: {
        ...mapGetters({
            user: 'Auth/currentUser',
            currentSubscriptionIsNotValid: 'Plan/currentSubscriptionIsNotValid'
        }),
        ...mapState({
            currentSubscription: state => state.Plan.currentSubscription
        }),
    },
    created() {
        if (!this.user) {
            window.location.href = "/"
        }
        bus.$on('displayCustomerInfo', (selectedId) => {
            this.selectedId = selectedId
            this.$modal.show('modalForCustomerInfo')
        })
        bus.$on('displayUserInfo', (selectedId) => {
            this.selectedId = selectedId
            this.$modal.show('modalForUserInfo')
        })

        // Route landing page based on user            
        if (this.$router.history._startLocation === '/vue') {
            if (this.user.role_id === 1) {        // Super Admin
                this.$router.push({ name: 'companyManage' })
            }
            else if (this.user.role_id === 2) {   // Company Admin
                this.$router.push({ name: 'dashboard' })
            }
            else {                               // Field Users
                this.$router.push({ name: 'fieldTasks' })
            }
        }

    },
    mounted() {
        this.$echo.channel(`job-chat-message`)
            .listen('.JobMessageSent', (e) => {
                console.log(e);
            });

        if (this.user.role_id == 2 && this.user.getting_started) {
            $('#gettingStartedModal').modal('show');
        }
    },
    methods: {
        closeMe() {
            this.$modal.hide('modalForCustomerInfo')
            this.$modal.hide('modalForUserInfo')
        },
    }
}
</script>