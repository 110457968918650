<template>
    <div class="card">
        <!-- VPO Items Header -->
        <div class="card-header">
            <div class="d-flex align-items-center justify-content-between">
                <h3 class="d-flex align-items-center mb-0">{{ $t('label.items') }}</h3>
                <button type="button" class="btn btn-default btn-sm " @click="addItem()">
                    {{ $t('label.add item') }}
                </button>
            </div>
        </div>

        <!-- VPO Items Table -->
        <div class="table-responsive">
            <table class="table table-flush table-sm">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">{{ $t('label.billable item') }}</th>
                        <th scope="col" width="10%">{{ $t('label.quantity') }}</th>
                        <th scope="col" width="17.5%">{{ $t('label.amount') }}</th>
                        <th scope="col" width="5%">Taxable?</th>
                        <th scope="col" width="5%"></th>
                    </tr>
                </thead>
                <tbody>
                    <!-- Loop Items -->
                    <template v-for="(v, p_index) in $v.form.list_items.$each.$iter">
                        <tr :key="p_index">
                            <!-- Item name -->
                            <td>
                                <!-- <input class="form-control" type="text" v-model="v.billable_item.$model" @blur="validateForm(v)" :class="{ 'border-danger': v.billable_item.$error }" :placeholder="$t('label.material, item, service, repair, etc') + '.'" /> -->
                                <div class="dropdown w-100">
                                    <div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <div class="input-group input-group-alternative input-group-merge search-contacts">
                                            <input type="text" class="form-control form-control-dropdown pointer" placeholder="Search Items" :class="{ 'border-danger': v.billable_item.$error }" @input="filterPurchaseItems($event, p_index)" v-model="v.billable_item.$model" autocomplete="input-field" data-lpignore="true" required>
                                            <div class="input-group-append">
                                                <span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
                                        <a class="dropdown-item text-default pointer" @click="showAddPurchseItemModal('add', p_index)">
                                            <i class="ri-add-fill"></i> Add Purchase Item
                                        </a>
                                        <div class="dropdown-divider mx-3"></div>
                                        <h4 class="mx-3">{{ $t('label.results') }}</h4>
                                        <div class="pl-3">
                                            <template v-for="(data, index) in allPurchaseItemsFilter.slice(0, 4)">
                                                <div :key="index" class="pointer dropdown-items" @click="selectPurchaseItem(data, p_index)">
                                                    <span class="jobname">{{ data.name }}</span>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <!-- Quantity -->
                            <td>
                                <input :id="p_index" type="number" name="quantity" v-model="v.quantity.$model" class="form-control" min="0" oninput="validity.valid||(value='');" required @change="checkQty(p_index); autoSum()">
                            </td>
                            <!-- Amount -->
                            <td>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text input-group-invoice"><i class="ri-money-dollar-circle-line"></i></span>
                                    </div>
                                    <money v-model="v.unit_cost.$model" v-bind="money" class="form-control" required @blur.native="autoSum()"></money>
                                </div>
                            </td>
                            <!-- Tax -->
                            <td class="text-center">
                                <input type="checkbox" v-model="v.taxable.$model" @change="autoSum" />
                            </td>
                            <!-- Delete Item -->
                            <td class="text-right">
                                <a class="btn btn-sm d-flex pr-1" style="padding-left: 0px; padding-right: 0px" @click="deleteItem(p_index)">
                                    <i class="pl-1 ri-delete-bin-line text-danger heading-title"></i>
                                </a>
                            </td>
                        </tr>
                        <div class="px-4 pb-2" v-if="v.billable_item.$error && !v.billable_item.required" :key="'tr2_' + p_index">
                            <div class="text-danger text-sm">*{{ $t('label.field required') }}</div>
                        </div>
                    </template>
                </tbody>

                <tbody class="border-top">
                    <tr>
                        <td class=""></td>
                        <td class="align-top pt-3 border-top">
                            <label class="form-control-label text-muted d-block">Sub Total</label>
                        </td>
                        <td class="align-top pt-3 border-top text-right">
                            <label v-if="form.total > 0" class="form-control-label text-muted d-block">${{ form.sub_total | formatThousands }}</label>
                            <label v-else class="form-control-label text-muted d-block">$0.00</label>
                        </td>
                        <td class="border-0"></td>
                    </tr>
                    <tr>
                        <td class=""></td>
                        <td class="align-top pt-3 border-top">
                            <label class="form-control-label text-muted d-block">Total Tax</label>
                        </td>
                        <td class="align-top pt-3 border-top text-right">
                            <label v-if="form.total > 0" class="form-control-label text-muted d-block">${{ form.tax | formatThousands }}</label>
                            <label v-else class="form-control-label text-muted d-block">$0.00</label>
                        </td>
                        <td class="border-0"></td>
                    </tr>
                    <tr>
                        <td class=""></td>
                        <td class="align-top pt-3 border-top">
                            <label class="form-control-label text-muted d-block">Total</label>
                        </td>
                        <td class="align-top pt-3 border-top text-right">
                            <label v-if="form.total > 0" class="form-control-label text-muted d-block">${{ form.total | formatThousands }}</label>
                            <label v-else class="form-control-label text-muted d-block">$0.00</label>
                        </td>
                        <td class="border-0"></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <modal class="py-4" name="addPurchaseOrderModal" height="auto" width="98%" :max-width="1024" :reset="true" :scrollable="true" :adaptive="true">
            <ModalAddEditPurchaseItem @cancelFnc="hideAddPurchseItemModal" />
        </modal>
    </div>
</template>

<script>
import ModalAddEditPurchaseItem from '@/components/Admin/Vendors/ModalAddEditPurchaseItem.vue';
import { minValue, required } from 'vuelidate/lib/validators';

export default {
    props: ['form', 'isValid', 'type', 'formDirty'],
    components: {
        ModalAddEditPurchaseItem
    },
    data() {
        return {
            companySettings: JSON.parse(localStorage.getItem('company_settings')),
            purchaseItems: [],
            allPurchaseItemsFilter: [],
            modalType: '',
            modalIndex: null,
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '',
                precision: 2,
                masked: false
            },
        }
    },
    validations: {
        form: {
            list_items: {
                $each: {
                    billable_item: { required },
                    quantity: { required },
                    unit_cost: {
                        required,
                        minValue: minValue(0.00),
                    },
                    taxable: {}
                }
            }
        },
    },
    watch: {
        // Watch for parent marking children forms as dirty
        formDirty(newVal) {
            if (newVal) this.$v.form.list_items.$touch();
        }
    },
    mounted() {
        this.getPurchaseItems();
        if (this.type == 'add')
            this.addItem();
        else if (this.type == 'edit')
            this.autoSum();
    },
    methods: {
        validateForm(v) {
            // Mark all items as dirty for parents form validation
            v.$touch();
            this.isValid.items = !this.$v.form.list_items.$invalid;
        },

        addItem() {
            this.form.list_items.push({
                billable_item: '',
                quantity: 0,
                unit_cost: '',
                selectedItem: '',
            }, {
                billable_item: '',
                quantity: 0,
                unit_cost: '',
                selectedItem: '',
            }, {
                billable_item: '',
                quantity: 0,
                unit_cost: '',
                selectedItem: '',
            });
            this.isValid.items = !this.$v.form.list_items.$invalid;
        },
        deleteItem(index) {
            if (this.form.list_items[index].id) // Needs to be removed from database
                this.form.list_items_to_delete.push(this.form.list_items[index]);


            // this.$v.form.list_items.splice(index, 1);
            this.form.list_items.splice(index, 1);
            this.autoSum();
            this.isValid.items = !this.$v.form.list_items.$invalid;
        },

        checkQty(index) {
            // Correct qty if item has less than 0 for quantity
            let qty = this.form.list_items[index].quantity;
            if (qty == null || qty == undefined || qty == '')
                this.form.list_items[index].quantity = 0;
        },
        autoSum() {
            // Sum up the total of all items
            this.form.total = 0;
            this.form.sub_total = 0;
            this.form.list_items.forEach((data, i) => {
                this.form.total += parseFloat(data.quantity) * parseFloat(data.unit_cost);
                this.form.sub_total += parseFloat(data.quantity) * parseFloat(data.unit_cost);
                if (this.companySettings.settings.invoice && data.taxable) {
                    this.form.list_items[i].tax = Number(data.quantity) * (parseFloat(data.unit_cost) * parseFloat(this.companySettings.settings.tax_rate / 100).toFixed(4));
                } else {
                    this.form.list_items[i].tax = 0;
                }
            });

            this.form.tax = parseFloat(this.form.list_items.reduce((n, { tax }) => n + tax, 0) || 0).toFixed(2);
            this.form.sub_total = parseFloat(this.form.sub_total).toFixed(2);
            this.form.total = parseFloat(Number(this.form.sub_total) + Number(this.form.tax)).toFixed(2);
        },
        getPurchaseItems() {
            var currentUrl = window.location.pathname;
            const firstSegment = currentUrl.split('/')[1];
            axios.get("/purchase-items/list", {
                params: {
                    itemType: firstSegment
                }
            }).then((response) => {
                this.purchaseItems = response.data.data.models;
                this.allPurchaseItemsFilter = response.data.data.models;
                this.allPurchaseItemsFilter.forEach((item, i) => {
                    let data = { ...item, taxable: false }
                    this.allPurchaseItemsFilter[i] = data;
                })
                this.$emit('onLoaded');
            }).catch((err) => console.log("ERROR: ", err))
        },
        filterPurchaseItems($e, index) {
            this.allPurchaseItemsFilter = this.purchaseItems;
            if ($e.target.value.length > 0) {
                this.allPurchaseItemsFilter = this.purchaseItems.filter(x => x.name.toLowerCase().includes($e.target.value.toLowerCase()));
            } else {
                this.allPurchaseItemsFilter = this.purchaseItems;
            }
        },
        showAddPurchseItemModal(e, p_index) {
            this.modalType = e;
            this.modalIndex = p_index;
            this.$modal.show('addPurchaseOrderModal');
        },
        hideAddPurchseItemModal(item) {
            if (item) {
                this.getPurchaseItems();
                this.selectPurchaseItem(item, this.modalIndex);
            }
            this.modalType = null;
            this.modalIndex = null;
            this.$modal.hide('addPurchaseOrderModal');
        },
        selectPurchaseItem(data, p_index) {
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            });
            const model = this.form.list_items[p_index]
            model.billable_item = data.name
            model.quantity = 1
            model.subtotal = model.quantity * Number(data.price)
            model.unit_cost = formatter.format(data.price).replace('$', '')
            model.item_id = data.id
            this.autoSum();
        }
    },
}
</script>
