<template>
    <div class="col-xl-12 order-xl-1 mobile-p-0 p-0" id="assetOperatorHistory">
        <!-- Header -->
        <div class="card" :class="{ 'modal-content': modal }">
            <div class="card-header">
                <div class="row pt-2">
                    <div class="col-6">
                        <h5 class="modal-title" id="assetOperatorHistoryTitle">{{ this.title }}</h5>
                    </div>
                    <div class="col-6">
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Body -->
            <div class="card-body p-0 m-0">
                <div v-if="users" class="card-body p-0 mt-2 border-top">
                    <div class="mt--1">
                        <div class="bg-white text-center row align-items-center m-0">
                            <i class="ri-user-fill ni-8x w-100 color-gray-400"></i>
                            <p class="w-100 font-weight-normal">{{ $t('label.no users have been assigned to this asset') }}.</p>
                        </div>
                    </div>
                </div>

                <!-- Operator History Table -->
                <div v-else class="table-responsive p-0 m-0">
                    <table class="table table-flush table-sm">
                        <thead class="thead-light">
                            <tr class="">
                                <th>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-4">{{ this.role }}</div>
                                            <div class="col-4">{{ $t('label.start date') }}</div>
                                            <div class="col-4">{{ $t('label.end date') }}</div>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="" v-for="(user, idx) in users" :key="idx">
                                <td>
                                    <div class="container">
                                        <div class="row">
                                            <!-- Avatar and employee name  -->
                                            <div class="col-4">
                                                <div class="d-flex">
                                                    <img class="rounded-circle" :src="user.operator.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">&nbsp;
                                                    <div>
                                                        <!-- Emp Name -->
                                                        <a class="cursor-pointer" href="#" @click="getUserInfo(user.operator.id, $t('label.responsible person'))">
                                                            {{ user.operator.first_name }} {{ user.operator.last_name }}
                                                        </a>
                                                        <!-- Employee Role  -->
                                                        <div class="text-muted">
                                                            {{ user.operator.role.name }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Start date  -->
                                            <div class="col-4 pt-2">{{ user.date_start }}</div>
                                            <!-- End date  -->
                                            <div class="col-4 pt-2">
                                                <div v-if="user.date_end">
                                                    {{ user.date_end }}
                                                </div>
                                                <div v-else>
                                                    {{ $t('label.current') }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- Footer -->
            <div class="card-footer border-top d-flex justify-content-between">
                <button class="btn btn-primary" type="button" data-dismiss="modal">
                    {{ ($t('label.close')).toUpperCase() }}
                </button>
            </div>
        </div>
    </div>
</template>

<style>

</style>

<script>
export default {
    props: ["users", "title", "role", "modal"],
    components: {},
    data() {
        return {}
    },
    mounted() { }
}
</script>
