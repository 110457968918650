<template>
	<div>
		<div class="modal-content">
			<div class="modal-header border-bottom">
				<h5 class="modal-title" id="invoiceSendModalTitle"> {{ sendType == "cancel" ? $t('label.send cancelation email') : $t('label.send email') }}</h5>
				<button type="button" class="close" @click="$emit('cancelFnc')">
					<i class="ri-close-line"></i>
				</button>
			</div>
			<form method="post" autocomplete="false" enctype="multipart/form-data" @submit.prevent="handleFormSubmit">
				<div class="modal-body">
					<!-- To: Email Address -->
					<div v-if="changesToVpo" class="row m-0 mb-3">
						<div class="col-12">
							<label class="form-control-label mb-0">{{ $t('label.changes to the vpo require an email to the customer with an attached vpo') }}.</label>
						</div>
					</div>
					<div class="row m-0 mb-3">
						<div class="col-12">
							<label for="email_to" class="form-control-label">{{ $t('label.to') }}</label>&nbsp;
							<span class="text-xs text-muted">( {{ $t('label.separate multiple email addresses with a comma') }} )</span>

							<input type="text" id="email_to" name="email_to" v-model="email.to" class="form-control" :placeholder="$t('label.email to')" autocomplete="false" required>
						</div>
					</div>
					<!-- Email Subject -->
					<div class="row m-0 mb-3">
						<div class="col-12">
							<label for="subject" class="form-control-label">{{ $t('label.subject') }}</label>
							<input type="text" id="subject" name="subject" v-model="email.subject" class="form-control" autocomplete="false" :placeholder="$t('label.email subject')" required>
						</div>
					</div>
					<!-- Email Body -->
					<div class="row m-0 mb-3">
						<div class="col-12">
							<label class="form-control-label">{{ $t('label.email body') }}</label>
							<textarea v-model="email.body" name="email_body" class="form-control" :placeholder="$t('label.body of email')" rows="10" required autofocus></textarea>
						</div>
					</div>

					<!-- Attachments Section -->
					<!-- PDF -->
					<div class="row m-0 mb-3">
						<div class="col-12">
							<label class="form-control-label">{{ $t('label.attachments') }}</label>
							<div class="row align-items-center m-0">
								<div class="col-auto p-0">
									<img class="" :src="require('@/assets/images/doc-pdf.png')" style="height: 48px; object-fit: cover;" />
								</div>
								<div class="col-auto">
									<p class="m-0" style="line-height: 20px;">
										<label class="form-control-label mb-0">{{ email.vpo_file_name }}</label>
										<br>
										<span class="text-muted">
											{{ email.vpo_file_size }}
										</span>
									</p>
								</div>
							</div>
						</div>
					</div>
					<!-- Additional Attachments -->
					<div class="row m-0 mb-3">
						<div class="col-12">
							<!-- List of existing and newly added attachments -->
							<div class="row m-0 pt-3 px-0">
								<div class="col-6 d-flex pb-3 pl-0 d-flex align-items-center" v-for="(file, index) in attachments" :key="index">
									<div class="d-flex align-items-center flex-grow-1">
										<div class="d-flex align-items-center" v-if="file.id">
											<img class="image-container float-left mr-2" style="height: 48px;  object-fit: cover;" :src="file.file_type == 'application/pdf' ? '/images/icons/doc-pdf.png' : '/' + file.download" />
											<span class="cursor-pointer text-bold" @click="selectFile(file, 'view')">
												<span v-if="file.display_name">{{ file.display_name }}</span>
												<span v-else>{{ file.name }}</span>
											</span>
										</div>
										<div v-else class="d-flex align-items-center">
											<img class="image-container float-left mr-2" style="height: 48px; object-fit: cover;" :src="(file.fileType === 'image/jpeg' || file.fileType === 'image/png') ? file.image : '/images/icons/doc-pdf.png'" />
											<span class="cursor-pointer text-bold" @click="selectFile(file, 'view')">{{ file.fileName }}</span>
										</div>
									</div>
									<div class="pl-2 ml-auto">
										<button type="button" class="btn btn-danger py-0 px-2" @click="showDltAttachModal(file, index)">
											<i class="ri-delete-bin-line heading-title p-0"></i>
										</button>
									</div>
								</div>
							</div>
							<!-- Dropzone to Add Attachments -->
							<div class="form-group dropzone-file m-0">
								<vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions" v-on:vdropzone-success="getUploadedFile" v-on:vdropzone-queue-complete="clearDZQueue"></vue-dropzone>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer border-top">
					<button type="submit" class="btn btn-primary mr-auto text-uppercase">{{ $t('label.send email') }}</button>
					<button type="button" class="btn btn-link  ml-auto" @click="$emit('cancelFnc')">{{ $t('label.cancel') }}</button>
				</div>
			</form>
		</div>

		<!-- Delete Attachment Modal -->
		<DeleteModal v-if="viewDltAttachModal" newId="vpoSendDeleteAttachment" @onClose="hideDltAttachModal" @customDeleteMethod="deleteAttachment">
		</DeleteModal>
	</div>
</template>

<script>
import DeleteModal from '@/components/ModalDelete2.vue';
import bus from '@/utils/event-bus';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
	props: ['vpo', 'changesToVpo', 'sendType', 'beforeSendFnc'],
	emits: ['cancelFnc', 'returnAfterSend'],
	components: {
		DeleteModal,
		vueDropzone: vue2Dropzone
	},
	data() {
		return {
			companySettings: JSON.parse(localStorage.getItem('company_settings')),
			curDate: moment().format("YYYY_MM_DD"),
			attachments: [],
			email: {
				to: null,
				subject: null,
				body: null,
				vpo_file_name: null,
				vpo_file_size: null,
				vpo_id: null
			},
			dropzoneOptions: {
				url: 'https://httpbin.org/post',
				acceptedFiles: 'image/*, application/pdf',
				maxFilesize: 10,
				addRemoveLinks: true,
				dictDefaultMessage: `<div><span>Drag and drop files here</span><br>
                                <span>OR</span><br>
                                <button type="button"class="btn btn-md btn-default">Browse Files</button></div>`,
			},
			viewTemplate: "form",
			disableSubmit: false,
			viewDltAttachModal: false,
			previouslySentEmail: null,
			selectedFile: {
				id: null
			},
		}
	},
	mounted() {
		this.initEmailData();
	},
	methods: {
		initEmailData() {
			this.email.vpo_id = this.vpo.id;
			this.email.email_type = this.sendType;
			this.email.subject = (this.sendType == 'cancel' ? 'CANCELLED - ' : "");
			this.email.subject += "VPO #" + this.vpo.vpo_number.vpo_number + " from " + this.companySettings.name;
			this.email.vpo_file_name = (this.sendType == 'cancel' ? 'CANCELLED_' : "")
			this.email.vpo_file_name += 'VPO-' + this.vpo.vpo_number.vpo_number + '_' + this.curDate + '.pdf';

			// Set 'to' recipients based on email type
			if (this.sendType == 'cancel') {
				// Get all recipients the original email was sent to
				this.getSentEmail().then(() => {
					let emailAddresses = JSON.parse(this.previouslySentEmail.to);

					this.email.to = "";
					emailAddresses.forEach((emAd, i) => {
						this.email.to += emAd;
						this.email.to += (i != emailAddresses.length - 1) ? ", " : "";
					});
				});

			} else {
				this.email.to = this.vpo.customer.email;
			}
		},
		async getSentEmail() {
			return axios.get(`/get-vpo-first-sent-email/${this.vpo.id}`).then(rsp => {
				this.previouslySentEmail = rsp.data.result;
			}).catch(err => console.error(err));
		},

		handleFormSubmit() {
			if (this.changesToVpo) {
				bus.$emit('updateVpo', this.email);
				this.$emit('cancelFnc');

			} else {
				this.loader = this.$loading.show();

				if (this.sendType == 'request' || this.sendType == 'cancel') {
					this.sendVpo();
				} else if (this.sendType == 'save_request') {
					// Perform call back then save VPO
					this.beforeSendFnc().then(() => {
						this.email.vpo_id = this.vpo.id;
						this.sendVpo();
					});
				}
			}
		},

		formValid() {
			let isValid = true,
				emails = this.$spSplitEmailString(this.email.to);

			// Validate email(s)
			emails.forEach(email => {
				isValid = this.$spValidEmail(email);
				// Break out of foreach if an email is invalid and alert user
				if (!isValid) {
					bus.$emit('banner-error', {
						message: $t('label.Please verify that the email(s) in the "To" field are correct If multiple emails are entered, be sure to separate them with commas')
					});

					this.loader.hide();
					return;
				}
			});

			return isValid;
		},


		sendVpo() {
			let uploadPromises = [];

			if (this.formValid()) {
				let emailData = this.email;
				emailData.toList = this.$spSplitEmailString(this.email.to);

				// Save any newly added attachments
				if (this.attachments.length > 0) {
					this.attachments.forEach(file => {
						if (!file.id)
							uploadPromises.push(this.saveFile(this.vpo.id, file));
					});
				}

				// Upload Files
				Promise.all(uploadPromises).then(response => {
					// Save and Send Email
					axios.post('/vpo-send-email', this.email).then(resp => {
						bus.$emit('banner-success', { message: `VPO Email sent successfully!` });

						this.loader.hide()
						this.$emit('returnAfterSend');
						this.$emit('cancelFnc');
					}).catch((err) => console.error(err));
				});
			}
		},

		clearForm() {
			this.email.to = null,
				this.email.subject = null;
			this.email.body = null;
			this.email.vpo_file_name = null;
			this.email.vpo_file_size = null;
			this.requestData = null
		},
		getUploadedFile(file) {
			this.attachments.push({
				image: file.dataURL,
				fileName: file.name,
				fileType: file.type,
				file: file
			});
		},
		clearDZQueue() {
			this.$refs.myVueDropzone.removeAllFiles(true);
		},

		selectFileToView(file) {
			this.selectedFile = file;
		},

		showDltAttachModal(file, idx) {
			this.selectedFile = file;
			this.selectedFile.idx = idx;

			this.viewDltAttachModal = true;
		},
		hideDltAttachModal() {
			this.viewDltAttachModal = false;
		},

		deleteAttachment() {
			if (this.selectedFile.id) { // Delete Saved File
				let rqstData = {
					id: this.selectedFile.id
				};

				axios.post(`/vpo/delete-attachment`, rqstData).then(() => {
					this.attachments.splice(this.selectedFile.idx, 1);
					bus.$emit('banner-success', {
						message: $t('label.vpo file deleted successfully')
					});
				}).catch(err => console.log(err));

			} else { // Delete Unsaved File
				this.attachments.splice(this.selectedFile.idx, 1);
				bus.$emit('banner-success', {
					message: $t('label.vpo file deleted successfully')
				});
			}
		},
		saveFile(entryId, file) {
			const data = new FormData();
			data.append('download', file.file);
			data.append('data', JSON.stringify({
				id: entryId,
				attachment_type: this.sendType == 'cancel' ? 'cancel' : 'send'
			}));

			return axios.post('/vpo/upload-attachment', data);
		},
	}
}
</script>
<style></style>
