<template>
    <div class="purchase-order">
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50">
            <!-- Header -->
            <div class="d-flex z-index-10">
                <!-- Breadcrumbs -->
                <div class="mr-auto z-index-1">
                    <breadcrumbs :title="$t('label.vpos')">
                        <!-- Breakcrumb slots -->
                        <li class="breadcrumb-item">
                            <span>{{ $t('label.vpos') }}</span>
                        </li>
                    </breadcrumbs>
                </div>

                <!-- New VPO -->
                <div class="py-4 align-items-center" style="z-index: 1">
                    <router-link class="text-primary" :to="{ name: 'variablePurchaseOrderCreate', params: { jobId: -1 } }">
                        <button type="button" class="btn btn-neutral">
                            {{ $t('label.new vpo') | uppercase }}
                        </button>
                    </router-link>
                </div>
            </div>

            <!-- Body -->
            <div class="card">
                <div class="card-header">
                    <h3 class="d-flex align-items-center mb-0">
                        <i class="ri-article-line"></i> {{ $t('label.vpos') }}
                    </h3>
                </div>
                <div v-if="allVpos.length == 0" class="card-body py-5">
                    <div class="bg-white text-center row align-items-center justify-content-center m-0">
                        <i class="ri-article-line" style="color: #CED4DA !important; font-size: 85px;"></i>
                        <p class="w-100 font-weight-normal">{{ $t('label.no VPOs have been created') }}.</p>
                    </div>
                </div>
                <div v-else class="card-body p-0">
                    <div class="row m-0 py-4">
                        <!-- VPO Searchbox -->
                        <div class="col-4">
                            <div class="form-group mb-0">
                                <div class="input-group input-group-alternative input-group-merge search-contacts">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-search"></i></span>
                                    </div>
                                    <input class="form-control" :placeholder="$t('label.search vpos')" type="text" v-model="searchText" autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <!-- Filter VPO's by Status -->
                        <div class="col-4">
                            <div class="form-group mb-0">
                                <div class="dropdown w-100">
                                    <div class="w-100" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <div class="input-group input-group-alternative input-group-merge search-contacts">
                                            <input name="anystatus" class="form-control form-control-dropdown pointer" :placeholder="$t('label.any status')" autocomplete="input-field" data-lpignore="true" readonly>
                                            <div class="input-group-append">
                                                <span class="input-group-text"><i class="ri-arrow-down-s-line"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100" @click.stop="">
                                        <a v-for="(d, i) in filterByStatuses" :key="'user-' + i" class="dropdown-item text-default">
                                            <label class="form-control-label font-weight-normal m-0 w-100 pointer" :for="'filter-' + d.id">
                                                <input class="form-control-input" :id="'filter-' + d.id" type="checkbox" v-model="d.active">
                                                {{ d.name }}
                                            </label>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-right">
                            <export-excel type="csv" name="VPOExport.csv" worksheet="Timesheet Report" class="btn btn-secondary btn-sm" :data="filteredV" :fields="vpo_header">
                                <i class="ri-file-excel-line"></i>
                                {{ $t('label.export results') }}
                            </export-excel>
                            <!-- TODO Sync QB -->
                            <button type="button" class="btn btn-secondary btn-sm" @click="syncVpo2QB()">
                                <i class="ri-refresh-line"></i>Sync QuickBooks Online
                            </button>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-flush table-sm border-bottom">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col" style="width: 8.5rem">VPO #</th>
                                    <th scope="col" style="width: 15rem">{{ $t('label.customer') }}</th>
                                    <th scope="col" style="width: 15rem">Quickbooks Invoice No.</th>
                                    <th scope="col" style="width: 15rem">{{ $t('label.job') }}</th>
                                    <th scope="col" style="width: 10rem">{{ $t('label.amount') }}</th>
                                    <th scope="col" style="width: 10rem">{{ $t('label.created') }}</th>
                                    <th scope="col" style="width: 10rem">{{ $t('label.status') }}</th>
                                    <th scope="col" style="width: 8rem"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- Loop Tasks -->
                                <tr v-for="(data, index) in paginatedAllVpos" :key="index">
                                    <!-- PO # -->
                                    <td>
                                        <div class="cursor-pointer text-dark-blue text-bold">
                                            <router-link :to="{ name: 'variablePurchaseOrderView', params: { id: data.id } }">
                                                {{ data.vpo_number.vpo_number }}
                                            </router-link>
                                        </div>
                                    </td>
                                    <!-- Customer -->
                                    <td>
                                        <div class="cursor-pointer text-dark-blue text-bold">
                                            <router-link :to="{ name: 'customer', params: { customerId: data.customer.id } }">
                                                {{ data.customer?.name }}
                                            </router-link>
                                        </div>
                                    </td>
                                 <td><a v-if="data.qb_id" target="_blank" :href="qb_url+'app/invoice?txnId='+data.qb_id">{{ data.quickbooks_bill_no }}</a></td>
                                    <!-- Job -->
                                    <td>
                                        <div class="cursor-pointer text-dark-blue text-bold" v-if="data.job" @click="editExistingJob(data.job.job_number.job_number)">{{ data.job.job_name }}</div>
                                    </td>
                                    <!-- Amount -->
                                    <td>
                                        <div class="job-name search-task">${{ parseFloat(data.overall_total).toFixed(2) | formatThousands }}</div>
                                    </td>
                                    <!-- Created Date -->
                                    <td>
                                        <div class="job-name search-task">{{ moment(data.created_at).format("MM/DD/YYYY") }}</div>
                                    </td>
                                    <!-- Status -->
                                    <td>
                                        <span class="badge badge-dot search-task">
                                            <i class="bg-success" :style="'background-color:' + data.vpo_status.color + ' !important'"></i>
                                            <span :class="{ 'text-success': data.vpo_status.name == 'Invoiced' }">{{ data.vpo_status.name }}</span>
                                        </span>
                                    </td>
                                    <!-- Menu Dropdown -->
                                    <td class="text-right">
                                        <a v-if="data.has_notes" class="btn dropdown-toggle" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                                            <i class="ri-message-2-line heading-title p-1"></i>
                                        </a>
                                        <a v-if="data.has_files" class="btn dropdown-toggle" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                                            <i class="ri-folders-line heading-title p-1"></i>
                                        </a>
                                        <a class="btn dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                                            <i class="ri-more-2-fill heading-title p-1"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" aria-labelledby="taskItemMenu">
                                            <!-- Edit VPO -->
                                            <router-link v-if="data.vpo_status?.name == 'Draft'" class="dropdown-item text-default pointer" :to="{ name: 'variablePurchaseOrderEdit', params: { id: data.id } }">
                                                <i class="ri-pencil-line"></i> {{ $t('label.edit') }}
                                            </router-link>
                                            <!-- View VPO Details -->
                                            <router-link class="dropdown-item text-default pointer" :to="{ name: 'variablePurchaseOrderView', params: { id: data.id } }">
                                                <i class="ri-eye-line"></i> {{ $t('label.view details') }}
                                            </router-link>
                                            <!-- View VPO PDF -->
                                            <a class="dropdown-item text-default pointer" @click="showModalVpoView(data)">
                                                <i class="ri-eye-line"></i> {{ $t('label.view pdf') }}
                                            </a>
                                            <!-- Send VPO -->
                                            <a v-if="data.vpo_status.name == 'Draft'" class="dropdown-item text-default pointer" @click="showModalVpoSend(data)">
                                                <i class="ri-mail-send-line"></i> {{ $t('label.send') }}
                                            </a>
                                            <!-- Download VPO as PDF -->
                                            <a :href="'/get-vpo-pdf-download/' + data.id" class="dropdown-item text-default pointer">
                                                <i class="ri-download-line"></i> {{ $t('label.download') }}
                                            </a>
                                            <!-- Print VPO PDF -->
                                            <a :href="'/get-vpo-pdf-file/' + data.id" target="_blank" class="dropdown-item text-default pointer">
                                                <i class="ri-printer-line"></i> {{ $t('label.print') }}
                                            </a>
                                            <!-- Mark Requested VPO as Approved or Denied -->
                                            <template v-if="data.vpo_status.name == 'Requested'">
                                                <div class="dropdown-divider mx-3"></div>
                                                <a class="dropdown-item text-default pointer" @click="setSelectedVpo(data); showMarkVpoApprModal()">
                                                    <span class="d-flex align-items-center">
                                                        <i class="ri-checkbox-circle-line"></i> {{ $t('label.mark as approved') }}
                                                    </span>
                                                </a>
                                                <a class="dropdown-item text-danger pointer" @click="setSelectedVpo(data); showMarkVpoDeniedModal()">
                                                    <span class="d-flex align-items-center">
                                                        <i class="ri-close-circle-fill"></i> {{ $t('label.mark as denied') }}
                                                    </span>
                                                </a>
                                            </template>
                                            <!-- Create Invoice from Approved VPO -->
                                            <template v-else-if="data.vpo_status.name == 'Approved'">
                                                <div class="dropdown-divider mx-3"></div>
                                                <a class="dropdown-item text-success pointer" @click="setSelectedVpo(data); showVpoInvoiceAddModal(data)">
                                                    <span class="d-flex align-items-center">
                                                        <i class="ri-checkbox-circle-fill"></i> {{ $t('label.create invoice') }}
                                                    </span>
                                                </a>
                                            </template>
                                            <!-- View Invoice PDF -->
                                            <template v-else-if="data.vpo_status.name == 'Invoiced'">
                                                <div class="dropdown-divider mx-3"></div>
                                                <a class="dropdown-item text-default pointer" @click="setSelectedVpo(data); showVpoInvoicePDFModal(data)">
                                                    <span class="d-flex align-items-center">
                                                        <i class="ri-bill-line"></i> {{ $t('label.view invoice') }}
                                                    </span>
                                                </a>
                                            </template>
                                            <!-- Cancel/Delete VPO -->
                                            <template v-if="isVpoRemovable(data)">
                                                <div class="dropdown-divider mx-3"></div>
                                                <a class="dropdown-item text-danger pointer" @click="setSelectedVpo(data); toggleDeleteModal(true)">
                                                    <span class="d-flex align-items-center">
                                                        <i class="ri-close-circle-line"></i> {{ data.vpo_status.name == 'Draft' ? $t('label.delete vpo') : $t('label.cancel vpo') }}
                                                    </span>
                                                </a>
                                            </template>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table-pagination v-model="paginatedAllVpos" :all-rows="filteredAllVpos" :perPage="perPage">
                        </table-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- View VPO PDF Modal -->
        <modal class="py-4" name="modalVpoView" height="auto" width="100%" :max-width="1000" :scrollable="true" :reset="true" :adaptive="true">
            <ModalVpoView :vpo="vpoData" @reload="loadLkupData; " @cancelFnc="$modal.hide('modalVpoView')"></ModalVpoView>
        </modal>

        <!-- Send VPO Modal -->
        <modal class="py-4" name="modalVpoSend" height="auto" width="100%" :max-width=" 900 " :scrollable=" true " :reset=" true " :adaptive=" true ">
            <ModalVpoSend :vpo=" vpoData " :send-type=" sendType " @cancelFnc="$modal.hide('modalVpoSend')" @returnAfterSend="getAllVpos()"></ModalVpoSend>
        </modal>

        <!-- Mark VPO as Approved -->
        <ModalConfirmation v-if=" viewMarkVpoApprModal " newId="MarkVpoApproved" :title=" $t('label.ARE YOU SURE YOU WANT TO MARK THIS VPO AS APPROVED') " @onClose="hideMarkVpoApprModal()" @customConfirmMethod="updateStatus('Approved')">
        </ModalConfirmation>

        <!-- Create Invoice From VPO -->
        <modal name="VpoInvoiceAddModal" class="pb-6" width="100%" height="auto" :reset=" true " :adaptive=" true " :max-width=" 1200 " :scrollable=" true ">
            <ModalInvoice v-if=" viewVpoInvoiceAddModal " source2="vpo" source="addInvoice" :job=" selectedVpoJob " :vpoId=" selectedVpo.id " :customerid=" selectedVpo.customer_id " @reloadInvoices=" getAllVpos " @cancelFnc=" hideVpoInvoiceAddModal " @customChange=" vpoInvcMdlCustChng ">
            </ModalInvoice>
        </modal>

        <!-- VPO Edit Invoice Modal -->
        <modal name="VpoInvoiceEditModal" class="pb-6" width="100%" height="auto" :reset=" true " :adaptive=" true " :max-width=" 1200 " :scrollable=" true ">
            <ModalInvoice v-if=" viewVpoInvoiceEditModal " source2="vpo" source="editInvoice" :job=" selectedVpoJob " :customerid=" selectedVpo.customer_id " :invoice-data=" { data: selectedVpoInvoice } " @reloadInvoices=" getAllVpos " @cancelFnc=" hideVpoInvoiceEditModal " @customChange=" vpoInvcMdlCustChng ">
            </ModalInvoice>
        </modal>

        <!-- VPO Invoice PDF Modal -->
        <modal class="pb-6" name="vpoInvoiceViewModal" height="auto" width="100%" :max-width=" 1200 " :scrollable=" true " :reset=" true " :adaptive=" true ">
            <ModalInvoiceView v-if=" viewVpoInvoicePDFModal " :job=" selectedVpoJob " :invoice-data=" { data: selectedVpoInvoice } " @cancelFnc=" hideVpoInvoicePDFModal " @reloadInvoices=" getAllVpos "></ModalInvoiceView>
        </modal>

        <!-- VPO Invoice Send Modal -->
        <modal class="pb-6" name="vpoInvoiceSendModal" height="auto" width="100%" :max-width=" 900 " :scrollable=" true " :reset=" true " :adaptive=" true ">
            <ModalInvoiceSend v-if=" viewVpoInvoiceSendModal " :job=" selectedVpoJob " :invoice-data=" { data: selectedVpoInvoice } " @reloadInvoices=" getAllVpos " @cancelFnc=" hideVpoInvoiceSendModal "></ModalInvoiceSend>
        </modal>

        <!-- VPO Invoice Payment Modal -->
        <modal class="pb-6" name="vpoInvoicePaymentModal" height="auto" width="100%" :max-width=" 1200 " :scrollable=" true " :reset=" true " :adaptive=" true ">
            <ModalPayment v-if=" viewVpoInvoicePaymentModal " source2="job" source="invoice" :job=" selectedVpoJob " :curr-id=" selectedVpoJob.id " :invoice-data=" { data: selectedVpoInvoice } " @reloadPayments=" getAllVpos " @cancelFnc=" hideVpoInvoicePaymentModal "></ModalPayment>
        </modal>

        <!-- Mark VPO as Denied -->
        <ModalConfirmMarkDenied v-if=" viewMarkVpoDeniedModal " newId="MarkVpoDenied" :btnText=" $t('label.yes') " :title=" $t('label.ARE YOU SURE YOU WANT TO MARK THIS VPO AS DENIED') " @onClose="hideMarkVpoDeniedModal()" @customDeleteMethod="updateStatus('Denied')">
        </ModalConfirmMarkDenied>

        <!-- Cancel VPO Modal -->
        <modal-delete-component v-if=" showDltPOModal " :newId=" 'Vpo_view' " :title=" isVpoDraft ? $t('label.ARE YOU SURE YOU WANT TO DELETE THIS VPO') : $t('label.ARE YOU SURE YOU WANT TO CANCEL THIS VPO') " :subtitle=" cancelVpoSubtitle " :btnText=" isVpoDraft ? $t('label.Yes, Delete VPO') : $t('label.Yes, Cancel VPO') " @onClose="toggleDeleteModal()" @customDeleteMethod="isVpoDraft ? deleteVpo() : showSendCancelModal()">
        </modal-delete-component>

        <fade-transition origin="center" :duration=" 250 " tag="div">
            <CustomLoader v-if=" qbSyncing " :loaderText=" loaderText " />
        </fade-transition>

    </div>
</template>

<script>
import ModalConfirmation from '@/components/ModalConfirmation.vue';
import ModalDeleteComponent from '@/components/ModalDelete2.vue';
import ModalInvoice from '@/components/ModalInvoice.vue';
import ModalInvoiceSend from '@/components/ModalInvoiceSend.vue';
import ModalInvoiceView from '@/components/ModalInvoiceView.vue';
import ModalPayment from '@/components/ModalPayment.vue';
import TablePagination from '@/components/TablePagination';
import ModalVpoSend from '@/components/VariablePurchaseOrders/SendVariablePurchaseOrder/VpoSendModal.vue';
import ModalVpoView from '@/components/VariablePurchaseOrders/modals/VpoPdfView.vue';
import bus from '@/utils/event-bus';
import CustomLoader from './tabs/loader.vue';

export default {
    props: ['id', 'modalToOpen'],
    components: {
        ModalPayment,
        ModalVpoView,
        ModalVpoSend,
        ModalInvoice,
        TablePagination,
        ModalInvoiceView,
        ModalInvoiceSend,
        ModalConfirmation,
        ModalDeleteComponent,
        ModalConfirmMarkDenied: ModalDeleteComponent,
        CustomLoader
    },
    data() {
        return {
            perPage: 10,
            loader: null,
            sendType: '',
            searchText: '',
            selectedVpo: {},
            selectedVpoJob: {},
            invoiceViewData: {},
            selectedVpoInvoice: {},
            allVpos: [],
            vpoData: [],
            allVpoStatus: [],
            filterByStatuses: [],
            paginatedAllVpos: [],
            showDltPOModal: false,
            viewMarkVpoApprModal: false,
            viewMarkVpoDeniedModal: false,
            viewVpoInvoiceAddModal: false,
            viewVpoInvoicePDFModal: false,
            viewVpoInvoiceEditModal: false,
            viewVpoInvoiceSendModal: false,
            viewVpoInvoicePaymentModal: false,
            vpo_header: {
                'VPO #': {
                    field: 'vpo_number.vpo_number',
                    callback: (value) => {
                        return value;
                    }
                },
                'CUSTOMER': {
                    field: 'customer.name',
                    callback: (value) => {
                        return value;
                    }
                },
                'JOB': {
                    field: 'job.job_name',
                    callback: (value) => {
                        return value;
                    }
                },
                'AMOUNT': {
                    field: 'overall_total',
                    callback: (value) => {
                        return '$' + parseFloat(value).toFixed(2);
                    }
                },
                'CREATED AT': {
                    field: 'created_at',
                    callback: (value) => {
                        return moment(value).format('MM/DD/YYYY hh:mm a');
                    }
                },
                'STATUS': {
                    field: 'vpo_status.name',
                    callback: (value) => {
                        return value;
                    }
                },
            },
            qbSyncing: false,
            loaderText: '',
            qb_url:process.env.VUE_APP_QUICKBOOKS_URL
        }
    },
    computed: {
        filteredV() {
            return this.filteredAllVpos; // Return the filteredAllVpos array
        },
        filteredAllVpos() {
            if (this.searchText.length == 0 && this.filterByStatusNum == this.allVpoStatus.length) {
                return this.allVpos;

            } else {
                let filteredVpos = this.allVpos;

                // Filter the VPO's by Search Bar text
                if (this.searchText.length > 0) {
                    let str = this.searchText.toLowerCase();
                    filteredVpos = filteredVpos.filter(v => v.job.job_name.toLowerCase().includes(str)
                        || (v.customer.name.toLowerCase()).includes(str)
                        || v.vpo_status.name.toLowerCase().includes(str)
                        || (v.vpo_number.vpo_number.toLowerCase()).includes(str)
                        || moment(v.created_at).format("MM/DD/YYYY").toLowerCase().includes(str)
                        || (parseFloat(v.overall_total).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLowerCase().includes(str)
                        || (parseFloat(v.overall_total).toFixed(2)).toString().toLowerCase().includes(str)
                    );
                }

                // Filter the VPO's by selected statuses
                if (this.filterByStatusNum <= this.allVpoStatus.length && this.filterByStatusNum > 0) {
                    filteredVpos = filteredVpos.filter(v => {
                        let statIdx = this.filterByStatuses.findIndex(st => st.id == v.vpo_status_id);
                        return this.filterByStatuses[statIdx].active;
                    });
                } else {
                    filteredVpos = [];
                }

                return filteredVpos;
            }
        },

        filterByStatusNum() {
            return this.filterByStatuses.reduce((ttl, st) => ttl + st.active, 0);
        },

        isVpoDraft() {
            return this.selectedVpo?.vpo_status?.name == "Draft" ? true : false;
        },
        cancelVpoSubtitle() {
            if (!this.isVpoDraft)
                return this.$t('label.This cannot be undone You will be able to send a message to the Customer on the next step');
            else
                return ' ';
        }
    },
    mounted() {
        bus.$on('reloadVPO', () => {
            this.loadLkupData();
        });

        this.loadLkupData();
    },
    methods: {
        async loadLkupData() {
            let promises = [];
            this.loader = this.$loading.show();
            await this.getAllVpos();
            await this.getAllVpoStatuses();
            /*promises.push(this.getAllVpos());
            promises.push(this.getAllVpoStatuses());*/

            // Hide the loader overlay after all lookup data is loaded
            // Promise.all(promises).then(() => {
            this.allVpoStatus.forEach(st => {
                if (!this.filterByStatuses.find(e => e.id === st.id)) {
                    this.filterByStatuses.push({
                        id: st.id,
                        name: st.name,
                        active: true
                    });
                }
            });

            // Send Vpo from props
            if (this.id && this.modalToOpen == 'send_vpo') {
                let slctIdx = this.allVpos.findIndex(vpo => vpo.id == this.id);
                if (slctIdx >= 0)
                    this.showModalVpoSend(this.allVpos[slctIdx]);
            }

            this.loader.hide();
            //  });
        },

        /* API Getters */
        async getAllVpos() {
            return axios.get(`/vpo_get_all`).then(rsp => {
                this.allVpos = rsp.data.result;
            }).catch(err => console.log(err));
        },
        async getAllVpoStatuses() {
            return axios.get(`/vpo_statuses_get_all`).then(rsp => {
                this.allVpoStatus = rsp.data.result;
            }).catch(err => console.log(err));
        },

        async getSelectedVpoJob(id) {
            return axios.get(`/get-job-by-id/${id}`).then(rsp => {
                this.selectedVpoJob = rsp.data.result;
            }).catch(err => console.log(err));
        },

        async getSelectedVpoInvoice(id) {
            return axios.get(`/get-invoice-by_id/${id}`).then(rsp => {
                this.selectedVpoInvoice = rsp.data.result;
            }).catch(err => console.error(err));
        },

        /* Vpo Approved Confirmation */
        showMarkVpoApprModal() {
            this.viewMarkVpoApprModal = true;
        },
        hideMarkVpoApprModal() {
            this.viewMarkVpoApprModal = false;
        },

        /* Vpo Denied Confirmation */
        showMarkVpoDeniedModal() {
            this.viewMarkVpoDeniedModal = true;
        },
        hideMarkVpoDeniedModal() {
            this.viewMarkVpoDeniedModal = false;
        },

        /* Vpo View Modal */
        showModalVpoView(item = null) {
            this.vpoData = item;
            this.$modal.show('modalVpoView');
        },
        hideModalVpoView() {
            this.$modal.hide('modalVpoView');
        },

        /* Vpo Send Modal */
        showModalVpoSend(item = null) {
            this.vpoData = item;
            this.sendType = "request";
            this.$modal.show('modalVpoSend');
        },
        hideModalVpoSend() {
            this.$modal.hide('modalVpoSend');
        },

        /* Vpos Invoice Create View */
        showVpoInvoiceAddModal(vpo) {
            // Render modal after necessary data has loaded
            this.getSelectedVpoJob(vpo.job_id).then(() => {
                this.viewVpoInvoiceAddModal = true;
                this.$modal.show("VpoInvoiceAddModal");
            });
        },
        hideVpoInvoiceAddModal() {
            this.viewVpoInvoiceAddModal = false;
            this.$modal.hide("VpoInvoiceAddModal");
        },

        /* Vpos Invoice Edit View */
        showVpoInvoiceEditModal() {
            this.viewVpoInvoiceEditModal = true;
            this.$modal.show("VpoInvoiceEditModal");
        },
        hideVpoInvoiceEditModal() {
            this.viewVpoInvoiceEditModal = false;
            this.$modal.hide("VpoInvoiceEditModal");
        },

        /* Vpos Invoice PDF View */
        showVpoInvoicePDFModal(vpo) {
            let promises = [
                this.getSelectedVpoJob(vpo.job_id),
                this.getSelectedVpoInvoice(vpo.invoice_id),
            ];

            Promise.all(promises).then(() => {
                this.viewVpoInvoicePDFModal = true;
                this.$modal.show('vpoInvoiceViewModal');
            });
        },
        hideVpoInvoicePDFModal(value) {
            this.viewVpoInvoicePDFModal = false;
            this.$modal.hide('vpoInvoiceViewModal');

            // If PDF Modal is calling a different modal
            if (value == 'editSelected')
                this.showVpoInvoiceEditModal();
            else if (value == 'invoiceSend')
                this.showVpoInvoiceSendModal();
            else if (value == 'invoicePayment')
                this.showVpoInvoicePaymentModal();
        },

        /* Vpos Invoice Send View */
        showVpoInvoiceSendModal() {
            this.viewVpoInvoiceSendModal = true;
            this.$modal.show('vpoInvoiceSendModal');
        },
        hideVpoInvoiceSendModal() {
            this.viewVpoInvoiceSendModal = false;
            this.$modal.hide('vpoInvoiceSendModal');
        },
        vpoInvcMdlCustChng(value, invId) {
            // Prepare to send the invoice
            if (value == 1) {
                // Update invoice data
                this.getSelectedVpoInvoice(invId).then(() => {
                    this.showVpoInvoiceSendModal();
                });
            }
        },

        /* Vpo Invoice Process Payment Modal */
        showVpoInvoicePaymentModal() {
            this.viewVpoInvoicePaymentModal = true;
            this.$modal.show('vpoInvoicePaymentModal');
        },
        hideVpoInvoicePaymentModal() {
            this.viewVpoInvoicePaymentModal = false;
            this.$modal.hide('vpoInvoicePaymentModal');
        },

        showSendCancelModal() {
            this.sendType = "cancel";
            this.$modal.show('modalVpoSend');
        },

        editExistingJob(jobNumber) {
            this.$router.push({
                path: `jobs/edit/${jobNumber}`
            });
        },
        setSelectedVpo(item) {
            this.selectedVpo = item;
            this.vpoData = this.selectedVpo;
        },
        toggleDeleteModal(status) {
            this.showDltPOModal = status;
        },

        isVpoRemovable(vpo) {
            let stNm = vpo.vpo_status.name;
            if (['Draft', 'Requested'].findIndex((nm) => nm == stNm) >= 0)
                return true;
        },

        updateStatus(statusName) {
            let rqstData = {
                id: this.selectedVpo.id,
                status_name: statusName
            };

            axios.post("/vpo-update-status", rqstData).then(() => {
                this.getAllVpos();
                bus.$emit('banner-success', {
                    message: this.$t('label.VPO status updated successfully') + '!'
                });
            }).catch(err => console.log(err));
        },

        // For drafts
        deleteVpo() {
            let rqstData = {
                id: this.selectedVpo.id,
                status_name: this.selectedVpo.vpo_status.name
            };

            axios.post("/vpo-delete", rqstData).then(() => {
                this.getAllVpos();
                bus.$emit('banner-success', {
                    message: this.$t('label.VPO Draft deleted successfully') + '!'
                });
            }).catch(err => console.log(err));
        },
        // TODO
        async syncVpo2QB() {

            var response;
            this.qbSyncing = true;
            try {

                this.loaderText = "Syncing VPO Items master to Quickbooks Online...";
                response = await axios.get('/quickbooks/sync-items-vpo');

                this.loaderText = "Syncing VPO customers to Quickbooks Online...";
                 response = await axios.post('/quickbooks/sync-customer');
 
                this.loaderText = "Syncing VPO to Quickbooks Online...";
                response = await axios.post('/quickbooks/sync-vpo');

                bus.$emit('banner-success', { message: response.data.message });
                this.loadLkupData();
            } catch (error) {
                console.log('ERROR: ', error);
                bus.$emit('banner-error', { message: error.data.message });
            } finally {
                this.qbSyncing = false;
            }
        },
         showQBOnlineBill() {
            let rqstData = {
                id: this.selectedVpo.id,
                status_name: this.selectedVpo.vpo_status.name
            };

            axios.post("/vpo-delete", rqstData).then(() => {
                this.getAllVpos();
                bus.$emit('banner-success', {
                    message: this.$t('label.VPO Draft deleted successfully') + '!'
                });
            }).catch(err => console.log(err));
        },
    },
}
</script>
