<template>
    <div class="card">
        <div class="card-header">
            <h3 class="d-flex align-items-center mb-0">
                <i class="ri-information-line"></i> {{ $t('label.vpo info') }}
            </h3>
        </div>
        <div class="card-body">
            <div class="row">
                <!-- Customer Name -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="vendor_name">{{ $t('label.customer') }}</label>
                        <h3 class="border-bottom" v-if="vpo.customer">{{ vpo.customer.name }}</h3>
                        <h3 class="border-bottom" v-else>&nbsp;</h3>
                    </div>
                </div>
                <!-- VPO Number -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="order_number">{{ $t('label.vpo') }} #</label>
                        <h3 class="border-bottom d-flex align-items-center justify-content-between" v-if="vpo.vpo_number.vpo_number">
                            <span class="d-flex align-items-center">
                                VPO-{{ vpo.vpo_number.vpo_number }}
                            </span>
                        </h3>
                        <h3 class="border-bottom" v-else>&nbsp;</h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- Job Name -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="job_order">{{ $t('label.job') }}</label>
                        <h3 class="border-bottom" v-if="vpo.job">{{ vpo.job.job_name }}</h3>
                        <h3 class="border-bottom" v-else>&nbsp;</h3>
                    </div>
                </div>
                <!-- Vpo Status -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="status">{{ $t('label.status') }}</label>
                        <h3 class="border-bottom" v-if="vpo.vpo_status">
                            <span class="badge badge-dot">
                                <i class="bg-success" :style="'background-color:' + vpo.vpo_status.color + ' !important'"></i> {{ vpo.vpo_status.name }}
                            </span>
                        </h3>
                        <h3 class="border-bottom" v-else>&nbsp;</h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- Customer Contact -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="customer_name">{{ $t('label.superintendent') }}</label>
                        <h3 class="border-bottom" v-if="vpo.customer_contact">{{ vpo.customer_contact.full_name }}</h3>
                        <h3 class="border-bottom" v-else>&nbsp;</h3>
                    </div>
                </div>
                <!-- Vpo Date -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="purchase_date">{{ $t('label.vpo date') }}</label>
                        <h3 class="border-bottom" v-if="vpo.purchase_date">{{ vpo.purchase_date }}</h3>
                        <h3 class="border-bottom" v-else>&nbsp;</h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- Created By -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="created_by">{{ $t('label.created by') }}</label>
                        <h3 class="border-bottom" v-if="vpo.created_by">
                            <img :src="vpo.created_by.picture" class="rounded-circle" style="width: 36px; height: 36px; object-fit: cover;">
                            <span class="cursor-pointer" @click="getUserInfo(vpo.created_by.id, 'Uploaded By')">{{ vpo.created_by.name }}</span>
                        </h3>
                        <h3 class="border-bottom" v-else>&nbsp;</h3>
                    </div>
                </div>
                <!-- Delivery Date -->
                <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label text-muted" for="delivery_date">{{ $t('label.expected completion date') }}</label>
                        <h3 class="border-bottom" v-if="vpo.delivery_date">{{ vpo.delivery_date }}</h3>
                        <h3 class="border-bottom" v-else>&nbsp;</h3>
                    </div>
                </div>
            </div>
            <!-- Description or Special Instructions -->
            <div class="form-group">
                <label class="form-control-label text-muted" for="delivery_date">{{ $t('label.description or special instructions') }}</label>
                <h3 class="border-bottom" v-if="vpo.description">{{ vpo.description }}</h3>
                <h3 class="border-bottom" v-else>&nbsp;</h3>
            </div>
            <!-- VPO Total -->
            <!-- <div class="row">
                <div class="form-group col-12 col-sm-2">
                    <label class="form-control-label text-muted" for="vpo_total">{{ $t('label.total') }}</label>
                    <h3 class="border-bottom" v-if="vpo.overall_total">&dollar;{{ vpo.overall_total.toFixed(2) }}</h3>
                    <h3 class="border-bottom" v-else>&nbsp;</h3>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    props: ['vpo'],
    data() {
        return {}
    },
    mounted() { },
    methods: {},
}
</script>
