/* eslint-disable no-unused-vars */

const namespaced = true;

const state = {
    job: [],
    // jobChatRooms: []
};

const getters = {
    job: state => state.job,
    // jobChatRooms: state => state.jobChatRooms,
};

const actions = {

    getJobById({ state, commit, rootState, dispatch }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get('/get-job-by-id/' + id)
                .then(res => {
                    commit('setJob', res.data.result);
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    },
    // getAllJobChatRooms({ state, commit, rootState, dispatch }){
    //     return new Promise((resolve, reject) => {
    //         axios
    //             .get('/get-all-chat-rooms/job')
    //             .then(res => {
    //                 commit('setJobChatRooms', res.data.result);
    //                 resolve(res);
    //             })
    //             .catch(err => reject(err));
    //     });
    // },

}

const mutations = {
    setJob(state, job) {
        state.job = job;
    },
    // setJobChatRooms(state, jobChatRooms) {
    //     state.jobChatRooms = jobChatRooms;
    // },
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
};