<template>
	<div class="">
        <div class="calendar-month">
            <!-- Calendar Options Header -->
            <div class="card mlr-15">
                <div class="card-header pt-2 pb-2">
                    <div class="row align-items-center">
                        <!-- Month Title -->
                        <div class="col-6">
                            <h3 class="mb-0">
                                <div class="d-flex align-items-center">
                                    <i class="ri-calendar-line heading-title"></i> {{ $t('label.month') }}
                                </div>
                            </h3>
                        </div>

                        <!-- Filter by Employee -->
                        <div class="col-6 calendar-view ml-auto float-right">
                            <div class="ml-auto float-right pt-2 pb-1 btn-group-light btn-group-sm rounded calendar-filter">
                                <div class="dropdown">
                                    <button type="button" class="d-flex align-items-center btn btn-white text-primary ml-auto" data-toggle="dropdown">
                                        <i class="ri-checkbox-circle-line"></i>
                                        <span v-if="selectedEmp">{{ selectedEmp.name }}</span>
                                        <span v-else>{{ $t('label.view employees') }}: {{ $t('label.all') }}</span>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" @click.stop="">
                                        <a class="dropdown-item text-default pointer d-flex" @click="selectedEmp = null; updateCalendarsTasks()">
                                            <span>{{ $t('label.view employees') }}: {{ $t('label.all') }}</span>
                                            <i v-if="!selectedEmp" class="ri-checkbox-circle-line ml-auto text-success"></i>
                                        </a>
                                        <div class="dropdown-divider"></div>
                                        <a v-for="(d,i) in allUsers" :key="i" class="dropdown-item text-default pointer" @click="selectedEmp = d; updateCalendarsTasks()">
                                            <div class="d-flex align-items-center">
                                                <span class="badge badge-dot">{{ d.name }}</span>
                                                <i v-if="selectedEmp && d.id == selectedEmp.id" class="ri-checkbox-circle-line ml-auto text-success"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Calendar Date Pickers -->
                <div class="card-body d-none d-md-block">
                    <div class="row align-items-center m-0">
                        <!-- Go to Today -->
                        <div class="col-auto p-0">
                            <button @click="today(); updateCalendarsTasks()" type="button" class="btn btn-default text-white text-uppercase px-3 py-1">{{ $t('label.today') }}</button>
                        </div>
                        <!-- Shift by Mne Month -->
                        <div class="col-auto p-0 pl-3">
                            <i class="ri-arrow-left-s-line heading-title pointer" @click="prevMonth(); updateCalendarsTasks()"></i>
                            <i class="ri-arrow-right-s-line heading-title pointer"  @click="nextMonth(); updateCalendarsTasks()"></i>
                        </div>
                        <!-- Month Date Picker -->
                        <span class="pl-3">
                            <date-picker v-model="currDate" 
                                :clearable="false"
                                type="month"
                                class="full-width"
                                format="MM/DD/YYYY"
                                :placeholder="$t('label.select date')"
                                @change="setMonth(); updateCalendarsTasks()">
                                    <template v-slot:input>
                                        <span class="pointer d-flex align-items-center">
                                            <i class="ri-calendar-2-line heading-title"></i>
                                            {{ textDate }}
                                            <i class="ri-arrow-down-s-line heading-title"></i>
                                        </span>
                                    </template>
                                    <template v-slot:icon-calendar><span></span></template>
                            </date-picker>
                        </span>
                    </div>
                </div>
            </div>

            <!-- Full Calendar Block -->
            <div class="card pb-2 calendar-month-view">
                <FullCalendar id="calendar" :options="calendarOptions2" ref="monthCalendar">
                    <template v-slot:eventContent="arg">
                        <div v-for="(taskType, idx) in arg.event.extendedProps.tasks" :key="idx" class="checklist-item checklist-item-success my-1" @click="showDayTasks(arg.event.startStr, taskType.name)">
                            <template v-if="taskType.tasks_count > 0">
                                <div class="checklist-info">
                                    <div class="col-12 pl-2">
                                        <h4 class="checklist-title mb-0">{{ taskType.name }} ({{ taskType.tasks_count }})</h4>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                </FullCalendar>
            </div>
        </div>
        
        <!-- Task Type Modal -->
        <modal class="py-4"
            name="viewTaskTypeModal"
            height="auto"
            width="100%"
            :classes="'rounded'"
            :max-width="900"
            :scrollable="true" 
            :reset="true" 
            :adaptive="true"
            @closed="$modal.hide('viewTaskTypeModal')">
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header border-bottom d-flex align-items-center">
                        <h5 class="modal-title" id="viewTaskTypeModalTitle">{{ viewTaskTypeModalDate }}</h5>
                        <button type="button" class="close" @click="$modal.hide('viewTaskTypeModal')">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                    <!-- Modal Body -->
                    <div class="modal-body p-0">
                        <!-- Table -->
                        <table class="table table-sm table-flush text-center m-0">
                            <!-- Table Header -->
                            <thead class="thead-light">
                                <tr style="width: 100%">
                                    <th scope="col" class="text-left pl-5" style="width: 25%">{{ $t('label.task name') }}</th>
                                    <th scope="col" class="text-left" style="width: 15%">{{ $t('label.dates') }}</th>
                                    <th scope="col" class="text-left" style="width: 15%">{{ $t('label.job') }}</th>
                                    <th scope="col" class="text-left" style="width: 15%">{{ $t('label.assigned to') }}</th>
                                    <th scope="col" class="text-left pr-5" style="width: 15%">{{ $t('label.status') }}</th>
                                    <th scope="col" class="text-left" style="width: 15%">{{ $t('label.time clocked') }}</th>
                                </tr>
                            </thead>

                            <!-- Table Body -->
                            <tbody>
                                <tr v-for="(data,index) in allTasksByType" :key="index">
                                    <td colspan="6" class="p-0">
                                        <div class="accordion-1" v-if="data.tasks.length">
                                            <div class="row">
                                                <div class="col-md-12 ml-auto">
                                                    <div class="accordion" aria-expanded="true" id="accordionExample">
                                                        <div class="card m-0">
                                                            <div class="card-header rounded-0" id="saf" @click="toggleDayTypeDropdown(data.name)">
                                                                <h5 class="mb-0">
                                                                    <button class="btn btn-link text-primary d-flex align-items-center mr-auto" type="button">
                                                                            <i class="row-arrow-sz" :class="data.show_dropdown ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'"></i>
                                                                            {{ data.name }} ({{ data.tasks.length }})
                                                                    </button>
                                                                </h5>
                                                            </div>
                                                            <div :id="'collapse-' + data.tasks[0].task_type_id" v-if="data.show_dropdown">
                                                                <div class="card-body opacity-8 p-0">
                                                                    <table style="width: 100%">
                                                                        <tr class="border-bottom" v-for="(value, idx) in data.tasks" :key="idx">
                                                                            <td class="text-left pl-5 py-4 task-title" style="width: 25%">
                                                                                <div class="text-bold pointer search-task" @click="showTaskOffCanvas(value)">{{ value.name }}</div>
                                                                            </td>
                                                                            <td class="text-left" style="width: 15%">
                                                                                {{ value.date_range_text }}
                                                                            </td>
                                                                            <td class="text-left" style="width: 15%">
                                                                                {{ value.job.job_name }}
                                                                            </td>
                                                                            <td class="text-left" style="width: 15%">
                                                                                <template v-if="0 < value.assigned_to.length">
                                                                                    <span v-for="(d,i) in value.assigned_to" :key="i" class="avatar avatar-sm rounded-circle crew-member-avatar" v-show="!d.is_foreman">
                                                                                        <img v-if="d.user.picture" @click="getUserInfo(d.user.id, 'Crew Members')" class="cursor-pointer rounded-circle" :src="d.user.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                                                                        <img v-else @click="getUserInfo(d.user.id, 'Crew Members')" class="cursor-pointer rounded-circle" src="#" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                                                                    </span>
                                                                                </template>
                                                                            </td>
                                                                            <td class="text-left pr-5" style="width: 15%">
                                                                                {{ value.task_status.name }}
                                                                            </td>
                                                                            <td class="text-left pr-5" style="width: 15%">
                                                                                {{ value.total_task_time }}
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <!-- Modal Footer -->
                    <div class="modal-footer border-top">
                        <button type="button" class="btn btn-primary mr-auto" @click="$modal.hide('viewTaskTypeModal')">{{ $t('label.close') }}</button>
                    </div>
                </div>
        </modal>
    </div>
</template>

<script>
	// import { bus } from '../app';
    import moment from 'moment-timezone';
	import FullCalendar from '@fullcalendar/vue';
	import dayGridPlugin from '@fullcalendar/daygrid';
	import bus from '@/utils/event-bus';

    export default {
		props: ['firstname','user'],
		components: {
			FullCalendar
		},
		data() {
			return {
                monthEndDate: moment().endOf('month').format('YYYY-MM-DD'),
                monthStartDate: moment().startOf('month').format('YYYY-MM-DD'),
				currDate: moment().format('MM/DD/YYYY'),
				textDate: moment().format('MMMM YYYY'),
                viewTaskTypeModalDate: null,
                selectedEmp: null,
                allTasksByType: [],
				alltaskstatus: [],
                allUsers: [],
				calendarOptions2: {
                    eventSources: [],
					plugins: [
						dayGridPlugin,
					],
					headerToolbar: {
						left: 'title',
						right: 'prev, next'
					},
					views: {
						dayGrid: {
							dayHeaderFormat: {
								weekday: this.isMobile() ? 'narrow' : 'long'
							}
						},
					},
					timeZone: this.user.timezone,
					themeSystem: 'bootstrap',
                    initialView: 'dayGridMonth',
                    contentHeight: 'auto',
					editable: true,
					selectable: true,
					selectMirror: true,
					fixedWeekCount: false,
				},
			}
        },
		created() {
            bus.$on('sidebarClockout', this.getDayTasks);
            bus.$on('taskOffCanvasUpdate', this.updateCalendarsTasks);

			moment.tz.setDefault(this.user.timezone);
            this.getUsers();
            this.getTaskStatuses();

            // Register Calendar event/prop data
            this.calendarOptions2.eventSources.push(this.getCalendarTasks());
        },
        mounted() {
			this.calendarOptions2.timeZone = this.user.timezone;
		},
        destroyed() {
            // Remove Event Listeners
            bus.$off('sidebarClockout', this.getDayTasks);
            bus.$off('taskOffCanvasUpdate', this.updateCalendarsTasks);
        },
		methods: {
            getCalendarTasks() {
                let loader = this.$loading.show(),
                    monthStDt = this.monthStartDate,
                    monthEndDt = this.monthEndDate,
                    selectedEmpId = this.selectedEmp ? this.selectedEmp.id : null;

                // Return an events object for the FullCalendar component
                return {
                    events(start, callback) {
                        var request_fields = {
                            'start_date': monthStDt,
                            'end_date': monthEndDt,
                            'emp_id': selectedEmpId
                        };
    
                        axios.post('get-tasks-for-month-cal', request_fields).then(rsp => {
                            loader.hide();
                            callback(rsp.data.result);
                        });
                    }
                }
            },
            getTaskStatuses() {
                axios.get('taskstatus').then(response => {
                    this.alltaskstatus = response.data.result;
                }).catch(err => console.error(err));
            },
            getUsers() {
                axios.get('get-all-users').then(response => {
                    this.allUsers = response.data.result;
                }).catch(err => console.error(err));
            },
            getDayTasks(date) {
                var loader = this.$loading.show(),
                    rqst_fields = {
                        'date': date,
                        'emp_id': this.selectedEmp ? this.selectedEmp.id : null
                    };

                return axios.post(`get-task-for-month-day`, rqst_fields).then(rsp => {
                    loader.hide();
                    this.allTasksByType = rsp.data.result;
                    this.allTasksByType.forEach(tp => this.$set(tp, 'show_dropdown', false));
                }).catch(err => console.log(err));
            },

            updateCalendarsTasks() {
                // Update the calendar with the new date ranges tasks
                this.$refs.monthCalendar.getApi().gotoDate(moment(this.currDate).format('YYYY-MM-DD'));
                this.calendarOptions2.eventSources = [this.getCalendarTasks()];
            },

            showDayTasks(date, initialTypeName) {
                // Initialize the Day with Tasks Modal
                this.viewTaskTypeModalDate = moment(date).format("MMMM Do YYYY");

                this.getDayTasks(date).then(() => {
                    this.toggleDayTypeDropdown(initialTypeName);
                    this.$modal.show('viewTaskTypeModal');
                });
            },

            toggleDayTypeDropdown(typeName) {
                // Toggle the Day Task Type dropdown to show/hide tasks
                let idx = this.allTasksByType.findIndex(tp => tp.name == typeName);
                this.allTasksByType[idx].show_dropdown = !this.allTasksByType[idx].show_dropdown;
            },

            setMonth() {
                this.textDate = moment(this.currDate).format('MMMM YYYY');
                this.monthEndDate = moment(this.currDate).endOf('month').format('YYYY-MM-DD');
                this.monthStartDate = moment(this.currDate).startOf('month').format('YYYY-MM-DD');
            },
            prevMonth() {
                this.monthEndDate = moment(this.monthEndDate).subtract(1, 'months');
                this.monthStartDate = moment(this.monthStartDate).subtract(1, 'months');
                this.updateCurrentDate()
            },
            nextMonth() {
                this.monthEndDate = moment(this.monthEndDate).add(1, 'months');
                this.monthStartDate = moment(this.monthStartDate).add(1, 'months');
                this.updateCurrentDate()
            },
            today() {
                this.monthEndDate = moment().endOf('month').format('YYYY-MM-DD');
                this.monthStartDate = moment().startOf('month').format('YYYY-MM-DD');
                this.updateCurrentDate()
            },
            updateCurrentDate() {
                // Update currDate for calendar view
                this.currDate = this.monthStartDate;
                this.textDate = moment(this.currDate).format('MMMM YYYY');
            },

            showTaskOffCanvas(task) {
                this.$modal.hide('viewTaskTypeModal');
                bus.$emit('openTaskOffCanvas', task.id)
            },

            isMobile() {
                return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
            },
		}
    }
</script>

<style>
	button.fc-prev-button.fc-button.fc-button-primary {
		border-radius: 0.25em !important;
	}
	button.fc--button.fc-button.fc-button-primary {
		display: none;
	}
	.fc-toolbar-chunk:nth-child(3) {
		position: absolute;
		right: 160px;
		top: 15px;
		opacity: 0;
	}
	#monthComponent .fc-h-event {
		background-color: transparent;
		border: none;
		padding: 5px 10px;
	}
    .row-arrow-sz {
		font-size: 17px;
	}
	.fc-h-event h4,
	.fc-h-event small {
		color: #32325d
	}
	.fc-day-today {
		background-color: #F7F8FD !important;
	}
	#monthComponent .fc-day-today > .fc-daygrid-day-frame {
		border-top: 8px solid #FF7F40 !important;
	}
	#monthComponent .fc-day-past > .fc-daygrid-day-frame,
	#monthComponent .fc-day-future > .fc-daygrid-day-frame {
		padding-top: 8px !important;
	}
	#monthComponent th.fc-day > .fc-scrollgrid-sync-inner {
		padding-top: 8px !important;
	}
	#monthComponent .mx-icon-calendar,
	#monthComponent .mx-icon-clear {
		display: none !important;
	}
	.fc-header-toolbar {
		display: none !important;
	}
</style>
