<template>
	<div class="po-pdf">
		<div class="modal-content">
			<div class="modal-header border-bottom">
				<h5 class="modal-title">
					{{ $t('label.vpo') }} - #{{ vpo.vpo_number.vpo_number }}
					<span v-if="vpo.vpo_status" class="badge badge-lg ml-3" :style="'background-color:' + vpo.vpo_status.color + ' !important'">
						{{ vpo.vpo_status.name }}
					</span>
				</h5>
				<button type="button" class="close" @click="$emit('cancelFnc')">
					<i class="ri-close-line"></i>
				</button>
			</div>
			<div class="modal-body">
				<div class="row m-0">
					<!-- PDF View -->
					<div class="col-12 pdf-preview">
						<template v-if="currentPage == 1">
							<div class="">
								<iframe id="vpoViewPdfFrame" src="" width="100%" height="850px"></iframe>
							</div>
						</template>
					</div>
				</div>
			</div>
			<div class="modal-footer border-top">
				<button type="button" class="btn btn-link ml-auto" @click="$emit('cancelFnc')">{{ $t('label.close') }}</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['vpo'],
	emits: ['cancelFnc', 'reload'],
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
			companySettings: JSON.parse(localStorage.getItem('company_settings')),
			loader: null,
			currentPage: 1,
			showDltVPOModal: false,
			subjectText: '',
			sendType: ''
		}
	},
	mounted() {
		this.loader = this.$loading.show();
		this.getPdf().then(() => {
			this.loader.hide()
		});
	},
	methods: {
		getPdf() {
			let url = `/get-vpo-pdf-file/${this.vpo.id}`;
			return axios.get(url, { responseType: 'blob' }).then(rsp => {
				const dataUrl = URL.createObjectURL(rsp.data);
				const iFrame = document.querySelector("#vpoViewPdfFrame");
				iFrame.src = dataUrl; 
			}).catch((err) => console.log("err", err));
		},
	}
}
</script>

<style lang="scss" scoped>
.po-pdf {
	.pdf-preview {
		border: 1px solid #8898aa;
		border-radius: 10px;
		padding: 1.5rem;
		height: auto;
		min-height: 842px;
	}
}
</style>
	
