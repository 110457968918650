<template>
	<div class="po-pdf">
		<div class="modal-content">
			<div class="modal-header border-bottom">
				<h5 class="modal-title">
					{{ $t('label.purchase order') }} - #{{ purchaseOrder.order_number }}
					<span v-if="purchaseOrder.purchase_order_status" class="badge badge-lg ml-3" :style="'background-color:' + purchaseOrder.purchase_order_status.color + ' !important'">
						{{ purchaseOrder.purchase_order_status.name }}
					</span>
				</h5>
				<button type="button" class="close" @click="$emit('cancelFnc')">
					<i class="ri-close-line"></i>
				</button>
			</div>
			<div class="modal-body">
				<div class="row m-0">
					<div class="col-8 pdf-preview">
						<!-- PAGE 1 -->
						<template v-if="currentPage == 1">
							<div class="row m-0">
								<div class="col-6 p-0">
									<div><img class="mb-3" :src="companySettings.logo" style="height: 75px; object-fit: cover;" /></div>
									<template>
										<h3 class="m-0">{{ $t('label.vendor') }}:</h3>
										<p>{{ purchaseOrder.vendor.vendor_name }}<br>
											{{ selectedVendorContact.address_1 }} <br>
											{{ selectedVendorContact.address_2 }} <br v-if="selectedVendorContact.address_2">
											{{ selectedVendorContact.city }}, {{ selectedVendorContact.state }} {{ selectedVendorContact.postal_code }}</p>
									</template>
								</div>
								<div class="col-6 p-0 text-right">
									<h1 class="m-0">{{ $t('label.purchase order') }}</h1>
									<h3>#{{ purchaseOrder.order_number }}</h3>
									<h3 class="m-0 mt-4">{{ $t('label.date') }}:</h3>
									<p>{{ purchaseOrder.purchase_date }}</p>
									<h3 class="m-0">{{ $t('label.terms') }}:</h3>
									<p>{{ purchaseOrder.terms }}</p>
								</div>
							</div>
							<div class="row m-0">
								<template v-for="(data, index) in purchaseOrder.purchase_order_address">
									<div :key="index" class="col-6 p-0">
										<h3 class="m-0">{{ data.address_type == 'billing' ? $t('label.bill to') : $t('label.ship to') }}:</h3>
										<div class="display_address col-auto p-0">
											<div>
												<p>{{ addressFilter(data).address_1 }} <br>
													{{ addressFilter(data).address_2 }} <br v-if="addressFilter(data).address_2">
													{{ addressFilter(data).city }}, {{ addressFilter(data).state }} {{ addressFilter(data).postal_code }}</p>
											</div>
										</div>
									</div>
								</template>
							</div>
							<div class="row m-0 pt-2 pb-4">
								<table class="table table-flush table-sm">
									<thead class="thead-light">
										<tr>
											<th scope="col">{{ $t('label.qty') }}</th>
											<th scope="col">{{ $t('label.item') }}</th>
											<th scope="col" style="width: 5rem">{{ $t('label.sku') }}</th>
											<th scope="col" class="text-right" style="width: 5rem">{{ $t('label.unit cost') }}</th>
											<th scope="col" class="text-right" style="width: 5rem">{{ $t('label.amount') }}</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(data, index) in purchaseOrder.purchase_order_items" :key="index">
											<td>{{ data.quantity }}</td>
											<td>{{ data.description }}</td>
											<td>{{ data.sku }}</td>
											<td class="text-right">${{ Number.parseFloat(data.unit_cost).toFixed(2) }}</td>
											<td class="text-right">${{ Number.parseFloat(data.total).toFixed(2) }}</td>
										</tr>
									</tbody>
									<tbody class="border-top">
										<tr>
											<td colspan="2" class="align-top pt-2">&nbsp;</td>
											<td class="align-top pt-2">
												<h3 class="m-0">{{ $t('label.subtotal') }}</h3>
												<h3 class="m-0">{{ $t('label.taxes') }}</h3>
											</td>
											<td colspan="2" class="align-top pt-2 text-right">
												<h3 class="m-0">${{ purchaseOrderItemSubTotal | formatThousands }}</h3>
												<h3 class="m-0">${{ purchaseOrderItemTaxes | formatThousands }}</h3>
											</td>
										</tr>
										<tr>
											<td colspan="2" class="border-0"></td>
											<td class="align-top pt-2 border-top">
												<h3 class="m-0">{{ $t('label.total') }}</h3>
											</td>
											<td colspan="2" class="align-top pt-2 border-top text-right">
												<h3 class="m-0">${{ purchaseOrderItemTotal | formatThousands }}</h3>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div v-if="purchaseOrder.purchase_order_comments" class="row m-0 pb-4">
								<div class="col-12 px-0">
									<!-- Comments -->
									<h3 class="m-0">{{ $t('label.description or special instructions') }}:</h3>
									<p class="lh-140">{{ purchaseOrder.purchase_order_comments.comments }}</p>
								</div>
							</div>
							<div class="row m-0 page-footer">
								<div class="col-6">{{ companySettings.website }}</div>
								<div class="col-6 text-right">Page 1 of 1</div>
							</div>
						</template>
					</div>
					<div class="col-4 p-0">
						<div class="row m-0">
							<div v-if="purchaseOrder.purchase_order_status.name == 'Draft' || purchaseOrder.purchase_order_status.name == 'Sent'" class="col-12 mb-3">
								<button type="button" class="btn btn-outline-primary w-100 text-uppercase d-flex align-items-center justify-content-center" @click="showModalPurchaseOrderSend(purchaseOrder)">
									<i class="ri-mail-send-line"></i> {{ $t('label.send') }}
								</button>
							</div>
							<!-- Download PDF -->
							<div class="col-12 mb-3">
								<a @click="$downloadStream(`/get-po-pdf-download/${purchaseOrder.id}`, pdfName)" class="btn btn-outline-primary text-primary w-100 text-uppercase d-flex align-items-center justify-content-center">
									<i class="ri-download-line"></i> {{ $t('label.download') }}
								</a>
							</div>
							<!-- Print PDF -->
							<div class="col-12 mb-3">
								<a @click="$openStreamBlank(`/get-po-pdf-file/${purchaseOrder.id}`), pdfName" class="btn btn-outline-primary text-primary w-100 text-uppercase d-flex align-items-center justify-content-center">
									<i class="ri-printer-line"></i> {{ $t('label.print') }}
								</a>
							</div>
							<!-- Edit PDF -->
							<div class="col-12 mb-3" v-if="purchaseOrder.purchase_order_status.name == 'Draft'">
								<router-link class="btn btn-outline-primary w-100 text-uppercase d-flex align-items-center justify-content-center" :to="{ name: 'purchaseOrderEdit', params: { id: purchaseOrder.id } }">
									<i class="ri-eye-line"></i> {{ $t('label.edit') }}
								</router-link>
							</div>
							<div class="col-12 mb-3">
								<!-- Delete Draft -->
								<div v-if="purchaseOrder.purchase_order_status.name == 'Draft'">
									<a class="btn btn-outline-danger w-100 text-uppercase d-flex align-items-center justify-content-center text-danger" @click="toggleDeleteModal(true)">
									<!-- <a class="btn btn-outline-danger w-100 text-uppercase d-flex align-items-center justify-content-center text-danger" @click="toggleDeleteModal(true, data.purchase_order_status.name)"> -->
										<i class="ri-close-circle-line"></i>{{ $t('label.delete purchase order') }}
									</a>
								</div>
								<!-- Cancel Sent PO -->
								<div v-if="purchaseOrder.purchase_order_status.name == 'Sent'">
									<a class="btn btn-outline-danger w-100 text-uppercase d-flex align-items-center justify-content-center text-danger" @click="toggleSendCancelModal()">
										<i class="ri-close-circle-line"></i>{{ $t('label.cancel purchase order') }}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer border-top">
				<button type="button" class="btn btn-link ml-auto" @click="$emit('cancelFnc')">{{ $t('label.close') }}</button>
			</div>
		</div>

		<!-- Purchase Order Send Modal -->
		<modal class="py-4" name="modalPurchaseOrderSend_modalview" height="auto" width="100%" :max-width="900" :scrollable="true" :reset="true" :adaptive="true">
			<ModalPurchaseOrderSend :purchase-order="purchaseOrder" :send-type="poSendType" :subject-text="subjectText" @cancelFnc="hideModalPurchaseOrderSend" @updateStatus="updateStatus"></ModalPurchaseOrderSend>
		</modal>

		<!-- Purchase Order Cancel -->
		<modal-delete-component v-if="showDltPOModal" :newId="'PurchaseOrder_modalview'" :title="isPoDraft ? $t('label.ARE YOU SURE YOU WANT TO DELETE THIS PURCHASE ORDER') : $t('label.ARE YOU SURE YOU WANT TO CANCEL THIS PURCHASE ORDER')" :subtitle="cancelPoSubtitle" :btnText="isPoDraft ? $t('label.Yes, Delete Purchase Order') : $t('label.Yes, Cancel Purchase Order')" @onClose="toggleDeleteModal()" @customDeleteMethod="isPoDraft ? deletePo() : sendCancelEmail()">
		</modal-delete-component>
	</div>
</template>

<script>
import ModalDeleteComponent from '@/components/ModalDelete2.vue';
import ModalPurchaseOrderSend from '@/components/ModalPurchaseOrderSend.vue';
import bus from '@/utils/event-bus';

export default {
	props: ['purchaseOrder', 'selectedVendorContact'],
	emits: ['cancelFnc', 'reload'],
	components: {
		ModalPurchaseOrderSend,
		ModalDeleteComponent
	},
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
			companySettings: JSON.parse(localStorage.getItem('company_settings')),
			selectedPurchaseOrder: {},
			currentPage: 1,
			showDltPOModal: false,
			subjectText: '',
			poSendType: ''
		}
	},
	computed: {
		pdfName() {
            let name = this.purchaseOrder.purchase_order_status == 'Cancelled' ? 'CANCELLED_' : '';
            return name + "SO-" + this.purchaseOrder.purchase_order_number.purchase_order_number + "_" +  moment().format('YYYY_MM_DD') + ".pdf";
        },
		purchaseOrderItemSubTotal() {
			let subtotal = 0;
			this.purchaseOrder.purchase_order_items.forEach((data, index) => {
				subtotal += data.subtotal;
			})
			return Number.parseFloat(subtotal).toFixed(2);
		},
		purchaseOrderItemTaxes() {
			let taxes = 0;
			this.purchaseOrder.purchase_order_items.forEach((data, index) => {
				taxes += (data.total - data.subtotal);
			})
			return Number.parseFloat(taxes).toFixed(2);
		},
		purchaseOrderItemTotal() {
			let total = 0;
			this.purchaseOrder.purchase_order_items.forEach((data, index) => {
				total += data.total;
			})
			return Number.parseFloat(total).toFixed(2);
		},
		isPoDraft() {
            return this.purchaseOrder.purchase_order_status.name == "Draft" ? true : false;
        },
        cancelPoSubtitle() {
            if (!this.isPoDraft)
                return this.$t('label.This cannot be undone You will be able to send a message to the supplier on the next step');
            else
                return ' ';
        }
	},
	mounted() {
		this.poSendType = 'send'
	},
	methods: {
		addressFilter(data) {
			let tempAddress = {};

			if (data.type == 'company') {
				tempAddress = data.company_address;
			} else if (data.type == 'customer') {
				tempAddress = data.customer_address;
			} else if (data.type == 'job') {
				tempAddress = data.job_address;
			} else if (data.type == 'store_location') {
				tempAddress = this.selectedVendorContact;
			} else {
				tempAddress = data;
			}

			return tempAddress;
		},
		changePage(page) {
			this.currentPage = page;
		},
		showModalPurchaseOrderSend(item = null) {
			this.subjectText = '';
			this.$modal.show('modalPurchaseOrderSend_modalview');
		},
		setSelectedPurchaseOrder(item) {
			this.selectedPurchaseOrder = item;
		},
		toggleDeleteModal(status) {
			this.showDltPOModal = status;
		},
		hideModalPurchaseOrderSend() {
            this.$modal.hide('modalPurchaseOrderSend_modalview');
			// this.$emit('cancelFnc');
        },
		toggleSendCancelModal() {
            this.poSendType = 'cancel'
            this.subjectText = 'CANCELLED';
            this.$modal.show('modalPurchaseOrderSend_modalview');
        },
		sendCancelEmail() {
			this.subjectText = 'CANCELLED';
			this.$modal.show('modalPurchaseOrderSend_modalview');
		},
		updateStatus() {
            // this.$emit('reload');
            this.$modal.hide('modalPurchaseOrderSend_modalview');
			this.$emit('cancelFnc');
        },
		deletePo() {
            let reqData = {
                id: this.purchaseOrder.id,
                status: this.purchaseOrder.purchase_order_status.name,
            };

            axios.post('/delete-purchase-order', reqData).then(res => {
                bus.$emit('banner-success', {
                    message: this.$t('label.Purchase Order Draft deleted successfully') + '!'
                });
				this.$emit('cancelFnc');
            });
        },
	}
}
</script>
<style lang="scss">
.po-pdf {
	.pdf-preview {
		border: 1px solid #8898aa;
		border-radius: 10px;
		padding: 1.5rem;
		height: auto;
		min-height: 842px;
	}

	.page-footer {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 20px 10px;
	}
}
</style>
	
