export default {
    messages(state) {
        return state.messages;
    },
    user(state) {
        return state.user;
    },
    qbstatus(state) {
        return state.isSyncToQb;
    },
    alljobnotes(state) {
        return state.job.notes;
    },
    alljobfiles(state) {
        return state.job.files;
    },
    users(state) {
        return state.users;
    },
    company(state) {
        return state.company;
    },
    coordinates(state) {
        return state.coordinates;
    },
    states(state) {
        return state.states;
    },
    countries(state) {
        return state.countries;
    }
}
