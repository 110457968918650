<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50" id="jobTypesContainer">
            <!-- HEADER -->
            <div class="d-flex" style="z-index: 100">
                <!-- Breadcrumbs -->
                <breadcrumbs :title="$t('label.job types')">
                    <!-- Breakcrumb slots -->
                    <li class="breadcrumb-item">
                        {{ $t('label.admin') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.content management') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.job types') }}
                    </li>
                </breadcrumbs>
            </div>

            <!-- CONTENT -->
            <div class="card">
                <div class="card-header">
                    <div class="row m-0 align-items-center justify-content-between">
                        <h3 class="mb-0  d-flex align-items-center">{{ $t('label.job types') }}</h3>
                        <button class="btn btn-sm btn-default m-0" @click="toggleAddModal(true)">
                            {{ $t('label.add job types') }}
                        </button>
                    </div>
                </div>
                <div class="card-body p-0 m-0">
                    <!-- Job Types List -->
                    <div class="table-responsive">
                        <table class="table table-flush border-bottom">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">{{ $t('label.name') }}</th>
                                    <th scope="col">{{ $t('label.creation date') }}</th>
                                    <th scope="col">{{ $t('label.job type status') }}</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(type, idx) in paginatedJobTypes" :key="idx">
                                    <td>{{ type.name }}</td>
                                    <td>{{ moment(type.created_at).format('MM/DD/YYYY') }}</td>
                                    <td>{{ type.status ? 'Active' : 'Inactive' }}</td>
                                    <td class="text-right">
                                        <div class="dropdown">
                                            <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fas fa-ellipsis-v"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                <button type="button" class="dropdown-item text-default" style="cursor: pointer" @click="setSelectedJobType(type); toggleEditModal(true)">
                                                    <i class="ri-pencil-line"></i> {{ $t('label.edit job type') }}
                                                </button>
                                                <button v-if="!type.is_active" type="button" class="dropdown-item text-danger" style="cursor: pointer" @click="setSelectedJobType(type); toggleDeleteModal(true)">
                                                    <i class="ri-delete-bin-line"></i> {{ $t('label.delete job type') }}
                                                </button>
                                                <button v-else type="button" class="dropdown-item text-danger" style="cursor: pointer; opacity: 0.6" @click="bannerError($t('label.This Job Type cannot be deleted because it is used on at least one Job') + '.' + trans('label.You can inactivate the Job Type to prevent future use') + '.')" :title="$t('label.This Job Type cannot be deleted because it is used on at least one Job') + '.' + $t('label.You can inactivate the Job Type to prevent future use') + '.'">
                                                    <i class="ri-delete-bin-line"></i> {{ $t('label.delete job type') }}
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table-pagination v-model="paginatedJobTypes" :all-rows="jobTypes" :perPage="perPage">
                        </table-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- Add New Job Type Modal -->
        <job-type-add-modal v-if="showAddTypeModal" @onClose="toggleAddModal()" @onSave="saveFromAddModal()">
        </job-type-add-modal>

        <!-- Edit Job Type Modal-->
        <job-type-edit-modal v-if="showEditTypeModal" :selected-item="selectedJobType" @onClose="toggleEditModal()" @onSave="saveFromEditModal()">
        </job-type-edit-modal>

        <!-- Delete Job Type Modal -->
        <modal-delete-component v-if="showDltTypeModal" :newId="'JobType'" :title="$t('label.ARE YOU SURE YOU WANT TO DELETE THIS JOB TYPE')" @onClose="toggleDeleteModal()" @customDeleteMethod="deleteJobType()">
        </modal-delete-component>

    </div>
</template>

<script>
import JobTypeAddModal from '@/components/Admin/JobTypes/JobTypeAddModal.vue';
import JobTypeEditModal from '@/components/Admin/JobTypes/JobTypeEditModal.vue';
import ModalDeleteComponent from '@/components/ModalDelete2.vue';
import TablePagination from '@/components/TablePagination';
import bus from '@/utils/event-bus';

export default {
    props: [],
    components: {
        JobTypeAddModal,
        JobTypeEditModal,
        TablePagination,
        ModalDeleteComponent
    },
    data() {
        return {
            jobTypes: [],
            paginatedJobTypes: [],
            selectedJobType: {},
            loader: null,
            perPage: 20,
            showAddTypeModal: false,
            showEditTypeModal: false,
            showDltTypeModal: false
        }
    },
    mounted() {
        this.loader = this.$loading.show();
        let promises = [
            axios.get("/all-job-types")
        ];

        Promise.all(promises).then((response) => {
            this.jobTypes = response[0].data.result;
            this.loader.hide();
        });
    },
    methods: {
        getJobTypes() {
            axios.get("/all-job-types").then(response => {
                this.jobTypes = response.data.result;
            }).catch(err => console.log(err));
        },

        // Modal Methods
        setSelectedJobType(item) {
            this.selectedJobType = item;
        },
        toggleAddModal(status) {
            this.showAddTypeModal = status;
        },
        toggleEditModal(status) {
            this.showEditTypeModal = status;
        },
        toggleDeleteModal(status) {
            this.showDltTypeModal = status;
        },
        saveFromAddModal(modalType) {
            this.getJobTypes();
            this.toggleAddModal(false);
        },
        saveFromEditModal() {
            this.getJobTypes();
            this.toggleEditModal(false);
        },
        deleteJobType() {
            if (this.selectedJobType.id) {
                axios.post("/delete-job-type/" + this.selectedJobType.id)
                    .then(response => {
                        this.getJobTypes();
                        bus.$emit('banner-success', {
                            message: $t('label.Job Type successfully deleted')
                        });
                    })
                    .catch(err => console.log(err));
            }
        },
        bannerError(msg) {
            bus.$emit('banner-error', { message: msg });
        },
    }
}
</script>
