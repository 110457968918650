import { store } from '@/store/store';
import router from './registerRouter.plugin';

// load the modules
async function loadModules() {
  const moduleContext = await require.context('@/modules', true, /\.module.js$/i);
  const baseModules = moduleContext
    .keys()
    .map(moduleContext)
    .map((m) => m.default);

  if (baseModules.length < 1) return;

  return await Promise.all(
    baseModules.map(async (module) => {
      let allowed = true;
      if (module.preRegisterCheck) {
        allowed = await module.preRegisterCheck();
      }

      if (allowed) {
        // register routes of module
        if (module.routes) {
          if (Array.isArray(module.routes)) {
            module.routes.forEach((route) => {
              router.addRoute(route);
            })
          } else {
            router.addRoute(module.routes);
          }
        }

        if (module.store && !Array.isArray(module.store)) {
          const moduleStore = await import(`@/modules/${module.store}`).then((m) => m.default);
          moduleStore && (await store.registerModule(module.name, moduleStore, { preserveState: !!store.state[module.name] }));
        }
      }
    })
  );
}

export async function registerModules() {
  await loadModules();
  // console.log(router.getRoutes());
}
