<template>
    <div>
        <div class="card mb-0" v-if="selectedDay">
            <div class="card-header">
                <div class="row d-flex align-items-center">
                    <div class="col">
                        <span v-if="userData.picture">
                            <img class="pointer rounded-circle my-avatar-md" :src="userData.picture" alt="">
                        </span>
                        Tasks for Date: {{ selectedDay.workDate | formatDateNoTz }}
                    </div>
                    <div class="col text-muted text-sm text-center" v-if="currentUser.role_id < 3">
                        Pay Period is "{{ (this.isPayPeriodClosed) ? 'Closed' : 'Open' }}"
                    </div>
                    <div class="col text-right">
                        <!-- <i class="ri-close-line text-xl pointer" @click="$emit('close')"></i> -->
                        <i class="ri-close-line text-xl pointer" @click="closeit"></i>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <!-- W2 user view -->
                <ul class="list-group" v-if="selectedDay.userPayType != '1099'">
                    <li class="list-group-item bg-gray-600 text-white">
                        <div class="row">
                            <div class="col-4">Task</div>
                            <div class="col-3">Crew</div>
                            <div class="col-2">Time</div>
                            <div class="col-3 text-right">Earnings</div>
                        </div>
                    </li>
                    <li class="list-group-item" v-for="(item,idx) in payrollItems" :key="idx">
                        <div class="row">
                            <div class="col-4">{{ item.task.name }}</div>
                            <div class="col-3">
                                <span v-for="crew in item.task.assigned_to" :key="crew.id" class="" data-toggle="tooltip" data-placement="top" :title="crew.user.name">
                                    <img class="pointer rounded-circle my-avatar-sm" :src="crew.user.picture" alt="">
                                </span>
                            </div>
                            <!-- Time -->
                            <div class="col-2">
                                <!-- Editable only if admin -->
                                <span v-if="currentUser.role_id < 3 && !isPayPeriodClosed" class="pointer text-primary" @click="reviseTime(item)">
                                    {{ item.duration | formatSeconds2Time }}
                                    <i class="ri-pencil-line"></i>
                                </span>
                                <!-- Standard view only -->
                                <span v-else>
                                    {{ item.duration | formatSeconds2Time }}
                                </span>
                            </div>
                            <div class="col-3 text-right">
                                <!-- Piece Rate User -->
                                <div v-if="item.piece_rate_amount">
                                    <!-- Editable only if admin -->
                                    <span v-if="currentUser.role_id < 3 && !isPayPeriodClosed" class="pointer text-primary" @click="revisePieceRate(item)">
                                        {{ item.piece_rate_amount | toCurrency }}
                                        <i class="ri-pencil-line"></i>
                                    </span>
                                    <!-- Standard View only -->
                                    <span v-else>
                                        {{ item.piece_rate_amount | toCurrency }}
                                    </span>
                                    <div class="text-muted text-sm">
                                        {{ item.piece_rate_percentage }}%
                                        Piece Rate
                                    </div>
                                </div>
                                <!-- Hourly User -->
                                <div v-else>
                                    {{ item.hourly_wage_amount | toCurrency }}
                                    <div>
                                        <!-- Edit hourly rate if missing -->
                                        <span v-if="!item.hourly_wage_rate && !isPayPeriodClosed && currentUser.role_id < 3">
                                            <span class="text-primary pointer text-sm" @click="toggleHourlyRateChange(item)">
                                                Hourly Rate missing
                                                <i class="ri-pencil-line"></i>
                                            </span>
                                        </span>
                                        <!-- standard hourly rate display -->
                                        <span v-else class="text-muted text-sm">
                                            Hourly Rate {{ item.hourly_wage_rate | toCurrency }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Add hourly rate -->
                        <div v-if="item.editHourlyRate">
                            <label class="text-sm">Enter users hourly rate</label>
                            <div class="input-group">
                                <div class="input-group-prepend" @click="saveHourlyRate(item)">
                                    <span class="input-group-text">$</span>
                                </div>

                                <input type="number" min="0.00" step=".1" class="form-control" v-model="item.newHourlyRate">

                                <div class="input-group-append pointer" @click="saveHourlyRate(item)">
                                    <span class="input-group-text text-success">
                                        <i class="ri-save-line"></i>
                                    </span>
                                </div>
                                <div class="input-group-append pointer" @click="toggleHourlyRateChange(item)">
                                    <span class="input-group-text text-danger">
                                        <i class="ri-close-circle-line"></i>
                                    </span>
                                </div>
                            </div>
                            <label class="text-sm">Enter the Reason for Time Revision:</label>
                            <div class="input-group">
                                <textarea v-model="$v.hourlyRateMissingNote.$model" name="notes" class="form-control" :placeholder="$t('label.notes')"></textarea>
                                <div v-if="$v.hourlyRateMissingNote.$error && !$v.hourlyRateMissingNote.required" class="text-danger text-sm">*{{ $t('label.field required') }}</div>
                            </div>
                        </div>
                        <div class="list-group-item bg-gray-600 text-white" @click="toggleLogsVisibility(item)">
                        <!-- <div class="list-group-item bg-gray-600 text-white" @click="toggleLogsVisibility"> -->
                            <div class="row cursor-pointer2">
                                <!-- <div class="col-9">View Earnings Revision Logs</div> -->
                                <i class="fas" :class="item.showLogs ? 'fa-chevron-up' : 'fa-chevron-down'"></i>               
                                <div class="mb-0 ml-2 d-inline">View Time Revision Logs</div>
                            </div>
                        </div>
                        <div v-if="item.showLogs">
                            <div class="row timeline-block" v-for="(log,index) in item.taskActivityLogs" :key="index">
                                <div>
                                <span class="timeline-step badge-activity-log">
                                    <i class="ri-pencil-line p-0"></i>
                                </span>
                                <div class="timeline-content" style="width:auto;">
                                    <span class="d-block">
                                        Change Made By: <span class="link cursor-pointer" @click="getUserInfo(log.created_by.id, 'Uploaded By')">{{ log.created_by.name }}</span>
                                    </span>
                                    <span class="date">{{ log.created_at | formatDateWithTime }}</span>
                                    <!-- <div class="details mt-3" v-if="log.old_time_in || log.old_time_out"> -->
                                    <div class="details mt-3" v-if="log.log_type == 'Updating Time'">
                                        <span>Time changed: {{moment(log.old_time_in).format('MM/DD/YYYY hh:mm a')}} - {{moment(log.old_time_out).format('MM/DD/YYYY hh:mm a')}} </span>
                                        <span> -> {{moment(log.new_time_in).format('MM/DD/YYYY hh:mm a')}} - {{moment(log.new_time_out).format('MM/DD/YYYY hh:mm a')}}</span>
                                    </div>
                                    <!-- For the new time entries -->
                                    <!-- <div class="details mt-3" v-if="!log.old_time_in && !log.old_time_out && log.new_time_in"> -->
                                    <div class="details mt-3" v-if="log.log_type =='Adding Time'">
                                        <span>Time Added: {{moment(log.new_time_in).format('MM/DD/YYYY hh:mm a')}} - {{moment(log.new_time_out).format('MM/DD/YYYY hh:mm a')}}</span>
                                    </div>
                                    <div class="details mt-3" v-if="log.log_type =='deleting TimePunch'">
                                        <span>Deleted: {{moment(log.old_time_in).format('MM/DD/YYYY hh:mm a')}} - {{moment(log.old_time_out).format('MM/DD/YYYY hh:mm a')}}</span>
                                    </div>
                                    <div>
                                        <span>Reason: </span>
                                        <span class="date">{{log.notes}}</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <!-- 1099 View -->
                <ul class="list-group" v-else>
                    <li class="list-group-item bg-gray-600 text-white">
                        <div class="row">
                            <div class="col-4">Task</div>
                            <div class="col-3">Crew</div>
                            <div class="col-3 text-right">Earnings</div>
                            <div class="col-2 text-right">
                                Approval Status
                                <!-- toggle Approve all -->
                                <!-- <div class="pointer text-info text-sm" @click="toggleApproveItems()">
                                    <i class="ri-checkbox-line"></i>
                                    Approve All
                                </div> -->
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item" v-for="item in payrollItems" :key="item.id">
                        <div class="row">
                            <div class="col-4">{{ item.task.name }}</div>
                            <div class="col-3">
                                <span v-for="crew in item.task.assigned_to" :key="crew.id" class="" data-toggle="tooltip" data-placement="top" :title="crew.user.name">
                                    <img class="pointer rounded-circle my-avatar-sm" :src="crew.user.picture" alt="">
                                </span>
                            </div>
                            <div class="col-3 text-right">
                                <!-- Piece Rate User -->
                                <!-- <div v-if="item.piece_rate_percentage"> -->
                                    <!-- Editable only if admin -->
                                    <span v-if="currentUser.role_id < 3 && !isPayPeriodClosed" class="pointer text-primary" @click="revisePieceRate(item)">
                                        {{ item.piece_rate_amount | toCurrency }}
                                        <i class="ri-pencil-line"></i>
                                    </span>
                                    <!-- Standard View only -->
                                    <span v-else>
                                        {{ item.piece_rate_amount | toCurrency }}
                                    </span>
                                    <div class="text-muted text-sm">
                                        {{ item.piece_rate_percentage }}%
                                        Piece Rate
                                    </div>
                                <!-- </div> -->
                                <!-- No piece rate added to task -->
                                <!-- <div v-else> -->
                                    <!-- To pay contractor for this task you must add piece rate to the task.  -->
                                    <!-- Go to Task -->
                                <!-- </div> -->
                            </div>
                            <div class="col-2 text-right">
                                <button type="button" class="btn btn-sm" :class="{ 'btn-success': item.approved_date, 'btn-danger': !item.approved_date }" @click="toggleApproveItems(item)">
                                    <i class="ri-check-line"></i>
                                </button>
                            </div>
                        </div>
                        <div class="list-group-item bg-gray-600 text-white" @click="toggleLogsVisibility(item)">
                            <div class="row cursor-pointer2">
                                <!-- <div class="col-9">View Earnings Revision Logs</div> -->
                                <i class="fas fa-chevron-down p-1"></i>
                                <div class="mb-0 ml-2 d-inline">View Earnings Revision Logs</div>
                            </div>
                        </div>
                        <div v-if="item.showLogs">
                            <div class="row timeline-block" v-for="log in item.taskActivityLogs" :key="log.id">
                                <span class="timeline-step badge-activity-log">
                                    <i class="ri-pencil-line p-0"></i>
                                </span>
                                <div class="timeline-content">
                                    <span class="d-block">
                                        Change Made By: <span class="link cursor-pointer" @click="getUserInfo(log.created_by.id, 'Uploaded By')">{{ log.created_by.name }}</span>
                                    </span>
                                    <span class="date">{{ log.created_at | formatDateWithTime }}</span>
                                    <div class="details mt-3" v-if="log.new_amount">
                                        <span>Amount Changed: ${{log.old_amount}} </span>
                                        <span> -> ${{log.new_amount}}</span>
                                    </div>
                                    <div>
                                        <span>Reason: </span>
                                        <span class="date">{{log.notes}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <div class="text-right pt-2">
                    <button type="button" class="btn btn-primary" @click="$emit('dataReturned')">Close</button>
                    <!-- <button type="button" class="btn btn-primary" @click="$emit('close')">Close</button> -->
                </div>
            </div>
        </div>
        <modal name="reviseUserTimeModal" height="auto" width="98%" :reset="true" :scrollable="true" :adaptive="true">
            <revise-time-modal :data-selected="selectedItem" @cancelFnc="$modal.hide('reviseUserTimeModal')"></revise-time-modal>
        </modal>
        <modal name="reviseTaskPieceRateModal" height="auto" width="98%" :reset="true" :scrollable="true" :adaptive="true">
            <payroll-adjust-piece-rate-allocations :task="task" :paytype="selectedDay.userPayType" :selectedDay="currSelectedDay" @cancelFnc1099="cancelFnc1099"></payroll-adjust-piece-rate-allocations>
            <!-- <payroll-adjust-piece-rate-allocations :task="task" :paytype="selectedDay.userPayType" @cancelFnc="$modal.hide('reviseUserTimeModal')"></payroll-adjust-piece-rate-allocations> -->
        </modal>
    </div>
</template>

<script>
import PayrollAdjustPieceRateAllocations from '@/components/Reports/PayrollAdjustPieceRateAllocations'
import ReviseTimeModal from '@/components/TaskOffCanvas/modals/ReviseTimeModal'
import bus from '@/utils/event-bus'
import { required } from 'vuelidate/lib/validators';

export default {
    props: ['selectedDay'],
    components: { ReviseTimeModal, PayrollAdjustPieceRateAllocations },
    emits: [],
    data() {
        return {
            currentUser: JSON.parse(localStorage.getItem('user')),
            selectedItem: null,
            payrollItems: [],
            userData: {},
            task: {},
            isPayPeriodClosed: 0,
            currSelectedDay: null,
            hourlyRateMissingNote: null,
            taskActivityLogs: [],
        }
    },
    validations: {
        hourlyRateMissingNote: { required }
    },
    created() {
        bus.$on('reviseTimeModalUpdate', this.loadDaysTasks)
        bus.$on('payrollAdjustPieceRateAllocations_Update', this.loadDaysTasks)
        this.currSelectedDay = this.selectedDay;
    },
    beforeDestroy() {
        bus.$off('reviseTimeModalUpdate', this.loadDaysTasks)
        bus.$off('payrollAdjustPieceRateAllocations_Update', this.loadDaysTasks)
    },
    mounted() {
        this.loadDaysTasks();
    },
    methods: {
        cancelFnc1099(thing) {
            this.$modal.hide('reviseUserTimeModal');
            this.$modal.hide('reviseTaskPieceRateModal');
            this.loadDaysTasks();
        },
        closeit() {
            this.$emit('dataReturned'); // Emit the 'dataReturned' event
        },
        loadDaysTasks() {
            let promises = []
            //Load pay period record to see if its open or closed
            promises.push(axios.post('/getPayPeriodByDate', { selectedDate: this.selectedDay.workDate }))

            //get selected date tasks
            promises.push(axios.post('/loadUserDailyTaskPayroll', { userId: this.selectedDay.userId, workDate: this.selectedDay.workDate }))

            Promise.all(promises).then((responses) => {
                this.isPayPeriodClosed = responses[0].data.is_closed;
                this.payrollItems = responses[1].data.payRollItems;
                this.getTaskActivityLogsForPayrollItems();
                this.payrollItems.forEach(element => {
                    element.editHourlyRate = false
                    this.userData = responses[1].data.userData
                });
            })
                .catch(err => {
                    console.log('ERROR: ', err)
                    bus.$emit('banner-error', { message: err, })
                })
        },
        getTaskActivityLogsForPayrollItems() {
            this.payrollItems.forEach((item) => {
            this.getTaskActivityLogs(item);
            });
        },
        getTaskActivityLogs(item) {
            if(this.selectedDay.userPayType == '1099') {
                item.log_type = 'Updated 1099 Earnings';
            } else {
                item.log_type = 'Updating Time';
            }
            return axios.post('/get-task-activity-logs', item)
            .then((response) => {
                item.taskActivityLogs = response.data.result;

            })
            .catch((err) => console.log(err))
            .finally(() => {
                // Check if all logs are fetched
                const allLogsFetched = this.payrollItems.every(
                (item) => item.taskActivityLogs
                );
                if (allLogsFetched) {
                // All logs are fetched, update the view
                this.$forceUpdate();
                }
            }); 
        },
        toggleLogsVisibility(item) {
            item.showLogs = !item.showLogs;
        },
        reviseTime(task) {
            this.selectedItem = {
                user: { id: task.user_id },
                task_id: task.task_id,
                workDate: task.work_date
            }
            this.$modal.show('reviseUserTimeModal')
        },
        revisePieceRate(task) {
            this.task = task.task
            this.$modal.show('reviseTaskPieceRateModal')
        },
        toggleHourlyRateChange(item) {
            item.editHourlyRate = !item.editHourlyRate
            let index = this.payrollItems.findIndex(item => {
                if (item.id === item.id) return true
            })
            this.$set(this.payrollItems, index, item)
        },
        saveHourlyRate(item) {
            this.$v.$touch();

			if (!this.$v.$invalid) {
                if (!item.newHourlyRate) {
                    return
                }
                if (parseFloat(item.newHourlyRate) < 1) {
                    return
                }

                axios
                    .post('/updatePayrollHourlyRate', { recordId: item.id, hourlyRate: item.newHourlyRate, note: this.hourlyRateMissingNote, userId: item.user_id, taskId: item.task_id})
                    .then(response => {

                        //This is where I need to add the edit log. So when a hourly rate is missing and added,
                        //add an edit log. log_type='hourly rate',
                        
                        bus.$emit('reviseTimeModalUpdate');
                        bus.$emit('banner-success', {
                            message: 'Hourly rate updated!',
                        })
                    })
                    .catch(err => {
                        console.log('ERROR: ', err.data)
                        bus.$emit('banner-error', { message: err.data })
                    })
            }
        },
        toggleApproveItems(item) {
            let items = this.payrollItems
            if (item) {
                items = [item]
            }

            let postItems = []
            items.forEach(item => {
                postItems.push({
                    id: item.id,
                    approved_user_id: (item.approved_user_id) ? null : this.currentUser.id,
                    approved_date: (item.approved_date) ? null : moment().format('YYYY-MM-DD HH:mm:ss'),
                })
            });

            axios
                .post('/toggleApprovePayrollItems', { items: postItems })
                .then(response => {
                    bus.$emit('reviseTimeModalUpdate');
                    bus.$emit('banner-success', {
                        message: 'Item(s) approval status updated!',
                    })
                })
                .catch(err => {
                    console.log('ERROR: ', err)
                    bus.$emit('banner-error', { message: err, })
                })
        },
    }
}
</script>

<style scoped>
.my-avatar-sm {
    width: 25px;
    height: 25px;
    object-fit: cover;
}

.my-avatar-md {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.timeline::before {
    height: calc(100% - 20px);
    border-right: 2px solid #CED4DA;
}

.timeline-content {
    max-width: 100%;
    margin-left: 50px;
}

.timeline-content span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #525F7F;
}

.timeline-content .link {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #005486;
}

.timeline-content .date {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #8898AA;
}

.timeline-content .details {
    background: #F6F9FC;
    border: 1px solid #DEE2E6;
    border-radius: 4px;
    padding: 12px 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #525F7F;
}

</style>