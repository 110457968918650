<template>
    <div>

        <!-- Big screen sidebar -->
        <nav class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white d-md-block" id="sidenav-main">
            <perfect-scrollbar class="h-100">
                <div class="scrollbar-inner scroll-scrollx_visible">
                    <div class="sidenav-header d-flex align-items-center">
                        <router-link to="/vue/jobs">
                            <a class="navbar-brand">
                                <img src="/argon/img/brand/SubPro_Logo_Knockout_White.svg" class="navbar-brand-img" alt="...">
                            </a>
                        </router-link>
                        <div class="ml-auto">
                            <!-- Sidenav toggler -->
                            <div class="sidenav-toggler d-none" data-action="sidenav-pin" data-target="#sidenav-main">
                                <div class="sidenav-toggler-inner">
                                    <i class="sidenav-toggler-line"></i>
                                    <i class="sidenav-toggler-line"></i>
                                    <i class="sidenav-toggler-line"></i>
                                </div>
                            </div>
                        </div>

                        <!-- Language Selector -->
                        <div class="dropdown">
                            <a class="btn btn-secondary" data-toggle="dropdown" id="navbarDropdownMenuLink">
                                <img class="lang-img" :src="'/images/flags/' + language + '.png'">
                                <i class="fa fa-caret-down"></i>
                            </a>
                            <ul class="dropdown-menu lang-toggle" aria-labelledby="navbarDropdownMenuLink">
                                <li>
                                    <a class="dropdown-item pointer" @click="setLang('en')">
                                        <img class="lang-img" src="/images/flags/en.png" />
                                        English
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item pointer" @click="setLang('es')">
                                        <img class="lang-img" src="/images/flags/es.png" />
                                        Spanish
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item pointer" @click="setLang('pt')">
                                        <img class="lang-img" src="/images/flags/pt.png" />
                                        Portuguese
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- Menu Items -->
                    <div class="navbar-inner" style="padding-bottom:200px">
                        <!-- Collapse -->
                        <div class="collapse navbar-collapse" id="sidenav-collapse-main">
                            <!-- Nav items -->
                            <ul class="navbar-nav">
                                <!-- Super-Admin has Access only -->
                                <span v-if="user.role_id == 1">
                                    <li class="nav-item">
                                        <router-link :to="{ name: 'companyManage' }">
                                            <a class="nav-link">
                                                <i class="ri-draft-line"></i>
                                                <span class="nav-link-text">{{ $t('label.manage companies') }}</span>
                                            </a>
                                        </router-link>
                                    </li>
                                </span>

                                <!-- Company Admin has access only-->
                                <span v-if="user.role_id == 2">
                                    <li class="nav-item">
                                        <a class="nav-link" href="#" data-toggle="modal" data-target="#gettingStartedModal">
                                            <i class="">&#128075;</i>
                                            <span class="nav-link-text">Getting Started</span>
                                        </a>
                                    </li>
                                </span>

                                <!-- Everyone has access except Super-Admin -->
                                <span v-if="user.role_id != 1">
                                    <!-- Dashboard -->
                                    <li class="nav-item" :class="{ 'active': $route.name == 'dashboard' }">
                                        <router-link :to="{ name: 'dashboard' }">
                                            <a class="nav-link">
                                                <i class="ri-dashboard-line"></i>
                                                <span class="nav-link-text">{{ $t('label.dashboard') }}</span>
                                            </a>
                                        </router-link>
                                    </li>
                                    <!-- Calendar -->
                                    <li class="nav-item" :class="{ 'active': $route.name == 'calendar' }">
                                        <router-link :to="{ name: 'calendar' }">
                                            <a class="nav-link">
                                                <i class="ri-calendar-line"></i>
                                                <span class="nav-link-text">{{ $t('label.calendar') }}</span>
                                            </a>
                                        </router-link>
                                    </li>

                                    <!-- Time Sheet -->
                                    <li class="nav-item" :class="{ 'active': $route.name == 'timesheet' }">
                                        <router-link :to="{ name: 'timesheet' }">
                                            <a class="nav-link">
                                                <i class="ri-time-line"></i>
                                                <span class="nav-link-text">{{ $t('label.timesheet') }}</span>
                                            </a>
                                        </router-link>
                                    </li>
                                </span>

                                <!-- Company Admin Menu -->
                                <span v-if="user.role_id == 2">
                                    <li class="nav-item">
                                        <br>
                                    </li>
                                    <!-- Jobs -->
                                    <li class="nav-item" :class="{ 'active': $route.name == 'jobs' }">
                                        <router-link :to="{ name: 'jobs' }">
                                            <a class="nav-link">
                                                <i class="ri-stack-line"></i>
                                                <span class="nav-link-text">{{ $t('label.jobs') }}</span>
                                            </a>
                                        </router-link>
                                    </li>
                                    <!-- Conatacts  -->
                                    <li class="nav-item" :class="{ 'active': $route.name == 'contacts' }">
                                        <router-link :to="{ name: 'contacts' }">
                                            <a class="nav-link">
                                                <i class="ri-contacts-book-2-line"></i>
                                                {{ $t('label.contacts') }}
                                            </a>
                                        </router-link>
                                    </li>

                                    <!-- Asset Management -->
                                    <li class="nav-item" :class="{ 'active': $route.name == 'assets' }">
                                        <router-link :to="{ name: 'assets' }">
                                            <a class="nav-link">
                                                <i class="ri-hammer-line"></i>
                                                {{ $t('label.assets') }}
                                            </a>
                                        </router-link>
                                    </li>

                                    <!-- Purchase Orders -->
                                    <li class="nav-item" :class="{ 'active': $route.name == 'purchaseOrders' }">
                                        <router-link :to="{ name: 'purchaseOrders' }">
                                            <a class="nav-link">
                                                <i class="ri-article-line"></i>
                                                {{ $t('label.purchase orders') }}
                                            </a>
                                        </router-link>
                                    </li>

                                    <!-- Variable Purchase Orders -->
                                    <li class="nav-item" :class="{ 'active': $route.name == 'variablePurchaseOrders' }">
                                        <router-link :to="{ name: 'variablePurchaseOrders' }">
                                            <a class="nav-link">
                                                <i class="ri-newspaper-line"></i>
                                                {{ $t('label.vpos') }}
                                            </a>
                                        </router-link>
                                    </li>

                                    <!-- Reports -->
                                    <li class="nav-item" :class="{ 'active': $route.path.indexOf('reports') >= 0 }">
                                        <router-link :to="{ name: 'reports' }">
                                            <a class="nav-link">
                                                <i class="ri-bar-chart-box-line"></i>
                                                <span class="nav-link-text">{{ $t('label.reports') }}</span>
                                            </a>
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <br>
                                    </li>

                                    <!-- Admin -->
                                    <li class="nav-item">
                                        <div>
                                            <span class="nav-link pointer" @click="adminMenuOpen = !adminMenuOpen">
                                                <i class="ri-admin-line"></i>
                                                <div class="nav-link-text">{{ $t('label.admin') }}</div>
                                                <span class="w-100"></span>
                                                <i class="ri-arrow-drop-right-line" v-if="!adminMenuOpen"></i>
                                                <i class="ri-arrow-drop-down-line" v-if="adminMenuOpen"></i>
                                            </span>
                                        </div>
                                        <div class="mb-200p" id="admin-list" v-show="adminMenuOpen">
                                            <ul class="nav nav-sm flex-column">
                                                <!-- Messages -->
                                                <li class="nav-item" :class="{ 'active': $route.name == 'messages' }">
                                                    <router-link :to="{ name: 'messages' }">
                                                        <a class="nav-link">
                                                            <i class="ri-message-2-line"></i>
                                                            {{ $t('label.messages') }}
                                                        </a>
                                                    </router-link>
                                                </li>
                                                <!-- Job Types -->
                                                <li class="nav-item" :class="{ 'active': $route.name == 'jobtypes' }">
                                                    <router-link :to="{ name: 'jobtypes' }">
                                                        <a class="nav-link">
                                                            <i class="ri-draft-line"></i>
                                                            {{ $t('label.job types') }}
                                                        </a>
                                                    </router-link>
                                                </li>

                                                <!-- Job Statuses -->
                                                <li class="nav-item" :class="{ 'active': $route.name == 'jobstatuses' }">
                                                    <router-link :to="{ name: 'jobstatuses' }">
                                                        <a class="nav-link">
                                                            <i class="ri-draft-line"></i>
                                                            {{ $t('label.job statuses') }}
                                                        </a>
                                                    </router-link>
                                                </li>

                                                <!-- Job Templates -->
                                                <li class="nav-item" :class="{ 'active': $route.name == 'jobTemplates' }">
                                                    <router-link to="/vue/admin/contentManagement/jobTemplates">
                                                        <a class="nav-link">
                                                            <i class="ri-draft-line"></i>
                                                            {{ $t('label.job templates') }}
                                                        </a>
                                                    </router-link>
                                                </li>

                                                <!-- Task Types -->
                                                <li class="nav-item" :class="{ 'active': $route.name == 'tasktypes' }">
                                                    <router-link :to="{ name: 'tasktypes' }">
                                                        <a class="nav-link">
                                                            <i class="ri-draft-line"></i>
                                                            {{ $t('label.task types') }}
                                                        </a>
                                                    </router-link>
                                                </li>

                                                <!-- Task Statuses -->
                                                <li class="nav-item" :class="{ 'active': $route.name == 'taskstatuses' }">
                                                    <router-link :to="{ name: 'taskstatuses' }">
                                                        <a class="nav-link">
                                                            <i class="ri-draft-line"></i>
                                                            {{ $t('label.task statuses') }}
                                                        </a>
                                                    </router-link>
                                                </li>
                                                <!-- Suppliers -->
                                                <li class="nav-item" :class="{ 'active': $route.name == 'suppliers' }">
                                                    <router-link :to="{ name: 'suppliers' }">
                                                        <a class="nav-link">
                                                            <i class="ri-draft-line"></i>
                                                            {{ $t('label.suppliers') }}
                                                        </a>
                                                    </router-link>
                                                </li>
                                                <!-- Users -->
                                                <li class="nav-item" :class="{ 'active': $route.name == 'users' }">
                                                    <router-link :to="{ name: 'users' }">
                                                        <a class="nav-link">
                                                            <i class="ri-team-line"></i>
                                                            <span class="nav-link-text">{{ $t('label.users') }}</span>
                                                        </a>
                                                    </router-link>
                                                </li>
                                                <!-- Crews -->
                                                <li class="nav-item" :class="{ 'active': $route.name == 'crews' }">
                                                    <router-link :to="{ name: 'crews' }">
                                                        <a class="nav-link">
                                                            <i class="ri-group-2-line"></i>
                                                            <span class="nav-link-text">{{ $t('label.crews') }}</span>
                                                        </a>
                                                    </router-link>
                                                </li>
                                                <!-- Billing needs routing -->
                                                <li class="nav-item" :class="{ 'active': $route.name == 'billing' }">
                                                    <a class="nav-link" href="/billing" target="_blank">
                                                        <i class="ri-draft-line"></i> {{ $t('label.billing') }}
                                                    </a>
                                                </li>
                                                <!-- Custom Fields -->
                                                <li class="nav-item" :class="{ 'active': $route.name == 'customfields' }">
                                                    <router-link :to="{ name: 'customfields' }">
                                                        <a class="nav-link">
                                                            <i class="ri-draft-line"></i>
                                                            {{ $t('label.custom fields') }}
                                                        </a>
                                                    </router-link>
                                                </li>
                                                <!-- Settings -->
                                                <li class="nav-item" :class="{ 'active': $route.name == 'settings' }">
                                                    <router-link :to="{ name: 'settings' }">
                                                        <a class="nav-link">
                                                            <i class="ri-settings-3-line"></i>
                                                            {{ $t('label.settings') }}
                                                        </a>
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </span>

                            </ul>
                        </div>
                    </div>

                    <!-- Clock in message -->
                    <div class="pl-3 pr-3 timer-loading" v-if="(user.role_id == 2) || (user.role_id > 2 && company.settings.allow_clockin)">
                        <div class="card mb-0 h-100">
                            <div class="card-header pt-3 pl-2 pr-2 border-0 bg-lightgray d-flex justify-content-center align-items-center h-100">
                            </div>
                        </div>
                    </div>
                    <!-- User Clock in -->
                    <user-clockin :user="user" class="d-none d-lg-block" @clockIn="viewClockInModal = true;" @clockOut="viewClockOutModal = true;"></user-clockin>
                </div>
            </perfect-scrollbar>
        </nav>

        <!-- Needs to be in parent z-index stacking context -->
        <clock-in-modal v-if="viewClockInModal" @closeFnc="viewClockInModal = false;"></clock-in-modal>
        <clock-out-modal v-if="viewClockOutModal" @closeFnc="viewClockOutModal = false;"></clock-out-modal>
    </div>
</template>

<script>
import ClockInModal from '@/components/TimeManagement/ClockInModal.vue';
import ClockOutModal from '@/components/TimeManagement/ClockOutModal.vue';

export default {
    props: [],
    components: {
        ClockInModal,
        ClockOutModal
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            company: JSON.parse(localStorage.getItem('company_settings')),
            language: null,
            adminMenuOpen: false,
            viewClockInModal: false,
            viewClockOutModal: false
        }
    },
    mounted() {
        this.isAdminMenuOpen()
        // set the selected language based on users saved settings
        this.language = (this.user.language) ? this.user.language : 'en'
    },
    methods: {
        setLang(lang) {
            // Update the users perfered language
            this.language = lang
            axios.post('/update-language', { language: lang }).then(response => {
                location.reload();
            }).catch(err => {
                console.log('ERROR: ', err)
            })
        },
        isAdminMenuOpen() {
            let routeArr = [
                'messages',
                'jobtypes',
                'jobstatuses',
                'tasktypes',
                'taskstatuses',
                "suppliers",
                'users',
                'crews',
                'customfields',
                'settings',
            ]
            if (routeArr.indexOf(this.$route.name) >= 0) {
                this.adminMenuOpen = true
            }
            else
                this.adminMenuOpen = false
        },
    }
}
</script>