<template>
    <div>
        <div class="modal-content">
            <div class="modal-header border-bottom">
                <h5 class="modal-title"><span id="modalTitle">{{ $t('label.customer information') }}</span></h5>
                <button type="button" class="close" aria-label="Close" @click="$emit('closeFnc')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body p-0">
                <div class="border-bottom p-4">
                    <div class="row align-items-center m-0">
                        <div>
                            <div class="round-square"><i class="ri-group-line"></i></div>
                        </div>
                        <div class="">
                            <div class="ml-3">
                                <h2 class="font-weight-normal m-0">{{ customerInfo.name }}</h2>
                            </div>
                        </div>
                    </div>
                    <!-- View Customer Btn -->
                    <button type="button" class="btn btn-default mr-auto mt-2" @click="redirectToCustomer()">{{ $t('label.view customer') }}</button>
                </div>

                <!-- Contact Name -->
                <div class="row align-items-center border-bottom m-0 p-1 pl-3">
                    <div class="col-4">
                        <h4 class="text-muted font-weight-normal m-0">{{ $t('label.contact name') }}</h4>
                    </div>
                    <div class="">
                        <h4 class="font-weight-normal m-0">{{ customerInfo.first_name }} {{ customerInfo.last_name }}</h4>
                    </div>
                </div>

                <!-- Phone 1 -->
                <div class="row align-items-center border-bottom m-0 p-1 pl-3">
                    <div class="col-4">
                        <h4 class="text-muted font-weight-normal m-0">{{ $t('label.phone') }} 1</h4>
                    </div>
                    <div class="">
                        <h4 class="font-weight-normal m-0">{{ customerInfo.phone_1 }}</h4>
                    </div>
                </div>
                <!-- Phone 2 -->
                <div class="row align-items-center border-bottom m-0 p-1 pl-3">
                    <div class="col-4">
                        <h4 class="text-muted font-weight-normal m-0">{{ $t('label.phone') }} 2</h4>
                    </div>
                    <div class="">
                        <h4 class="font-weight-normal m-0">{{ customerInfo.phone_2 }}</h4>
                    </div>
                </div>
                <!-- email -->
                <div class="row align-items-center m-0 p-1 pl-3">
                    <div class="col-4">
                        <h4 class="text-muted font-weight-normal m-0">{{ $t('label.email') }}</h4>
                    </div>
                    <div class="">
                        <h4 class="font-weight-normal m-0"><a :href="'mailto:' + customerInfo.email">{{ customerInfo.email }}</a></h4>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-top">
                <button type="button" class="btn btn-primary mr-auto" @click="$emit('closeFnc')">{{ $t('label.close') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';
export default {
    props: ['customerId'],
    components: {},
    emits: ['closeFnc'],
    data() {
        return {
            customerInfo: {}
        }
    },
    mounted() {
        this.loadCustomerData()
    },
    methods: {
        loadCustomerData() {
            axios.post('/get-user-info', { id: this.customerId, name: 'Customer' })
                .then(response => {
                    this.customerInfo = response.data.data
                })
                .catch(err => {
                    console.log('ERROR: ', err)
                    bus.$emit('banner-error', { message: err, })
                })
        },
        redirectToCustomer() {
            this.$router.push({ name: 'customer', params: { customerInfo: this.customerInfo, customerId: this.customerInfo.id } })
            this.$emit('closeFnc')
        },
    }
}
</script>