<template>
    <div class="">
        <div class="modal-content">
            <div class="modal-header border-bottom">
                <h5 v-if="task.material_source == 1" class="modal-title" id="addJobMaterialsModalTitle">
                    {{ source == 'edit' ? $t('label.edit job material') : $t('label.add job material') }}
                </h5>
                <h5 v-else class="modal-title" id="addJobMaterialsModalTitle">
                    {{ source == 'edit' ? $t('label.edit task material') : $t('label.add task material') }}
                </h5>
                <button type="button" class="close" @click="$emit('cancelFnc')">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <form method="post" autocomplete="off" enctype="multipart/form-data" @submit.prevent="saveJobMaterial">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.material') }}</label>
                                <vue-autosuggest ref="autosuggest" v-model="query" :suggestions="filteredSuggestions" :inputProps="inputProps" :getSuggestionValue="s => s.item.name" :limit="5">
                                    <template slot-scope="{ suggestion }">
                                        <div>{{ suggestion.item.name }}</div>
                                    </template>
                                </vue-autosuggest>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.unit') }}</label>
                                <input v-model="form.unit" type="text" name="unit" class="form-control" :placeholder="$t('label.unit')" required autofocus autocomplete="input-field" data-lpignore="true">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.quantity') }}</label>
                                <input v-model="form.quantity" type="number" name="quantity" min="0" @blur="checkMaterialQty()" class="form-control" :placeholder="$t('label.quantity')" required autofocus autocomplete="input-field" data-lpignore="true">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.location') }}</label>
                                <input v-model="form.location" type="text" name="location" class="form-control" :placeholder="$t('label.location')" autofocus autocomplete="input-field" data-lpignore="true">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-control-label text-muted" for="input-name">{{ $t('label.notes') }}</label>
                                <textarea v-model="form.notes" name="notes" rows="5" class="form-control" :placeholder="$t('label.notes')" autofocus></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-top">
                    <button v-if="task.material_source == 1" type="submit" class="btn btn-primary mr-auto">{{ source == 'edit' ? $t('label.update job material') : $t('label.add job material') }}</button>
                    <button v-else type="submit" class="btn btn-primary mr-auto">{{ source == 'edit' ? $t('label.update task material') : $t('label.add task material') }}</button>
                    <button type="button" class="btn btn-link  ml-auto close2add" @click="$emit('cancelFnc')">{{ $t('label.close') }}</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>

import * as $ from 'jquery';
import { VueAutosuggest } from 'vue-autosuggest';

export default {
    props: ['source', 'materialData', 'jobid', 'task'],
    emits: ['cancelFnc', 'reload'],
    components: {
        VueAutosuggest
    },
    data() {
        return {
            form: {
                location: null,
                material: null,
                unit: null,
                quantity: 0,
                notes: null
            },
            jobmaterialsList: [],
            query: '',
            inputProps: {
                id: "autosuggest__input",
                placeholder: "Material",
                class: "ddd",
                required: true
            },
        }
    },
    computed: {
        filteredSuggestions() {
            return [{
                data: this.jobmaterialsList.filter(item => {
                    if (item.name) {
                        return (
                            item.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1
                        );
                    } else {
                        return '';
                    }
                })
            }];
        }
    },
    mounted() {
        console.log("In addJobMaterial");

        this.getJobMaterialsList()
        if (this.source == 'edit') {
            this.editModal(this.materialData);
        }
    },
    methods: {
        editModal(item) {
            this.form.location = item.location;
            this.form.material = item.material;
            this.query = item.material;
            this.form.unit = item.unit;
            this.form.quantity = item.quantity;
            this.form.notes = item.notes;
            this.form.id = item.id;
        },
        getJobMaterialsList() {
            let type = 'jobmaterials';
            axios.get('/jobmaterials-list').then(response => {
                this.jobmaterialsList = response.data.options;
            })
                .catch(err => {
                    //console.log('list carriers error:', error)
                });
        },
        saveJobMaterial() {
            this.form.material = $('#autosuggest__input').val();
            var busToEmit = '',
                apiRequest = '',
                request_fields = {
                    'location': this.form.location,
                    'material': this.form.material,
                    'unit': this.form.unit,
                    'quantity': this.form.quantity,
                    'notes': this.form.notes,
                };

            // Is Task or Job Material
            if (this.task.material_source == 2) {// task materials
                request_fields.task_id = this.task.id
                apiRequest = '/taskmaterials';
                busToEmit = 'MaterialModalTaskUpdate';
            } else if (this.task.material_source == 1) { // job materials
                request_fields.job_id = this.task.job.id
                apiRequest = '/jobmaterials';
                busToEmit = 'MaterialModalJobUpdate';
            }

            // Is Add or Edit Modal
            if (this.source == 'edit') {
                request_fields.id = this.form.id;
                apiRequest = apiRequest + '/update';
            }

            axios.post(apiRequest, request_fields).then(() => {
                this.$emit('reload');
                this.$emit('cancelFnc');
                bus.$emit(busToEmit);

                this.clearForm();
            }).catch(err => {
                //console.log('list carriers error:', error)
            });
        },
        clearForm() {
            $('#autosuggest__input').val('');
            this.query = '';
            this.form.location = '';
            this.form.material = '';
            this.form.unit = '';
            this.form.quantity = 0;
            this.form.notes = '';
        },
        checkMaterialQty() {
            if (this.form.quantity < 0)
                this.form.quantity = 0;
        },
    },
}
</script>
