<template>
    <div class="card" id="assetLifecycle">
        <div class="card-header">
            <div class="row align-items-center">
                <div class="col-8">
                    <h3 class="mb-0 d-flex align-items-center">
                        <i class="ri-refresh-line heading-title"></i>
                        {{ $t('label.lifecycle') }}
                    </h3>
                </div>
                <div class="col-4 text-right"></div>
            </div>
        </div>
        <div class="card-body pb-2">
            <div class="row">
                <div class="col-6 form-group">
                    <label class="form-control-label text-muted">{{ $t('label.start date') }}</label>
                    <h3 class="border-bottom">
                        <div v-if="vehicle.in_service_date">{{ vehicle.in_service_date }}</div>
                        <div v-else>&nbsp;</div>
                    </h3>
                </div>
                <div class="col-6 form-group">
                    <label class="form-control-label text-muted">{{ $t('label.end date') }}</label>
                    <h3 class="border-bottom">
                        <div v-if="vehicle.out_of_service_date">{{ vehicle.out_of_service_date }}</div>
                        <div v-else>&nbsp;</div>
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-6 form-group">
                    <label class="form-control-label text-muted">{{ $t('label.starting odometer') }}</label>
                    <h3 class="border-bottom">
                        <div v-if="vehicle.in_service_odometer">{{ vehicle.in_service_odometer.odometer_reading }}&nbsp;mi</div>
                        <div v-else>&nbsp;</div>
                    </h3>
                </div>
                <div class="col-6 form-group">
                    <label class="form-control-label text-muted">{{ $t('label.final odometer') }}</label>
                    <h3 class="border-bottom">
                        <div v-if="vehicle.out_of_service_odometer">{{ vehicle.out_of_service_odometer.odometer_reading }}&nbsp;mi</div>
                        <div v-else>&nbsp;</div>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    props: ['vehicle'],
    data() {
        return {}
    },
    mounted() { },
    methods: {}
}
</script>