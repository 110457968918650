/* eslint-disable */

const getters = {

  windowBreakPoint: (state) => {
    // This should be same as tailwind. So, it stays in sync with tailwind utility classes
    if (state.windowWidth >= 1200) return 'xl';
    else if (state.windowWidth >= 992) return 'lg';
    else if (state.windowWidth >= 768) return 'md';
    else if (state.windowWidth >= 576) return 'sm';
    else return 'xs';
  },

  scrollbarTag: (state) => {
    return state.is_touch_device ? 'div' : 'VuePerfectScrollbar';
  },

  isCustomerToken: (state) => () => {
    return localStorage.getItem('_token');
  },

  isLoggedIn: (state) => {
    const user = Object.assign({}, state.AppActiveUser);
    return Object.keys(user).length > 0 ? true : false;
  },

  currentUser: (state) => {
    const user = Object.assign({}, state.AppActiveUser);
    return Object.keys(user).length > 0 ? user : {};
  },

  getDomain: (state) => {
    return state.domain;
  },

  getLocals: (state) => {
    let _local = localStorage.getItem('_local');
    if (_local) {
      return JSON.parse(_local);
    } else {
      return null;
    }
  },

  isRouteGuardLoading: (state) => state.isRouteGuardLoading
};

export default getters;
