<template>
    <div>
        <notify-me width="400px" :event-bus="bus">
            <template slot="content" scope="{data}">
                <div class="p-2" style="width: 100%; word-break: break-all; text-align: left">
                    <h4><b>{{ data.title }}</b></h4>
                    <p style="margin: 0; color: #fff">{{ data.text }}</p>
                </div>
            </template>
        </notify-me>
        <div class="header-container">
            <div class="header bg-gradient-primary py-7 py-lg-8 pt-lg-9">
                <div class="container">
                    <div class="header-body text-center mb-7">
                        <div class="row justify-content-center">

                        </div>
                    </div>
                </div>
                <div class="separator separator-bottom separator-skew zindex-100">
                    <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
                    </svg>
                </div>
            </div>
        </div>
        <div class="top-container">
            <div class="align-all-items container pb-5">
                <div style="width: 100%">
                    <fade-transition origin="center" mode="out-in" :duration="250">
                        <router-view></router-view>
                    </fade-transition>
                </div>
            </div>
            <footer class="my-4">
                <div class="container">
                    <div class="row align-items-center justify-content-lg-between">
                        <div class="col-xl-6">
                            <div class="copyright text-center text-lg-left text-muted">
                                © 2022 SubPro
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <ul class="nav nav-footer justify-content-center justify-content-lg-end">
                                <li class="nav-item">
                                    <a href="https://spdevawt.com/privacy-policy" class="nav-link" target="_blank">Privacy Policy</a>
                                </li>
                                <li class="nav-item">
                                    <a href="https://spdevawt.com/terms-of-service" class="nav-link" target="_blank">Terms of Service</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link" target="_blank">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script lang="js">
import bus from '@/utils/event-bus';
import Notify from 'vue-notify-me';
export default {
    name: 'FullPageLayout',
    components: {
        'notify-me': Notify,
    },
    data: () => ({
        status: 'alert-success',
        bus
    }),
    mounted() {
        //
    }
}
</script>

<style lang="scss" scoped>
.header-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

.top-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}

.top-container .align-all-items {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3em 0;
    min-height: 90vh;
}
</style>
  