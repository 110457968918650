<template>
	<div class="z-index-3">
		<!-- Clock in. -->
		<div v-if="hasLoaded == false && company.settings" class="navbar-inner sidebar-clockin position-bottom pl-3 pr-3" style="z-index: 1 !important">
			<!-- <div v-if="(user.role_id == 2) || (user.role_id > 2 && company.settings.allow_clockin)" class="card mb-0 shadow-none" style="background-color: #e9ecef"> -->
			<div v-if="(user.role_id == 2) || (user.role_id > 2) && company.settings.allow_clockin" class="card mb-0 shadow-none" style="background-color: #e9ecef">
				<div class="card-header pt-3 pl-2 pr-2 border-0 bg-lightgray">
					<div class="row align-items-center m-0">
						<div class="col p-0">
							<p class="mb-0">{{ $t('label.Clock in to begin tracking your time') }}.</p>
						</div>
					</div>
				</div>
				<div class="card-body p-3">
					<button type="button" class="btn btn-success" :disabled="clockingInActive" @click="$emit('clockIn')">
						<i class="ri-time-line"></i> {{ $t('label.clock in') }}
					</button>
				</div>
			</div>
		</div>

		<!-- Clock Out -->
		<div v-if="hasLoaded == true" class="navbar-inner sidebar-clockin position-bottom pl-3 pr-3">
			<div class="card mb-0 bg-default shadow-none">
				<div class="card-header bg-default pt-3 pb-0 pl-2 pr-2">
					<div class="row align-items-center m-0">
						<div class="col p-0" v-if="userClockin">
							<h3 class="mb-0 text-white" v-if="userClockin.task">{{ userClockin.task.name }}</h3>
							<h4 class="mb-0 font-weight-normal text-white" v-if="userClockin.task">{{ userClockin.task.task_type.name }}</h4>
						</div>
					</div>
				</div>
				<div class="card-body p-3">
					<button type="button" class="btn btn-danger" @click="$emit('clockOut')">
						<i class="far fa-clock"></i>
						{{ $t('label.clock out') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex';

export default {
	props: ['user'],
	// Parent handels clock in/out modals due to nav bar stacking index
	emits: ['clockIn', 'clockOut'],
	data() {
		return {
			description: null,
			clockingInActive: false,
			currentlyClockedIn: false,
		}
	},
	computed: {
		...mapGetters({
			company: 'company',
			coordinates: 'coordinates',
			userClockin: 'time/userClockin',
			hasLoaded: 'time/hasLoaded',
			isEdit: 'time/isEdit',
		}),
	},
	watch: {
		hasLoaded(newVal) {
			this.clockingInActive = newVal;
		},
	},
	created() {
		bus.$on('checkClockinStatus', () => {
			this.$store.dispatch('time/getUserCurrentClockin');
		});
	},
	mounted() {
		// Initialize vuex localStorage watcher
		this.$store.dispatch('time/initLocalStorageWatcher');

		setTimeout(() => {
			this.$store.dispatch('time/getUserCurrentClockin');

			if (this.user)
				this.$store.dispatch('getLoggedInUser');
		}, 1000);
	},
	methods: {}
}
</script>
