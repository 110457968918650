<template>
    <div class="col-xl-12 order-xl-1 mobile-p-0">
        <div class="card">
            <div class="card-header">
                <div class="row align-items-center justify-content-between">
                    <div>
                        <h3 class="mb-0 d-flex align-items-center"><i class="ri-bill-line heading-title"></i> {{ $t('label.purchase orders') }}</h3>
                    </div>
                    <div>
                        <router-link class="text-primary" :to="{ name: 'purchaseOrderCreate', params: { allVendorsList: this.allVendors, jobid: this.jobid } }">
                            <button type="button" class="btn btn-sm btn-default">
                                {{ $t('label.add purchase order') }}
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
            <div v-if="purchaseOrders.length == 0">
                <div class="card-body m-0 py-0">
                    <div class="bg-white text-center row align-items-center justify-content-center m-0">
                        <i class="ri-bill-line" style="color: #CED4DA !important; font-size: 85px;"></i>
                        <p class="w-100 font-weight-normal">{{ $t('label.no purchase orders have been created') }}</p>
                    </div>
                </div>
            </div>
            <div v-else class="table-responsive pb-4">
                <table class="table table-flush table-sm border-bottom" style="margin: 0 !important;">
                    <thead class="thead-light">
                        <tr>
                            <th scope="col" style="width: 8.5rem">SO #</th>
                            <th scope="col" width="10%">QUICKBOOKS BILL NO.</th>
                            <th scope="col">{{ $t('label.vendor') }}</th>
                            <th scope="col">{{ $t('label.job') }}</th>
                            <th scope="col" style="width: 10rem">{{ $t('label.amount') }}</th>
                            <th scope="col" style="width: 10rem">{{ $t('label.created') }}</th>
                            <th scope="col" style="width: 12rem">{{ $t('label.status') }}</th>
                            <th scope="col" style="width: 11.5rem"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- Loop Tasks -->
                        <tr v-for="(data, index) in paginatedAllPurchaseOrders" :key="index" class="pointer">
                            <!-- PO # -->
                            <td>
                                <div class="task-name search-task" @click="routeToPOView(data)">
                                    {{ 'SO-' + data.purchase_order_number.purchase_order_number }}
                                </div>
                            </td>
                            <td><a v-if="data.qb_bill_id" target="_blank" :href="qb_url + 'app/bill?txnId=' + data.qb_bill_id">{{ data.quickbooks_bill_no }}</a></td>
                            <!-- Vendor -->
                            <td>
                                <div class="job-name search-task" @click="routeToVendorView(data)">
                                    {{ data.vendor.vendor_name }}
                                </div>
                            </td>
                            <!-- Job -->
                            <td>
                                <div class="text-link" v-if="data.job">{{ data.job.job_name }}</div>
                            </td>
                            <!-- Amount -->
                            <td>
                                <div class="job-name search-task">${{ parseFloat(data.amount).toFixed(2) | formatThousands }}</div>
                            </td>
                            <!-- Created Date -->
                            <td>
                                <div class="job-name search-task">{{ data.created_at | formatDateWithTz }}</div>
                            </td>
                            <!-- Status -->
                            <td>
                                <span class="badge badge-dot search-task">
                                    <i class="bg-success" :style="'background-color:' + data.purchase_order_status.color + ' !important'"></i> {{ data.purchase_order_status.name }}
                                </span>
                            </td>
                            <!-- menu dropdown -->
                            <td class="text-right">
                                <a v-if="data.has_notes" class="btn dropdown-toggle" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                                    <i class="ri-message-2-line heading-title p-1"></i>
                                </a>
                                <a v-if="data.has_files" class="btn dropdown-toggle" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                                    <i class="ri-folders-line heading-title p-1"></i>
                                </a>
                                <a class="btn dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                                    <i class="ri-more-2-fill heading-title p-1"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" aria-labelledby="taskItemMenu">
                                    <!-- Edit PO only on draft -->
                                    <router-link v-if="data.purchase_order_status.name == 'Draft'" class="dropdown-item text-default pointer" :to="{ name: 'purchaseOrderEdit', params: { id: data.id } }">
                                        <i class="ri-pencil-line"></i> {{ $t('label.edit') }}
                                    </router-link>
                                    <!-- View PO -->
                                    <router-link class="dropdown-item text-default pointer" :to="{ name: 'purchaseOrderView', params: { id: data.id } }">
                                        <i class="ri-eye-line"></i> {{ $t('label.view purchase order') }}
                                    </router-link>
                                    <!-- View PDF -->
                                    <a class="dropdown-item text-default pointer" @click="showModalPurchaseOrderView(data)">
                                        <i class="ri-eye-line"></i> {{ $t('label.view pdf') }}
                                    </a>
                                    <!-- Send only on draft and send -->
                                    <a class="dropdown-item text-default pointer" v-if="['Draft'].indexOf(data.purchase_order_status.name) !== -1" @click="showModalPurchaseOrderSend(data)">
                                        <i class="ri-mail-send-line"></i> {{ $t('label.send') }}
                                    </a>
                                    <!-- Download -->
                                    <a @click="$downloadStream(`/get-po-pdf-download/${data.id}`, getPdfDownloadName(data))" class="dropdown-item text-default pointer">
                                        <i class="ri-download-line"></i> {{ $t('label.download') }}
                                    </a>
                                    <!-- Print -->
                                    <a @click="$openStreamBlank(`/get-po-pdf-file/${data.id}`), getPdfDownloadName(data)" class="dropdown-item text-default pointer">
                                        <i class="ri-printer-line"></i> {{ $t('label.print') }}
                                    </a>
                                    <!-- Delete Draft -->
                                    <div v-if="data.purchase_order_status.name == 'Draft'">
                                        <div class="dropdown-divider mx-3"></div>
                                        <a class="dropdown-item text-danger pointer d-flex align-items-center" @click="setSelectedPurchaseOrder(data); toggleDeleteModal(true, data.purchase_order_status.name)">
                                            <i class="ri-close-circle-line"></i>{{ $t('label.delete purchase order') }}
                                        </a>
                                    </div>
                                    <!-- Cancel Sent PO -->
                                    <div v-if="data.purchase_order_status.name == 'Sent'">
                                        <div class="dropdown-divider mx-3"></div>
                                        <a class="dropdown-item text-danger pointer d-flex align-items-center" @click="setSelectedPurchaseOrder(data); toggleSendCancelModal()">
                                            <i class="ri-close-circle-line"></i>{{ $t('label.cancel purchase order') }}
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table-pagination v-model="paginatedAllPurchaseOrders" :all-rows="purchaseOrders" :perPage="perPage">
                </table-pagination>
            </div>
        </div>

        <!-- Purchase Order View Modal -->
        <modal class="py-4" name="modalPurchaseOrderView" height="auto" width="100%" :max-width="1200" :scrollable="true" :reset="true" :adaptive="true">
            <ModalPurchaseOrderView :purchase-order="purchaseOrderData" :selected-vendor-contact="selectedVendorContact" @reload="loadLkupData" @cancelFnc="$modal.hide('modalPurchaseOrderView')"></ModalPurchaseOrderView>
        </modal>

        <!-- Purchase Order Send Modal -->
        <modal class="py-4" name="modalPurchaseOrderSend" height="auto" width="100%" :max-width="900" :scrollable="true" :reset="true" :adaptive="true">
            <ModalPurchaseOrderSend :purchase-order="purchaseOrderData" :send-type="poSendType" :subject-text="subjectText" @cancelFnc="hideModalPurchaseOrderSend()"></ModalPurchaseOrderSend>
        </modal>

        <!-- Purchase Order Cancel -->
        <modal-delete-component v-if="showDltPOModal" :newId="'PurchaseOrder_view'" :title="cancelStatus == 'Draft' ? $t('label.ARE YOU SURE YOU WANT TO DELETE THIS PURCHASE ORDER') : $t('label.ARE YOU SURE YOU WANT TO CANCEL THIS PURCHASE ORDER')" :subtitle="cancelSubtitle" :btnText="cancelStatus == 'Draft' ? $t('label.Yes,Delete Purchase Order') : $t('label.Yes, Cancel Purchase Order')" @onClose="toggleDeleteModal()" @customDeleteMethod="deletePurchaseOrder()">
        </modal-delete-component>
    </div>
</template>
<script>
import ModalDeleteComponent from '@/components/ModalDelete2.vue';
import ModalPurchaseOrderSend from '@/components/ModalPurchaseOrderSend.vue';
import ModalPurchaseOrderView from '@/components/ModalPurchaseOrderView.vue';
import TablePagination from '@/components/TablePagination';
import TableServerPagination from '@/components/TableServerPagination.vue';
import bus from '@/utils/event-bus';

export default {
    props: ['jobid'],
    components: {
        TableServerPagination,
        ModalPurchaseOrderView,
        ModalPurchaseOrderSend,
        ModalDeleteComponent,
        TablePagination,
    },
    data() {
        return {
            qb_url: process.env.VUE_APP_QUICKBOOKS_URL,
            allVendors: [],
            currJobInfo: [],
            purchaseOrders: [],
            purchaseOrderData: [],
            selectedVendorContact: [],
            allPurchaseOrderStatus: [],
            paginatedAllPurchaseOrders: [],
            poSendType: '',
            subjectText: '',
            cancelStatus: 'Draft',
            cancelSubtitle: 'This cannot be undone.',
            perPage: 5,
            showDltPOModal: false,
        }
    },
    mounted() {
        this.loadLkupData();

        bus.$on('reloadPurchaseOrder', () => {
            this.getPurchaseOrders();
        });
    },
    methods: {
        loadLkupData() {
            this.getPurchaseOrders();
            this.getPurchaseOrderStatus();
            this.getAllVendors();
        },
        getPurchaseOrders() {
            this.clickSearch = true;
            this.purchaseOrders = [];
            this.paginatedAllPurchaseOrders = [];

            axios.get('/get-purchase-orders-by-job-id/' + this.jobid)
                .then(res => {
                    this.purchaseOrders = res.data.result;
                    this.paginatedAllPurchaseOrders = res.data.result;
                })
                .catch(err => console.log(err));
        },
        getPurchaseOrderStatus() {
            axios.get('/get-all-purchase-order-status')
                .then(res => {
                    this.allPurchaseOrderStatus = res.data.result;
                })
                .catch(err => console.log(err));
        },
        async getAllVendors() {
            await axios.get('/get-vendors')
                .then(res => {
                    this.allVendors = res.data.result;
                })
                .catch(err => console.log(err));
        },
        routeToPOView(data) {
            this.$router.push({ name: 'purchaseOrderView', params: { id: data.id } })
        },
        routeToVendorView(data) {
            this.$router.push({ name: 'vendordetails', params: { id: data.vendor_id, vendorInfo: data.vendor } })
        },
        setSelectedPurchaseOrder(item) {
            this.selectedPurchaseOrder = item;
            this.purchaseOrderData = this.selectedPurchaseOrder;
        },
        showModalPurchaseOrderSend(item = null) {
            this.subjectText = '';
            this.poSendType = 'send';
            this.purchaseOrderData = item;
            this.$modal.show('modalPurchaseOrderSend');
        },
        hideModalPurchaseOrderSend() {
            this.$modal.hide('modalPurchaseOrderSend');
        },
        showModalPurchaseOrderView(item = null) {
            this.purchaseOrderData = item;
            for (var i = 0; i < item.vendor.vendor_contacts.length; i++) {
                if (item.vendor.vendor_contacts[i].id == item.vendor_contact_id)
                    this.selectedVendorContact = item.vendor.vendor_contacts[i];
            }
            this.$modal.show('modalPurchaseOrderView');
        },
        getPdfDownloadName(po) {
            let name = po.purchase_order_status.name == 'Cancelled' ? 'CANCELLED_' : '';
            return name + "SO-" + po.purchase_order_number.purchase_order_number + "_" + moment().format('YYYY_MM_DD') + ".pdf";
        },
        toggleDeleteModal(show, status) {
            this.cancelStatus = status;
            this.showDltPOModal = show;
            if (status === 'Sent')
                this.cancelSubtitle += ' You will be able to send a message to the Vendor on the next step.';
        },
        toggleSendCancelModal() {
            this.poSendType = 'cancel'
            this.subjectText = 'CANCELLED';
            this.$modal.show('modalPurchaseOrderSend');
        },
        deletePurchaseOrder() {
            let reqData = {
                id: this.selectedPurchaseOrder.id,
                status: this.selectedPurchaseOrder.purchase_order_status.name,
            };

            axios.post('/delete-purchase-order', reqData).then(() => {
                this.getPurchaseOrders();
                bus.$emit('banner-success', {
                    message: this.$t('label.Purchase Order Draft deleted successfully') + '!'
                });
            });
        }
    },
}
</script>