/* eslint-disable no-unused-vars */
import { api } from '@/plugins/API.plugin';
import { store } from "@/store/store";
import { getDomain } from '@/utils/helpers';
import EventEmitter from 'events';

const localStorageKey = 'loggedIn';
const tokenExpiryKey = 'tokenExpiry';

class AuthService extends EventEmitter {
    async checking(data) {
        return api({ url: '/checking', method: 'get', data });
    }

    async login(loginData, config) {
        const { subDomain } = getDomain();
        const url = subDomain == 'admin' ? '/admin-login' : '/login';
        return api.post(url, loginData, config).then(async (res) => {
            const userData = res.data.data.user;
            const company_settings = res.data.data.company_settings;
            if (userData) {
                this.localLogin(userData, company_settings);
                this.setLocal(res.data.data._local);
                this.setAuthenticationHeader(res.data.data._token);
                if (loginData.checkbox_remember_me) {
                    this.setRememberLoginData(loginData);
                } else {
                    this.removeRememberLoginData();
                }
            }
            return res.data;
        });
    }

    async sendPasswordResetLink(data) {
        return api({ url: '/password/forgot', method: 'post', data });
    }

    async resetPassword(data) {
        return api({ url: '/password/reset', method: 'post', data });
    }

    localLogin(userInfo, company_settings) {
        return new Promise((resolve) => {
            let today = new Date();
            this.tokenExpiry = new Date();
            this.tokenExpiry.setDate(today.getDate() + 3);
            localStorage.setItem('tokenExpiryKey', this.tokenExpiry);
            localStorage.setItem('localStorageKey', 'true');
            localStorage.setItem('user', JSON.stringify(userInfo));

            console.log("company_settings", company_settings);
            localStorage.setItem('company_settings', JSON.stringify(company_settings));
            store.commit('GET_COMPANY_SETTINGS', company_settings);
            store.dispatch('Auth/updateUserInfo', userInfo);
            resolve();
        });
    }

    localLogOut() {
        localStorage.removeItem(localStorageKey);
        localStorage.removeItem(tokenExpiryKey);
        localStorage.removeItem('userInfo');
        localStorage.removeItem('company_settings');

        this.idToken = null;
        this.tokenExpiry = null;
        this.profile = null;
    }

    async logOut() {
        return new Promise((resolve, reject) => {
            localStorage.removeItem(localStorageKey);
            localStorage.removeItem(tokenExpiryKey);
            localStorage.removeItem('userInfo');
            localStorage.removeItem('company_settings');
            localStorage.removeItem('_token');
            this.idToken = null;
            this.tokenExpiry = null;
            this.profile = null;
            localStorage.clear();

            resolve();
        });
    }

    setLocal(string) {
        localStorage.setItem('_local', JSON.stringify(string));
        store.dispatch('Auth/setUserLocal', string);
    }

    getLocal() {
        let _local = localStorage.getItem('_local');
        return JSON.parse(_local);
    }

    setAuthenticationHeader(token) {
        localStorage.setItem('_token', token);
    }

    setRememberLoginData(loginData) {
        localStorage.setItem('rememberData', JSON.stringify(loginData));
    }

    removeRememberLoginData() {
        localStorage.removeItem('rememberData');
    }

    getUserLocal() {
        return api({ url: '/js/lang', method: 'get' });
    }

    getCurrentUser() {
        return api({ url: '/get-user', method: 'post' });
    }

    validateField(data) {
        return api({ url: '/validate-register-form-field', method: 'post', data });
    }

    checkLoginAndReturnDecryptedAtribbute(data) {
        return api({ url: '/check-login-and-return-decrypted-atribbute', method: 'post', data });
    }
}

export default new AuthService();

