<template>
    <!-- Duplicate Job Template w/ Vue js Modal -->
    <modal :name="modalName" @closed="closeFunc()" :classes="'rounded'" :min-height="400" width="525px" :scrollable="true" height="auto" :reset="true" :adaptive="true">
        <div class="bg-gradient-primary mobile-p-0 p-0">
            <div class="modal-header">
                <button type="button" class="close" @click="closeFunc()">
                    <span class="text-lighter" aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body text-white">
                <div class="py-3 text-center">
                    <i class="ri-todo-line ni-3x"></i>
                    <h4 class="heading mt-4 text-white">
                        <span v-if="title">{{ title }}</span>
                        <span v-else>{{ $t('label.ARE YOU SURE YOU WANT TO CONTINUE') }}</span>?
                    </h4>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-white" @click="confirmFunc()">
                    <div v-if="btnText">{{ btnText }}</div>
                    <div v-else>{{ $t('label.yes') }}</div>
                </button>
                <button type="button" class="btn btn-link text-white ml-auto" data-dismiss="modal" @click="closeFunc()">
                    {{ $t('label.close') }}
                </button>
            </div>
        </div>

    </modal>
</template>

<script>
export default {
    props: ['newId', 'title', 'btnText'],
    emits: ['customConfirmMethod', 'onClose'],
    data() {
        return {
            modalName: this.newId + "ConfirmationModal"
        }
    },
    mounted() {
        this.$modal.show(this.modalName);
    },
    methods: {
        confirmFunc() {
            this.$modal.hide(this.modalName);
            this.$emit('customConfirmMethod');
            this.$emit('onClose');
        },
        closeFunc() {
            this.$modal.hide(this.modalName);
            this.$emit('onClose');
        }
    }
}
</script>