<template>
    <modal :name="modalName" @closed="$emit('onClose')" :classes="'rounded'" :min-height="400" width="80%" :scrollable="true" height="auto" :reset="true" :adaptive="true">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header border-bottom">
                <h5 class="modal-title"> {{ $t('label.edit') + ' ' + formInfo.order_number }}</h5>
                <button type="button" class="close" @click="$emit('onClose')">
                    <i class="ri-close-line"></i>
                </button>
            </div>

            <!-- Modal Body -->
            <div class="modal-body purchase-order">
                <!-- VPO Info -->
                <VpoInfo :form-info="formInfo" :is-valid="isValid" po-type="edit" :job-id="jobId"></VpoInfo>

                <!-- VPO Items -->
                <VpoItems :form="formItem" :is-valid="isValid" type="edit" :formDirty="formDirty"></VpoItems>
            </div>

            <!-- Modal Footer -->
            <div class="modal-footer border-top">
                <div class="d-flex text-right">
                    <div class="ml-auto">
                        <span class="d-block d-sm-inline pb-2">
                            <button type="button" class="btn btn-link" @click="$emit('onClose')">
                                {{ $t('label.cancel') | uppercase }}
                            </button>
                        </span>
                        <span class="d-block d-sm-inline">
                            <button type="button" class="btn btn-primary" @click="validateVpo('save')">
                                {{ $t('label.save vpo') | uppercase }}
                            </button>
                        </span>
                        <span class="d-block d-sm-inline">
                            <button type="button" class="btn btn-primary" @click="validateVpo('save_send')">
                                {{ $t('label.save and send') | uppercase }}
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import VpoInfo from '@/components/VariablePurchaseOrders/AddEditVariablePurchaseOrders/VpoInfo.vue';
import VpoItems from '@/components/VariablePurchaseOrders/AddEditVariablePurchaseOrders/VpoItems.vue';
import ModalVpoSend from '@/components/VariablePurchaseOrders/SendVariablePurchaseOrder/VpoSendModal.vue';
import bus from '@/utils/event-bus';

export default {
    props: ['source', 'jobId', 'vpoId'],
    emits: ['onClose', 'onSaveAndSend'],
    components: {},
    components: {
        VpoInfo,
        VpoItems,
        ModalVpoSend
    },
    data() {
        return {
            modalName: "VpoEditModal" + this.source,
            formDirty: false,
            formInfo: {
                comments: '',
                job: null,
                terms: null,
                job_id: null,
                status: null,
                customer: null,
                job_name: null,
                customer_id: null,
                order_number: "",
                customer_name: null,
                delivery_date: null,
                superintendent_user_id: null,
                purchase_date: moment().format('MM/DD/YYYY'),
            },
            formItem: {
                list_items: [],
                list_items_to_delete: [],
                total: parseFloat(0.00).toFixed(2)
            },
            isValid: {
                items: null,
                customer_name: null,
                superintendent_user_id: null,
                job_name: null,
                purchase_date: true,
            },
        }
    },
    mounted() {
        this.$modal.show(this.modalName);
        this.getVpo();
    },
    methods: {
        getVpo() {
            return axios.get(`/vpo_get_by_id/` + this.vpoId).then(rsp => {
                let vpoData = rsp.data.result;

                // Form Info
                this.copyObjData(this.formInfo, vpoData);
                this.formInfo.status = vpoData.vpo_status.id;
                this.formInfo.comments = vpoData.description;
                this.formInfo.job_name = vpoData.job.job_name;
                this.formInfo.customer_name = vpoData.customer.name;
                this.formInfo.order_number = 'VPO-' + vpoData.vpo_number.vpo_number;
                this.formInfo.purchase_date = vpoData.purchase_date ? moment(vpoData.purchase_date).format('MM/DD/YYYY') : null;
                this.formInfo.delivery_date = vpoData.delivery_date ? moment(vpoData.delivery_date).format('MM/DD/YYYY') : null;

                // Form items
                this.formItem.list_items = vpoData.vpo_items;
                this.formItem.total = parseFloat(vpoData.overall_total).toFixed(2);
            }).catch(err => console.log(err));
        },
        // Validate all necessary fields in child components
        validateVpo(type) {
            try {
                let formValid = true;
                this.touchForms();

                // Check fieldsWithError for fields with an error
                for (const key in this.isValid) {
                    if ((this.isValid[key] === false))
                        formValid = false;
                    if (this.isValid[key] === null) {
                        formValid = false;
                        this.isValid[key] = false;
                    }
                }

                // Validate number of items
                if (this.formItem.list_items.length < 1)
                    throw new Error($t('label.vpo must have at least one billable item') + '.');
                // Validate Form Fields
                if (!formValid)
                    throw new Error($t('label.please validate required fields before proceeding') + '.');
                // Validate VPO has billable amount
                if (this.formItem.total <= 0)
                    throw new Error($t('label.cannot create a vpo with a total amount of 0') + '.');

                this.saveVpo().then(() => {
                    // Save the draft and return to previous page
                    if (type == 'save')
                        this.$emit('onClose');

                    // Save then open send modal
                    else if (type == 'save_send')
                        this.$emit('onSaveAndSend', this.vpoId);
                });

            } catch (err) {
                bus.$emit('banner-error', { message: err });
            }
        },
        touchForms() {
            this.formDirty = false;
            this.$nextTick(() => this.formDirty = true);
        },

        async saveVpo() {
            this.loader = this.$loading.show();
            let mergedForm = {
                vpo_id: this.vpoId,
                form_info: this.formInfo,
                form_item: this.formItem,
            };

            return axios.post('/vpo-update', mergedForm).then(() => {
                this.loader.hide();

                bus.$emit('banner-success', {
                    message: $t('label.vpo saved successfully!')
                });
            });
        },

        copyObjData(obj1, obj2) {
            for (const key in obj2)
                if (key in obj1) obj1[key] = obj2[key];
        },
    }
}
</script>