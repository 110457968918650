<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50">
            <!-- Header -->
            <div class="d-flex">
                <!-- Breadcrumbs -->
                <breadcrumbs :title="$t('label.job templates')">
                    <!-- Breakcrumb slots -->
                    <li class="breadcrumb-item">
                        {{ $t('label.administrative') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.content management') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.job templates') }}
                    </li>
                </breadcrumbs>
            </div>

            <!-- Templates Card -->
            <div class="card">
                <div class="card-header">
                    <div class="d-flex align-items-center">
                        <h3 class="d-flex py-1 align-items-center">
                            {{ $t('label.job templates') }}
                        </h3>
                        <div class="ml-auto text-right">
                            <span class="ml-auto py-1 mr-2 btn-group btn-group-sm btn-group-light">
                                <button class="btn text-bold" :class="{ active: currentFilter == 'all' }" @click="setCurrentFilters('all')"> {{ $t('label.all job templates') }}</button>
                                <button class="btn text-bold" :class="{ active: currentFilter == 'active' }" @click="setCurrentFilters('active')">{{ $t('label.active') }}</button>
                                <button class="btn text-bold" :class="{ active: currentFilter == 'inactive' }" @click="setCurrentFilters('inactive')">{{ $t('label.inactive') }}</button>
                                <button class="btn text-bold" :class="{ active: currentFilter == 'draft' }" @click="setCurrentFilters('draft')">{{ $t('label.drafts') }}</button>
                            </span>
                            <button type="button" class="btn btn-sm btn-default" @click="createNewJobTemplate()">
                                {{ $t('label.create new template') }}
                            </button>
                        </div>
                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table table-flush border-bottom">
                        <thead class="thead-light">
                            <tr>
                                <th>
                                    <div class="row">
                                        <div class="col-5">{{ $t('label.template name') }}</div>
                                        <div class="col-2">{{ $t('label.status') }} </div>
                                        <div class="col-4 text-right">{{ $t('label.last edited') }}</div>
                                        <div class="col-1"></div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="">
                            <tr v-for="(j_template, idx) in paginatedTemplates" :key="idx">
                                <td>
                                    <div class="row">
                                        <div class="col-5 d-flex align-items-center">
                                            <a class="text-bold blue-text" @click="editExistingJobTemplate(j_template)">
                                                <div class="cursor-pointer" v-if="j_template.job_name">
                                                    {{ j_template.job_name }}
                                                </div>
                                                <div class="cursor-pointer" v-else>
                                                    {{ $t('label.untitled') }}
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-2 d-flex align-items-center">
                                            <!-- This is inactive -->
                                            <div v-if="j_template.status == 'inactive'">
                                                <span class="status-indicator sold"></span>
                                                &nbsp;{{ $t('label.inactive') }}
                                            </div>
                                            <!-- This is draft -->
                                            <div v-else-if="j_template.status == 'draft'">
                                                <span class="status-indicator inshop"></span>
                                                &nbsp;{{ $t('label.draft') }}
                                            </div>
                                            <!-- This is active -->
                                            <div v-else>
                                                <span class="status-indicator inactive"></span>
                                                &nbsp;{{ $t('label.active') }}
                                            </div>
                                        </div>
                                        <!-- Last Edited -->
                                        <div class="col-4 d-flex">
                                            <div class="d-flex ml-auto align-items-center">
                                                <img class="rounded-circle py--1 my--1" :src="j_template.created_by.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">&nbsp;
                                                <div class="pl-2">{{ j_template.created_by.name }}</div>
                                                <div class="pl-2">{{ moment(j_template.updated_at).format('MM/DD/YYYY - h:mm:ss') }} </div>
                                            </div>
                                        </div>
                                        <div class="col-1 d-flex">
                                            <div class="dropdown ml-auto">
                                                <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fas fa-ellipsis-v"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                    <template v-if="j_template.status == 'active'">
                                                        <a class="dropdown-item text-dark pointer" @click="openJobFromTempModal(j_template)">
                                                            <i class="ri-edit-line"></i>{{ $t('label.create job from template') }}
                                                        </a>
                                                        <b-dropdown-divider></b-dropdown-divider>
                                                    </template>
                                                    <a class="dropdown-item text-dark pointer" @click="editExistingJobTemplate(j_template)">
                                                        <i class="ri-edit-line"></i>{{ $t('label.edit') }}
                                                    </a>
                                                    <a class="dropdown-item" href="#" data-toggle="modal" @click="launchDuplicateModal(j_template)">
                                                        <i class="ri-file-copy-line"></i>{{ $t('label.duplicate template') }}
                                                    </a>
                                                    <b-dropdown-divider></b-dropdown-divider>
                                                    <a class="dropdown-item text-danger" href="#" data-toggle="modal" @click="openDeleteTempModal(j_template)">
                                                        <i class="ri-delete-bin-line"></i> {{ $t('label.delete template') }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- Pagination -->
                    <table-pagination v-model="paginatedTemplates" :all-rows="filteredTemplates" :perPage="perPage">
                    </table-pagination>
                </div>
            </div>
        </div>

        <!-- Modal to Delete Template-->
        <modal-delete-component v-if="showJobTempDlt" :new-id="'Job Template'" :title="$t('label.ARE YOU SURE YOU WANT TO DELETE THIS JOB TEMPLATE')" @onClose="closeDeleteTempModal" @customDeleteMethod="deleteJobTemplate">
        </modal-delete-component>

        <!-- Modal to Duplicate Template-->
        <modal-duplicate-job-template v-if="showJobTempDupl" :job-id="selected_job_template.job_id" :template_id="selected_job_template.id" @onClose="closeDuplicateTempModal" @onDuplicate="editExistingJobTemplate">
        </modal-duplicate-job-template>

        <!-- Modal to Confirm New Job From Template -->
        <custom-conf-modal v-if="showJobFromTemplate" :new-id="'jobFromTemp'" :title="$t('label.ARE YOU SURE YOU WANT TO USE THIS TEMPLATE')" @customConfirmMethod="createJobFromTemplate" @onClose="closeJobFromTempModal">
        </custom-conf-modal>
    </div>
</template>

<script>
import ModalDuplicateJobTemplate from '@/components/Admin/JobTemplates/ModalJobTemplateDuplicate.vue';
import CustomConfModal from '@/components/ModalConfirmation.vue';
import ModalDelete2Vue from '@/components/ModalDelete2.vue';
import TablePagination from '@/components/TablePagination';
import bus from '@/utils/event-bus';

export default {
    props: [],
    components: {
        CustomConfModal,
        TablePagination,
        ModalDuplicateJobTemplate,
        modalDeleteComponent: ModalDelete2Vue
    },
    data() {
        return {
            perPage: 20,
            templateId: [],
            job_templates: [],
            paginatedTemplates: [],
            currentFilter: "all",
            newId: 'Job Template',
            job_template_id: null,
            showJobTempDlt: false,
            showJobTempDupl: false,
            showJobFromTemplate: false,
            selected_job_template: null,
        }
    },
    computed: {
        filteredTemplates() {
            if (this.currentFilter == 'active') {
                return Object.values(this.job_templates).filter(j_template =>
                    j_template.status == 'active');
            } else if (this.currentFilter == 'inactive') {
                return Object.values(this.job_templates).filter(j_template =>
                    j_template.status == 'inactive');
            } else if (this.currentFilter == 'draft') {
                return Object.values(this.job_templates).filter(j_template =>
                    j_template.status == 'draft');
            } else if (this.currentFilter == 'all') {
                return this.job_templates;
            }
            else
                return null
        }
    },
    mounted() {
        this.getAllJobTemplates();
        this.loader = this.$loading.show();

        Promise.all([this.getAllJobTemplates()]).then((response) => {
            this.loader.hide();
        });
    },
    methods: {
        getAllJobTemplates() {
            return axios.get("/get-all-job-templates").then(response => {
                this.job_templates = response.data.data;
            }).catch(err => console.log(err));
        },

        setCurrentFilters(filterType) {
            this.currentFilter = filterType;
        },

        editExistingJobTemplate(template) {
            this.$router.push({
                path: `jobTemplates/edit/${template.id}`
            });
        },
        createNewJobTemplate() {
            axios.post("/create-job-template",).then(response => {
                this.editExistingJobTemplate(response.data.result);
            }).catch(err => console.log(err));
        },

        openDeleteTempModal(j_template) {
            this.templateId = j_template.job_id;
            this.showJobTempDlt = true;
        },
        closeDeleteTempModal() {
            this.showJobTempDlt = false;
        },
        deleteJobTemplate() {
            axios.post('/delete-job-template', {
                id: this.templateId
            }).then(response => {
                this.closeDeleteTempModal();
                this.getAllJobTemplates();
                bus.$emit('banner-success', {
                    message: $t('label.job template deleted successfully') + '!',
                });
            }).catch(err => { });
        },

        openJobFromTempModal(j_template) {
            this.selectedTemplate = j_template;
            this.showJobFromTemplate = true;
        },
        closeJobFromTempModal() {
            this.showJobFromTemplate = false;
        },
        createJobFromTemplate() {
            var data = {
                'id': this.selectedTemplate.job_id,
                'job_name': this.selectedTemplate.job_name
            };

            axios.post('/create-job-from-template', data).then(response => {
                this.$router.push({
                    //	path: `jobs/edit/${response.data.id}`
                    name: 'jobEdit',
                    params: { id: response.data.id }
                });
            }).catch(err => { });
        },

        closeDuplicateTempModal() {
            this.showJobTempDupl = false;
        },
        launchDuplicateModal(j_template) {
            this.showJobTempDupl = true;
            this.selected_job_template = j_template;
        }
    },
}
</script>
