<template>
    <div class="container-fluid">
        <div class="mt-4 row">
            <div class="col-10">
                <h5 class="modal-title">{{ $t('label.confirm') }} <span class="text-danger">{{ $t('label.cancellation') }}</span> {{ $t('label.of request for') }} {{ getEmployeeName(item.user_id) }} </h5>
            </div>
            <div class="col-2 text-right">
                <button type="button" class="close" @click="$modal.hide('requestCancelModal')">
                    <span>&times;</span>
                </button>
            </div>
        </div>
        <div class="mt-4">
            <label for="notes">{{ $t('label.please give a reason for cancelation') }}</label>
            <span class="text-xs text-danger">* {{ $t('label.required') }}</span>

            <textarea id="notes" class="form-control" v-model="statusNotes" required></textarea>
            <!-- error display -->
            <div class="text-danger text-sm mt-1" v-if="formErrors.managerNotes">
                <div v-for="(error, i) in formErrors.managerNotes" :key="i">* {{ error }}</div>
            </div>
        </div>
        <div class="mt-4 mb-4">
            <div class="row">
                <div class="col-sm-6">
                    <button type="button" class="btn btn-success" @click="submitCancel()">
                        {{ $t('label.confirm') }}
                        {{ $t('label.cancel') }}
                    </button>
                </div>
                <div class="col-sm-6 text-right">
                    <button type="button" class="btn btn-secondary" @click="$modal.hide('requestCancelModal')">
                        {{ $t('label.cancel') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import bus from '@/utils/event-bus';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('userData')

export default {
    props: ['item'],
    emit: ['returnChanges'],
    data() {
        return {
            statusNotes: null,
            formErrors: { managerNotes: null }
        }
    },
    computed: {
        ...mapState({
            allUsers: state => state.allUsers,
        }),
    },
    created() {
        if (!this.allUsers.length) {
            this.getAllUsers().then(response => { })
        }
    },
    methods: {
        ...mapActions(['getAllUsers']),
        getEmployeeName(userId) {
            let companyUsers = this.allUsers;
            let idx = companyUsers.findIndex(request => {
                if (request.id === userId) {
                    return true;
                }
            });
            return (idx >= 0) ? companyUsers[idx].name : 'N/A';
        },
        submitCancel() {
            axios
                .post(`/cancelTimeOffRequest`, { id: this.item.id, managerNotes: this.statusNotes })
                .then(response => {
                    this.$modal.hide('requestCancelModal');
                    bus.$emit('banner-success', {
                        message: 'Request Cancelled!',
                    });

                    //Pass back the status change data
                    this.$emit('returnChanges', response.data);
                })
                .catch(err => {
                    // catch laravel validation errors
                    if (err.response) {
                        if (err.response.status === 422) {
                            this.formErrors = err.response.data.errors;
                        }
                    }
                    else {
                        console.error('ERROR: ', err);
                    }
                });
        },
    }
}
</script>
