<template>
    <div>
        <div class="row form-group">
            <div class="col-lg-8 col-sm-12">
                <!-- Show All Assets, Vehicles, and/or Items -->
                <div class="btn-group btn-group-light" role="group">
                    <button type="button" class="btn" :class="{ active: assetType == 'all' }" @click="setAssetType('all')">
                        <i class="ri-hammer-line"></i>
                        {{ $t("label.all assets") }}
                    </button>
                    <button type="button" class="btn" :class="{ active: assetType == 'vehicles' }" @click="setAssetType('vehicles')">
                        <i class="ri-truck-line"></i>
                        {{ $t("label.vehicles") }}
                    </button>
                    <button type="button" class="btn" :class="{ active: assetType == 'items' }" @click="setAssetType('items')">
                        <i class="ri-tools-line"></i>
                        {{ $t("label.items") }}
                    </button>
                </div>
            </div>
            <div class="col-lg-4 col-sm-12 text-lg-right pt-lg-0 pt-3">
                <!-- Compact or Regular view -->
                <div class="btn-group btn-group-light" role="group">
                    <button type="button" class="btn" :class="{ active: viewType == 'compact' }" @change="filtersBackToMain" @click="setViewType('compact')">
                        <i class="ri-grid-line"></i>
                        {{ $t("label.compact") }}
                    </button>
                    <button type="button" class="btn" :class="{ active: viewType == 'regular' }" @change="filtersBackToMain" @click="setViewType('regular')">
                        <i class="ri-layout-row-line"></i>
                        {{ $t("label.regular") }}
                    </button>
                </div>
            </div>
        </div>

        <!-- Assigned Status Filter -->
        <h3>{{ $t('label.assigned status') }}</h3>
        <div class="form-group">
            <span class="mr-4">
                <input type="checkbox" name="assigned" id="assigned" @change="filtersBackToMain" v-model="assignedStatuses.assigned" />&nbsp;
                <label for="assigned">{{ $t("label.assigned") }}</label>
            </span>
            <span class="mr-4">
                <input type="checkbox" name="unassigned" id="unassigned" @change="filtersBackToMain" v-model="assignedStatuses.unassigned" />&nbsp;
                <label for="unassigned">{{ $t("label.unassigned") }}</label>
            </span>
            <span class="mr-4">
                <input type="checkbox" name="all" id="all" @click="toggleAllAssigned($event)" />&nbsp;
                <label for="all">{{ $t("label.all") }}</label>
            </span>
        </div>

        <!-- Asset Status Filter -->
        <h3>{{ $t('label.asset status') }}</h3>
        <div class="form-group">
            <span class="mr-4 d-inline-block">
                <input type="checkbox" name="stat_active" id="stat_active" @change="filtersBackToMain" v-model="assetStatuses.active" />&nbsp;
                <label for="stat_active">{{ $t("label.active") }}</label>
            </span>
            <span class="mr-4 d-inline-block">
                <input type="checkbox" name="stat_shop" id="stat_shop" @change="filtersBackToMain" v-model="assetStatuses.inShop" />&nbsp;
                <label for="stat_shop">{{ $t("label.in shop") }}</label>
            </span>
            <span class="mr-4 d-inline-block">
                <input type="checkbox" name="stat_out_of_service" id="stat_out_of_service" @change="filtersBackToMain" v-model="assetStatuses.outOfService" />&nbsp;
                <label for="stat_out_of_service">{{ $t("label.out of service") }}</label>
            </span>
            <span class="mr-4 d-inline-block">
                <input type="checkbox" name="stat_inactive" id="stat_inactive" @change="filtersBackToMain" v-model="assetStatuses.inactive" />&nbsp;
                <label for="stat_inactive">{{ $t("label.inactive") }}</label>
            </span>
            <span class="mr-4 d-inline-block">
                <input type="checkbox" name="stat_sold" id="stat_sold" @change="filtersBackToMain" v-model="assetStatuses.sold" />&nbsp;
                <label for="stat_sold">{{ $t("label.sold/archived") }}</label>
            </span>
            <span class="mr-4 d-inline-block">
                <input type="checkbox" name="stat_all" id="stat_all" @click="toggleAllAssetStatuses($event)" />&nbsp;
                <label for="stat_all">{{ $t("label.all") }}</label>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            assignedStatuses: {
                assigned: true,
                unassigned: false
            },
            assetStatuses: {
                active: true,
                inShop: true,
                outOfService: true,
                inactive: false,
                sold: false,
            },
            assetType: "all",
            viewType: "compact",
        };
    },
    mounted() {
        this.viewTypeBackToMain();
        this.filtersBackToMain();
    },
    methods: {
        toggleAllAssigned(event) {
            console.log(event.target.checked);
            if (event.target.checked) {
                Object.keys(this.assignedStatuses).forEach((i) => {
                    this.assignedStatuses[i] = true;
                });
            } else {
                this.assignedStatuses.assigned = true;
                this.assignedStatuses.unassigned = false;
            }

            this.filtersBackToMain();
        },
        toggleAllAssetStatuses(event) {
            console.log(event.target.checked);
            if (event.target.checked) {
                Object.keys(this.assetStatuses).forEach((i) => {
                    this.assetStatuses[i] = true;
                });
            } else {
                this.assetStatuses.active = true;
                this.assetStatuses.inShop = true;
                this.assetStatuses.outOfService = true;
                this.assetStatuses.inactive = false;
                this.assetStatuses.sold = false;
            }
            this.filtersBackToMain();
        },
        setAssetType(type) {
            this.assetType = type;
            this.filtersBackToMain();
        },
        setViewType(type) {
            this.viewType = type;
            this.viewTypeBackToMain();
        },

        filtersBackToMain() {
            this.$emit("set-filters", {
                assignedStatuses: this.assignedStatuses,
                assetStatuses: this.assetStatuses,
                assetType: this.assetType
            });
        },
        viewTypeBackToMain() {
            this.$emit("set-view", this.viewType);
        }
    },
};
</script>
