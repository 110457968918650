<template>
    <div class="card">
        <div class="card-header">
            <h3 class="d-flex align-items-center mb-0">
                <i class="ri-feedback-line"></i> {{ $t('label.description, special instructions & attachments') }}
            </h3>
        </div>
        <div class="card-body">
            <div class="row mb-3">
                <div class="col-12">
                    <label class="form-control-label text-muted">{{ $t('label.description or special instructions') }}</label>
                    <h3 class="font-weight-normal" v-if="purchaseOrder.purchase_order_comments">{{ purchaseOrder.purchase_order_comments.comments }}</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    props: ['purchaseOrder'],
    emits: [''],
    components: {
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            dropzoneOptions: {
                // url: 'https://httpbin.org/post',
                url: 'https://putsreq.com/sMLPgj8Pe0jHquCDgZcs',
                acceptedFiles: 'image/*, application/pdf',
                maxFilesize: 10,
                addRemoveLinks: true,
                dictDefaultMessage: `<div><span>Drag and drop files here</span><br>
                                <span>OR</span><br>
                                <button type="button"class="btn btn-md btn-default">Browse Files</button></div>`,
            },
            viewTemplate: "form",
            disableSubmit: false,
            selectedFile: {
                id: null
            },
        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        getUploadedFile(file) {
            setTimeout(function () {
                this.formComment.selectedEntry.attachments.push({
                    image: file.dataURL,
                    fileName: file.name,
                    fileType: file.type,
                    file: file
                });

                // Clear the dropzone for more files
                this.$refs.myVueDropzone.removeAllFiles(true);
                this.disableSubmit = false;
            }.bind(this), 1000);
        },
        uploadingFile() {
            this.disableSubmit = true;
        },
        selectFile(file, viewTemplate) {
            this.selectedFile = file;
            this.viewTemplate = viewTemplate;
        },
    },
}
</script>
