<template>
    <div class="col-xl-12 order-xl-1 mobile-p-0 p-0" id="maintenanceEntries">
        <div class="card">
            <!-- Maintenance Header -->
            <div class="card-header">
                <div class="row">
                    <div class="col-6">
                        <h3 class="mb-0 d-flex align-items-center">
                            <i class="ri-tools-line heading-title"></i>
                            {{ $t('label.maintenance') }}
                        </h3>
                    </div>
                    <div class="col-6 text-right">
                        <button type="button" class="btn btn-sm btn-default m-0" data-toggle="modal" data-target="#addOrEditEntryModal" @click="clickAddEntry()">
                            {{ $t('label.add maintenance entry') }}
                        </button>
                    </div>
                </div>
            </div>

            <!-- Maintenance Body -->
            <div class="card-body m-0 p-0">
                <!-- Search Bar -->
                <div class="row">
                    <div class="col-11 col-sm-7 col-md-5 form-group m-3">
                        <div class="input-group input-group-sm input-group-alternative input-group-merge border-top">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-search"></i></span>
                            </div>
                            <input name="entrySearch" class="form-control" v-model="entrySearch" :change="searchEntries()" :placeholder="$t('label.search maintenance')">
                        </div>
                    </div>
                </div>

                <!-- Maintenance Entry List -->
                <div v-show="filteredEntries.length < 1" class="border-top">
                    <div class="bg-white text-center row align-items-center m-0">
                        <i class="ri-tools-line ni-8x w-100 color-gray-400"></i>
                        <p class="w-100 font-weight-normal">{{ $t('label.no maintenance entries have been added to this asset') }}.</p>
                    </div>
                </div>
                <div v-show="filteredEntries.length >= 1">
                    <div class="table-responsive pb-4">
                        <table id="mtnc-table" class="table table-flush table-sm div-table">
                            <!-- Table Header -->
                            <thead class="thead-light">
                                <tr class="">
                                    <th class="px-2">
                                        <div class="row p-0 m-0">
                                            <div class="col-1"></div>
                                            <div class="col-4" :class="{ 'col-sm-3 col-lg-2': (assetType === 'vehicle') }">{{ $t('label.completion date') }}</div>
                                            <template v-if="assetType === 'vehicle'">
                                                <div class="col-4 col-sm-3 col-lg-2 hidden-sm-down">{{ $t('label.vehicles odometer') }}</div>
                                                <div class="col-4">{{ $t('label.details') }}</div>
                                                <div class="col-2 hidden-md-down">{{ $t('label.total') }}</div>
                                            </template>
                                            <template v-if="assetType === 'item'">
                                                <div class="col-4">{{ $t('label.details') }}</div>
                                                <div class="col-2 hidden-md-down">{{ $t('label.total') }}</div>
                                            </template>
                                            <div class=""></div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>

                            <!-- Table Body -->
                            <tbody class="border-bottom">
                                <template v-for="(entry, index) in paginatedEntries">
                                    <tr v-if="entryDropdownIdx !== entry.id" :key="index">
                                        <td colspan="5" class="px-2 list-group-item-action collapsed-entry">
                                            <!-- Collapsed View -->
                                            <div class="row p-0 m-0 d-flex align-items-center">
                                                <div class="col-1" @click="expandOrCollapseList(entry.id)">
                                                    <i class="fas fa-angle-down fa-lg"></i>
                                                </div>
                                                <div class="col-4" :class="{ 'col-sm-3 col-lg-2': (assetType === 'vehicle') }" @click="expandOrCollapseList(entry.id)">
                                                    <div class="row align-items-center m-0">{{ entry.completion_date }}</div>
                                                </div>
                                                <template v-if="assetType === 'vehicle'">
                                                    <div class="col-3 col-sm-3 col-lg-2 hidden-sm-down" @click="expandOrCollapseList(entry.id)">
                                                        <div class="row align-items-center m-0">{{ entry.odometer ? entry.odometer.odometer_reading.toLocaleString() : "0" }}&nbsp;mi</div>
                                                    </div>
                                                    <div class="col-4" @click="expandOrCollapseList(entry.id)">
                                                        <div class="row align-items-center m-0 collapsed-details">{{ entry.details }}</div>
                                                    </div>
                                                    <div class="col-2 text-right hidden-md-down" @click="expandOrCollapseList(entry.id)">
                                                        <div class="row m-0 text-center">${{ entry.total_cost > 0 ? Number.parseFloat(entry.total_cost).toFixed(2) : "0.00" }}</div>
                                                    </div>
                                                </template>
                                                <template v-if="assetType === 'item'">
                                                    <div class="col-4" @click="expandOrCollapseList(entry.id)">
                                                        <div class="row align-items-center m-0 collapsed-details">{{ entry.details }}</div>
                                                    </div>
                                                    <div class="col-2 text-right hidden-md-down" @click="expandOrCollapseList(entry.id)">
                                                        <div class="row m-0 text-center">${{ entry.total_cost > 0 ? Number.parseFloat(entry.total_cost).toFixed(2) : "0.00" }}</div>
                                                    </div>
                                                </template>
                                                <div class="ml-auto text-right">
                                                    <!-- Options -->
                                                    <div class="dropdown">
                                                        <a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown">
                                                            <i class="fas fa-ellipsis-v"></i>
                                                        </a>
                                                        <div id="elmDropdown" class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                            <a class="dropdown-item d-flex align-items-center" href="#" data-dismiss="modal" data-toggle="modal" data-target="#addOrEditEntryModal" @click="clickEditEntry(entry)">
                                                                <i class="ri-pencil-line pr-2"></i> {{ $t('label.edit maintenance entry') }}
                                                            </a>
                                                            <a v-if="user.role_id == 1 | user.role_id == 2" class="dropdown-item d-flex align-items-center text-danger" href="#" data-dismiss="modal" data-toggle="modal" data-target="#deleteEntryModal" @click="clickDeleteEntry(entry)">
                                                                <i class="ri-delete-bin-line pr-2"></i> {{ $t('label.delete maintenance entry') }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <template v-if="entryDropdownIdx === entry.id">
                                        <tr :key="index + '2'">
                                            <!-- Expanded View -->
                                            <div class="px-2 expanded-entry-header">
                                                <div class="row p-0 m-0 d-flex">
                                                    <div class="col-11" @click="expandOrCollapseList()">
                                                        <i class="fas fa-angle-up fa-lg expanded-icon"></i>
                                                    </div>
                                                    <div class="ml-auto">
                                                        <!-- Options -->
                                                        <div class="dropdown">
                                                            <a class="btn btn-sm text-light" href="#" role="button" data-toggle="dropdown">
                                                                <i class="fas fa-ellipsis-v"></i>
                                                            </a>
                                                            <div id="elmDropdown" class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                                <a class="dropdown-item d-flex align-items-center" href="#" data-dismiss="modal" data-toggle="modal" data-target="#addOrEditEntryModal" @click="clickEditEntry(entry)">
                                                                    <i class="ri-pencil-line pr-2"></i> {{ $t('label.edit maintenance entry') }}
                                                                </a>
                                                                <a v-if="user.role_id == 1 | user.role_id == 2" class="dropdown-item d-flex align-items-center text-danger" href="#" data-dismiss="modal" data-toggle="modal" data-target="#deleteEntryModal" @click="clickDeleteEntry(entry)">
                                                                    <i class="ri-delete-bin-line pr-2"></i> {{ $t('label.delete maintenance entry') }}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </tr>
                                        <tr :key="index + '3'">
                                            <div class="expanded-entry-body pl-2 pr-2">
                                                <div class="pt-2 pb-2">
                                                    <!-- Entry Information -->
                                                    <div class="row text-sm p-0 m-0">
                                                        <div class="col-6">
                                                            <div class="p-3">
                                                                <label class="text-muted">{{ $t('label.completion date') }}</label>
                                                                <div class="border-bottom text-bold">{{ entry.completion_date }}</div>
                                                            </div>
                                                            <template v-if="assetType === 'vehicle'">
                                                                <div class="p-3">
                                                                    <label class="text-muted">{{ $t('label.vehicles odometer') }}</label>
                                                                    <div class="border-bottom text-bold">{{ entry.odometer ? entry.odometer.odometer_reading : "0" }}&nbsp;mi</div>
                                                                </div>
                                                            </template>
                                                            <div class="p-3">
                                                                <label class="text-muted">{{ $t('label.details') }}</label>
                                                                <div class="border-bottom text-bold">{{ entry.details }}</div>
                                                            </div>
                                                            <div class="p-3">
                                                                <label class="text-muted">{{ $t('label.total') }}</label>
                                                                <div class="border-bottom text-bold">${{ entry.total_cost > 0 ? Number.parseFloat(entry.total_cost).toFixed(2) : "0.00" }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="p-3">
                                                                <label class="text-muted mb--2">{{ $t('label.receipts') }}</label>
                                                                <div class="p-3">
                                                                    <!-- Receipt Files -->
                                                                    <div v-if="entry.receipt_files.length <= 0">
                                                                        <div class="text-muted">{{ $t('label.no files have been uploaded') }}.</div>
                                                                    </div>
                                                                    <div v-else>
                                                                        <div class="pl-3" v-for="(file, idx) in entry.receipt_files" :key="idx">
                                                                            <div class="row flex-container pb-2">
                                                                                <div class="">
                                                                                    <img class="image-container image-container-thumbnail float-left mr-2" :src="file.file_type == 'application/pdf' ? '/images/icons/doc-pdf.png' : '/' + file.download" />
                                                                                </div>
                                                                                <div class="">
                                                                                    <a class="text-bold" href="#" data-dismiss="modal" data-toggle="modal" data-target="#viewEntryFileModal" @click="selectFile(file, entry)">
                                                                                        <span v-if="file.display_name">{{ file.display_name }}</span>
                                                                                        <span v-else>{{ file.name }}</span>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </tr>
                                    </template>
                                </template>
                            </tbody>
                        </table>
                        <!-- Pagination -->
                        <table-pagination v-model="paginatedEntries" :all-rows="filteredEntries" :perPage="perPage">
                        </table-pagination>
                    </div>
                </div>
            </div>

            <!-- View Entry Selected File -->
            <div class="modal" id="viewEntryFileModal" tabindex="-1" role="dialog">
                <div class="modal-dialog" style="max-width: 1200px" role="document">
                    <div class="modal-content">
                        <!-- Header -->
                        <div class="modal-header border-bottom">
                            <h5 class="modal-title">
                                <span v-if="selectedFile.display_name">{{ selectedFile.display_name }}</span>
                                <span v-else>{{ selectedFile.name }}</span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <i class="ri-close-line"></i>
                            </button>
                        </div>
                        <!-- Body -->
                        <div v-if="selectedFile.id" class="modal-body p-0">
                            <div class="col-xl-12 order-xl-1 mobile-p-0 p-0">
                                <div class="p-3">
                                    <div class="row">
                                        <!-- Image of the file -->
                                        <div class="col-lg-8 col-12 p-4 text-center" :class="{ 'col-lg-8': selectedFile.id, 'col-lg-12': !selectedFile.id }" style="background: #F6F9FC;">
                                            <div v-if="selectedFile.file_type == 'application/pdf'">
                                                <div class="w-100">
                                                    <img class="w-25" src="/images/icons/other-file.png" alt="Card image cap">
                                                </div>
                                                <div class="pt-3 pb-4 w-100">{{ $t('label.Preview unavailable for this file type') }}.</div>
                                                <a class="btn btn-primary" :href="mtncFolderPath + selectedFile.name" download>
                                                    <i class="ri-download-line pr-2"></i>
                                                    {{ $t('label.download') }}
                                                </a>

                                            </div>
                                            <div v-else class="p-2">
                                                <img class="mw-100" :src="'/' + encodeURI(selectedFile.download)" alt="Card image cap">
                                            </div>
                                        </div>

                                        <!-- File Details -->
                                        <div class="col-lg-4 col-12 p-4">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <h1 class="p-0 col-lg-10" style="word-break: break-all;">
                                                            <span v-if="selectedFile.display_name">{{ selectedFile.display_name }}</span>
                                                            <span v-else>{{ selectedFile.name }}</span>
                                                        </h1>
                                                        <button type="button" class="btn btn-default btn-sm btn m-0 pr-3 pl-3 pt-1 pb-1 dropdown-btn" data-toggle="dropdown">
                                                            <i class="fas fa-ellipsis-h"></i>
                                                        </button>
                                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                                            <a class="dropdown-item d-flex align-items-center" :href="mtncFolderPath + selectedFile.name" download>
                                                                <i class="ri-download-line pr-2"></i> {{ $t('label.download file') }}
                                                            </a>
                                                            <a class="dropdown-item d-flex align-items-center text-danger" href="#" data-dismiss="modal" data-toggle="modal" data-target="#deleteEntryFileModal" @click="clickDeleteFile()">
                                                                <i class="ri-delete-bin-line pr-2"></i> {{ $t('label.delete file') }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.uploaded by') }}</label>
                                                        <div class="input-value">
                                                            <img class="rounded-circle" :src="selectedFile.created_by.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
                                                            <span class="cursor-pointer px-2" @click="getUserInfo(user.id, 'Uploaded By')">{{ selectedFile.created_by.name }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label text-muted font-weight-normal" for="input-name">{{ $t('label.uploaded date') }}</label>
                                                        <div class="input-value">
                                                            {{ selectedFile.created_at | formatDate }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Footer -->
                        <div class="modal-footer border-top d-flex justify-content-between">
                            <a class="btn btn-primary" :href="mtncFolderPath + selectedFile.name" download>
                                <i class="ri-download-line pr-2"></i>
                                {{ $t('label.download') }}
                            </a>
                            <button type="button" class="btn btn-link  ml-auto close2editfile" data-dismiss="modal">{{ $t('label.close') }}</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Add or Edit Modal -->
            <div class="modal fade" style="z-index: 99" id="addOrEditEntryModal" tabindex="-1" role="dialog">
                <div class="modal-dialog" style="max-width: 1000px;" role="document">
                    <div v-if="modalActive">
                        <!-- Add -->
                        <div class="" v-if="selectedEntry.id === null">
                            <asset-mtnc-entry-add :asset-type="assetType" :asset-api="assetApi" :asset-id="assetId" :modal="true" :mtnc-folder-path="mtncFolderPath" @on-save="closeAddOrEdit">
                            </asset-mtnc-entry-add>
                        </div>

                        <!-- Edit -->
                        <div class="" v-if="selectedEntry.id !== null">
                            <asset-mtnc-entry-edit :asset-type="assetType" :asset-api="assetApi" :modal="true" :entry="selectedEntry" :mtnc-folder-path="mtncFolderPath" @on-save="closeAddOrEdit" @on-file-delete="deleteFile">
                            </asset-mtnc-entry-edit>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Delete Entry Modal -->
            <div class="modal fade" id="deleteEntryModal" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
                    <div class="modal-content bg-gradient-danger">
                        <!-- Delete Header -->
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <!-- Delete Form Details -->
                        <div class="modal-body">
                            <div class="py-3 text-center">
                                <i class="ri-error-warning-line ni-3x"></i>
                                <h4 class="heading mt-4 text-">{{ $t('label.ARE YOU SURE YOU WANT TO DELETE THIS MAINTENANCE ENTRY') }}?</h4>
                                <p>{{ $t('label.This action cannot be undone') }}!</p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-white" id="deleteEntryBtn" data-dismiss="modal" @click="deleteEntry()">{{ $t('label.Yes, Delete') }}</button>
                            <button type="button" class="btn btn-link text-white ml-auto" data-dismiss="modal">{{ $t('label.cancel') }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Delete File Modal -->
            <div class="modal fade" id="deleteEntryFileModal" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
                    <div class="modal-content bg-gradient-danger">
                        <!-- Delete Header -->
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <!-- Delete Form Details -->
                        <div class="modal-body">
                            <div class="py-3 text-center">
                                <i class="ri-error-warning-line ni-3x"></i>
                                <h4 class="heading mt-4 text-">{{ $t('label.ARE YOU SURE YOU WANT TO DELETE THIS FILE') }}?</h4>
                                <p>{{ $t('label.This action cannot be undone') }}!</p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-white" id="deleteFileBtn" data-dismiss="modal" @click="deleteFile({ entryId: selectedEntry.id, fileId: selectedFile.id, })">{{ $t('label.Yes, Delete') }}</button>
                            <button type="button" class="btn btn-link text-white ml-auto" data-dismiss="modal">{{ $t('label.cancel') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.collapsed-entry:hover {
    cursor: pointer !important;
}

.collapsed-details {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

.expanded-entry-header {
    background-color: #00224D;
    padding-top: 10px;
    padding-bottom: 10px;
}

.expanded-entry-header:hover {
    cursor: pointer;
    background-color: #1b2e4d;
}

.expanded-entry-body {
    background-color: #FAFAFA;
}

.expanded-icon {
    padding-top: 7px;
    color: #FFFFFF;
}

.img-view {
    max-width: 800px;
    max-height: 600px;
}
</style>

<script>
import TablePagination from '@/components/TablePagination.vue';

import bus from '@/utils/event-bus';
import * as $ from 'jquery';
import * as _ from 'lodash';
import AssetMaintenanceEntriesAddOrEditVue from './AssetMaintenanceEntriesAddOrEdit.vue';

export default {
    props: ['assetId', 'assetType'],
    components: {
        TablePagination,
        assetMtncEntryAdd: AssetMaintenanceEntriesAddOrEditVue,
        assetMtncEntryEdit: AssetMaintenanceEntriesAddOrEditVue
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            allUsers: [],
            allEntries: [],
            filteredEntries: [],
            paginatedEntries: [],
            perPage: 5,
            currentPage: 1,
            entrySearch: "",
            mtncFolderPath: "",
            entryDropdownIdx: "",
            assetApi: "/",
            entrySearchPrerender: "",
            modalActive: false,
            selectedEntry: {
                id: null
            },
            selectedFile: {
                id: null
            },
        }
    },
    computed: {
        showingCurrPage() {
            return (1 + (this.perPage * (this.currentPage - 1)));
        },
        showingToPage() {
            let toVal = this.currentPage * this.perPage;
            if (toVal >= this.filteredEntries.length)
                return this.filteredEntries.length;
            else
                return toVal;
        },
    },
    mounted() {
        // Vehicle or asset item
        if (this.assetType === "vehicle") {
            this.assetApi += "vehicle-maintenance/";
            this.mtncFolderPath = "/vehicle-uploads/";
        } else if (this.assetType === 'item') {
            this.assetApi += "asset-item-maintenance/";
            this.mtncFolderPath = "/asset-item-uploads/";
        }

        this.getAllEntries();

        // Watch for external use of adding a new entry
        $('#addOrEditEntryModal').on('show.bs.modal', () => {
            this.modalActive = true;
        });
        $('#addOrEditEntryModal').on('hide.bs.modal', () => {
            this.selectedEntry = {
                id: null
            };
        });
    },
    methods: {
        getAllEntries() {
            axios.get(this.assetApi + 'get-entries/' + this.assetId).then(response => {
                this.allEntries = response.data;
                if (this.assetType === "vehicle")
                    this.sortVehicleEntries();

                this.filteredEntries = JSON.parse(JSON.stringify(this.allEntries));
            }).catch(err => console.log(err));
        },

        clickDeleteEntry(entry) {
            this.selectedEntry = entry;
        },
        clickEditEntry(entry) {
            this.modalActive = true;
            this.selectedEntry = JSON.parse(JSON.stringify(entry));
        },
        clickAddEntry() {
            this.modalActive = true;
            this.selectedEntry = {
                id: null
            };
        },
        closeAddOrEdit() {
            this.modalActive = false;
            this.getAllEntries();
        },

        selectFile(file, entry) {
            this.selectedFile = file;
            this.selectedEntry = entry;
        },
        clearSelectedFile() {
            this.selectedFile = {
                id: null
            };
        },

        sortVehicleEntries() {
            this.allEntries.sort(function compare(cur, next) {
                if (cur.completion_date === next.completion_date) {
                    if (cur.odometer.odometer_reading < next.odometer.odometer_reading)
                        return 1;
                    else if (cur.odometer.odometer_reading > next.odometer.odometer_reading)
                        return -1;
                }

                return 0;
            });
        },

        deleteEntry() {
            let entryId = this.selectedEntry.id;

            // Remove entry receipt_files before deleting entry  
            // to avoid having the DOM render unlinked files.
            this.filteredEntries.forEach((entry, idx) => {
                if (entry.id === entryId)
                    entry.receipt_files = [];
            });

            axios.post(this.assetApi + "destroy", { id: entryId }).then(response => {
                this.getAllEntries();
                this.selectedEntry = {
                    id: null
                };

                bus.$emit('banner-success', {
                    message: this.$t('label.maintenance entry deleted successfully') + '!'
                });
            }).catch(err => console.log(err));
        },

        clickDeleteFile(file) {
            if (file)
                this.selectedFile = file;
        },
        deleteFile(data) {
            axios.post(this.assetApi + "destroy-file", {
                id: data.fileId
            }).then(response => {
                // Remove deleted file from entry receipt_file list
                this.filteredEntries.forEach(entry => {
                    if (entry.id === data.entryId) {
                        entry.receipt_files.forEach((file, idx) => {
                            if (file.id === data.fileId) {
                                entry.receipt_files.splice(idx, 1);
                            }
                        });
                    }
                });

                bus.$emit('banner-success', {
                    message: this.$t('label.maintenance entry file deleted successfully') + '!'
                });
            }).catch(err => console.log(err));
        },

        expandOrCollapseList(idx) {
            this.entryDropdownIdx = idx ? idx : "";
        },

        // Searchbar filter
        searchEntries: _.debounce(function () {
            // After page rerenders from :change, don't proceed unless there is a change in search string
            if (this.entrySearch !== this.entrySearchPrerender) {
                if (this.entrySearch.length > 0) {
                    let search = this.entrySearch.toLowerCase(),
                        filtered = [];

                    // Find entries with fields matching the search string
                    this.allEntries.forEach(entry => {
                        let compDate = entry.completion_date,
                            details = entry.details.toLowerCase(),
                            total = entry.total_cost ? String(entry.total_cost) : '0.00'

                        // Vehicles
                        if (this.assetType === "vehicle") {
                            let odom = String(entry.odometer.odometer_reading)

                            if (compDate.includes(search) || odom.includes(search) ||
                                details.includes(search) || total.includes(search)) {

                                filtered.push(entry);
                            }
                        }

                        // Asset Items
                        if (this.assetType === "item") {
                            if (compDate.includes(search) || details.includes(search) || total.includes(search))
                                filtered.push(entry);
                        }
                    });

                    // Update filtered entries
                    this.filteredEntries = filtered;
                } else {
                    this.filteredEntries = JSON.parse(JSON.stringify(this.allEntries));
                }

                // Update search string
                this.entrySearchPrerender = this.entrySearch;
            }
        }),
    },
}
</script>
