<template>
    <div>
        <div class="modal-content">
            <div class="modal-header border-bottom">
                <h5 class="modal-title" v-if="selectedUser">{{ $t('label.edit user') }}</h5>
                <h5 class="modal-title" v-else>{{ $t('label.add user') }}</h5>
                <button type="button" class="close" @click="$emit('cancelFnc')" aria-label="Close">
                    <i class="ri-close-line"></i>
                </button>
            </div>

            <div class="modal-body">
                <!-- Compensation -->
                <div class="card">
                    <div class="card-body">
                        <div class="card-title">{{ $t('label.compensation') }}</div>
                        <div class="row">
                            <!-- Pay Type -->
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="pay_type">{{ $t('label.pay type') }}</label>
                                    <select name="pay_type" id="pay_type" v-model="user.pay_type" class="form-control" @change="checkPayRateDisabled()">
                                        <option :value="type" :key="type" v-for="type in lookupData.payTypes">{{ type }}</option>
                                    </select>
                                </div>
                            </div>
                            <!-- Pay Rate -->
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="payRate">{{ $t('label.pay rate') }}</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>
                                        </div>
                                        <input id="payRate" type="text" v-model="user.payRate" :disabled="payRateIsDisabled" class="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card" v-if="user.pay_type == '1099'">
                    <div class="card-body">
                        <div class="card-title">1099 Information</div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="pay_type">Company Name</label>
                                    <span class="text-xs text-danger">&nbsp;* {{ $t('label.required') }}</span>
                                    <input type="text" class="form-control" v-model="$v.user.pay1099.company_name.$model" required autofocus autocomplete="input-field" data-lpignore="true">

                                    <div v-if="$v.user.pay1099.company_name.$error && !$v.user.pay1099.company_name.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="pay_type">Federal Tax ID or SS</label>
                                    <span class="text-xs text-danger">&nbsp;* {{ $t('label.required') }}</span>

                                    <!-- <input type="password" class="form-control" v-model="$v.user.pay1099.tax_id.$model" required autofocus autocomplete="input-field" data-lpignore="true"> -->

                                    <div class="input-group input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="ri-lock-password-line"></i></span>
                                        </div>
                                        <input :class="`form-control`" v-model="$v.user.pay1099.tax_id.$model" :readonly="user.id && !encrptView.ss" required autofocus autocomplete="input-field" data-lpignore="true" placeholder="Federal Tax ID or SS" :type="user.id && !encrptView.ss ? 'password' : 'text'" name="password">
                                        <div v-if="user.id" class="input-group-append" @click="userAuthModal(true, 'ss')" style="cursor: pointer;">
                                            <span class="input-group-text">
                                                <i v-if="encrptView.ss" class="ri-eye-line"></i>
                                                <i v-else class="ri-eye-close-line"></i>
                                            </span>
                                        </div>
                                    </div>

                                    <div v-if="$v.user.pay1099.tax_id.$error && !$v.user.pay1099.tax_id.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card-title">Liability Insurance</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="pay_type">Carrier Name</label>
                                    <span class="text-xs text-danger">&nbsp;* {{ $t('label.required') }}</span>
                                    <input type="text" class="form-control" v-model="$v.user.pay1099.insurance_carrier_name.$model" required autofocus autocomplete="input-field" data-lpignore="true">
                                    <div v-if="$v.user.pay1099.insurance_carrier_name.$error && !$v.user.pay1099.insurance_carrier_name.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="pay_type">Policy Number</label>

                                    <span class="text-xs text-danger">&nbsp;* {{ $t('label.required') }}</span>

                                    <!-- <input type="text" class="form-control" v-model="$v.user.pay1099.insurance_policy_number.$model" required autofocus autocomplete="input-field" data-lpignore="true"> -->
                                    <div class="input-group input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="ri-lock-password-line"></i></span>
                                        </div>
                                        <input :class="`form-control`" v-model="$v.user.pay1099.insurance_policy_number.$model" :readonly="user.id && !encrptView.li_policy_number" required autofocus autocomplete="input-field" data-lpignore="true" placeholder="Policy Number" :type="user.id && !encrptView.li_policy_number ? 'password' : 'text'" name="password">
                                        <div v-if="user.id" class="input-group-append" @click="userAuthModal(true, 'li_policy_number')" style="cursor: pointer;">
                                            <span class="input-group-text">
                                                <i v-if="encrptView.li_policy_number" class="ri-eye-line"></i>
                                                <i v-else class="ri-eye-close-line"></i>
                                            </span>
                                        </div>
                                    </div>

                                    <div v-if="$v.user.pay1099.insurance_policy_number.$error && !$v.user.pay1099.insurance_policy_number.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="pay_type">Effective Date</label>
                                    <span class="text-xs text-danger">&nbsp;* {{ $t('label.required') }}</span>
                                    <date-picker v-model="$v.user.pay1099.insurance_effective_date.$model" class="full-width" valueType="format" format="MM/DD/YYYY" input-class="form-control" placeholder="Effective Date">
                                    </date-picker>
                                    <div v-if="$v.user.pay1099.insurance_effective_date.$error && !$v.user.pay1099.insurance_effective_date.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card-title">Workers Compensation Insurance</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="pay_type">Policy Number</label>
                                    <span class="text-xs text-danger">&nbsp;* {{ $t('label.required') }}</span>
                                    <!-- <input type="text" class="form-control" v-model="$v.user.pay1099.compensation_insurance_policy_number.$model" required autofocus autocomplete="input-field" data-lpignore="true"> -->
                                    <div class="input-group input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="ri-lock-password-line"></i></span>
                                        </div>
                                        <input :class="`form-control`" v-model="$v.user.pay1099.compensation_insurance_policy_number.$model" :readonly="user.id && !encrptView.wci_policy_number" required autofocus autocomplete="input-field" data-lpignore="true" placeholder="Policy Number" :type="user.id && !encrptView.wci_policy_number ? 'password' : 'text'" name="password">
                                        <div v-if="user.id" class="input-group-append" @click="userAuthModal(true, 'wci_policy_number')" style="cursor: pointer;">
                                            <span class="input-group-text">
                                                <i v-if="encrptView.wci_policy_number" class="ri-eye-line"></i>
                                                <i v-else class="ri-eye-close-line"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div v-if="$v.user.pay1099.insurance_policy_number.$error && !$v.user.pay1099.insurance_policy_number.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="pay_type">Effective Date</label>
                                    <span class="text-xs text-danger">&nbsp;* {{ $t('label.required') }}</span>
                                    <date-picker v-model="$v.user.pay1099.compensation_insurance_effective_date.$model" class="full-width" valueType="format" format="MM/DD/YYYY" input-class="form-control" placeholder="Effective Date">
                                    </date-picker>

                                    <div v-if="$v.user.pay1099.compensation_insurance_effective_date.$error && !$v.user.pay1099.compensation_insurance_effective_date.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- User Information -->
                <div class="card">
                    <div class="card-body">
                        <div class="card-title">{{ $t('label.user information') }}</div>
                        <div class="row">
                            <!-- User Status -->
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-password-confirmation">{{ $t('label.status') }}</label>
                                    <br>
                                    <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                        <label class="btn" :class="{ 'active': user.status == 1 }">
                                            <input type="radio" name="status" id="option1" v-model="user.status" value="1"> {{ $t('label.active') }}
                                        </label>
                                        <label class="btn" :class="{ 'active': user.status == 0 }">
                                            <input type="radio" name="status" id="option2" v-model="user.status" value="0"> {{ $t('label.inactive') }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!-- Role -->
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-role">{{ $t('label.role') }}</label>
                                    <span class="text-xs text-danger">&nbsp;* {{ $t('label.required') }}</span>
                                    <select name="role_id" class="form-control" id="input-role" v-model="$v.user.role_id.$model" :placeholder="$t('label.role')" required>
                                        <option :value="null" selected>- Select a Role -</option>
                                        <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
                                    </select>

                                    <div v-if="$v.user.role_id.$error && !$v.user.role_id.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <!-- first name -->
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label">{{ $t('label.first name') }}</label>
                                    <span class="text-xs text-danger">&nbsp;* {{ $t('label.required') }}</span>

                                    <input type="text" class="form-control" name="first_name" v-model="$v.user.first_name.$model" :placeholder="$t('label.first name')" required autofocus autocomplete="input-field" data-lpignore="true">

                                    <div v-if="$v.user.first_name.$error && !$v.user.first_name.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>

                                </div>
                            </div>
                            <!-- last name -->
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label">{{ $t('label.last name') }}</label>
                                    <span class="text-xs text-danger">&nbsp;* {{ $t('label.required') }}</span>

                                    <input type="text" class="form-control" name="first_name" v-model="$v.user.last_name.$model" :placeholder="$t('label.last name')" required autocomplete="input-field" data-lpignore="true">

                                    <div v-if="$v.user.last_name.$error && !$v.user.last_name.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>

                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <!-- email -->
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-email">{{ $t('label.email') }}</label>
                                    <span class="text-xs text-danger">&nbsp;* {{ $t('label.required') }}</span>

                                    <input type="email" class="form-control" name="email" id="input-email" v-model="$v.user.email.$model" :placeholder="$t('label.email')" required autocomplete="new-password" data-lpignore="true">

                                    <div v-if="$v.user.email.$error && !$v.user.email.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>

                                </div>
                            </div>
                            <!-- Phone -->
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-phone">{{ $t('label.phone') }}</label>
                                    <input type="text" class="form-control" name="phone" id="input-phone" v-model="$v.user.phone.$model" v-mask="'(###) ###-####'" :placeholder="$t('label.phone')" autocomplete="input-field" data-lpignore="true">

                                    <div v-if="$v.user.phone.$error && !$v.user.phone.required" class="text-danger text-sm">*&nbsp; must be a 10 digit number.</div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <!-- Hire Date -->
                            <div class="col-sm-6">
                                <label class="form-control-label">{{ $t('label.hire date') }}</label>
                                <date-picker v-model="user.hire_date" class="full-width" valueType="format" format="MM/DD/YYYY" input-class="form-control" :placeholder="$t('label.select date')">
                                </date-picker>
                            </div>
                            <!-- Timezone -->
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label">{{ $t('label.timezone') }}</label>
                                    <span class="text-xs text-danger">&nbsp;* {{ $t('label.required') }}</span>
                                    <div class="sp-tooltip d-block">
                                        <select name="timezone" class="form-control" v-model="$v.user.timezone.$model" :placeholder="$t('label.timezone')" :disabled="userClockedIn" required>
                                            <option v-for="(timeZone, idx) in lookupData.timezones" :key="idx" :value="timeZone.timezone">(UTC {{ timeZone.offset }}) {{ timeZone.new_name }}
                                            </option>
                                        </select>
                                        <span v-if="userClockedIn" class="sp-tooltiptext time-logged text-sm mb--3">
                                            {{ $t('label.user must be clocked out before you can change their timezone') }}.
                                        </span>

                                        <div v-if="$v.user.timezone.$error && !$v.user.timezone.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Profile Photo -->
                        <div class="form-group">
                            <label class="form-control-label">{{ $t('label.profile photo') }}</label>
                            <div class="row">
                                <div class="col-sm-8">
                                    <input type="file" class="form-control" name="photo" accept="image/png, image/jpeg" ref="fileInput" @change="onFileSelected" :placeholder="$t('label.photo')">
                                </div>
                                <div class="col-sm-4">
                                    <img class="rounded-circle" v-if="user.picture" :src="user.picture" style="width: 100px; height: 100px; object-fit: cover;" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- User Address -->
                <div class="card">
                    <div class="card-body">
                        <div class="card-title">Mailing Address</div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label class="form-control-label" for="pay_type">Search Address</label>
                                    <!-- <span class="text-xs text-danger">&nbsp;* {{ $t('label.required') }}</span> -->
                                    <input ref="search_address" type="text" class="form-control" placeholder="Type your address here..." v-model="$v.user.pay1099.search_address.$model" required autofocus autocomplete="input-field" data-lpignore="true">
                                    <div v-if="$v.user.pay1099.search_address.$error && !$v.user.pay1099.search_address.required" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
                                </div>
                            </div>
                        </div>
                        <!-- billing_address_1: this.user.billing_address_1,
                    billing_zip: this.user.billing_zip,
                    billing_state: this.user.billing_state,
                    billing_city: this.user.billing_city,
                    billing_country: this.user.billing_country -->
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="pay_type">Address / Route</label>
                                    <input disabled type="text" class="form-control" v-model="user.billing_address_1" autocomplete="input-field" data-lpignore="true">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="pay_type">City</label>
                                    <input disabled type="text" class="form-control" v-model="user.billing_city" autocomplete="input-field" data-lpignore="true">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="pay_type">State</label>
                                    <input disabled type="text" class="form-control" v-model="user.billing_state" autocomplete="input-field" data-lpignore="true">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="pay_type">Zip Code</label>
                                    <input disabled type="text" class="form-control" v-model="user.billing_zip" autocomplete="input-field" data-lpignore="true">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Passwords -->
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <!-- password -->
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-password">{{ $t('label.password') }}</label>
                                    <span class="text-xs text-danger" v-if="!selectedUser">&nbsp;* {{ $t('label.required') }}</span>
                                    <div class="input-group" v-show="!pass1Visable">
                                        <input type="password" name="password" id="input-password1" class="form-control" autocomplete="off" :readonly="selectedUser" onclick="this.removeAttribute('readOnly');" :placeholder="passwordPlaceHolder" v-model="$v.password.$model">
                                        <div class="input-group-append">
                                            <span class="input-group-text pointer" @click="pass1Visable = true"><i class="ri-eye-fill"></i></span>
                                        </div>
                                    </div>
                                    <div class="input-group" v-show="pass1Visable">
                                        <input type="text" name="password" id="input-password2" class="form-control" autocomplete="off" v-model="password">
                                        <div class="input-group-append">
                                            <span class="input-group-text pointer" @click="pass1Visable = false"><i class="ri-eye-off-fill"></i></span>
                                        </div>
                                    </div>
                                    <div v-if="($v.password.$error && !$v.password.required)" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
                                </div>
                            </div>
                            <!-- confirm password -->
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-password-confirmation">{{ $t('label.confirm password') }}</label>
                                    <span class="text-xs text-danger" v-if="!selectedUser">&nbsp;* {{ $t('label.required') }}</span>
                                    <div class="input-group" v-show="!pass2Visable">
                                        <input type="password" name="password" id="input-password-confirmation" class="form-control" autocomplete="off" :readonly="selectedUser" onclick="this.removeAttribute('readOnly');" :placeholder="passwordPlaceHolder" v-model="$v.passwordConfirm.$model">
                                        <div class="input-group-append">
                                            <span class="input-group-text pointer" @click="pass2Visable = true"><i class="ri-eye-fill"></i></span>
                                        </div>
                                    </div>
                                    <div class="input-group" v-show="pass2Visable">
                                        <input type="text" name="password" id="input-password-confirmation2" class="form-control" autocomplete="off" v-model="passwordConfirm">
                                        <div class="input-group-append">
                                            <span class="input-group-text pointer" @click="pass2Visable = false"><i class="ri-eye-off-fill"></i></span>
                                        </div>
                                    </div>
                                    <div class="text-danger" v-if="passwordConfirmError">* {{ passwordConfirmError }}</div>
                                    <div v-if="($v.passwordConfirm.$error && !$v.passwordConfirm.required)" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Quickbooks Sync -->
                <div class="card" v-if="lookupData.companyData.sync_to_qb">
                    <div class="card-body">
                        <div class="card-title">{{ $t('label.link user to quickbooks') }}</div>
                        <div class="form-group">
                            <div class="row m-0 d-flex align-items-center mb-3">
                                <label class="form-control-label mb-0 mr-3" for="input-qb-employee">{{ $t('label.quickbooks employee') }}</label>
                                <a v-if="lookupData.authUrl" href="#" @click="quickbooksLogin" class="btn btn-sm btn-primary">
                                    {{ $t('label.get quickbooks employees') }}
                                </a>
                            </div>

                            <!-- quickbooks employees -->
                            <select name="qb_id" id="input-qb-employee" class="form-control" v-model="user.qb_id" :placeholder="$t('label.role')">
                                <option value="">-</option>
                                <option v-for="qbEmp in lookupData.qbEmployees" :key="qbEmp.Id" :value="qbEmp.Id">{{ qbEmp.DisplayName }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Footer -->
            <div class="modal-footer border-top">
                <button type="button" class="btn btn-primary mr-auto" @click="saveItem">{{ $t('label.save') }}</button>
                <button type="button" class="btn btn-link ml-auto" @click="$emit('cancelFnc')">{{ $t('label.cancel') }}</button>
            </div>
        </div>

        <modal name="userAuthModal" height="auto" width="98%" :max-width="800" :reset="true" :scrollable="true" :adaptive="true">
            <div class="modal-content">
                <div class="modal-header border-bottom">
                    <h5 class="modal-title">Authentication Check</h5>
                    <button type="button" class="close" @click="userAuthModal(false)" aria-label="Close">
                        <i class="ri-close-line"></i>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label class="form-control-label" for="pay_type">Email Address</label>
                                <span class="text-xs text-danger">&nbsp;* {{ $t('label.required') }}</span>
                                <input type="text" class="form-control" readonly v-model="loginData.email" required autofocus autocomplete="input-field" data-lpignore="true">
                                <div v-if="false" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label class="form-control-label" for="pay_type">Password</label>
                                <span class="text-xs text-danger">&nbsp;* {{ $t('label.required') }}</span>
                                <div class="input-group input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="ri-lock-password-line"></i></span>
                                    </div>
                                    <input @keyup.enter="checkLoginAndReturnDecryptedAtribbute" :class="`form-control`" v-model="loginData.password" required autofocus autocomplete="input-field" data-lpignore="true" placeholder="Password" :type="loginData.showPass ? 'text' : 'password'" name="password">
                                    <div class="input-group-append" @click="loginData.showPass = !loginData.showPass" style="cursor: pointer;">
                                        <span class="input-group-text">
                                            <i v-if="loginData.showPass" class="ri-eye-line"></i>
                                            <i v-else class="ri-eye-close-line"></i>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="false" class="text-danger text-sm">*&nbsp;{{ $t('label.field required') }}.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-top">
                    <button type="button" class="btn btn-primary mr-auto" @click="checkLoginAndReturnDecryptedAtribbute">{{ loginData.is_Loading ? 'Loading...' : 'Authenticate' }}</button>
                    <button type="button" class="btn btn-link ml-auto" @click="userAuthModal(false)">{{ $t('label.cancel') }}</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import AuthService from '@/services/AuthService';
import bus from '@/utils/event-bus';
import { getDomain } from '@/utils/helpers';
import { minLength, required, requiredIf } from 'vuelidate/lib/validators';
import { mapGetters, mapState } from 'vuex';

export default {
    props: ['selectedUser', 'roles'],
    emits: ['cancelFnc', 'returnFnc'],
    components: {},
    data() {
        return {
            googleAutocompletePlaces: null,
            currentActiveUser: JSON.parse(localStorage.getItem('user')),
            encrptView: {
                ss: false,
                li_policy_number: false,
                wci_policy_number: false,
            },
            loginData: {
                is_Loading: false,
                selected_column: null,
                showPass: false,
                email: '',
                password: 'password'
            },
            user: {
                status: 1,
                qb_id: null,
                picture: null,
                pay_rate: '0',
                pay_type: 'Hourly',
                timezone: 'America/Denver',
                hire_date: moment().format('MM/DD/YYYY'),
                first_name: null,
                last_name: null,
                phone: '',
                email: null,
                role_id: null,
                pay1099: {
                    company_name: '',
                    mail_address_1: '',
                    mail_address_2: '',
                    mail_city: '',
                    mail_state: '',
                    mail_zip: '',
                    tax_id: '',
                    insurance_carrier_name: '',
                    insurance_policy_number: '',
                    insurance_effective_date: '',
                    compensation_insurance_policy_number: '',
                    compensation_insurance_effective_date: ''
                }

            },
            password: null,
            profileImg: null,
            userClockedIn: null,
            passwordConfirm: null,
            passwordConfirmError: null,
            passwordPlaceHolder: '',
            pass1Visable: false,
            pass2Visable: false,
            payRateIsDisabled: false,
            lookupData: { companyData: { sync_to_qb: 0 }, timezones: [], qbEmployees: [], payTypes: [] },
            is1099: false,
        }
    },
    validations: {
        user: {
            first_name: { required },
            last_name: { required },
            email: { required },
            phone: { minLength: minLength(14) },
            role_id: { required },
            timezone: { required },

            pay1099: {
                mail_address_2: {},
                search_address: {
                    required: true
                },
                company_name: {
                    required: requiredIf(function () {
                        if (this.user.pay_type == '1099') {
                            return true;
                        } else {
                            return false;
                        }
                    })
                },
                // mail_address_1: {
                //     required: requiredIf(function () {
                //         if (this.user.pay_type == '1099') {
                //             return true;
                //         } else {
                //             return false;
                //         }
                //     })
                // },
                // mail_city: {
                //     required: requiredIf(function () {
                //         if (this.user.pay_type == '1099') {
                //             return true;
                //         } else {
                //             return false;
                //         }
                //     })
                // },
                // mail_state: {
                //     required: requiredIf(function () {
                //         if (this.user.pay_type == '1099') {
                //             return true;
                //         } else {
                //             return false;
                //         }
                //     })
                // },
                // mail_zip: {
                //     required: requiredIf(function () {
                //         if (this.user.pay_type == '1099') {
                //             return true;
                //         } else {
                //             return false;
                //         }
                //     })
                // },
                tax_id: {
                    required: requiredIf(function () {
                        if (this.user.pay_type == '1099') {
                            return true;
                        } else {
                            return false;
                        }
                    })
                },
                insurance_carrier_name: {
                    required: requiredIf(function () {
                        if (this.user.pay_type == '1099') {
                            return true;
                        } else {
                            return false;
                        }
                    })
                },
                insurance_policy_number: {
                    required: requiredIf(function () {
                        if (this.user.pay_type == '1099') {
                            return true;
                        } else {
                            return false;
                        }
                    })
                },
                insurance_effective_date: {
                    required: requiredIf(function () {
                        if (this.user.pay_type == '1099') {
                            return true;
                        } else {
                            return false;
                        }
                    })
                },
                compensation_insurance_policy_number: {
                    required: requiredIf(function () {
                        if (this.user.pay_type == '1099') {
                            return true;
                        } else {
                            return false;
                        }
                    })
                },
                compensation_insurance_effective_date: {
                    required: requiredIf(function () {
                        if (this.user.pay_type == '1099') {
                            return true;
                        } else {
                            return false;
                        }
                    })
                },
            }
        },
        password: { required: requiredIf(function () { return this.selectedUser ? false : true; }) },
        passwordConfirm: { required: requiredIf(function () { return this.selectedUser ? false : true; }) },
    },
    watch: {
        userClockin() {
            this.setUserClockedIn();
        }
    },
    computed: {
        ...mapState({
            currentSubscription: state => state.Plan.currentSubscription
        }),
        ...mapGetters({
            userClockin: 'time/userClockin',
        })
    },
    created() {
        this.$store.dispatch('time/getUserCurrentClockin');
    },
    mounted() {
        this.loadLookupData();
        this.initializeGooglePlaces();
        if (this.selectedUser) {
            this.setUserClockedIn(true);
            this.passwordPlaceHolder = '******';
            this.user = JSON.parse(JSON.stringify(this.selectedUser));
            this.user.payRate = this.user.pay_rate;
            this.user.hire_date = moment(this.user.hire_date).format('MM/DD/YYYY');
        }
    },
    methods: {
        initializeGooglePlaces() {
            if (!this.$refs.search_address) return;
            this.googleAutocompletePlaces = new google.maps.places.Autocomplete(this.$refs.search_address, {
                componentRestrictions: { country: ["us"] },
                fields: ["address_components", "geometry"],
                types: ["address"],
            });

            let address = {
                billing_address_1: '',
                billing_zip: '',
                billing_state: '',
                billing_city: '',
                billing_country: ''
            };
            this.googleAutocompletePlaces.addListener("place_changed", () => {
                Object.keys(address).forEach(key => address[key] = '');
                const place = this.googleAutocompletePlaces.getPlace();
                const reversedArray = place.address_components.reverse();
                for (const component of place.address_components) {
                    const componentType = component.types[0];
                    switch (componentType) {
                        case "street_number": {
                            address.billing_address_1 = `${component.long_name} ${address.billing_address_1}`;
                            break;
                        }
                        case "route": {
                            address.billing_address_1 += component.short_name;
                            break;
                        }
                        case "postal_code": {
                            address.billing_zip = `${component.long_name}${address.billing_zip}`;
                            break;
                        }
                        case "postal_code_suffix": {
                            address.billing_zip = `${address.billing_zip}-${component.long_name}`;
                            break;
                        }
                        case "locality":
                            address.billing_city = component.long_name;
                            break;
                        case "administrative_area_level_1": {
                            address.billing_state = component.short_name;
                            break;
                        }
                        case "country":
                            address.billing_country = component.long_name;
                            break;
                    }
                }

                Object.keys(address).forEach(key => this.$set(this.user, key, address[key]));
                console.log(this.user);
            })
        },
        userAuthModal(action, column = null) {
            this.loginData.selected_column = column;
            this.loginData.email = this.currentActiveUser.email;
            this.loginData.password = '';

            if (action && this.encrptView[this.loginData.selected_column] == false) {
                this.$modal.show('userAuthModal')
            } else {
                this.encrptView[this.loginData.selected_column] = false;
                this.$modal.hide('userAuthModal')
            }
        },
        authenticateUser() {
            this.loginData.is_Loading = true;
            this.$store.dispatch('Auth/login', {
                data: {
                    email: this.loginData.email,
                    password: this.loginData.password
                },
            })
                .then(res => {
                    if (this.loginData.selected_column) {
                        this.encrptView[this.loginData.selected_column] = true;
                    }
                    this.userAuthModal(false);
                })
                .catch(err => {
                    this.$bus.$emit('banner-error', { message: err.data.message })
                    console.log(err)
                }).finally(() => {
                    this.loginData.is_Loading = false;
                });
        },
        checkLoginAndReturnDecryptedAtribbute() {
            this.loginData.is_Loading = true;
            const payload = {
                'password': this.loginData.password,
                'email': this.loginData.email,
                'key': 'pay_1099__' + this.loginData.selected_column,
                'for_id': this.user.pay1099.id,
            };
            AuthService.checkLoginAndReturnDecryptedAtribbute(payload)
                .then(res => {
                    if (res.data.data) {
                        const response = res.data.data;
                        if (this.loginData.selected_column) {
                            this.encrptView[this.loginData.selected_column] = true;
                            if (response.key == "pay_1099__ss") this.user.pay1099.tax_id = response.value
                            if (response.key == "pay_1099__li_policy_number") this.user.pay1099.insurance_policy_number = response.value
                            if (response.key == "pay_1099__wci_policy_number") this.user.pay1099.compensation_insurance_policy_number = response.value
                        }
                    }
                    this.userAuthModal(false);
                })
                .catch(err => {
                    this.$bus.$emit('banner-error', { message: err.data.message })
                }).finally(() => {
                    this.loginData.is_Loading = false;
                });
        },
        loadLookupData() {
            this.loader = this.$loading.show()

            axios.get('/loadUserLookupData').then(response => {
                this.loader.hide();
                this.lookupData.companyData = response.data.companyData;
                this.lookupData.timezones = response.data.timezones;
                this.lookupData.qbEmployees = response.data.qbEmployees;
                this.lookupData.authUrl = response.data.authUrl
                // Skip salary as an option for now
                response.data.payTypes.forEach(item => {
                    if (item != 'Salary') {
                        this.lookupData.payTypes.push(item)
                    }
                })
            }).catch(err => {
                console.log('ERROR: ', err)
                bus.$emit('banner-error', { message: err, })
            });
        },
        setUserClockedIn(init) {
            // If user being edited is also the current active user, use
            // vuex store date to enable synchronization between windows.
            let currUser = JSON.parse(localStorage.getItem('user'));

            if (currUser.id == this.selectedUser.id)
                this.userClockedIn = this.userClockin;
            else if (init)
                this.getUserClockInStatus();
        },
        getUserClockInStatus() {
            const subDomain = getDomain().subDomain;

            if (subDomain != 'admin') {
                this.selectedUser && axios.get('/get-user-current-clockin/' + this.selectedUser.id).then(res => {
                    this.userClockedIn = res.data.result;
                });
            }
        },
        saveItem() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.passwordConfirmError = null
                let saveData = {
                    first_name: this.user.first_name,
                    last_name: this.user.last_name,
                    name: this.user.first_name + ' ' + this.user.last_name,
                    email: this.user.email,
                    phone: this.user.phone,
                    role_id: this.user.role_id,
                    timezone: this.user.timezone,
                    qb_id: this.user.qb_id,
                    status: this.user.status,
                    pay_type: this.user.pay_type,
                    pay_rate: this.user.payRate,
                    hire_date: moment(this.user.hire_date).format('YYYY-MM-DD'),
                    billing_address_1: this.user.billing_address_1,
                    billing_zip: this.user.billing_zip,
                    billing_state: this.user.billing_state,
                    billing_city: this.user.billing_city,
                    billing_country: this.user.billing_country
                }

                let api = '/userAdd',
                    successMessage = 'Created';

                // Update User
                if (this.user.id) {
                    api = '/userUpdate';
                    saveData.id = this.user.id;
                    successMessage = 'Updated';

                    // Password change optional
                    if (this.password) {
                        if (this.password !== this.passwordConfirm) {
                            this.passwordConfirmError = "Passwords do not match."
                            bus.$emit('banner-error', { message: this.passwordConfirmError, })
                            return
                        }

                        saveData.password = this.password;
                    }
                } else { // Create user      
                    saveData.password = this.password;
                    saveData.password_confirmation = this.passwordConfirm;
                }

                let formData = new FormData()
                for (let key in saveData) {
                    formData.append(key, saveData[key])
                }

                if (this.user.pay_type == '1099') {
                    for (let key in this.user.pay1099) {
                        formData.append(`pay1099[${key}]`, this.user.pay1099[key]);
                    }
                }


                // for (var pair of formData.entries()) {
                //     console.log(pair[0] + ', ' + pair[1]);
                // }

                // File upload
                if (this.profileImg)
                    formData.append('photo', this.profileImg);


                if (!this.selectedUser && this.currentSubscription.active_users >= this.currentSubscription.total_users) {
                    this.$bus.$emit('banner-error', { message: "You've reached the maximum number of Users allowed on your current plan. Please purchase an additional users package or upgrade your Subscription Package." })
                    return;
                } else {
                    let loading = this.$loading.show();
                    axios({ url: api, data: formData, method: 'post', headers: { "Content-Type": "multipart/form-data" }, }).then(response => {
                        this.$bus.$emit('banner-success', {
                            message: `User ${successMessage} Successfully!`,
                        })

                        // Update current user local storage
                        if (this.user.id && (this.user.id == this.currentActiveUser.id)) {
                            if (response.data.data)
                                localStorage.setItem('user', JSON.stringify(response.data.data));

                            this.$bus.$emit("adminUsersUpdateCurrUser");
                        }

                        this.$store.dispatch('Plan/getCurrentPlan')
                        this.$emit('returnFnc', response.data)
                        loading.hide();
                    }).catch(err => {
                        console.log('error', err);
                        loading.hide();
                        this.$bus.$emit('banner-error', { message: err.data.message })
                    }).finally(() => {
                        loading.hide();
                    });
                }
            } else {
                bus.$emit('banner-error', {
                    message: this.$t('label.please validate required fields before proceeding') + '.'
                });
            }


        },
        quickbooksLogin() {
            // Launch Popup
            let win = window.open(this.lookupData.authUrl, 'connectPopup'),
                pollOAuth = window.setInterval(function () {
                    try {
                        if (win.document.URL.indexOf("code") != -1) {
                            window.clearInterval(pollOAuth);
                            win.close();
                            location.reload();
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }, 100);
        },
        onFileSelected(event) {
            const files = event.target.files;
            const fileReader = new FileReader();

            fileReader.addEventListener('load', () => {
                this.user.picture = fileReader.result
            });
            fileReader.readAsDataURL(files[0]);
            this.profileImg = files[0];
        },
        checkPayRateDisabled() {
            if (this.user.pay_type == '1099') {
                this.user.payRate = 0;
                this.payRateIsDisabled = true;
            } else {
                this.payRateIsDisabled = false;
            }
        },
    }
}
</script>

<style scoped>
.time-logged {
    width: 200px;
    white-space: normal;
}
</style>