<template>
    <div>
        <div class="header bg-primary sp-comp-banner"></div>

        <div class="container-fluid pb-5 sp-mobile-mt-50" id="timesheetSettingsContainer">
            <!-- Page Header -->
            <div class="d-flex" style="z-index: 100">
                <!-- Breadcrumbs -->
                <breadcrumbs :title="$t('label.notification settings')">
                    <!-- Breakcrumb slots -->
                    <li class="breadcrumb-item">
                        {{ $t('label.admin') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.settings') }}
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('label.notifications') }}
                    </li>
                </breadcrumbs>
            </div>

            <div class="card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h3 class="mb-0">{{ $t('label.notification settings') }}</h3>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <!-- Clock in reminder -->
                    <div class="p-0">
                        <h3>{{ $t('label.clock in reminder') }}</h3>
                        <p class="py-2">{{ $t('label.if employees are not clocked in by the time below they will receive a notification reminder to clock in this setting can be overridden on each employees profile') }}</p>
                        <div class="row">
                            <!-- Clock in time reminder threshold -->
                            <div class="col-lg-5 col-xs-6 col-sm-7">
                                <div class="row align-items-center">
                                    <div class="col-3">
                                        <h4>{{ $t('label.hour') }}</h4>
                                        <select name="clock_in_hour" v-model="form.clock_in_hour" class="form-control col-12 col-sm-12 p-xs-0" required>
                                            <option v-for="(data, index) in hours" :value="data" :key="index">{{ data }}</option>
                                        </select>
                                    </div>
                                    <div class="col-3">
                                        <h4>{{ $t('label.minute') }}</h4>
                                        <select name="clock_in_minute" v-model="form.clock_in_minute" class="form-control col-12 col-sm-12 p-xs-0" required>
                                            <option v-for="(data, index) in minutes" :value="data" :key="index">{{ data == 0 ? data + '0' : data }}</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <div class="row m-0 py-1">
                                            <div class="custom-control custom-radio mr-3">
                                                <input name="clock_in_period" class="custom-control-input" value="1" id="am" type="radio" v-model="form.clock_in_period">
                                                <label class="custom-control-label" for="am">AM</label>
                                            </div>
                                            <div class="custom-control custom-radio">
                                                <input name="clock_in_period" class="custom-control-input" value="0" id="pm" type="radio" v-model="form.clock_in_period">
                                                <label class="custom-control-label" for="pm">PM</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Include Weekend Days -->
                            <div class="col-lg-7 col-xs-6 col-sm-5">
                                <h4 class="mb-0">{{ $t('label.include weekends') }}</h4>
                                <div class="row m-0 py-1">
                                    <div class="custom-control custom-checkbox mr-3">
                                        <input name="include_weekends[]" class="custom-control-input" value="1" id="saturday" type="checkbox" v-model="form.include_weekends">
                                        <label class="custom-control-label" for="saturday">{{ $t('label.saturday') }}</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input name="include_weekends[]" class="custom-control-input" value="2" id="sunday" type="checkbox" v-model="form.include_weekends">
                                        <label class="custom-control-label" for="sunday">{{ $t('label.sunday') }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>

                    <!-- Clock out reminder -->
                    <div class="p-0">
                        <h3 class="mb-0">{{ $t('label.clock out reminder') }}</h3>
                        <div class="form-group">
                            <p class="d-flex align-items-center mb-0">
                                <!-- TODO: This phrase needs to be translation friendly -->
                                Send reminder to users to clock out if they are currently clocked in after
                                <input type="text" v-model="form.clock_out_reminder" name="clock_out_reminder" id="input-clock-reminder" class="form-control mx-2" required autofocus autocomplete="input-field" data-lpignore="true" style="text-align: center; width: 50px;">
                                hours of clocked time for the day.
                            </p>
                        </div>
                    </div>
                    <hr>

                    <!-- SMS Reminders -->
                    <div class="p-0">
                        <h3 class="mb-0">{{ $t('label.sms reminders') }}</h3>
                        <div class="form-group">
                            <p class="mb-0">{{ $t('label.allow employees to receive text sms reminders to clock in and out') }}</p>
                            <div class="row m-0 py-1">
                                <div class="custom-control custom-radio mr-3">
                                    <input name="text_reminder" class="custom-control-input" id="text_reminder_1" value="1" type="radio" v-model="form.text_reminder">
                                    <label class="custom-control-label" for="text_reminder_1">{{ $t('label.yes') }}</label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input name="text_reminder" class="custom-control-input" id="text_reminder_2" value="0" type="radio" v-model="form.text_reminder">
                                    <label class="custom-control-label" for="text_reminder_2">{{ $t('label.no') }}</label>
                                </div>
                            </div>
                            <p class="mt-3 mb-0">{{ $t('label.roles that can opt in to text sms reminders to clock in and out') }}</p>
                            <div class="row m-0 py-1">
                                <div class="custom-control custom-checkbox mr-3">
                                    <input name="text_reminder_opt_in_roles[]" class="custom-control-input text_reminder_opt_in_roles" value="2" id="admin" type="checkbox" v-model="form.text_reminder_opt_in_roles">
                                    <label class="custom-control-label" for="admin">{{ $t('label.admin') }}</label>
                                </div>
                                <div class="custom-control custom-checkbox mr-3">
                                    <input name="text_reminder_opt_in_roles[]" class="custom-control-input text_reminder_opt_in_roles" value="3" id="foreman" type="checkbox" v-model="form.text_reminder_opt_in_roles">
                                    <label class="custom-control-label" for="foreman">{{ $t('label.foreman') }}</label>
                                </div>
                                <div class="custom-control custom-checkbox mr-3">
                                    <input name="text_reminder_opt_in_roles[]" class="custom-control-input text_reminder_opt_in_roles" value="4" id="laborer" type="checkbox" v-model="form.text_reminder_opt_in_roles">
                                    <label class="custom-control-label" for="laborer">{{ $t('label.laborer') }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>

                    <!-- Task Notifications -->
                    <div class="p-0">
                        <h3 class="mb-0">{{ $t('label.task notifications') }}</h3>
                        <div class="form-group">
                            <p class="mb-0">{{ $t('label.select which notification gets sent to users when a task is assigned') }}</p>
                            <div class="row m-0 py-1">
                                <div class="custom-control custom-radio mr-3">
                                    <input name="task_notifications" class="custom-control-input" id="task_notifications_1" value="1" type="radio" v-model="form.task_notifications">
                                    <label class="custom-control-label" for="task_notifications_1">{{ $t('label.every assigned task') }}</label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input name="task_notifications" class="custom-control-input" id="task_notifications_2" value="0" type="radio" v-model="form.task_notifications">
                                    <label class="custom-control-label" for="task_notifications_2">{{ $t('label.only tasks assigned on the day of the task start date') }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Save Footer -->
            <div class="text-center">
                <button type="button" @click="saveSettings()" class="btn btn-primary">{{ $t('label.save settings') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import bus from '@/utils/event-bus';
import { mapGetters } from 'vuex';

export default {
    props: [],
    components: {},
    data() {
        return {
            loader: null,
            hours: new Array(12).fill(1).map((bin, idx) => idx + 1),
            minutes: ['0', '15', '30', '40'],
            form: {
                text_reminder: null,
                clock_in_hour: null,
                clock_in_minute: null,
                clock_in_period: null,
                include_weekends: null,
                clock_out_reminder: null,
                task_notifications: null,
                text_reminder_opt_in_roles: null
            }
        }
    },
    computed: {
        ...mapGetters(['company']),
    },
    created() {
        this.loader = this.$loading.show();
        this.$store.dispatch('getCompanySettings').then(() => {
            this.initForm();
            this.loader.hide();
        });
    },
    mounted() { },
    methods: {
        initForm() {
            // Set form values
            for (const key in this.company.settings)
                if (key in this.form)
                    this.form[key] = this.company.settings[key];
        },

        saveSettings() {
            let notifSettings = JSON.parse(JSON.stringify(this.form));
            notifSettings.company_id = this.company.id;

            axios.post('/settings', notifSettings).then(response => {
                bus.$emit('banner-success', {
                    message: $t('label.company settings saved successfully')
                });
            }).catch(err => console.log(err));
        }
    }
}
</script>