<template>
	<div class="col-xl-12 order-xl-1">
		<div class="card">
			<div class="card-header">
				<div class="row align-items-center justify-content-between">
					<div>
						<h3 class="mb-0 d-flex"><i class="ri-stack-line"></i> {{ $t('label.customer jobs') }}</h3>
					</div>
					<div>
						<div class="dropdown">
							<!-- Add job button -->
							<button type="button" class="btn btn-sm btn-default" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								{{ $t('label.add job') }}
							</button>
							<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" style="min-width: 300px;">
								<a class="dropdown-item" href="#" @click="$modal.show('customersAddJobModal')">
									<i class="ri-file-3-line"></i> {{ $t('label.empty job') }}
								</a>
								<div class="dropdown-divider"></div>
								<h4 class="m-0 px-3">{{ $t('label.job templates') }}</h4>
								<div class="row align-items-center m-0 templates cursor-pointer2 flex-nowrap" v-for="(data, index) in alljobtemplates" :value="data.id" :key="index">
									<a class="dropdown-item col" href="#" data-toggle="modal" data-target="#useTemplateModal" @click="useTemplate(data)">
										<i class="ri-pages-line"></i> {{ data.job_name }}
									</a>
									<div class="dropdown-submenu p-0 col-auto" style="z-index: 90000" :id="'template' + index">
										<a class="text-light test" href="#" role="button">
											<i class="fas fa-ellipsis-h btn-sm px-4 py-2"></i>
										</a>
										<div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow">
											<button class="dropdown-item pointer" @click="editExistingJobTemplate(data)">
												<i class="ri-pencil-line"></i> {{ $t('label.edit template') }}
											</button>
											<button class="dropdown-item text-danger pointer" type="button" @click="openDeleteTempModal(data)">
												<i class="ri-delete-bin-line"></i> {{ $t('label.delete template') }}
											</button>
										</div>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<button class="dropdown-item pointer" type="button" @click="createNewJobTemplate()">
									<i class="ri-file-add-line"></i> {{ $t('label.new template') }}
								</button>
								<!-- <router-link to="/vue/jobTemplates"> -->
								<router-link :to="{ name: 'jobTemplates' }">
									<a class="dropdown-item text-dark">
										<i class="ri-settings-3-line"></i>&nbsp;{{ $t('label.manage templates') }}
									</a>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body pb-4">
				<div class="btn-group btn-group-light rounded row m-0" role="group" aria-label="Basic example">
					<button v-for="(data, index) in alljobstatus" :key="index" type="button" class="btn btn-secondary text-primary" :class="{ active: data.id == current }" @click="current = data.id; getJobs(data.id);">
						{{ data.name }}
					</button>
					<button type="button" class="btn btn-secondary text-primary" :class="{ active: 0 == current }" @click="current = 0; getJobs(0);">
						{{ $t('label.all jobs') }}
					</button>
				</div>
			</div>

			<div class="table-responsive" id="jobs-header-table">
				<v-server-table class="table-sm" ref="vueservertable" url="/get-jobs" :columns="columns" :options="options">
					<template slot="job_number.job_number" slot-scope="props">
						<span>{{ props.row.job_number.job_number }}</span>
					</template>
					<template slot="job_name" slot-scope="props">
						<router-link :to="{ name: 'jobEdit', params: { id: props.row.job_number.job_number } }">
							{{ props.row.job_name }}
						</router-link>
					</template>
					<template slot="job_status.name" slot-scope="props">
						<span class="badge badge-dot">
							<i class="bg-success"></i>
							{{ props.row.job_status ? props.row.job_status.name : '' }}
						</span>
					</template>
					<template v-if="props.row.job_manager" slot="job_manager.name" slot-scope="props">
						<div class="cursor-pointer" @click="getUserInfo(props.row.job_manager.id, 'Job Manager')">
							<img class="rounded-circle" v-if="props.row.job_manager.picture" :src="props.row.job_manager.picture" alt="" style="width: 36px; height: 36px; object-fit: cover;">
							{{ props.row.job_manager ? props.row.job_manager.name : '' }}
						</div>
					</template>
					<template slot="start_date" slot-scope="props">
						{{ props.row.start_date | formatDate }}
					</template>
					<template slot="end_date" slot-scope="props">
						{{ props.row.end_date | formatDate }}
					</template>
					<template slot="actions" slot-scope="props">
						<div class="dropdown">
							<a class="btn btn-sm btn-icon-only text-light" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<i class="fas fa-ellipsis-v"></i>
							</a>
							<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
								<a @click="getJobId(props.row)" data-toggle="modal" class="dropdown-item d-flex align-items-center">
									<i class="ri-pencil-line"></i>{{ $t('label.edit job') }}
								</a>
							</div>
						</div>
					</template>
				</v-server-table>
			</div>
		</div>

		<!-- Confirm Template Usage -->
		<div class="modal fade" id="useTemplateModal" tabindex="-1" role="dialog" aria-labelledby="useTemplateModal" aria-hidden="true">
			<div class="modal-dialog modal-primary modal-dialog-centered modal-" role="document">
				<div class="modal-content bg-gradient-primary">
					<div class="modal-header">
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<i class="ri-close-line text-white"></i>
						</button>
					</div>
					<div class="modal-body">
						<div class="py-3 text-center">
							<i class="ri-todo-line ni-3x"></i>
							<h4 class="heading mt-4">{{ $t('label.ARE YOU SURE YOU WANT TO USE THIS TEMPLATE') }}?</h4>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-white" data-dismiss="modal" @click="createJobFromTemplate()">{{ $t('label.yes') }}</button>
						<button type="button" class="btn btn-link text-white ml-auto" data-dismiss="modal">{{ $t('label.close') }}</button>
					</div>
				</div>
			</div>
		</div>

		<!-- MODAL FOR DELETE -->
		<modal-delete-component v-if="showJobTempDlt" :new-id="'Job Template'" :title="$t('label.ARE YOU SURE YOU WANT TO DELETE THIS JOB TEMPLATE')" @onClose="closeDeleteTempModal" @customDeleteMethod="deleteJobTemplate">
		</modal-delete-component>

		<!-- Add Job Modal -->
		<modal class="pb-6" name="customersAddJobModal" height="auto" width="98%" :max-width="1024" :scrollable="true" :reset="true" :adaptive="true">
			<job-add-component :customerid="customerid" :getjobs="getJobs" @closeAddJobModal="$modal.hide('customersAddJobModal')" @returnAddJobModal="receiveNewJob"></job-add-component>
		</modal>

		<!-- Job Edit Modal -->
		<modal-job-edit v-if="showEditJobModal" :jobdata="jobdata" :key="jobdata.id" source="customer"></modal-job-edit>
	</div>
</template>
<style>
.VueTables.VueTables--client>.row:first-child {
	display: none;
}

.dropdown-toggle::after {
	content: none;
}

.dropdown-toggle {
	background-color: transparent;
	border-color: transparent;
	color: #ced4da !important;
	padding: 0;
}

.table-bordered th,
.table-bordered td {
	background-color: #fff;
	border: none;
	border-bottom: 1px solid #e9ecef
}

.table-bordered thead th,
.table-bordered thead td {
	color: #8898aa;
	background-color: #f6f9fc;
}

.VuePagination {
	height: 75px;
	margin: 0;
}

.VuePagination__count {
	position: absolute;
	left: 0;
	top: 25%;
	text-align: left !important;
	font-family: Open Sans, sans-serif;
	font-size: .875rem;
}

li.VuePagination__pagination-item.page-item a.page-link {
	z-index: 2000;
}

#jobs-header-table {
	overflow-x: hidden;
}
</style>
<script>
import ModalDeleteComponent from "@/components/ModalDelete2.vue";
import bus from '@/utils/event-bus';

export default {
	props: ['customerid'],
	components: {
		ModalDeleteComponent
	},
	data() {
		return {
			alljobstatus: [],
			alljobs: [],
			form: {
				add: {
					notes: null
				},
				edit: {
					notes: null,
					id: null
				}
			},
			columns: ['job_number.job_number', 'job_name', 'job_manager.name', 'job_status.name', 'start_date', 'end_date', 'actions'],
			options: {
				perPage: 5,
				perPageValues: [5],
				headings: {
					'job_number.job_number': this.$t('label.job id'),
					job_name: 'Job Name',
					'job_manager.name': this.$t('label.job manager'),
					'job_status.name': this.$t('label.job status'),
					start_date: this.$t('label.start date'),
					end_date: this.$t('label.end date'),
					actions: ''
				},
				editableColumns: ['job_number.job_number', 'job_name', 'job_manager.name', 'job_status.name', 'start_date', 'end_date'],
				sortable: ['job_number.job_number', 'job_name', 'job_manager.name', 'job_status.name', 'start_date', 'end_date'],
				filterable: false,
				columnsClasses: {
					'job_number.job_number': 'text-left',
					job_name: 'text-left',
					'job_manager.name': 'text-left',
					'job_status.name': 'text-left',
					start_date: 'text-left',
					end_date: 'text-left',
					actions: 'text-center actions-column'
				},
				responseAdapter({ data }) {
					this.alljobs = data.result;
					return {
						data: data.result2,
						count: data.result.length
					}
				},
				requestAdapter(data) {

				},
			},
			current: null,
			alljobtemplates: [],
			jobdata: {},
			showEditJobModal: false,
			showJobTempDlt: false,
		}
	},
	created() {
		this.getJobStatus();

		bus.$on('getJobStatus', () => {
			this.showEditJobModal = false;
			this.getJobStatus();
		});
	},
	mounted() {
		this.getAllJobTemplates(true);
	},
	methods: {
		getAllJobTemplates(init) {
			let loading = this.$loading.show();

			axios.get('/get-active-job-templates').then(response => {
				this.alljobtemplates = response.data.result;
				loading.hide()

				if (init) {
					$(".templates").ready(function () {
						$('.dropdown-submenu a.test').on("click", function (e) {
							$('.dropdown-submenu a.test').next('div.dropdown-menu').slideUp("fast");
							$(this).next('div.dropdown-menu').toggle();
							e.stopPropagation();
							e.preventDefault();
						});
					});

					$(document).on("click", function (e) {
						$('.dropdown-submenu a.test').next('div.dropdown-menu').slideUp("fast");
					});
				}
			}).catch(err => {
				//console.log('list carriers error:', error)
			});
		},
		useTemplate(data) {
			this.templateData = data;
		},
		createJobFromTemplate() {
			var request_fields = {
				'id': this.templateData.job_id,
				'job_name': this.templateData.job_name
			};

			axios.post('/create-job-from-template', request_fields)
				.then(response => {
					this.editExistingJob(response.data.id);
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		createNewJobTemplate() {
			axios.post("/create-job-template").then(response => {
				this.editExistingJobTemplate(response.data.result);
			}).catch(err => console.log(err));
		},
		editExistingJobTemplate(template) {
			this.$router.push({
				name: 'jobTemplateEdit',
				params: { id: template.id }
			});
		},
		openDeleteTempModal(data) {
			this.templateData = data;
			this.showJobTempDlt = true;
		},
		closeDeleteTempModal() {
			this.showJobTempDlt = false;
		},
		deleteJobTemplate() {
			axios.post('/delete-job-template', {
				'id': this.templateData.job_id,
			}).then(response => {
				this.getAllJobTemplates();
			}).catch(err => {
				//console.log('list carriers error:', error)
			});
		},
		editExistingJob(jobNumber) {
			this.$router.push({
				name: 'jobEdit',
				params: { id: jobNumber }
			});
		},
		getJobStatus() {
			axios.get('/jobstatus').then(response => {
				this.alljobstatus = response.data.result;
				this.current = this.alljobstatus.filter(x => x.name == 'Open').map(x => x.id)[0];

				let self = this;
				this.alljobstatus.forEach(function (item, index) {
					item.name = self.isMobile() ? item.name : item.name + ' Jobs'
				});

				this.getJobs(this.current);
			})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
		},
		getJobs(id) {
			var jobstatusid = id == 0 ? [] : [id];
			var request_fields = {
				'customer_id': this.customerid,
				'job_status_id': jobstatusid
			};

			var that = this;
			this.options.requestAdapter = function (data) {
				return {
					limit: data.limit ? data.limit : 5,
					page: data.page,
					customer_id: request_fields.customer_id,
					job_status_id: request_fields.job_status_id,
					fromCustomer: true,
				}
			}

			this.$refs.vueservertable.refresh();
		},
		saveJobNote() {
			var request_fields = {
				'notes': this.form.add.notes,
				'job_id': this.jobid
			};
			axios.post('/jobnotes', request_fields)
				.then(response => {
					this.form.add.notes = '';
					this.getJobs();
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
			document.querySelector('.close2addnote').click();

		},
		editModal(item) {
			this.form.edit.notes = item.notes;
			this.form.edit.id = item.id;
		},
		updateJobNote() {
			var request_fields = {
				'notes': this.form.edit.notes,
				'job_id': this.jobid,
				'id': this.form.edit.id
			};
			axios.post('/jobnotes/update', request_fields)
				.then(response => {
					this.getJobs();
				})
				.catch(err => {
					//console.log('list carriers error:', error)
				});
			document.querySelector('.close2editnote').click();
		},
		getJobId(data) {
			this.showEditJobModal = true;
			this.jobdata = data;
		},
		isMobile() {
			return $(window).width() < 481;
		},
		receiveNewJob(newJob) {
		},
	}
}
</script>
